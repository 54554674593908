import {
  COControlInterface,
  COControlInterfaceKeys,
  COQuestionTemplateInterface,
  COSortType
} from "../interfaces/co-interfaces";
import {
  COCalculatedValueTypes,
  COComponentType,
  COTypes,
  COConditionSlugs
} from "../constants/co-constants";
import {
  COUnitSlugs,
  unitControl,
  unitOptions
} from "./elements/controls/co-units.template";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";
import {
  descriptionQuestionValidators,
  roundToDecimalTransformQuestionValidators,
  titleQuestionValidators,
  equationLengthValidator,
  variableLengthQuestionValidators,
  tabularQuestionValidators
} from "./elements/validators/co-validators.template";
import {
  decimalNumberQuestionControls,
  defaultQuestionEquationAnswerControl,
  kpiChartControls,
  kpiVisibilityControls,
  kpuUsedInQuestionControl,
  sensitiveInformationDropdownControls,
  templateKPIControl
} from "./elements/controls/co-controls.template";
import {
  kpiChartMeta,
  kpiMeta,
  variableQuestionTemplateMeta
} from "./elements/meta/co-meta.template";
import {
  CO_CHART_DEFAULT_RANGE,
  CO_CHART_TYPES
} from "../constants/co-chart.constants";
import { updateCustomizationKPIValidators } from "./elements/validators/co-submission-update-validators.template";
import { COPipelineFilterOptionTemplateSlugs } from "./elements/pipeline/co-pipeline.template-filters";
import { mergeTemplates } from "./co-template.template";
import { CODefaultEquations } from "../constants/co-calculation.constants";
import {
  NonStandardKPISlugs,
  StandardKPISlugs
} from "../constants/co-question.constants";
import { branchingQuestionControlMeta } from "./elements/controls/co-question-branching-controls.template";

export const kpiTemplateForSlug = (
  template_slug: StandardKPISlugs | string | undefined
): COQuestionTemplateInterface | undefined => {
  switch (template_slug) {
    case StandardKPISlugs.NUMBER_KPI:
      return numberKPI();
    case StandardKPISlugs.PERCENT_KPI:
      return percentKPI();
    case StandardKPISlugs.LOCKED_NUMBER_KPI:
      return lockedNumberKPI();
    case StandardKPISlugs.LOCKED_PERCENT_KPI:
      return lockedPercentKPI();
    case StandardKPISlugs.CHART_KPI:
      return chartKPI();
    case StandardKPISlugs.APPLICATION_KPI:
      return applicationKPI();
    case StandardKPISlugs.NUMBER_REFERENCE_KPI:
      return numberReferenceKPI();
    case StandardKPISlugs.REFERENCE_KPI:
      return referenceKPI();
  }

  let slugTemplate: COQuestionTemplateInterface = {
    co_question_template_slug: template_slug,
    co_question_json: {
      co_question_template_slug: template_slug
    }
  };

  // can override any property specifically here at the template level - to minimize duplication
  switch (template_slug) {
    case NonStandardKPISlugs.Q3_POTENTIAL_BENEFIT_KPI: {
      let template = mergeTemplates([
        numberKPI(),
        slugTemplate,
        {
          co_controls_json: [
            ...templateKPIControl({ is_disabled: 1 }),
            unitControl({
              unitOptions: unitOptions().filter(
                unit => unit.slug !== COUnitSlugs.PERCENT && !unit.is_custom
              ),
              is_disabled: 1
            }),
            ...decimalNumberQuestionControls({ is_disabled: 1 }),
            ...viewSettingsControls(),
            ...defaultQuestionEquationAnswerControl(),
            ...kpuUsedInQuestionControl()
          ],
          co_meta_json: {
            title: {
              display_context_overrides: {
                assessment_customize: {
                  is_editable: false
                }
              }
            },
            variable: {
              display_context_overrides: {
                assessment_customize: {
                  is_editable: false
                }
              }
            },
            note: {
              value:
                "This is a special KPI, and can't be fully edited or deleted.",
              value_localization_key: "co_kpi_potential_benefit_note"
            }
          },
          co_options_json: {
            unit: PATH({
              route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
            }),
            condition_overrides: [
              { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
              { slug: COConditionSlugs.HIDE_FOR_EDIT },
              {
                slug:
                  COConditionSlugs.HIDE_KPI_IF_NOT_CALCULATED_IN_VIEW_CONTEXT
              }
            ],
            prevent_deleting: 1
          }
        }
      ]);
      return template;
    }
    case StandardKPISlugs.GAUGE_KPI: {
      // Change only for the current gauge KPI (Overall Score in Q1), but open for future feature
      let template = mergeTemplates([
        chartKPI(),
        slugTemplate,
        {
          co_controls_json: [
            ...kpiChartControls({ is_disabled: 1 }),
            ...templateKPIControl({ is_disabled: 1 }),
            ...viewSettingsControls()
          ],
          co_meta_json: {
            ...branchingQuestionControlMeta(),
            ...kpiChartMeta(),
            ...variableQuestionTemplateMeta({ is_editable: false })
          }
        }
      ]);
      return template;
    }
  }
};

const viewSettingsControls = (): COControlInterface[] => {
  const controls: COControlInterface[] = [
    ...kpiVisibilityControls(),
    ...sensitiveInformationDropdownControls()
  ];
  return controls;
};

export const advancedKPITemplates = (): COQuestionTemplateInterface[] => {
  return [
    numberKPI(),
    percentKPI(),
    chartKPI(),
    applicationKPI(),
    lockedPercentKPI(),
    lockedNumberKPI()
  ];
};

export const KPITemplates = (): COQuestionTemplateInterface[] => {
  return [numberKPI(), percentKPI(), chartKPI()];
};

export const numberKPI = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardKPISlugs.NUMBER_KPI,
    co_question_template_co_type: COTypes.KPI,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Number",
        value_localization_key: "co_kpi_type_number"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...kpiMeta()
    },
    co_controls_json: [
      ...templateKPIControl(),
      unitControl({
        unitOptions: unitOptions().filter(
          unit => unit.slug !== COUnitSlugs.PERCENT && !unit.is_custom
        )
      }),
      ...decimalNumberQuestionControls(),
      ...viewSettingsControls(),
      ...defaultQuestionEquationAnswerControl(),
      ...kpuUsedInQuestionControl()
    ],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NUMERIC
      },
      optional_for_submission: 1,
      condition_overrides: [
        { slug: COConditionSlugs.HIDE_KPI_IF_NOT_CALCULATED_IN_VIEW_CONTEXT }
      ]
    },
    co_validators_json: [
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...roundToDecimalTransformQuestionValidators(),
      ...updateCustomizationKPIValidators(),
      ...equationLengthValidator(),
      ...variableLengthQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_template_slug: StandardKPISlugs.NUMBER_KPI,
      co_question_co_type: COTypes.KPI,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        component_type: COComponentType.KPI_TABLE_ROW,
        visibility_permissions: [],
        condition_overrides: [],
        can_impact_kpi: 1,
        is_disabled: 0,
        rounding_decimals: 2,
        unit: PATH({
          route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
        })
      },
      co_variable_name: "",
      co_equation: CODefaultEquations.NUMBER_KPI,
      co_display_equation: ""
    }
  };
};

export const percentKPI = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardKPISlugs.PERCENT_KPI,
    co_question_template_co_type: COTypes.KPI,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Percentage",
        value_localization_key: "co_kpi_type_percentage"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...kpiMeta()
    },
    co_controls_json: [
      ...templateKPIControl(),
      ...roundToDecimalTransformQuestionValidators(),
      ...viewSettingsControls(),
      ...defaultQuestionEquationAnswerControl(),
      ...kpuUsedInQuestionControl()
    ],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NUMERIC
      },
      optional_for_submission: 1,
      condition_overrides: [
        { slug: COConditionSlugs.HIDE_KPI_IF_NOT_CALCULATED_IN_VIEW_CONTEXT }
      ]
    },
    co_validators_json: [
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationKPIValidators(),
      ...equationLengthValidator(),
      ...variableLengthQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_template_slug: StandardKPISlugs.PERCENT_KPI,
      co_question_co_type: COTypes.KPI,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        pipeline_filter_options_slug:
          COPipelineFilterOptionTemplateSlugs.COPercentFilter,
        component_type: COComponentType.KPI_TABLE_ROW,
        visibility_permissions: [],
        condition_overrides: [],
        can_impact_kpi: 1,
        is_disabled: 0,
        rounding_decimals: 2,
        unit: PATH({
          route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.PERCENT
        })
      },
      co_variable_name: "",
      co_equation: CODefaultEquations.NUMBER_KPI,
      co_display_equation: ""
    }
  };
};

export const lockedPercentKPI = (): COQuestionTemplateInterface => {
  let kpi = percentKPI();
  kpi.co_question_template_slug = StandardKPISlugs.LOCKED_PERCENT_KPI;
  if (kpi.co_question_json) {
    kpi.co_question_json.co_question_template_slug =
      StandardKPISlugs.LOCKED_PERCENT_KPI;
  }

  // prevent deletion
  if (kpi.co_options_json) {
    kpi.co_options_json.prevent_deleting = 1;
  }
  // remove variable editing
  if (kpi.co_meta_json?.variable) {
    kpi.co_meta_json.variable.is_editable = false;
  }

  // remove template change option
  let templateControl = (kpi.co_controls_json || []).find(
    control => control.key === COControlInterfaceKeys.CONTROL_TEMPLATE
  );
  if (templateControl) {
    if (templateControl.options) {
      templateControl.options.is_disabled = 1;
      templateControl.items = PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.KPI_TYPE_ADVANCED_CONTROL_OPTIONS
      });
    }
  }
  return kpi;
};

export const lockedNumberKPI = (): COQuestionTemplateInterface => {
  let kpi = numberKPI();
  kpi.co_question_template_slug = StandardKPISlugs.LOCKED_NUMBER_KPI;
  if (kpi.co_question_json) {
    kpi.co_question_json.co_question_template_slug =
      StandardKPISlugs.LOCKED_NUMBER_KPI;
  }

  // prevent deletion
  if (kpi.co_options_json) {
    kpi.co_options_json.prevent_deleting = 1;
  }
  // remove variable editing
  if (kpi.co_meta_json?.variable) {
    kpi.co_meta_json.variable.is_editable = false;
  }

  // remove template change option
  let templateControl = (kpi.co_controls_json || []).find(
    control => control.key === COControlInterfaceKeys.CONTROL_TEMPLATE
  );
  if (templateControl) {
    if (templateControl.options) {
      templateControl.options.is_disabled = 1;
      templateControl.items = PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.KPI_TYPE_ADVANCED_CONTROL_OPTIONS
      });
    }
  }

  // remove old and swap new
  kpi.co_controls_json = (kpi.co_controls_json || []).filter(
    control =>
      control.key !== COControlInterfaceKeys.CONTROL_UNITS &&
      control.key !== COControlInterfaceKeys.CONTROL_ROUNDING
  );
  // unit option switchup
  let newUnitControl = unitControl({ unitOptions: unitOptions() });
  if (newUnitControl.options) {
    newUnitControl.options.is_disabled = 1;
  }
  kpi.co_controls_json?.push(newUnitControl);

  return kpi;
};

export const applicationKPI = (): COQuestionTemplateInterface => {
  let kpi = numberKPI();
  kpi.co_question_template_slug = StandardKPISlugs.APPLICATION_KPI;
  if (kpi.co_question_json) {
    kpi.co_question_json.co_question_template_slug =
      StandardKPISlugs.APPLICATION_KPI;
  }
  // prevent deletion
  if (kpi.co_options_json) {
    kpi.co_options_json.prevent_deleting = 1;
    kpi.co_options_json.is_read_only = 1;
  }

  let templateControl = (kpi.co_controls_json || []).find(
    control => control.key === COControlInterfaceKeys.CONTROL_TEMPLATE
  );
  if (templateControl) {
    if (templateControl.options) {
      templateControl.options.is_disabled = 1;
      templateControl.items = PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.KPI_TYPE_ADVANCED_CONTROL_OPTIONS
      });
    }
  }
  return kpi;
};

export const chartKPI = (): COQuestionTemplateInterface => {
  let kpi: COQuestionTemplateInterface = {
    co_question_template_slug: StandardKPISlugs.CHART_KPI,
    co_question_template_co_type: COTypes.CHART,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Chart",
        value_localization_key: "co_kpi_type_chart"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...kpiChartMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_controls_json: [
      ...templateKPIControl(),
      ...viewSettingsControls(),
      ...kpiChartControls({ is_disabled: 0 })
    ],
    co_validators_json: [
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_options_json: {
      is_read_only: 0,
      no_tabular_support: 1,
      optional_for_submission: 1
    },
    co_question_json: {
      co_question_template_slug: StandardKPISlugs.CHART_KPI,
      co_question_co_type: COTypes.CHART,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        component_type: COComponentType.KPI_CHART,
        visibility_permissions: [],
        is_disabled: 0,
        chart: {
          x_axis_range: CO_CHART_DEFAULT_RANGE,
          y_axis_range: CO_CHART_DEFAULT_RANGE,
          bubble_range: CO_CHART_DEFAULT_RANGE,
          component_type: CO_CHART_TYPES.BUBBLE_CHART,
          y_axis_ahid: "",
          x_axis_ahid: "",
          bubble_ahid: "",
          title: {
            value: ""
          }
        },
        condition_overrides: []
      },
      co_question_default_options_json: {},
      co_variable_name: "",
      co_equation: "",
      co_display_equation: ""
    }
  };

  let templateControl = (kpi.co_controls_json || []).find(
    control => control.key === COControlInterfaceKeys.CONTROL_TEMPLATE
  );

  return kpi;
};

export const numberReferenceKPI = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
    co_question_template_co_type: COTypes.REFERENCE,
    co_question_template_meta_json: {},
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...kpiMeta()
    },
    co_controls_json: [],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NUMERIC
      },
      calculated_value_type: COCalculatedValueTypes.NUMBER,
      optional_for_submission: 1
    },
    co_validators_json: [],
    co_question_json: {
      co_question_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
      co_question_co_type: COTypes.REFERENCE,
      co_question_options_json: {
        component_type: COComponentType.KPI_TABLE_ROW,
        visibility_permissions: [],
        condition_overrides: [],
        is_disabled: 0
      },
      co_equation: CODefaultEquations.REFERENCE_KPI
    }
  };
};

export const referenceKPI = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardKPISlugs.REFERENCE_KPI,
    co_question_template_co_type: COTypes.REFERENCE,
    co_question_template_meta_json: {},
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...kpiMeta()
    },
    co_controls_json: [],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.ALPHABETICAL
      },
      calculated_value_type: COCalculatedValueTypes.TEXT,
      optional_for_submission: 1
    },
    co_validators_json: [],
    co_question_json: {
      co_question_template_slug: StandardKPISlugs.REFERENCE_KPI,
      co_question_co_type: COTypes.REFERENCE,
      co_question_options_json: {
        component_type: COComponentType.KPI_TABLE_ROW,
        visibility_permissions: [],
        condition_overrides: [],
        is_disabled: 0,
        unit: PATH({
          route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
        })
      },
      co_equation: CODefaultEquations.REFERENCE_KPI
    }
  };
};
