import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  convertFromHTML
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  getAllDraftJsDecorators,
  linkDecorator
} from "../components/TextEditorComments/TextEditorComments";
import { CONTAINS_HTML_TAG_REGEX } from "../constants/regexConstants";

export const isEmptyDraftJs = rawString => {
  if (!rawString) {
    return true;
  }

  try {
    let parsed = JSON.parse(rawString);
    let editor_state = EditorState.createWithContent(convertFromRaw(parsed));
    let content_state = editor_state.getCurrentContent();
    let plain_text = content_state.getPlainText();
    return plain_text.length === 0 && content_state.hasText() === false;
  } catch (e) {
    return true;
  }
};

export const stringFromRaw = rawString => {
  if (!rawString || typeof rawString !== "string") {
    return "";
  }
  try {
    let comment_text = JSON.parse(rawString.replace(/(\r\n|\n|\r)/gm, ""));
    comment_text = EditorState.createWithContent(convertFromRaw(comment_text));
    return comment_text.getCurrentContent().getPlainText();
  } catch (e) {
    return "";
  }
};

export const isStringDraftJS = rawString => {
  if (!rawString || typeof rawString !== "string") {
    return "";
  }
  try {
    let comment_text = JSON.parse(rawString.replace(/(\r\n|\n|\r)/gm, ""));
    comment_text = EditorState.createWithContent(convertFromRaw(comment_text));
    return !!comment_text.getCurrentContent();
  } catch (e) {
    return false;
  }
};

export const convertToStringified = draftJSObject => {
  const obj = JSON.stringify(convertToRaw(draftJSObject.getCurrentContent()));

  return obj;
};

const _iterateThroughBlockMap = (contentState, callback) => {
  const blockMap = contentState.getBlockMap();

  for (const [key, block] of blockMap) {
    typeof callback === "function" && callback(block, key);
  }
};

export const getBlockCharacters = contentBlock => {
  if (!contentBlock) {
    return "";
  }

  const text = contentBlock.getText();
  return text.split("\n").join("\n");
};

//this only counts characters, and ignores new line
export const getCharacters = contentState => {
  if (!contentState) {
    return "";
  }

  const blockTextArr = [];

  _iterateThroughBlockMap(contentState, block => {
    const textArrWithoutNewLine = getBlockCharacters(block);

    blockTextArr.push(textArrWithoutNewLine);
  });

  return blockTextArr.join("");
};

// get plain text content if passed a draftjs string
// return string if normal string
export const getPlainTextFromDraftJsOrString = str => {
  if (typeof str !== "string") {
    return "";
  }

  const contentString = stringFromRaw(str);

  // Error parsing probably not draftjs
  if (contentString === "" && str !== "") {
    return str;
  }
  return contentString;
};

export const getHtmlFromDraftString = draftString => {
  try {
    const parsedData = JSON.parse(draftString.replace(/(\r\n|\n|\r)/gm, ""));
    const draftObj = convertFromRaw(parsedData);
    const htmlString = stateToHTML(draftObj);
    return htmlString;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const getDraftOrEmptyStringFromHtml = htmlString => {
  try {
    const blocksFromHTML = convertFromHTML(htmlString);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return convertToStringified(draftJsCreateWithContentAndPlugins(state));
  } catch (e) {
    return "";
  }
};

export const getDraftStateFromHtml = htmlString => {
  try {
    const blocksFromHTML = convertFromHTML(htmlString);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return draftJsCreateWithContentAndPlugins(state);
  } catch (e) {
    console.log(e);
    return EditorState.createEmpty();
  }
};

export const draftJsCreateWithContentAndPlugins = content => {
  return EditorState.set(
    EditorState.createWithContent(content, getAllDraftJsDecorators()),
    {
      decorator: linkDecorator
    }
  );
};

// plain text, html string, or draftjs string to draftjs string
export const toDraftJsString = text => {
  if (typeof text !== "string") {
    return convertToRichOrPlainText("", "rich-text");
  }
  // already draftjs
  if (text[0] === "{" && text[text.length - 1] === "}") {
    try {
      JSON.parse(text);
      return text;
    } catch (e) {
      // Do nothing and continue to next case
    }
  }

  if (CONTAINS_HTML_TAG_REGEX.test(text)) {
    const draftFromHtml = getDraftOrEmptyStringFromHtml(text);
    if (draftFromHtml) {
      return draftFromHtml;
    }
  }

  return convertToRichOrPlainText(text, "rich-text");
};

/*
  @param inputValue: string | number | undefined | null:
  @param conversionType: "rich-text" | "plain-text"; 
  @return: returns a plain string or a draft js JSON string
*/
export const convertToRichOrPlainText = (
  inputValue,
  conversionType = "rich-text"
) => {
  let rawValue;

  if (inputValue === undefined || inputValue === null) {
    rawValue = "";
  } else if (typeof inputValue === "number") {
    rawValue = inputValue.toString();
  } else {
    rawValue = inputValue;
  }

  let processedValue;

  if (conversionType === "rich-text") {
    try {
      //check if json string can be converted to a draftjs object
      const parsedJsonString = JSON.parse(rawValue);
      const draftJSObject = draftJsCreateWithContentAndPlugins(
        convertFromRaw(parsedJsonString)
      );

      processedValue = !!draftJSObject
        ? convertToStringified(draftJSObject)
        : convertToStringified(
            draftJsCreateWithContentAndPlugins(ContentState.createFromText(""))
          );
    } catch (e) {
      //string is not valid draftjs, convert plain text to draftjs string
      processedValue = convertToStringified(
        draftJsCreateWithContentAndPlugins(
          ContentState.createFromText(rawValue)
        )
      );
    }
    return processedValue;
  } else if (conversionType === "plain-text") {
    //convert any value to plain text
    // `stringFromRaw` returns "" if not valid draft js string
    processedValue = stringFromRaw(rawValue);

    //if processedValue === "" but rawValue is not falsy
    //we know that rawValue is plain text and should return that
    // an empty draft js object returns the whole thing - this needs fixing
    return processedValue === "" && rawValue ? rawValue : processedValue;
  }
};

export const getCurrentSelectionDomElement = (
  editorState,
  editorContainerDom
) => {
  try {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();

    const currentSelectionDomElement = editorContainerDom
      ? editorContainerDom.querySelector(`span[data-offset-key^="${startKey}"]`)
      : document.querySelector(`span[data-offset-key^="${startKey}"]`);

    return currentSelectionDomElement;
  } catch {
    return null;
  }

  return null;
};
