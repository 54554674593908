import React from "react";
import { useTranslation } from "react-i18next";
import "./BadgePopup.scss";
import { achievementBadgeFromSlug } from "./../../../utils/achievementHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import urlWithTenant from "../../../utils/urlHelper";

const BadgePopup = props => {
  const {
    badge_slug = "slug",
    badge_name = "unknown",
    badge_description = "unknown"
  } = props.badge;

  const { clickHandler } = props;
  const { t } = useTranslation("common");

  let link = "#";
  let user = SessionHelper.currentUser();
  if (user && user.user_profile_slug) {
    if (
      props.badge &&
      props.badge.achievement_id &&
      props.badge.achievement_id > 0
    ) {
      link = urlWithTenant(`/user/${user.user_profile_slug}/path#achievements`);
    } else {
      link = urlWithTenant(`/user/${user.user_profile_slug}/path#badges`);
    }
  }

  return (
    <a className="BadgePopup" href={link} onClick={clickHandler}>
      <img
        className="BadgePopup__image"
        src={achievementBadgeFromSlug(badge_slug)}
        alt="badge"
      />
      <div className="BadgePopup__text">
        <h2 className="BadgePopup__name">
          {t(["gamification_title_be_" + badge_slug, badge_name])}
        </h2>
        <p className="BadgePopup__description">
          {t(["gamification_content_be_" + badge_slug, badge_description])}
        </p>
      </div>
    </a>
  );
};

export default BadgePopup;
