import { unitTemplates } from "../templates/elements/controls/co-units.template";
import {
  kpiTemplateAdvancedControlOptions,
  kpiTemplateControlOptions,
  questionTemplateControlOptions
} from "../templates/co-questions.template";
import {
  COBaseInterface,
  COContextInterface,
  COSectionInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { COValidationErrorManager } from "./co-validation-error-manager.class";
import {
  contextPathKeys,
  coreContextPathKeys
} from "../constants/co-context.constants";

// super super slow to have these initilized each time - moved to globals
let templateCache: any;
const templates = () => {
  if (!templateCache) {
    templateCache = {
      units: unitTemplates(),
      question_type_control_options: questionTemplateControlOptions(false),
      question_type_advanced_control_options: questionTemplateControlOptions(
        true
      ),
      kpi_type_control_options: kpiTemplateControlOptions(),
      kpi_type_advanced_control_options: kpiTemplateAdvancedControlOptions()
    };
  }
  return templateCache;
};

export interface COContext extends COContextInterface {}
export class COContext {
  constructor(context: COContextInterface) {
    let assigned = Object.assign(this, context);
    if (!assigned.validationErrorManager) {
      assigned.validationErrorManager = new COValidationErrorManager();
    }
    // removed from here because we don't want to to have to import COProcessExternalData
    // if (!assigned.process_external_data) {
    //   assigned.process_external_data = new COProcessExternalData();
    // }
    if (!assigned.function_hooks) {
      assigned.function_hooks = {};
    }
    assigned.templates = templates();

    Object.defineProperty(this, "comparison_context", {
      get() {
        return comparisonContextWithAHIDsMatched(this);
      }
    });

    return assigned;
  }
  // toJSON is automatically used by JSON.stringify
  toJSON?(): any {
    // copy all fields from `this` to an empty object and return in
    let jsonObject = Object.assign({}, this, {
      // convert fields that need converting
    });

    delete jsonObject["validationErrorManager"];

    //kills off all the internal objects and only well send up display context stuff
    for (const pathKey of contextPathKeys) {
      delete jsonObject[pathKey];
    }

    return jsonObject;
  }

  // this needs to preserve the underlying pointers to the objects
  update?(contextInterface: COContextInterface): COContext {
    // create copy of old context
    let newContext = new COContext(this);
    // update sub objects as well
    // assign fields of new
    newContext = Object.assign(newContext, contextInterface);
    return newContext;
  }

  // used to update a "top level only" loaded assessment with a full object

  setFullAssessment?({ assessment }: { assessment: COBaseInterface }) {
    // keep same object - but update the assessment and remove referenced objects that won't match anymore
    delete this.process_answer_selection;
    delete this.process_answer;
    delete this.answer_option;
    delete this.question;
    delete this.section;
    this.assessment = assessment;
    // clear validation errors
    if (this.validationErrorManager) {
      this.validationErrorManager.validation_errors = [];
    }
    return this;
  }

  functionAllOtherSectionsAndQuestions?(
    context: COContextInterface
  ): [COSectionInterface[], COQuestionInterface[]] {
    let sections: COSectionInterface[] = [];
    let questions: COQuestionInterface[] = [];

    if (context.assessment?.co_assessment_sections) {
      for (const section of context.assessment.co_assessment_sections) {
        if (
          section.co_assessment_section_ahid !==
            context.section?.co_assessment_section_ahid &&
          section.co_questions
        ) {
          const sectionIncludesExcludedQuestion = section.co_questions.some(
            question => {
              return (
                question.options?.excluded_from_branching_paths_selection === 1
              );
            }
          );
          // add section if it's not the current section
          // and it has no excluded questions
          if (!sectionIncludesExcludedQuestion) {
            sections.push(section);
          }
        }

        if (section.co_questions) {
          for (const question of section.co_questions) {
            if (
              question.co_question_ahid !==
                context.question?.co_question_ahid &&
              question.options?.excluded_from_branching_paths_selection !== 1
            ) {
              // add question if it's not the current question and not excluded from branching selection
              questions.push(question);
            }
          }
        }
      }
    }

    return [sections, questions];
  }

  // comparison context - used with validators to compare 2 assessments for differences
}

export const comparisonContextWithAHIDsMatched = (
  context: COContextInterface
) => {
  //  we update the comparison context to have the same object structure by finding AHIDs
  // then we can just use the regular path system on the comparison_context object
  let comparison_context: COContextInterface | null =
    context.validation_context?.comparison_context || null;
  if (comparison_context) {
    let comparison_context_matched: COContext | undefined = new COContext({
      assessment: comparison_context.assessment, // this one is obvious
      validation_context: comparison_context.validation_context,
      display_context: comparison_context.display_context
    });
    // section question answer_option
    let comparison_assessment: COBaseInterface | undefined =
      comparison_context_matched.assessment;
    if (comparison_assessment) {
      for (const key of coreContextPathKeys) {
        let coObjectInContext: COBaseInterface | undefined = context[key];
        if (coObjectInContext) {
          if (coObjectInContext.getAHID) {
            let ahidToFind = coObjectInContext.getAHID();
            // now we try to find it in the comparison context traversing from assessment down
            if (ahidToFind) {
              let comparison_context_object = comparison_assessment.findAHId?.(
                ahidToFind
              );
              if (comparison_context_object) {
                let updateProperty = {};
                updateProperty[key] = comparison_context_object;
                comparison_context_matched = comparison_context_matched?.update?.(
                  updateProperty
                );
              } else {
                console.log(`object not found for ahid ${ahidToFind}`);
                console.log(coObjectInContext);
              }
            }
          }
        }
      }
      //now we should have a up to date context match we can path through easily
      return comparison_context_matched;
    }
    // the comparison context has to have the same AHID object (this is to compare versions against eachother)
  }

  // go through all the main properties and try to update them to match

  return comparison_context;
};

export const // this is used almost entirely by the frontend to replace the old context.update function which now needs to be optional, and got messy
  updateContext = (
    context?: COContextInterface,
    contextObjects?: COContextInterface
  ): COContextInterface => {
    let retContext = context || new COContext({});
    return retContext.update?.(contextObjects || {}) || retContext;
  };
