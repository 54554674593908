import {
  FETCH_ALL_DOCUMENT_TEMPLATES,
  FETCH_ALL_DOCUMENT_TEMPLATE_TYPES,
  SET_DOCUMENT_TEMPLATE_TO_UPDATE,
  SEARCH_DOCUMENT_TEMPLATES,
  SET_SEARCH_QUERY,
  SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA
} from "./../constants/actionTypes";

const INITIAL_STATE = {
  processDefinitionDocuments: null,
  solutionDesignDocuments: null,
  developmentSpecificationDocuments: null,
  documentTemplateTypes: [],
  documentTemplateToUpdate: null,
  searchQuery: null,
  isLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_DOCUMENT_TEMPLATES:
      return {
        ...state,
        processDefinitionDocuments: action.payload.PDD,
        solutionDesignDocuments: action.payload.SDD,
        developmentSpecificationDocuments: action.payload.DSD
      };
    case FETCH_ALL_DOCUMENT_TEMPLATE_TYPES:
      return {
        ...state,
        documentTemplateTypes: action.payload.filter(
          doc => doc.file_type_id <= 3
        )
      };
    case SET_DOCUMENT_TEMPLATE_TO_UPDATE:
      return {
        ...state,
        documentTemplateToUpdate: action.payload
      };
    case SEARCH_DOCUMENT_TEMPLATES:
      let query = action.payload.query.toLowerCase();
      let templates = action.payload.templates;

      return {
        ...state,
        processDefinitionDocuments: templates.PDD.filter(
          t => t.template_title.toLowerCase().indexOf(query) !== -1
        ),
        solutionDesignDocuments: templates.SDD.filter(
          t => t.template_title.toLowerCase().indexOf(query) !== -1
        ),
        developmentSpecificationDocuments: templates.DSD.filter(
          t => t.template_title.toLowerCase().indexOf(query) !== -1
        )
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      };
    case SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
}
