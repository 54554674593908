export enum CORoleType {
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
  PROGRAM_MANAGER = "PROGRAM_MANAGER",
  CURATOR = "CURATOR",
  DIRECT_MANAGER = "DIRECT_MANAGER",
  SUBMITTER = "SUBMITTER",
  PROCESS_OWNER = "PROCESS_OWNER",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  AP_COLLABORATOR = "AP_COLLABORATOR",
  FOLLOWER_IN_AP = "FOLLOWER_IN_AP",
  STANDARD_USER = "STANDARD_USER",
  COMMENT_MENTIONED_USER = "COMMENT_MENTIONED_USER",
  COMPONENT_AUTHOR = "COMPONENT_AUTHOR",
  COMPONENT_DOWNLOADED_USER = "COMPONENT_DOWNLOADED_USER",
  IDEA_APPROVER = "IDEA_APPROVER",
  BUSINESS_REVIEWERS = "BUSINESS_REVIEWERS",
  TECHNICAL_REVIEWERS = "TECHNICAL_REVIEWERS",
  POWER_USER = "POWER_USER",
  INTERESTED_IN_APP = "INTERESTED_IN_APP",
  PROCESS_CONSUMER = "PROCESS_CONSUMER",
  DEALHUB = "DEALHUB",
  FINANCE = "FINANCE",
  PROCESS_COLLABORATOR = "PROCESS_COLLABORATOR",
  COMMENT_USER = "COMMENT_USER",
  ACCOUNT_OWNER = "ACCOUNT_OWNER",
  AUTOMATION_SUBMITTER = "AUTOMATION_SUBMITTER",
  ASSESSED_IDEA_REVIEWERS = "ASSESSED_IDEA_REVIEWERS",
  AUTHORIZED_USER = "AUTHORIZED_USER",
  TRIGGER_USER = "TRIGGER_USER"
}

export enum CORoleSlugType {
  AH_ACCOUNT_OWNER = "ah-account-owner",
  AH_SYSTEM_ADMIN = "ah-system-admin",
  AH_RPA_SPONSOR = "ah-rpa-sponsor",
  AH_COMPONENT_CURATOR = "ah-curator",
  AH_PROGRAM_MANAGER = "ah-program-manager",
  AH_IDEA_APPROVER = "ah-idea-approver",
  AH_AUTHORIZED_USER = "ah-authorized-user",
  AH_STANDARD_USER = "ah-standard-user",
  AH_SUBMITTER = "ah-submitter",
  AH_PROCESS_OWNER = "ah-process-owner",
  AH_PROJECT_MANAGER = "ah-project-manager",
  AH_BUSINESS_ANALYST = "ah-business-analyst",
  AH_SOLUTION_ARCHITECT = "ah-solution-architect",
  AH_RPA_DEVELOPER = "ah-rpa-developer",
  AH_PROCESS_ABOUT_SECTION_EDITOR = "ah-process-about-section-editor",
  AH_PROCESS_CBA_SECTION_EDITOR = "ah-process-cba-section-editor",
  AH_PROCESS_DOCUMENT_SECTION_EDITOR = "ah-process-documentation-section-editor",
  AH_PROCESS_COMPONENT_SECTION_EDITOR = "ah-process-component-section-editor",
  AH_COMPONENT_SUBMITTER = "ah-component-submitter",
  AH_COMPONENT_DOWNLOADER = "ah-component-downloader",
  AH_TOP_DOWN_SUBMITTER = "ah-top-down-submitter",
  AH_BUSINESS_REVIEWER = "ah-business-reviewer",
  AH_TECHNICAL_REVIEWER = "ah-technical-reviewer",
  AH_AUTOMATION_SUBMITTER = "ah-automation-submitter",
  AH_POWER_USER = "ah-cd-power-user",
  AH_CITIZEN_DEVELOPER = "ah-citizen-developer",
  AH_AUTOMATION_REQUESTER = "ah-automation-requester",
  AH_PROCESS_MINING_SUBMITTER = "ah-process-mining-submitter",
  AH_TASK_MINING_SUBMITTER = "ah-task-mining-submitter",
  AH_AUTOMATION_CONSUMER = "ah-automation-consumer",
  AH_CHANGE_REQUEST_SUBMITTER = "ah-change-request-submitter"
}

export enum COCollaboratorRoleType {
  PROCESS_SUBMITTER = "process_submitter",
  PROCESS_OWNER = "process_owner",
  SYSTEM_ADMIN = "system_admin",
  CURATOR = "curator",
  PROGRAM_MANAGER = "program_manager",
  DIRECT_MANAGER = "direct_manager",
  REPORTEE = "reportee",
  AUTHORIZED_USER = "authorized_user",
  COE_COLLABORATOR = "coe_collaborator",
  COLLABORATOR = "collaborator"
}
