export enum COAssessmentTemplateSlugs {
  OVR_DEFAULT_V1 = "OVR_DEFAULT_V1",
  Q1_DEFAULT_V1 = "Q1_DEFAULT_V1",
  Q2_DEFAULT_V1 = "Q2_DEFAULT_V1",
  CBA_DEFAULT_V1 = "CBA_DEFAULT_V1",
  CITIZEN_DEFAULT_V1 = "CITIZEN_DEFAULT_V1",
  MISC_DEFAULT_V1 = "MISC_DEFAULT_V1",
  STATS_DEFAULT_V1 = "STATS_DEFAULT_V1",
  CR_DEFAULT_V1 = "CR_DEFAULT_V1",
  EX_DEFAULT_V1 = "EX_DEFAULT_V1"
}
