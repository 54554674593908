import { CancelToken } from "axios";
import axios from "../axiosInstance";
import { filterCategoriesForCurrentUser } from "../categoryUtils";

export const getAdminCategoriesAndLevels = async (
  cancelToken?: CancelToken
): Promise<any> => {
  const path = `/api/v1/admin/categories`;
  const response = await axios.get(path, { cancelToken });
  return response.data.data;
};

export const syncCategories = async (categories): Promise<any> => {
  const path = `/api/v1/admin/categories`;
  const response = await axios.post(path, { categories });
  return response.data.data;
};

export const deleteCategory = async (category_id): Promise<any> => {
  const path = `/api/v1/admin/categories/${category_id}`;
  const response = await axios.delete(path);
  return response.data.data;
};

export const checkIfCategoryCanBeDeleted = async (
  category_id
): Promise<any> => {
  const path = `/api/v1/admin/categories/${category_id}/delete-check`;
  const response = await axios.get(path);
  return response.data.data;
};

export const addCategoryLevel = async (category_level_name): Promise<any> => {
  const path = `/api/v1/admin/category-levels`;
  const response = await axios.post(path, {
    category_level_name: category_level_name
  });
  return response.data.data;
};

export const editCategoryLevel = async (level): Promise<any> => {
  const path = `/api/v1/admin/category-levels/${level.category_level_id}`;
  const response = await axios.patch(path, {
    category_level_name: level.category_level_name
  });
  return response.data.data;
};

export const deleteCategoryLevel = async (level): Promise<any> => {
  const path = `/api/v1/admin/category-levels/${level.category_level_id}`;
  const response = await axios.delete(path);
  return response.data.data;
};

export const checkIfCategoryLevelCanBeDeleted = async (
  category_level_id
): Promise<any> => {
  const path = `/api/v1/admin/category-levels/${category_level_id}/delete-check`;
  const response = await axios.get(path);
  return response.data.data.can_delete;
};

export const getCategoryApplications = async (category_id): Promise<any> => {
  const path = `/api/v1/admin/categories/${category_id}/applications`;
  const response = await axios.get(path);
  return response.data.data;
};

export const getFilterCategoriesAndLevels = async (): Promise<any> => {
  const path = `/api/v1/categories`;
  const response = await axios.get(path);
  let processedData: any = {};
  processedData.levels = response?.data?.data?.levels || [];
  processedData.categories = filterCategoriesForCurrentUser(
    response?.data?.data?.categories || []
  );
  return processedData;
};

export const getAllCategoriesAndLevels = async (): Promise<any> => {
  const path = `/api/v1/categories`;
  const response = await axios.get(path);
  let processedData: any = {};
  processedData.levels = response?.data?.data?.levels || [];
  processedData.categories = response?.data?.data?.categories || [];
  return processedData;
};
