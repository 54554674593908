import React from "react";
import { useTranslation } from "react-i18next";
import "./CellTypeMyComponents.scss";

const CellTypeMyComponents = props => {
  const { component_type = {} } = props;
  const { t } = useTranslation("common");

  return (
    <div
      className="CellTypeMyComponents"
      data-testid="CellTypeMyComponentsContainer"
    >
      <span className="CellTypeMyComponents__type">
        {t([
          "component_type_" + component_type.component_type_slug,
          component_type.component_type_name || ""
        ])}
      </span>
    </div>
  );
};

export default CellTypeMyComponents;
