import React from "react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { hideModal } from "../../../../actions/modalActions";
import {
  clearCurrentAutomation,
  setStatus
} from "../../../../actions/automationPipelineActions";
import { AutomationPipelineState } from "../../../../reducers/automationPipelineReducer";
import ModalTemplatePipeline from "../ModalTemplatePipeline/ModalTemplatePipeline";
import { swalSpinnerLoading } from "../../../../utils/SweetAlertLoaderSpinner";
import { triggerAnalyticsWithDefaultData } from "../../../../utils/analyticsUtils";
import { ProcessInterface } from "../../../../types/processTypes";
import { automationPhaseStatusFireAnalytics } from "../../../../utils/automationUtils";

interface Props {
  hideModal: Function;
  clearCurrentAutomation: Function;
  setStatus: Function;
  automationPipeline: AutomationPipelineState;
  modalProps: {
    heading?: string;
    headingModalLocalizationKey?: string;
    body?: string;
    bodyLocalizationKey?: string;
    confirmText?: string;
    confirmTextLocalizationKey?: string;
    cancelText?: string;
    cancelTextLocalizationKey?: string;
    analyticEventName?: string;
    noAdditionalBEActions?: boolean;
  } | null;
  onConfirmProp?: (cb: Function) => void;
  children: any;
}

const ModalPipelineGeneric = ({
  hideModal,
  clearCurrentAutomation,
  setStatus,
  automationPipeline,
  modalProps,
  onConfirmProp,
  children
}: Props) => {
  const {
    statusChangeCallback,
    statusChangeId,
    currentAutomation
  } = automationPipeline;
  const { t } = useTranslation("common");

  const {
    heading = "",
    headingModalLocalizationKey = "",
    body = "",
    bodyLocalizationKey = "",
    confirmText = "",
    confirmTextLocalizationKey = "",
    cancelText = "",
    cancelTextLocalizationKey = "",
    analyticEventName,
    noAdditionalBEActions = false
  } = modalProps || {};

  const closeModal = () => {
    hideModal();
    clearCurrentAutomation();
  };

  const onConfirm = () => {
    const slug = currentAutomation?.process_slug;

    const setStatusFunction = (reason?: string) => {
      if (slug && statusChangeId) {
        const payload: { [key: string]: any } = {
          errorCallback: automationPipeline.errorCallback,
          slug,
          statusChangeId
        };
        if (reason) {
          payload.reason = reason;
        }
        if (noAdditionalBEActions) {
          payload.noAdditionalBEActions = true;
        }
        setStatus(payload, afterApprove);
        swalSpinnerLoading({
          title: t("modal_pipeline_on_confirm_loading_text", "Updating")
        });
        return;
      }
    };

    typeof onConfirmProp === "function"
      ? onConfirmProp(setStatusFunction)
      : setStatusFunction();
  };

  const afterApprove = data => {
    if (analyticEventName) {
      triggerAnalyticsWithDefaultData(analyticEventName);
    }

    automationPhaseStatusFireAnalytics({
      automation: currentAutomation as ProcessInterface,
      phaseStatusProps: {
        statusChangeId: statusChangeId as number
      }
    });
    clearCurrentAutomation();

    typeof statusChangeCallback === "function" && statusChangeCallback(data);
  };

  let headingTranslated: string = t(
    "automation_pipeline_modal_header_generic",
    "Perform Action?"
  );

  let textTranslated: string | React.ReactElement = t(
    "automation_pipeline_modal_body_generic",
    "Are you sure you want to perform this action?"
  );

  let confirmTextTranslated: string = t(
    "automation_pipeline_modal_confirm_text_generic",
    "Confirm"
  );

  let cancelTextTranslated: string = t(
    "automation_pipeline_modal_cancel_text_generic",
    "Cancel"
  );

  if (headingModalLocalizationKey) {
    headingTranslated = t(headingModalLocalizationKey, heading);
  }

  if (bodyLocalizationKey) {
    textTranslated = (
      <Trans t={t} i18nKey={bodyLocalizationKey}>
        {body}
      </Trans>
    );
  }

  if (confirmTextLocalizationKey) {
    confirmTextTranslated = t(confirmTextLocalizationKey, confirmText);
  }

  if (cancelTextLocalizationKey) {
    cancelTextTranslated = t(cancelTextLocalizationKey, cancelText);
  }

  if (confirmTextLocalizationKey)
    return (
      <ModalTemplatePipeline
        heading={headingTranslated}
        closeModal={closeModal}
        onConfirm={onConfirm}
        text={textTranslated}
        cancelText={cancelTextTranslated}
        confirmText={confirmTextTranslated}
      >
        {children}
      </ModalTemplatePipeline>
    );
};

const mapStateToProps = ({ automationPipeline, modal }) => ({
  automationPipeline,
  modalProps: modal.modalProps
});

const mapDispatchToProps = {
  hideModal,
  clearCurrentAutomation,
  setStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPipelineGeneric);
