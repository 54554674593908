import {
  FETCH_COMPONENT_COMMENTS,
  ADD_NEW_COMPONENT_COMMENT,
  SET_COMPONENT_COMMENT_ID_TO_EDIT,
  SET_COMPONENT_COMMENT_ID_TO_REPLY_TO,
  DELETE_COMPONENT_COMMENT,
  UPDATE_COMPONENT_COMMENT,
  CLEAR_COMPONENT_COMMENTS
} from "../constants/actionTypes";

import uniqBy from "lodash/uniqBy";

const INITIAL_STATE = {
  componentProfileComments: null,
  currentComponentProfileCommentPage: 1,
  totalComponentProfilePages: 1,
  commentIdToEdit: null,
  commentIdToReplyTo: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COMPONENT_COMMENTS:
      let payload = action.payload;
      if (state.componentProfileComments) {
        var currentComments = state.componentProfileComments.comments;
        var newComments = payload.comments;
        let allComments = uniqBy(
          [...newComments, ...currentComments],
          "comment_id"
        );
        payload.comments = [...allComments].sort(
          (a, b) => b.comment_created_epoch - a.comment_created_epoch
        );
      } else {
        payload.comments = payload.comments.sort(
          (a, b) => b.comment_created_epoch - a.comment_created_epoch
        );
      }
      return {
        ...state,
        componentProfileComments: payload,
        needToFetchProcessProfileComments: false
      };
    case DELETE_COMPONENT_COMMENT:
      let componentProfileComments = { ...state.componentProfileComments };
      if (action.payload.comment_parent_id) {
        let commentParent = componentProfileComments.comments.find(
          obj => obj.comment_id === action.payload.comment_parent_id
        );
        if (commentParent) {
          commentParent.replies = commentParent.replies.filter(
            obj => obj.comment_id !== action.payload.comment_id
          );
        }
      } else {
        componentProfileComments.comments = componentProfileComments.comments.filter(
          obj => obj.comment_id !== action.payload.comment_id
        );
      }

      return {
        ...state,
        componentProfileComments: componentProfileComments
      };
    case UPDATE_COMPONENT_COMMENT:
      componentProfileComments = { ...state.componentProfileComments };
      var index = componentProfileComments.comments.findIndex(
        obj => obj.comment_id === action.payload.comment_id
      );
      if (index > -1) {
        componentProfileComments.comments[index] = action.payload;
      }
      return {
        ...state,
        componentProfileComments: componentProfileComments
      };
    case ADD_NEW_COMPONENT_COMMENT:
      componentProfileComments = null;
      if (state.componentProfileComments) {
        componentProfileComments = { ...state.componentProfileComments };
        componentProfileComments.comments.unshift(action.payload);
      }
      return {
        ...state,
        componentProfileComments: componentProfileComments
      };
    case SET_COMPONENT_COMMENT_ID_TO_EDIT:
      return {
        ...state,
        commentIdToEdit: action.payload
      };
    case SET_COMPONENT_COMMENT_ID_TO_REPLY_TO:
      return {
        ...state,
        commentIdToReplyTo: action.payload
      };
    case CLEAR_COMPONENT_COMMENTS:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
