import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successIcon from "../../assets/images/ic-success-3.svg";
import { NESTED_THEME_CONTAINER_ID } from "../StyleThemeProvider/StyleThemeProvider";

import "./ToastAutomationPipeline.scss";

const ToastSwal = withReactContent(Swal).mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  customClass: {
    container: "ToastAutomationPipeline__container",
    popup: "ToastAutomationPipeline"
  },
  showCloseButton: true,
  imageUrl: successIcon,
  target: `#${NESTED_THEME_CONTAINER_ID}`,
  timer: 3000
});

export default ToastSwal;
