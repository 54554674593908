import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const taskAssignedOriginalNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = taskAssignedOriginalNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.TASK_ASSIGNED_ORIGINAL,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const taskAssignedOriginalNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.SUBMITTED_IDEA_TO_REVIEW: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.IDEA_APPROVER]
          },
          legacy_notification_id: 11,
          platform_event_type: COPlatformEventType.IDEA_SUBMITTED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.SUBMITTED_IDEA_APPROVED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Submitted idea to review"
          },
          message: {
            value:
              "The automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " submitted by " +
              COActionReplacementStrings.PROCESS_SUBMITTER_NAME +
              " needs your review."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
