import {
  FETCHING_RPA_SOFTWARE_VENDOR_DATA,
  FETCH_RPA_SOFTWARE_VENDOR_DATA,
  CLEAR_RPA_SOFTWARE_VENDOR_DATA,
  FETCH_RPA_SOFTWARE_VENDOR_DROPDOWN_DATA
} from "../constants/actionTypes";

const INITIAL_STATE = {
  rpaSoftwareVendorData: null,
  rpaSoftwareVendorDropdownData: null,
  isLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_RPA_SOFTWARE_VENDOR_DATA:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_RPA_SOFTWARE_VENDOR_DATA:
      return {
        ...state,
        rpaSoftwareVendorData: action.payload,
        isLoading: false
      };
    case CLEAR_RPA_SOFTWARE_VENDOR_DATA:
      return {
        ...state,
        rpaSoftwareVendorData: null
      };
    case FETCH_RPA_SOFTWARE_VENDOR_DROPDOWN_DATA:
      return {
        ...state,
        rpaSoftwareVendorDropdownData: action.payload
      };
    default:
      return state;
  }
}
