import React, { useEffect, useRef } from "react";
import {
  maintainFocusInContainer,
  enableArrowNavigation,
  focusOnFirstFocusableElement
} from "../../../utils/accessibilityUtils/accessibilityUtils";

//for dropdown container menus
//should implement "keyboard trap" and "keyboard navigation"
const AccessibleMenuContainer = ({
  className = "",
  hasKeyboardTrap = true,
  hasKeyboardNavigation = true,
  component = "div",
  closeMenu,
  children,
  ...restProps
}: {
  children: any;
  className?: string;
  component?: string;
  hasKeyboardTrap?: boolean;
  hasKeyboardNavigation?: boolean;
  closeMenu?: () => any;
}) => {
  const accessibleContainerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(
    null
  );

  useEffect(() => {
    if (hasKeyboardTrap && accessibleContainerRef.current) {
      focusOnFirstFocusableElement(accessibleContainerRef.current);
    }
  }, [hasKeyboardTrap]);

  const onKeyDown = e => {
    if (
      hasKeyboardNavigation &&
      (e.key === "ArrowUp" ||
        e.keyCode === 38 ||
        e.key === "ArrowDown" ||
        e.keyCode === 40)
    ) {
      enableArrowNavigation(e, accessibleContainerRef?.current);
    }

    if (hasKeyboardTrap && (e.key === "Tab" || e.keyCode === 9)) {
      maintainFocusInContainer(e, accessibleContainerRef?.current);
    }

    if (
      typeof closeMenu === "function" &&
      (e.key === "Escape" || e.keyCode === 27)
    ) {
      closeMenu();
    }
  };

  return React.createElement(
    component,
    {
      ...restProps,
      ref: accessibleContainerRef,
      className: className,
      onKeyDown
    },
    children
  );
};

export default AccessibleMenuContainer;
