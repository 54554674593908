import { Button } from "@material-ui/core";
import React from "react";
import { ButtonApolloProps } from "../../../types/buttonTypes";

const ButtonApolloPrimary = (props: ButtonApolloProps) => {
  return (
    <Button variant="contained" disableElevation color="secondary" {...props} />
  );
};

export default ButtonApolloPrimary;
