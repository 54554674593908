import { COAssessment } from "../classes/co-assessment.class";
import { COContext } from "../classes/co-context.class";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../constants/co-path.constants";
import { COPathOverrideInterface } from "../interfaces/co-interfaces";

import {
  getValue,
  resolvePathWithOverrides,
  setValue
} from "../utils/co-path.utils";
import {
  coAssertArrayOrNull,
  coAssertArrayWithContents,
  coAssertEquals,
  coAssertString,
  coAssertUndefinedOrNull
} from "../utils/co-test.utils";

export const testPathSystem = ({
  assessment,
  logSuccess = false
}: {
  assessment: COAssessment;
  logSuccess?: boolean;
}) => {
  // basic path get

  let context = new COContext({
    assessment
  });

  testPathGET({
    testName: "test path get",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.TITLE.VALUE
    }),
    logSuccess
  });

  // basic path set (which requires path to path get and path to path set)
  testPathSET({
    testName: "test path set",
    context:
      context.update?.({
        section: context.assessment?.co_assessment_sections?.[0]
      }) || context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_META_JSON.TITLE.VALUE
    }),
    parent: {
      value: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_META_JSON.TITLE.VALUE
      })
    },
    logSuccess
  });

  testPathGET({
    testName: "test path get array",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_SECTIONS_OBJECT
    }),
    assertFCT: coAssertArrayWithContents,
    logSuccess
  });

  // lets have a recursive pat // this should return a path (string)
  let section = context.assessment?.co_assessment_sections?.[0] || {};
  if (section.co_section_options_json) {
    section.co_section_options_json.is_hidden = PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN
    });
  }
  testPathGET({
    testName: "test path get recursive death",
    context: context.update?.({ section: section }) || context,
    assertFCT: coAssertString,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN
    }),
    logSuccess
  });
  if (section.co_section_options_json) {
    section.co_section_options_json.is_hidden = 0;
  }

  testPathGET({
    testName: "test path no object in context",
    context: new COContext({ assessment }),
    assertFCT: coAssertUndefinedOrNull,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN
    }),
    logSuccess
  });

  testPathGET({
    testName: "test path invalid",
    context: new COContext({ assessment }),
    assertFCT: coAssertUndefinedOrNull,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN
    }),
    logSuccess
  });

  testPathGET({
    testName: "test path inverse function",
    context: context.update?.({ section: section }) || context,
    assertFCT: (object, name) => {
      return coAssertEquals(object, 1);
    },
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN,
      operator: PATH_OPERATORS.INVERSE
    }),
    logSuccess
  });

  testResolvePath({
    testName: "test path resolve no overrides",
    context: new COContext({
      assessment,
      section: assessment.co_assessment_sections?.[0]
    }),
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_META_JSON.TITLE.VALUE
    }),
    logSuccess
  });

  testResolvePath({
    testName: "test path resolve with override",
    context: new COContext({
      assessment,
      section: assessment.co_assessment_sections?.[0]
    }),
    path: PATH({
      route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN
    }),
    overrides: [
      {
        value: "banana",
        path: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_HIDDEN
        })
      }
    ],
    logSuccess
  });
};

export const testPathGET = ({
  testName,
  context,
  path,
  assertFCT = coAssertString,
  logSuccess = false
}: {
  testName: string;
  context: COContext;
  path: string;
  assertFCT?: Function;
  logSuccess?: boolean;
}) => {
  let value = getValue(context, path);
  assertFCT(value, path);
  if (logSuccess) {
    console.log(`Test Passed ${testName} - ${path}`);
  }
};

export const testPathSET = ({
  testName,
  context,
  path,
  assertFCT = coAssertString,
  parent,
  logSuccess = false
}: {
  testName: string;
  context: COContext;
  path: string;
  parent: any;
  assertFCT?: Function;
  logSuccess?: boolean;
}) => {
  let valueToSet = "Test Value";
  let value = setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: valueToSet
  });
  assertFCT(value, path);
  let getRecheck = getValue(context, path);
  coAssertEquals(valueToSet, getRecheck);
  if (logSuccess) {
    console.log(`Test Passed ${testName} - ${path}`);
  }
};

export const testResolvePath = ({
  testName,
  context,
  path,
  overrides = [],
  logSuccess = false
}: {
  testName: string;
  context: COContext;
  path: string;
  logSuccess?: boolean;
  overrides?: COPathOverrideInterface[];
}) => {
  let resolvedPath = resolvePathWithOverrides({
    context,
    valueOrPath: path,
    overrides
  });
  coAssertString(resolvedPath?.value, path);
  if (overrides && overrides.length > 0) {
    coAssertEquals(resolvedPath?.value, overrides[0].value);
    coAssertArrayOrNull(resolvedPath?.pathArray, path);
  } else {
    coAssertArrayWithContents(resolvedPath?.pathArray, path);
  }

  if (logSuccess) {
    console.log(`Test Passed ${testName} - ${path}`);
  }
};
