const browsersSupported = () => {
  const user_browser = window.navigator.userAgent;
  const isFirefox =
    user_browser.indexOf("Firefox") > -1 &&
    user_browser.indexOf("Chrome") === -1;
  const isSafari =
    user_browser.indexOf("Safari") > -1 &&
    user_browser.indexOf("Chrome") === -1;
  const isOpera = user_browser.indexOf("OPR") > -1 && !!window.opr;
  const isChrome = !!window.chrome;

  return isFirefox || isSafari || isOpera || isChrome;
};

export default browsersSupported;
