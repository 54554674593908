import React from "react";
import swal, { swalClose } from "../components/SweetAlert/SweetAlert";
import LoaderLogoSmall from "../components/LoaderLogoSmall/LoaderLogoSmall";
import { LocalizeTranslationKey } from "./localizedAlertMessages";
import { NESTED_THEME_CONTAINER_ID } from "../components/StyleThemeProvider/StyleThemeProvider";
import { SweetAlertOptions } from "sweetalert2";

export const SweetAlertLoaderSpinner = (
  options: SweetAlertOptions | any = {}
) => {
  const {
    title = options.title || "Saving",
    icon = "info",
    showGif = true,
    text = "",
    ...restOptions
  }: SweetAlertOptions | any = options;
  const loadingGif = showGif ? <LoaderLogoSmall /> : null;
  const checkIcon = null;

  return swal.fire({
    title,
    icon,
    text,
    imageUrl: checkIcon,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showCloseButton: false,
    html: loadingGif,
    animation: false,
    target: `#${NESTED_THEME_CONTAINER_ID}`,
    ...restOptions
  });
};

export const swalSpinnerLoading = (options: SweetAlertOptions | any = {}) => {
  const {
    title = <LocalizeTranslationKey localeKey={"alert_saving_your_changes"} />,
    ...restOptions
  } = options;
  return SweetAlertLoaderSpinner({
    title,
    icon: "info",
    showGif: true,
    showCloseButton: false,
    ...restOptions
  });
};

export const swalSpinnerSuccess = (options: SweetAlertOptions | any = {}) => {
  return SweetAlertLoaderSpinner({
    title: <LocalizeTranslationKey localeKey={"alert_success_title"} />,
    icon: "success",
    showGif: false,
    showCloseButton: true,
    ...options
  });
};

export const swalSpinnerForAuthenticatingLoading = (
  options: SweetAlertOptions | any = {}
) => {
  return SweetAlertLoaderSpinner({
    title: "",
    icon: "info",
    showGif: true,
    showCloseButton: false
  });
};

export const swalSpinnerClose = () => {
  swalClose();
};
