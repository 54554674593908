import {
  COContextInterface,
  COQuestionInterface,
  COQuestionAnswerOptionInterface,
  COProcessAnswerSelectionInterface,
  COProcessAnswerInterface
} from "../interfaces/co-interfaces";
import { newProcessAnswerWithoutImporting } from "./co-circular-refrence-helper";

export const addEmptyAdditionalAnswerSelection = ({
  context,
  question,
  questionAnswerOption
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionAnswerOption: COQuestionAnswerOptionInterface;
}) => {
  if (!question.co_process_answer) {
    question.co_process_answer = newProcessAnswerWithoutImporting({
      context,
      question
    });
  }

  let process_answer: COProcessAnswerInterface = question.co_process_answer;
  if (process_answer.addAdditionalAnswerSelection) {
    process_answer.addAdditionalAnswerSelection({
      context,
      question,
      answerOption: questionAnswerOption
    });
  }
};

// had to take out of processAnswer class because templates need to use controls, controls are on on templates, which extend the main objects, and circular references
export const removeAnswerSelection = ({
  context,
  process_answer,
  process_answer_selection
}: {
  context: COContextInterface;
  process_answer: COProcessAnswerInterface;
  process_answer_selection: COProcessAnswerSelectionInterface;
}) => {
  if (process_answer) {
    process_answer.co_process_answer_selections = process_answer.co_process_answer_selections?.filter(
      pas => pas !== process_answer_selection
    );
  }
};
