export enum CONotificationActionSlugs {
  SUBMITTED_IDEA_TO_REVIEW = "SUBMITTED_IDEA_TO_REVIEW",
  SUBMITTED_IDEA_APPROVED = "SUBMITTED_IDEA_APPROVED",
  SUBMITTED_IDEA_FOLLOWED_APPROVED = "SUBMITTED_IDEA_FOLLOWED_APPROVED",
  PROCESS_OWNER_ADDED_IN_IDEA = "PROCESS_OWNER_ADDED_IN_IDEA",
  REVIEW_APPROVED_IDEA = "REVIEW_APPROVED_IDEA",
  SUBMITTED_IDEA_REJECTED = "SUBMITTED_IDEA_REJECTED",
  IDEA_FOLLOWED_REJECTED = "IDEA_FOLLOWED_REJECTED",
  SUBMITTED_IDEA_MARKED_AS_DUPLICATE = "SUBMITTED_IDEA_MARKED_AS_DUPLICATE",
  IDEA_FOLLOWED_MARKED_AS_DUPLICATE = "IDEA_FOLLOWED_MARKED_AS_DUPLICATE",
  PARENT_IDEA_NEW_FOLLOWER = "PARENT_IDEA_NEW_FOLLOWER",
  PARENT_IDEA_NEW_UPVOTE = "PARENT_IDEA_NEW_UPVOTE",
  ASSESSMENT_LAUNCHED = "ASSESSMENT_LAUNCHED",
  AUTOMATION_MOVED_TO_ASSESSMENT_NOT_STARTED = "AUTOMATION_MOVED_TO_ASSESSMENT_NOT_STARTED",
  REVIEW_ASSESSED_AUTOMATION = "REVIEW_ASSESSED_AUTOMATION",
  ASSESSMENT_SCORE_GENERATED = "ASSESSMENT_SCORE_GENERATED",
  ASSESSED_AUTOMATION_APPROVED = "ASSESSED_AUTOMATION_APPROVED",
  ASSIGN_PROJECT_MANAGER = "ASSIGN_PROJECT_MANAGER",
  ASSESSED_AUTOMATION_REJECTED = "ASSESSED_AUTOMATION_REJECTED",
  ASSESSED_AUTOMATION_PUT_ON_HOLD = "ASSESSED_AUTOMATION_PUT_ON_HOLD",
  AUTOMATION_ARCHIVED = "AUTOMATION_ARCHIVED",
  PROJECT_MANAGER_ASSIGNED = "PROJECT_MANAGER_ASSIGNED",
  YOU_WERE_ADDED_AS_COLLABORATOR = "YOU_WERE_ADDED_AS_COLLABORATOR",
  NEW_COLLABORATOR_ADDED = "NEW_COLLABORATOR_ADDED",
  ABOUT_EDITING_RIGHTS_GRANTED = "ABOUT_EDITING_RIGHTS_GRANTED",
  DOCUMENTATION_EDITING_RIGHTS_GRANTED = "DOCUMENTATION_EDITING_RIGHTS_GRANTED",
  YOU_WERE_REMOVED_AS_COLLABORATOR = "YOU_WERE_REMOVED_AS_COLLABORATOR",
  COLLABORATOR_REMOVED_FROM_AP = "COLLABORATOR_REMOVED_FROM_AP",
  YOU_WERE_REMOVED_FROM_CERTAIN_ROLE = "YOU_WERE_REMOVED_FROM_CERTAIN_ROLE",
  ROLE_REMOVED_FOR_EXISTING_COLLABORATOR = "ROLE_REMOVED_FOR_EXISTING_COLLABORATOR",
  ABOUT_EDITING_RIGHTS_REVOKED = "ABOUT_EDITING_RIGHTS_REVOKED",
  DOCUMENTATION_EDITING_RIGHTS_REVOKED = "DOCUMENTATION_EDITING_RIGHTS_REVOKED",
  PHASE_STATUS_CHANGED = "PHASE_STATUS_CHANGED",
  OVERVIEW_SECTION_INFO_CHANGED = "OVERVIEW_SECTION_INFO_CHANGED",
  AP_ABOUT_PAGE_UPDATED = "AP_ABOUT_PAGE_UPDATED",
  NEW_DOCUMENT_ADDED_IN_AP = "NEW_DOCUMENT_ADDED_IN_AP",
  DOCUMENT_UPDATED_IN_AP = "DOCUMENT_UPDATED_IN_AP",
  DOCUMENT_DELETED_IN_AP = "DOCUMENT_DELETED_IN_AP",
  NEW_FOLLOWER_IN_AP = "NEW_FOLLOWER_IN_AP",
  NEW_UPVOTE_IN_AP = "NEW_UPVOTE_IN_AP",
  NEW_COMMENT_ADDED_IN_AP = "NEW_COMMENT_ADDED_IN_AP",
  NEW_REPLY_ADDED_IN_AP = "NEW_REPLY_ADDED_IN_AP",
  USER_MENTIONED_IN_COMMENT_IN_AP = "USER_MENTIONED_IN_COMMENT_IN_AP",
  ABUSE_REPORTED_AP = "ABUSE_REPORTED_AP",
  DIRECT_MANAGER_ADDED_TO_USER = "DIRECT_MANAGER_ADDED_TO_USER",
  DIRECT_MANAGER_REMOVED_FROM_USER = "DIRECT_MANAGER_REMOVED_FROM_USER",
  NEW_PROCESS_ROLE_ADDED = "NEW_PROCESS_ROLE_ADDED",
  PROCESS_ROLE_UPDATED = "PROCESS_ROLE_UPDATED",
  PROCESS_ROLE_DISABLED = "PROCESS_ROLE_DISABLED",
  PROCESS_ROLE_ENABLED = "PROCESS_ROLE_ENABLED",
  PROCESS_ROLE_DELETED = "PROCESS_ROLE_DELETED",
  TEMPLATE_DOCUMENT_ADDED = "TEMPLATE_DOCUMENT_ADDED",
  TEMPLATE_DOCUMENT_UPDATED = "TEMPLATE_DOCUMENT_UPDATED",
  TEMPLATE_DOCUMENT_SET_AS_DEFAULT = "TEMPLATE_DOCUMENT_SET_AS_DEFAULT",
  TEMPLATE_DOCUMENT_DELETED = "TEMPLATE_DOCUMENT_DELETED",
  NEW_SUPPORT_TASK_ADDED = "NEW_SUPPORT_TASK_ADDED",
  NEW_REPORT_ABUSE_TASK_ADDED = "NEW_REPORT_ABUSE_TASK_ADDED",
  PROJECT_MANAGER_ROLE_ASSIGNED = "PROJECT_MANAGER_ROLE_ASSIGNED",
  NEW_REUSABLE_COMPONENT_ADDED_IN_AP = "NEW_REUSABLE_COMPONENT_ADDED_IN_AP",
  REUSABLE_COMPONENT_REMOVED_IN_AP = "REUSABLE_COMPONENT_REMOVED_IN_AP",
  SYSTEM_ADMIN_ADDED_TO_USER = "SYSTEM_ADMIN_ADDED_TO_USER",
  ASSIGNED_SYSTEM_ADMIN = "ASSIGNED_SYSTEM_ADMIN",
  PROGRAM_MANAGER_ADDED_TO_USER = "PROGRAM_MANAGER_ADDED_TO_USER",
  CURATOR_ADDED_TO_USER = "CURATOR_ADDED_TO_USER",
  SYSTEM_ADMIN_REMOVED_FROM_USER = "SYSTEM_ADMIN_REMOVED_FROM_USER",
  PROGRAM_MANAGER_REMOVED_FROM_USER = "PROGRAM_MANAGER_REMOVED_FROM_USER",
  CURATOR_REMOVED_FROM_USER = "CURATOR_REMOVED_FROM_USER",
  PROCESS_HIERARCHY_UPDATED = "PROCESS_HIERARCHY_UPDATED",
  APPLICATION_INVENTORY_UPDATED = "APPLICATION_INVENTORY_UPDATED",
  USER_INFORMATION_UPDATED = "USER_INFORMATION_UPDATED",
  YOU_WERE_ASSIGNED_PROGRAM_MANAGER = "YOU_WERE_ASSIGNED_PROGRAM_MANAGER",
  YOU_WERE_ASSIGNED_CURATOR = "YOU_WERE_ASSIGNED_CURATOR",
  YOU_WERE_REMOVED_AS_SYSTEM_ADMIN = "YOU_WERE_REMOVED_AS_SYSTEM_ADMIN",
  YOU_WERE_REMOVED_AS_PROGRAM_MANAGER = "YOU_WERE_REMOVED_AS_PROGRAM_MANAGER",
  YOU_WERE_REMOVED_AS_CURATOR = "YOU_WERE_REMOVED_AS_CURATOR",
  COMPONENT_ARCHIVED = "COMPONENT_ARCHIVED",
  COMPONENT_FOLLOWED_UPDATED = "COMPONENT_FOLLOWED_UPDATED",
  COMPONENT_UPLOADED_FOR_CURATION = "COMPONENT_UPLOADED_FOR_CURATION",
  COMPONENT_CURATION_STARTED = "COMPONENT_CURATION_STARTED",
  COMPONENT_CURATION_REJECTED = "COMPONENT_CURATION_REJECTED",
  COMPONENT_CURATION_NEEDS_UPDATE = "COMPONENT_CURATION_NEEDS_UPDATE",
  COMPONENT_CURATION_PUBLISHED = "COMPONENT_CURATION_PUBLISHED",
  COMPONENT_CURATION_DOWNLOADED = "COMPONENT_CURATION_DOWNLOADED",
  COMPONENT_REVIEW_ADDED = "COMPONENT_REVIEW_ADDED",
  COMPONENT_COMMENT_ADDED = "COMPONENT_COMMENT_ADDED",
  COMPONENT_NEW_REPLY = "COMPONENT_NEW_REPLY",
  COMPONENT_NEW_COMMENT = "COMPONENT_NEW_COMMENT",
  AUTHORIZED_USER_ADDED_TO_USER = "AUTHORIZED_USER_ADDED_TO_USER",
  COE_COLLABORATOR_ADDED_TO_USER = "COE_COLLABORATOR_ADDED_TO_USER",
  YOU_WERE_ASSIGNED_AUTHORIZED_USER = "YOU_WERE_ASSIGNED_AUTHORIZED_USER",
  YOU_WERE_ASSIGNED_COE_COLLABORATOR = "YOU_WERE_ASSIGNED_COE_COLLABORATOR",
  AUTHORIZED_USER_REMOVED_FROM_USER = "AUTHORIZED_USER_REMOVED_FROM_USER",
  COE_COLLABORATOR_REMOVED_FROM_USER = "COE_COLLABORATOR_REMOVED_FROM_USER",
  YOU_WERE_REMOVED_AS_AUTHORIZED_USER = "YOU_WERE_REMOVED_AS_AUTHORIZED_USER",
  YOU_WERE_REMOVED_AS_COE_COLLABORATOR = "YOU_WERE_REMOVED_AS_COE_COLLABORATOR",
  USER_SYNC_FINISHED = "USER_SYNC_FINISHED",
  CD_AUTOMATION_APPROVED = "CD_AUTOMATION_APPROVED",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW = "CD_AUTOMATION_TO_TECHNICAL_REVIEW",
  CD_AUTOMATION_ON_HOLD = "CD_AUTOMATION_ON_HOLD",
  CD_ATUOMATION_REJECTED = "CD_ATUOMATION_REJECTED",
  CD_AUTOMATION_APPROVED_FOR_CONSUMPTION = "CD_AUTOMATION_APPROVED_FOR_CONSUMPTION",
  CD_AUTOMATION_REWORK_REQUIRED = "CD_AUTOMATION_REWORK_REQUIRED",
  CD_AUTOMATION_ARCHIVED_BUSINESS_REVIEW = "CD_AUTOMATION_ARCHIVED_BUSINESS_REVIEW",
  CD_AUTOMATION_APPROVED_FOR_POWER_USER = "CD_AUTOMATION_APPROVED_FOR_POWER_USER",
  CD_AUTOMATION_ARCHIVED_TECHNICAL_REVIEW = "CD_AUTOMATION_ARCHIVED_TECHNICAL_REVIEW",
  AUTOMATION_PUBLISHED_FOR_LIVE_CONSUMPTION = "AUTOMATION_PUBLISHED_FOR_LIVE_CONSUMPTION",
  AUTOMATION_HAS_UPDATED_PACKAGE = "AUTOMATION_HAS_UPDATED_PACKAGE",
  USER_MENTIONED_IN_QUESTION_IN_AS = "USER_MENTIONED_IN_QUESTION_IN_AS",
  QUEUE_ITEMS_FINISHED = "QUEUE_ITEMS_FINISHED"
}

export enum CONotificationActionGroupSlugs {
  SUBMITTED_IDEA_APPROVED = "SUBMITTED_IDEA_APPROVED",
  PROCESS_OWNER_ADDED_IN_IDEA = "PROCESS_OWNER_ADDED_IN_IDEA",
  REVIEW_APPROVED_IDEA = "REVIEW_APPROVED_IDEA",
  SUBMITTED_IDEA_REJECTED = "SUBMITTED_IDEA_REJECTED",
  DUPLICATE_SUBMITTED_IDEA = "DUPLICATE_SUBMITTED_IDEA",
  PARENT_IDEA_NEW_FOLLOWER = "PARENT_IDEA_NEW_FOLLOWER",
  PARENT_IDEA_NEW_UPVOTE = "PARENT_IDEA_NEW_UPVOTE",
  ASSESSMENT_LAUNCHED = "ASSESSMENT_LAUNCHED",
  REVIEW_ASSESSED_AUTOMATION = "REVIEW_ASSESSED_AUTOMATION",
  ASSESSMENT_SCORE_GENERATED = "ASSESSMENT_SCORE_GENERATED",
  ASSESSED_AUTOMATION_APPROVED = "ASSESSED_AUTOMATION_APPROVED",
  ASSESSED_AUTOMATION_REJECTION = "ASSESSED_AUTOMATION_REJECTION",
  ASSIGN_PROJECT_MANAGER = "ASSIGN_PROJECT_MANAGER",
  ASSESSED_AUTOMATION_PUT_ON_HOLD = "ASSESSED_AUTOMATION_PUT_ON_HOLD",
  AUTOMATION_ARCHIVED = "AUTOMATION_ARCHIVED",
  PROJECT_MANAGER_ASSIGNED = "PROJECT_MANAGER_ASSIGNED",
  ADDED_AS_PROCESS_COLLABORATOR = "ADDED_AS_PROCESS_COLLABORATOR",
  EDITING_ABOUT_GRANTED = "EDITING_ABOUT_GRANTED",
  EDITING_DOCUMENTATION_GRANTED = "EDITING_DOCUMENTATION_GRANTED",
  REMOVED_FROM_PROCESS = "REMOVED_FROM_PROCESS",
  REMOVED_AS_PROCESS_COLLABORATOR = "REMOVED_AS_PROCESS_COLLABORATOR",
  EDITING_ABOUT_REVOKED = "EDITING_ABOUT_REVOKED",
  EDITING_DOCUMENTATION_REVOKED = "EDITING_DOCUMENTATION_REVOKED",
  PHASE_STATUS_CHANGED = "PHASE_STATUS_CHANGED",
  OVERVIEW_SECTION_INFO_CHANGED = "OVERVIEW_SECTION_INFO_CHANGED",
  AP_ABOUT_PAGE_UPDATED = "AP_ABOUT_PAGE_UPDATED",
  NEW_DOCUMENT_ADDED_IN_AP = "NEW_DOCUMENT_ADDED_IN_AP",
  DOCUMENT_UPDATED_IN_AP = "DOCUMENT_UPDATED_IN_AP",
  DOCUMENT_DELETED_IN_AP = "DOCUMENT_DELETED_IN_AP",
  NEW_FOLLOWER_IN_AP = "NEW_FOLLOWER_IN_AP",
  NEW_UPVOTE_IN_AP = "NEW_UPVOTE_IN_AP",
  NEW_COMMENT_ADDED_IN_AP = "NEW_COMMENT_ADDED_IN_AP",
  NEW_REPLY_ADDED_IN_AP = "NEW_REPLY_ADDED_IN_AP",
  USER_MENTIONED_IN_COMMENT_IN_AP = "USER_MENTIONED_IN_COMMENT_IN_AP",
  ABUSE_REPORTED_AP = "ABUSE_REPORTED_AP",
  DIRECT_MANAGER_ADDED_TO_USER = "DIRECT_MANAGER_ADDED_TO_USER",
  DIRECT_MANAGER_REMOVED_FROM_USER = "DIRECT_MANAGER_REMOVED_FROM_USER",
  NEW_PROCESS_ROLE_ADDED = "NEW_PROCESS_ROLE_ADDED",
  PROCESS_ROLE_UPDATED = "PROCESS_ROLE_UPDATED",
  PROCESS_ROLE_DISABLED = "PROCESS_ROLE_DISABLED",
  PROCESS_ROLE_ENABLED = "PROCESS_ROLE_ENABLED",
  PROCESS_ROLE_DELETED = "PROCESS_ROLE_DELETED",
  TEMPLATE_DOCUMENT_ADDED = "TEMPLATE_DOCUMENT_ADDED",
  TEMPLATE_DOCUMENT_UPDATED = "TEMPLATE_DOCUMENT_UPDATED",
  TEMPLATE_DOCUMENT_SET_AS_DEFAULT = "TEMPLATE_DOCUMENT_SET_AS_DEFAULT",
  TEMPLATE_DOCUMENT_DELETED = "TEMPLATE_DOCUMENT_DELETED",
  NEW_SUPPORT_TASK_ADDED = "NEW_SUPPORT_TASK_ADDED",
  NEW_REPORT_ABUSE_TASK_ADDED = "NEW_REPORT_ABUSE_TASK_ADDED",
  PROJECT_MANAGER_ROLE_ASSIGNED = "PROJECT_MANAGER_ROLE_ASSIGNED",
  NEW_REUSABLE_COMPONENT_ADDED_IN_AP = "NEW_REUSABLE_COMPONENT_ADDED_IN_AP",
  REUSABLE_COMPONENT_REMOVED_IN_AP = "REUSABLE_COMPONENT_REMOVED_IN_AP",
  ADDED_SYSTEM_ADMIN = "ADDED_SYSTEM_ADMIN",
  ADDED_PROGRAM_MANAGER = "ADDED_PROGRAM_MANAGER",
  ADDED_CURATOR = "ADDED_CURATOR",
  REMOVED_SYSTEM_ADMIN = "REMOVED_SYSTEM_ADMIN",
  REMOVED_PROGRAM_MANAGER = "REMOVED_PROGRAM_MANAGER",
  REMOVED_CURATOR = "REMOVED_CURATOR",
  PROCESS_HIERARCHY_UPDATED = "PROCESS_HIERARCHY_UPDATED",
  APPLICATION_INVENTORY_UPDATED = "APPLICATION_INVENTORY_UPDATED",
  USER_INFORMATION_UPDATED = "USER_INFORMATION_UPDATED",
  COMPONENT_ARCHIVED = "COMPONENT_ARCHIVED",
  COMPONENT_FOLLOWED_UPDATED = "COMPONENT_FOLLOWED_UPDATED",
  COMPONENT_UPLOADED_FOR_CURATION = "COMPONENT_UPLOADED_FOR_CURATION",
  COMPONENT_CURATION_STARTED = "COMPONENT_CURATION_STARTED",
  COMPONENT_CURATION_REJECTED = "COMPONENT_CURATION_REJECTED",
  COMPONENT_CURATION_NEEDS_UPDATE = "COMPONENT_CURATION_NEEDS_UPDATE",
  COMPONENT_CURATION_PUBLISHED = "COMPONENT_CURATION_PUBLISHED",
  COMPONENT_CURATION_DOWNLOADED = "COMPONENT_CURATION_DOWNLOADED",
  COMPONENT_REVIEW_ADDED = "COMPONENT_REVIEW_ADDED",
  COMPONENT_COMMENT = "COMPONENT_COMMENT",
  ADDED_AUTHORIZED_USER = "ADDED_AUTHORIZED_USER",
  ADDED_COE_COLLABORATOR = "ADDED_COE_COLLABORATOR",
  REMOVED_AUTHORIZED_USER = "REMOVED_AUTHORIZED_USER",
  REMOVED_COE_COLLABORATOR = "REMOVED_COE_COLLABORATOR",
  USER_SYNC_FINISHED = "USER_SYNC_FINISHED",
  CD_AUTOMATION_APPROVED = "CD_AUTOMATION_APPROVED",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW = "CD_AUTOMATION_TO_TECHNICAL_REVIEW",
  CD_AUTOMATION_ON_HOLD = "CD_AUTOMATION_ON_HOLD",
  CD_AUTOMATION_REJECTED = "CD_AUTOMATION_REJECTED",
  CD_AUTOMATION_APPROVED_FOR_CONSUMPTION = "CD_AUTOMATION_APPROVED_FOR_CONSUMPTION",
  CD_AUTOMATION_REWORK_REQUIRED = "CD_AUTOMATION_REWORK_REQUIRED",
  CD_AUTOMATION_ARCHIVED_BUSINESS_REVIEW = "CD_AUTOMATION_ARCHIVED_BUSINESS_REVIEW",
  CD_AUTOMATION_READY_FOR_POWER_USER = "CD_AUTOMATION_READY_FOR_POWER_USER",
  CD_AUTOMATION_ARCHIVED_TECHNICAL_REVIEW = "CD_AUTOMATION_ARCHIVED_TECHNICAL_REVIEW",
  AUTOMATION_PUBLISHED_FOR_LIVE_CONSUMPTION = "AUTOMATION_PUBLISHED_FOR_LIVE_CONSUMPTION",
  AUTOMATION_HAS_UPDATED_PACKAGE = "AUTOMATION_HAS_UPDATED_PACKAGE",
  USER_MENTIONED_IN_QUESTION_IN_AS = "USER_MENTIONED_IN_QUESTION_IN_AS",
  QUEUE_ITEMS_FINISHED = "QUEUE_ITEMS_FINISHED"
}

export enum CONotificationActionType {
  TASK_ASSIGNED_ORIGINAL = "TASK_ASSIGNED",
  AUTOMATION_REVIEWED = "AUTOMATION_REVIEWED",
  FOLLOWED_AUTOMATION_UPDATES = "FOLLOWED_AUTOMATION_UPDATES",
  COLLABORATION_INVITES = "COLLABORATION_INVITES",
  TASK_ASSIGNED = "TASK_ASSIGNED",
  FOLLOWERS = "FOLLOWERS",
  UPVOTES = "UPVOTES",
  AUTOMATION_STATUS_UPDATE = "AUTOMATION_STATUS_UPDATE",
  NEW_CONTENT = "NEW_CONTENT",
  COLLABORATORS_UPDATES = "COLLABORATORS_UPDATES",
  COMMENTS = "COMMENTS",
  MENTIONS = "MENTIONS",
  USER_PROFILE_UPDATES = "USER_PROFILE_UPDATES",
  ADMIN_CONSOLE_UPDATES = "ADMIN_CONSOLE_UPDATES",
  COMPONENT_SUPPORT = "COMPONENT_SUPPORT",
  CONTENT_DELETION = "CONTENT_DELETION",
  GAMIFICATION = "GAMIFICATION",
  ADMIN_ROLE_INVITES = "ADMIN_ROLE_INVITES",
  QUEUE_STATUS_UPDATES = "QUEUE_STATUS_UPDATES"
}

export enum CONotificationActionCategoryTypes {
  AUTOMATION = 1,
  COMPONENT = 2,
  USER = 3,
  ADMIN = 4,
  PROCESS_COMPONENT = 5,
  ADMIN_ROLES = 6,
  ADMIN_HIERARCHY = 7,
  ADMIN_APP_INVENTORY = 8
}
