import { EXTERNAL_CONNECTION_CONSTANTS } from "../constants/external-connection.constants";

/**
 * Validate Jira site url, it should follow https://{domain}.atlassian.net pattern.
 */
export const isValidJiraConnectionSiteUrl = (siteUrl: string) => {
  let url: URL;
  try {
    url = new URL(siteUrl);
  } catch (e) {
    return false;
  }

  const host = url.hostname;
  const hostnameSegs = host.split(".");
  if (
    url.protocol === "https:" &&
    hostnameSegs.length === 3 &&
    hostnameSegs[0] !== "" && // domain should not be empty
    hostnameSegs[1] === "atlassian" &&
    hostnameSegs[2] === "net" &&
    !url.origin.endsWith("/") &&
    url.origin === siteUrl &&
    url.pathname === "/" && // URL adds an additional '/'
    url.search === ""
  ) {
    return true;
  }

  return false;
};

/**
 * Extract issue key from given issue link, which follows {site_url}/browse/{issue_key}, and issue_key follows {project}-[0-9]+
 */
export const extractIssueKey = (siteUrl, project, link) => {
  let key: any;
  const basePattern = `${siteUrl}/${EXTERNAL_CONNECTION_CONSTANTS.URL_BROWSE}/`;
  const issueKeyPattern = `${project}-[0-9]+`;
  if (link.match(`^${basePattern}${issueKeyPattern}$`)) {
    key = link.replace(basePattern, "");
  }
  return key;
};
