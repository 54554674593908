import { Tooltip } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { featureFlag } from "../../helpers/FeatureFlag";

const TenantSignupMigrateDisabled = ({
  children,
  component: BaseComponent,
  ...baseComponentProps
}: {
  children?: any;
  component: React.ElementType;
  [baseComponentPropKey: string]: any;
}) => {
  const { t } = useTranslation("common");

  const tooltipText = t([
    "tenant_signup_migrate_disabled_tooltip_text",
    "Signup is temporarily disabled, please check back later"
  ]);

  const canSignup = !featureFlag.isTenantSignupMigrateDisabled();

  const baseChildren = (
    <BaseComponent {...baseComponentProps} disabled={!canSignup}>
      {children}
    </BaseComponent>
  );

  return canSignup ? (
    baseChildren
  ) : (
    <Tooltip title={tooltipText} placement="top">
      <span>{baseChildren}</span>
    </Tooltip>
  );
};

export default TenantSignupMigrateDisabled;
