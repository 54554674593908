import React from "react";
import swal from "./../../components/SweetAlert/SweetAlert";
import { LocalizeTranslationKey } from "./../localizedAlertMessages";
import "./../fileDownloadSwal/fileDownloadSwal.scss";
import { renderToStaticMarkup } from "react-dom/server";

type FileUploadSwalArgs = {
  percentValue: number;
  titleText?: string | JSX.Element;
};

const fileUploadSwal = ({
  percentValue,
  titleText
}: FileUploadSwalArgs): void => {
  const title = titleText || (
    <LocalizeTranslationKey localeKey="file_upload_alert_title" />
  );

  swal.fire({
    animation: false,
    title: renderToStaticMarkup(
      <div className="fileDownloadSwal__header">{title}</div>
    ),
    showCloseButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    html: `
      <div class="fileDownloadSwal">
        <div class="fileDownloadSwal__bar-container">
          <div class="fileDownloadSwal__bar" style="width: ${percentValue *
            2}px;"></div>
        </div>
        <h2 class="fileDownloadSwal__text" style="font-size: 16px;">${percentValue}%</h2>
      </div>
    `
  });
};

export default fileUploadSwal;
