export const convertLegacyEventToCOPlatformEventType = ({
  legacy_event
}: {
  legacy_event;
}): string => {
  return legacy_event
    .toUpperCase()
    .split("-")
    .join("_");
};
