export enum COActionTypes {
  EMAIL = "EMAIL",
  NOTIFICATION = "NOTIFICATION",
  WEBHOOK = "WEBHOOK",
  TASK = "TASK",
  EMAIL_REMINDER = "EMAIL_REMINDER"
}

export enum COActionStates {
  NOT_SENT = "NOT_SENT",
  DELIVERED = "DELIVERED",
  COMPLETED = "COMPLETED"
}

export enum COUserActionType {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN"
}

export enum COActionReplacementContextualValues {
  PROCESS_ID = "PROCESS_ID",
  PROCESS_PHASE = "PROCESS_PHASE",
  PROCESS_STATUS = "PROCESS_STATUS",
  FETCH_PROCESS_PHASE_STATUS = "FETCH_PROCESS_PHASE_STATUS",
  PROCESS_REJECTED_REASON = "PROCESS_REJECTED_REASON",
  PROCESS_ARCHIVED_REASON = "PROCESS_ARCHIVED_REASON",
  REFERENCED_USER_ID = "REFERENCED_USER_ID",
  DOCUMENT_ID = "DOCUMENT_ID",
  COMMENT_ID = "COMMENT_ID",
  COMPONENT_ID = "COMPONENT_ID",
  COMPONENT_COMMENT_ID = "COMPONENT_COMMENT_ID",
  COMPONENT_REJECTED_REASON = "COMPONENT_REJECTED_REASON",
  TEMPLATE_ID = "TEMPLATE_ID",
  ROLE_ID = "ROLE_ID",
  REPORTED_PROCESS_ID = "REPORTED_PROCESS_ID",
  PROCESS_SUBMITTER_ID = "PROCESS_SUBMITTER_ID",
  PROCESS_OWNER_ID = "PROCESS_OWNER_ID",
  SYSTEM_ADMIN_ID = "SYSTEM_ADMIN_ID",
  CURATOR_ID = "CURATOR_ID",
  PROGRAM_MANAGER_ID = "PROGRAM_MANAGER_ID",
  DIRECT_MANAGER_ID = "DIRECT_MANAGER_ID",
  REPORTEE_ID = "REPORTEE_ID",
  AUTHORIZED_USER_ID = "AUTHORIZED_USER_ID",
  COE_COLLABORATOR_ID = "COE_COLLABORATOR_ID",
  COLLABORATOR_ID = "COLLABORATOR_ID",
  TENANT_ID = "TENANT_ID",
  PROCESS_QUESTION_ID = "PROCESS_QUESTION_ID",
  PROCESS_ROLE_NAME = "PROCESS_ROLE_NAME",
  UPDATED_USER_ATTRIBUTES = "UPDATED_USER_ATTRIBUTES",
  COLLABORATOR_ROLES = "COLLABORATOR_ROLES",
  PERMISSION_ROLES = "PERMISSION_ROLES"
}

export enum COActionReplacementStrings {
  // Notifications
  PROCESS_SLUG = "{{idOrSlug}}",
  PROCESS_NAME = "{{ProcessName}}",
  PROCESS_PHASE = "{{ProcessPhase}}",
  PROCESS_STATUS = "{{ProcessStatus}}",
  PROCESS_ARCHIVED_REASON = "{{ProcessArchivedReason}}",
  PROCESS_REJECTED_REASON = "{{ProcessRejectedReason}}",
  PROCESS_ROLE_NAME = "{{ProcessRoleName}}",
  PROCESS_SUBMITTER_SLUG = "{{ProcessSubmitterSlug}}",
  PROCESS_SUBMITTER_NAME = "{{ProcessSubmitter}}",
  PROCESS_OWNER_SLUG = "{{ProcessOwnerSlug}}",
  PROCESS_OWNER_NAME = "{{ProcessOwner}}",

  AUTOMATION_STORE_PROCESS_NAME = "{{AutomationStoreProcessName}}",
  AUTOMATION_STORE_PROCESS_NAME_HERE = "{{AutomationStoreProcessNameHere}}",

  USER_SLUG = "{{RefUserSlug}}",
  USER_FULL_NAME = "{{RefUserFullName}}",
  DIRECT_MANAGER_NAME = "{{DirectManager}}",
  REPORTEE_NAME = "{{ReporteeFullName}}",
  SYSTEM_ADMIN_NAME = "{{SystemAdmin}}",
  PROGRAM_MANAGER_NAME = "{{ProgramManager}}",
  CURATOR_NAME = "{{Curator}}",
  AUTHORIZED_USER_NAME = "{{AuthorizedUser}}",
  COE_COLLABORATOR_NAME = "{{CoeCollaborator}}",
  COLLABORATOR_NAME = "{{CollaboratorFullName}}",

  COLLABORATOR_ROLES = "{{ProcessCollaboratorRoles}}",
  DOCUMENT_TITLE = "{{ProcessDocumentTitle}}",
  COMMENT_TITLE = "{{ProcessCommentText}}",
  PROCESS_ABUSE_TITLE = "{{ProcessAbuseTitle}}",
  PROCESS_ABUSE_DESCR = "{{ProcessAbuseDescription}}",
  PROCESS_TEMPLATE_TITLE = "{{ProcessTemplateTitle}}",
  COMPONENT_SLUG = "{{ComoponentSlug}}",
  COMPONENT_NAME = "{{ComponentName}}",
  COMPONENT_COMMENT = "{{ComponentComment}}",
  COMPONENT_REJECTED_REASON = "{{ComponentRejectReason}}",
  UPDATED_USER_ATTRIBUTES = "{{UpdatedUserAttributes}}",

  // Emails
  BEGINNING_OF_EMAIL = "{{>BeginningOfEmail}}",
  END_OF_EMAIL = "{{>EndOfEmail}}",
  EMAIL_TITLE = "{{EmailTitle}}",
  REMINDER_EMAIL_TITLE_PREFIX = "{{ReminderEmailTitlePrefix}}",
  REMINDER_EMAIL_BODY_PREFIX = "{{ReminderEmailBodyPrefix}}",

  PROCESS_DESCR = "{{ProcessDescription}}",
  VIEW_MORE_PROCESS_DESCR = "{{ViewMore}}",
  PROCESS_COMMENT = "{{ProcessComment}}",
  PROCESS_QUESTION = "{{ProcessQuestion}}",
  TARGET_USER_FIRST_NAME = "{{UserFirstName}}",

  TENANT_NAME = "{{TenantName}}",
  TENANT_STATE = "{{State}}",
  TENANT_COMPANY_NAME = "{{CompanyName}}",
  LICENSE_PLAN = "{{LicensePlan}}",
  LICENSE_PLAN_OPTION_NUMBER = "{{LicensePlanOptionNumber}}",
  TENANT_MIGRATION_OPTION = "{{MigrationOption}}",
  SALES_MESSAGE = "{{SalesMessage}}",
  ERROR_MESSAGE = "{{ErrorMessage}}",
  PERMISSION_ROLES = "{{PermissionRoles}}",

  PROCESS_PROFILE_URL = "{{ProcessUrl}}",
  AUTOMATION_STORE_PROCESS_URL = "{{AutomationStoreProcessUrl}}",
  EXPLORE_IDEAS_URL = "{{ExploreIdeasUrl}}",
  SUBMIT_IDEA_URL = "{{SubmitIdeaUrl}}",
  USER_PROFILE_URL = "{{RefUserUrl}}",
  PROCESS_COMMENT_URL = "{{ProcessCommentUrl}}",
  PROCESS_CBA_URL = "{{ProcessCBAUrl}}",
  COMPONENT_PROFILE_URL = "{{ComponentUrl}}",
  PROCESS_COMPONENT_URL = "{{ProcessComponentUrl}}",
  PROCESS_DOCUMENTATION_URL = "{{ProcessDocumentationUrl}}",
  TENANT_LOGIN_URL = "{{TenantLoginUrl}}",
  USER_GUIDE_FIRST_USER_URL = "{{UserGuideFirstUserUrl}}",
  USER_GUIDE_STANDARD_USER_URL = "{{UserGuideStandardUserUrl}}",
  STATUS_PAGE_URL = "{{StatusPage}}",
  PROCESS_EDIT_URL = "{{ProcessEditUrl}}",
  EMAIL_CONFIRMATION_LINK = "{{EmailConfirmationLink}}",
  UNSUBSCRIBE_LINK = "{{UnsubscribeLink}}"
}

export const AUTOMATION_PROFILE_URL: string =
  "/automation-profile/" + COActionReplacementStrings.PROCESS_SLUG;
export const AUTOMATION_PROFILE_EDIT_URL: string =
  "/automation-profile/" + COActionReplacementStrings.PROCESS_SLUG + "/edit";
export const AUTOMATION_PIPELINE_URL: string = "/automation-pipeline";
export const AUTOMATION_PROFILE_COLLABORATORS_URL: string =
  "/automation-profile/" +
  COActionReplacementStrings.PROCESS_SLUG +
  "/collaborators";
export const AUTOMATION_PROFILE_DOCUMENTATION_URL: string =
  "/automation-profile/" +
  COActionReplacementStrings.PROCESS_SLUG +
  "/documentation";
export const USER_PROFILE_URL: string =
  "/user/" + COActionReplacementStrings.USER_SLUG;
export const USER_PROFILE_EMAIL_SETTINGS_URL: string =
  "/user/" + COActionReplacementStrings.USER_SLUG + "/email-settings";
export const PROCESS_SUBMITTER_PROFILE_URL: string =
  "/user/" + COActionReplacementStrings.PROCESS_SUBMITTER_SLUG;
export const PROCESS_OWNER_PROFILE_URL: string =
  "/user/" + COActionReplacementStrings.PROCESS_OWNER_SLUG;
export const ADMIN_USER_ROLES_URL: string = "/admin/user-roles";
export const ADMIN_DOCUMENATION_URL: string = "/admin/documentation";
export const COMPONENT_PROFILE_URL: string =
  "/component/" + COActionReplacementStrings.COMPONENT_SLUG;
export const AUTOMATION_PROFILE_COMPONENTS_URL: string =
  "/automation-profile/" +
  COActionReplacementStrings.PROCESS_SLUG +
  "/components";
export const AUTOMATION_PROFILE_CBA_URL: string =
  "/automation-profile/" + COActionReplacementStrings.PROCESS_SLUG + "/cba";
export const ADMIN_MANAGER_USERS_URL: string = "/admin/manage-users";
export const ADMIN_MANAGER_COMPONENTS_URL: string = "/admin/manage-components";
export const EXPLORE_URL: string = "/explore-automations";
export const EXPLORE_COMPONENTS_URL: string = "/explore-components";
export const ADMIN_HIERARCHY_URL: string = "/admin/hierarchy";
export const ADMIN_APP_INVENTORY_URL: string = "/admin/app-inventory";
export const MY_COMPONENTS_URL: string = "/my-components";
export const MY_TASKS_URL: string = "/my-tasks";
export const TOP_DOWN_SUBMISSION_URL: string = "/top-down";
export const EXPLORE_PUBLISHED_AUTOMATIONS_URL: string =
  "/explore-published-automations/" + COActionReplacementStrings.PROCESS_SLUG;
