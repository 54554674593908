import { CONotificationActionTemplateInterface } from "../../../../interfaces/co-interfaces";
import { COActionTypes } from "../../../../constants/actions/co-action-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../../constants/co-path.constants";
import { taskAssignedOriginalNotificationActionTemplate } from "./types/co-notification-task-assigned-original-templates";
import { automationReviewedNotificationActionTemplate } from "./types/co-notification-automation-reviewed-templates";
import { followedAutomationUpdateNotificationActionTemplate } from "./types/co-notification-followed-automation-update-templates";
import { collaborationInvitesNotificationActionTemplate } from "./types/co-notification-collaboration-invites-templates";
import { taskAssignedNotificationActionTemplate } from "./types/co-notification-task-assigned-templates";
import { followersNotificationActionTemplate } from "./types/co-notification-followers-templates";
import { upvotesNotificationActionTemplate } from "./types/co-notification-upvotes.templates";
import { automationStatusNotificationActionTemplate } from "./types/co-notification-automation-status-templates";
import { newContentNotificationActionTemplate } from "./types/co-notification-new-content-templates";
import { collaboratorsUpdatesNotificationActionTemplate } from "./types/co-notification-collaborators-updates-templates";
import { commentsNotificationActionTemplate } from "./types/co-notification-comments-templates";
import { mentionsNotificationActionTemplate } from "./types/co-notification-mentions-templates";
import { userProfileUpdatesNotificationActionTemplate } from "./types/co-notification-user-profile-updates-templates";
import { adminConsoleUpdatesNotificationActionTemplate } from "./types/co-notification-admin-console-updates-templates";
import { componentSupportNotificationActionTemplate } from "./types/co-notification-component-support-templates";
import { contentDeletionUpdateNotificationActionTemplate } from "./types/co-notification-content-deletion-templates";
import { gamificationNotificationActionTemplate } from "./types/co-notification-gamification-templates";
import { adminRoleInvitesNotificationActionTemplate } from "./types/co-notification-admin-role-invites-templates";
import { queueStatusUpdatesNotificationActionTemplate } from "./types/co-notification-queue-status-updates-templates";

export const notificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface => {
  // get template based on slug
  let template = notificationActionTemplateFromSlug(slug);

  // add values that will be the same for each notification
  template = {
    co_action_template_slug: slug,
    co_action_template_co_type: COActionTypes.NOTIFICATION,
    ...template
  };
  template.co_action_json = {
    co_action_type: COActionTypes.NOTIFICATION,
    co_action_template_slug: slug,
    ...(template.co_action_json ?? {})
  };
  template.co_meta_json = {
    ...(template.co_meta_json ?? {}),
    title: {
      ...template.co_meta_json?.title,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    message: {
      ...template.co_meta_json?.message,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    contextual_message: PATH({
      route:
        PATH_ROUTE.CONTEXT.ACTION
          .FUNCTION_PARSE_ACTION_META_VALUE_TO_LOCALIZATION_META_ITEM,
      operator: PATH_OPERATORS.FUNCTION,
      operatorParams: {
        value: PATH({
          route: PATH_ROUTE.CONTEXT.ACTION.META.MESSAGE.VALUE
        })
      }
    }),
    redirect_url: {
      ...template.co_meta_json?.redirect_url,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    contextual_redirect_url: {
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.ACTION.FUNCTION_PARSE_ACTION_META_VALUE_TO_STRING,
        operator: PATH_OPERATORS.FUNCTION,
        operatorParams: {
          value: PATH({
            route: PATH_ROUTE.CONTEXT.ACTION.META.REDIRECT_URL.VALUE
          })
        }
      }),
      options: {
        is_hidden: 1
      }
    }
  };

  return template;
};

const notificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface => {
  return (
    taskAssignedOriginalNotificationActionTemplate(slug) ??
    automationReviewedNotificationActionTemplate(slug) ??
    followedAutomationUpdateNotificationActionTemplate(slug) ??
    collaborationInvitesNotificationActionTemplate(slug) ??
    taskAssignedNotificationActionTemplate(slug) ??
    followersNotificationActionTemplate(slug) ??
    upvotesNotificationActionTemplate(slug) ??
    automationStatusNotificationActionTemplate(slug) ??
    newContentNotificationActionTemplate(slug) ??
    collaboratorsUpdatesNotificationActionTemplate(slug) ??
    commentsNotificationActionTemplate(slug) ??
    mentionsNotificationActionTemplate(slug) ??
    userProfileUpdatesNotificationActionTemplate(slug) ??
    adminConsoleUpdatesNotificationActionTemplate(slug) ??
    componentSupportNotificationActionTemplate(slug) ??
    contentDeletionUpdateNotificationActionTemplate(slug) ??
    gamificationNotificationActionTemplate(slug) ??
    adminRoleInvitesNotificationActionTemplate(slug) ??
    queueStatusUpdatesNotificationActionTemplate(slug) ??
    {}
  );
};
