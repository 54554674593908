import React from "react";
import styled from "styled-components/macro";
import { useTranslation, Trans } from "react-i18next";
import "../components/Modal/ModalApproveIdea/ModalApproveIdea.scss";
import { swalClose } from "../components/SweetAlert/SweetAlert";

const ErrorMessageButton = styled.button`
  color: var(--color-primary);
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
`;

export const LocalizeTranslationKey = ({ localeKey, backupValue = "" }) => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return <>{translate([localeKey, backupValue])}</>;
};

export const LocalizeTranslationKeyWithParam = ({ localeKey, param }) => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return <span>{translate(localeKey, param)}</span>;
};

export const LocalizeTranslationKeyWithPostText = ({
  localeKey,
  postText = ""
}) => {
  const { t: translate } = useTranslation("common");
  return <span>{translate(localeKey) + postText}</span>;
};

export const NoProductOwnerMessage = props => {
  const { t } = useTranslation("common");

  return (
    <Trans t={t} i18nKey="alert_message_no_product_owner">
      {/* prettier-ignore */}
      The idea cannot be approved because a Process Owner is not assigned yet.
      Go to
      <ErrorMessageButton
        className="ModalApproveIdea_error_msg_link"
        onClick={() => {
          if (typeof props.openQuickViewFunc === "function") {
            props.openQuickViewFunc(props.automation);
          } else {
            swalClose();
          }
        }}
      >
        Quick View
      </ErrorMessageButton>
      and assign a Process Owner.
    </Trans>
  );
};

export const SubmitAssessmentNoProductOwnerMessage = props => {
  const { t } = useTranslation("common");

  return (
    <Trans t={t} i18nKey="alert_message_submit_assessment_no_product_owner">
      The assessment cannot be submitted because a Process Owner is not assigned
      yet. Go to
      <button
        className="ModalApproveIdea_error_msg_link"
        onClick={() => {
          if (typeof props.openQuickViewFunc === "function") {
            props.openQuickViewFunc(props.automation);
          }
        }}
      >
        <strong>Quick View</strong>
      </button>
      and assign a Process Owner.
    </Trans>
  );
};
