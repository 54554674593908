import React from "react";
import { Link } from "react-router-dom";
import "./ButtonContainer.scss";

const getInnerMostChild = children => {
  if (typeof children === "string") {
    return children;
  }

  let last;

  if (children && typeof children === "object") {
    if (children && Array.isArray(children)) {
      last = children.length - 1;
      return getInnerMostChild(children[last]);
    }

    if (children.props && children.props.children) {
      return getInnerMostChild(children.props.children);
    }
  }

  return children;
};

const ButtonContainer = props => {
  let classes = ["ButtonContainer"];
  let {
    children,
    clickHandler,
    className,
    to,
    href,
    target,
    download,
    extraStyles,
    ...restProps
  } = props;

  clickHandler = clickHandler
    ? clickHandler
    : () => {
        console.log(`clicked ${getInnerMostChild(children)} button`);
      };

  if (className) {
    classes = classes.concat(className.trim().split(" "));
  }

  let button = (
    <button {...restProps} className={classes.join(" ")} onClick={clickHandler}>
      {children}
    </button>
  );

  if (to) {
    button = (
      <Link
        {...restProps}
        to={to}
        className={classes.join(" ")}
        onClick={clickHandler}
      >
        {children}
      </Link>
    );
  }

  if (href) {
    let targetProps = {};
    if (target) {
      targetProps.target = target;
    }
    if (download) {
      targetProps.download = download;
    }

    button = (
      <a
        {...restProps}
        {...targetProps}
        href={href}
        className={classes.join(" ")}
        onClick={clickHandler}
      >
        {children}
      </a>
    );
  }

  return button;
};

export default ButtonContainer;
