import { LocalizationHashMapInterface } from "../../../interfaces/co-interfaces";

// Some of these rich text fields are hard coded to slugs here
// be careful creating new map these do not get replaces
export const CO_DEFAULT_LOCALIZATION_HASH_MAP: LocalizationHashMapInterface = {
  "67897": {
    localizationKey: "automation_development_type_be_coe",
    fallbackValue: "CoE"
  },
  "2274071": {
    localizationKey: "automation_card_phase_be_idea",
    fallbackValue: "Idea"
  },
  "2398476": {
    localizationKey: "questionnaire_choice_be_documentation-misc",
    fallbackValue: "Misc"
  },
  "2598969": {
    localizationKey: "export_csv_header_label_process_tags",
    fallbackValue: "Tags"
  },
  "63058797": {
    localizationKey: "add_collaborator_role_modal_permission_about",
    fallbackValue: "About"
  },
  "115155230": {
    localizationKey: "questionnaire_form_question_name_be_hierarchy",
    fallbackValue: "Category"
  },
  "289913428": {
    localizationKey: "user_role_be_ah-cd-power-user",
    fallbackValue: "Citizen Developer Power User"
  },
  "594760089": {
    localizationKey: "questionnaire_form_section_title_be_q1-overview",
    fallbackValue: "Overview"
  },
  "1003413111": {
    localizationKey: "explore_automation_page_filter_phase_status",
    fallbackValue: "Phase Status"
  },
  "1294948945": {
    localizationKey: "user_role_be_ah-cd-self-user",
    fallbackValue: "Citizen Developer Self User"
  },
  "1680917663": {
    localizationKey: "coe_table_tooltip_painpoint",
    fallbackValue:
      "<p>Describe the inconvenience, tedium, and time consumption that affects the person(s) who have to perform the task or process.</p>"
  },
  "2064779153": {
    localizationKey: "automation_table_column_be_cd_process_negative_impact",
    fallbackValue: "Negative Impact"
  },
  "-512026636": {
    localizationKey: "admin_orchestrator_mapping_column_process_name",
    fallbackValue: "Automation Name"
  },
  "-1931557750": {
    localizationKey: "admin_orchestrator_mapping_column_process_id",
    fallbackValue: "Auto-generated ID"
  },
  "-1506676572": {
    localizationKey: "export_csv_header_label_orchestrator_automation_uuid",
    fallbackValue: "Automation ID"
  },
  "-56677412": {
    localizationKey: "co_section_decription_label",
    fallbackValue: "Description"
  },
  "-971430524": {
    localizationKey:
      "questionnaire_form_question_description_be_process_description",
    fallbackValue:
      "<p>Describe your process and include any pre-conditions, inputs or triggers and outcomes.</p>"
  },
  "-1246283411": {
    localizationKey: "automation_table_column_be_process_pain_points",
    fallbackValue: "Pain Points"
  },
  "-730697720": {
    localizationKey: "coe_table_tooltip_negative_impact",
    fallbackValue:
      "<p>Describe how the task or process affects the enterprise such as low morale, slow response time for customers which results in lower customer satisfaction or other downsides.</p>"
  },
  "-323029553": {
    localizationKey: "automation_table_column_be_ovr-misc_useful_links",
    fallbackValue: "Useful links"
  },
  "-499275969": {
    localizationKey: "co_units_development_type",
    fallbackValue: "Development Type"
  },
  "-593216610": {
    localizationKey: "automation_development_type_be_development-type-none",
    fallbackValue: "No Development Type"
  },
  "-1212549812": {
    localizationKey: "coe_driven_idea",
    fallbackValue: "COE driven Idea"
  },
  "-1957301961": {
    localizationKey: "my_tasks_page_title_cell_type_automation",
    fallbackValue: "Automation"
  },
  "-1195293887": {
    localizationKey: "process_mining",
    fallbackValue: "Process mining"
  },
  "-847445205": {
    localizationKey: "task_mining",
    fallbackValue: "Task mining"
  },
  "-1100816956": {
    localizationKey: "export_csv_header_label_process_priority",
    fallbackValue: "Priority"
  },
  "-1579724881": {
    localizationKey: "process_priority",
    fallbackValue: "<p>The process priority</p>"
  },
  "-1942320919": {
    localizationKey: "admin_console_process_role_be_submitter",
    fallbackValue: "Submitter"
  },
  "49439215": {
    localizationKey: "automation_table_column_process_suitability_score",
    fallbackValue: "Suitability Score"
  },
  "63210124": {
    localizationKey: "questionnaire_form_response_choice_be_stability-agree",
    fallbackValue: "Agree"
  },
  "196353469": {
    localizationKey: "questionnaire_form_question_description_be_input_quality",
    fallbackValue:
      "<p>Use the scale to indicate if the format of the input data has a predictable format available in fixed fields, and is easily detectable via search algorithms (excels, tables with fixed structure) or if it can be described as not highly predictable and difficult to detect (web logs, multimedia content, customer service interactions and social media data).</p>"
  },
  "-1453954995": {
    localizationKey: "questionnaire_form_question_description_be_stability",
    fallbackValue:
      "<p>Use the scale to indicate if the way in which you perform the task/process can vary or if changes are expected for the next 6 months. If you do not have visibility on upcoming changes then you can base your answer on your past experience with the task/process.</p>"
  },
  "306001284": {
    localizationKey: "questionnaire_form_question_description_be_documentation",
    fallbackValue:
      "<p>Having any documentation increases the readiness score and builds a stronger case for your idea to be automated. Attaching files is optional.</p>"
  },
  "335739646": {
    localizationKey: "questionnaire_form_response_choice_be_stability-disagree",
    fallbackValue: "Disagree"
  },
  "337677279": {
    localizationKey: "questionnaire_form_question_description_be_rules",
    fallbackValue:
      "<p>Use the scale to indicate if the task/process is managed through precise and well-defined rules or if it is rather creative and irregular.</p>"
  },
  "588042349": {
    localizationKey: "coe_process_overall_score_candidate_idea",
    fallbackValue:
      "<p>This is an overall score indicating how good of a candidate your idea is for automation.</p>"
  },
  "626890114": {
    localizationKey: "automation_table_column_be_process_process_owner_user_id",
    fallbackValue: "Process Owner"
  },
  "698892685": {
    localizationKey: "questionnaire_form_question_name_be_input_type",
    fallbackValue: "The input data is accessed digitally"
  },
  "926732542": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-no-documentation",
    fallbackValue: "There are no files affiliated with this process"
  },
  "1013739743": {
    localizationKey:
      "automation_table_column_be_cd_process_overall_gauge_score",
    fallbackValue: "Overall score"
  },
  "1124533894": {
    localizationKey: "coe_process_overall_score_idea",
    fallbackValue:
      "<p>This is an overall score indicating how good your idea is for automation.</p>"
  },
  "1155275584": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-task-process-maps-flowcharts",
    fallbackValue: "Task/Process maps/flowcharts"
  },
  "1304024162": {
    localizationKey:
      "questionnaire_form_section_description_be_q1-high-level-assessment",
    fallbackValue:
      "<p>Tell us about how this process works now. This section will help us compute a score for your idea. This is a score generated based on the as is suitability for automation and how ready it is to be automated.</p>"
  },
  "1393573878": {
    localizationKey: "coe_assign_process_owner_to_review",
    fallbackValue:
      "<p>Assign a Process Owner to review the filled in information.</p>"
  },
  "1441050673": {
    localizationKey:
      "questionnaire_form_section_title_be_q1-assign-process-owner",
    fallbackValue: "Assign Process Owner"
  },
  "1475757227": {
    localizationKey: "questionnaire_form_question_description_be_input_type",
    fallbackValue:
      "<p>Use the scale to indicate if the input data is stored and accessible from a machine (mobile phone, laptop, server etc).</p>"
  },
  "1611457594": {
    localizationKey: "questionnaire_form_question_name_be_input_quality",
    fallbackValue: "The data is highly formatted"
  },
  "1711304452": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-detailed-work-instructions",
    fallbackValue: "Detailed Work Instructions"
  },
  "1935112063": {
    localizationKey: "questionnaire_form_question_name_be_stability",
    fallbackValue: "This process has no expected changes in the next 6 months"
  },
  "1998423324": {
    localizationKey: "automation_table_column_be_process_readiness_score",
    fallbackValue: "Readiness Score"
  },
  "2109793625": {
    localizationKey: "questionnaire_form_response_choice_be_documentation-misc",
    fallbackValue: "Misc. Files"
  },
  "-1377082436": {
    localizationKey:
      "questionnaire_form_section_title_be_q1-high-level-assessment",
    fallbackValue: "High Level Assessment"
  },
  "-182119710": {
    localizationKey: "automation_card_phase_be_assessment",
    fallbackValue: "Assessment"
  },
  "-620441130": {
    localizationKey: "questionnaire_form_question_name_be_rules",
    fallbackValue: "The decisions have straight forward rules"
  },
  "-137084432": {
    localizationKey:
      "questionnaire_form_response_choice_be_stability-strongly-agree",
    fallbackValue: "Strongly Agree"
  },
  "-2096550748": {
    localizationKey:
      "questionnaire_form_response_choice_be_stability-neither-disagree-or-agree",
    fallbackValue: "Neither Disagree or Agree"
  },
  "-929804006": {
    localizationKey:
      "questionnaire_form_response_choice_be_stability-strongly-disagree",
    fallbackValue: "Strongly Disagree"
  },
  "-27883726": {
    localizationKey:
      "questionnaire_form_response_choice_be_owner-does-not-know",
    fallbackValue: "I don't know"
  },
  "-485466283": {
    localizationKey: "questionnaire_form_question_name_be_documentation",
    fallbackValue: "Are any of these process documents available?"
  },
  "-855915343": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-standard-operating-procedure",
    fallbackValue: "Standard Operating Procedure"
  },
  "-140943775": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-input-files",
    fallbackValue: "Input Files"
  },
  "-1468873064": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-output-files",
    fallbackValue: "Output Files"
  },
  "-1262245907": {
    localizationKey: "coe_section_title_be_estimated-benefit",
    fallbackValue: "Assessment KPIs"
  },
  "-1721974808": {
    localizationKey: "coe_process_overall_score_automation",
    fallbackValue:
      "<p>This is an overall score indicating how ready your idea is for automation.</p>"
  },
  "-702863319": {
    localizationKey: "submit_employee_idea_success_stat_idea_score_title",
    fallbackValue: "Idea Score"
  },
  "2529": {
    localizationKey: "cd_automation_published_no",
    fallbackValue: "No"
  },
  "88775": {
    localizationKey: "bulk_action_alert_no_po_confirm",
    fallbackValue: "Yes"
  },
  "1838957": {
    localizationKey:
      "coe_question_response_choice_be_structured-input-lesser-forty",
    fallbackValue: "<40%"
  },
  "2106349": {
    localizationKey:
      "decision_pipeline_automation_goal_be_automation-goal-cost",
    fallbackValue: "Cost"
  },
  "44244053": {
    localizationKey: "roi_edit_page_section_header_be_automation-potential",
    fallbackValue: "Automation Potential"
  },
  "59130894": {
    localizationKey:
      "coe_question_response_choice_be_structured-input-greater-eighty",
    fallbackValue: ">=80%"
  },
  "65793529": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-frequency-daily",
    fallbackValue: "Daily"
  },
  "91164722": {
    localizationKey: "edit_section_advanced_info_detailed_assessment",
    fallbackValue: "Detailed Assessment"
  },
  "116635393": {
    localizationKey: "coe_question_edit_text_be_frequency",
    fallbackValue: "Task/process frequency"
  },
  "140851666": {
    localizationKey: "coe_question_edit_text_be_average_handling_time",
    fallbackValue: "Average processing time per transaction"
  },
  "153797480": {
    localizationKey:
      "coe_question_tooltip_be_estimated_benefit_ftes_per_employee",
    fallbackValue:
      "<p>The benefit computed per company divided by the number of employees</p>"
  },
  "163198074": {
    localizationKey: "submit_automation_form_field_label_applications_used",
    fallbackValue: "Applications used"
  },
  "167239081": {
    localizationKey: "roi_edit_page_section_title_be_ease-of-implementation",
    fallbackValue: "Ease of Implementation"
  },
  "278017541": {
    localizationKey: "coe_question_be_average_review_audit_time",
    fallbackValue: "Average review or audit time per transaction"
  },
  "297367875": {
    localizationKey: "coe_question_edit_text_be_ftes_required",
    fallbackValue: "FTEs Required"
  },
  "299082397": {
    localizationKey:
      "coe_question_response_choice_be_application-stability-minor-change-expected",
    fallbackValue: "Minor change expected"
  },
  "306605283": {
    localizationKey:
      "coe_question_response_choice_be_decision-complexity-greater-eight",
    fallbackValue: "There are >= 8 ways to complete the process"
  },
  "315314695": {
    localizationKey:
      "coe_question_response_choice_be_structured-input-between-forty-sixty",
    fallbackValue: "40%-60%"
  },
  "335851520": {
    localizationKey: "citizen_developer_q3_option_be_cd-process-peaks-regular",
    fallbackValue: "Regular (e.g. month closing)"
  },
  "340163245": {
    localizationKey: "coe_question_edit_text_be_total_rework_time",
    fallbackValue: "Total rework time"
  },
  "386792788": {
    localizationKey: "coe_question_tooltip_be_employee_average_working_days",
    fallbackValue:
      "<p>Exclude bank holidays, paid holidays, any company benefit holidays.</p>"
  },
  "485352513": {
    localizationKey: "coe_question_edit_text_be_average_rework_time",
    fallbackValue: "Average rework time per transaction"
  },
  "617778069": {
    localizationKey:
      "coe_question_edit_text_be_total_time_needed_to_perform_work",
    fallbackValue: "Total time needed to perform the work - AS IS"
  },
  "1411995981": {
    localizationKey: "post_automation_details_table_section_header",
    fallbackValue: "Post automation results"
  },
  "624694525": {
    localizationKey: "citizen_developer_q3_option_be_cd-process-peaks-no-peaks",
    fallbackValue: "There are no peaks"
  },
  "652753932": {
    localizationKey: "coe_question_tooltip_be_digital_inputs_percent",
    fallbackValue:
      "<p>Here we would like to understand if the input data is digital and to what extent. Digital data is data that can be stored on a machine: mobile phone, laptop, server etc. Please provide as close as possible of an approximation. Don't hesitate to reach out to the submitters of this idea to gain a better understanding of the degree of digitization. You can find them listed in the contributors section.</p>"
  },
  "656817763": {
    localizationKey: "coe_question_edit_text_be_process_peaks",
    fallbackValue: "Process peaks"
  },
  "-1560262822": {
    localizationKey: "citizen_developer_q3_question_label_be_cd_process_peaks",
    fallbackValue: "Automation process peaks"
  },
  "747802021": {
    localizationKey: "coe_question_tooltip_be_scanned_inputs",
    fallbackValue:
      "<p>You can easily check if you have scanned documents by trying to do a Ctrl-F. This will not work in scanned documents. Don't hesitate to reach out to the submitters of this idea to gain a better understanding of the degree of digitization. You can find them listed in the contributors section.</p>"
  },
  "762256983": {
    localizationKey:
      "coe_question_response_choice_be_decision-complexity-between-two-five",
    fallbackValue: "There are >= 2 but < 5 ways to complete the process"
  },
  "806800023": {
    localizationKey:
      "post_automation_details_table_section_average_error_rate_tooltip",
    fallbackValue: "<p>After automation, how often do errors occur?</p>"
  },
  "856216167": {
    localizationKey: "export_csv_header_label_estimated_benefit_currency",
    fallbackValue: "Benefit per company (currency/year)"
  },
  "872527038": {
    localizationKey:
      "coe_question_response_choice_be_process-length-between-fifty-hundred",
    fallbackValue: "Between 50-100 steps"
  },
  "895718649": {
    localizationKey: "coe_question_buckets_ease_of_implementation",
    fallbackValue:
      "<p>Buckets the Ease of Implementation into Easy / Medium / Difficult Ranges</p>"
  },
  "902808061": {
    localizationKey: "coe_question_edit_text_be_structured_input",
    fallbackValue: "% of Structured digital data input"
  },
  "910117232": {
    localizationKey:
      "export_csv_header_label_estimated_cost_benefit_per_employee",
    fallbackValue: "Benefit per employee (currency/year)"
  },
  "910900609": {
    localizationKey: "coe_question_tooltip_be_total_rework_time",
    fallbackValue: "<p>Total rework time at the level of the company</p>"
  },
  "937940249": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-frequency-quarterly",
    fallbackValue: "Quarterly"
  },
  "1406017267": {
    localizationKey: "coe_question_title_thin_apps_used",
    fallbackValue: "Thin Applications Used"
  },
  "1097857451": {
    localizationKey: "coe_question_tooltip_be_average_handling_time",
    fallbackValue:
      "<p>Please insert the average time it takes to run the process/task once in minutes. Feel free to have a discussion with the submitters of this idea which you can find in the collaborators section.</p>"
  },
  "1101527363": {
    localizationKey: "co_questions_question_type_applications",
    fallbackValue: "Applications"
  },
  "1127848904": {
    localizationKey: "coe_average_rework_time_after_automation",
    fallbackValue: "Average rework time - after automation"
  },
  "1149711679": {
    localizationKey: "coe_question_edit_text_be_volumes",
    fallbackValue: "Activity volume average (per selected frequency)"
  },
  "1188177138": {
    localizationKey:
      "decision_pipeline_automation_goal_be_automation-goal-productivity",
    fallbackValue: "Productivity"
  },
  "-439269368": {
    localizationKey: "coe_question_title_application_number_multiplier",
    fallbackValue: "Application number multiplier"
  },
  "1205971046": {
    localizationKey: "coe_question_edit_text_be_number_of_employees",
    fallbackValue: "Number of employees performing the task"
  },
  "1263459780": {
    localizationKey: "coe_question_tooltip_be_estimated_benefit_ftes",
    fallbackValue:
      "<p>The Automation Potential % multiplied by the FTEs Required.</p>"
  },
  "1399650065": {
    localizationKey:
      "coe_question_response_choice_be_decision-complexity-between-five-eight",
    fallbackValue: "There are >= 5 but < 8 ways to complete the process"
  },
  "1400630556": {
    localizationKey: "coe_average_number_steps",
    fallbackValue:
      "<p>Select from the dropdown menu the option which fits best. E.g. a step could be one verification that is done in an excel table, the download of a file etc.</p>"
  },
  "1500961109": {
    localizationKey:
      "coe_question_tooltip_be_total_time_needed_to_perform_work",
    fallbackValue: "<p>Total number of hours at the level of the company</p>"
  },
  "1512280889": {
    localizationKey: "coe_question_edit_text_be_employee_working_hours",
    fallbackValue: "Working hours per day"
  },
  "1557461549": {
    localizationKey: "co_units_ease_of_implementation",
    fallbackValue: "Ease of Implementation Level"
  },
  "1562975184": {
    localizationKey: "coe_question_tooltip_be_application_stability",
    fallbackValue:
      "<p>If you are unsure on how to make the difference between a major change and other types of changes please talk to your IT responsible/Program Manager.</p>"
  },
  "-19348905": {
    localizationKey: "coe_section_title_be_employee-profile",
    fallbackValue: "Employee profile"
  },
  "1680380395": {
    localizationKey:
      "export_csv_header_label_estimated_benefit_ftes_per_employee",
    fallbackValue: "Benefit per employee (FTEs)"
  },
  "1684358782": {
    localizationKey: "coe_detailed_assessment_live_benefit",
    fallbackValue:
      "Detailed assessment - Live benefit per employee (hours/month)"
  },
  "1685268817": {
    localizationKey: "coe_detailed_assessment_tooltip",
    fallbackValue:
      "<p>You need to answer all the questions in this section (except Average Employee Full Cost, Error Rate and Review/Audit related questions) in order to submit the assessment.</p>"
  },
  "1728376604": {
    localizationKey:
      "coe_question_response_choice_be_task-stability-major-change-expected",
    fallbackValue: "Major changes expected"
  },
  "1800097780": {
    localizationKey: "coe_section_title_be_data-input",
    fallbackValue: "Data input"
  },
  "1899883050": {
    localizationKey: "coe_question_edit_text_be_digital_inputs_percent",
    fallbackValue: "% of Digital data input"
  },
  "1985053051": {
    localizationKey: "coe_question_edit_text_be_automation_goal",
    fallbackValue: "Primary reason to automate the process"
  },
  "2018052660": {
    localizationKey:
      "coe_question_response_choice_be_decision-complexity-same-way",
    fallbackValue: "Process is completed the same way every time"
  },
  "2044810131": {
    localizationKey: "coe_question_edit_text_be_average_work_to_be_reviewed",
    fallbackValue: "Average work to be reviewed/audited (% of total volume)"
  },
  "2048066568": {
    localizationKey: "coe_question_edit_text_be_average_error_rate",
    fallbackValue: "Average error rate (% of total volume)"
  },
  "2074511700": {
    localizationKey: "coe_question_applications_cannot_be_customized",
    fallbackValue:
      "<p>The Applications Section is currently unavailable for customization.</p>"
  },
  "2090323979": {
    localizationKey:
      "coe_question_response_choice_be_structured-input-between-sixty-eighty",
    fallbackValue: "60%-80%"
  },
  "2124690533": {
    localizationKey: "coe_question_tooltip_be_process_peaks",
    fallbackValue:
      "<p>A peak is a noticeable increase in activity/volumes in a certain period of time. E.g. Purchase Orders may experience a peak at the end of the month or quarter as people are trying to squeeze in purchases in that budget cycle.</p>"
  },
  "2143473286": {
    localizationKey: "coe_question_tooltip_be_number_of_employees",
    fallbackValue:
      "<p>This value is taken into account if the volumes added are 'per employee'. It will also be taken into consideration in the number of required licenses if you decide to use attended robots, in the Cost-Benefit Analysis stage.</p>"
  },
  "-511268772": {
    localizationKey: "export_csv_header_label_process_goal",
    fallbackValue: "Automation goal"
  },
  "-1979098913": {
    localizationKey:
      "decision_pipeline_automation_goal_be_automation-goal-quality",
    fallbackValue: "Quality"
  },
  "1599972199": {
    localizationKey: "coe_section_title_be_environmental-stability",
    fallbackValue: "Environmental stability"
  },
  "-1322822982": {
    localizationKey: "coe_question_edit_text_be_task_stability",
    fallbackValue: "Process changes expected in the next 6 months"
  },
  "-827058435": {
    localizationKey: "coe_question_tooltip_be_task_stability",
    fallbackValue:
      "<p>Think about changes of the following type: Legislative changes that are upcoming and could change the rules/logic of the process. Organizational changes such as redistributing bits of the processes between different roles/departments. Industry shifts that could change the business model and hence the rules of the process.</p>"
  },
  "-1636885271": {
    localizationKey:
      "coe_question_response_choice_be_task-stability-no-change-expected",
    fallbackValue: "No change expected"
  },
  "-1949321700": {
    localizationKey:
      "coe_question_response_choice_be_application-stability-some-change-expected",
    fallbackValue: "Some change expected"
  },
  "-2127003847": {
    localizationKey:
      "coe_question_response_choice_be_application-stability-several-changes-expected",
    fallbackValue: "Several changes expected"
  },
  "-1120682322": {
    localizationKey: "coe_question_edit_text_be_application_stability",
    fallbackValue: "Applications changes expected in the next 6 months"
  },
  "-1105967497": {
    localizationKey: "coe_question_edit_text_be_employee_average_working_days",
    fallbackValue: "Average working days per year"
  },
  "-1576022063": {
    localizationKey: "coe_question_tooltip_be_employee_working_hours",
    fallbackValue:
      "<p>Legal number of working hours in a day, as defined in the legislation of the country or as defined in the working contract.</p>"
  },
  "-1167831696": {
    localizationKey: "coe_question_edit_text_be_employee_average_full_cost",
    fallbackValue: "Average employee full cost per year (Optional)"
  },
  "1921044432": {
    localizationKey: "roi_edit_page_section_title_be_process-volumtry",
    fallbackValue: "Process volumetry"
  },
  "-1172160840": {
    localizationKey: "coe_question_tooltip_be_frequency",
    fallbackValue:
      "<p>We want to understand how often is the task or process performed.</p>"
  },
  "-1707840351": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-frequency-weekly",
    fallbackValue: "Weekly"
  },
  "-1146299193": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-frequency-bi-weekly",
    fallbackValue: "Bi-Weekly"
  },
  "-1393678355": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-frequency-monthly",
    fallbackValue: "Monthly"
  },
  "-1650694486": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-frequency-yearly",
    fallbackValue: "Yearly"
  },
  "-486098240": {
    localizationKey: "coe_question_tooltip_be_volumes",
    fallbackValue:
      "<p>E.g. number of incoming documents, files, requests, number of transactions executed.</p>"
  },
  "-2031068102": {
    localizationKey: "coe_question_response_choice_be_volumes-per-company",
    fallbackValue: "Per Company"
  },
  "-1040335215": {
    localizationKey: "coe_question_response_choice_be_volumes-per-employee",
    fallbackValue: "Per Employee"
  },
  "-553818434": {
    localizationKey: "coe_question_edit_text_be_total_processing_time",
    fallbackValue: "Total processing time"
  },
  "-1019766126": {
    localizationKey: "coe_question_tooltip_be_total_processing_time",
    fallbackValue: "<p>Total processing time at the level of the company</p>"
  },
  "-637970163": {
    localizationKey: "coe_question_edit_text_be_total_review_audit_time",
    fallbackValue: "Total review/audit time"
  },
  "-357393567": {
    localizationKey: "coe_question_tooltip_be_total_review_audit_time",
    fallbackValue: "<p>Total review/audit time at the level of the company</p>"
  },
  "-31679729": {
    localizationKey: "coe_question_edit_text_be_yearly_cost_for_process",
    fallbackValue: "Cost/Year for Process AS IS"
  },
  "2126807123": {
    localizationKey: "coe_section_title_be_process-ways-of-working",
    fallbackValue: "Process ways of working"
  },
  "-1733440837": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-peaks-rare-predictable",
    fallbackValue:
      "Rare but predictable event (e.g. winter holidays/ yearly closing)"
  },
  "-2059130035": {
    localizationKey:
      "citizen_developer_q3_option_be_cd-process-peaks-rare-unpredictable",
    fallbackValue: "Rare and unpredictable event"
  },
  "-1046989198": {
    localizationKey: "coe_question_edit_text_be_process_length",
    fallbackValue: "Average number of steps"
  },
  "-1396805571": {
    localizationKey:
      "coe_question_response_choice_be_process-length-less-twenty",
    fallbackValue: "Less than 20 steps"
  },
  "-1707342887": {
    localizationKey:
      "coe_question_response_choice_be_process-length-between-twenty-fifty",
    fallbackValue: "Between 20-50 steps"
  },
  "-602573441": {
    localizationKey:
      "coe_question_response_choice_be_process-length-between-hundred-hundredfifty",
    fallbackValue: "Between 100-150 steps"
  },
  "-207337249": {
    localizationKey:
      "coe_question_response_choice_be_process-length-more-than-hundredfifty",
    fallbackValue: "More than 150 steps"
  },
  "-21070316": {
    localizationKey: "coe_question_edit_text_be_decision_complexity",
    fallbackValue: "Number of ways to complete the process"
  },
  "-1955262165": {
    localizationKey: "coe_question_tooltip_be_decision_complexity",
    fallbackValue:
      "<p>Select from the dropdown the option that best describes the number of variations in which the process or activity can be completed.</p>"
  },
  "-1157858268": {
    localizationKey: "coe_question_edit_text_be_scanned_inputs",
    fallbackValue: "Scanned data input"
  },
  "-94600103": {
    localizationKey: "coe_question_tooltip_be_structured_input",
    fallbackValue:
      "<p>Here we would like to understand if your input data is structured or unstructured. Structured data: has a predictable format, exists in fixed fields (e.g. an xls cell or a field in a form) and is easily detectable via search algorithms. Think about your structured data as: excels, tables with fixed structure, databases where you can easily extract your information through a search function. Unstructured data: may have its own internal structure, but this is not highly predictable. Examples: web logs, multimedia content, customer service interactions and social media data. </p>"
  },
  "1750814607": {
    localizationKey: "coe_question_title_thin_client_multiplier",
    fallbackValue: "Thin client multiplier"
  },
  "-1435577427": {
    localizationKey: "coe_question_ease_vs_benefits",
    fallbackValue: "Ease vs benefits"
  },
  "-329334363": {
    localizationKey: "coe_question_tooltip_be_automation_potential",
    fallbackValue:
      "<p>Computed based on the answers to % of Structured Digital Data Input, % of Digital Data Input and Number of Ways to Complete the Process</p>"
  },
  "-1815170516": {
    localizationKey: "coe_question_tooltip_be_ease_of_implementation",
    fallbackValue:
      "<p>Computed based on the answers from Environmental Stability, Process Ways of Working, Scanned Data Input, % of Structured Digital Data Input and Applications Used sections</p>"
  },
  "-651047558": {
    localizationKey:
      "automation_table_sort_options_be_estimated_benefits_hours",
    fallbackValue: "Benefit per company (hours saved/year)"
  },
  "-1372369716": {
    localizationKey: "coe_question_tooltip_be_estimated_benefit_hours",
    fallbackValue:
      "<p>The Automation Potential % multiplied by the Total Time Needed to Perform the Work - AS-IS Process (hours/year)</p>"
  },
  "-1405722634": {
    localizationKey: "coe_question_tooltip_be_estimated_cost_benefit",
    fallbackValue:
      "<p>The Automation Potential % multiplied by the Cost/Year for the Process AS-IS</p>"
  },
  "-1859270892": {
    localizationKey: "export_csv_header_label_estimated_benefit_fte",
    fallbackValue: "Benefit per company (FTEs)"
  },
  "-1202188847": {
    localizationKey:
      "export_csv_header_label_estimated_benefit_hours_per_employee",
    fallbackValue: "Benefit per employee (hours saved/year)"
  },
  "-985850244": {
    localizationKey: "coe_average_processing_time_after_automation",
    fallbackValue: "Average processing time per transaction - after automation"
  },
  "-1777955040": {
    localizationKey: "coe_after_automation_process_time",
    fallbackValue:
      "<p>After automation, how long does it take to perform this process once?</p>"
  },
  "-1311700045": {
    localizationKey: "coe_average_error_rate_automation",
    fallbackValue: "Average error rate - after automation"
  },
  "-1351870598": {
    localizationKey: "coe_after_resolve_error_time",
    fallbackValue:
      "<p>After automation, in minutes, how long does it take to resolve an error?</p>"
  },
  "74219460": {
    localizationKey: "automation_table_column_be_cd_misc-misc_media",
    fallbackValue: "Media"
  },
  "-1011261429": {
    localizationKey:
      "automation_table_column_be_cd_average_rework_time_pre_automation",
    fallbackValue: "Average rework time - before automation"
  },
  "447645211": {
    localizationKey: "citizen_developer_q3_post_automation",
    fallbackValue: "After Automation"
  },
  "623498876": {
    localizationKey: "coe_potential_benefit_per_employee",
    fallbackValue: "Potential Benefit Per Employee"
  },
  "841246304": {
    localizationKey:
      "automation_table_column_be_cd_average_process_duration_pre_automation",
    fallbackValue: "Average Process Duration Before Automation"
  },
  "1213829957": {
    localizationKey: "automation_table_column_be_cd_cd_potential_benefit",
    fallbackValue: "Number of people that could benefit from this automation"
  },
  "-311668107": {
    localizationKey:
      "citizen_developer_q3_option_short_be_cd_potential_benefit",
    fallbackValue: "Potential benefit"
  },
  "1388907287": {
    localizationKey: "automation_table_column_be_cd_volume_average",
    fallbackValue: "Volume Average"
  },
  "1524545202": {
    localizationKey: "automation_table_column_q3-automation_source_code",
    fallbackValue: "Source code"
  },
  "1546018614": {
    localizationKey: "automation_table_column_be_cd_q3-automation_components",
    fallbackValue: "Components"
  },
  "1791876937": {
    localizationKey: "coe_duration_difference",
    fallbackValue: "Duration Difference"
  },
  "1853904453": {
    localizationKey: "coe_error_rate_difference",
    fallbackValue: "Error Rate Difference"
  },
  "2137949349": {
    localizationKey:
      "citizen_developer_q3_question_description_be_cd_process_frequency",
    fallbackValue: "<p>How often do you have to perform this process?</p>"
  },
  "-1505082158": {
    localizationKey: "collaborator_cell_role_be_ah-citizen-developer",
    fallbackValue: "Citizen Developer"
  },
  "2027441904": {
    localizationKey: "co_assessment_type_citizen_developer",
    fallbackValue: "Citizen Developer Assessment"
  },
  "2093778289": {
    localizationKey: "process_details_component_title",
    fallbackValue: "Process Details"
  },
  "-315684661": {
    localizationKey: "automation_table_column_be_process_frequency",
    fallbackValue: "Process frequency"
  },
  "-861382908": {
    localizationKey:
      "citizen_developer_q3_question_description_be_cd_process_frequency",
    fallbackValue:
      "<p>How many repetitions do you typically perform on this process in the selected frequency?</p>"
  },
  "-811448505": {
    localizationKey:
      "citizen_developer_q3_question_description_be_cd_potential_benefit",
    fallbackValue:
      "<p>How many people do you think can benefit from using this automation at your organization?</p>"
  },
  "-1374453967": {
    localizationKey:
      "citizen_developer_q3_section_title_be_cd-pre-and-post-automation",
    fallbackValue: "Pre and post automation"
  },
  "-983392753": {
    localizationKey:
      "citizen_developer_q3_question_description_be_cd_average_process_duration_pre_automation",
    fallbackValue: "<p>How long does it take to perform this process once?</p>"
  },
  "-203507264": {
    localizationKey:
      "automation_table_column_be_cd_average_error_rate_pre_automation",
    fallbackValue: "Average Error Rate - Before Automation"
  },
  "-673306122": {
    localizationKey:
      "citizen_developer_q3_question_description_be_cd_average_error_rate_pre_automation",
    fallbackValue: "<p>In percentages how often do errors occur?</p>"
  },
  "-111546837": {
    localizationKey:
      "citizen_developer_q3_question_description_be_cd_average_rework_time_pre_automation",
    fallbackValue:
      "<p>In minutes, how long does it take to resolve an error?</p>"
  },
  "-1950392268": {
    localizationKey: "coe_rework_time_difference",
    fallbackValue: "Rework Time Difference"
  },
  "-973562946": {
    localizationKey: "coe_citizen_development_live_benefit",
    fallbackValue: "Citizen Development - Live Benefit Per Employee Hours/Month"
  },
  "-1908600166": {
    localizationKey: "submit_automation_form_field_label_documentation",
    fallbackValue: "Documentation"
  },
  "1823217991": {
    localizationKey: "automation_table_column_be_q3-automation_package",
    fallbackValue: "Upload package"
  },
  "1555606875": {
    localizationKey:
      "automation_table_column_be_q3-automation_user_guides_and_docs",
    fallbackValue: "User guides and documents"
  },
  "-897072377": {
    localizationKey:
      "automation_table_column_be_potential_benefit_across_company",
    fallbackValue: "Potential Benefit Across Company"
  },
  "666414318": {
    localizationKey: "edit_benefit_actual_benefits",
    fallbackValue: "Actual benefits"
  },
  "-874051912": {
    localizationKey:
      "automation_table_column_be_cd_process_challenges_expected",
    fallbackValue: "Expected challenges"
  },
  "1750549212": {
    localizationKey:
      "admin_manage_categories_category_helper_example_category_benefits",
    fallbackValue: "Benefits"
  },
  "28549214": {
    localizationKey:
      "automation_table_column_be_cd_process_challenges_encountered",
    fallbackValue: "Encountered challenges"
  },
  "-1211644156": {
    localizationKey: "automation_table_column_be_cd_process_benefits_expected",
    fallbackValue: "Expected benefits"
  },
  "-1769542512": {
    localizationKey: "edit_challenges_title",
    fallbackValue: "Challenges"
  },
  "-2081440546": {
    localizationKey: "automation_table_column_be_cd_process_lessons_learned",
    fallbackValue: "Lessons learned"
  },
  "1365331815": {
    localizationKey: "automation_table_column_misc-misc_similar_automations",
    fallbackValue: "Similar automations"
  },
  "2152482": {
    localizationKey: "roi_edit_page_section_header_be_easy",
    fallbackValue: "Easy"
  },
  "-1994163307": {
    localizationKey: "roi_edit_page_section_header_be_medium",
    fallbackValue: "Medium"
  },
  "-985057186": {
    localizationKey: "roi_edit_page_section_header_be_difficult",
    fallbackValue: "Difficult"
  },
  "-686813494": {
    localizationKey:
      "roi_edit_page_section_title_be_detailed_assessment_version",
    fallbackValue: "Detailed Assessment Version"
  },
  "-2113799716": {
    localizationKey:
      "automation_table_column_be_automation_potential_benefit_across_company",
    fallbackValue: "Across Company"
  },
  "1933601430": {
    localizationKey: "automation_table_column_be_launch_assessment_approver",
    fallbackValue: "Launch Assessment Approver"
  },
  "586858426": {
    localizationKey: "automation_table_column_be_process_type",
    fallbackValue: "RPA Automation Type"
  },
  "-232518030": {
    localizationKey: "automation_table_sort_options_be_unattended",
    fallbackValue: "Unattended"
  },
  "607400153": {
    localizationKey: "automation_table_sort_options_be_attended",
    fallbackValue: "Attended"
  },
  "461561672": {
    localizationKey:
      "automation_table_column_be_cd_automation_assessment_version",
    fallbackValue: "Citizen developer assessment Version"
  },
  "-57857574": {
    localizationKey:
      "automation_table_column_be_cd_accepted_to_compliance_and_privacy",
    fallbackValue: "Compliance and privacy"
  },
  "1766609578": {
    localizationKey:
      "automation_table_column_be_cd_detailed_assessment_version",
    fallbackValue: "Detailed assessment Version"
  },
  "1717781497": {
    localizationKey: "automation_table_column_be_license_type",
    fallbackValue: "License Type"
  },
  "-1891202684": {
    localizationKey: "automation_table_column_be_software_vendor",
    fallbackValue: "RPA Software Vendor"
  },
  "1926977556": {
    localizationKey:
      "automation_table_column_be_cd_high_level_assessment_version",
    fallbackValue: "High level assessment Version"
  },
  "1894124595": {
    localizationKey: "automation_table_column_be_cd_idea_phase_approver",
    fallbackValue: "Idea Phase Approver"
  },
  "-1743950578": {
    localizationKey: "automation_table_column_be_cd_misc_assessment_version",
    fallbackValue: "Misc Assessment Version"
  },
  "775537531": {
    localizationKey: "automation_table_column_be_cd_process_num_requests",
    fallbackValue: "Number of Automation Requests"
  },
  "83007067": {
    localizationKey: "automation_table_column_be_cd_process_num_collaborators",
    fallbackValue: "Number of Collaborators"
  },
  "-919972858": {
    localizationKey: "automation_table_column_be_cd_process_num_comments",
    fallbackValue: "Number of Comments"
  },
  "-677266264": {
    localizationKey: "automation_table_column_be_cd_process_num_components",
    fallbackValue: "Number of Components"
  },
  "-310796570": {
    localizationKey: "automation_table_column_be_cd_process_num_documents",
    fallbackValue: "Number of Documents"
  },
  "-488427453": {
    localizationKey: "automation_table_column_be_cd_process_num_followers",
    fallbackValue: "Number of Followers"
  },
  "-371394286": {
    localizationKey: "automation_table_column_be_cd_process_num_media",
    fallbackValue: "Number of Media Items"
  },
  "1786550805": {
    localizationKey:
      "automation_table_column_be_cd_process_num_similar_automations",
    fallbackValue: "Number of Similar Automations"
  },
  "-1788387735": {
    localizationKey: "automation_table_column_be_cd_process_num_votes",
    fallbackValue: "Interests"
  },
  "1584597825": {
    localizationKey: "automation_table_column_be_overview_assessment_version",
    fallbackValue: "Overview assessment Version"
  },
  "-777224319": {
    localizationKey:
      "automation_table_column_be_cba-cba_ease_of_implementation_level",
    fallbackValue: "Revised Ease of Implementation Level"
  },
  "645555427": {
    localizationKey: "automation_table_column_tooltip_be_idea_phase_approver",
    fallbackValue: "Name of the Idea Approver. "
  },
  "-1506089085": {
    localizationKey:
      "automation_table_column_tooltip_be_launch_assessment_approver",
    fallbackValue: "Name of the Program Manager who launched the assessment."
  },
  "82138963": {
    localizationKey:
      "automation_table_column_tooltip_be_qualification_phase_approver",
    fallbackValue: "Name of the Program Manager who reviewed the idea."
  },
  "-1437390509": {
    localizationKey: "automation_table_column_tooltip_be_pdd_link",
    fallbackValue:
      "Will be populated only if a PDD link is embedded for the idea. Uploaded documents are not displayed in this column."
  },
  "-1781701315": {
    localizationKey:
      "automation_table_column_tooltip_be_automation_potential_benefit_across_company",
    fallbackValue:
      "Computed by multiplying the Potential Benefit per employee with the number of people that can benefit from the automation"
  },
  "997723594": {
    localizationKey:
      "coe_question_response_choice_tooltip_be_q2-pct_digital_data_less_25",
    fallbackValue: "Less than 25%"
  },
  "-1195650072": {
    localizationKey:
      "coe_question_response_choice_tooltip_be_q2-pct_digital_data_less_50_more_25",
    fallbackValue: "Less than 50%, More than 25%"
  },
  "997726322": {
    localizationKey:
      "coe_question_response_choice_tooltip_be_q2-pct_digital_data_less_50",
    fallbackValue: "Less than 50%"
  },
  "-718210861": {
    localizationKey:
      "coe_question_response_choice_tooltip_be_q2-pct_digital_data_less_75_more_50",
    fallbackValue: "Less than 75%, More than 50%"
  },
  "997728399": {
    localizationKey:
      "coe_question_response_choice_tooltip_be_q2-pct_digital_data_less_75",
    fallbackValue: "Less than 75%"
  },
  "1515867262": {
    localizationKey:
      "coe_question_response_choice_tooltip_be_q2-pct_digital_data_more_75",
    fallbackValue: "More than or 75%"
  },
  "-1774741460": {
    localizationKey: "co_meta_variable_name_tooltip",
    fallbackValue:
      "The variable name will be used in the formulas. E.g.: HOURS_SAVED"
  },
  "939343012": {
    localizationKey: "co_meta_editable_answer_score_tooltip",
    fallbackValue: "The value used in the calculation"
  },
  "-1713860516": {
    localizationKey: "co_meta_answer_option_label",
    fallbackValue: "Answer options"
  },
  "1588109264": {
    localizationKey: "co_meta_answer_score_option_label",
    fallbackValue: "Answer score"
  },
  "1884376823": {
    localizationKey: "co_meta_answer_option_text",
    fallbackValue: "Answer option"
  },
  "-572223935": {
    localizationKey: "cda_default_assessment_title",
    fallbackValue: "Default Assessment"
  },
  "74079017": {
    localizationKey: "co_unit_day_per_year_suffix",
    fallbackValue: "Days Per Year"
  },
  "714369420": {
    localizationKey: "co_unit_hours_per_month_suffix",
    fallbackValue: "Hours Per Month"
  },
  "577580977": {
    localizationKey: "co_unit_hours_per_year_suffix",
    fallbackValue: "Hours Per Year"
  },
  "295706024": {
    localizationKey: "co_unit_hours_per_day_suffix",
    fallbackValue: "Hours Per Day"
  },
  "-1565412161": {
    localizationKey: "co_unit_minutes_suffix",
    fallbackValue: "Minutes"
  },
  "69916399": {
    localizationKey: "co_unit_hours_suffix",
    fallbackValue: "Hours"
  },
  "2122871": {
    localizationKey: "co_unit_days_suffix",
    fallbackValue: "Days"
  },
  "85299126": {
    localizationKey: "co_unit_years_suffix",
    fallbackValue: "Years"
  },
  "-824357429": {
    localizationKey: "co_meta_formula_tooltip",
    fallbackValue: "This is what is used to calculate the KPI"
  },
  "-555676357": {
    localizationKey: "chart_accessment_title_estimated_in_assessment",
    fallbackValue: "Estimated in Detailed Assessment"
  },
  "467327472": {
    localizationKey:
      "automation_table_column_be_estimated_implementation_costs",
    fallbackValue: "Est. Implementation Costs"
  },
  "1934508434": {
    localizationKey:
      "automation_table_column_be_estimated_net_benefit_year_one",
    fallbackValue: "Est. Net Benefit Year 1"
  },
  "1934508435": {
    localizationKey:
      "automation_table_column_be_estimated_net_benefit_year_two",
    fallbackValue: "Est. Net Benefit Year 2"
  },
  "-1597003315": {
    localizationKey: "automation_table_column_be_estimated_running_costs",
    fallbackValue: "Est. Running Costs"
  },
  "640799280": {
    localizationKey: "automation_table_column_be_ftr_overridden",
    fallbackValue: "FTR Overridden"
  },
  "1733781742": {
    localizationKey: "automation_table_column_be_published",
    fallbackValue: "Published to Store"
  },
  "-511073324": {
    localizationKey: "automation_table_column_be_automation_name",
    fallbackValue: "Automation name"
  },
  "1607225885": {
    localizationKey:
      "automation_table_column_be_percentage_of_structured_digital_data_input",
    fallbackValue: "% of structured digital data input"
  },
  "-1959723915": {
    localizationKey: "automation_table_column_be_automation_potential",
    fallbackValue: "Automation potential"
  },
  "1479707523": {
    localizationKey:
      "automation_table_column_be_automation_store_average_review",
    fallbackValue: "Automation Store Average Review"
  },
  "672132480": {
    localizationKey:
      "automation_table_column_be_average_error_rate_before_automation",
    fallbackValue: "Average error rate - before automation"
  },
  "-1628351763": {
    localizationKey:
      "automation_table_column_be_average_process_duration_before_automation",
    fallbackValue: "Average process duration - before automation"
  },
  "1586653815": {
    localizationKey:
      "automation_table_column_be_cba_live_benefit_pre_employee_hours_month",
    fallbackValue: "CBA - Live Benefit Pre Employee Hours/Month"
  },
  "-830641361": {
    localizationKey: "automation_table_column_be_cost_year_for_process_as_is",
    fallbackValue: "Cost/Year for process AS IS"
  },
  "-1364121847": {
    localizationKey: "automation_table_column_be_date_submitted",
    fallbackValue: "Date Submitted"
  },
  "-196409642": {
    localizationKey: "automation_table_column_be_design_documents",
    fallbackValue: "Design Documents"
  },
  "-498322657": {
    localizationKey: "automation_table_column_be_development_type",
    fallbackValue: "Development type"
  },
  "1169232265": {
    localizationKey: "automation_table_column_be_ease_of_implementation",
    fallbackValue: "Ease of implementation"
  },
  "1389540909": {
    localizationKey: "automation_table_column_be_ease_of_implementation_level",
    fallbackValue: "Ease of implementation level"
  },
  "857457221": {
    localizationKey: "automation_table_column_be_error_rate_difference",
    fallbackValue: "Error rate difference"
  },
  "232723747": {
    localizationKey: "automation_table_column_be_ftes_required",
    fallbackValue: "FTEs required"
  },
  "-673310647": {
    localizationKey: "automation_table_column_be_idea_score",
    fallbackValue: "Idea score"
  },
  "613467460": {
    localizationKey: "automation_table_column_be_idea_source",
    fallbackValue: "Idea source"
  },
  "71240811": {
    localizationKey:
      "automation_table_column_be_is_published_in_automation_store",
    fallbackValue: "Published to Store"
  },
  "-1259246317": {
    localizationKey: "automation_table_column_be_last_modified",
    fallbackValue: "Last Modified"
  },
  "1378168827": {
    localizationKey: "automation_table_column_be_launch_date",
    fallbackValue: "Launch Date"
  },
  "-1314055311": {
    localizationKey: "automation_table_column_be_negative_impact",
    fallbackValue: "Negative impact"
  },
  "-876494283": {
    localizationKey: "automation_table_column_be_number_of_applications",
    fallbackValue: "Number of Applications"
  },
  "-218209470": {
    localizationKey:
      "automation_table_column_be_number_of_automation_downloads_from_automation_store",
    fallbackValue: "Downloads"
  },
  "-171375003": {
    localizationKey:
      "automation_table_column_be_number_of_automation_store_reviews",
    fallbackValue: "Number of Automation Store Reviews"
  },
  "-330150579": {
    localizationKey: "automation_table_column_be_pain_points",
    fallbackValue: "Pain points"
  },
  "1919545943": {
    localizationKey: "automation_table_column_be_phase_status",
    fallbackValue: "Phase status"
  },
  "1941943015": {
    localizationKey:
      "automation_table_column_be_potential_benefit_across_company",
    fallbackValue: "Potential benefit across company"
  },
  "197061148": {
    localizationKey:
      "automation_table_column_be_potential_benefit_per_employee",
    fallbackValue: "Potential benefit per employee"
  },
  "2072346616": {
    localizationKey: "automation_table_column_be_process_duration_difference",
    fallbackValue: "Process duration difference"
  },
  "118172283": {
    localizationKey: "automation_table_column_be_qualification_phase_approver",
    fallbackValue: "Qualification Phase Approver"
  },
  "2027975996": {
    localizationKey: "automation_table_column_be_readiness_score",
    fallbackValue: "Readiness score"
  },
  "1348127796": {
    localizationKey: "automation_table_column_be_rework_time_difference",
    fallbackValue: "Rework time difference"
  },
  "-586858426": {
    localizationKey: "automation_table_column_be_rpa_automation_type",
    fallbackValue: "RPA Automation Type"
  },
  "1693265451": {
    localizationKey: "automation_table_column_be_submission_source_link",
    fallbackValue: "Submission Source Link"
  },
  "78991887": {
    localizationKey: "automation_table_column_be_suitability_score",
    fallbackValue: "Suitability score"
  },
  "-275745993": {
    localizationKey: "automation_table_column_be_volume_average",
    fallbackValue: "Volume average"
  },
  "-1364093273": {
    localizationKey: "automation_table_column_be_submitters_business_unit",
    fallbackValue: "Submitter’s Business Unit"
  },
  "1851686287": {
    localizationKey: "automation_table_column_be_submitters_department",
    fallbackValue: "Submitter’s Department"
  },
  "1272135949": {
    localizationKey: "automation_table_column_be_collaborators",
    fallbackValue: "Collaborators"
  },
  "65921": {
    localizationKey: "tab_name_be_all",
    fallbackValue: "All"
  },
  "-1829797088": {
    localizationKey: "co_questions_question_type_rich_text",
    fallbackValue: "Free text with formatting"
  },
  "-1555255162": {
    localizationKey: "co_questions_question_type_process_property",
    fallbackValue: "Process property"
  },
  "1512333889": {
    localizationKey: "co_questions_question_type_free_text",
    fallbackValue: "Free text"
  },
  "736449215": {
    localizationKey: "co_questions_question_type_external_data",
    fallbackValue: "External data"
  },
  "-1803317159": {
    localizationKey: "co_questions_question_type_single_choice",
    fallbackValue: "Single choice"
  },
  "594208145": {
    localizationKey: "co_questions_question_type_multiple_choice",
    fallbackValue: "Multiple choice"
  },
  "2645995": {
    localizationKey: "co_questions_question_type_user",
    fallbackValue: "User"
  },
  "-1597113167": {
    localizationKey: "co_questions_question_type_single_choice_nested",
    fallbackValue: "Single choice (Nested)"
  },
  "1071632058": {
    localizationKey: "co_questions_question_type_percentage",
    fallbackValue: "Percentage"
  },
  "-1950496919": {
    localizationKey: "co_questions_question_type_number",
    fallbackValue: "Number"
  },
  "926364987": {
    localizationKey: "co_questions_question_type_document",
    fallbackValue: "Document"
  },
  "-546818532": {
    localizationKey: "co_questions_question_type_automations",
    fallbackValue: "Automations"
  },
  "65071038": {
    localizationKey: "co_kpi_type_chart",
    fallbackValue: "Chart"
  },
  "-657838022": {
    // NOTE: this hash is actually for "NOTE: You cannot delete this section." but we want can't
    localizationKey: "co_note_cannot_delete_section",
    fallbackValue: "NOTE: You can't delete this section."
  },
  "25968360": {
    localizationKey: "co_note_question_rename_automation_profile",
    fallbackValue:
      "NOTE: If this question gets renamed the Automation Store profile keeps displaying the original question title."
  },
  "-1917751447": {
    localizationKey: "co_note_question_cannot_delete_required_idea",
    fallbackValue:
      "NOTE: This question cannot be deleted. It is required for any idea submission."
  },
  "-1791269559": {
    localizationKey: "co_note_only_displayed_process_task_mining",
    fallbackValue:
      "NOTE: This question is only displayed when ideas are submitted from Process Mining or Task Mining."
  },
  "18975544": {
    localizationKey: "co_note_cannot_edit_delete_section",
    fallbackValue:
      "NOTE: This section cannot be edited or deleted. It contains the questions from the Automation Profile header."
  },
  "-1082661401": {
    localizationKey: "co_note_documents_added_visibility_settings",
    fallbackValue:
      "NOTE: The documents added will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings."
  },
  "-828834987": {
    localizationKey: "co_note_question_default_collaborator_role",
    fallbackValue:
      "NOTE: This question is tied to a default collaborator role. The answer will be displayed in the Collaborators page of the automation profile regardless of this section's visibility settings."
  },
  "-154173364": {
    localizationKey: "co_note_section_title_automation_profile",
    fallbackValue:
      "NOTE: This section's title doesn't show on the automation profile"
  },
  "757749002": {
    localizationKey: "co_note_section_cannot_delete",
    fallbackValue: "NOTE: This section can't be deleted."
  },
  "-2110466337": {
    localizationKey: "co_note_components_added",
    fallbackValue:
      "NOTE: The components added to this question will be displayed in the Components page of the automation profile."
  },
  "1884805397": {
    localizationKey: "co_note_documents_added",
    fallbackValue:
      "NOTE: The documents added to questions from this section will be displayed in the Documentation page of the automation profile."
  },
  "437319297": {
    localizationKey: "co_note_question_will_not_be_shown_visibility_settings",
    fallbackValue:
      "NOTE: This question will not be shown in the automation pipeline. The answer will be displayed in the Components page of the automation profile regardless of this section's visibility settings."
  },
  "802853580": {
    localizationKey: "co_note_question_will_not_be_shown",
    fallbackValue:
      "NOTE: This question will not be shown in the automation pipeline."
  },
  "-402725110": {
    localizationKey: "co_note_kpi_displayed_automation_store",
    fallbackValue: "NOTE: KPI is displayed in the Automation Store profile"
  },
  "1903221508": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-detailed-work-instructions",
    fallbackValue: "Detailed work instructions"
  },
  "181787889": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-standard-operating-procedure",
    fallbackValue: "Standard operating procedure"
  },
  "-111391103": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-input-files",
    fallbackValue: "Input files"
  },
  "-1439320392": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-output-files",
    fallbackValue: "Output files"
  },
  "2139346297": {
    localizationKey: "questionnaire_form_response_choice_be_documentation-misc",
    fallbackValue: "Misc. files"
  },
  "691994442": {
    localizationKey:
      "questionnaire_form_response_choice_be_documentation-input-files-output-files",
    fallbackValue: "Input files - Output files"
  },
  "-107531760": {
    localizationKey:
      "questionnaire_form_response_choice_be_input-type-strongly-agree",
    fallbackValue: "Strongly agree"
  },
  "-1710760732": {
    localizationKey:
      "questionnaire_form_response_choice_be_input-type-neither-disagree-or-agree",
    fallbackValue: "Neither disagree or agree"
  },
  "-994448134": {
    localizationKey:
      "questionnaire_form_response_choice_be_input-type-strongly-disagree",
    fallbackValue: "Strongly disagree"
  },
  "-158161723": {
    localizationKey: "automation_table_sort_options_be_no-software-vendor",
    fallbackValue: "No RPA Software Vendor"
  },
  "1874051585": {
    localizationKey: "automation_table_sort_options_be_no_license_key",
    fallbackValue: "No License Type"
  },
  "2049086467": {
    localizationKey: "automation_table_sort_options_be_employee-driven",
    fallbackValue: "Employee-driven"
  },
  "908777272": {
    localizationKey: "automation_table_sort_options_be_coe-driven",
    fallbackValue: "CoE-driven"
  },
  "-2111426719": {
    localizationKey: "automation_table_sort_options_be_process-mining",
    fallbackValue: "Process Mining"
  },
  "-1763578037": {
    localizationKey: "automation_table_sort_options_be_task-mining",
    fallbackValue: "Task Mining"
  },
  "-868825247": {
    localizationKey: "automation_table_column_be_overview_assessment_version",
    fallbackValue: "Overview assessment Version"
  },
  "1617770580": {
    localizationKey:
      "automation_table_column_be_cd_high_level_assessment_version",
    fallbackValue: "High level assessment Version"
  },
  "1864740744": {
    localizationKey:
      "automation_table_column_be_cd_automation_assessment_version",
    fallbackValue: "Citizen Developer Assessment Version"
  },
  "1818973241": {
    localizationKey: "co_ovr_header_information_title",
    fallbackValue: "Header information"
  },
  "-221101435": {
    localizationKey:
      "automation_table_column_be_cd_process_overall_gauge_score_descr",
    fallbackValue: "Estimated in High Level Assessment"
  },
  "1052928004": {
    localizationKey: "co_benefit_per_employee_hous_per_month",
    fallbackValue: "Benefit per employee (hours saved/month)"
  },
  "-1247361882": {
    localizationKey:
      "automation_table_column_be_cd_average_process_duration_post_automation",
    fallbackValue: "Average process duration - after automation"
  },
  "1122081222": {
    localizationKey: "co_potential_benefit_per_employee_hous_per_month",
    fallbackValue: "Potential benefit per employee (hours saved/month)"
  },
  "1405835305": {
    localizationKey: "co_process_owner_q1_description",
    fallbackValue:
      "<p>Assign a Process Owner who will be invited to fill in a more detailed assessment for this automation idea.</p>"
  },
  "1144276389": {
    localizationKey: "co_automation_potential_description",
    fallbackValue:
      "<p>Computed based on the answers to % of structured digital data input, % of digital data input and Number of ways to complete the process</p>"
  },
  "1051109367": {
    localizationKey: "co_documentation_question_default_note",
    fallbackValue:
      "NOTE: This question will not be shown in the automation pipeline. The answer will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings."
  },
  "-328612892": {
    localizationKey: "automation_table_column_be_process_num_requests",
    fallbackValue: "Requests"
  },
  "-109682326": {
    localizationKey: "automation_table_column_be_pdd_link",
    fallbackValue: "PDD Link"
  },
  "162244762": {
    localizationKey: "automation_table_column_be_applications_used",
    fallbackValue: "Applications Used"
  },
  "-511824733": {
    localizationKey: "automation_table_column_be_process_type",
    fallbackValue: "Automation Type"
  },
  "-298585899": {
    localizationKey: "automation_table_column_be_date_of_idea_approval",
    fallbackValue: "Date of Idea Approval"
  },
  "1437464793": {
    localizationKey: "automation_table_column_be_date_of_launched_assessment",
    fallbackValue: "Date of Launched Assessment"
  },
  "-663949269": {
    localizationKey:
      "automation_table_column_be_date_of_qualifacation_approval",
    fallbackValue: "Date of Qualification Approval"
  },
  "-1750811384": {
    localizationKey: "automation_table_column_be_assessment_phase_approver",
    fallbackValue: "Assessment Phase Approver"
  },
  "2034357691": {
    localizationKey: "automation_table_column_be_number_of_robots",
    fallbackValue: "Number Of Robots"
  },
  "1848587689": {
    localizationKey: "automation_table_column_be_cba_automation_potential",
    fallbackValue: "Revised Automation Potential"
  },
  "8677011": {
    localizationKey: "automation_table_column_be_cba_estimated_benefit_cost",
    fallbackValue: "Revised Benefit per company (currency/year)"
  },
  "-82931352": {
    localizationKey: "automation_table_column_be_cba_estimated_benefit_ftes",
    fallbackValue: "Revised Benefit per company (FTEs)"
  },
  "422689230": {
    localizationKey: "automation_table_column_be_cba_estimated_benefit_score",
    fallbackValue: "Revised Benefit per company (hours saved/year)"
  },
  "406207172": {
    localizationKey:
      "export_csv_header_label_cba_estimated_cost_benefit_per_employee",
    fallbackValue: "Revised Benefit per employee (currency/year)"
  },
  "912331287": {
    localizationKey:
      "export_csv_header_label_cba_estimated_benefit_ftes_per_employee",
    fallbackValue: "Revised Benefit per employee (FTEs)"
  },
  "2018880509": {
    localizationKey:
      "automation_table_column_be_cba_estimated_benefit_hours_per_employee",
    fallbackValue: "Revised Benefit per employee (hours saved/year)"
  },
  "-1025314307": {
    localizationKey: "automation_table_column_be_cba_ease_of_implementation",
    fallbackValue: "Revised Ease of Implementation"
  },
  "1343510047": {
    localizationKey: "cr_hash_change_request",
    fallbackValue: "Change Request"
  },
  "67156": {
    localizationKey: "cr_hash_bug",
    fallbackValue: "Bug"
  },
  "146486506": {
    localizationKey: "cr_hash_medium_next_months",
    fallbackValue: "Medium - Next few months"
  },
  "396098157": {
    localizationKey: "cr_hash_additional_benefit_per_company_fte",
    fallbackValue: "Additional benefit per company (FTEs)"
  },
  "502952399": {
    localizationKey: "cr_hash_how_many_ftes",
    fallbackValue:
      "<p>How many additional FTEs would the company save if this change request gets implemented? </p>"
  },
  "503440927": {
    localizationKey: "cr_hash_select_option_that_best_fits_explain_in_comments",
    fallbackValue:
      "<p>Select the option that best fits your scenario and explain the selection in comments</p>"
  },
  "813220691": {
    localizationKey: "cr_hash_additional_benefit_per_company_hours_year",
    fallbackValue: "Additional benefit per company (hours saved/year)"
  },
  "1444811442": {
    localizationKey: "cr_hash_additional_benefit_per_company_currency_year",
    fallbackValue: "Additional benefit per company (currency/year) "
  },
  "1465661216": {
    localizationKey: "cr_hash_how_many_hours_company_save",
    fallbackValue:
      "<p>How many additional hours would the company save if this change request gets implemented? </p>"
  },
  "1470403766": {
    localizationKey: "cr_hash_improvement",
    fallbackValue: "Improvement"
  },
  "1480879429": {
    localizationKey: "cr_hash_money_company_saves",
    fallbackValue:
      "<p>What additional amount of money would the company save if this change request gets implemented? </p>"
  },
  "1490365225": {
    localizationKey: "cr_hash_urgency",
    fallbackValue: "Urgency"
  },
  "1767195516": {
    localizationKey: "cr_hash_high_level_assessment",
    fallbackValue: "High level assessment"
  },
  "2004252205": {
    localizationKey: "cr_hash_parent_automation",
    fallbackValue: "Parent automation"
  },
  "-1072592350": {
    localizationKey: "cr_hash_details",
    fallbackValue: "Details"
  },
  "-632725981": {
    localizationKey: "cr_hash_reaston_for_change",
    fallbackValue: "Reason for change"
  },
  "-1863043423": {
    localizationKey: "cr_hash_system_change",
    fallbackValue: "System change"
  },
  "-1482891615": {
    localizationKey: "cr_hash_process_change",
    fallbackValue: "Process change"
  },
  "-917811991": {
    localizationKey: "cr_hash_asap_downtime",
    fallbackValue: "ASAP - Bot will undergo downtime soon"
  },
  "-2105291782": {
    localizationKey: "cr_hash_hight_upcoming_deadline",
    fallbackValue: "High - Upcoming hard deadline"
  },
  "-1362784976": {
    localizationKey: "cr_hash_low_bot_will_have_not_have_downtime",
    fallbackValue: "Low - Bot will not have downtime at all"
  },
  "-1084875764": {
    localizationKey: "cr_hash_target_launch_date",
    fallbackValue: "Target launch date"
  },
  "-1347456360": {
    localizationKey: "cr_hash_documents",
    fallbackValue: "Documents"
  },
  "-1327508287": {
    localizationKey: "cr_hash_assessments_visibility",
    fallbackValue: "Assessments visibility"
  },
  "-1532317596": {
    localizationKey: "cr_hash_include_additional_assessments",
    fallbackValue: "<p>Include additional assessments if necessary below</p>"
  },
  "-2133620278": {
    localizationKey: "cr_hash_hidden",
    fallbackValue: "Hidden"
  },
  "-1671305264": {
    localizationKey: "cr_hash_start_fresh",
    fallbackValue: "Start fresh"
  },
  "-1799709220": {
    localizationKey: "cr_hash_inherit_from_parent",
    fallbackValue: "Inherit from parent idea"
  },
  "-1902300142": {
    localizationKey: "cr_hash_detailed_assessment",
    fallbackValue: "Detailed assessment"
  },
  "-163496272": {
    localizationKey: "cr_hash_citizen_developer_assessment",
    fallbackValue: "Citizen developer assessment"
  },
  "-1207600869": {
    localizationKey: "cr_hash_change_request_assessment_version",
    fallbackValue: "Change Request Assessment Version"
  },
  "-1592416254": {
    localizationKey:
      "automation_table_column_be_cd_process_num_change_requests",
    fallbackValue: "Number of Change Requests"
  },
  "27731949": {
    localizationKey: "ex_hash_auto_computed_based_on_process_volumetry",
    fallbackValue:
      "<p>Auto-computed based on values inserted in the Process Volumetry section of the Detailed assessment or the Citizen Developer assessment</p>"
  },
  "39347171": {
    localizationKey: "ex_hash_actual_savings_data",
    fallbackValue: "Actual savings data"
  },
  "105985586": {
    localizationKey: "ex_hash_da_average_work_reviewed",
    fallbackValue:
      "Detailed Assessment - Average work to be reviewed/audited (% of total volume)"
  },
  "259787872": {
    localizationKey: "ex_hash_da_average_rework_time",
    fallbackValue: "Detailed Assessment - Average rework time per transaction"
  },
  "269671690": {
    localizationKey: "ex_hash_allocate_reference_manual_time",
    fallbackValue:
      "<p>Allocate 100% or less of the reference manual time saved per run (min).</p>"
  },
  "288323178": {
    localizationKey: "ex_hash_sum_target_savings_from_package",
    fallbackValue:
      "<p>Sum of all yearly target cost savings from the referenced Orchestrator packages</p>"
  },
  "338973160": {
    localizationKey: "ex_hash_cd_rework_time_before",
    fallbackValue:
      "Citizen Developer Assessment - Average rework time - before automation"
  },
  "393737674": {
    localizationKey: "ex_hash_cost_savings_per_run",
    fallbackValue: "Total cost savings per run"
  },
  "438573584": {
    localizationKey: "ex_hash_target_automated_values",
    fallbackValue: "Yearly target automated volumes"
  },
  "535736790": {
    localizationKey: "ex_hash_da_working_days_per_year",
    fallbackValue: "Detailed Assessment - Average working days per year"
  },
  "743817414": {
    localizationKey: "ex_hash_da_review_audit_time_per_transaction",
    fallbackValue:
      "Detailed Assessment - Average review or audit time per transaction"
  },
  "782849120": {
    localizationKey: "ex_hash_savings_per_idea",
    fallbackValue: "Total actual savings per automation idea"
  },
  "961821297": {
    localizationKey: "ex_hash_da_average_processing_time",
    fallbackValue:
      "Detailed Assessment - Average processing time per transaction"
  },
  "964012050": {
    localizationKey: "ex_hash_da_average_error_rate_after_automation",
    fallbackValue: "Detailed Assessment - Average error rate - after automation"
  },
  "1064432846": {
    localizationKey: "ex_hash_da_aht_saved_per_transaction",
    fallbackValue: "Detailed Assessment - AHT saved per transaction"
  },
  "1136919344": {
    localizationKey: "ex_hash_cd_average_process_duration_before_automation",
    fallbackValue:
      "Citizen Developer Assessment - Average process duration - before automation"
  },
  "1269877763": {
    localizationKey: "ex_hash_cd_average_error_rate_before_automation",
    fallbackValue:
      "Citizen Developer Assessment - Average error rate - before automation"
  },
  "1373214922": {
    localizationKey: "ex_hash_total_time_savings_per_run",
    fallbackValue: "Total time savings per run"
  },
  "1403663626": {
    localizationKey: "ex_hash_execution_assessment",
    fallbackValue: "Execution Assessment"
  },
  "1492861085": {
    localizationKey: "ex_hash_using_queues_insights_adapts",
    fallbackValue:
      "<p>If the process using the package is run through queues, the actual benefit formula in Insights adapts to take queue item counts into account</p>"
  },
  "1725594187": {
    localizationKey: "ex_hash_cd_average_rework_time_after_automation",
    fallbackValue:
      "Citizen Developer Assessment - Average rework time - after automation"
  },
  "1779791744": {
    localizationKey: "ex_hash_da_activity_volume_average",
    fallbackValue:
      "Detailed Assessment - Activity volume average (per selected frequency)"
  },
  "1854088364": {
    localizationKey: "ex_hash_final_time_saved_per_run",
    fallbackValue: "Final manual time saved per run"
  },
  "1984405168": {
    localizationKey: "ex_hash_hourly_employee_cost",
    fallbackValue: "Hourly employee cost"
  },
  "2026940677": {
    localizationKey: "ex_hash_da_employees_performing_task",
    fallbackValue:
      "Detailed Assessment - Number of employees performing the task"
  },
  "2032718032": {
    localizationKey: "ex_hash_cd_error_rate_after_automation",
    fallbackValue:
      "Citizen Developer Assessment - Average error rate - after automation"
  },
  "-1311045901": {
    localizationKey: "ex_hash_fill_in_data_to_compute_benefits",
    fallbackValue:
      "<p>Fill in the business data needed for computing the Actual benefits based on the automation's successful runs</p>"
  },
  "-1390401422": {
    localizationKey: "ex_hash_referenced_questions_and_kpis",
    fallbackValue: "Referenced questions and KPIs"
  },
  "-1252429850": {
    localizationKey:
      "ex_hash_section_contains_ref_questions_from_other_assessments",
    fallbackValue:
      "<p>This section contains referenced questions from the other types of assessment, such that they can be used for computing the KPIs needed in the Actual benefit calculation. All questions and KPIs in this section are hidden everywhere, by default.</p>"
  },
  "-419302519": {
    localizationKey: "ex_hash_da_average_error_rate_of_volume",
    fallbackValue:
      "Detailed Assessment - Average error rate (% of total volume)"
  },
  "-1787673731": {
    localizationKey: "ex_hash_da_time_per_transaction_after_automation",
    fallbackValue:
      "Detailed Assessment - Average processing time per transaction - after automation"
  },
  "-1339520183": {
    localizationKey: "ex_hash_da_rework_time_after_automation",
    fallbackValue:
      "Detailed Assessment - Average rework time - after automation"
  },
  "-1367385191": {
    localizationKey: "ex_hash_time_saved_according_to_da_assessment",
    fallbackValue:
      "<p>Total manual time estimated to be saved for performing and reviewing one transaction, in case of using the detailed assessment</p>"
  },
  "-210752527": {
    localizationKey: "ex_hash_da_employee_cost_per_year",
    fallbackValue:
      "Detailed Assessment - Average employee full cost per year (Optional)"
  },
  "-193802856": {
    localizationKey: "ex_hash_da_working_hours_day",
    fallbackValue: "Detailed Assessment - Working hours per day"
  },
  "-415149689": {
    localizationKey: "ex_hash_hourly_cost_employee_preforming_task_from_da",
    fallbackValue:
      "<p>Hourly cost with the employee performing the activity, based on the detailed assessment data. In case of citizen developer assessment, this data is lacking.</p>"
  },
  "-1232353150": {
    localizationKey: "ex_hash_da_task_process_frequency",
    fallbackValue: "Detailed Assessment - Task/process frequency"
  },
  "-1754818166": {
    localizationKey: "ex_hash_da_activity_volume_average_nested",
    fallbackValue:
      "Detailed Assessment - Activity volume average (per selected frequency) (nested)"
  },
  "-672748831": {
    localizationKey: "ex_hash_da_manual_volumes_year",
    fallbackValue: "Detailed Assessment - Manual volumes (per year)"
  },
  "-919236462": {
    localizationKey: "ex_hash_number_transactions_performed_year_da",
    fallbackValue:
      "<p>Total number of transactions performed manually, in a year (in case of using the detailed assessment)</p>"
  },
  "-1460569546": {
    localizationKey: "ex_hash_da_automation_potential",
    fallbackValue: "Detailed Assessment - Automation potential"
  },
  "-1989443581": {
    localizationKey: "ex_hash_cd_process_duration_after_automation",
    fallbackValue:
      "Citizen Developer Assessment - Average process duration - after automation"
  },
  "-302134644": {
    localizationKey: "ex_hash_cd_aht_saved_per_transaction",
    fallbackValue: "Citizen Developer Assessment - AHT saved per transaction"
  },
  "-964110190": {
    localizationKey: "ex_hash_total_manual_time_saved_transaction_cd",
    fallbackValue:
      "<p>Total manual time saved performing and reviewing one transaction, in case of using the citizen developer assessment.</p>"
  },
  "-189863238": {
    localizationKey: "ex_hash_cd_volume_average",
    fallbackValue: "Citizen Developer Assessment - Volume average"
  },
  "-1583038872": {
    localizationKey: "ex_hash_cd_process_frequency",
    fallbackValue: "Citizen Developer Assessment - Process frequency"
  },
  "-1024526584": {
    localizationKey: "ex_hash_cd_people_whod_benefit",
    fallbackValue:
      "Citizen Developer Assessment - Number of people that could benefit from this automation"
  },
  "-2039316321": {
    localizationKey: "ex_hash_cd_manual_volumes_year",
    fallbackValue: "Citizen Developer Assessment - Manual volumes (per year)"
  },
  "-1398885570": {
    localizationKey: "ex_hash_volumes_handled_cd",
    fallbackValue:
      "<p>Total yearly volumes handled by the employees performing the activity  (in case of using the citizen developer assessment)</p>"
  },
  "-944838772": {
    localizationKey: "ex_hash_select_orchestrator_packages_for_idea",
    fallbackValue:
      "<p>Select one or several Orchestrator packages that reflect the automation build for the idea, and define the additional metadata needed for computing the actual benefits. This information with be used in UiPath Insights, for ROI reporting.</p>"
  },
  "-444609840": {
    localizationKey: "ex_hash_orchestrator_package",
    fallbackValue: "Orchestrator package"
  },
  "-1260552191": {
    localizationKey:
      "ex_hash_select_orchestrator_packages_for_insights_reporting",
    fallbackValue:
      "<p>Select the Orchestrator package. In case several package cover the entire scope of the idea, use the add row button for adding a second package. This information with be used in UiPath Insights, for ROI reporting.</p>"
  },
  "-2011968322": {
    localizationKey: "ex_hash_using_queues",
    fallbackValue: "Using queues"
  },
  "-476655200": {
    localizationKey: "ex_hash_reference_manual_time_saved",
    fallbackValue: "Reference manual time saved per run (min)"
  },
  "-799741586": {
    localizationKey: "ex_hash_time_savings_per_run",
    fallbackValue: "Time savings per run"
  },
  "-1324250097": {
    localizationKey: "ex_hash_computed_on_manual_time_overridden",
    fallbackValue:
      "<p>Auto-computed based on whether the user overridden the Final manual time saved per run or not. Used in Insights for ROI reporting purposes</p>"
  },
  "-1779218834": {
    localizationKey: "ex_hash_cost_savings_per_run_single",
    fallbackValue: "Cost savings per run"
  },
  "-886563806": {
    localizationKey: "ex_hash_computed_from_employee_profile",
    fallbackValue:
      "<p>Auto-computed from the Employee Profile data and the Final manual time saved per run</p>"
  },
  "-798675758": {
    localizationKey: "ex_hash_computed_manual_volumes_and_automation_potential",
    fallbackValue:
      "<p>Auto-computed based on the manual volumes of transactions and the automation potential %</p>"
  },
  "-1196278947": {
    localizationKey: "ex_hash_yearly_target_hours_saved",
    fallbackValue: "Yearly target hours saved"
  },
  "-1023220580": {
    localizationKey: "ex_hash_computed_from_data_input_per_package",
    fallbackValue:
      "<p>Auto-computed based on the data input per Orchestrator package</p>"
  },
  "-769554657": {
    localizationKey: "ex_hash_yearly_target_savings",
    fallbackValue: "Yearly target cost savings"
  },
  "-159833261": {
    localizationKey: "ex_hash_target_savings_for_idea",
    fallbackValue:
      "<p>Aggregates the target actual savings for the entire automation idea</p>"
  },
  "-868747430": {
    localizationKey: "ex_hash_sum_savings_per_run",
    fallbackValue:
      "<p>Sum of time savings per run from the referenced Orchestrator packages</p>"
  },
  "-851733414": {
    localizationKey: "ex_hash_sum_savings",
    fallbackValue:
      "<p>Sum of cost savings per run from the referenced Orchestrator packages</p>"
  },
  "-1765506246": {
    localizationKey: "ex_hash_total_hours_saved",
    fallbackValue: "Total hours saved"
  },
  "-367680202": {
    localizationKey: "ex_hash_sum_target_hours_saved",
    fallbackValue:
      "<p>Sum of all yearly target hours saved from the referenced Orchestrator packages</p>"
  },
  "-1235731742": {
    localizationKey: "ex_hash_total_cost_savings",
    fallbackValue: "Total cost savings"
  },
  "-1048944393": {
    localizationKey: "ex_hash_nested_question_label",
    fallbackValue: "nested"
  },
  "2004233261": {
    localizationKey: "ex_hash_what_applications_impacted",
    fallbackValue: "What applications are impacted?"
  }
};
