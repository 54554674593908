import React from "react";
import ComponentNewVersionTooltip from "../../components/ComponentNewVersionTooltip/ComponentNewVersionTooltip";
import {
  REJECTED_PHASE,
  NEEDS_UPDATE_PHASE,
  UPDATE_AVAILABLE,
  component_map_to_name,
  component_map_to_classname,
  component_map_to_slug
} from "../../constants/componentPermissionsConstants";
import { CLASSNAME_GRAY } from "../../constants/phaseColorsConstants.js";
import compareVersions from "compare-versions";
import { withTranslation } from "react-i18next";

import "./CellStatusComponents.scss";
import { Tooltip } from "@material-ui/core";
import ChipApollo from "../ChipApollo/ChipApollo";

const CellStatusComponents = props => {
  const {
    component_status_id,
    component_slug,
    component_reject_reason,
    component_semantic_version,
    latest_published_version,
    latest_published_semantic_version,
    latest_published_release_notes,
    t,
    className
  } = props;
  let statusChipClassName = component_map_to_classname[component_status_id];
  let status = component_map_to_name[component_status_id];
  let statusSlug = component_map_to_slug[component_status_id];

  let showUpdateAvailableTooltip = false;

  if (
    latest_published_semantic_version &&
    compareVersions(
      component_semantic_version,
      latest_published_semantic_version
    ) === -1
  ) {
    statusChipClassName = component_map_to_classname[UPDATE_AVAILABLE];
    status = component_map_to_name[UPDATE_AVAILABLE];
    statusSlug = component_map_to_slug[UPDATE_AVAILABLE];
    showUpdateAvailableTooltip = true;
  }

  let rejectReason = component_reject_reason;
  if (
    component_status_id !== REJECTED_PHASE &&
    component_status_id !== NEEDS_UPDATE_PHASE
  ) {
    rejectReason = "";
  }

  if (component_status_id === null || component_status_id === undefined) {
    statusChipClassName = CLASSNAME_GRAY;
  }

  let translatedStatus = statusSlug
    ? t("component_cell_status_" + (statusSlug || "").toLowerCase(), status)
    : status;

  let tooltipTitle = "";
  let tooltipPlacement = "bottom";

  if (showUpdateAvailableTooltip) {
    tooltipTitle = (
      <ComponentNewVersionTooltip
        tooltipContent={latest_published_release_notes}
        lastestPublishedVersion={latest_published_version}
        lastestPublishedSementicVersion={latest_published_semantic_version}
        componentSlug={component_slug}
      />
    );
  } else if (rejectReason) {
    tooltipTitle = rejectReason;
    tooltipPlacement = "top";
  }

  return (
    <div
      className={"CellStatusComponents" + (className ? ` ${className}` : "")}
    >
      {tooltipTitle ? (
        <div>
          <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
            <ChipApollo
              data-testid={"component-status-field"}
              size="small"
              label={translatedStatus}
              className={statusChipClassName}
              isStatusChip={true}
            />
          </Tooltip>
        </div>
      ) : (
        <div>
          <ChipApollo
            data-testid={"component-status-field"}
            size="small"
            label={translatedStatus}
            className={statusChipClassName}
            isStatusChip={true}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation("common")(CellStatusComponents);
