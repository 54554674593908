import {
  parseDisplayEquation,
  parseRawEquation,
  kpisWithEquationReferencingQuestion,
  allKPIsInContext,
  getOtherQuestionsInAssessmentWithVariablesThatCanImpactKpi
} from "../helpers/co-equation.helper";
import {
  COAssessmentInterface,
  COBaseInterface,
  COContextInterface,
  COControlOptionListItem,
  CODisplayContext,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COQuestionAnswerOptionInterface,
  COQuestionDBClass,
  COQuestionInterface,
  COQuestionTemplateDBClass,
  COQuestionTemplateInterface,
  COSectionInterface,
  COControlInterface
} from "../interfaces/co-interfaces";
import { getValue } from "../utils/co-path.utils";
import {
  equationReferenceValidator,
  equationTransformValidator,
  equationValidator,
  variableChangeValidator,
  variableDuplicateCheckValidator,
  variableValidator,
  variableUpdateCalculatedValueKeyValidator
} from "../templates/elements/validators/co-question.validators";
import { COBase } from "./co-base.class";
import { COCalculatedValue } from "./co-calculated-value.class";
import { COProcessAnswer } from "./co-process-answer.class";
import {
  COQuestionAnswerOption,
  COQuestionAnswerOptionTemplate
} from "./co-question-answer-option.class";
import {
  COContextObjectKey,
  COTypes,
  COConditionSlugs,
  CO_NON_TABULAR_DEFAULT_INDEX
} from "../constants/co-constants";
import {
  addAnswerOption,
  isQuestionAnswered
} from "../helpers/co-question.helper";
import {
  coRandomVar,
  doesExistPositiveContextMatch,
  isNullOrUndefined,
  rawStringFromPotentiallyDraftJS
} from "../utils/co-utils";
import { objectDeepCopy, optionsInContext } from "../helpers/co-context.helper";
import { COProcessAnswerSelection } from "./co-process-answer-selection.class";
import { COBaseTemplate } from "./co-base-template.class";
import { PATH } from "../constants/co-path.constants";
import { updatePositionSortOrderForItems } from "../helpers/co-position.helper";
import { questionBranchingControls } from "../templates/elements/controls/co-question-branching-controls.template";
import { updateContext } from "./co-context.class";
import { calculatedValueKey } from "../helpers/co-calculated-type.helper";

export interface COQuestion extends COQuestionInterface {}
export class COQuestion extends COBase {
  static getClassName(): string {
    return "COQuestion";
  }
  constructor({
    propertiesFromJSON,
    context,
    template,
    raw,
    parentCOObject
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COQuestionTemplate;
    raw?: any;
    parentCOObject?: COSectionInterface | COQuestionAnswerOptionInterface;
  }) {
    super({
      propertiesFromJSON
    });

    this.objectClassMapping = {
      arrays: [
        {
          objectKey: "co_question_answer_options",
          objectClass: COQuestionAnswerOption
        }
      ],
      objects: [
        {
          objectKey: "co_process_answer",
          objectClass: COProcessAnswer
        },
        {
          objectKey: "co_process_calculated_value",
          objectClass: COCalculatedValue
        }
      ],
      dbClass: COQuestionDBClass,
      contextKey: COContextObjectKey.QUESTION,
      ahid_key: "co_question_ahid",
      objectClass: COQuestion
    };

    if (!this.co_question_answer_options) {
      this.co_question_answer_options = [];
    }

    // template means we're making a new object - not loading from json or db result
    if ((template || raw) && context) {
      this[this.objectClassMapping.ahid_key || ""] =
        (raw && raw.co_question_ahid) ||
        (template && template.co_question_json?.co_question_ahid) ||
        COQuestion.generateAHID();

      if (template) {
        this.setNewTemplate?.(context, template);
      }
      if (raw) {
        this.setRawValues?.(context, raw);
      }

      this.generateSlugsAndKeys?.(context);

      if (parentCOObject) {
        this.updateParent?.(parentCOObject);
      }

      if (context) {
        this.processCustomTemplateVars?.({ context: context });
      }
    }

    this.checkAndLoadTemplate?.();

    // we have an issue with the calculated value key not being set correctly - as the question could change if part of a different assessment
    this.updateCalculatedValueKey?.(context);

    //  stored as -1 in the database if it's non tabular
    this.setQuestionTableRowIndex?.(this.co_table_row_index); // this might be in the object, for example when the backend is sent a full assessment from the frontend
  }

  templateSlug?() {
    return this.co_question_template_slug;
  }

  setQuestionTableRowIndex?(co_table_row_index?: number) {
    // do we check here if the section is tabular?
    if (isNullOrUndefined(co_table_row_index)) {
      this.co_table_row_index = CO_NON_TABULAR_DEFAULT_INDEX;
    } else {
      this.co_table_row_index = co_table_row_index;
    }

    // might end up making these functions
    if (this.co_process_answer) {
      this.co_process_answer.co_table_row_index = this.co_table_row_index;
    }
    if (this.co_process_calculated_value) {
      this.co_process_calculated_value.co_table_row_index = this.co_table_row_index;
    }
  }

  updateCalculatedValueKey?(context: COContextInterface | undefined) {
    //key is based off variable and assessment type
    if (context) {
      this.co_calculated_value_key = calculatedValueKey({
        assessment: context.assessment,
        question: this
      });
    }
  }

  // update some question variables to ahids - node the order we build the assessment matters here
  processCustomTemplateVars?({ context }: { context: COContextInterface }) {
    if (this.co_question_options_json && this.co_question_options_json.chart) {
      for (const key of Object.keys(this.co_question_options_json.chart)) {
        COBase.updateQuestionVariableToAHIDFromCustomTemplate({
          context,
          parent: this.co_question_options_json.chart,
          propertyToUpdate: key
        });
      }
    }
  }

  objectMeta?(): COMetadataInterface {
    return this.co_question_meta_json || {};
  }
  objectOptions?(): COOptionsInterface {
    return this.co_question_options_json || {};
  }
  objectPosition?(): COPositionInterface {
    return this.co_question_position_json || {};
  }

  // has to take into account table row index for sorting or it gets super messed up
  sortOrder?(): number {
    return (
      (this.objectPosition?.().sort_order || 0) +
      (this.co_table_row_index || 0) * 100000000
    );
  }

  objectSearchName?(): string {
    return (
      super.objectSearchName?.() +
      (this.co_variable_name || "") +
      (this.co_display_equation || "")
    );
  }

  updateParent?(co_object: COBaseInterface) {
    this.co_question_parent_ahid = COQuestion.ahid(co_object);
  }

  // we should ideally call this after they initially set the title - like after so any characters
  generateSlugsAndKeys?(context: COContextInterface) {
    let variable_name = "";
    if (this.co_question_meta_json?.title?.value) {
      let title = this.co_question_meta_json?.title?.value;
      if (title.length > 2) {
        variable_name = title.replace(/[ ]+/g, "_");
        variable_name = variable_name.replace(/[\W]+/g, "");
        variable_name = variable_name.toUpperCase();
        variable_name = variable_name.replace(/[\d]+/g, "");
        // we need this to be unique in the context;
        let foundUnique = false;
        if (context && context.assessment) {
          if (context.assessment.co_assessment_sections) {
            foundUnique = true;
            for (const section of context.assessment.co_assessment_sections) {
              if (section && section.co_questions) {
                for (const question of section.co_questions) {
                  if (question.co_variable_name === variable_name) {
                    foundUnique = false;
                  }
                }
              }
            }
          }
        }
        if (!foundUnique) {
          variable_name = variable_name + "_" + coRandomVar();
        }
      }
    }
    if (!this.co_variable_name && variable_name) {
      this.co_variable_name = variable_name;
    }
    this.updateCalculatedValueKey?.(context);
  }

  customValidators?() {
    let unitTarget = this.options?.unit;
    let unit_validators;
    if (unitTarget) {
      let resolvedUnitTarget = getValue(
        COQuestion.setContext(undefined, this),
        unitTarget
      );
      if (resolvedUnitTarget) {
        unit_validators = resolvedUnitTarget.validations;
      }
    }
    let validators = [...(unit_validators || [])];
    // these are custom validators for the equation and variables
    validators.push(variableValidator());
    validators.push(variableDuplicateCheckValidator());
    validators.push(variableChangeValidator());
    validators.push(variableUpdateCalculatedValueKeyValidator()); // this should be last for variable_name validators

    if (this.co_question_co_type === COTypes.KPI) {
      validators.push(equationTransformValidator());
      validators.push(equationValidator());
      validators.push(equationReferenceValidator());
    }
    return validators;
  }

  updateRawEquation?({
    context,
    displayEquation
  }: {
    context: COContextInterface;
    displayEquation?: string;
  }) {
    let parsedEquation = parseDisplayEquation({
      context,
      question: this,
      displayEquation: displayEquation
    });
    if (
      parsedEquation.validation_errors &&
      parsedEquation.validation_errors.length > 0
    ) {
      // we only want to show the error if we really needed to update the raw equation
      if (!this.co_equation) {
        console.log(
          "errors parsing display to raw equation - possibly due to permissions"
        );
        console.log(parsedEquation.validation_errors);
      }
    }
    if (parsedEquation && !isNullOrUndefined(parsedEquation.rawEquation)) {
      this.co_equation = parsedEquation.rawEquation;
    }
  }

  updateDisplayEquation?({
    context,
    rawEquation
  }: {
    context: COContextInterface;
    rawEquation?: string;
  }) {
    let parsedEquation = parseRawEquation({
      context,
      question: this,
      rawEquation: rawEquation
    });
    if (parsedEquation && !isNullOrUndefined(parsedEquation.displayEquation)) {
      this.co_display_equation = parsedEquation.displayEquation;
    }
  }

  initializeNewProcessAnswer?({ context }: { context?: COContextInterface }) {
    this.co_process_answer = new COProcessAnswer({
      context,
      question: this
    });
  }

  // clear calculated values
  static prepareForCalculation(
    context: COContextInterface,
    question: COQuestion
  ) {
    super.prepareForCalculation(context, question);
    question.co_process_calculated_value = undefined;
  }

  functionQuestionBranchingControls?(
    context: COContextInterface
  ): COControlInterface[] {
    return questionBranchingControls(context);
  }

  functionQuestionsThatCanImpactKPISControlOptions?(
    context: COContextInterface
  ) {
    let currentQuestion: COQuestion | undefined = context.question;
    let questionControls: COControlOptionListItem[] = [];
    let assessment: COAssessmentInterface | undefined = context.assessment;
    for (const section of assessment?.co_assessment_sections || []) {
      for (const question of section.co_questions || []) {
        let questionContext = context.update?.({ question }) || context;
        const resolvedOptionsInContext: COOptionsInterface = optionsInContext({
          context: questionContext,
          options: question.options || {},
          should_resolve: true
        });
        if (
          currentQuestion?.co_question_ahid !== question.co_question_ahid &&
          resolvedOptionsInContext.can_impact_kpi
        ) {
          questionControls.push({
            key: question.co_question_ahid,
            value: question.co_question_ahid,
            meta: {
              value: question.co_variable_name
            }
          });
        }
      }
    }
    return questionControls;
  }

  calculate?(
    context: COContextInterface,
    throwExceptionOnCalculationError = false
  ): any[] {
    context = COQuestion.setContext(context, this);
    let calculatedQuestions: any[] = [];

    this.co_process_calculated_value =
      COCalculatedValue.calculateForQuestion(
        this,
        context,
        throwExceptionOnCalculationError
      ) || undefined;

    if (this.co_process_calculated_value) {
      calculatedQuestions.push(this);
    }
    return calculatedQuestions;
  }

  defaultValueInContext?(context: COContextInterface): any {
    if (
      !isNullOrUndefined(
        this.co_question_default_options_json?.default_equation_value
      )
    ) {
      return getValue(
        context,
        this.co_question_default_options_json?.default_equation_value
      );
    }
    return undefined;
  }

  processAnswerSelectionsForOption?(
    context: COContextInterface,
    answerOption: COQuestionAnswerOption
  ): COProcessAnswerSelection[] {
    return (
      this.co_process_answer?.co_process_answer_selections?.filter(
        pas =>
          pas.co_question_answer_option_ahid ===
          answerOption.co_question_answer_option_ahid
      ) || []
    );
  }

  answerOptionsForContext?(
    context: COContextInterface
  ): COQuestionAnswerOption[] {
    // do we want to see the answers we've selected or all the options
    // really don't like this hardcoded nature
    let contextCheck: CODisplayContext = {
      assessment_view: 1
    };

    if (doesExistPositiveContextMatch(context.display_context, contextCheck)) {
      if (
        this.co_process_answer &&
        this.co_process_answer.co_process_answer_selections
      ) {
        let questionAnswerOptions: COQuestionAnswerOption[] = [];
        for (const answer_selected of this.co_process_answer
          .co_process_answer_selections) {
          if (
            answer_selected.co_question_answer_option &&
            !questionAnswerOptions.find(
              qao => qao == answer_selected.co_question_answer_option
            ) // we don't want to render duplicate question answer options we can have multiple process_answer_selections pointing to the same answer option
          ) {
            questionAnswerOptions.push(
              answer_selected.co_question_answer_option
            );
          }
        }
        return questionAnswerOptions;
      }
      return []; // nothing selected
    }

    if (this.co_question_answer_options) {
      return this.co_question_answer_options;
    }
    return [];
  }

  // used in control via path system - do not change name unless updating templates
  // can't use this because of how the function is called
  functionKPIReferencedControlOptions?(context: COContextInterface) {
    let question: COQuestion | undefined = context.question;
    let referenced = kpisWithEquationReferencingQuestion({
      context,
      question: question || {}
    });
    let all = allKPIsInContext({ context });
    let kpis: COQuestion[] = [...new Set([...referenced, ...all])];
    if (!isNullOrUndefined(kpis)) {
      return kpis
        .map(kpi => {
          return {
            key: kpi.co_question_ahid,
            value: kpi,
            checked: !!referenced.find(rkpi => rkpi === kpi),
            meta: {
              value: getValue(
                context.update?.({ question: kpi }) || context,
                kpi.meta?.title?.value
              )
            }
          };
        })
        .filter(kpi => kpi.key !== question?.co_question_ahid); //filter out current question
    }
  }

  // used in control via path system - do not change name unless updating templates
  functionOtherQuestionsControlOptions?(context: COContextInterface) {
    let currentQuestion: COQuestion | undefined = context.question;
    let questionControls: COControlOptionListItem[] = [];
    let assessment: COAssessmentInterface | undefined = context.assessment;
    for (const section of assessment?.co_assessment_sections || []) {
      for (const question of section.co_questions || []) {
        if (currentQuestion?.co_question_ahid !== question.co_question_ahid) {
          //don't pick self
          questionControls.push({
            key: question.co_question_ahid,
            value: question.co_question_ahid,
            meta: {
              value: question.co_variable_name
            }
          });
        }
      }
    }
    return questionControls;
  }

  // used in control via path system
  functionQuestionAnswersControlOptions?(context: COContextInterface) {
    const currentQuestion: COQuestion | undefined = context.question;
    const answerControls: COControlOptionListItem[] =
      currentQuestion?.co_question_answer_options?.map(option => {
        return {
          key: option.co_question_answer_option_ahid,
          value: option.co_question_answer_option_ahid,
          meta: {
            value: option.co_question_answer_option_meta_json?.title?.value
          }
        };
      }) || [];
    return answerControls;
  }

  // used in control via path system - do not change name unless updating templates
  functionOtherQuestionsVariablesDropdownOptions?(context: COContextInterface) {
    const otherQuestionsWithVariables = getOtherQuestionsInAssessmentWithVariablesThatCanImpactKpi(
      context
    );
    return (otherQuestionsWithVariables || []).map(
      q => q?.co_variable_name || ""
    );
  }

  // used in conditions
  functionQuestionIsAnswered?(context: COContextInterface): boolean {
    return isQuestionAnswered({ context });
  }

  setNewTemplate?(
    context: COContextInterface,
    questionTemplate: COQuestionTemplateInterface
  ) {
    this.template = questionTemplate;
    this.setRawValues?.(context, questionTemplate.co_question_json);
  }

  cache?: any;

  setRawValues?(context: COContextInterface, rawQuestionValues: any) {
    if (!rawQuestionValues) {
      throw new Error("Question Constructor Requires Raw Values");
    }
    let questionTemplateDeepCopy = objectDeepCopy(rawQuestionValues);
    let keys = Object.keys(questionTemplateDeepCopy);
    //assign keys from template
    for (const key of keys) {
      this[key] = questionTemplateDeepCopy[key];
    }

    this.co_question_answer_options = [];
    let answerOptions: COQuestionAnswerOption[] | undefined =
      rawQuestionValues.co_question_answer_options;
    if (answerOptions) {
      for (const answerOptionRaw of answerOptions) {
        addAnswerOption({
          context,
          question: this,
          questionAnswerOptionRaw: answerOptionRaw
        });
      }
    }
  }
}

export interface COQuestionTemplate extends COQuestionTemplateInterface {}
export class COQuestionTemplate extends COBaseTemplate {
  static getClassName(): string {
    return "COQuestionTemplate";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [
        {
          objectKey: "co_question_answer_option_templates",
          objectClass: COQuestionAnswerOptionTemplate
        }
      ],
      objects: [
        {
          objectKey: "co_question_json",
          objectClass: COQuestion
        }
      ],
      dbClass: COQuestionTemplateDBClass,
      objectClass: COQuestionTemplate
    };
  }

  // static questionTemplatesForForQuestion({
  //   context,
  //   question
  // }: {
  //   context: COContextInterface;
  //   question: COQuestion;
  // }) {
  //   return questionTemplateForSlug(question.co_question_template_slug);
  // }

  calculate?(context: COContextInterface): any[] {
    return [];
  }
}

export const addQuestion = ({
  context,
  section,
  question,
  questionTemplate,
  rawQuestionValues,
  atIndex,
  reCalculatePosition = true
}: {
  context: COContextInterface;
  section: COSectionInterface;
  question?: COQuestionInterface;
  questionTemplate?: COQuestionTemplateInterface;
  rawQuestionValues?: any;
  atIndex?: number;
  reCalculatePosition?: boolean;
}): COQuestionInterface => {
  if (!section && !questionTemplate && !rawQuestionValues && !question) {
    console.log("question error");
    throw new Error("Add Question Requires a Question Object or a Template");
  }
  if (!section.co_questions) {
    section.co_questions = [];
  }
  if (!question) {
    question = new COQuestion({
      context: context,
      template: questionTemplate,
      raw: rawQuestionValues,
      parentCOObject: section
    });
  } else {
    question.updateParent?.(section);
  }

  // is there a better place/way to do this?
  question.co_question_parent_ahid = section.co_assessment_section_ahid;
  if (typeof atIndex === "number") {
    section?.co_questions?.splice(atIndex, 0, question);
  } else {
    section.co_questions?.push(question);
  }

  if (reCalculatePosition) {
    updatePositionSortOrderForItems(section.co_questions);
  }

  return question;
};

export const removeQuestion = (
  context: COContextInterface,
  section: COSectionInterface,
  question: COQuestionInterface
) => {
  if (section.co_questions) {
    section.co_questions = section.co_questions?.filter(
      existing => existing.co_question_ahid !== question.co_question_ahid
    );
    updatePositionSortOrderForItems(section.co_questions);
  }

  const questionContext = updateContext(context, {
    section: section,
    question: question
  });
  question.onRemoveFromAssessment?.(questionContext);
};
