import React from "react";
import { connect } from "react-redux";
import { hideModal } from "../../actions/modalActions";
import ModalAddUpdateDocument from "./ModalAddUpdateDocument/ModalAddUpdateDocument";
import ModalImageUploader from "./ModalImageUploader/ModalImageUploader";
import ModalLogoUploader from "./ModalLogoUploader/ModalLogoUploader";
import ModalApproveIdea from "./ModalApproveIdea/ModalApproveIdea";
import ModalRejectIdea from "./ModalRejectIdea/ModalRejectIdea";
import ModalDeleteIdea from "./ModalDeleteIdea/ModalDeleteIdea";
import ModalApproveQualification from "./ModalApproveQualification/ModalApproveQualification";
import ModalImportEditDocumentTemplate from "./ModalImportEditDocumentTemplate/ModalImportEditDocumentTemplate";
import ModalReportAutomationAbuse from "./ModalReportAbuse/ModalReportAutomation";
import ModalReportCommentAbuse from "./ModalReportAbuse/ModalReportComment";
import ModalReportReviewAbuse from "./ModalReportAbuse/ModalReportReview";
import ModalReportComponentBug from "./ModalReportAbuse/ModalReportComponentBug";
import ModalReportComponentAbuse from "./ModalReportAbuse/ModalReportComponentAbuse";
import RejectComponent from "./RejectComponent/RejectComponent";
import ModalComponentTypes from "./ModalComponentTypes/ModalComponentTypes";
import ModalComponentVersionNotes from "./ModalComponentVersionNotes/ModalComponentVersionNotes";
import ModalDownloadTemplatePeople from "./ModalDownloadTemplatePeople/ModalDownloadTemplatePeople";
import ModalChangeAccountPlan from "./ModalAccountLicense/ModalChangeAccountPlan/ModalChangeAccountPlan";
import ModalExtendAccountTrial from "./ModalAccountLicense/ModalExtendAccountTrial/ModalExtendAccountTrial";
import ModalRequestRenewal from "./ModalAccountLicense/ModalRequestRenewal/ModalRequestRenewal";

/** Modal Type Constants */
import {
  ADD_DOCUMENT_MODAL,
  UPDATE_DOCUMENT_MODAL,
  IMAGE_UPLOADER_MODAL,
  LOGO_UPLOADER_MODAL,
  CHECK_FOR_PROCESS_DUPLICATES_MODAL,
  APPROVE_IDEAS,
  REJECT_IDEAS,
  APPROVE_QUALIFICATION,
  DELETE_AUTOMATION,
  ADMIN_IMPORT_TEMPLATE_MODAL,
  ADMIN_UPDATE_TEMPLATE_MODAL,
  REJECT_COMPONENT_MODAL,
  REPORT_AUTOMATION_ABUSE_MODAL,
  REPORT_COMMENT_ABUSE_MODAL,
  REPORT_REVIEW_ABUSE_MODAL,
  REPORT_COMPONENT_BUG_MODAL,
  REPORT_COMPONENT_ABUSE_MODAL,
  COMPONENT_TYPES_MODAL,
  COMPONENT_VERSION_NOTES_MODAL,
  DOWNLOAD_TEMPLATE_PEOPLE_MODAL,
  CHANGE_ACCOUNT_PLAN_MODAL,
  EXTEND_ACCOUNT_PLAN_MODAL,
  REQUEST_RENEWAL_MODAL,
  STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL,
  STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL,
  STATUS_PIPELINE_GENERIC_MODAL,
  CREATE_RENAME_SAVE_VIEW_MODAL
} from "../../constants/modalTypes";
import ModalPipelineGenericWithCheckboxList from "./automationPipelineModals/ModalPipelineGenericWithCheckboxList/ModalPipelineGenericWithCheckboxList";
import ModalPipelineGenericWithRadioButtons from "./automationPipelineModals/ModalPipelineGenericWithRadioButtons/ModalPipelineGenericWithRadioButtons";
import ModalPipelineGeneric from "./automationPipelineModals/ModalPipelineGeneric/ModalPipelineGeneric";
import ModalCreateRenameSaveView from "./ModalCreateRenameSaveView/ModalCreateRenameSaveView";

const MODAL_COMPONENTS = {
  [ADD_DOCUMENT_MODAL]: ModalAddUpdateDocument,
  [UPDATE_DOCUMENT_MODAL]: ModalAddUpdateDocument,
  [IMAGE_UPLOADER_MODAL]: ModalImageUploader,
  [LOGO_UPLOADER_MODAL]: ModalLogoUploader,
  [APPROVE_IDEAS]: ModalApproveIdea,
  [REJECT_IDEAS]: ModalRejectIdea,
  [DELETE_AUTOMATION]: ModalDeleteIdea,
  [APPROVE_QUALIFICATION]: ModalApproveQualification,
  [ADMIN_IMPORT_TEMPLATE_MODAL]: ModalImportEditDocumentTemplate,
  [ADMIN_UPDATE_TEMPLATE_MODAL]: ModalImportEditDocumentTemplate,
  [REPORT_AUTOMATION_ABUSE_MODAL]: ModalReportAutomationAbuse,
  [REPORT_COMMENT_ABUSE_MODAL]: ModalReportCommentAbuse,
  [REPORT_REVIEW_ABUSE_MODAL]: ModalReportReviewAbuse,
  [REPORT_COMPONENT_BUG_MODAL]: ModalReportComponentBug,
  [REPORT_COMPONENT_ABUSE_MODAL]: ModalReportComponentAbuse,
  [REJECT_COMPONENT_MODAL]: RejectComponent,
  [COMPONENT_TYPES_MODAL]: ModalComponentTypes,
  [COMPONENT_VERSION_NOTES_MODAL]: ModalComponentVersionNotes,
  [DOWNLOAD_TEMPLATE_PEOPLE_MODAL]: ModalDownloadTemplatePeople,
  [CHANGE_ACCOUNT_PLAN_MODAL]: ModalChangeAccountPlan,
  [EXTEND_ACCOUNT_PLAN_MODAL]: ModalExtendAccountTrial,
  [REQUEST_RENEWAL_MODAL]: ModalRequestRenewal,
  [STATUS_PIPELINE_GENERIC_MODAL]: ModalPipelineGeneric,
  [STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL]: ModalPipelineGenericWithCheckboxList,
  [STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL]: ModalPipelineGenericWithRadioButtons,
  [CREATE_RENAME_SAVE_VIEW_MODAL]: ModalCreateRenameSaveView
};

const ModalContainer = props => {
  if (!props.modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[props.modalType];

  return (
    <SpecificModal
      type={props.modalType}
      closeModal={props.closeModal}
      {...(props.modalProps || {})}
    />
  );
};

const mapStateToProps = state => {
  return {
    modalType: state.modal.modalType,
    modalProps: state.modal.modalProps
  };
};
const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
