import {
  FETCH_EXPLORE_PEOPLE_DATA,
  CLEAR_EXPLORE_PEOPLE_DATA,
  SET_EXPLORE_PEOPLE_IS_LOADING_DATA,
  FETCH_EXPLORE_PEOPLE_FILTERS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  explorePeopleData: null,
  isLoadingExplorePeople: false,
  userFilters: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_EXPLORE_PEOPLE_DATA:
      return {
        ...state,
        explorePeopleData: action.payload
      };
    case FETCH_EXPLORE_PEOPLE_FILTERS:
      return {
        ...state,
        userFilters: action.payload
      };
    case CLEAR_EXPLORE_PEOPLE_DATA:
      return {
        ...state,
        explorePeopleData: null
      };
    case SET_EXPLORE_PEOPLE_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingExplorePeople: action.payload
      };
    default:
      return state;
  }
}
