export interface RawDraftJSInterface {
  blocks?: RawDraftJSBlockInterface[];
  entityMap?: { [key: string]: RawDraftJSEntityMapInterface };
}

interface RawDraftJSBlockInterface {
  data?: any;
  depth?: number;
  entityRanges?: RawDraftJSEntityRangeInterface[];
  inlineStyleRanges: {
    length: number;
    offset: number;
    style: InlineStyleRangeStylesEnum;
  }[];
  key?: string;
  text?: string;
  type: BlockTypeEnum;
}

interface RawDraftJSEntityRangeInterface {
  offset?: number;
  length?: number;
  key?: string;
}

interface RawDraftJSEntityMapInterface {
  type?: string;
  mutability?: string;
  data?: RawDraftJSEntityMapDataInterface;
}

interface RawDraftJSEntityMapDataInterface {
  url?: string;
}

export interface DraftJSUrlWithPosition {
  offset: number;
  length: number;
  url: string;
}

export enum BlockTypeEnum {
  UNSTYLED = "unstyled",
  HEADER_ONE = "header-one",
  HEADER_TWO = "header-two",
  HEADER_THREE = "header-three",
  UNORDERED_LIST_ITEM = "unordered-list-item",
  ORDERED_LIST_ITEM = "ordered-list-item",
  CODE_BLOCK = "code-block"
}

export enum InlineStyleRangeStylesEnum {
  LINETHROUGH = "LINETHROUGH",
  ITALIC = "ITALIC",
  UNDERLINE = "UNDERLINE",
  BOLD = "BOLD"
}
