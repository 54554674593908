import React, { Component } from "react";
import "./SingleReleaseNoteCard.scss";
import ComponentVersionBadge from "../ComponentVersionBadge/ComponentVersionBadge";

class SingleReleaseNoteCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  toggleCard = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  render() {
    const { versionHistory } = this.props;

    return (
      <div
        className="SingleReleaseNoteCard__gray-container"
        onClick={this.toggleCard}
        data-testid="SingleReleaseNoteCard"
      >
        <div className="SingleReleaseNoteCard__version-input-container">
          <div>
            <input
              className={
                "SingleReleaseNoteCard__version-name-input SingleReleaseNoteCard__read-only"
              }
              readOnly={true}
              type="text"
              value={`Version ${
                versionHistory.component_version
                  ? versionHistory.component_version
                  : versionHistory.semantic_version
                  ? versionHistory.semantic_version
                  : ""
              }`}
            />
          </div>
          <div className="SingleReleaseNoteCard__badge-wrapper">
            <ComponentVersionBadge
              className="SingleReleaseNoteCard__badge"
              readOnly
              componentRejectReason={versionHistory.component_reject_reason}
              componentStatusId={versionHistory.component_status_id}
            />
          </div>
        </div>
        {this.state.isOpen && (
          <div>
            <div className="SingleReleaseNoteCard__separator" />
            <textarea
              className={
                "SingleReleaseNoteCard__version-notes-input SingleReleaseNoteCard__read-only"
              }
              readOnly={true}
              value={
                versionHistory.release_notes &&
                versionHistory.release_notes.length > 0
                  ? versionHistory.release_notes
                  : "No release notes available"
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default SingleReleaseNoteCard;
