import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { hideModal } from "../../../actions/modalActions";
import {
  getAndDownloadAllUserCSV,
  generateEmptyUsersCSV
} from "./../../../utils/userHelper";
import {
  triggerAnalyticsWithDefaultData,
  DOWNLOAD_MASS_CHANGES_TEMPLATE_SUCCESS,
  DOWNLOAD_NEW_USERS_TEMPLATE_SUCCESS
} from "../../../utils/analyticsUtils";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import ButtonMain from "../../buttons/ButtonMain/ButtonMain";
import RadioButtonPrimary from "../../CheckboxComponents/RadioButtonPrimary/RadioButtonPrimary";

import "./ModalDownloadTemplatePeople.scss";
const PERFORM_MASS_CHANGES_VALUE = 1;
const ADD_NEW_USERS_VALUE = 2;

class ModalDownloadTemplatePeople extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRadio: PERFORM_MASS_CHANGES_VALUE
    };
  }

  closeModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  callAnalytics = () => {
    const eventName =
      this.state.selectedRadio === PERFORM_MASS_CHANGES_VALUE
        ? DOWNLOAD_MASS_CHANGES_TEMPLATE_SUCCESS
        : DOWNLOAD_NEW_USERS_TEMPLATE_SUCCESS;
    triggerAnalyticsWithDefaultData(eventName);
  };

  afterDownloadComplete = () => {
    this.callAnalytics();
  };

  handleConfirm = () => {
    const { selectedRadio } = this.state;

    if (selectedRadio === PERFORM_MASS_CHANGES_VALUE) {
      getAndDownloadAllUserCSV(
        null,
        null,
        {
          completionCallback: this.afterDownloadComplete
        },
        this.props.t("csv_filename_admin_users"),
        []
      );
    } else {
      generateEmptyUsersCSV(
        { completionCallback: this.afterDownloadComplete },
        this.props.t("csv_filename_admin_new_users_template")
      );
    }

    this.closeModal();
  };

  onRadioChangeChange = e => {
    e.preventDefault();
    const { value } = e.target;

    if (parseInt(value) !== this.state.selectedRadio) {
      this.setState({
        selectedRadio: parseInt(value)
      });
    }
  };

  render() {
    const modalContentStyle = {
      padding: "0px 24px 24px 24px",
      borderRadius: "8px",
      boxShadow: "0 8px 6px 0 rgba(0, 0, 0, 0.04)",
      width: "464px",
      borderBottom: "1px solid #eee"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={modalContentStyle}>
        <ModalTemplateOne
          heading={this.props.t("modal_download_people_template_heading")}
          onClose={this.closeModal}
          headerStyle={{ borderBottom: "0px" }}
        >
          <div
            className="ModalDownloadTemplatePeople"
            data-testid="ModalDownloadTemplatePeople"
          >
            <div className="ModalDownloadTemplatePeople__radio-buttons">
              <RadioButtonPrimary
                text={this.props.t(
                  "modal_download_people_template_perform_mass_changes"
                )}
                name="question_input_type_id"
                value={PERFORM_MASS_CHANGES_VALUE}
                isChecked={
                  PERFORM_MASS_CHANGES_VALUE === this.state.selectedRadio
                }
                onChange={this.onRadioChangeChange}
              />
              <RadioButtonPrimary
                text={this.props.t(
                  "modal_download_people_template_add_new_users"
                )}
                name="question_input_type_id"
                value={ADD_NEW_USERS_VALUE}
                isChecked={ADD_NEW_USERS_VALUE === this.state.selectedRadio}
                onChange={this.onRadioChangeChange}
              />
            </div>
            <div className="ModalDownloadTemplatePeople__buttons">
              <ButtonMain
                type="secondary"
                className="ModalDownloadTemplatePeople__button ModalDownloadTemplatePeople__cancel"
                clickHandler={this.closeModal}
              >
                {this.props.t("modal_download_people_template_cancel")}
              </ButtonMain>
              <ButtonMain
                className="ModalDownloadTemplatePeople__button ModalDownloadTemplatePeople__approve"
                type="primary"
                clickHandler={this.handleConfirm}
              >
                {this.props.t("modal_download_people_template_ok")}
              </ButtonMain>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  hideModal
};

export default withTranslation("common")(
  connect(null, mapDispatchToProps)(ModalDownloadTemplatePeople)
);
