import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";

import {
  FETCH_ALL_DOCUMENT_TEMPLATES,
  FETCH_ALL_DOCUMENT_TEMPLATE_TYPES,
  SET_DOCUMENT_TEMPLATE_TO_UPDATE,
  SEARCH_DOCUMENT_TEMPLATES,
  SET_SEARCH_QUERY,
  SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA
} from "../constants/actionTypes";

export function setIsLoadingToTrue() {
  return function(dispatch) {
    dispatch({
      type: SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA,
      payload: true
    });
  };
}

export function setIsLoadingToFalse() {
  return function(dispatch) {
    dispatch({
      type: SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA,
      payload: false
    });
  };
}

export function fetchAllDocumentTemplates(callback: any = undefined) {
  return function(dispatch) {
    axios
      .get(`/api/v1/admin/templates/`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_DOCUMENT_TEMPLATES,
          payload: response.data.data
        });

        if (callback && typeof callback === "function") {
          callback();
        }
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchAllDocumentTemplateTypes() {
  return function(dispatch) {
    axios
      .get(`/api/v1/filetypes`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_DOCUMENT_TEMPLATE_TYPES,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function addAdminDocumentTemplate(data, callback) {
  return function(dispatch) {
    axios
      .post(`/api/v1/admin/templates`, data)
      .then(response => {
        dispatch(fetchAllDocumentTemplates());
        if (typeof callback === "function") {
          const data =
            response && response.data && response.data.data
              ? response.data.data
              : {};
          callback(data);
        }
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function updateAdminDocumentTemplate(template_id, modifiedData) {
  return function(dispatch) {
    axios
      .put(`/api/v1/admin/templates/${template_id}`, modifiedData)
      .then(response => {
        dispatch(fetchAllDocumentTemplates());
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setDocumentTemplateToUpdate(documentTemplate) {
  return function(dispatch) {
    dispatch({
      type: SET_DOCUMENT_TEMPLATE_TO_UPDATE,
      payload: documentTemplate
    });
  };
}

export function deleteDocumentTemplate(data) {
  return function(dispatch) {
    axios
      .delete(`/api/v1/admin/templates/${data.template_id}`)
      .then(response => {
        dispatch(fetchAllDocumentTemplates());
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setOrRemoveDocumentTemplateAsDefault(template_id, value, cb) {
  swalSpinnerLoading({});
  return function(dispatch) {
    axios
      .put(`/api/v1/admin/templates/${template_id}`, {
        template_is_default: value
      })
      .then(response => {
        dispatch(fetchAllDocumentTemplates(cb));
      })
      .catch(error => {
        apiErrorAlert(error);
        swalSpinnerClose();
      });
  };
}

export function searchDocumentTemplates(query) {
  return function(dispatch) {
    dispatch(setIsLoadingToTrue());
    axios
      .get(`/api/v1/admin/templates/`)
      .then(response => {
        dispatch({
          type: SEARCH_DOCUMENT_TEMPLATES,
          payload: {
            templates: response.data.data,
            query: query
          }
        });
        dispatch(setIsLoadingToFalse());
      })
      .catch(error => {
        apiErrorAlert(error);
        dispatch(setIsLoadingToFalse());
      });
  };
}

export function setSearchQuery(query) {
  return function(dispatch) {
    dispatch({
      type: SET_SEARCH_QUERY,
      payload: query
    });
  };
}
