import React from "react";
import "./CellDownloadsMyComponents.scss";

const CellDownloadsMyComponents = props => {
  const { component_num_downloads } = props;

  return (
    <div
      className="CellDownloadsMyComponents"
      data-testid="CellDownloadsMyComponentsContainer"
    >
      <span className="CellDownloadsMyComponents__downloads-count">
        {component_num_downloads}
      </span>
    </div>
  );
};

export default CellDownloadsMyComponents;
