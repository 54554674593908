import {
  COAssessmentInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { COAssessmentTypes } from "../constants/co-constants";

export const calculatedValueKey = ({
  assessment,
  co_assessment_type,
  co_variable_name,
  question
}: {
  assessment?: COAssessmentInterface;
  co_assessment_type?: COAssessmentTypes;
  co_variable_name?: string;
  question?: COQuestionInterface;
}): string => {
  let assessmentType = co_assessment_type || "";
  if (assessment) {
    assessmentType = assessment?.co_assessment_type || "";
  }
  return `${assessmentType.toUpperCase()}-${co_variable_name ||
    question?.co_variable_name ||
    ""}`;
};
