import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import {
  fetchAllProcessDocumentTypes,
  addProcessDocument,
  updateProcessDocument,
  fetchAllProcessDocuments,
  setProcessDocumentToUpdate
} from "../../../actions/processProfileDocumentsActions";
import { uploadFile } from "../../../actions/fileTransferActions";
import { fetchPPPProfileData } from "../../../actions/processProfileActions";
import {
  ADD_DOCUMENT_MODAL,
  UPDATE_DOCUMENT_MODAL
} from "../../../constants/modalTypes";
import {
  PROCESS_DOCUMENT,
  ALLOWED_FILE_TYPES_DEFAULT,
  ALLOWED_FILE_TYPES_EXECUTABLE
} from "../../../constants/fileUploadConstants";
import swal from "../../SweetAlert/SweetAlert";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import AddDocumentDropBox from "./AddDocumentDropBox/AddDocumentDropBox";
import LabelDocumentUpload from "../../LabelDocumentUpload/LabelDocumentUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import { swalSpinnerClose } from "../../../utils/SweetAlertLoaderSpinner";
import "./ModalAddUpdateDocument.scss";
import { renderPrivacyTextComponent } from "../../../utils/reusableStaticComponents";
import TenantSettingHelper from "../../../helpers/TenantSettingsHelper";
import { getKeyValue } from "../../../utils/objectParsers";
import { validateFileLink } from "../../../utils/fileHelpers";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";
import ReactSelectApollo from "../../ReactSelectApollo/ReactSelectApollo";
import InputTextareaApollo from "../../inputs/InputTextareaApollo/InputTextareaApollo";
import { InputLabel } from "@material-ui/core";

const EMBED = "EMBED";
const UPLOAD = "UPLOAD";

class ModalAddUpdateDocument extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { document_type_id = "" } = props;
    const isFileUploadEnabled = TenantSettingHelper.showFileUploads();
    if (props.type === UPDATE_DOCUMENT_MODAL && props.processDocumentToUpdate) {
      this.state = {
        activeTab:
          !isFileUploadEnabled || props.processDocumentToUpdate.embed_link
            ? EMBED
            : UPLOAD,
        embed_link: props.processDocumentToUpdate.embed_link,
        fileData: props.processDocumentToUpdate.file
          ? { name: props.processDocumentToUpdate.file.originalname }
          : "",
        document_title: props.processDocumentToUpdate.document_title,
        document_description:
          props.processDocumentToUpdate.document_description,
        document_type_id: props.processDocumentToUpdate.document_type_id,
        submittingForm: false,
        isFileUploadEnabled: isFileUploadEnabled
      };
    } else {
      this.state = {
        activeTab: isFileUploadEnabled ? UPLOAD : EMBED,
        embed_link: "",
        fileData: null,
        document_title: "",
        document_description: "",
        document_type_id,
        submittingForm: false,
        isFileUploadEnabled: isFileUploadEnabled
      };
    }
  }

  componentDidMount() {
    this.props.fetchAllProcessDocumentTypes();
  }

  renderUpload = () => {
    if (!this.state.fileData) {
      return (
        <AddDocumentDropBox handleFileChange={this.handleFileChange}>
          <p className="ModalAddUpdateDocument__upload-text-top">
            {/* prettier-ignore */}
            <Trans t={this.props.t} i18nKey="modal_add_update_document_title">
              <span className="ModalAddUpdateDocument__upload-text-top--highlight" data-testid="ModalAddUpdateDocument__AddFileButton">Add file</span> or drop file
              here
            </Trans>
          </p>
          <p className="ModalAddUpdateDocument__upload-text-bottom">
            {this.props.allowedFileTypesMessage ||
            this.props.allowedFileTypesMetaItem ? (
              this.props.allowedFileTypesMessage ? (
                this.props.allowedFileTypesMessage
              ) : (
                <>
                  {this.props.t([
                    this.props.allowedFileTypesMetaItem
                      ?.value_localization_key || "",
                    this.props.allowedFileTypesMetaItem?.value
                  ])}
                </>
              )
            ) : (
              <>
                {this.props.t([
                  "modal_add_update_document_content",
                  "(Maximum upload file size: 200 MB)"
                ])}{" "}
                <br />
                {TenantSettingHelper.showExecutableFile()
                  ? this.props.t([
                      "modal_add_update_document_content_types",
                      "Allowed file types: jpg, jpeg, gif, png, txt, doc, docx, ppt, pptx, csv, xls, xlsx, xlsm, pdf,.ssp, .zip, .rar, .vsdx,.xaml, .nupkg, .bat, .bmp and .exe ."
                    ])
                  : this.props.t([
                      "modal_add_update_document_content_types_without_executable_files",
                      "Allowed file types: jpg, jpeg, gif, png, txt, doc, docx, ppt, pptx, csv, xls, xlsx, xlsm, pdf,.ssp, .zip, .rar, .vsdx,.xaml, .nupkg and .bmp."
                    ])}
              </>
            )}
          </p>
        </AddDocumentDropBox>
      );
    } else if (this.state.fileData && this.state.fileData.name) {
      return (
        <AddDocumentDropBox
          handleFileChange={this.handleFileChange}
          noClick={true}
          noStyles={true}
        >
          <div className="ModalAddUpdateDocument__chip-container">
            <LabelDocumentUpload
              fileName={this.state.fileData.name}
              handleClearFile={this.handleClearFile}
            />
          </div>
        </AddDocumentDropBox>
      );
    }
  };

  handleFieldChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => {
      const state = { ...prevState };
      if (name === "document_description" && value.length > 151) {
        return state;
      } else if (name === "document_title" && value.length > 46) {
        return state;
      } else {
        state[name] = value;
      }
      return state;
    });
  };

  handleFileChange = fileData => {
    const { useFileNameAsTitle = true } = this.props;
    const { document_title: currentDocumentTitle } = this.state;

    if (fileData) {
      let allowedFileTypes = ALLOWED_FILE_TYPES_DEFAULT;

      if (TenantSettingHelper.showExecutableFile()) {
        allowedFileTypes = allowedFileTypes.concat(
          ALLOWED_FILE_TYPES_EXECUTABLE
        );
      }

      if (this.props.allowedFileTypes) {
        allowedFileTypes = this.props.allowedFileTypes;
      }

      const fileExtension =
        fileData &&
        (fileData.name || "")
          .split(".")
          .pop()
          .toLowerCase();

      const isAllowed =
        fileExtension && (allowedFileTypes || []).indexOf(fileExtension) > -1;

      if (!isAllowed) {
        swal.fire({
          title: this.props.t(["modal_logo_uploader_error", "Error"]),
          text:
            this.props.t(["error_invalid_file_format", "Invalid file type."]) +
            " Allowed Types: " +
            (allowedFileTypes || []).join(","),

          type: "error"
        } as any);
        return;
      }
    }

    //200MB
    if (!this.props.maxAllowedFileSize && fileData.size > 209715200) {
      swal.fire({
        title: this.props.t(["error_title_file_too_large", "File too large"]),
        text: this.props.t([
          "error_text_file_too_large_200",
          "File must be less than 200MB"
        ]),
        type: "error"
      } as any);
      return false;
    }

    if (
      this.props.maxAllowedFileSize &&
      fileData.size > this.props.maxAllowedFileSize
    ) {
      swal.fire({
        title: this.props.t(["error_title_file_too_large", "File too large"]),
        type: "error"
      } as any);
      return false;
    }

    const updatedState: any = { fileData };

    if (useFileNameAsTitle && !currentDocumentTitle) {
      updatedState.document_title = fileData.name;
    }

    this.setState(updatedState);
  };

  handleClearFile = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileData: null });
  };

  renderTypeOptions = () => {
    const { documentTypes } = this.props;
    if (Object.keys(documentTypes).length === 0) {
      return [];
    } else {
      return documentTypes.data.map(obj => ({
        label: this.props.t([
          "file_type_be_" + obj.file_type_key,
          obj.file_type_name
        ]),
        value: obj.file_type_id
      }));
    }
  };

  renderEmbed = () => {
    return (
      <div className="ModalAddUpdateDocument__field">
        <InputTextApollo
          label={this.props.t([
            "modal_add_update_document_file_link",
            "File Link"
          ])}
          placeholder={this.props.t([
            "modal_add_update_document_insert_file_link",
            "Insert file link"
          ])}
          required
          InputProps={{
            "data-testid": "ModalAddUpdateDocument__FieldInput",
            name: "embed_link",
            value: this.state.embed_link,
            onChange: this.handleFieldChange
          }}
        />
      </div>
    );
  };

  closeModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.closeModal();
  };

  toggleTab = e => {
    if (e) {
      e.preventDefault();
    }
    let { tab } = e.target.dataset;
    this.setState({ activeTab: tab });
  };

  checkIfValid = () => {
    if (
      this.state.activeTab === UPLOAD &&
      this.state.fileData &&
      this.state.fileData.size &&
      this.state.document_title &&
      this.state.document_type_id
    ) {
      return true;
    }
    if (
      this.state.activeTab === UPLOAD &&
      this.props.type === UPDATE_DOCUMENT_MODAL &&
      this.state.fileData &&
      this.state.document_title &&
      this.state.document_type_id
    ) {
      return true;
    }
    if (
      this.state.activeTab === EMBED &&
      this.state.document_title &&
      this.state.embed_link &&
      this.state.document_type_id
    ) {
      return true;
    }
    return false;
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.checkIfValid()) {
      return false;
    }

    if (
      this.state.activeTab === EMBED &&
      typeof this.state.embed_link === "string" &&
      !validateFileLink(this.state.embed_link)
    ) {
      swal.fire({
        title: this.props.t([
          "modal_add_update_document_error_header",
          "Error"
        ]),
        text: this.props.t([
          "modal_add_update_document_https_link_error",
          "File link must start with http:// or https://"
        ])
      });
      return false;
    }

    this.setState({
      submittingForm: true
    });

    if (
      this.state.activeTab === UPLOAD &&
      this.state.fileData &&
      this.state.fileData.size
    ) {
      const params = {
        file: this.state.fileData,
        type: PROCESS_DOCUMENT
      };

      this.props.uploadFile(
        params,
        this.saveDocumentAfterUpload,
        error => {
          this.setState({
            submittingForm: false
          });
        },
        true
      );
    } else if (
      this.state.activeTab === UPLOAD &&
      this.props.type === UPDATE_DOCUMENT_MODAL &&
      this.state.fileData
    ) {
      const data = {
        processSlug: this.props.processProfileInfo.process_slug,
        document_id: this.props.processDocumentToUpdate.document_id,
        process_id: this.props.processProfileInfo.process_id,
        file_id: this.props.processDocumentToUpdate.file_id,
        document_title: this.state.document_title,
        document_description: this.state.document_description,
        embed_link: "",
        document_type_id: this.state.document_type_id
      };
      this.props.updateProcessDocument(
        data,
        this.cleanUpAfterDocumentSave,
        this.addUpdateProcessDocumentErrorCallback
      );
    } else if (this.state.activeTab === EMBED) {
      if (this.props.type === ADD_DOCUMENT_MODAL) {
        const data = {
          processSlug: this.props.processProfileInfo.process_slug,
          process_id: this.props.processProfileInfo.process_id,
          file_id: null,
          document_title: this.state.document_title,
          document_description: this.state.document_description,
          embed_link: this.state.embed_link,
          document_type_id: this.state.document_type_id
        };

        if (typeof this.props.handleAddDocument === "function") {
          this.props.handleAddDocument({
            documentData: data
          });
          return;
        }

        this.props.addProcessDocument(
          data,
          this.cleanUpAfterDocumentSave,
          this.addUpdateProcessDocumentErrorCallback
        );
      } else if (this.props.type === UPDATE_DOCUMENT_MODAL) {
        const data = {
          processSlug: this.props.processProfileInfo.process_slug,
          document_id: this.props.processDocumentToUpdate.document_id,
          process_id: this.props.processProfileInfo.process_id,
          file_id: null,
          document_title: this.state.document_title,
          document_description: this.state.document_description,
          embed_link: this.state.embed_link,
          document_type_id: this.state.document_type_id
        };
        this.props.updateProcessDocument(
          data,
          this.cleanUpAfterDocumentSave,
          this.addUpdateProcessDocumentErrorCallback
        );
      }
    }
  };

  addUpdateProcessDocumentErrorCallback = error => {
    this.setState({
      submittingForm: false
    });
  };

  saveDocumentAfterUpload = fileData => {
    const data: any = {
      processSlug: this.props.processProfileInfo.process_slug,
      process_id: this.props.processProfileInfo.process_id,
      file_id: fileData.file_id,
      document_title: this.state.document_title,
      document_description: this.state.document_description,
      embed_link: "",
      document_type_id: this.state.document_type_id,
      file: fileData,
      file_type:
        (getKeyValue(this, "props.documentTypes.data") || []).find(
          obj => (obj || {}).file_type_id === this.state.document_type_id
        ) || {}
    };

    if (this.props.type === ADD_DOCUMENT_MODAL) {
      if (typeof this.props.handleAddDocument === "function") {
        this.props.handleAddDocument({
          documentData: data
        });
        swalSpinnerClose();
        return;
      }

      this.props.addProcessDocument(
        data,
        this.cleanUpAfterDocumentSave,
        this.addUpdateProcessDocumentErrorCallback
      );
    } else if (this.props.type === UPDATE_DOCUMENT_MODAL) {
      data.document_id = this.props.processDocumentToUpdate.document_id;
      this.props.updateProcessDocument(
        data,
        this.cleanUpAfterDocumentSave,
        this.addUpdateProcessDocumentErrorCallback
      );
    }
  };

  cleanUpAfterDocumentSave = () => {
    this.props.closeModal();

    this.props.setProcessDocumentToUpdate(null);
    if (typeof this.props.afterDocumentAddedCallback === "function") {
      this.props.afterDocumentAddedCallback();
    } else {
      const params = {
        processId: this.props.processProfileInfo.process_slug
      };
      this.props.fetchPPPProfileData(params);
      const processSlug = this.props.processProfileInfo.process_slug;
      this.props.fetchAllProcessDocuments(processSlug);
    }
  };

  render() {
    const { type } = this.props;
    const { activeTab, isFileUploadEnabled } = this.state;
    let label =
      type === ADD_DOCUMENT_MODAL
        ? this.props.t(["modal_add_update_document_add_file_tab", "Add File"])
        : this.props.t([
            "modal_add_update_document_updated_file_tab",
            "Update File"
          ]);

    const typeOptions = this.renderTypeOptions();
    let uploadClasses = "ModalAddUpdateDocument__toggle";
    let embedClasses = "ModalAddUpdateDocument__toggle";

    if (activeTab === UPLOAD) {
      uploadClasses += " ModalAddUpdateDocument__toggle--active";
    } else {
      embedClasses += " ModalAddUpdateDocument__toggle--active";
    }

    const modalContentStyle = {
      borderRadius: "4px",
      width: "500px",
      maxWidth: "96%",
      padding: 0
    };

    return (
      <Modal onClose={() => {}} contentStyle={modalContentStyle}>
        <ModalTemplateOne
          heading={label}
          headerStyle={{ padding: "24px 30px 0" }}
          onClose={this.props.closeModal}
        >
          <form
            className="ModalAddUpdateDocument"
            onSubmit={this.handleSubmit}
            autoComplete="off"
            data-testid="ModalAddUpdateDocument"
          >
            <div className="ModalAddUpdateDocument__header">
              {isFileUploadEnabled && (
                <div className="ModalAddUpdateDocument__header-bottom">
                  <button
                    className={uploadClasses}
                    onClick={this.toggleTab}
                    data-tab={UPLOAD}
                  >
                    {this.props.t([
                      "modal_add_update_document_upload",
                      "Upload"
                    ])}
                  </button>

                  <button
                    data-testid="ModalAddUpdateDocument__Toggle"
                    className={embedClasses}
                    onClick={this.toggleTab}
                    data-tab={EMBED}
                  >
                    {this.props.t([
                      "modal_add_update_document_embed_link",
                      "Embed Link"
                    ])}
                  </button>
                </div>
              )}
            </div>
            <div className="ModalAddUpdateDocument__main">
              {activeTab === UPLOAD && isFileUploadEnabled
                ? this.renderUpload()
                : this.renderEmbed()}

              {renderPrivacyTextComponent({ t: this.props.t })}
              <div className="ModalAddUpdateDocument__field">
                <InputTextApollo
                  label={this.props.t([
                    "modal_add_update_document_add_title",
                    "Title"
                  ])}
                  placeholder={this.props.t([
                    "modal_add_update_document_add_title_placeholer",
                    "Name this file"
                  ])}
                  required
                  InputProps={{
                    "data-testid": "ModalAddUpdateDocument__FieldText",
                    name: "document_title",
                    value: this.state.document_title,
                    onChange: this.handleFieldChange
                  }}
                />
              </div>
              <label className="ModalAddUpdateDocument__field">
                <InputLabel>
                  {this.props.t([
                    "modal_add_update_document_file_type",
                    "File Type"
                  ])}
                </InputLabel>

                <div data-testid="AddUpdateDocumentSelect__FileTypeOptions">
                  <ReactSelectApollo
                    options={typeOptions}
                    value={typeOptions.find(
                      obj => obj.value === this.state.document_type_id
                    )}
                    onChange={e => {
                      this.setState({ document_type_id: e.value });
                    }}
                    placeholder={this.props.t(
                      "modal_add_update_document_select_file_placeholder",
                      "Select File Type"
                    )}
                    isDisabled={this.props.isFileTypeDisabled}
                    styles={{
                      menuList: base => ({
                        ...base,
                        maxHeight: "150px"
                      })
                    }}
                  />
                </div>
              </label>
              <div className="ModalAddUpdateDocument__field">
                <InputTextareaApollo
                  label={this.props.t([
                    "modal_add_update_document_description",
                    "Description (Optional)"
                  ])}
                  placeholder={this.props.t(
                    "modal_add_update_document_placeholder"
                  )}
                  InputProps={{
                    name: "document_description",
                    value: this.state.document_description,
                    onChange: this.handleFieldChange,
                    "data-testid": "ModalAddUpdateDocument__FieldInput"
                  }}
                  //inputProps={{}}
                />
              </div>
            </div>
            <div className="ModalAddUpdateDocument__footer">
              <ButtonApolloSecondary
                onClick={this.closeModal}
                data-testid="ModalAddUpdateDocument__CancelButton"
              >
                {this.props.t([
                  "automation_profile_documentation_cancel_button",
                  "Cancel"
                ])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                data-testid="ModalAddUpdateDocument__SaveButton"
                className="ModalAddUpdateDocument__footer-save-btn"
                type="submit"
                disabled={
                  !this.checkIfValid() || this.state.submittingForm
                    ? true
                    : false
                }
              >
                {this.state.submittingForm ? (
                  <CircularProgress
                    style={{ color: "gray", width: "15px", height: "15px" }}
                  />
                ) : (
                  label
                )}
              </ButtonApolloPrimary>
            </div>
          </form>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  type: state.modal.modalType,
  documentTypes: state.processProfileDocuments.documentTypes,
  processDocumentToUpdate: state.processProfileDocuments.processDocumentToUpdate
});

const mapActionsToProps = {
  fetchAllProcessDocumentTypes,
  uploadFile,
  addProcessDocument,
  updateProcessDocument,
  fetchPPPProfileData,
  fetchAllProcessDocuments,
  setProcessDocumentToUpdate
};

export default withTranslation("common")(
  connect(mapStateToProps, mapActionsToProps)(ModalAddUpdateDocument)
);
