import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import UploadEditComponentUploadSection from "../../../pages/UploadEditComponentPage/UploadEditComponentUploadSection/UploadEditComponentUploadSection";
import { validateFileLink } from "../../../utils/fileHelpers";
import "./ModalComponentVersionNotes.scss";
import UploadEditComponentVersionContainer from "../../../pages/UploadEditComponentPage/UploadEditComponentVersionContainer/UploadEditComponentVersionContainer";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";

class ModalComponentVersionNotes extends Component {
  constructor(props) {
    super(props);
    if (this.props.showEmptyForm) {
      this.state = {
        component_file: [],
        component_source_code: [],
        component_version_name: "",
        component_version_release_notes: "",
        component_file_link: "",
        component_source_code_link: ""
      };
    } else {
      this.state = {
        component_file: this.props.componentFiles || [],
        component_source_code: this.props.componentSourceCode || [],
        component_version_name: this.props.componentVersionName || "",
        component_version_release_notes:
          this.props.componentVersionReleaseNotes || "",
        component_file_link: this.props.componentFilesLink || "",
        component_source_code_link: this.props.componentSourceCodeLink || ""
      };
    }
  }

  setDataArray = dataObj => {
    const { field, data } = dataObj;
    this.setState({
      [`component_${field}`]: [...data],
      [`component_${field}_error`]: ""
    });
  };

  handleVersionNameChange = e => {
    this.setState({
      component_version_name: e.target.value,
      component_version_error: ""
    });
  };

  handleVersionReleaseNotes = e => {
    this.setState({
      component_version_release_notes: e.target.value,
      component_version_error: ""
    });
  };

  hasValidationErrors = () => {
    const submitErrorsObj = {};
    if (
      (!this.state.component_source_code ||
        this.state.component_source_code?.length === 0 ||
        !this.state.component_source_code[0]?.file?.file_id) &&
      !this.state.component_source_code_link
    ) {
      submitErrorsObj.component_source_code_error = this.props.t([
        "modal_component_version_notes_error_source_code_required",
        "Please upload component source code"
      ]);
    }
    if (
      !!this.state.component_source_code_link &&
      !validateFileLink(this.state.component_source_code_link)
    ) {
      submitErrorsObj.component_source_code_error = this.props.t(
        "modal_component_https_link_error",
        "File link must start with http:// or https://"
      );
    }

    if (
      (!this.state.component_file ||
        this.state.component_file?.length === 0 ||
        !this.state.component_file[0]?.file?.file_id) &&
      !this.state.component_file_link
    ) {
      submitErrorsObj.component_file_error = this.props.t([
        "modal_component_version_notes_error_component_file_required",
        "Please upload component file"
      ]);
    }

    if (
      !!this.state.component_file_link &&
      !validateFileLink(this.state.component_file_link)
    ) {
      submitErrorsObj.component_file_error = this.props.t(
        "modal_component_https_link_error",
        "File link must start with http:// or https://"
      );
    }

    if (
      !/\S/.test(this.state.component_version_name) ||
      !/\S/.test(this.state.component_version_release_notes)
    ) {
      submitErrorsObj.component_version_error = this.props.t(
        "modal_component_version_notes_error_version_name_required"
      );
    }

    if (!/^\d+(.\d+)*$/.test(this.state.component_version_name)) {
      submitErrorsObj.component_version_error = this.props.t(
        "modal_component_version_notes_error_version_name_invalid"
      );
    }

    if (Object.keys(submitErrorsObj).length > 0) {
      this.setState(submitErrorsObj);
      return true;
    }

    return false;
  };

  handleSubmitNewVersion = () => {
    if (this.hasValidationErrors()) return;
    const data = {
      component_version: this.state.component_version_name,
      release_notes: this.state.component_version_release_notes,
      component_source_code_file_id: this.state.component_source_code[0]?.file
        ?.file_id,
      component_file_id: this.state.component_file[0]?.file?.file_id,
      component_file_link: this.state.component_file_link,
      component_source_code_link: this.state.component_source_code_link
    };

    this.props.handleSubmitNewVersion(data);
  };

  handleFileLinkChange = e => {
    this.setState({
      component_file_link: e.target.value
    });
  };

  handleSourceCodeLinkChange = e => {
    this.setState({
      component_source_code_link: e.target.value
    });
  };

  handleSubmitFilesAndVersionNotes = () => {
    if (this.hasValidationErrors()) return;
    const data = {
      component_version_name: this.state.component_version_name,
      component_version_release_notes: this.state
        .component_version_release_notes,
      component_source_code: this.state.component_source_code,
      component_file: this.state.component_file,
      component_file_link: this.state.component_file_link,
      component_source_code_link: this.state.component_source_code_link
    };

    this.props.getInfoFromUploadModal(data);
  };

  render() {
    const { closeModal } = this.props;

    let preloadVersionData = {};
    if (!this.props.showEmptyForm) {
      preloadVersionData = {
        component_version_release_notes:
          this.props.componentVersionReleaseNotes || "",
        component_version_name: this.props.componentVersionName || ""
      };
    }

    return (
      <Modal
        onClose={closeModal}
        contentStyle={{ maxHeight: "90vh", width: "750px", overflowY: "auto" }}
        disableOverlayClick={true}
      >
        <ModalTemplateOne
          heading={this.props.header}
          onClose={closeModal}
          headerStyle={{ padding: "12px 28px 15px" }}
          exitBtnStyle={{ top: "12px" }}
        >
          <div
            className="ModalComponentVersionNotes"
            data-testid="ModalComponentVersionNotes"
          >
            <UploadEditComponentUploadSection
              componentFileError={this.state.component_file_error}
              componentFiles={this.state.component_file}
              componentFilesLink={this.state.component_file_link}
              componentSourceCodeError={this.state.component_source_code_error}
              componentSourceCode={this.state.component_source_code}
              componentSourceCodeLink={this.state.component_source_code_link}
              handleFileLinkChange={this.handleFileLinkChange}
              handleSourceCodeLinkChange={this.handleSourceCodeLinkChange}
              setDataArray={this.setDataArray}
            />

            <UploadEditComponentVersionContainer
              handleVersionReleaseNotes={this.handleVersionReleaseNotes}
              handleVersionNameChange={this.handleVersionNameChange}
              handleSubmitVersionNotes={this.handleSubmitVersionNotes}
              release_notes={this.state.component_version_release_notes}
              version_name={this.state.component_version_name}
              versionError={this.state.component_version_error}
              isEdit={true}
              latest_published_version={this.props.latest_published_version}
              slug={this.props.slug}
              hideLatest={false}
              showReleaseNoteBtn={this.props.showReleaseNoteBtn}
              preloadVersionData={preloadVersionData}
              componentStatusId={this.props.componentStatusId}
            />

            {this.props.isEdit ? (
              <div
                className="ModalComponentVersionNotes__action-buttons"
                data-testid="ModalComponentVersionNotes__ActionButtons"
              >
                <ButtonApolloSecondary onClick={closeModal}>
                  {this.props.t([
                    "modal_component_version_notes_cancel",
                    "Cancel"
                  ])}
                </ButtonApolloSecondary>
                <ButtonApolloPrimary onClick={this.handleSubmitNewVersion}>
                  {this.props.t([
                    "modal_component_version_notes_save_as_draft",
                    "Save as Draft"
                  ])}
                </ButtonApolloPrimary>
              </div>
            ) : (
              <div
                className="ModalComponentVersionNotes__action-buttons"
                data-testid="ModalComponentVersionNotes__ActionButtons"
              >
                <ButtonApolloSecondary
                  data-testid="ModalComponentVersionNotes__CancelButton"
                  onClick={() => this.props.closeHandler()}
                >
                  {this.props.t([
                    "modal_component_version_notes_cancel",
                    "Cancel"
                  ])}
                </ButtonApolloSecondary>
                <ButtonApolloPrimary
                  data-testid="ModalComponentVersionNotes__SaveButton"
                  onClick={this.handleSubmitFilesAndVersionNotes}
                >
                  {this.props.t(["modal_component_version_notes_save", "Save"])}
                </ButtonApolloPrimary>
              </div>
            )}
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}
export default withTranslation("common")(ModalComponentVersionNotes);
