import {
  COProcessSubmissionType,
  COAssessmentTypes
} from "../constants/co-constants";
import { CustomQuestionSlugs } from "../constants/co-question.constants";
import {
  COContextInterface,
  COProcess,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { answerQuestion } from "./co-question.helper";
import { COProcessContext } from "../classes/co-process-context.class";
import { COAssessment } from "../classes/co-assessment.class";
import { optionsInContext } from "./co-context.helper";
import { ASSESSMENT_VISIBILITY_START_FRESH_OPTION_VALUE } from "../constants/co-question-answer-option.constants";

export const setProcessSubmissionType = ({
  process,
  submissionType
}: {
  process: COProcess;
  submissionType: COProcessSubmissionType;
}) => {
  // change process_submission_type
  process.process_submission_type = submissionType;

  // also find process_submission_type question and answer it
  const process_context:
    | COProcessContext
    | undefined = process.process_context as COProcessContext;
  if (process_context.co_contexts) {
    for (const contextKey of Object.keys(process_context.co_contexts)) {
      const context = process_context.co_contexts[contextKey];
      if (context) {
        if (
          context.assessment?.co_assessment_type ===
          COAssessmentTypes.OVERVIEW_ASSESSMENT_TYPE
        ) {
          COAssessment.prepForSubmissionViewOrEdit({
            assessment: context.assessment || {}
          });

          setProcessSubmissionTypeInContext({
            context,
            submissionType: submissionType
          });
        }
      }
    }
  }
  process_context.updateCalculatedValues?.({ recalc: true });
};

const setProcessSubmissionTypeInContext = ({
  context,
  submissionType
}: {
  context: COContextInterface;
  submissionType: COProcessSubmissionType;
}) => {
  if (context.assessment) {
    if (context.assessment.co_assessment_sections) {
      for (const section of context.assessment.co_assessment_sections) {
        if (section.co_questions) {
          for (const question of section.co_questions) {
            if (
              question.co_variable_name ===
              CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE
            ) {
              // found the question
              if (question.co_question_answer_options) {
                for (const option of question.co_question_answer_options) {
                  if (
                    option.co_question_answer_option_value ===
                    submissionType + ""
                  ) {
                    // found the right answer option
                    answerQuestion({
                      context: context,
                      question: question,
                      questionAnswerOption: option
                    });
                    return;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export const answerParentAutomationQuestion = ({
  process,
  parent_process_id
}: {
  process: COProcess;
  parent_process_id: string;
}) => {
  const process_context:
    | COProcessContext
    | undefined = process.process_context as COProcessContext;
  if (process_context.co_contexts) {
    for (const contextKey of Object.keys(process_context.co_contexts)) {
      const context = process_context.co_contexts[contextKey];
      if (context) {
        if (
          context.assessment?.co_assessment_type ===
          COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE
        ) {
          COAssessment.prepForSubmissionViewOrEdit({
            assessment: context.assessment || {}
          });

          setParentAutomationQuestionInContext({ context, parent_process_id });
        }
      }
    }
  }
  process_context.updateCalculatedValues?.({ recalc: true });
};

const setParentAutomationQuestionInContext = ({
  context,
  parent_process_id
}: {
  context: COContextInterface;
  parent_process_id: string;
}) => {
  if (context.assessment) {
    if (context.assessment.co_assessment_sections) {
      for (const section of context.assessment.co_assessment_sections) {
        if (section.co_questions) {
          for (const question of section.co_questions) {
            if (
              question.co_variable_name ===
              CustomQuestionSlugs.CR_PARENT_AUTOMATION
            ) {
              // found the question
              if (
                question.co_question_answer_options &&
                (question.co_question_answer_options?.length ?? 0) > 0
              ) {
                const questionAnswerOption =
                  question.co_question_answer_options[0];
                questionAnswerOption.co_question_answer_option_value = parent_process_id;
                answerQuestion({
                  context: context,
                  question,
                  questionAnswerOption
                });
                if (question.co_process_answer?.co_process_answer_selections) {
                  const answerOption =
                    question.co_process_answer.co_process_answer_selections[0];

                  answerOption.co_question_answer_option_value = parent_process_id;
                  answerOption.co_process_answer_selection_input = parent_process_id;
                }
              }
            }
          }
        }
      }
    }
  }
};

export const answerAssessmentVisibilityQuestionsAsStartFresh = ({
  process
}: {
  process: COProcess;
}) => {
  const process_context:
    | COProcessContext
    | undefined = process.process_context as COProcessContext;

  // look for assessment visibility questions
  if (process_context.co_contexts?.CR?.assessment) {
    COAssessment.prepForSubmissionViewOrEdit({
      assessment: process_context.co_contexts.CR.assessment || {}
    });
    if (process_context.co_contexts.CR.assessment.co_assessment_sections) {
      for (const section of process_context.co_contexts.CR.assessment
        .co_assessment_sections) {
        if (section.co_questions) {
          for (const question of section.co_questions) {
            // found questions. find assessment visibility questions
            if (
              question.co_variable_name ===
                CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS &&
              process_context.co_contexts.Q1
            ) {
              answerAssessmentVisibilityQuestionAsStartFresh({
                context: process_context.co_contexts.Q1,
                visibility_question: question
              });
            } else if (
              question.co_variable_name ===
                CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS &&
              process_context.co_contexts.Q2
            ) {
              answerAssessmentVisibilityQuestionAsStartFresh({
                context: process_context.co_contexts.Q2,
                visibility_question: question
              });
            } else if (
              question.co_variable_name ===
                CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS &&
              process_context.co_contexts.Q3
            ) {
              answerAssessmentVisibilityQuestionAsStartFresh({
                context: process_context.co_contexts.Q3,
                visibility_question: question
              });
            } else if (
              question.co_variable_name ===
                CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS &&
              process_context.co_contexts.MISC
            ) {
              answerAssessmentVisibilityQuestionAsStartFresh({
                context: process_context.co_contexts.MISC,
                visibility_question: question
              });
            }
          }
        }
      }
    }
  }
  process_context.updateCalculatedValues?.({ recalc: true });
};

const answerAssessmentVisibilityQuestionAsStartFresh = ({
  context,
  visibility_question
}: {
  context: COContextInterface;
  visibility_question: COQuestionInterface;
}) => {
  const optionsInCtx = optionsInContext({
    context,
    options: visibility_question.options ?? {},
    should_resolve: true
  });
  if (optionsInCtx.is_hidden === 1) {
    // don't answer question if it's hidden
    return;
  }

  if (context.assessment?.functionAreAnyQuestionsAnswered?.(context) !== true) {
    // no questions are answered. Don't change the assessment visibility question
    return;
  }

  if (visibility_question.co_question_answer_options) {
    for (const option of visibility_question.co_question_answer_options) {
      if (
        option.co_question_answer_option_value ===
        ASSESSMENT_VISIBILITY_START_FRESH_OPTION_VALUE
      ) {
        // found the Start Fresh answer option
        answerQuestion({
          context: context,
          question: visibility_question,
          questionAnswerOption: option
        });
        return;
      }
    }
  }
};
