import { COBaseTemplateInterface } from "../interfaces/co-interfaces";
import { mergeObjects } from "../utils/co-utils";

export const mergeTemplates = (templates: any[]): COBaseTemplateInterface => {
  let finalTemplate: COBaseTemplateInterface = {};
  for (const template of templates) {
    if (!finalTemplate) {
      finalTemplate = { ...template };
    } else {
      finalTemplate = mergeObjects(finalTemplate, template);
    }
  }
  return finalTemplate;
};
