import {
  SET_CHECK_FOR_DUPLICATES_DATA,
  POTENTIAL_DUPLICATES_SERVER_RESPONSE,
  CLEAR_POTENTIAL_DUPLICATES
} from "../constants/actionTypes";

const INITIAL_STATE = {
  dataToCheck: null,
  potentialDuplicates: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHECK_FOR_DUPLICATES_DATA:
      return {
        ...state,
        dataToCheck: action.payload
      };
    case POTENTIAL_DUPLICATES_SERVER_RESPONSE:
      return {
        ...state,
        potentialDuplicates: action.payload
      };
    case CLEAR_POTENTIAL_DUPLICATES:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
