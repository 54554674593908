import {
  AUTOMATION_HUB_SERVICE,
  AUTOMATION_STORE_SERVICE,
  AUTOMATION_HUB_PROFILE_PREFIX_URL,
  AUTOMATION_STORE_PROFILE_PREFIX_URL,
  AUTOMATION_STORE_EXPLORE_URL,
  AUTOMATION_HUB_EXPLORE_AUTOMATIONS_URL
} from "../constants/urlConstants";
import SessionHelper from "../helpers/SessionHelper";
import TenantSettingHelper from "../helpers/TenantSettingsHelper";

const urlWithTenant = (url, service = AUTOMATION_HUB_SERVICE) => {
  if (!url || url.length === 0) {
    return "";
  }

  if (url.includes("http:") || url.includes("https:")) {
    return url;
  }

  if (url[0] !== "/") {
    return url;
  }

  let tenant = SessionHelper.getTenantNameUserToken();
  if (!tenant) {
    let currentPathName = window.location.pathname;
    let splitBySlash = currentPathName.split("/");
    if (splitBySlash.length > 1) {
      tenant = splitBySlash[1];
    }
  }

  let finalUrl = "/" + tenant;

  if (SessionHelper.isFpsUri()) {
    let p = window.location.pathname + "/";
    const currentFpsService = SessionHelper.currentFpsService();
    finalUrl = p.substring(0, p.toLowerCase().indexOf(`/${currentFpsService}`));
    finalUrl += `/${service}`;
  }

  if (url[url.length - 1] === "/" && url.length > 1) {
    //so if they just pass in /
    finalUrl += "/";
  }
  return finalUrl + url;
};

export default urlWithTenant;

export const setActiveLinkAttribute = (path, match) => {
  let isActive = "";
  if (match && match.url && match.url === path) {
    isActive = "active";
  }
  return isActive;
};

export const getPathWithoutTenantInfo = pathname => {
  if (typeof pathname !== "string") {
    return "";
  }

  const tenant = SessionHelper.isFpsUri()
    ? AUTOMATION_HUB_SERVICE
    : SessionHelper.getTenantNameUserToken();

  if (tenant && typeof tenant === "string") {
    const pathnameArr = pathname.split("/");
    const idx = pathnameArr.findIndex(
      curr => curr.toLowerCase() === tenant.toLowerCase()
    );
    if (idx > -1) {
      return `/${pathnameArr.slice(idx + 1).join("/")}`;
    }
  }

  return pathname;
};

export const redirectToPathWithTimeStampOrDefaultRoute = (
  history,
  defaultRoute
) => {
  const pathWithTimestamp = SessionHelper.getValueForKey("pathWithTimestamp");
  // Make sure the pathWithTimestamp local storage object exists
  // make sure it has a path and timestamp properties
  // make sure it has not been more than 2 minutes since the object was set
  if (
    pathWithTimestamp &&
    pathWithTimestamp.path &&
    pathWithTimestamp.timestamp &&
    Date.now() <= 1000 * 60 * 2 + pathWithTimestamp.timestamp
  ) {
    SessionHelper.clearValueForKey("pathWithTimestamp");
    history.replace(pathWithTimestamp.path);
  } else {
    history.replace(defaultRoute);
  }
};

// specfically used for routing in notifications for now
export const determineUrlBasedOnService = urlToModify => {
  if (!urlToModify) {
    return "";
  }
  let urlServiceType = AUTOMATION_HUB_SERVICE;
  if (urlToModify.includes(AUTOMATION_STORE_PROFILE_PREFIX_URL)) {
    urlServiceType = AUTOMATION_STORE_SERVICE;
  }

  // if it's not fps or if automation is disabled, but it has the store url in its url, replace it with automation hub equivalents and revert service type
  if (
    urlServiceType === AUTOMATION_STORE_SERVICE &&
    (!SessionHelper.isFpsUri() ||
      !TenantSettingHelper.isAutomationStoreEnabled())
  ) {
    urlServiceType = AUTOMATION_HUB_SERVICE;

    // replace profile urls
    urlToModify = urlToModify.replace(
      AUTOMATION_STORE_PROFILE_PREFIX_URL,
      AUTOMATION_HUB_PROFILE_PREFIX_URL
    );

    // replace generic explore urls (important that this goes after profile check since the link is more broad)
    urlToModify = urlToModify.replace(
      AUTOMATION_STORE_EXPLORE_URL,
      AUTOMATION_HUB_EXPLORE_AUTOMATIONS_URL
    );
  }

  return urlWithTenant(urlToModify, urlServiceType);
};
