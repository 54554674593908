import {
  FETCH_AUTOMATION_FOR_IDEA,
  CLEAR_CURRENT_AUTOMATION,
  SET_STATUS,
  DELETE_AUTOMATION,
  FETCH_ALL_PHASES_AND_STATUSES,
  SET_PIPELINE_PAGE_TYPE,
  CLEAR_PIPELINE_PAGE_TYPE
} from "../constants/actionTypes";

export interface AutomationPipelineState {
  currentAutomation: null | { [automationProperty: string]: any };
  statusChangeId: null | number;
  allPhasesAndStatuses: { [phaseStatusId: string]: string };
  statusChangeCallback: null | Function;
  errorCallback: null | Function;
  pageType: null | string;
}

const DEFAULT_STATE: AutomationPipelineState = {
  currentAutomation: null,
  //this is the status that will be fed into setStatus
  statusChangeId: null,
  allPhasesAndStatuses: {},
  statusChangeCallback: null,
  errorCallback: null,
  pageType: null
};

const parsePhaseArray = phaseArr => {
  const phaseStatusObj = {};

  phaseArr.forEach(({ phase_status }) => {
    phase_status.forEach(({ phase_status_key, phase_status_id }) => {
      phaseStatusObj[phase_status_key] = phase_status_id;
    });
  });

  return phaseStatusObj;
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_AUTOMATION_FOR_IDEA:
      const { automation, statusChangeId, cb, errorCallback } = action.payload;
      return {
        ...state,
        currentAutomation: automation,
        statusChangeCallback: cb,
        errorCallback: errorCallback,
        statusChangeId
      };
    case SET_STATUS:
      return {
        ...state,
        currentAutomation: action.payload
      };

    case DELETE_AUTOMATION:
      return {
        ...state,
        deleted: action.payload
      };

    case FETCH_ALL_PHASES_AND_STATUSES:
      return {
        ...state,
        allPhasesAndStatuses: parsePhaseArray(action.payload)
      };
    case SET_PIPELINE_PAGE_TYPE:
      const pageType = action.payload;

      return {
        ...state,
        pageType
      };
    case CLEAR_PIPELINE_PAGE_TYPE:
      return {
        ...state,
        pageType: null
      };
    case CLEAR_CURRENT_AUTOMATION:
      return {
        ...state,
        currentAutomation: null,
        statusChangeId: null,
        statusChangeCallback: null,
        errorCallback: null
      };
    default:
      return state;
  }
};
