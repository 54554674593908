import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  COMPONENT_PROFILE_URL,
  MY_COMPONENTS_URL,
  MY_TASKS_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const componentSupportNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = componentSupportNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.COMPONENT_SUPPORT,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const componentSupportNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.NEW_SUPPORT_TASK_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 86,
          platform_event_type: COPlatformEventType.COMPONENT_SUPPORT_REQUESTED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_SUPPORT_TASK_ADDED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New support task is added"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " has requested support for your component " +
              COActionReplacementStrings.COMPONENT_NAME +
              ". Have a look at the request and reply here."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.NEW_REPORT_ABUSE_TASK_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR, CORoleType.SYSTEM_ADMIN]
          },
          legacy_notification_id: 87,
          platform_event_type: COPlatformEventType.COMPONENT_ABUSE_REPORTED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_REPORT_ABUSE_TASK_ADDED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New report abuse task is added"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " has reported an abuse for the component " +
              COActionReplacementStrings.COMPONENT_NAME +
              ". Have a look at the request and reply here."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_ARCHIVED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 123,
          platform_event_type: COPlatformEventType.COMPONENT_ARCHIVED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_ARCHIVED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component archived"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.COMPONENT_NAME +
              " component was archived. You can still view it in My Components - Uploads."
          },
          redirect_url: {
            value: MY_COMPONENTS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_FOLLOWED_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.COMPONENT_DOWNLOADED_USER,
              CORoleType.TRIGGER_USER
            ]
          },
          legacy_notification_id: 124,
          platform_event_type:
            COPlatformEventType.COMPONENT_UPDATED_FOR_DOWNLOADED_USERS,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_FOLLOWED_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component I follow is updated"
          },
          message: {
            value:
              "There is an update for a component you have been following. You can now download the updated version of " +
              COActionReplacementStrings.COMPONENT_NAME +
              "."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_UPLOADED_FOR_CURATION: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.CURATOR]
          },
          legacy_notification_id: 125,
          platform_event_type:
            COPlatformEventType.COMPONENT_UPLOADED_FOR_CURATION,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_UPLOADED_FOR_CURATION,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component uploaded for curation"
          },
          message: {
            value:
              "There is a new request to review content. Click on " +
              COActionReplacementStrings.COMPONENT_NAME +
              " to review this component."
          },
          redirect_url: {
            value: MY_TASKS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_CURATION_STARTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 126,
          platform_event_type: COPlatformEventType.COMPONENT_CURATION_STARTED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_CURATION_STARTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component curation started"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the component " +
              COActionReplacementStrings.COMPONENT_NAME +
              " to the status In Review. Track the status in My Components - Uploads."
          },
          redirect_url: {
            value: MY_COMPONENTS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_CURATION_REJECTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 127,
          platform_event_type: COPlatformEventType.COMPONENT_CURATION_REJECT,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_CURATION_REJECTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component curation reject"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " rejected your component " +
              COActionReplacementStrings.COMPONENT_NAME +
              ", with the following comment: '" +
              COActionReplacementStrings.COMPONENT_REJECTED_REASON +
              "'"
          },
          redirect_url: {
            value: MY_COMPONENTS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_CURATION_NEEDS_UPDATE: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 128,
          platform_event_type:
            COPlatformEventType.COMPONENT_CURATION_NEEDS_UPDATE,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_CURATION_NEEDS_UPDATE,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component curation needs update"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the component " +
              COActionReplacementStrings.COMPONENT_NAME +
              " to the status Needs Update. The component is now back in Draft status and you can find it in My Components > Uploads."
          },
          redirect_url: {
            value: MY_COMPONENTS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_CURATION_PUBLISHED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 129,
          platform_event_type: COPlatformEventType.COMPONENT_CURATION_PUBLISH,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_CURATION_PUBLISHED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component curation publish"
          },
          message: {
            value:
              "Your component " +
              COActionReplacementStrings.COMPONENT_NAME +
              " has been published and can now be downloaded by all company users."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_CURATION_DOWNLOADED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER, CORoleType.TRIGGER_USER]
          },
          legacy_notification_id: 130,
          platform_event_type: COPlatformEventType.COMPONENT_DOWNLOADED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_CURATION_DOWNLOADED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component downloaded"
          },
          message: {
            value:
              "We hope you enjoyed " +
              COActionReplacementStrings.COMPONENT_NAME +
              " and we would love to hear your opinions on it. Please take two minutes of your time to leave a review on it and tell us what you think."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_REVIEW_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 131,
          platform_event_type: COPlatformEventType.COMPONENT_REVIEW_ADDED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_REVIEW_ADDED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component review added"
          },
          message: {
            value:
              "Your component " +
              COActionReplacementStrings.COMPONENT_NAME +
              " has received a new review by " +
              COActionReplacementStrings.USER_FULL_NAME +
              "."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_COMMENT_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_notification_id: 132,
          platform_event_type: COPlatformEventType.COMPONENT_COMMENT_ADDED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_COMMENT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component comment added"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added a comment in the component " +
              COActionReplacementStrings.COMPONENT_NAME +
              ": " +
              COActionReplacementStrings.COMPONENT_COMMENT +
              "."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_NEW_REPLY: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 133,
          platform_event_type: COPlatformEventType.COMPONENT_NEW_REPLY,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_COMMENT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component new reply"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added a reply in the component " +
              COActionReplacementStrings.COMPONENT_NAME +
              ": " +
              COActionReplacementStrings.COMPONENT_COMMENT +
              "."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COMPONENT_NEW_COMMENT: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMMENT_MENTIONED_USER]
          },
          legacy_notification_id: 130,
          platform_event_type: COPlatformEventType.COMPONENT_USER_MENTIONED,
          notification_category_type:
            CONotificationActionCategoryTypes.COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.COMPONENT_COMMENT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Mentioned in component comment"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " mentioned you in a comment in the component " +
              COActionReplacementStrings.COMPONENT_NAME +
              ": " +
              COActionReplacementStrings.COMPONENT_COMMENT +
              "."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
