import { COComponentType } from "../../../constants/co-constants";
import {
  PATH,
  PATH_OPERATORS,
  PATH_ROUTE
} from "../../../constants/co-path.constants";
import {
  COContextInterface,
  COControlInterface,
  CODocumentInfo,
  CODocumentOptionsInterface,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COProcessAnswerSelectionInterface
} from "../../../interfaces/co-interfaces";
import { isPath } from "../../../utils/co-path.utils";
import { coUUID } from "../../../utils/co-utils";
import { documentSelectedControlCallback } from "./co-document-controls-utils";

export const documentPickerControls = ({
  position,
  meta,
  options = {}
}: {
  meta?: COMetadataInterface;
  position?: COPositionInterface;
  options?: COOptionsInterface;
}) => {
  let key = coUUID();
  let controls: COControlInterface[] = [
    {
      key,
      target: "",
      meta: meta || {
        title: {
          // the value here will be the document name so we do not want it localized with a key
          placeholder: "Add document",
          placeholder_localization_key: "add_document_control_title"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_BUTTON,
        ...options
      },
      subControls:
        options.max_selectable_answers || 0 > 0
          ? addMultipleDocumentsControls(options)
          : [],
      controlFunction: ({
        context,
        control,
        option,
        parentControl
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
        parentControl: COControlInterface;
      }) => {
        // basically we need to trigger a function to get a document
        if (context.function_hooks?.documentSelectPopup) {
          //let questionClassObject: COQuestion | undefined = context.question;
          // so the KPI loses it's class somehow need to find in the context

          let documentOptions: CODocumentOptionsInterface = {};
          if (context.answer_option?.options?.document_options) {
            documentOptions = context.answer_option.options.document_options;
          } else if (context.question?.options?.document_options) {
            documentOptions = context.question?.options?.document_options;
          }
          context.function_hooks.documentSelectPopup({
            context,
            documentOptions,
            callback: (document: any) => {
              return documentSelectedControlCallback({
                document,
                context,
                control
              });
            }
          });
        }
      },
      position: position,
      items: []
    }
  ];
  return controls;
};

export const addAdditionalDocumentControlForAnswerOption = (
  context,
  options
) => {
  // we need to add another document picker
  console.log("adding additional document picker");
  if (
    context.answer_option &&
    context.answer_option.template &&
    context.answer_option.template.co_meta_json &&
    context.answer_option.template.co_meta_json.controls
  ) {
    if (
      context.answer_option.template.co_meta_json.controls.controls &&
      Array.isArray(
        context.answer_option.template.co_meta_json.controls.controls
      )
    ) {
      context.answer_option.template.co_meta_json.controls.controls.push(
        ...documentPickerControls({
          options
        })
      );
    }
  }
};
export const addMultipleDocumentsControls = options => {
  let controls: COControlInterface[] = [
    {
      meta: {
        title: {
          value: "+",
          value_localization_key: "",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      controlFunction: ({
        context,
        control,
        option,
        parentControl
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
        parentControl: COControlInterface;
      }) => {
        addAdditionalDocumentControlForAnswerOption(context, options);
      },
      options: {
        component_type: COComponentType.CONTROL_BUTTON
      }
    },
    {
      meta: {
        title: {
          value: "-",
          value_localization_key: "",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      controlFunction: ({
        context,
        control,
        option,
        parentControl
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
        parentControl: COControlInterface;
      }) => {
        // we need to remove a document picker
        if (
          context.answer_option &&
          context.answer_option.template &&
          context.answer_option.template.co_meta_json &&
          context.answer_option.template.co_meta_json.controls
        ) {
          if (context.answer_option.template.co_meta_json.controls.controls) {
            let processAnswerSelection:
              | COProcessAnswerSelectionInterface
              | undefined = context.process_answer_selection;

            let parentKey = parentControl.key;
            let existing_document_data: CODocumentInfo = context
              ?.process_external_data?.document_data || { documents: [] };

            if (processAnswerSelection && parentKey) {
              // documents are now stored in an array here -
              existing_document_data.documents = (
                existing_document_data.documents || []
              ).filter(doc => {
                return doc.co_control_key != parentKey;
              });

              const ped: any = context?.process_external_data;
              if (ped && ped.syncDocuments) {
                ped.syncDocuments?.(existing_document_data.documents);
              }

              delete processAnswerSelection
                .co_process_answer_selection_info_json[parentKey];

              // to do moved to sync function
              processAnswerSelection.co_process_answer_selection_input = Object.values(
                processAnswerSelection.co_process_answer_selection_info_json
              )
                .map((documentData: any) => {
                  return documentData
                    ? documentData?.document_title || "Unknown"
                    : "Unknown";
                })
                .join(",");

              // don't delete the last control
              if (
                parentControl &&
                Array.isArray(
                  context.answer_option.template.co_meta_json.controls.controls
                )
              ) {
                context.answer_option.template.co_meta_json.controls.controls = context.answer_option.template.co_meta_json.controls.controls.filter(
                  ctrl => ctrl !== parentControl
                );
                if (
                  context.answer_option.template.co_meta_json.controls.controls
                    .length === 0
                ) {
                  context.answer_option.template.co_meta_json.controls.controls.push(
                    ...documentPickerControls({
                      options
                    })
                  );
                }
              }
            }
          }
        }
      },
      options: {
        component_type: COComponentType.CONTROL_BUTTON
      }
    }
  ];
  return controls;
};
