import _ from "lodash";

//utility function for getting last key in 'keys' array
//made this so no errors when you try to invoke key of undefined

export const getKeyValue = (obj, keys) => {
  if (!keys) {
    return undefined;
  }

  if (typeof keys === "string") {
    keys = keys.split(".");
  }

  let keyValue = obj;

  for (let i = 0; i < keys.length; i++) {
    const currKey = keys[i];
    if (!keyValue || typeof keyValue !== "object") {
      return undefined;
    }
    if (keyValue[currKey] === undefined) {
      return undefined;
    }
    if (keyValue[currKey] === null) {
      return null;
    }

    keyValue = keyValue[currKey];
  }

  return keyValue;
};

export const isEmptyObject = obj => {
  if (obj === null || obj === undefined) {
    return true;
  } else if (typeof obj === "object" && Object.keys(obj).length === 0) {
    return true;
  } else {
    return false;
  }
};

export const containsSameKeyValue = (obj1, obj2, keyName) => {
  if (
    obj1 === null ||
    typeof obj1 !== "object" ||
    obj2 === null ||
    typeof obj2 !== "object"
  ) {
    return false;
  }
  if (typeof obj1[keyName] !== "object") {
    return obj1[keyName] === obj2[keyName];
  }
  return _.isEqual(obj1[keyName], obj2[keyName]);
};
