import {
  COFilterOptionItemInterface,
  COQueryConditionOperator
} from "../../../interfaces/co-interfaces";

export enum COPipelineFilterOptionTemplateSlugs {
  COPercentFilter = "co_percent_filter"
}

export const templateFilterOptions = (
  template_slug: string
): COFilterOptionItemInterface[] => {
  switch (template_slug) {
    case COPipelineFilterOptionTemplateSlugs.COPercentFilter:
      return pctQuestionFilterOptions();
  }
  return [];
};

export const pctQuestionFilterOptions = (): COFilterOptionItemInterface[] => {
  let filterOptions: COFilterOptionItemInterface[] = [];
  filterOptions.push({
    filter_option_key: "co_pct_filter_25",
    meta: {
      value: "Less than 25%"
    },
    filter_query_condition: {
      values: "25",
      operator: COQueryConditionOperator.LESS_THAN
    }
  });
  filterOptions.push({
    filter_option_key: "co_pct_filter_50",
    meta: {
      value: "Less than 50%, More than 25%"
    },
    filter_query_condition: {
      values: ["25", "50"],
      operator: COQueryConditionOperator.BETWEEN
    }
  });
  filterOptions.push({
    filter_option_key: "co_pct_filter_75",
    meta: {
      value: "Less than 75%, More than 50%"
    },
    filter_query_condition: {
      values: ["50", "75"],
      operator: COQueryConditionOperator.BETWEEN
    }
  });
  filterOptions.push({
    filter_option_key: "co_pct_filter_max",
    meta: {
      value: "More than or 75%"
    },
    filter_query_condition: {
      values: "75",
      operator: COQueryConditionOperator.MORE_THAN_OR_EQUALS
    }
  });
  return filterOptions;
};
