import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import {
  EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION,
  TOP_DOWN_PERMISSION_PERMISSION,
  SUBMIT_AUTOMATION_PERMISSION,
  SUBMIT_CHANGE_REQUEST_PERMISSION
} from "../../../constants/adminPermissionsConstants";
import urlWithTenant from "../../../utils/urlHelper";
import DropDownNavAuth from "../../DropDownNavAuth/DropDownNavAuth";
import { setQuestionnaireCloseRoute } from "../../../actions/questionnaireActions";
import {
  SHARE_AN_AUTOMATION,
  triggerAnalyticsWithDefaultData
} from "../../../utils/analyticsUtils";
import { CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE } from "../../../constants/urlConstants";
import { COProcessSubmissionType } from "@uipath/ah-co";
import ModalChangeRequestParentSelection from "../../Modal/ModalChangeRequestParentSelection/ModalChangeRequestParentSelection";

const InnerButtonContainer = styled.div`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  border-radius: 3px;
`;

const ButtonContainer = styled(({ children, className }) => (
  <div className={className}>
    <InnerButtonContainer>
      <svg
        width="32"
        height="31"
        viewBox="0 0 32 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10"
          y="7"
          width="12"
          height="12"
          rx="6"
          fill="var(--color-primary, #FA4616)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 13C8 8.584 11.584 5 16 5C20.416 5 24 8.584 24 13C24 17.416 22 22.5 16 22.5C10 22.5 8 17.416 8 13ZM16.8 13.8H20V12.2H16.8V9H15.2V12.2H12V13.8H15.2V17H16.8V13.8Z"
          fill="var(--color-background, white)"
          className="ShareIdeaButton__bulb-light"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 4C11.0371 4 7 8.12125 7 13.1875C7 16.3112 8.67286 19.3331 11 21V23C11 23.5523 11.4477 24 12 24H20C20.5523 24 21 23.5523 21 23V21C23.3271 19.3331 25 16.3112 25 13.1875C25 8.12125 20.9629 4 16 4ZM12 26.5C12 27.325 12.6 28 13.3333 28H18.6667C19.4 28 20 27.325 20 26.5V26H12V26.5ZM19 19.5L20 19C21.89 17.7467 23 14.84 23 12.6667C23 8.98667 19.864 6 16 6C12.136 6 9 8.98667 9 12.6667C9 14.84 10.11 17.7467 12 19L13 19.5V22H19V19.5Z"
          fill="var(--color-primary, #0067df)"
        />
      </svg>
      {children}
    </InnerButtonContainer>
  </div>
))(
  props => css`
    display: inline-flex;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    height: ${props.theme.sizes["nav-auth-height"]};
    padding: 0px;
    cursor: pointer;

    svg {
      height: 20px;
    }

    .ShareIdeaButton__bulb-light {
      animation: ShareIdeaButton__bulb-light-flickerAnimation 20s infinite;
    }

    @keyframes ShareIdeaButton__bulb-light-flickerAnimation {
      0% {
        opacity: 1;
      }
      92% {
        opacity: 1;
      }
      94% {
        opacity: 0.7;
      }
      96% {
        opacity: 1;
      }
      98% {
        opacity: 0.7;
      }
      100% {
        opacity: 1;
      }
    }
  `
);

const DropDownNavAuthStyled = styled(DropDownNavAuth)`
  &:hover ${InnerButtonContainer}, &:focus ${InnerButtonContainer} {
    background: var(--color-hover);
  }
`;

const LinkStyled = styled(Link)`
  &:hover ${InnerButtonContainer} {
    background: var(--color-hover);
  }
`;

const ShareIdeaButton = ({
  user,
  isOpen,
  onAutomationIdeaClickCallback,
  onShareAutomationClickCallback,
  onSubmitCoeIdeaClickCallback,
  dropRight = false,
  setQuestionnaireCloseRouteDispatch
}) => {
  const { t } = useTranslation("common");
  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);

  const onAutomationIdeaClick = () => {
    setQuestionnaireCloseRouteDispatch(window.location.pathname);
    typeof onAutomationIdeaClickCallback === "function" &&
      onAutomationIdeaClickCallback();
  };
  const onShareAutomationClick = () => {
    triggerAnalyticsWithDefaultData(SHARE_AN_AUTOMATION);
    setQuestionnaireCloseRouteDispatch(window.location.pathname);
    typeof onShareAutomationClickCallback === "function" &&
      onShareAutomationClickCallback();
  };

  const onSubmitCoeIdeaClick = () => {
    setQuestionnaireCloseRouteDispatch(window.location.pathname);
    typeof onShareAutomationClickCallback === "function" &&
      onSubmitCoeIdeaClickCallback();
  };

  const onSubmitChangeRequestIdeaClick = () => {
    setShowChangeRequestModal(true);
    setQuestionnaireCloseRouteDispatch(window.location.pathname);
    typeof onShareAutomationClickCallback === "function" &&
      onSubmitCoeIdeaClickCallback();
  };

  if (!user) {
    return null;
  }

  const canSubmitRegularIdea =
    user &&
    user.ah_permissions &&
    user.ah_permissions[EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION] === 1;

  const canSubmitCoeIdea =
    user && user.ah_permissions
      ? user.ah_permissions[TOP_DOWN_PERMISSION_PERMISSION] === 1
      : false;

  const canSubmitAutomation =
    user && user.ah_permissions
      ? user.ah_permissions[SUBMIT_AUTOMATION_PERMISSION] === 1
      : false;

  const canSubmitChangeRequest =
    user && user.ah_permissions
      ? user.ah_permissions[SUBMIT_CHANGE_REQUEST_PERMISSION] === 1
      : false;

  const canSeeHoverMenu =
    canSubmitCoeIdea || canSubmitAutomation || canSubmitChangeRequest;

  if (canSeeHoverMenu) {
    return (
      <>
        <DropDownNavAuthStyled
          key={`submit idea ${isOpen}`}
          rightItem={dropRight}
          titleComponent={
            <ButtonContainer>
              {t(["application_header_submit_idea_button", "Submit idea"])}
            </ButtonContainer>
          }
        >
          {canSubmitRegularIdea ? (
            <Link
              to={urlWithTenant(
                CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
                  COProcessSubmissionType.EMPLOYEE_DRIVEN
                )
              )}
              onClick={onAutomationIdeaClick}
            >
              {t([
                "application_header_submit_idea_employee_driven",
                "Submit an idea"
              ])}
            </Link>
          ) : null}

          {canSubmitCoeIdea ? (
            <Link
              to={urlWithTenant(
                CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
                  COProcessSubmissionType.TOP_DOWN_SUBMISSION
                )
              )}
              onClick={onSubmitCoeIdeaClick}
            >
              {t([
                "application_header_submit_idea_coe_driven",
                "Submit a CoE driven idea"
              ])}
            </Link>
          ) : (
            ""
          )}

          {canSubmitAutomation ? (
            <Link
              to={urlWithTenant(
                CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
                  COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION
                )
              )}
              onClick={onShareAutomationClick}
            >
              {t([
                "application_header_submit_automation",
                "Submit an automation"
              ])}
            </Link>
          ) : null}
          {canSubmitChangeRequest ? (
            <button onClick={onSubmitChangeRequestIdeaClick}>
              {t([
                "application_header_submit_change_request",
                "Submit a change request"
              ])}
            </button>
          ) : (
            ""
          )}
        </DropDownNavAuthStyled>
        {showChangeRequestModal ? (
          <ModalChangeRequestParentSelection
            onClose={() => setShowChangeRequestModal(false)}
          />
        ) : (
          ""
        )}
      </>
    );
  }

  if (canSubmitRegularIdea) {
    return (
      <div className="NavAuth__submit-idea-wrapper">
        <LinkStyled
          to={urlWithTenant(
            CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
              COProcessSubmissionType.EMPLOYEE_DRIVEN
            )
          )}
          onClick={onAutomationIdeaClick}
        >
          <ButtonContainer>
            {t(["application_header_submit_idea_button", "Submit idea"])}
          </ButtonContainer>
        </LinkStyled>
      </div>
    );
  }
  return <div />;
};

const mapDispatchToProps = {
  setQuestionnaireCloseRouteDispatch: setQuestionnaireCloseRoute
};

export default connect(null, mapDispatchToProps)(ShareIdeaButton);
