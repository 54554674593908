import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import InputCustomChildWithLabel from "./../../../components/inputs/InputCustomChildWithLabel/InputCustomChildWithLabel";
import InputUploadEditFileList from "./../../../components/inputs/InputUploadEditFileList/InputUploadEditFileList";
import {
  COMPONENT_FILE,
  COMPONENT_SOURCE_CODE
} from "./../../../constants/fileUploadConstants";
import ButtonMain from "../../../components/buttons/ButtonMain/ButtonMain";
import SessionHelper from "../../../helpers/SessionHelper";
import { doesFileExist } from "../../../utils/fileHelpers";
import "../UploadEditComponent/UploadEditComponent.scss";
import { InputLabel } from "@material-ui/core";

class UploadEditComponentUploadSection extends Component {
  render() {
    const currentUser = SessionHelper.currentUser()
      ? SessionHelper.currentUser().user_id
      : {};
    const hasComponentFile = doesFileExist(this.props.componentFiles);
    const hasSourceFile = doesFileExist(this.props.componentSourceCode);
    const {
      notEditable,
      showNewVersionButton,
      handleNewVersionButtonClick,
      component_author_user_id
    } = this.props;

    return (
      <div
        className="UploadEditComponentUploadSection"
        data-testid="UploadEditComponentUploadSection"
      >
        <div className="UploadEditComponent__field">
          <InputLabel required error={this.props.componentFileError}>
            {this.props.t([
              "upload_component_file_uploaded_label",
              "File Upload"
            ])}
          </InputLabel>
          <InputCustomChildWithLabel
            label=""
            error={this.props.componentFileError}
          />
          {hasComponentFile || (
            <p className="UploadEditComponent__field-description">
              {this.props.t("upload_component_file_uploaded_comment")}
            </p>
          )}

          <InputUploadEditFileList
            hideUploadBtn={hasComponentFile}
            setData={this.props.setDataArray}
            field="file"
            files={this.props.componentFiles}
            fileLink={this.props.componentFilesLink}
            acceptFileTypes={[".nupkg", ".zip", ".xaml"]}
            fileLimit={1}
            sizeLimit={50000000}
            fileType={COMPONENT_FILE}
            notEditable={notEditable}
            showLink={true}
            onChange={this.props.handleFileLinkChange}
            link={this.props.componentFilesLink}
          />
        </div>

        <div className="UploadEditComponent__field">
          <InputLabel required error={this.props.componentSourceCodeError}>
            {this.props.t([
              "upload_component_source_code_label",
              "Source Code"
            ])}
          </InputLabel>
          <InputCustomChildWithLabel
            label=""
            error={this.props.componentSourceCodeError}
          />
          {hasSourceFile || (
            <p className="UploadEditComponent__field-description">
              {this.props.t("upload_component_source_code_comment")}
            </p>
          )}
          <InputUploadEditFileList
            hideUploadBtn={hasSourceFile}
            setData={this.props.setDataArray}
            field="source_code"
            files={this.props.componentSourceCode}
            fileLink={this.props.componentSourceCodeLink}
            acceptFileTypes={[".zip", ".xaml"]}
            fileLimit={1}
            sizeLimit={50000000}
            fileType={COMPONENT_SOURCE_CODE}
            notEditable={notEditable}
            showLink={true}
            onChange={this.props.handleSourceCodeLinkChange}
            link={this.props.componentSourceCodeLink}
          />
        </div>
        {showNewVersionButton && currentUser === component_author_user_id && (
          <ButtonMain
            clickHandler={handleNewVersionButtonClick}
            type="secondary"
            data-testid="AddNewVersion_Button"
          >
            {this.props.t("upload_component_add_new_version_button")}
          </ButtonMain>
        )}
      </div>
    );
  }
}

export default withTranslation("common")(UploadEditComponentUploadSection);
