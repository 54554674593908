import { COAssessment } from "../classes/co-assessment.class";
import { getDefaultAssessmentJSON } from "../templates/co-template-loader";
import { testPathSystem } from "./co-path.tests";
import { testPipeline } from "./co-pipeline.tests";
import { testResolverSystem } from "./co-resolver.tests";
import { testValidationSystem } from "./co-validator.tests";

export const runCOTests = () => {
  let logSuccess = true;
  testPathSystem({ assessment: assessmentToTest(), logSuccess });
  testResolverSystem({ assessment: assessmentToTest(), logSuccess });
  testValidationSystem({ assessment: assessmentToTest(), logSuccess });
  testPipeline({ assessment: assessmentToTest(), logSuccess });
};

export const assessmentToTest = (): COAssessment => {
  let assessment: COAssessment | null = COAssessment.objectFromJSON(
    getDefaultAssessmentJSON()
  );
  return assessment || {};
};
