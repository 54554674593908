import { CustomQuestionSlugs } from "../../../constants/co-question.constants";
import {
  COFilterOptionItemInterface,
  COFilterTargetType,
  COFilterType,
  COQueryConditionOperator
} from "../../../interfaces/co-interfaces";
import { LegacyProcessColumnName } from "../../../interfaces/co-legacy.interfaces";

export enum COPipelineLegacySlugs {
  numberOfRobots = "number-of-robots"
}

export const legacyFilterOptionsForQuestionLegacySlug = (
  legacy_slug: string
): {
  filterOptions?: COFilterOptionItemInterface[];
  filterType?: COFilterType;
} => {
  switch (legacy_slug) {
    case COPipelineLegacySlugs.numberOfRobots:
      return {
        filterOptions: rangeFilterOptions({
          min_number: 5,
          min_display: "five",
          max_number: 10,
          max_display: "ten",
          filter_column: LegacyProcessColumnName(
            CustomQuestionSlugs.NUMBER_OF_ROBOTS
          )
        })
      };
  }
  return {};
};

export const legacyFilterOptionsProcessColumn = (
  process_column_name: string
): {
  filterOptions?: COFilterOptionItemInterface[];
  filterType?: COFilterType;
} => {
  switch (process_column_name) {
    case "process_priority":
      return {
        filterOptions: coFormattedPriorityFilterValues(),
        filterType: COFilterType.PROCESS_SELECTION_FILTER
      };
  }
  return {};
};

export const legacyFilterOptions = (
  legacy_slug: string
): COFilterOptionItemInterface[] => {
  switch (legacy_slug) {
    case COPipelineLegacySlugs.numberOfRobots:
      return rangeFilterOptions({
        min_number: 5,
        min_display: "five",
        max_number: 10,
        max_display: "ten",
        filter_column: LegacyProcessColumnName(
          CustomQuestionSlugs.NUMBER_OF_ROBOTS
        )
      });
  }
  return [];
};

export const coFormattedPriorityFilterValues = () => {
  let rawResults = fetchPriorityFilterValues(); // todo move
  return rawResults.map(item => {
    let name = item.priority_name;
    return {
      meta: {
        value: name + ""
      },
      filter_option_key: item.priority_id + "",
      filter_query_condition: {
        targetType: COFilterTargetType.ANSWER_VALUE,
        values: [item.priority_id + ""],
        operator: COQueryConditionOperator.IN
      }
    } as COFilterOptionItemInterface;
  });
};
export const fetchPriorityFilterValues = () => {
  /*
    Filtering follows the business unit format where it's an array of strings,
    but this is 1-100
  */
  const filterValues: any = [];
  filterValues.push({
    priority_id: 127,
    priority_name: "None",
    priority_key: "process-priority-none"
  });
  for (let i = 1; i <= 100; i++) {
    filterValues.push({
      priority_id: i,
      priority_name: i
    });
  }
  return filterValues;
};

export const rangeFilterOptions = ({
  min_number,
  min_display,
  max_number,
  max_display,
  filter_column
}: {
  min_number: Number;
  min_display: String;
  max_number: Number;
  max_display: String;
  filter_column: String | null | undefined;
}): COFilterOptionItemInterface[] => {
  let filterOptions: COFilterOptionItemInterface[] = [];

  if (typeof filter_column === "string") {
    filterOptions.push({
      filter_option_key: `${filter_column}_fewer_than_${min_display}`,
      meta: {
        value: `Fewer than ${min_number}`
      },
      filter_query_condition: {
        values: `${min_number}`,
        operator: COQueryConditionOperator.LESS_THAN
      }
    });
    filterOptions.push({
      filter_option_key: `${filter_column}_between_${min_display}_${max_display}`,
      meta: {
        value: `Between ${min_number} and ${max_number}`
      },
      filter_query_condition: {
        values: [`${min_number}`, `${max_number}`],
        operator: COQueryConditionOperator.BETWEEN
      }
    });
    filterOptions.push({
      filter_option_key: `${filter_column}_more_than_${max_display}`,
      meta: {
        value: `More than ${max_number}`
      },
      filter_query_condition: {
        values: `${max_number}`,
        operator: COQueryConditionOperator.MORE_THAN_OR_EQUALS
      }
    });
  }

  return filterOptions;
};
