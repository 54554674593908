export enum COTaskActionSlugs {
  SUBMITTED_IDEA_TO_REVIEW_TASK = "SUBMITTED_IDEA_TO_REVIEW_TASK",
  REVIEW_APPROVED_IDEA_TASK = "REVIEW_APPROVED_IDEA_TASK",
  RESUBMIT_IDEA_TASK = "RESUBMIT_IDEA_TASK",
  ASSESMENT_LAUNCHED_TASK = "ASSESMENT_LAUNCHED_TASK",
  REVIEW_ASSESSED_AUTOMATION_TASK = "REVIEW_ASSESSED_AUTOMATION_TASK",
  ASSIGN_PROJECT_MANAGER_TASK = "ASSIGN_PROJECT_MANAGER_TASK",
  AP_PROJECT_MANAGER_ROLE_ASSIGNED_TASK = "AP_PROJECT_MANAGER_ROLE_ASSIGNED_TASK",
  AP_EDIT_ABOUT_RIGHTS_GRANTED_TASK = "AP_EDIT_ABOUT_RIGHTS_GRANTED_TASK",
  AP_EDIT_DOCUMENTATION_RIGHTS_GRANTED_TASK = "AP_EDIT_DOCUMENTATION_RIGHTS_GRANTED_TASK",
  AP_EDIT_COMPONENT_RIGHTS_GRANTED_TASK = "AP_EDIT_COMPONENT_RIGHTS_GRANTED_TASK",
  AP_NEW_COMPONENT_CREATED_TASK = "AP_NEW_COMPONENT_CREATED_TASK",
  AP_ABUSE_REPORTED_TASK = "AP_ABUSE_REPORTED_TASK",
  GO_CURATION_NEEDS_UPDATE_TASK = "GO_CURATION_NEEDS_UPDATE_TASK",
  GO_CURATION_IN_PROGRESS_TASK = "GO_CURATION_IN_PROGRESS_TASK",
  GO_SUPPORT_REQUEST_TASK = "GO_SUPPORT_REQUEST_TASK",
  GO_ABUSE_REPORTED_TASK = "GO_ABUSE_REPORTED_TASK",
  AP_COMMENT_ABUSE_REPORTED_TASK = "AP_COMMENT_ABUSE_REPORTED_TASK",
  GO_REVIEW_ABUSE_REPORTED_TASK = "GO_REVIEW_ABUSE_REPORTED_TASK",
  GO_COMMENT_ABUSE_REPORTED_TASK = "GO_COMMENT_ABUSE_REPORTED_TASK",
  REVIEW_COE_DRIVEN_IDEA_TASK = "REVIEW_COE_DRIVEN_IDEA_TASK",
  AP_EDIT_CBA_RIGHTS_GRANTED_TASK = "AP_EDIT_CBA_RIGHTS_GRANTED_TASK",
  CD_AUTOMATION_SHARED_TASK = "CD_AUTOMATION_SHARED_TASK",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW_TASK = "CD_AUTOMATION_TO_TECHNICAL_REVIEW_TASK",
  CD_AUTOMATION_REJECTED_TASK = "CD_AUTOMATION_REJECTED_TASK",
  CD_AUTOMATION_REWORK_REQUIRED_TASK = "CD_AUTOMATION_REWORK_REQUIRED_TASK",
  CD_AUTOMATION_REQUESTED_TASK = "CD_AUTOMATION_REQUESTED_TASK",
  PUBLISH_AUTOMATION_TO_AUTOMATION_STORE_TASK = "PUBLISH_AUTOMATION_TO_AUTOMATION_STORE_TASK",
  REVIEW_CHANGE_REQUEST_TASK = "REVIEW_CHANGE_REQUEST_TASK"
}

export enum COTaskType {
  APPROVE_CONTENT = "APPROVE_CONTENT",
  GENERATE_CONTENT = "GENERATE_CONTENT",
  GENERAL_TASK = "GENERAL_TASK",
  CURATE = "CURATE",
  SUPPORT = "SUPPORT"
}

export enum COTaskActivityType {
  SUBMITTED_IDEA_TO_REVIEW = "SUBMITTED_IDEA_TO_REVIEW",
  REVIEW_APPROVED_IDEA = "REVIEW_APPROVED_IDEA",
  RESUBMIT_IDEA = "RESUBMIT_IDEA",
  ASSESMENT_LAUNCHED = "ASSESMENT_LAUNCHED",
  REVIEW_ASSESSED_AUTOMATION = "REVIEW_ASSESSED_AUTOMATION",
  ASSIGN_PROJECT_MANAGER = "ASSIGN_PROJECT_MANAGER",
  AP_PROJECT_MANAGER_ROLE_ASSIGNED = "AP_PROJECT_MANAGER_ROLE_ASSIGNED",
  AP_EDIT_ABOUT_RIGHTS_GRANTED = "AP_EDIT_ABOUT_RIGHTS_GRANTED",
  AP_EDIT_DOCUMENTATION_RIGHTS_GRANTED = "AP_EDIT_DOCUMENTATION_RIGHTS_GRANTED",
  AP_EDIT_COMPONENT_RIGHTS_GRANTED = "AP_EDIT_COMPONENT_RIGHTS_GRANTED",
  AP_NEW_COMPONENT_CREATED = "AP_NEW_COMPONENT_CREATED",
  AP_ABUSE_REPORTED = "AP_ABUSE_REPORTED",
  GO_CURATION_NEEDS_UPDATE = "GO_CURATION_NEEDS_UPDATE",
  GO_CURATION_IN_PROGRESS = "GO_CURATION_IN_PROGRESS",
  GO_SUPPORT_REQUEST = "GO_SUPPORT_REQUEST",
  GO_ABUSE_REPORTED = "GO_ABUSE_REPORTED",
  AP_COMMENT_ABUSE_REPORTED = "AP_COMMENT_ABUSE_REPORTED",
  GO_REVIEW_ABUSE_REPORTED = "GO_REVIEW_ABUSE_REPORTED",
  GO_COMMENT_ABUSE_REPORTED = "GO_COMMENT_ABUSE_REPORTED",
  REVIEW_COE_DRIVEN_IDEA = "REVIEW_COE_DRIVEN_IDEA",
  AP_EDIT_CBA_RIGHTS_GRANTED = "AP_EDIT_CBA_RIGHTS_GRANTED",
  CD_AUTOMATION_SHARED = "CD_AUTOMATION_SHARED",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW = "CD_AUTOMATION_TO_TECHNICAL_REVIEW",
  CD_AUTOMATION_REJECTED = "CD_AUTOMATION_REJECTED",
  CD_AUTOMATION_REWORK_REQUIRED = "CD_AUTOMATION_REWORK_REQUIRED",
  CD_AUTOMATION_REQUESTED = "CD_AUTOMATION_REQUESTED",
  PUBLISH_AUTOMATION_TO_AUTOMATION_STORE = "PUBLISH_AUTOMATION_TO_AUTOMATION_STORE",
  REVIEW_CHANGE_REQUEST = "REVIEW_CHANGE_REQUEST"
}

export enum COTaskPopUpMessageSource {
  PROCESS_REPORT = "PROCESS_REPORT",
  PROCESS_REPORTED_COMMENT = "PROCESS_REPORTED_COMMENT",
  ROLE_COMMENT = "ROLE_COMMENT",
  PROCESS_REJECTED_REASON = "PROCESS_REJECTED_REASON"
}
