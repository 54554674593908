import React from "react";
import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import { LocalizeTranslationKey } from "../utils/localizedAlertMessages";
import ToastSwal from "../components/ToastAutomationPipeline/ToastAutomationPipeline";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";

import {
  FETCH_COMPONENT_COMMENTS,
  ADD_NEW_COMPONENT_COMMENT,
  SET_COMPONENT_COMMENT_ID_TO_EDIT,
  SET_COMPONENT_COMMENT_ID_TO_REPLY_TO,
  DELETE_COMPONENT_COMMENT,
  UPDATE_COMPONENT_COMMENT,
  CLEAR_COMPONENT_COMMENTS
} from "../constants/actionTypes";

export function fetchComponentComments(params) {
  const { componentSlug, page = 1, limit = 20 } = params;

  return function(dispatch) {
    axios
      .get(
        `/api/v1/components/slug/${componentSlug}/comments?page=${page}&limit=${limit}`
      )
      .then(response => {
        dispatch({
          type: FETCH_COMPONENT_COMMENTS,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function addComponentComment(params) {
  const { successCallback, failureCallback, ...fields } = params;
  const { comment_component_slug } = params;

  return function(dispatch) {
    axios
      .post(
        `/api/v1/components/slug/${comment_component_slug}/comments/`,
        fields
      )
      .then(response => {
        if (fields.comment_parent_id) {
          axios
            .get(
              `/api/v1/components/slug/${comment_component_slug}/comments/${fields.comment_parent_id}`
            )
            .then(response => {
              dispatch({
                type: UPDATE_COMPONENT_COMMENT,
                payload: response.data.data
              });
              if (typeof successCallback === "function") {
                successCallback();
              }
            })
            .catch(error => {
              apiErrorAlert(error);
              if (typeof failureCallback === "function") {
                failureCallback();
              }
            });
        } else {
          if (typeof successCallback === "function") {
            successCallback();
          }
          dispatch({
            type: ADD_NEW_COMPONENT_COMMENT,
            payload: response.data.data
          });
        }
      })
      .catch(error => {
        apiErrorAlert(error);
        if (typeof failureCallback === "function") {
          failureCallback();
        }
      });
  };
}

export function deleteComponentComment(params) {
  const { comment_component_slug, comment_id, comment_parent_id } = params;
  return function(dispatch) {
    axios
      .delete(
        `/api/v1/components/slug/${comment_component_slug}/comments/${comment_id}`
      )
      .then(response => {
        dispatch({
          type: DELETE_COMPONENT_COMMENT,
          payload: {
            comment_id,
            comment_parent_id
          }
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setComponentCommentIdToEdit(commentID) {
  return function(dispatch) {
    dispatch({
      type: SET_COMPONENT_COMMENT_ID_TO_EDIT,
      payload: commentID
    });
  };
}

export function editComponentComment(params) {
  const { comment_id, component_slug } = params;
  return function(dispatch) {
    axios
      .put(
        `/api/v1/components/slug/${component_slug}/comments/${comment_id}`,
        params
      )
      .then(response => {
        if (params.comment_parent_id) {
          axios
            .get(
              `/api/v1/components/slug/${component_slug}/comments/${params.comment_parent_id}`
            )
            .then(response => {
              dispatch({
                type: UPDATE_COMPONENT_COMMENT,
                payload: response.data.data
              });
            })
            .catch(error => apiErrorAlert(error));
        } else {
          dispatch({
            type: UPDATE_COMPONENT_COMMENT,
            payload: response.data.data
          });
        }
        dispatch({
          type: SET_COMPONENT_COMMENT_ID_TO_EDIT,
          payload: null
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setComponentCommentIdToReplyTo(commentID) {
  return function(dispatch) {
    dispatch({
      type: SET_COMPONENT_COMMENT_ID_TO_REPLY_TO,
      payload: commentID
    });
  };
}

export function clearComponentComments() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_COMPONENT_COMMENTS
    });
  };
}

export function reportAbuseForComponentComment(componentSlug, commentID, data) {
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_saving_report_title"} />
    )
  });
  return function(dispatch) {
    axios
      .post(
        `/api/v1/components/slug/${componentSlug}/comments/${commentID}/report`,
        data
      )
      .then(resp => {
        swalSpinnerClose();
        ToastSwal.fire({
          title: (
            <LocalizeTranslationKey
              localeKey={"info_alert_comment_abuse_reported_content"}
            />
          )
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}
