import React, { Component } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import SessionHelper from "../../helpers/SessionHelper";
import { isValidTenantSlug } from "../../utils/tenantSlugValidator";

class LogoutRedirectPage extends Component {
  componentDidMount() {
    let params = {};
    if (window && window.location && window.location.search) {
      params = queryString.parse(window.location.search);
    }

    let tenant_name = "";

    if (params && params.tenantName && isValidTenantSlug(params.tenantName)) {
      tenant_name = params.tenantName;
    }

    if (!tenant_name) {
      // Fallback to value saved in storage if query param does not exist
      const tenantNameFromStorage =
        SessionHelper.getLoginLogoutTenantNameWithoutPrefix() || "";
      if (tenantNameFromStorage && isValidTenantSlug(tenantNameFromStorage)) {
        tenant_name = tenantNameFromStorage;
      }
    }

    window.location.href = "/" + tenant_name;
  }

  render() {
    return <div style={{ height: "100vh" }}></div>;
  }
}

export default withRouter(LogoutRedirectPage);
