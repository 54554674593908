import { COEmailActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COEmailActionSlugs,
  COEmailActivityType,
  COEmailType
} from "../../../../../constants/actions/co-email-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const contentProducerNotificationEmailActionTemplate = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  // get template based on slug
  let template = contentProducerNotificationEmailActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      email_type: COEmailType.CONTENT_PRODUCER_NOTIFICATION,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const contentProducerNotificationEmailActionTemplateFromSlug = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  switch (slug) {
    case COEmailActionSlugs.ASSESMENT_LAUNCHED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER]
          },
          legacy_email_id: 5,
          email_activity_type: COEmailActivityType.ASSESSMENT_LAUNCHED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
          ],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessment launched"
          },
          email_body: {
            value: "assesment_launched"
          }
        }
      };
    }
    case COEmailActionSlugs.RESUBMIT_IDEA_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER]
          },
          legacy_email_id: 6,
          email_activity_type: COEmailActivityType.RESUBMIT_IDEA,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Resubmit idea"
          },
          email_body: {
            value: "resubmit_idea"
          }
        }
      };
    }
    case COEmailActionSlugs.PROJECT_MANAGER_ASSIGNED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_email_id: 9,
          email_activity_type: COEmailActivityType.PROJECT_MANAGER_ASSIGNED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROJECT_MANAGER_ASSIGNED,
          platform_event_phase_status_triggers: [],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Project Manager assigned"
          },
          email_body: {
            value: "project_manager_assigned"
          }
        }
      };
    }
    case COEmailActionSlugs.AUTOMATION_RESUBMIT_IDEA_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_email_id: 17,
          email_activity_type: COEmailActivityType.REVIEW_REJECTED_IDEA,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_REJECTED
          ],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Automation idea resubmitted"
          },
          email_body: {
            value: "automation_resubmit_idea"
          }
        }
      };
    }
    case COEmailActionSlugs.ABOUT_EDITING_RIGHTS_GRANTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_email_id: 20,
          email_activity_type:
            COEmailActivityType.PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "About section editing rights granted"
          },
          email_body: {
            value: "about_editing_rights_granted"
          }
        }
      };
    }
    case COEmailActionSlugs.CBA_EDITING_RIGHTS_GRANTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_email_id: 21,
          email_activity_type:
            COEmailActivityType.PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CBA section editing rights granted"
          },
          email_body: {
            value: "cba_editing_rights_granted"
          }
        }
      };
    }
    case COEmailActionSlugs.COMPONENT_EDITING_RIGHTS_GRANTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_email_id: 22,
          email_activity_type:
            COEmailActivityType.PROCESS_COLLABORATOR_COMPONENTS_EDIT_RIGHTS_GRANTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_COMPONENTS_EDIT_RIGHTS_GRANTED,
          settings_ids: [27],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Components section editing rights granted"
          },
          email_body: {
            value: "component_editing_rights_granted"
          }
        }
      };
    }
    case COEmailActionSlugs.REVIEW_COE_DRIVEN_IDEA_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_email_id: 23,
          email_activity_type: COEmailActivityType.COE_DRIVEN_IDEA_SUBMITTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.COE_DRIVEN_IDEA_SUBMITTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review CoE driven idea"
          },
          email_body: {
            value: "review_coe_driven_idea"
          }
        }
      };
    }
    case COEmailActionSlugs.USER_MENTIONED_IN_COMMENT_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMMENT_USER]
          },
          legacy_email_id: 24,
          email_activity_type:
            COEmailActivityType.PROCESS_COMMENT_USER_MENTIONED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COMMENT_USER_MENTIONED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User mentioned in question / comment"
          },
          email_body: {
            value: "user_mentioned_in_comment"
          }
        }
      };
    }
    case COEmailActionSlugs.DOCUMENTATION_EDITING_RIGHTS_GRANTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_email_id: 27,
          email_activity_type:
            COEmailActivityType.PROCESS_COLLABORATOR_DOCUMENTS_EDIT_RIGHTS_GRANTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_GRANTED,
          settings_ids: [25],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Documentation section editing rights granted"
          },
          email_body: {
            value: "documentation_editing_rights_granted"
          }
        }
      };
    }
    case COEmailActionSlugs.AUTOMATION_COMMENT_REPLY_ADDED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 39,
          email_activity_type: COEmailActivityType.PROCESS_COMMENT_REPLY_ADDED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_COMMENT_REPLY_ADDED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Automation comment reply added"
          },
          email_body: {
            value: "automation_comment_reply_added"
          }
        }
      };
    }
    case COEmailActionSlugs.USER_MENTIONED_IN_QUESTION_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMMENT_USER]
          },
          legacy_email_id: 41,
          email_activity_type:
            COEmailActivityType.PROCESS_QUESTION_USER_MENTIONED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_QUESTION_USER_MENTIONED,
          settings_ids: [44],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User mentioned in question"
          },
          email_body: {
            value: "user_mentioned_in_question"
          }
        }
      };
    }
  }
  return undefined;
};
