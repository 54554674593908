import {
  COBaseValidationError,
  COContextInterface,
  COValidationBaseErrorClass,
  COValidationContext,
  COValidationError
} from "../interfaces/co-interfaces";
import { doesExistPositiveContextMatch } from "../utils/co-utils";
import { mergeValidationErrors } from "../utils/co-validation-error.utils";

export const validationContextIsTransform = (
  validation_context: COValidationContext
): boolean => {
  return (
    validation_context &&
    doesExistPositiveContextMatch({
      assessment_customize_transform_edit: 1,
      assessment_customize_transform_view: 1,
      assessment_customize_transform_blur: 1,
      assessment_submission_transform_view: 1,
      assessment_submission_transform_edit: 1,
      assessment_submission_transform_blur: 1
    })
  );
};

export const viewTransformForContext = (
  validation_context?: COValidationContext
): COValidationContext => {
  // making sure it's non-empty
  let contextToReturn: COValidationContext = {
    assessment_customize_transform_view: 0,
    assessment_submission_transform_view: 0
  };
  if (validation_context?.assessment_customize) {
    contextToReturn.assessment_customize_transform_view = 1;
  }
  if (validation_context?.assessment_submission) {
    contextToReturn.assessment_submission_transform_view = 1;
  }
  return contextToReturn;
};
export const editTransformForContext = (
  validation_context?: COValidationContext
): COValidationContext => {
  // making sure it's non-empty
  let contextToReturn: COValidationContext = {
    assessment_customize_transform_edit: 0,
    assessment_submission_transform_edit: 0
  };
  if (validation_context?.assessment_customize) {
    contextToReturn.assessment_customize_transform_edit = 1;
  }
  if (validation_context?.assessment_submission) {
    contextToReturn.assessment_submission_transform_edit = 1;
  }
  return contextToReturn;
};
export const blurTransformForContext = (
  validation_context?: COValidationContext
): COValidationContext => {
  // making sure it's non-empty
  let contextToReturn: COValidationContext = {
    assessment_customize_transform_blur: 0,
    assessment_submission_transform_blur: 0
  };
  if (validation_context?.assessment_customize) {
    contextToReturn.assessment_customize_transform_blur = 1;
  }
  if (validation_context?.assessment_submission) {
    contextToReturn.assessment_submission_transform_blur = 1;
  }
  return contextToReturn;
};

//only want the core error object
export const simplifiedErrorObject = (
  error: COValidationError
): COBaseValidationError => {
  let validKeys = Object.keys(new COValidationBaseErrorClass());
  let baseError = {};
  for (const key of Object.keys(error)) {
    if (validKeys.find(f => f === key)) {
      baseError[key] = error[key];
    }
  }
  return baseError;
};

// global error handling moved out of context class for circular reference dealing with

export const addValidationErrors = (
  context: COContextInterface,
  errors: COValidationError[] = []
) => {
  if (context.validationErrorManager) {
    context.validationErrorManager.validation_errors = mergeValidationErrors(
      context.validationErrorManager.validation_errors,
      errors
    );
  }
};

export const clearValidationErrorsForKey = (
  context: COContextInterface,
  error_key: string
) => {
  let toDelete = validationErrorsForErrorKey(context, error_key);
  if (context.validationErrorManager) {
    context.validationErrorManager.validation_errors = context.validationErrorManager.validation_errors?.filter(
      e => !toDelete.find(d => d === e)
    );
  }
};

export const clearValidationErrors = (context: COContextInterface) => {
  if (context.validationErrorManager) {
    context.validationErrorManager.validation_errors = [];
  }
};
// this is external I think
export const validationErrorsForErrorKeyOrObject = (
  context: COContextInterface,
  error_key: string,
  object: any
) => {
  return [
    ...validationErrorsForErrorKey(context, error_key),
    ...validationErrorsForObject(context, object)
  ];
};
export const validationErrorsForErrorKey = (
  context: COContextInterface,
  error_key: string
) => {
  let validationErrors: COValidationError[] = [];
  if (context.validationErrorManager && error_key.length > 0) {
    for (const err of context.validationErrorManager.validation_errors || []) {
      if (err.error_key?.endsWith(error_key)) {
        validationErrors.push(err);
      }
    }
  }
  return validationErrors;
};
const validationErrorsForObject = (
  context: COContextInterface,
  object: any
) => {
  //loop through and figure out
  let validationErrors: COValidationError[] = [];
  if (context.validationErrorManager) {
    for (const err of context.validationErrorManager.validation_errors || []) {
      if (err.problem_object) {
        if (Object.is(err.problem_object, object)) {
          validationErrors.push(err);
        }
      }
    }
  }
  return validationErrors;
};
