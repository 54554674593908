import React from "react";
import { useTranslation } from "react-i18next";
import "./CellTypeManageComponents.scss";

const CellTypeManageComponents = props => {
  const { component_type = {} } = props;
  const { t } = useTranslation("common");
  return (
    <div
      className="CellTypeManageComponents"
      data-testid="CellTypeManageComponentsContainer"
    >
      <span className="CellTypeManageComponents-text">
        {t([
          "component_type_" + component_type.component_type_slug,
          component_type.component_type_name || ""
        ])}
      </span>
    </div>
  );
};

export default CellTypeManageComponents;
