import React, { useEffect } from "react";
import SessionHelper from "../../helpers/SessionHelper";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import { auth } from "../../utils/auth";
import urlWithTenant from "../../utils/urlHelper";
import { AUTH_TYPE_FPS } from "../../constants/authConstants";

const FpsBackgroundRefreshPage = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    auth.authorize();
  }, [history]);

  return <h1>BACKGROUND REFRESH</h1>;
};
export default FpsBackgroundRefreshPage;
