import {
  COProfileBannerSlugPayload,
  COProfileBannerPayload,
  COProfileBannerInterface,
  COContextInterface,
  COControlInterface
} from "../../../interfaces/co-interfaces";
import {
  COProfileBannerSlugs,
  COConditionSlugs,
  COComponentType,
  COHorizontalAlignment
} from "../../../constants/co-constants";
import { COAssessment } from "../../../classes/co-assessment.class";

export const assessmentBannerFromSlugPayload = (
  slugPayload: COProfileBannerSlugPayload
): COProfileBannerInterface => {
  let payload: COProfileBannerPayload | undefined = slugPayload.payload;
  let slug = slugPayload.slug;
  switch (slug) {
    case COProfileBannerSlugs.UNANSWERED_REQUIRED_CR_QUESTIONS: {
      return {
        slug: slug,
        options: {
          is_hidden: 1,
          condition_overrides: [
            {
              slug: COConditionSlugs.SHOW_WHEN_ANY_REQUIRED_QUESTIONS_UNANSWERED
            },
            { slug: COConditionSlugs.HIDE_IF_USER_CANT_EDIT_PROCESS }
          ]
        },
        controls: [
          {
            options: {
              component_type: COComponentType.CONTROL_BANNER,
              horizontal_alignment: COHorizontalAlignment.LEFT
            },
            meta: {
              title: {
                value:
                  "The Change Request assessment contains unanswered required questions.",
                value_localization_key: "converted_to_cr_banner_title"
              }
            },
            subControls: [
              {
                meta: {
                  title: {
                    value: "See questions",
                    value_localization_key: "converted_to_cr_banner_edit_button"
                  }
                },
                options: {
                  component_type: COComponentType.CONTROL_BUTTON
                },
                controlFunction: ({
                  context,
                  control,
                  option
                }: {
                  context: COContextInterface;
                  control: COControlInterface;
                  option: any;
                }) => {
                  context.function_hooks?.openEditViewForProcess?.({ context });
                }
              }
            ]
          }
        ]
      };
    }
  }
  return {};
};
