import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import { uploadImage } from "./../../../actions/fileTransferActions";
import {
  setUploadedImageURL,
  setUploadImageCompletionFunction
} from "./../../../actions/modalImageUploaderActions";
import { setTenantLogo } from "./../../../actions/userProfileActions";
import swal from "./../../SweetAlert/SweetAlert";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import AddDocumentDropBox from "./../ModalAddUpdateDocument/AddDocumentDropBox/AddDocumentDropBox";
import "./ModalLogoUploader.scss";
import "./ModalLogoUploaderCropper.scss";
import logoHelper from "../../../helpers/tenantLogoHelper";
import { PROFILE_IMAGE } from "../../../constants/fileUploadConstants";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import { Chip } from "@material-ui/core";

class ModalLogoUploader extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      fileData: null,
      imgSrc: "",
      imgSrcExt: "",
      crop: {
        x: 0,
        y: 0,
        height: 100,
        width: 100
      }
    };
  }
  //Image process functions
  base64StringtoFile = (base64String, filename) => {
    let arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  image64toCanvasRef = (canvasRef, image64, pixelCrop) => {
    if (!pixelCrop || !canvasRef) {
      return;
    }
    let canvas = canvasRef;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    let ctx = canvas.getContext("2d");
    let image = new Image();
    image.src = image64;
    image.onload = function() {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
    };
  };

  extractImageFileExtensionFromBase64 = base64Data => {
    return base64Data.substring(
      "data:image/".length,
      base64Data.indexOf(";base64")
    );
  };

  renderUpload = () => {
    if (!this.state.fileData) {
      return (
        <AddDocumentDropBox handleFileChange={this.handleFileChange}>
          <p className="ModalLogoUploader__upload-text-top">
            {/* prettier-ignore */}
            <Trans t={this.props.t} i18nKey="modal_logo_uploader_add_file">
              <span className="ModalImageUploader__upload-text-top-highlight">
                Add file
              </span>
              or drop file here
            </Trans>
          </p>
          <p className="ModalLogoUploader__upload-text-bottom">
            {this.props.t("modal_logo_uploader_file_size")} <br />
            {this.props.t("modal_logo_uploader_file_type")}
          </p>
        </AddDocumentDropBox>
      );
    } else if (this.state.fileData && this.state.fileData.name) {
      return (
        <AddDocumentDropBox
          handleFileChange={this.handleFileChange}
          noClick={true}
          noStyles={true}
        >
          <Chip
            label={this.state.fileData.name}
            onDelete={this.handleClearFile}
          />
        </AddDocumentDropBox>
      );
    }
  };

  handleFileChange = fileData => {
    try {
      if (fileData) {
        let allowedFileTypes = ["jpg", "jpeg", "png", "svg", "bmp"];
        const fileExtension =
          fileData &&
          (fileData.name || "")
            .split(".")
            .pop()
            .toLowerCase();
        const isAllowed =
          fileExtension && allowedFileTypes.indexOf(fileExtension) > -1;

        if (!isAllowed) {
          swal.fire({
            title: this.props.t("modal_logo_uploader_error"),
            text: this.props.t("modal_logo_uploader_error_content"),
            type: "error"
          });
          return;
        }

        //10MB
        if (fileData.size > 10485600) {
          swal.fire({
            title: this.props.t("error_title_file_too_large"),
            text: this.props.t("error_text_file_too_large_5"),
            type: "error"
          });
          return false;
        }

        const myFileItemReader = new FileReader();

        myFileItemReader.addEventListener(
          "load",
          () => {
            const myResult = myFileItemReader.result;
            this.setState(prevState => {
              return {
                imgSrc: myResult,
                imgSrcExt: fileExtension,
                fileData
              };
            });
          },
          false
        );

        myFileItemReader.readAsDataURL(fileData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleClearFile = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileData: null });
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onImageLoaded = (image, croppedAreaPixels) => {
    this.imageRef = image;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const { imgSrc } = this.state;
    this.image64toCanvasRef(canvasRef, imgSrc, croppedAreaPixels);
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    const canvasRef = this.imagePreviewCanvasRef.current;
    const { imgSrc } = this.state;
    this.image64toCanvasRef(canvasRef, imgSrc, croppedAreaPixels);
  };

  renderPreview = () => {
    let activeImageUrl = this.props.uploadedURL;

    if (this.state.fileData) {
      activeImageUrl = URL.createObjectURL(this.state.fileData);
    }

    if (!activeImageUrl && !this.state.fileData) {
      return null;
    }

    if (!this.state.fileData) {
      return (
        <div className="ModalLogoUploader__preview_image-placeholder">
          <img
            className="ModalLogoUploader__preview_image"
            src={activeImageUrl}
            alt="Preview"
          />
        </div>
      );
    } else {
      return (
        <div className="ModalLogoUploader__cropper">
          <div className="ModalLogoUploader__crop-container">
            <ReactCrop
              src={this.state.imgSrc}
              crop={this.state.crop}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              onImageLoaded={this.onImageLoaded}
            />
            <br />
            <canvas
              style={{ display: "none" }}
              ref={this.imagePreviewCanvasRef}
            ></canvas>
          </div>
        </div>
      );
    }
  };

  closeModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.closeModal();
  };

  handleSubmit = e => {
    e.preventDefault();
    const imgSrc = this.state.imgSrc;
    if (imgSrc) {
      const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrcExt = this.state.imgSrcExt;
      const imageData64 = canvasRef.toDataURL("image/" + imgSrcExt);
      const myFilename = "cropped_logo_image." + imgSrcExt;
      //This is the cropped image being uploaded
      const myNewCroppedFile = this.base64StringtoFile(imageData64, myFilename);
      this.props.uploadImage(
        myNewCroppedFile,
        this.saveLinkAfterUpload,
        PROFILE_IMAGE,
        true
      );
    }
  };

  saveLinkAfterUpload = fileData => {
    this.props.setUploadedImageURL(fileData.url);
    logoHelper.setLogo(fileData.url);
    this.props.setTenantLogo(fileData.url);
    if (this.props.uploadedCompletionFunct) {
      if (this.props.uploadedCompletionFunct) {
        this.props.uploadedCompletionFunct(fileData.url);
      }
      this.props.setUploadImageCompletionFunction(null);
    }
    this.props.closeModal();
  };

  render() {
    const modalContentStyle = {
      padding: "0",
      borderRadius: "4px",
      width: "500px",
      maxWidth: "96%"
    };

    const headerStyle = {
      paddingLeft: "20px",
      paddingRight: "20px"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={modalContentStyle}>
        <ModalTemplateOne
          heading={this.props.t(["upload_logo_modal_title", "Upload Logo"])}
          headerStyle={headerStyle}
          onClose={this.props.closeModal}
        >
          <form
            className={`ModalLogoUploader ${this.state.fileData &&
              "ModalLogoUploader-lower-height"}`}
            onSubmit={this.handleSubmit}
            autoComplete="off"
            data-testid="ModalLogoUploader"
          >
            <div className="ModalLogoUploader__preview">
              {this.renderPreview()}
            </div>
            <div className="ModalLogoUploader__main">{this.renderUpload()}</div>
            <div className="ModalLogoUploader__footer">
              <ButtonApolloSecondary type="secondary" onClick={this.closeModal}>
                {this.props.t(["upload_logo_cancel_label", "Cancel"])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                className="ModalLogoUploader__footer-save-btn"
                data-testid="ModalLogoUploader__footer-save-btn"
                type="submit"
                disabled={!this.state.imgSrc}
              >
                {this.props.t(["upload_logo_upload_label", "Upload"])}
              </ButtonApolloPrimary>
            </div>
          </form>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  uploadedURL: state.imageUpload.uploadedURL,
  uploadedName: state.imageUpload.uploadedName,
  uploadedCompletionFunct: state.imageUpload.uploadedCompletion
});

const mapActionsToProps = {
  uploadImage,
  setUploadedImageURL,
  setUploadImageCompletionFunction,
  setTenantLogo
};

export default withTranslation("common")(
  connect(mapStateToProps, mapActionsToProps)(ModalLogoUploader)
);
