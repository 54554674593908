import React, { Component } from "react";
import TextEditorComments, {
  getAllDraftJsDecorators
} from "./../../TextEditorComments/TextEditorComments";
import {
  convertToStringified,
  draftJsCreateWithContentAndPlugins
} from "./../../../utils/editorHelper";
import { EditorState, convertFromRaw } from "draft-js";
import "./../inputWithLabelStyles.scss";
import "./InputRichTextWithLabel.scss";

class InputRichTextWithLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: this.generateStateFromRaw(props.value)
    };

    this.errorRef = React.createRef();
    this.inputTimeout = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value === "" && prevProps.value !== "") {
      this.setState({
        editorState: EditorState.createEmpty(getAllDraftJsDecorators())
      });
    } else if (this.props.updateValue && this.props.value !== prevProps.value) {
      this.setState({
        editorState: this.generateStateFromRaw(this.props.value)
      });
    }
    if (this.props.error && !prevProps.error && this.errorRef.current) {
      this.errorRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }

    if (!prevProps.manualUpdate && this.props.manualUpdate) {
      this.setState({
        editorState: this.generateStateFromRaw(this.props.value)
      });
    }
  }

  generateStateFromRaw = value => {
    let startText = "";
    try {
      let text = JSON.parse(value);
      startText = draftJsCreateWithContentAndPlugins(convertFromRaw(text));
    } catch (e) {
      startText = EditorState.createEmpty(getAllDraftJsDecorators());
    }

    return startText;
  };

  handleEditorChange = editorState => {
    this.setState({ editorState: editorState });
    const newValue = convertToStringified(editorState);

    // Only update parent if the value is different to prevent infinite render loop
    if (newValue !== this.props.value) {
      let eventToFire = () => {
        const fakeEvent = {
          target: {
            name: this.props.name,
            value: convertToStringified(editorState)
          }
        };
        typeof this.props.onChange === "function" && this.props.onChange(fakeEvent);
      };
      if (this.props.input_debounce_ms) {
        clearTimeout(this.inputTimeout?.current);
        this.inputTimeout.current = setTimeout(() => {
          eventToFire();
        }, this.props.input_debounce_ms);
      } else {
        eventToFire();
      }
    }
  };

  handlePastedText = pastedText => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;

    if (currentContentLength + pastedText.length > this.props.maxLength - 1) {
      return "handled";
    }
  };

  render() {
    const {
      readOnly,
      label,
      placeholder = "",
      error = "",
      required = false,
      hideInlineStyling,
      className = "",
      maxLength,
      hideBlockStyling,
      autoFocus,
      extraStyling,
      ariaLabel,
      ariaLabelledBy,
      focusOnLoad
    } = this.props;

    const classes = ["inputWithLabelStyles", "InputRichTextWithLabel"];

    let handleChange = this.handleEditorChange;

    if (className) {
      classes.push(className);
    }

    return (
      <div className={classes.join(" ")}>
        {label ? (
          <div className="inputWithLabelStyles__label">
            {label}
            {required && (
              <span className="inputWithLabelStyles__label-required">*</span>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="inputWithLabelStyles__input-wrapper">
          <TextEditorComments
            onChange={handleChange}
            editorState={this.state.editorState}
            hideInlineStyling={hideInlineStyling}
            readOnly={readOnly}
            placeholder={placeholder}
            autoFocus={autoFocus}
            hideBlockStyling={hideBlockStyling}
            extraStyling={extraStyling}
            focusOnLoad={focusOnLoad}
            maxLength={maxLength}
            ariaLabel={ariaLabel}
            ariaLabelledBy={ariaLabelledBy}
          />
        </div>
        {error && (
          <p className="inputWithLabelStyles__error" ref={this.errorRef}>
            {error}
          </p>
        )}
      </div>
    );
  }
}

export default InputRichTextWithLabel;
