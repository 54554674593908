import { COContext } from "../classes/co-context.class";
import {
  COComponentType,
  COComponentStyles,
  COCalculatedValueTypes,
  CORichTextOptions,
  COValidationType,
  COProcessSubmissionType,
  COContextObjectKey,
  COPathPartType,
  COPermissionSlugs,
  COModalType,
  COHorizontalAlignment,
  COVerticalAlignment,
  COLoadableProcessFields,
  COAssessmentTypes
} from "../constants/co-constants";
import {
  COPhaseStatusActions,
  COPhaseStatusKeys
} from "../constants/co-phase-status.constants";
import {
  COActionTypes,
  COUserActionType
} from "../constants/actions/co-action-constants";
import {
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../constants/actions/co-notification-action-constants";
import {
  COEmailType,
  COEmailActivityType,
  COEmailActionSlugs
} from "../constants/actions/co-email-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../constants/actions/co-platform-events-constants";
import {
  COTaskType,
  COTaskActivityType,
  COTaskPopUpMessageSource
} from "../constants/actions/co-task-action-constants";
import { CORoleType } from "../constants/actions/co-roles.constants";
import { COEmailReminderActionSlugs } from "../constants/actions/co-email-reminder-action-constants";
import { COTenantSettingsKeys } from "../constants/co-tenant-settings.constants";

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export interface COBaseBase {
  readonly co_assessment_id?: number;
  is_top_level_only?: boolean;
}

interface COLoadFunction {
  (source: string, subString: string): any;
}
interface COSaveFunction {
  (source: string, subString: string): any;
}

interface COObjectClassMap {
  objectKey: any;
  objectClass: any;
  objectLoadFunction?: COLoadFunction;
  objectSaveFunction?: COSaveFunction;
}

export interface COObjectClassMappingInterface {
  dbClass: any;
  objectClass: any;
  arrays: COObjectClassMap[];
  objects: COObjectClassMap[];
  contextKey?: COContextObjectKey;
  ahid_key?: string;
}

export interface COBaseInterface extends Writeable<COBaseBase> {
  isVisibleInContext?(context?: COContextInterface): boolean;
  objectDisplayName?(includeObjectType?: boolean): string;
  findObjectsWithPropertyOfValue?(
    property: string,
    value: any
  ): COBaseInterface[];
  findAHId?(ahid: string): COBaseInterface | null;
  findAHIds?(ahid: string): COBaseInterface[];
  getAHID?(): string | undefined;
  incrementRenderCount?(): void;
  onRemoveFromAssessment?(context?: COContextInterface): void;
  objectClassMapping?: COObjectClassMappingInterface;
  contextForOptionsAndMetaMerging?: COContextInterface;
  renderCounter?: number;
  controls?: COControlInterface[];
  validators?: COValidationItemInterface[];
  calculation_options?: COCalculationInterface;
  meta?: COMetadataInterface;
  options?: COOptionsInterface;
  template?: COBaseTemplateInterface;
}

// interfaces with database tables backing them
export interface COAAddSectionInterface {
  context: COContextInterface;
  assessment: COAssessmentInterface;
  section?: COSectionInterface;
  rawValuesFromTemplate?: any;
}

export class COAssessmentDBClass {
  constructor(
    readonly co_assessment_id?: number,
    readonly co_assessment_ahid?: string,
    readonly co_assessment_type?: string,
    readonly category_id?: number,
    readonly co_assessment_template_slug?: string,
    readonly co_assessment_meta_json?: COMetadataInterface,
    readonly co_assessment_version_meta_json?: COVersionMetadataInterface,
    readonly co_assessment_options_json?: COOptionsInterface,
    readonly is_deleted?: number,
    readonly created_epoch?: number,
    readonly updated_epoch?: number
  ) {}
}

export interface COAssessmentInterface
  extends Writeable<COAssessmentDBClass>,
    COBaseInterface {
  co_process_assessment?: COProcessAssessmentInterface;
  co_assessment_sections?: COSectionInterface[];
  num_processes_on_version?: number;
  validation_errors?: COValidationError[];
  version_meta?: COVersionMetadataInterface;
  functionAllQuestions?(context: COContextInterface): COQuestionInterface[];
  functionNumVisibleSections?(context: COContextInterface);
  functionAreAllRequiredQuestionsAnswered?(
    context: COContextInterface
  ): boolean;
  functionHasUserAnsweredQuestionInAssessment?(
    context: COContextInterface
  ): boolean;
  functionAreAnyQuestionsAnswered?(context: COContextInterface): boolean;
  functionAllVisibleBanners?(
    context: COContextInterface
  ): COProfileBannerInterface[];
}

export interface COProfileBannerSlugPayload {
  slug?: string;
  payload?: COProfileBannerPayload;
}

export interface COProfileBannerPayload {}

export interface COProfileBannerInterface {
  slug?: string;
  controls?: COControlInterface[];
  options?: COOptionsInterface;
}

export class COSectionDBClass {
  constructor(
    readonly co_assessment_section_id?: number,
    readonly co_assessment_section_ahid?: string,
    readonly co_assessment_id?: number,
    readonly co_section_template_slug?: string,
    readonly co_section_meta_json?: COMetadataInterface,
    readonly co_section_position_json?: COPositionInterface,
    readonly co_section_options_json?: COOptionsInterface,
    readonly created_epoch?: number,
    readonly updated_epoch?: number
  ) {}
}

export interface COSectionInterface
  extends Writeable<COSectionDBClass>,
    COBaseInterface {
  co_questions?: COQuestionInterface[];
  addTabularSectionRow?({ context, co_table_row_index });
  removeTabularSectionRow?({ context, co_table_row_index });
  recalculateTabularIndexes?({ context, section });
}

export class COQuestionDBClass {
  constructor(
    readonly co_question_id?: number,
    readonly co_question_ahid?: string,
    readonly co_question_co_type?: string,
    readonly co_question_template_slug?: string,
    readonly co_assessment_id?: number,
    readonly co_question_meta_json?: COMetadataInterface,
    readonly co_question_position_json?: COPositionInterface,
    readonly co_question_options_json?: COOptionsInterface,
    readonly co_question_default_options_json?: COQuestionDefaultOptionsInterface,
    readonly co_variable_name?: string,
    readonly co_equation?: string,
    readonly co_calculated_value_key?: string,
    readonly created_epoch?: number,
    readonly updated_epoch?: number,
    readonly co_question_parent_ahid?: string
  ) {}
}

export interface COQuestionInterface
  extends Writeable<COQuestionDBClass>,
    COBaseInterface {
  co_display_equation?: string;
  co_question_answer_options?: COQuestionAnswerOptionInterface[];
  co_question_answer_option_templates?: COQuestionAnswerOptionTemplateInterface[];
  co_process_answer?: COProcessAnswerInterface;
  co_process_calculated_value?: COCalculatedValueInterface;
  co_table_row_index?: number;
  cache?: any;
  setNewTemplate?(
    context: COContextInterface,
    questionTemplate: COQuestionTemplateInterface
  ): void;
  defaultValueInContext?(context: COContextInterface): any;
  updateDisplayEquation?({
    context,
    rawEquation
  }: {
    context: COContextInterface;
    rawEquation?: string;
  }): any;
  updateRawEquation?({
    context,
    displayEquation
  }: {
    context: COContextInterface;
    displayEquation?: string;
  }): any;
  initializeNewProcessAnswer?({ context }: { context?: COContextInterface });
  updateParent?(co_object: COBaseInterface);
  updateCalculatedValueKey?(context: COContextInterface | undefined);
  setQuestionTableRowIndex?(co_table_row_index?: number);
  functionQuestionIsAnswered?(context: COContextInterface): boolean;
}

export class COQuestionAnswerOptionDBClass {
  constructor(
    readonly co_question_answer_option_id?: number,
    readonly co_question_answer_option_ahid?: string,
    readonly co_question_ahid?: string,
    readonly co_assessment_id?: number,
    readonly co_question_answer_option_template_slug?: string,
    readonly co_question_answer_option_value?: string,
    readonly co_question_answer_option_meta_json?: COMetadataInterface,
    readonly co_question_answer_option_options_json?: COOptionsInterface,
    readonly co_question_answer_option_position_json?: COPositionInterface,
    readonly created_epoch?: number,
    readonly updated_epoch?: number
  ) {}
}
export interface COQuestionAnswerOptionInterface
  extends Writeable<COQuestionAnswerOptionDBClass>,
    COBaseInterface {
  updateQuestion?(question: COQuestionInterface): any;
  functionBranchingPathsControls?(
    context: COContextInterface
  ): COMetadataInterface | undefined;
}

export class COCalculatedValueDBClass {
  constructor(
    readonly co_calculated_value_id?: number,
    readonly co_assessment_id?: number,
    readonly transaction_state?: number,
    readonly created_transaction_state?: number,
    readonly process_id?: number,
    readonly co_question_id?: number,
    readonly co_table_row_index?: number,
    readonly co_calculated_value_key?: string,
    readonly co_calculated_value?: string,
    readonly co_calculated_display_value?: string,
    readonly co_calculated_value_type?: string,
    readonly process_column_name?: string,
    readonly created_epoch?: number,
    readonly updated_epoch?: number
  ) {}
}
export interface COCalculatedValueInterface
  extends Writeable<COCalculatedValueDBClass>,
    COBaseInterface {
  validation_errors?: COValidationError[];
}

export class COCalculatedValueHistoryDBClass {
  constructor(
    readonly co_calculated_value_history_id?: number,
    readonly process_id?: number,
    readonly process_history_version?: number,
    readonly co_question_id?: number,
    readonly co_assessment_id?: number,
    readonly co_calculated_value_key?: string,
    readonly co_calculated_value?: string,
    readonly co_calculated_display_value?: string,
    readonly co_calculated_value_type?: string,
    readonly process_column_name?: string,
    readonly created_epoch?: number,
    readonly updated_epoch?: number
  ) {}
}
export interface COCalculatedValueHistoryInterface
  extends Writeable<COCalculatedValueHistoryDBClass>,
    COBaseInterface {}

export class COProcessAssessmentDBClass {
  constructor(
    readonly co_assessment_id?: number,
    readonly process_id?: number,
    readonly last_calculated_epoch?: number
  ) {}
}

export interface COProcessAssessmentInterface
  extends Writeable<COProcessAssessmentDBClass>,
    COBaseInterface {}

export class COProcessAssessmentMappingDBClass {
  constructor(
    readonly co_process_assessment_mapping_id?: number,
    readonly co_assessment_id?: number,
    readonly co_assessment_type?: string,
    readonly process_submission_type?: number,
    readonly category_id?: number,
    readonly co_process_assessment_mapping_json?: string,
    readonly updated_epoch?: number,
    readonly created_epoch?: number
  ) {}
}

export interface COProcessAssessmentMappingInterface
  extends Writeable<COProcessAssessmentMappingDBClass> {}

export interface CODownloadableProcessAssessmentMappingInterface
  extends COProcessAssessmentMappingInterface {
  co_assessment?: COAssessmentInterface;
}

export class COProcessAnswerDBClass {
  constructor(
    readonly co_process_answer_id?: number,
    readonly co_assessment_id?: number,
    readonly process_id?: number,
    readonly user_id?: number,
    readonly co_process_answer_ahid?: string,
    readonly co_process_answer_template_slug?: string,
    readonly transaction_state?: number,
    readonly created_transaction_state?: number,
    readonly co_question_id?: number,
    readonly co_question_ahid?: string,
    readonly co_table_row_index?: number,
    readonly co_process_answer_meta_json?: COMetadataInterface,
    readonly created_epoch?: number
  ) {}
}

export interface COProcessAnswerInterface
  extends Writeable<COProcessAnswerDBClass>,
    COBaseInterface {
  co_process_answer_validation?: COValidationInterface;
  co_process_answer_selections?: COProcessAnswerSelectionInterface[];
  addAdditionalAnswerSelection?({
    context,
    question,
    answerOption
  }: {
    context: COContextInterface;
    question: COQuestionInterface;
    answerOption: COQuestionAnswerOptionInterface;
  }): any;
  //FE helper function
  selectOrUpdateOption?({
    context,
    question,
    answerOption
  }: {
    context: COContextInterface;
    question: COQuestionInterface;
    answerOption: COQuestionAnswerOptionInterface;
  }): COProcessAnswerSelectionInterface | undefined;
  deSelectOption?({
    context,
    answerOption
  }: {
    context: COContextInterface;
    answerOption: COQuestionAnswerOptionInterface;
  }): void;
}

export class COProcessAnswerHistoryDBClass {
  constructor(
    readonly co_process_answer_history_id?: number,
    readonly process_id?: number,
    readonly process_history_version?: number,
    readonly user_id?: number,
    readonly co_assessment_id?: number,
    readonly co_question_id?: number,
    readonly co_process_answer_ahid?: string,
    readonly co_question_answer_option_ahid?: string,
    readonly co_process_answer_value?: string,
    readonly co_process_answer_meta_json?: COMetadataItemInterface,
    readonly created_epoch?: number
  ) {}
}

export interface COProcessAnswerHistoryInterface
  extends Writeable<COProcessAnswerHistoryDBClass>,
    COBaseInterface {
  co_process_answer_selections?: COProcessAnswerSelectionHistoryInterface[];
}

export class COProcessAnswerSelectionDBClass {
  constructor(
    readonly co_process_answer_selection_id?: number,
    readonly co_process_answer_selection_ahid?: string,
    readonly co_process_answer_id?: number,
    readonly co_process_answer_ahid?: string,
    readonly co_question_answer_option_id?: number,
    readonly co_question_answer_option_ahid?: string,
    readonly co_process_answer_selection_input?: string,
    readonly co_question_answer_option_value?: string,
    readonly co_process_answer_selection_info_json?: any,
    readonly created_epoch?: number
  ) {}
}

export interface COProcessAnswerSelectionInterface
  extends Writeable<COProcessAnswerSelectionDBClass>,
    COBaseInterface {
  co_question_answer_option?: COQuestionAnswerOptionInterface;
}

export class COProcessAnswerSelectionHistoryDBClass {
  constructor(
    readonly co_process_answer_selection_history_id?: number,
    readonly co_process_answer_selection_ahid?: string,
    readonly co_process_answer_history_id?: number,
    readonly co_process_answer_ahid?: string,
    readonly co_question_answer_option_id?: number,
    readonly co_question_answer_option_ahid?: string,
    readonly co_process_answer_selection_input?: string,
    readonly co_question_answer_option_value?: string,
    readonly co_process_answer_selection_info_json?: any,
    readonly created_epoch?: number
  ) {}
}

export interface COProcessAnswerSelectionHistoryInterface
  extends Writeable<COProcessAnswerSelectionHistoryDBClass>,
    COBaseInterface {
  co_question_answer_option?: COQuestionAnswerOptionInterface;
}

export interface COBaseTemplateInterface {
  co_validators_json?: COValidationItemInterface[];
  co_controls_json?: COControlInterface[];
  co_options_json?: COOptionsInterface;
  co_calculation_json?: COCalculationInterface;
  co_meta_json?: COMetadataInterface;
  co_version_meta_json?: COVersionMetadataInterface;
}

export class COAssessmentTemplateDBClass {
  constructor(
    readonly co_assessment_template_id?: number,
    readonly co_assessment_template_slug?: string,
    readonly co_assessment_type?: string,
    readonly co_assessment_template_meta_json?: COMetadataInterface,
    readonly co_assessment_json?: COAssessmentInterface
  ) {}
}

export interface COAssessmentTemplateInterface
  extends Writeable<COAssessmentTemplateDBClass>,
    COBaseTemplateInterface {}

export class COSectionTemplateDBClass {
  constructor(
    readonly co_section_template_id?: number,
    readonly co_section_template_slug?: string,
    readonly co_section_template_meta_json?: COMetadataInterface,
    readonly co_section_json?: COSectionInterface
  ) {}
}

export interface COSectionTemplateInterface
  extends Writeable<COSectionTemplateDBClass>,
    COBaseTemplateInterface {}

export class COQuestionTemplateDBClass {
  constructor(
    readonly co_question_template_id?: number,
    readonly co_question_template_slug?: string,
    readonly co_question_template_co_type?: string,
    readonly co_question_template_answer_option_template_slugs?: string,
    readonly co_question_template_meta_json?: COMetadataInterface,
    readonly co_question_json?: COQuestionInterface
  ) {}
}

export interface COQuestionTemplateInterface
  extends Writeable<COQuestionTemplateDBClass>,
    COBaseTemplateInterface {
  co_question_answer_option_templates?: COQuestionAnswerOptionTemplateInterface[];
}

export class COQuestionAnswerOptionTemplateDBClass {
  constructor(
    readonly co_question_answer_option_template_id?: number,
    readonly co_question_answer_option_template_slug?: string,
    readonly co_question_answer_option_template_meta_json?: COMetadataInterface,
    readonly co_question_answer_option_json?: COQuestionAnswerOptionInterface
  ) {}
}

export interface COQuestionAnswerOptionTemplateInterface
  extends Writeable<COQuestionAnswerOptionTemplateDBClass>,
    COBaseTemplateInterface,
    COBaseInterface {}

export class COProcessAnswerTemplateDBClass {
  constructor(
    readonly co_process_answer_json?: COProcessAnswerInterface,
    readonly co_process_answer_template_slug?: string
  ) {}
}
export interface COProcessAnswerTemplateInterface
  extends Writeable<COProcessAnswerTemplateDBClass>,
    COBaseTemplateInterface {}

export interface COProcessAnswerInterface
  extends Writeable<COProcessAnswerDBClass>,
    COBaseInterface {
  co_process_answer_validation?: COValidationInterface;
  co_process_answer_selections?: COProcessAnswerSelectionInterface[];
}

// json object interfaces (all optional)
export interface COBaseDataInterface {
  ahid?: string;
}

export interface COColumnInfoInterface extends Writeable<COQuestionDBClass> {
  process_column_name?: string;
}

export interface COCalculationModifierInterface {
  currency?: CurrencyInterface;
  currency_division_factor?: number;
}

export interface CODocumentOptionsInterface {
  allowedFileTypes?: any[];
  documentTypeId?: any;
  maxAllowedFileSize?: number;
  allowedFileTypesMessage?: string;
  allowedFileTypesMetaItem?: COMetadataItemInterface;
}

export interface COContextsByType {
  [key: string]: COContextInterface;
}
export interface COAssessmentsByType {
  [key: string]: COAssessmentInterface;
}

// context interface
export interface COContextInterface {
  assessment?: COAssessmentInterface;
  section?: COSectionInterface;
  question?: COQuestionInterface;
  process_answer?: COProcessAnswerInterface;
  process_answer_selection?: COProcessAnswerSelectionInterface;
  answer_option?: COQuestionAnswerOptionInterface;
  validationErrorManager?: COValidationErrorManagerInterface;
  process_external_data?: COProcessExternalDataInterface;
  templates?: COTemplatesInterface;
  validation_context?: COValidationContext;
  display_context?: CODisplayContext;
  function_hooks?: COFunctionHookInterface;
  calculation_modifiers?: COCalculationModifierInterface;
  comparison_context?: COContextInterface;
  action?: COActionInterface;
  update?(contextInterface: COContextInterface): COContextInterface;
  setFullAssessment?({ assessment }: { assessment: COBaseInterface });
  functionAllOtherSectionsAndQuestions?(
    context: COContextInterface
  ): [COSectionInterface[], COQuestionInterface[]];
}

export interface COExternalCallbackInterface {
  applicationsCallback?: (applications: COApplicationInfoItem[]) => void;
  documentsCallback?: (documents: any[]) => void;
  userPickerCallback?: ({ user: any, external_data_source: string }) => void;
  mediaCallback?: (media: any[]) => void;
  processCallback?: ({ updatedData }: { updatedData: any }) => void;
  similarAutomationCallback?: (similar_automations: any[]) => void;
  componentsCallback?: (components: any[]) => void;
  tagsCallback?: (tags: any[]) => void;
  categoriesCallback?: (categories: any[]) => void;
  collaboratorsCallback?: (collaborators: any[]) => void;
}

export interface COPositionInterface extends COBaseDataInterface {
  sort_order?: number;
  rules?: COPositionRuleInterface[];
}

export interface COChartInterface {
  title?: COMetadataItemInterface;
  component_type?: string;
  x_axis_ahid?: string;
  y_axis_ahid?: string;
  bubble_ahid?: string;
  x_axis_range?: string;
  y_axis_range?: string;
  bubble_range?: string;
  left_value_ahid?: string;
  right_value_ahid?: string;
  gauge_main_ahid?: string;
}

// trying to organize this better - these are used by everything
interface COCoreOptionsInterface extends COBaseDataInterface {
  component_type?: COComponentType;
  component_style?: COComponentStyles;
  is_hidden?: number | string;
  is_disabled?: number | string;
  display_context_overrides?: CODisplayContextMetaDataOverrides;
  visibility_permissions?: string | string[];
  condition_overrides?: COConditionOverrideSlugPayload[];
  accessibility_options?: COAccessibilityOptionsInterface;
}

interface COAssessmentOptionsInterface extends COCoreOptionsInterface {
  optional_for_submission?: number | string;
  co_system_build_version?: string;
  optional_for_submission_with_warning?: number | string;
  co_profile_banners?: COProfileBannerSlugPayload[];
}

interface COSectionOptionsInterface extends COCoreOptionsInterface {
  is_read_only?: string | number;
  disable_adding_applications?: string | number;
  disable_adding_documents?: string | number; // TODO path this into TenantSettingss
  is_tabular?: number;
}

interface COQuestionOptionsInterface extends COCoreOptionsInterface {
  hide_comments_section?: number | string;
  can_impact_kpi?: number | string;
  no_tabular_support?: number | string;
  pre_fill_default_answer?: number | string;
  pre_fill_default_answer_function?: (
    context: COContextInterface
  ) => void | string;
  optional_for_submission?: number | string;
  max_selectable_answers?: number | string;
  min_selectable_answers?: number | string;
  min_question_answer_options?: number | string;
  max_question_answer_options?: number | string;
  rounding_decimals?: number | string;
  chart?: COChartInterface | string;
  legacy_slug?: string;
  pipeline_filter_options_slug?: string;
  pipeline_filter_options?: COFilterOptionItemInterface[] | string;
  pipeline_sort_options?: COSortOptionsInterface;
  external_answer_source?: string;
  referenced_calculated_value_process_column_name?: string;
  calculated_value_type?: string | COCalculatedValueTypes;
  unit?: COUnitInterface | string;
  max_value?: number | string;
  min_value?: number | string;
  prevent_deleting?: number | string;
  is_read_only?: string | number;
  document_options?: CODocumentOptionsInterface;
  undefined_value?: string; // we have cases where -1 means undefined, we can use this to not show a display value - TOOD check if we need this
  excluded_from_branching_paths_selection?: number;
  supports_branching_paths?: number | string;
  max_answer_length?: number | string;
}
interface COQuestionAnswerOptionOptionsInterface
  extends COCoreOptionsInterface {
  legacy_slug?: string;
  calculated_value_type?: string | COCalculatedValueTypes;
  document_options?: CODocumentOptionsInterface;
}

interface COMetaOptionsInterface extends COCoreOptionsInterface {
  multi_line?: number | string;
  rich_text_controls?: string | CORichTextOptions;
  is_copyable?: number | string;
}

interface COControlOptionsInterface extends COCoreOptionsInterface {
  horizontal_alignment?: COHorizontalAlignment;
  vertical_alignment?: COVerticalAlignment;
}

interface COAccessibilityOptionsInterface {
  aria_labelled_by?: string;
}

export interface COActionOptionsInterface {
  action_target?: COActionTargetInterface;
  is_inactive?: number;
  is_deleted?: number;
}

interface COActionTargetInterface {
  target_roles?: string | string[];
  target_user_ids?: string | string[];
  process_collaborators?: string | string[];
  process_followers?: string | string[];
  process_interested_users?: string | string[];
  process_business_reviewers?: string | string[];
  process_technical_reviewers?: string | string[];
  process_assessed_idea_reviewers?: string | string[];
  dealhub_users?: string | string[];
  finance_users?: string | string[];
  mentioned_users?: string | string[];
  non_user_recipient_email?: string;
}

export interface CONotificationActionOptionsInterface {
  legacy_notification_id?: number;
  platform_event_type?: COPlatformEventType;
  platform_event_phase_status_triggers?: COPhaseStatusKeys[]; // These are the phase/statuses that can trigger this action when the platform event is PROCESS_PHASE_CHANGED
  notification_type?: CONotificationActionType;
  notification_category_type?: CONotificationActionCategoryTypes;
  notification_group_slug?: CONotificationActionGroupSlugs;
  settings_ids?: number[];
}

export interface COEmailActionOptionsInterface {
  legacy_email_id?: number;
  email_type?: COEmailType;
  email_activity_type?: COEmailActivityType;
  email_category_type?: COCategoryType;
  platform_event_type?: COPlatformEventType;
  platform_event_phase_status_triggers?: COPhaseStatusKeys[]; // These are the phase/statuses that can trigger this action when the platform event is PROCESS_PHASE_CHANGED
  settings_ids?: number[];
  slug_for_email_reminder?: string;
}

export interface COTaskActionOptionsInterface {
  legacy_task_id?: number;
  task_type?: COTaskType;
  task_activity_type_id?: number;
  task_activity_type?: COTaskActivityType;
  task_category_type?: COCategoryType;
  platform_event_type?: COPlatformEventType;
  platform_event_phase_status_triggers?: COPhaseStatusKeys[]; // These are the phase/statuses that can trigger this action when the platform event is PROCESS_PHASE_CHANGED
  task_auto_completion_criteria?: COTaskActionAutoCompletionCriteriaInterface;
  settings_ids?: number[];
  task_completion?: COTaskActionCompletionInterface;
  task_popup_message_source?: COTaskPopUpMessageSource;
  email_reminder_options?: COTaskEmailReminderOptionsInterface;
}

export interface COTaskEmailReminderOptionsInterface {
  email_reminder_slug?: COEmailReminderActionSlugs;
  send_reminder_delay?: number;
  number_reminders_to_send?: number;
}

export interface COEmailReminderActionOptionsInterface {
  original_task_action_id?: number;
  email_template_meta_slug?: COEmailActionSlugs;
  send_reminder_delay?: number;
  number_reminders_to_send?: number;
}

interface COTaskActionCompletionInterface {
  task_is_completed?: number;
  task_completed_by_user_id?: number;
  task_completed_epoch?: number;
}

interface COTaskActionAutoCompletionCriteriaInterface {
  moved_to_phase_status?: COPhaseStatusKeys[]; // These are the phase/statuses that can trigger this task to be auto-completed when the process is moved to them
  process_collaborator_role_added?: CORoleType[]; // If any of these roles are added to the process as a collaborator, the task will be auto-completed
}

interface COUserActionOptionsInterface {
  has_seen_action?: number | string;
  is_cleared?: number | string;
}

export interface COOptionsInterface
  extends COAssessmentOptionsInterface,
    COSectionOptionsInterface,
    COQuestionOptionsInterface,
    COQuestionAnswerOptionOptionsInterface,
    COMetaOptionsInterface,
    COControlOptionsInterface,
    COActionOptionsInterface,
    CONotificationActionOptionsInterface,
    COEmailActionOptionsInterface,
    COTaskActionOptionsInterface,
    COUserActionOptionsInterface,
    COEmailReminderActionOptionsInterface {}

export interface COPositionRuleInterface {}

export interface COMetadataInterface extends COBaseDataInterface {
  prefix?: COMetadataItemInterface;

  title?: COMetadataItemInterface;
  title_header?: COMetadataItemInterface;
  tabular_title?: COMetadataItemInterface;

  message?: COMetadataItemInterface;
  contextual_message?: COMetadataItemInterface | string;
  redirect_url?: COMetadataItemInterface;
  contextual_redirect_url?: COMetadataItemInterface;
  email_title?: COMetadataItemInterface;
  contextual_email_title?: COMetadataItemInterface;
  email_body?: COMetadataItemInterface;
  contextual_email_body?: COMetadataItemInterface;
  note?: COMetadataItemInterface;
  description?: COMetadataItemInterface;
  comment?: COMetadataItemInterface;
  prefill?: COMetadataItemInterface;
  variable?: COMetadataItemInterface;
  variable_configuration?: COMetadataItemInterface;
  equation?: COMetadataItemInterface;

  chart?: COMetadataItemInterface;
  // user for question answer options
  answer_value?: COMetadataItemInterface;
  answer_value_header?: COMetadataItemInterface;

  //for showing a whole bunch of places - mostly view profile
  calculated_value?: COMetadataItemInterface;

  // can be used to show inline controls in the coeditorjson
  controls?: COMetadataItemInterface;
  // we can easily add more

  // branching
  branching?: COMetadataItemInterface;

  // tabular
  tabular?: COMetadataItemInterface;

  //misc
  debug?: COMetadataItemInterface;
  suffix?: COMetadataItemInterface;
}

export interface CODisplayContext {
  assessment_none?: number;
  assessment_view?: number;
  assessment_view_sidebar?: number;
  assessment_customize?: number;
  assessment_customize_advanced?: number;
  assessment_customize_modal?: number;
  assessment_submission?: number;
  assessment_submission_sidebar?: number;
  all_pipelines?: number;
  automation_pipeline?: number;
  decision_pipeline?: number;
  idea_submissions_pipeline?: number;
  automation_submissions_pipeline?: number;
  following_pipeline?: number;
  collaborations_pipeline?: number;
  search_filter?: string;
}
export interface CODisplayContextMetaDataOverrides {
  assessment_view?: COMetadataItemInterface | COOptionsInterface;
  assessment_view_sidebar?: COMetadataItemInterface | COOptionsInterface;
  assessment_customize?: COMetadataItemInterface | COOptionsInterface;
  assessment_customize_advanced?: COMetadataItemInterface | COOptionsInterface;
  assessment_customize_modal?: COMetadataItemInterface | COOptionsInterface;
  assessment_submission?: COMetadataItemInterface | COOptionsInterface;
  all_pipelines?: COMetadataItemInterface | COOptionsInterface;
  automation_pipeline?: COMetadataItemInterface | COOptionsInterface;
  decision_pipeline?: COMetadataItemInterface | COOptionsInterface;
  idea_submissions_pipeline?: COMetadataItemInterface | COOptionsInterface;
  automation_submissions_pipeline?:
    | COMetadataItemInterface
    | COOptionsInterface;
  following_pipeline?: COMetadataItemInterface | COOptionsInterface;
  collaborations_pipeline?: COMetadataItemInterface | COOptionsInterface;
}

export interface COValidationContext {
  assessment_view?: number;
  assessment_submission?: number;
  assessment_submission_strict_validation?: number;
  assessment_submission_transform_view?: number;
  assessment_submission_transform_edit?: number;
  assessment_submission_transform_blur?: number;
  assessment_customize?: number;
  assessment_customize_update?: number;
  assessment_customize_transform_edit?: number;
  assessment_customize_transform_view?: number;
  assessment_customize_transform_blur?: number;
  comparison_context?: COContextInterface | null;
}

export interface COLocalizedStringValuesInterface {
  value?: string;
  localization_key?: string;
  localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
}

export interface COLocalizedStringGroupingInterface {
  values?: COLocalizationReplacementType[];
  separator?: string;
  separator_localization_key?: string;
}

export interface COLocalizedStringValuesLinkInterface {
  value?: string;
  localization_key?: string;
  url?: string;
}

export type COLocalizationReplacementType =
  | string
  | number
  | COLocalizedStringGroupingInterface
  | COLocalizedStringValuesInterface
  | COLocalizedStringValuesLinkInterface;

export interface COMetadataItemInterface extends COBaseDataInterface {
  label?: string;
  label_localization_key?: string;
  label_localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  tooltip?: string;
  tooltip_localization_key?: string;
  tooltip_localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  placeholder?: string;
  placeholder_localization_key?: string;
  placeholder_localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  format?: COStringFormat | string; // it can be a reference to an other object
  value?: string;
  value_localization_key?: string;
  value_localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  value_path_array?: COPathPart[];
  is_editable?: boolean;
  options?: COOptionsInterface;
  position?: COPositionInterface;
  display_context?: CODisplayContext;
  display_context_overrides?: CODisplayContextMetaDataOverrides;
  required?: string | number;
  help_text?: string;
  help_text_localization_key?: string;
  help_text_localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  autocomplete?: COAutoCompleteInfo;
  controls?: COControlInterface[] | string;
  apollo_icon?: string;
  color?: string; // for icon
}

export interface COAutoCompleteInfo {
  options?: string | string[];
}

export interface COVersionMetadataInterface extends COMetadataInterface {
  version_number?: COMetadataItemInterface;
  version?: COMetadataItemInterface;
  version_release_notes?: COMetadataItemInterface;
  version_status?: COStatusKeys;
}

export interface COQuestionDefaultOptionsInterface extends COBaseDataInterface {
  co_question_answer_option_ahid?: string;
  co_question_ahid?: string;
  co_process_answer_input?: string;
  co_process_answer_value?: string;
  default_equation_value?: string | number;
}

export interface COCalculationInterface extends COBaseDataInterface {
  is_auto_selected?: number | string;
  input_is_value?: number | string;
  answer_value_is_question_ahid?: number | string;
}

export interface COStringFormat {
  prefix?: string;
  suffix?: string;
  modifier?: string;
  currency?: CurrencyInterface | string;
  enum_options?: COEnumOptionItemInterface[] | string;
}

export interface COUnitInterface extends COBaseDataInterface {
  slug?: string;
  target?: string;
  meta?: COMetadataItemInterface;
  title_format?: COStringFormat;
  display_value_format?: COStringFormat;
  input_value_format?: COStringFormat;
  validations?: COValidationItemInterface[];
  is_custom?: number | string;
}

export interface COValidationInterface extends COBaseDataInterface {
  validations?: COValidationItemInterface[];
}

export interface COValidationFunction {
  ({
    context,
    validation_context,
    value,
    target,
    error
  }: {
    context: COContextInterface;
    validation_context: COValidationContext;
    value: any;
    target: any;
    error: COValidationError;
  }): COValidationError[];
}

export interface COValidationItemInterface extends COBaseDataInterface {
  target?: string;
  validationSlug?: string; // for handling very specific issues
  meta?: COMetadataItemInterface;
  validationType?: COValidationType; // max_value, min_value, max_length, min_length, array_length, required,
  validationFunction?: COValidationFunction;
  value?: number | string; // for targeting paths
  is_disabled?: number | string;
  validation_context?: COValidationContext;
  targetParent?: any; // calculated at resolve time - not saved to object
}

export interface COControlOptionListItem {
  key?: string;
  value?: any | string;
  checked?: boolean;
  meta?: COMetadataItemInterface;
  options?: COOptionsInterface;
}

export interface COControlFunctionParamsInterface {
  context: COContextInterface;
  control: COControlInterface;
  option: any;
  parentControl?: COControlInterface;
}
export interface COControlFunction {
  (COControlFunctionParamsInterface): any;
}

export interface COControlToggleOverrideValues {
  isChecked?: ({
    context: COContextInterface,
    control: COControlInterface
  }) => boolean;
  trueValue?: any;
  falseValue?: any;
}

export interface COControlInterface extends COBaseDataInterface {
  key?: string;
  meta?: COMetadataInterface;
  options?: COOptionsInterface;
  position?: COPositionInterface;
  target?: string;
  targetBindings?: string[];
  controlFunction?: COControlFunction;
  optionIsSelectedFunction?: COControlFunction;
  items?: COControlOptionListItem[] | string;
  toggleOverrideValues?: COControlToggleOverrideValues;
  subControls?: COControlInterface[];
}

export enum COControlInterfaceKeys {
  CONTROL_TEMPLATE = "TEMPLATE",
  CONTROL_ROUNDING = "ROUNDING",
  CONTROL_UNITS = "UNITS",
  CONTROL_VISIBILITY = "VISIBILITY"
}

export interface COEnumOptionItemInterface {
  KEY: string;
  VALUE: number | string;
}

export enum COStatusKeys {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED"
}

export interface COConditionOverride {
  slug?: string; // key for checking if exists in selection list
  meta?: COMetadataItemInterface; // title for selection
  priority?: number;
  condition?: COCondition;
  conditions?: COCondition[];
  remove_condition_override_slugs_when_selected?: COConditionOverrideSlugPayload[];
  add_condition_override_slugs_when_selected?: COConditionOverrideSlugPayload[];
  remove_condition_override_slugs_when_deselected?: COConditionOverrideSlugPayload[];
  add_condition_override_slugs_when_deselected?: COConditionOverrideSlugPayload[];
}

export interface COConditionOverridePayload {
  legacy_slugs?: string[];
  pathRoute?: string;
  desired_value?: any;
  phase_status_key?: string;
  next_phase_status_key?: string;
  co_question_ahid?: string;
  co_question_answer_option_ahid?: string;
  co_calculated_value_key?: string;
}

// this is used to store a reference to a condition and a payload in the db, we need something like this for conditional logic
export interface COConditionOverrideSlugPayload {
  slug?: string;
  payload?: COConditionOverridePayload;
}

export interface COFunctionHookInterface {
  showModal?: (props: {
    context: any;
    modalType: COModalType;
    payload: any;
  }) => void;
  showSwalPopUp?: (props: {
    context: any;
    co_swal_pop_up: COSwalPopUpInterface;
    callback?: (confirmed: boolean) => void;
  }) => void;
  documentSelectPopup?: (props: {
    context: any;
    question?: any;
    documentOptions: CODocumentOptionsInterface;
    callback: Function;
  }) => void;
  openEditViewForProcess?: (props: { context: COContextInterface }) => void;
  determineUrlBasedOnService?: (props: {
    context: COContextInterface;
    url: string;
  }) => string | null;
  triggerUiRender?: () => void;
  translateFunction?: (key: string | string[], options?: any) => string;
}
export interface COSwalPopUpInterface {
  title: string;
  title_localization_key: string;
  message: string;
  message_localization_key: string;
  show_cancel_button?: boolean;
  confirm_button_text?: string;
  confirm_button_text_localization_key?: string;
}
// we can have more
export interface COTemplatesInterface {
  units?: COUnits;
  question_type_control_options?: COControlOptionListItem[];
  question_type_advanced_control_options?: COControlOptionListItem[];
  kpi_type_control_options?: COControlOptionListItem[];
  kpi_type_advanced_control_options?: COControlOptionListItem[];
}

export enum COSortValue {
  ASC = "ASC",
  DESC = "DES"
}

export interface COSortInterface {
  sort_value: COSortValue;
}

export enum COFilterType {
  CALCULATED_VALUE_FILTER = "co_value_filter",
  PROCESS_SELECTION_FILTER = "co_selection_filter",
  PROCESS_MULTI_SELECTION_FILTER = "co_multi_selection_filter",
  DATE_PICKER_FILTER = "co_date_picker_filter",
  CATEGORY_PICKER = "co_category_picker_filter",
  USERS_FILTER = "co_users_filter",
  DROPDOWN = "dropdown" //deprecated filter type key
}

export enum COTableCellType {
  PLAIN_TEXT = "co-table-plain-text",
  PILLS = "co-table-pills-type",
  LINKS = "co-table-links-type",
  USERS = "co-table-users-type",
  PROFILE_LINK = "co-table-profile-link-type"
}
export enum COFilterTargetType {
  CALCULATED_VALUE = "CALCULATED_VALUE", // assumed default
  CALCULATED_DISPLAY_VALUE = "CALCULATED_DISPLAY_VALUE",
  ANSWER_VALUE = "ANSWER_VALUE", // assumed default
  ANSWER_TITLE_VALUE = "ANSWER_TITLE_VALUE"
}
export enum COQueryConditionOperator {
  EQUALS = "=",
  NOT_EQUALS = "!=",
  LESS_THAN = "<",
  MORE_THAN = ">",
  LESS_THAN_OR_EQUALS = "<=",
  MORE_THAN_OR_EQUALS = ">=",
  BETWEEN = "BETWEEN",
  IN = "in"
}

export enum COPipelineDisplayContextKey {
  AUTOMATION_PIPELINE = "automation_pipeline_all",
  AUTOMATION_PIPELINE_REVIEW = "automation_pipeline_review",
  AUTOMATION_PIPELINE_IMPLEMENTATION = "automation_pipeline_implementation",
  AUTOMATION_PIPELINE_LIVE = "automation_pipeline_live",
  AUTOMATION_PIPELINE_DECISION_PIPELINE = "automation_pipeline_decision_pipeline",
  IDEA_SUBMISSION_PIPELINE = "idea_submissions_pipeline", // (my ideas)
  AUTOMATION_SUBMISSION_PIPELINE = "automation_submissions_pipeline", // (my automatins)
  CHANGE_REQUEST_SUBMISSION_PIPELINE = "change_requests_submissions_pipeline", // (my change requests)
  FOLLOWING_PIPELINE = "following_pipeline", // following
  COLLABORATIONS_PIPELINE = "collaborations_pipeline", // my collaborations
  PROCESSES = "processes", // explore automations (automations and ideas),
  AUTOMATIONS = "automations", // just automations
  AUTOMATIONS_TO_CONSUME = "automations_to_consume", // just automations
  IDEAS = "ideas" // just ideas
}

export enum COPipelineDefaultSort {
  AUTOMATION_PIPELINE = "process_created_epoch",
  DECISION_PIPELINE = "process_estimated_benefit_score",
  IDEA_SUBMISSION_PIPELINE = "process_created_epoch",
  CHANGE_REQUEST_SUBMISSION_PIPELINE = "process_created_epoch",
  AUTOMATION_SUBMISSION_PIPELINE = "process_created_epoch",
  FOLLOWING_PIPELINE = "process_created_epoch",
  COLLABORATIONS_PIPELINE = "process_updated_epoch"
}

export interface COPipelineProcessInCacheInterface {
  process_id: number;
  in_cache: number;
}
export interface COPipelineFieldsAndColumnInterface {
  fields: any[];
  metaData?: any;
  filters?: COPipelineQueryFilterInterface;
  columns?: COPipelineColumnInterface[];
}

export interface COPipelineQueryFilterInterface {
  page?: number;
  limit?: number;
  sort?: string;
  order?: string;
  s?: string;
  submissionType?: string[];
  categoryIds?: string[];
  dept?: string[];
  bu?: string[];
  softwareVendor?: string[];
  licenseType?: string[];
  phases?: string[];
  status?: string[];
  collaborators?: string[];
  score?: string[];
  numRobots?: string[];
  processType?: string[];
  priorities?: string[];
  start?: number;
  end?: number;
  csvDownload?: boolean;
  developmentType?: string[];
  automationPublished?: string[];
  applicationIds?: string[];
  automationIds?: string[];
  tags?: string[];
  processIds?: string[];
  pre?: string;
  columns?: string[];
  detailedAssessmentVersion?: string[];
}
export interface COFilterQueryCondition {
  values?: string | string[];
  operator?: COQueryConditionOperator;
  targetType?: COFilterTargetType; // use COFilterTargetType.ANSWER_VALUE by default
}

export interface COFilterOptionItemInterface {
  meta?: COMetadataItemInterface;
  filter_object?: any; /// this could be a user object or a full application or anything
  filter_option_key?: string;
  filter_query_condition?: COFilterQueryCondition;
}

export enum COSortType {
  NONE = "NONE",
  NUMERIC = "NUMERIC",
  ALPHABETICAL = "ALPHABETICAL"
}

export interface COSortOptionsInterface {
  sort_type?: COSortType;
}

export interface LegacyWorkspaceColumnHeaderMetaDataInterface {
  display: string;
  key: string;
  filterType: COFilterType;
  selectionType: string;
  filterable: boolean;
  filterValues?: any;
  sort: string;
  sortOrder: number;
  grouping?: {
    display: string;
    key: string;
    sortOrder: number;
    groupSortOrder: number;
  };
}

export interface COPipelineColumnInterface
  extends LegacyWorkspaceColumnHeaderMetaDataInterface {
  resolved_meta?: COMetadataInterface;
  column_key?: string;
  column_tooltip?: string;
  co_calculated_value_key?: string;
  co_calculated_value_type?: COCalculatedValueTypes | string;
  unit?: COUnitInterface;
  filter_options?: COFilterOptionItemInterface[];
  sort_options?: COSortOptionsInterface;
  position?: COPositionInterface;
  selected_filter_options?: COFilterOptionItemInterface[];
  input_is_value?: boolean;
  cellType?: COTableCellType;
}

export interface COFilterSelectionInterface {
  col?: string; // column key
  keys?: string[]; // filter_option_key
}

export interface COCalculatedValueQuestionInfoInterface {
  process_column_name?: any;
  max?: number;
  min?: number;
  question?: COQuestionInterface;
}

export interface COProcessAssessmentParameters {
  process_id?: number;
  co_assessment_id?: number;
  co_assessment_type?: string;
  display_context?: CODisplayContext;
  includeQuestionAnswerOptions?: boolean;
  topLevelOnly?: boolean;
  bypassPermissions?: boolean;
}

export const shouldIncludeQuestionAnswerOptions = (
  display_context: CODisplayContext
): boolean => {
  if (display_context) {
    if (
      display_context.assessment_view ||
      display_context.assessment_view_sidebar
    ) {
      return false;
    }
  }
  return true;
};

export interface CORawQuery {
  rawString: string;
  parameters: any[];
}

export interface COCondition {
  // the conditions array here act like an OR , if any are true it's true
  or_conditions?: COConditionCalculationInterface[];
  display_context?: CODisplayContext;
  overrides?: COOptionsInterface;
}

export const defaultVisibilityDisplayContext: CODisplayContext = {
  assessment_submission: 1,
  assessment_view: 1
};

export interface COConditionCalculationInterface {
  co_calculated_value_key?: string;
  process_column_name?: string;
  co_question_ahid?: string;
  operator?: COVisibilityConditionOperator;
  desired_value?: string | boolean | number; // can be a path potentially
  current_value?: string | boolean | number; // can be path
  debug_key?: string; // for making it easier to debug - set to anything to have that logged out during calculatins
  // if any conditions are here - they are AND so the current condition and all of the conditions in the AND condition need to be true
  and_conditions?: COConditionCalculationInterface[];
  or_conditions?: COConditionCalculationInterface[];
}

export enum COVisibilityConditionOperator {
  EQUALS = "=",
  NOT_EQUALS = "!=",
  LESS_THAN = "<",
  MORE_THAN = ">",
  LESS_THAN_OR_EQUALS = "<=",
  MORE_THAN_OR_EQUALS = ">=",
  NOT_EXISTS = "NOT_EXISTS", // THESE are used for the visibility calculations as well
  EXISTS = "EXISTS",
  MIN_PHASE_OR_STATUS = "MIN_PHASE_OR_STATUS",
  MAX_PHASE_OR_STATUS = "MAX_PHASE_OR_STATUS",
  BETWEEN_PHASE_OR_STATUSES = "BETWEEN_PHASE_OR_STATUSES", // this expects a desired value of min,max (comma sep phase or status keys)
  HAS_COMMON_ELEMENT = "HAS_COMMON_ELEMENT",
  HAS_NO_COMMON_ELEMENT = "HAS_NO_COMMON_ELEMENT",
  HAS_ALL_COMMON_ELEMENTS = "HAS_ALL_COMMON_ELEMENTS"
}

export interface COApplicationInfoItem {
  application_id?: number;
  application_name?: string;
  application_version?: string;
  application_language?: string;
  application_code?: string;
  application_is_citrix_client?: boolean;
}

export interface COApplicationInfo {
  applications?: COApplicationInfoItem[];
  allApplications?: COApplicationInfoItem[];
}

export interface COCollaborator {
  user_id?: number;
  commment?: string;
  user_roles?: COCollaboratorUserRole[];
}

interface COCollaboratorUserRole {
  permission_buckets?: string[];
  process_id?: number;
  role_admin_console_assignable?: number;
  role_description?: string;
  role_has_assignment_toggle?: number;
  role_id?: number;
  role_is_active?: 0 | 1;
  role_is_default?: 0 | 1;
  role_is_editable?: 0 | 1;
  role_is_renamable?: 0 | 1;
  role_is_used_in_automation?: 0 | 1;
  role_is_visible?: 0 | 1;
  role_name?: string;
  role_number_assignable?: number;
  role_order_number?: number;
  role_slug?: string;
  role_type?: string;
}

export interface COCollaboratorInfo {
  process_owner?: COCollaborator | null;
  process_submitter?: COCollaborator | null;
  collaborators?: COCollaborator[];
}

export interface COMediaInterface {
  media_id_or_something: any;
}

export interface COMediaInfo {
  media?: COMediaInterface[];
}

export interface COTagInterface {
  tag_id: any;
  tag_whatever: any;
}

export interface COTagInfo {
  tags?: COTagInterface[];
}

export interface COSimilarAutomationInfo {
  processes?: COProcess[];
}
export interface COComponentInterface {
  component_id?: number;
  component_slug?: string;
  component_name?: string;
  component_reject_reason?: string;
  component_status_id?: number;
  component_semantic_version?: string;
  latest_published_version?: string;
  latest_published_semantic_version?: string;
  latest_published_release_notes?: string;
  component_created_epoch?: number;
  component_updated_epoch?: number;
}

export interface COComponentInfo {
  components?: COComponentInterface[];
}

export interface COCategoryInfo {
  categories?: any[];
  selected_categories?: any[];
  user_can_not_edit_categories?: boolean;
  levels?: any[];
}

export interface CODocumentInfo {
  documents?: any[];
}

export interface CODocumentInterface {
  document_id?: number;
  document_title?: string;
}

// I did NOT choose these hypen keys - SUPER annoying to deal with
export interface COCOTenantSettingsInterface {
  ["file-upload-display"]?: number;
  ["task-capture-display"]?: number;
  ["components-display"]?: number;
  ["leaderboard-display"]?: number;
  ["cost-benefit-analysis-display"]?: number;
  ["date-format"]?: number;
  ["decimal-separator"]?: number;
  ["executable-file-display"]?: number;
  ["citizen-development-display"]?: number;
  ["share-automation-source-code-mandatory"]?: number;
  ["automation-store"]?: number;
  ["instant-consumption"]?: number;
  ["display-user-gamification-data"]?: number;
  ["display-explore-users-and-profiles"]?: number;
}

export interface COContainerSizeInterface {
  width?: number;
  height?: number;
}

export interface CurrencyInterface {
  currency_abbreviation?: string; // "USD"
  currency_id?: number; // 1
  currency_is_active?: number; // 1
  currency_name?: string; // "United States Dollar"
  currency_sort_order?: number; // 1
  currency_symbol?: string; // "$"
}
export const defaultCurrency: CurrencyInterface = {
  currency_abbreviation: "USD",
  currency_id: 1,
  currency_is_active: 1,
  currency_name: "United States Dollar",
  currency_sort_order: 1,
  currency_symbol: "$"
};

export interface COPathPart {
  type: COPathPartType;
  property: string;
  arrayIndex?: number;
  contextualValue?: any;
  operatorFCT?: Function;
  operatorParams?: any;
}

export enum COValidationSlugs {
  KPI_EQUATION_MATCH = "KPI_EQUATION_MATCH",
  KPI_VARIABLE_CHANGED = "KPI_EQUATION_MATCH",
  KPI_TYPE_CHANGED = "KPI_TYPE_CHANGED",
  QUESTION_EQUATION_MATCH = "QUESTION_EQUATION_MATCH",
  QUESTION_REMOVED = "QUESTION_REMOVED",
  REFERENCE_QUESTION_ADDED_OR_REMOVED = "REFERENCE_QUESTION_ADDED_OR_REMOVED",
  REFERENCE_QUESTION_REFERENCE_CHANGED = "REFERENCE_QUESTION_REFERENCE_CHANGED",
  QUESTION_TYPE_CHANGED = "QUESTION_TYPE_CHANGED",
  QUESTION_VARIABLE_CHANGED = "QUESTION_VARIABLE_CHANGED",
  QUESTION_ANSWER_OPTION_VALUE_CHANGED = "QUESTION_ANSWER_OPTION_VALUE_CHANGED",
  QUESTION_ANSWER_OPTION_REMOVED = "QUESTION_ANSWER_OPTION_REMOVED",
  QUESTION_CANNOT_BE_SHOWN_IN_TABULAR_FORMAT = "QUESTION_CANNOT_BE_SHOWN_IN_TABULAR_FORMAT",
  SECTION_IS_TABULAR_MATCH = "SECTION_IS_TABULAR_MATCH"
}

export interface COValidationErrorManagerInterface {
  validation_errors?: COValidationError[];
}

export class COValidationBaseErrorClass {
  readonly error_message?: string;
  readonly error_localization_key?: string;
  readonly error_localization_values?: {
    [key: string]:
      | string
      | number
      | COLocalizedStringGroupingInterface
      | COLocalizedStringValuesInterface;
  };
  readonly error_key?: string;
  readonly problem_property?: string;
  readonly validationItem?: COValidationItemInterface; // the original one
  readonly severity?: string;
  readonly error_type?: string; // this could be metadata, calculation, impacts_calculation
}
export interface COBaseValidationError
  extends Writeable<COValidationBaseErrorClass> {}

export interface COValidationError extends COBaseValidationError {
  resolvedValidationItem?: COValidationItemInterface; // the one with the paths rendered out
  problem_object?: COBaseInterface | any;
  target_path_parts?: COPathPart[];
  transformedValue?: any;
  section?: COSectionInterface;
}

export interface COPathOverrideInterface {
  path: string;
  value: any;
}

export interface COAssessmentUpdateValidationInfo {
  assessment?: COAssessmentInterface;
  original_assessment?: COAssessmentInterface;
  validation_errors?: COValidationError[];
  can_update?: boolean;
}

export interface BackwardsCompatibleCOProcess extends COProcess {
  cd_advanced_information?: any;
  high_level_assessment?: any;
  process_headcount?: number | null;
  process_is_potential_duplicate?: any;
  process_east_of_baseline_score?: any;
  process_AHT_employee_per_transaction?: any;
  process_rules_comment?: any;
  process_input_type_comment?: any;
  process_input_quality_comment?: any;
  process_stability_comment?: any;
  process_document_availability_comment?: any;
  project_manager_user_id?: any;
  process_type_id?: any;
  cba_process_type_id?: any;
  est_benefit_ftes?: any;
  number_of_employees?: any;
  process_benefit_expressed_in_currency_per_year?: any;
  total_rework_time?: any;
  total_processing_time?: any;
  total_review_audit_time?: any;
  total_time_needed_to_perform_work?: any;
  process_average_handling_time?: any;
  process_digital_input_percent?: any;
  process_goal?: any;
  goal?: any;
  process_goal__display?: any;
  process_goal_slug?: any;
  process_automation_score?: any;
  process_ease_of_implementation_score?: any;
  process_estimated_benefit_score?: any;
  process_ease_of_implementation_level__display?: any;
  process_cost_per_year?: any;
  process_volume?: any;
  process_rules?: any;
  process_input_type?: any;
  process_stability?: any;
  process_input_quality?: any;
  process_document_availability?: any;
  pdd_link?: any;
  employee_average_full_cost?: any;
  average_error_rate?: any;
  average_review_audit_time?: any;
  average_rework_time?: any;
  average_work_to_be_reviewed?: any;
  employee_average_working_days?: any;
  employee_working_hours?: any;
  detailed_assessment_version?: any;
  launch_date?: any;
  process_volumes_per_year?: number;
  process_suitability_score?: number;
  process_readiness_score?: number;
  software_vendor_id?: any;
  software_cost_id?: any;
  cost_description?: any;
  number_of_robots?: any;
  idea_phase_approver?: any;
  launch_assessment_approver?: any;
  qualification_phase_approver?: any;
  process_type?: any;
  software_vendor?: any;
  running_cost?: any;
  ftr_overridden?: any;
  est_implementation_costs?: any;
  est_running_costs?: any;
  est_net_benefit_year_one?: any;
  est_net_benefit_year_two?: any;
  analysis_baseline?: any;
  analysis_delta?: any;
  analysis_actual?: any;
  solution_design_baseline?: any;
  solution_design_delta?: any;
  solution_design_actual?: any;
  development_baseline?: any;
  development_delta?: any;
  development_actual?: any;
  testing_baseline?: any;
  testing_delta?: any;
  testing_actual?: any;
  structured_input?: any;
  application_stability?: any;
  process_length?: any;
  ftes_required?: any;
  decision_complexity?: any;
  task_stability?: any;
  scanned_inputs?: any;
  frequency?: any;
  cost_frequency?: any;
  implementation_tier?: any;
  estimated_implementation_costs?: any;
  estimated_running_costs?: any;
  estimated_net_benefit_year_one?: any;
  estimated_net_benefit_year_two?: any;
}
export enum BackwardsCompatibilityLoadingOptions {
  HIGH_LEVEL_ASSESSMENT = "HIGH_LEVEL_ASSESSMENT",
  DETAILED_ASSESSMENT = "DETAILED_ASSESSMENT",
  CITIZEN_DEVELOPER_ASSESSMENT = "CITIZEN_DEVELOPER_ASSESSMENT",
  PIPELINE_COLUMNS = "PIPELINE_COLUMNS",
  PROCESS_COLUMNS = "PROCESS_COLUMNS",
  CBA_COLUMNS = "CBA_COLUMNS",
  USER_DATA = "USER_DATA",
  PROCESS_USER_OBJECTS = "PROCESS_USER_OBJECTS",
  PROCESS_OBJECTS = "PROCESS_OBJECTS",
  LEGACY_CUSTOM_QUESTIONS = "LEGACY_CUSTOM_QUESTIONS",
  PROCESS_URL = "PROCESS_URL",
  PLAIN_TEXT_CONVERSION = "PLAIN_TEXT_CONVERSION"
}

export enum COJSONFormAssessmentLoadingOptions {
  FETCH_LEVEL_ONE_CATEGORIES = "LEVEL_ONE_CATEGORIES",
  ALLOW_NO_OWNER = "ALLOW_NO_OWNER"
}

export interface COProcess {
  process_id?: any;
  process_submission_type?: COProcessSubmissionType;
  process_slug?: string | null;
  process_name?: string | null;
  process_description?: string | null;
  process_challenges_encountered?: string | null;
  process_challenges_expected?: string | null;
  process_lessons_learned?: string | null;
  process_benefits_expected?: string | null;
  process_benefits_actual?: string | null;
  process_num_followers?: number;
  process_num_votes?: number;
  process_num_media?: number;
  process_num_documents?: number;
  process_num_downloads?: number;
  process_num_collaborators?: number;
  process_num_components?: number;
  process_num_comments?: number;
  process_num_applications?: number;
  process_num_similar_automations?: number;
  process_num_change_requests?: number;
  process_automation_score?: number;
  process_ease_of_implementation_score?: number;
  process_estimated_benefit_score?: number;
  process_benefit_expressed_in_currency_per_year?: number;
  process_pain_points?: string | null;
  process_negative_impact?: string | null;
  process_priority?: any;
  skipCache?: any;
  process_phase_id?: any;
  process_phase_status_id?: any;
  phase_status_id?: any;
  process_phase?: any;
  process_phase_status?: any;
  phase_status_key?: any;
  phase_status_name?: any;
  co_assessments?: { [key: string]: COAssessmentInterface };
  advanced_information?: any;
  is_published?: any;
  process_volumes_per_year?: any;
  process_ease_of_implementation_level?: any;
  user_is_following?: any;
  process_published_text?: any;
  process_published_text_key?: any;
  user_has_voted?: any;
  categories?: any;
  process_submitter?: COUserInterface | null;
  readable_submission_time?: any;
  process_owner?: COUserInterface | null;
  permissions?: any;
  currency?: any;
  orchestrator_automation_uuid?: any;
  process_estimated_benefits_ftes?: any;
  process_development_type?: any;
  media?: any;
  applications?: any;
  documents?: any[];
  components?: any[];
  packages?: any[]; //orchestrator packages
  similar_automations?: any;
  source_code?: any; // document object
  automation_potential_benefit_per_employee?: any;
  estimated_benefit_hours_per_employee?: any;
  estimated_cost_benefit_per_employee?: any;
  estimated_benefit_ftes_per_employee?: any;
  automation_potential_benefit_across_company?: any;
  accepted_to_compliance_and_privacy?: any;
  process_review_score?: any;
  process_num_reviews?: any;
  process_num_requests?: any;
  process_num_questions?: any;
  user_has_reviewed?: any;
  user_has_requested?: any;
  process_published_info?: any;
  process_tags?: any;
  process_submission_source_link?: any;
  cba_automation_potential?: any;
  cba_ease_of_implementation?: any;
  // per company
  cba_estimated_benefit_score?: any;
  cba_estimated_benefit_cost?: any;
  cba_estimated_benefit_ftes?: any;
  // per employee
  cba_estimated_benefit_hours_per_employee?: any;
  cba_estimated_cost_benefit_per_employee?: any;
  cba_estimated_benefit_ftes_per_employee?: any;
  // ah_flow_warnings?: any;
  relevance?: any; // stripped out
  sort_column?: string; // stripped out
  processURL?: any;
  collaborators?: any;

  process_owner_user_id?: any;
  process_overall_gauge_score?: any;
  process_context?: COProcessContextInterface;
  co_phase_status_action?: COPhaseStatusActions;

  process_submitter_user_id?: any;
  process_rejected_reason?: string;
  process_archived_reason?: string;

  process_history_version?: any;
  process_updated_epoch?: number;
  process_created_epoch?: number;

  automation_store_benefit_employees_hrs_mo?: number;

  initial_category_id?: number;

  specific_fields_to_load?: string[];
  process_is_published?: any;

  parent_process_id?: any;
  parent_process?: any;
  collaboratorsShowLimit?: number;

  jira_ticket_link?: any;

  disableEmails?: boolean; // used from import pipeline
}

export interface COProcessContextInterface {
  calculated_values?: { [key: string]: COCalculatedValueInterface };
  co_contexts?: COContextsByType;
  process_external_data?: COProcessExternalDataInterface;
  process_callbacks?: COExternalCallbackInterface;
  display_context?: CODisplayContext;
  updateFromProcess?(data: COProcessExternalDataInterface);
  updateDisplayContext?(display_context: CODisplayContext);
}

export interface COProcessExternalDataInterface {
  process_id?: number;
  application_data?: COApplicationInfo;
  category_data?: COCategoryInfo;
  document_data?: CODocumentInfo;
  currency?: CurrencyInterface;
  permissions?: AHPermissionsInterface;
  collaborator_data?: COCollaboratorInfo;
  media_data?: COMediaInfo;
  similar_automations?: COSimilarAutomationInfo;
  component_data?: COComponentInfo;
  process?: COProcess;
  tag_data?: COTagInfo;
  phase_status_key?: COPhaseStatusKeys;
  post_action_phase_status_key?: COPhaseStatusKeys;
  global_calculated_values?: { [key: string]: COCalculatedValueInterface }; //for sharing calculated values across assessments
  refereced_question_control_options?: COControlOptionListItem[]; //for sharing calculated values across assessments
  externallyLoadReferencedQuestions?: ({
    co_assessment_type,
    onLoaded,
    onError
  }: {
    co_assessment_type: COAssessmentTypes;
    onLoaded: (controlOptions: any[]) => void;
    onError: (error: any) => void;
  }) => void;
  container_size?: COContainerSizeInterface;
  user?: COUserInterface;
  referenced_user?: COUserInterface;
  component?: COComponentInterface;
  component_comment?: COCommentInterface;
  document?: CODocumentInterface;
  comment?: COCommentInterface;
  template?: COProcessTemplateInterface;
  role?: CORoleInterface;
  reported_process?: COReportedProcessInterface;
  process_reported_comment?: COProcessReportedCommentInterface;
  process_question?: COProcessQuestionInterface;
  tenant?: COTenantInterface;
  user_attributes?: string;
  process_role_name?: string;
  collaborator_roles?: string;
  permission_roles?: string;
  collaborators?: { [key: string]: COUserInterface }; // JOE let's mae this generic for any collaborator role
  beginningOfEmail?: string;
  endOfEmail?: string;
  emailTitle?: string;
  emailTemplate?: string;
  reminderEmailTitlePrefix?: string;
  reminderEmailBodyPrefix?: string;
  process_phase?: string;
  process_status?: string;
  process_rejected_reason?: string;
  process_archived_reason?: string;
  component_rejected_reason?: string;

  dataSyncApplicationsProcessCallback?: (
    applications: COApplicationInfoItem[]
  ) => void;
  dataSyncDocumentsProcessCallback?: (documents: any) => void;
  dataSyncCollaboratorsProcessCallback?: (collaborators: any) => void;
  dataSyncUserCallback?: ({ user: any, external_data_source: string }) => void;
  dataSyncMediaCallback?: (media: any) => void;
  dataSyncProcessPropertyCallback?: ({
    updatedData
  }: {
    updatedData: any;
  }) => void;
  dataSyncSimilarAutomationsCallback?: (similar_automations: any) => void;
  dataSyncComponentsCallback?: (components: any) => void;
  dataSyncTagsCallback?: (tags: any) => void;
  dataSyncCategoryCallback?: (selected_categories: any[]) => void;
  updateFromProcess?: (data: COProcessExternalDataInterface) => void;
  syncUserPicker?({
    context,
    user,
    external_data_source
  }: {
    context: COContextInterface;
    user: any;
    external_data_source: string;
  });
  syncMedia?(media: any);
  syncCollaborators?(collaborators: any);
  syncComponents?(components: any);
  syncDocuments?(documents: any);
  syncSimilarAutomations?(automations: any);
  syncApplications?(newApplications: COApplicationInfoItem[]);
  syncTags?(tags: any);
  syncSelectedCategories?(categories: any[]);
  functionExternalPropertyValueForQuestion?(context: COContextInterface);
  functionReferencedCalculatedValue?(context: COContextInterface);
  functionLazyLoadedQuestionOptionsForReferenceControl?(
    context: COContextInterface
  );
}
export interface AHPermissionsInterfaceProperties {
  j_impossible_permission?: number;
  s_q2_sensitive_information?: number;
  s_cba_sensitive_information?: number;
  p_edit?: number;
}
export interface AHPermissionsInterface {
  ah_permissions?: AHPermissionsInterfaceProperties;
  process_permissions?: AHPermissionsInterfaceProperties;
}

export interface COUnits {
  none?: COUnitInterface;
  ease_of_implementation_level?: COUnitInterface;
  days_year?: COUnitInterface;
  hours_year?: COUnitInterface;
  hours_month?: COUnitInterface;
  hours_day?: COUnitInterface;
  minutes?: COUnitInterface;
  hours?: COUnitInterface;
  days?: COUnitInterface;
  years?: COUnitInterface;
  currency?: COUnitInterface;
  percent?: COUnitInterface;
  submission_type?: COUnitInterface;
  development_type?: COUnitInterface;
  automation_type?: COUnitInterface;
  yesno?: COUnitInterface;
}

export interface COPermissionInterface {
  meta?: COMetadataItemInterface;
  slug: COPermissionSlugs;
}

export interface COProcessPublishedInfoInterface {
  process_published_info_id: number;
  process_id: number;
  user_id: number;
  process_document_id: number;
  published_at: string;
  published_epoch: number;
  published_version: null | string;
  published_version_notes: null | string;
  updated_at: string;
  updated_epoch: string;
  is_published: 0 | 1;
  num_downloads: number;
  user_has_consumed: 0 | 1;
}

export interface COTabularRenderOptions {
  question_meta: boolean;
  process_answer_meta: boolean;
  question_options: boolean;
  is_tabular_table?: boolean;
}

export interface LocalizationHashMapInterface {
  [hash: string]: {
    localizationKey: string;
    fallbackValue: string;
  };
}

export interface COUserInterface {
  user_id?: number;
  user_first_name?: string;
  user_last_name?: string;
  user_profile_slug?: string;
  user_email?: string;
  user_profile_pic?: string;
  user_banner_pic?: string | null;
  user_location?: string;
  user_country?: null | string;
  user_business_unit?: string;
  user_department?: string;
  user_direct_manager_user_id?: number;
  user_created_epoch?: number;
  user_created_at?: string;
  user_is_active?: 0 | 1;
  user_is_deleted?: 0 | 1;
  user_uuid?: string;
  user_last_login_epoch?: number;
  user_last_login_time?: string;
  deleter_user_id?: null | number;
  user_updated_at?: string;
  user_updated_epoch?: number;
  user_job_title?: string;
  user_num_automations?: number;
  user_num_documents?: number;
  user_num_components?: number;
  user_about_me?: string;
  user_num_following?: number;
  user_invite_status?: number;
  user_is_admin?: 0 | 1;
  user_num_collaborations?: number;
  user_is_new?: 0 | 1;
  ah_permissions?: any;
}

export interface COCommentInterface {
  comment_id?: number;
  comment_text?: string;
}

export interface COProcessTemplateInterface {
  template_id?: number;
  template_title?: string;
}

export interface CORoleInterface {
  role_id?: number;
  role_title?: string;
  comment?: string;
  user?: COUserInterface;
}

export interface COReportedProcessInterface {
  reported_process_id?: number;
  process_id?: number;
  reported_reason?: string;
  user?: COUserInterface;
}

export interface COProcessReportedCommentInterface {
  process_reported_comment_id?: number;
  process_id?: number;
  comment_id?: number;
  reported_reason?: string;
  user?: COUserInterface;
}

export interface COProcessQuestionInterface {
  process_question_id?: number;
  comment?: string;
}

export interface COTenantInterface {
  tenant_id?: string;
  state?: string;
  tenant_company_name?: string;
  license_plan?: string;
  license_plan_option_number?: string;
  is_new_tenant?: boolean;
  sales_message?: string;
  error_message?: string;
  tenant_name?: string;
  tenant_company_url?: string;
}

export interface COActionContextValuesInterface {
  reference_user_id?: number;
  process_id?: number;
  process_phase?: string;
  process_status?: string;
  process_rejected_reason?: string;
  process_archived_reason?: string;
  process_specific_fields?: COLoadableProcessFields[];
  component_id?: number;
  component_rejected_reason?: string;
  component_comment_id?: number;
  user_id?: number;
  comment_id?: number;
  document_id?: number;
  template_id?: number;
  role_id?: number;
  reported_process_id?: number;
  process_reported_comment_id?: number;
  process_question_id?: number;
  tenant_id?: string;
  user_attributes?: string;
  process_role_name?: string;
  collaborator_roles?: string;
  permission_roles?: string;
  legacy_text_replacement_values?:
    | string
    | {
        [key: string]: { text?: string; url?: string };
      };
  collaborator_ids?: { [key: string]: number }; // JOE let's mae this generic for any collaborator role
  beginningOfEmail?: string;
  endOfEmail?: string;
  emailTitle?: string;
  emailTemplate?: string;
  reminderEmailTitlePrefix?: string;
  reminderEmailBodyPrefix?: string;
}

export interface COActionContextInterface {
  process?: COProcess;
  process_phase?: string;
  process_status?: string;
  process_rejected_reason?: string;
  process_archived_reason?: string;
  user?: COUserInterface;
  referenced_user?: COUserInterface;
  component?: COComponentInterface;
  component_rejected_reason?: string;
  component_comment?: COCommentInterface;
  document?: CODocumentInterface;
  comment?: COCommentInterface;
  template?: COProcessTemplateInterface;
  role?: CORoleInterface;
  reported_process?: COReportedProcessInterface;
  process_reported_comment?: COProcessReportedCommentInterface;
  process_question?: COProcessQuestionInterface;
  tenant?: COTenantInterface;
  user_attributes?: string;
  process_role_name?: string;
  collaborator_roles?: string;
  permission_roles?: string;
  legacy_text_replacement_values?:
    | string
    | {
        [key: string]: { text?: string; url?: string };
      };
  collaborators?: { [key: string]: COUserInterface }; // JOE let's mae this generic for any collaborator role
  beginningOfEmail?: string;
  endOfEmail?: string;
  emailTitle?: string;
  emailTemplate?: string;
  reminderEmailTitlePrefix?: string;
  reminderEmailBodyPrefix?: string;
}

export interface COLegacyActionInterface {
  notification_id?: number;
  notification_slug?: string;
  notification_type_id?: number;
  notification_title?: string;
  notification_text?: string;
  notification_referance_id?: number;
  notification_reference_text?: string;
  replaced_texts?: { [key: string]: { text?: string; url?: string } };
  notification_text_replacements?: {
    [key: string]: { text?: string; url?: string };
  };
  notification_is_active?: 0 | 1;
  notification_is_deleted?: 0 | 1;
  notification_activity_id?: number;
  notification_action_view?: string;
  notification_category_type?: number;
  notification_group_slug?: string;
  settings_id?: number[];
  reference_user_id?: number;
  reference_user?: COUserInterface;
  process_id?: number;
  component_id?: number;
  user_id?: number;
  comment_id?: number;
  comment_object_type?: any;
  document_id?: number;
  created_at?: string;
  created_epoch?: number;
  updated_at?: string;
  updated_epoch?: number;
  notification_has_viewed?: 0 | 1;
  type?: {
    notification_type_id?: number;
    notification_type_key?: string;
    notification_type_text?: string;
  };
  user_notification_action_url?: string;
  user_notification_id?: number;
  user_notification_uuid?: string;
  user_notification_type_id?: number;
  user_notification_text?: string;
  user_notification_is_deleted?: 0 | 1;
  user_notification_category_type?: number;
  user_notification_activity_type?: number;
}

export interface COLegacyTaskInterface {
  task_id?: number;
  task_text?: string;
  task_type_id?: number;
  task_is_active?: 0 | 1;
  task_is_deleted?: 0 | 1;
  task_is_manual?: 0 | 1;
  task_activity_type?: number;
  task_link_type?: number;
  task_category_type?: number;
  task_action_view?: string;
  platform_event_id?: number;
  task_activity_id?: number;
  task_activity_action_url_id?: number;
  setting_ids?: number[];
  task_activity_key?: string;
  reference_user_id?: number;
  process_id?: number;
  component_id?: number;
  task_reference_object_id?: number;
  replaced_texts?: { [key: string]: { text?: string; url?: string } };
  task_text_replacements?: {
    [key: string]: { text?: string; url?: string };
  };
  created_at?: string;
  created_epoch?: number;
  updated_at?: string;
  updated_epoch?: number;
  user_task_id?: number;
  task_is_completed?: number;
}

export interface COLegacyEmailInterface {
  email_id?: number;
  email_type_id?: number;
  email_title?: string;
  email_activity_id?: number;
  email_template_name?: string;
  platform_event_id?: number;
  email_is_active?: 0 | 1;
  email_is_deleted?: 0 | 1;
  platform_type_id?: number;
  email_category_type?: number;
  setting_ids?: number[];
}

export interface COLegacyEmailTemplateData {
  CompanyName?: string;
  EmailConfirmationLink?: string;
  TenantLoginUrl?: string;
  CustomerPersonalDataProcessingUrl?: string;
  State?: string;
  LicensePlan?: string;
  LicensePlanOptionNumber?: string;
  MigrationOption?: string;
  TenantName?: string;
  SalesMessage?: string;
  ErrorMessage?: string;
  StatusPage?: string;
  UserGuideFirstUserUrl?: string;
  UserGuideStandardUserUrl?: string;
  SubmitIdeaUrl?: string;
  ExploreIdeasUrl?: string;

  ProcessName?: string;
  ProcessOwner?: string;
  ProcessSubmitter?: string;
  ProcessComment?: string;
  ProcessUrl?: string;
  ProcessEditUrl?: string;
  ProcessCBAUrl?: string;
  ProcessCommentUrl?: string;
  ProcessComponentUrl?: string;
  ProcessDocumentationUrl?: string;
  AutomationStoreProcessUrl?: string;
  ProcessQuestion?: string;
  ProcessDescription?: string;
  ViewMore?: string;

  ComponentName?: string;
  ComponentAuthor?: string;
  ComponentUrl?: string;
  ComponentComment?: string;

  RefUserUrl?: string;
  RefUserFullName?: string;
  UserFirstName?: string;
  PermissionRoles?: string;
}

export class COActionTemplateDBClass {
  constructor(
    readonly co_action_template_id?: number,
    readonly co_action_template_slug?: string,
    readonly co_action_template_co_type?: COActionTypes,
    readonly co_action_json?: COActionInterface
  ) {}
}

export interface COActionTemplateInterface
  extends Writeable<COActionTemplateDBClass>,
    COBaseTemplateInterface {}

export class CONotificationActionTemplateDBClass {
  constructor(
    readonly co_action_options_json?: CONotificationActionOptionsInterface
  ) {}
}

export interface CONotificationActionTemplateInterface
  extends Writeable<CONotificationActionTemplateDBClass>,
    COActionTemplateInterface {}

export class COEmailActionTemplateDBClass {
  constructor(
    readonly co_action_options_json?: COEmailActionOptionsInterface
  ) {}
}

export interface COEmailActionTemplateInterface
  extends Writeable<COEmailActionTemplateDBClass>,
    COActionTemplateInterface {}

export class COTaskActionTemplateDBClass {
  constructor(readonly co_action_options_json?: COTaskActionOptionsInterface) {}
}

export interface COTaskActionTemplateInterface
  extends Writeable<COTaskActionTemplateDBClass>,
    COActionTemplateInterface {}

export class COUserActionDBClass {
  constructor(
    readonly co_user_action_id?: number,
    readonly co_action_id?: number,
    readonly user_id?: number,
    readonly co_user_action_options_json?: COUserActionOptionsInterface,
    readonly co_user_action_type?: COUserActionType,
    readonly created_epoch?: number
  ) {}
}

export interface COUserActionInterface
  extends Writeable<COUserActionDBClass>,
    COBaseInterface {}

export class CORoleActionDBClass {
  constructor(
    readonly co_role_action_id?: number,
    readonly co_action_id?: number,
    readonly role_slug?: string,
    readonly co_role_action_json?: Object,
    readonly created_epoch?: number
  ) {}
}

export interface CORoleActionInterface
  extends Writeable<CORoleActionDBClass>,
    COBaseInterface {}

export class COActionDBClass {
  constructor(
    readonly co_action_id?: number,
    readonly co_workflow_id?: number,
    readonly co_action_ahid?: string,
    readonly co_parent_ahid?: string,
    readonly co_action_template_slug?: string,
    readonly co_action_type?: COActionTypes,
    readonly co_action_meta_json?: COMetadataInterface,
    readonly co_action_options_json?: COActionOptionsInterface,
    readonly co_action_context_values_json?: COActionContextValuesInterface,
    readonly created_epoch?: number,
    readonly updated_epoch?: number
  ) {}
}

export interface COActionInterface
  extends Writeable<COActionDBClass>,
    COBaseInterface {
  position?: COPositionInterface;
  recipients?: COActionRecipientInterface[];
  post_action_actions?: COActionInterface[];
  co_action_context?: COActionContextInterface;
  co_user_action?: COUserActionInterface;
  legacy_data?: any;
  functionParseActionMetaValueToLocalizationMetaItem?(
    context: COContextInterface,
    operatorParams: { value?: string }
  ): COMetadataItemInterface | undefined;
  functionParseActionMetaValueToString?(
    context: COContextInterface,
    operatorParams: { value?: string }
  ): string | undefined;
  functionLoadAndParseActionMetaEmailTemplate?(
    context: COContextInterface,
    operatorParams: { value?: string }
  ): COMetadataItemInterface | undefined;
}

export interface CONotificationActionInterface extends COActionInterface {}

export interface COEmailActionInterface extends COActionInterface {}

export interface COTaskActionInterface extends COActionInterface {}

// Info for a recipient for an action, targetting a specific user by ID, all users of a role, etc
export interface COActionRecipientInterface {
  user_id?: number;
  role_id?: number;
}

export class COEmailReminderActionTemplateDBClass {
  constructor(
    readonly co_action_options_json?: COEmailReminderActionOptionsInterface
  ) {}
}

export interface COEmailReminderActionTemplateInterface
  extends Writeable<COEmailReminderActionTemplateDBClass>,
    COActionTemplateInterface {}

export interface COEmailReminderActionInterface extends COActionInterface {}

export interface COTenantSettingsSectionInterface {
  section_input_type?: string;
  section_key?: string;
  section_title?: string;
  settings?: COTenantSettingsDataInterface[];
}

export interface COTenantSettingsDataInterface {
  dependent_settings?: COTenantSettingsDataInterface[];
  setting_description?: string;
  setting_id?: number;
  setting_key?: COTenantSettingsKeys;
  setting_name?: string;
  setting_option_id?: number;
  setting_option_slug?: string;
  setting_option_type?: string;
  setting_option_value?: string;
  setting_options?: COTenantSettingOptionInterface[];
  setting_type?: string;
  tenant_setting_id?: number;
}

export interface COTenantSettingOptionInterface {
  setting_option_id?: number;
  setting_option_slug?: string;
  setting_option_value?: string;
}

export interface FetchActionsFilteringData {
  page?: number; // gets converted from 1 to 0 to 1 to 1 based in legacy system
  limit?: number;
  skip?: number;
  sort?: string;
  sortOrder?: string;
  originalNoteTypeSlugs?: string[];
  noteTypeSlugs?: string[];
  originalTaskTypeSlugs?: string[];
  taskTypeSlugs?: string[];
  taskCompletedQuery?: boolean;
  searchText?: string;
  processPhaseIds?: number[];
  processStatusIds?: number[];
  componentStatusIds?: number[];
  collaborators?: number[];
  start?: number;
  end?: number;
  unreadNotificationsOnly?: boolean;
  pageIndex?: number; //always zero based
}
