import {
  FETCH_PROCESS_COLLABORATORS,
  FETCH_PROCESS_ROLES,
  FETCH_PROCESS_PERMISSIONS,
  UPDATE_PROCESS_COLLABORATORS,
  ADD_SINGLE_PROCESS_COLLABORATOR
} from "../constants/actionTypes";

const INITIAL_STATE = {
  collaborators: null,
  roles: null,
  permissions: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROCESS_COLLABORATORS:
      return {
        ...state,
        collaborators: action.payload
      };
    case FETCH_PROCESS_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case FETCH_PROCESS_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      };
    case UPDATE_PROCESS_COLLABORATORS:
      return {
        ...state,
        collaborators: action.payload
      };
    case ADD_SINGLE_PROCESS_COLLABORATOR:
      return {
        ...state,
        collaborator: action.payload
      };
    default:
      return state;
  }
}
