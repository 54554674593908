import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL,
  AUTOMATION_PROFILE_COLLABORATORS_URL,
  AUTOMATION_PROFILE_DOCUMENTATION_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const collaborationInvitesNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = collaborationInvitesNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.COLLABORATION_INVITES,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const collaborationInvitesNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.PROCESS_OWNER_ADDED_IN_IDEA: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER, CORoleType.FOLLOWER_IN_AP]
          },
          legacy_notification_id: 14,
          platform_event_type: COPlatformEventType.PROCESS_OWNER_ASSIGNED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROCESS_OWNER_ADDED_IN_IDEA,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Process owner added in an idea"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.PROCESS_OWNER_NAME +
              " as a Process Owner in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_ADDED_AS_COLLABORATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 42,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_ASSIGNED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_AS_PROCESS_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were added as a collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added you as a " +
              COActionReplacementStrings.COLLABORATOR_ROLES +
              " in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_AS_COLLABORATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 50,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_REMOVED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_FROM_PROCESS,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were removed as a collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you from the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_FROM_CERTAIN_ROLE: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 52,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_CERTAIN_ROLE_REMOVED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_AS_PROCESS_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value:
              "You were removed as a certain role, but remain with other roles"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you as a " +
              COActionReplacementStrings.COLLABORATOR_ROLES +
              " from the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ABOUT_EDITING_RIGHTS_REVOKED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 54,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_REVOKED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.EDITING_ABOUT_REVOKED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Editing right for About page in AP revoked"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " revoked your editing rights for About section in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.DOCUMENTATION_EDITING_RIGHTS_REVOKED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 55,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_REVOKED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.EDITING_DOCUMENTATION_REVOKED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Editing right for Documentation page in AP revoked"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " revoked your editing rights for Documentation section in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_DOCUMENTATION_URL
          }
        }
      };
    }
  }
  return undefined;
};
