export enum COPhaseKeys {
  INITIAL = "INITIAL",
  IDEA = "IDEA",
  ASSESSMENT = "ASSESSMENT",
  BUSINESS_REVIEW = "BUSINESS_REVIEW",
  TECHNICAL_REVIEW = "TECHNICAL_REVIEW",
  QUALIFICATION = "QUALIFICATION",
  ANALYSIS = "ANALYSIS",
  SOLUTION_DESIGN = "SOLUTION_DESIGN",
  DEVELOPMENT = "DEVELOPMENT",
  TESTING = "TESTING",
  LIVE = "LIVE"
}
export const COPhaseArray = [
  COPhaseKeys.INITIAL,
  COPhaseKeys.IDEA,
  COPhaseKeys.ASSESSMENT,
  COPhaseKeys.BUSINESS_REVIEW,
  COPhaseKeys.TECHNICAL_REVIEW,
  COPhaseKeys.QUALIFICATION,
  COPhaseKeys.ANALYSIS,
  COPhaseKeys.SOLUTION_DESIGN,
  COPhaseKeys.DEVELOPMENT,
  COPhaseKeys.TESTING,
  COPhaseKeys.LIVE
];

export interface COPhaseStatusRangeInterface {
  min_key?: COPhaseStatusKeys | COPhaseKeys | string;
  max_key?: COPhaseStatusKeys | COPhaseKeys | string;
}
//TODO when we do the customization system we're going to have to deal with this
export const PHASE_STATUS_LEGACY_LIVE_ONGOING_ID_USED_IN_LAUNCH_DAATE_KPI = 89;

export enum COPhaseStatusKeys {
  INITIAL_SUBMISSION = "INITIAL_SUBMISSION",
  //IDEA_DRAFT = "IDEA_DRAFT",
  IDEA_AWAITING_REVIEW = "IDEA_AWAITING_REVIEW",
  IDEA_APPROVED = "IDEA_APPROVED",
  IDEA_REJECTED = "IDEA_REJECTED",
  IDEA_DUPLICATE = "IDEA_DUPLICATE",
  IDEA_ARCHIVED = "IDEA_ARCHIVED",
  ASSESSMENT_NOT_STARTED = "ASSESSMENT_NOT_STARTED",
  ASSESSMENT_ON_HOLD = "ASSESSMENT_ON_HOLD",
  ASSESSMENT_IN_PROGRESS = "ASSESSMENT_IN_PROGRESS",
  //ASSESSMENT_DRAFT = "ASSESSMENT_DRAFT",
  ASSESSMENT_AWAITING_REVIEW = "ASSESSMENT_AWAITING_REVIEW",
  ASSESSMENT_REJECTED = "ASSESSMENT_REJECTED",
  ASSESSMENT_POSTPONED = "ASSESSMENT_POSTPONED",
  ASSESSMENT_ARCHIVED = "ASSESSMENT_ARCHIVED",
  QUALIFICATION_ON_HOLD = "QUALIFICATION_ON_HOLD",
  QUALIFICATION_AWAITING_REVIEW = "QUALIFICATION_AWAITING_REVIEW",
  QUALIFICATION_APPROVED = "QUALIFICATION_APPROVED",
  QUALIFICATION_REJECTED = "QUALIFICATION_REJECTED",
  QUALIFICATION_ARCHIVED = "QUALIFICATION_ARCHIVED",
  ANALYSIS_NOT_STARTED = "ANALYSIS_NOT_STARTED",
  ANALYSIS_ON_TRACK = "ANALYSIS_ON_TRACK",
  ANALYSIS_CANCELLED = "ANALYSIS_CANCELLED",
  ANALYSIS_ON_HOLD = "ANALYSIS_ON_HOLD",
  ANALYSIS_AT_RISK = "ANALYSIS_AT_RISK",
  ANALYSIS_DELAYED = "ANALYSIS_DELAYED",
  ANALYSIS_COMPLETED = "ANALYSIS_COMPLETED",
  ANALYSIS_ARCHIVED = "ANALYSIS_ARCHIVED",
  SOLUTION_DESIGN_NOT_STARTED = "SOLUTION_DESIGN_NOT_STARTED",
  SOLUTION_DESIGN_ON_TRACK = "SOLUTION_DESIGN_ON_TRACK",
  SOLUTION_DESIGN_CANCELLED = "SOLUTION_DESIGN_CANCELLED",
  SOLUTION_DESIGN_ON_HOLD = "SOLUTION_DESIGN_ON_HOLD",
  SOLUTION_DESIGN_AT_RISK = "SOLUTION_DESIGN_AT_RISK",
  SOLUTION_DESIGN_DELAYED = "SOLUTION_DESIGN_DELAYED",
  SOLUTION_DESIGN_COMPLETED = "SOLUTION_DESIGN_COMPLETED",
  SOLUTION_DESIGN_ARCHIVED = "SOLUTION_DESIGN_ARCHIVED",
  DEVELOPMENT_NOT_STARTED = "DEVELOPMENT_NOT_STARTED",
  DEVELOPMENT_IN_PROGRESS = "DEVELOPMENT_IN_PROGRESS",
  DEVELOPMENT_CANCELLED = "DEVELOPMENT_CANCELLED",
  DEVELOPMENT_ON_HOLD = "DEVELOPMENT_ON_HOLD",
  DEVELOPMENT_AT_RISK = "DEVELOPMENT_AT_RISK",
  DEVELOPMENT_DELAYED = "DEVELOPMENT_DELAYED",
  DEVELOPMENT_COMPLETED = "DEVELOPMENT_COMPLETED",
  DEVELOPMENT_ARCHIVED = "DEVELOPMENT_ARCHIVED",
  TESTING_NOT_STARTED = "TESTING_NOT_STARTED",
  TESTING_ON_TRACK = "TESTING_ON_TRACK",
  TESTING_CANCELLED = "TESTING_CANCELLED",
  TESTING_ON_HOLD = "TESTING_ON_HOLD",
  TESTING_AT_RISK = "TESTING_AT_RISK",
  TESTING_DELAYED = "TESTING_DELAYED",
  TESTING_COMPLETED = "TESTING_COMPLETED",
  TESTING_ARCHIVED = "TESTING_ARCHIVED",
  BUSINESS_REVIEW_AWAITING_REVIEW = "BUSINESS_REVIEW_AWAITING_REVIEW",
  BUSINESS_REVIEW_ON_HOLD = "BUSINESS_REVIEW_ON_HOLD",
  BUSINESS_REVIEW_REJECTED = "BUSINESS_REVIEW_REJECTED",
  BUSINESS_REVIEW_APPROVED = "BUSINESS_REVIEW_APPROVED",
  BUSINESS_REVIEW_ARCHIVED = "BUSINESS_REVIEW_ARCHIVED",
  TECHNICAL_REVIEW_AWAITING_REVIEW = "TECHNICAL_REVIEW_AWAITING_REVIEW",
  TECHNICAL_REVIEW_ON_HOLD = "TECHNICAL_REVIEW_ON_HOLD",
  TECHNICAL_REVIEW_REWORK_REQUIRED = "TECHNICAL_REVIEW_REWORK_REQUIRED",
  TECHNICAL_REVIEW_ARCHIVED = "TECHNICAL_REVIEW_ARCHIVED",
  LIVE_ONGOING = "LIVE_ONGOING",
  LIVE_ON_HOLD = "LIVE_ON_HOLD",
  LIVE_COMPLETED = "LIVE_COMPLETED",
  LIVE_READY_FOR_PRODUCTION = "LIVE_READY_FOR_PRODUCTION",
  LIVE_HYPERCARE = "LIVE_HYPERCARE",
  LIVE_ARCHIVED = "LIVE_ARCHIVED"
}

// where we don't need to validate
export const COPhaseStatusKeysNotRequiringPermissions = [
  COPhaseStatusKeys.IDEA_AWAITING_REVIEW,
  COPhaseStatusKeys.ASSESSMENT_NOT_STARTED,
  COPhaseStatusKeys.BUSINESS_REVIEW_AWAITING_REVIEW,
  COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS
];

export const COPhaseStatuses = {
  [COPhaseKeys.INITIAL]: [COPhaseStatusKeys.INITIAL_SUBMISSION],
  [COPhaseKeys.IDEA]: [
    COPhaseStatusKeys.IDEA_AWAITING_REVIEW,
    COPhaseStatusKeys.IDEA_APPROVED,
    COPhaseStatusKeys.IDEA_REJECTED,
    COPhaseStatusKeys.IDEA_DUPLICATE,
    COPhaseStatusKeys.IDEA_ARCHIVED
  ],
  [COPhaseKeys.ASSESSMENT]: [
    COPhaseStatusKeys.ASSESSMENT_NOT_STARTED,
    COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS,
    COPhaseStatusKeys.ASSESSMENT_AWAITING_REVIEW,
    COPhaseStatusKeys.ASSESSMENT_ON_HOLD,
    COPhaseStatusKeys.ASSESSMENT_POSTPONED,
    COPhaseStatusKeys.ASSESSMENT_REJECTED,
    COPhaseStatusKeys.ASSESSMENT_ARCHIVED
  ],
  [COPhaseKeys.BUSINESS_REVIEW]: [
    COPhaseStatusKeys.BUSINESS_REVIEW_AWAITING_REVIEW,
    COPhaseStatusKeys.BUSINESS_REVIEW_ON_HOLD,
    COPhaseStatusKeys.BUSINESS_REVIEW_REJECTED,
    COPhaseStatusKeys.BUSINESS_REVIEW_APPROVED,
    COPhaseStatusKeys.BUSINESS_REVIEW_ARCHIVED
  ],
  [COPhaseKeys.TECHNICAL_REVIEW]: [
    COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW,
    COPhaseStatusKeys.TECHNICAL_REVIEW_ON_HOLD,
    COPhaseStatusKeys.TECHNICAL_REVIEW_REWORK_REQUIRED,
    COPhaseStatusKeys.TECHNICAL_REVIEW_ARCHIVED
  ],
  [COPhaseKeys.QUALIFICATION]: [
    COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW,
    COPhaseStatusKeys.QUALIFICATION_ON_HOLD,
    COPhaseStatusKeys.QUALIFICATION_APPROVED,
    COPhaseStatusKeys.QUALIFICATION_REJECTED,
    COPhaseStatusKeys.QUALIFICATION_ARCHIVED
  ],
  [COPhaseKeys.ANALYSIS]: [
    COPhaseStatusKeys.ANALYSIS_NOT_STARTED,
    COPhaseStatusKeys.ANALYSIS_ON_TRACK,
    COPhaseStatusKeys.ANALYSIS_CANCELLED,
    COPhaseStatusKeys.ANALYSIS_ON_HOLD,
    COPhaseStatusKeys.ANALYSIS_AT_RISK,
    COPhaseStatusKeys.ANALYSIS_DELAYED,
    COPhaseStatusKeys.ANALYSIS_COMPLETED,
    COPhaseStatusKeys.ANALYSIS_ARCHIVED
  ],
  [COPhaseKeys.SOLUTION_DESIGN]: [
    COPhaseStatusKeys.SOLUTION_DESIGN_NOT_STARTED,
    COPhaseStatusKeys.SOLUTION_DESIGN_ON_TRACK,
    COPhaseStatusKeys.SOLUTION_DESIGN_CANCELLED,
    COPhaseStatusKeys.SOLUTION_DESIGN_ON_HOLD,
    COPhaseStatusKeys.SOLUTION_DESIGN_AT_RISK,
    COPhaseStatusKeys.SOLUTION_DESIGN_DELAYED,
    COPhaseStatusKeys.SOLUTION_DESIGN_COMPLETED,
    COPhaseStatusKeys.SOLUTION_DESIGN_ARCHIVED
  ],
  [COPhaseKeys.DEVELOPMENT]: [
    COPhaseStatusKeys.DEVELOPMENT_NOT_STARTED,
    COPhaseStatusKeys.DEVELOPMENT_IN_PROGRESS,
    COPhaseStatusKeys.DEVELOPMENT_CANCELLED,
    COPhaseStatusKeys.DEVELOPMENT_ON_HOLD,
    COPhaseStatusKeys.DEVELOPMENT_AT_RISK,
    COPhaseStatusKeys.DEVELOPMENT_DELAYED,
    COPhaseStatusKeys.DEVELOPMENT_COMPLETED,
    COPhaseStatusKeys.DEVELOPMENT_ARCHIVED
  ],
  [COPhaseKeys.TESTING]: [
    COPhaseStatusKeys.TESTING_NOT_STARTED,
    COPhaseStatusKeys.TESTING_ON_TRACK,
    COPhaseStatusKeys.TESTING_CANCELLED,
    COPhaseStatusKeys.TESTING_ON_HOLD,
    COPhaseStatusKeys.TESTING_AT_RISK,
    COPhaseStatusKeys.TESTING_DELAYED,
    COPhaseStatusKeys.TESTING_COMPLETED,
    COPhaseStatusKeys.TESTING_ARCHIVED
  ],
  [COPhaseKeys.LIVE]: [
    COPhaseStatusKeys.LIVE_READY_FOR_PRODUCTION,
    COPhaseStatusKeys.LIVE_ONGOING,
    COPhaseStatusKeys.LIVE_ON_HOLD,
    COPhaseStatusKeys.LIVE_HYPERCARE,
    COPhaseStatusKeys.LIVE_ARCHIVED
  ]
};

export const COPhaseStatusArray = [
  COPhaseStatusKeys.INITIAL_SUBMISSION,
  //COPhaseStatusKeys.IDEA_DRAFT,
  COPhaseStatusKeys.IDEA_AWAITING_REVIEW,
  COPhaseStatusKeys.IDEA_APPROVED,
  COPhaseStatusKeys.IDEA_REJECTED,
  COPhaseStatusKeys.IDEA_DUPLICATE,
  COPhaseStatusKeys.IDEA_ARCHIVED,
  //COPhaseStatusKeys.ASSESSMENT_DRAFT,
  COPhaseStatusKeys.ASSESSMENT_NOT_STARTED,
  COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS,
  COPhaseStatusKeys.ASSESSMENT_AWAITING_REVIEW,
  COPhaseStatusKeys.ASSESSMENT_ON_HOLD,
  COPhaseStatusKeys.ASSESSMENT_POSTPONED,
  COPhaseStatusKeys.ASSESSMENT_REJECTED,
  COPhaseStatusKeys.ASSESSMENT_ARCHIVED,
  COPhaseStatusKeys.BUSINESS_REVIEW_AWAITING_REVIEW,
  COPhaseStatusKeys.BUSINESS_REVIEW_ON_HOLD,
  COPhaseStatusKeys.BUSINESS_REVIEW_REJECTED,
  COPhaseStatusKeys.BUSINESS_REVIEW_APPROVED,
  COPhaseStatusKeys.BUSINESS_REVIEW_ARCHIVED,
  COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW,
  COPhaseStatusKeys.TECHNICAL_REVIEW_ON_HOLD,
  COPhaseStatusKeys.TECHNICAL_REVIEW_REWORK_REQUIRED,
  COPhaseStatusKeys.TECHNICAL_REVIEW_ARCHIVED,
  COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW,
  COPhaseStatusKeys.QUALIFICATION_ON_HOLD,
  COPhaseStatusKeys.QUALIFICATION_APPROVED,
  COPhaseStatusKeys.QUALIFICATION_REJECTED,
  COPhaseStatusKeys.QUALIFICATION_ARCHIVED,
  COPhaseStatusKeys.ANALYSIS_NOT_STARTED,
  COPhaseStatusKeys.ANALYSIS_ON_TRACK,
  COPhaseStatusKeys.ANALYSIS_CANCELLED,
  COPhaseStatusKeys.ANALYSIS_ON_HOLD,
  COPhaseStatusKeys.ANALYSIS_AT_RISK,
  COPhaseStatusKeys.ANALYSIS_DELAYED,
  COPhaseStatusKeys.ANALYSIS_COMPLETED,
  COPhaseStatusKeys.ANALYSIS_ARCHIVED,
  COPhaseStatusKeys.SOLUTION_DESIGN_NOT_STARTED,
  COPhaseStatusKeys.SOLUTION_DESIGN_ON_TRACK,
  COPhaseStatusKeys.SOLUTION_DESIGN_CANCELLED,
  COPhaseStatusKeys.SOLUTION_DESIGN_ON_HOLD,
  COPhaseStatusKeys.SOLUTION_DESIGN_AT_RISK,
  COPhaseStatusKeys.SOLUTION_DESIGN_DELAYED,
  COPhaseStatusKeys.SOLUTION_DESIGN_COMPLETED,
  COPhaseStatusKeys.SOLUTION_DESIGN_ARCHIVED,
  COPhaseStatusKeys.DEVELOPMENT_NOT_STARTED,
  COPhaseStatusKeys.DEVELOPMENT_IN_PROGRESS,
  COPhaseStatusKeys.DEVELOPMENT_CANCELLED,
  COPhaseStatusKeys.DEVELOPMENT_ON_HOLD,
  COPhaseStatusKeys.DEVELOPMENT_AT_RISK,
  COPhaseStatusKeys.DEVELOPMENT_DELAYED,
  COPhaseStatusKeys.DEVELOPMENT_COMPLETED,
  COPhaseStatusKeys.DEVELOPMENT_ARCHIVED,
  COPhaseStatusKeys.TESTING_NOT_STARTED,
  COPhaseStatusKeys.TESTING_ON_TRACK,
  COPhaseStatusKeys.TESTING_CANCELLED,
  COPhaseStatusKeys.TESTING_ON_HOLD,
  COPhaseStatusKeys.TESTING_AT_RISK,
  COPhaseStatusKeys.TESTING_DELAYED,
  COPhaseStatusKeys.TESTING_COMPLETED,
  COPhaseStatusKeys.TESTING_ARCHIVED,
  COPhaseStatusKeys.LIVE_READY_FOR_PRODUCTION,
  COPhaseStatusKeys.LIVE_ONGOING,
  COPhaseStatusKeys.LIVE_ON_HOLD,
  COPhaseStatusKeys.LIVE_HYPERCARE,
  COPhaseStatusKeys.LIVE_ARCHIVED
];

export const COPhaseMinPhaseStatusMap = {
  [COPhaseKeys.INITIAL]: COPhaseStatusKeys.INITIAL_SUBMISSION,
  [COPhaseKeys.IDEA]: COPhaseStatusKeys.IDEA_AWAITING_REVIEW,
  [COPhaseKeys.ASSESSMENT]: COPhaseStatusKeys.ASSESSMENT_NOT_STARTED,
  [COPhaseKeys.BUSINESS_REVIEW]:
    COPhaseStatusKeys.BUSINESS_REVIEW_AWAITING_REVIEW,
  [COPhaseKeys.TECHNICAL_REVIEW]:
    COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW,
  [COPhaseKeys.QUALIFICATION]: COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW,
  [COPhaseKeys.ANALYSIS]: COPhaseStatusKeys.ANALYSIS_NOT_STARTED,
  [COPhaseKeys.SOLUTION_DESIGN]: COPhaseStatusKeys.SOLUTION_DESIGN_ON_TRACK,
  [COPhaseKeys.DEVELOPMENT]: COPhaseStatusKeys.DEVELOPMENT_NOT_STARTED,
  [COPhaseKeys.TESTING]: COPhaseStatusKeys.TESTING_ON_TRACK,
  [COPhaseKeys.LIVE]: COPhaseStatusKeys.LIVE_ONGOING
};
export const COPhaseMaxPhaseStatusMap = {
  [COPhaseKeys.INITIAL]: COPhaseStatusKeys.INITIAL_SUBMISSION,
  [COPhaseKeys.IDEA]: COPhaseStatusKeys.IDEA_ARCHIVED,
  [COPhaseKeys.ASSESSMENT]: COPhaseStatusKeys.ASSESSMENT_ARCHIVED,
  [COPhaseKeys.BUSINESS_REVIEW]: COPhaseStatusKeys.BUSINESS_REVIEW_ARCHIVED,
  [COPhaseKeys.TECHNICAL_REVIEW]: COPhaseStatusKeys.TECHNICAL_REVIEW_ARCHIVED,
  [COPhaseKeys.QUALIFICATION]: COPhaseStatusKeys.QUALIFICATION_ARCHIVED,
  [COPhaseKeys.ANALYSIS]: COPhaseStatusKeys.ANALYSIS_ARCHIVED,
  [COPhaseKeys.SOLUTION_DESIGN]: COPhaseStatusKeys.SOLUTION_DESIGN_ARCHIVED,
  [COPhaseKeys.DEVELOPMENT]: COPhaseStatusKeys.DEVELOPMENT_ARCHIVED,
  [COPhaseKeys.TESTING]: COPhaseStatusKeys.TESTING_ARCHIVED,
  [COPhaseKeys.LIVE]: COPhaseStatusKeys.LIVE_ARCHIVED
};

// frontend refrences automationStatusTypes.ts
export enum COPhaseStatusActions {
  IMPORT_PIPELINE = "IMPORT_PIPELINE",
  UPDATE = "UPDATE", // the default one
  NEW = "NEW",
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  ARCHIVE = "ARCHIVE",
  MARK_AS_COMPLETE = "MARK_AS_COMPLETE",
  MARK_AS_DUPLICATE = "MARK_AS_DUPLICATE",
  PUT_ON_HOLD = "PUT_ON_HOLD",
  MOVE_TO = "MOVE_TO",
  REWORK = "REWORK",
  APPROVE_COE = "APPROVE_COE",
  APPROVE_CD = "APPROVE_CD",
  START_DEVELOPMENT = "START_DEVELOPMENT",
  DELETE = "DELETE",
  START_DEVELOPMENT_IN_STUDIO = "START_DEVELOPMENT_IN_STUDIO"
}

// we will add to these as we go
export const CO_PHASE_KEY_ACTION_MAPPING: {
  [key: string]: {
    modalActions?: {
      [key: string]: {
        nextStatusKey?: string;
      };
    };
  };
} = {
  [COPhaseStatusKeys.IDEA_AWAITING_REVIEW]: {
    modalActions: {
      [COPhaseStatusActions.APPROVE]: {
        nextStatusKey: COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
      }
    }
  },
  [COPhaseStatusKeys.ASSESSMENT_NOT_STARTED]: {
    modalActions: {
      [COPhaseStatusActions.UPDATE]: {
        nextStatusKey: COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS
      },
      [COPhaseStatusActions.MARK_AS_COMPLETE]: {
        nextStatusKey: COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
      }
    }
  },
  [COPhaseStatusKeys.ASSESSMENT_AWAITING_REVIEW]: {
    modalActions: {
      [COPhaseStatusActions.UPDATE]: {
        nextStatusKey: COPhaseStatusKeys.ASSESSMENT_AWAITING_REVIEW
      },
      [COPhaseStatusActions.MARK_AS_COMPLETE]: {
        nextStatusKey: COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
      }
    }
  },
  [COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS]: {
    modalActions: {
      [COPhaseStatusActions.MARK_AS_COMPLETE]: {
        nextStatusKey: COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
      },
      [COPhaseStatusActions.UPDATE]: {
        nextStatusKey: COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS
      }
    }
  }
};

export const phaseOrStatusOrder = ({
  phase_status_key,
  max_index = false
}: {
  phase_status_key: string;
  max_index?: boolean;
}) => {
  let phaseStatus: COPhaseStatusKeys | null =
    COPhaseStatusKeys[phase_status_key];
  if (phaseStatus) {
    let orderIndex: number = COPhaseStatusArray.indexOf(phaseStatus);
    if (orderIndex >= 0) {
      return orderIndex;
    }
  }
  let phase: COPhaseKeys | null = COPhaseKeys[phase_status_key];
  if (phase) {
    let phaseStatusKey: COPhaseStatusKeys = max_index
      ? COPhaseMaxPhaseStatusMap[phase]
      : COPhaseMinPhaseStatusMap[phase];
    if (phaseStatusKey) {
      let orderIndex: number = COPhaseStatusArray.indexOf(phaseStatusKey);
      if (orderIndex >= 0) {
        return orderIndex;
      }
    }
  }
  return -1;
};

//inclusive
export const isPhaseSlugInRange = ({
  phase_status_key,
  range
}: {
  phase_status_key?: string;
  range?: COPhaseStatusRangeInterface;
}) => {
  // show if no range defined
  if (!range || !phase_status_key) {
    return true;
  }
  // show if current phase status not found // always phase status key
  let currentPhaseStatusIndex = phaseOrStatusOrder({ phase_status_key });
  if (currentPhaseStatusIndex < 0) {
    console.log(phase_status_key);
    console.log("no phase found for current process");
    return true;
  }

  if (range.max_key || range.min_key) {
    let minIndex = range.min_key
      ? phaseOrStatusOrder({
          phase_status_key: range.min_key,
          max_index: false
        })
      : -1;

    let maxIndex = range.max_key
      ? phaseOrStatusOrder({
          phase_status_key: range.max_key,
          max_index: true
        })
      : 100000;
    if (minIndex > maxIndex) {
      console.log(
        `Range Error - end index is before start index ${range.min_key} ${range.max_key}`
      );
    } else {
      // console.log(
      //   `current min max ${minIndex} to ${maxIndex} - target value ${currentPhaseStatusIndex}`
      // );

      if (
        (currentPhaseStatusIndex > minIndex ||
          currentPhaseStatusIndex == minIndex) &&
        (currentPhaseStatusIndex == maxIndex ||
          currentPhaseStatusIndex < maxIndex)
      ) {
        //   console.log("range check passed");
        return true;
      }
    }
  } else {
    return true; // no range specified
  }

  return false;
};
