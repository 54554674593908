import {
  COContextInterface,
  COPathOverrideInterface
} from "../interfaces/co-interfaces";

import { resolvePathWithOverrides } from "./co-path.utils";
import { isNullOrUndefined } from "./co-utils";

//this needs to be pretty deep
// metadata then each metadata item
// then each item option

// metadata
// options
// validateors
// controls

export const CO_PATH_ARRAY_SUFFIX = "_path_array";
export const CO_BLACKLISTED_RESOLUTION_KEY_SUFFIX = [
  "_path_array",
  "_json",
  "validations",
  "controls"
];
export const CO_BLACKLISTED_RESOLUTION_KEY_PREFIX = ["co_"];

// we can get into dangerous recursive issues
// so here is the problem - recursively resolving - finds things like process_answer_selections which is a huge array and then resolves everything inside that
// so it's like ultra madness traversiing through all properties via paths - I think we can only resolve items at one level - then can resolve again where we need to
export const shouldResolveProperty = (property: string, item: any): boolean => {
  // or we get smart about it -
  // we only want to resolve interfaces not classes
  // if (Object.keys(item).length > 50) {
  //   return false;
  // }

  // no base objects should ever be resolved - which I could better check class or instance
  if (
    !item ||
    item.dont_resolve ||
    item.co_assessment_id ||
    item.getAHID ||
    item.validations
  ) {
    //console.log("caught a co object");
    return false;
  }

  if (typeof item === "function" || item instanceof Function) {
    //console.log("caught a function");
    return false;
  }
  for (const item of CO_BLACKLISTED_RESOLUTION_KEY_PREFIX) {
    if (property.startsWith(item)) {
      return false;
    }
  }
  for (const item of CO_BLACKLISTED_RESOLUTION_KEY_SUFFIX) {
    if (property.endsWith(item)) {
      return false;
    }
  }

  return true;
};

// we crank through an object, resolving all paths until they terminate, returning a resolved object
// we avoid trying to resolve non metadata, control validation objects - because resolving arrays of questions is expensive and recurses
export const resolve = ({
  context,
  item,
  parent,
  item_key,
  overrides,
  depth = 0
}: {
  context: COContextInterface;
  item: any;
  parent?: any;
  item_key?: string;
  overrides?: COPathOverrideInterface[];
  depth?: number;
}): any => {
  // console.log(
  //   `resolving object ${JSON.stringify(item)?.substr(0, 30)} ${JSON.stringify(
  //     parent
  //   )?.substr(0, 30)} ${item_key}`
  // );

  depth++;

  // we do not want to resolve COObjects and follow paths endlessly
  if (!shouldResolveProperty(item_key || "", item)) {
    return item;
  }

  if (depth > 10) {
    console.log(
      `max depth exceeded for item ${item_key} - parent ${JSON.stringify(
        parent
      )?.substr(0, 30)} ${JSON.stringify(item)}`
    );
    return item;
  }

  let itemCopy = JSON.parse(JSON.stringify(item || {}));
  let clone = {};

  if (Array.isArray(item)) {
    let cloneArray: any[] = [];
    let itemArray = item;
    for (const it of itemArray) {
      cloneArray.push(
        resolve({
          context,
          item: it,
          parent: parent,
          item_key: item_key,
          overrides,
          depth
        })
      );
    }
    return cloneArray;
  }

  if (
    typeof item === "object" &&
    item !== null &&
    !(item instanceof Function)
  ) {
    for (const key of Object.keys(itemCopy || {})) {
      let element = itemCopy[key];
      clone[key] = resolve({
        context,
        item: element,
        parent: clone,
        item_key: key,
        overrides,
        depth
      });
    }
    return clone;
  }

  // it's a value (not object or array)

  let valueAndPathArray = resolvePathWithOverrides({
    context,
    valueOrPath: item,
    overrides: overrides || []
  });

  if (valueAndPathArray) {
    clone = valueAndPathArray.value;

    if (valueAndPathArray.pathArray) {
      parent[`${item_key}${CO_PATH_ARRAY_SUFFIX}`] =
        valueAndPathArray.pathArray;
    }

    // we  start resolving the values found at the end of paths, but this get's messy super super fast - I think we don't want to resolve objects that come back from paths
    // if it's an object or array we want to resolve the properties of the object as well
    if (
      !isNullOrUndefined(clone) &&
      (Array.isArray(clone) || typeof clone === "object") &&
      !(item instanceof Function)
    ) {
      return resolve({
        context,
        item: clone,
        item_key: item_key,
        parent: parent,
        depth: depth
      });
    }
    // } // if it's just a value then return it - this has already been resolved in the path function to the end
    return clone;
  } else {
    return undefined;
  }
};

// must already be resolved
export const pathArrayForResolvedKey = (resolvedObject: any, key: string) => {
  return resolvedObject && resolvedObject[`${key}${CO_PATH_ARRAY_SUFFIX}`];
};
