/*
  Determines the app root path based on the specified service.
*/
import * as path from "path";

function joinWithAppRootPath(pathToModule: string, serviceDir: string): string {
  const splitString = `${path.sep}ah-common`;
  const rootPath = path.resolve(__dirname).split(splitString)[0];
  return path.join(rootPath, serviceDir, pathToModule);
}

export function joinWithTenantServiceRoot(pathToModule: string): string {
  return joinWithAppRootPath(pathToModule, "tenant-service");
}

export function joinWithFrontdoorServiceRoot(pathToModule: string): string {
  return joinWithAppRootPath(pathToModule, "frontdoor-service");
}

export function joinWithOpenApiServiceRoot(pathToModule: string): string {
  return joinWithAppRootPath(pathToModule, "open-api-service");
}
