import axios from "../axiosInstance";
import { filterCategoriesWithinCategoryIds } from "../categoryUtils";
import { getFilterCategoriesAndLevels } from "./adminCategoriesApiUtils";

export const getUserTopLevelCategoryIdsForPermission = async (
  permission_slug
): Promise<any> => {
  const path = `/api/v1/categories/action/${permission_slug}`;
  const response = await axios.get(path);
  let categories = response?.data?.data || [];
  return categories;
};

//this loads the categories for the user, then filters them by what categories they have the permission slug on.
export const getFilterCategoriesAndLevelsForPermission = async (
  permission_slug
): Promise<any> => {
  let categoriesAndLevels = await getFilterCategoriesAndLevels();
  let userTopLevelCategoryIdsForPermission = await getUserTopLevelCategoryIdsForPermission(
    permission_slug
  );
  if (
    categoriesAndLevels &&
    categoriesAndLevels.categories &&
    Array.isArray(categoriesAndLevels.categories) &&
    userTopLevelCategoryIdsForPermission &&
    Array.isArray(userTopLevelCategoryIdsForPermission)
  ) {
    categoriesAndLevels.categories = filterCategoriesWithinCategoryIds(
      categoriesAndLevels.categories,
      userTopLevelCategoryIdsForPermission
    );
    return categoriesAndLevels;
  }
  return categoriesAndLevels;
};
