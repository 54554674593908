import React from "react";
import dotsImage from "../../assets/images/ic-pending.svg";

import "./CellActionMyComponents.scss";

const CellActionMyComponents = props => {
  const { component_id, onClick } = props;

  return (
    <div className="CellActionMyComponents">
      {
        <button
          className="CellActionMyComponents__button"
          data-testid={`cell-action-${component_id}`}
          onClick={e => onClick(e, component_id)}
        >
          <img
            src={dotsImage}
            alt="Menu Dots"
            data-testid="CellActionMyComponentsDotsImage"
          />
        </button>
      }
    </div>
  );
};

export default CellActionMyComponents;
