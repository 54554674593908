import React, { memo } from "react";
import ImageThemed from "../ImageThemed/ImageThemed";
import customActivityLight from "./../../assets/images/component_type_icons/custom-activity.svg";
import customActivityDark from "./../../assets/images/component_type_icons/custom-activity-dark.svg";
import snippetLight from "./../../assets/images/component_type_icons/snippet.svg";
import snippetDark from "./../../assets/images/component_type_icons/snippet-dark.svg";
import workflowTemplateLight from "./../../assets/images/component_type_icons/workflow-template.svg";
import workflowTemplateDark from "./../../assets/images/component_type_icons/workflow-template-dark.svg";
import connectorLight from "./../../assets/images/component_type_icons/connector.svg";
import connectorDark from "./../../assets/images/component_type_icons/connector-dark.svg";
import dashboardLight from "./../../assets/images/component_type_icons/dashboard.svg";
import dashboardDark from "./../../assets/images/component_type_icons/dashboard-dark.svg";
import mlModelLight from "./../../assets/images/component_type_icons/ml-model.svg";
import mlModelDark from "./../../assets/images/component_type_icons/ml-model-dark.svg";
import solutionLight from "./../../assets/images/component_type_icons/solution.svg";
import solutionDark from "./../../assets/images/component_type_icons/solution-dark.svg";

const ComponentTypeIcon = ({
  componentTypeSlug,
  className,
  alt
}: {
  componentTypeSlug?: string;
  className?: string;
  alt?: string;
}) => {
  let lightSrc = "";
  let darkSrc = "";

  if (componentTypeSlug === "custom-activity") {
    lightSrc = customActivityLight;
    darkSrc = customActivityDark;
  } else if (componentTypeSlug === "snippet") {
    lightSrc = snippetLight;
    darkSrc = snippetDark;
  } else if (componentTypeSlug === "workflow-template") {
    lightSrc = workflowTemplateLight;
    darkSrc = workflowTemplateDark;
  } else if (componentTypeSlug === "connector") {
    lightSrc = connectorLight;
    darkSrc = connectorDark;
  } else if (componentTypeSlug === "dashboard") {
    lightSrc = dashboardLight;
    darkSrc = dashboardDark;
  } else if (componentTypeSlug === "machine-learning-model") {
    lightSrc = mlModelLight;
    darkSrc = mlModelDark;
  } else if (componentTypeSlug === "solution") {
    lightSrc = solutionLight;
    darkSrc = solutionDark;
  }

  return (
    <ImageThemed
      alt={alt || ""}
      src={lightSrc}
      srcDark={darkSrc}
      className={className}
    />
  );
};

export default memo(ComponentTypeIcon);
