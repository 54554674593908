import { Chip } from "@material-ui/core";
import React from "react";
import IconApollo from "../IconApollo/IconApollo";
import "./LabelDocumentUpload.scss";

const LabelDocumentUpload = props => {
  const { fileName, handleClearFile, className = "" } = props;

  return (
    <Chip
      className={`LabelDocumentUpload ${className}`}
      data-testid="LabelDocumentUpload"
      label={fileName}
      onDelete={handleClearFile}
      deleteIcon={
        <IconApollo
          data-testid="LabelDocumentUpload__ClearUploadButton"
          className="LabelDocumentUpload__clear-upload-btn"
          icon="close"
        />
      }
    />
  );
};

export default LabelDocumentUpload;
