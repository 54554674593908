import {
  SET_UPLOADED_IMAGE_URL,
  SET_UPLOADED_IMAGE_MODAL_COMPLETION,
  CLEAR_UPLOADED_IMAGE_URL
} from "../constants/actionTypes";

const INITIAL_STATE = {
  uploadedURL: "",
  uploadedCompletion: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_UPLOADED_IMAGE_URL:
      return {
        ...state,
        uploadedURL: action.payload
      };
    case SET_UPLOADED_IMAGE_MODAL_COMPLETION:
      return {
        ...state,
        uploadedCompletion: action.payload
      };
    case CLEAR_UPLOADED_IMAGE_URL:
      return {
        ...state,
        uploadedURL: ""
      };
    default:
      return state;
  }
}
