import { COAssessmentTypes, COConditionSlugs } from "../constants/co-constants";
import {
  sectionTemplateForSlug,
  defaultSectionTemplate
} from "../templates/co-section.template";
import { updateMetaDataValues } from "../utils/co-template.utils";
import { updatePositionSortOrderForItems } from "./co-position.helper";
import {
  addSectionToAssessment,
  addQuestionToSection
} from "./co-section.helper";
import {
  COAssessmentInterface,
  COConditionOverrideSlugPayload,
  COContextInterface,
  COSectionInterface,
  COSectionTemplateInterface
} from "../interfaces/co-interfaces";
import { customQuestionTemplateForSlug } from "../templates/co-custom-question-template-loader";

export const addSectionAndQuestions = ({
  context,
  assessment,
  section_template,
  question_slugs,
  ahid_modifier = ""
}: {
  context: COContextInterface;
  assessment: COAssessmentInterface;
  section_template: COSectionTemplateInterface;
  question_slugs: string[];
  ahid_modifier?: string;
}) => {
  let ahid_assessment_code = ``;
  if (
    assessment.co_assessment_type === COAssessmentTypes.DETAILED_ASSESSMENT_TYPE
  ) {
    ahid_assessment_code = ``; // not ideal but necessary for backwards compatibilty for the original CDA
  } else {
    ahid_assessment_code = `${assessment.co_assessment_type?.toLowerCase()}-`;
  }

  //hardcode section ahid
  let section_hardcoded_ahid = `ah-section-${ahid_assessment_code}0-${
    context.assessment?.co_assessment_sections?.length
  }${ahid_modifier.length > 0 ? "-" + ahid_modifier : ""}`;

  if (section_template?.co_section_json) {
    section_template.co_section_json.co_assessment_section_ahid = section_hardcoded_ahid;
  }
  let section: COSectionInterface | null = addSectionToAssessment({
    context,
    assessment,
    template: section_template,
    validate: false
  });

  if (section) {
    for (const slug of question_slugs) {
      let template = customQuestionTemplateForSlug(slug);
      if (template) {
        let reCalculatePosition = false;
        // we want to preserve the order from the template
        //hardcode question ahid
        let question_hardcoded_ahid = `ah-question-${ahid_assessment_code}0-${
          context.assessment?.co_assessment_sections?.length
        }${ahid_modifier.length > 0 ? "-" + ahid_modifier : ""}-${
          section?.co_questions?.length
        }`;
        if (template?.co_question_json) {
          template.co_question_json.co_question_ahid = question_hardcoded_ahid;
        }

        // hardcode question answer option ahid
        for (
          var qAIndex = 0;
          qAIndex <
            (template?.co_question_json?.co_question_answer_options || [])
              .length || 0;
          qAIndex++
        ) {
          let question_answer_option_hardcoded_ahid = `ah-answer_option-${ahid_assessment_code}0-${
            context.assessment?.co_assessment_sections?.length
          }${ahid_modifier.length > 0 ? "-" + ahid_modifier : ""}-${
            section?.co_questions?.length
          }-${qAIndex}`;
          if (
            template?.co_question_json?.co_question_answer_options?.[qAIndex]
          ) {
            template.co_question_json.co_question_answer_options[
              qAIndex
            ].co_question_answer_option_ahid = question_answer_option_hardcoded_ahid;
          }
        }
        let question = addQuestionToSection({
          context: context.update?.({ section }) || context,
          section: section,
          template: template,
          validate: false,
          reCalculatePosition
        });
      } else {
        console.log(`Default Question Template Not Found ${template}`);
      }
    }
    let sortedQuestions = (section?.co_questions || []).sort((a, b) => {
      return (
        (a.co_question_position_json?.sort_order || 0) -
        (b.co_question_position_json?.sort_order || 0)
      );
    });
    section.co_questions = sortedQuestions;
    updatePositionSortOrderForItems(section.co_questions || []);
  }
  return section;
};

export const sectionTemplateWithTitleAndDescription = ({
  title,
  description,
  note,
  sectionTemplateSlug,
  condition_overrides,
  is_read_only,
  prevent_deleting,
  is_hidden_everywhere,
  hide_before_live_phase,
  section_is_tabular
}: {
  title?: string;
  description?: string;
  note?: string;
  sectionTemplateSlug?: string;
  condition_overrides?: COConditionOverrideSlugPayload[];
  is_read_only?: number | string;
  prevent_deleting?: number | string;
  is_hidden_everywhere?: number | string;
  hide_before_live_phase?: number | string;
  section_is_tabular?: number | string;
}): COSectionTemplateInterface => {
  let sectionTemplate =
    (sectionTemplateSlug
      ? sectionTemplateForSlug(sectionTemplateSlug)
      : defaultSectionTemplate()) || {};

  updateMetaDataValues({
    metadata: sectionTemplate.co_section_json?.co_section_meta_json,
    title,
    description,
    note
  });

  if (sectionTemplate.co_section_json) {
    if (condition_overrides) {
      sectionTemplate.co_section_json.co_section_options_json = {
        ...sectionTemplate.co_section_json.co_section_options_json,
        condition_overrides: condition_overrides
      };
    }
    if (is_read_only) {
      sectionTemplate.co_section_json.co_section_options_json = {
        ...sectionTemplate.co_section_json.co_section_options_json,
        is_read_only: is_read_only
      };
    }
    if (prevent_deleting) {
      sectionTemplate.co_section_json.co_section_options_json = {
        ...sectionTemplate.co_section_json.co_section_options_json,
        prevent_deleting: prevent_deleting
      };
    }
    if (is_hidden_everywhere) {
      sectionTemplate.co_section_json.co_section_options_json = {
        ...sectionTemplate.co_section_json.co_section_options_json,
        condition_overrides: [
          ...(sectionTemplate.co_section_json.co_section_options_json
            ?.condition_overrides || []),
          { slug: COConditionSlugs.HIDDEN }
        ]
      };
    }
    if (hide_before_live_phase) {
      sectionTemplate.co_section_json.co_section_options_json = {
        ...sectionTemplate.co_section_json.co_section_options_json,
        condition_overrides: [
          ...(sectionTemplate.co_section_json.co_section_options_json
            ?.condition_overrides || []),
          { slug: COConditionSlugs.SECTION_SHOWN_AFTER_LIVE }
        ]
      };
    }
    if (section_is_tabular) {
      sectionTemplate.co_section_json.co_section_options_json = {
        ...sectionTemplate.co_section_json.co_section_options_json,
        is_tabular: 1
      };
    }
  }

  return sectionTemplate;
};
