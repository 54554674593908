import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  fetchAllDocumentTemplateTypes,
  addAdminDocumentTemplate,
  updateAdminDocumentTemplate,
  setDocumentTemplateToUpdate
} from "../../../actions/adminDocumentationTemplatesActions";
import { ADMIN_IMPORT_TEMPLATE_MODAL } from "./../../../constants/modalTypes";
import swal from "./../../SweetAlert/SweetAlert";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import AddDocumentDropBox from "../ModalAddUpdateDocument/AddDocumentDropBox/AddDocumentDropBox";
import { ADMIN_DOCUMENT_TEMPLATE } from "./../../../constants/fileUploadConstants";
import { uploadFile } from "./../../../actions/fileTransferActions";
import { renderPrivacyTextComponent } from "../../../utils/reusableStaticComponents";
import {
  triggerAnalyticsWithDefaultData,
  IMPORT_NEW_DOC_TEMPLATE_SUCCESS
} from "../../../utils/analyticsUtils";
import TenantSettingsHelper from "../../../helpers/TenantSettingsHelper";
import "./ModalImportEditDocumentTemplate.scss";
import ReactSelectApollo from "../../ReactSelectApollo/ReactSelectApollo";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";
import InputLabelApollo from "../../inputs/InputLabelApollo/InputLabelApollo";
import { Chip, FormControlLabel, Switch } from "@material-ui/core";
import Tooltip from "../../Tooltip/Tooltip";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";

class ModalImportEditDocumentTemplate extends Component {
  constructor(props) {
    super(props);

    this.initialState = {};
    if (props.documentTemplateToUpdate) {
      this.state = {
        fileData: props.documentTemplateToUpdate.file,
        template_title: props.documentTemplateToUpdate.template_title,
        template_type_id: props.documentTemplateToUpdate.template_type_id,
        template_is_default: props.documentTemplateToUpdate.template_is_default
      };
    } else {
      this.state = {
        fileData: "",
        template_title: "",
        template_type_id: "",
        template_is_default: false
      };
    }
    this.initialState = this.state;
  }

  componentDidMount() {
    this.props.fetchAllDocumentTemplateTypes();
  }

  renderUpload = () => {
    if (!this.state.fileData) {
      return (
        <AddDocumentDropBox handleFileChange={this.handleFileChange}>
          <p className="ModalImportEditDocument__upload-text-top">
            {/* prettier-ignore */}
            <Trans
              t={this.props.t}
              i18nKey="modal_import_document_add_file_here"
            >
              <span className="ModalImportEditDocument__upload-text-top--highlight">Add file</span> or drop file
              here
            </Trans>
          </p>
          <p className="ModalImportEditDocument__upload-text-bottom">
            {this.props.t([
              "modal_import_document_size",
              "(Maximum upload file size: 10 MB)"
            ])}{" "}
            <br />
            {this.props.t([
              "modal_import_document_type",
              "Allowed file types: doc, docx, xls, xlsx, ppt, pptx and pdf."
            ])}
          </p>
        </AddDocumentDropBox>
      );
    } else if (
      this.state.fileData &&
      (this.state.fileData.filename || this.state.fileData.name)
    ) {
      return (
        <div className="ModalImportEditDocument__clear-upload-container">
          <Chip
            className="ModalImportEditDocument__clear-upload"
            label={this.state.fileData.filename || this.state.fileData.name}
            onDelete={this.handleClearFile}
          />
        </div>
      );
    }
  };

  handleFileChange = fileData => {
    try {
      if (fileData) {
        const allowedFileTypes = [
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "pdf"
        ];

        const fileExtension =
          fileData &&
          (fileData.name || "")
            .split(".")
            .pop()
            .toLowerCase();
        const isAllowed =
          fileExtension && allowedFileTypes.indexOf(fileExtension) > -1;

        if (!isAllowed) {
          swal.fire({
            title: this.props.t("modal_import_document_error_not_right_format"),
            text: this.props.t("modal_import_document_error_file_type"),
            type: "error"
          });
          return false;
        }

        //10MB
        if (fileData.size > 10485760) {
          swal.fire({
            title: this.props.t("error_title_file_too_large"),
            text: this.props.t("error_text_file_too_large_10"),
            type: "error"
          });
          return false;
        }

        this.setState({ fileData: fileData });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleClearFile = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileData: null });
  };

  renderTypeOptions = () => {
    const { documentTemplateTypes } = this.props;
    return documentTemplateTypes.map(obj => ({
      label: this.props.t([
        "file_type_be_" + obj.file_type_key,
        obj.file_type_name
      ]),
      value: obj.file_type_id
    }));
  };

  notifyCallback() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  closeModalAndNotifyCallback(notifyCallback = true) {
    this.props.closeModal();
    this.props.setDocumentTemplateToUpdate(null);
    if (notifyCallback) {
      this.notifyCallback();
    }
  }

  closeModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.closeModalAndNotifyCallback();
  };

  checkIfValid = () => {
    if (
      this.state.fileData &&
      this.state.template_title &&
      this.state.template_type_id
    ) {
      return true;
    }
    return false;
  };

  handleImportTemplate = e => {
    e.preventDefault();
    if (!this.checkIfValid()) {
      return false;
    }

    const params = {
      file: this.state.fileData,
      type: ADMIN_DOCUMENT_TEMPLATE
    };
    this.props.uploadFile(params, this.saveDocumentAfterUpload, null);
  };

  handleEditTemplate = e => {
    e.preventDefault();
    if (!this.checkIfValid()) {
      return false;
    }
    if (this.initialState.fileData != this.state.fileData) {
      const params = {
        file: this.state.fileData,
        type: ADMIN_DOCUMENT_TEMPLATE
      };
      this.props.uploadFile(params, this.updateDocument, null);
    } else {
      this.updateDocument(null);
    }
  };

  updateDocument = fileData => {
    let data = {};
    if (fileData) {
      data["file_id"] = fileData.file_id;
    }
    if (this.initialState.template_type_id != this.state.template_type_id) {
      data["template_type_id"] = this.state.template_type_id;
    }
    if (this.initialState.template_title != this.state.template_title) {
      data["template_title"] = this.state.template_title;
    }
    if (
      this.initialState.template_is_default != this.state.template_is_default
    ) {
      data["template_is_default"] = this.state.template_is_default;
    }

    this.props.updateAdminDocumentTemplate(
      this.props.documentTemplateToUpdate.template_id,
      data
    );
    this.props.setDocumentTemplateToUpdate(null);
    this.closeModalAndNotifyCallback();
  };

  saveDocumentAfterUpload = fileData => {
    const data = {
      file_id: fileData.file_id,
      template_type_id: this.state.template_type_id,
      template_title: this.state.template_title,
      template_is_default: this.state.template_is_default
    };
    this.props.addAdminDocumentTemplate(data, this.afterSaveSuccessful);
    this.closeModalAndNotifyCallback(false);
  };

  afterSaveSuccessful = () => {
    this.notifyCallback();
    triggerAnalyticsWithDefaultData(IMPORT_NEW_DOC_TEMPLATE_SUCCESS);
  };

  render() {
    const { type } = this.props;
    let titleLabel =
      type === ADMIN_IMPORT_TEMPLATE_MODAL
        ? this.props.t([
            "modal_import_document_import_new_template_title",
            "Import New Template"
          ])
        : this.props.t([
            "modal_import_document_import_edit_template_title",
            "Edit Template"
          ]);
    let buttonLabel =
      type === ADMIN_IMPORT_TEMPLATE_MODAL
        ? this.props.t(["modal_import_document_import", "Import"])
        : this.props.t(["modal_import_document_save", "Save"]);
    let submitAction =
      type === ADMIN_IMPORT_TEMPLATE_MODAL
        ? this.handleImportTemplate
        : this.handleEditTemplate;

    const typeOptions = this.renderTypeOptions();

    const disableSettingDefault = !TenantSettingsHelper.showFileUploads();

    const modalContentStyle = {
      padding: "0",
      borderRadius: "4px",
      width: "500px",
      maxWidth: "96%"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={modalContentStyle}>
        <ModalTemplateOne
          heading={titleLabel}
          headerStyle={{ padding: "24px 30px 0" }}
          onClose={this.closeModal}
        >
          <form
            className="ModalImportEditDocument"
            onSubmit={submitAction}
            autoComplete="off"
          >
            <div
              className="ModalImportEditDocument__main"
              data-testid="ModalImportEditDocumentTemplate"
            >
              {this.renderUpload()}
              {renderPrivacyTextComponent({ t: this.props.t })}
              <InputTextApollo
                label={this.props.t(["modal_import_document_title", "Title"])}
                placeholder={this.props.t([
                  "modal_import_document_name_file",
                  "Name this file"
                ])}
                className="ModalImportEditDocument__field-input"
                required
                InputProps={{
                  value: this.state.template_title,
                  onChange: e =>
                    this.setState({ template_title: e.target.value }),
                  name: "template_title"
                }}
                inputProps={{
                  "data-testid": "ModalImportEditDocument__DocumentName"
                }}
              />
              <InputLabelApollo
                label={this.props.t([
                  "modal_import_document_document_type",
                  "Document Type"
                ])}
                className="ModalImportEditDocument__field-input"
                required
              >
                <ReactSelectApollo
                  options={typeOptions}
                  value={typeOptions.find(
                    obj => obj.value === this.state.template_type_id
                  )}
                  onChange={e => {
                    this.setState({ template_type_id: e.value });
                  }}
                  placeholder={this.props.t([
                    "modal_import_document_select_ducument_type",
                    "Select Document Type"
                  ])}
                  styles={{
                    menuList: base => ({
                      ...base,
                      maxHeight: "100px"
                    })
                  }}
                  aria-label={this.props.t([
                    "modal_import_document_select_ducument_type_aria_label",
                    "Document Type"
                  ])}
                />
              </InputLabelApollo>

              <Tooltip
                title={
                  disableSettingDefault
                    ? this.props.t([
                        "documentation_template_default_readonly_message",
                        "This template is not visible to users. File Upload is disabled from the Settings page."
                      ])
                    : ""
                }
              >
                <FormControlLabel
                  className="ModalImportEditDocument__field-input"
                  control={
                    <Switch
                      checked={!!this.state.template_is_default}
                      onChange={() =>
                        this.setState({
                          template_is_default: !this.state.template_is_default
                        })
                      }
                    />
                  }
                  disabled={disableSettingDefault}
                  label={this.props.t([
                    "modal_import_document_set_template",
                    "Set template as default"
                  ])}
                />
              </Tooltip>
            </div>
            <div className="ModalImportEditDocument__footer">
              <ButtonApolloSecondary
                onClick={this.closeModal}
                data-testid="ModalImportEditDocument__CancelButton"
              >
                {this.props.t(["modal_import_document_cancel", "Cancel"])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                data-testid="ModalImportEditDocument__SaveButton"
                onClick={submitAction}
                disabled={!this.checkIfValid()}
                className={`ModalImportEditDocument__footer-save-btn`}
              >
                {buttonLabel}
              </ButtonApolloPrimary>
            </div>
          </form>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  documentTemplateTypes:
    state.adminDocumentationTemplates.documentTemplateTypes,
  documentTemplateToUpdate:
    state.adminDocumentationTemplates.documentTemplateToUpdate
});

const mapActionsToProps = {
  uploadFile,
  fetchAllDocumentTemplateTypes,
  addAdminDocumentTemplate,
  updateAdminDocumentTemplate,
  setDocumentTemplateToUpdate
};

export default withTranslation("common")(
  connect(mapStateToProps, mapActionsToProps)(ModalImportEditDocumentTemplate)
);
