import React from "react";

const DEFAULT_PRIVACY_TEXT_STYLE = {
  marginTop: "10px",
  padding: "10px",
  backgroundColor: "var(--color-background-secondary)",
  fontWeight: 500,
  fontSize: ".75rem",
  color: "var(--color-foreground-de-emp)"
};

export const renderPrivacyTextComponent = (props = {}) => {
  const { className = "", style = {}, t } = props;
  const content = t
    ? t("modal_add_update_document_privacy")
    : "Please make sure that the technical documentation you upload does not contain classified information or personal data.";
  return (
    <p
      className={className}
      style={{ ...DEFAULT_PRIVACY_TEXT_STYLE, ...style }}
    >
      {content}
    </p>
  );
};
