import SessionHelper from "./SessionHelper";

import {
  module_go_id,
  module_navigator_id
} from "./../constants/moduleConstants";

class ModuleHelper {
  isModuleEnabled(feature_id) {
    let data = SessionHelper.getDataFromUserToken();
    if (data) {
      let enabled_modules = data.enabled_modules;
      if (enabled_modules) {
        for (let i = 0; i < enabled_modules.length; i++) {
          let module = enabled_modules[i];
          if (module.id === feature_id) {
            return true;
          }
        }
      }
    }

    return false;
  }

  isGoEnabled() {
    return this.isModuleEnabled(module_go_id);
  }

  isNavigatorEnabled() {
    return this.isModuleEnabled(module_navigator_id);
  }
}

export default new ModuleHelper();
