import { COConditionSlugs } from "./co-constants";

export const branchConditionSlugs: COConditionSlugs[] = [
  COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION,
  COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS
];

// ensure that these answer option values match the slugs produced by selectableAnswerOptionValueValidator
// or the special logic for the assessment visibility options may no longer work
export const ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE = "hidden";
export const ASSESSMENT_VISIBILITY_START_FRESH_OPTION_VALUE = "start_fresh";
export const ASSESSMENT_VISIBILITY_INHERIT_OPTION_VALUE =
  "inherit_from_parent_idea";
