import React from "react";
import { LocalizeTranslationKey } from "../utils/localizedAlertMessages";

export const COMPANY_NAME = "company_name";
export const STATE = "state";
export const WORK_EMAIL = "work_email";
export const LICENSE_PLAN = "license_plan";
export const LICENSE_PLAN_OPTIONS = "license_plan_option";
export const IS_NEW_TENANT = "is_new_tenant";
export const TENANT_NAME = "tenant_name";
export const SALES_MESSAGE = "sales_message";
export const RENEW_SUBSCRIPTION = "renew_subscription";
export const TENANT_EMAIL = "tenant_email";

//plan slugs
export const TRIAL_SLUG = "TRIAL";
export const STANDARD_SLUG = "ENT-STD";
export const PREMIUM_SLUG = "PREMIUM";
export const NOT_FOR_RESALE_SLUG = "NFR";

//license button types
export const CHANGE_PLAN = "Change Plan";
export const EXTEND_TRIAL = "Extend Trial";
export const REQUEST_RENEWAL = "Request Renewal";

//sucess messages
export const REQUEST_SUCCESSFUL = "Your request was successfully submitted";
export const VALIDATE_USER_EMAIL =
  "Your request has been received. A representative will be in touch with you shortly.";

//Regex for Checks
export const STANDARD_TEXT_REGEX = /^$|[a-x\d\-_\s\!\(\)\#.:]+$/i;
export const PATH_REGEX = /^$|^[a-z\d][a-z\d\-_\s\!\(\)\#.:]*$/i;

//character limits
const SALES_MESSAGE_MAX_LENGTH = 255;

export const UNLIMITED_ACCOUNTS_NUMBER = 1000000;

export const CHANGE_PLAN_FIELDS = {
  [COMPANY_NAME]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_change_plan_company_name"}
      />
    ),
    name: COMPANY_NAME,
    value: "",
    type: "text",
    required: true
  },

  [WORK_EMAIL]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_change_plan_work_email"}
      />
    ),
    name: WORK_EMAIL,
    value: "",
    type: "email",
    required: true
  },

  [LICENSE_PLAN]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_change_plan_select_plan"}
      />
    ),
    name: LICENSE_PLAN,
    value: null,
    type: "dropdown",
    required: true
  },

  [IS_NEW_TENANT]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_change_plan_is_new_tenant"}
      />
    ),
    name: IS_NEW_TENANT,
    value: 0,
    type: "radio",
    options: [
      {
        label: (
          <LocalizeTranslationKey
            localeKey={
              "manage_plan_change_plan_is_new_tenant_option_keep_current"
            }
          />
        ),
        value: 0,
        description: (
          <LocalizeTranslationKey
            localeKey={
              "manage_plan_change_plan_is_new_tenant_option_keep_current_description"
            }
          />
        )
      },
      {
        label: (
          <LocalizeTranslationKey
            localeKey={
              "manage_plan_change_plan_is_new_tenant_option_new_tenant"
            }
          />
        ),
        value: 1,
        description: (
          <LocalizeTranslationKey
            localeKey={
              "manage_plan_change_plan_is_new_tenant_option_new_tenant_description"
            }
          />
        )
      }
    ],
    required: true
  },
  [TENANT_NAME]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_change_plan_tenant_name"}
      />
    ),
    name: TENANT_NAME,
    value: "",
    type: "text",
    validRegex: PATH_REGEX,
    required: true
  },

  [SALES_MESSAGE]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_change_plan_sales_message"}
      />
    ),
    name: SALES_MESSAGE,
    value: "",
    maxLength: SALES_MESSAGE_MAX_LENGTH,
    type: "textarea",
    required: true
  }
};

export const EXTEND_TRIAL_FIELDS = {
  [SALES_MESSAGE]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_extent_trial_sales_message"}
      />
    ),
    name: SALES_MESSAGE,
    value: "",
    maxLength: SALES_MESSAGE_MAX_LENGTH,
    type: "textarea",
    required: true
  }
};

export const REQUEST_RENEWAL_FIELDS = {
  [RENEW_SUBSCRIPTION]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_request_renewal_renew_subscription"}
      />
    ),
    name: RENEW_SUBSCRIPTION,
    value: null,
    type: "dropdown",
    required: true
  },
  [SALES_MESSAGE]: {
    label: (
      <LocalizeTranslationKey
        localeKey={"manage_plan_request_renewal_sales_message"}
      />
    ),
    name: SALES_MESSAGE,
    value: "",
    type: "textarea",
    maxLength: SALES_MESSAGE_MAX_LENGTH,
    required: true
  }
};
