import React, { Component } from "react";
import ModalContainer from "../components/Modal/ModalContainer";
import NavLogin from "./../components/NavLogin/NavLogin";
import MainFooter from "./../components/footers/MainFooter/MainFooter";
import MasterLayoutWrapper from "./masterLayoutWrapper";
import SessionHelper from "./../helpers/SessionHelper";

export default class AuthLayout extends Component {
  render() {
    return (
      <MasterLayoutWrapper>
        {!SessionHelper.isFpsUri() && <NavLogin />}
        {this.props.children}

        <ModalContainer />
        {!SessionHelper.isFpsUri() && <MainFooter />}
      </MasterLayoutWrapper>
    );
  }
}
