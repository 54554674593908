import React, { ReactNode } from "react";
import styled from "styled-components";
import IconApollo from "../IconApollo/IconApollo";
import Tooltip from "../Tooltip/Tooltip";

const Container = styled.div`
  display: inline-block;
  font-size: 16px;
  color: var(--color-icon-default);
`;

const IconApolloStyled = styled(IconApollo)`
  color: inherit;
`;

const TooltipIcon = ({
  title,
  icon,
  iconSrText,
  className,
  TooltipProps,
  IconApolloProps
}: {
  title?: ReactNode;
  icon?: string;
  iconSrText?: string;
  className?: string;
  TooltipProps?: { [key: string]: any };
  IconApolloProps?: { [key: string]: any };
}) => {
  if (!title && !TooltipProps?.title) {
    return null;
  }

  return (
    <Tooltip title={title} {...TooltipProps}>
      <Container className={className}>
        <IconApolloStyled
          icon={icon || "info_outline"}
          srText={iconSrText}
          fontSize="inherit"
          {...IconApolloProps}
        />
      </Container>
    </Tooltip>
  );
};

export default TooltipIcon;
