import {
  TOTAL_SUM_STAT_ROW_KEY,
  PAGE_SUM_STAT_ROW_KEY
} from "../constants/liveAutomationsConstants";

const STATUS_FILTER_DATA_PROP = "selectedStatusFilterId";
const AUTOMATION_NAME_FILTER_DATA_PROP = "selectedSubmissionFilterId";
const PRIORITY_FILTER_DATA_PROP = "selectedPriorityIds";
const DEVELOPMENT_TYPE_FILTER_DATA_PROP = "selectedDevelopmentTypeIds";

export const AUTOMATION_CHECKBOX_STRING = "Automation Checkbox";
export const AUTOMATION_NAME_STRING = "Automation Name";
export const AUTOMATION_ID_STRING = "Automation ID";
export const LAUNCH_DATE_STRING = "Launch Date";
export const DESCRIPTION_STRING = "Description";
export const IDEA_PHASE_APPROVER_STRING = "Idea Phase Approver";
export const LAUNCH_ASSESSMENT_APPROVER_STRING = "Launch Assessment Approver";
export const QUALIFICATION_PHASE_APPROVER_STRING =
  "Qualification Phase Approver";
export const DATE_OF_ACTION_STRING = "Date of Action";
export const NUMBER_OF_ROBOTS_STRING = "Number Of Robots";
export const ESTIMATED_BENEFIT_FTE_STRING = "Est. Benefit Per Company (FTEs)";
export const ESTIMATED_IMPLEMENTATION_COSTS = "Est. Implementation Costs";
export const ESTIMATED_RUNNING_COSTS = "Est. Running Costs";
export const ESTIMATED_NET_BENEFIT_YEAR_1 = "Est. Net Benefit Year 1";
export const ESTIMATED_NET_BENEFIT_YEAR_2 = "Est. Net Benefit Year 2";
export const LAST_MODIFIED_STRING = "Last Modified";
export const DATE_SUBMITTED_STRING = "Date Submitted";
export const HIERARCHY_STRING = "Hierarchy";
export const CATEGORY_STRING = "Categories";
export const AUTOMATION_AREA_STRING = "Automation Area";
export const AUTOMATION_CATEGORY_STRING = "Automation Category";
export const AUTOMATION_SUB_CATEGORY_STRING = "Automation Sub-Category";
export const DEPARTMENT_STRING = "Submitter’s Department";
export const BUSINESS_UNIT_STRING = "Submitter’s Business Unit";
export const PHASE_STRING = "Phase";
export const STATUS_STRING = "Status";
export const IDEA_SCORE_STRING = "Idea Score";
export const ESTIMATED_BENEFIT_HOURS_STRING =
  "Est. Benefit Per Company (Hours)";
export const ESTIMATED_BENEFIT_HOURS_CSV_STRING =
  "Estimated Benefits Per Company (hours saved in a year)";
export const ESTIMATED_BENEFITS_CURRENCY_CSV_STRING =
  "Estimated Benefit Per Company (currency)";
export const ESTIMATED_BENEFITS_CURRENCY_STRING =
  "Est. Benefit Per Company (currency)";
export const ESTIMATED_IMPLEMENTATION_COSTS_STRING =
  "Est. Implementation Costs";
export const ESTIMATED_RUNNING_COSTS_STRING = "Est. Running Costs";
export const ESTIMATED_NET_BENEFIT_YEAR_ONE_STRING = "Est. Net Benefit Year 1";
export const ESTIMATED_NET_BENEFIT_YEAR_TWO_STRING = "Est. Net Benefit Year 2";
export const PDD_LINK_STRING = "PDD Link";
export const RPA_SOFTWARE_VENDOR_CSV_STRING = "RPA Software Vendor";
export const AUTOMATION_TYPE_CSV_STRING = "Automation Type";
export const LICENSE_TYPE_CSV_STRING = "License Type";
export const AUTOMATION_POTENTIAL_CSV_STRING = "Automation Potential";
export const EASE_OF_IMPLEMENTATION_STRING = "Ease of Implementation";
export const ASSESSMENT_SCORE_STRING = "Assessment Score";
export const AUTOMATION_GOAL_STRING = "Automation Goal";
export const COLLABORATORS_STRING = "Collaborators";
export const UPVOTES_STRING = "Upvotes";
export const ANALYSIS_BASELINE_STRING = "Analysis-Baseline";
export const ANALYSIS_ACTUAL_STRING = "Analysis-Actual";
export const ANALYSIS_DELTA_STRING = "Analysis-Delta";
export const SOLUTION_DESIGN_BASELINE_STRING = "Solution Design-Baseline";
export const SOLUTION_DESIGN_ACTUAL_STRING = "Solution Design-Actual";
export const SOLUTION_DESIGN_DELTA_STRING = "Solution Design-Delta";
export const DEVELOPMENT_BASELINE_STRING = "Development-Baseline";
export const DEVELOPMENT_ACTUAL_STRING = "Development-Actual";
export const DEVELOPMENT_DELTA_STRING = "Development-Delta";
export const TESTING_BASELINE_STRING = "Testing-Baseline";
export const TESTING_ACTUAL_STRING = "Testing-Actual";
export const TESTING_DELTA_STRING = "Testing-Delta";
export const RPA_SOFTWARE_VENDOR_STRING = "RPA Software Vendor";
export const AUTOMATION_TYPE_STRING = "Automation Type";
export const LICENSE_TYPE_STRING = "License Type";
export const ROBOT_WORKING_HOURS_PER_DAY_STRING = "Robot Working hours per day";
export const ROBOT_WORKING_DAYS_PER_YEAR_STRING = "Robot Working days per year";
export const AHT_EMPLOYEE_PER_TRANSACTION_MINUTES_STRING =
  "AHT Employee per Transaction (minutes)";
export const ROBOT_SPEED_MULTIPLIER_STRING = "Robot Speed Multiplier";
export const AHT_ROBOT_PER_TRANSACTION_MINUTES_STRING =
  "AHT Robot per Transaction (minutes)";
export const AUTOMATION_POTENTIAL_FINAL_STRING = "Automation Potential (final)";
export const VOLUMES_PER_YEAR_TOTAL_STRING = "Volumes per Year - Total";
export const VOLUMES_PER_YEAR_HUMAN_AFTER_AUTOMATION_STRING =
  "Volumes per Year - Human After Automation";
export const VOLUMES_PER_YEAR_ROBOT_FINAL_AFTER_AUTOMATION_STRING =
  "Volumes per Year - Robot Final After Automation";
export const HUMAN_COST_PER_YEAR_STRING = "Human Cost per Year";
export const RUNNING_COST_PER_TRANSACTION_HUMAN_STRING =
  "Running Cost per Transaction (Human)";
export const FTR_OVERRIDDEN_STRING = "FTR Overridden";
export const IMPLEMENTATION_PEOPLE_COSTS_STRING = "Implementation People Costs";
export const RPA_SOFTWARE_COSTS_PER_YEAR_STRING = "RPA Software Costs per year";
export const OTHER_SOFTWARE_COSTS_PER_YEAR_STRING =
  "Other Software Costs per year";
export const SUPPORT_TEAM_COSTS_PER_YEAR_STRING = "Support Team Costs per year";
export const INFRASTRUCTURE_COSTS_PER_YEAR_STRING =
  "Infrastructure Costs per year	";
export const OTHER_COSTS_PER_YEAR_STRING = "Other Costs per year";
export const TOTAL_RUNNING_COSTS_PER_YEAR_ROBOT_STRING =
  "Total Running Costs per year (Robot)";
export const RUNNING_COST_PER_TRANSACTION_ROBOT_STRING =
  "Running Cost per Transaction (Robot)";
export const TOTAL_YEAR_1_STRING = "Total Year 1";
export const TOTAL_YEAR_2_STRING = "Total Year 2";
const ACTION_STRING = "Action";
export const PROCESS_PRIORITY_STRING = "Priority";
export const DEVELOPMENT_TYPE_STRING = "Development Type";

/* Not equivalent to the field name for the process, also used for localization  */
export const ASSESSMENT_SCORE_KEY = "assessment-score";
export const ACTION_KEY = "action";
export const AUTOMATION_NAME_KEY = "process_name";
export const LAUNCH_DATE_KEY = "launch_date";
export const TASK_NAME_KEY = "task_name";
export const DATE_ASSIGNED_KEY = "date_assigned_task";
export const TASK_TITLE_KEY = "task_title";
export const AUTOMATION_ID_KEY = "orchestrator_automation_uuid";
export const FTR_OVERRIDDEN_KEY = "ftr_overridden";
export const DESCRIPTION_KEY = "description";
export const IDEA_PHASE_APPROVER_KEY = "idea_phase_approver";
export const LAUNCH_ASSESSMENT_APPROVER_KEY = "launch_assessment_approver";
export const QUALIFICATION_PHASE_APPROVER_KEY = "qualification_phase_approver";
export const CATEGORY_KEY = "hierarchy";
export const HIERARCHY_KEY = "hierarchy";
export const TAGS_KEY = "process_tags";
export const DEPARTMENT_KEY = "department";
export const BUSINESS_UNIT_KEY = "business_unit";
export const AUTOMATION_GOAL_KEY = "process_goal";
export const RPA_SOFTWARE_VENDOR_KEY = "software_vendor";
export const LICENSE_TYPE_KEY = "license_type";
export const AUTOMATION_TYPE_KEY = "process_type";
export const PHASE_KEY = "phase";
export const STATUS_KEY = "phase_status";
export const COLLABORATORS_KEY = "collaborators";
export const UPVOTES_KEY = "number_of_votes";
export const IDEA_SCORE_KEY = "idea_score";
export const AUTOMATION_POTENTIAL_KEY = "process_automation_score";
export const CBA_AUTOMATION_POTENTIAL_KEY = "cba_automation_potential";
export const ESTIMATED_BENEFIT_HOURS_KEY = "estimated_benefit_hours";
export const ESTIMATED_BENEFIT_FTE_KEY = "estimated_benefit_fte";
export const CBA_ESTIMATED_BENEFIT_FTE_KEY = "cba_estimated_benefit_ftes";
export const EASE_OF_IMPLEMENTATION_KEY =
  "process_ease_of_implementation_score";
export const CBA_EASE_OF_IMPLEMENTATION_KEY = "cba_ease_of_implementation";
export const ESTIMATED_BENEFIT_CURRENCY_KEY = "estimated_benefit_currency";
export const CBA_ESTIMATED_BENEFIT_CURRENCY_KEY = "cba_estimated_benefit_cost";
export const ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE_KEY =
  "estimated_benefit_hours_per_employee";
export const CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE_KEY =
  "cba_estimated_benefit_hours_per_employee";
export const CBA_ESTIMATED_BENEFIT_SCORE = "cba_estimated_benefit_score";
export const ESTIMATED_BENEFIT_FTE_PER_EMPLOYEE_KEY =
  "estimated_benefit_ftes_per_employee";
export const CBA_ESTIMATED_BENEFIT_FTE_PER_EMPLOYEE_KEY =
  "cba_estimated_benefit_ftes_per_employee";
export const ESTIMATED_BENEFIT_CURRENCY_PER_EMPLOYEE_KEY =
  "estimated_cost_benefit_per_employee";
export const CBA_ESTIMATED_BENEFIT_CURRENCY_PER_EMPLOYEE_KEY =
  "cba_estimated_cost_benefit_per_employee";
export const NUMBER_OF_ROBOTS_KEY = "number_of_robots";
export const ESTIMATED_IMPLEMENTATION_COSTS_KEY =
  "estimated_implementation_costs";
export const ESTIMATED_RUNNING_COSTS_KEY = "estimated_running_costs";
export const ESTIMATED_NET_BENEFIT_YEAR_ONE_KEY =
  "estimated_net_benefit_year_one";
export const ESTIMATED_NET_BENEFIT_YEAR_TWO_KEY =
  "estimated_net_benefit_year_two";
export const PDD_LINK_KEY = "pdd_link";
export const USER_INVITE_STATUS_KEY = "user_invite_status";
export const PROCESS_PRIORITY_KEY = "process_priority";
export const DEVELOPMENT_TYPE_KEY = "development_type";
export const PUBLISHED_KEY = "automation_published";
export const AUTOMATION_POTENTIAL_BENEFIT_KEY =
  "automation_potential_benefit_across_company";
export const APPLICATIONS_USED_KEY = "applications_used";
export const IDEA_SOURCE_KEY = "process_submission_type";
export const ROBOT_WORKING_HOURS_PER_DAY_KEY = "robot_working_hours_per_day";
export const AHT_EMPLOYEE_PER_TRANSACTION_MINUTES_KEY =
  "aht_employee_per_transaction_minutes";
export const AHT_ROBOT_PER_TRANSACTION_MINUTES_KEY =
  "aht_robot_per_transaction_minutes";
export const AUTOMATION_POTENTIAL_FINAL_KEY = "automation_potential_final";
export const HUMAN_COST_PER_YEAR_KEY = "human_cost_per_year";
export const IMPLEMENTATION_PEOPLE_COSTS_KEY = "implementation_people_costs";
export const INFRASTRUCTURE_COSTS_PER_YEAR_KEY =
  "infrastructure_costs_per_year";
export const OTHER_COSTS_PER_YEAR_KEY = "other_costs_per_year";
export const OTHER_SOFTWARE_COSTS_PER_YEAR_KEY =
  "other_software_costs_per_year";
export const ROBOT_SPEED_MULTIPLIER_KEY = "robot_speed_multiplier";
export const ROBOT_WORKING_DAYS_PER_YEAR_KEY = "robot_working_days_per_year";
export const RPA_SOFTWARE_COSTS_PER_YEAR_KEY = "rpa_software_costs_per_year";
export const RUNNING_COST_PER_TRANSACTION_HUMAN_KEY =
  "running_cost_per_transaction_human";
export const RUNNING_COST_PER_TRANSACTION_ROBOT_KEY =
  "running_cost_per_transaction_robot";
export const SUPPORT_TEAM_COSTS_PER_YEAR_KEY = "support_team_costs_per_year";
export const TOTAL_RUNNING_COSTS_PER_YEAR_ROBOT_KEY =
  "total_running_costs_per_year_robot";
export const TOTAL_YEAR_ONE_KEY = "total_year_one";
export const TOTAL_YEAR_TWO_KEY = "total_year_two";
export const VOLUMES_PER_YEAR_HUMAN_AFTER_AUTOMATION_KEY =
  "volumes_per_year_human_after_automation";
export const VOLUMES_PER_YEAR_ROBOT_FINAL_AFTER_AUTOMATION_KEY =
  "volumes_per_year_robot_final_after_automation";
export const TOTAL_PROCESSING_TIME_KEY = "total_processing_time";
export const TOTAL_REWORK_TIME_KEY = "total_rework_time";
export const TOTAL_REVIEW_AUDIT_TIME_KEY = "total_review_audit_time";
export const TOTAL_TIME_NEEDED_TO_PERFORM_WORK =
  "total_time_needed_to_perform_work";
export const VOLUMES_PER_YEAR_TOTAL_KEY = "volumes_per_year_total";
export const ANALYSIS_BASELINE_KEY = "analysis_baseline";
export const ANALYSIS_ACTUAL_KEY = "analysis_actual";
export const ANALYSIS_DELTA_KEY = "analysis_delta";
export const SOLUTION_DESIGN_BASELINE_KEY = "solution_design_baseline";
export const SOLUTION_DESIGN_ACTUAL_KEY = "solution_design_actual";
export const SOLUTION_DESIGN_DELTA_KEY = "solution_design_delta";
export const DEVELOPMENT_BASELINE_KEY = "development_baseline";
export const DEVELOPMENT_ACTUAL_KEY = "development_actual";
export const DEVELOPMENT_DELTA_KEY = "development_delta";
export const TESTING_BASELINE_KEY = "testing_baseline";
export const TESTING_ACTUAL_KEY = "testing_actual";
export const TESTING_DELTA_KEY = "testing_delta";
export const ANALYSIS_KEY = "analysis";
export const VOLUMES_PER_YEAR_KEY = "volumes_per_year";
export const SOLUTION_DESIGN_KEY = "solution_design";
export const DEVELOPMENT_KEY = "development";
export const TESTING_KEY = "testing";
export const NUMBER_OF_EMPLOYEES_KEY = "number_of_employees";
export const AUTO_GENERATED_ID_KEY = "process_id";
export const DATE_SUBMITTED_KEY = "process_created_epoch";

export const HEADER_TO_FILTER_FIELD = {
  [STATUS_STRING]: STATUS_FILTER_DATA_PROP,
  [AUTOMATION_NAME_STRING]: AUTOMATION_NAME_FILTER_DATA_PROP,
  [PROCESS_PRIORITY_STRING]: PRIORITY_FILTER_DATA_PROP,
  [DEVELOPMENT_TYPE_STRING]: DEVELOPMENT_TYPE_FILTER_DATA_PROP
};

export const HEADER_TOOLTIPS = {
  [IDEA_PHASE_APPROVER_KEY]: "Name of the Idea Approver.",
  [LAUNCH_ASSESSMENT_APPROVER_KEY]:
    "Name of the Program Manager who launched the assessment.",
  [QUALIFICATION_PHASE_APPROVER_KEY]:
    "Name of the Program Manager who reviewed the idea.",
  [PDD_LINK_KEY]:
    "Will be populated only if a PDD link is embedded for the idea. Uploaded documents are not displayed in this column."
};

export const COLUMN_KEY_DELTAS = [
  "analysis_delta",
  "development_delta",
  "solution_design_delta",
  "testing_delta"
];
export const DELTA_ROW_KEYS = [PAGE_SUM_STAT_ROW_KEY, TOTAL_SUM_STAT_ROW_KEY];
export const COLUMN_KEY_TO_HEADER = {
  orchestrator_automation_uuid: AUTOMATION_ID_STRING,
  launch_date: LAUNCH_DATE_STRING,
  analysis_baseline: ANALYSIS_BASELINE_STRING,
  analysis_actual: ANALYSIS_ACTUAL_STRING,
  analysis_delta: ANALYSIS_DELTA_STRING,
  solution_design_baseline: SOLUTION_DESIGN_BASELINE_STRING,
  solution_design_actual: SOLUTION_DESIGN_ACTUAL_STRING,
  solution_design_delta: SOLUTION_DESIGN_DELTA_STRING,
  development_baseline: DEVELOPMENT_BASELINE_STRING,
  development_actual: DEVELOPMENT_ACTUAL_STRING,
  development_delta: DEVELOPMENT_DELTA_STRING,
  testing_baseline: TESTING_BASELINE_STRING,
  testing_actual: TESTING_ACTUAL_STRING,
  testing_delta: TESTING_DELTA_STRING,
  software_vendor: RPA_SOFTWARE_VENDOR_STRING,
  process_type: AUTOMATION_TYPE_STRING,
  license_type: LICENSE_TYPE_STRING,
  robot_working_hours_per_day: ROBOT_WORKING_HOURS_PER_DAY_STRING,
  robot_working_days_per_year: ROBOT_WORKING_DAYS_PER_YEAR_STRING,
  aht_employee_per_transaction_minutes: AHT_EMPLOYEE_PER_TRANSACTION_MINUTES_STRING,
  robot_speed_multiplier: ROBOT_SPEED_MULTIPLIER_STRING,
  aht_robot_per_transaction_minutes: AHT_ROBOT_PER_TRANSACTION_MINUTES_STRING,
  automation_potential_final: AUTOMATION_POTENTIAL_FINAL_STRING,
  volumes_per_year_total: VOLUMES_PER_YEAR_TOTAL_STRING,
  volumes_per_year_human_after_automation: VOLUMES_PER_YEAR_HUMAN_AFTER_AUTOMATION_STRING,
  volumes_per_year_robot_final_after_automation: VOLUMES_PER_YEAR_ROBOT_FINAL_AFTER_AUTOMATION_STRING,
  human_cost_per_year: HUMAN_COST_PER_YEAR_STRING,
  running_cost_per_transaction_human: RUNNING_COST_PER_TRANSACTION_HUMAN_STRING,
  number_of_robots: NUMBER_OF_ROBOTS_STRING,
  ftr_overridden: FTR_OVERRIDDEN_STRING,
  implementation_people_costs: IMPLEMENTATION_PEOPLE_COSTS_STRING,
  rpa_software_costs_per_year: RPA_SOFTWARE_COSTS_PER_YEAR_STRING,
  other_software_costs_per_year: OTHER_SOFTWARE_COSTS_PER_YEAR_STRING,
  support_team_costs_per_year: SUPPORT_TEAM_COSTS_PER_YEAR_STRING,
  infrastructure_costs_per_year: INFRASTRUCTURE_COSTS_PER_YEAR_STRING,
  other_costs_per_year: OTHER_COSTS_PER_YEAR_STRING,
  total_running_costs_per_year_robot: TOTAL_RUNNING_COSTS_PER_YEAR_ROBOT_STRING,
  running_cost_per_transaction_robot: RUNNING_COST_PER_TRANSACTION_ROBOT_STRING,
  total_year_one: TOTAL_YEAR_1_STRING,
  total_year_two: TOTAL_YEAR_2_STRING,
  process_priority: PROCESS_PRIORITY_STRING,
  development_type: DEVELOPMENT_TYPE_STRING
};
