import React, { Component } from "react";
import { getKeyValue } from "../../../../utils/objectParsers";
import "./AddDocumentDropBox.scss";

class AddDocumentDropBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDraggedOn: false
    };
  }

  componentWillMount() {
    window.document.addEventListener("dragover", this.preventDefault);
    window.document.addEventListener("drop", this.preventDefault);
  }

  componentWillUnmount() {
    window.document.removeEventListener("dragover", this.preventDefault);
    window.document.removeEventListener("drop", this.preventDefault);
  }

  preventDefault = e => {
    if (e) e.preventDefault();
  };

  handleDragOver = e => {
    this.preventDefault(e);
    this.setState({ isDraggedOn: true });
  };

  handleDragLeave = e => {
    this.preventDefault(e);
    this.setState({ isDraggedOn: false });
  };

  handleDrop = e => {
    this.preventDefault(e);
    this.setState({ isDraggedOn: false });
    if (e.dataTransfer.files[0]) {
      this.props.handleFileChange(e.dataTransfer.files[0]);
    }
  };

  handleFileSelection = e => {
    if (e.target.files[0]) {
      this.props.handleFileChange(e.target.files[0]);
    }
  };

  clickFileInput = e => {
    e.stopPropagation();

    if (typeof e.target.querySelector === "function" && !this.props.noClick) {
      const fileElement = e.target.querySelector(".AddDocumentDropBox__input");

      if (fileElement && typeof fileElement.click === "function") {
        fileElement.click();
      }
    }
  };

  render() {
    const { noStyles, noClick } = this.props;

    let className = "AddDocumentDropBox";
    if (this.state.isDraggedOn) {
      className += " AddDocumentDropBox--hovered";
    } else if (noStyles) {
      className = "";
    }

    //this clears the input so you can trigger the "handleFileSelection" action again when uploading the same doc
    let onInputClick = event => {
      event.target.value = "";
    };

    return (
      <label
        className={className}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        data-testid="AddDocumentDropBox__label"
        tabIndex={0}
        onKeyPress={this.clickFileInput}
      >
        {!noClick && (
          <input
            type="file"
            className="AddDocumentDropBox__input"
            onChange={this.handleFileSelection}
            onClick={onInputClick}
          />
        )}
        {this.props.children}
      </label>
    );
  }
}

export default AddDocumentDropBox;
