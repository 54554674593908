export const coUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const coRandomVar = () => {
  let randomVar = "xxxx_xxxxx_xxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).toUpperCase();
  });
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  randomVar = randomVar.replace(/[0-9]/g, function(c) {
    var r = Math.random() * characters.length;
    return characters.charAt(Math.round(r));
  });

  return randomVar;
};
export const coRandomShortVar = () => {
  let randomVar = "xxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).toUpperCase();
  });
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  randomVar = randomVar.replace(/[0-9]/g, function(c) {
    var r = Math.random() * characters.length;
    return characters.charAt(Math.round(r));
  });
  return randomVar;
};

export const toSlug = (title: string) => {
  return (title || "")
    .toLowerCase()
    .replace(/\s/g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "")
    .substring(0, 255); /// we need to clamp these so they can fit in db columns
};

export const transformTextToDraftJS = text => {
  const draftJS = {
    blocks: [
      {
        key: "oapi1",
        text: text,
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  };

  return JSON.stringify(draftJS);
};
export const isDraftJS = text => {
  if (!text) {
    return false;
  }
  try {
    let parsed = JSON.parse(text);
    if (parsed && parsed.blocks) {
      return true;
    }
  } catch (error) {
    return false;
  }
};
export const isRunningInBrowser = () => {
  if (typeof process !== "undefined") {
    if (process.env && process.env.AH_ENV) {
      return false;
    }
  }
  return true;
};

export const mergeObjects = (
  baseObj: { [key: string]: any },
  overRiddingObj: { [key: string]: any }
) => {
  const baseObjKeys = Object.keys({
    ...(baseObj || {})
  });
  const overRiddingObjKeys = Object.keys({ ...(overRiddingObj || {}) });
  const keysSet = new Set([...baseObjKeys, ...overRiddingObjKeys]);

  const mergedObject = [...keysSet].reduce((accumObj, sharedKey) => {
    if (
      baseObj[sharedKey] !== undefined &&
      overRiddingObj[sharedKey] === undefined
    ) {
      const value = baseObj[sharedKey];
      accumObj[sharedKey] = value;
    } else if (
      baseObj[sharedKey] === undefined &&
      overRiddingObj[sharedKey] !== undefined
    ) {
      const value = overRiddingObj[sharedKey];
      accumObj[sharedKey] = value;
    } else {
      if (
        !isNullOrUndefined(overRiddingObj[sharedKey]) &&
        !isNullOrUndefined(baseObj[sharedKey]) &&
        typeof overRiddingObj[sharedKey] === "object" &&
        typeof baseObj[sharedKey] === "object" &&
        !Array.isArray(overRiddingObj[sharedKey]) &&
        !Array.isArray(baseObj[sharedKey])
      ) {
        accumObj[sharedKey] = mergeObjects(
          baseObj[sharedKey],
          overRiddingObj[sharedKey]
        );
      } else {
        accumObj[sharedKey] = overRiddingObj[sharedKey];
      }
    }
    return accumObj;
  }, {});
  return mergedObject;
};

export const isString = x => {
  return Object.prototype.toString.call(x) === "[object String]";
};

export const isInfiniteOrNAN = value => {
  if (!isFinite(value)) {
    return true;
  }
  if (Number.isNaN(value) || isNaN(value)) {
    return true;
  }
};
export const isNullOrUndefined = value => {
  const falsyArr = ["undefined"];
  if (value === null) {
    return true;
  }
  return falsyArr.includes(typeof value);
};

export const doesExistPositiveContextMatch = (
  options?: any,
  checkOptions?: any
): boolean => {
  if (!checkOptions || !options) {
    return true;
  }

  if (options && checkOptions) {
    for (const key of Object.keys(options)) {
      if (options[key] && checkOptions[key]) {
        return true;
      }
    }
  }

  return false;
};

function getFirstGroup(regexp, str) {
  const array = [...str.matchAll(regexp)];
  return array.map(m => m[1]);
}

export const rawStringFromPotentiallyDraftJS = (value: string): string => {
  if (value && typeof value === "string") {
    let regexexp = new RegExp(/"text":"(.*?)"/g);
    let results: RegExpMatchArray | null = getFirstGroup(regexexp, value);
    if (results && Array.isArray(results) && results.length > 0) {
      return results.join("");
    }
  }
  return value;
};

export const inEnum = (enumObject: any, val: any) => {
  if ((<any>Object).values(enumObject).includes(val)) {
    return true;
  }
  return false;
};
