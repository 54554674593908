import React from "react";
import "./SignupTenantInputWrapper.scss";

const SignupTenantInputWrapper = props => {
  const { children, error } = props;

  return (
    <>
      <div
        className="SignupTenantInputWrapper"
        data-testid="SignupTenantInputWrapper"
      >
        {children}
      </div>
      {error ? (
        <p className="SignupTenantInputWrapper__required-error">{error}</p>
      ) : (
        ""
      )}
    </>
  );
};

export default SignupTenantInputWrapper;
