import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";

import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import {
  COAssessmentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { defaultAssessmentTemplate } from "../co-assessment.template";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import {
  COSectionTemplateSlugs,
  CONonStandardSectionTemplateSlugs
} from "../../constants/co-section.constants";

export const defaultQ3AssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.CITIZEN_DEVELOPER_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.CITIZEN_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.CITIZEN_DEVELOPER_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.CITIZEN_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default Citizen Developer Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
    assessmentTemplate.co_options_json = {
      ...assessmentTemplate.co_options_json,
      condition_overrides: [
        { slug: COConditionSlugs.Q3_VISIBILITY },
        // This assessment is not optional during submission, but is optional with a warning when saving
        {
          slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE
        },
        { slug: COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING },
        {
          slug: COConditionSlugs.HIDE_ASSESSMENT_DURING_CHANGE_REQUEST
        },
        {
          slug:
            COConditionSlugs.SHOW_DURING_CR_BASED_ON_Q3_ASSESSMENT_VISIBILITY_QUESTION
        },
        ...templateAssessmentConditions()
      ]
    };
  }
  return assessmentTemplate;
};
export const AHDefaultQ3AssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateQ3() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-q3-0-0`;
  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHFreshQ3AssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateQ3(false) });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "",
      sectionTemplateSlug: COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1
    }),
    question_slugs: []
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Process details",
    sectionTemplateSlug: COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY,
      CustomQuestionSlugs.AUTOMATION_PROCESS_PEAKS,
      CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE,
      CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Pre and post automation",
    sectionTemplateSlug: COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION,
      CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION,
      CustomQuestionSlugs.AUTOMATION_AVERAGE_PROCESS_DURATION_DIFFERENCE,
      CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION,
      CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION,
      CustomQuestionSlugs.AUTOMATION_AVERAGE_ERROR_RATE_DIFFERENCE,
      CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION,
      CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION,
      CustomQuestionSlugs.AUTOMATION_AVERAGE_REWORK_TIME_DIFFERENCE,
      CustomQuestionSlugs.CTZN_DEV_LIVE_BENEFIT_EM_HRS_MO
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Documentation",
    note:
      "NOTE: The documents added to questions from this section will be displayed in the Documentation page of the automation profile.",
    sectionTemplateSlug: COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1,
    condition_overrides: [
      { slug: COConditionSlugs.HIDE_FOR_EDIT },
      { slug: COConditionSlugs.HIDE_FROM_PROFILE }
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.AUTOMATION_SOURCE_CODE,
      CustomQuestionSlugs.AUTOMATION_PACKAGE,
      CustomQuestionSlugs.AUTOMATION_USER_GUIDES_AND_DOCS
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Media",
    sectionTemplateSlug: COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1,
    condition_overrides: [
      { slug: COConditionSlugs.HIDE_FOR_EDIT },
      { slug: COConditionSlugs.HIDE_FROM_PROFILE }
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.MISC_MEDIA]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Applications",
    note:
      "The Applications Section is currently unavailable for customization.",
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_APPLICATIONS_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.APPLICATIONS]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Components",
    sectionTemplateSlug: COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1,
    condition_overrides: [
      { slug: COConditionSlugs.HIDE_FOR_EDIT },
      { slug: COConditionSlugs.HIDE_FROM_PROFILE }
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.AUTOMATION_COMPONENTS]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Potential benefit",
    note: "NOTE: This section can't be deleted.",
    sectionTemplateSlug:
      CONonStandardSectionTemplateSlugs.POTENTIAL_BENEFIT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE,
      CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY
    ]
  });
};

export const assessmentTemplateQ3 = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultQ3AssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Citizen Developer Assessment",
      description: ""
    });
    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New Citizen Developer Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};
