import { fetchAutomationsForChangeRequestParentSearch } from "../utils/apiUtils/changeRequestApiUtils";
import { COProcessSubmissionType } from "@uipath/ah-co";

export const searchParentAutomationsForChangeRequest = ({
  searchText,
  ignoreProcessIDs,
  ignoreSubmissionTypes,
  callback
}: {
  searchText: string;
  ignoreProcessIDs: number[];
  ignoreSubmissionTypes?: COProcessSubmissionType[];
  callback: (options: {
    label?: string;
    value?: number | string;
    valueObj?: any;
  }) => void;
}) => {
  fetchAutomationsForChangeRequestParentSearch({
    searchText: searchText,
    resultsCallback: results => {
      let options = results.reduce((accum, obj) => {
        accum = accum || [];
        if (
          ignoreProcessIDs.length > 0 &&
          ignoreProcessIDs.includes(obj.process_id)
        ) {
          return accum;
        }
        if (ignoreSubmissionTypes) {
          if (ignoreSubmissionTypes.includes(obj.process_submission_type)) {
            return accum;
          }
        }
        accum.push({
          label: obj.process_name,
          value: obj.process_id,
          valueObj: obj
        });
        return accum;
      }, []);
      callback(options);
    }
  });
};
