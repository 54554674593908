import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import ModalPipelineGeneric from "../ModalPipelineGeneric/ModalPipelineGeneric";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const DescriptionList = styled.ul`
  margin-top: 19px;
  display: flex;
  flex-direction: column;
`;
const DescriptionItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 17.58px;

  &:first-of-type {
    margin-top: 0px;
  }
`;
const CheckboxWithStyles = withStyles({
  root: {
    padding: 0
  }
})(Checkbox);
const DescriptionLabel = styled.label`
  margin-left: 13px;
`;

type ListItemType = [string, string];

interface Props {
  listItems: ListItemType[] | undefined;
}
const ModalPipelineGenericWithCheckboxList = ({ listItems }: Props) => {
  const { t } = useTranslation("common");

  return (
    <ModalPipelineGeneric>
      <DescriptionList>
        {listItems?.map(
          ([descriptionLocalizationKey, descriptionText], idx) => (
            <DescriptionItem key={`${descriptionText}-${idx}`}>
              <CheckboxWithStyles checked />
              <DescriptionLabel>
                {t(descriptionLocalizationKey, descriptionText)}
              </DescriptionLabel>
            </DescriptionItem>
          )
        )}
      </DescriptionList>
    </ModalPipelineGeneric>
  );
};

const mapStateWithProps = ({ modal }) => ({
  listItems: modal?.modalProps.listItemsDescription
});

export default connect(mapStateWithProps)(ModalPipelineGenericWithCheckboxList);
