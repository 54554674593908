import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { LocalizeTranslationKey } from "../../../utils/localizedAlertMessages";
import { sweetAlertLoader } from "../../../utils/sweetAlertLoader";
import Modal from "../Modal";
import {
  clearCurrentAutomation,
  setStatus
} from "../../../actions/automationPipelineActions";
import { hideModal } from "../../../actions/modalActions";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import ButtonMain from "../../buttons/ButtonMain/ButtonMain";
import { QUALIFICATION_APPROVED } from "../../../constants/automationStatusTypes";
import "./ModalApproveQualification.scss";
import {
  triggerAnalyticsWithDefaultData,
  APPROVE_ASSESSED_IDEA_SUCCESS_EVENT_NAME
} from "../../../utils/analyticsUtils";

class ModalApproveQualification extends Component {
  closeModal = () => {
    const { clearCurrentAutomation, hideModal } = this.props;

    clearCurrentAutomation();
    hideModal();
  };

  findIdForStatus = status => {
    const { tableAutomations } = this.props;
    const statuses = tableAutomations.automationStatuses;
    const statusObj = statuses.find(currStatus => {
      return currStatus.phase_status_key === status;
    });

    return statusObj.phase_status_id;
  };

  approveQualification = () => {
    const { setStatus, automationPipeline } = this.props;

    const { currentAutomation, allPhasesAndStatuses } = automationPipeline;

    const statusChangeId = allPhasesAndStatuses[QUALIFICATION_APPROVED];

    setStatus(
      {
        slug: currentAutomation.process_slug,
        statusChangeId
      },
      this.afterApproveQualification
    );
    sweetAlertLoader({
      text: (
        <LocalizeTranslationKey localeKey="component_actions_updating_text" />
      )
    });
  };

  afterApproveQualification = data => {
    triggerAnalyticsWithDefaultData(APPROVE_ASSESSED_IDEA_SUCCESS_EVENT_NAME);

    if (
      this.props.automationPipeline &&
      typeof this.props.automationPipeline.statusChangeCallback === "function"
    ) {
      this.props.automationPipeline.statusChangeCallback(data);
    }
  };

  render() {
    const modalContentStyle = {
      padding: "0px 24px 24px 24px",
      borderRadius: "8px",
      boxShadow: "0 8px 6px 0 rgba(0, 0, 0, 0.04)",
      width: "464px"
    };

    const headerStyle = {
      borderBottom: "0",
      paddingRight: "0px"
    };

    return (
      <Modal contentStyle={modalContentStyle} onClose={this.closeModal}>
        <ModalTemplateOne
          className="ModalApproveQualification__container"
          heading={this.props.t([
            "modal_approve_qualification_heading",
            "Approve Automation?"
          ])}
          headerStyle={headerStyle}
          onClose={this.closeModal}
        >
          <div
            className="ModalApproveQualification"
            data-testid="ModalApproveQualification"
          >
            <p className="ModalApproveQualification__text">
              {this.props.t([
                "modal_approve_qualification_content",
                "Are you sure you want to approve the automation? Once approved, you will need to assign a Project Manager and plan its implementation"
              ])}
            </p>
            <div className="ModalApproveQualification__buttons">
              <ButtonMain
                type="secondary"
                className="ModalApproveQualification__button ModalApproveQualification__cancel"
                clickHandler={this.closeModal}
              >
                {this.props.t(["modal_approve_qualification_cancel", "Cancel"])}
              </ButtonMain>
              <ButtonMain
                type="primary"
                className="ModalApproveQualification__button ModalApproveQualification__approve"
                clickHandler={this.approveQualification}
              >
                {this.props.t([
                  "modal_approve_qualification_approve",
                  "Approve"
                ])}
              </ButtonMain>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = ({ tableAutomations, automationPipeline }) => ({
  tableAutomations,
  automationPipeline
});

const mapDispatchToProps = {
  hideModal,
  clearCurrentAutomation,
  setStatus
};
export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ModalApproveQualification)
);
