import { isNullOrUndefined } from "../utils/co-utils";
import * as math from "../libraries/math";
import { isRunningInBrowser } from "../utils/co-utils";

math.config({
  number: "BigNumber", // Default type of number:
  // 'number' (default), 'BigNumber', or 'Fraction'
  precision: 64 // Number of significant digits for BigNumbers
});
const limitedEvaluate = math.evaluate;
const limitedParse = math.parse;

// server side disable to prevent library abuse
if (!isRunningInBrowser() || process.env.AH_ENV) {
  math.import(
    {
      import: function() {
        //throw new Error("Function import is disabled");
      },
      createUnit: function() {
        throw new Error("Function createUnit is disabled");
      },
      evaluate: function() {
        throw new Error("Function evaluate is disabled");
      },
      parse: function() {
        throw new Error("Function parse is disabled");
      },
      simplify: function() {
        throw new Error("Function simplify is disabled");
      },
      derivative: function() {
        throw new Error("Function derivative is disabled");
      }
    },
    { override: true }
  );
}

// interface mathInterface {
//   limitedParse: Function;
//   limitedEvaluate: Function;
//   simplify: Function;
//   format: Function;
// }
// const coMath = (): mathInterface => {
//   return math;
// };

export const coMathEvaluate = (equation): any => {
  if (isNullOrUndefined(equation) || equation === "") {
    return undefined;
  }

  let value = limitedEvaluate(equation);

  if (isNullOrUndefined(value)) {
    // console.log(`equation ${equation} results as ${value} `);
    return undefined;
  }

  value = math.format(value, { notation: "fixed" });

  if (Number.isNaN(value) || isNaN(value)) {
    return undefined;
  }

  // force value to string - we don't ever do math on it directly - we just pump it into equations (which are strings) or store it as a CO (as a string)
  return `${value}`;
};

export const coMathValidateEquation = (equation): any => {
  const node = limitedParse(equation);
  node.traverse((node, path, parent) => {
    if (node.type === "OperatorNode") {
      if (node.op === "*" && node["implicit"]) {
        throw new Error("Invalid syntax: Implicit multiplication found");
      }
    }
  });
  return limitedEvaluate(equation);
};
