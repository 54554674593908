import {
  FETCH_MY_COMPONENTS,
  CLEAR_MY_COMPONENTS,
  PHASE_CHANGE_MY_COMPONENTS,
  DELETE_MY_COMPONENTS,
  SET_MY_COMPONENTS_UPLOAD_COUNT,
  SET_MY_COMPONENTS_DOWNLOAD_COUNT
} from "../constants/actionTypes";
import { MY_COMPONENT_DOWNLOADS } from "../constants/tableFields";

const INITIAL_STATE = {
  components: null,
  page: 1,
  pageSize: 10,
  totalPages: 0,
  uploadCount: null,
  downloadCount: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MY_COMPONENTS:
      const {
        components,
        page,
        pageSize,
        totalItems,
        totalPages,
        type
      } = action.payload;

      let downloadCount = state.downloadCount;
      let uploadCount = state.uploadCount;

      if (type === MY_COMPONENT_DOWNLOADS) {
        downloadCount = totalItems;
      } else {
        uploadCount = totalItems;
      }

      return {
        ...state,
        components,
        page,
        pageSize,
        totalItems,
        totalPages,
        downloadCount,
        uploadCount
      };
    case CLEAR_MY_COMPONENTS:
      return {
        ...state,
        components: null
      };
    case PHASE_CHANGE_MY_COMPONENTS:
      var newComponents = state.components.map(component => {
        if (component.component_id === action.payload.component_id) {
          component = action.payload;
        }
        return component;
      });

      return {
        ...state,
        components: newComponents
      };
    case DELETE_MY_COMPONENTS:
      newComponents = state.components.filter(
        component => component.component_slug !== action.payload.slug
      );

      return {
        ...state,
        components: newComponents
      };
    case SET_MY_COMPONENTS_UPLOAD_COUNT:
      return {
        ...state,
        uploadCount: action.payload
      };
    case SET_MY_COMPONENTS_DOWNLOAD_COUNT:
      return {
        ...state,
        downloadCount: action.payload
      };
    default:
      return state;
  }
}
