import {
  COErrorTypes,
  CO_TABULAR_AGGREGATION_OPERATORS
} from "../constants/co-constants";
import { coMathEvaluate } from "../libraries/co-math";
import { errorKeyFromPath } from "../utils/co-path.utils";
import {
  CO_AHID_REPLACE_REGEX,
  CO_AHID_REPLACE_REGEX_WITH_AGGREGATION,
  CO_AHID_RESOLVED_VALUE_INSERT_REGEX,
  CO_VARIABLE_RESOLVED_INERT_REGEX_CLEANUP_REGEX,
  CO_VARIABLE_RESOLVED_VALUE_INSERT_REGEX,
  CO_VAR_REPLACE_REGEX,
  parseRawEquation,
  validateEquationFormat,
  variablesInEquation
} from "./co-equation.helper";
import { toFloat } from "./co-validation.helper";
import { optionsInContext } from "./co-context.helper";
import { COUnitSlugs } from "../templates/elements/controls/co-units.template";
import { isInfiniteOrNAN, isNullOrUndefined } from "../utils/co-utils";
import {
  COAssessmentInterface,
  COContextInterface,
  COQuestionAnswerOptionInterface,
  COQuestionInterface,
  COValidationError
} from "../interfaces/co-interfaces";
import { COEquationFunctions } from "../constants/co-calculation.constants";
import { QUESTION_EQUATION_VALIDATE_TARGET } from "../constants/co-equation.constants";
import { COBase } from "../classes/co-base.class";
import { updateContext } from "../classes/co-context.class";

// finds all questions referenced by a questions equation and recursively checks for references from prior question equations
export const validateQuestionEquationDoesntReferenceQuestions = ({
  context,
  question,
  rawEquation,
  questionsToFind = []
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  rawEquation?: string;
  questionsToFind?: {
    question: COQuestionInterface;
    questionWithEquationWithQuestion: COQuestionInterface;
  }[];
}): COValidationError[] => {
  let assessment: COAssessmentInterface | undefined = context.assessment;
  let errors: COValidationError[] = [];
  let parsedRawEquation = parseRawEquation({
    context,
    question,
    rawEquation: rawEquation
  });
  if (
    parsedRawEquation.validation_errors &&
    parsedRawEquation.validation_errors.length > 0
  ) {
    errors = [...errors, ...parsedRawEquation.validation_errors];
    return errors;
  }

  let questionsBeingReferencedByQuestion: COQuestionInterface[] =
    parsedRawEquation.questionsInEquation;

  // we also need to add any answer options that reference questions because that's a thing
  for (const answerOption of question.co_question_answer_options || []) {
    if (
      answerOption.calculation_options?.answer_value_is_question_ahid &&
      answerOption.co_question_answer_option_meta_json?.answer_value?.value
    ) {
      let questionReferencedByAnswerOption = assessment?.findAHId?.(
        answerOption.co_question_answer_option_meta_json.answer_value.value
      );
      if (questionReferencedByAnswerOption) {
        questionsBeingReferencedByQuestion.push(
          questionReferencedByAnswerOption
        );
      }
    }
  }

  for (const questionInEquation of questionsBeingReferencedByQuestion) {
    // make sure question doesn't reference variable of the same question
    if (questionInEquation.co_question_ahid === question.co_question_ahid) {
      let validationError: COValidationError = {
        error_message: `Variable circular reference found in question - ${question.co_question_meta_json?.title?.value} `,
        error_key: errorKeyFromPath(context, QUESTION_EQUATION_VALIDATE_TARGET),
        error_type: COErrorTypes.IMPACTS_CALCULATION,
        problem_object: question,
        problem_property: question.co_variable_name
      };
      errors.push(validationError);
      return errors;
    }
    for (const qtf of questionsToFind) {
      if (
        questionsToFind.find(
          qtf =>
            qtf.question.co_question_ahid ===
            questionInEquation.co_question_ahid
        )
      ) {
        let validationError: COValidationError = {
          error_message: `Variable circular reference found - ${qtf.question.co_variable_name} is referenced in the equation for ${question.co_variable_name} and ${qtf.questionWithEquationWithQuestion.co_variable_name}`,
          error_type: COErrorTypes.IMPACTS_CALCULATION,
          error_key: errorKeyFromPath(
            context,
            QUESTION_EQUATION_VALIDATE_TARGET
          ),
          problem_object: question,
          problem_property: question.co_equation
        };
        errors.push(validationError);
        return errors;
      }
    }

    // check each question in this questions equation to make sure none of the previous questions are referenced
    if (
      !questionsToFind.find(
        q => q.question.co_question_ahid === questionInEquation.co_question_ahid
      )
    ) {
      let recursiveErrors = validateQuestionEquationDoesntReferenceQuestions({
        context,
        question: questionInEquation,
        questionsToFind: [
          {
            question: questionInEquation,
            questionWithEquationWithQuestion: question
          },
          ...questionsToFind
        ]
      });
      if (recursiveErrors && recursiveErrors.length > 0) {
        errors = [...errors, ...recursiveErrors];
        return errors;
      }
    }
  }

  return errors;
};

// make sure all answers are in
export const calculate = ({
  context,
  question
}: {
  context: COContextInterface;
  question: COQuestionInterface;
}): { value: any; defaultValueUsed?: boolean; errors: COValidationError[] } => {
  let equationCalculationErrors: COValidationError[] = [];
  let defaultValue = question.defaultValueInContext?.(context);

  // we want to see if we specifically set this to 0 and if so then we just return the answer selections
  let questionOptionsInContext = optionsInContext({
    context,
    options: question.options || {},
    should_resolve: true
  });
  let can_impact_kpi = questionOptionsInContext.can_impact_kpi;

  if (!isNullOrUndefined(can_impact_kpi) && !can_impact_kpi) {
    // then we we can just skip out
    // console.log(
    //   `calculations can't impact KPI ${question.objectDisplayName?.()}`
    // );
    if (question.co_process_answer?.co_process_answer_selections) {
      let rawValue = question.co_process_answer?.co_process_answer_selections
        .map(selection => selection.co_question_answer_option_value)
        .join(",");
      return {
        value: rawValue,
        defaultValueUsed:
          question.co_process_answer.co_process_answer_selections.length === 0,
        errors: []
      };
    }
  }

  // if (
  //   question.options?.external_answer_source === COExternalAnswerSources.STATS
  // ) {
  //   console.log(question);
  // }

  // first check to see if this equation isn't broken
  let equationValidationErrors: COValidationError[] = validateEquationFormat({
    context,
    question,
    equation: question.co_equation || ""
  });

  if (equationCalculationErrors.length > 0) {
    console.log(`got equation validation errors`);
    equationCalculationErrors = [
      ...equationCalculationErrors,
      ...equationValidationErrors
    ];
  }

  let circularReferenceErrors: COValidationError[] = validateQuestionEquationDoesntReferenceQuestions(
    { context, question, rawEquation: question.co_equation }
  );
  if (circularReferenceErrors.length > 0) {
    console.log(`got equation circular reference errors - can't calculate`); // we need to kick out of this one
    return {
      value: defaultValue,
      errors: [...circularReferenceErrors, ...equationCalculationErrors]
    };
  }

  // parse equation into dependent variables
  let parsedEquation = parseRawEquation({ context, question });
  if (
    parsedEquation.validation_errors &&
    parsedEquation.validation_errors.length > 0
  ) {
    return {
      value: defaultValue,
      defaultValueUsed: true,
      errors: [
        ...parsedEquation.validation_errors,
        ...equationCalculationErrors
      ]
    };
  }
  let rawEquation = parsedEquation.rawEquation;
  let originalEquation = parsedEquation.rawEquation;

  // loop through and recurse calculate each variable in the equation
  let question_table_aggregates: { [key: string]: COQuestionInterface[] } = {};
  let question_table_aggregate_values: { [key: string]: any[] } = {};

  for (const questionInEquation of parsedEquation.questionsInEquation) {
    let { value, errors } = calculate({
      context,
      question: questionInEquation
    });

    if (Array.isArray(errors)) {
      equationCalculationErrors = [...equationCalculationErrors, ...errors];
    }

    //make sure not NAN or undefined or not a number
    // or it could be null (no answer) - in which case??? entire equation is null?
    if (
      !isNullOrUndefined(value) &&
      value !== "" &&
      !isNaN(value) &&
      !Array.isArray(value) &&
      questionInEquation.co_question_ahid
    ) {
      const list_has_additional_question_of_same_ahid_for_tabular_aggregation = parsedEquation.questionsInEquation.find(
        q => {
          return (
            q.co_question_ahid === questionInEquation.co_question_ahid &&
            q.co_question_ahid &&
            q.co_table_row_index !== questionInEquation.co_table_row_index &&
            !(question_table_aggregates[q.co_question_ahid] || []).includes(q)
          );
        }
      );

      // if there are multiple questions in the list
      if (list_has_additional_question_of_same_ahid_for_tabular_aggregation) {
        question_table_aggregates[questionInEquation.co_question_ahid] = [
          ...(question_table_aggregates[questionInEquation.co_question_ahid] ||
            []),
          questionInEquation
        ];
        question_table_aggregate_values[questionInEquation.co_question_ahid] = [
          ...(question_table_aggregate_values[
            questionInEquation.co_question_ahid
          ] || []),
          value
        ];
      } else {
        // ok we have a value - and it's the last value we need to have calculated
        // so here we need to deal with aggegation - we might have multiple questions in this list with the same ahid and different table columnn indexes
        // we need to grab all of them - and do the calculating, then sum or whatever

        if (
          !question_table_aggregate_values[questionInEquation.co_question_ahid]
        ) {
          // standard replacement
          let regex = CO_AHID_REPLACE_REGEX(
            questionInEquation.co_question_ahid
          );
          rawEquation = rawEquation.replace(
            regex,
            CO_AHID_RESOLVED_VALUE_INSERT_REGEX(`${value}`)
          );
        } else {
          // add the final value as well the final question
          question_table_aggregate_values[
            questionInEquation.co_question_ahid
          ].push(value);

          question_table_aggregates[questionInEquation.co_question_ahid].push(
            questionInEquation
          );

          // ok we hardcode SUM for now - but I should be able to get this from the KPI or the equation it'self like VAR_1 - we'd do SUM_VAR_1 or VAR_1[SUM]
          let sumAggregation = 0;

          let firstValue: number =
            question_table_aggregate_values[
              questionInEquation.co_question_ahid
            ][0];

          let minAggregation: number | null = null;
          let maxAggregation: number | null = null;

          for (const val of question_table_aggregate_values[
            questionInEquation.co_question_ahid
          ]) {
            // incase it's not sorted
            if (parseInt(questionInEquation.co_table_row_index + "") == 0) {
              firstValue = val;
            }
            const floatVal = parseFloat(val + ""); // need it to be numerical
            sumAggregation += floatVal;
            if (minAggregation === null || floatVal < minAggregation) {
              minAggregation = floatVal;
            }
            if (maxAggregation === null || floatVal > maxAggregation) {
              maxAggregation = floatVal;
            }
          }

          /// replace in the possible aggregations
          rawEquation = rawEquation.replace(
            CO_AHID_REPLACE_REGEX_WITH_AGGREGATION(
              questionInEquation.co_question_ahid,
              CO_TABULAR_AGGREGATION_OPERATORS.SUM
            ),
            CO_AHID_RESOLVED_VALUE_INSERT_REGEX(`${sumAggregation}`)
          );
          /// replace in the possible aggregations
          rawEquation = rawEquation.replace(
            CO_AHID_REPLACE_REGEX_WITH_AGGREGATION(
              questionInEquation.co_question_ahid,
              CO_TABULAR_AGGREGATION_OPERATORS.MIN
            ),
            CO_AHID_RESOLVED_VALUE_INSERT_REGEX(`${minAggregation}`)
          );
          /// replace in the possible aggregations
          rawEquation = rawEquation.replace(
            CO_AHID_REPLACE_REGEX_WITH_AGGREGATION(
              questionInEquation.co_question_ahid,
              CO_TABULAR_AGGREGATION_OPERATORS.MAX
            ),
            CO_AHID_RESOLVED_VALUE_INSERT_REGEX(`${maxAggregation}`)
          );

          // finally replace in with the "first row" as the default
          // standard replacement
          let regex = CO_AHID_REPLACE_REGEX(
            questionInEquation.co_question_ahid
          );
          rawEquation = rawEquation.replace(
            regex,
            CO_AHID_RESOLVED_VALUE_INSERT_REGEX(`${firstValue}`)
          );
        }
      }
    } else {
      equationCalculationErrors.push({
        error_message: `Undefined Value ${value} in Equation ${originalEquation} when calculating ${questionInEquation.co_variable_name}`,
        error_type: COErrorTypes.IMPACTS_CALCULATION,
        problem_object: questionInEquation,
        problem_property: questionInEquation.co_variable_name
      });
    }
  }

  // handle remaining variables - these will be the Built in Function Variables
  let variablesLeftInEquation = variablesInEquation({
    equation: rawEquation,
    includeFunctions: true
  });

  let defaultValueUsed = false;

  for (const functionVariable of variablesLeftInEquation) {
    let { value, isDefaultAnswer } = valueForBuiltInVariableFunction({
      context,
      question,
      functionVariable
    });

    if (isDefaultAnswer) {
      defaultValueUsed = isDefaultAnswer;
    }

    if (Array.isArray(value)) {
      equationCalculationErrors = [...equationCalculationErrors, ...value];
    } else {
      if (!isNullOrUndefined(value)) {
        let regex = CO_VAR_REPLACE_REGEX(functionVariable);
        rawEquation = rawEquation
          .replace(regex, CO_VARIABLE_RESOLVED_VALUE_INSERT_REGEX(`${value}`))
          .replace(CO_VARIABLE_RESOLVED_INERT_REGEX_CLEANUP_REGEX(), "");
      } else {
        equationCalculationErrors.push({
          error_message: `Undefined Answer for question ${functionVariable} in ${question.co_variable_name}`,
          error_type: COErrorTypes.IMPACTS_CALCULATION,
          problem_object: question,
          problem_property: question.co_equation
        });
      }
    }
  }

  if (equationCalculationErrors.length > 0) {
    return {
      value: defaultValue,
      defaultValueUsed: true,
      errors: [...equationCalculationErrors]
    };
  }

  try {
    let result = coMathEvaluate(rawEquation);

    if (isNullOrUndefined(result) || isInfiniteOrNAN(result)) {
      result = defaultValue; // return default value if inf or NAN
      defaultValueUsed = true;
    }

    return {
      value: result,
      errors: [...equationCalculationErrors],
      defaultValueUsed
    };
  } catch (error) {
    return {
      value: defaultValue,
      defaultValueUsed: true,
      errors: [...equationCalculationErrors]
    };
  }
};

export const modifyAnswerInputForCalculationModifiers = ({
  context,
  question,
  value
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  value: any;
}) => {
  let finalValue = value;
  if (
    !isNullOrUndefined(value) &&
    context.calculation_modifiers &&
    context.calculation_modifiers.currency_division_factor
  ) {
    let questionOptionsInContext = optionsInContext({
      context,
      options: question.options || {},
      should_resolve: true
    });
    if (
      questionOptionsInContext.unit &&
      typeof questionOptionsInContext.unit !== "string"
    ) {
      // don't care if it's 0 or ""
      if (
        questionOptionsInContext.unit.slug === COUnitSlugs.CURRENCY &&
        finalValue
      ) {
        let conversionFactor =
          context.calculation_modifiers.currency_division_factor;

        finalValue =
          toFloat(finalValue + "") /
          (conversionFactor !== 0 ? conversionFactor : 1);

        // its NAN
        if (finalValue !== finalValue) {
          finalValue = value;
        }
      }
    }
  }
  return finalValue;
};

const valueForBuiltInVariableFunction = ({
  context,
  question,
  functionVariable
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  functionVariable: string;
}): {
  value: string | number | undefined | COValidationError[];
  isDefaultAnswer: boolean;
} => {
  let selectedAnswers = questionAnswerValues(context, question);
  let defaultAnswer = question.defaultValueInContext?.(context);

  // first the ones that don't need selected answers
  // these are WAAAY too specific for here - but may be too late - we really need to refactor this
  if (functionVariable === COEquationFunctions.COUNT_APPLICATIONS) {
    let applications =
      context.process_external_data?.application_data?.applications;
    if (applications) {
      return { value: applications.length, isDefaultAnswer: false };
    }
    return { value: defaultAnswer, isDefaultAnswer: true };
  }

  if (functionVariable === COEquationFunctions.COUNT_THIN_APPLICATIONS) {
    let count = 0;
    let applications =
      context.process_external_data?.application_data?.applications;
    if (applications) {
      for (const app of applications) {
        if (!!app.application_is_citrix_client) {
          count++;
        }
      }
    }
    return { value: count, isDefaultAnswer: false };
  }

  if (functionVariable === COEquationFunctions.REF_CALC_VAL) {
    let value = context.process_external_data?.functionReferencedCalculatedValue?.(
      updateContext(context, { question })
    );
    if (value == "" || isNullOrUndefined(value)) {
      // empty  string - don't save answer
      // we might want to take the default answer here from the question?
      return { value: defaultAnswer, isDefaultAnswer: true };
    }
    // we need to force this to a number for use in other calculations
    return { value: parseFloat(value + ""), isDefaultAnswer: false };
  }

  if (
    !selectedAnswers ||
    selectedAnswers.length === 0 ||
    isNullOrUndefined(selectedAnswers[0])
  ) {
    return { value: defaultAnswer, isDefaultAnswer: true };
  }

  let isDefaultAnswer = false;

  if (functionVariable === COEquationFunctions.PLAIN_TEXT) {
    let value = selectedAnswers[0];
    if (value == "") {
      // empty  string - don't save answer
      isDefaultAnswer = true;
    }
    return { value, isDefaultAnswer };
  }
  if (functionVariable === COEquationFunctions.EXTERNAL_ANSWER) {
    let value = selectedAnswers[0];
    if (value == "") {
      // empty  string - don't save answer
      isDefaultAnswer = true;
    }
    return { value, isDefaultAnswer };
  }

  // so we need to "resolve any of the answers that have the calculation being dependent on previous questions"

  if (functionVariable === COEquationFunctions.FIRST_ANSWER) {
    return { value: toFloat(selectedAnswers[0]), isDefaultAnswer };
  }

  if (functionVariable === COEquationFunctions.SUM_OF_ANSWERS) {
    const sum = getSum(selectedAnswers);
    return { value: sum, isDefaultAnswer };
  }

  if (functionVariable === COEquationFunctions.AVERAGE_OF_ANSWERS) {
    let average = 0;
    let averagefound = false;
    if (selectedAnswers.length > 0) {
      averagefound = true;
      const sum = getSum(selectedAnswers);
      average = sum / toFloat(selectedAnswers.length);
    }
    return {
      value: averagefound ? average : defaultAnswer,
      isDefaultAnswer: averagefound ? false : true
    };
  }

  if (functionVariable === COEquationFunctions.MAX_OF_ANSWERS) {
    let max = 0;
    let maxfound = false;
    for (const answer of selectedAnswers) {
      if (!isNullOrUndefined(answer)) {
        let answerNum = toFloat(answer);
        if (answerNum >= max) {
          maxfound = true;
          max = answerNum;
        }
      }
    }
    return {
      value: maxfound ? max : defaultAnswer,
      isDefaultAnswer: maxfound ? false : true
    };
  }

  if (functionVariable === COEquationFunctions.MIN_OF_ANSWERS) {
    let min = 10000000;
    let minFound = false;
    for (const answer of selectedAnswers) {
      if (!isNullOrUndefined(answer)) {
        let answerNum = toFloat(answer);
        if (answerNum < min) {
          minFound = true;
          min = answerNum;
        }
      }
    }
    return {
      value: minFound ? min : defaultAnswer,
      isDefaultAnswer: minFound ? false : true
    };
  }

  if (functionVariable === COEquationFunctions.COUNT_OF_ANSWERS) {
    let count = 0;
    for (const answer of selectedAnswers) {
      if (!isNullOrUndefined(answer)) {
        count++;
      }
    }
    return { value: count, isDefaultAnswer: false };
  }

  return defaultAnswer;
};

export const questionAnswerValues = (
  context: COContextInterface,
  question: COQuestionInterface
): any[] => {
  // ok we basically just get the selected answer value from the process answer selections BUT we have to deal with the case where the value references the output of a different question
  let valuesArray: any[] = [];
  for (const process_answer_selection of question.co_process_answer
    ?.co_process_answer_selections || []) {
    let value = process_answer_selection.co_question_answer_option_value;
    if (question.calculation_options?.input_is_value) {
      value = process_answer_selection.co_process_answer_selection_input;
    }
    if (value && COBase.isAHID(value)) {
      if (process_answer_selection.co_question_answer_option) {
        let selectedOption: COQuestionAnswerOptionInterface | undefined =
          process_answer_selection.co_question_answer_option;
        if (
          selectedOption &&
          selectedOption.calculation_options?.answer_value_is_question_ahid
        ) {
          let assessment: COAssessmentInterface | undefined =
            context.assessment;
          let questionReferenced = assessment?.findAHId?.(value);
          if (questionReferenced) {
            //should somehow bubble the error
            let { value: calculatedValue } = calculate({
              context,
              question: questionReferenced
            });
            value = calculatedValue;
          }
        }
      }
    }
    valuesArray.push(value);
  }
  return valuesArray;
};

const getSum = answers => {
  let sum = 0;
  for (const answer of answers) {
    if (!isNullOrUndefined(answer)) {
      sum = sum + toFloat(answer);
    }
  }
  return sum;
};
