import {
  LegacyQuestionInterface,
  LegacyQuestionResponseChoice
} from "../interfaces/co-legacy.interfaces";
import { CustomQuestionSlugs } from "./co-question.constants";

export const CO_SECTION_CUSTOM_QUESTIONS_LEGACY_SLUG =
  "CO_SECTION_CUSTOM_QUESTIONS_LEGACY_SLUG";

export const LegacyQuestionSlugs = {
  AUTOMATION_GOAL: "automation_goal",
  TASK_STABILITY: "task_stability",
  APPLICATION_STABILITY: "application_stability",
  EMPLOYEE_AVERAGE_WORKING_DAYS: "employee_average_working_days",
  EMPLOYEE_WORKING_HOURS: "employee_working_hours",
  EMPLOYEE_AVERAGE_FULL_COST: "employee_average_full_cost",
  FREQUENCY: "frequency",
  VOLUMES: "volumes",
  VOLUMES_OPTION: "volumes_option",
  NUMBER_OF_EMPLOYEES: "number_of_employees",
  AVERAGE_HANDLING_TIME: "average_handling_time",
  AVERAGE_ERROR_RATE: "average_error_rate",
  AVERAGE_REWORK_TIME: "average_rework_time",
  AVERAGE_WORK_TO_BE_REVIEWED: "average_work_to_be_reviewed",
  AVERAGE_REVIEW_AUDIT_TIME: "average_review_audit_time",
  PROCESS_PEAKS: "process_peaks",
  PROCESS_LENGTH: "process_length",
  DECISION_COMPLEXITY: "decision_complexity",
  DIGITAL_INPUTS_PERCENT: "digital_inputs_percent",
  SCANNED_INPUTS: "scanned_inputs",
  STRUCTURED_INPUT: "structured_input",

  //OVR
  PROCESS_NAME: "process_name",
  PROCESS_DESCRIPTION: "process_description",
  CATEGORIES: "hierarchy",

  //Q1
  RULES: "rules",
  INPUT_TYPE: "input_type",
  INPUT_QUALITY: "input_quality",
  STABILITY: "stability",
  DOCUMENTATION: "documentation",
  OWNER: "owner",

  //Q3 - some of these are actually questions others are process columns - need to figure it out
  CD_PROCESS_FREQUENCY: "cd_process_frequency",
  CD_PROCESS_PEAKS: "cd_process_peaks",
  CD_VOLUME_AVERAGE: "cd_volume_average",
  CD_POTENTIAL_BENEFIT: "cd_potential_benefit", //OMG THIS IS THE NUMBER OF PEOPLE WHO WOULD BENEFIT QUESTION!!!
  CD_AVERAGE_PROCESS_DURATION_PRE_AUTOMATION:
    "cd_average_process_duration_pre_automation",
  CD_AVERAGE_PROCESS_DURATION_POST_AUTOMATION:
    "cd_average_process_duration_post_automation",
  CD_AVERAGE_ERROR_RATE_PRE_AUTOMATION: "cd_average_error_rate_pre_automation",
  CD_AVERAGE_ERROR_RATE_POST_AUTOMATION:
    "cd_average_error_rate_post_automation",
  CD_AVERAGE_REWORK_TIME_PRE_AUTOMATION:
    "cd_average_rework_time_pre_automation",
  CD_AVERAGE_REWORK_TIME_POST_AUTOMATION:
    "cd_average_rework_time_post_automation",
  DA_AVERAGE_PROCESS_DURATION_POST_AUTOMATION:
    "cd_average_process_duration_post_automation",
  DA_AVERAGE_ERROR_RATE_POST_AUTOMATION:
    "cd_average_error_rate_post_automation",
  DA_AVERAGE_REWORK_TIME_POST_AUTOMATION:
    "cd_average_rework_time_post_automation"
};

// just incase some old legacy question is null
export const LegacySlugForLegacyQuestion = (
  legacyQuestion: LegacyQuestionInterface
) => {
  if (legacyQuestion.question_is_default) {
    if (legacyQuestion.process_identifier) {
      return legacyQuestion.process_identifier;
    }
  }
  return `legacy-question-id-${legacyQuestion.question_id}`;
};

export const LegacySlugForLegacyQuestionAnswer = (
  legacyQuestion: LegacyQuestionInterface,
  legacyQuestionAnswerOption: LegacyQuestionResponseChoice
) => {
  if (legacyQuestion.question_is_default) {
    if (legacyQuestionAnswerOption.response_choice_slug) {
      return legacyQuestionAnswerOption.response_choice_slug;
    }
  }
  // used for custom questions
  return `legacy-question-response-id-${legacyQuestionAnswerOption.response_choice_id}`;
};

// this maps our custom question slugs to the slugs used in the legacy question system for the open api backwards compatibility,
// this is unrelated to process columns
export const LegacyToCustomSlugMapping = () => {
  return {
    // OVERVIEW

    [LegacyQuestionSlugs.PROCESS_NAME]: CustomQuestionSlugs.OVERVIEW_NAME,
    [LegacyQuestionSlugs.PROCESS_DESCRIPTION]:
      CustomQuestionSlugs.OVERVIEW_DESCRIPTION,
    [LegacyQuestionSlugs.CATEGORIES]: CustomQuestionSlugs.OVERVIEW_CATEGORY,

    // Q2
    [LegacyQuestionSlugs.AUTOMATION_GOAL]:
      CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE,
    [LegacyQuestionSlugs.TASK_STABILITY]:
      CustomQuestionSlugs.PROCESS_CHANGE_EXPECTED,
    [LegacyQuestionSlugs.APPLICATION_STABILITY]:
      CustomQuestionSlugs.APPLICATION_CHANGE_EXPECTED,
    [LegacyQuestionSlugs.EMPLOYEE_AVERAGE_WORKING_DAYS]:
      CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR,
    [LegacyQuestionSlugs.EMPLOYEE_WORKING_HOURS]:
      CustomQuestionSlugs.WORKING_HOURS_PER_DAY,
    [LegacyQuestionSlugs.EMPLOYEE_AVERAGE_FULL_COST]:
      CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR,
    [LegacyQuestionSlugs.FREQUENCY]: CustomQuestionSlugs.TASK_PROCESS_FREQUENCY,
    [LegacyQuestionSlugs.VOLUMES]: CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE,
    [LegacyQuestionSlugs.VOLUMES_OPTION]:
      CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE_FREQUENCY,
    [LegacyQuestionSlugs.NUMBER_OF_EMPLOYEES]:
      CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK,
    [LegacyQuestionSlugs.AVERAGE_HANDLING_TIME]:
      CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION,
    [LegacyQuestionSlugs.AVERAGE_ERROR_RATE]:
      CustomQuestionSlugs.AVG_ERROR_RATE,
    [LegacyQuestionSlugs.AVERAGE_REWORK_TIME]:
      CustomQuestionSlugs.AVG_REWORK_TIME,
    [LegacyQuestionSlugs.AVERAGE_WORK_TO_BE_REVIEWED]:
      CustomQuestionSlugs.AVG_WORK_REVIEWED,
    [LegacyQuestionSlugs.AVERAGE_REVIEW_AUDIT_TIME]:
      CustomQuestionSlugs.AVG_REV_TIME,
    [LegacyQuestionSlugs.PROCESS_PEAKS]: CustomQuestionSlugs.PROCESS_PEAKS,
    [LegacyQuestionSlugs.PROCESS_LENGTH]:
      CustomQuestionSlugs.AVERAGE_NUMBER_OF_STEPS,

    [LegacyQuestionSlugs.DECISION_COMPLEXITY]:
      CustomQuestionSlugs.NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS,
    [LegacyQuestionSlugs.DIGITAL_INPUTS_PERCENT]:
      CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT,
    [LegacyQuestionSlugs.SCANNED_INPUTS]:
      CustomQuestionSlugs.SCANNED_DATA_INPUT,
    [LegacyQuestionSlugs.STRUCTURED_INPUT]:
      CustomQuestionSlugs.PCT_STRUCTURED_DIGITAL_DATA_INPUT,

    // Q1

    [LegacyQuestionSlugs.RULES]:
      CustomQuestionSlugs.Q1_DECISIONS_STRAIGHTFORWARD_RULES,
    [LegacyQuestionSlugs.INPUT_TYPE]:
      CustomQuestionSlugs.Q1_INPUT_DATA_ACCESSED_DIGITALLY,
    [LegacyQuestionSlugs.INPUT_QUALITY]:
      CustomQuestionSlugs.Q1_DATA_HIGHLY_FORMATTED,
    [LegacyQuestionSlugs.STABILITY]: CustomQuestionSlugs.Q1_NO_EXPECTED_CHANGES,
    [LegacyQuestionSlugs.DOCUMENTATION]:
      CustomQuestionSlugs.Q1_PROCESS_DOCUMENTS,

    // Citizen Developer

    [LegacyQuestionSlugs.CD_PROCESS_FREQUENCY]:
      CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY,
    [LegacyQuestionSlugs.CD_PROCESS_PEAKS]:
      CustomQuestionSlugs.AUTOMATION_PROCESS_PEAKS,
    [LegacyQuestionSlugs.CD_VOLUME_AVERAGE]:
      CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE,

    [LegacyQuestionSlugs.CD_POTENTIAL_BENEFIT]:
      CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT,

    // // so this is kinda busted because we have duplicate keys

    [LegacyQuestionSlugs.CD_AVERAGE_REWORK_TIME_PRE_AUTOMATION]:
      CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION,

    // [LegacyQuestionSlugs.CD_AVERAGE_REWORK_TIME_POST_AUTOMATION]:
    //   CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION,

    [LegacyQuestionSlugs.CD_AVERAGE_PROCESS_DURATION_PRE_AUTOMATION]:
      CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION,

    // [LegacyQuestionSlugs.CD_AVERAGE_PROCESS_DURATION_POST_AUTOMATION]:
    //   CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION,

    [LegacyQuestionSlugs.CD_AVERAGE_ERROR_RATE_PRE_AUTOMATION]:
      CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION,

    // [LegacyQuestionSlugs.CD_AVERAGE_ERROR_RATE_POST_AUTOMATION]:
    //   CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION,

    [LegacyQuestionSlugs.DA_AVERAGE_PROCESS_DURATION_POST_AUTOMATION]:
      CustomQuestionSlugs.DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION,

    [LegacyQuestionSlugs.DA_AVERAGE_REWORK_TIME_POST_AUTOMATION]:
      CustomQuestionSlugs.DA_AVG_REWORK_TIME_AFTER_AUTOMATION,

    [LegacyQuestionSlugs.DA_AVERAGE_ERROR_RATE_POST_AUTOMATION]:
      CustomQuestionSlugs.DA_AVG_ERROR_RATE_AFTER_AUTOMATION
  };
};

// the Q2 and Q3 share keys for the post automation result section, the LegacyToCustomSlugMapping is a dictionary that we loop through the keys of - this wipes out any diplicates
export const LegacyToCustomSlugMappingSharedKeys = {
  [LegacyQuestionSlugs.CD_AVERAGE_PROCESS_DURATION_POST_AUTOMATION]:
    CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION,

  [LegacyQuestionSlugs.CD_AVERAGE_REWORK_TIME_POST_AUTOMATION]:
    CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION,

  [LegacyQuestionSlugs.CD_AVERAGE_ERROR_RATE_POST_AUTOMATION]:
    CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION
};

export const LegacySlugForCOQuestion = (
  standard_question_template_slug: string
): string => {
  let legacySlug = "";

  let newToLegacy = {};
  for (const k of Object.keys(LegacyToCustomSlugMapping())) {
    newToLegacy[LegacyToCustomSlugMapping()[k]] = k;
  }
  // add the dupes back in - assumes that the values are unique not the keys
  for (const k of Object.keys(LegacyToCustomSlugMappingSharedKeys)) {
    newToLegacy[LegacyToCustomSlugMappingSharedKeys[k]] = k;
  }

  // add in properties that are shared between assessments and can't be represented in KV form
  // I'd love to have this be an array if there are more than one association - but we use it other places
  // after this release we should be able to delete all legacy migration nonsense
  if (newToLegacy[standard_question_template_slug]) {
    return newToLegacy[standard_question_template_slug];
  }

  return legacySlug;
};

export const LegacyQuestionAnswerSlugs = {
  AUTOMATION_GOAL_COST: "automation-goal-cost",
  AUTOMATION_GOAL_QUALITY: "automation-goal-quality",
  AUTOMATION_GOAL_PRODUCTIVITY: "automation-goal-productivity",
  TASK_STABILITY_NO_CHANGE_EXPECTED: "task-stability-no-change-expected",
  TASK_STABILITY_MINOR_CHANGE_EXPECTED: "task-stability-minor-change-expected",
  TASK_STABILITY_SOME_CHANGE_EXPECTED: "task-stability-some-change-expected",
  TASK_STABILITY_SEVERAL_CHANGES_EXPECTED:
    "task-stability-several-changes-expected",
  TASK_STABILITY_MAJOR_CHANGE_EXPECTED: "task-stability-major-change-expected",
  APPLICATION_STABILITY_NO_CHANGE_EXPECTED:
    "application-stability-no-change-expected",
  APPLICATION_STABILITY_MINOR_CHANGE_EXPECTED:
    "application-stability-minor-change-expected",
  APPLICATION_STABILITY_SOME_CHANGE_EXPECTED:
    "application-stability-some-change-expected",
  APPLICATION_STABILITY_SEVERAL_CHANGES_EXPECTED:
    "application-stability-several-changes-expected",
  APPLICATION_STABILITY_MAJOR_CHANGE_EXPECTED:
    "application-stability-major-change-expected",
  FREQUENCY_DAILY: "frequency-daily",
  FREQUENCY_WEEKLY: "frequency-weekly",
  FREQUENCY_BI_WEEKLY: "frequency-bi-weekly",
  FREQUENCY_MONTHLY: "frequency-monthly",
  FREQUENCY_QUARTERLY: "frequency-quarterly",
  FREQUENCY_YEARLY: "frequency-yearly",
  VOLUMES_PER_COMPANY: "volumes-per-company",
  VOLUMES_PER_EMPLOYEE: "volumes-per-employee",
  PROCESS_PEAKS_REGULAR: "process-peaks-regular",
  PROCESS_PEAKS_RARE_PREDICTABLE: "process-peaks-rare-predictable",
  PROCESS_PEAKS_RARE_UNPREDICTABLE: "process-peaks-rare-unpredictable",
  PROCESS_PEAKS_NO_PEAKS: "process-peaks-no-peaks",
  PROCESS_LENGTH_LESS_TWENTY: "process-length-less-twenty",
  PROCESS_LENGTH_BETWEEN_TWENTY_FIFTY: "process-length-between-twenty-fifty",
  PROCESS_LENGTH_BETWEEN_FIFTY_HUNDRED: "process-length-between-fifty-hundred",
  PROCESS_LENGTH_BETWEEN_HUNDRED_HUNDREDFIFTY:
    "process-length-between-hundred-hundredfifty",
  PROCESS_LENGTH_MORE_THAN_HUNDREDFIFTY:
    "process-length-more-than-hundredfifty",
  DECISION_COMPLEXITY_SAME_WAY: "decision-complexity-same-way",
  DECISION_COMPLEXITY_BETWEEN_TWO_FIVE: "decision-complexity-between-two-five",
  DECISION_COMPLEXITY_BETWEEN_FIVE_EIGHT:
    "decision-complexity-between-five-eight",
  DECISION_COMPLEXITY_GREATER_EIGHT: "decision-complexity-greater-eight",
  SCANNED_INPUTS_YES: "scanned-inputs-yes",
  SCANNED_INPUTS_NO: "scanned-inputs-no",
  STRUCTURED_INPUT_GREATER_EIGHTY: "structured-input-greater-eighty",
  STRUCTURED_INPUT_BETWEEN_SIXTY_EIGHTY:
    "structured-input-between-sixty-eighty",
  STRUCTURED_INPUT_BETWEEN_FORTY_SIXTY: "structured-input-between-forty-sixty",
  STRUCTURED_INPUT_LESSER_FORTY: "structured-input-lesser-forty",

  Q1_RULES_STRONGLY_AGREE: "rules-strongly-agree",
  Q1_RULES_AGREE: "rules-agree",
  Q1_RULES_NEITHER_DISAGREE_OR_AGREE: "rules-neither-disagree-or-agree",
  Q1_RULES_DISAGREE: "rules-disagree",
  Q1_RULES_STRONGLY_DISAGREE: "rules-strongly-disagree",
  Q1_RULES_I_DONT_KNOW: "rules-i-dont-know",
  Q1_INPUT_TYPE_STRONGLY_AGREE: "input-type-strongly-agree",
  Q1_INPUT_TYPE_AGREE: "input-type-agree",
  Q1_INPUT_TYPE_NEITHER_DISAGREE_OR_AGREE:
    "input-type-neither-disagree-or-agree",
  Q1_INPUT_TYPE_DISAGREE: "input-type-disagree",
  Q1_INPUT_TYPE_STRONGLY_DISAGREE: "input-type-strongly-disagree",
  Q1_INPUT_TYPE_I_DONT_KNOW: "input-type-i-dont-know",
  Q1_INPUT_QUALITY_STRONGLY_AGREE: "input-quality-strongly-agree",
  Q1_INPUT_QUALITY_AGREE: "input-quality-agree",
  Q1_INPUT_QUALITY_NEITHER_DISAGREE_OR_AGREE:
    "input-quality-neither-disagree-or-agree",
  Q1_INPUT_QUALITY_DISAGREE: "input-quality-disagree",
  Q1_INPUT_QUALITY_STRONGLY_DISAGREE: "input-quality-strongly-disagree",
  Q1_INPUT_QUALITY_I_DONT_KNOW: "input-quality-i-dont-know",
  Q1_STABILITY_STRONGLY_AGREE: "stability-strongly-agree",
  Q1_STABILITY_AGREE: "stability-agree",
  Q1_STABILITY_NEITHER_DISAGREE_OR_AGREE: "stability-neither-disagree-or-agree",
  Q1_STABILITY_DISAGREE: "stability-disagree",
  Q1_STABILITY_STRONGLY_DISAGREE: "stability-strongly-disagree",
  Q1_STABILITY_I_DONT_KNOW: "stability-i-dont-know",

  Q1_DOCUMENTATION_DETAILED_WORK_INSTRUCTIONS:
    "documentation-detailed-work-instructions",
  Q1_DOCUMENTATION_STANDARD_OPERATING_PROCEDURE:
    "documentation-standard-operating-procedure",
  Q1_DOCUMENTATION_TASK_PROCESS_MAPS_FLOWCHARTS:
    "documentation-task-process-maps-flowcharts",
  Q1_DOCUMENTATION_INPUT_FILES: "documentation-input-files",
  Q1_DOCUMENTATION_NO_DOCUMENTATION: "documentation-no-documentation",
  Q1_DOCUMENTATION_OUTPUT_FILES: "documentation-output-files",
  Q1_DOCUMENTATION_MISC: "documentation-misc",
  Q1_DOCUMENTATION_I_DONT_KNOW: "documentation-i-dont-know",

  Q3_PROCESS_FREQUENCY_DAILY: "cd-process-frequency-daily",
  Q3_PROCESS_FREQUENCY_WEEKLY: "cd-process-frequency-weekly",
  Q3_PROCESS_FREQUENCY_BI_WEEKLY: "cd-process-frequency-bi-weekly",
  Q3_PROCESS_FREQUENCY_MONTHLY: "cd-process-frequency-monthly",
  Q3_PROCESS_FREQUENCY_QUATERLY: "cd-process-frequency-quarterly",
  Q3_PROCESS_FREQUENCY_YEARLY: "cd-process-frequency-yearly",
  Q3_PROCESS_PEAKS_REGULAR: "cd-process-peaks-regular",
  Q3_PROCESS_PEAKS_RARE_PREDICTABLE: "cd-process-peaks-rare-predictable",
  Q3_PROCESS_PEAKS_RARE_UNPREDICTABLE: "cd-process-peaks-rare-unpredictable",
  Q3_PROCESS_PEAKS_NO_PEAKS: "cd-process-peaks-no-peaks",

  OVR_DEVELOPMENT_TYPE_NONE: "development-type-none",
  OVR_DEVELOPMENT_TYPE_COE: "coe",
  OVR_DEVELOPMENT_TYPE_CITIZEN_DEV_PWER_USER: "cd-power-user",
  OVR_DEVELOPMENT_TYPE_CITIZEN_DEV_SELF_USER: "cd-self-user"
};
