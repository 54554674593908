import {
  FILE_UPLOAD_TOGGLE_OFF_SUCCESS,
  TASK_CAPTURE_TOGGLE_OFF_SUCCESS,
  COMPONENTS_TOGGLE_OFF_SUCCESS,
  LEADERBOARD_TOGGLE_OFF_SUCCESS,
  COMMENT_QUESTION_SECTION_TOGGLE_OFF_SUCCESS
} from "../utils/analyticsUtils";

export const TENANT_SETTINGS_SESSION_KEY = "tenant_settings";
export const TENANT_SETTINGS_FILE_UPLOAD_DISPLAY = "file-upload-display";
export const TENANT_SETTINGS_COMPONENTS_DISPLAY = "components-display";
export const TENANT_SETTINGS_TASK_CAPTURE_DISPLAY = "task-capture-display";
export const TENANT_SETTINGS_LEADERBOARD_DISPLAY = "leaderboard-display";
export const TENANT_SETTINGS_DATE_FORMAT = "date-format";
export const TENANT_SETTINGS_EXECUTABLE_FILE_DISPLAY =
  "executable-file-display";
export const TENANT_SETTINGS_CITIZEN_DEVELOPMENT_DISPLAY =
  "citizen-development-display";
export const TENANT_SETTINGS_SHARE_AUTOMATION_SOURCE_CODE_MANDATORY =
  "share-automation-source-code-mandatory";
export const TENANT_SETTINGS_AUTOMATION_STORE = "automation-store";
export const TENANT_SETTINGS_INSTANT_CONSUMPTION = "instant-consumption";
export const TENANT_SETTINGS_USER_GAMIFICATION =
  "display-user-gamification-data";
export const TENANT_SETTINGS_EXPLORE_USERS_AND_PROFILE =
  "display-explore-users-and-profiles";
export const TENANT_SETTINGS_COMMENTS_AND_QUESTIONS_DISPLAY =
  "comments-questions-display";

/* Tenant setting option value slugs */
// const TENANT_SETTINGS_BINARY_ONE = "binary-one";
export const TENANT_SETTINGS_BINARY_ZERO = "binary-zero";

export const SETTING_SLUG_TELEMETRY_MAP = {
  [TENANT_SETTINGS_FILE_UPLOAD_DISPLAY]: FILE_UPLOAD_TOGGLE_OFF_SUCCESS,
  [TENANT_SETTINGS_TASK_CAPTURE_DISPLAY]: TASK_CAPTURE_TOGGLE_OFF_SUCCESS,
  [TENANT_SETTINGS_COMPONENTS_DISPLAY]: COMPONENTS_TOGGLE_OFF_SUCCESS,
  [TENANT_SETTINGS_LEADERBOARD_DISPLAY]: LEADERBOARD_TOGGLE_OFF_SUCCESS,
  [TENANT_SETTINGS_COMMENTS_AND_QUESTIONS_DISPLAY]: COMMENT_QUESTION_SECTION_TOGGLE_OFF_SUCCESS
};
export const LOCALIZATION_PREFIX_TENANT_SETTINGS = "tenant_settings";
