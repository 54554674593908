import {
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARD_USER_RANKING,
  CLEAR_LEADERBOARD_DATA
} from "../constants/actionTypes";

const INITIAL_STATE = {
  leaderboardData: null,
  leaderboardUserRankData: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD:
      return {
        ...state,
        leaderboardData: action.payload
      };
    case FETCH_LEADERBOARD_USER_RANKING:
      return {
        ...state,
        leaderboardUserRankData: action.payload
      };
    case CLEAR_LEADERBOARD_DATA:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
