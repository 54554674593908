import CellPhase from "../components/CellPhase/CellPhase";
import CellStatus from "../components/CellStatus/CellStatus";
import CellCollaborators from "../components/CellCollaborators/CellCollaborators";
import CellComponentManageComponents from "../components/CellComponentManageComponents/CellComponentManageComponents";
import CellFirstUploadedManageComponents from "../components/CellFirstUploadedManageComponents/CellFirstUploadedManageComponents";
import CellLastUpdatedByOwner from "../components/CellLastUpdatedByOwner/CellLastUpdatedByOwner";
import CellLastCuratedManageComponents from "../components/CellLastCuratedManageComponents/CellLastCuratedManageComponents";
import CellTypeManageComponents from "../components/CellTypeManageComponents/CellTypeManageComponents";
import CellStatusComponents from "../components/CellStatusComponents/CellStatusComponents";
import CuratorManageComponents from "../components/CuratorManageComponents/CuratorManageComponents";
import CellNameMyComponents from "../components/CellNameMyComponents/CellNameMyComponents";
import CellModifiedMyComponents from "../components/CellModifiedMyComponents/CellModifiedMyComponents";
import CellTypeMyComponents from "../components/CellTypeMyComponents/CellTypeMyComponents";
import CellRatingUserContainer from "../components/CellRatingUserContainer/CellRatingUserContainer";
import CellDownloadsMyComponents from "../components/CellDownloadsMyComponents/CellDownloadsMyComponents";
import CellActionMyComponents from "../components/CellActionMyComponents/CellActionMyComponents";
import CellVersionMyComponents from "../components/CellVersionMyComponents/CellVersionMyComponents";
import {
  AUTOMATION_NAME_HEADER_KEY,
  IDEA_SCORE_HEADER_KEY,
  ESTIMATED_BENEFIT_HOURS_HEADER_KEY,
  ESTIMATED_BENEFIT_CURRENCY_HEADER_KEY,
  UPVOTES_HEADER_KEY,
  PROCESS_PRIORITY_HEADER_KEY,
  AUTOMATION_POTENTIAL_HEADER_KEY,
  LAUNCH_DATE_HEADER_KEY,
  PHASE_HEADER_KEY
} from "./myWorkspaceConstants";
import {
  AUTOMATION_NAME_KEY,
  AUTOMATION_ID_KEY,
  DESCRIPTION_KEY,
  IDEA_PHASE_APPROVER_KEY,
  LAUNCH_ASSESSMENT_APPROVER_KEY,
  QUALIFICATION_PHASE_APPROVER_KEY,
  HIERARCHY_KEY,
  TAGS_KEY,
  DEPARTMENT_KEY,
  BUSINESS_UNIT_KEY,
  AUTOMATION_GOAL_KEY,
  RPA_SOFTWARE_VENDOR_KEY,
  LICENSE_TYPE_KEY,
  AUTOMATION_TYPE_KEY,
  PHASE_KEY,
  STATUS_KEY,
  COLLABORATORS_KEY,
  UPVOTES_KEY,
  IDEA_SCORE_KEY,
  AUTOMATION_POTENTIAL_KEY,
  ESTIMATED_BENEFIT_HOURS_KEY,
  ESTIMATED_BENEFIT_FTE_KEY,
  ESTIMATED_BENEFIT_CURRENCY_KEY,
  NUMBER_OF_ROBOTS_KEY,
  ESTIMATED_IMPLEMENTATION_COSTS_KEY,
  ESTIMATED_RUNNING_COSTS_KEY,
  ESTIMATED_NET_BENEFIT_YEAR_ONE_KEY,
  ESTIMATED_NET_BENEFIT_YEAR_TWO_KEY,
  PDD_LINK_KEY,
  ACTION_KEY,
  ASSESSMENT_SCORE_KEY,
  FTR_OVERRIDDEN_KEY,
  PROCESS_PRIORITY_KEY,
  DEVELOPMENT_TYPE_KEY
} from "./automationHeaderConstants";
import CellFTROverridden from "../components/CellFTROverridden/CellFTROverridden";
import CellDevelopmentType from "../components/CellDevelopmentType/CellDevelopmentType";
import CellProcessTags from "../components/CellProcessTags/CellProcessTags";

export const IDEAS = "Ideas";
export const COLLABORATIONS = "Collaborations";
export const AUTOMATION_PIPELINE = "PIPELINE-MANAGER";
export const MANAGE_COMPONENTS = "Manage-Components";
export const TASKS = "Tasks";
export const TEAM = "Team";
export const MY_COMPONENTS = "My-Components";
export const MY_COMPONENTS_UPLOADS = "My-Components-UPLOADS";
export const MY_COMPONENTS_DOWNLOADS = "My-Components-DOWNLOADS";
export const FOLLOWING = "Following";

export const Automation_Name = "Automation Name";
export const DESCRIPTION = "Description";
export const AUTOMATION_ID = "Automation ID";
export const PDD_LINK = "PDD Link";
export const LAUNCH_ASSESSMENT_APPROVER = "Launch Assessment Approver";
export const QUALIFICATION_PHASE_APPROVER = "Qualification Phase Approver";
export const IDEA_PHASE_APPROVER = "Idea Phase Approver";
export const Hierarchy = "Hierarchy";
export const Business_Unit = "Submitter Business Unit";
export const Department = "Submitter Department";
export const Phase = "Phase";
export const Status = "Status";
export const Automation_Goal = "Automation Goal";
export const Idea_Score = "Idea Score";
export const Assessment_Score = "Assessment Score";
export const Collaborators = "Collaborators";
export const Upvotes = "Upvotes";
export const INTERESTS_STRING = "Interests";
export const Action = "Action";
export const Priority = "Priority";
export const Development_Type = "Development Type";
export const NUMBER_OF_UPVOTES = "Number of Upvotes";
export const ESTIMATED_BENEFITS_HOURS = "Est. Benefit Per Company (Hours)";
export const ESTIMATED_BENEFITS_FTE = "Est. Benefit Per Company (FTEs)";
export const ESTIMATED_BENEFITS_CURRENCY =
  "Est. Benefit Per Company (currency)";
export const ESTIMATED_IMPLEMENTATION_COSTS = "Est. Implementation Costs";
export const ESTIMATED_RUNNING_COSTS = "Est. Running Costs";
export const ESTIMATED_NET_BENEFITS_YR1 = "Est. Net Benefit Year 1";
export const ESTIMATED_NET_BENEFITS_YR2 = "Est. Net Benefit Year 2";
export const RPA_SOFTWARE_VENDOR = "RPA Software Vendor";
export const LICENSE_TYPE = "License Type";
export const AUTOMATION_TYPE = "Automation Type";
export const NUMBER_OF_ROBOTS = "Number Of Robots";
export const AUTOMATION_POTENTIAL = "Automation Potential";

export const NAME = "Name";
export const MODIFIED = "Modified";
export const STATUS = "Status";
export const RATING = "Rating";
export const DOWNLOADS = "Downloads";
export const ACTION = "Action";
export const VERSION = "Version";

//COLUMNS FOR MY TASKS
export const TASK = "Task";
export const DATE_ASSIGNED = "Date Assigned";
export const TITLE = "Title";
export const PHASE = "Phase";
export const COLLABORATORS = "Collaborators";

//COLUMNS FOR MANAGE COMPONENTS
export const COMPONENTS = "COMPONENTS";
export const COMPONENTS_VERSION = "VERSION";
export const FIRST_UPLOADED = "FIRST UPLOADED";
export const LAST_UPDATED_BY_OWNER = "LAST UPDATED BY OWNER";
export const LAST_CURATED = "LAST CURATED";
export const TYPE = "Type";
export const CURATOR = "CURATOR";

export const QUICK_VIEW = "QUICK_VIEW";
export const AUTOMATION_PROFILE = "AUTOMATION_PROFILE";
export const APPROVE = "APPROVE";
export const REJECT = "REJECT";
export const ARCHIVE = "ARCHIVE";
export const DELETE = "DELETE";
export const LAUNCH_ASSESSMENT = "LAUNCH_ASSESSMENT";
export const MARK_AS_DUPLICATE = "MARK_AS_DUPLICATE";
export const MARK_AS_COMPLETE = "MARK_AS_COMPLETE";
export const PUT_ON_HOLD = "PUT_ON_HOLD";

export const DEFAULT_TASKS_ACTION_OPTIONS = [
  MARK_AS_COMPLETE,
  QUICK_VIEW,
  AUTOMATION_PROFILE
];

export const Alphabetical = "Alphabetical";
export const LAST_UPDATED = "Last Updated";
export const Newest_Submitted = "Newest Submitted";
export const Oldest_Submitted = "Oldest Submitted";
export const DATE_SUBMITTED = "Date Submitted";
export const LAST_MODIFIED = "Last Modified";
export const OLDEST_MODIFIED = "Oldest Modified";
export const DATE_MODIFIED = "Date Modified";
export const LAST_ASSIGNED = "Last Assigned";
export const OLDEST_ASSIGNED = "Oldest Assigned";
export const PRIORITY_SORT = "Priority";
export const PHASE_SORT = "Phase";
export const AUTOMATION_POTENTIAL_SORT = "Automation Potential";
export const LAUNCH_DATE = "Launch Date";

export const Alphabetical_key = "alphabetical";
export const LAST_UPDATED_key = "last_updated";
export const Newest_Submitted_key = "newest_submitted";
export const Oldest_Submitted_key = "oldest_submitted";
export const DATE_SUBMITTED_KEY = "date_submitted";

export const LAST_MODIFIED_key = "last_modified";
export const DATE_MODIFIED_KEY = "date_modified";
export const OLDEST_MODIFIED_key = "oldest_modified";
export const LAST_ASSIGNED_key = "last_assigned";
export const OLDEST_ASSIGNED_key = "oldest_assigned";
export const Idea_Score_key = "idea_score";
export const ESTIMATED_BENEFITS_HOURS_key = "estimated_benefits_hours";
export const ESTIMATED_BENEFITS_CURRENCY_key = "estimated_benefits_currency";
export const Assessment_Score_key = "assessment_score";
export const Upvotes_key = "upvotes_score";
export const PRIORITY_SORT_KEY = "priority";
export const PHASE_SORT_KEY = "phase_order";
export const AUTOMATION_POTENTIAL_SORT_KEY = "process_automation_score";
export const LAUNCH_DATE_KEY = "launch_date";

export const ASC = "asc";
export const DESC = "desc";

//My Components tab types
export const MY_COMPONENT_DOWNLOADS = "Downloads";
export const MY_COMPONENT_UPLOADS = "Uploads";

export const COLUMN_COMPONENT_MY_COMPONENTS = {
  [NAME]: CellNameMyComponents,
  [MODIFIED]: CellModifiedMyComponents,
  [VERSION]: CellVersionMyComponents,
  [TYPE]: CellTypeMyComponents,
  [STATUS]: CellStatusComponents,
  [RATING]: CellRatingUserContainer,
  [DOWNLOADS]: CellDownloadsMyComponents,
  [ACTION]: CellActionMyComponents
};

export const COLUMN_COMPONENT_MANAGE_COMPONENTS = {
  [COMPONENTS]: CellComponentManageComponents,
  [COMPONENTS_VERSION]: CellVersionMyComponents,
  [FIRST_UPLOADED]: CellFirstUploadedManageComponents,
  [LAST_UPDATED_BY_OWNER]: CellLastUpdatedByOwner,
  [LAST_CURATED]: CellLastCuratedManageComponents,
  [TYPE]: CellTypeManageComponents,
  [STATUS]: CellStatusComponents,
  [CURATOR]: CuratorManageComponents,
  [ACTION]: CellActionMyComponents
};

export const COLUMN_KEY_TO_FIELDS = {
  [AUTOMATION_NAME_KEY]: [
    "process_name",
    "process_updated_epoch",
    "process_created_epoch",
    "process_slug",
    "process_overall_gauge_score",
    "process_owner",
    "process_submission_type"
  ],
  [DESCRIPTION_KEY]: ["process_description"],
  [PDD_LINK_KEY]: ["pdd_link", "process_slug"],
  [LAUNCH_ASSESSMENT_APPROVER_KEY]: ["launch_assessment_approver"],
  [QUALIFICATION_PHASE_APPROVER_KEY]: ["qualification_phase_approver"],
  [AUTOMATION_ID_KEY]: ["orchestrator_automation_uuid"],
  [FTR_OVERRIDDEN_KEY]: ["ftr_overridden"],
  [BUSINESS_UNIT_KEY]: ["process_submitter", "user_business_unit"],
  [HIERARCHY_KEY]: ["process_L1", "process_L2", "process_L3", "categories"],
  [TAGS_KEY]: ["process_tags"],
  [DEPARTMENT_KEY]: ["process_submitter", "user_department"],
  [PHASE_KEY]: ["process_phase"],
  [STATUS_KEY]: ["process_phase_status"],
  [AUTOMATION_GOAL_KEY]: ["process_goal", "goal"],
  [IDEA_SCORE_KEY]: ["process_overall_gauge_score", "process_submission_type"],
  [ASSESSMENT_SCORE_KEY]: [
    "process_estimated_benefit_score",
    "process_ease_of_implementation_score"
  ],
  [ESTIMATED_BENEFIT_HOURS_KEY]: [
    "process_estimated_benefit_score",
    "process_ease_of_implementation_score"
  ],
  [COLLABORATORS_KEY]: [
    "process_num_collaborators",
    "collaborators",
    "process_owner",
    "process_slug"
  ],
  [UPVOTES_KEY]: ["process_num_votes"],
  [RPA_SOFTWARE_VENDOR_KEY]: ["software_vendor"],
  [LICENSE_TYPE_KEY]: ["cost_description"],
  [AUTOMATION_TYPE_KEY]: ["process_type_id"],

  [ESTIMATED_BENEFIT_FTE_KEY]: ["est_benefit_ftes"],
  [ESTIMATED_BENEFIT_CURRENCY_KEY]: [
    "process_benefit_expressed_in_currency_per_year",
    "currency"
  ],
  [ESTIMATED_IMPLEMENTATION_COSTS_KEY]: [
    "est_implementation_costs",
    "currency"
  ],
  [ESTIMATED_RUNNING_COSTS_KEY]: ["est_running_costs", "currency"],
  [ESTIMATED_NET_BENEFIT_YEAR_ONE_KEY]: [
    "est_net_benefit_year_one",
    "currency"
  ],
  [ESTIMATED_NET_BENEFIT_YEAR_TWO_KEY]: [
    "est_net_benefit_year_two",
    "currency"
  ],
  [NUMBER_OF_ROBOTS_KEY]: ["number_of_robots"],
  [AUTOMATION_POTENTIAL_KEY]: ["process_automation_score"],
  [ACTION_KEY]: ["process_slug"],
  [PROCESS_PRIORITY_KEY]: ["process_priority"],
  [DEVELOPMENT_TYPE_KEY]: ["process_development_type"]
};

export const COLUMN_FIELD_MY_COMPONENTS = {
  [NAME]: [
    "component_name",
    "component_created_epoch",
    "versions",
    "component_status_id"
  ],
  [MODIFIED]: ["component_updated_epoch"],
  [VERSION]: ["component_semantic_version", "component_version"],
  [TYPE]: ["component_type"],
  //this needs to be replaced later
  [STATUS]: [
    "component_status_id",
    "component_slug",
    "component_reject_reason",
    "component_semantic_version",
    "latest_published_version",
    "latest_published_semantic_version",
    "latest_published_release_notes"
  ],
  [RATING]: ["component_reviews_avg", "component_num_reviews"],
  [DOWNLOADS]: ["component_num_downloads"],
  [ACTION]: ["component_id", "permissions"]
};

export const COLUMN_FIELD_MY_TASKS = {
  [TASK]: [
    "type_name",
    "user_task_text",
    "task_activity_key",
    "user_task_action_url",
    "type_key",
    "task_comment"
  ],
  [DATE_ASSIGNED]: ["created_epoch"],
  [TITLE]: {
    "1": ["process_updated_epoch", "process_name"],
    "2": ["component_updated_epoch", "component_name"]
  },
  [PHASE]: ["process_phase"],
  [STATUS]: {
    "1": ["process_phase_status"],
    "2": ["component_status_id"]
  },
  [COLLABORATORS]: [
    "process_num_collaborators",
    "collaborators",
    "process_slug",
    "process_owner"
  ],
  [ACTION]: []
};

export const COLUMN_FIELD_MANAGE_COMPONENTS = {
  [COMPONENTS]: ["component_name", "component_slug"],
  [COMPONENTS_VERSION]: ["component_version", "component_semantic_version"],
  [FIRST_UPLOADED]: ["component_created_epoch"],
  [LAST_UPDATED_BY_OWNER]: ["component_updated_epoch"],
  [LAST_CURATED]: ["component_updated_epoch"],
  [TYPE]: ["component_type"],
  [STATUS]: ["component_status_id", "component_reject_reason"],
  [CURATOR]: ["component_curator_user_id", "curator"],
  [ACTION]: ["component_id", "permissions"]
};

export const COLUMN_LENGTH = {
  [Automation_Name]: 270,
  [Hierarchy]: 200,
  [Department]: 150,
  [Phase]: 150,
  [Status]: 150,
  [Automation_Goal]: 250,
  [Idea_Score]: 75,
  [Assessment_Score]: 118,
  [Collaborators]: 130,
  [Upvotes]: 100,
  [Action]: 78
};

export const COLUMN_IDX = {
  [Automation_Name]: 0,
  [Hierarchy]: 1,
  [Department]: 2,
  [Phase]: 3,
  [Status]: 4,
  [Automation_Goal]: 5,
  [Idea_Score]: 6,
  [Assessment_Score]: 7,
  [Collaborators]: 8,
  [Upvotes]: 9,
  [Action]: 10
};

export const COLUMN_IDXS = {
  [AUTOMATION_PIPELINE]: {
    [Automation_Name]: 0,
    [Hierarchy]: 1,
    [Department]: 2,
    [Business_Unit]: 3,
    [Phase]: 4,
    [Status]: 5,
    [Automation_Goal]: 6,
    [Idea_Score]: 7,
    [Assessment_Score]: 8,
    [Collaborators]: 9,
    [Upvotes]: 10,
    [Action]: 11
  },
  [TASKS]: {
    [TASK]: 0,
    [DATE_ASSIGNED]: 1,
    [TITLE]: 2,
    [PHASE]: 3,
    [STATUS]: 4,
    [COLLABORATORS]: 5,
    [ACTION]: 6
  },
  [MY_COMPONENTS]: {
    [NAME]: 0,
    [MODIFIED]: 1,
    [VERSION]: 2,
    [TYPE]: 3,
    [STATUS]: 4,
    [RATING]: 5,
    [DOWNLOADS]: 6,
    [ACTION]: 7
  }
};

export const INITIAL_COLUMN = [
  Automation_Name,
  Hierarchy,
  Phase,
  Status,
  Automation_Goal,
  Idea_Score,
  Assessment_Score,
  Collaborators,
  Upvotes,
  Action
];

export const INITIAL_COLUMNS = {
  [AUTOMATION_PIPELINE]: [
    Automation_Name,
    Hierarchy,
    Business_Unit,
    Department,
    Phase,
    Status,
    Automation_Goal,
    Idea_Score,
    AUTOMATION_POTENTIAL,
    ESTIMATED_BENEFITS_HOURS
  ],
  [COLLABORATIONS]: [
    Automation_Name,
    Hierarchy,
    Phase,
    Status,
    Idea_Score,
    ESTIMATED_BENEFITS_HOURS,
    Collaborators,
    Action
  ],
  [IDEAS]: [
    Automation_Name,
    Hierarchy,
    Phase,
    Status,
    Idea_Score,
    ESTIMATED_BENEFITS_HOURS,
    Collaborators,
    Action
  ],
  [TEAM]: [
    Automation_Name,
    Hierarchy,
    Phase,
    Status,
    Idea_Score,
    ESTIMATED_BENEFITS_HOURS,
    Collaborators,
    Action
  ],
  [TASKS]: [TASK, DATE_ASSIGNED, TITLE, PHASE, STATUS, COLLABORATORS, ACTION],
  [FOLLOWING]: [
    Automation_Name,
    Hierarchy,
    Phase,
    Status,
    ESTIMATED_BENEFITS_HOURS,
    Collaborators,
    Upvotes,
    Action
  ],
  [MY_COMPONENTS_UPLOADS]: [NAME, MODIFIED, TYPE, RATING, ACTION],
  [MY_COMPONENTS_DOWNLOADS]: [NAME, MODIFIED, VERSION, STATUS, ACTION],
  [MANAGE_COMPONENTS]: [
    COMPONENTS,
    COMPONENTS_VERSION,
    FIRST_UPLOADED,
    LAST_UPDATED_BY_OWNER,
    LAST_CURATED,
    TYPE,
    STATUS,
    CURATOR,
    ACTION
  ]
};

export const HEADER_STYLES_MY_COMPONENTS = {
  [NAME]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [MODIFIED]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [VERSION]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [TYPE]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [STATUS]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [RATING]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [DOWNLOADS]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [ACTION]: {
    mainStyle: {},
    secondaryStyle: {}
  }
};

export const HEADER_STYLES_MANAGE_COMPONENTS = {
  [COMPONENTS]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [COMPONENTS_VERSION]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [FIRST_UPLOADED]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [LAST_UPDATED_BY_OWNER]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [LAST_CURATED]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [TYPE]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [STATUS]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [CURATOR]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [ACTION]: {
    mainStyle: {},
    secondaryStyle: {}
  }
};

export const HEADER_STYLES_MY_TASKS = {
  [TASK]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [DATE_ASSIGNED]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [TITLE]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [PHASE]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [STATUS]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [COLLABORATORS]: {
    mainStyle: {},
    secondaryStyle: {}
  },
  [ACTION]: {
    mainStyle: {},
    secondaryStyle: {}
  }
};

export const DEFAULT_FILTER_COLUMN = {
  [Hierarchy]: { label: Hierarchy, value: Hierarchy },
  [Department]: { label: Department, value: Department },
  [Phase]: { label: Phase, value: Phase },
  [Status]: { label: Status, value: Status },
  [Automation_Goal]: { label: Automation_Goal, value: Automation_Goal },
  [Idea_Score]: { label: Idea_Score, value: Idea_Score },
  [Assessment_Score]: { label: Assessment_Score, value: Assessment_Score },
  [Collaborators]: { label: Collaborators, value: Collaborators },
  [Upvotes]: { label: Upvotes, value: Upvotes },
  [Priority]: { label: Priority, value: Priority },
  [Development_Type]: { label: Development_Type, value: Development_Type }
};

export const DEFAULT_FILTER_COLUMNS = {
  [AUTOMATION_PIPELINE]: {
    [Hierarchy]: { label: Hierarchy, value: Hierarchy },
    [Department]: { label: Department, value: Department },
    [Business_Unit]: { label: Business_Unit, value: Business_Unit },
    [Phase]: { label: Phase, value: Phase },
    [Status]: { label: Status, value: Status },
    [Automation_Goal]: { label: Automation_Goal, value: Automation_Goal },
    [Idea_Score]: { label: Idea_Score, value: Idea_Score },
    [Assessment_Score]: { label: Assessment_Score, value: Assessment_Score },
    [Collaborators]: { label: Collaborators, value: Collaborators },
    [Upvotes]: { label: Upvotes, value: Upvotes },
    [Priority]: { label: Priority, value: Priority },
    [Development_Type]: { label: Development_Type, value: Development_Type }
  },
  [MY_COMPONENTS_UPLOADS]: {
    [MODIFIED]: { label: MODIFIED, value: MODIFIED },
    [TYPE]: { label: TYPE, value: TYPE },
    [RATING]: { label: RATING, value: RATING }
  },
  [MY_COMPONENTS_DOWNLOADS]: {
    [MODIFIED]: { label: MODIFIED, value: MODIFIED },
    [VERSION]: { label: VERSION, value: VERSION },
    [STATUS]: { label: STATUS, value: STATUS }
  },
  [TASKS]: {
    [DATE_ASSIGNED]: { label: DATE_ASSIGNED, value: DATE_ASSIGNED },
    [TITLE]: { label: TITLE, value: TITLE },
    [PHASE]: { label: PHASE, value: PHASE },
    [STATUS]: { label: STATUS, value: STATUS },
    [COLLABORATORS]: { label: COLLABORATORS, value: COLLABORATORS }
  }
};

/*
Table tab options stuff
*/
export const ALL = "All";
export const ASSESSMENT_PIPELINE = "Assessment Pipeline";
export const DECISION_PIPELINE = "Decision Pipeline";
export const IN_REVIEW = "Review";
export const SUBMITTED = "Submitted";
export const UPLOADS = "Uploads";
export const INCOMPLETE = "Incomplete";
export const COMPLETE = "Complete";

/* 
  DELETE THESE WHEN MAINTABLE AUTOMATIONS DELETED Save View keys
*/

export const ALL_PIPELINE_SAVE_VIEW_KEY = "all_pipeline";
export const ASSESSMENT_PIPELINE_SAVE_VIEW_KEY = "assessment_pipeline";
export const DECISION_PIPELINE_SAVE_VIEW_KEY = "decision_pipeline";

export const SAVE_VIEW_PAGE_TO_KEY = {
  [ALL]: ALL_PIPELINE_SAVE_VIEW_KEY,
  [ASSESSMENT_PIPELINE]: ASSESSMENT_PIPELINE_SAVE_VIEW_KEY,
  [DECISION_PIPELINE]: DECISION_PIPELINE_SAVE_VIEW_KEY
};

export const DEFAULT_TABS = [ALL, ASSESSMENT_PIPELINE, DECISION_PIPELINE];

export const TAB_TO_COUNT = {
  [ALL]: "all",
  [ASSESSMENT_PIPELINE]: "assessment",
  [DECISION_PIPELINE]: "decision"
};

/* Mostly used for sorting */
const process_name = "process_name";
const process_created_epoch = "process_created_epoch";
const process_overall_gauge_score = "process_overall_gauge_score";
const process_estimated_benefit_score = "process_estimated_benefit_score";
const process_benefit_expressed_in_currency_per_year =
  "process_benefit_expressed_in_currency_per_year";
const process_num_votes = "process_num_votes";
const process_updated_epoch = "process_updated_epoch";
const created_at = "created_at";
const process_priority = "process_priority";

export const SORT_OPTIONS = {
  [Alphabetical]: {
    label: Alphabetical,
    value: Alphabetical,
    key: Alphabetical_key,
    isSelected: false
  },
  [Newest_Submitted]: {
    label: Newest_Submitted,
    value: Newest_Submitted,
    key: Newest_Submitted_key,
    isSelected: false
  },
  [Idea_Score]: {
    label: Idea_Score,
    value: Idea_Score,
    key: Idea_Score_key,
    isSelected: false
  },
  [Assessment_Score]: {
    label: Assessment_Score,
    value: Assessment_Score,
    key: Assessment_Score_key,
    isSelected: false
  },
  [Upvotes]: {
    label: Upvotes,
    value: INTERESTS_STRING,
    key: Upvotes_key,
    isSelected: false
  },
  [LAST_MODIFIED]: {
    label: LAST_MODIFIED,
    value: LAST_MODIFIED,
    key: LAST_MODIFIED_key,
    isSelected: false
  },
  [OLDEST_MODIFIED]: {
    label: OLDEST_MODIFIED,
    value: OLDEST_MODIFIED,
    key: OLDEST_MODIFIED_key,
    isSelected: false
  },
  [LAST_ASSIGNED]: {
    label: LAST_ASSIGNED,
    value: LAST_ASSIGNED,
    key: LAST_ASSIGNED_key,
    isSelected: false
  },
  [OLDEST_ASSIGNED]: {
    label: OLDEST_ASSIGNED,
    value: OLDEST_ASSIGNED,
    key: OLDEST_ASSIGNED_key,
    isSelected: false
  },
  [Priority]: {
    label: PRIORITY_SORT,
    value: PRIORITY_SORT,
    KEY: PRIORITY_SORT_KEY,
    isSelected: false
  }
};

/* 
  TODO: Old My tasks table still using this will 
  remove once My Tasks is using new table
*/
export const SORT_OPTIONS_LIST_FOR_TABLES = [
  {
    label: Alphabetical,
    key: Alphabetical_key,
    value: Alphabetical,
    isSelected: false
  },
  {
    label: LAST_MODIFIED,
    key: LAST_MODIFIED_key,
    value: LAST_MODIFIED,
    isSelected: false
  },
  {
    label: OLDEST_MODIFIED,
    key: OLDEST_MODIFIED_key,
    value: OLDEST_MODIFIED,
    isSelected: false
  },
  {
    label: Newest_Submitted,
    key: Newest_Submitted_key,
    value: Newest_Submitted,
    isSelected: false
  },
  {
    label: Oldest_Submitted,
    key: Oldest_Submitted_key,
    value: Oldest_Submitted,
    isSelected: false
  },
  {
    label: Idea_Score,
    key: Idea_Score_key,
    value: Idea_Score,
    isSelected: false
  },
  {
    label: ESTIMATED_BENEFITS_HOURS,
    key: ESTIMATED_BENEFITS_HOURS_key,
    value: ESTIMATED_BENEFITS_HOURS,
    isSelected: false
  },
  {
    label: ESTIMATED_BENEFITS_CURRENCY,
    key: ESTIMATED_BENEFITS_CURRENCY_key,
    value: ESTIMATED_BENEFITS_CURRENCY,
    isSelected: false
  },
  {
    label: Assessment_Score,
    key: Assessment_Score_key,
    value: Assessment_Score,
    isSelected: false
  },
  {
    label: Upvotes,
    key: Upvotes_key,
    value: Upvotes,
    isSelected: false
  },
  {
    label: LAST_ASSIGNED,
    key: LAST_ASSIGNED_key,
    value: LAST_ASSIGNED,
    isSelected: false
  },
  {
    label: OLDEST_ASSIGNED,
    key: OLDEST_ASSIGNED_key,
    value: OLDEST_ASSIGNED,
    isSelected: false
  },
  {
    label: PRIORITY_SORT,
    key: PRIORITY_SORT_KEY,
    value: PRIORITY_SORT,
    isSelected: false
  }
];
export const SORT_OPTIONS_LIST_FOR_TABLES_MY_WORKSPACE = [
  {
    label: Alphabetical,
    key: Alphabetical_key,
    value: Alphabetical,
    isSelected: false
  },
  {
    label: DATE_MODIFIED,
    key: DATE_MODIFIED_KEY,
    value: DATE_MODIFIED
  },
  {
    label: Newest_Submitted,
    key: Newest_Submitted_key,
    value: Newest_Submitted,
    isSelected: false
  },
  {
    label: Oldest_Submitted,
    key: Oldest_Submitted_key,
    value: Oldest_Submitted,
    isSelected: false
  },
  {
    label: DATE_SUBMITTED,
    key: DATE_SUBMITTED_KEY,
    value: DATE_SUBMITTED,
    isSelected: false
  },
  {
    label: LAUNCH_DATE,
    key: LAUNCH_DATE_KEY,
    value: LAUNCH_DATE,
    isSelected: false
  },
  {
    label: Idea_Score,
    key: Idea_Score_key,
    value: Idea_Score,
    isSelected: false
  },
  {
    label: AUTOMATION_POTENTIAL_SORT,
    key: AUTOMATION_POTENTIAL_SORT_KEY,
    value: AUTOMATION_POTENTIAL_SORT,
    isSelected: false
  },
  {
    label: ESTIMATED_BENEFITS_HOURS,
    key: ESTIMATED_BENEFITS_HOURS_key,
    value: ESTIMATED_BENEFITS_HOURS,
    isSelected: false
  },
  {
    label: ESTIMATED_BENEFITS_CURRENCY,
    key: ESTIMATED_BENEFITS_CURRENCY_key,
    value: ESTIMATED_BENEFITS_CURRENCY,
    isSelected: false
  },
  {
    label: Assessment_Score,
    key: Assessment_Score_key,
    value: Assessment_Score,
    isSelected: false
  },
  {
    label: PHASE_SORT,
    key: PHASE_SORT_KEY,
    value: PHASE_SORT,
    isSelected: false
  },
  {
    label: Upvotes,
    key: Upvotes_key,
    value: Upvotes,
    isSelected: false
  },
  {
    label: LAST_ASSIGNED,
    key: LAST_ASSIGNED_key,
    value: LAST_ASSIGNED,
    isSelected: false
  },
  {
    label: OLDEST_ASSIGNED,
    key: OLDEST_ASSIGNED_key,
    value: OLDEST_ASSIGNED,
    isSelected: false
  },
  {
    label: PRIORITY_SORT,
    key: PRIORITY_SORT_KEY,
    value: PRIORITY_SORT,
    isSelected: false
  }
];

export const SORT_OPTIONS_KEYS = {
  [AUTOMATION_PIPELINE]: [
    Alphabetical,
    LAUNCH_DATE,
    DATE_MODIFIED,
    DATE_SUBMITTED,
    Idea_Score,
    AUTOMATION_POTENTIAL_SORT,
    ESTIMATED_BENEFITS_HOURS,
    ESTIMATED_BENEFITS_CURRENCY,
    PHASE_SORT,
    Upvotes,
    PRIORITY_SORT
  ],
  [DECISION_PIPELINE]: [PRIORITY_SORT],
  [COLLABORATIONS]: [
    Alphabetical,
    DATE_MODIFIED,
    Idea_Score,
    ESTIMATED_BENEFITS_HOURS,
    Upvotes
  ],
  [TASKS]: [LAST_ASSIGNED, OLDEST_ASSIGNED],
  [FOLLOWING]: [
    Alphabetical,
    DATE_MODIFIED,
    Idea_Score,
    ESTIMATED_BENEFITS_HOURS,
    Upvotes
  ],
  [IDEAS]: [
    Alphabetical,
    DATE_SUBMITTED,
    Idea_Score,
    ESTIMATED_BENEFITS_HOURS,
    Upvotes
  ]
};

export const SORT_VALUE_NAME_MAP = {
  [process_name]: Alphabetical,
  [process_created_epoch]: {
    [ASC]: Newest_Submitted,
    [DESC]: Oldest_Submitted
  },
  [process_overall_gauge_score]: Idea_Score,
  [process_estimated_benefit_score]: Assessment_Score,
  [process_num_votes]: Upvotes,
  [process_updated_epoch]: {
    [DESC]: LAST_MODIFIED,
    [ASC]: OLDEST_MODIFIED
  },
  [process_priority]: PRIORITY_SORT
};

export const SORT_OPTION_VALUES = {
  [Alphabetical]: {
    sort: process_name,
    order: ASC
  },
  [Newest_Submitted]: {
    sort: process_created_epoch,
    order: DESC
  },
  [Oldest_Submitted]: {
    sort: process_created_epoch,
    order: ASC
  },
  [DATE_SUBMITTED]: {
    sort: process_created_epoch
  },
  [Idea_Score]: {
    sort: process_overall_gauge_score,
    order: DESC
  },
  [Assessment_Score]: {
    sort: process_estimated_benefit_score,
    order: DESC
  },
  [ESTIMATED_BENEFITS_HOURS]: {
    sort: process_estimated_benefit_score,
    order: DESC
  },
  [ESTIMATED_BENEFITS_CURRENCY]: {
    sort: process_benefit_expressed_in_currency_per_year,
    order: DESC
  },
  [Upvotes]: {
    sort: process_num_votes,
    order: DESC
  },
  [LAST_MODIFIED]: {
    sort: process_updated_epoch,
    order: DESC
  },
  [OLDEST_MODIFIED]: {
    sort: process_updated_epoch,
    order: ASC
  },
  [DATE_MODIFIED]: {
    sort: process_updated_epoch
  },
  [LAST_ASSIGNED]: {
    sort: created_at,
    order: DESC
  },
  [OLDEST_ASSIGNED]: {
    sort: created_at,
    order: ASC
  },
  [PRIORITY_SORT]: {
    sort: process_priority,
    order: ASC
  },
  [PHASE_SORT]: {
    sort: "phase_order",
    order: ASC
  },
  [AUTOMATION_POTENTIAL_SORT]: {
    sort: "process_automation_score",
    order: DESC
  },
  [LAUNCH_DATE]: { sort: "launch_date", order: DESC }
};

export const SORT_OPTION_VALUES_OPPOSITE = {
  [process_name + ASC]: Alphabetical,
  [process_created_epoch + DESC]: Newest_Submitted,
  [process_created_epoch + ASC]: Oldest_Submitted,
  [process_overall_gauge_score + DESC]: Idea_Score,
  [process_estimated_benefit_score + DESC]: Assessment_Score,
  [process_estimated_benefit_score + DESC]: ESTIMATED_BENEFITS_HOURS,
  [process_benefit_expressed_in_currency_per_year +
  DESC]: ESTIMATED_BENEFITS_CURRENCY,
  [process_num_votes + DESC]: Upvotes,
  [process_updated_epoch + DESC]: LAST_MODIFIED,
  [process_updated_epoch + ASC]: OLDEST_MODIFIED,
  [created_at + DESC]: LAST_ASSIGNED,
  [created_at + ASC]: OLDEST_ASSIGNED,
  [process_priority + ASC]: PRIORITY_SORT
};

export const AWAITING_REVIEW = "Awaiting Review";
export const NOT_STARTED = "Not Started";
export const APPROVED = "Approved";
export const ON_TRACK = "On Track";
export const COMPLETED = "Completed";
export const REJECTED = "Rejected";
export const AT_RISK = "At Risk";
export const DELAYED = "Delayed";
export const CANCELLED = "Cancelled";
export const DUPLICATE = "Duplicate";
export const ON_HOLD = "On Hold";
export const IN_PROGRESS = "In Progress";
export const DRAFT = "Draft";
export const DEPLOYED = "Deployed";
export const ARCHIVED = "Archived";

export const searchFilterValue = "searchFilterValue";

export const SORT_TYPE_TO_COLUMN_SLUG = {
  [Alphabetical]: AUTOMATION_NAME_HEADER_KEY,
  [LAST_MODIFIED]: AUTOMATION_NAME_HEADER_KEY,
  [OLDEST_MODIFIED]: AUTOMATION_NAME_HEADER_KEY,
  [DATE_MODIFIED]: AUTOMATION_NAME_HEADER_KEY,
  [Newest_Submitted]: AUTOMATION_NAME_HEADER_KEY,
  [Oldest_Submitted]: AUTOMATION_NAME_HEADER_KEY,
  [DATE_SUBMITTED]: AUTOMATION_NAME_HEADER_KEY,
  [Idea_Score]: IDEA_SCORE_HEADER_KEY,
  [ESTIMATED_BENEFITS_HOURS]: ESTIMATED_BENEFIT_HOURS_HEADER_KEY,
  [ESTIMATED_BENEFITS_CURRENCY]: ESTIMATED_BENEFIT_CURRENCY_HEADER_KEY,
  [Upvotes]: UPVOTES_HEADER_KEY,
  [PRIORITY_SORT]: PROCESS_PRIORITY_HEADER_KEY,
  [PHASE_SORT]: PHASE_HEADER_KEY,
  [AUTOMATION_POTENTIAL_SORT]: AUTOMATION_POTENTIAL_HEADER_KEY,
  [LAUNCH_DATE]: LAUNCH_DATE_HEADER_KEY
};

/*
  Column data is used to simulate the columns endpoint used in
  the for `Automation Pipeline` page. This is necessary to keep 
  frontend table code consistent and to not write duplicate code.
  This can  be removed once backend implements the columns logic
  for the other tables (My Ideas, My Team, etc)
*/
export const AUTOMATION_NAME_COLUMN_DATA = {
  display: "Automation Name",
  key: "process_name",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 100,
  sort: "ASC",
  filterValues: null
};

export const HIEARCHY_COLUMN_DATA = {
  display: "Hierarchy",
  key: "hierarchy",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 400,
  sort: "ASC",
  filterValues: null
};

export const DEPARTMENT_COLUMN_DATA = {
  display: "Submitter's Department",
  key: "department",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 600,
  sort: "ASC",
  filterValues: null
};

export const BUSINESS_UNIT_COLUMN_DATA = {
  display: "Submitter’s Business Unit",
  key: "business_unit",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sort: "ASC",
  sortOrder: 500
};

export const PHASE_COLUMN_DATA = {
  display: "Phase",
  key: "phase",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 1000,
  sort: "ASC",
  filterValues: null
};

export const STATUS_COLUMN_DATA = {
  display: "Status",
  key: "phase_status",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 1100,
  sort: "ASC",
  filterValues: null
};

export const COLLABORATORS_COLUMN_DATA = {
  display: "Collaborators",
  key: "collaborators",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 1500,
  sort: "ASC",
  filterValues: null
};

export const AUTOMATION_GOAL_DATA = {
  display: "Automation Goal",
  key: "process_goal",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 1600,
  sort: "ASC",
  filterValues: null
};

export const IDEA_SCORE_COLUMN_DATA = {
  display: "Idea Score",
  key: "idea_score",
  filterType: "input",
  selectionType: "float",
  filterable: false,
  sortOrder: 1700,
  sort: "ASC",
  filterValues: null
};

export const ASSESSMENT_SCORE_COLUMN_DATA = {
  display: "Est. Benefit Per Company (Hours)",
  key: "estimated_benefit_hours",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sortOrder: 1900,
  sort: "ASC",
  filterValues: null
};

export const UPVOTES_COLUMN_DATA = {
  display: "Interests",
  key: "number_of_votes",
  filterType: null,
  selectionType: "float",
  filterable: false,
  sortOrder: 3000,
  sort: "DESC",
  filterValues: []
};

export const PUBLISHED_DATA = {
  display: "Published",
  key: "automation_published",
  filterType: null,
  selectionType: "float",
  filterable: false,
  sortOrder: 2900,
  sort: "DESC",
  //this is temporary until backend passes the filter
  filterValues: [
    {
      published_filter_name: "Yes",
      published_filter_slug: "yes"
    },
    {
      published_filter_name: "No",
      published_filter_slug: "no"
    }
  ]
};

export const PRIORITY_DATA = {
  display: "Priority",
  key: "process_priority",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sort: "ASC",
  sortOrder: 250,
  filterValues: []
};

export const DEVELOPMENT_TYPE_DATA = {
  display: "Development Type",
  key: "development_type",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sort: "ASC",
  sortOrder: 950,
  filterValues: []
};

export const IDEA_SOURCE_DATA = {
  display: "Idea Source",
  key: "process_submission_type",
  filterType: "dropdown",
  selectionType: "enum",
  filterable: true,
  sort: "ASC",
  sortOrder: 925,
  filterValues: []
};

export const POTENTIAL_BENEFIT_DATA = {
  display: "Potential Benefit",
  key: "automation_potential_benefit_across_company",
  filterType: null,
  selectionType: "float",
  filterable: false,
  sort: "DESC",
  sortOrder: 1850,
  filterValues: []
};

export const MY_WORKSPACE_COLUMNS_DATA = [
  AUTOMATION_NAME_COLUMN_DATA,
  HIEARCHY_COLUMN_DATA,
  DEPARTMENT_COLUMN_DATA,
  PHASE_COLUMN_DATA,
  STATUS_COLUMN_DATA,
  AUTOMATION_GOAL_DATA,
  IDEA_SCORE_COLUMN_DATA,
  ASSESSMENT_SCORE_COLUMN_DATA,
  COLLABORATORS_COLUMN_DATA,
  UPVOTES_COLUMN_DATA
];

export const MY_AUTOMATIONS_COLUMNS_DATA = [
  AUTOMATION_NAME_COLUMN_DATA,
  HIEARCHY_COLUMN_DATA,
  PHASE_COLUMN_DATA,
  STATUS_COLUMN_DATA,
  AUTOMATION_GOAL_DATA,
  PUBLISHED_DATA,
  UPVOTES_COLUMN_DATA
];

export const COLUMN_KEY_TO_FILTER_KEY = {
  process_name: "submissionType",
  process_goal: "process_goal",
  hierarchy: "hierarchy",
  department: "dept",
  phase: "phases",
  phase_status: "status",
  collaborators: "collaborators",
  idea_score: "score",
  estimated_benefit_hours: "score",
  published: "published"
};

/* We do not store nulls for this priority field in the backend, so when it's 127 it's the same as null */
export const PRIORITY_NULL_VALUE = 127;
