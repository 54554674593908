import React from "react";
import styled, { css } from "styled-components/macro";
import ButtonApolloPrimary from "../../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import Modal from "../../Modal";
import ModalTemplateOne from "../../ModalTemplateOne/ModalTemplateOne";

const ModalWithStyling = styled(Modal)`
  .Modal__content {
    padding: 24px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 1px 5px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    width: 464px;
  }
`;

const ModalTemplateOneWithStyling = styled(ModalTemplateOne)`
  color: var(--color-foreground);
  font-size: 0.875rem;

  .ModelTemplateOne__header {
    position: static;
    padding: 0;
    margin-bottom: 16px;

    .ModelTemplateOne__label {
      justify-content: left;
    }
  }
`;

const BodyText = styled.p`
  color: var(--color-foreground);
  font-size: 0.875rem;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
`;

const buttonStyling = css`
  min-height: 32px;
  min-width: 88px;
  padding: 6px 16px;
  border-radius: 3px;
`;

const ButtonMainWithStyling = styled(ButtonApolloPrimary)`
  ${buttonStyling}
`;

//@ts-ignore
const ButtonCancel = styled(ButtonApolloSecondary)`
  ${buttonStyling}
  && {
    margin-left: 16px;
  }
`;

interface Props {
  heading: string;
  children?: any;
  closeModal: () => void;
  onConfirm: () => void;
  text: string | React.ReactElement;
  cancelText: string;
  confirmText: string;
}

const ModalTemplatePipeline = ({
  heading,
  closeModal,
  text,
  cancelText,
  confirmText,
  onConfirm,
  children
}: Props) => {
  return (
    <ModalWithStyling onClose={closeModal}>
      <ModalTemplateOneWithStyling heading={heading} onClose={closeModal}>
        <BodyText>{text}</BodyText>
        {children}
        <ButtonContainer>
          <ButtonMainWithStyling onClick={onConfirm} data-testid={`${confirmText.replaceAll(' ', '_')}_btn`}>
            {confirmText}
          </ButtonMainWithStyling>
          <ButtonCancel onClick={closeModal} data-testid="cancel_btn" >{cancelText}</ButtonCancel>
        </ButtonContainer>
      </ModalTemplateOneWithStyling>
    </ModalWithStyling>
  );
};

export default ModalTemplatePipeline;
