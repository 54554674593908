module.exports = {
  HOTJAR_ENABLED: process.env.REACT_APP_HOTJAR_ENABLED,
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  AUTH_SCOPE: process.env.REACT_APP_AUTH_SCOPE,
  AUTH_RESPONSE_TYPE: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  AUTH_AUDIENCE: process.env.REACT_APP_AUTH_AUDIENCE,
  FPS_BASE_URL: process.env.REACT_APP_FPS_BASE_URL
    ? process.env.REACT_APP_FPS_BASE_URL
    : "https://" + window.location.host,
  FPS_AUTH_DOMAIN: process.env.REACT_APP_CYPRESS_TESTING
    ? process.env.REACT_APP_FPS_AUTH_DOMAIN_CYPRESS
    : process.env.REACT_APP_FPS_AUTH_DOMAIN,
  FPS_AUTH_CLIENT_ID: process.env.REACT_APP_FPS_AUTH_CLIENT_ID,
  FPS_AUTH_SCOPE: process.env.REACT_APP_FPS_AUTH_SCOPE,
  FPS_AUTH_RESPONSE_TYPE: process.env.REACT_APP_FPS_AUTH_RESPONSE_TYPE,
  API_URL: process.env.REACT_APP_API_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  APP_INSIGHTS_KEY: process.env.REACT_APP_APP_INSIGHTS_KEY,
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  LAUNCH_DARKLY_CLIENT_SIDE_ID:
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  CONTEXT_CAPTURE_LIB_KEY: process.env.REACT_APP_CONTEXT_CAPTURE_LIB_KEY,
  CONTEXT_CAPTURE_LIB_SRC: process.env.REACT_APP_CONTEXT_CAPTURE_LIB_SRC,
  PUBLIC_URL: process.env.PUBLIC_URL,
  BUILD_VERSION: process.env.REACT_APP_BUILD_VERSION,
  NEW_RELIC_ACCOUNT_ID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
  NEW_RELIC_LICENSE_KEY: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
  NEW_RELIC_APPLICATION_ID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
};
