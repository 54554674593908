import React from "react";
import { useTranslation } from "react-i18next";

import "./ComponentNewVersionTooltip.scss";
import { Link } from "react-router-dom";
import urlWithTenant from "../../utils/urlHelper";

const ComponentNewVersionTooltip = props => {
  const { t } = useTranslation("common");

  return (
    <div
      className="ComponentNewVersionTooltip"
      data-testid="ComponentNewVersionTooltipContainer"
    >
      <div className="ComponentNewVersionTooltip-label">
        {t("component_new_version_tooltip_update_available")}
      </div>
      <Link
        to={urlWithTenant(
          `/component/${props.componentSlug}?version=${props.lastestPublishedSementicVersion}`
        )}
      >
        <div>
          {t("component_new_version_tooltip_download", {
            versionName: props.lastestPublishedVersion
          })}
        </div>
      </Link>
      <div className="ComponentNewVersionTooltip-label">
        {t("component_new_version_tooltip_whats_new")}
      </div>
      <div
        className="ComponentNewVersionTooltip-text"
        data-testid="ComponentNewVersionTooltipText"
      >
        {props.tooltipContent}
      </div>
    </div>
  );
};

export default ComponentNewVersionTooltip;
