import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const automationStatusNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = automationStatusNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.AUTOMATION_STATUS_UPDATE,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const automationStatusNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.AUTOMATION_MOVED_TO_ASSESSMENT_NOT_STARTED: {
      return {
        co_options_json: {
          is_deleted: 1,
          is_inactive: 1,
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 25,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSESSMENT_LAUNCHED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Automation moved to 'Assessment' - 'Not started'"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.AUTOMATION_ARCHIVED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 39,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_ARCHIVED,
            COPhaseStatusKeys.ASSESSMENT_ARCHIVED,
            COPhaseStatusKeys.QUALIFICATION_ARCHIVED,
            COPhaseStatusKeys.ANALYSIS_ARCHIVED,
            COPhaseStatusKeys.SOLUTION_DESIGN_ARCHIVED,
            COPhaseStatusKeys.DEVELOPMENT_ARCHIVED,
            COPhaseStatusKeys.TESTING_ARCHIVED,
            COPhaseStatusKeys.LIVE_ARCHIVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.AUTOMATION_ARCHIVED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Automation was archived"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              ", with the question / comment " +
              COActionReplacementStrings.PROCESS_ARCHIVED_REASON +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PHASE_STATUS_CHANGED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 56,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.PHASE_STATUS_CHANGED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Change of phase and status"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
