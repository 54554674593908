import {
  COControlInterface,
  COControlOptionListItem,
  COQuestionTemplateInterface,
  COSortType
} from "../interfaces/co-interfaces";
import {
  COComponentType,
  COExternalAnswerSources,
  COTypes,
  CORichTextOptions,
  COCalculatedValueTypes,
  COConditionSlugs
} from "../constants/co-constants";
import { questionAnswerOptionTemplateForSlug } from "./co-question-answer-options.template";
import {
  COUnitSlugs,
  unitControl,
  unitOptions
} from "./elements/controls/co-units.template";
import {
  PATH,
  PATH_OPERATORS,
  PATH_ROUTE
} from "../constants/co-path.constants";

import {
  descriptionQuestionTemplateMeta,
  prefillOptionSingleChoiceQuestionTemplateMeta,
  prefillOptionMultipleChoiceQuestionTemplateMeta,
  prefillQuestionTemplateMeta,
  titleApplicationQuestionTemplateMeta,
  titleProcessPropertyQuestionTemplateMeta,
  titleQuestionTemplateMeta,
  titleTabularQuestionTemplateMeta,
  variableQuestionTemplateMeta
} from "./elements/meta/co-meta.template";
import {
  applicationEquationQuestionControls,
  decimalNumberQuestionControls,
  defaultQuestionEquationAnswerControl,
  equationTypeQuestionControls,
  kpuUsedInQuestionControl,
  maxSelectableAnswersQuestionControls,
  sensitiveInformationDropdownControls,
  questionVisibilityControls,
  templateQuestionControl,
  minimumCategoriesControl,
  showInTableFormatControls,
  settingsOptionsControls,
  defaultRefQuestionAnswerControl
} from "./elements/controls/co-controls.template";
import {
  answerInputRangeValidators,
  answerInputRequiredQuestionValidators,
  answerSelectionRequiredQuestionValidators,
  applicationRequiredQuestionValidators,
  descriptionQuestionValidators,
  maxMinAnswersOptionsQuestionValidators,
  maxSelectedAnswersQuestionValidators,
  numberAnswerOptionMinMaxValidator,
  roundToDecimalTransformQuestionValidators,
  titleQuestionValidators,
  variableLengthQuestionValidators,
  prefillMaxAnswerLengthValidators,
  minimumCategoriesSelectionValidator,
  answerRequiredExternalAnswerSourceValidators,
  numberQuestionRequiresPositiveNumberAnswerValidators,
  prefillAnswerRequiresPositiveNumberValidators,
  prefillAnswerCheckDecimalPlacesValidators,
  tabularQuestionValidators,
  maxAnswerLengthValidators
} from "./elements/validators/co-validators.template";
import { advancedKPITemplates, KPITemplates } from "./co-kpis.template";
import { updateCustomizationQuestionValidators } from "./elements/validators/co-submission-update-validators.template";
import { COPipelineFilterOptionTemplateSlugs } from "./elements/pipeline/co-pipeline.template-filters";
import { templateQuestionConditions } from "./elements/conditions/co-conditions";
import { mergeTemplates } from "./co-template.template";
import {
  NonStandardQuestionSlugs,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../constants/co-question.constants";
import { CODefaultEquations } from "../constants/co-calculation.constants";
import { branchingQuestionControlMeta } from "./elements/controls/co-question-branching-controls.template";

export const questionTemplateForSlug = (
  template_slug: StandardQuestionSlugs | string | undefined
): COQuestionTemplateInterface | undefined => {
  switch (template_slug) {
    case StandardQuestionSlugs.STANDARD_TEXT:
      return defaultTextQuestion();
    case StandardQuestionSlugs.MULTIPLE_CHOICE:
      return defaultMultipleChoiceQuestion();
    case StandardQuestionSlugs.SINGLE_CHOICE:
      return defaultSingleChoiceQuestion();
    case StandardQuestionSlugs.SINGLE_CHOICE_NESTED:
      return defaultNestedSingleChoiceQuestion();
    case StandardQuestionSlugs.PERCENTAGE:
      return defaultPCTQuestion();
    case StandardQuestionSlugs.NUMBER:
      return defaultNumberQuestion();
    case StandardQuestionSlugs.APPLICATIONS_COUNT:
      return defaultApplicationsQuestion();
    case StandardQuestionSlugs.PROCESS_PROPERTY:
      return defaultProcessPropertyQuestion();
    case StandardQuestionSlugs.USER:
      return defaultUserQuestion();
    case StandardQuestionSlugs.MEDIA:
      return defaultMediaQuestion();
    case StandardQuestionSlugs.AUTOMATIONS:
      return defaultAutomationsQuestion();
    case StandardQuestionSlugs.DOCUMENT:
      return defaultDocumentQuestion();
    case StandardQuestionSlugs.COMPONENTS:
      return defaultComponentsQuestion();
    case StandardQuestionSlugs.DATE:
      return defaultDateQuestion();
    case StandardQuestionSlugs.COLLABORATORS:
      return defaultCollaboratorsQuestion();
    case StandardQuestionSlugs.PROCESS_LINK:
      return defaultProcessLinkQuestion();
    case StandardQuestionSlugs.ORCHESTRATOR_PACKAGE_MAPPING:
      return defaultOrchestratorPackageMappingQuestion();
  }

  // first override with slug
  let slugTemplate: COQuestionTemplateInterface = {
    co_question_template_slug: template_slug,
    co_question_json: {
      co_question_template_slug: template_slug
    }
  };

  // can override any property specifically here at the template level - to minimize duplication
  switch (template_slug) {
    case StandardQuestionSlugs.RANGED_NUMBER: {
      let template = defaultNumberQuestion();
      return mergeTemplates([
        defaultNumberQuestion(),
        slugTemplate,
        {
          co_validators_json: [
            ...(template.co_validators_json || []),
            ...answerInputRangeValidators()
          ],
          co_controls_json: [
            ...(template.co_controls_json || [])
            //TODO BUILD CONTROLS
          ],
          co_options_json: {
            ...{ max_value: 100, min_value: 0 }
          }
        }
      ]);
    }
    case StandardQuestionSlugs.RICH_TEXT: {
      let template = defaultTextQuestion();
      return mergeTemplates([
        template,
        slugTemplate,
        {
          co_question_template_answer_option_template_slugs:
            StandardQuestionAnswerOptionSlugs.STANDARD_RICH_TEXT_OPTION,
          co_question_template_meta_json: {
            title: {
              value: "Free text with formatting",
              value_localization_key: "co_questions_question_type_rich_text"
            }
          },
          co_options_json: {
            rich_text_controls: CORichTextOptions.default,
            condition_overrides: [
              { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
              ...templateQuestionConditions()
            ]
          },
          co_question_json: {
            co_question_answer_options: [
              questionAnswerOptionTemplateForSlug(
                StandardQuestionAnswerOptionSlugs.STANDARD_RICH_TEXT_OPTION
              )?.co_question_answer_option_json || {}
            ],
            condition_overrides: [],
            co_question_options_json: {
              max_answer_length: 10000
            }
          },
          co_controls_json: [...settingsControls(), ...viewSettingsControls()]
        }
      ]);
    }
    case NonStandardQuestionSlugs.OVR_AUTOMATION_NAME: {
      return mergeTemplates([
        defaultTextQuestion(),
        slugTemplate,
        {
          co_meta_json: {
            ...branchingQuestionControlMeta(),
            ...titleQuestionTemplateMeta(),
            ...titleTabularQuestionTemplateMeta(),
            ...descriptionQuestionTemplateMeta({ is_editable: false }),
            ...variableQuestionTemplateMeta({ is_editable: false }),
            ...prefillQuestionTemplateMeta({ is_editable: false })
          },
          co_controls_json: [
            ...settingsControls({
              settingsOptionsDisabled: 1,
              includeTemplateControls: false
            }),
            ...viewSettingsControls({
              visibilityControlsDisabled: 1,
              sensitiveInformationFieldDisabled: 1,
              showInTableFormatControlsDisabled: 1
            })
          ],
          co_question_json: {
            co_question_options_json: {
              max_answer_length: 100
            }
          }
        }
      ]);
    }
    case NonStandardQuestionSlugs.OVR_CATEGORIES: {
      return mergeTemplates([
        defaultExternalDataQuestion(),
        slugTemplate,
        {
          co_validators_json: [...minimumCategoriesSelectionValidator()],
          co_controls_json: [
            ...settingsControls({
              includeRequiredField: false,
              includeCommentsField: false,
              includePrefillQuestionField: false,
              includeTemplateControls: false
            }),
            ...viewSettingsControls({
              visibilityControlsDisabled: 1,
              includeSensitiveInformationField: false,
              includeShowInTableFormatField: false
            }),
            ...minimumCategoriesControl()
          ],
          co_meta_json: {
            variable: {
              is_editable: false
            }
          },
          co_options_json: {
            component_type: COComponentType.QUESTION_CATEGORIES,
            external_answer_source: COExternalAnswerSources.CATEGORIES, // but could be overridden to be anything
            calculated_value_type: COCalculatedValueTypes.CATEGORY,
            excluded_from_branching_paths_selection: 1
          },
          co_question_json: {
            co_question_template_slug: NonStandardQuestionSlugs.OVR_CATEGORIES
          }
        }
      ]);
    }
    case NonStandardQuestionSlugs.OVR_TAGS: {
      return mergeTemplates([
        defaultExternalDataQuestion(),
        slugTemplate,
        {
          co_controls_json: [
            ...settingsControls({
              includeCommentsField: false,
              includePrefillQuestionField: false,
              includeTemplateControls: false
            }),
            ...viewSettingsControls({
              includeShowInTableFormatField: false,
              includeHideFromPipelineVisibilityOption: false
            })
          ],
          co_options_json: {
            component_type: COComponentType.QUESTION_TAGS,
            external_answer_source: COExternalAnswerSources.TAGS,
            calculated_value_type: COCalculatedValueTypes.TAG_ARRAY
          },
          co_question_json: {
            co_question_template_slug: NonStandardQuestionSlugs.OVR_TAGS
          }
        }
      ]);
    }
    case StandardQuestionSlugs.LINK: {
      return mergeTemplates([
        defaultExternalDataQuestion(),
        slugTemplate,
        {
          co_options_json: {
            component_type: COComponentType.LINK,
            calculated_value_type: COCalculatedValueTypes.URL
          },
          co_question_json: {
            co_question_template_slug: StandardQuestionSlugs.LINK
          }
        }
      ]);
    }
    case NonStandardQuestionSlugs.Q1_DOCUMENTS: {
      return mergeTemplates([
        defaultMultipleChoiceQuestion(),
        slugTemplate,
        {
          co_question_template_slug: StandardQuestionSlugs.MULTIPLE_CHOICE,
          co_options_json: {
            no_tabular_support: 1,
            condition_overrides: [
              { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
              ...templateQuestionConditions()
            ]
          },
          co_controls_json: [
            ...settingsControls({
              includeCanImpactKPIField: true,
              includeTemplateControls: false
            }),
            ...viewSettingsControls(),
            ...maxSelectableAnswersQuestionControls(),
            ...equationTypeQuestionControls(),
            ...kpuUsedInQuestionControl()
          ]
        }
      ]);
    }
  }
  // ok so we couldn't find it - let's see if it's custom
};

const settingsControls = ({
  settingsOptionsDisabled = 0,
  includeRequiredField = true,
  includeCanImpactKPIField = false,
  includeCommentsField = true,
  includePrefillQuestionField = true,
  includeTemplateControls = true,
  advancedTemplateControlsOnly = false
}: {
  settingsOptionsDisabled?: number;
  includeRequiredField?: boolean;
  includeCanImpactKPIField?: boolean;
  includeCommentsField?: boolean;
  includePrefillQuestionField?: boolean;
  includeTemplateControls?: boolean;
  advancedTemplateControlsOnly?: boolean;
} = {}): COControlInterface[] => {
  const controls: COControlInterface[] = [];
  if (includeTemplateControls) {
    controls.push(
      ...templateQuestionControl({
        advanced_editor_only: advancedTemplateControlsOnly
      })
    );
  }
  controls.push(
    ...settingsOptionsControls({
      is_disabled: settingsOptionsDisabled,
      includeRequiredField,
      includeCanImpactKPIField,
      includeCommentsField,
      includePrefillQuestionField
    })
  );
  return controls;
};

const viewSettingsControls = ({
  visibilityControlsDisabled = 0,
  includeHideBeforeLiveVisibilityOption = true,
  includeHideFromPipelineVisibilityOption = true,
  sensitiveInformationFieldDisabled = 0,
  includeSensitiveInformationField = true,
  showInTableFormatControlsDisabled = 0,
  includeShowInTableFormatField = true
}: {
  visibilityControlsDisabled?: number;
  includeHideBeforeLiveVisibilityOption?: boolean;
  includeHideFromPipelineVisibilityOption?: boolean;
  sensitiveInformationFieldDisabled?: number;
  includeSensitiveInformationField?: boolean;
  showInTableFormatControlsDisabled?: number;
  includeShowInTableFormatField?: boolean;
} = {}): COControlInterface[] => {
  const controls: COControlInterface[] = [
    ...questionVisibilityControls({
      is_disabled: visibilityControlsDisabled,
      includeHideFromPipelineVisibilityOption: includeHideFromPipelineVisibilityOption,
      includeHideBeforeLiveVisibilityOption: includeHideBeforeLiveVisibilityOption
    })
  ];
  if (includeSensitiveInformationField) {
    controls.push(
      ...sensitiveInformationDropdownControls({
        is_disabled: sensitiveInformationFieldDisabled
      })
    );
  }
  if (includeShowInTableFormatField) {
    controls.push(
      ...showInTableFormatControls({
        is_disabled: showInTableFormatControlsDisabled
      })
    );
  }
  return controls;
};

// I don't think this big list is going to be used anymore
export const questionTemplates = (): COQuestionTemplateInterface[] => {
  let questionTemplateArray = [
    defaultTextQuestion(),
    questionTemplateForSlug(StandardQuestionSlugs.RICH_TEXT) || {},
    defaultNumberQuestion(),
    questionTemplateForSlug(StandardQuestionSlugs.RANGED_NUMBER) || {},
    defaultPCTQuestion(),
    defaultSingleChoiceQuestion(),
    defaultNestedSingleChoiceQuestion(),
    defaultMultipleChoiceQuestion(),
    defaultApplicationsQuestion(),
    defaultProcessPropertyQuestion(),
    defaultUserQuestion(),
    defaultMediaQuestion(),
    defaultAutomationsQuestion(),
    questionTemplateForSlug(NonStandardQuestionSlugs.OVR_TAGS) || {},
    defaultDocumentQuestion(),
    defaultComponentsQuestion(),
    questionTemplateForSlug(StandardQuestionSlugs.LINK) || {},
    questionTemplateForSlug(NonStandardQuestionSlugs.OVR_CATEGORIES) || {},
    defaultDateQuestion(),
    defaultOrchestratorPackageMappingQuestion()
  ];
  return questionTemplateArray;
};

export const standardQuestionTemplates = (): COQuestionTemplateInterface[] => {
  let standardQuestionTemplateArray = [
    defaultTextQuestion(),
    questionTemplateForSlug(StandardQuestionSlugs.RICH_TEXT) || {},
    defaultNumberQuestion(),
    defaultPCTQuestion(),
    defaultSingleChoiceQuestion(),
    defaultMultipleChoiceQuestion(),
    defaultDateQuestion(),
    defaultOrchestratorPackageMappingQuestion()
  ];
  return standardQuestionTemplateArray;
};

export const kpiTemplateAdvancedControlOptions = (): COControlOptionListItem[] => {
  return advancedKPITemplates().map(template => {
    return {
      dont_resolve: true,
      key: template.co_question_template_slug,
      value: { ...template, slug: template.co_question_template_slug },
      meta: {
        value: template.co_question_template_meta_json?.title?.value,
        value_localization_key:
          template.co_question_template_meta_json?.title?.value_localization_key
      }
    };
  });
};
export const kpiTemplateControlOptions = (): COControlOptionListItem[] => {
  return KPITemplates().map(template => {
    return {
      dont_resolve: true,
      key: template.co_question_template_slug,
      value: template.co_question_template_slug, // moved to template slug from { ...template, slug: template.co_question_template_slug },
      meta: {
        value: template.co_question_template_meta_json?.title?.value,
        value_localization_key:
          template.co_question_template_meta_json?.title?.value_localization_key
      }
    };
  });
};

export const questionTemplateControlOptions = (
  includeAdvanced: boolean = false
): COControlOptionListItem[] => {
  return (includeAdvanced
    ? questionTemplates()
    : standardQuestionTemplates()
  ).map(template => {
    return {
      dont_resolve: true,
      key: template.co_question_template_slug,
      value: template.co_question_template_slug, // moved to template slug from { ...template, slug: template.co_question_template_slug },
      meta: {
        value: template.co_question_template_meta_json?.title?.value,
        value_localization_key:
          template.co_question_template_meta_json?.title?.value_localization_key
      }
    };
  });
};

export const defaultTextQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.STANDARD_TEXT,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_TEXT_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Free text",
        value_localization_key: "co_questions_question_type_free_text"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta(),
      ...prefillQuestionTemplateMeta()
    },
    co_controls_json: [...settingsControls(), ...viewSettingsControls()],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.ALPHABETICAL
      },
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...answerInputRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...answerInputRequiredQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...prefillMaxAnswerLengthValidators(),
      ...tabularQuestionValidators(),
      ...maxAnswerLengthValidators()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_equation: CODefaultEquations.TEXT,
      co_question_template_slug: StandardQuestionSlugs.STANDARD_TEXT,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_variable_name: "",
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        max_answer_length: 500
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_TEXT_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultNumberQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.NUMBER,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_NUMBER_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Number",
        value_localization_key: "co_questions_question_type_number"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta(),
      ...prefillQuestionTemplateMeta()
    },
    co_controls_json: [
      ...decimalNumberQuestionControls(),
      ...settingsControls(),
      ...viewSettingsControls(),
      ...kpuUsedInQuestionControl(),
      unitControl({
        unitOptions: unitOptions().filter(
          unit => unit.slug !== COUnitSlugs.PERCENT && !unit.is_custom
        )
      }),
      ...defaultQuestionEquationAnswerControl()
    ],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NUMERIC
      },
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...answerInputRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...roundToDecimalTransformQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...prefillMaxAnswerLengthValidators(),
      ...numberQuestionRequiresPositiveNumberAnswerValidators(),
      ...prefillAnswerRequiresPositiveNumberValidators(),
      ...prefillAnswerCheckDecimalPlacesValidators()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.NUMBER,
      co_equation: CODefaultEquations.ANSWER,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_variable_name: "",
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE },
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        rounding_decimals: "1",
        unit: PATH({
          route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
        }),
        max_answer_length: 500
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_NUMBER_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

//let's just edit what we actually need
export const defaultPCTQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Percentage",
        value_localization_key: "co_questions_question_type_percentage"
      }
    },
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_PERCENT_OPTION,
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta(),
      ...prefillQuestionTemplateMeta()
    },
    co_controls_json: [
      ...settingsControls(),
      ...viewSettingsControls(),
      ...kpuUsedInQuestionControl()
    ],
    co_options_json: {
      pipeline_filter_options_slug:
        COPipelineFilterOptionTemplateSlugs.COPercentFilter,
      pipeline_sort_options: {
        sort_type: COSortType.ALPHABETICAL
      },
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...answerInputRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...roundToDecimalTransformQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...prefillMaxAnswerLengthValidators(),
      ...numberQuestionRequiresPositiveNumberAnswerValidators(),
      ...prefillAnswerRequiresPositiveNumberValidators(),
      ...prefillAnswerCheckDecimalPlacesValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
      co_equation: CODefaultEquations.ANSWER,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_variable_name: "",
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE },
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        rounding_decimals: "2",
        unit: PATH({
          route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.PERCENT
        }),
        max_answer_length: 500
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_PERCENT_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultSingleChoiceQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Single choice",
        value_localization_key: "co_questions_question_type_single_choice"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta(),
      ...prefillOptionSingleChoiceQuestionTemplateMeta()
    },
    co_controls_json: [
      ...settingsControls({ includeCanImpactKPIField: true }),
      ...viewSettingsControls(),
      ...kpuUsedInQuestionControl()
    ],
    co_options_json: {
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...maxSelectedAnswersQuestionValidators(),
      ...maxMinAnswersOptionsQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...answerSelectionRequiredQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
      co_question_co_type: COTypes.QUESTION,
      co_variable_name: "",
      co_equation: CODefaultEquations.ANSWER,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE },
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.DOESNT_SUPPORT_BRANCHING_IN_TABULAR_SECTION }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        min_question_answer_options: 2,
        max_question_answer_options: 20,
        supports_branching_paths: 1
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultNestedSingleChoiceQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE_NESTED,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Single choice (Nested)",
        value_localization_key:
          "co_questions_question_type_single_choice_nested"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      title: {
        label: "Nested Question Title",
        tooltip:
          "This question will be shown inline with the previous question. The title is for reference only",
        value: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
        }),
        is_editable: true,
        options: {
          component_type: COComponentType.META_EDITABLE_H2_TEXT
        },
        display_context: {
          assessment_customize: 1
        }
      },
      ...variableQuestionTemplateMeta()
    },
    co_controls_json: [
      ...settingsControls({
        includeCanImpactKPIField: true,
        includeCommentsField: false,
        includePrefillQuestionField: false,
        advancedTemplateControlsOnly: true
      }),
      ...viewSettingsControls({ includeShowInTableFormatField: false }),
      ...kpuUsedInQuestionControl()
    ],
    co_options_json: {
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...maxSelectedAnswersQuestionValidators(),
      ...maxMinAnswersOptionsQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...answerSelectionRequiredQuestionValidators(),
      ...updateCustomizationQuestionValidators()
    ],
    co_question_json: {
      co_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE_NESTED,
      co_question_co_type: COTypes.QUESTION,
      co_variable_name: "",
      co_equation: CODefaultEquations.ANSWER,
      co_question_meta_json: {
        title: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.DOESNT_SUPPORT_BRANCHING_IN_TABULAR_SECTION }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        min_question_answer_options: 1,
        max_question_answer_options: 20,
        supports_branching_paths: 1
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultMultipleChoiceQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_slug: StandardQuestionSlugs.MULTIPLE_CHOICE,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Multiple choice",
        value_localization_key: "co_questions_question_type_multiple_choice"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta(),
      ...prefillOptionMultipleChoiceQuestionTemplateMeta()
    },
    co_controls_json: [
      ...settingsControls({ includeCanImpactKPIField: true }),
      ...viewSettingsControls(),
      ...maxSelectableAnswersQuestionControls(),
      ...equationTypeQuestionControls(),
      ...kpuUsedInQuestionControl()
    ],
    co_options_json: {
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...maxSelectedAnswersQuestionValidators(),
      ...maxMinAnswersOptionsQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...answerSelectionRequiredQuestionValidators(),
      ...titleQuestionValidators(),
      ...tabularQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators()
    ],
    co_question_json: {
      co_question_template_slug: StandardQuestionSlugs.MULTIPLE_CHOICE,
      co_question_co_type: COTypes.QUESTION,
      co_variable_name: "",
      co_equation: CODefaultEquations.SUM_OF_ANSWERS,
      co_display_equation: CODefaultEquations.SUM_OF_ANSWERS,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE },
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.DOESNT_SUPPORT_BRANCHING_IN_TABULAR_SECTION }
        ],
        is_disabled: 0,
        min_question_answer_options: 2,
        max_question_answer_options: 20,
        min_selectable_answers: 1,
        max_selectable_answers: 4,
        supports_branching_paths: 1
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultApplicationsQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.APPLICATIONS_COUNT,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Applications",
        value_localization_key: "co_questions_question_type_applications"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleApplicationQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_validators_json: [
      ...applicationRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_options_json: {
      component_type: COComponentType.QUESTION_APPLICATIONS,
      external_answer_source: COExternalAnswerSources.APPLICATIONS,
      calculated_value_type: COCalculatedValueTypes.APPLICATION_ARRAY,
      prevent_deleting: 1,
      is_read_only: 1,
      no_tabular_support: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_controls_json: [
      ...settingsControls({
        includeCommentsField: false,
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls({
        includeSensitiveInformationField: false,
        includeShowInTableFormatField: false
      }),
      ...applicationEquationQuestionControls(),
      ...kpuUsedInQuestionControl()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.APPLICATIONS_COUNT,
      co_equation: CODefaultEquations.COUNT_APPLICATIONS,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [],
        is_disabled: 0
      },
      co_question_answer_options: [
        // questionAnswerOptionTemplateForSlug( - we don't have answer options here - this is actually a "COUNT" of the applications, not the real association
        //   StandardQuestionAnswerOptionSlugs.STANDARD_NUMBER_OPTION
        // )?.co_question_answer_option_json || {}
      ]
    }
  };
};

// this is to handle automation auto generated id mostly
export const defaultProcessPropertyQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_slug: StandardQuestionSlugs.PROCESS_PROPERTY,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.PROCESS_PROPERTY_CHOICE_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Process property",
        value_localization_key: "co_questions_question_type_process_property"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleProcessPropertyQuestionTemplateMeta()
    },
    co_validators_json: [
      ...titleQuestionValidators(),
      ...tabularQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_options_json: {
      no_tabular_support: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_controls_json: [...questionVisibilityControls()],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.PROCESS_PROPERTY,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        external_answer_source: COExternalAnswerSources.AUTO_GENERATED_ID,
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        is_disabled: 0
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.PROCESS_PROPERTY_CHOICE_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultUserQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.USER,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.USER_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "User",
        value_localization_key: "co_questions_question_type_user"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta({ is_editable: false }),
      ...titleTabularQuestionTemplateMeta({ is_editable: false }),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta({ is_editable: false })
    },
    co_validators_json: [
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...answerRequiredExternalAnswerSourceValidators(),
      ...tabularQuestionValidators()
    ],
    co_options_json: {
      component_type: COComponentType.QUESTION_USER_PICKER,
      no_tabular_support: 1,
      external_answer_source: COExternalAnswerSources.PROCESS_OWNER, // but could be overridden to be anything
      condition_overrides: [...templateQuestionConditions()]
    },
    co_controls_json: [
      ...settingsControls({
        includeCommentsField: false,
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls({
        includeHideBeforeLiveVisibilityOption: false,
        includeSensitiveInformationField: false,
        includeShowInTableFormatField: false
      })
    ],
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.USER,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [],
        is_disabled: 0
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.USER_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultDocumentQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.DOCUMENT,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Document",
        value_localization_key: "co_questions_question_type_document"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta()
    },
    co_validators_json: [
      ...titleQuestionValidators(),
      ...numberAnswerOptionMinMaxValidator(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_options_json: {
      no_tabular_support: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_controls_json: [
      ...settingsControls({
        includeCommentsField: false,
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls({
        includeHideBeforeLiveVisibilityOption: false,
        includeHideFromPipelineVisibilityOption: false,
        includeShowInTableFormatField: false
      }),
      ...maxSelectableAnswersQuestionControls()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.DOCUMENT,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        is_disabled: 0
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

// this is to handle components
export const defaultComponentsQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.COMPONENTS,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Components",
        value_localization_key: ""
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_validators_json: [
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_options_json: {
      component_type: COComponentType.QUESTION_COMPONENTS,
      external_answer_source: COExternalAnswerSources.COMPONENTS, // but could be overridden to be anything
      calculated_value_type: COCalculatedValueTypes.COMPONENT_ARRAY,
      no_tabular_support: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_controls_json: [
      ...settingsControls({
        includeCommentsField: false,
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls({
        includeHideBeforeLiveVisibilityOption: false,
        includeHideFromPipelineVisibilityOption: false,
        includeSensitiveInformationField: false,
        includeShowInTableFormatField: false
      })
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.COMPONENTS,
      co_equation: CODefaultEquations.TEXT,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [],
        is_disabled: 0
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

// this is to handle media
export const defaultMediaQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.MEDIA,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Media",
        value_localization_key: ""
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_validators_json: [
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_options_json: {
      component_type: COComponentType.QUESTION_MEDIA,
      external_answer_source: COExternalAnswerSources.MEDIA, // but could be overridden to be anything
      calculated_value_type: COCalculatedValueTypes.FILE_ARRAY,
      no_tabular_support: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_controls_json: [
      ...settingsControls({
        includeCommentsField: false,
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls({
        includeHideFromPipelineVisibilityOption: false,
        includeShowInTableFormatField: false
      })
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.MEDIA,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        is_disabled: 0,
        condition_overrides: []
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

// this is to handle automations
export const defaultAutomationsQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.AUTOMATIONS,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Automations",
        value_localization_key: "co_questions_question_type_automations"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta()
    },
    co_validators_json: [
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_options_json: {
      component_type: COComponentType.QUESTION_AUTOMATIONS,
      external_answer_source: COExternalAnswerSources.SIMILAR_AUTOMATIONS, // but could be overridden to be anything
      calculated_value_type: COCalculatedValueTypes.PROCESS_ARRAY,
      no_tabular_support: 1,
      prevent_deleting: 1,
      is_read_only: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_controls_json: [
      ...questionVisibilityControls({
        includeHideFromPipelineVisibilityOption: false
      })
    ],
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.AUTOMATIONS,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        is_disabled: 0,
        condition_overrides: []
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

// default external data question
export const defaultExternalDataQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "External data",
        value_localization_key: "co_questions_question_type_external_data"
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_validators_json: [
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_controls_json: [
      ...viewSettingsControls({
        includeSensitiveInformationField: false,
        includeShowInTableFormatField: false
      })
    ],
    co_options_json: {
      no_tabular_support: 1,
      condition_overrides: [...templateQuestionConditions()]
    },
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [],
        is_disabled: 0
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultDateQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.DATE,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_DATE_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Date",
        value_localization_key: "co_ca_date_question"
      }
    },
    co_meta_json: {
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta(),
      ...branchingQuestionControlMeta()
    },
    co_controls_json: [
      ...settingsControls({ includePrefillQuestionField: false }),
      ...viewSettingsControls()
    ],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NONE
      },
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...answerInputRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_equation: CODefaultEquations.ANSWER,
      co_question_template_slug: StandardQuestionSlugs.DATE,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_variable_name: "",
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        min_question_answer_options: 1,
        max_question_answer_options: 1,
        calculated_value_type: COCalculatedValueTypes.EPOCH
      },

      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_DATE_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultCollaboratorsQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.COLLABORATORS,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Collaborators",
        value_localization_key: ""
      }
    },
    co_meta_json: {
      ...branchingQuestionControlMeta(),
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_validators_json: [
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_calculation_json: {
      input_is_value: 1,
      is_auto_selected: 1
    },
    co_options_json: {
      component_type: COComponentType.QUESTION_COLLABORATORS,
      external_answer_source: COExternalAnswerSources.COLLABORATORS, // but could be overridden to be anything
      calculated_value_type: COCalculatedValueTypes.USER_ARRAY,
      condition_overrides: [
        ...templateQuestionConditions(),
        { slug: COConditionSlugs.HIDE_FOR_EDIT }
      ]
    },
    co_controls_json: [
      ...settingsControls({
        includeCommentsField: false,
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls({
        includeHideFromPipelineVisibilityOption: false,
        includeShowInTableFormatField: false
      })
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_question_template_slug: StandardQuestionSlugs.COLLABORATORS,
      co_equation: CODefaultEquations.EXTERNAL,
      co_variable_name: "",
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_question_options_json: {
        is_disabled: 0,
        condition_overrides: []
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultProcessLinkQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug: StandardQuestionSlugs.PROCESS_LINK,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.PROCESS_LINK_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Process Link",
        value_localization_key: "co_ca_process_link_question"
      }
    },
    co_meta_json: {
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta({
        is_editable: false
      }),
      ...branchingQuestionControlMeta()
    },
    co_controls_json: [
      ...settingsControls({
        includePrefillQuestionField: false,
        includeTemplateControls: false
      }),
      ...viewSettingsControls()
    ],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NONE
      },
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...answerInputRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_equation: CODefaultEquations.ANSWER,
      co_question_template_slug: StandardQuestionSlugs.PROCESS_LINK,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_variable_name: "",
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        is_disabled: 0,
        calculated_value_type: COCalculatedValueTypes.PROCESS
      },

      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.PROCESS_LINK_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};

export const defaultOrchestratorPackageMappingQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug:
      StandardQuestionSlugs.ORCHESTRATOR_PACKAGE_MAPPING,
    co_question_template_co_type: COTypes.QUESTION,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.ORCHESTRATOR_PACKAGE_MAPPING_OPTION,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_EDITABLE_H2_TEXT },
        value: "Orchestrator Package",
        value_localization_key: "co_roi_orchestrator_package_mapping_question"
      }
    },
    co_meta_json: {
      ...titleQuestionTemplateMeta(),
      ...titleTabularQuestionTemplateMeta(),
      ...descriptionQuestionTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_controls_json: [
      ...settingsControls({ includePrefillQuestionField: false }),
      ...viewSettingsControls()
    ],
    co_options_json: {
      pipeline_sort_options: {
        sort_type: COSortType.NONE
      },
      condition_overrides: [...templateQuestionConditions()]
    },
    co_validators_json: [
      ...answerInputRequiredQuestionValidators(),
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...descriptionQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_question_json: {
      co_question_co_type: COTypes.QUESTION,
      co_equation: CODefaultEquations.ANSWER,
      co_question_template_slug:
        StandardQuestionSlugs.ORCHESTRATOR_PACKAGE_MAPPING,
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        },
        description: {
          value: ""
        },
        prefill: {
          value: ""
        }
      },
      co_question_position_json: {
        sort_order: 0
      },
      co_variable_name: "",
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        is_disabled: 0,
        max_selectable_answers: 1,
        min_selectable_answers: 1,
        min_question_answer_options: 1,
        max_question_answer_options: 1,
        calculated_value_type: COCalculatedValueTypes.ORCHESTRATOR_PACKAGE
      },

      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.ORCHESTRATOR_PACKAGE_MAPPING_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};
