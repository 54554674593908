export const isValidNumber = str => {
  const currentNum = parseFloat(str);
  return !isNaN(currentNum);
};

export const numberWithCommas = (num, roundCount) => {
  if ((!num || typeof num != "number") && num !== 0) {
    return "";
  }

  if (!!roundCount && roundCount > 0) {
    num = num.toFixed(roundCount);
  }
  var parts = num.toString().split(".");
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
};

export const buildNumberWithCurrency = (number, currency = "") => {
  if (!number || typeof number != "number") {
    return "";
  }
  number = Math.round(number);
  if (number < 100000) {
    return currency + numberWithCommas(number);
  }
  return currency + shortenNumber(number, 2);
};

const shortenNumber = (number, roundCount = 0) => {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(roundCount) + "b";
  }
  if (number >= 1000000) {
    return (number / 1000000).toFixed(roundCount) + "m";
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(roundCount) + "k";
  }
  return number;
};
