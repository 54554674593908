import React from "react";
import {
  FOCUSABLE_ELEMENTS_SELECTOR,
  CLOSE_DROPDOWN_EVENT
} from "../../constants/accessibilityConstants";

export const focusOnFirstFocusableElement = (
  containerElement?: HTMLElement | null,
  selectorUsed: string = FOCUSABLE_ELEMENTS_SELECTOR
) => {
  if (containerElement) {
    const focusableElements = containerElement?.querySelectorAll(selectorUsed);
    const firstFocusableElement = focusableElements?.[0] as
      | HTMLElement
      | undefined;
    firstFocusableElement?.focus();

    return firstFocusableElement || null;
  }

  return null;
};

// Get a list of all focusable elements in the provided 'containerElement'
const getFocusableElement = (
  containerElement?: HTMLElement | null
): NodeListOf<Element> | undefined => {
  return containerElement?.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR);
};

// Get first focusable element from the provided list of 'focusableElements'
const getFirstFocusableElement = (
  focusableElements: NodeListOf<Element> | undefined
): Element | undefined => {
  return focusableElements?.[0];
};

// Focus on first focusable element in the provided 'containerElement'
export const focusOnFirstFocusableElementInContainer = (
  containerElement?: HTMLElement | null
) => {
  const focusableElements = getFocusableElement(containerElement);
  const firstFocusableElement = getFirstFocusableElement(focusableElements);
  if (firstFocusableElement) {
    (firstFocusableElement as HTMLElement)?.focus();
  }
};

//implement "keyboard trap" useful for when we where we want to
//maintain focus inside a container when tabbing, like a modal
export const maintainFocusInContainer = (
  e: React.KeyboardEvent<HTMLElement>,
  containerElement?: HTMLElement | null
) => {
  if (!containerElement) {
    return;
  }

  const focusableElements = getFocusableElement(containerElement);
  const firstFocusableElement = getFirstFocusableElement(focusableElements);
  const lastFocusableElement =
    focusableElements?.[focusableElements.length - 1];

  //tab + shift key clicked together
  if (e.shiftKey) {
    if (
      lastFocusableElement &&
      document.activeElement === firstFocusableElement
    ) {
      (lastFocusableElement as HTMLElement)?.focus();
      e.preventDefault();
    }
  } else {
    //tab key is pressed
    if (
      firstFocusableElement &&
      document.activeElement === lastFocusableElement
    ) {
      (firstFocusableElement as HTMLElement)?.focus();
      e.preventDefault();
    }
  }
};

export const enableArrowNavigation = (
  e: React.KeyboardEvent<HTMLElement>,
  containerElement?: HTMLElement | null
) => {
  if (!containerElement) {
    return;
  }

  const currentFocusedElement = document.activeElement;

  //have to check if we are navigating inside the container element
  //prevents scenarios where we are controlling focus
  //outside the scope of the container
  if (!containerElement.contains(currentFocusedElement)) {
    return;
  }

  const focusableElements = containerElement?.querySelectorAll(
    FOCUSABLE_ELEMENTS_SELECTOR
  );

  const firstFocusableElement = focusableElements?.[0];
  const lastFocusableElement =
    focusableElements?.[focusableElements.length - 1];

  console.log({
    currentFocusedElement,
    firstFocusableElement,
    lastFocusableElement
  });

  let nextFocusTarget;

  if (e.key === "ArrowUp" || e.keyCode === 38) {
    e.preventDefault();
    nextFocusTarget =
      firstFocusableElement && currentFocusedElement === firstFocusableElement
        ? lastFocusableElement
        : currentFocusedElement?.previousSibling;
  } else if (e.key === "ArrowDown" || e.keyCode === 40) {
    e.preventDefault();
    nextFocusTarget =
      lastFocusableElement && currentFocusedElement === lastFocusableElement
        ? firstFocusableElement
        : currentFocusedElement?.nextSibling;
  }

  console.log({ nextFocusTarget });

  nextFocusTarget && nextFocusTarget.focus();
};

export const focusElementByQuery = (query?: string) => {
  const focusableElements = document.querySelectorAll(query || "");

  const firstFocusableElement = focusableElements?.[0] as
    | HTMLElement
    | undefined;
  firstFocusableElement?.focus();
};

export const dispatchCloseDropdownEvent = () => {
  const event = new Event(CLOSE_DROPDOWN_EVENT);
  document.dispatchEvent(event);
};
