import React from "react";
import moment from "moment";
import "./CellFirstUploadedManageComponents.scss";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";

const CellFirstUploadedManageComponents = props => {
  const { component_created_epoch } = props;

  return (
    <div
      className="CellFirstUploadedManageComponents"
      data-testid="CellFirstUploadedManageComponentsContainer"
    >
      <span className="CellFirstUploadedManageComponents__uploaded-time">
        {showDateWithTenantDateFormat(
          component_created_epoch,
          props.t("general_data_format_slash", "L")
        )}
      </span>
    </div>
  );
};

export default withTranslation("common")(CellFirstUploadedManageComponents);
