import {
  COContextInterface,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COQuestionAnswerOptionDBClass,
  COQuestionAnswerOptionInterface,
  COQuestionAnswerOptionTemplateDBClass,
  COQuestionAnswerOptionTemplateInterface,
  COQuestionInterface,
  COSectionInterface,
  COControlInterface,
  COControlOptionListItem,
  COConditionOverrideSlugPayload
} from "../interfaces/co-interfaces";
import { COBase } from "./co-base.class";
import { getValue } from "../utils/co-path.utils";
import { questionAnswerOptionTemplateForSlug } from "../templates/co-question-answer-options.template";
import { selectableAnswerOptionValueValidator } from "../templates/elements/validators/co-question-option.validators";
import { objectDeepCopy } from "../helpers/co-context.helper";
import {
  COContextObjectKey,
  COComponentType,
  COConditionSlugs
} from "../constants/co-constants";
import { COBaseTemplate } from "./co-base-template.class";
import { branchingPathsControls } from "../templates/elements/controls/co-question-answer-option-branching-controls.template";
import { isNullOrUndefined } from "../utils/co-utils";
import { removeAnswerOptionBranchConditions } from "../helpers/co-question-answer-option.helper";

export interface COQuestionAnswerOption
  extends COQuestionAnswerOptionInterface {}

export class COQuestionAnswerOption extends COBase {
  static getClassName(): string {
    return "COQuestionAnswerOption";
  }
  constructor({
    propertiesFromJSON,
    context,
    template,
    raw,
    question
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COQuestionAnswerOptionTemplate;
    raw?: any;
    question?: COQuestionInterface;
  }) {
    super({
      propertiesFromJSON
    });

    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: COQuestionAnswerOptionDBClass,
      ahid_key: "co_question_answer_option_ahid",
      contextKey: COContextObjectKey.ANSWER_OPTION,
      objectClass: COQuestionAnswerOption
    };

    //template means we're making a new question answer option or from a template directly - not loading from json
    if (template || raw) {
      if (question) {
        this.updateQuestion?.(question);
      }
      if (template) {
        this.template = template;
        this.setKeysFromTemplate?.(template.co_question_answer_option_json);
      } else if (raw) {
        this.setKeysFromTemplate?.(raw);

        // I want to set this template here so I can use the calculation_options field later on
        if (this.co_question_answer_option_template_slug) {
          let templateFromSlug = questionAnswerOptionTemplateForSlug(
            this.co_question_answer_option_template_slug
          );
          if (templateFromSlug) {
            this.template = templateFromSlug;
          }
        }
      }

      this[this.objectClassMapping.ahid_key || ""] =
        (raw && raw.co_question_answer_option_ahid) ||
        (template &&
          template.co_question_answer_option_json
            ?.co_question_answer_option_ahid) ||
        COQuestionAnswerOption.generateAHID();

      // this is because in the generic templates I don't know the AHID of other questions yet
      // so I'm setting the display variable in the template then looking up the AHID when I add the answer option from the template
      if (context) {
        this.processCustomTemplateVars?.({ context });
      }
    }

    this.checkAndLoadTemplate?.();
  }

  templateSlug?() {
    return this.co_question_answer_option_template_slug;
  }

  processCustomTemplateVars?({ context }: { context: COContextInterface }) {
    if (this.calculation_options) {
      if (
        this.calculation_options.answer_value_is_question_ahid &&
        this.co_question_answer_option_value
      ) {
        COBase.updateQuestionVariableToAHIDFromCustomTemplate({
          context,
          parent: this,
          propertyToUpdate: "co_question_answer_option_value"
        });
      }
    }
  }

  objectMeta?(): COMetadataInterface {
    return this.co_question_answer_option_meta_json || {};
  }
  objectOptions?(): COOptionsInterface {
    return this.co_question_answer_option_options_json || {};
  }
  objectPosition?(): COPositionInterface {
    return this.co_question_answer_option_position_json || {};
  }

  updateQuestion?(question: COQuestionInterface) {
    this.co_question_ahid = question.co_question_ahid;
  }

  //the raw answer option properties
  setKeysFromTemplate?(template: any) {
    let templateDeepCopy = objectDeepCopy(template);
    let keys = Object.keys(templateDeepCopy);
    //assign keys from template
    for (const key of keys) {
      this[key] = templateDeepCopy[key];
    }
  }

  static answerOptionsForTemplates(
    context: COContextInterface,
    question: COQuestionInterface,
    templates: COQuestionAnswerOptionTemplate[]
  ) {
    let answerOptions: COQuestionAnswerOption[] = [];
    for (const template of templates) {
      const ao: COQuestionAnswerOption = new COQuestionAnswerOption({
        context,
        question,
        template
      });
      answerOptions.push(ao);
    }
    return answerOptions;
  }

  customValidators?() {
    let unitTarget = this.options?.unit;
    let unit_validators;
    if (unitTarget) {
      // this is ok because we're resolving to unit templates in all context
      let resolvedUnitTarget = getValue(
        COQuestionAnswerOption.setContext(undefined, this),
        unitTarget
      );
      if (resolvedUnitTarget) {
        unit_validators = resolvedUnitTarget.validations;
      }
    }
    let validators = [...(unit_validators || [])];
    validators.push(selectableAnswerOptionValueValidator());
    return validators;
  }

  onRemoveFromAssessment?(context?: COContextInterface) {
    super.onRemoveFromAssessment?.(context);

    if (context && !isNullOrUndefined(context)) {
      // we need to remove all branching conditions related to this answer option from other sections/questions
      removeAnswerOptionBranchConditions({
        context
      });
    }
  }

  functionBranchingPathsControls?(
    context: COContextInterface
  ): COMetadataInterface | undefined {
    return branchingPathsControls(context);
  }
}

// template needs to be here or we'll get circular references
export interface COQuestionAnswerOptionTemplate
  extends COQuestionAnswerOptionTemplateInterface {}

export class COQuestionAnswerOptionTemplate extends COBaseTemplate {
  static getClassName(): string {
    return "COQuestionAnswerOptionTemplate";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [
        {
          objectKey: "co_question_answer_option_json",
          objectClass: COQuestionAnswerOption
        }
      ],
      dbClass: COQuestionAnswerOptionTemplateDBClass,
      objectClass: COQuestionAnswerOptionTemplate
    };
  }
}
