import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import { FETCH_LICENSE_PLAN_OPTIONS } from "../constants/actionTypes";

export const fetchLicensePlanOptions = () => {
  return function(dispatch) {
    axios
      .get(`/api/v1/admin/tenant/license-plan-options`)
      .then(response => {
        dispatch({
          type: FETCH_LICENSE_PLAN_OPTIONS,
          payload: response.data.data
          // payload: response.data.data,
        });
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
};

export const setLicensePlanOptions = ({ data, callback }) => {
  return function(dispatch) {
    axios
      .post(`/api/v1/admin/tenant/license-plan`, data)
      .then(response => {
        if (typeof callback === "function") {
          callback(response);
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
};
export const setExtendAccountTrial = ({ data, callback }) => {
  return function(dispatch) {
    axios
      .post(`/api/v1/admin/tenant/extend-trial`, data)
      .then(response => {
        if (typeof callback === "function") {
          callback(response);
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
};
export const setMoreLicenses = ({ data, callback }) => {
  return function(dispatch) {
    axios
      .post(`/api/v1/admin/tenant/purchase-licenses`, data)
      .then(response => {
        if (typeof callback === "function") {
          callback(response);
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
};

export const renewLicense = ({ data, callback }) => {
  return function(dispatch) {
    axios
      .post(`/api/v1/admin/tenant/renew-license`, data)
      .then(response => {
        if (typeof callback === "function") {
          callback(response);
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
};
