import {
  COProcessSubmissionType,
  COProcessSubmissionTypeSlugForType
} from "@uipath/ah-co";

export const AUTOMATION_HUB_SERVICE = "automationhub_";
export const AUTOMATION_STORE_SERVICE = "automationstore_";
export const INTEGRATION_SERVICE = "connections_";
export const AUTOMATION_STORE_PROFILE_PREFIX_URL =
  "/explore-published-automations/";
export const AUTOMATION_STORE_EXPLORE_URL = "/explore-published-automations";
export const AUTOMATION_HUB_PROFILE_PREFIX_URL = "/automation-profile/";
export const AUTOMATION_HUB_EXPLORE_AUTOMATIONS_URL =
  "/explore-automation-program";
export const ORCHESTRATOR_SERVICE = "orchestrator_";

export const CO_SUBMIT_PROCESS_PAGE = "submit";

export const CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE = (
  process_submission_type: COProcessSubmissionType
): string => {
  return `/${CO_SUBMIT_PROCESS_PAGE}/${COProcessSubmissionTypeSlugForType[process_submission_type]}`;
};
