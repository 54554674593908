import { LegacyQuestionAnswerSlugs } from "../../constants/co-migration-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";
import { COPhaseStatusKeys } from "../../constants/co-phase-status.constants";
import {
  COCalculatedValueTypes,
  COConditionSlugs
} from "../../constants/co-constants";

export const customQ2SelectionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.PCT_STRUCTURED_DIGITAL_DATA_INPUT,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PCT_STRUCTURED_DIGITAL_DATA_INPUT,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "% of structured digital data input",
        description:
          "Here we would like to understand if your input data is structured or unstructured. Structured data: has a predictable format, exists in fixed fields (e.g. an xls cell or a field in a form) and is easily detectable via search algorithms. Think about your structured data as: excels, tables with fixed structure, databases where you can easily extract your information through a search function. Unstructured data: may have its own internal structure, but this is not highly predictable. Examples: web logs, multimedia content, customer service interactions and social media data. ",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PCT_STRUCTURED_DIGITAL_DATA_INPUT
          ),
          co_question_position_json: {
            sort_order: 30
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: ">=80%",
            value: "0.0",
            legacy_slug:
              LegacyQuestionAnswerSlugs.STRUCTURED_INPUT_GREATER_EIGHTY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "60%-80%",
            value: "0.4",
            legacy_slug:
              LegacyQuestionAnswerSlugs.STRUCTURED_INPUT_BETWEEN_SIXTY_EIGHTY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "40%-60%",
            value: "0.7",
            legacy_slug:
              LegacyQuestionAnswerSlugs.STRUCTURED_INPUT_BETWEEN_FORTY_SIXTY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "<40%",
            value: "1",
            legacy_slug: LegacyQuestionAnswerSlugs.STRUCTURED_INPUT_LESSER_FORTY
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.SCANNED_DATA_INPUT,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.SCANNED_DATA_INPUT,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Scanned data input",
        description:
          "You can easily check if you have scanned documents by trying to do a Ctrl-F. This will not work in scanned documents. Don't hesitate to reach out to the submitters of this idea to gain a better understanding of the degree of digitization. You can find them listed in the contributors section.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.SCANNED_DATA_INPUT
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Yes",
            value: "1.2",
            legacy_slug: LegacyQuestionAnswerSlugs.SCANNED_INPUTS_YES
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "No",
            value: "1",
            legacy_slug: LegacyQuestionAnswerSlugs.SCANNED_INPUTS_NO
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Number of ways to complete the process",
        description:
          "Select from the dropdown the option that best describes the number of variations in which the process or activity can be completed.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS
          ),
          co_question_position_json: {
            sort_order: 30
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Process is completed the same way every time",
            value: "0.1",
            legacy_slug: LegacyQuestionAnswerSlugs.DECISION_COMPLEXITY_SAME_WAY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "There are >= 2 but < 5 ways to complete the process",
            value: "0.2",
            legacy_slug:
              LegacyQuestionAnswerSlugs.DECISION_COMPLEXITY_BETWEEN_TWO_FIVE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "There are >= 5 but < 8 ways to complete the process",
            value: "0.7",
            legacy_slug:
              LegacyQuestionAnswerSlugs.DECISION_COMPLEXITY_BETWEEN_FIVE_EIGHT
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "There are >= 8 ways to complete the process",
            value: "1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.DECISION_COMPLEXITY_GREATER_EIGHT
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_NUMBER_OF_STEPS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_NUMBER_OF_STEPS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Average number of steps",
        description:
          "Select from the dropdown menu the option which fits best. E.g. a step could be one verification that is done in an excel table, the download of a file etc.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_NUMBER_OF_STEPS
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Less than 20 steps",
            value: "0.1",
            legacy_slug: LegacyQuestionAnswerSlugs.PROCESS_LENGTH_LESS_TWENTY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Between 20-50 steps",
            value: "0.2",
            legacy_slug:
              LegacyQuestionAnswerSlugs.PROCESS_LENGTH_BETWEEN_TWENTY_FIFTY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Between 50-100 steps",
            value: "0.4",
            legacy_slug:
              LegacyQuestionAnswerSlugs.PROCESS_LENGTH_BETWEEN_FIFTY_HUNDRED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Between 100-150 steps",
            value: "0.6",
            legacy_slug:
              LegacyQuestionAnswerSlugs.PROCESS_LENGTH_BETWEEN_HUNDRED_HUNDREDFIFTY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "More than 150 steps",
            value: "1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.PROCESS_LENGTH_MORE_THAN_HUNDREDFIFTY
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.PROCESS_PEAKS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PROCESS_PEAKS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Process peaks",
        description:
          "A peak is a noticeable increase in activity/volumes in a certain period of time. E.g. Purchase Orders may experience a peak at the end of the month or quarter as people are trying to squeeze in purchases in that budget cycle.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PROCESS_PEAKS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Regular (e.g. month closing)",
            value: "3",
            legacy_slug: LegacyQuestionAnswerSlugs.PROCESS_PEAKS_REGULAR
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title:
              "Rare but predictable event (e.g. winter holidays/ yearly closing)",
            value: "2",
            legacy_slug:
              LegacyQuestionAnswerSlugs.PROCESS_PEAKS_RARE_PREDICTABLE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Rare and unpredictable event",
            value: "1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.PROCESS_PEAKS_RARE_UNPREDICTABLE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "There are no peaks",
            value: "0",
            legacy_slug: LegacyQuestionAnswerSlugs.PROCESS_PEAKS_NO_PEAKS
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE_FREQUENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE_FREQUENCY,
        standard_question_template_slug:
          StandardQuestionSlugs.SINGLE_CHOICE_NESTED,
        title: "Activity volume average (per selected frequency)",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE_FREQUENCY
          ),
          co_question_position_json: {
            sort_order: 25
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.QUESTION_IS_REQUIRED }]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Per company",
            value: "0",
            legacy_slug: LegacyQuestionAnswerSlugs.VOLUMES_PER_COMPANY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Per employee",
            value: "1",
            legacy_slug: LegacyQuestionAnswerSlugs.VOLUMES_PER_EMPLOYEE
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.TASK_PROCESS_FREQUENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.TASK_PROCESS_FREQUENCY,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Task/process frequency",
        description:
          "We want to understand how often is the task or process performed.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.TASK_PROCESS_FREQUENCY
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.QUESTION_ANSWER_SINGLE_CHOICE_OPTION,
            title: "Daily",
            value: CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR,
            legacy_slug: LegacyQuestionAnswerSlugs.FREQUENCY_DAILY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Weekly",
            value: "52",
            legacy_slug: LegacyQuestionAnswerSlugs.FREQUENCY_WEEKLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Bi-Weekly",
            value: "26",
            legacy_slug: LegacyQuestionAnswerSlugs.FREQUENCY_BI_WEEKLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Monthly",
            value: "12",
            legacy_slug: LegacyQuestionAnswerSlugs.FREQUENCY_MONTHLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Quarterly",
            value: "4",
            legacy_slug: LegacyQuestionAnswerSlugs.FREQUENCY_QUARTERLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Yearly",
            value: "1",
            legacy_slug: LegacyQuestionAnswerSlugs.FREQUENCY_YEARLY
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Primary reason to automate the process",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          legacy_slug: "",
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Cost",
            value: "cost",
            legacy_slug: LegacyQuestionAnswerSlugs.AUTOMATION_GOAL_COST
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Quality",
            value: "quality",
            legacy_slug: LegacyQuestionAnswerSlugs.AUTOMATION_GOAL_QUALITY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Productivity",
            value: "productivity",
            legacy_slug: LegacyQuestionAnswerSlugs.AUTOMATION_GOAL_PRODUCTIVITY
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.PROCESS_CHANGE_EXPECTED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PROCESS_CHANGE_EXPECTED,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Process changes expected in the next 6 months",
        description:
          "Think about changes of the following type: Legislative changes that are upcoming and could change the rules/logic of the process. Organizational changes such as redistributing bits of the processes between different roles/departments. Industry shifts that could change the business model and hence the rules of the process.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PROCESS_CHANGE_EXPECTED
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "No change expected",
            value: "0",
            legacy_slug:
              LegacyQuestionAnswerSlugs.TASK_STABILITY_NO_CHANGE_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Minor change expected",
            value: "0.2",
            legacy_slug:
              LegacyQuestionAnswerSlugs.TASK_STABILITY_MINOR_CHANGE_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Some change expected",
            value: "0.4",
            legacy_slug:
              LegacyQuestionAnswerSlugs.TASK_STABILITY_SOME_CHANGE_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Several changes expected",
            value: "0.8",
            legacy_slug:
              LegacyQuestionAnswerSlugs.TASK_STABILITY_SEVERAL_CHANGES_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Major changes expected",
            value: "1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.TASK_STABILITY_MAJOR_CHANGE_EXPECTED
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.APPLICATION_CHANGE_EXPECTED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.APPLICATION_CHANGE_EXPECTED,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Applications changes expected in the next 6 months",
        description:
          "If you are unsure on how to make the difference between a major change and other types of changes please talk to your IT responsible/Program Manager.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.APPLICATION_CHANGE_EXPECTED
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "No change expected",
            value: "0",
            legacy_slug:
              LegacyQuestionAnswerSlugs.APPLICATION_STABILITY_NO_CHANGE_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Minor change expected",
            value: "0.2",
            legacy_slug:
              LegacyQuestionAnswerSlugs.APPLICATION_STABILITY_MINOR_CHANGE_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Some change expected",
            value: "0.4",
            legacy_slug:
              LegacyQuestionAnswerSlugs.APPLICATION_STABILITY_SOME_CHANGE_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Several changes expected",
            value: "0.8",
            legacy_slug:
              LegacyQuestionAnswerSlugs.APPLICATION_STABILITY_SEVERAL_CHANGES_EXPECTED
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Major changes expected",
            value: "1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.APPLICATION_STABILITY_MAJOR_CHANGE_EXPECTED
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.PROCESS_OWNER_Q2,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PROCESS_OWNER_Q2,
        standard_question_template_slug: StandardQuestionSlugs.USER,
        title: "Process Owner",
        description: "",
        note:
          "NOTE: This question is tied to a default collaborator role. The answer will be displayed in the Collaborators page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PROCESS_OWNER_Q2
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          hide_comments_section: 1,
          optional_for_submission: 0,
          calculated_value_type: COCalculatedValueTypes.USER,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FOR_EDIT },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            {
              slug: COConditionSlugs.REQUIRED_FOR_PHASE_STATUS_CHANGE,
              payload: {
                phase_status_key: COPhaseStatusKeys.IDEA_AWAITING_REVIEW,
                next_phase_status_key: COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
              }
            }
          ]
        }
      })
    }
  ];
};
