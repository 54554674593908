import React from "react";
import "./CellVersionMyComponents.scss";

const CellVersionMyComponents = props => {
  const {
    component_version,
    component_semantic_version,
    className = ""
  } = props;

  return (
    <div
      className={`CellVersionMyComponents ${className}`}
      data-testid="CellVersionMyComponentsContainer"
    >
      <span className="CellVersionMyComponents__version-name">
        V{component_version || component_semantic_version}
      </span>
    </div>
  );
};

export default CellVersionMyComponents;
