import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReportAbuseModal from "./ModalReportAbuse";
import { REPORT_AUTOMATION_ABUSE } from "../../../constants/reportConstants";
import { getKeyValue } from "../../../utils/objectParsers";

class ModalReportAutomation extends React.Component<any, any> {
  render() {
    return (
      <ReportAbuseModal
        {...this.props}
        title={this.props.t("modal_report_automation_title")}
        message={this.props.t("modal_report_automation_content")}
        type={REPORT_AUTOMATION_ABUSE}
        currAutomation={this.props.currAutomation}
        onClose={this.props.onClose}
      />
    );
  }
}

const mapStateToProps = ({ modal }) => ({
  currAutomation: getKeyValue(modal, "modalProps.currAutomation") || null,
  onClose: getKeyValue(modal, "modalProps.onClose") || null
});
export default connect(mapStateToProps)(
  withTranslation("common")(ModalReportAutomation)
);
