import { COTaskAction } from "../../classes/actions/co-task-action.class";
import {
  COActionOptionsInterface,
  COActionTemplateInterface,
  COEmailActionInterface,
  COEmailActionOptionsInterface,
  COEmailReminderActionInterface,
  COEmailReminderActionOptionsInterface,
  COTaskActionOptionsInterface,
  COActionContextValuesInterface
} from "../../interfaces/co-interfaces";
import { actionTemplate } from "../../templates/elements/actions/co-action-templates";

export const emailReminderForTask = ({
  task,
  tenant_id,
  numberOfReminders,
  reminderInterval
}: {
  task: COTaskAction;
  tenant_id?: string;
  numberOfReminders: number;
  reminderInterval: number;
}): COEmailReminderActionInterface | undefined => {
  let taskOptions:
    | COTaskActionOptionsInterface
    | undefined = task.co_action_options_json as COTaskActionOptionsInterface;
  if (typeof taskOptions === "string") {
    taskOptions = JSON.parse(taskOptions);
  }
  const slug = taskOptions?.email_reminder_options?.email_reminder_slug;
  if (!slug) {
    return undefined;
  }

  // Pull the data we'll need to send an email into one object
  const emailReminderTemplate = actionTemplate(slug);
  if (!emailReminderTemplate) {
    return undefined;
  }

  const options: COEmailReminderActionOptionsInterface = {
    ...emailReminderTemplate.co_options_json,
    send_reminder_delay: reminderInterval,
    number_reminders_to_send: numberOfReminders,
    original_task_action_id: task.co_action_id
  };

  return {
    co_action_template_slug: slug,
    co_action_type: emailReminderTemplate.co_action_template_co_type,
    co_action_meta_json: emailReminderTemplate.co_meta_json,
    co_action_options_json: options as COActionOptionsInterface,
    template: emailReminderTemplate,
    co_action_context_values_json: {
      ...task.co_action_context_values_json,
      tenant_id: tenant_id
    }
  };
};

export const emailTemplateForEmailReminder = ({
  emailReminder
}: {
  emailReminder: COEmailReminderActionInterface;
}): COActionTemplateInterface | undefined => {
  const emailReminderOptions:
    | COEmailReminderActionOptionsInterface
    | undefined = emailReminder.co_action_options_json as COEmailReminderActionOptionsInterface;
  if (!emailReminderOptions) {
    return undefined;
  }
  const emailSlug = emailReminderOptions.email_template_meta_slug;
  if (!emailSlug) {
    return undefined;
  }
  const emailTemplate = actionTemplate(emailSlug);
  return emailTemplate;
};

export const emailInterfaceForEmailTemplateForReminders = ({
  emailTemplate,
  emailReminder,
  actionContextValues
}: {
  emailTemplate: COActionTemplateInterface;
  emailReminder: COEmailReminderActionInterface;
  actionContextValues: COActionContextValuesInterface;
}): COEmailActionInterface => {
  const options: COEmailActionOptionsInterface = {
    ...emailTemplate.co_options_json,
    slug_for_email_reminder: emailReminder.co_action_template_slug
  };
  return {
    co_action_template_slug: emailTemplate.co_action_template_slug,
    co_action_type: emailTemplate.co_action_template_co_type,
    co_action_meta_json: emailTemplate.co_meta_json,
    co_action_options_json: options as COActionOptionsInterface,
    template: emailTemplate,
    co_action_context_values_json: actionContextValues
  };
};
