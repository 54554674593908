import {
  COContextInterface,
  CORoleActionInterface,
  CORoleActionDBClass
} from "../../interfaces/co-interfaces";
import { COBase } from "../co-base.class";
import { COContextObjectKey } from "../../constants/co-constants";

export interface CORoleAction extends CORoleActionInterface {}

export class CORoleAction extends COBase {
  static getClassName(): string {
    return "CORoleAction";
  }
  constructor({
    propertiesFromJSON,
    context
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
  }) {
    super({
      propertiesFromJSON
    });

    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: CORoleActionDBClass,
      contextKey: COContextObjectKey.ACTION,
      ahid_key: "",
      objectClass: CORoleAction
    };

    this.checkAndLoadTemplate?.();
  }
}
