import { COAssessment } from "../classes/co-assessment.class";
import { COContext } from "../classes/co-context.class";
import { resolve } from "../utils/co-resolver.utils";

import { isPath, setValue } from "../utils/co-path.utils";

import { isNullOrUndefined } from "../utils/co-utils";
import {
  coAssertArrayWithContents,
  coAssertEquals,
  coAssertString,
  failedAssert
} from "../utils/co-test.utils";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";

export const testResolverSystem = ({
  assessment,
  logSuccess = false
}: {
  assessment: COAssessment;
  logSuccess?: boolean;
}) => {
  let context = new COContext({ assessment });
  let item: any = context.assessment?.meta;
  setValue({
    context,
    parentObject: item?.title,
    property: "value",
    newValueToSet: "Banana"
  });
  testResolveItem({
    testName: "test resolve simple item",
    context,
    item,
    assertFCT: (resolvedItem, name) => {
      return coAssertEquals(resolvedItem?.title?.value, "Banana");
    },
    logSuccess
  });

  context = new COContext({ assessment });
  item = context.assessment?.meta;
  setValue({
    context,
    parentObject: item?.title,
    property: "value",
    newValueToSet: PATH({
      route:
        PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON.TITLE
          .VALUE
    })
  });
  testResolveItem({
    testName: "test resolve item with path to path",
    context,
    item,
    assertFCT: (resolvedItem, name) => {
      return coAssertEquals(
        resolvedItem?.title?.value,
        assessment.co_assessment_version_meta_json?.title?.value
      );
    },
    logSuccess
  });

  context = new COContext({ assessment });
  item = context.assessment?.meta;
  setValue({
    context,
    parentObject: item?.title,
    property: "value",
    newValueToSet: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_SECTIONS_OBJECT
    })
  });
  testResolveItem({
    testName: "test not resolving array of questions",
    context,
    item,
    assertFCT: (resolvedItem, name) => {
      let resolvedItemValue = resolvedItem?.title?.value;
      coAssertArrayWithContents(resolvedItemValue, name);

      return coAssertEquals(
        resolvedItemValue.length,
        assessment.co_assessment_sections?.length
      );
    },
    logSuccess
  });

  let preppedAssessment = COAssessment.prepForSubmissionViewOrEdit({
    assessment
  });
  context = new COContext({
    assessment: preppedAssessment,
    section: preppedAssessment?.co_assessment_sections?.[0],
    question: preppedAssessment?.co_assessment_sections?.[0].co_questions?.[0],
    answer_option:
      preppedAssessment?.co_assessment_sections?.[0].co_questions?.[0]
        .co_question_answer_options?.[0],
    process_answer:
      preppedAssessment?.co_assessment_sections?.[0].co_questions?.[0]
        .co_process_answer
  });
  item = {
    toResolve:
      context.assessment?.co_assessment_sections?.[0].co_questions?.[0]
        .validators
  };

  const assertNoPathInObject = objectToCheck => {
    for (const k of Object.keys(objectToCheck)) {
      if (!isNullOrUndefined(objectToCheck[k])) {
        if (isPath(objectToCheck[k])) {
          failedAssert("Path Not Resolved", objectToCheck);
        }
      }
    }
  };

  testResolveItem({
    testName: "test resolving question validators",
    context,
    item,
    assertFCT: (resolvedItem, name) => {
      // console.log(item);
      // console.log(resolvedItem);
      // we want to make sure none of the elements are paths anymore
      coAssertArrayWithContents(resolvedItem.toResolve, name);
      for (const validator of resolvedItem.toResolve) {
        assertNoPathInObject(validator);
      }
    },
    logSuccess
  });

  item = {
    toResolve:
      context.assessment?.co_assessment_sections?.[0].co_questions?.[0].controls
  };

  testResolveItem({
    testName: "test resolving question controls",
    context,
    item,
    assertFCT: (resolvedItem, name) => {
      // console.log(item);
      // console.log(resolvedItem);
      // we want to make sure none of the elements are paths anymore
      coAssertArrayWithContents(resolvedItem.toResolve, name);
      for (const ctrl of resolvedItem.toResolve) {
        assertNoPathInObject(ctrl);
      }
    },
    logSuccess
  });
};

export const testResolveItem = ({
  testName,
  context,
  item,
  assertFCT = coAssertString,
  logSuccess = false
}: {
  testName: string;
  context: COContext;
  item: any;
  assertFCT?: Function;
  logSuccess?: boolean;
}) => {
  let resolvedItem = resolve({ context, item });
  assertFCT(resolvedItem, testName);
  if (logSuccess) {
    console.log(`Test Passed ${testName}`);
  }
};
