import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";

export const queueStatusUpdatesNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = queueStatusUpdatesNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.QUEUE_STATUS_UPDATES,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const queueStatusUpdatesNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.QUEUE_ITEMS_FINISHED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 156,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.QUEUE_ITEMS_FINISHED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Queue Items Finished"
          },
          message: {
            value: "The Queue Items have finished"
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
  }
  return undefined;
};
