import SessionHelper from "../helpers/SessionHelper";

const loadScript = (src: string, type: string, callbackfn: Function) => {
  const newScript: any = document.createElement("script");
  newScript.type = type;
  newScript.setAttribute("async", "true");
  newScript.setAttribute("src", src);

  if (newScript.readyState) {
    newScript.onreadystatechange = function() {
      if (/loaded|complete/.test(newScript.readyState)) callbackfn();
    };
  } else {
    newScript.addEventListener("load", callbackfn, false);
  }
  document?.documentElement?.firstChild?.appendChild(newScript);
};

// load versionless apollo packages for all Envs
export const registerApolloPackagesScript = () => {
  if (process.env.NODE_ENV !== "production") {
    // For local dev, we can fetch apollo js from alpha portal
    loadScript(
      `/portal_/apollo/packages/portal-shell/2/portal-shell.esm.js?spaceName=automationhub`,
      "module",
      function() {
        console.log("load apollo packages from alpha Portal");
      }
    );
  } else if (!SessionHelper.isFpsUri()) {
    // For non fps we want to load files from build to prevent cross site requests
    loadScript(
      `${process.env.PUBLIC_URL}/static/apollo/packages/portal-shell/portal-shell.esm.js`,
      "module",
      function() {
        console.log("load apollo packages from local");
      }
    );
  } else {
    // For cloud and K8S, we can fetch apollo js from portal
    loadScript(
      `/portal_/apollo/packages/portal-shell/2/portal-shell.esm.js?spaceName=automationhub`,
      "module",
      function() {
        console.log("load apollo packages from Portal");
      }
    );
  }
};
