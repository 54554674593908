import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import { COActionReplacementStrings } from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";

export const gamificationNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = gamificationNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.GAMIFICATION,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const gamificationNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // there are no gamification slugs yet
  return undefined;
};
