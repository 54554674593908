import React, { Component } from "react";
import CookieBanner from "react-cookie-banner";

import urlWithTenant from "../../utils/urlHelper";

class CookiePopup extends Component {
  render() {
    const message =
      "This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience. Some of the cookies are necessary in order for you to log in, store your preferences and for the proper display of our web pages.";

    // cookies.remove('accepts-cookies')

    // reset cookies on first render
    // !state.accepted && cookies.get('accepts-cookies') && cookies.remove('accepts-cookies')

    const styles = {
      banner: {
        fontFamily: "Noto Sans",
        minHeight: "90px",
        height: "auto",
        background:
          "rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat",
        backgroundSize: "30px 30px",
        backgroundColor: "",
        fontSize: "15px",
        fontWeight: 600,
        position: "fixed",
        bottom: "0px"
      },
      button: {
        border: "1px solid white",
        borderRadius: 4,
        width: 180,
        height: 32,
        lineHeight: "32px",
        background: "transparent",
        color: "white",
        fontSize: "14px",
        fontWeight: 600,
        opacity: 1,
        right: 20,
        marginTop: -18
      },
      message: {
        display: "block",
        padding: "9px 220px 9px 18px",
        lineHeight: 1.3,
        textAlign: "left",
        color: "white"
      },
      link: {
        textDecoration: "none",
        fontWeight: "bold"
      }
    };

    return (
      <div data-testid="CookiePopupContainer">
        <CookieBanner
          styles={styles}
          message={message}
          link={
            <a href={urlWithTenant("/cookie-policy")}>
              To find out more about the cookies we use, see our Cookies Policy
            </a>
          }
          buttonMessage="Accept and Continue"
          dismissOnScroll={false}
          dismissOnClick={false}
          onAccept={() => this.setState({ accepted: true })}
        />
      </div>
    );
  }
}

export default CookiePopup;
