import React, { Component } from "react";
import { useTranslation, Trans } from "react-i18next";
import urlWithTenant from "../../utils/urlHelper";
import { Link } from "react-router-dom";
import _ from "lodash";

import "./ErrorHandler.scss";
import { EXPLORE_AUTOMATIONS_PROGRAM_ROUTE } from "../../constants/routesConstants";

export const ErrorHandlerContent = () => {
  const { t } = useTranslation("common");
  return (
    <div className="ErrorHandler" data-testid="ErrorHandlerContainer">
      <div className="ErrorHandler__main">
        <h1>{t(["general_error_message_title", "Sorry..."])}</h1>
        <p>
          {t([
            "general_error_message_1",
            "Something went wrong with this page. The page you are looking for could not be found."
          ])}
        </p>
        <span
          className="ErrorHandler__main-link-section"
          style={{ display: "block" }}
        >
          {/* prettier-ignore */}
          <Trans t={t} i18nKey="general_error_message_2">
            Click
            <a
              className="ErrorHandler__main-link"
              href={urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE)}
            >
              here
            </a>
            to go back to the home page.
          </Trans>
        </span>
      </div>
    </div>
  );
};

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null //TODO: to log errorInfo to the backend in the future in case of error occurs
    };
  }

  static getDerivedStateFromError(errorInfo) {
    return {
      error: true,
      errorInfo
    };
  }

  resetErrorInState = () => {
    this.setState({
      error: null,
      errorInfo: null
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.error || prevState.error) {
      this.resetErrorInState();
    }
  }
  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextState.error) {
      return true;
    }
    if (_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  };

  render() {
    if (this.state.error) {
      return <ErrorHandlerContent />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorHandler;
