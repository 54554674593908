import React from "react";
import { useTranslation } from "react-i18next";
import ModalTemplateOne from "../Modal/ModalTemplateOne/ModalTemplateOne";
import styled from "styled-components/macro";
import ButtonApolloPrimary from "../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import SelectItemSearchField from "../SelectItemSearchField/SelectItemSearchField";

const ButtonApolloPrimaryStyled = styled(ButtonApolloPrimary)`
  &&& {
    margin-left: 16px;
  }
`;

const SearchModalDiv = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const SearchModalWrapper = styled.div`
  width: 98%;
  max-width: 480px;
`;

const SearchModalContent = styled.div`
  padding: 1px 32px 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const SearchModal = ({
  selected,
  heading,
  subtext,
  addButtonText,
  isComponent,
  removeSelectedItem,
  handleSelectedItemChange,
  handleAddSelectedItems,
  loadOptions,
  closeModal,
  CustomOptionComponent
}: {
  selected: any;
  heading: any;
  subtext: any;
  addButtonText?: any;
  isComponent?: boolean;
  removeSelectedItem: (thing: any) => void;
  handleSelectedItemChange: any;
  handleAddSelectedItems: any;
  loadOptions: any;
  closeModal: () => void;
  CustomOptionComponent?: any;
}) => {
  const { t } = useTranslation("common");

  const handleCloseModal = () => {
    closeModal();
  };

  const stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <SearchModalDiv data-testid="SearchModal">
      <SearchModalWrapper onClick={stopPropagation}>
        <ModalTemplateOne
          heading={heading}
          onClose={handleCloseModal}
          headerStyle={{
            padding: "24px 30px 0"
          }}
        >
          <SearchModalContent>
            <SelectItemSearchField
              selected={selected}
              subtext={subtext}
              isComponent={isComponent}
              CustomOptionComponent={CustomOptionComponent}
              removeSelectedItem={removeSelectedItem}
              handleSelectedItemChange={handleSelectedItemChange}
              loadOptions={loadOptions}
            />

            <ButtonContainer data-testid="SearchModal__ButtonContainer">
              <ButtonApolloSecondary onClick={handleCloseModal}>
                {t(["search_modal_cancel", "Cancel"])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimaryStyled
                disabled={!selected.length}
                onClick={handleAddSelectedItems}
              >
                {addButtonText || t(["search_modal_add", "Add"])}
              </ButtonApolloPrimaryStyled>
            </ButtonContainer>
          </SearchModalContent>
        </ModalTemplateOne>
      </SearchModalWrapper>
    </SearchModalDiv>
  );
};

export default SearchModal;
