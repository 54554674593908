import { COAssessment } from "../classes/co-assessment.class";
import { COBase } from "../classes/co-base.class";
import { COContext } from "../classes/co-context.class";
import {
  COAssessmentUpdateValidationInfo,
  COValidationError,
  COValidationSlugs
} from "../interfaces/co-interfaces";
import { validate } from "./co-object-validation-helper";

export const validateAssessmentForUpdate = ({
  assessment,
  originalAssessment
}: {
  assessment?: COAssessment;
  originalAssessment?: COAssessment;
}): COAssessmentUpdateValidationInfo => {
  let validationInfo: COAssessmentUpdateValidationInfo = {
    assessment: assessment,
    original_assessment: originalAssessment,
    validation_errors: [],
    can_update: false
  };
  if (originalAssessment?.num_processes_on_version === 0) {
    validationInfo.can_update = true;
    return validationInfo;
  }

  let comparison_context = new COContext({
    assessment: originalAssessment,
    display_context: {
      assessment_customize: 1
    },
    validation_context: {
      assessment_customize_update: 1
    }
  });

  let context = new COContext({
    assessment,
    display_context: {
      assessment_customize: 1
    },
    validation_context: {
      assessment_customize_update: 1,
      comparison_context: comparison_context
    }
  });

  let validation_errors = validate({
    context,
    co_object: context.assessment || {}
  });

  console.log(validation_errors);
  validationInfo.validation_errors = validation_errors;

  if (validation_errors.length === 0) {
    validationInfo.can_update = true;
  }

  return validationInfo;
};

export const niceDisplayMessageForUpdateValidationError = (
  validationError: COValidationError,
  errorMessageLocalized: string | null = null
): string => {
  let msg =
    errorMessageLocalized ||
    validationError.error_message ||
    "Default Error Message";

  let coBaseProblemObject: COBase = validationError?.problem_object;

  switch (validationError?.resolvedValidationItem?.validationSlug) {
    case COValidationSlugs.KPI_EQUATION_MATCH:
    case COValidationSlugs.KPI_VARIABLE_CHANGED:
    case COValidationSlugs.QUESTION_EQUATION_MATCH:
    case COValidationSlugs.QUESTION_VARIABLE_CHANGED:
    case COValidationSlugs.KPI_TYPE_CHANGED:
    case COValidationSlugs.QUESTION_TYPE_CHANGED:
    case COValidationSlugs.QUESTION_REMOVED:
    case COValidationSlugs.REFERENCE_QUESTION_ADDED_OR_REMOVED:
    case COValidationSlugs.REFERENCE_QUESTION_REFERENCE_CHANGED:
      return `${msg} -- ${coBaseProblemObject.objectDisplayName?.()}`;
    case COValidationSlugs.QUESTION_ANSWER_OPTION_REMOVED: {
      let parentObject: COBase | null =
        validationError.target_path_parts &&
        Array.isArray(validationError.target_path_parts) &&
        validationError.target_path_parts.length > 2
          ? validationError.target_path_parts?.[
              validationError.target_path_parts.length - 2
            ].contextualValue || null
          : null;
      return `${msg} -- ${parentObject?.objectDisplayName?.() ||
        ""} -- ${coBaseProblemObject.objectDisplayName?.()}`;
    }
    case COValidationSlugs.QUESTION_ANSWER_OPTION_VALUE_CHANGED: {
      let parentFromContext: COBase | null =
        validationError.target_path_parts &&
        Array.isArray(validationError.target_path_parts) &&
        validationError.target_path_parts.length > 0
          ? validationError.target_path_parts?.[0].contextualValue?.question ||
            null
          : null;
      return `${msg} -- ${parentFromContext?.objectDisplayName?.() ||
        ""} -- ${coBaseProblemObject.objectDisplayName?.()}`;
    }
    case COValidationSlugs.QUESTION_CANNOT_BE_SHOWN_IN_TABULAR_FORMAT: {
      let parentFromContext: COBase | null =
        validationError.target_path_parts &&
        Array.isArray(validationError.target_path_parts) &&
        validationError.target_path_parts.length > 0
          ? validationError.target_path_parts?.[0]?.contextualValue?.question ||
            null
          : null;
      return `${msg} -- ${validationError?.section?.co_section_meta_json?.title
        ?.value ||
        "Unnamed section"} - ${parentFromContext?.objectDisplayName?.() ||
        "Unnamed reference"}`;
    }
    default:
      return msg;
  }
};
