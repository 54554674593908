import {
  IDEA_ARCHIVED,
  IDEA_APPROVED,
  IDEA_REJECTED,
  IDEA_DUPLICATE,
  ASSESSMENT_NOT_STARTED,
  QUALIFICATION_APPROVED,
  QUALIFICATION_REJECTED,
  QUALIFICATION_ON_HOLD,
  BUSINESS_REVIEW_APPROVED,
  BUSINESS_REVIEW_REJECTED,
  BUSINESS_REVIEW_ON_HOLD,
  TECHNICAL_REVIEW_AWAITING_REVIEW,
  LIVE_ONGOING,
  TECHNICAL_REVIEW_REWORK_REQUIRED,
  TECHNICAL_REVIEW_ON_HOLD,
  DEVELOPMENT_NOT_STARTED,
  DEVELOPMENT_IN_PROGRESS
} from "../constants/automationStatusTypes";
import { stringFromRaw } from "./editorHelper";

export const toLowerCaseCapitalizeFirst = str => {
  if (!str) {
    return "";
  }

  if (typeof str !== "string" || str.length < 1) {
    return "";
  }
  let strArr = str.split(" ");

  strArr = strArr.map(word => {
    let char0 = "";
    if (word.length > 0) {
      char0 = word[0];
      if (char0) {
        char0 = char0.toUpperCase();
      }
    }

    let char1 = "";
    if (word.length > 1) {
      char1 = word.slice(1);
      if (char1) {
        char1 = char1.toLowerCase();
      }
    }

    return char0 + char1;
  });

  return strArr.join(" ");
};

export const getFullName = (first, last) => {
  if (!first && !last) {
    return "";
  }

  if (!first) {
    return last;
  }

  if (!last) {
    return first;
  }

  return `${first} ${last}`;
};

export const noneIfFalse = word => {
  if (typeof word === "string") {
    word = word.trim();
  }
  return word === "" || word === null || word === undefined ? "None" : word;
};

export const parseName = (name, maxLength = 50, emptyText = "") => {
  if (!name) {
    return emptyText;
  }

  if (name.length > maxLength) {
    return name.slice(0, maxLength - 2) + "...";
  }

  return name;
};

export const determineUpdatedStatusText = currentPhaseStatus => {
  switch (currentPhaseStatus) {
    case IDEA_ARCHIVED:
      return "Archived";
    case IDEA_APPROVED:
    case BUSINESS_REVIEW_APPROVED:
    case DEVELOPMENT_NOT_STARTED:
    case LIVE_ONGOING:
      return "Approved";
    case IDEA_REJECTED:
    case BUSINESS_REVIEW_REJECTED:
      return "Rejected";
    case IDEA_DUPLICATE:
      return "Marked As Duplicate";
    case ASSESSMENT_NOT_STARTED:
      return "Assessment Launched";
    case QUALIFICATION_APPROVED:
      return "Approved";
    case QUALIFICATION_REJECTED:
      return "Rejected";
    case QUALIFICATION_ON_HOLD:
    case BUSINESS_REVIEW_ON_HOLD:
    case TECHNICAL_REVIEW_ON_HOLD:
      return "Put on Hold";
    case TECHNICAL_REVIEW_AWAITING_REVIEW:
      return "Moved to Technical Review";
    case TECHNICAL_REVIEW_REWORK_REQUIRED:
      return "Rework Required";
    case DEVELOPMENT_IN_PROGRESS:
      return "Development Started";
    default:
      return "Archived";
  }
};

export const pluralOrSingular = (text, numResults) => {
  return numResults > 1 ? text + "s" : text;
};

export const getDomain = email => {
  if (typeof email !== "string" || !email) {
    return "";
  }

  const indexOfAt = email.indexOf("@");

  if (indexOfAt < 0) {
    return "";
  }

  const domain = email.slice(indexOfAt + 1);

  return domain.trim();
};

export const processStringWithBrackets = string => {
  if (string === null || string === undefined || typeof string !== "string") {
    return [""]; // has to be a empty string in a array since we using 0 index
  }
  return string.split("(");
};

export const validateCommentText = comment => {
  if (
    comment === null ||
    comment === undefined ||
    typeof comment !== "string"
  ) {
    return false;
  }
  if (typeof comment === "string" && comment.trim().length === 0) {
    return false;
  }
  return true;
};

export const hasNonEmptyDraftJSText = jsonString => {
  try {
    return stringFromRaw(jsonString).length > 0;
  } catch (error) {
    return false;
  }
};

export const truncateText = (text, textLimit = 100) => {
  if (typeof text !== "string" || !text) {
    return "";
  }

  if (text.length > textLimit) {
    return text.substring(0, textLimit) + "...";
  }

  return text;
};

export const stringIsPositiveIntOrEmpty = str => {
  if (typeof str !== "string") {
    return false;
  }
  if (str === "") {
    return true;
  }
  if (str.match(/^[0-9]*$/)) {
    return true;
  }
  return false;
};

export const stringIsBetween0And100OrEmpty = str => {
  if (typeof str !== "string") {
    return false;
  }
  if (str === "") {
    return true;
  }
  if (
    stringIsFloatingPointOrEmpty(str) &&
    Number(str) >= 0 &&
    Number(str) <= 100
  ) {
    return true;
  }
  return false;
};

export const stringIsFloatingPointOrEmpty = str => {
  if (typeof str !== "string") {
    return false;
  }
  if (str === "") {
    return true;
  }
  if (str.match(/^[0-9]*\.?[0-9]*$/)) {
    return true;
  }
  return false;
};

export const stringIsNumberMaxTwoDecimalsOrEmpty = str => {
  if (typeof str !== "string") {
    return false;
  }
  if (str === "") {
    return true;
  }
  if (str.match(/^[0-9]*\.?[0-9]{0,2}$/)) {
    return true;
  }
  return false;
};
