import React, { ReactElement } from "react";
import { convertStatusIdToColor } from "../../utils/statusHelper";
import Tooltip from "../Tooltip/Tooltip";
import { component_map_to_slug } from "../../constants/componentPermissionsConstants";
import { useTranslation } from "react-i18next";
import {
  NEEDS_UPDATE_PHASE,
  REJECTED_PHASE,
  DRAFT_PHASE
} from "../../constants/componentPermissionsConstants";
import { Chip } from "@material-ui/core";

const ComponentVersionBadge = ({
  componentStatusId,
  readOnly,
  componentRejectReason,
  className = "",
  showTooltipOverride,
  tooltipTextOverride,
  labelOverride,
  colorMapOverride
}: {
  componentStatusId: any;
  readOnly: boolean;
  componentRejectReason?: any;
  className?: string;
  showTooltipOverride?: boolean;
  tooltipTextOverride?: ReactElement | string;
  labelOverride?: string;
  colorMapOverride?: "blue" | "green" | "yellow" | "red" | "gray";
}) => {
  const { t } = useTranslation("common");
  let showTooltip =
    (componentStatusId === NEEDS_UPDATE_PHASE ||
      componentStatusId === REJECTED_PHASE) &&
    (componentRejectReason === 0 || !!componentRejectReason);
  if (showTooltipOverride !== undefined) {
    showTooltip = showTooltipOverride;
  }

  let tooltipText: any = "";
  if (showTooltip) {
    tooltipText = (
      <>
        <p>
          <strong>
            {t([
              "upload_component_version_container_need_update",
              "This component needs update."
            ])}
          </strong>
        </p>
        <p>{componentRejectReason}</p>
      </>
    );
    if (tooltipTextOverride !== undefined) {
      tooltipText = tooltipTextOverride;
    }
  }

  let label =
    (readOnly && componentStatusId) || componentStatusId === DRAFT_PHASE
      ? t([
          "component_status_be_" +
            (component_map_to_slug[componentStatusId] || "").toLowerCase(),
          ""
        ])
      : t(["upload_component_version_container_new", "New"]);
  if (labelOverride !== undefined) {
    label = labelOverride;
  }

  const colorFromMap =
    colorMapOverride || convertStatusIdToColor(componentStatusId);
  switch (colorFromMap) {
    case "blue":
      className += " info";
      break;
    case "green":
      className += " success";
      break;
    case "yellow":
      className += " warning";
      break;
    case "red":
      className += " error";
      break;
    default:
      break;
  }

  return (
    <Tooltip title={tooltipText}>
      <Chip className={className} label={label} />
    </Tooltip>
  );
};

export default ComponentVersionBadge;
