import {
  CLEAR_UNSAVED_DATA,
  FLAG_UNSAVED_DATA,
  SET_SAVE_CALLBACK,
  UPDATE_LEAVING_CURATION,
  SET_TITLE_TEXT_BUTTON_TEXT_OPTIONS
} from "../constants/actionTypes";

export const clearUnsavedData = () => {
  return {
    type: CLEAR_UNSAVED_DATA,
    payload: false
  };
};
export const flagUnsavedData = () => {
  return {
    type: FLAG_UNSAVED_DATA,
    payload: true
  };
};

export const setTileTextButtonOptions = title_text_button_text_options => {
  return {
    type: SET_TITLE_TEXT_BUTTON_TEXT_OPTIONS,
    payload: title_text_button_text_options
  };
};

export const setSaveCallback = callback => {
  return {
    type: SET_SAVE_CALLBACK,
    payload: callback
  };
};

export const flagLeavingCuration = () => {
  return {
    type: UPDATE_LEAVING_CURATION,
    payload: true
  };
};

export const clearLeavingCuration = () => {
  return {
    type: UPDATE_LEAVING_CURATION,
    payload: false
  };
};
