import { COQuestionTemplate } from "../classes/co-question.class";
import { COQuestionTemplateInterface } from "../interfaces/co-interfaces";
import { customCRQuestionTemplatesBySlugs } from "./default_cr_assessment/co-custom-cr-questions.template";
import { customEXKPITemplatesBySlugs } from "./default_ex_assessment/co-custom-ex-kpis.template";
import { customEXQuestionTemplatesBySlugs } from "./default_ex_assessment/co-custom-ex-questions.template";
import { customEXRefQuestionsTemplatesBySlugs } from "./default_ex_assessment/co-custom-ex-ref-questions.template";
import { customMiscQuestionTemplatesBySlugs } from "./default_misc_assessment/co-custom-misc-questions.template";
import { customOVRInputQuestionTemplatesBySlugs } from "./default_ovr_assessment/co-custom-ovr-input-questions.template";
import { customQ1KPITemplatesBySlugs } from "./default_q1_assessment/co-custom-q1-kpis.template";
import { customQ1SelectionTemplatesBySlugs } from "./default_q1_assessment/co-custom-q1-selection-questions.template";
import { customQ2InputQuestionTemplatesBySlugs } from "./default_q2_assessment/co-custom-q2-input-questions.template";
import { customQ2KPITemplatesBySlugs } from "./default_q2_assessment/co-custom-q2-kpis.template";
import { customQ2SelectionTemplatesBySlugs } from "./default_q2_assessment/co-custom-q2-selection-questions.template";
import { customQ3KPITemplatesBySlug } from "./default_q3_assessment/co-custom-q3-kpis.template";
import { customQ3QuestionTemplatesBySlugs } from "./default_q3_assessment/co-custom-q3-questions.template";
import { customStatsKPITemplatesBySlugs } from "./default_stats_assessment/co-custom-stats-kpi.template";

//TODO I DEF need to have some sort of slug key lookup here. this is crazy
export const customQuestionTemplateForSlug = template_slug => {
  let customTemplates = customQuestionTemplatesBySlugs();
  return (
    customTemplates.find(element => element.slug === template_slug)?.template ||
    null
  );
};

export const customQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplateInterface | undefined;
}[] => {
  return [
    ...customOVRInputQuestionTemplatesBySlugs(),
    ...customQ1SelectionTemplatesBySlugs(),
    ...customQ1KPITemplatesBySlugs(),
    ...customQ2SelectionTemplatesBySlugs(),
    ...customQ2KPITemplatesBySlugs(),
    ...customQ2InputQuestionTemplatesBySlugs(),
    ...customQ3QuestionTemplatesBySlugs(),
    ...customQ3KPITemplatesBySlug(),
    ...customMiscQuestionTemplatesBySlugs(),
    ...customStatsKPITemplatesBySlugs(),
    ...customCRQuestionTemplatesBySlugs(),
    ...customEXQuestionTemplatesBySlugs(),
    ...customEXKPITemplatesBySlugs(),
    ...customEXRefQuestionsTemplatesBySlugs()
  ];
};

export const customizableQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    ...customOVRInputQuestionTemplatesBySlugs(),
    ...customQ1SelectionTemplatesBySlugs(),
    ...customQ2SelectionTemplatesBySlugs(),
    ...customQ2InputQuestionTemplatesBySlugs(),
    ...customQ3QuestionTemplatesBySlugs(),
    ...customMiscQuestionTemplatesBySlugs(),
    ...customQ1KPITemplatesBySlugs(),
    ...customQ2KPITemplatesBySlugs(),
    ...customQ3KPITemplatesBySlug(),
    ...customCRQuestionTemplatesBySlugs(),
    ...customEXQuestionTemplatesBySlugs(),
    ...customEXKPITemplatesBySlugs(),
    ...customEXRefQuestionsTemplatesBySlugs()
  ];
};
