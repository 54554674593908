import { COComponentType, COConditionSlugs } from "../constants/co-constants";
import { COSectionTemplateInterface } from "../interfaces/co-interfaces";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";
import {
  sectionApplicationCanAddApplicationsControls,
  sectionControls,
  sectionTabularControls
} from "./elements/controls/co-controls.template";
import { sectionValidators } from "./elements/validators/co-validators.template";
import { templateSectionConditions } from "./elements/conditions/co-conditions";
import { mergeTemplates } from "./co-template.template";
import {
  CONonStandardSectionTemplateSlugs,
  COSectionTemplateSlugs
} from "../constants/co-section.constants";
import { inEnum } from "../utils/co-utils";
import { branchingSectionControlMeta } from "./elements/controls/co-section-branching-controls.template";

export const sectionTemplateForSlug = (
  template_slug: string
): COSectionTemplateInterface | undefined => {
  // first override with slug
  let slugTemplate: COSectionTemplateInterface = {
    co_section_template_slug: template_slug,
    co_section_json: {
      co_section_template_slug: template_slug
    }
  };

  let defaultTemplate: COSectionTemplateInterface = mergeTemplates([
    defaultSectionTemplate(),
    slugTemplate
  ]);

  // so now just override the differences
  switch (template_slug) {
    case COSectionTemplateSlugs.OVR_DEFAULT_SECTION_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_controls_json: [...sectionControls({ is_disabled: 1 })],
          co_section_json: {
            co_section_options_json: {
              prevent_deleting: 1
            }
          }
        }
      ]);
    }
    case COSectionTemplateSlugs.Q1_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.Q2_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.Q3_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.STATS_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.CR_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.EX_DEFAULT_SECTION_V1: {
      return mergeTemplates([defaultTemplate, {}]);
    }
    case COSectionTemplateSlugs.Q2_DEFAULT_SIDEBAR_SECTION_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_section_json: {
            co_section_options_json: {
              condition_overrides: [
                ...(defaultTemplate.co_section_json?.co_section_options_json
                  ?.condition_overrides || []),
                { slug: COConditionSlugs.SIDEBAR }
              ]
            }
          }
        }
      ]);
    }
    case CONonStandardSectionTemplateSlugs.POTENTIAL_BENEFIT_SECTION_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_options_json: {
            display_context_overrides: {
              assessment_customize: {
                prevent_deleting: 1
              }
            }
          },
          co_meta_json: {
            title: {
              display_context_overrides: {
                assessment_customize: {
                  is_editable: false
                }
              }
            }
          },
          co_section_json: {
            co_section_options_json: {
              condition_overrides: [
                ...(defaultTemplate.co_section_json?.co_section_options_json
                  ?.condition_overrides || []),
                { slug: COConditionSlugs.SIDEBAR }
              ]
            }
          }
        }
      ]);
    }
    case COSectionTemplateSlugs.Q2_APPLICATIONS_SECTION_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_meta_json: {
            title: {
              display_context_overrides: {
                assessment_customize: {
                  is_editable: false
                }
              }
            },
            description: {
              display_context_overrides: {
                assessment_customize: {
                  is_editable: false
                }
              }
            }
          },
          co_options_json: {
            is_read_only: 1
          },
          co_controls_json: [
            ...sectionControls(),
            ...sectionApplicationCanAddApplicationsControls()
          ],
          co_section_json: {
            co_section_options_json: {
              disable_adding_applications: 0
            }
          }
        }
      ]);
    }
    case COSectionTemplateSlugs.CBA_DEFAULT_SECTION_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_controls_json: [], // no controls,
          co_validators_json: [] // no validators
        }
      ]);
    }
    case CONonStandardSectionTemplateSlugs.PROCESS_OWNER_SECTION_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_meta_json: {
            title: {
              display_context: {
                assessment_customize: 1,
                assessment_submission: 1
              }
            }
          }
        }
      ]);
    }
    case CONonStandardSectionTemplateSlugs.CR_SECTION_VISIBILITY_LOCKED_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_controls_json: [...sectionControls({ is_disabled: 1 })],
          co_section_json: {
            co_section_options_json: {
              prevent_deleting: 1
            }
          }
        }
      ]);
    }
    case CONonStandardSectionTemplateSlugs.EX_SECTION_TABULAR_V1: {
      return mergeTemplates([
        defaultTemplate,
        {
          co_section_json: {
            co_section_options_json: {
              is_tabular: 1
            }
          }
        }
      ]);
    }
  }
  // can't do this return defaultTemplate; we need to to fail due to centralized template loading
  if (inEnum(COSectionTemplateSlugs, template_slug)) {
    //
    console.log(
      `loading a section template not in switch ${template_slug} double check`
    );
    return defaultTemplate;
  }
};

export const defaultSectionTemplate = (): COSectionTemplateInterface => {
  return {
    co_section_template_slug: COSectionTemplateSlugs.Q2_DEFAULT_SECTION_V1,
    co_meta_json: {
      ...branchingSectionControlMeta(),
      title: {
        position: { sort_order: 0 },
        options: {
          component_type: COComponentType.META_H3_TEXT,
          is_read_only: PATH({
            route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_READ_ONLY
          })
        },
        value: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_META_JSON.TITLE.VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            is_editable: true,
            label: "Section",
            label_localization_key: "co_section_title_label",
            placeholder: "Enter Section Title",
            placeholder_localization_key: "co_section_title_placeholder",
            options: {
              component_type: COComponentType.META_EDITABLE_H3_TEXT,
              optional_for_submission: 0
            }
          }
        }
      },
      description: {
        position: { sort_order: 1 },
        options: {
          component_type: COComponentType.META_PARAGRAPH_TEXT,
          is_read_only: PATH({
            route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_READ_ONLY
          })
        },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_META_JSON.DESCRIPTION.VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            is_editable: true,
            label: "Description",
            label_localization_key: "co_section_decription_label",
            placeholder: "This text will appear below your title",
            placeholder_localization_key: "co_section_decription_placeholder",
            options: {
              component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT
            }
          }
        }
      },
      note: {
        options: { component_type: COComponentType.META_TEXT },
        display_context: {
          assessment_customize: 1
        },
        value: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_META_JSON.NOTE.VALUE
        })
      },
      ...branchingSectionControlMeta()
    },
    co_validators_json: [...sectionValidators()],
    co_controls_json: [...sectionControls(), ...sectionTabularControls()],
    co_options_json: {
      condition_overrides: [...templateSectionConditions()]
    },
    co_section_json: {
      co_section_template_slug: COSectionTemplateSlugs.Q2_DEFAULT_SECTION_V1,
      co_section_meta_json: {
        title: {
          value: ""
        },
        description: {
          value: ""
        }
      },
      co_section_options_json: {
        component_type: COComponentType.SECTION_TABLE,
        is_tabular: 0,
        condition_overrides: []
      },
      co_section_position_json: {
        sort_order: 0
      }
    }
  };
};
