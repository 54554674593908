import { BUSINESS_UNIT_KEY, DEPARTMENT_KEY } from "./automationHeaderConstants";

export const AUTOMATION_NAME_HEADER_KEY = "process_name";
export const AUTOMATION_NAME_HEADER_LOCALIZATION_KEY = "localization_key";
export const HIERARCHY_HEADER_KEY = "hierarchy";
export const LAUNCH_DATE_HEADER_KEY = "launch_date";
export const DEPARTMENT_HEADER_KEY = "department";
export const BUSINESS_UNIT_HEADER_KEY = "business_unit";
export const AUTOMATION_GOAL_HEADER_KEY = "process_goal";
export const AUTOMATION_GOAL_HEADER_LOCALIZATION_KEY = "response_choice_slug";
export const SOFTWARE_VENDOR_HEADER_KEY = "software_vendor";
export const LICENSE_TYPE_HEADER_KEY = "license_type";
export const AUTOMATION_TYPE_HEADER_KEY = "process_type";
export const AUTOMATION_TYPE_HEADER_LOCALIZATION_KEY = "process_type_key";
export const PHASE_HEADER_KEY = "phase";
export const PHASE_HEADER_FILTER_LOCALIZATION_KEY = "phase_key";
export const STATUS_HEADER_KEY = "phase_status";
export const COLLABORATORS_HEADER_KEY = "collaborators";
export const IDEA_SCORE_HEADER_KEY = "idea_score";
export const ESTIMATED_BENEFIT_HOURS_HEADER_KEY =
  "process_estimated_benefit_score";
export const NUMBER_OF_ROBOTS_HEADER_KEY = "number_of_robots";
export const NUMBER_OF_ROBOTS_HEADER_LOCALIZATION_KEY = "localizationKey";
export const ESTIMATED_BENEFIT_CURRENCY_HEADER_KEY =
  "process_benefit_expressed_in_currency_per_year";
export const UPVOTES_HEADER_KEY = "number_of_votes";
export const USER_INVITE_STATUS_KEY = "user_invite_status";
export const PROCESS_PRIORITY_HEADER_KEY = "process_priority";
export const DEVELOPMENT_TYPE_HEADER_KEY = "development_type";
export const IDEA_SOURCE_HEADER_KEY = "process_submission_type";
export const DEVELOPMENT_TYPE_HEADER_LOCALIZATION_KEY = "development_type_slug";
export const ROLES_SLUG_HEADER_KEY = "role_slug";
export const AUTOMATION_POTENTIAL_HEADER_KEY = "process_automation_score";
export const DEFAULT_PAGE_LIMIT = 10;

export const AUTOMATION_PIPELINE_LEGACY_COLUMNS_NOT_HANDLED_IN_CO_SYSTEM = {
  [BUSINESS_UNIT_KEY]: "user_business_unit",
  [DEPARTMENT_KEY]: "user_department"
};
