export const MANAGE_APP_INVENTORY_PERMISSION = "s_manage_app_inventory";
export const MANAGE_COMPONENTS_PERMISSION = "s_manage_components";
export const MANAGE_CATEGORIES_PERMISSION = "s_manage_categories";
export const MANAGE_PEOPLE_PERMISSION = "s_manage_people";
export const MANAGE_ROLES_PERMISSION = "s_manage_roles";
export const MANAGE_TEMPLATES_PERMISSION = "s_manage_templates";
export const TOP_DOWN_PERMISSION_PERMISSION = "s_top_down";
export const DASHBOARD_PERMISSION = "s_dashboards";
export const TENANT_SETTINGS_PERMISSION = "s_tenant_settings";
export const MANAGE_COST_SETUP_PERMISSION = "s_cost_setup";
export const CUSTOMIZE_ASSESSMENT_PERMISSION = "s_customize_assessment";
export const MANAGE_OPEN_API_PERMISSION = "s_open_api";
export const ADMIN_AUDIT_LOGS_PERMISSION = "s_audit_logs";
export const VIEW_REPORTS_PERMISSION = "s_reports";
export const EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION = "s_employee_driven";
export const SUBMIT_AUTOMATION_PERMISSION = "s_submit_automation";
export const SUBMIT_CHANGE_REQUEST_PERMISSION = "s_submit_change_request";
export const SUBMIT_COMPONENT_PERMISSION = "s_submit_component";
export const AUTOMATION_PIPELINE_VIEW_ALL = "s_automation_pipeline_view_all";
export const AUTOMATION_PIPELINE_VIEW_REVIEW =
  "s_automation_pipeline_view_review";
export const AUTOMATION_PIPELINE_VIEW_DECISION_PIPELINE =
  "s_automation_pipeline_view_decision_pipeline";
export const AUTOMATION_PIPELINE_VIEW_IMPLEMENTATION =
  "s_automation_pipeline_view_implementation";
export const AUTOMATION_PIPELINE_VIEW_LIVE = "s_automation_pipeline_view_live";
export const IMPORT_PIPELINE_PERMISSION = "s_edit_import_pipeline";
export const ORCHESTRATOR_MAPPING_PERMISSION = "s_manage_orchestrator_mapping";
export const ASSESSMENT_VERSION_PERMISSION = "s_assessment_version";
export const INSIGHTS_CONNECTION_PERMISSION = "s_manage_insights_integration";
export const CHANGE_IDEA_SOURCE_PERMISSION = "s_change_idea_source";
export const USE_INTEGRATIONS_PERMISSION = "s_use_integrations";
export const MANAGE_INTEGRATIONS_PERMISSION = "s_manage_integrations";

// update this for every new page added to admin console
export const ACCESS_ADMIN_CONSOLE_PERMISSIONS = [
  MANAGE_CATEGORIES_PERMISSION,
  MANAGE_COMPONENTS_PERMISSION,
  TENANT_SETTINGS_PERMISSION,
  MANAGE_ROLES_PERMISSION,
  CUSTOMIZE_ASSESSMENT_PERMISSION,
  MANAGE_TEMPLATES_PERMISSION,
  MANAGE_APP_INVENTORY_PERMISSION,
  MANAGE_COST_SETUP_PERMISSION,
  ADMIN_AUDIT_LOGS_PERMISSION,
  MANAGE_PEOPLE_PERMISSION,
  IMPORT_PIPELINE_PERMISSION,
  MANAGE_OPEN_API_PERMISSION,
  ORCHESTRATOR_MAPPING_PERMISSION,
  MANAGE_INTEGRATIONS_PERMISSION
];
//automation level permissions
export const EDIT_AUTOMATION_PERMISSION = "p_edit";
export const CONSUME_AUTOMATION = "p_consume_automation";
export const PUBLISH_AUTOMATION_TO_AUTOMATION_STORE =
  "p_publish_to_automation_store";
export const START_DEVELOPMENT = "p_start_development";
export const SUBMIT_CHANGE_REQUEST_COLLABORATOR_PERMISSIONS =
  "p_submit_change_request_collaborator";

export const AUTOMATION_PIPELINE_TAB_PERMISSIONS = [
  AUTOMATION_PIPELINE_VIEW_ALL,
  AUTOMATION_PIPELINE_VIEW_REVIEW,
  AUTOMATION_PIPELINE_VIEW_DECISION_PIPELINE,
  AUTOMATION_PIPELINE_VIEW_IMPLEMENTATION,
  AUTOMATION_PIPELINE_VIEW_LIVE
];
