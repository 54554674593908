import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  reportAbuseForAutomationProfile,
  reportAbuseForAutomationComment
} from "../../../actions/processProfileActions";
import {
  reportComponentBug,
  reportComponentAbuse
} from "../../../actions/componentProfileActions";
import { reportAbuseForComponentComment } from "../../../actions/componentProfileCommentsActions";
import { reportReviewAbuse } from "../../../actions/componentProfileReviewsActions";
import Modal from "../Modal";
import { stringFromRaw } from "../../../utils/editorHelper.js";
import {
  REPORT_AUTOMATION_ABUSE,
  REPORT_REVIEW,
  REPORT_COMMENT,
  REPORT_COMPONENT_BUG,
  REPORT_COMPONENT_ABUSE
} from "../../../constants/reportConstants";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import InputRichTextApollo from "../../inputs/InputRichTextApollo/InputRichTextApollo";
import ButtonClose from "../../buttons/ButtonClose/ButtonClose";
import "./ModalReportAbuse.scss";

class ModalReportAbuse extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      error: ""
    };
  }

  closeModal = () => {
    this.props.closeModal();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleTextChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      error: ""
    });
  };

  submitReport = () => {
    let processProfile = this.props.processProfile;
    let process_slug = processProfile?.process_slug;
    let review_id = this.props.review_id;
    let componentProfile = this.props.componentProfile;
    let component_slug = componentProfile?.component_slug;
    let comment_id = this.props.comment_id;

    if (!/\S/.test(stringFromRaw(this.state.description))) {
      this.setState({
        error: this.props.t("modal_report_abuse_error_invalid_description")
      });
      return;
    }

    const data = {
      reported_reason: this.state.description
    };

    switch (this.props.type) {
      case REPORT_AUTOMATION_ABUSE:
        this.props.reportAbuseForAutomationProfile(process_slug, data);
        this.closeModal();
        break;
      case REPORT_REVIEW:
        const reviewDescription = {
          review_description: this.state.description
        };
        this.props.reportReviewAbuse(
          review_id,
          component_slug,
          reviewDescription
        );
        this.closeModal();
        break;
      case REPORT_COMMENT:
        if (component_slug) {
          this.props.reportAbuseForComponentComment(
            component_slug,
            comment_id,
            data
          );
        } else {
          this.props.reportAbuseForAutomationComment(
            process_slug,
            comment_id,
            data
          );
        }
        this.closeModal();
        break;
      case REPORT_COMPONENT_BUG:
        this.props.reportComponentBug(component_slug, data);
        this.closeModal();
        break;
      case REPORT_COMPONENT_ABUSE:
        this.props.reportComponentAbuse(component_slug, data);
        this.closeModal();
        break;
      default:
        break;
    }
  };

  render() {
    const modalContentStyle = {
      width: "100%",
      maxWidth: "480px",
      borderRadius: "4px",
      boxShadow: "0 8px 6px 0 rgba(0, 0, 0, 0.04)",
      padding: "32px"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={modalContentStyle}>
        <div className="ModalReportAbuse" data-testid="ModalReportAbuse">
          <div className="ModalReportAbuse__header">
            <h1
              id="ModalReportAbuse__title"
              className="ModalReportAbuse__title"
            >
              {this.props.title}
            </h1>
            <ButtonClose onClick={this.closeModal} />
          </div>
          <div className="ModalReportAbuse__main">
            <p className="ModalReportAbuse__body-text">{this.props.message}</p>
            <InputRichTextApollo
              InputProps={{
                value: this.state.description,
                name: "description",
                onChange: this.handleTextChange
              }}
              label=""
              error={this.state.error}
              maxLength={2000}
              hideBlockStyling={true}
              ariaLabelledBy={"ModalReportAbuse__title"}
            />
            <div className="ModalReportAbuse__buttons">
              <ButtonApolloSecondary
                className="ModalReportAbuse__button ModalReportAbuse__cancel"
                onClick={this.closeModal}
              >
                {this.props.t(["modal_report_abuse_cancel", "Cancel"])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                className="ModalReportAbuse__button ModalReportAbuse__submit"
                onClick={this.submitReport}
              >
                {this.props.t(["modal_report_abuse_submit", "Submit"])}
              </ButtonApolloPrimary>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapActionsToProps = {
  reportAbuseForAutomationProfile,
  reportAbuseForAutomationComment,
  reportReviewAbuse,
  reportComponentBug,
  reportComponentAbuse,
  reportAbuseForComponentComment
};

export default withTranslation("common")(
  connect(null, mapActionsToProps)(ModalReportAbuse)
);
