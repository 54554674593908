import React from "react";
import styled from "styled-components/macro";
import { Chip } from "@material-ui/core";
import { ChipApolloProps } from "../../types/chipTypes";

//The font-size for Apollo chip `default` color is 14px, but for other colors it's 12px. So we need to force it here
const StyledChipWithFixedFontsize = styled(Chip)`
  font-size: 12px !important;
`;

//in case other styles override font-weight
const StyledChipWithFixedFontweight = styled(Chip)`
  font-weight: normal !important;
`;

export const ChipApollo = (props: ChipApolloProps) => {
  if (props?.isStatusChip) {
    return <StyledChipWithFixedFontsize {...props} />;
  }
  return <StyledChipWithFixedFontweight {...props} />;
};

export default ChipApollo;
