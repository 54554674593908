import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";

import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import {
  COAssessmentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import { COSectionTemplateSlugs } from "../../constants/co-section.constants";
import { defaultAssessmentTemplate } from "../co-assessment.template";

export const defaultOVRAssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.OVERVIEW_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.OVR_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.OVERVIEW_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.OVR_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default Overview Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
    assessmentTemplate.co_options_json = {
      ...assessmentTemplate.co_options_json,
      optional_for_submission: 1,
      condition_overrides: [
        // This assessment is never optional
        { slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL },
        ...templateAssessmentConditions()
      ]
    };
  }
  return assessmentTemplate;
};

export const AHDefaultOVRAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateOVR() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-ovr-0-0`;
  addDefaultSections({ context, assessment });

  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHFreshOVRAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateOVR(false) });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-ovr-0-0`;
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "About",
    note: "NOTE: You can't delete this section.",
    sectionTemplateSlug: COSectionTemplateSlugs.OVR_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.OVERVIEW_NAME,
      CustomQuestionSlugs.OVERVIEW_AUTO_GENERATED_ID,
      CustomQuestionSlugs.OVERVIEW_AUTOMATION_ID,
      CustomQuestionSlugs.OVERVIEW_CATEGORY,
      CustomQuestionSlugs.OVERVIEW_DESCRIPTION
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Header information",
    note:
      "NOTE: This section cannot be edited or deleted. It contains the questions from the Automation Profile header.",
    sectionTemplateSlug: COSectionTemplateSlugs.OVR_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
    is_read_only: 1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.OVERVIEW_DEVELOPMENT_TYPE,
      CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE,
      CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMITTER,
      CustomQuestionSlugs.OVERVIEW_PROCESS_PHASE_STATUS,
      CustomQuestionSlugs.OVERVIEW_PRIORITY
    ]
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "About",
    note: "NOTE: You can't delete this section.",
    sectionTemplateSlug: COSectionTemplateSlugs.OVR_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.OVERVIEW_NAME,
      CustomQuestionSlugs.OVERVIEW_AUTO_GENERATED_ID,
      CustomQuestionSlugs.OVERVIEW_AUTOMATION_ID,
      CustomQuestionSlugs.OVERVIEW_CATEGORY,
      CustomQuestionSlugs.OVERVIEW_DESCRIPTION,
      CustomQuestionSlugs.OVERVIEW_PAIN_POINTS,
      CustomQuestionSlugs.OVERVIEW_NEGATIVE_IMPACT,
      CustomQuestionSlugs.OVERVIEW_TAGS,
      CustomQuestionSlugs.OVERVIEW_USEFUL_LINK
    ]
  });

  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Header information",
    note:
      "NOTE: This section cannot be edited or deleted. It contains the questions from the Automation Profile header.",
    sectionTemplateSlug: COSectionTemplateSlugs.OVR_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
    is_read_only: 1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.OVERVIEW_DEVELOPMENT_TYPE,
      CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE,
      CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMITTER,
      CustomQuestionSlugs.OVERVIEW_PROCESS_PHASE_STATUS,
      CustomQuestionSlugs.OVERVIEW_PRIORITY
    ]
  });
};

export const assessmentTemplateOVR = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultOVRAssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Overview",
      description: ""
    });
    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New Overview"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};
