import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { userInitials } from "../../utils/userHelper";
import { COUserInterface } from "@uipath/ah-co";

const DEFAULT_AVATAR_SIZE = "32px";

const AvatarContainer = styled.div<{
  $width?: string;
  $height?: string;
  $borderRadius?: string;
  $fontSize?: string;
  $border?: string;
  $color?: string;
  $background?: string;
}>(
  ({
    $width,
    $height,
    $borderRadius,
    $fontSize,
    $border,
    $color,
    $background
  }) => css`
    width: ${$width || DEFAULT_AVATAR_SIZE};
    max-width: ${$width || DEFAULT_AVATAR_SIZE};
    min-width: ${$width || DEFAULT_AVATAR_SIZE};
    height: ${$height || $width || DEFAULT_AVATAR_SIZE};
    max-height: ${$height || $width || DEFAULT_AVATAR_SIZE};
    min-height: ${$height || $width || DEFAULT_AVATAR_SIZE};
    border-radius: ${$borderRadius || "3px"};
    overflow: hidden;
    font-size: ${$fontSize || "14px"};
    color: ${$color || `var(--color-foreground-link)`};
    border: ${$border ? $border : `1px solid var(--color-border-de-emp)`};
    background: ${$background || "var(--color-background)"};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: var(--color-background);
`;

const AvatarInitials = styled.div`
  font-size: 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  text-align: center;
`;

const Avatar = ({
  user,
  initials,
  image,
  className,
  width,
  borderRadius,
  fontSize,
  height,
  border,
  onClick,
  alt,
  color,
  background
}: {
  user?: COUserInterface | null;
  initials?: string;
  image?: string;
  className?: string;
  width?: string;
  borderRadius?: string;
  fontSize?: string;
  height?: string;
  border?: string;
  onClick?: () => void;
  alt?: string;
  color?: string;
  background?: string;
}) => {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [user?.user_profile_slug]);

  let imageSource = "";
  let initialsToDisplay = "";

  if (user && typeof user === "object") {
    if (user.user_profile_pic && !imageError) {
      imageSource = user.user_profile_pic;
    } else if (
      typeof user.user_first_name === "string" ||
      typeof user.user_last_name === "string"
    ) {
      initialsToDisplay = userInitials(user);
    }
  }

  if (image && !imageSource && !initialsToDisplay) {
    imageSource = image;
  } else if (initials && !imageSource && !initialsToDisplay) {
    initialsToDisplay = initials;
  }

  const handleImageError = e => {
    setImageError(true);
  };

  const renderContent = (imageSource, initialsToDisplay, alt = "") => {
    if (!imageError && imageSource) {
      return (
        <AvatarImage src={imageSource} alt={alt} onError={handleImageError} />
      );
    } else {
      return <AvatarInitials>{initialsToDisplay}</AvatarInitials>;
    }
  };

  return (
    <AvatarContainer
      className={className}
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      $fontSize={fontSize}
      $border={border}
      $color={color}
      $background={background}
      onClick={onClick}
    >
      {renderContent(imageSource, initialsToDisplay, alt)}
    </AvatarContainer>
  );
};

export default Avatar;
