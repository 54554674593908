export const isValidTenantSlug = slug => {
  if (
    typeof slug === "string" &&
    slug.match(
      /^[一-龠ぁ-ゔァ-ヴー々〆〤–àâäèéêëîïôœæùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'ÄäÖöÜüẞßａ-ｚＡ-Ｚ０-９\w\-\!\s.]+$/
    )
  ) {
    return true;
  }

  return false;
};
