// Copied from react-router-scroll-memory
// https://github.com/ipatate/react-router-scroll-memory
// Needed to change so it does not scroll to top when query params change

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

export const getScrollPage = () => {
  let docScrollTop = 0;
  if (document.documentElement && document.documentElement !== null) {
    docScrollTop = document.documentElement.scrollTop;
  }
  return window.pageYOffset || docScrollTop;
};

export const getScrollElement = elementId => {
  let elemScrollTop = 0;
  const element = document.getElementById(elementId);
  if (element !== null) {
    elemScrollTop = element.scrollTop;
  }
  return elemScrollTop;
};

export const scrollTo = scrollNumber =>
  window.requestAnimationFrame(() => {
    window.scrollTo(0, scrollNumber);
  });

export const scrollToElement = (scrollNumber, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollTop = scrollNumber;
  }
};

export const isBrowser = () => typeof window !== "undefined";

class ScrollMemory extends Component {
  constructor(props) {
    super(props);
    // add event for click on previous or next browser button
    this.detectPop = this.detectPop.bind(this);
    // stock location key with scroll associate
    this.url = new Map();
  }

  componentDidMount() {
    window.addEventListener("popstate", this.detectPop);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.detectPop);
  }

  shouldComponentUpdate(nextProps) {
    if (!isBrowser()) return false;
    const { location } = this.props;
    // location before change url
    const actual = location;
    // location after change url
    const next = nextProps.location;
    // the first page has not key, set "enter" for key
    const key = actual.key || "enter";

    // if hash => let the normal operation of the browser
    // !!!!! ==============
    // This was the code changed to not watch for next.search !== actual.search
    // !!!!! ==============
    const locationChanged =
      next.pathname !== actual.pathname && next.hash === "";

    // get scroll of the page or the element before change location
    const scroll = this.props.elementID
      ? getScrollElement(this.props.elementID)
      : getScrollPage();

    if (locationChanged) {
      // pass page or element scroll to top
      this.props.elementID
        ? scrollToElement(0, this.props.elementID)
        : scrollTo(0);
      // save scroll with key location
      this.url.set(key, scroll);
    }
    // never render
    return false;
  }

  detectPop(location) {
    if (!isBrowser()) return;
    const { state } = location;
    // key or enter page
    const key = state && state.key ? state.key : "enter";
    // get the next for scroll position
    const nextFind = this.url.get(key);

    // if find in url map => scroll to position
    if (nextFind) {
      this.props.elementID
        ? scrollToElement(nextFind, this.props.elementID)
        : scrollTo(nextFind);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollMemory);
