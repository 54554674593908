import uniqBy from "lodash/uniqBy";

import {
  FETCH_NOTIFICATIONS,
  FETCH_MORE_NOTIFICATIONS,
  FETCH_NOTIFICATION_TYPES,
  CLEAR_NOTIFICATIONS_REDUCER,
  MARK_NOTIFICATION_READ,
  UNMARK_NOTIFICATION_READ,
  SET_IS_LOADING_NOTIFICATIONS,
  SET_IS_LOADING_MORE_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_READ,
  IS_MARKING_ALL_NOTIFICATIONS_READ,
  HAS_MARKED_ALL_NOTIFICATIONS_READ,
  MARK_ACTION_READ,
  UNMARK_ACTION_READ
} from "../constants/actionTypes";

const INITIAL_STATE = {
  notifications: null,
  notificationTypes: null,
  hasMoreNotifications: true,
  isLoadingNotifications: false,
  isLoadingMoreNotifications: false,
  allNotificationsMarkedRead: false,
  isMarkingAllNotificationsRead: false,
  hasMarkedAllNotificationsRead: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS: {
      let hasMoreNotifications = true;
      if (
        Array.isArray(action.payload.actions) &&
        action.payload.actions.length === 0
      ) {
        hasMoreNotifications = false;
      }

      const notifications = action.payload.actions;
      if (
        action.payload.totalItems &&
        notifications.length === action.payload.totalItems
      ) {
        hasMoreNotifications = false;
      }

      return {
        ...state,
        notifications: notifications,
        isLoadingNotifications: false,
        hasMoreNotifications
      };
    }
    case FETCH_MORE_NOTIFICATIONS: {
      let hasMoreNotifications = true;
      if (
        !Array.isArray(state.notifications) &&
        !Array.isArray(action.payload.actions)
      ) {
        return { ...state };
      }

      const notifications = action.payload.actions;

      // get unique notifications by ID
      // depending on if it's an action or legacy
      let allReturnedNotifications = [...state.notifications, ...notifications];
      let allNotifications = [];
      for (const notif of allReturnedNotifications) {
        if (notif.co_action_id) {
          if (
            !allNotifications.some(
              existingNotif => notif.co_action_id === existingNotif.co_action_id
            )
          ) {
            allNotifications.push(notif);
          }
        } else if (notif.legacy_data?.user_notification_id) {
          if (
            !allNotifications.some(
              existingNotif =>
                notif.legacy_data.user_notification_id ===
                existingNotif.legacy_data?.user_notification_id
            )
          ) {
            allNotifications.push(notif);
          }
        }
      }

      if (allNotifications.length === state.notifications.length) {
        hasMoreNotifications = false;
      }
      if (
        action.payload.totalItems &&
        allNotifications.length === action.payload.totalItems
      ) {
        hasMoreNotifications = false;
      }

      if (state.allNotificationsMarkedRead) {
        allNotifications.forEach(notification => {
          if (notification.co_user_action_json) {
            if (notification.co_user_action?.co_user_action_options_json) {
              notification.co_user_action.co_user_action_options_json.has_seen_action = 1;
            } else {
              notification.co_user_action.co_user_action_options_json = {
                has_seen_action: 1
              };
            }
          }
        });
      }

      return {
        ...state,
        notifications: allNotifications,
        hasMoreNotifications,
        isLoadingMoreNotifications: false
      };
    }
    case SET_IS_LOADING_NOTIFICATIONS: {
      return {
        ...state,
        isLoadingNotifications: action.payload
      };
    }
    case SET_IS_LOADING_MORE_NOTIFICATIONS: {
      return {
        ...state,
        isLoadingMoreNotifications: action.payload
      };
    }
    case FETCH_NOTIFICATION_TYPES: {
      return {
        ...state,
        notificationTypes: action.payload
      };
    }
    case MARK_NOTIFICATION_READ: {
      const notifications = state.notifications.map(obj => {
        if (action.payload.includes(obj.user_notification_id)) {
          obj.notification_has_viewed = 1;
        }
        return obj;
      });
      return {
        ...state,
        notifications: notifications
      };
    }
    case UNMARK_NOTIFICATION_READ: {
      const notifications = state.notifications.map(obj => {
        if (action.payload.includes(obj.user_notification_id)) {
          obj.notification_has_viewed = 0;
        }
        return obj;
      });
      return {
        ...state,
        notifications: notifications
      };
    }
    case MARK_ALL_NOTIFICATIONS_READ: {
      const notifications = state.notifications.map(notification => {
        notification.notification_has_viewed = 1;
        return notification;
      });
      return {
        ...state,
        notifications: notifications,
        allNotificationsMarkedRead: true
      };
    }
    case IS_MARKING_ALL_NOTIFICATIONS_READ: {
      return {
        ...state,
        isMarkingAllNotificationsRead: action.payload
      };
    }
    case HAS_MARKED_ALL_NOTIFICATIONS_READ: {
      return {
        ...state,
        hasMarkedAllNotificationsRead: true
      };
    }
    case CLEAR_NOTIFICATIONS_REDUCER: {
      return {
        ...INITIAL_STATE
      };
    }
    case MARK_ACTION_READ: {
      const notifications = state.notifications.map(obj => {
        if (
          (obj.co_action_id && action.payload === obj.co_action_id) ||
          (obj.legacy_data?.user_notification_id &&
            action.payload === obj.legacy_data.user_notification_id)
        ) {
          if (obj.co_user_action) {
            if (obj.co_user_action?.co_user_action_options_json) {
              obj.co_user_action.co_user_action_options_json.has_seen_action = 1;
            } else {
              obj.co_user_action.co_user_action_options_json = {
                has_seen_action: 1
              };
            }
          }
        }
        return obj;
      });
      return {
        ...state,
        notifications: notifications
      };
    }
    case UNMARK_ACTION_READ: {
      const notifications = state.notifications.map(obj => {
        if (
          (obj.co_action_id && action.payload === obj.co_action_id) ||
          (obj.legacy_data?.user_notification_id &&
            action.payload === obj.legacy_data.user_notification_id)
        ) {
          if (obj.co_user_action) {
            if (obj.co_user_action?.co_user_action_options_json) {
              obj.co_user_action.co_user_action_options_json.has_seen_action = 0;
            } else {
              obj.co_user_action.co_user_action_options_json = {
                has_seen_action: 0
              };
            }
          }
        }
        return obj;
      });
      return {
        ...state,
        notifications: notifications
      };
    }
    default:
      return state;
  }
}
