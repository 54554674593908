import { CO_NON_TABULAR_DEFAULT_INDEX } from "../constants/co-constants";
import {
  COContextInterface,
  COQuestionInterface,
  COSectionInterface
} from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "../utils/co-utils";

export const questionsByTableRowIndex = ({
  section
}: {
  section: COSectionInterface;
}): { [key: number]: COQuestionInterface[] } => {
  // sort into arrays of questions keyed by key

  const indexGroupedQuestions: { [key: number]: COQuestionInterface[] } = {};
  for (const question of section?.co_questions || []) {
    let table_row_index = question.co_table_row_index;
    if (isNullOrUndefined(table_row_index) || table_row_index == undefined) {
      table_row_index = 0;
    }
    if (!indexGroupedQuestions[table_row_index]) {
      indexGroupedQuestions[table_row_index] = [];
    }
    indexGroupedQuestions[table_row_index].push(question);
  }

  return indexGroupedQuestions;
};

export const calculatedValueKeyWithTabularSuffix = ({
  calculatedValueKey,
  question
}: {
  calculatedValueKey: string;
  question: COQuestionInterface;
}) => {
  let tabularSuffix = "";
  if (
    !isNullOrUndefined(question.co_table_row_index) &&
    parseInt(question.co_table_row_index + "") >= 0
  ) {
    tabularSuffix = `[${question.co_table_row_index}]`;
  }

  let keyWithTabularSuffix = `${calculatedValueKey}${tabularSuffix}`;
  return keyWithTabularSuffix;
};

// instead of ah-q-blahdfs we have ah-q-blahdfs[0] ah-q-blahfds[1]
// this returns a dictionary of keys (the table index keys) with an array of the questions for each key
export const groupJSONFormResponseObjectsByTabularIndex = ({
  jsonSectionQuestions
}: {
  jsonSectionQuestions: [{ [key: string]: any }];
}) => {
  let jsonSections: any[];

  // To support both tabular submissions (array of dictionaries) and non-tabular submissions (original dictionary input)
  if (!Array.isArray(jsonSectionQuestions)) {
    // Add it to an array to share logic of iterating through all json sections and respective question answers
    jsonSections = [jsonSectionQuestions];
  } else {
    // This means they are answering it in a tabular format and it is already an array
    jsonSections = jsonSectionQuestions;
  }

  const groupedJSONQuestionInSectionByTabularIndex = {};
  for (const jsonSection of jsonSections) {
    for (const questionKey of Object.keys(jsonSection)) {
      let splitKey = (questionKey || "").split("[");
      // console.log(
      //   `this key is: ${questionKey} and its split location is ${splitKey} with a length of ${splitKey.length}`
      // );
      if (splitKey.length > 1) {
        const co_table_row_index = parseInt(splitKey[1].replace("]", "") + "");
        // sets on process answer and calculated value
        if (!groupedJSONQuestionInSectionByTabularIndex[co_table_row_index]) {
          groupedJSONQuestionInSectionByTabularIndex[co_table_row_index] = [];
        }
        jsonSection[questionKey].co_calculated_value_key = splitKey[0]; // the non table format key
        groupedJSONQuestionInSectionByTabularIndex[co_table_row_index].push(
          jsonSection[questionKey]
        );
      } else {
        // For questions like the process owner that do not follow the value format
        if (typeof jsonSection[questionKey] !== "object") {
          jsonSection[questionKey] = {
            value: jsonSection[questionKey]
          };
        }
        jsonSection[questionKey].co_calculated_value_key = questionKey;
        if (
          !groupedJSONQuestionInSectionByTabularIndex[
            CO_NON_TABULAR_DEFAULT_INDEX
          ]
        ) {
          groupedJSONQuestionInSectionByTabularIndex[
            CO_NON_TABULAR_DEFAULT_INDEX
          ] = [];
        }
        groupedJSONQuestionInSectionByTabularIndex[
          CO_NON_TABULAR_DEFAULT_INDEX
        ].push(jsonSection[questionKey]);
      }
    }
  }
  return groupedJSONQuestionInSectionByTabularIndex;
};
