import { COValidationErrorException } from "../classes/co-validation-error-manager.class";
import {
  COValidationContext,
  COValidationError,
  COValidationItemInterface,
  COContextInterface,
  COBaseInterface
} from "../interfaces/co-interfaces";
import {
  doesExistPositiveContextMatch,
  isNullOrUndefined
} from "../utils/co-utils";
import { mergeValidationErrors } from "../utils/co-validation-error.utils";
import {
  automaticValidation,
  validateItem,
  validatorsForPropertyInContext
} from "./co-validation.helper";

export const validateAndThrowException = (
  context: COContextInterface,
  co_object: COBaseInterface
) => {
  let errors = validate({ context, co_object });
  if (errors && errors.length > 0) {
    throw new COValidationErrorException(errors);
  }
};

// find validators that target the propertyKey on the propertyParentObject
// propertyParentObject we'll find by actual object reference like the co_quesiton_meta_json.title
// propertyKey is what we're looking for validators that target.  So for the propertyParentObject - we're looking for the "value" ie validators
// in this context that hit co_quesiton_meta_json.title.value

export const transformedValueInContext = ({
  context,
  propertyKey,
  propertyParentObject, // what we're actually finding
  value,
  validation_context
}: {
  context: COContextInterface;
  propertyKey?: string;
  propertyParentObject?: any; // what we're actually finding
  value?: any; //current value
  validation_context: COValidationContext;
}): string | number | undefined => {
  let validators = validatorsForPropertyInContext({
    context,
    propertyKey,
    propertyParentObject,
    validation_context,
    resolveValidator: false
  }); // we need to handle multiple transformations
  let transformedValue = value;
  let targetPath = validators && validators.length > 0 && validators[0].target;
  if (targetPath && !isNullOrUndefined(transformedValue)) {
    for (const validator of validators) {
      let validationErrors: any = validateItem({
        context,
        validator,
        validation_context,
        pathOverrides: [{ path: targetPath, value: transformedValue }]
      });
      let potentialTransformedValue = (validationErrors &&
      Array.isArray(validationErrors) &&
      validationErrors.length > 0
        ? validationErrors[0]
        : validationErrors
      )?.transformedValue;
      if (!isNullOrUndefined(potentialTransformedValue)) {
        transformedValue = potentialTransformedValue;
      }
    }
  }

  return transformedValue;
};

export const flattenValidationErrors = (
  validationErrors: any[]
): COValidationError[] => {
  let flattenedArray: COValidationError[] = [];
  for (const iterator of validationErrors) {
    if (iterator) {
      if (Array.isArray(iterator)) {
        if (iterator.length > 0) {
          flattenedArray.push(iterator[0]);
        }
      } else {
        flattenedArray.push(iterator);
      }
    }
  }
  return flattenedArray;
};

export const validate = ({
  context,
  co_object,
  validatorsFromParent = []
}: {
  context: COContextInterface;
  co_object: COBaseInterface;
  validatorsFromParent?: COValidationItemInterface[];
}): COValidationError[] => {
  let updatedContext = context;
  if (co_object?.objectClassMapping?.contextKey) {
    let contextInterface: COContextInterface = {};
    contextInterface[co_object.objectClassMapping.contextKey] = co_object;
    updatedContext = context.update?.(contextInterface) || context;
  }
  // very important that I set / reset context here

  let validationErrors: COValidationError[] = [];

  let objectValidations = co_object?.validators || [];
  objectValidations = [...objectValidations, ...validatorsFromParent];

  // check if visible before validating but only for submission - we don't want to not validate assessment customization
  if (
    doesExistPositiveContextMatch(context.display_context, {
      assessment_submission: 1
    })
  ) {
    let isCurrentlyVisible = co_object.isVisibleInContext?.(context);
    //console.log(`checking visibility`);
    if (!isCurrentlyVisible) {
      //console.log("no longer visible, stop checks in sub objects");
      return validationErrors;
    }
  }

  let automaticValidationErrors: COValidationError[] = automaticValidation({
    context: updatedContext,
    callingObject: co_object,
    validation: { validations: objectValidations }
  });

  if (automaticValidationErrors) {
    validationErrors = mergeValidationErrors(
      validationErrors,
      automaticValidationErrors
    );
  }

  //propagate this down

  //validate all sub objects based on the class mapping
  let classMapping = co_object?.objectClassMapping;
  if (classMapping) {
    for (const element of classMapping.objects) {
      let co: COBaseInterface = co_object[element.objectKey];
      if (co) {
        let objectErrors = validate({
          context: updatedContext,
          co_object: co,
          validatorsFromParent: objectValidations
        });
        mergeValidationErrors(validationErrors, objectErrors);
      }
    }
    for (const arrayObject of classMapping.arrays) {
      let objectArray: COBaseInterface[] =
        co_object[arrayObject.objectKey] || [];
      objectArray.map(co => {
        let objectErrors = validate({
          context: updatedContext,
          co_object: co,
          validatorsFromParent: objectValidations
        });
        mergeValidationErrors(validationErrors, objectErrors);
        //return co;
      });
    }
  }
  return validationErrors;
};
