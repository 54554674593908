import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import "./MainFooter.scss";
import urlWithTenant from "../../../utils/urlHelper";

const EXTERNAL = "EXTERNAL";
const INTERNAL = "INTERNAL";

const col1LinkData = [
  {
    text: "Terms & Conditions",
    href: "https://www.uipath.com/legal/terms-of-use",
    type: EXTERNAL
  },
  {
    text: "Privacy Policy",
    href: "https://www.uipath.com/legal/privacy-policy",
    type: EXTERNAL
  },
  {
    text: "Cookies Policy",
    href: urlWithTenant("/cookie-policy"),
    type: INTERNAL
  },
  {
    text: "Contact Us",
    href:
      "https://www.uipath.com/company/contact-us/automation-hub-technical-support",
    type: EXTERNAL
  }
];

const col2LinkData = [
  {
    text: "UiPath.com",
    href: "https://www.uipath.com/",
    type: EXTERNAL
  },
  {
    text: "UiPath Forum",
    href: "https://forum.uipath.com/",
    type: EXTERNAL
  },
  {
    text: "UiPath Academy",
    href: "https://academy.uipath.com/",
    type: EXTERNAL
  },
  {
    text: "UiPath Go!",
    href: "https://go.uipath.com/",
    type: EXTERNAL
  }
];

class MainFooter extends PureComponent {
  createColumn = columnData => {
    const ExternalContainer = ({ children, href }) => (
      <a href={href} className="MainFooter__link">
        {children}
      </a>
    );

    const InternalContainer = ({ children, href }) => (
      <Link href={href} className="MainFooter__link">
        {children}
      </Link>
    );

    return columnData.map(linkObj => {
      let Container = ExternalContainer;
      if (columnData.type === INTERNAL) {
        Container = InternalContainer;
      }
      return (
        <li key={linkObj.text + linkObj.href}>
          <Container href={linkObj.href}>{linkObj.text}</Container>
        </li>
      );
    });
  };

  render() {
    return (
      <footer className="MainFooter" data-testid="MainFooter">
        <div className="MainFooter__top-wrapper">
          <nav className="MainFooter__top-container">
            <div className="MainFooter__top-column">
              <h3 className="MainFooter__column-header">Help</h3>
              <ul>{this.createColumn(col1LinkData)}</ul>
            </div>
            <div className="MainFooter__top-column">
              <h3 className="MainFooter__column-header">Related Links</h3>
              <ul>{this.createColumn(col2LinkData)}</ul>
            </div>
          </nav>
        </div>
        <div className="MainFooter__bottom-wrapper">
          <div className="MainFooter__bottom-container">
            <p className="MainFooter__bottom-container-text MainFooter__bottom-container-text--name">
              UiPath Automation Hub
            </p>
            <p className="MainFooter__bottom-container-text MainFooter__bottom-container-text--copy">
              &copy; 2019 - {new Date().getFullYear()} UiPath - All Rights
              Reserved
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default MainFooter;
