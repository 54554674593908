//= =====================
// Component phase color constants
//= =====================
export const YELLOW = "yellow";
export const GREEN = "green";
export const RED = "red";
export const BLUE = "blue";
export const GRAY = "gray";

export const CLASSNAME_BLUE = "info";
export const CLASSNAME_GREEN = "success";
export const CLASSNAME_RED = "error";
export const CLASSNAME_YELLOW = "warning";
export const CLASSNAME_GRAY = "default";
