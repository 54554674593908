import React from "react";
import Login from "./../../components/Login/Login";

const LoginPage = props => (
  <React.Fragment>
    <Login />
  </React.Fragment>
);

export default LoginPage;
