import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { uploadImage } from "../../../actions/fileTransferActions";
import {
  setUploadedImageURL,
  setUploadImageCompletionFunction
} from "../../../actions/modalImageUploaderActions";
import swal from "../../SweetAlert/SweetAlert";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import AddDocumentDropBox from "../ModalAddUpdateDocument/AddDocumentDropBox/AddDocumentDropBox";
import "./ModalImageUploader.scss";
import { PROFILE_IMAGE } from "../../../constants/fileUploadConstants";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import { Chip } from "@material-ui/core";

class ModalImageUploader extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      fileData: null
    };
  }

  renderUpload = () => {
    if (!this.state.fileData) {
      return (
        <AddDocumentDropBox handleFileChange={this.handleFileChange}>
          <p className="ModalImageUploader__upload-text-top">
            {/* prettier-ignore */}
            <Trans
              t={this.props.t}
              i18nKey="modal_image_uploader_add_image_here"
            >
              <span className="ModalImageUploader__upload-text-top-highlight">Add image</span> or drop image
              here
            </Trans>
          </p>
          <p className="ModalImageUploader__upload-text-bottom">
            {this.props.t("modal_image_uploader_maxium_size")} <br />
            {this.props.t("modal_image_uploader_allowed_file")}
          </p>
        </AddDocumentDropBox>
      );
    } else if (this.state.fileData && this.state.fileData.name) {
      return (
        <div className="ModalImageUploader__main-chip-container">
          <Chip
            label={this.state.fileData.name}
            onDelete={this.handleClearFile}
          />
        </div>
      );
    }
  };

  handleFileChange = fileData => {
    if (fileData?.size > 5242800) {
      swal.fire({
        title: this.props.t("error_title_file_too_large", "File too large"),
        text: this.props.t(
          "error_text_file_too_large_5",
          "File must be less than 5MB"
        )
      });
      return false;
    }
    this.setState({ fileData: fileData });
  };

  handleClearFile = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileData: null });
  };

  renderPreview = () => {
    let activeImageUrl = this.props.uploadedURL;
    if (this.state.fileData) {
      activeImageUrl = URL.createObjectURL(this.state.fileData);
    }

    if (!activeImageUrl && !this.state.fileData) {
      return null;
    }
    return (
      <div>
        <img
          className="ModalImageUploader__preview_image"
          src={activeImageUrl}
          alt="Preview"
        />
      </div>
    );
  };

  closeModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.closeModal();
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.fileData && this.state.fileData.size) {
      this.props.uploadImage(
        this.state.fileData,
        this.saveLinkAfterUpload,
        PROFILE_IMAGE
      );
    } else {
      this.props.closeModal();
    }
  };

  saveLinkAfterUpload = fileData => {
    this.props.setUploadedImageURL(fileData.url);
    if (this.props.uploadedCompletionFunct) {
      if (this.props.uploadedCompletionFunct) {
        this.props.uploadedCompletionFunct(fileData.url);
      }
      this.props.setUploadImageCompletionFunction(null);
    }
    this.props.closeModal();
  };

  render() {
    let label = this.props.t(["modal_image_uploader_save", "Save"]);

    const modalContentStyle = {
      padding: "0",
      borderRadius: "4px",
      width: "500px",
      maxWidth: "96%"
    };

    const headerStyle = {
      borderBottom: "0",
      padding: "20px 30px 0",
      marginBottom: 0
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={modalContentStyle}>
        <ModalTemplateOne
          heading={this.props.t("modal_image_uploader_select_image")}
          headerStyle={headerStyle}
          onClose={this.props.closeModal}
        >
          <form
            className="ModalImageUploader"
            onSubmit={this.handleSubmit}
            autoComplete="off"
            data-testid="ModalImageUploader"
          >
            <div className="ModalImageUploader__header">
              <div className="ModalImageUploader__header-bottom"></div>
            </div>
            <div className="ModalImageUploader__preview">
              {this.renderPreview()}
            </div>
            <div className="ModalImageUploader__main">
              {this.renderUpload()}
            </div>
            <div className="ModalImageUploader__footer">
              <ButtonApolloSecondary type="reset" onClick={this.closeModal}>
                {this.props.t(["modal_image_uploader_cancel", "Cancel"])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                type="submit"
                className="ModalImageUploader__footer-save-btn"
              >
                {label}
              </ButtonApolloPrimary>
            </div>
          </form>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  uploadedURL: state.imageUpload.uploadedURL,
  uploadedCompletionFunct: state.imageUpload.uploadedCompletion
});

const mapActionsToProps = {
  uploadImage,
  setUploadedImageURL,
  setUploadImageCompletionFunction
};
export default withTranslation("common")(
  connect(mapStateToProps, mapActionsToProps)(ModalImageUploader)
);
