import {
  CORoleType,
  CORoleSlugType
} from "../constants/actions/co-roles.constants";

export const slugsForRoleType = (
  role: string | CORoleType
): CORoleSlugType[] => {
  let slugs: CORoleSlugType[] = [];
  switch (role) {
    case CORoleType.SYSTEM_ADMIN: {
      return [CORoleSlugType.AH_SYSTEM_ADMIN];
    }
    case CORoleType.PROGRAM_MANAGER: {
      return [CORoleSlugType.AH_PROGRAM_MANAGER];
    }
    case CORoleType.CURATOR: {
      return [CORoleSlugType.AH_COMPONENT_CURATOR];
    }
    case CORoleType.DIRECT_MANAGER: {
      // This isn't linked to a direct role, it's just the user's direct manager
      // and we don't use Direct Managers anymore
      return [];
    }
    case CORoleType.IDEA_APPROVER: {
      return [CORoleSlugType.AH_IDEA_APPROVER];
    }
    case CORoleType.SUBMITTER:
    case CORoleType.AUTOMATION_SUBMITTER: {
      return [
        CORoleSlugType.AH_SUBMITTER,
        CORoleSlugType.AH_COMPONENT_SUBMITTER,
        CORoleSlugType.AH_TOP_DOWN_SUBMITTER,
        CORoleSlugType.AH_AUTOMATION_SUBMITTER,
        CORoleSlugType.AH_PROCESS_MINING_SUBMITTER,
        CORoleSlugType.AH_TASK_MINING_SUBMITTER,
        CORoleSlugType.AH_CHANGE_REQUEST_SUBMITTER
      ];
    }
    case CORoleType.PROCESS_OWNER: {
      return [CORoleSlugType.AH_PROCESS_OWNER];
    }
    case CORoleType.PROJECT_MANAGER: {
      return [CORoleSlugType.AH_PROJECT_MANAGER];
    }
    case CORoleType.AP_COLLABORATOR: {
      // we will handle collaborators separately with user_ids
      return [];
    }
    case CORoleType.FOLLOWER_IN_AP: {
      // we don't have a followers role
      return [];
    }
    case CORoleType.STANDARD_USER: {
      // while there is a standard_user role, we really just want the 1 target user for the notification
      // so dont pass a role here
      return [];
    }
    case CORoleType.COMPONENT_AUTHOR: {
      return [CORoleSlugType.AH_COMPONENT_SUBMITTER];
    }
    case CORoleType.COMPONENT_DOWNLOADED_USER: {
      return [CORoleSlugType.AH_COMPONENT_DOWNLOADER];
    }
    case CORoleType.BUSINESS_REVIEWERS: {
      // This also refers to the permission for business reviewer
      return [CORoleSlugType.AH_BUSINESS_REVIEWER];
    }
    case CORoleType.TECHNICAL_REVIEWERS: {
      // This also refers to the permission for technical reviewer
      return [CORoleSlugType.AH_TECHNICAL_REVIEWER];
    }
    case CORoleType.POWER_USER: {
      return [CORoleSlugType.AH_POWER_USER];
    }
    case CORoleType.INTERESTED_IN_APP: {
      // we don't have an interested role
      return [];
    }
    case CORoleType.PROCESS_CONSUMER: {
      return [CORoleSlugType.AH_AUTOMATION_CONSUMER];
    }
    case CORoleType.COMMENT_MENTIONED_USER:
    case CORoleType.COMMENT_USER: {
      // we don't have a role for comment mentioned users
      return [];
    }
    case CORoleType.PROCESS_COLLABORATOR: {
      // this will just be the target user of the email, so not a specific role
      return [];
    }
    case CORoleType.ACCOUNT_OWNER: {
      return [CORoleSlugType.AH_ACCOUNT_OWNER];
    }
    case CORoleType.ASSESSED_IDEA_REVIEWERS: {
      // This refers to a permission, not a role
      return [];
    }
    case CORoleType.DEALHUB:
    case CORoleType.FINANCE: {
      // These aren't roles/permissions. These refer to constants on the tenant
      // related to Sales
      return [];
    }
    case CORoleType.AUTHORIZED_USER: {
      return [CORoleSlugType.AH_AUTHORIZED_USER];
    }
    case CORoleType.TRIGGER_USER: {
      // This refers to the user who triggered the platform event for the action
      return [];
    }
  }
  return slugs;
};
