import React, { Component } from "react";
import InputEdit from "./../../InputEdit/InputEdit";

import "./../inputWithLabelStyles.scss";
import "./InputSelectWithLabel.scss";

class InputSelectWithLabel extends Component {
  constructor(props) {
    super(props);
    this.errorRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && !prevProps.error && this.errorRef.current) {
      this.errorRef.current.scrollIntoView({ block: "center" });
    }
  }

  render() {
    const {
      label,
      required,
      onChange,
      placeholder,
      error,
      options = [],
      value,
      name,
      autoFocus,
      classNamePrefix
    } = this.props;

    const dropdownOptions = {
      options,
      onChange,
      value,
      name,
      placeholder,
      classNamePrefix
    };

    return (
      <label className="inputWithLabelStyles InputSelectWithLabel">
        <p className="inputWithLabelStyles__label">
          {label}
          {required && (
            <span className="inputWithLabelStyles__label-required">*</span>
          )}
        </p>
        <div className="inputWithLabelStyles__input-wrapper">
          <InputEdit
            inputType="dropdown"
            autoFocus={autoFocus}
            placeholder={placeholder}
            dropdownOptions={dropdownOptions}
          />
        </div>
        {error && (
          <p className="inputWithLabelStyles__error" ref={this.errorRef}>
            {error}
          </p>
        )}
      </label>
    );
  }
}

export default InputSelectWithLabel;
