import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import { hideModal } from "../../../actions/modalActions";
import { CREATE_RENAME_SAVE_VIEW_MODAL } from "../../../constants/modalTypes";
import { SaveViewEntryType } from "../../../types/saveViewTypes";
import { NON_WHITESPACE_CHAR_REGEX } from "../../../constants/regexConstants";

const SAVE_VIEW_NAME_MAX_LENGTH = 255;

const ModalStyled = styled(Modal)`
  .Modal__content {
    width: 416px;
    padding: 24px;
    border-radius: 3px;
  }
`;

const ModalTemplateOneStyled = styled(ModalTemplateOne)`
  .ModelTemplateOne__header {
    padding: 0;
  }
`;

const InputTextApolloStyled = styled(InputTextApollo)`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  margin-top: 58px;
  display: flex;
  justify-content: end;
`;

const buttonStyle = css`
  && {
    min-width: 112px;
  }
`;

const CancelButton = styled(ButtonApolloSecondary)`
  ${buttonStyle}
  && {
    margin-right: 8px;
  }
`;
const ConfirmButton = styled(ButtonApolloPrimary)`
  ${buttonStyle}
`;

const ModalCreateRenameSaveView = ({
  modalProps,
  hideModalReducer
}: {
  modalProps: {
    isNew: boolean;
    saveViewObj: SaveViewEntryType;
    afterConfirm: (updatedSaveViewObj: SaveViewEntryType) => Promise<void>;
  };
  hideModalReducer: (modalType: string) => void;
}) => {
  const { isNew = false, saveViewObj, afterConfirm } = modalProps;
  const { t } = useTranslation("common");
  const [saveViewName, setSaveViewName] = useState(
    isNew ? "" : saveViewObj?.save_view_sub_name || ""
  );
  const [errorText, setErrorText] = useState("");

  const headingText = useMemo(() => {
    return isNew
      ? t(["modal_create_rename_save_view_header_new", "Save view"])
      : t(["modal_create_rename_save_view_header_update", "Rename view"]);
  }, [isNew]);

  const onNameChange = e => {
    setErrorText("");
    setSaveViewName(e?.target?.value || "");
  };

  const onConfirm = () => {
    if (!NON_WHITESPACE_CHAR_REGEX.test(saveViewName)) {
      setErrorText(
        t([
          "modal_create_rename_save_view_error_text",
          "Please enter a valid name"
        ])
      );
      return;
    }
    const updatedSaveViewObj: SaveViewEntryType = {
      ...saveViewObj,
      save_view_sub_name: saveViewName
    };

    closeModal();
    afterConfirm(updatedSaveViewObj);
  };

  const closeModal = () => {
    hideModalReducer(CREATE_RENAME_SAVE_VIEW_MODAL);
  };

  return (
    <ModalStyled onClose={closeModal}>
      <ModalTemplateOneStyled heading={headingText} onClose={closeModal}>
        <InputTextApolloStyled
          label={t(["modal_create_rename_save_view_input_label", "View name"])}
          InputProps={{ value: saveViewName, onChange: onNameChange }}
          maxLength={SAVE_VIEW_NAME_MAX_LENGTH}
          error={!!errorText}
          helperText={errorText}
        />
        <ButtonsContainer>
          <CancelButton onClick={closeModal}>
            {t(["modal_create_rename_save_view_cancel_button", "Cancel"])}
          </CancelButton>
          <ConfirmButton onClick={onConfirm}>
            {t(["modal_create_rename_save_view_ok_button", "Ok"])}
          </ConfirmButton>
        </ButtonsContainer>
      </ModalTemplateOneStyled>
    </ModalStyled>
  );
};

const mapStateToProps = ({ modal }) => ({
  modalProps: modal.modalProps
});

const mapDispatchToProps = {
  hideModalReducer: hideModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCreateRenameSaveView);
