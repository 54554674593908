import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { focusOnFirstFocusableElement } from "../../../utils/accessibilityUtils/accessibilityUtils";
import ButtonApolloPrimary from "../ButtonApolloPrimary/ButtonApolloPrimary";
import { MAIN_SKIP_LINK_LANDMARK } from "../../../constants/accessibilityConstants";

const ButtonSkipLinkStyles = styled(ButtonApolloPrimary)`
  &&& {
    position: absolute;
    transform: translateY(-100%);
    transition: transform .3s;
    z-index: 9999;
  
    &:focus {
      transform: translateY(0);
    }
  }
`;

const ButtonSkipLink = () => {
  const { t } = useTranslation("common");

  const handleClick = (e) => {
    e.preventDefault();
    const container = document.getElementById(MAIN_SKIP_LINK_LANDMARK);
    if (container) {
      focusOnFirstFocusableElement(container);
    }
  }

  return (
    <ButtonSkipLinkStyles onClick={handleClick}>
      {t(["skip_to_main_content", "Skip to main content"])}
    </ButtonSkipLinkStyles>
  );
};

export default ButtonSkipLink;