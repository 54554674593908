import {
  COProcessAnswerSelectionInterface,
  CODocumentInfo
} from "../../../interfaces/co-interfaces";
import { isPath } from "../../../utils/co-path.utils";

export const documentSelectedControlCallback = ({
  control,
  context,
  document
}) => {
  // the document control uses the process_answer_selection object - but process_answer_selection can hold an array of documents
  // the control index maps to the index within the process_answer_selection documents
  // basically all of this is to deal with the Q1 document picker that is a multiple choice question where a selected answer has multiple document selection controls
  // however we also need to deal with the CR and Q3 document picker - which is a question that has multiple answer options (not a answer option that allows an array of documents)
  // for the Q3 and CR the control key needs to be unique to the process_answer_selection - not just the control so we append the co_process_answer_selection_ahid to it
  let processAnswerSelection: COProcessAnswerSelectionInterface | undefined =
    context.process_answer_selection;

  let existing_document_data: CODocumentInfo = context?.process_external_data
    ?.document_data || { documents: [] };

  if (processAnswerSelection && document.documentData) {
    // documents are now stored in an array here -
    let co_control_key =
      (control.key || "") +
        "_" +
        processAnswerSelection.co_process_answer_selection_ahid || ""; // append ahid so that this works for multiple document answers as well

    // remove existing
    existing_document_data.documents = (
      existing_document_data.documents || []
    ).filter(doc => doc.co_control_key != co_control_key);

    // we're setting the title and item at the proper index
    if (!processAnswerSelection.co_process_answer_selection_info_json) {
      processAnswerSelection.co_process_answer_selection_info_json = {};
    }

    document.documentData.co_control_key = co_control_key;
    processAnswerSelection.co_process_answer_selection_info_json[
      co_control_key
    ] = document?.documentData;

    // this is tricky = we only to update this if each answer selection has it's control
    // if the control is stored in the answer option - then it needs to be contextual because the control meta
    if (control?.meta?.title) {
      if (isPath(control.meta.title.value)) {
        // don't update if it's a path
      } else {
        control.meta.title.value =
          document?.documentData.document_title || "Unknown";
      }
    }

    // to do moved to sync function - shared with the prefill and the add / remove buttons
    processAnswerSelection.co_process_answer_selection_input = Object.values(
      processAnswerSelection.co_process_answer_selection_info_json
    )
      .map((documentData: any) => {
        return documentData
          ? documentData?.document_title || "Unknown"
          : "Unknown";
      })
      .join(",");

    // add the doc to the documents external values
    existing_document_data.documents?.push(document.documentData);

    const ped: any = context?.process_external_data;
    if (ped && ped.syncDocuments) {
      ped.syncDocuments?.(existing_document_data.documents);
    }
  }
};
