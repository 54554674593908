import React, { useMemo } from "react";
import styled, { css } from "styled-components/macro";
import Avatar from "../../Avatar/Avatar";

const EntryContainer = styled.div<{ $isFocused?: boolean }>(
  ({ $isFocused }) => css`
    background: ${$isFocused
      ? "var(--color-secondary-focused, #e9f1fa)"
      : `var(--color-background, #fff)`};
    color: var(--color-foreground-link, #0067df);
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4px;
    position: relative;
    z-index: 1;
    min-height: 48px;
    width: 100%;
    cursor: pointer;
  `
);

export const MentionEntry = props => {
  const { mention, isFocused, ...parentProps } = props;

  const initials = useMemo(() => {
    let value = "?";
    if (mention.name && typeof mention.name === "string") {
      const nameParts: string[] = mention.name.split(" ");
      if (nameParts.length === 1 && nameParts[0]) {
        value = nameParts[0].charAt(0).toUpperCase();
      } else if (nameParts.length === 2 && nameParts[0] && nameParts[1]) {
        value =
          nameParts[0].charAt(0).toUpperCase() +
          nameParts[1].charAt(0).toUpperCase();
      } else if (
        nameParts.length > 2 &&
        nameParts[0] &&
        nameParts[nameParts.length - 1]
      ) {
        value =
          nameParts[0].charAt(0).toUpperCase() +
          nameParts[nameParts.length - 1].charAt(0).toUpperCase();
      }
    }
    return value;
  }, [mention.name]);

  return (
    <EntryContainer {...parentProps} $isFocused={isFocused}>
      <Avatar image={mention.avatar} width="32px" initials={initials} />
      <div>{mention.name}</div>
    </EntryContainer>
  );
};
