import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import ModalAccountLicense from "../ModalAccountLicense";
import { swalClose } from "../../../SweetAlert/SweetAlert";
import { successSwal } from "../../../../utils/accountLicenseAlerts";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../../../../utils/SweetAlertLoaderSpinner";
import { hideModal } from "../../../../actions/modalActions";
import { setExtendAccountTrial } from "../../../../actions/accountLicenseActions";
import {
  EXTEND_TRIAL_FIELDS,
  TENANT_NAME
} from "../../../../constants/accountLicenseConstants";
import SessionHelper from "../../../../helpers/SessionHelper";
import { parseDataForRequest } from "../../../../utils/accountLicenseUtils";
import { validateExtendTrialFields } from "../../../../utils/accountLicenseValidationHelper";

class ModalExtendAccountTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: cloneDeep(EXTEND_TRIAL_FIELDS)
    };
  }

  setStateWithObj = obj => {
    this.setState(obj);
  };

  handleSave = () => {
    const validationObj = validateExtendTrialFields(this.state.fields);

    if (validationObj.hasError) {
      this.setStateWithObj({ fields: validationObj.updatedState });
    } else {
      const parsedData = parseDataForRequest(this.state.fields);
      parsedData[TENANT_NAME] = SessionHelper.getTenantNameUserToken();
      this.sendRequest(parsedData);
    }
  };

  sendRequest = data => {
    swalSpinnerLoading();
    this.props.setExtendAccountTrial({
      data: data,
      callback: this.afterSaveRequest
    });
  };

  afterSaveRequest = response => {
    swalSpinnerClose();
    this.props.hideModal();
    successSwal({});

    setTimeout(() => {
      swalClose();
    }, 2000);
  };

  render() {
    return (
      <ModalAccountLicense
        header={this.props.t(["modal_extend_trial_header", "Extend Trial"])}
        requestSwalTitle={this.props.t([
          "modal_extend_trial_title",
          "Request Trial Extension?"
        ])}
        fields={this.state.fields}
        setStateWithObj={this.setStateWithObj}
        handleCancel={this.handleCancel}
        handleSave={this.handleSave}
        closeModalCallback={this.closeModalCallback}
      />
    );
  }
}

const mapDispatchToProps = {
  hideModal,
  setExtendAccountTrial
};
export default withTranslation("common")(
  connect(null, mapDispatchToProps)(ModalExtendAccountTrial)
);
