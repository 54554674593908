import { updatePositionSortOrderForItems } from "../helpers/co-position.helper";
import { toNumber } from "../helpers/co-validation.helper";
import {
  COContextInterface,
  COProcessAnswerDBClass,
  COProcessAnswerInterface,
  COProcessAnswerSelectionInterface,
  COProcessAnswerTemplateInterface,
  COQuestionAnswerOptionInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { getValue } from "../utils/co-path.utils";
import { COBase } from "./co-base.class";
import { COContextObjectKey, COTypes } from "../constants/co-constants";
import { COBaseTemplate } from "./co-base-template.class";
import { objectDeepCopy, optionsInContext } from "../helpers/co-context.helper";
import { splitDelimittedValues } from "../helpers/co-question.helper";
import { isNullOrUndefined } from "../utils/co-utils";
import { StandardProcessAnswerSlugs } from "../constants/co-question.constants";
import { PATH } from "../constants/co-path.constants";
import { COProcessAnswerSelection } from "./co-process-answer-selection.class";
import { loadTemplateForSlugWithoutImporting } from "../helpers/co-circular-refrence-helper";

export interface COProcessAnswer extends COProcessAnswerInterface {}
export class COProcessAnswer extends COBase {
  static getClassName(): string {
    return "COProcessAnswer";
  }
  constructor({
    propertiesFromJSON,
    context,
    template,
    question
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COBaseTemplate;
    question?: COQuestionInterface;
  }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [
        {
          objectKey: "co_process_answer_selections",
          objectClass: COProcessAnswerSelection
        }
      ],
      objects: [],
      dbClass: COProcessAnswerDBClass,
      contextKey: COContextObjectKey.PROCESS_ANSWER,
      ahid_key: "co_process_answer_ahid",
      objectClass: COProcessAnswer
    };

    // meaning we're creating a new process answer - not loading from json
    if (question && context) {
      this[
        this.objectClassMapping.ahid_key || ""
      ] = COProcessAnswer.generateAHID();

      // apply default template at runtime
      let defaultTemplate: COProcessAnswerTemplateInterface = loadTemplateForSlugWithoutImporting(
        question.co_question_co_type === COTypes.QUESTION ||
          question.co_question_co_type === COTypes.CALCULATED_VALUE_REFERENCE
          ? StandardProcessAnswerSlugs.QUESTION
          : StandardProcessAnswerSlugs.KPI
      );

      if (defaultTemplate?.co_process_answer_json) {
        let deepCopy = objectDeepCopy(defaultTemplate?.co_process_answer_json);
        for (const key of Object.keys(deepCopy)) {
          this[key] = deepCopy[key];
        }
        this.template = defaultTemplate;
      }

      this.co_question_ahid = question.co_question_ahid;
      this.co_question_id = question.co_question_id;
      this.co_table_row_index = question.co_table_row_index;
      this.co_process_answer_selections = [];

      this.initializeDefaultAnswers?.({
        context,
        question
      });
    }

    this.checkAndLoadTemplate?.();
  }

  templateSlug?() {
    return this.co_process_answer_template_slug;
  }

  objectDisplayName?(includeObjectType?: boolean): string {
    return "Selected Answer";
  }

  prepForSubmissionViewOrEdit?({ context }: { context: COContextInterface }) {
    for (const process_answer_selection of this.co_process_answer_selections ||
      []) {
      const pas: COProcessAnswerSelection = process_answer_selection;
      pas.prepForSubmissionViewOrEdit?.({
        context: context.update?.({ process_answer_selection }) || context
      });
    }
  }

  // handle prefill logic, moved here for better organization
  initializeDefaultAnswers?({ context, question }) {
    let prefillValue = question.co_question_meta_json?.prefill?.value;

    let optInCtx = optionsInContext({
      context,
      options: context.question?.options
    });

    let prefillOptionSelected = optInCtx.pre_fill_default_answer;

    let pre_fill_default_answer =
      prefillOptionSelected && !isNullOrUndefined(prefillValue);

    // we might have a prefill value coming from external answer source
    if (
      prefillOptionSelected &&
      isNullOrUndefined(prefillValue) &&
      question.options?.external_answer_source
    ) {
      // lets see if we have it
      let path = PATH({ route: question.options.external_answer_source });
      let externalValue = getValue(context, path);
      if (!isNullOrUndefined(externalValue)) {
        prefillValue = externalValue;
        pre_fill_default_answer = true;
      }
    }

    // for questions that have input_is_value (free number or text input)
    // we need to automatically add an "answer option" which is used to
    // hold the value the user inputs
    // (in this system all questions have answer options, not just multiple select).
    if (
      question.co_question_answer_options &&
      question.co_question_answer_options.length === 1
    ) {
      const firstAnswerOption = question.co_question_answer_options[0];
      const isAutoSelected = getValue(
        context,
        firstAnswerOption.calculation_options?.input_is_value
      );

      if (isAutoSelected) {
        this.selectOrUpdateOption?.({
          context,
          question,
          answerOption: firstAnswerOption
        });
      }

      if (pre_fill_default_answer) {
        let answerSelection =
          (this.co_process_answer_selections?.length || 0) > 0 &&
          this.co_process_answer_selections?.[0];
        if (answerSelection) {
          answerSelection.co_process_answer_selection_input = prefillValue;
        }
      }
    } else if (question.co_question_answer_options && pre_fill_default_answer) {
      // it could be an ahid - or it could be a value coming from external data.
      // this is for single/multiple choice questions
      const prefillValues = splitDelimittedValues(
        question?.co_question_meta_json?.prefill?.value
      );
      const answerOptionsToPrefill = question.co_question_answer_options.filter(
        answer_option =>
          prefillValues.some(
            value =>
              value === answer_option.co_question_answer_option_ahid ||
              value == answer_option.co_question_answer_option_value // lose compare might be a string to int
          )
      );

      answerOptionsToPrefill.forEach(option =>
        this.selectOrUpdateOption?.({
          context,
          question,
          answerOption: option
        })
      );
    }

    if (
      prefillOptionSelected &&
      !isNullOrUndefined(
        context?.question?.options?.pre_fill_default_answer_function
      ) &&
      typeof context.question.options.pre_fill_default_answer_function ===
        "function"
    ) {
      context.question.options.pre_fill_default_answer_function(context);
    }
  }

  // function from context pathingn
  // used in control via path system - do not change name unless updating templates
  functionSelectedAnswerOptionsLegacySlugs?(context: COContextInterface) {
    let currentProcessAnswer: COProcessAnswer | undefined =
      context.process_answer;
    if (currentProcessAnswer) {
      return (currentProcessAnswer.co_process_answer_selections || [])
        .map(
          selection =>
            selection.co_question_answer_option
              ?.co_question_answer_option_options_json?.legacy_slug || ""
        )
        .join(",");
    }
    return "";
  }

  //FE helper function
  selectOrUpdateOption?({
    context,
    question,
    answerOption
  }: {
    context: COContextInterface;
    question: COQuestionInterface;
    answerOption: COQuestionAnswerOptionInterface;
  }): COProcessAnswerSelectionInterface | undefined {
    if (answerOption) {
      //ok find the selected answer option and update it or select it
      if (!this.co_process_answer_selections) {
        this.co_process_answer_selections = [];
      }

      let selectedAnswer:
        | COProcessAnswerSelection
        | undefined = this.co_process_answer_selections.find(
        selectedAnswer =>
          selectedAnswer.co_question_answer_option_ahid ===
          answerOption.co_question_answer_option_ahid
      );
      if (selectedAnswer && selectedAnswer.updateAnswer) {
        selectedAnswer.updateAnswer(context, answerOption);
      } else {
        selectedAnswer = new COProcessAnswerSelection({
          context,
          processAnswer: this,
          answerOption
        });

        let max_selectable_answers = getValue(
          context,
          question.options?.max_selectable_answers
        );

        if (
          max_selectable_answers &&
          this.co_process_answer_selections.length + 1 >
            toNumber(max_selectable_answers)
        ) {
          // this should make things like true / false work automatically
          this.co_process_answer_selections.pop(); //kickout last in option
        }
        this.co_process_answer_selections.push(selectedAnswer);
      }
      updatePositionSortOrderForItems(this.co_process_answer_selections);

      return selectedAnswer;
    }
  }

  addAdditionalAnswerSelection?({
    context,
    question,
    answerOption
  }: {
    context: COContextInterface;
    question: COQuestionInterface;
    answerOption: COQuestionAnswerOptionInterface;
  }): COProcessAnswerSelection | undefined {
    if (answerOption) {
      if (!this.co_process_answer_selections) {
        this.co_process_answer_selections = [];
      }

      let max_selectable_answers = getValue(
        context,
        question.options?.max_selectable_answers
      );

      if (
        max_selectable_answers &&
        this.co_process_answer_selections.length + 1 >
          toNumber(max_selectable_answers)
      ) {
        return;
      }

      let selectedAnswer: COProcessAnswerSelection = new COProcessAnswerSelection(
        {
          context,
          processAnswer: this,
          answerOption
        }
      );

      this.co_process_answer_selections.push(selectedAnswer);
      updatePositionSortOrderForItems(this.co_process_answer_selections);
      return selectedAnswer;
    }
  }

  //FE helper function
  deSelectOption?({
    context,
    answerOption
  }: {
    context: COContextInterface;
    answerOption: COQuestionAnswerOptionInterface;
  }) {
    this.co_process_answer_selections = this.co_process_answer_selections?.filter(
      selection =>
        selection.co_question_answer_option_ahid !==
        answerOption.co_question_answer_option_ahid
    );
    updatePositionSortOrderForItems(this.co_process_answer_selections || []);
  }
}
