import { COCalculatedValueTypes } from "../../src/constants/co-constants";
import { COContext } from "../classes/co-context.class";
import { COQuestionAnswerOption } from "../classes/co-question-answer-option.class";
import {
  COCalculatedValueQuestionInfoInterface,
  COFilterOptionItemInterface,
  COFilterType,
  COQueryConditionOperator,
  COMetadataInterface,
  COFilterTargetType
} from "../interfaces/co-interfaces";
import {
  coFormattedPriorityFilterValues,
  legacyFilterOptionsForQuestionLegacySlug,
  legacyFilterOptions,
  legacyFilterOptionsProcessColumn
} from "../templates/elements/pipeline/co-pipeline.legacy-filters";
import { templateFilterOptions } from "../templates/elements/pipeline/co-pipeline.template-filters";
import { toSlug } from "../utils/co-utils";
import { metaInContext, optionsInContext } from "./co-context.helper";
import { COTableCellType } from "../interfaces/co-interfaces";
import { LegacyProcessColumnName } from "../../src/interfaces/co-legacy.interfaces";
import { CustomQuestionSlugs } from "../constants/co-question.constants";
import {
  COExternallyLoadableFilterColumnDynamicFilterTemplate,
  COExternallyLoadableFilterColumns
} from "../constants/co-pipleline.constants";

export const filterOptionsForQuestions = ({
  context,
  questionColumnInfoItems
}: {
  context: COContext;
  questionColumnInfoItems: COCalculatedValueQuestionInfoInterface[];
}): {
  filter_options: COFilterOptionItemInterface[];
  filter_type: COFilterType;
  isFilterable: boolean;
  empty_filter_for_dynamic_filter_selection?: COFilterOptionItemInterface;
  baseFilterOptions?: COFilterOptionItemInterface[];
} => {
  let filterOptions: COFilterOptionItemInterface[] = [];
  let filterType = COFilterType.CALCULATED_VALUE_FILTER;
  let isFilterable = false;
  let emptyFilterForDynamicFilterSelection:
    | COFilterOptionItemInterface
    | undefined = undefined;
  let baseFilterOptions: COFilterOptionItemInterface[] | undefined = undefined;

  for (const item of questionColumnInfoItems) {
    // let's let all the answer options
    let question = item.question || {};
    let qContext = context.update?.({ question }) || context;
    let resolvedQuestionOptions = optionsInContext({
      context: qContext,
      options: question.options || {},
      should_resolve: true
    });

    if (resolvedQuestionOptions.is_hidden) {
      continue;
    }

    // get from template slug or from template (slug preferred)
    let questionFilterOptions: COFilterOptionItemInterface[] = [];
    if (resolvedQuestionOptions.pipeline_filter_options_slug) {
      questionFilterOptions = templateFilterOptions(
        resolvedQuestionOptions.pipeline_filter_options_slug
      );
    }

    // options from external
    let process_column_name = LegacyProcessColumnName(
      question?.co_variable_name || ""
    );
    if (COExternallyLoadableFilterColumns.includes(process_column_name || "")) {
      isFilterable = true;
      filterOptions = [];
      //console.log(`Externally Loaded Filter Option - ${process_column_name}`);
      if (process_column_name) {
        let filterOptionInfo =
          COExternallyLoadableFilterColumnDynamicFilterTemplate[
            process_column_name
          ];
        if (filterOptionInfo) {
          emptyFilterForDynamicFilterSelection =
            filterOptionInfo?.emptyFilterForDynamicFilterSelection || undefined;
          baseFilterOptions = filterOptionInfo?.baseFilterOptions || [];
        }
      }
    }

    // Use resolvedQuestionOptions.legacy_slug to get the CBA legacy column filters
    // options based on legacy slug for backwards compat

    if (resolvedQuestionOptions.legacy_slug) {
      let optAndType = legacyFilterOptionsForQuestionLegacySlug(
        resolvedQuestionOptions.legacy_slug
      );
      if (optAndType) {
        if (optAndType.filterOptions) {
          questionFilterOptions = optAndType.filterOptions;
        }
        if (optAndType.filterType) {
          filterType = optAndType.filterType;
        }
      }
    }

    // will centralize this better
    if (LegacyProcessColumnName(question?.co_variable_name || "")) {
      let optAndType = legacyFilterOptionsProcessColumn(
        LegacyProcessColumnName(question?.co_variable_name || "") || ""
      );
      if (optAndType) {
        if (optAndType.filterOptions) {
          questionFilterOptions = optAndType.filterOptions;
        }
        if (optAndType.filterType) {
          filterType = optAndType.filterType;
        }
      }
    }

    // options coming from template pipeline_filter_options
    if (
      (!questionFilterOptions || questionFilterOptions.length === 0) &&
      resolvedQuestionOptions.pipeline_filter_options &&
      Array.isArray(resolvedQuestionOptions.pipeline_filter_options)
    ) {
      questionFilterOptions = resolvedQuestionOptions.pipeline_filter_options;
    }

    if (
      questionFilterOptions &&
      Array.isArray(questionFilterOptions) &&
      questionFilterOptions.length > 0
    ) {
      for (const filterOption of questionFilterOptions) {
        if (
          !filterOptions.find(
            opt => opt.filter_option_key === filterOption.filter_option_key
          )
        ) {
          filterOptions.push(filterOption);
        }
      }
      // we want to make a difference between the filter options and the potential options to show in the UI
      filterType = COFilterType.CALCULATED_VALUE_FILTER;
    } else if (
      // unit filter - enums for like ease of implementation level
      resolvedQuestionOptions.unit &&
      typeof resolvedQuestionOptions.unit !== "string" &&
      resolvedQuestionOptions?.unit?.display_value_format?.enum_options &&
      Array.isArray(
        resolvedQuestionOptions?.unit?.display_value_format?.enum_options
      )
    ) {
      // we have enum options! Let's filter
      filterType = COFilterType.CALCULATED_VALUE_FILTER;
      for (const enumOpt of resolvedQuestionOptions.unit.display_value_format
        ?.enum_options || []) {
        filterOptions.push({
          filter_option_key: enumOpt.KEY,
          meta: {
            value: enumOpt.KEY
          },
          filter_query_condition: {
            values: [enumOpt.VALUE + ""],
            operator: COQueryConditionOperator.EQUALS
          }
        });
      }
    } else {
      // ok the base multiple single choice filter
      if (question.co_question_answer_options) {
        // add one for each answer option
        filterType = COFilterType.PROCESS_SELECTION_FILTER;
        if (resolvedQuestionOptions?.max_selectable_answers !== 1) {
          filterType = COFilterType.PROCESS_MULTI_SELECTION_FILTER;
        }

        for (const answer_option of question.co_question_answer_options || []) {
          let filterOption = filterOptionForAnswerOption({
            context: qContext.update?.({ answer_option }) || qContext,
            question_answer_option: answer_option
          });
          if (filterOption) {
            let existingFilterOption = filterOptions.find(
              opt => opt.filter_option_key === filterOption?.filter_option_key
            );
            if (existingFilterOption) {
              if (
                existingFilterOption.filter_query_condition?.operator ===
                  COQueryConditionOperator.IN &&
                filterOption.filter_query_condition?.operator ===
                  COQueryConditionOperator.IN &&
                Array.isArray(
                  existingFilterOption.filter_query_condition.values
                ) &&
                Array.isArray(filterOption.filter_query_condition.values)
              ) {
                // then we aggregate
                if (
                  !existingFilterOption.filter_query_condition.values.includes(
                    filterOption.filter_query_condition?.values?.[0]
                  )
                ) {
                  existingFilterOption.filter_query_condition.values = [
                    ...existingFilterOption.filter_query_condition.values,
                    ...filterOption.filter_query_condition.values
                  ];
                }
              } else {
                filterOptions.push(filterOption);
              }
            } else {
              filterOptions.push(filterOption);
            }
          }
        }
      } else {
        //kpi or something
        filterType = COFilterType.CALCULATED_VALUE_FILTER;
      }
    }

    if (
      item?.question?.co_question_options_json?.calculated_value_type ===
      COCalculatedValueTypes.EPOCH
    ) {
      filterType = COFilterType.DATE_PICKER_FILTER;
      isFilterable = true;
    }

    // handled by frontend
    if (
      item?.question?.co_question_options_json?.calculated_value_type ===
        COCalculatedValueTypes.CATEGORY ||
      item?.question?.co_question_options_json?.calculated_value_type ===
        COCalculatedValueTypes.CATEGORY_ARRAY
    ) {
      filterType = COFilterType.CATEGORY_PICKER;
      isFilterable = true;
    }
  }

  if (filterOptions.length > 0) {
    isFilterable = true;
  }

  return {
    filter_options: filterOptions,
    filter_type: filterType,
    isFilterable,
    empty_filter_for_dynamic_filter_selection: emptyFilterForDynamicFilterSelection,
    baseFilterOptions
  };
};

export const filterOptionForAnswerOption = ({
  context,
  question_answer_option
}: {
  context: COContext;
  question_answer_option: COQuestionAnswerOption;
}): COFilterOptionItemInterface | null => {
  if (question_answer_option.calculation_options?.input_is_value) {
    return null;
  } else if (
    question_answer_option.calculation_options?.answer_value_is_question_ahid
  ) {
    // skip for now - we'd need to get really fancy and calculate that question or load it's calculated value
    // this deals with the "daily" answer option
    return null;
  } else {
    let resolved_meta: COMetadataInterface = metaInContext({
      context: context,
      meta: { title: question_answer_option.meta?.title },
      should_resolve: true
    });

    // use legacy slug if we have one for matching up filters

    let titleSlug = toSlug(resolved_meta.title?.value || "");
    let filter_option_key: any =
      question_answer_option.co_question_answer_option_options_json
        ?.legacy_slug || resolved_meta.title?.value
        ? titleSlug
        : question_answer_option.getAHID?.() || "unknown-filter-key";
    // filter input slugs here

    let useActualAnswerValues = false;
    let value = "";
    let operator = COQueryConditionOperator.IN;
    let targetType = COFilterTargetType.ANSWER_VALUE;
    if (useActualAnswerValues) {
      // old way we'd take tha co_question_answer_value - but now we always just want the slug for slug compare
      value = question_answer_option.co_question_answer_option_value || "0";
      // we have an issue if the value is like 0.0 and the calculated value will be 0
      // if it's a number we need to "normalize it"
      if (isNumber(value + "")) {
        value = parseFloat(value) + "";
      }
      targetType = COFilterTargetType.ANSWER_VALUE;
    } else {
      value = titleSlug;
      targetType = COFilterTargetType.ANSWER_TITLE_VALUE;
    }

    return {
      filter_option_key,
      meta: {
        value: resolved_meta.title?.value
      },
      filter_query_condition: {
        targetType,
        values: [value],
        operator
      }
    };
  }
};

export const getCellType = (columnKey, calculated_value_type) => {
  switch (columnKey) {
    case LegacyProcessColumnName(
      CustomQuestionSlugs.STATS_PROCESS_APPLICATIONS
    ):
    case LegacyProcessColumnName(CustomQuestionSlugs.STATS_PROCESS_TAGS):
      return COTableCellType.PILLS;
    case LegacyProcessColumnName(CustomQuestionSlugs.STATS_PDD_LINKS):
      return COTableCellType.LINKS;
    default:
  }
  switch (calculated_value_type) {
    case COCalculatedValueTypes.URL:
    case COCalculatedValueTypes.URL_ARRAY:
      return COTableCellType.LINKS;
    case COCalculatedValueTypes.TAG_ARRAY:
    case COCalculatedValueTypes.APPLICATION_ARRAY:
      return COTableCellType.PILLS;
    case COCalculatedValueTypes.PROCESS:
      return COTableCellType.PROFILE_LINK;
    case COCalculatedValueTypes.DATE_TIME: // eugene you can do a lot with this
    default:
      return COTableCellType.PLAIN_TEXT;
  }
};

const isNumber = str => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};
