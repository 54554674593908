import {
  COContextInterface,
  COControlInterface,
  COMetadataInterface,
  COSectionInterface,
  COSwalPopUpInterface
} from "../../../interfaces/co-interfaces";
import {
  COComponentType,
  COHorizontalAlignment
} from "../../../constants/co-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../constants/co-path.constants";
import { branchConditionSlugs } from "../../../constants/co-question-answer-option.constants";
import {
  questionTitleForBranchingCondition,
  answerOptionTitlesForBranchingCondition
} from "./co-question-branching-controls.template";

export const branchingSectionControlMeta = (): COMetadataInterface => {
  return {
    branching: {
      is_editable: true,
      label: "Section branching logic controls",
      position: { sort_order: 3 },
      options: {
        component_type: COComponentType.META_CONTROL,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_READ_ONLY
        })
      },
      controls: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.FUNCTION_SECTION_BRANCHING_CONTROLS,
        operator: PATH_OPERATORS.FUNCTION
      }),
      display_context: {
        assessment_customize: 1
      }
    }
  };
};

export const confirmRemoveBranchPopUpData: COSwalPopUpInterface = {
  title: "Remove Branch?",
  title_localization_key: "cda_remove_branch_condition_title",
  message:
    "Are you sure you want to remove the branching logic condition from this question?",
  message_localization_key: "cda_remove_branch_condition_text",
  show_cancel_button: true,
  confirm_button_text: "Remove",
  confirm_button_text_localization_key: "cda_remove_button"
};

export const sectionBranchingControls = (
  context: COContextInterface
): COControlInterface[] => {
  let controls: COControlInterface[] = [];

  let currentSection: COSectionInterface | undefined = context.section;
  if (!currentSection || !currentSection.options) {
    return controls;
  }
  let conditions = currentSection.options.condition_overrides;
  const branchConditionSlugStrings: string[] = branchConditionSlugs.map(
    cd => cd + ""
  );
  let selectedBranchConditions =
    conditions?.filter(cond =>
      branchConditionSlugStrings.includes(cond.slug ?? "")
    ) || [];
  if (conditions && context) {
    for (const selectedBranchCondition of selectedBranchConditions) {
      const questionTitle: string = questionTitleForBranchingCondition({
        context,
        selectedBranchCondition
      });
      const answerOptionTitles: string[] = answerOptionTitlesForBranchingCondition(
        { context, selectedBranchCondition }
      );

      let ctrl: COControlInterface = {
        options: {
          component_type: COComponentType.CONTROL_BANNER,
          horizontal_alignment: COHorizontalAlignment.RIGHT
        },
        meta: {
          title: {
            value:
              'This section is hidden until the {{allAnswerOptionTitles}} option is selected on the "{{questionTitle}}" question',
            value_localization_key: "branching_paths_banner_section_message",
            value_localization_values: {
              questionTitle: questionTitle,
              allAnswerOptionTitles: {
                values: answerOptionTitles,
                separator: " or ",
                separator_localization_key:
                  "branching_paths_message_or_separator"
              }
            }
          }
        },
        subControls: [
          {
            meta: {
              title: {
                value: "Remove",
                value_localization_key: "branching_paths_banner_remove"
              }
            },
            options: {
              component_type: COComponentType.CONTROL_BUTTON
            },
            controlFunction: ({
              context,
              control,
              option
            }: {
              context: COContextInterface;
              control: COControlInterface;
              option: any;
            }) => {
              if (context.function_hooks?.showSwalPopUp) {
                context.function_hooks.showSwalPopUp({
                  context,
                  co_swal_pop_up: confirmRemoveBranchPopUpData,
                  callback: confirmed => {
                    if (
                      confirmed &&
                      currentSection?.co_section_options_json
                        ?.condition_overrides &&
                      selectedBranchCondition
                    ) {
                      // we need to remove the condition
                      currentSection.co_section_options_json.condition_overrides = currentSection.co_section_options_json.condition_overrides.filter(
                        cd => {
                          return !(
                            cd.slug === selectedBranchCondition.slug &&
                            cd.payload?.co_question_ahid ===
                              selectedBranchCondition.payload
                                ?.co_question_ahid &&
                            cd.payload?.co_question_answer_option_ahid ===
                              selectedBranchCondition.payload
                                ?.co_question_answer_option_ahid
                          );
                        }
                      );
                    }
                  }
                });
              }
            }
          }
        ]
      };
      controls.push(ctrl);
    }
  }
  return controls;
};
