import {
  doesExistPositiveContextMatch,
  isNullOrUndefined
} from "../utils/co-utils";
import { resolve } from "../utils/co-resolver.utils";

import { trueInContextForRangeConditions } from "./co-condition.helper";
import { conditionOverrideFromSlugPayload } from "../templates/elements/conditions/co-conditions";
import {
  COOptionsInterface,
  COConditionOverrideSlugPayload,
  COConditionOverride,
  COCondition,
  COMetadataInterface,
  COMetadataItemInterface,
  COContextInterface
} from "../interfaces/co-interfaces";

// we have some problematic keys here for jsoning
export const objectDeepCopy = (objectToCopy: any): any => {
  let copyObject = objectToCopy;
  if (objectToCopy) {
    return JSON.parse(JSON.stringify(objectToCopy));

    // if (Array.isArray(objectToCopy)) {
    //   copyObject = objectToCopy.map(item => objectDeepCopy(item));
    // } else if (typeof objectToCopy === "object") {
    //   copyObject = { ...objectToCopy };
    //   delete copyObject["validation_errors"];
    //   for (const key of Object.keys(copyObject)) {
    //     let item = copyObject[key];
    //     copyObject[key] = objectDeepCopy(item);
    //   }
    // }
  }
  return copyObject;
};
export const optionsInContext = ({
  context,
  options,
  should_resolve = false
}: {
  context: COContextInterface;
  options: COOptionsInterface;
  should_resolve?: boolean;
}): COOptionsInterface => {
  let optionsCopy: COOptionsInterface = objectDeepCopy(options); // we need a deep copy here

  let updatedOptions: COOptionsInterface = { ...optionsCopy };

  // first lets apply any condition overrides (which could impact context overrides)
  updatedOptions = applyOverridesToOptions({
    context,
    options: updatedOptions
  });
  // and now let's do the display overrides - doesn't currently work inside a path calculation - but could be moved to the optionsInConditionalCOntext
  // function below but requires a lot of backwards support testing
  let display_context_overrides = optionsCopy?.display_context_overrides;
  if (display_context_overrides) {
    // ok so we find a match
    for (const context_key of Object.keys(context.display_context || {})) {
      if (!isNullOrUndefined(display_context_overrides[context_key])) {
        let context_overrides = display_context_overrides[context_key];
        updateItemWithOverrides(updatedOptions, context_overrides);
      }
    }
  }

  if (should_resolve) {
    let resolved = resolve({ context, item: updatedOptions });
    return resolved;
  }

  return updatedOptions;
};

// used to calculate condition_overrides from with the getter of the options in the coBaseObject
export const optionsInConditionalContext = ({
  context,
  options
}: {
  context: COContextInterface;
  options: COOptionsInterface;
}): COOptionsInterface => {
  let optionsCopy: COOptionsInterface = objectDeepCopy(options); // we need a deep copy here
  let updatedOptions: COOptionsInterface = { ...optionsCopy };
  updatedOptions = applyOverridesToOptions({
    context,
    options: updatedOptions
  });
  return updatedOptions;
};

export const applyOverridesToOptions = ({ context, options }) => {
  let condition_overrides: COConditionOverrideSlugPayload[] =
    options?.condition_overrides || [];
  if (Array.isArray(condition_overrides)) {
    let overrides: COConditionOverride[] = [];
    for (const SP of condition_overrides) {
      let overToAdd = conditionOverrideFromSlugPayload(SP);
      if (overToAdd) {
        overrides.push(overToAdd);
      }
    }

    if (overrides) {
      overrides = overrides.sort((b, a) => {
        return (b.priority || 0) - (a.priority || 0);
      });

      for (const context_override of overrides) {
        if (context_override.conditions || context_override.condition) {
          let conditions: COCondition[] =
            context_override.conditions &&
            context_override.conditions.length > 0
              ? context_override.conditions
              : context_override.condition
              ? [context_override.condition]
              : [];

          for (const condition of conditions) {
            let condition_is_valid = trueInContextForRangeConditions({
              context,
              condition: condition
            });

            if (condition_is_valid) {
              let overrides = condition.overrides;
              if (overrides && Object.keys(overrides).length > 0) {
                updateItemWithOverrides(options, overrides);
              }
            } else {
            }
          }
        }
      }
    }
  }
  return options;
};

export const metaInContext = ({
  context,
  meta,
  should_resolve = false
}: {
  context: COContextInterface;
  meta: COMetadataInterface;
  should_resolve?: boolean;
}): COMetadataInterface => {
  // copy over all items - update for context rules
  // also can remove items here
  let metaCopy: COMetadataInterface = objectDeepCopy(meta); // we need a deep copy here
  let updatedMeta: COMetadataInterface = { ...metaCopy };

  for (const key of Object.keys(metaCopy)) {
    let currentItem: COMetadataItemInterface = metaCopy[key];
    let currentItemInContext = metaItemInContext({
      context,
      metaItem: currentItem
    });
    if (!isNullOrUndefined(currentItemInContext)) {
      updatedMeta[key] = currentItemInContext;
    } else {
      // remove items that are not in context
      delete updatedMeta[key];
    }
  }
  if (should_resolve) {
    return resolve({ context, item: updatedMeta });
  }
  return updatedMeta;
};

export const metaItemInContext = ({
  context,
  metaItem
}: {
  context: COContextInterface;
  metaItem: COMetadataItemInterface;
}): COMetadataItemInterface | null => {
  if (isNullOrUndefined(metaItem)) {
    return null;
  }

  let updatedMetaItem: COMetadataItemInterface = { ...metaItem }; // shallow copy
  let display_context_overrides = metaItem.display_context_overrides;
  if (display_context_overrides) {
    // ok so we find a match
    for (const context_key of Object.keys(context.display_context || {})) {
      if (!isNullOrUndefined(display_context_overrides[context_key])) {
        let context_overrides = display_context_overrides[context_key];
        updateItemWithOverrides(updatedMetaItem, context_overrides);
        // loop through each property and copy it over (overriding it)
      }
    }
  }
  // copy over all items - update for context rules
  // also can remove items here
  if (metaItem.display_context) {
    if (
      !doesExistPositiveContextMatch(
        context.display_context,
        metaItem.display_context
      )
    ) {
      return null;
    }
  }
  return updatedMetaItem;
};

export const updateItemWithOverrides = (
  itemToOverride,
  context_overrides,
  ignoreKey: string[] = []
) => {
  for (const key_to_override of Object.keys(context_overrides)) {
    if (!ignoreKey.includes(key_to_override)) {
      // this is what you're setting
      let itemToOverrideWith = context_overrides[key_to_override];
      if (!isNullOrUndefined(itemToOverrideWith)) {
        if (
          typeof itemToOverrideWith === "object" &&
          itemToOverrideWith !== null
        ) {
          if (isNullOrUndefined(itemToOverride[key_to_override])) {
            itemToOverride[key_to_override] = {};
          }
          updateItemWithOverrides(
            itemToOverride[key_to_override],
            itemToOverrideWith
          );
        } else {
          itemToOverride[key_to_override] = itemToOverrideWith;
        }
      }
    }
  }
};

// Returns the AHID of the highest CO Object in the provided context
// so we know how deep the context currently is in handling/rendering
// answer_option -> question -> section -> assessment
export const ahidOfHighestCOObject = ({
  context
}: {
  context: COContextInterface;
}): string | undefined => {
  if (context.answer_option) {
    return context.answer_option?.co_question_answer_option_ahid;
  } else if (context.question) {
    return context.question?.co_question_ahid;
  } else if (context.section) {
    return context.section?.co_assessment_section_ahid;
  } else if (context.assessment) {
    return context.assessment?.co_assessment_ahid;
  }
  return undefined;
};

// Returns the AHID of the parent of the highest CO Object in the provided context
// For example, if we are on the answer_option, then return the question AHID
export const ahidOfParentOfHighestCOObject = ({
  context
}: {
  context: COContextInterface;
}): string | undefined => {
  if (context.answer_option) {
    return context.question?.co_question_ahid;
  } else if (context.question) {
    return context.section?.co_assessment_section_ahid;
  } else if (context.section) {
    return context.assessment?.co_assessment_ahid;
  }
  return undefined;
};
