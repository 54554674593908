import { COSection } from "../classes/co-assessment-section.class";
import { COAssessment } from "../classes/co-assessment.class";
import { COContext } from "../classes/co-context.class";
import { COQuestion } from "../classes/co-question.class";
import { COTypes } from "../constants/co-constants";
import { COAssessmentInterface, COProcess } from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "../utils/co-utils";
import { canRemoveQuestion } from "./co-section.helper";

export const assessmentIsEmpty = (assessment: any): boolean => {
  if (isNullOrUndefined(assessment)) {
    return true;
  }
  if (Array.isArray(assessment)) {
    return true;
  }
  if (!assessment.co_assessment_template_slug) {
    return true;
  }
  if (!assessment.co_assessment_ahid) {
    return true;
  }
  return false;
};

export const allQuestions = (assessment: COAssessment): COQuestion[] => {
  let allQuestionsInAssessment: COQuestion[] = [];
  for (const section of assessment.co_assessment_sections || []) {
    for (const question of section.co_questions || []) {
      allQuestionsInAssessment.push(question);
    }
  }
  return allQuestionsInAssessment.filter(
    q => q.co_question_co_type === COTypes.QUESTION
  );
};

export const allSectionsAndQuestions = (
  context: COContext,
  assessment: COAssessment
): COQuestion[] => {
  let allSections: COSection[] = [];
  for (const section of assessment.co_assessment_sections || []) {
    if (section.isVisibleInContext?.(context.update?.({ section }) || {})) {
      section.co_questions = (section.co_questions || [])?.filter(q => {
        q.isVisibleInContext?.(context.update?.({ question: q }) || {});
      });
      allSections.push(section);
    }
  }
  return allSections;
};

export const allVisibleQuestions = (
  context: COContext,
  assessment: COAssessment
): COQuestion[] => {
  let allQuestionsInAssessment: COQuestion[] = [];
  for (const section of assessment.co_assessment_sections || []) {
    if (section.isVisibleInContext?.(context.update?.({ section }) || {})) {
      for (const question of section.co_questions || []) {
        if (
          question.isVisibleInContext?.(context.update?.({ question }) || {})
        ) {
          allQuestionsInAssessment.push(question);
        }
      }
    }
  }
  return allQuestionsInAssessment.filter(
    q => q.co_question_co_type === COTypes.QUESTION
  );
};

export const allAnsweredQuestionsFromAssessments = (
  assessments: COAssessment[]
): COQuestion[] => {
  let allQuestionsWithAnswers: COQuestion[] = [];
  for (const assessment of assessments) {
    allQuestionsWithAnswers = [
      ...allQuestionsWithAnswers,
      ...allQuestions(assessment).filter(
        q =>
          (q.co_process_answer?.co_process_answer_selections?.length || 0) >
            0 &&
          !isNullOrUndefined(
            q.co_process_answer?.co_process_answer_selections?.[0]
              .co_question_answer_option_value
          )
      )
    ];
  }
  return allQuestionsWithAnswers;
};

export const canAddSection = ({ context }: { context: COContext }): boolean => {
  // we can do a validator check here or something to see if we can add more
  return true;
};

export const canRemoveSection = ({
  context,
  section
}: {
  context: COContext;
  section: COSection;
}): boolean => {
  let sectionContext = context.update?.({ section }) || {};
  for (const question of section.co_questions || []) {
    if (!canRemoveQuestion({ context: sectionContext, section, question })) {
      return false;
    }
  }
  return true;
};

export const preSubmittedCalculatedValueForProcess = ({
  process,
  process_column
}: {
  process: COProcess;
  process_column: string | null;
}): string | undefined => {
  if (process && process.co_assessments && process_column) {
    for (const assessment_type of Object.keys(process.co_assessments)) {
      let assessment = COAssessment.objectFromJSON(
        process.co_assessments[assessment_type]
      );
      if (assessment) {
        let values = preSubmittedValuesFromAssessmentForProcessColumn({
          // if the assessment is coming straight from the body then it's not a class object yet
          assessment,
          process_columns: [process_column]
        });
        if (values && !isNullOrUndefined(values[process_column])) {
          return values[process_column];
        }
      }
    }
  }
  return undefined;
};

export const preSubmittedValuesFromAssessmentForProcessColumn = ({
  assessment,
  process_columns
}: {
  assessment?: COAssessmentInterface;
  process_columns?: (string | null)[];
}): { [key: string]: string } => {
  let found: { [key: string]: string } = {};
  if (assessment && process_columns && process_columns.length > 0) {
    let calculatedContext = COAssessment.calculate({
      context: new COContext({ assessment }),
      throwExceptionOnCalculationError: false
    });
    if (calculatedContext) {
      // find the calculated values
      for (const section of calculatedContext.assessment
        ?.co_assessment_sections || []) {
        for (const question of section.co_questions || []) {
          if (
            question?.co_process_calculated_value?.process_column_name &&
            !isNullOrUndefined(
              question.co_process_calculated_value.co_calculated_value
            )
          ) {
            if (
              process_columns.includes(
                question.co_process_calculated_value.process_column_name
              )
            ) {
              found[question.co_process_calculated_value.process_column_name] =
                question.co_process_calculated_value.co_calculated_value || "";
              found[
                `${question.co_process_calculated_value.process_column_name}__display`
              ] =
                question.co_process_calculated_value
                  .co_calculated_display_value || "";
            }
          }
        }
      }
    }
  }
  return found;
};
