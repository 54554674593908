import {
  FETCHING_ONE_TIME_COSTS_DATA,
  FETCH_ONE_TIME_COSTS_DATA,
  CLEAR_ONE_TIME_COSTS_DATA,
  FETCH_ONE_TIME_COSTS_DROPDOWN_DATA
} from "../constants/actionTypes";

const INITIAL_STATE = {
  oneTimeCostsData: null,
  oneTimeCostsDropdownData: null,
  isLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_ONE_TIME_COSTS_DATA:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ONE_TIME_COSTS_DATA:
      return {
        ...state,
        oneTimeCostsData: action.payload,
        isLoading: false
      };
    case CLEAR_ONE_TIME_COSTS_DATA:
      return {
        ...state,
        oneTimeCostsData: null
      };
    case FETCH_ONE_TIME_COSTS_DROPDOWN_DATA:
      return {
        ...state,
        oneTimeCostsDropdownData: action.payload
      };
    default:
      return state;
  }
}
