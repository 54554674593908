import React, { createContext, useState, useLayoutEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import styleTheme from "../../constants/styleTheme";
import {
  apolloMaterialUiThemeDark,
  apolloMaterialUiThemeLight
} from "@uipath/apollo-material-ui-theme";
import { GlobalBaseStyles } from "./GlobalBaseStyles";
import SessionHelper from "../../helpers/SessionHelper";
import { featureFlag } from "../../helpers/FeatureFlag";

export const NESTED_THEME_CONTAINER_ID = "ah-apollo-theme-container";
export const APOLLO_THEME_LIGHT_KEY = "light";
export const APOLLO_THEME_DARK_KEY = "dark";
export const APOLLO_THEME_STORAGE_KEY = "PORTAL_THEME";

type ApolloThemeName = "light" | "dark";

export interface ApolloThemeContextProps {
  currentTheme: ApolloThemeName;
  setTheme: React.Dispatch<React.SetStateAction<ApolloThemeName>> | null;
}

export const ApolloThemeContext = createContext<ApolloThemeContextProps>({
  currentTheme: APOLLO_THEME_LIGHT_KEY,
  setTheme: null
});

const getTheme = (name: ApolloThemeName) => {
  return name === APOLLO_THEME_DARK_KEY
    ? apolloMaterialUiThemeDark
    : apolloMaterialUiThemeLight;
};

interface ComponentProps {
  children: any;
}

const StyleThemeProvider = ({ children }: ComponentProps) => {
  const [themeName, setThemeName] = useState<ApolloThemeName>(
    APOLLO_THEME_LIGHT_KEY
  );
  const theme = getTheme(themeName);

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName
  };

  useLayoutEffect(() => {
    if (featureFlag.isApolloDarkThemeEnabled()) {
      const currentTheme =
        SessionHelper.getValueForKeyWithoutPrefix(APOLLO_THEME_STORAGE_KEY) ||
        APOLLO_THEME_LIGHT_KEY;
      setThemeName(currentTheme);
      SessionHelper.updateUserPilot({ theme: currentTheme });
    }
  }, []);

  return (
    <ApolloThemeContext.Provider value={contextValue}>
      {/* @ts-ignore */}
      <portal-sheet theme={themeName}>
        <ThemeProvider
          theme={{
            ...styleTheme,
            apolloTheme: theme
          }}
        >
          <MuiThemeProvider theme={theme}>
            <GlobalBaseStyles />
            <div id={NESTED_THEME_CONTAINER_ID}>{children}</div>
          </MuiThemeProvider>
        </ThemeProvider>
        {/* @ts-ignore */}
      </portal-sheet>
    </ApolloThemeContext.Provider>
  );
};

export default StyleThemeProvider;
