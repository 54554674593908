export const CUSTOM_STYLES = {
  LINETHROUGH: {
    textDecoration: "line-through"
  }
};

export const INLINE_STYLES = [
  { label: "Bold", style: "BOLD", icon: "format_bold" },
  { label: "Italic", style: "ITALIC", icon: "format_italic" },
  { label: "LineThrough", style: "LINETHROUGH", icon: "format_strikethrough" },
  { label: "Underline", style: "UNDERLINE", icon: "format_underlined" },
  { label: "HyperLink", style: "HYPERLINK", icon: "link" }
];

export const BLOCK_STYLES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  //{label: 'H4', style: 'header-four'},
  //{label: 'H5', style: 'header-five'},
  //{label: 'H6', style: 'header-six'},
  //{label: 'Blockquote', style: 'blockquote'},
  { label: "UL", style: "unordered-list-item", icon: "format_list_bulleted" },
  { label: "OL", style: "ordered-list-item", icon: "format_list_numbered" },
  { label: "code", style: "code-block", icon: "code" }
];
