import { isNullOrUndefined } from "../utils/co-utils";
import { COErrorTypes } from "../constants/co-constants";
import { COValidationError } from "../interfaces/co-interfaces";

export const errorAlertFields = (
  error: COValidationError
): { title: string; text: string } => {
  let objectDisplayName = "";
  if (error.problem_object && error.problem_object.objectDisplayName) {
    objectDisplayName = error.problem_object?.objectDisplayName(true);
  }
  let part_string = "";
  if (error.target_path_parts) {
    for (const part of error.target_path_parts) {
      if (part !== error.target_path_parts[0]) {
        if (part.contextualValue?.objectDisplayName) {
          part_string +=
            (part_string.length > 0 ? "-" : "") +
            part.contextualValue.objectDisplayName(true);
        } else {
          part_string += (part_string.length > 0 ? "-" : "") + part.property;
        }
      }
    }
  }
  let title = "Validation ";
  if (objectDisplayName) {
    title += ": " + objectDisplayName;
  }
  if (!isNullOrUndefined(error?.resolvedValidationItem?.validationType)) {
    title += ` : ${error?.resolvedValidationItem?.validationType?.toUpperCase()}`;
  }

  let text = "";
  if (error?.error_message) {
    text += `
     ${error?.error_message}`;
  }
  if (
    !isNullOrUndefined(error.resolvedValidationItem?.value) &&
    error.resolvedValidationItem?.value !== "1" &&
    error.resolvedValidationItem?.value !== 1 &&
    error.resolvedValidationItem?.value !== "0" &&
    error.resolvedValidationItem?.value !== 0
  ) {
    text += `\n
    Limit: ${error.resolvedValidationItem?.value}`;
  }

  if (
    !isNullOrUndefined(error.error_type) &&
    error.error_type !== COErrorTypes.ERROR
  ) {
    text += `\n
    Error: ${error.error_type?.toUpperCase()}`;
  }
  if (part_string) {
    text += `\n
     - ${part_string}`;
  }

  return {
    title: title,
    text: `${text}`
  };
};
