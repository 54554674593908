import {
  YELLOW,
  GREEN,
  RED,
  BLUE,
  GRAY,
  CLASSNAME_BLUE,
  CLASSNAME_GREEN,
  CLASSNAME_RED,
  CLASSNAME_YELLOW,
  CLASSNAME_GRAY
} from "./phaseColorsConstants";

//= =====================
// Permission for various component actions
//= =====================
export const SUBMIT_REVIEW = "c_submit_review";
export const REPORT_COMMENT = "c_report_comment";
export const DOWNLOAD = "c_download";
export const REPORT_ISSUE = "c_report_issue";

//= =====================
// Curation Flow Permissions
//= =====================
export const ARCHIVE = "c_archive";
export const CURATE = "c_curate";
export const DELETE_COMPONENT = "c_delete";
export const EDIT = "c_edit";
export const PUBLISH = "c_publish";
export const REJECT = "c_reject";
export const SAVE_AS_DRAFT = "c_save_as_draft";
export const SUBMIT = "c_upload";
export const WITHDRAW = "c_withdraw";
export const COMPONENT_PROFILE = "c_view";

//= =====================
// Curation Flow Button Names
//= =====================
export const ARCHIVE_BUTTON = "Archive";
export const CONTINUE_CURATION_BUTTON = "Continue Curation";
export const CURATE_BUTTON = "Curate";
export const DELETE_COMPONENT_BUTTON = "Delete";
export const EDIT_BUTTON = "Edit";
export const PUBLISH_BUTTON = "Publish";
export const REJECT_BUTTON = "Reject";
export const SAVE_AS_DRAFT_BUTTON = "Save As Draft";
export const SAVE_BUTTON = "Save";
export const SUBMIT_BUTTON = "Submit";
export const WITHDRAW_BUTTON = "Withdraw";
export const CANCEL_BUTTON = "Cancel";
export const COMPONENT_PROFILE_BUTTON = "Component Profile";

//= =====================
// Curation Flow PHASES
//= =====================
export const DRAFT_PHASE = 1;
export const SUBMITTED_PHASE = 2;
export const IN_REVIEW_PHASE = 3;
export const WITHDRAWN_PHASE = 4;
export const PUBLISHED_PHASE = 5;
export const ARCHIVED_PHASE = 6;
export const REJECTED_PHASE = 7;
export const NEEDS_UPDATE_PHASE = 8;
export const UPDATE_AVAILABLE = 9;

export const DRAFT_NAME = "Draft";
export const SUBMITTED_NAME = "Submitted";
export const IN_REVIEW_NAME = "In Review";
export const WITHDRAWN_NAME = "Withdrawn";
export const PUBLISHED_NAME = "Published";
export const ARCHIVED_NAME = "Archived";
export const REJECTED_NAME = "Rejected";
export const NEEDS_UPDATE_NAME = "Needs Update";
export const UPDATE_AVAILABLE_NAME = "Update Available";

export const DRAFT_SLUG = "DRAFT";
export const SUBMITTED_SLUG = "SUBMITTED";
export const IN_REVIEW_SLUG = "IN_REVIEW";
export const WITHDRAWN_SLUG = "WITHDRAWN";
export const PUBLISHED_SLUG = "PUBLISHED";
export const ARCHIVED_SLUG = "ARCHIVED";
export const REJECTED_SLUG = "REJECTED";
export const NEEDS_UPDATE_SLUG = "NEEDS_UPDATE";
export const UPDATE_AVAILABLE_SLUG = "UPDATE_AVAILABLE";

export const showEditOption = permissions => {
  if (
    permissions[ARCHIVE] === 1 ||
    permissions[CURATE] === 1 ||
    permissions[DELETE_COMPONENT] === 1 ||
    permissions[EDIT] === 1 ||
    permissions[PUBLISH] === 1 ||
    permissions[REJECT] === 1 ||
    permissions[SAVE_AS_DRAFT] === 1 ||
    permissions[SUBMIT] === 1 ||
    permissions[WITHDRAW] === 1
  ) {
    return true;
  }
  return false;
};

export const component_map_to_name = {
  [DRAFT_PHASE]: DRAFT_NAME,
  [SUBMITTED_PHASE]: SUBMITTED_NAME,
  [IN_REVIEW_PHASE]: IN_REVIEW_NAME,
  [WITHDRAWN_PHASE]: WITHDRAWN_NAME,
  [PUBLISHED_PHASE]: PUBLISHED_NAME,
  [ARCHIVED_PHASE]: ARCHIVED_NAME,
  [REJECTED_PHASE]: REJECTED_NAME,
  [NEEDS_UPDATE_PHASE]: NEEDS_UPDATE_NAME,
  [UPDATE_AVAILABLE]: UPDATE_AVAILABLE_NAME
};

export const component_map_to_color = {
  [DRAFT_PHASE]: YELLOW,
  [SUBMITTED_PHASE]: GREEN,
  [IN_REVIEW_PHASE]: YELLOW,
  [WITHDRAWN_PHASE]: RED,
  [PUBLISHED_PHASE]: BLUE,
  [ARCHIVED_PHASE]: GRAY,
  [REJECTED_PHASE]: RED,
  [NEEDS_UPDATE_PHASE]: YELLOW,
  [UPDATE_AVAILABLE]: GREEN
};

export const component_map_to_classname = {
  [DRAFT_PHASE]: CLASSNAME_YELLOW,
  [SUBMITTED_PHASE]: CLASSNAME_GREEN,
  [IN_REVIEW_PHASE]: CLASSNAME_YELLOW,
  [WITHDRAWN_PHASE]: CLASSNAME_RED,
  [PUBLISHED_PHASE]: CLASSNAME_BLUE,
  [ARCHIVED_PHASE]: CLASSNAME_GRAY,
  [REJECTED_PHASE]: CLASSNAME_RED,
  [NEEDS_UPDATE_PHASE]: CLASSNAME_YELLOW,
  [UPDATE_AVAILABLE]: CLASSNAME_GREEN
};

export const component_map_to_slug = {
  [DRAFT_PHASE]: DRAFT_SLUG,
  [SUBMITTED_PHASE]: SUBMITTED_SLUG,
  [IN_REVIEW_PHASE]: IN_REVIEW_SLUG,
  [WITHDRAWN_PHASE]: WITHDRAWN_SLUG,
  [PUBLISHED_PHASE]: PUBLISHED_SLUG,
  [ARCHIVED_PHASE]: ARCHIVED_SLUG,
  [REJECTED_PHASE]: REJECTED_SLUG,
  [NEEDS_UPDATE_PHASE]: NEEDS_UPDATE_SLUG,
  [UPDATE_AVAILABLE]: UPDATE_AVAILABLE_SLUG
};
