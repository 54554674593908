import { EditorState, ContentState, convertToRaw } from "draft-js";
import {
  RawDraftJSInterface,
  DraftJSUrlWithPosition
} from "../interfaces/draft-js-helper-interfaces";
import { objectDeepCopy } from "./co-context.helper";

export const convertToRawDraftJs = (text: string): RawDraftJSInterface => {
  // already draftjs
  if (text[0] === "{" && text[text.length - 1] === "}") {
    try {
      return JSON.parse(text);
    } catch (e) {
      // Do nothing and continue to next case
    }
  }

  return convertToRaw(
    EditorState.createWithContent(
      ContentState.createFromText(text)
    ).getCurrentContent()
  );
};

export const addLinkToRawDraftJs = ({
  rawDraftJs,
  link
}: {
  rawDraftJs: RawDraftJSInterface;
  link: DraftJSUrlWithPosition;
}): RawDraftJSInterface => {
  if (!link.url) {
    return rawDraftJs;
  }
  const updatedRawDraftJs: RawDraftJSInterface = objectDeepCopy(rawDraftJs);

  // get highest key
  let highestKey: number = 0;
  if (updatedRawDraftJs.blocks && updatedRawDraftJs.blocks.length > 0) {
    const firstBlock = updatedRawDraftJs.blocks[0];
    if (firstBlock.entityRanges && firstBlock.entityRanges.length > 0) {
      const keys: number[] = firstBlock.entityRanges
        .map(range => {
          return !range.key ? -1 : +range.key;
        })
        .filter((key): key is number => {
          return key > -1;
        })
        .sort((lhs, rhs) => {
          return lhs > rhs ? 1 : 0;
        });
      highestKey = keys[0] + 1;
    }

    // add link to entity ranges
    if (!firstBlock.entityRanges) {
      firstBlock.entityRanges = [];
    }
    firstBlock.entityRanges.push({
      offset: link.offset,
      length: link.length,
      key: highestKey.toString()
    });
  } else {
    // no blocks somehow, nothing to add
    return rawDraftJs;
  }

  // also add link to entityMap
  if (!updatedRawDraftJs.entityMap) {
    updatedRawDraftJs.entityMap = {};
  }
  updatedRawDraftJs.entityMap[highestKey.toString()] = {
    type: "LINK",
    mutability: "MUTABLE",
    data: {
      url: link.url
    }
  };

  return updatedRawDraftJs;
};
