import { PIPELINE_COLUMN_NAME } from "../constants/co-constants";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName
} from "../constants/co-question.constants";

export interface LegacyQuestionInputType {
  question_input_type_id?: number; // 7,
  question_input_type_text?: string; // 'Radio' 'FloatingPercentage' 'Dropdown' 'PostiveInteger' 'FloatingNumber'
}

export interface LegacyQuestionResponseChoice {
  documentsList?: any;
  document?: any;
  question_id?: number; // used in open api responses for some reason
  response_choice_id?: number;
  response_sort_order?: number;
  response_choice_score_weight?: number;
  response_choice_text?: string; // '60%-80%',
  response_choice_value?: number; //0.4, I think number
  response_choice_slug?: string; //'structured-input-between-sixty-eighty',
  response_choice_type?: any; // null,
  response_choice_is_deleted?: number; // 0,
  response_choice_uuid?: string; // '17988660-855F-4A02-A9B8-D48800EC09D4',
  selected?: boolean; // true
}

export interface LegacyQuestionInterface {
  question_id?: number;
  matched_new_question?: boolean;
  process_identifier?: string; // automation_goal
  question_input_type?: LegacyQuestionInputType; // [Object], //
  question_text?: string; // 'Primary Reason to Automate the Process'
  question_description?: string;
  question_tips_and_examples?: string; //rich text description
  question_weight?: number;
  question_impacts_the_score?: number;
  question_is_active?: number;
  question_is_deleted?: number;
  question_is_editable?: number;
  question_is_default?: number;
  question_is_mandatory?: number;
  question_sort_order?: number;
  process_assesment_score_id?: any; // no idea
  question_has_choices?: number;
  user_can_comment?: boolean;
  question_name?: string; //'Automation Goal',
  question_uuid?: string; //'2A6F1E40-3BC3-422D-9E2C-1E92B0D31EF1',
  question_response_choices?: LegacyQuestionResponseChoice[]; // [Array],
  question_input_post_slug?: string; // null,
  comment?: string; // null,
  has_answered?: boolean;
  saved_answer?: boolean;
  question_response_answers?: LegacyQuestionResponseChoice[]; // [Array],
  answer_text?: any; //
  question_is_auto_computed?: number;
  categories?: any[];
}

export interface LegacyProcessStatisticInterface {
  process_statistics_id?: number;
  process_statistics_field_key?: string; // IMPORTANT TO GET WHEN LOADING (not in table by default) need to join with process statistics field table
  process_statistics_field_id?: number;
  process_id?: number;
  process_statistics_value?: number;
  created_epoch?: number;
  created_at?: string;
  updated_epoch?: number;
  updated_at?: string;
}

export interface LegacyProcessStatisticsFieldInterface {
  process_statistics_field_id?: number;
  process_statistics_field_key?: string;
  process_statistics_field_name?: string;
  process_statistics_field_description?: string;
  process_statistics_field_default_value?: number | null;
}

/*
total_processing_time
total_rework_time
total_review_audit_time
total_time_needed_to_perform_work
estimated_benefit_hours_per_employee
estimated_cost_benefit_per_employee
estimated_benefit_ftes_per_employee
number_of_employees
process_estimated_benefits_ftes
process_benefit_expressed_in_currency_per_year
*/

// so there's some crazyness for the process goal and the

// Used in the columns for Pipelines
// estimated_benefit_fte
// estimated_benefit_currency

// Used in the Calculated Values
// process_estimated_benefits_ftes
// process_benefit_expressed_in_currency_per_year
export const LegacyPipelineColumnName = (co_variable: string) => {
  switch (co_variable) {
    // DON"T SHOW handled by old system
    case "process_estimated_benefits_ftes":
    case "process_benefit_expressed_in_currency_per_year":
      return null;

    // Old CBA pipeline process field keys
    case "estimated_implementation_costs":
      return "est_implementation_costs";
    case "estimated_running_costs":
      return "est_running_costs";
    case "estimated_net_benefit_year_one":
      return "est_net_benefit_year_one";
    case "estimated_net_benefit_year_two":
      return "est_net_benefit_year_two";
  }
};

export const DefaultQuestionsThatMayNotBeInLegacyQuestionionaires = [
  "process_name",
  "process_description",
  "hierarchy",
  "owner"
];

export const LegacyProcessColumnName = (
  co_variable_name_or_legacy_slug: string
) => {
  let legacyNames = {
    [CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE]: "process_goal",
    [CustomQuestionSlugs.PROCESS_CHANGE_EXPECTED]: "task_stability",
    [CustomQuestionSlugs.APPLICATION_CHANGE_EXPECTED]: "application_stability",
    [CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR]:
      "employee_average_working_days",
    [CustomQuestionSlugs.WORKING_HOURS_PER_DAY]: "employee_working_hours",
    [CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR]:
      "employee_average_full_cost",
    [CustomQuestionSlugs.TASK_PROCESS_FREQUENCY]: "frequency",
    [CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE]: "process_volume",
    [CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK]:
      "number_of_employees",
    [CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION]:
      "process_average_handling_time",
    [CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE_FREQUENCY]: "volumes_option",
    [CustomQuestionSlugs.TOTAL_PROCESSING_TIME]: "total_processing_time",
    [CustomQuestionSlugs.AVG_ERROR_RATE]: "average_error_rate",
    [CustomQuestionSlugs.AVG_REWORK_TIME]: "average_rework_time",
    [CustomQuestionSlugs.TOTAL_REWORK_TIME]: "total_rework_time",
    [CustomQuestionSlugs.AVG_WORK_REVIEWED]: "average_work_to_be_reviewed",
    [CustomQuestionSlugs.AVG_REV_TIME]: "average_review_audit_time",
    [CustomQuestionSlugs.TOTAL_REV_TIME]: "total_review_audit_time",
    [CustomQuestionSlugs.TOTAL_TIME_NEEDED_AS_IS]:
      "total_time_needed_to_perform_work",
    [CustomQuestionSlugs.FTE_REQUIRED]: "ftes_required",
    [CustomQuestionSlugs.COST_PER_YEAR_AS_IS]: "process_cost_per_year",
    [CustomQuestionSlugs.PROCESS_PEAKS]: "process_peaks",
    [CustomQuestionSlugs.AVERAGE_NUMBER_OF_STEPS]: "process_length",
    [CustomQuestionSlugs.NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS]:
      "decision_complexity",
    [CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT]:
      "process_digital_input_percent",
    [CustomQuestionSlugs.SCANNED_DATA_INPUT]: "scanned_inputs",
    [CustomQuestionSlugs.PCT_STRUCTURED_DIGITAL_DATA_INPUT]: "structured_input",
    // [CustomQuestionSlugs.APPLICATIONS]: "process_num_applications", - we don't want to map this anymore
    // [CustomQuestionSlugs.APPLICATIONS_THIN]: "COUNT_THIN_APPS",
    // [CustomQuestionSlugs.APPLICATION_THIN_CLIENT_MULTIPLIER]: "THIN_CLIENT_M",
    // [CustomQuestionSlugs.APPLICATION_NUMBER_MULTIPLIER]: "NB_APPS",
    [CustomQuestionSlugs.AUTOMATION_POTENTIAL]: "process_automation_score",
    [CustomQuestionSlugs.EASE_OF_IMPLEMENTATION]:
      "process_ease_of_implementation_score",
    [CustomQuestionSlugs.EASE_OF_IMPLEMENTATION_LEVEL]:
      "process_ease_of_implementation_level",

    [CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS]:
      "process_estimated_benefit_score",
    [CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY]:
      "process_benefit_expressed_in_currency_per_year",
    [CustomQuestionSlugs.BENEFIT_PER_COMPANY_FTE]:
      "process_estimated_benefits_ftes",

    [CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS]:
      "estimated_benefit_hours_per_employee",
    //
    [CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_CURRENCY]:
      "estimated_cost_benefit_per_employee",
    [CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_FTES]:
      "estimated_benefit_ftes_per_employee",

    [CustomQuestionSlugs.DA_AVG_REWORK_TIME_AFTER_AUTOMATION]:
      "cd_average_rework_time_post_automation",

    [CustomQuestionSlugs.DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION]:
      "cd_average_process_duration_post_automation",

    [CustomQuestionSlugs.DA_AVG_ERROR_RATE_AFTER_AUTOMATION]:
      "cd_average_error_rate_post_automation",

    [CustomQuestionSlugs.DA_LIVE_BENEFIT_EM_HRS_MO]:
      "automation_store_benefit_employees_hrs_mo",

    [CustomQuestionSlugs.CTZN_DEV_LIVE_BENEFIT_EM_HRS_MO]:
      "automation_store_benefit_employees_hrs_mo",

    [CustomQuestionSlugs.CBA_LIVE_BENEFIT_EM_HRS_MO]:
      "automation_store_benefit_employees_hrs_mo",

    // CBA Columns
    [CustomQuestionSlugs.CBA_ESTIMATED_BENEFIT_FTES]:
      "cba_estimated_benefit_ftes",
    [CustomQuestionSlugs.NUMBER_OF_ROBOTS]: "number_of_robots",
    [CustomQuestionSlugs.ESTIMATED_IMPLEMENTATION_COSTS]:
      "estimated_implementation_costs",
    [CustomQuestionSlugs.ESTIMATED_RUNNING_COSTS]: "estimated_running_costs",
    [CustomQuestionSlugs.ESTIMATED_NET_BENEFIT_YEAR_ONE]:
      "estimated_net_benefit_year_one",
    [CustomQuestionSlugs.ESTIMATED_NET_BENEFIT_YEAR_TWO]:
      "estimated_net_benefit_year_two",
    [CustomQuestionSlugs.FTR_OVERRIDDEN]: "ftr_overridden",
    [CustomQuestionSlugs.CBA_EASE_OF_IMPLEMENTATION]:
      "cba_ease_of_implementation",
    [CustomQuestionSlugs.CBA_EASE_OF_IMPLEMENTATION_LEVEL]:
      "cba_ease_of_implementation_level",
    [CustomQuestionSlugs.CBA_AUTOMATION_POTENTIAL]: "cba_automation_potential",
    [CustomQuestionSlugs.CBA_ESTIMATED_BENEFIT_COST]:
      "cba_estimated_benefit_cost",
    [CustomQuestionSlugs.CBA_ESTIMATED_BENEFIT_SCORE]:
      "cba_estimated_benefit_score",
    [CustomQuestionSlugs.CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE]:
      "cba_estimated_benefit_hours_per_employee",
    [CustomQuestionSlugs.CBA_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE]:
      "cba_estimated_cost_benefit_per_employee",
    [CustomQuestionSlugs.CBA_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE]:
      "cba_estimated_benefit_ftes_per_employee",
    [CustomQuestionSlugs.CBA_STATS_LICENSE_TYPE]: "license_type",
    [CustomQuestionSlugs.CBA_STATS_SOFTWARE_VENDOR]: "software_vendor",
    [CustomQuestionSlugs.CBA_STATS_PROCESS_TYPE]: "process_type",

    // stats
    [CustomQuestionSlugs.STATS_PROCESS_NUM_FOLLOWERS]: "process_num_followers",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_VOTES]: "process_num_votes",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_MEDIA]: "process_num_media",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_DOCUMENTS]: "process_num_documents",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_COLLABORATORS]:
      "process_num_collaborators",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_COMPONENTS]:
      "process_num_components",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_COMMENTS]: "process_num_comments",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_APPLICATIONS]:
      "process_num_applications",
    [CustomQuestionSlugs.STATS_PROCESS_APPLICATIONS]: "process_applications",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_SIMILAR_AUTOMATIONS]:
      "process_num_similar_automations",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_REQUESTS]: "process_num_requests",
    [CustomQuestionSlugs.STATS_PROCESS_NUM_CHANGE_REQUESTS]:
      "process_num_change_requests",

    [CustomQuestionSlugs.STATS_PROCESS_TAGS]: "process_tag_names",
    [CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVER]:
      "idea_phase_approver",
    [CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER]:
      "launch_assessment_approver",
    [CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVER]:
      "qualification_phase_approver",
    [CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH]:
      "idea_phase_approved_epoch",
    [CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH]:
      "launch_assessment_approved_epoch",
    [CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH]:
      "qualification_phase_approved_epoch",

    [CustomQuestionSlugs.STATS_PROCESS_UPDATED_EPOCH]: "process_updated_epoch",
    [CustomQuestionSlugs.STATS_PROCESS_CREATED_EPOCH]: "process_created_epoch",
    [CustomQuestionSlugs.STATS_PROCESS_LAUNCHED_EPOCH]: "launch_date",

    [CustomQuestionSlugs.STATS_AUTOMATION_IS_PUBLISHED]: "is_published",

    [CustomQuestionSlugs.STATS_AUTOMATION_REVIEW_SCORE]: "process_review_score",
    [CustomQuestionSlugs.STATS_AUTOMATION_NUM_DOWNLOADS]:
      "process_num_downloads",
    [CustomQuestionSlugs.STATS_AUTOMATION_NUM_REVIEWS]: "process_num_reviews",
    [CustomQuestionSlugs.STATS_PDD_LINKS]: "pdd_link", // EVEN THO its an array sigh ...

    //Q1
    [CustomQuestionSlugs.Q1_DECISIONS_STRAIGHTFORWARD_RULES]: "process_rules",
    [CustomQuestionSlugs.Q1_INPUT_DATA_ACCESSED_DIGITALLY]:
      "process_input_type",
    [CustomQuestionSlugs.Q1_DATA_HIGHLY_FORMATTED]: "process_input_quality",
    [CustomQuestionSlugs.Q1_NO_EXPECTED_CHANGES]: "process_stability",
    [CustomQuestionSlugs.Q1_PROCESS_DOCUMENTS]: "process_document_availability",

    // move to process columns
    [CustomQuestionSlugs.Q1_SUITABILITY_SCORE]: "process_suitability_score",
    [CustomQuestionSlugs.Q1_READINESS_SCORE]: "process_readiness_score",
    [CustomQuestionSlugs.Q1_OVERALL_GAUGE_SCORE]: "process_overall_gauge_score",

    //OVR move to process columns
    [CustomQuestionSlugs.OVERVIEW_NAME]: "process_name",
    [CustomQuestionSlugs.OVERVIEW_DESCRIPTION]: "process_description",
    [CustomQuestionSlugs.OVERVIEW_AUTOMATION_ID]:
      "orchestrator_automation_uuid",

    [CustomQuestionSlugs.OVERVIEW_CATEGORY]: "hierarchy",

    // we don't want to mess with the id [CustomQuestionSlugs.OVERVIEW_AUTO_GENERATED_ID]: "process_id",
    [CustomQuestionSlugs.OVERVIEW_PROCESS_PHASE_STATUS]: "phase_status_key",
    [CustomQuestionSlugs.OVERVIEW_PAIN_POINTS]: "process_pain_points",
    [CustomQuestionSlugs.OVERVIEW_NEGATIVE_IMPACT]: "process_negative_impact",
    [CustomQuestionSlugs.OVERVIEW_PRIORITY]: "process_priority",
    [CustomQuestionSlugs.OVERVIEW_DEVELOPMENT_TYPE]: "process_development_type",
    [CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE]:
      "process_submission_type",
    [CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMITTER]:
      "process_submitter_user_id",
    [CustomQuestionSlugs.OVERVIEW_USEFUL_LINK]:
      "process_submission_source_link",
    //MISC move to process columns
    [CustomQuestionSlugs.MISC_ACTUAL_CHALLENGES]:
      "process_challenges_encountered",
    [CustomQuestionSlugs.MISC_EXPECTED_CHALLENGES]:
      "process_challenges_expected",
    [CustomQuestionSlugs.MISC_LESSONS_LEARNED]: "process_lessons_learned",
    [CustomQuestionSlugs.MISC_EXPECTED_BENEFITS]: "process_benefits_expected",
    [CustomQuestionSlugs.MISC_ACTUAL_BENEFITS]: "process_benefits_actual",
    [CustomQuestionVariableName(
      CustomQuestionSlugs.PROCESS_OWNER_Q1 // can be either Q1 or Q2. They both have the same CustomQuestionVariableName
    )]: "process_owner_user_id",

    //Q3 - some of these are actually questions others are process columns - need to figure it out
    [CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY]: "cd_process_frequency",
    [CustomQuestionSlugs.AUTOMATION_PROCESS_PEAKS]: "cd_process_peaks",
    [CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE]: "cd_volume_average",
    [CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT]:
      "cd_potential_benefit", //OMG THIS IS THE NUMBER OF PEOPLE WHO WOULD BENEFIT QUESTION!!!
    [CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION]:
      "cd_average_process_duration_pre_automation",
    [CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION]:
      "cd_average_process_duration_post_automation",
    [CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION]:
      "cd_average_error_rate_pre_automation",
    [CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION]:
      "cd_average_error_rate_post_automation",
    [CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION]:
      "cd_average_rework_time_pre_automation",
    [CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION]:
      "cd_average_rework_time_post_automation",
    [CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY]:
      "automation_potential_benefit_across_company",
    [CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE]:
      "automation_potential_benefit_per_employee",
    [CustomQuestionSlugs.AUTOMATION_COMPLIANCE_AND_PRIVACY]:
      "accepted_to_compliance_and_privacy",

    [CustomQuestionSlugs.CR_PARENT_AUTOMATION]: "parent_process_id"
  };

  if (LegacyProcessColumnNameForCBAFieldKey(co_variable_name_or_legacy_slug)) {
    return LegacyProcessColumnNameForCBAFieldKey(
      co_variable_name_or_legacy_slug
    );
  }

  if (legacyNames[co_variable_name_or_legacy_slug]) {
    return legacyNames[co_variable_name_or_legacy_slug];
  }
  return null;
};

export const LEGACY_FILTER_KEYS_TO_CO_KEY = {
  openAPIKeys: [
    "processGoal",
    "createdStart",
    "createdEnd",
    "updatedStart",
    "updatedEnd"
  ],
  columns: {
    values: {
      processGoal: { replace: "process_goal" },
      score: { replace: "process_ease_of_implementation_level" }
    }
  },
  processGoal: {
    replace: "process_goal",
    values: {
      "automation-goal-cost": { replace: "cost" },
      "automation-goal-quality": { replace: "quality" },
      "automation-goal-productivity": { replace: "productivity" }
    }
  },
  submissionType: {
    replace: "process_submission_type",
    values: {
      "1": { replace: "Employee-driven" },
      "2": { replace: "CoE-driven" },
      "3": { replace: "Citizen Developer" },
      "4": { replace: "Process Mining" },
      "5": { replace: "Task Mining" }
    }
  },
  developmentType: {
    replace: "process_development_type",
    values: {
      "development-type-none": { replace: "No Development Type" },
      coe: { replace: "CoE" },
      "cd-power-user": { replace: "Citizen Developer Power User" },
      "cd-self-user": { replace: "Citizen Developer Self User" }
    }
  },
  processType: {
    replace: "process_type",
    values: {
      "1": { replace: "Attended" },
      "2": { replace: "Unattended" },
      none: { replace: "None" }
    }
  },
  end: {
    replace: "launch_date_end"
  },
  start: {
    replace: "launch_date_start"
  },
  score: {
    replace: "process_ease_of_implementation_level",
    values: {
      EASY: { replace: "Easy" },
      MEDIUM: { replace: "Medium" },
      DIFFICULT: { replace: "Difficult" }
    }
  },
  createdStart: {
    replace: "process_created_epoch_start"
  },
  createdEnd: {
    replace: "process_created_epoch_end"
  },
  updatedStart: {
    replace: "process_updated_epoch_start"
  },
  updatedEnd: {
    replace: "process_updated_epoch_end"
  }
};

export const LegacyProcessColumnNameByPipelineColumn = (
  pipelineKey: string
) => {
  let legacyNames = {
    [PIPELINE_COLUMN_NAME.PROCESS_GOAL]: LegacyProcessColumnName(
      CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE
    ),
    [PIPELINE_COLUMN_NAME.NUMBER_OF_EMPLOYEES]: LegacyProcessColumnName(
      CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK
    ),
    [PIPELINE_COLUMN_NAME.TOTAL_PROCESSING_TIME]: LegacyProcessColumnName(
      CustomQuestionSlugs.TOTAL_PROCESSING_TIME
    ),
    [PIPELINE_COLUMN_NAME.TOTAL_REWORK_TIME]: LegacyProcessColumnName(
      CustomQuestionSlugs.TOTAL_REWORK_TIME
    ),
    [PIPELINE_COLUMN_NAME.TOTAL_REVIEW_AUDIT_TIME]: LegacyProcessColumnName(
      CustomQuestionSlugs.TOTAL_REV_TIME
    ),
    [PIPELINE_COLUMN_NAME.TOTAL_TIME_NEEDED_TO_PERFORM_WORK]: LegacyProcessColumnName(
      CustomQuestionSlugs.TOTAL_TIME_NEEDED_AS_IS
    ),
    [PIPELINE_COLUMN_NAME.AUTOMATION_POTENTIAL]: LegacyProcessColumnName(
      CustomQuestionSlugs.AUTOMATION_POTENTIAL
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_EASE_OF_IMPLEMENTATION]: LegacyProcessColumnName(
      CustomQuestionSlugs.EASE_OF_IMPLEMENTATION
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_EASE_OF_IMPLEMENTATION_LEVEL]: LegacyProcessColumnName(
      CustomQuestionSlugs.EASE_OF_IMPLEMENTATION_LEVEL
    ),
    [PIPELINE_COLUMN_NAME.ESTIMATED_BENEFIT_HOURS]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS
    ),
    [PIPELINE_COLUMN_NAME.ESTIMATED_BENEFIT_FTE]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_FTE
    ),
    [PIPELINE_COLUMN_NAME.ESTIMATED_BENEFIT_CURRENCY]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY
    ),
    [PIPELINE_COLUMN_NAME.ESTIMATED_BENEFIT_HOURS_PER_YEAR]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS
    ),
    [PIPELINE_COLUMN_NAME.ESTIMATED_COST_BENEFIT_PER_EMPLOYEE]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_CURRENCY
    ),
    [PIPELINE_COLUMN_NAME.ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_FTES
    ),
    [PIPELINE_COLUMN_NAME.AVG_PROCESS_TIME_TRANSACTION]: LegacyProcessColumnName(
      CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_VOLUME]: LegacyProcessColumnName(
      CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_DIGITAL_INPUT_PERCENT]: LegacyProcessColumnName(
      CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_COST_PER_YEAR]: LegacyProcessColumnName(
      CustomQuestionSlugs.COST_PER_YEAR_AS_IS
    ),
    [PIPELINE_COLUMN_NAME.BENEFIT_PER_COMPANY_CURRENCY]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY
    ),
    [PIPELINE_COLUMN_NAME.BENEFIT_PER_COMPANY_HOURS]: LegacyProcessColumnName(
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_AUTOMATION_SCORE]: LegacyProcessColumnName(
      CustomQuestionSlugs.AUTOMATION_POTENTIAL
    ),
    [PIPELINE_COLUMN_NAME.PROCESS_PRIORITY]: LegacyProcessColumnName(
      CustomQuestionSlugs.OVERVIEW_PRIORITY
    )
  };

  if (legacyNames[pipelineKey]) {
    return legacyNames[pipelineKey];
  }
  return null;
};

// this supports the old format for filters and questions if we have it in our system
// returns null if we don't have a mapping
export interface LegacyFilterOption {
  response_choice_id: number;
  response_choice_is_deleted: number;
  response_choice_score_weight: number;
  response_choice_slug: string;
  response_choice_text: string;
  response_choice_tool_tip: string | null;
  response_choice_type: string | null;
  response_choice_uuid: string;
  response_choice_value: number;
  response_sort_order: number;
}

export const LegacyCBAColumnNamesInCOSystem = [
  "cba_automation_potential",
  "cba_estimated_benefit_score",
  "cba_estimated_benefit_cost",
  "cba_estimated_benefit_ftes",
  "cba_estimated_benefit_hours_per_employee",
  "cba_estimated_cost_benefit_per_employee",
  "cba_estimated_benefit_ftes_per_employee",
  "cba_ease_of_implementation",
  "number_of_robots",
  "ftr_overridden",
  "est_implementation_costs",
  "est_running_costs",
  "est_net_benefit_year_one",
  "est_net_benefit_year_two"
];
// well this is crazy
const PROCESS_STATISTICS_FIELD_KEY = {
  ESTIMATED_BENEFIT_FTES: "estimated-benefit-ftes",
  ESTIMATED_IMPLEMENTATION_COSTS: "estimated-implementation-costs",
  ESTIMATED_RUNNING_COSTS: "estimated-running-costs",
  ESTIMATED_NET_BENEFIT_YEAR_ONE: "estimated-net-benefit-year-one",
  ESTIMATED_NET_BENEFIT_YEAR_TWO: "estimated-net-benefit-year-two",
  RPA_SOFTWARE_COSTS_PER_YEAR: "rpa-software-costs-per-year",
  OTHER_SOFTWARE_COSTS_PER_YEAR: "other-software-costs-per-year",
  SUPPORT_TEAM_COSTS_PER_YEAR: "support-team-costs-per-year",
  INFRASTRUCTURE_COSTS_PER_YEAR: "infrastructure-costs-per-year",
  OTHER_COSTS_PER_YEAR: "other-costs-per-year",
  TOTAL_YEAR_ONE: "total-year-one",
  TOTAL_YEAR_TWO: "total-year-two",
  HUMAN_COST_PER_YEAR: "human-cost-per-year",
  NUMBER_OF_ROBOTS: "number-of-robots",
  RUNNING_COST_PER_TRANSACTION_HUMAN: "running-cost-per-transaction-human",
  FTR_OVERRIDDEN: "ftr-overridden",
  TOTAL_RUNNING_COSTS_PER_YEAR_ROBOT: "total-running-costs-per-year-robot",
  RUNNING_COST_PER_TRANSACTION_ROBOT: "running-cost-per-transaction-robot",
  VOLUMES_PER_YEAR_TOTAL: "volumes-per-year-total",
  VOLUMES_PER_YEAR_HUMAN_AFTER_AUTOMATION:
    "volumes-per-year-human-after-automation",
  VOLUMES_PER_YEAR_ROBOT_FINAL_AFTER_AUTOMATION:
    "volumes-per-year-robot-final-after-automation",
  EASE_OF_IMPLEMENTATION_FINAL: "ease-of-implementation-final",
  FINAL_AUTOMATION_POTENTIAL: "final-automation-potential",
  FINAL_ESTIMATED_BENEFIT_CURRENCY: "final-estimated-benefit-currency",
  FINAL_ESTIMATED_BENEFIT_SCORE: "final-estimated-benefit-score",
  TOTAL_PROCESSING_TIME: "total-processing-time",
  TOTAL_REWORK_TIME: "total-rework-time",
  TOTAL_REVIEW_AUDIT_TIME: "total-review-audit-time",
  TOTAL_TIME_NEEDED_TO_PERFORM_WORK: "total-time-needed-to-perform-work",
  NUMBER_OF_EMPLOYEES: "number-of-employees",
  ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE: "estimated-benefit-hours-per-employee",
  ESTIMATED_COST_BENEFIT_PER_EMPLOYEE: "estimated-cost-benefit-per-employee",
  ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE: "estimated-benefit-ftes-per-employee",
  VOLUMES_OPTION: "volumes-option",
  FINAL_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE:
    "final-estimated-benefit-hours-per-employee",
  FINAL_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE:
    "final-estimated-cost-benefit-per-employee",
  FINAL_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE:
    "final-estimated-benefit-ftes-per-employee"
};

export const LegacyProcessColumnNameForCBAFieldKey = legacySlug => {
  switch (legacySlug) {
    case PROCESS_STATISTICS_FIELD_KEY.FINAL_AUTOMATION_POTENTIAL: //"final-automation-potential", is the format and it should correspond with the legacy slug
      return "cba_automation_potential";
    case PROCESS_STATISTICS_FIELD_KEY.FINAL_ESTIMATED_BENEFIT_SCORE:
      return "cba_estimated_benefit_score";
    case PROCESS_STATISTICS_FIELD_KEY.FINAL_ESTIMATED_BENEFIT_CURRENCY:
      return "cba_estimated_benefit_cost";
    case PROCESS_STATISTICS_FIELD_KEY.ESTIMATED_BENEFIT_FTES:
      return "cba_estimated_benefit_ftes";
    case PROCESS_STATISTICS_FIELD_KEY.FINAL_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE:
      return "cba_estimated_benefit_hours_per_employee";
    case PROCESS_STATISTICS_FIELD_KEY.FINAL_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE:
      return "cba_estimated_cost_benefit_per_employee";
    case PROCESS_STATISTICS_FIELD_KEY.FINAL_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE:
      return "cba_estimated_benefit_ftes_per_employee";
    case PROCESS_STATISTICS_FIELD_KEY.EASE_OF_IMPLEMENTATION_FINAL:
      return "cba_ease_of_implementation";
    case PROCESS_STATISTICS_FIELD_KEY.NUMBER_OF_ROBOTS:
      return "number_of_robots";
    case PROCESS_STATISTICS_FIELD_KEY.FTR_OVERRIDDEN:
      return "ftr_overridden";
    case PROCESS_STATISTICS_FIELD_KEY.ESTIMATED_IMPLEMENTATION_COSTS:
      return "estimated_implementation_costs";
    case PROCESS_STATISTICS_FIELD_KEY.ESTIMATED_RUNNING_COSTS:
      return "estimated_running_costs";
    case PROCESS_STATISTICS_FIELD_KEY.ESTIMATED_NET_BENEFIT_YEAR_ONE:
      return "estimated_net_benefit_year_one";
    case PROCESS_STATISTICS_FIELD_KEY.ESTIMATED_NET_BENEFIT_YEAR_TWO:
      return "estimated_net_benefit_year_two";
  }
  return null;
};
