import { COPathPartType } from "../constants/co-constants";
import { isContextProperty } from "../constants/co-context.constants";
import {
  COMPARISON_CONTEXT_KEY,
  CONTEXT_KEY,
  PATH_PARAM_SEP,
  PATH_SEP
} from "../constants/co-path.constants";
import {
  COPathPart,
  COPathOverrideInterface,
  COContextInterface
} from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "./co-utils";

export const COPathOperatorFunctions = {
  function: (context: COContextInterface, value: any, params: any) => {
    if (value instanceof Function) {
      return value(context, params);
    } else {
      return value;
    }
  },
  inverse: (context, value, params) => {
    return value ? 0 : 1;
  },
  forceOff: (context, value, params) => {
    return 0;
  },
  forceOn: (context, value, params) => {
    return 1;
  },
  isDefined: (context, value, params) => {
    return !isNullOrUndefined(value) ? 1 : 0;
  },
  isUndefined: (context, value, params) => {
    return isNullOrUndefined(value) ? 1 : 0;
  },
  isGreaterThanOne: (context, value, params) => {
    if (isNullOrUndefined(value)) {
      return 0;
    }
    return value > 1 ? 1 : 0;
  },
  isNotGreaterThanOne: (context, value, params) => {
    if (isNullOrUndefined(value)) {
      return 1;
    }
    return value > 1 ? 0 : 1;
  },
  isEmptyArray: (context, value, params) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return false;
  },
  isNotEmptyArray: (context, value, params) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return false;
  },
  splitIndex: (context, value, params) => {
    let desiredIndex = params?.split_index || 0;
    if (Array.isArray(value)) {
      return value[desiredIndex];
    } else if (typeof value === "string") {
      return value?.split(",")?.[desiredIndex] || undefined;
    } else if (value) {
      console.log("warning trying to find split index of non string");
    }
    return value;
  }
};

// helper function for determining if a value is a path or not
export const isPath = (value: any): boolean => {
  if (typeof value === "string") {
    return routeFromPath(value) != null;
  }
  return false;
};

// this returns the actual path like context.assessment.meta.title.value
// from the full path PATH(context.assessment.meta.title.value,inverse) or whatever
export const routeFromPath = (value: string): string | null => {
  if (value && typeof value === "string") {
    let results: RegExpMatchArray | null = value.match(/PATH\((\S.*)\)/);
    if (results && results.length > 1) {
      return results[1]; //the capture group is result object at index 1
    }
  }
  return null;
};

// helper function for getting a value at the end of the path, or the value if no path
// this uses the path transform at the end
// this will traverse path to path to path until max_depth or the value is found
export const getValue = (
  context: COContextInterface,
  valueOrPath: any,
  max_depth?: number
): any => {
  let valueWithPath = resolvePath({
    context,
    valueOrPath,
    max_depth
  });
  if (valueWithPath) {
    return valueWithPath.value;
  }
  return undefined;
};

// this helper function sets the value at the end of the path - used for inputs in meta objects where the value is a path pointing elsewhere as well as for controls setting values
// this will traverse path to path to path until max_depth or the value is found
// this uses the path transform at the end
// you must pass in the parent object that you want to set this value on and the property you want to set
export const setValue = ({
  context,
  parentObject,
  property,
  newValueToSet,
  max_depth
}: {
  context: COContextInterface;
  parentObject: any;
  property: string;
  newValueToSet: any;
  max_depth?: number;
}): any => {
  if (parentObject) {
    // can handle bulk setting of arrays as well
    if (isPath(parentObject[property])) {
      return setPropertyAtPath({
        context,
        pathString: parentObject[property],
        value: newValueToSet,
        max_depth
      });
    } else {
      if (Array.isArray(parentObject)) {
        console.log(
          "You're trying to set a value on an array of objects - this is not supported because we have no good way to update the context for any further resolved paths"
        );
        return null;
      }
      console.log(
        "You're trying to set a value directly on a meta object, not via path - controls and meta inputs should always use paths and not store data directly in the templates themselves"
      );
      // there's actually no place where we set a value in a meta object directly on the first level - it's always through the path
    }
  }
};

// this is the core of the pathing system
// takes the full path as a string ie PATH(context.assessment.meta.title.text)
// breaks it into Path Objects an ordered array of elements which have the value within the context passed in
// this will recursively traverse paths that end it paths that point to paths, unless a max depth is hit
// if the path can't be resolved (like it's referencing an object that hasn't been set in the context yet (like process_answer)) it returns an empty array
// [] ie path not found - is different then the value being undefined at the end of a path (which a validator could be targeting)
export const parsePath = ({
  context,
  path,
  depth = 0,
  max_depth = 10
}: {
  context: COContextInterface;
  path: string;
  depth?: number;
  max_depth?: number;
}): COPathPart[] => {
  depth++;
  let pathParts: COPathPart[] = [];
  let parentContextualObject: any = null;
  if (path) {
    let parameters = routeFromPath(path)?.split(PATH_PARAM_SEP);
    let operator = parameters || [].length > 1 ? parameters?.[1] : undefined;
    let operatorFCT = operator ? COPathOperatorFunctions[operator] : undefined;
    let operatorParamJSON =
      parameters || [].length > 2 ? parameters?.[2] : undefined;
    let operatorParams = operatorParamJSON
      ? JSON.parse(operatorParamJSON || "")
      : undefined;

    let routepath = parameters?.[0];
    let parts = routepath?.split(PATH_SEP);
    if (parts) {
      for (var x = 0; x < parts.length; x++) {
        let part = parts[x];
        // first part is context
        if (
          x === 0 &&
          (part === CONTEXT_KEY || part === COMPARISON_CONTEXT_KEY)
        ) {
          if (part === CONTEXT_KEY) {
            parentContextualObject = context;
            let pathPart: COPathPart = {
              type: COPathPartType.PATH_PART_CONTEXT,
              property: part,
              contextualValue: parentContextualObject,
              operatorFCT: operatorFCT,
              operatorParams
            };
            pathParts.push(pathPart);
          } else if (part === COMPARISON_CONTEXT_KEY) {
            parentContextualObject = context.comparison_context;
            let pathPart: COPathPart = {
              type: COPathPartType.PATH_PART_CONTEXT,
              property: part,
              contextualValue: parentContextualObject,
              operatorFCT: operatorFCT,
              operatorParams
            };
            pathParts.push(pathPart);
          }

          //second part is the context property like assessment question section
        } else if (isContextProperty(part)) {
          parentContextualObject =
            (parentContextualObject && parentContextualObject[part]) ||
            undefined;
          if (isNullOrUndefined(parentContextualObject)) {
            // we should kick out here -
            // we can't get to the path - no context
            return [];
          }
          let pathPart: COPathPart = {
            type: COPathPartType.PATH_PART_CONTEXT_ELEMENT,
            property: part,
            contextualValue: parentContextualObject,
            operatorFCT: operatorFCT,
            operatorParams
          };
          pathParts.push(pathPart);

          //rest is going to be an array regex or property to property
        } else {
          //it's just a property
          // let's get the value of the property

          let ctxVal = contextualValue(context, parentContextualObject, part);
          let pathPart: COPathPart = {
            type: COPathPartType.PATH_PART_PROPERTY,
            property: part,
            contextualValue: ctxVal,
            operatorFCT: operatorFCT,
            operatorParams
          };

          parentContextualObject = pathPart.contextualValue;

          pathParts.push(pathPart);
          // path of path

          // if the value of the property is a path - then we want to follow that path and keep going
          // ex question.meta.title.value (at the end of a path) may have the value of  => question.question_meta_json.title.value
          if (isPath(ctxVal)) {
            if (depth < max_depth) {
              let patPartsFromNewPath = parsePath({
                context,
                path: ctxVal,
                depth,
                max_depth
              });
              if (patPartsFromNewPath && patPartsFromNewPath.length > 0) {
                pathParts = [...pathParts, ...patPartsFromNewPath];
                if (pathParts.length > 0) {
                  parentContextualObject =
                    pathParts[pathParts.length - 1].contextualValue;
                }
              } else {
                // we want to resolve to nothing here because we couldn't get to the path this way the validators don't trip up thinking a path is a value
                return [];
              }
            } else {
              //console.log("Max pathParsingDepth Exceeded stopping ");
              // so we actually don't want to return null or something - we can use the max depth to get to the top level of an object
            }
          }
        }
      }
    }
  }
  return pathParts;
};

// this gets the value of a path part in the context object
// this handles arrays - getting the object of the pathPart
// and sets the context on the parent if the parent is something like a COBase object that might need the context to override something in the options object
const contextualValue = (
  context: COContextInterface,
  parentContextualObject: any,
  property_key: string
) => {
  if (isNullOrUndefined(parentContextualObject)) {
    return;
  }

  if (Array.isArray(parentContextualObject)) {
    // if the parent object was an array - then I need to loop through the array -
    // returning an array of the values
    let arrayOfResultingObjects: any[] = [];
    for (const element of parentContextualObject) {
      let potentialArrayProperty = element[property_key];
      if (!isNullOrUndefined(potentialArrayProperty)) {
        arrayOfResultingObjects.push(potentialArrayProperty);
      }
    }
    return arrayOfResultingObjects;
  }

  //get value
  let valueInParent;
  // this gives the options object on the CO the context it needs to override options based on the condition system
  if (
    property_key === "options" &&
    parentContextualObject.optionsWithOverrides
  ) {
    valueInParent = parentContextualObject?.optionsWithOverrides?.(
      context,
      parentContextualObject[property_key]
    );
    // then let's get the conditionally overidden version
  } else {
    valueInParent = parentContextualObject[property_key];
  }

  if (!isNullOrUndefined(valueInParent)) {
    return valueInParent;
  }
  return undefined;
};

// this will set the value at the end of the path - or when the path reaches max depth
// this also can handle the operator functionality at the end of paths (like inverse)
// setIfCurrentlyUndefined controls if we're willing to set a property that doesn't exist in the object we targeting, this is somewhat of a safety check to prevent accidently setting a large object inadvertently in a _json property of a question that will be stored with it
const setPropertyAtPath = ({
  context,
  pathString,
  value,
  max_depth,
  setIfCurrentlyUndefined = false
}: {
  context: COContextInterface;
  pathString: string;
  value: any;
  max_depth?: number;
  setIfCurrentlyUndefined?: boolean;
}): any => {
  let pathParts = parsePath({ context, path: pathString, max_depth });
  if (pathParts && pathParts.length > 1) {
    // we want to set the second to last item - because that's the object with the property we want on it
    let pathPartOfProperty = pathParts[pathParts.length - 1];

    // I don't want to set a random object in json by mistake
    // this is a check - because it requires the option or whatever to be defined in a question that uses it
    // this makes it difficult to implement new validators in a template without updating the json metadata in a question
    if (isNullOrUndefined(pathPartOfProperty.contextualValue)) {
      if (!setIfCurrentlyUndefined) {
        console.log(
          `currently undefined property trying to be set ${pathString}`
        );
        return null;
      }
    }

    // so if i'm setting the title.value - then value is parts-1 title is parts-2 (the parent object we're setting it on)
    let parentObject = pathParts[pathParts.length - 2].contextualValue;
    if (!isNullOrUndefined(parentObject)) {
      let property = pathPartOfProperty.property;
      // apply operator like inverse
      if (pathPartOfProperty.operatorFCT) {
        let operatedValue = pathPartOfProperty.operatorFCT(
          context,
          value,
          pathPartOfProperty.operatorParams
        );
        parentObject[property] = operatedValue;
      } else {
        parentObject[property] = value;
      }
      return parentObject[property];
    }
  }

  return null;
};

export interface COResolvedPath {
  pathArray: COPathPart[];
  value: any;
}

// this allows overriding a path - this is useful for testing a value change before making it, or using a validator out of context
export const resolvePathWithOverrides = ({
  context,
  valueOrPath,
  overrides = []
}: {
  context: COContextInterface;
  valueOrPath: any;
  overrides?: COPathOverrideInterface[];
}): COResolvedPath | undefined => {
  let matchedOverride = overrides.find(over => over.path === valueOrPath);
  if (matchedOverride) {
    return { value: matchedOverride.value, pathArray: [] };
  }
  return resolvePath({ context, valueOrPath });
};

// returns the value and the end of the path and the array of path objects to get there
const resolvePath = ({
  context,
  valueOrPath,
  max_depth
}: {
  context: COContextInterface;
  valueOrPath: any;
  max_depth?: number;
}): COResolvedPath | undefined => {
  if (isPath(valueOrPath)) {
    let parsedPath = parsePath({ context, path: valueOrPath, max_depth });
    if (parsedPath && parsedPath.length > 0) {
      let pathPart = parsedPath[parsedPath.length - 1];
      if (pathPart) {
        let value = pathPart.contextualValue;
        if (pathPart.operatorFCT) {
          let operatedValue = pathPart.operatorFCT(
            context,
            value,
            pathPart.operatorParams
          );
          value = operatedValue;
        }
        return { value: value, pathArray: parsedPath };
      }
    }
    return undefined;
  }
  return {
    value: valueOrPath,
    pathArray: []
  };
};

// for things ike unanswered questions - we'll need to be able to check for an item in context then get that path array
// so we want to check the resolved path for errors - as well as be able to deal with things like
// un answered answer things - so like the question-object it's self should be able to pump in errors
// or they could look up errors from the context  - like if the path
export const errorKeyFromPath = (
  context: COContextInterface,
  path: string
): string => {
  let pathArray = parsePath({ context, path });
  return errorKeyFromPathArray(pathArray);
};

export const errorKeyFromPathArray = (pathPartArray: COPathPart[]): string => {
  let error_key = "";
  for (const pathPart of pathPartArray) {
    let contextualVal: any = pathPart.contextualValue || {};
    if (contextualVal && contextualVal.getAHID) {
      let ahidVal = contextualVal.getAHID();
      if (ahidVal) {
        error_key += `(${ahidVal}):`;
      } else {
        if (error_key.length > 0) {
          error_key += "-";
        }
      }
    } else {
      error_key += "-";
    }
    error_key += `${pathPart.property}`;
  }
  return error_key;
};
