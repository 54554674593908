import {
  COEmailActionInterface,
  COLegacyEmailInterface,
  COEmailActionTemplateInterface,
  COLegacyEmailTemplateData,
  COProcess,
  COActionOptionsInterface,
  COActionContextValuesInterface
} from "../../interfaces/co-interfaces";
import { actionTemplate } from "../../templates/elements/actions/co-action-templates";
import {
  COEmailActionSlugs,
  COEmailActivityType
} from "../../constants/actions/co-email-action-constants";
import { convertLegacyEventToCOPlatformEventType } from "./co-platform-events.helper";
import { COPlatformEventType } from "../../constants/actions/co-platform-events-constants";

export const convertLegacyEmailToAction = ({
  legacy_action,
  templateData,
  refUserId,
  processId,
  componentId,
  tenantId,
  commentId,
  componentCommentId,
  processQuestionId,
  targetUserIds,
  taggedUserIds
}: {
  legacy_action: COLegacyEmailInterface;
  templateData: COLegacyEmailTemplateData;
  refUserId?: number;
  processId?: number;
  componentId?: number;
  tenantId?: string;
  commentId?: number;
  componentCommentId?: number;
  processQuestionId?: number;
  targetUserIds?: string[];
  taggedUserIds?: string[];
}): COEmailActionInterface | undefined => {
  const legacySlug: string | undefined = legacy_action.email_template_name;
  if (!legacySlug) {
    return undefined;
  }
  let actionSlug = legacySlug
    .toUpperCase()
    .split("-")
    .join("_");
  if (!actionSlug) {
    return undefined;
  }
  if (!Object.keys(COEmailActionSlugs).includes(actionSlug)) {
    // add "_EMAIL" and check again
    actionSlug = actionSlug + "_EMAIL";
    if (!Object.keys(COEmailActionSlugs).includes(actionSlug)) {
      console.log(
        "Found an email slug that we don't have in the CO system: " + actionSlug
      );
      return undefined;
    }
  }

  const template: COEmailActionTemplateInterface = actionTemplate(
    actionSlug
  ) as COEmailActionTemplateInterface;

  const options = template.co_options_json;
  if (options) {
    if (targetUserIds) {
      if (options.action_target) {
        options.action_target = {
          target_user_ids: targetUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          target_user_ids: targetUserIds
        };
      }
    }
    if (taggedUserIds) {
      if (options.action_target) {
        options.action_target = {
          mentioned_users: taggedUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          mentioned_users: taggedUserIds
        };
      }
    }
  }

  const legacy_text_replacement_values: {
    [key: string]: { text?: string; url?: string };
  } = {};
  Object.keys(templateData).forEach(key => {
    legacy_text_replacement_values["{" + key + "}"] = {
      text: templateData[key]
    };
  });

  return {
    co_action_template_slug: actionSlug,
    co_action_type: template.co_action_template_co_type,
    co_action_meta_json: template.co_meta_json,
    co_action_options_json: options,
    template: template,
    co_action_context_values_json: {
      process_id: processId,
      component_id: componentId,
      reference_user_id: refUserId,
      tenant_id: tenantId,
      comment_id: commentId,
      component_comment_id: componentCommentId,
      process_question_id: processQuestionId,
      legacy_text_replacement_values: legacy_text_replacement_values
    }
  };
};

export const getEmailInterfaceForTemplate = ({
  template,
  targetUserIds,
  taggedUserIds,
  nonUserRecipientEmail,
  actionContextValues
}: {
  template: COEmailActionTemplateInterface;
  targetUserIds?: string[];
  taggedUserIds?: string[];
  nonUserRecipientEmail?: string;
  actionContextValues: COActionContextValuesInterface;
}): COEmailActionInterface => {
  const options = template.co_options_json;
  if (options) {
    if (targetUserIds) {
      if (options.action_target) {
        options.action_target = {
          target_user_ids: targetUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          target_user_ids: targetUserIds
        };
      }
    }
    if (taggedUserIds) {
      if (options.action_target) {
        options.action_target = {
          mentioned_users: taggedUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          mentioned_users: taggedUserIds
        };
      }
    }
    if (nonUserRecipientEmail) {
      if (options.action_target) {
        options.action_target = {
          non_user_recipient_email: nonUserRecipientEmail,
          ...options.action_target
        };
      } else {
        options.action_target = {
          non_user_recipient_email: nonUserRecipientEmail
        };
      }
    }
  }

  return {
    co_action_template_slug: template.co_action_template_slug,
    co_action_type: template.co_action_template_co_type,
    co_action_meta_json: template.co_meta_json,
    co_action_options_json: options,
    template: template,
    co_action_context_values_json: actionContextValues
  };
};

const convertLegacyEmailValueToCoEmailStringValue = ({
  legacy_value
}: {
  legacy_value: string;
}): string => {
  return legacy_value
    .toUpperCase()
    .split("-")
    .join("_");
};

export const getEmailTemplatesForPlatformEventSlug = ({
  platform_event,
  activity,
  process
}: {
  platform_event?: string;
  activity?: string;
  process?: COProcess;
}): COEmailActionTemplateInterface[] => {
  const coPlatformEvent = convertLegacyEmailValueToCoEmailStringValue({
    legacy_value: platform_event ?? ""
  });
  const coActivityType = convertLegacyEmailValueToCoEmailStringValue({
    legacy_value: activity ?? ""
  });

  return Object.keys(COEmailActionSlugs)
    .map(slug => {
      return actionTemplate(slug) as COEmailActionTemplateInterface;
    })
    .filter(template => {
      if (template.co_options_json) {
        // check that the notification is active and not deleted
        const options: COActionOptionsInterface = template.co_options_json as COActionOptionsInterface;
        if (options.is_deleted === 1 || options.is_inactive === 1) {
          return false;
        }

        // check for event match
        if (platform_event) {
          if (
            template.co_options_json.platform_event_type === coPlatformEvent
          ) {
            if (
              template.co_options_json.platform_event_type ===
              COPlatformEventType.PROCESS_PHASE_CHANGED
            ) {
              // if the event is PROCESS_PHASE_CHANGED, check the phase status triggers as well
              if (
                template.co_options_json
                  ?.platform_event_phase_status_triggers &&
                process?.phase_status_key &&
                template.co_options_json.platform_event_phase_status_triggers.includes(
                  process.phase_status_key
                )
              ) {
                return true;
              }
            } else {
              // otherwise the event matches without any other checks
              return true;
            }
            return false;
          }
        }
        if (activity) {
          return (
            template.co_options_json.email_activity_type === coActivityType
          );
        }
        return false;
      }
    });
};
