import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  USER_PROFILE_URL,
  ADMIN_USER_ROLES_URL,
  ADMIN_DOCUMENATION_URL,
  ADMIN_MANAGER_USERS_URL,
  ADMIN_MANAGER_COMPONENTS_URL,
  EXPLORE_URL,
  EXPLORE_COMPONENTS_URL,
  ADMIN_HIERARCHY_URL,
  ADMIN_APP_INVENTORY_URL,
  TOP_DOWN_SUBMISSION_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const adminConsoleUpdatesNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = adminConsoleUpdatesNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.ADMIN_CONSOLE_UPDATES,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const adminConsoleUpdatesNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.NEW_PROCESS_ROLE_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 77,
          platform_event_type: COPlatformEventType.ADMIN_PROCESS_ROLE_ADDED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_PROCESS_ROLE_ADDED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New role added"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " created the role " +
              COActionReplacementStrings.PROCESS_ROLE_NAME +
              " in Manage Access - Roles section."
          },
          redirect_url: {
            value: ADMIN_USER_ROLES_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROCESS_ROLE_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 78,
          platform_event_type: COPlatformEventType.ADMIN_PROCESS_ROLE_ADDED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROCESS_ROLE_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Role updated"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the role " +
              COActionReplacementStrings.PROCESS_ROLE_NAME +
              " in Manage Access - Roles section."
          },
          redirect_url: {
            value: ADMIN_USER_ROLES_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROCESS_ROLE_DISABLED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 79,
          platform_event_type: COPlatformEventType.ADMIN_PROCESS_ROLE_DISABLED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROCESS_ROLE_DISABLED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Role disabled"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " disabled the role " +
              COActionReplacementStrings.PROCESS_ROLE_NAME +
              " in Manage Access - Roles section."
          },
          redirect_url: {
            value: ADMIN_USER_ROLES_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROCESS_ROLE_ENABLED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 80,
          platform_event_type: COPlatformEventType.ADMIN_PROCESS_ROLE_DISABLED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROCESS_ROLE_ENABLED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Role enabled"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " enabled the role " +
              COActionReplacementStrings.PROCESS_ROLE_NAME +
              " in Manage Access - Roles section."
          },
          redirect_url: {
            value: ADMIN_USER_ROLES_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROCESS_ROLE_DELETED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 81,
          platform_event_type: COPlatformEventType.ADMIN_PROCESS_ROLE_DELETED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROCESS_ROLE_DELETED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Role deleted"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " deleted the role " +
              COActionReplacementStrings.PROCESS_ROLE_NAME +
              " in Manage Access - Roles section."
          },
          redirect_url: {
            value: ADMIN_USER_ROLES_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.TEMPLATE_DOCUMENT_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 82,
          platform_event_type:
            COPlatformEventType.ADMIN_TEMPLATE_DOCUMENT_ADDED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.TEMPLATE_DOCUMENT_ADDED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Template document added"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added the template " +
              COActionReplacementStrings.PROCESS_TEMPLATE_TITLE +
              " in Platform setup - Documentation section."
          },
          redirect_url: {
            value: ADMIN_DOCUMENATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.TEMPLATE_DOCUMENT_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 83,
          platform_event_type:
            COPlatformEventType.ADMIN_TEMPLATE_DOCUMENT_UPDATED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.TEMPLATE_DOCUMENT_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Template document updated"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the template " +
              COActionReplacementStrings.PROCESS_TEMPLATE_TITLE +
              " in Platform setup - Documentation section."
          },
          redirect_url: {
            value: ADMIN_DOCUMENATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.TEMPLATE_DOCUMENT_SET_AS_DEFAULT: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 84,
          platform_event_type:
            COPlatformEventType.ADMIN_TEMPLATE_DOCUMENT_SET_DEFAULT,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.TEMPLATE_DOCUMENT_SET_AS_DEFAULT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Template document set as default"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " set as default the template " +
              COActionReplacementStrings.PROCESS_TEMPLATE_TITLE +
              " in Platform setup - Documentation section."
          },
          redirect_url: {
            value: ADMIN_DOCUMENATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.TEMPLATE_DOCUMENT_DELETED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 85,
          platform_event_type: COPlatformEventType.ADMIN_TEMPLATE_DELETED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.TEMPLATE_DOCUMENT_DELETED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Template document deleted"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " deleted the template " +
              COActionReplacementStrings.PROCESS_TEMPLATE_TITLE +
              " in Platform setup - Documentation section."
          },
          redirect_url: {
            value: ADMIN_DOCUMENATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.SYSTEM_ADMIN_ADDED_TO_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 108,
          platform_event_type: COPlatformEventType.SYSTEM_ADMIN_ADDED_TO_USER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_SYSTEM_ADMIN,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "System Admin added to a user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.SYSTEM_ADMIN_NAME +
              " as a System Admin."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROGRAM_MANAGER_ADDED_TO_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 110,
          platform_event_type:
            COPlatformEventType.PROGRAM_MANAGER_ADDED_TO_USER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_PROGRAM_MANAGER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Program Manager added to a user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.PROGRAM_MANAGER_NAME +
              " as a Program Manager."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CURATOR_ADDED_TO_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 111,
          platform_event_type: COPlatformEventType.CURATOR_ADDED_TO_USER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug: CONotificationActionGroupSlugs.ADDED_CURATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Curator added to a user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.CURATOR_NAME +
              " as a Curator."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.SYSTEM_ADMIN_REMOVED_FROM_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 112,
          platform_event_type:
            COPlatformEventType.SYSTEM_ADMIN_REMOVED_FROM_USER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_SYSTEM_ADMIN,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "System admin removed from user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.SYSTEM_ADMIN_NAME +
              " as a System Admin."
          },
          redirect_url: {
            value: EXPLORE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROGRAM_MANAGER_REMOVED_FROM_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 113,
          platform_event_type:
            COPlatformEventType.PROGRAM_MANAGER_REMOVED_FROM_USER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_PROGRAM_MANAGER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Program Manager removed from user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.PROGRAM_MANAGER_NAME +
              " as a Program Manager."
          },
          redirect_url: {
            value: EXPLORE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CURATOR_REMOVED_FROM_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 114,
          platform_event_type: COPlatformEventType.CURATOR_REMOVED_FROM_USER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_CURATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Curator removed from user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.CURATOR_NAME +
              " as a Curator."
          },
          redirect_url: {
            value: EXPLORE_COMPONENTS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROCESS_HIERARCHY_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 115,
          platform_event_type: COPlatformEventType.PROCESS_HIERARCHY_UPDATED,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_HIERARCHY,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROCESS_HIERARCHY_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Process hierarchy updated"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the Automation Hierarchy in Platform setup - Hierarchy section."
          },
          redirect_url: {
            value: ADMIN_HIERARCHY_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.APPLICATION_INVENTORY_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 116,
          platform_event_type:
            COPlatformEventType.APPLICATION_INVENTORY_UPDATED,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_APP_INVENTORY,
          notification_group_slug:
            CONotificationActionGroupSlugs.APPLICATION_INVENTORY_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Application inventory updated"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the Application inventory in Platform setup - App inventory section."
          },
          redirect_url: {
            value: ADMIN_APP_INVENTORY_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.USER_INFORMATION_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 117,
          platform_event_type:
            COPlatformEventType.ADMIN_USERS_INFORMATION_UPDATED,
          notification_category_type: CONotificationActionCategoryTypes.USER,
          notification_group_slug:
            CONotificationActionGroupSlugs.USER_INFORMATION_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User's information updated"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the following information in your user profile: " +
              COActionReplacementStrings.UPDATED_USER_ATTRIBUTES +
              ""
          },
          redirect_url: {
            value: USER_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_ASSIGNED_PROGRAM_MANAGER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 118,
          platform_event_type: COPlatformEventType.ASSIGNED_PROGRAM_MANAGER,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_PROGRAM_MANAGER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were assigned Program Manager"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added you as a Program Manager."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_ASSIGNED_CURATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 119,
          platform_event_type: COPlatformEventType.ASSIGNED_CURATOR,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug: CONotificationActionGroupSlugs.ADDED_CURATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were assigned Curator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added you as a Curator."
          },
          redirect_url: {
            value: ADMIN_MANAGER_COMPONENTS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_AS_SYSTEM_ADMIN: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 120,
          platform_event_type: COPlatformEventType.REMOVED_AS_SYSTEM_ADMIN,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_SYSTEM_ADMIN,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were removed as System admin"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you as a System Admin."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_AS_PROGRAM_MANAGER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 121,
          platform_event_type: COPlatformEventType.REMOVED_AS_PROGRAM_MANAGER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_PROGRAM_MANAGER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were removed as Program Manager"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you as a Program Manager."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_AS_CURATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 122,
          platform_event_type: COPlatformEventType.REMOVED_AS_CURATOR,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_CURATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were removed as Curator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you as a Curator."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.AUTHORIZED_USER_ADDED_TO_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 135,
          platform_event_type:
            COPlatformEventType.AUTHORIZED_USER_ADDED_TO_USER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_AUTHORIZED_USER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Authorized User added to user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.AUTHORIZED_USER_NAME +
              " as a Authorized User."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COE_COLLABORATOR_ADDED_TO_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 136,
          platform_event_type:
            COPlatformEventType.COE_COLLABORATOR_ADDED_TO_USER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_COE_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CoE Collaborator added to user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.COE_COLLABORATOR_NAME +
              " as a CoE Collaborator."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_ASSIGNED_AUTHORIZED_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 137,
          platform_event_type: COPlatformEventType.ASSIGNED_AUTHORIZED_USER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_AUTHORIZED_USER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were assigned Authorized User"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added you as an Authorized User."
          },
          redirect_url: {
            value: TOP_DOWN_SUBMISSION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_ASSIGNED_COE_COLLABORATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 138,
          platform_event_type: COPlatformEventType.ASSIGNED_COE_COLLABORATOR,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_COE_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were assigned CoE Collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added you as a CoE Collaborator."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.AUTHORIZED_USER_REMOVED_FROM_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 139,
          platform_event_type:
            COPlatformEventType.AUTHORIZED_USER_REMOVED_FROM_USER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_AUTHORIZED_USER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Authorized User removed from user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.AUTHORIZED_USER_NAME +
              " as an Authorized User."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.COE_COLLABORATOR_REMOVED_FROM_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN, CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 140,
          platform_event_type:
            COPlatformEventType.COE_COLLABORATOR_REMOVED_FROM_USER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_COE_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CoE Collaborator removed from user"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.COE_COLLABORATOR_NAME +
              " as a Coe Collaborator."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_AS_AUTHORIZED_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 141,
          platform_event_type: COPlatformEventType.REMOVED_AS_AUTHORIZED_USER,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_AUTHORIZED_USER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were removed as Authorized User"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you as an Authorized User."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.YOU_WERE_REMOVED_AS_COE_COLLABORATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 142,
          platform_event_type: COPlatformEventType.REMOVED_AS_COE_COLLABORATOR,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_COE_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were removed as CoE Collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed you as a CoE Collaborator."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
    case CONotificationActionSlugs.USER_SYNC_FINISHED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER, CORoleType.TRIGGER_USER]
          },
          legacy_notification_id: 143,
          platform_event_type: COPlatformEventType.USER_SYNC_FINISHED,
          notification_category_type: CONotificationActionCategoryTypes.ADMIN,
          notification_group_slug:
            CONotificationActionGroupSlugs.USER_SYNC_FINISHED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User sync is completed"
          },
          message: {
            value:
              "The import of users and the mass changes from the Assign Roles page are now completed."
          },
          redirect_url: {
            value: ""
          }
        }
      };
    }
  }
  return undefined;
};
