import { COTaskActionTemplateInterface } from "../../../../interfaces/co-interfaces";
import { COActionTypes } from "../../../../constants/actions/co-action-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../../constants/co-path.constants";
import { COComponentType } from "../../../../constants/co-constants";
import { approveContentTaskActionTemplate } from "./types/co-approve-content-task-templates";
import { generateContentTaskActionTemplate } from "./types/co-generate-content-task-templates";
import { generalTasksTaskActionTemplate } from "./types/co-general-tasks-task-templates";
import { curateTaskActionTemplate } from "./types/co-curate-task-templates";
import { supportTaskActionTemplate } from "./types/co-support-task-templates";

export const taskActionTemplate = (
  slug: string
): COTaskActionTemplateInterface => {
  // get template based on slug
  let template = taskActionTemplateFromSlug(slug);

  // add values that will be the same for each notification
  template = {
    co_action_template_slug: slug,
    co_action_template_co_type: COActionTypes.TASK,
    ...template
  };
  template.co_action_json = {
    co_action_type: COActionTypes.TASK,
    co_action_template_slug: slug,
    ...(template.co_action_json ?? {})
  };
  template.co_meta_json = {
    title: {
      ...template.co_meta_json?.title,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    message: {
      ...template.co_meta_json?.title,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    contextual_message: PATH({
      route:
        PATH_ROUTE.CONTEXT.ACTION
          .FUNCTION_PARSE_ACTION_META_VALUE_TO_LOCALIZATION_META_ITEM,
      operator: PATH_OPERATORS.FUNCTION,
      operatorParams: {
        value: PATH({
          route: PATH_ROUTE.CONTEXT.ACTION.META.MESSAGE.VALUE
        })
      }
    }),
    redirect_url: {
      ...template.co_meta_json?.title,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    contextual_redirect_url: {
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.ACTION.FUNCTION_PARSE_ACTION_META_VALUE_TO_STRING,
        operator: PATH_OPERATORS.FUNCTION,
        operatorParams: {
          value: PATH({
            route: PATH_ROUTE.CONTEXT.ACTION.META.REDIRECT_URL.VALUE
          })
        }
      }),
      options: {
        is_hidden: 1
      }
    },
    ...(template.co_meta_json ?? {})
  };

  return template;
};

const taskActionTemplateFromSlug = (
  slug: string
): COTaskActionTemplateInterface => {
  return (
    approveContentTaskActionTemplate(slug) ??
    generateContentTaskActionTemplate(slug) ??
    generalTasksTaskActionTemplate(slug) ??
    curateTaskActionTemplate(slug) ??
    supportTaskActionTemplate(slug) ??
    {}
  );
};
