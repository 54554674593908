import {
  COProcessAnswerHistoryDBClass,
  COProcessAnswerHistoryInterface
} from "../interfaces/co-interfaces";
import { COBase } from "./co-base.class";
import { COQuestionAnswerOption } from "./co-question-answer-option.class";

export interface COProcessAnswerHistory
  extends COProcessAnswerHistoryInterface {}
export class COProcessAnswerHistory extends COBase {
  static getClassName(): string {
    return "COProcessAnswerHistory";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [
        {
          objectKey: "co_question_answer_option",
          objectClass: COQuestionAnswerOption
        }
      ],
      objects: [],
      dbClass: COProcessAnswerHistoryDBClass,
      objectClass: COProcessAnswerHistory
    };
  }
}
