import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";
import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import {
  COAssessmentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { defaultAssessmentTemplate } from "../co-assessment.template";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import { COSectionTemplateSlugs } from "../../constants/co-section.constants";

export const defaultEXAssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.EXECUTION_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.EX_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.EXECUTION_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.EX_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default Execution Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
    assessmentTemplate.co_options_json = {
      ...assessmentTemplate.co_options_json,
      condition_overrides: [
        { slug: COConditionSlugs.ASSESSMENT_SHOWN_AFTER_LIVE },
        { slug: COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING },
        {
          slug:
            COConditionSlugs.HIDE_IN_VIEW_MODE_IF_NO_QUESTIONS_ANSWERED_BY_USER_IN_ASSESSMENT
        },
        ...templateAssessmentConditions()
      ]
    };
  }
  return assessmentTemplate;
};

export const AHDefaultEXAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateEX() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-ex-0-0`;
  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHFreshEXAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateEX(false) });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "",
      sectionTemplateSlug: COSectionTemplateSlugs.EX_DEFAULT_SECTION_V1
    }),
    question_slugs: []
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Referenced questions and KPIs",
    description:
      "This section contains referenced questions from the other types of assessment, such that they can be used for computing the KPIs needed in the Actual benefit calculation. All questions and KPIs in this section are hidden everywhere, by default.",
    sectionTemplateSlug: COSectionTemplateSlugs.EX_DEFAULT_SECTION_V1,
    prevent_deleting: 0,
    is_hidden_everywhere: 1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.EX_DA_TIME_PER_TRANS_BEFORE,
      CustomQuestionSlugs.EX_DA_ERROR_RATE_BEFORE,
      CustomQuestionSlugs.EX_DA_REWORK_TIME_BEFORE,
      CustomQuestionSlugs.EX_DA_REVIEW_RATE,
      CustomQuestionSlugs.EX_DA_REVIEW_TIME,
      CustomQuestionSlugs.EX_DA_AVERAGE_PROCESSING_TIME_AFTER,
      CustomQuestionSlugs.EX_DA_AVERAGE_ERROR_RATE_AFTER,
      CustomQuestionSlugs.EX_DA_AVERAGE_REWORK_TIME_AFTER,
      CustomQuestionSlugs.EX_DA_AHT_PER_TRANSACTION,
      CustomQuestionSlugs.EX_DA_AVERAGE_EMPLOYEE_FULL_COST,
      CustomQuestionSlugs.EX_DA_AVERAGE_WORKING_DAYS_PER_YEAR,
      CustomQuestionSlugs.EX_DA_WORKING_HOURS_PER_DAY,
      CustomQuestionSlugs.EX_DA_TASK_PROCESS_FREQUENCY,
      CustomQuestionSlugs.EX_DA_ACTIVITY_VOLUME_AVERAGE,
      CustomQuestionSlugs.EX_DA_VOLUMES_FREQUENCY,
      CustomQuestionSlugs.EX_DA_NUMBER_OF_EMPLOYEES,
      CustomQuestionSlugs.EX_DA_HOURLY_EMPLOYEE_COST,
      CustomQuestionSlugs.EX_DA_MANUAL_VOLUMES_PER_YEAR,
      CustomQuestionSlugs.EX_DETAILED_ASSESSMENT__AUTOMATION_POTENTIAL,
      CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_BEFORE,
      CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_AFTER,
      CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_BEFORE,
      CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_AFTER,
      CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_BEFORE,
      CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_AFTER,
      CustomQuestionSlugs.EX_CDA_AHT_SAVED_PER_TRANSACTION,
      CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__VOLUME_AVERAGE,
      CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__PROCESS_FREQUENCY,
      CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__NUMBER_OF_PEOPLE,
      CustomQuestionSlugs.EX_CDA_MANUAL_VOLUMES_PER_YEAR
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Actual savings data",
    description:
      "Select one or several Orchestrator packages that reflect the automation build for the idea, and define the additional metadata needed for computing the actual benefits. This information with be used in UiPath Insights, for ROI reporting.",
    sectionTemplateSlug: COSectionTemplateSlugs.EX_DEFAULT_SECTION_V1,
    prevent_deleting: 0,
    hide_before_live_phase: 1,
    section_is_tabular: 1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.EX_ORCHESTRATOR_PACKAGE_INSIGHTS,
      CustomQuestionSlugs.EX_USING_QUEUES_INSIGHTS,
      CustomQuestionSlugs.EX_REFERENCE_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS,
      CustomQuestionSlugs.EX_FINAL_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS,
      CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS,
      CustomQuestionSlugs.EX_COST_SAVINGS_PER_RUN_INSIGHTS,
      CustomQuestionSlugs.EX_YEARLY_AUTOMATED_VOLUMES_INSIGHTS,
      CustomQuestionSlugs.EX_YEARLY_TARGET_HOURS_SAVED_INSIGHTS,
      CustomQuestionSlugs.EX_YEARLY_TARGET_COST_SAVINGS_INSIGHTS
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Total actual savings per automation idea",
    description:
      "Aggregates the target actual savings for the entire automation idea",
    sectionTemplateSlug: COSectionTemplateSlugs.EX_DEFAULT_SECTION_V1,
    prevent_deleting: 0,
    hide_before_live_phase: 1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.EX_TOTAL_TIME_SAVINGS_PER_RUN_INSIGHTS,
      CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_PER_RUN_INSIGHTS,
      CustomQuestionSlugs.EX_TOTAL_HOURS_SAVED_INSIGHTS,
      CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_INSIGHTS
    ]
  });
};

export const assessmentTemplateEX = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultEXAssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Execution Assessment",
      description:
        "Fill in the business data needed for computing the Actual benefits based on the automation's successful runs"
    });
    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New Execution Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};
