import { coUUID, isNullOrUndefined, mergeObjects } from "../utils/co-utils";
import {
  optionsInConditionalContext,
  optionsInContext
} from "../helpers/co-context.helper";
import { isVisibleBasedOnPermissions } from "../helpers/co-permissions.helper";
import {
  COBaseInterface,
  COBaseTemplateInterface,
  COContextInterface,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COQuestionInterface,
  COValidationItemInterface
} from "../interfaces/co-interfaces";
import {
  newContextWithoutImporting,
  loadTemplateForSlugWithoutImporting
} from "../helpers/co-circular-refrence-helper";

export interface COBase extends COBaseInterface {}
const AHID_GENERIC_KEY = "AHID";

export class COBase {
  dont_resolve?: boolean = true;

  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    if (propertiesFromJSON) {
      for (const key of Object.keys(propertiesFromJSON)) {
        this[key] = propertiesFromJSON[key];
      }
    }
    this.dont_resolve = true;
    Object.defineProperty(this, "calculation_options", {
      get() {
        return this.template?.co_calculation_json || {};
      }
    });
    Object.defineProperty(this, "controls", {
      get() {
        return this.template?.co_controls_json || [];
      }
    });
    Object.defineProperty(this, "options", {
      get() {
        let objectOptions = this.objectOptions?.() || {};
        let templateOptions = this.template?.co_options_json || {};

        let merged = {
          ...templateOptions,
          ...objectOptions,
          display_context_overrides: mergeObjects(
            {
              ...(templateOptions.display_context_overrides || {})
            },
            { ...(objectOptions.display_context_overrides || {}) }
          ),
          condition_overrides: [
            ...(objectOptions.condition_overrides || []),
            ...(templateOptions.condition_overrides || [])
          ]
        };
        return merged;
      }
    });
    Object.defineProperty(this, "validators", {
      get() {
        return [
          ...(this.customValidators?.() || []),
          ...(this.template?.co_validators_json || [])
        ];
      }
    });
    Object.defineProperty(this, "meta", {
      // WE DO NOT MERGE META
      get() {
        return this.template?.co_meta_json || {};
      }
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: COBase,
      objectClass: COBase
    };
  }

  checkAndLoadTemplate?() {
    if (!this.template) {
      //we need templates validation and control options
      let template_slug = this.templateSlug?.();
      if (template_slug) {
        let templateFromSlug: COBaseTemplateInterface = loadTemplateForSlugWithoutImporting(
          template_slug
        );

        if (templateFromSlug) {
          this.template = templateFromSlug;
        }
      }
    }
  }
  templateSlug?(): string | undefined {
    return "";
  }

  debugInfo?: any;
  generateDebugInfo?() {
    console.log(this.objectClassMapping?.dbClass);
    this.debugInfo = {
      validators: this.validators,
      controls: this.controls,
      calculation_options: this.calculation_options
    };
    console.log(this.debugInfo);
  }

  optionsWithOverrides?(context, options) {
    return optionsInConditionalContext({
      context: context,
      options: options
    });
  }

  // recursive is find for finding ahid
  findAHId?(ahid: string): COBaseInterface | null {
    const results = this.findAHIds?.(ahid);
    if (results && results.length > 0) {
      return results[0];
    }
    return null;
  }
  // recursive is find for finding ahid
  findAHIds?(ahid: string): COBaseInterface[] {
    if (this.findObjectsWithPropertyOfValue) {
      let matches = this.findObjectsWithPropertyOfValue(AHID_GENERIC_KEY, ahid);
      if (matches && matches.length > 0) {
        return matches;
      }
    }
    return [];
  }

  findObjectsWithPropertyOfValue?(
    property: string,
    value: any
  ): COBaseInterface[] {
    let objectsFound: COBaseInterface[] = [];

    let propertyToCheck = property;
    if (property === AHID_GENERIC_KEY) {
      propertyToCheck = this.objectClassMapping?.ahid_key || "";
    }

    if (this[propertyToCheck] === value) {
      objectsFound.push(this);
    }

    let classMapping = this.objectClassMapping;
    if (classMapping) {
      for (const element of classMapping.objects) {
        let co_object: COBase = this[element.objectKey];
        if (co_object) {
          if (co_object.findObjectsWithPropertyOfValue) {
            let matches = co_object.findObjectsWithPropertyOfValue(
              property,
              value
            );
            if (matches) {
              objectsFound = [...objectsFound, ...matches];
            }
          }
        }
      }
      for (const arrayObject of classMapping.arrays) {
        let objectArray: COBase[] = this[arrayObject.objectKey] || [];
        if (objectArray) {
          for (const co_ob of objectArray) {
            if (co_ob.findObjectsWithPropertyOfValue) {
              let matches = co_ob.findObjectsWithPropertyOfValue(
                property,
                value
              );
              if (matches) {
                objectsFound = [...objectsFound, ...matches];
              }
            }
          }
        }
      }
    }
    return objectsFound;
  }

  // cranks through and sorts everything all sub objects by sort_order
  sort?(): COBase {
    let classMapping = this.objectClassMapping;
    if (classMapping) {
      for (const element of classMapping.objects) {
        this[element.objectKey]?.sort();
      }
      for (const arrayObject of classMapping.arrays) {
        let itemArray = this[arrayObject.objectKey];
        if (
          arrayObject.objectClass?.staticObjectClassMapping &&
          Array.isArray(itemArray)
        ) {
          this[arrayObject.objectKey] = itemArray.sort((a, b) => {
            return (a.sortOrder?.() || 0) - (b.sortOrder?.() || 0);
          });
          for (const item of this[arrayObject.objectKey]) {
            item.sort?.();
          }
        }
      }
    }
    return this;
  }

  objectMeta?(): COMetadataInterface {
    return {};
  }

  objectPosition?(): COPositionInterface {
    return {};
  }

  objectOptions?(): COOptionsInterface {
    return {};
  }

  customValidators?(): COValidationItemInterface[] {
    return [];
  }

  sortOrder?(): number {
    return this.objectPosition?.().sort_order || 0;
  }

  // we now can have a search fliter - you can be displayed if you title matches the filter or if something with a title below you matches the filter

  isVisibleInContext?(context?: COContextInterface): boolean {
    // update the context for this object we can, necessary for some functions to work
    if (!context) {
      console.log("no context passed into visibility check");
      return true;
    }

    // let superdebug = false;
    // if (this.objectClassMapping?.contextKey === "assessment") {
    //   if (
    //     context?.assessment?.co_assessment_template_slug === "Q1_DEFAULT_V1"
    //   ) {
    //     superdebug = true;
    //   }
    // }

    let contextWithThisObject: COContextInterface = context;

    if (this?.objectClassMapping?.contextKey) {
      contextWithThisObject =
        contextWithThisObject?.update?.({
          [this.objectClassMapping?.contextKey]: this
        }) || contextWithThisObject;
    }

    // this handles all conditional logic now
    const resolvedOptionsInContext: COOptionsInterface = optionsInContext({
      context: contextWithThisObject,
      options: this.options || {},
      should_resolve: true
    });

    if (resolvedOptionsInContext?.is_hidden) {
      return false;
    }

    // can generically deal with permissions here
    // the question is do we want to ignore this on the assessment customization screen, and submit/edit- I think yes
    if (
      !isVisibleBasedOnPermissions({
        context: contextWithThisObject,
        resolvedOptionsInContext
      })
    ) {
      return false;
    }

    // search through objet class mapping for any visibility
    // this is only for search
    let searchContext = contextWithThisObject.display_context?.search_filter;
    if (searchContext && searchContext.length > 0) {
      let searchName = this.objectSearchName?.() || "";
      if (searchName.toLowerCase().includes(searchContext.toLowerCase())) {
        return true;
      }
      if (searchName) {
        // see if any children in arrays of this object are visible -  this this is going to be SLOW
        if (this.objectClassMapping?.arrays) {
          for (const arrayObject of this.objectClassMapping.arrays) {
            if (this[arrayObject.objectKey]) {
              for (const coObjectInArray of this[arrayObject.objectKey]) {
                if (
                  coObjectInArray?.isVisibleInContext?.(contextWithThisObject)
                ) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false; // no match or child match
    }

    return true;
  }

  objectDisplayName?(includeObjectType?: boolean): string {
    let typeName = this.objectClassMapping?.objectClass?.getType?.() || "";
    return (
      (includeObjectType ? typeName + ": " : "") +
      (this.objectMeta?.()?.title?.value || "")
    );
  }

  objectSearchName?(): string {
    return this.objectDisplayName?.(false) || "";
  }

  // toJSON is automatically used by JSON.stringify
  // HOWEVER THIS ONLY WORKS ONE LAYER DEEP - NEED IT TO WORK RECURSEVELY
  toJSON?(): any {
    let jsonObject = Object.assign({}, this, {
      // convert fields that need converting
    });
    jsonObject = this.prepareForJSON?.(jsonObject);

    return jsonObject;
  }

  prepareForJSON?(jsonObject: any): any {
    delete jsonObject["objectClassMapping"];
    delete jsonObject["validation_errors"];
    delete jsonObject["cache"];
    delete jsonObject["template"];
    delete jsonObject["contextForOptionsAndMetaMerging"];
    return jsonObject;
  }

  getAHID?(): string | undefined {
    if (this.objectClassMapping && this.objectClassMapping.ahid_key) {
      return this[this.objectClassMapping.ahid_key];
    }
    return undefined;
  }

  incrementRenderCount?() {
    if (!this.renderCounter) {
      this.renderCounter = 0;
    }
    this.renderCounter++;
  }

  static ahid(object: COBase): string | undefined {
    return object[this.staticObjectClassMapping?.ahid_key || "ahid"];
  }

  static isAHID(ahid: string) {
    return ahid && typeof ahid === "string" && ahid.split("-").length > 1;
  }

  // this *must* be overloaded in the derived classes:
  // https://stackoverflow.com/questions/13613524/get-an-objects-class-name-at-runtime
  static getClassName(): string {
    return this.name;
  }

  static getType(): string {
    let type = this.staticObjectClassMapping?.contextKey?.toString();
    if (!type) {
      type = this.getClassName()
        .toLowerCase()
        .substring(2);
    }

    // limit to 30 characters
    return type?.substring(0, 30);
  }

  static generateAHID() {
    return `ah-${this.getType()}-${new Date().getTime()}-${coUUID()}`;
  }

  static get staticObjectClassMapping() {
    return new this({}).objectClassMapping;
  }

  // when we define things in the custom question templates (like for default assessment) we don't know the ahids - so we set the vars and convert
  static updateQuestionVariableToAHIDFromCustomTemplate({
    context,
    parent,
    propertyToUpdate
  }: {
    context: COContextInterface;
    parent: any;
    propertyToUpdate: string;
  }) {
    let assessment: COBase | undefined = context?.assessment;
    if (assessment) {
      let questionsFound:
        | COBaseInterface[]
        | undefined = assessment.findObjectsWithPropertyOfValue?.(
        "co_variable_name",
        parent[propertyToUpdate] || "unknown"
      );
      if (questionsFound && questionsFound.length > 0) {
        let question: COQuestionInterface = questionsFound[0];
        parent[propertyToUpdate] = question.co_question_ahid;
      }
    }
  }

  //puts the json object into class
  static objectFromJSON(json: any): COBase | null {
    if (!json) {
      return null;
    }
    let classMapping = this.staticObjectClassMapping;
    let mappingObject = {};
    if (classMapping) {
      for (const element of classMapping.objects) {
        mappingObject[element.objectKey] = element.objectClass.objectFromJSON(
          json[element.objectKey]
        );
      }
      for (const arrayObject of classMapping.arrays) {
        mappingObject[arrayObject.objectKey] = (
          json[arrayObject.objectKey] || []
        ).map(element => arrayObject.objectClass.objectFromJSON(element));
      }
    }

    let propertiesFromJSON = { ...json, ...mappingObject };
    let classObject = new this({
      propertiesFromJSON: propertiesFromJSON
    });
    return classObject;
  }

  // load generically into object - parsing json
  static loadFromDBResult(response: any): COBase {
    let thisObject: any = {};
    for (var prop in response) {
      if (Object.prototype.hasOwnProperty.call(response, prop)) {
        if (prop.includes("_json")) {
          if (response[prop]) {
            try {
              thisObject[prop] = JSON.parse(response[prop] || "");
            } catch (error) {
              thisObject[prop] = error;
              console.log(
                `CoBase JSON Parse Error ${error} ${prop} ${response[prop]}`
              );
            }
          } else {
            thisObject[prop] = {};
          }
        } else {
          thisObject[prop] = response[prop];
        }
      }
    }
    return new this({ propertiesFromJSON: thisObject });
  }

  //gets columns for db insert / update
  static dbTableColumnNames(): string[] {
    if (
      this.staticObjectClassMapping &&
      this.staticObjectClassMapping.dbClass
    ) {
      return Object.keys(new this.staticObjectClassMapping.dbClass());
    }
    return [];
  }

  static prepareForInsertUpdate(co_object): any {
    let preparedObject: any = {};
    let validDBKeys = this.dbTableColumnNames();
    let objectProperties: string[] = Object.keys(co_object);
    for (var i = 0; i < objectProperties.length; i++) {
      var prop: string = objectProperties[i];
      //we only want the keys we can actually store in the DB - which come from the db interface
      if (validDBKeys.includes(prop)) {
        let value = co_object[prop];
        if (!isNullOrUndefined(value)) {
          if (prop.includes("_json")) {
            try {
              if (typeof value === "object" && value !== null) {
                value = JSON.stringify(value || {});
              }
            } catch (error) {
              console.log(`CoBase JSON Parse Error ${error} ${prop} ${value}`);
            }
          }
          preparedObject[prop] = value;
        }
      }
    }
    return preparedObject;
  }

  // overridden in the classes with actual context
  static setContext(
    context?: COContextInterface,
    self?: COBase
  ): COContextInterface {
    let contextKey;
    if (self?.objectClassMapping?.contextKey) {
      contextKey = self.objectClassMapping.contextKey;
    } else {
      contextKey = this.staticObjectClassMapping?.contextKey; // so this takes it if you call COSection.setContext
    }
    if (contextKey && self) {
      let ctx: COContextInterface = context || newContextWithoutImporting();
      ctx = ctx.update?.({ [contextKey]: self }) || ctx;
      return ctx;
    }
    return newContextWithoutImporting();
  }

  static prepareForCalculation(context: COContextInterface, co_object: COBase) {
    if (co_object) {
      context = this.setContext(context, co_object);
      let classMapping = this.staticObjectClassMapping;
      if (classMapping) {
        for (const element of classMapping.objects) {
          if (co_object[element.objectKey]) {
            element.objectClass.prepareForCalculation(
              context,
              co_object[element.objectKey]
            );
          }
        }
        for (const arrayObject of classMapping.arrays) {
          if (co_object[arrayObject.objectKey]) {
            for (const coObjectInArray of co_object[arrayObject.objectKey]) {
              arrayObject.objectClass.prepareForCalculation(
                context,
                coObjectInArray
              );
            }
          }
        }
      }
    }
  }

  static incrementRenderCounters(
    context: COContextInterface,
    co_object: COBase
  ) {
    if (context?.assessment) {
      // @ts-ignore
      context?.assessment?.incrementRenderCount?.();
    }
    if (context?.section) {
      // @ts-ignore
      context?.section?.incrementRenderCount?.();
    }
    if (context?.question) {
      // @ts-ignore
      context?.question?.incrementRenderCount?.();
    }
    if (co_object) {
      co_object?.incrementRenderCount?.();
      context = this.setContext(context, co_object);
      let classMapping = this.staticObjectClassMapping;
      if (classMapping) {
        for (const element of classMapping.objects) {
          if (co_object[element.objectKey]) {
            element.objectClass.incrementRenderCounters(
              context,
              co_object[element.objectKey]
            );
          }
        }
        for (const arrayObject of classMapping.arrays) {
          if (co_object[arrayObject.objectKey]) {
            for (const coObjectInArray of co_object[arrayObject.objectKey]) {
              arrayObject.objectClass.incrementRenderCounters(
                context,
                coObjectInArray
              );
            }
          }
        }
      }
    }
  }

  onRemoveFromAssessment?(context?: COContextInterface) {
    let classMapping = this.objectClassMapping;
    if (classMapping) {
      for (const element of classMapping.objects) {
        const coObjectElement = this[element.objectKey];
        if (coObjectElement) {
          const coObjectContext = COBase.setContext(context, coObjectElement);
          coObjectElement.onRemoveFromAssessment?.(coObjectContext);
        }
      }
      for (const arrayObject of classMapping.arrays) {
        if (this[arrayObject.objectKey]) {
          for (const coObjectInArray of this[arrayObject.objectKey]) {
            const coObjectContext = COBase.setContext(context, coObjectInArray);
            coObjectInArray.onRemoveFromAssessment?.(coObjectContext);
          }
        }
      }
    }
  }
}
