import {
  FETCH_PPP_COMMENTS,
  ADD_NEW_PPP_COMMENT,
  SET_PPP_COMMENT_ID_TO_EDIT,
  SET_PPP_COMMENT_ID_TO_REPLY_TO,
  DELETE_PPP_COMMENT,
  UPDATE_PPP_COMMENT,
  CLEAR_PPP_COMMENTS
} from "../constants/actionTypes";

import uniqBy from "lodash/uniqBy";

const INITIAL_STATE = {
  processProfileComments: null,
  currentProcessProfileCommentPage: 1,
  totalProcessProfilePages: 1,
  commentIdToEdit: null,
  commentIdToReplyTo: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PPP_COMMENTS:
      let payload = action.payload;
      if (state.processProfileComments) {
        var currentComments = state.processProfileComments.comments;
        var newComments = payload.comments;
        let allComments = uniqBy(
          [...newComments, ...currentComments],
          "comment_id"
        );
        payload.comments = [...allComments].sort(
          (a, b) => b.comment_created_epoch - a.comment_created_epoch
        );
      } else {
        payload.comments = payload.comments.sort(
          (a, b) => b.comment_created_epoch - a.comment_created_epoch
        );
      }
      return {
        ...state,
        processProfileComments: payload,
        needToFetchProcessProfileComments: false
      };
    case DELETE_PPP_COMMENT:
      var processProfileComments = { ...state.processProfileComments };
      if (action.payload.comment_parent_id) {
        let commentParent = processProfileComments.comments.find(
          obj => obj.comment_id === action.payload.comment_parent_id
        );
        if (commentParent) {
          commentParent.replies = commentParent.replies.filter(
            obj => obj.comment_id !== action.payload.comment_id
          );
        }
      } else {
        processProfileComments.comments = processProfileComments.comments.filter(
          obj => obj.comment_id !== action.payload.comment_id
        );
      }
      return {
        ...state,
        processProfileComments: processProfileComments
      };

    case UPDATE_PPP_COMMENT:
      processProfileComments = { ...state.processProfileComments };
      var index = processProfileComments.comments.findIndex(
        obj => obj.comment_id === action.payload.comment_id
      );
      if (index > -1) {
        processProfileComments.comments[index] = action.payload;
      }
      return {
        ...state,
        processProfileComments: processProfileComments
      };
    case ADD_NEW_PPP_COMMENT:
      processProfileComments = null;
      if (state.processProfileComments) {
        processProfileComments = { ...state.processProfileComments };
        processProfileComments.comments.unshift(action.payload);
      }
      return {
        ...state,
        processProfileComments: processProfileComments
      };
    case SET_PPP_COMMENT_ID_TO_EDIT:
      return {
        ...state,
        commentIdToEdit: action.payload
      };
    case SET_PPP_COMMENT_ID_TO_REPLY_TO:
      return {
        ...state,
        commentIdToReplyTo: action.payload
      };
    case CLEAR_PPP_COMMENTS:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
