import {
  COContextInterface,
  COLegacyTaskInterface,
  COTaskActionTemplateInterface,
  COTaskActionInterface,
  COUserInterface,
  COTaskActionOptionsInterface,
  COActionOptionsInterface,
  COActionContextValuesInterface
} from "../../interfaces/co-interfaces";
import { COLoadableProcessFields } from "../../constants/co-constants";
import {
  COTaskActionSlugs,
  COTaskType,
  COTaskPopUpMessageSource
} from "../../constants/actions/co-task-action-constants";
import { actionTemplate } from "../../templates/elements/actions/co-action-templates";
import { localizeStringWithValues } from "../localization/co-localize-string.helper";

export const convertLegacyTaskTypeToCOActionType = ({
  legacy_type
}: {
  legacy_type: string;
}): string => {
  return legacy_type
    .toUpperCase()
    .split("-")
    .join("_");
};

export const convertLegacyTaskToAction = ({
  legacy_action,
  targetUserIds
}: {
  legacy_action: COLegacyTaskInterface;
  targetUserIds?: string[];
}): COTaskActionInterface | undefined => {
  const legacySlug: string | undefined = legacy_action.task_activity_key;
  if (!legacySlug) {
    return undefined;
  }
  let actionSlug = convertLegacyTaskTypeToCOActionType({
    legacy_type: legacySlug
  });
  if (!actionSlug) {
    return undefined;
  }
  if (!Object.keys(COTaskActionSlugs).includes(actionSlug)) {
    // add "_TASK" and check again
    actionSlug = actionSlug + "_TASK";
    if (!Object.keys(COTaskActionSlugs).includes(actionSlug)) {
      console.log(
        "Found a task slug that we don't have in the CO system: " + actionSlug
      );
      return undefined;
    }
  }

  const template: COTaskActionTemplateInterface = actionTemplate(
    actionSlug
  ) as COTaskActionTemplateInterface;

  let options: any = template.co_options_json;
  if (options) {
    if (targetUserIds) {
      if (options.action_target) {
        options.action_target = {
          target_user_ids: targetUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          target_user_ids: targetUserIds
        };
      }
    }
  }

  if (legacy_action.task_is_completed === 1) {
    options = options as COTaskActionOptionsInterface;
    options.task_completion = {
      task_is_completed: 1
    };
  }

  let actionContextValues: COActionContextValuesInterface = {
    process_id: legacy_action.process_id,
    process_specific_fields: [
      COLoadableProcessFields.phase_status,
      COLoadableProcessFields.collaborators
    ],
    component_id: legacy_action.component_id,
    reference_user_id: legacy_action.reference_user_id,
    legacy_text_replacement_values:
      legacy_action.task_text_replacements ?? legacy_action.replaced_texts
  };
  actionContextValues = addReferencedObjectIDToContextValuesForTask({
    template,
    referenceObjectId: legacy_action.task_reference_object_id,
    actionContextValues
  });

  return {
    co_action_template_slug: actionSlug,
    co_action_type: template.co_action_template_co_type,
    co_action_meta_json: template.co_meta_json,
    co_action_options_json: options,
    template: template,
    created_epoch: legacy_action.created_epoch,
    updated_epoch: legacy_action.updated_epoch,
    legacy_data: legacy_action,
    co_user_action: {},
    co_action_context_values_json: actionContextValues
  };
};

export const getTaskInterfaceForTemplate = ({
  template,
  targetUserIds,
  actionContextValues
}: {
  template: COTaskActionTemplateInterface;
  targetUserIds?: string[];
  actionContextValues: COActionContextValuesInterface;
}): COTaskActionInterface => {
  const options = template.co_options_json;
  if (options) {
    if (targetUserIds) {
      if (options.action_target) {
        options.action_target = {
          target_user_ids: targetUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          target_user_ids: targetUserIds
        };
      }
    }
  }

  return {
    co_action_template_slug: template.co_action_template_slug,
    co_action_type: template.co_action_template_co_type,
    co_action_meta_json: template.co_meta_json,
    co_action_options_json: options,
    template: template,
    co_action_context_values_json: actionContextValues
  };
};

export const addReferencedObjectIDToContextValuesForTask = ({
  template,
  referenceObjectId,
  actionContextValues
}: {
  template: COTaskActionTemplateInterface;
  referenceObjectId?: number;
  actionContextValues: COActionContextValuesInterface;
}): COActionContextValuesInterface => {
  let role_id: number | undefined = undefined;
  let reported_process_id: number | undefined = undefined;
  let process_reported_comment_id: number | undefined = undefined;

  const taskOptions: COTaskActionOptionsInterface = template.co_options_json as COTaskActionOptionsInterface;
  if (
    taskOptions &&
    taskOptions.task_popup_message_source &&
    referenceObjectId &&
    referenceObjectId > 0
  ) {
    switch (taskOptions.task_popup_message_source) {
      case COTaskPopUpMessageSource.PROCESS_REPORT: {
        reported_process_id = referenceObjectId;
        break;
      }
      case COTaskPopUpMessageSource.PROCESS_REPORTED_COMMENT: {
        process_reported_comment_id = referenceObjectId;
        break;
      }
      case COTaskPopUpMessageSource.ROLE_COMMENT: {
        role_id = referenceObjectId;
        break;
      }
    }
  }

  return {
    ...actionContextValues,
    role_id: role_id,
    reported_process_id: reported_process_id,
    process_reported_comment_id: process_reported_comment_id
  };
};

export const getTaskTemplatesForActivityTypeID = ({
  activityType
}: {
  activityType: number;
}): COTaskActionTemplateInterface[] => {
  return Object.keys(COTaskActionSlugs)
    .map(slug => {
      return actionTemplate(slug) as COTaskActionTemplateInterface;
    })
    .filter(template => {
      if (template.co_options_json) {
        // check that the task is active and not deleted
        const options: COActionOptionsInterface = template.co_options_json as COActionOptionsInterface;
        if (options.is_deleted === 1 || options.is_inactive === 1) {
          return false;
        }

        // check for activity type id match
        return template.co_options_json.task_activity_type_id === activityType;
      }
    });
};

export const taskCommentForTask = ({
  context,
  action
}: {
  context: COContextInterface;
  action: COTaskActionInterface;
}): { user: COUserInterface; comment: any } | undefined => {
  const options:
    | COTaskActionOptionsInterface
    | undefined = action.co_action_options_json as COTaskActionOptionsInterface;
  if (options && action.co_action_context?.referenced_user) {
    let comment: any = undefined;

    switch (options.task_popup_message_source) {
      case COTaskPopUpMessageSource.PROCESS_REPORT: {
        if (action.co_action_context?.reported_process) {
          comment = action.co_action_context.reported_process.reported_reason;
        }
        break;
      }
      case COTaskPopUpMessageSource.PROCESS_REPORTED_COMMENT: {
        if (action.co_action_context?.process_reported_comment) {
          comment =
            action.co_action_context.process_reported_comment.reported_reason;
        }
        break;
      }
      case COTaskPopUpMessageSource.ROLE_COMMENT: {
        if (action.co_action_context?.role) {
          comment = action.co_action_context.role.comment;
        }
        break;
      }
      case COTaskPopUpMessageSource.PROCESS_REJECTED_REASON: {
        if (action.co_action_context?.process) {
          comment = action.co_action_context.process.process_rejected_reason;
        }
        break;
      }
    }

    if (comment) {
      return { comment, user: action.co_action_context?.referenced_user };
    }
  }
  return undefined;
};

export const nameForTaskType = ({
  context,
  type,
  t
}: {
  context: COContextInterface;
  type?: COTaskType;
  t: any;
}): string => {
  switch (type) {
    case COTaskType.APPROVE_CONTENT: {
      return localizeStringWithValues({
        context,
        value: "Approve Content",
        localization_key: "co_action_task_type_approve_content",
        t
      });
    }
    case COTaskType.CURATE: {
      return localizeStringWithValues({
        context,
        value: "Curate",
        localization_key: "co_action_task_type_curate",
        t
      });
    }
    case COTaskType.GENERATE_CONTENT: {
      return localizeStringWithValues({
        context,
        value: "Generate Content",
        localization_key: "co_action_task_type_generate_content",
        t
      });
    }
    case COTaskType.GENERAL_TASK: {
      return localizeStringWithValues({
        context,
        value: "General Task",
        localization_key: "co_action_task_type_general_task",
        t
      });
    }
    case COTaskType.SUPPORT: {
      return localizeStringWithValues({
        context,
        value: "Support",
        localization_key: "co_action_task_type_support",
        t
      });
    }
  }
  return "";
};

export const taskIdForTaskType = ({
  taskType
}: {
  taskType: string;
}): number | undefined => {
  switch (taskType) {
    case COTaskType.APPROVE_CONTENT: {
      return 1;
    }
    case COTaskType.GENERATE_CONTENT: {
      return 2;
    }
    case COTaskType.GENERAL_TASK: {
      return 3;
    }
    case COTaskType.CURATE: {
      return 4;
    }
    case COTaskType.SUPPORT: {
      return 5;
    }
  }
  return undefined;
};
