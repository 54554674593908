import { COComponentType } from "../../../constants/co-constants";
import {
  PATH,
  PATH_OPERATORS,
  PATH_ROUTE
} from "../../../constants/co-path.constants";
import {
  COContextInterface,
  COControlInterface,
  COMetadataInterface
} from "../../../interfaces/co-interfaces";

export const tabularControlMeta = ({
  co_table_row_index = 0,
  num_rows = 0,
  callback,
  is_for_controls_left,
  is_for_controls_right
}: {
  co_table_row_index: number;
  num_rows: number;
  callback?: () => void;
  is_for_controls_left?: boolean;
  is_for_controls_right?: boolean;
}): COMetadataInterface => {
  const controls: any[] = [];

  if (
    is_for_controls_left ||
    (!is_for_controls_left && !is_for_controls_right)
  ) {
    controls.push({
      meta: {
        title: {
          apollo_icon: "add_circle",
          color: "--color-primary"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_BUTTON
      },
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        if (context.section) {
          context.section.addTabularSectionRow?.({
            context,
            co_table_row_index
          });
          callback?.();
        }
      }
    });
  }

  if (
    (is_for_controls_right ||
      (!is_for_controls_left && !is_for_controls_right)) &&
    num_rows > 1
  ) {
    controls.push({
      meta: {
        title: {
          apollo_icon: "remove_circle",
          color: "--color-error-icon"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_BUTTON
      },
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        if (context.section) {
          context.section.removeTabularSectionRow?.({
            context,
            co_table_row_index
          });
          callback?.();
        }
      }
    });
  }

  return {
    tabular: {
      is_editable: true,
      label: "Section Tabular controls",
      position: { sort_order: 3 },
      options: {
        component_type: COComponentType.META_CONTROL,
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_TABULAR,
          operator: PATH_OPERATORS.INVERSE
        })
      },
      controls: controls,
      display_context: {
        assessment_submission: 1
      }
    }
  };
};
