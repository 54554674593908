import React from "react";
import {
  PROCESS_SUBMISSION_TYPE_QUESTIONNAIRE,
  PROCESS_SUBMISSION_TYPE_TOP_DOWN,
  PROCESS_SUBMISSION_TYPE_CITIZEN_DEVELOPER,
  PROCESS_SUBMISSION_TYPE_PROCESS_MINING,
  PROCESS_SUBMISSION_TYPE_TASK_MINING,
  PROCESS_SUBMISSION_TYPE_CHANGE_REQUEST
} from "../constants/profileConstants.js";
import { getLocalizedRoleName } from "./userRoleLocalizationUtils";

export const ROLE_TYPE_SERVICE = "service";
export const ROLE_TYPE_PROCESS = "process";
export const ROLE_TYPE_SYSTEM = "system";

// submitters
export const SUBMITTER_ROLE_SLUG = "ah-submitter";
export const TOP_DOWN_SUBMITTER_ROLE_SLUG = "ah-top-down-submitter";
export const AUTOMATION_SUBMITTER_ROLE_SLUG = "ah-automation-submitter";
export const PROCESS_MINING_SUBMITTER_ROLE_SLUG = "ah-process-mining-submitter";
export const TASK_MINING_SUBMITTER_ROLE_SLUG = "ah-task-mining-submitter";
export const CHANGE_REQUEST_SUBMITTER_ROLE_SLUG = "ah-change-request-submitter";

export const PROCESS_OWNER_ROLE_SLUG = "ah-process-owner";
export const CITIZEN_DEVELOPER_ROLE_SLUG = "ah-citizen-developer";
export const ACCOUNT_OWNER_ROLE_SLUG = "ah-account-owner";
export const AUTOMATION_CONSUMER_ROLE_SLUG = "ah-automation-consumer";

// checkboxes
export const CHECKBOX_EDIT_ABOUT_ROLE_SLUG = "ah-process-about-section-editor";
export const CHECKBOX_EDIT_CBA_ROLE_SLUG = "ah-process-cba-section-editor";
export const CHECKBOX_EDIT_DOCS_ROLE_SLUG =
  "ah-process-documentation-section-editor";
export const CHECKBOX_EDIT_COMPONENTS_ROLE_SLUG =
  "ah-process-component-section-editor";
export const CHECKBOX_BUCKET_SLUGS = {
  [CHECKBOX_EDIT_ABOUT_ROLE_SLUG]: "edit-process",
  [CHECKBOX_EDIT_CBA_ROLE_SLUG]: "edit-cba",
  [CHECKBOX_EDIT_DOCS_ROLE_SLUG]: "edit-process-documentation",
  [CHECKBOX_EDIT_COMPONENTS_ROLE_SLUG]: "edit-process-components"
};

// need to only allow roles that a USER can have + some non restricted ones like process_owner and submitter
// used for AddCollaboratorRoleDropdownn
export const GLOBALLY_ASSIGNABLE_ROLE_SLUGS = [
  TOP_DOWN_SUBMITTER_ROLE_SLUG,
  SUBMITTER_ROLE_SLUG,
  AUTOMATION_SUBMITTER_ROLE_SLUG,
  CHANGE_REQUEST_SUBMITTER_ROLE_SLUG,
  PROCESS_MINING_SUBMITTER_ROLE_SLUG,
  TASK_MINING_SUBMITTER_ROLE_SLUG
];

export const SUBMITTER_ROLE_SLUGS = [
  TOP_DOWN_SUBMITTER_ROLE_SLUG,
  SUBMITTER_ROLE_SLUG,
  AUTOMATION_SUBMITTER_ROLE_SLUG,
  CHANGE_REQUEST_SUBMITTER_ROLE_SLUG,
  PROCESS_MINING_SUBMITTER_ROLE_SLUG,
  TASK_MINING_SUBMITTER_ROLE_SLUG
];

// used in AutomationProfilePageManageCollaborators to remove invalid options for roles
export const SUBMISSION_TYPE_TO_ROLES = {
  [PROCESS_SUBMISSION_TYPE_QUESTIONNAIRE]: SUBMITTER_ROLE_SLUG,
  [PROCESS_SUBMISSION_TYPE_TOP_DOWN]: TOP_DOWN_SUBMITTER_ROLE_SLUG,
  [PROCESS_SUBMISSION_TYPE_CITIZEN_DEVELOPER]: AUTOMATION_SUBMITTER_ROLE_SLUG,
  [PROCESS_SUBMISSION_TYPE_PROCESS_MINING]: PROCESS_MINING_SUBMITTER_ROLE_SLUG,
  [PROCESS_SUBMISSION_TYPE_TASK_MINING]: TASK_MINING_SUBMITTER_ROLE_SLUG,
  [PROCESS_SUBMISSION_TYPE_CHANGE_REQUEST]: CHANGE_REQUEST_SUBMITTER_ROLE_SLUG
};

export const permissionCheckBoxStatus = (user, role_slug_key) => {
  let returnObject = {
    checked: false,
    readOnly: false,
    disabled: false
  };

  if (user && user.user_is_deleted === 1) {
    returnObject.disabled = true;
  }

  if (
    permissionBucketExistsInUserRoles(
      CHECKBOX_BUCKET_SLUGS[role_slug_key],
      user.user_roles,
      ROLE_TYPE_PROCESS,
      [role_slug_key]
    )
  ) {
    returnObject.readOnly = true;
  }

  if (
    permissionBucketExistsInUserRoles(
      CHECKBOX_BUCKET_SLUGS[role_slug_key],
      user.user_roles,
      ROLE_TYPE_PROCESS,
      []
    )
  ) {
    returnObject.checked = true;
  }
  return returnObject;
};

export const permissionBucketExistsInUserRoles = (
  bucket_key,
  user_roles,
  role_type,
  role_slugs_to_exclude = []
) => {
  if (user_roles && bucket_key) {
    for (const role of user_roles) {
      if (
        role.process_id > 0 &&
        role.role_type === role_type &&
        !role_slugs_to_exclude.find(slug => {
          return slug === role.role_slug;
        })
      ) {
        if (role.permission_buckets) {
          if (
            role.permission_buckets.find(bkey => {
              return bkey === bucket_key;
            })
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const distinctAdminAssignableUserRolesById = user_roles => {
  let uniqueRoles = [];
  for (const role of user_roles) {
    if (
      !uniqueRoles.find(obj => {
        return obj.role_id === role.role_id;
      })
    ) {
      if (role.role_admin_console_assignable) {
        uniqueRoles.push(role);
      }
    }
  }
  return uniqueRoles;
};

export const userCanBeAssignedProcessRole = (
  user,
  role,
  currentProcessId = -1
) => {
  const userPermittedRoles =
    Array.isArray(user.rolesAssignable) && user.rolesAssignable.length > 0
      ? user.rolesAssignable
      : user.user_roles;

  if (userPermittedRoles && role) {
    if (
      userPermittedRoles.find(r => {
        //process_id === -1 means role can be assigned regardless
        //process_id === currentProcessId means role can be assigned to current automation
        const processIdMatch =
          r.process_id === -1 ||
          (currentProcessId && currentProcessId === r.process_id);

        return (
          role.role_is_visible &&
          role.role_is_active &&
          (role.role_admin_console_assignable === 0 ||
            (r.role_id === role.role_id && processIdMatch))
        );
      })
    ) {
      return true;
    }
  }
  return false;
};

export const roleStringForUser = (user, t) => {
  if (!user || !user.user_roles) {
    if (typeof t === "function") {
      return t("collaborator_cell_role_none");
    } else {
      return "None";
    }
  }

  let roleString = "";

  user.user_roles.forEach(function(role) {
    if (
      role.role_type === ROLE_TYPE_PROCESS &&
      role.process_id > 0 &&
      role.role_number_assignable != 0 &&
      role.role_is_active === 1 &&
      role.role_is_visible === 1 // added to remove checkboxes from showing here
    ) {
      if (roleString.length > 0) {
        roleString += ", ";
      }
      if (typeof t === "function") {
        roleString += getLocalizedRoleName(t, role, role.role_name);
      } else {
        roleString += role.role_name;
      }
    }
  });
  return roleString;
};

export const roleArrayForUser = (user, addLineBreak = false, t) => {
  if (!user || !user.user_roles) {
    if (typeof t === "function") {
      return [t("collaborator_cell_role_none")];
    } else {
      return ["None"];
    }
  }

  let roleArray = [];
  let filteredRoles = (user.user_roles || []).filter(role => {
    return (
      role.role_type === ROLE_TYPE_PROCESS &&
      role.process_id > 0 &&
      role.role_number_assignable != 0 &&
      role.role_is_active === 1 &&
      role.role_is_visible === 1 // added to remove checkboxes from showing here
    );
  });
  filteredRoles.forEach((role, index, arr) => {
    if (roleArray.length > 0 && addLineBreak) {
      roleArray.push(<br key={index} />);
    }

    let roleText = role.role_name;

    if (typeof t === "function") {
      roleText = getLocalizedRoleName(t, role, role.role_name);
    }

    let stringToPush = roleText;
    if (arr.length - 1 !== index) {
      // if not the last
      stringToPush += ", ";
    }
    roleArray.push(stringToPush);
  });
  return roleArray;
};

export const roleCheckBoxStatusForUser = (
  role,
  roles,
  user,
  collaborators,
  t
) => {
  let returnObject = { checked: false, isDisabled: false, tooltip: "" };

  if (user) {
    let roles = user.user_roles;
    if (roles) {
      roles.forEach(function(r) {
        if (r.role_slug == role.role_slug && r.process_id > 0) {
          //-1 processes are assignable
          returnObject.checked = true;
        }
      });
    }
  }

  if (role.role_number_assignable > 0) {
    let usersAssignedThisRole = [];
    for (const collaborator of collaborators) {
      if (
        collaborator.user_roles &&
        collaborator.user_roles.find(c_role => {
          return c_role.role_slug === role.role_slug && c_role.process_id > 0;
        })
      ) {
        if (collaborator.user_is_deleted != 1) {
          if (collaborator.user_id != user.user_id) {
            usersAssignedThisRole.push(collaborator);
          }
        }
      }
    }

    if (usersAssignedThisRole.length >= role.role_number_assignable) {
      returnObject.isDisabled = true;
      returnObject.tooltip = t([
        "add_collaborator_role_dropdown_user_tooltip",
        "This role has already been assigned, please un-assign the currently assigned user before selecting a new user"
      ]);
    }
  }

  return returnObject;
};

export const userRolesToCSVString = user_roles => {
  let kvArray = [];

  for (const role of user_roles) {
    kvArray.push([role.role_slug, role.category_id]);
  }
  return JSON.stringify(kvArray);
};

export const userRolesCSVStringToUserRoles = (user_roles_string, roles) => {
  const rolesParsedData = {
    parseError: false,
    isIgnored: false,
    parsedRoles: []
  };

  if (
    typeof user_roles_string !== "string" ||
    user_roles_string.trim() === ""
  ) {
    rolesParsedData.isIgnored = true;
  } else {
    try {
      let kvArray = JSON.parse(user_roles_string);
      if (kvArray && Array.isArray(kvArray)) {
        for (const kv of kvArray) {
          if (Array.isArray(kv) && kv.length > 1) {
            let role_slug = kv[0];
            let category_id = kv[1];
            let role = (roles || []).find(r => {
              return r.role_slug === role_slug;
            });

            if (role) {
              rolesParsedData.parsedRoles.push({
                category_id: category_id,
                role_id: role.role_id,
                role_admin_console_assignable:
                  role.role_admin_console_assignable,
                role_type: role.role_type,
                role_name: role.role_name,
                role_slug: role.role_slug
              });
            }
          } else {
            rolesParsedData.parseError = true;
            rolesParsedData.parsedRoles = [];
          }
        }
      } else {
        rolesParsedData.parseError = true;
        rolesParsedData.parsedRoles = [];
      }
    } catch (error) {
      console.log(`error parsing json ${user_roles_string}`);
      rolesParsedData.parseError = true;
      rolesParsedData.parsedRoles = [];
    }
  }

  return rolesParsedData;
};

export const categoriesArray = categories => {
  let addCategoryToArray = (categoryObject, arrayToAppendTo) => {
    arrayToAppendTo.push(categoryObject);
    for (const c of categoryObject.subcategories || []) {
      addCategoryToArray(c, arrayToAppendTo);
    }
  };
  let allCategoriesArray = [];
  for (const category of categories) {
    addCategoryToArray(category, allCategoriesArray);
  }

  return allCategoriesArray;
};

export const categoriesById = categories => {
  let categoriesByIDDictionary = {};

  let allCategoriesArray = categoriesArray(categories);

  for (const cat of allCategoriesArray) {
    categoriesByIDDictionary[cat.category_id] = cat;
  }

  return categoriesByIDDictionary;
};

export const rolesBySlug = roles => {
  let rolesBySlugDict = {};
  for (const role of roles) {
    rolesBySlugDict[role.role_slug] = role;
  }
  return rolesBySlugDict;
};

// renaming this to something more clear - updateRolesAssignableForUser
export const updateRolesAssignableForUser = (user, currProcessId) => {
  if (!user) {
    return null;
  }

  const updatedUser = { ...user, rolesAssignable: [] };
  const currentUserRoles = user.user_roles;

  if (Array.isArray(currentUserRoles) && currentUserRoles.length > 0) {
    currentUserRoles.forEach(role => {
      if (
        role.process_id === -1 ||
        (currProcessId && currProcessId === role.process_id)
      ) {
        updatedUser.rolesAssignable.push(role);
      }
    });
  }

  return updatedUser;
};

export const filterCollaboratorUserRolesForProcess = (
  user_roles,
  process_id
) => {
  // if there are no user roles or there is no valid process role (excluding checkbox rights roles)
  return (
    user_roles?.filter(
      role =>
        role &&
        role.role_type === ROLE_TYPE_PROCESS &&
        role.process_id === process_id &&
        !Object.keys(CHECKBOX_BUCKET_SLUGS).includes(role.role_slug)
    ) || []
  );
};

export const areUserRolesValidForProcess = (user_roles, process_id) => {
  if (
    filterCollaboratorUserRolesForProcess(user_roles, process_id).length === 0
  ) {
    return false;
  } else {
    return true;
  }
};
