import {
  COComponentType,
  COConditionSlugs,
  CORichTextOptions
} from "../constants/co-constants";
import { StandardProcessAnswerSlugs } from "../constants/co-question.constants";
import { COProcessAnswerTemplateInterface } from "../interfaces/co-interfaces";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";
import { processAnswerValidators } from "./elements/validators/co-validators.template";

export const processAnswerTemplateForSlug = template_slug => {
  return processAnswerTemplates().find(
    element => element.co_process_answer_template_slug === template_slug
  );
};
export const processAnswerTemplates = (): COProcessAnswerTemplateInterface[] => {
  let answerOptions: COProcessAnswerTemplateInterface[] = [
    defaultKPIProcessAnswerTemplate(),
    defaultQuestionProcessAnswerTemplate()
  ];
  return answerOptions;
};

export const defaultKPIProcessAnswerTemplate = (): COProcessAnswerTemplateInterface => {
  let template = baseTemplate();
  // disable things like comments for the KPI
  template.co_process_answer_template_slug = StandardProcessAnswerSlugs.KPI;
  delete template.co_process_answer_json?.co_process_answer_meta_json?.comment;
  if (template.co_process_answer_json) {
    template.co_process_answer_json.co_process_answer_template_slug =
      StandardProcessAnswerSlugs.KPI;
  }
  return template;
};
export const defaultQuestionProcessAnswerTemplate = (): COProcessAnswerTemplateInterface => {
  let template = baseTemplate();
  template.co_process_answer_template_slug =
    StandardProcessAnswerSlugs.QUESTION;
  if (template.co_process_answer_json) {
    template.co_process_answer_json.co_process_answer_template_slug =
      StandardProcessAnswerSlugs.QUESTION;
  }
  return template;
};

const baseTemplate = (): COProcessAnswerTemplateInterface => {
  return {
    co_validators_json: [...processAnswerValidators()],
    co_meta_json: {
      comment: {
        is_editable: true,
        label: "",
        position: { sort_order: 1 },
        options: {
          component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT,
          is_hidden: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.HIDE_COMMENTS_SECTION
          }),
          condition_overrides: [
            {
              slug: COConditionSlugs.HIDE_IF_IN_TABULAR_SECTION
            }
          ],
          max_answer_length: 500,
          rich_text_controls: CORichTextOptions.none,
          accessibility_options: {
            aria_labelled_by: "" // set to blank so one doesn't get set to the comment field and it uses the default aria-label that would be set to the field
          }
        },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.PROCESS_ANSWER.CO_PROCESS_ANSWER_META_JSON
              .COMMENT.VALUE
        }),
        tooltip: "",
        placeholder: "Enter Comment",
        placeholder_localization_key: "co_process_placeholder_enter_comment",
        display_context_overrides: {
          assessment_view: {
            is_editable: false,
            options: {
              component_type: COComponentType.META_PARAGRAPH_TEXT
            }
          }
        }
      }
    },
    co_process_answer_json: {
      co_process_answer_meta_json: {
        comment: {
          value: ""
        }
      }
    }
  };
};
