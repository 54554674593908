import { COConditionSlugs } from "../../constants/co-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";

export const customEXQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.EX_ORCHESTRATOR_PACKAGE_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_ORCHESTRATOR_PACKAGE_INSIGHTS,
        standard_question_template_slug:
          StandardQuestionSlugs.ORCHESTRATOR_PACKAGE_MAPPING,
        title: "Orchestrator package",
        description:
          "Select the Orchestrator package. In case several package cover the entire scope of the idea, use the add row button for adding a second package. This information with be used in UiPath Insights, for ROI reporting.",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_ORCHESTRATOR_PACKAGE_INSIGHTS
          ),
          co_question_position_json: {
            sort_order: 0
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [],
          prevent_deleting: 1
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_USING_QUEUES_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_USING_QUEUES_INSIGHTS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Using queues",
        description:
          "If the process using the package is run through queues, the actual benefit formula in Insights adapts to take queue item counts into account",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_USING_QUEUES_INSIGHTS
          ),
          co_question_position_json: {
            sort_order: 100
          },
          co_question_meta_json: {
            prefill: {
              value: "ah-answer_option-ex-0-2-1-1"
            }
          }
        },
        options_meta_overrides: {
          is_read_only: 1,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS }
          ],
          prevent_deleting: 1
        },
        condition_overrides_to_remove: [],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Yes",
            value: "1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "No",
            value: "0"
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.EX_FINAL_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_FINAL_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Final manual time saved per run",
        description:
          "Allocate 100% or less of the reference manual time saved per run (min).",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_FINAL_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS
          ),
          co_question_position_json: {
            sort_order: 400
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [],
          prevent_deleting: 1
        }
      })
    }
  ];
};
