import { Radio } from "@material-ui/core";
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { BASE_URL } from "../../constants/envConstants";

const MigrateOptionWrapper = styled.div(
  props => css`
    padding: 0 30px 10px 50px;
    font-size: 14px;
    word-break: break-word;
    hyphens: auto;
    color: var(--color-foreground, ${props.theme.colors.ink700});
  `
);

const MigrationOptionsWrapper = styled.div`
  margin-bottom: 100px;
  overflow: scroll;
`;

const SelectTenantTitle = styled.div(
  props => css`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-foreground, ${props.theme.colors.ink700});
    display: flex;
    padding: 10px 20px;
  `
);

const SingleRadioButton = styled.div`
  padding: 10px 20px 0px 20px;
  display: flex;
  align-items: center;
`;

const MigrationOption = styled.div(
  props => css`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: var(--color-foreground, ${props.theme.colors.ink700});
    display: flex;
    padding-left: 10px;
  `
);

const ListItem = styled.li(
  props => css`
    list-style-type: circle;
    margin-left: 22px;
    margin-top: 10px;

    a {
      text-decoration: underline;
      color: var(--color-primary, ${props.theme.colors.blue500});
    }
  `
);

const FpsProvisionTenantSelectorMigrationConfirmBox = ({
  selectedTenantSlug,
  migrationOption,
  onMigrationOptionChange
}) => {
  const { t } = useTranslation("common");
  const ahUrl = BASE_URL + "/" + selectedTenantSlug;
  let cloudUrl = window.location.href.substring(
    0,
    window.location.href.indexOf("/automationhub_")
  );
  cloudUrl += "/automationhub_";
  const cloudHost = window.location.host;
  return (
    <>
      <MigrationOptionsWrapper>
        <SelectTenantTitle>
          {t(["fps_migration_option_title", "Select migration options"])}
        </SelectTenantTitle>
        <div>
          <SingleRadioButton>
            <Radio
              name="migration_option"
              value={1}
              checked={parseInt(migrationOption) === 1}
              onChange={onMigrationOptionChange}
            />
            <MigrationOption>
              {t(["fps_migration_option_full", "Full migration"])}
            </MigrationOption>
          </SingleRadioButton>
          <MigrateOptionWrapper>
            <ul>
              <ListItem>
                {t([
                  "fps_migration_option_full_point_1",
                  "All users from your existing Automation Hub instance will be imported to Automation Cloud and will receive email invitations to join it."
                ])}
              </ListItem>
              <ListItem>
                {/* prettier-ignore */}
                <Trans t={t} i18nKey="fps_migration_option_full_point_2">
            The URLs for Automation Hub will change from
            <a href={ahUrl} target="_blank" rel="noopener noreferrer">
              {{ ahUrl }}
            </a>
            to
            <a
              href={cloudUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ cloudUrl }}
            </a>
          </Trans>
              </ListItem>
              <ListItem>
                {t([
                  "fps_migration_option_full_point_3",
                  "All the users will be automatically redirected to the new URLs."
                ])}
              </ListItem>
            </ul>
          </MigrateOptionWrapper>
        </div>

        <div>
          <SingleRadioButton>
            <Radio
              name="migration_option"
              value={0}
              checked={parseInt(migrationOption) === 0}
              onChange={onMigrationOptionChange}
            />
            <MigrationOption>
              {t(["fps_migration_option_partial", "Partial migration"])}
            </MigrationOption>
          </SingleRadioButton>

          <MigrateOptionWrapper>
            <ul>
              <ListItem>
                {/* prettier-ignore */}
                <Trans t={t} i18nKey="fps_migration_option_partial_point_1">
            To validate and try out Automation Hub via Automation Cloud
            you (email) will have access to this Automation Hub tenant
            via the
            <a
              href={cloudHost}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ cloudHost }}
            </a>
            url
          </Trans>
              </ListItem>
              <ListItem>
                {/* prettier-ignore */}
                <Trans t={t} i18nKey="fps_migration_option_partial_point_2">
          All existing Automation Hub users will be unaffected and can
          access the platform through
          <a href={ahUrl} target="_blank" rel="noopener noreferrer">
            {{ahUrl}}
          </a>
          </Trans>
              </ListItem>
              <ListItem>
                <Trans t={t} i18nKey="fps_migration_option_partial_point_3">
                  All system emails and links will follow the
                  <a href={BASE_URL} target="_blank" rel="noopener noreferrer">
                    {{ BASE_URL }}
                  </a>
                  url format. You will need to complete the migration process
                  later in the tenant setting menu.
                </Trans>
              </ListItem>
            </ul>
          </MigrateOptionWrapper>
        </div>
      </MigrationOptionsWrapper>
    </>
  );
};
export default FpsProvisionTenantSelectorMigrationConfirmBox;
