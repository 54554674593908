import axios from "./axiosInstance";
import { getKeyValue } from "./objectParsers";

export const fetchUsers = async ({ callback, ...props }) => {
  try {
    const usersData = await fetchUsersData(props);
    const users = usersData ? usersData.users : null;

    if (typeof callback === "function") {
      callback(users);
    }

    return users;
  } catch (error) {
    throw error;
  }
};

export const searchUsers = async ({
  callback,
  searchTerm = "",
  isActive,
  categoryId,
  roleSlug,
  userRoleProcessId,
  limit = 20
}) => {
  let searchQuery = `?limit=${limit}`;

  if (searchTerm) {
    searchQuery += `&s=${searchTerm}`;
  }

  if (isActive === false || isActive === true) {
    searchQuery += `&active=${isActive}`;
  }

  if (categoryId !== undefined) {
    searchQuery += `&category_id=${categoryId}`;
  }

  if (roleSlug) {
    searchQuery += `&role_slug=${roleSlug}`;
  }

  if (userRoleProcessId) {
    searchQuery += `&user_role_process_id=${userRoleProcessId}`;
  }

  const response = await axios.get(`/api/v1/search/users${searchQuery}`);
  const users = response.data.data.users;

  if (typeof callback === "function") {
    callback(users);
  }

  return users;
};

export const fetchProcessRoles = async ({ page = 1, limit = 10000 }) => {
  const response = await axios.get(
    `/api/v1/roles/processes?page=${page}&limit=${limit}`
  );

  return response?.data?.data;
};

export const fetchUsersData = async ({
  searchTerm,
  page = 1,
  limit = 30,
  isActive,
  inviteStatus,
  isAdmin = false,
  callback,
  cancelToken,
  licensedUsers,
  categoryId,
  roleSlug,
  userRoleProcessId
}) => {
  let url = isAdmin
    ? `/api/v1/admin/users?&page=${page}&limit=${limit}`
    : `/api/v1/users?&page=${page}&limit=${limit}`;

  if (searchTerm && typeof searchTerm === "string") {
    url += `&s=${searchTerm}`;
  }

  if (
    inviteStatus &&
    (typeof inviteStatus === "string" || inviteStatus instanceof Array)
  ) {
    url +=
      typeof inviteStatus === "string"
        ? `&invite=${inviteStatus}`
        : `&invite=${inviteStatus.join(",")}`;
  }

  if (isActive === false || isActive === true) {
    url += `&active=${isActive}`;
  }

  if (licensedUsers) {
    url += "&licensed=true";
  }

  if (categoryId !== undefined) {
    url += `&category_id=${categoryId}`;
  }

  if (roleSlug) {
    url += `&role_slug=${roleSlug}`;
  }

  if (userRoleProcessId) {
    url += `&user_role_process_id=${userRoleProcessId}`;
  }

  try {
    const response = await axios.get(url, { cancelToken });
    const usersData = getKeyValue(response, ["data", "data"]);

    if (typeof callback === "function") {
      callback(usersData);
    }

    return usersData;
  } catch (error) {
    throw error;
  }
};

export const fetchApplicationsByIds = async ({ l1Ids, limit }) => {
  if (!(l1Ids instanceof Array) && l1Ids.length < 1) {
    return [];
  }

  const limitParam = limit !== undefined ? `?limit=${limit}` : "";

  let applications = [];

  try {
    for (let i = 0; i < l1Ids.length; i++) {
      const l1Id = l1Ids[i];
      const response = await axios.get(
        `/api/v1/processes/taxonomy/${l1Id}/applications${limitParam}`
      );
      const newApplications =
        getKeyValue(response, ["data", "data", "applications"]) || [];
      applications = applications.concat(newApplications);
    }
  } catch (e) {
    throw e;
  }

  return applications;
};

export const fetchAutomationColumnsData = async ({
  userSlug,
  cancelToken,
  columnPage = "pipeline-manager"
}) => {
  let columnsData = [];

  try {
    const response = await axios.get(
      `api/v1/users/slug/${userSlug}/${columnPage}/columns`,
      { cancelToken }
    );
    columnsData = getKeyValue(response, "data.data") || [];
  } catch (error) {
    throw error;
  }

  return columnsData;
};

//no typescript so args should be:
// {
//   queryParamsString?: string,
//   type: "ideas" | "automations"
// }
export const fetchAllAutomations = async ({
  queryParamsString = "",
  type = "ideas"
}) => {
  const resp = await axios.get(`api/v1/${type}${queryParamsString}`);

  return resp.data.data;
};

export const fetchAutomationsData = async ({
  userSlug,
  cancelToken,
  pageString = "pipeline-manager",
  queryParamsString = ""
}) => {
  let automationsData = [];

  const apiUrl =
    pageString === "pipeline-manager"
      ? `api/v1/users/slug/${userSlug}/${pageString}/data${queryParamsString}`
      : `api/v1/users/slug/${userSlug}/${pageString}${queryParamsString}`;

  try {
    const response = await axios.get(apiUrl, { cancelToken });
    automationsData = getKeyValue(response, "data.data") || [];
  } catch (error) {
    throw error;
  }

  return automationsData;
};

export const fetchAutomationCollaborators = async ({ processSlug }) => {
  const response = await axios.get(
    `api/v1/processes/slug/${processSlug}/collaborators`
  );

  return response?.data?.data;
};
