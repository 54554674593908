import { COEmailActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COEmailActionSlugs,
  COEmailActivityType,
  COEmailType
} from "../../../../../constants/actions/co-email-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";

export const contentConsumerNotificationEmailActionTemplate = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  // get template based on slug
  let template = contentConsumerNotificationEmailActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      email_type: COEmailType.CONTENT_CONSUMER_NOTIFICATION,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const contentConsumerNotificationEmailActionTemplateFromSlug = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  switch (slug) {
    case COEmailActionSlugs.COMPONENT_UPDATED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_DOWNLOADED_USER]
          },
          legacy_email_id: 28,
          email_activity_type:
            COEmailActivityType.COMPONENT_UPDATED_FOR_DOWNLOADED_USERS,
          email_category_type: COCategoryType.COMPONENT,
          platform_event_type:
            COPlatformEventType.COMPONENT_UPDATED_FOR_DOWNLOADED_USERS,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component updated"
          },
          email_body: {
            value: "component_updated"
          }
        }
      };
    }
    case COEmailActionSlugs.COMPONENT_REJECTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_email_id: 29,
          email_activity_type: COEmailActivityType.COMPONENT_CURATION_REJECTED,
          email_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_CURATION_REJECT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component rejected"
          },
          email_body: {
            value: "component_rejected"
          }
        }
      };
    }
    case COEmailActionSlugs.COMPONENT_BUG_REPORTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_email_id: 30,
          email_activity_type: COEmailActivityType.COMPONENT_BUG_REPORTED,
          email_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_BUG_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component bug reported"
          },
          email_body: {
            value: "component_bug_reported"
          }
        }
      };
    }
    case COEmailActionSlugs.COMPONENT_ABUSE_REPORTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_email_id: 31,
          email_activity_type: COEmailActivityType.COMPONENT_ABUSE_REPORTED,
          email_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_ABUSE_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component abuse reported"
          },
          email_body: {
            value: "component_abuse_reported"
          }
        }
      };
    }
  }
  return undefined;
};
