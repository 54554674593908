import React, { Component } from "react";
import "./../inputWithLabelStyles.scss";
import "./InputCustomChildWithLabel.scss";

class InputCustomChildWithLabel extends Component {
  constructor(props) {
    super(props);
    this.errorRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && !prevProps.error && this.errorRef.current) {
      this.errorRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }
  }

  render() {
    const { label, required, error, children } = this.props;

    return (
      <label className="inputWithLabelStyles InputCustomChildWithLabel">
        <p className="inputWithLabelStyles__label">
          {label}
          {required && (
            <span className="inputWithLabelStyles__label-required">*</span>
          )}
        </p>
        <div className="inputWithLabelStyles__input-wrapper">{children}</div>
        {error && (
          <p className="inputWithLabelStyles__error" ref={this.errorRef}>
            {error}
          </p>
        )}
      </label>
    );
  }
}

export default InputCustomChildWithLabel;
