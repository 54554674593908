import { customQuestionWithInfo } from "../co-custom-questions.template";
import { COConditionSlugs } from "../../constants/co-constants";
import { COPhaseStatusKeys } from "../../constants/co-phase-status.constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";

export const customMiscQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.MISC_EXPECTED_BENEFITS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_EXPECTED_BENEFITS,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Expected benefits",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_EXPECTED_BENEFITS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.MISC_ACTUAL_BENEFITS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_ACTUAL_BENEFITS,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Actual benefits",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_ACTUAL_BENEFITS
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.MISC_EXPECTED_CHALLENGES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_EXPECTED_CHALLENGES,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Expected challenges",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_EXPECTED_CHALLENGES
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.MISC_ACTUAL_CHALLENGES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_ACTUAL_CHALLENGES,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Encountered challenges",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_ACTUAL_CHALLENGES
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.MISC_LESSONS_LEARNED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_LESSONS_LEARNED,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Lessons learned",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_LESSONS_LEARNED
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.MISC_MEDIA,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_MEDIA,
        standard_question_template_slug: StandardQuestionSlugs.MEDIA,
        title: "Media",
        description: "",
        note:
          "NOTE: This question will not be shown in the automation pipeline.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_MEDIA
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.MISC_SIMILAR_AUTOMATIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.MISC_SIMILAR_AUTOMATIONS,
        standard_question_template_slug: StandardQuestionSlugs.AUTOMATIONS,
        title: "Similar automations",
        description: "",
        note:
          "NOTE: This question will not be shown in the automation pipeline.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.MISC_SIMILAR_AUTOMATIONS
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          prevent_deleting: 0,
          is_read_only: 0,
          condition_overrides: [{ slug: COConditionSlugs.HIDE_FROM_PIPELINE }]
        }
      })
    }
  ];
};
