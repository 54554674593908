import React from "react";
import OriginalSwal from "sweetalert2";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import "./SweetAlert.scss";
import logo from "../../assets/ic_warning.svg";
import { LocalizeTranslationKey } from "../../utils/localizedAlertMessages";
import { NESTED_THEME_CONTAINER_ID } from "../StyleThemeProvider/StyleThemeProvider";

const ConfirmButtonText = () => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return (
    <span data-testid="GeneralAlertPopup__ConfirmButton">
      {translate(["confirm_popup_button_text", "OK"])}
    </span>
  );
};

const CancelButtonText = () => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return (
    <span data-testid="GeneralAlertPopup__CancelButton">
      {translate(["cancel_popup_button_text", "Cancel"])}
    </span>
  );
};

const Swal = withReactContent(OriginalSwal).mixin({
  customClass: "CustomSwal",
  reverseButtons: true,
  showCloseButton: true,
  animation: false,
  target: `#${NESTED_THEME_CONTAINER_ID}`,
  confirmButtonText: <ConfirmButtonText />,
  cancelButtonText: <CancelButtonText />,
  closeButtonHtml: (
    <span data-testid="GeneralAlertPopup__CloseButton">&times;</span>
  )
});

export const CustomToast = withReactContent(OriginalSwal).mixin({
  customClass: "CustomSwalToast",
  imageUrl: logo,
  target: `#${NESTED_THEME_CONTAINER_ID}`,
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000
});

export const swalClose = () => {
  try {
    Swal.close();
  } catch (e) {
    console.log("Error closing swal", e);
  }
};

export const SwalForRegistering = messageObj => {
  let message = messageObj.message;

  if (!!messageObj.localizationKey) {
    message = (
      <LocalizeTranslationKey
        localeKey={messageObj.localizationKey}
        backupValue={message}
      />
    );
  }
  return withReactContent(OriginalSwal).mixin({
    customClass: "CustomSwal",
    reverseButtons: true,
    showCancelButton: true,
    animation: false,
    target: `#${NESTED_THEME_CONTAINER_ID}`,
    html: message,
    title: (
      <LocalizeTranslationKey
        localeKey={"auth_thank_you_for_registering_title"}
        backupValue={"Thank You For Registering!"}
      />
    ),
    cancelButtonText: (
      <LocalizeTranslationKey
        localeKey={"auth_thank_you_for_registering_cancel"}
        backupValue={"OK"}
      />
    ),
    confirmButtonText: (
      <LocalizeTranslationKey
        localeKey={"auth_thank_you_for_registering_confirm"}
        backupValue={"Resend Email"}
      />
    )
  });
};

export const SwalForRegisteringError = messageObj => {
  let message = messageObj.message;
  if (!!messageObj.localizationKey) {
    message = (
      <LocalizeTranslationKey
        localeKey={messageObj.localizationKey}
        backupValue={message}
      />
    );
  }
  return withReactContent(OriginalSwal).mixin({
    customClass: "CustomSwal",
    reverseButtons: true,
    showCloseButton: true,
    target: `#${NESTED_THEME_CONTAINER_ID}`,
    animation: false,
    html: message,
    title: (
      <LocalizeTranslationKey
        localeKey={"auth_authentication_alert_error"}
        backupValue={"Error"}
      />
    )
  });
};

export default Swal;
