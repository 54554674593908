import {
  COComponentType,
  COContextObjectKey,
  CODevelopmentTypeOptions,
  COProcessAutomationType,
  COProcessAutomationTypeInfo,
  COProcessSubmissionTypeInfo,
  COValidationType
} from "../../../constants/co-constants";
import {
  dynamicPath,
  PATH,
  PATH_ROUTE
} from "../../../constants/co-path.constants";
import {
  COContextInterface,
  COControlInterface,
  COControlInterfaceKeys,
  COUnitInterface,
  COUnits
} from "../../../interfaces/co-interfaces";

export enum COUnitSlugs {
  NONE = "none",
  DAYS_YEAR = "days_year",
  HOURS_YEAR = "hours_year",
  HOURS_MONTH = "hours_month",
  HOURS_DAY = "hours_day",
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  YEARS = "years",
  CURRENCY = "currency",
  PERCENT = "percent",
  EASE_OF_IMPLEMENTATION_LEVEL = "ease_of_implementation_level",
  SUBMISSION_TYPE = "submission_type",
  DEVELOPMENT_TYPE = "development_type",
  YESNO = "yesno",
  AUTOMATION_TYPE = "automation_type"
}

export const unitTemplates = (): COUnits => {
  return {
    none: {
      slug: COUnitSlugs.NONE,
      meta: {
        value: "No unit",
        value_localization_key: "co_units_no_units"
      },
      display_value_format: {}
    },
    submission_type: {
      is_custom: 1,
      slug: COUnitSlugs.SUBMISSION_TYPE,
      meta: {
        value: "Idea Source",
        value_localization_key: "co_units_submission_type"
      },
      display_value_format: {
        enum_options: Object.keys(COProcessSubmissionTypeInfo).map(key => {
          let obj = COProcessSubmissionTypeInfo[key];
          return {
            KEY: obj.title || "",
            VALUE: parseInt(key + "")
          };
        })
      }
    },
    development_type: {
      is_custom: 1,
      slug: COUnitSlugs.DEVELOPMENT_TYPE,
      meta: {
        value: "Development Type",
        value_localization_key: "co_units_development_type"
      },
      display_value_format: {
        enum_options: CODevelopmentTypeOptions.map(option => {
          return {
            KEY: option.development_type_name || "",
            VALUE: option.development_type_slug || ""
          };
        })
      }
    },
    automation_type: {
      is_custom: 1,
      slug: COUnitSlugs.AUTOMATION_TYPE,
      meta: {
        value: "Automation Type",
        value_localization_key: "co_units_automation_type"
      },
      display_value_format: {
        enum_options: Object.keys(COProcessAutomationTypeInfo).map(key => {
          let obj = COProcessAutomationTypeInfo[key];
          if (obj.key === "") {
            // empty state - need for filter
            return {
              KEY: obj.title || "",
              VALUE: ""
            };
          }
          return {
            KEY: obj.title || "",
            VALUE: obj.title || ""
          };
        })
      }
    },
    yesno: {
      is_custom: 1,
      slug: COUnitSlugs.YESNO,
      meta: {
        value: "Yes/No",
        value_localization_key: "co_units_yes_no"
      },
      display_value_format: {
        enum_options: [
          { KEY: "Yes", VALUE: "1" },
          { KEY: "No", VALUE: "0" }
        ]
      }
    },
    ease_of_implementation_level: {
      is_custom: 1,
      slug: COUnitSlugs.EASE_OF_IMPLEMENTATION_LEVEL,
      meta: {
        value: "Ease of Implementation Level",
        value_localization_key: "co_units_ease_of_implementation"
      },
      display_value_format: {
        enum_options: [
          {
            KEY: "Easy",
            VALUE: 2
          },
          {
            KEY: "Medium",
            VALUE: 1
          },
          {
            KEY: "Difficult",
            VALUE: 0
          }
        ]
      }
    },
    days_year: {
      slug: COUnitSlugs.DAYS_YEAR,
      meta: {
        value: "Days / Year",
        value_localization_key: "co_units_day_per_year"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Days Per Year"
      },
      display_value_format: {
        suffix: "Days Per Year"
      },
      validations: [
        {
          target: PATH({
            route:
              PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
                .CO_PROCESS_ANSWER_SELECTION_INPUT
          }),
          validationType: COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE,
          validation_context: {
            assessment_submission_transform_view: 1
          },
          value: 365
        },
        {
          target: PATH({
            route:
              PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
                .CO_PROCESS_ANSWER_SELECTION_INPUT
          }),
          validationType: COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE,
          validation_context: {
            assessment_submission_transform_view: 1
          },
          value: 0
        }
      ]
    },
    hours_year: {
      slug: COUnitSlugs.HOURS_YEAR,
      meta: {
        value: "Hours / Year",
        value_localization_key: "co_units_hours_per_year"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Hours Per Year"
      },
      display_value_format: {
        suffix: "Hours Per Year"
      }
    },
    hours_month: {
      slug: COUnitSlugs.HOURS_MONTH,
      meta: {
        value: "Hours / Month",
        value_localization_key: "co_units_hours_per_month"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Hours Per Month"
      },
      display_value_format: {
        suffix: "Hours Per Month"
      }
    },
    hours_day: {
      slug: COUnitSlugs.HOURS_DAY,
      meta: {
        value: "Hours / Day",
        value_localization_key: "co_units_hours_per_day"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Hours Per Day"
      },
      display_value_format: {
        suffix: "Hours Per Day"
      },
      validations: [
        {
          target: PATH({
            route:
              PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
                .CO_PROCESS_ANSWER_SELECTION_INPUT
          }),
          validationType: COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE,
          validation_context: {
            assessment_submission_transform_view: 1
          },
          value: 24
        },
        {
          target: PATH({
            route:
              PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
                .CO_PROCESS_ANSWER_SELECTION_INPUT
          }),
          validationType: COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE,
          validation_context: {
            assessment_submission_transform_view: 1
          },
          value: 0
        }
      ]
    },
    minutes: {
      slug: COUnitSlugs.MINUTES,
      meta: {
        value: "Minutes",
        value_localization_key: "co_units_minutes"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Minutes"
      },
      display_value_format: {
        suffix: "Minutes"
      },
      validations: []
    },
    hours: {
      slug: COUnitSlugs.HOURS,
      meta: {
        value: "Hours",
        value_localization_key: "co_units_hours"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Hours"
      },
      display_value_format: {
        suffix: "Hours"
      },
      validations: []
    },

    days: {
      slug: COUnitSlugs.DAYS,
      meta: {
        value: "Days",
        value_localization_key: "co_units_days"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Days"
      },
      display_value_format: {
        suffix: "Days"
      },
      validations: []
    },
    years: {
      slug: COUnitSlugs.YEARS,
      meta: {
        value: "Years",
        value_localization_key: "co_units_years"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "Years"
      },
      display_value_format: {
        suffix: "Years"
      },
      validations: []
    },
    currency: {
      slug: COUnitSlugs.CURRENCY,
      meta: {
        value: "Currency",
        value_localization_key: "co_units_currency"
        // value: PATH({
        //   route: PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.CURRENCY.CURRENCY_NAME
        // })
      },
      title_format: {
        // suffix: PATH({
        //   route:
        //     PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.CURRENCY
        //       .CURRENCY_ABBREVIATION
        // })
      },
      input_value_format: {
        prefix: PATH({
          route:
            PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.CURRENCY.CURRENCY_SYMBOL
        })
      },
      display_value_format: {
        prefix: PATH({
          route:
            PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.CURRENCY.CURRENCY_SYMBOL
        }),
        currency: PATH({
          route: PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.CURRENCY_OBJECT
        })
      }
    },
    percent: {
      slug: COUnitSlugs.PERCENT,
      meta: {
        value_localization_key: "co_units_percent",
        value: "Percentage"
      },
      title_format: {
        suffix: ""
      },
      input_value_format: {
        suffix: "%"
      },
      display_value_format: {
        suffix: "%"
      }
    }
  };
};

export const unitOptions = () => {
  let optionsArray: COUnitInterface[] = [];
  let unitTemplate = unitTemplates();
  for (const unitKey of Object.keys(unitTemplate)) {
    optionsArray.push(unitTemplate[unitKey]);
  }
  return optionsArray;
};

export const unitControl = ({
  unitOptions,
  is_disabled = undefined
}: {
  unitOptions: COUnitInterface[];
  is_disabled?: number | string;
}): COControlInterface => {
  if (unitOptions.length == 0) {
    return {};
  }
  return {
    key: COControlInterfaceKeys.CONTROL_UNITS,
    target: PATH({
      route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.UNIT_OBJECT
    }),
    controlFunction: ({
      context,
      control,
      option
    }: {
      context: COContextInterface;
      control: COControlInterface;
      option: any;
    }) => {
      // we're going to handle this manually because we don't want to the resolved item (the unit) we want to set the path to the unit in the template
      let pathToUnitTemplate = dynamicPath(
        COContextObjectKey.TEMPLATES,
        `units.${option?.value?.slug}`
      );
      // we can't use set value or it will resolve the unit to the unit in the template
      // we have to just handle this explicity
      // TODO some better way of being able to set references without them being resolved
      if (
        context?.question?.co_question_options_json?.unit &&
        pathToUnitTemplate
      ) {
        context.question.co_question_options_json.unit = pathToUnitTemplate;
      }
    },
    meta: {
      title: {
        value: "Unit",
        value_localization_key: "co_units_units_label"
        //tooltip: "This sets the unit"
      }
    },
    options: {
      component_type: COComponentType.CONTROL_PICKER,
      is_disabled: is_disabled,
      optional_for_submission: 0
    },
    position: {
      sort_order: -899
    },
    items: unitOptions.map(unit => ({
      key: unit.slug,
      value: dynamicPath(COContextObjectKey.TEMPLATES, `units.${unit.slug}`),
      meta: {
        value: unit.meta?.value,
        value_localization_key: unit.meta?.value_localization_key
      }
    }))
  };
};
