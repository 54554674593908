import { COTaskActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COTaskActionSlugs,
  COTaskType,
  COTaskActivityType,
  COTaskPopUpMessageSource
} from "../../../../../constants/actions/co-task-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import {
  AUTOMATION_PROFILE_URL,
  AUTOMATION_PROFILE_COLLABORATORS_URL
} from "../../../../../constants/actions/co-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";
import { COEmailReminderActionSlugs } from "../../../../../constants/actions/co-email-reminder-action-constants";

export const generalTasksTaskActionTemplate = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  // get template based on slug
  let template = generalTasksTaskActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each task
    template.co_options_json = {
      task_type: COTaskType.GENERAL_TASK,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const generalTasksTaskActionTemplateFromSlug = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  switch (slug) {
    case COTaskActionSlugs.ASSIGN_PROJECT_MANAGER_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_task_id: 6,
          task_activity_type_id: 6,
          task_activity_type: COTaskActivityType.ASSIGN_PROJECT_MANAGER,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_APPROVED
          ],
          task_auto_completion_criteria: {
            process_collaborator_role_added: [CORoleType.PROJECT_MANAGER]
          },
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.ASSIGN_PROJECT_MANAGER_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assign Project Manager"
          },
          message: {
            value:
              "Assign a Project Manager for an automation approved for implementation"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_PROJECT_MANAGER_ROLE_ASSIGNED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_task_id: 7,
          task_activity_type_id: 7,
          task_activity_type:
            COTaskActivityType.AP_PROJECT_MANAGER_ROLE_ASSIGNED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_ASSIGNED,
          task_popup_message_source: COTaskPopUpMessageSource.ROLE_COMMENT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Project Manager role assigned"
          },
          message: {
            value:
              "As a Project manager in an automation, bring other collaborators on board"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_ABUSE_REPORTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_task_id: 12,
          task_activity_type_id: 12,
          task_activity_type: COTaskActivityType.AP_ABUSE_REPORTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_ABUSE_REPORTED,
          task_popup_message_source: COTaskPopUpMessageSource.PROCESS_REPORT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Abuse reported"
          },
          message: {
            value: "Review an abuse"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_COMMENT_ABUSE_REPORTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_task_id: 20,
          task_activity_type_id: 17,
          task_activity_type: COTaskActivityType.AP_COMMENT_ABUSE_REPORTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_ABUSE_COMMENT_REPORTED,
          task_popup_message_source:
            COTaskPopUpMessageSource.PROCESS_REPORTED_COMMENT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Process comment abuse reported"
          },
          message: {
            value: "Review an abuse in a Automation comment"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
