import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";
import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import {
  COAssessmentTypes,
  COConditionSlugs,
  COProfileBannerSlugs
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { defaultAssessmentTemplate } from "../co-assessment.template";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import {
  COSectionTemplateSlugs,
  CONonStandardSectionTemplateSlugs
} from "../../constants/co-section.constants";

export const defaultCRAssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.CR_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.CR_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default Change Request Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
    assessmentTemplate.co_options_json = {
      ...assessmentTemplate.co_options_json,
      condition_overrides: [
        { slug: COConditionSlugs.CR_VISIBILITY },
        // This assessment is not optional during submission, but is optional with a warning when saving
        {
          slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE
        },
        { slug: COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING },
        ...templateAssessmentConditions()
      ],
      co_profile_banners: [
        { slug: COProfileBannerSlugs.UNANSWERED_REQUIRED_CR_QUESTIONS }
      ]
    };
  }
  return assessmentTemplate;
};

export const AHDefaultCRAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateCR() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-cr-0-0`;
  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHFreshCRAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateCR(false) });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "",
      sectionTemplateSlug: COSectionTemplateSlugs.CR_DEFAULT_SECTION_V1
    }),
    question_slugs: []
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Details",
    sectionTemplateSlug:
      CONonStandardSectionTemplateSlugs.CR_SECTION_VISIBILITY_LOCKED_V1,
    condition_overrides: [{ slug: COConditionSlugs.SIDEBAR }],
    prevent_deleting: 1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.CR_PARENT_AUTOMATION,
      CustomQuestionSlugs.CR_REASON_FOR_CHANGE,
      CustomQuestionSlugs.CR_URGENCY,
      CustomQuestionSlugs.CR_TARGET_LAUNCH_DATE
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Applications",
    note:
      "The Applications Section is currently unavailable for customization.",
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_APPLICATIONS_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.CR_APPLICATIONS]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Documentation",
    sectionTemplateSlug: COSectionTemplateSlugs.CR_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.CR_DOCUMENTATION]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Collaborators",
    sectionTemplateSlug: COSectionTemplateSlugs.CR_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.CR_COLLABORATORS]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Benefits",
    sectionTemplateSlug: COSectionTemplateSlugs.CR_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_HOURS,
      CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_CURRENCY,
      CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_FTE
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Assessments visibility",
    description: "Include additional assessments if necessary below",
    sectionTemplateSlug:
      CONonStandardSectionTemplateSlugs.CR_SECTION_VISIBILITY_LOCKED_V1,
    condition_overrides: [
      { slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT },
      { slug: COConditionSlugs.HIDE_FROM_PROFILE },
      { slug: COConditionSlugs.HIDE_FROM_PIPELINE }
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS,
      CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS,
      CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS,
      CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS
    ]
  });
};

export const assessmentTemplateCR = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultCRAssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Change Request",
      description: ""
    });
    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New Change Request Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};
