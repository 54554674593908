import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import ModalAccountLicense from "../ModalAccountLicense";
import { swalClose } from "../../../SweetAlert/SweetAlert";
import {
  fetchLicensePlanOptions,
  setLicensePlanOptions
} from "../../../../actions/accountLicenseActions";
import { hideModal } from "../../../../actions/modalActions";
import { successSwal } from "../../../../utils/accountLicenseAlerts";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../../../../utils/SweetAlertLoaderSpinner";
import { fetchTenantToEdit } from "../../../../actions/userAdminActions";
import SessionHelper from "../../../../helpers/SessionHelper";
import { CHANGE_PLAN_FIELDS } from "../../../../constants/accountLicenseConstants";
import {
  validateChangePlanFields,
  checkIfPersonalEmail
} from "../..//../../utils/accountLicenseValidationHelper";
import { parseDataForRequest } from "../../../../utils/accountLicenseUtils";
import {
  COMPANY_NAME,
  WORK_EMAIL,
  TENANT_NAME,
  REQUEST_SUCCESSFUL,
  VALIDATE_USER_EMAIL
} from "../../../../constants/accountLicenseConstants";

class ModalChangeAccountPlan extends Component {
  constructor(props) {
    super(props);

    const fields = cloneDeep(CHANGE_PLAN_FIELDS);
    fields[TENANT_NAME].value = SessionHelper.getTenantNameUserToken();
    this.state = {
      fields
    };
  }

  componentDidMount() {
    if (!this.props.licenseDropdownOptions) {
      this.props.fetchLicensePlanOptions();
    }

    if (!this.props.tenantInformation) {
      this.props.fetchTenantToEdit();
    } else {
      this.setTenantRelatedData(this.props.tenantInformation);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.tenantInformation && this.props.tenantInformation) {
      this.setTenantRelatedData(this.props.tenantInformation);
    }
  }

  setStateWithObj = obj => {
    this.setState(obj);
  };

  setTenantRelatedData = async ({ tenant_company_name, admin_user }) => {
    const newFieldsObj = { ...this.state.fields };

    newFieldsObj[COMPANY_NAME].value = tenant_company_name || "";

    const accountOwnerEmail = (admin_user && admin_user.user_email) || "";
    const isPersonalEmail = await checkIfPersonalEmail(accountOwnerEmail);

    newFieldsObj[WORK_EMAIL].value = isPersonalEmail ? "" : accountOwnerEmail;

    this.setState({
      fields: newFieldsObj
    });
  };

  handleSave = async () => {
    const validationObj = await validateChangePlanFields(this.state.fields);

    if (validationObj.hasError) {
      this.setStateWithObj({ fields: validationObj.updatedState });
    } else {
      const parsedData = parseDataForRequest(this.state.fields);
      this.sendRequest(parsedData);
    }
  };

  sendRequest = data => {
    swalSpinnerLoading({});
    this.props.setLicensePlanOptions({ data, callback: this.afterSaveRequest });
  };

  afterSaveRequest = response => {
    swalSpinnerClose();
    this.props.hideModal();
    successSwal({
      text: this.determineSuccessMessage(),
      showConfirmButton: true
    });
    setTimeout(() => {
      swalClose();
    }, 4000);
  };

  determineSuccessMessage = () => {
    const { tenantInformation } = this.props;
    const { fields } = this.state;

    const tenantEmail =
      tenantInformation &&
      tenantInformation.admin_user &&
      tenantInformation.admin_user.user_email;
    const userSubmittedEmail = fields[WORK_EMAIL].value;

    return tenantEmail === userSubmittedEmail
      ? this.props.t([
          "modal_change_account_request_successful",
          "Your request was successfully submitted"
        ])
      : this.props.t([
          "modal_change_account_validate_email",
          "Your request has been received. A representative will be in touch with you shortly."
        ]);
  };

  render() {
    return (
      <ModalAccountLicense
        header={this.props.t([
          "modal_change_account_change_plan",
          "Change Plan"
        ])}
        requestSwalTitle={this.props.t([
          "modal_change_account_change_title",
          "Request Plan Change?"
        ])}
        fields={this.state.fields}
        dropdownOptions={this.props.licenseDropdownOptions}
        tenantInformation={this.props.tenantInformation}
        setStateWithObj={this.setStateWithObj}
        handleSave={this.handleSave}
      />
    );
  }
}

const mapStateToProps = ({ accountLicense, userAdmin }) => ({
  licenseDropdownOptions: accountLicense.licenseDropdownOptions,
  tenantInformation: userAdmin.tenantInformation
});

const mapDispatchToProps = {
  hideModal,
  fetchLicensePlanOptions,
  fetchTenantToEdit,
  setLicensePlanOptions
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ModalChangeAccountPlan)
);
