import { COActionTemplateInterface } from "../../../interfaces/co-interfaces";
import { CONotificationActionSlugs } from "../../../constants/actions/co-notification-action-constants";
import { COEmailActionSlugs } from "../../../constants/actions/co-email-action-constants";
import { COTaskActionSlugs } from "../../../constants/actions/co-task-action-constants";
import { COWebhookActionSlugs } from "../../../constants/actions/co-webhook-action-constants";
import { notificationActionTemplate } from "./notifications/co-notification-action-templates";
import { emailActionTemplate } from "./emails/co-email-action-templates";
import { taskActionTemplate } from "./tasks/co-task-action-templates";
import { inEnum } from "../../../utils/co-utils";
import { COEmailReminderActionSlugs } from "../../../constants/actions/co-email-reminder-action-constants";
import { emailReminderActionTemplate } from "./email_reminders/co-email-reminder-action-templates";

export const actionTemplate = (slug: string): COActionTemplateInterface => {
  // get the template based on the type of action slug
  let template: COActionTemplateInterface = {};
  if (inEnum(CONotificationActionSlugs, slug)) {
    template = notificationActionTemplate(slug);
  } else if (inEnum(COEmailActionSlugs, slug)) {
    template = emailActionTemplate(slug);
  } else if (inEnum(COWebhookActionSlugs, slug)) {
    // TODO: handle webhook slugs here
  } else if (inEnum(COTaskActionSlugs, slug)) {
    template = taskActionTemplate(slug);
  } else if (inEnum(COEmailReminderActionSlugs, slug)) {
    template = emailReminderActionTemplate(slug);
  }

  // TODO: add values that will be the same for each type of action

  return template;
};
