import { customQuestionWithInfo } from "../co-custom-questions.template";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import {
  COQuestionInterface,
  COQuestionTemplateInterface
} from "../../interfaces/co-interfaces";
import { CODefaultEquations } from "../../constants/co-calculation.constants";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardKPISlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";

export const cbaEaseOfImplementationLevelKPITemplate = (
  cba_ease_of_implementation_question: COQuestionInterface
): COQuestionTemplateInterface => {
  return customQuestionWithInfo({
    slug: CustomQuestionSlugs.CBA_EASE_OF_IMPLEMENTATION_LEVEL,
    standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
    title: "Revised Ease of Implementation Level",
    description:
      "Buckets the Revised Ease of Implementation into Easy / Medium / Difficult Ranges",
    question_overrides: {
      co_variable_name: CustomQuestionVariableName(
        CustomQuestionSlugs.CBA_EASE_OF_IMPLEMENTATION_LEVEL
      ),
      co_display_equation: `(${CustomQuestionSlugs.CBA_EASE_OF_IMPLEMENTATION} > 64 ) ? 2 : (${CustomQuestionSlugs.CBA_EASE_OF_IMPLEMENTATION} > 34) ? 1 : 0`,
      co_equation: `($(${cba_ease_of_implementation_question.co_question_ahid}) > 64 ) ? 2 : ($(${cba_ease_of_implementation_question.co_question_ahid}) > 34) ? 1 : 0`
    },
    options_meta_overrides: {
      unit: PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.EASE_OF_IMPLEMENTATION_LEVEL
      }),
      rounding_decimals: 0
    }
  });
};

export const cbaLiveBenefitPerEmployeeMonth = (
  question_ahid_final_estimated_benefit_per_employee: COQuestionInterface
): COQuestionTemplateInterface => {
  return customQuestionWithInfo({
    slug: CustomQuestionSlugs.CBA_LIVE_BENEFIT_EM_HRS_MO,
    standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
    options_meta_overrides: {
      unit: PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_MONTH
      })
    },
    question_overrides: {
      co_question_meta_json: {
        title: { value: "Benefit per employee (hours saved/month)" }
      },
      co_variable_name: CustomQuestionVariableName(
        CustomQuestionSlugs.CBA_LIVE_BENEFIT_EM_HRS_MO
      ),
      co_display_equation: `round((${CustomQuestionSlugs.CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE} / 12),0)`,
      co_equation: `round(($(${question_ahid_final_estimated_benefit_per_employee.co_question_ahid}) / 12),0)`,
      co_question_default_options_json: {
        default_equation_value: 0
      }
    }
  }); //cba_estimated_benefit_hours_per_employee
};

export const cbaAdditionalQuestionTemplates = (): COQuestionTemplateInterface[] => {
  return [
    customQuestionWithInfo({
      slug: CustomQuestionSlugs.CBA_STATS_LICENSE_TYPE,
      standard_question_template_slug: StandardQuestionSlugs.STANDARD_TEXT,
      title: "License Type",
      description: "",
      question_overrides: {
        co_variable_name: CustomQuestionVariableName(
          CustomQuestionSlugs.CBA_STATS_LICENSE_TYPE
        ),
        co_equation: CODefaultEquations.TEXT
      },
      options_meta_overrides: {}
    }),
    customQuestionWithInfo({
      slug: CustomQuestionSlugs.CBA_STATS_SOFTWARE_VENDOR,
      standard_question_template_slug: StandardQuestionSlugs.STANDARD_TEXT,
      title: "RPA Software Vendor",
      description: "",
      question_overrides: {
        co_variable_name: CustomQuestionVariableName(
          CustomQuestionSlugs.CBA_STATS_SOFTWARE_VENDOR
        ),
        co_equation: CODefaultEquations.TEXT
      },
      options_meta_overrides: {}
    }),
    customQuestionWithInfo({
      slug: CustomQuestionSlugs.CBA_STATS_PROCESS_TYPE,
      standard_question_template_slug: StandardQuestionSlugs.STANDARD_TEXT,
      title: "Automation Type",
      description: "",
      question_overrides: {
        co_variable_name: CustomQuestionVariableName(
          CustomQuestionSlugs.CBA_STATS_PROCESS_TYPE
        ),
        co_equation: CODefaultEquations.TEXT
      },
      options_meta_overrides: {
        unit: PATH({
          route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.AUTOMATION_TYPE
        })
      }
    })
  ];
};

// make these questions manually - as KPIS and do the updating like the stats updating (when I do that)
/*
  CBA_ESTIMATED_BENEFIT_FTES: "CBA_ESTIMATED_BENEFIT_FTES",
  NUMBER_OF_ROBOTS: "NUMBER_OF_ROBOTS",
  ESTIMATED_IMPLEMENTATION_COSTS: "ESTIMATED_IMPLEMENTATION_COSTS",
  ESTIMATED_RUNNING_COSTS: "ESTIMATED_RUNNING_COSTS",
  ESTIMATED_NET_BENEFIT_YEAR_ONE: "ESTIMATED_NET_BENEFIT_YEAR_ONE",
  ESTIMATED_NET_BENEFIT_YEAR_TWO: "ESTIMATED_NET_BENEFIT_YEAR_TWO",
  FTR_OVERRIDDEN: "FTR_OVERRIDDEN",

  CBA_EASE_OF_IMPLEMENTATION: "CBA_EASE_OF_IMPLEMENTATION",
  CBA_EASE_OF_IMPLEMENTATION_LEVEL: "CBA_EASE_OF_IMPLEMENTATION_LEVEL",

  CBA_AUTOMATION_POTENTIAL: "CBA_AUTOMATION_POTENTIAL",
  CBA_ESTIMATED_BENEFIT_COST: "CBA_ESTIMATED_BENEFIT_COST",
  CBA_ESTIMATED_BENEFIT_SCORE: "CBA_ESTIMATED_BENEFIT_SCORE",
  CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE:
    "CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE",
  CBA_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE:
    "CBA_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE",
  CBA_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE:
    "CBA_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE", */
