import {
  FETCH_ALL_PROCESS_TEMPLATES,
  FETCH_ACTIVE_PROCESS_TEMPLATES
} from "../constants/actionTypes";

const INITIAL_STATE = {
  all_templates: null,
  active_templates: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_PROCESS_TEMPLATES:
      return {
        ...state,
        all_templates: action.payload
      };
    case FETCH_ACTIVE_PROCESS_TEMPLATES:
      return {
        ...state,
        active_templates: action.payload
      };
    default:
      return state;
  }
}
