import cloneDeep from "lodash/cloneDeep";
import {
  FETCH_MY_TASKS,
  FETCH_TASK_FILTERS,
  FETCH_TASK_COLLABORATORS,
  FETCH_TASK_FILTER_COLLABORATORS,
  EDIT_SINGLE_AUTOMATION_TASK,
  CLEAR_MY_TASKS,
  CLEAR_TASK_COLLABORATORS,
  REMOVE_TASK
} from "../constants/actionTypes";

const INITIAL_STATE = {
  tasks: null,
  page: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  counts: null,
  collaborators: null,
  filterData: null,
  collaboratorsFilterData: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MY_TASKS:
      const {
        tasks,
        page,
        pageSize,
        totalItems,
        totalPages,
        counts
      } = action.payload;
      return {
        ...state,
        tasks,
        page,
        pageSize,
        totalItems,
        totalPages,
        counts
      };
    case FETCH_TASK_FILTERS: {
      const {
        componentStatus,
        dates,
        phases,
        processStatus,
        taskTypes: types
      } = action.payload;

      const filterData = {
        componentStatus,
        dates,
        phases,
        status: processStatus,
        types
      };

      return {
        ...state,
        filterData
      };
    }
    case FETCH_TASK_FILTER_COLLABORATORS: {
      return {
        ...state,
        collaboratorsFilterData: action.payload
      };
    }
    case FETCH_TASK_COLLABORATORS:
      return {
        ...state,
        collaborators: action.payload
      };
    case EDIT_SINGLE_AUTOMATION_TASK: {
      const updatedAutomationTask = action.payload;

      let updatedTasksList = state.tasks.map(task => {
        if (
          task.process_id &&
          task.process_id === updatedAutomationTask.process_id
        ) {
          task.process = {
            ...task.process,
            ...updatedAutomationTask
          };
          return task;
        }

        return task;
      });

      return {
        ...state,
        tasks: updatedTasksList
      };
    }
    case REMOVE_TASK:
      let updatedTasks = cloneDeep(state.tasks);
      const { task_id } = action.payload;

      updatedTasks = updatedTasks.filter(task => {
        return task.user_task_id !== task_id;
      });

      return {
        ...state,
        tasks: updatedTasks
      };
    case CLEAR_TASK_COLLABORATORS:
      return {
        ...state,
        collaborator: null
      };
    case CLEAR_MY_TASKS:
      return {
        ...state,
        tasks: null,
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
        counts: null,
        collaborators: null
      };
    default:
      return state;
  }
}
