import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";

import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import {
  COAssessmentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import { COSectionTemplateSlugs } from "../../constants/co-section.constants";
import { defaultAssessmentTemplate } from "../co-assessment.template";

export const defaultMISCAssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.MISC_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.MISC_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.MISC_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.MISC_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default Miscellaneous Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
  }
  assessmentTemplate.co_options_json = {
    ...assessmentTemplate.co_options_json,
    optional_for_submission: 1,
    condition_overrides: [
      // This assessment is not optional during submission, but is optional with a warning when saving
      {
        slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE
      },
      { slug: COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING },
      {
        slug: COConditionSlugs.HIDE_ASSESSMENT_DURING_CHANGE_REQUEST
      },
      {
        slug:
          COConditionSlugs.SHOW_DURING_CR_BASED_ON_MISC_ASSESSMENT_VISIBILITY_QUESTION
      },
      ...templateAssessmentConditions()
    ]
  };
  return assessmentTemplate;
};

export const AHDefaultMiscAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateMISC() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-misc-0-0`;
  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHFreshMiscAssessmentJSON = () => {
  let assessment = new COAssessment({
    template: assessmentTemplateMISC(false)
  });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "",
      sectionTemplateSlug: COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1
    }),
    question_slugs: []
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  let sectionTemplate;

  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Benefits",
    sectionTemplateSlug: COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT }]
  });

  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.MISC_EXPECTED_BENEFITS,
      CustomQuestionSlugs.MISC_ACTUAL_BENEFITS
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Challenges",
    sectionTemplateSlug: COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT }]
  });

  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.MISC_EXPECTED_CHALLENGES,
      CustomQuestionSlugs.MISC_ACTUAL_CHALLENGES
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Lessons learned",
    sectionTemplateSlug: COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT }]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.MISC_LESSONS_LEARNED]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Media",
    sectionTemplateSlug: COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT }]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.MISC_MEDIA]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Similar automations",
    sectionTemplateSlug: COSectionTemplateSlugs.MISC_DEFAULT_SECTION_V1,
    condition_overrides: [{ slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT }]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.MISC_SIMILAR_AUTOMATIONS]
  });
};
export const assessmentTemplateMISC = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultMISCAssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Misc",
      description: ""
    });
    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New Misc Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};
