import {
  SET_UPLOADED_IMAGE_URL,
  CLEAR_UPLOADED_IMAGE_URL,
  SET_UPLOADED_IMAGE_MODAL_COMPLETION
} from "../constants/actionTypes";

export function setUploadedImageURL(imageUrl: string) {
  return function(dispatch) {
    dispatch({
      type: SET_UPLOADED_IMAGE_URL,
      payload: imageUrl
    });
  };
}

export function setUploadImageCompletionFunction(
  callbackFunction: (imageUrl?: string | null) => void
) {
  return function(dispatch) {
    dispatch({
      type: SET_UPLOADED_IMAGE_MODAL_COMPLETION,
      payload: callbackFunction
    });
  };
}

export function clearUploadedImageURL() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_UPLOADED_IMAGE_URL
    });
  };
}
