import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL,
  EXPLORE_PUBLISHED_AUTOMATIONS_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const automationReviewedNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = automationReviewedNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.AUTOMATION_REVIEWED,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const automationReviewedNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.SUBMITTED_IDEA_APPROVED: {
      return {
        co_options_json: {
          is_deleted: 1,
          is_inactive: 1,
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 12,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_APPROVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.SUBMITTED_IDEA_APPROVED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Submitted idea approved"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " approved your idea " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.SUBMITTED_IDEA_REJECTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 17,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_REJECTED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.SUBMITTED_IDEA_REJECTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Submitted idea rejected"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " rejected your idea " +
              COActionReplacementStrings.PROCESS_NAME +
              ", with the following comment: '" +
              COActionReplacementStrings.PROCESS_REJECTED_REASON +
              "'."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.SUBMITTED_IDEA_MARKED_AS_DUPLICATE: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 20,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_DUPLICATE
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.DUPLICATE_SUBMITTED_IDEA,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Submitted idea marked as duplicate"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " marked your idea " +
              COActionReplacementStrings.PROCESS_NAME +
              " as being a duplicate"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ASSESSED_AUTOMATION_APPROVED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.AP_COLLABORATOR
            ]
          },
          legacy_notification_id: 32,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_APPROVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSESSED_AUTOMATION_APPROVED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessed automation is approved"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ASSESSED_AUTOMATION_REJECTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 35,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_REJECTED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSESSED_AUTOMATION_REJECTION,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessed automation is rejected"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              ", with the question / comment " +
              COActionReplacementStrings.PROCESS_REJECTED_REASON +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ASSESSED_AUTOMATION_PUT_ON_HOLD: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 37,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.ASSESSMENT_ON_HOLD
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSESSED_AUTOMATION_PUT_ON_HOLD,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessed automation is put on hold"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_APPROVED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 144,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.BUSINESS_REVIEW_APPROVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_APPROVED,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation approved"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " approved your automation - " +
              COActionReplacementStrings.PROCESS_NAME +
              " as a submission."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 145,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation to technical review"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " has moved your automation - " +
              COActionReplacementStrings.PROCESS_NAME +
              " to technical review."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_ON_HOLD: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 146,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.BUSINESS_REVIEW_ON_HOLD
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_ON_HOLD,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation on hold"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " has updated the automation - " +
              COActionReplacementStrings.PROCESS_NAME +
              " to on hold."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_ATUOMATION_REJECTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 147,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.BUSINESS_REVIEW_REJECTED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_REJECTED,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation rejeceted"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " rejected your automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ", with the following comment: '" +
              COActionReplacementStrings.PROCESS_REJECTED_REASON +
              "'."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_APPROVED_FOR_CONSUMPTION: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER, CORoleType.INTERESTED_IN_APP]
          },
          legacy_notification_id: 148,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.LIVE_ONGOING
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_APPROVED_FOR_CONSUMPTION,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation approved for consumption"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " approved the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " for large consumption."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_REWORK_REQUIRED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 149,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_REWORK_REQUIRED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_REWORK_REQUIRED,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation rework required"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " marked your automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " as rework required."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_ARCHIVED_BUSINESS_REVIEW: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 150,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.BUSINESS_REVIEW_ARCHIVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_ARCHIVED_BUSINESS_REVIEW,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation archived in Business Review"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " archived your automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ""
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_APPROVED_FOR_POWER_USER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 151,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.DEVELOPMENT_NOT_STARTED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_READY_FOR_POWER_USER,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation approved for CD Power User work"
          },
          message: {
            value:
              "A new automation has been approved for Citizen Developer Power User Work: " +
              COActionReplacementStrings.PROCESS_NAME +
              ". Check the Automation Pipeline - Implementation tab and start the development."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.CD_AUTOMATION_ARCHIVED_TECHNICAL_REVIEW: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_notification_id: 152,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_ARCHIVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.CD_AUTOMATION_ARCHIVED_TECHNICAL_REVIEW,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD Automation was archived in Technical Review"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " archived your automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ""
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.AUTOMATION_PUBLISHED_FOR_LIVE_CONSUMPTION: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER, CORoleType.INTERESTED_IN_APP]
          },
          legacy_notification_id: 153,
          platform_event_type: COPlatformEventType.AUTOMATION_PUBLISHED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.AUTOMATION_PUBLISHED_FOR_LIVE_CONSUMPTION,
          settings_ids: [44],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value:
              "Automation published for large consumption in the Automation Store"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " has published the automation " +
              COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME +
              " for large consumption in the Automation Store"
          },
          redirect_url: {
            value: EXPLORE_PUBLISHED_AUTOMATIONS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.AUTOMATION_HAS_UPDATED_PACKAGE: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_CONSUMER]
          },
          legacy_notification_id: 154,
          platform_event_type: COPlatformEventType.AUTOMATION_PUBLISHED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.AUTOMATION_HAS_UPDATED_PACKAGE,
          settings_ids: [44, 45],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "The package for automation has been updated"
          },
          message: {
            value:
              "The package for automation " +
              COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME +
              " has been updated! Get the new version " +
              COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME_HERE +
              ""
          },
          redirect_url: {
            value: EXPLORE_PUBLISHED_AUTOMATIONS_URL
          }
        }
      };
    }
  }
  return undefined;
};
