import { COEmailActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COEmailActionSlugs,
  COEmailActivityType,
  COEmailType
} from "../../../../../constants/actions/co-email-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const functionNotificationEmailActionTemplate = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  // get template based on slug
  let template = functionNotificationEmailActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      email_type: COEmailType.FUNCTIONAL_NOTIFICATION,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const functionNotificationEmailActionTemplateFromSlug = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  switch (slug) {
    case COEmailActionSlugs.WELCOME_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 1,
          email_activity_type: COEmailActivityType.USER_INVITED_TO_JOIN_NAVGO,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.SEND_USER_INVITE_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Welcome Email"
          },
          email_body: {
            value: "welcome_email"
          }
        }
      };
    }
    case COEmailActionSlugs.SUBMITTED_IDEA_TO_REVIEW_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.IDEA_APPROVER]
          },
          legacy_email_id: 2,
          email_activity_type: COEmailActivityType.SUBMITTED_IDEA_REVIEW,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.IDEA_SUBMITTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Submitted idea to review"
          },
          email_body: {
            value: "submitted_idea_to_review"
          }
        }
      };
    }
    case COEmailActionSlugs.ASSIGN_PROJECT_MANAGER_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 8,
          email_activity_type: COEmailActivityType.ASSIGN_PROJECT_MANAGER,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_APPROVED
          ],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assign project manager"
          },
          email_body: {
            value: "assign_project_manager"
          }
        }
      };
    }
    case COEmailActionSlugs.FIRST_USER_STANDARD_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 10,
          email_activity_type: COEmailActivityType.FIRST_USER_STANDARD,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.FIRST_USER_STANDARD_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "First user standard"
          },
          email_body: {
            value: "first_user_standard"
          }
        }
      };
    }
    case COEmailActionSlugs.FIRST_USER_TRIAL_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TRIGGER_USER]
          },
          legacy_email_id: 11,
          email_activity_type: COEmailActivityType.FIRST_USER_TRIAL,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.FIRST_USER_TRIAL_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "First user trial"
          },
          email_body: {
            value: "first_user_trial"
          }
        }
      };
    }
    case COEmailActionSlugs.DEAL_HUB_CHANGE_PLAN_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.DEALHUB, CORoleType.FINANCE]
          },
          legacy_email_id: 12,
          email_activity_type: COEmailActivityType.CHANGED_PLAN_DEALHUB_EMAIL,
          email_category_type: COCategoryType.TENANT,
          platform_event_type: COPlatformEventType.CHANGED_PLAN_DEALHUB_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Dealhub change plan"
          },
          email_body: {
            value: "deal_hub_change_plan"
          }
        }
      };
    }
    case COEmailActionSlugs.USER_DELETED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 16,
          email_activity_type: COEmailActivityType.USER_DELETED,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.USER_DELETED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User deleted"
          },
          email_body: {
            value: "user_deleted"
          }
        }
      };
    }
    case COEmailActionSlugs.ACCOUNT_DEACTIVATED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 25,
          email_activity_type: COEmailActivityType.USER_DEACTIVATED,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.USER_DEACTIVATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User deactivated"
          },
          email_body: {
            value: "account_deactivated"
          }
        }
      };
    }
    case COEmailActionSlugs.ACCOUNT_ACTIVATED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 26,
          email_activity_type: COEmailActivityType.USER_ACTIVATED,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.USER_ACTIVATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "User reactivated"
          },
          email_body: {
            value: "account_activated"
          }
        }
      };
    }
    case COEmailActionSlugs.WELCOME_EMAIL_FPS: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 40,
          email_activity_type: COEmailActivityType.WELCOME_EMAIL_FPS,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.SEND_USER_INVITE_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Welcome Email FPS"
          },
          email_body: {
            value: "welcome_email_fps"
          }
        }
      };
    }
  }
  return undefined;
};
