import { combineReducers } from "redux";
import modalReducer from "./modalReducer";
import userProfileReducer from "./userProfileReducer";
import componentProfileReducer from "./componentProfileReducer";
import componentProfileCommentsReducer from "./componentProfileCommentsReducer";
import componentProfileReviewsReducer from "./componentProfileReviewsReducer";
import componentUploadEditReducer from "./componentUploadEditReducer";
import questionnaireReducer from "./questionnaireReducer";
import exploreAutomationsReducer from "./exploreAutomationsReducer";
import explorePeopleReducer from "./explorePeopleReducer";
import exploreComponentsReducer from "./exploreComponentsReducer";
import userAdminReducer from "./userAdminReducer";
import myTasksReducer from "./myTasksReducer";
import myComponentsReducer from "./myComponentsReducer";
import manageComponentsReducer from "./manageComponentsReducer";
import adminHierarchySetupReducer from "./adminHierarchySetupReducer";
import adminDocumentationTemplatesReducer from "./adminDocumentationTemplatesReducers";
import adminManageApplicationsReducer from "./adminManageApplicationsReducer";
import globalDataReducer from "./globalDataReducer";
import processTemplatesReducer from "./processTemplatesReducer";
import processProfileReducer from "./processProfileReducer";
import processProfileDocumentsReducer from "./processProfileDocumentsReducer";
import processProfileCommentsReducer from "./processProfileCommentsReducer";
import processProfileCollaboratorsReducer from "./processProfileCollaboratorsReducer";
import fileTransferReducer from "./fileTransferReducer";
import tableAutomationsReducer from "./tableAutomationsReducer";
import imageUploadReducer from "./imageUploadReducer";
import automationPipelineReducer from "./automationPipelineReducer";
import processDuplicateCheckReducer from "./processDuplicateCheckReducer";
import userStatusReducer from "./userStatusReducer";
import notificationsReducer from "./notificationsReducer";
import badgesReducer from "./badgesReducer";
import leaderboardReducer from "./leaderboardReducer";
import adminCostSetupReducer from "./adminCostSetupReducer";
import adminOtherRunningCostsReducer from "./adminOtherRunningCostsReducer";
import adminRpaSoftwareVendorReducer from "./adminRpaSoftwareVendorReducer";
import adminOneTimeCostsReducer from "./adminOneTimeCostsReducer";
import adminRunningCostsReducer from "./adminRunningCostsReducer";
import automationPerformanceDashboardReducer from "./automationPerformanceDashboardReducer";
import automationCostDashboardReducer from "./automationCostDashboardReducer";
import accountLicenseReducer from "./accountLicenseReducer";

const rootReducer = combineReducers({
  modal: modalReducer,
  processProfile: processProfileReducer,
  processProfileDocuments: processProfileDocumentsReducer,
  processProfileCollaborators: processProfileCollaboratorsReducer,
  processProfileComments: processProfileCommentsReducer,
  processTemplates: processTemplatesReducer,
  userProfile: userProfileReducer,
  componentProfile: componentProfileReducer,
  componentProfileComments: componentProfileCommentsReducer,
  componentProfileReviews: componentProfileReviewsReducer,
  componentUploadEdit: componentUploadEditReducer,
  questionnaire: questionnaireReducer,
  exploreAutomations: exploreAutomationsReducer,
  explorePeople: explorePeopleReducer,
  exploreComponents: exploreComponentsReducer,
  fileTransfer: fileTransferReducer,
  tableAutomations: tableAutomationsReducer,
  imageUpload: imageUploadReducer,
  processDuplicateCheck: processDuplicateCheckReducer,
  automationPipeline: automationPipelineReducer,
  userAdmin: userAdminReducer,
  myTasks: myTasksReducer,
  myComponents: myComponentsReducer,
  manageComponents: manageComponentsReducer,
  adminHierarchySetup: adminHierarchySetupReducer,
  adminDocumentationTemplates: adminDocumentationTemplatesReducer,
  userStatus: userStatusReducer,
  adminManageApplications: adminManageApplicationsReducer,
  globalData: globalDataReducer,
  notifications: notificationsReducer,
  badges: badgesReducer,
  leaderboard: leaderboardReducer,
  adminCostSetup: adminCostSetupReducer,
  adminRpaSoftwareVendor: adminRpaSoftwareVendorReducer,
  adminOneTimeCosts: adminOneTimeCostsReducer,
  adminRunningCosts: adminRunningCostsReducer,
  adminOtherRunningCosts: adminOtherRunningCostsReducer,
  automationPerformanceDashboard: automationPerformanceDashboardReducer,
  automationCostDashboard: automationCostDashboardReducer,
  accountLicense: accountLicenseReducer
});

export default rootReducer;
