import { COCalculatedValueTypes, COTypes } from "../constants/co-constants";
import {
  COAssessmentInterface,
  COCalculatedValueDBClass,
  COCalculatedValueInterface,
  COContextInterface,
  COProcessAnswerInterface,
  COQuestionAnswerOptionInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { COBase } from "./co-base.class";
import { calculate } from "../helpers/co-calculation.helper";
import { LegacyProcessColumnName } from "../interfaces/co-legacy.interfaces";
import {
  displayValueForKPI,
  displayValueForProcessAnswer
} from "../helpers/co-format.helper";
import { isNullOrUndefined } from "../utils/co-utils";
import { COValidationErrorException } from "./co-validation-error-manager.class";
import { addValidationErrors } from "../helpers/co-context-validation.helper";
import { optionsInContext } from "../helpers/co-context.helper";
import {
  calculatedValueTypeForQuestion,
  getValueAndDisplayValueForLinkCOType
} from "../helpers/co-calculated-value-type.helper";
import { calculatedValueKey } from "../helpers/co-calculated-type.helper";

export interface COCalculatedValue extends COCalculatedValueInterface {}
export class COCalculatedValue extends COBase {
  static getClassName(): string {
    return "COCalculatedValue";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: COCalculatedValueDBClass,
      objectClass: COCalculatedValue
    };
  }

  static ahid(object: COCalculatedValue): string | undefined {
    return object.co_calculated_value_key;
  }

  static calculatedValueProcessColumnName({
    question
  }: {
    question?: COQuestionInterface;
  }): string {
    // look up legacy column name via variable (used for Q2, or legacy_slug used for CBA)
    if (question?.co_variable_name) {
      let legacyColumnName = LegacyProcessColumnName(question.co_variable_name);
      if (legacyColumnName) {
        return legacyColumnName;
      } else if (question.co_question_options_json?.legacy_slug) {
        legacyColumnName = LegacyProcessColumnName(
          question.co_question_options_json?.legacy_slug
        );
        if (legacyColumnName) {
          return legacyColumnName;
        }
      }
    }
    return question?.co_calculated_value_key?.toLowerCase() || "";
  }

  static calculateForQuestion(
    question: COQuestionInterface,
    context: COContextInterface,
    throwExceptionOnCalculationError: boolean = false
  ): COCalculatedValue | null {
    let calculatedValue = new COCalculatedValue({});

    // this could be a KPI or a question
    let co_assessment: COAssessmentInterface = context.assessment || {};
    let { value, errors, defaultValueUsed } = calculate({
      context,
      question
    });

    if (Array.isArray(errors)) {
      addValidationErrors(context, errors);
      calculatedValue.validation_errors = errors;
      if (throwExceptionOnCalculationError) {
        throw new COValidationErrorException(errors);
      }
    }

    let questionOptionsInContext = optionsInContext({
      context,
      options: question.options || {},
      should_resolve: true
    });

    // we don't want to save it if the user hasn't answered - but we do want default values to be used in calculations
    if (defaultValueUsed) {
      // we don't want to wipe out external data questions and KPIS because we might be updating them and if the FE doesn't set them they'll be wiped out
      if (!questionOptionsInContext?.external_answer_source) {
        // console.log(
        //   `using default value question, skipping calculated value ${question.objectDisplayName?.()}`
        // );
        //console.log(question);
        return null;
      }
    }

    calculatedValue.co_calculated_value = value;

    calculatedValue.co_calculated_value_key = calculatedValueKey({
      assessment: co_assessment,
      question
    });

    // co_calculated_value_type comes from question answer template, or question template or if not it's based on the can_impact_kpi flag
    let co_calculated_value_type = calculatedValueTypeForQuestion({
      context,
      question,
      optionsInContext // passing in to avoid circular references
    });

    calculatedValue.co_calculated_value_type = co_calculated_value_type;

    // display value
    calculatedValue.co_calculated_display_value = value; // we want to match value even if null or undefined
    if (question.co_question_co_type === COTypes.KPI) {
      calculatedValue.co_calculated_display_value = displayValueForKPI?.({
        context,
        question,
        value: value
      });
    } else if (question.co_process_answer) {
      let process_answer: COProcessAnswerInterface = question.co_process_answer;
      calculatedValue.co_calculated_display_value = displayValueForProcessAnswer?.(
        { context, process_answer, value: value }
      );
    }

    // starts some generic handling of COTypes for display value
    switch (co_calculated_value_type) {
      case COCalculatedValueTypes.URL:
        let formatted = getValueAndDisplayValueForLinkCOType(value);
        value = formatted.value;
        calculatedValue.co_calculated_value = value;
        calculatedValue.co_calculated_display_value = formatted.display_value;
        break;
    }

    // if we have an undefined entry then we don't want to display it as a calculated value
    // currently in use for CBA
    if (
      !isNullOrUndefined(value) &&
      questionOptionsInContext?.undefined_value
    ) {
      // loose comparison
      if (value + "" == questionOptionsInContext.undefined_value) {
        calculatedValue.co_calculated_display_value = "";
      }
    }

    question.co_calculated_value_key = calculatedValue.co_calculated_value_key;
    calculatedValue.co_table_row_index = question.co_table_row_index;
    calculatedValue.process_column_name = this.calculatedValueProcessColumnName(
      {
        question
      }
    );

    return calculatedValue;
  }
}
