import {
  COContextInterface,
  COQuestionAnswerOptionTemplateInterface,
  COQuestionInterface,
  COQuestionTemplateInterface
} from "../interfaces/co-interfaces";
import { isRunningInBrowser } from "../utils/co-utils";
import { loadTemplateForSlugWithoutImporting } from "./co-circular-refrence-helper";

// we only can do this on the frontend because the backend we really don't want globals which could leak cross tenant data
export const updateQuestionTypeAKATemplate = ({
  context,
  question,
  questionTemplate,
  questionTemplateSlug
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionTemplate?: COQuestionTemplateInterface;
  questionTemplateSlug?: string;
}): COQuestionInterface => {
  let useCache = false;
  if (isRunningInBrowser()) {
    useCache = true;
    if (!question.cache) {
      question.cache = {};
      question.cache.question_info_by_template_slug = {};
    }
  }

  let templateToApply;
  if (questionTemplate) {
    templateToApply = { ...questionTemplate };
  } else if (questionTemplateSlug) {
    let templateFromSlug = loadTemplateForSlugWithoutImporting(
      questionTemplateSlug
    );
    templateToApply = { ...templateFromSlug };
  }

  if (
    useCache &&
    questionTemplate?.co_question_template_slug &&
    question.cache?.question_info_by_template_slug[
      questionTemplate?.co_question_template_slug
    ]
  ) {
    templateToApply = {
      ...templateToApply,
      co_question_json: JSON.parse(
        question.cache?.question_info_by_template_slug[
          questionTemplate?.co_question_template_slug
        ]
      )
    };
  }
  //cache existing
  if (useCache && question.co_question_template_slug) {
    question.cache.question_info_by_template_slug[
      question.co_question_template_slug
    ] = JSON.stringify(question);
  }

  // remove position data and meta data to keep same title and description
  delete templateToApply.co_question_json?.co_question_meta_json;
  delete templateToApply.co_question_json?.co_question_position_json;

  // increment counter with decimal to prevent possible previous match stopping render
  question.renderCounter = (question.renderCounter || 0) + 100.1;
  question.setNewTemplate?.(context || {}, templateToApply);

  return question;
};

/// can move to helpers
export const answerOptionTemplatesFromQuestionTemplate = ({
  questionTemplate
}: {
  questionTemplate: COQuestionTemplateInterface;
}): COQuestionAnswerOptionTemplateInterface[] => {
  const templates: COQuestionAnswerOptionTemplateInterface[] = [];
  if (
    questionTemplate &&
    questionTemplate.co_question_answer_option_templates
  ) {
    return questionTemplate.co_question_answer_option_templates;
  }
  if (
    questionTemplate &&
    questionTemplate.co_question_template_answer_option_template_slugs
  ) {
    let answer_option_slugs = questionTemplate?.co_question_template_answer_option_template_slugs?.split(
      ","
    );
    for (const slug of answer_option_slugs) {
      let answerTemplate = loadTemplateForSlugWithoutImporting(slug);
      if (!answerTemplate) {
        throw new Error(
          `Unable to load question answer template for slug ${slug}`
        );
      }
      templates.push(answerTemplate);
    }
  }
  return templates;
};
