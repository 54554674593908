import { COSection } from "../classes/co-assessment-section.class";
import { COContext } from "../classes/co-context.class";
import { COQuestionAnswerOption } from "../classes/co-question-answer-option.class";
import { COQuestion, COQuestionTemplate } from "../classes/co-question.class";
import { COEquationFunctions } from "../constants/co-calculation.constants";
import {
  COCalculatedValueTypes,
  COExternalAnswerSources,
  COTypes,
  COConditionSlugs
} from "../constants/co-constants";
import {
  LegacySlugForLegacyQuestion,
  LegacySlugForLegacyQuestionAnswer,
  LegacyToCustomSlugMapping
} from "../constants/co-migration-constants";
import {
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs,
  NonStandardQuestionSlugs,
  CustomQuestionSlugs
} from "../constants/co-question.constants";
import { COSectionTemplateSlugs } from "../constants/co-section.constants";
import { COConditionOverrideSlugPayload } from "../interfaces/co-interfaces";
import {
  DefaultQuestionsThatMayNotBeInLegacyQuestionionaires,
  LegacyProcessColumnName,
  LegacyQuestionInterface
} from "../interfaces/co-legacy.interfaces";
import { questionAnswerOptionTemplateForSlug } from "../templates/co-question-answer-options.template";
import { questionTemplateForSlug } from "../templates/co-questions.template";
import { documentSelectedControlCallback } from "../templates/elements/controls/co-document-controls-utils";
import { addAdditionalDocumentControlForAnswerOption } from "../templates/elements/controls/co-document-controls.template";
import { toLegacyVariable } from "../utils/co-migration.utils";
import {
  isDraftJS,
  isNullOrUndefined,
  toSlug,
  transformTextToDraftJS
} from "../utils/co-utils";

import { addAnswerOption, answerQuestion } from "./co-question.helper";

export enum LegacyQuestionInputTypeEnum {
  SingleChoice = "Radio",
  FreeText = "Text",
  FreeRichText = "Textarea",
  MultipleChoice = "Multiple",
  ProcessDocumentation = "ProcessDocumentation",
  Dropdown = "Dropdown", // used in the old Q3 `cd_advanced_information` property
  Taxonomy = "Taxonomy"
}

export const legacyCustomQuestionToCOQuestion = ({
  context,
  section,
  legacyQuestion
}: {
  context: COContext;
  section: COSection;
  legacyQuestion: LegacyQuestionInterface;
}): COQuestion | null => {
  if (!legacyQuestion || legacyQuestion.question_is_deleted) {
    return null;
  }
  let title = legacyQuestion.question_text || "Customized Question";

  // will be emptydraft object if empty
  let description = "";
  if (
    legacyQuestion.question_tips_and_examples &&
    legacyQuestion.question_tips_and_examples != "null" &&
    legacyQuestion.question_tips_and_examples != "NULL" &&
    legacyQuestion.question_tips_and_examples.length > 0
  ) {
    description = legacyQuestion.question_tips_and_examples;
  } else if (
    legacyQuestion.question_description &&
    legacyQuestion.question_description != "null" &&
    legacyQuestion.question_description != "NULL" &&
    legacyQuestion.question_description.length > 0
  ) {
    description = legacyQuestion.question_description;
  }
  description = isDraftJS(description)
    ? description
    : transformTextToDraftJS(description);

  let optional_for_submission = (!isNullOrUndefined(
    legacyQuestion.question_is_mandatory
  )
  ? !!legacyQuestion.question_is_mandatory
  : true)
    ? 0
    : 1;

  let is_hidden = !isNullOrUndefined(legacyQuestion.question_is_active)
    ? legacyQuestion.question_is_active
      ? 0
      : 1
    : 1;

  // no matter what if it's hidden make it optional for legacy questions
  if (is_hidden) {
    optional_for_submission = 1;
  }

  let condition_overrides: COConditionOverrideSlugPayload[] = [];
  if (is_hidden) {
    condition_overrides.push({ slug: COConditionSlugs.HIDDEN });
  }

  if (legacyQuestion.question_impacts_the_score) {
    condition_overrides.push({
      slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI
    });
  }
  if (legacyQuestion.user_can_comment) {
    condition_overrides.push({
      slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION
    });
  }

  if (optional_for_submission === 0) {
    condition_overrides.push({ slug: COConditionSlugs.QUESTION_IS_REQUIRED });
  }

  let sort_order = legacyQuestion.question_sort_order || 0;

  //let question_input_type = legacyQuestion.question_input_type;
  let input_type =
    legacyQuestion.question_input_type?.question_input_type_text ||
    LegacyQuestionInputTypeEnum.FreeText;

  let question_response_choices =
    legacyQuestion.question_response_choices || [];

  let max_question_answer_options = 1;
  let max_selectable_answers = 1;

  if (input_type === LegacyQuestionInputTypeEnum.SingleChoice) {
    max_question_answer_options = 20;
  }

  if (input_type === LegacyQuestionInputTypeEnum.MultipleChoice) {
    max_question_answer_options = 25;
    max_selectable_answers = Math.max(5, question_response_choices.length);
  }

  let legacy_slug = LegacySlugForLegacyQuestion(legacyQuestion);

  let templateSlug = "";
  switch (input_type) {
    case LegacyQuestionInputTypeEnum.FreeText:
      templateSlug = StandardQuestionSlugs.STANDARD_TEXT;
      condition_overrides.push({
        slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE
      });
      break;
    case LegacyQuestionInputTypeEnum.SingleChoice:
      templateSlug = StandardQuestionSlugs.SINGLE_CHOICE;
      condition_overrides.push({
        slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE
      });
      break;
    case LegacyQuestionInputTypeEnum.MultipleChoice:
      templateSlug = StandardQuestionSlugs.MULTIPLE_CHOICE;

      condition_overrides.push({
        slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE
      });
      break;
    case LegacyQuestionInputTypeEnum.FreeRichText:
      templateSlug = StandardQuestionSlugs.RICH_TEXT;
      break;
    case LegacyQuestionInputTypeEnum.ProcessDocumentation:
      templateSlug = NonStandardQuestionSlugs.Q1_DOCUMENTS;
      condition_overrides.push({
        slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE
      });
      break;
    default:
      console.log(
        `Unable to find Question Template for Slug in Custom Question Migration ${legacy_slug}`
      );
      return null;
      break;
  }

  let questionTemplate = questionTemplateForSlug(templateSlug);
  if (!questionTemplate) {
    console.log(
      `Unable to find Question Template for Slug in Custom Question Migration ${templateSlug}`
    );
  }
  // now we override a couple things here
  if (questionTemplate?.co_question_json?.co_question_meta_json?.title) {
    questionTemplate.co_question_json.co_question_meta_json.title.value = title;
  }
  if (questionTemplate?.co_question_json?.co_question_meta_json?.description) {
    questionTemplate.co_question_json.co_question_meta_json.description.value = description;
  }
  // override defaults

  if (questionTemplate?.co_question_json?.co_question_options_json) {
    questionTemplate.co_question_json.co_question_options_json = {
      ...questionTemplate.co_question_json.co_question_options_json,
      max_selectable_answers,
      max_question_answer_options,
      legacy_slug,
      condition_overrides
    };
  }

  // override template defaults
  if (questionTemplate?.co_question_json?.co_question_position_json) {
    questionTemplate.co_question_json.co_question_position_json = {
      ...questionTemplate.co_question_json.co_question_position_json,
      sort_order
    };
  }

  if (questionTemplate?.co_question_json) {
    questionTemplate.co_question_json.co_variable_name = toLegacyVariable(
      title || ""
    );

    // if this is a default Q1 assessment question - we want to try to maintain the equation if it impacts the KPI
    if (legacyQuestion.question_impacts_the_score) {
      // impacts score and is specific to Q1 - we need it to match for the KPI we migrate over
      let variable = LegacyToCustomSlugMapping()[legacy_slug];
      if (variable && variable.length > 0) {
        console.log(
          `Found Edited Q1 Question with Type Changed we need to mantain variable for ${legacyQuestion.question_text} - ${legacy_slug} - variable - ${variable}`
        );
        questionTemplate.co_question_json.co_variable_name = variable;
      }
    }

    if (input_type == LegacyQuestionInputTypeEnum.MultipleChoice) {
      questionTemplate.co_question_json.co_equation =
        COEquationFunctions.MAX_OF_ANSWERS;
      questionTemplate.co_question_json.co_display_equation =
        COEquationFunctions.MAX_OF_ANSWERS;
    }
  }

  //console.log(questionTemplate?.co_question_json?.co_variable_name);
  //console.log(legacy_slug);

  let co_question = new COQuestion({
    context,
    template: questionTemplate,
    parentCOObject: section
  });

  // lets add some answer options
  if (
    input_type === LegacyQuestionInputTypeEnum.SingleChoice ||
    input_type === LegacyQuestionInputTypeEnum.MultipleChoice
  ) {
    let answer_option_template_slug =
      StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION;
    if (input_type === LegacyQuestionInputTypeEnum.MultipleChoice) {
      answer_option_template_slug =
        StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION;
    }

    co_question.co_question_answer_options = []; // PURGE THE STUPID AUTO OPTION FROM THE TEMPLATE

    let valueIndex = 0;
    for (const legacyAnswerOption of question_response_choices) {
      valueIndex++;
      let title =
        legacyAnswerOption.response_choice_text || `answer-option${valueIndex}`;

      let answer_value = toSlug(title);
      if (
        !isNullOrUndefined(legacyAnswerOption.response_choice_score_weight) &&
        legacyQuestion.question_impacts_the_score
      ) {
        answer_value = legacyAnswerOption.response_choice_score_weight + "";
      }
      let answer_sort_order = legacyAnswerOption.response_sort_order || 0;
      let answer_option_template = questionAnswerOptionTemplateForSlug(
        answer_option_template_slug
      );
      let answer_legacy_slug = LegacySlugForLegacyQuestionAnswer(
        legacyQuestion,
        legacyAnswerOption
      );

      let co_question_answer_option = new COQuestionAnswerOption({
        context,
        question: co_question,
        template: answer_option_template
      });

      if (
        co_question_answer_option &&
        co_question_answer_option.co_question_answer_option_options_json &&
        co_question_answer_option.co_question_answer_option_meta_json?.title
      ) {
        co_question_answer_option.co_question_answer_option_position_json = {
          sort_order: answer_sort_order
        };
        co_question_answer_option.co_question_answer_option_options_json.legacy_slug = answer_legacy_slug;
        // not a thing co_question_answer_option.co_question_answer_option_options_json.can_impact_kpi = 0;
        co_question_answer_option.co_question_answer_option_meta_json.title.value = title;
        co_question_answer_option.co_question_answer_option_value =
          answer_value + "";
      }

      if (co_question_answer_option) {
        addAnswerOption({
          context,
          question: co_question,
          questionAnswerOption: co_question_answer_option
        });
      } else {
        console.log(
          "error building answer option template for custom question"
        );
        console.log(legacyAnswerOption);
        console.log(legacyQuestion);
      }
    }
  }
  return co_question;
};

export const legacyQuestionsToNewAssessment = ({
  context,
  legacyQuestions,
  logInfo
}: {
  context: COContext;
  legacyQuestions: LegacyQuestionInterface[];
  logInfo: boolean;
}) => {
  let assessment = context.assessment;

  for (const section of assessment?.co_assessment_sections || []) {
    let sectionContext = context.update?.({ section });
    for (const question of section.co_questions || []) {
      let questionContext = sectionContext?.update?.({ question });
      // update question
      if (question.co_question_co_type === COTypes.QUESTION) {
        let variable_name = question.co_variable_name;
        let question_legacy_slug =
          question.co_question_options_json?.legacy_slug;
        if (question_legacy_slug) {
          //   console.log(`looking for legacy slug ${question_legacy_slug}`);
          // find old question that maps to it
          let foundLegacyQuestionMatchingQuestionInAssessment = false;
          // look for some sort of identifier then make a mapping
          for (const old_question of legacyQuestions) {
            let old_question_legacy_slug = LegacySlugForLegacyQuestion(
              old_question
            );

            // console.log(
            //   `comparing against legacy slug ${old_question_legacy_slug}`
            // );

            if (old_question_legacy_slug === question_legacy_slug) {
              foundLegacyQuestionMatchingQuestionInAssessment = true;
              old_question.matched_new_question = true;
              //found match
              if (logInfo) {
                console.log(
                  `found match ${variable_name} - ${old_question_legacy_slug}`
                );
              }

              // ok so now we can look and see what type it is
              let process_answer = question.co_process_answer;
              if (!process_answer) {
                throw new Error(
                  `We should have a process answer - did you call prepareforEditSubmit? ${question.co_variable_name}`
                );
              }
              let processAnswerContext = questionContext?.update?.({
                process_answer
              });
              let legacyQuestion: LegacyQuestionInterface = old_question;
              if (!isNullOrUndefined(legacyQuestion.comment)) {
                if (process_answer?.co_process_answer_meta_json?.comment) {
                  if (logInfo) {
                    console.log("comment added");
                  }
                  // ok determine if it IS draftjs before converting to draft JS
                  process_answer.co_process_answer_meta_json.comment.value = isDraftJS(
                    legacyQuestion.comment
                  )
                    ? legacyQuestion.comment
                    : transformTextToDraftJS(legacyQuestion.comment);
                }
              }
              // can't rely on the legacy question input type - need to rely on the co question type to answer it, this is because question data is overwritten in legacy Q1 versions
              // check if this question is "selectable" - ie we don't have a selected option
              if (
                (question.co_process_answer?.co_process_answer_selections
                  ?.length || 0) === 0
              ) {
                if (
                  (legacyQuestion.question_response_answers &&
                    legacyQuestion.question_response_answers.length > 0) ||
                  (legacyQuestion.question_response_choices &&
                    legacyQuestion.question_response_choices.length > 0)
                ) {
                  if (legacyQuestion.question_response_answers) {
                    for (const legacyAnswer of legacyQuestion.question_response_answers) {
                      let choiceFound = false;

                      let legacyAnswerSlug = LegacySlugForLegacyQuestionAnswer(
                        legacyQuestion,
                        legacyAnswer
                      );

                      let answerOption = question.co_question_answer_options?.find(
                        ao =>
                          ao.co_question_answer_option_options_json
                            ?.legacy_slug === legacyAnswerSlug
                      );
                      if (answerOption && processAnswerContext) {
                        if (logInfo) {
                          console.log("matched answer option found");
                        }

                        answerQuestion({
                          context: processAnswerContext,
                          question,
                          questionAnswerOption: answerOption
                        });

                        choiceFound = true;

                        // so we need to deal with some very fancy answers here - for drafts
                        if (
                          legacyAnswer.documentsList ||
                          (legacyAnswer.document &&
                            legacyAnswer.document.file_id)
                        ) {
                          let documentPickerControls =
                            answerOption.meta?.controls?.controls;

                          let process_answer_selection = question.co_process_answer?.co_process_answer_selections?.find(
                            selection =>
                              selection.co_question_answer_option ==
                              answerOption
                          );

                          if (
                            documentPickerControls &&
                            process_answer_selection
                          ) {
                            let processAnswerOptionContext = context.update?.({
                              question,
                              process_answer,
                              answer_option: answerOption,
                              process_answer_selection
                            });
                            if (legacyAnswer.documentsList) {
                              for (
                                let x = 0;
                                x < legacyAnswer.documentsList.length;
                                x++
                              ) {
                                // I'm worried that we're not adding a control here for each doc
                                if (
                                  documentPickerControls[
                                    documentPickerControls.length - 1
                                  ]
                                ) {
                                  let control =
                                    documentPickerControls[
                                      documentPickerControls.length - 1
                                    ];
                                  let document = {
                                    documentData: legacyAnswer.documentsList[x]
                                  };
                                  documentSelectedControlCallback({
                                    control,
                                    context: processAnswerOptionContext,
                                    document
                                  });
                                  addAdditionalDocumentControlForAnswerOption(
                                    processAnswerOptionContext,
                                    control !== null &&
                                      typeof control === "object"
                                      ? control.options
                                      : {}
                                  );
                                }
                              }
                            }
                            if (
                              legacyAnswer.document &&
                              legacyAnswer.document.file_id
                            ) {
                              if (documentPickerControls.length > 0) {
                                let control = documentPickerControls[0];
                                let document = {
                                  documentData: legacyAnswer.document
                                };
                                documentSelectedControlCallback({
                                  control,
                                  context: processAnswerOptionContext,
                                  document
                                });
                              }
                            }
                          }
                        }
                      }
                      if (!choiceFound) {
                        console.log(legacyQuestion.process_identifier);
                        console.log(legacyAnswer.response_choice_slug);
                        console.log(
                          `WARNING!!! Answer Option Not Found For Legacy Question ${legacyQuestion.process_identifier} for answer ${legacyAnswerSlug}`
                        );
                      }
                    }
                  } else {
                    // this must be an un-answered question
                    // so I think question_has_choices is a false flag
                  }
                }
              } else {
                if (legacyQuestion.has_answered) {
                  // we should already have a default selection;
                  let selectedAnswerOption =
                    process_answer?.co_process_answer_selections?.[0];
                  if (!selectedAnswerOption) {
                    throw new Error(
                      `WE SHOULD HAVE AN AUTO SELECTED ANSWER OPTION ${question.co_variable_name}`
                    );
                  } else {
                    // this was empty for the base idea?? // I think we need to default to 0 - not sure
                    // need to determine if this answer is rich text or not - they should theoretically match up already
                    let ans_value = legacyQuestion.answer_text || undefined;
                    if (
                      question.co_question_template_slug ===
                      StandardQuestionSlugs.RICH_TEXT
                    ) {
                      if (ans_value) {
                        if (!isDraftJS(ans_value)) {
                          ans_value = transformTextToDraftJS(ans_value);
                        }
                      }
                    }
                    selectedAnswerOption.co_question_answer_option_value = ans_value;
                    selectedAnswerOption.co_process_answer_selection_input = ans_value;
                  }
                } else {
                  if (logInfo) {
                    console.log("question not answered");
                    console.log(legacyQuestion.process_identifier);
                  }
                }
              }
            }
          }

          if (!foundLegacyQuestionMatchingQuestionInAssessment) {
            if (
              !DefaultQuestionsThatMayNotBeInLegacyQuestionionaires.includes(
                question_legacy_slug
              )
            ) {
              console.log(
                ` legacyQuestionsToNewAssessment - No Legacy Question Found for Assesment Question ${question_legacy_slug}!`
              );
            }
          }
        }
      }
    }
  }
};

export const legacyOverViewToAssessmentFromProcessInfo = (process, context) => {
  //console.log(topRowResults);
  let debugMigration = false;
  for (const section of context.assessment?.co_assessment_sections || []) {
    let sectionContext = context.update?.({ section });
    for (const question of section.co_questions || []) {
      // update question
      let answer = "";
      let variable_name = question.co_variable_name;
      if (variable_name) {
        let process_column = LegacyProcessColumnName(variable_name);
        if (process_column && process[process_column]) {
          answer = process[process_column];
        } else {
          // If it is not directly on the process object search through possible legacy questions
          if (Array.isArray(process?.questions) && process?.questions?.length) {
            // This was a submit employee idea draft
            const oldAssessmentSectionsArray = process.questions;
            const oldQuestions: any = [];
            oldAssessmentSectionsArray.forEach(sectionContainer => {
              sectionContainer?.questions?.forEach(q => {
                oldQuestions.push(q);
              });
            });
            legacyQuestionsToNewAssessment({
              context: sectionContext,
              legacyQuestions: oldQuestions,
              logInfo: false
            });
          }
        }
        if (debugMigration) {
          console.log(
            `process column - ${process_column} - answer ${answer} - ${context.assessment?.co_assessment_type}`
          );
        }
      }
      if (question.co_question_co_type === COTypes.QUESTION) {
        if (answer) {
          answerInputQuestion({
            context: sectionContext,
            question,
            answer,
            debugMigration
          });
        }
      } else if (question.co_question_co_type === COTypes.KPI) {
        if (answer) {
          answerKPI({
            context: sectionContext,
            question,
            answer,
            debugMigration
          });
        }
      }
    }
  }

  return context.assessment || {};
};

export const answerInputQuestion = ({
  context,
  question,
  answer,
  debugMigration = false
}: {
  context: COContext;
  question: COQuestion;
  answer: string;
  debugMigration?: boolean;
}) => {
  // ok so now we can look and see what type it is
  if (debugMigration) {
    console.log(
      `Legacy Migration - Answering ${question.objectDisplayName?.()} - ${answer} - ${
        context.assessment?.co_assessment_type
      }`
    );
  }

  let questionContext = context.update?.({ question });
  if (question && questionContext) {
    let process_answer = question.co_process_answer;
    if (!process_answer) {
      throw new Error(
        `We should have a process answer - did you call prepareForUpdateEdit? ${question.co_variable_name}`
      );
    }
    let processAnswerContext = questionContext.update?.({
      process_answer
    });
    // we should already have a default selection;
    let selectedAnswerOption =
      process_answer?.co_process_answer_selections?.[0];

    // only check here if it's a mutiple answer option situation
    if (
      selectedAnswerOption &&
      (question?.co_question_answer_options?.length || 0) > 1 &&
      answer
    ) {
      if (selectedAnswerOption.co_question_answer_option_value != answer) {
        selectedAnswerOption = undefined;
        if (debugMigration) {
          console.log(
            `DeSelecting Selected Answer Option for Question ${question.objectDisplayName?.()}  - ${answer} - ${
              context.assessment?.co_assessment_type
            }`
          );
        }
      }
    }
    if (!selectedAnswerOption) {
      let answerOptionFound = false;
      //console.log(question.co_question_answer_options);
      for (const answer_option of question.co_question_answer_options || []) {
        if (
          answer_option.co_question_answer_option_value == answer ||
          (answer_option.options?.legacy_slug &&
            answer &&
            answer == answer_option.options?.legacy_slug)
        ) {
          answerOptionFound = true;
          if (debugMigration) {
            console.log(
              `Answering ${question.objectDisplayName?.()} with answer option ${answer_option.objectDisplayName?.()}`
            );
          }
          answerQuestion({
            context,
            question,
            questionAnswerOption: answer_option
          });
          break;
        }
      }
      if (!answerOptionFound) {
        console.log(
          "No selected answer option - and no existing answers options match value "
        );
      }
    } else {
      // this was empty for the base idea?? // I think we need to default to 0 - not sure
      selectedAnswerOption.co_question_answer_option_value = answer;
      selectedAnswerOption.co_process_answer_selection_input = answer;
    }
    return processAnswerContext;
  }

  return context;
};
export const answerKPI = ({
  context,
  question,
  answer,
  debugMigration = false
}: {
  context: COContext;
  question: COQuestion;
  answer: string;
  debugMigration?: boolean;
}) => {
  // ok so now we can look and see what type it is
  if (debugMigration) {
    console.log(
      `Legacy Migration - Answering KPI ${question.objectDisplayName?.()} - ${answer} - ${
        context.assessment?.co_assessment_type
      }`
    );
  }

  //not gonna lie this feels kinda hacky - generally for stats we just update the calculated value, but for the first submission we need actually something.
  // so here we're going to edit the equation of the assessment question to be the answer, this won't save to the full assesssment (because all processes share the same assessment)
  // but it will save that value as the calculated value for this migration.
  if (
    question?.options?.external_answer_source === COExternalAnswerSources.STATS
  ) {
    //console.log(`updating stats question equation to be the answer ${answer}`);
    // don't really know the best way to update these - I can edit the equation
    question.co_equation = answer + "";
    question.co_display_equation = answer + "";
    // console.log(
    //   `updated stats question equation to be the answer ${question.co_equation}`
    // );
  }
  return context;
};
