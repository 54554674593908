const AH_ENVIRONMENT = {
  TEST: "test",
  SANDBOX: "sandbox",
  DEVELOPMENT: "development",
  ALPHA: "alpha",
  STAGING: "staging",
  PRODUCTION: "production"
};

const AH_ENVIRONMENT_TO_BRANCH = {
  test: "development",
  sandbox: "development",
  development: "development",
  alpha: "alpha",
  staging: "staging",
  production: "master"
};

// to support environment comparison
const AH_ENVIRONMENT_ORDER_NUMBER = {
  test: 0,
  sandbox: 1,
  development: 2,
  alpha: 3,
  staging: 4,
  production: 10
};

const AH_INFRA = {
  LOCALHOST_NODE: "localhost-node",
  DOCKER_AZURE_K8: "docker-azure-k8",
  DOCKER_AZURE_K8_ONPREM: "docker-azure-k8-onprem",
  DOCKER_SERVICE_FABRIC_K8_ONPREM: "docker-service-fabric-k8-onprem",
  LOCALHOST_NODE_ONPREM: "localhost-node-onprem"
};

const AH_SERVICES = {
  TENANT_SERVICE: "tenant-service",
  FRONTDOOR_SERVICE: "frontdoor-service",
  OPENAPI_SERVICE: "open-api-service"
};

const AH_SERVICE_NAMES = {
  "tenant-service": "Automation Hub Tenant Server",
  "frontdoor-service": "Automation Hub Frontdoor Server",
  "open-api-service": "Automation Hub OpenAPI Server"
};

const AH_SERVICE_DEFAULT_PORT = {
  "tenant-service": 3001,
  "frontdoor-service": 8080,
  "open-api-service": 3002
};

const FILEPATH_TO_AH_SERVICE = {
  "tenant-service": "tenant-service", // git
  tenantservice: "tenant-service", // docker
  "frontdoor-service": "frontdoor-service", // git
  frontdoorservice: "frontdoor-service", // docker
  "open-api-service": "open-api-service", // git
  openapiservice: "open-api-service" // docker
};

module.exports = {
  AH_ENVIRONMENT,
  AH_ENVIRONMENT_TO_BRANCH,
  AH_ENVIRONMENT_ORDER_NUMBER,
  AH_INFRA,
  AH_SERVICES,
  AH_SERVICE_NAMES,
  AH_SERVICE_DEFAULT_PORT,
  FILEPATH_TO_AH_SERVICE
};
