import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";

export function getReleaseHistory(componentSlug, setStateCallBack) {
  axios
    .get("/api/v1/components/slug/" + componentSlug + "/versions")
    .then(response => {
      setStateCallBack(response.data.data);
    })
    .catch(error => {
      apiErrorAlert(error);
    });
}
