import { COEmailReminderActionTemplateInterface } from "../../../../interfaces/co-interfaces";
import { COActionTypes } from "../../../../constants/actions/co-action-constants";
import { COEmailReminderActionSlugs } from "../../../../constants/actions/co-email-reminder-action-constants";
import { COEmailActionSlugs } from "../../../../constants/actions/co-email-action-constants";

export const emailReminderActionTemplate = (
  slug: string
): COEmailReminderActionTemplateInterface => {
  // get template based on slug
  let template = emailReminderActionTemplateFromSlug(slug);

  // add values that will be the same for each notification
  template = {
    co_action_template_slug: slug,
    co_action_template_co_type: COActionTypes.EMAIL_REMINDER,
    ...template
  };
  template.co_action_json = {
    co_action_type: COActionTypes.EMAIL_REMINDER,
    co_action_template_slug: slug,
    ...(template.co_action_json ?? {})
  };
  return template;
};

const emailReminderActionTemplateFromSlug = (
  slug: string
): COEmailReminderActionTemplateInterface => {
  switch (slug) {
    case COEmailReminderActionSlugs.SUBMITTED_IDEA_TO_REVIEW_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.SUBMITTED_IDEA_TO_REVIEW_EMAIL
        },
        co_meta_json: {
          title: {
            value: "Submitted idea to review - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.REVIEW_COE_DRIVEN_IDEA_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.REVIEW_COE_DRIVEN_IDEA_EMAIL
        },
        co_meta_json: {
          title: {
            value: "Review CoE driven idea - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.ASSESSMENT_LAUNCHED_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug: COEmailActionSlugs.ASSESMENT_LAUNCHED_EMAIL
        },
        co_meta_json: {
          title: {
            value: "Assessment launched - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.REVIEW_ASSESSED_AUTOMATION_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug: COEmailActionSlugs.ASSESSED_AUTOMATION_EMAIL
        },
        co_meta_json: {
          title: {
            value: "Review assessed automation - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.ASSIGN_PROJECT_MANAGER_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.ASSIGN_PROJECT_MANAGER_EMAIL
        },
        co_meta_json: {
          title: {
            value: "Assign Project Manager - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.CD_AUTOMATION_SHARED_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.CD_AUTOMATION_SHARED_EMAIL
        },
        co_meta_json: {
          title: {
            value: "CD automation shared - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL
        },
        co_meta_json: {
          title: {
            value: "CD automation moved to technical review - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.CD_AUTOMATION_REWORK_REQUIRED_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.CD_AUTOMATION_REWORK_REQUIRED_EMAIL
        },
        co_meta_json: {
          title: {
            value: "CD automation rework required - reminder email"
          }
        }
      };
    }
    case COEmailReminderActionSlugs.REVIEW_CHANGE_REQUEST_EMAIL_REMINDER: {
      return {
        co_options_json: {
          email_template_meta_slug:
            COEmailActionSlugs.CHANGE_REQUEST_SUBMITTED_EMAIL
        },
        co_meta_json: {
          title: {
            value: "Review change request - reminder email"
          }
        }
      };
    }
  }
  return {};
};
