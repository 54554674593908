import { LocalizationHashMapInterface } from "../../../interfaces/co-interfaces";

// Some of these rich text fields are hard coded to slugs here
// be careful creating new map these do not get replaces
export const CO_ACTIONS_LOCALIZATION_HASH_MAP: LocalizationHashMapInterface = {
  "-1246776693": {
    localizationKey:
      "notification_automation_published_for_live_consumption_message",
    fallbackValue:
      "{{RefUserFullName}} has published the automation {{AutomationStoreProcessName}} for large consumption in the Automation Store"
  },
  "14702206": {
    localizationKey: "notification_user_information_updated_title",
    fallbackValue: "User's information updated"
  },
  "17724881": {
    localizationKey: "notification_authorized_user_removed_from_user_title",
    fallbackValue: "Authorized User removed from user"
  },
  "38725416": {
    localizationKey: "notification_direct_manager_added_to_user_message",
    fallbackValue:
      "{{RefUserFullName}} added {{DirectManager}} as a Direct Manager for {{ReporteeFullName}}."
  },
  "40939637": {
    localizationKey: "notification_assign_project_manager_title",
    fallbackValue: "Assign Project manager"
  },
  "55548445": {
    localizationKey: "notification_review_approved_idea_message",
    fallbackValue:
      "The automation {{ProcessName}} was approved by {{RefUserFullName}}. It is your turn to take a look and launch the assessment for it."
  },
  "64516093": {
    localizationKey: "notification_coe_collaborator_added_to_user_title",
    fallbackValue: "CoE Collaborator added to user"
  },
  "76964293": {
    localizationKey: "notification_parent_idea_new_follower_title",
    fallbackValue: "New follower for the parent idea"
  },
  "78969377": {
    localizationKey: "notification_you_were_assigned_coe_collaborator_title",
    fallbackValue: "You were assigned CoE Collaborator"
  },
  "-1600822813": {
    localizationKey: "notification_component_comment_added_message",
    fallbackValue:
      "{{RefUserFullName}} added a comment in the component {{ComponentName}}: {{ComponentComment}}."
  },
  "144165095": {
    localizationKey: "notification_component_new_reply_title",
    fallbackValue: "Component new reply"
  },
  "155845573": {
    localizationKey: "notification_component_archived_title",
    fallbackValue: "Component archived"
  },
  "166822750": {
    localizationKey: "notification_cd_automation_to_technical_review_message",
    fallbackValue:
      "{{RefUserFullName}} has moved your automation - {{ProcessName}} to technical review."
  },
  "200407824": {
    localizationKey: "notification_assessment_launched_title",
    fallbackValue: "Assessment launched"
  },
  "236629032": {
    localizationKey:
      "notification_cd_automation_archived_business_review_message",
    fallbackValue:
      "{{RefUserFullName}} archived your automation {{ProcessName}}"
  },
  "333409710": {
    localizationKey: "notification_submitted_idea_to_review_message",
    fallbackValue:
      "The automation {{ProcessName}} submitted by {{ProcessSubmitter}} needs your review."
  },
  "336726507": {
    localizationKey: "notification_system_admin_removed_from_user_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{SystemAdmin}} as a System Admin."
  },
  "360606258": {
    localizationKey: "notification_program_manager_removed_from_user_title",
    fallbackValue: "Program Manager removed from user"
  },
  "375427858": {
    localizationKey: "notification_overview_section_info_changed_message",
    fallbackValue:
      "{{RefUserFullName}} updated the Overview information in the automation {{ProcessName}}."
  },
  "414312666": {
    localizationKey: "notification_template_document_deleted_title",
    fallbackValue: "Template document deleted"
  },
  "450398716": {
    localizationKey: "notification_component_comment_added_title",
    fallbackValue: "Component comment added"
  },
  "452357237": {
    localizationKey: "notification_authorized_user_removed_from_user_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{AuthorizedUser}} as an Authorized User."
  },
  "457003421": {
    localizationKey: "notification_you_were_assigned_coe_collaborator_message",
    fallbackValue: "{{RefUserFullName}} added you as a CoE Collaborator."
  },
  "465894262": {
    localizationKey: "notification_new_process_role_added_title",
    fallbackValue: "New role added"
  },
  "498113103": {
    localizationKey: "notification_review_approved_idea_title",
    fallbackValue: "Review approved idea in order to launch assessment"
  },
  "516477500": {
    localizationKey: "notification_you_were_assigned_authorized_user_title",
    fallbackValue: "You were assigned Authorized User"
  },
  "540011597": {
    localizationKey: "notification_system_admin_added_to_user_title",
    fallbackValue: "System Admin added to a user"
  },
  "551321489": {
    localizationKey: "notification_reusable_component_removed_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} removed the reusable component {{ComponentName}} from the automation {{ProcessName}}."
  },
  "590296224": {
    localizationKey: "notification_documentation_editing_rights_revoked_title",
    fallbackValue: "Editing right for Documentation page in AP revoked"
  },
  "708236801": {
    localizationKey: "notification_template_document_added_title",
    fallbackValue: "Template document added"
  },
  "709428174": {
    localizationKey: "notification_new_reply_added_in_ap_title",
    fallbackValue: "New reply to an existing comment"
  },
  "718305876": {
    localizationKey:
      "notification_automation_moved_to_assessment_not_started_title",
    fallbackValue: "Automation moved to 'Assessment' - 'Not started'"
  },
  "721154399": {
    localizationKey: "notification_system_admin_removed_from_user_title",
    fallbackValue: "System admin removed from user"
  },
  "730450635": {
    localizationKey: "notification_cd_automation_approved_message",
    fallbackValue:
      "{{RefUserFullName}} approved your automation - {{ProcessName}} as a submission."
  },
  "751408189": {
    localizationKey:
      "notification_you_were_removed_as_coe_collaborator_message",
    fallbackValue: "{{RefUserFullName}} removed you as a CoE Collaborator."
  },
  "783382270": {
    localizationKey: "notification_document_deleted_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} deleted the document {{ProcessDocumentTitle}} in the automation {{ProcessName}}."
  },
  "809575863": {
    localizationKey: "notification_process_role_enabled_title",
    fallbackValue: "Role enabled"
  },
  "838906105": {
    localizationKey: "notification_component_curation_started_title",
    fallbackValue: "Component curation started"
  },
  "867118592": {
    localizationKey: "notification_program_manager_added_to_user_title",
    fallbackValue: "Program Manager added to a user"
  },
  "903750302": {
    localizationKey: "notification_template_document_updated_message",
    fallbackValue:
      "{{RefUserFullName}} updated the template {{ProcessTemplateTitle}} in Platform setup - Documentation section."
  },
  "911338322": {
    localizationKey: "notification_reusable_component_removed_in_ap_title",
    fallbackValue: "Reusable component removed in AP"
  },
  "1415421235": {
    localizationKey: "notification_cd_automation_on_hold_message",
    fallbackValue:
      "{{RefUserFullName}} has updated the automation - {{ProcessName}} to on hold."
  },
  "941866650": {
    localizationKey: "notification_automation_archived_message",
    fallbackValue:
      "{{RefUserFullName}} updated the automation {{ProcessName}} to the phase {{ProcessPhase}} and status {{ProcessStatus}}, with the question / comment {{ProcessArchivedReason}}."
  },
  "1000053349": {
    localizationKey: "notification_component_followed_updated_message",
    fallbackValue:
      "There is an update for a component you have been following. You can now download the updated version of {{ComponentName}}."
  },
  "1006473019": {
    localizationKey: "notification_submitted_idea_approved_title",
    fallbackValue: "Submitted idea approved"
  },
  "1007561778": {
    localizationKey: "notification_user_information_updated_message",
    fallbackValue:
      "{{RefUserFullName}} updated the following information in your user profile: {{UpdatedUserAttributes}}"
  },
  "1008143970": {
    localizationKey: "notification_project_manager_role_assigned_message",
    fallbackValue:
      "{{RefUserFullName}} added {{CollaboratorFullName}} as a Project Manager in the automation {{ProcessName}}."
  },
  "1023996198": {
    localizationKey: "notification_assessment_score_generated_message",
    fallbackValue:
      "{{RefUserFullName}} marked the detailed assessment of the automation {{ProcessName}} as being complete."
  },
  "1124225035": {
    localizationKey: "notification_submitted_idea_marked_as_duplicate_message",
    fallbackValue:
      "{{RefUserFullName}} marked your idea {{ProcessName}} as being a duplicate"
  },
  "1125291132": {
    localizationKey: "notification_you_were_assigned_curator_message",
    fallbackValue: "{{RefUserFullName}} added you as a Curator."
  },
  "1130018930": {
    localizationKey: "notification_new_reusable_component_added_in_ap_title",
    fallbackValue: "New reusable component added in AP"
  },
  "1176195970": {
    localizationKey: "notification_automation_archived_title",
    fallbackValue: "Automation was archived"
  },
  "1203560777": {
    localizationKey:
      "notification_role_removed_for_existing_collaborator_title",
    fallbackValue: "Role removed for an existing collaborator"
  },
  "1207193982": {
    localizationKey: "notification_assessed_automation_rejected_message",
    fallbackValue:
      "{{RefUserFullName}} updated the automation {{ProcessName}} to the phase {{ProcessPhase}} and status {{ProcessStatus}}, with the question / comment {{ProcessRejectedReason}}."
  },
  "1213784711": {
    localizationKey: "notification_component_review_added_message",
    fallbackValue:
      "Your component {{ComponentName}} has received a new review by {{RefUserFullName}}."
  },
  "1239920374": {
    localizationKey: "notification_new_collaborator_added_message",
    fallbackValue:
      "{{RefUserFullName}} added {{CollaboratorFullName}} as a {{ProcessCollaboratorRoles}} in the automation {{ProcessName}}."
  },
  "1241076460": {
    localizationKey: "notification_queue_items_finished_message",
    fallbackValue: "The Queue Items have finished"
  },
  "1270298691": {
    localizationKey: "notification_assessment_score_generated_title",
    fallbackValue: "Assessment score generated"
  },
  "1278214641": {
    localizationKey: "notification_submitted_idea_approved_message",
    fallbackValue: "{{RefUserFullName}} approved your idea {{ProcessName}}."
  },
  "1290632505": {
    localizationKey: "notification_cd_atuomation_rejected_title",
    fallbackValue: "CD Automation rejeceted"
  },
  "1311246764": {
    localizationKey: "notification_review_assessed_automation_title",
    fallbackValue: "Review assessed automation"
  },
  "1352458951": {
    localizationKey: "notification_you_were_added_as_collaborator_message",
    fallbackValue:
      "{{RefUserFullName}} added you as a {{ProcessCollaboratorRoles}} in the automation {{ProcessName}}."
  },
  "1363255477": {
    localizationKey: "notification_ap_about_page_updated_message",
    fallbackValue:
      "{{RefUserFullName}} updated the About page in the automation {{ProcessName}}."
  },
  "1375153307": {
    localizationKey: "notification_component_review_added_title",
    fallbackValue: "Component review added"
  },
  "1380037174": {
    localizationKey: "notification_about_editing_rights_revoked_message",
    fallbackValue:
      "{{RefUserFullName}} revoked your editing rights for About section in the automation {{ProcessName}}."
  },
  "1406676192": {
    localizationKey: "notification_you_were_removed_as_authorized_user_title",
    fallbackValue: "You were removed as Authorized User"
  },
  "1416104089": {
    localizationKey: "notification_submitted_idea_to_review_title",
    fallbackValue: "Submitted idea to review"
  },
  "1419746625": {
    localizationKey: "notification_cd_automation_approved_title",
    fallbackValue: "CD Automation approved"
  },
  "1422966682": {
    localizationKey: "notification_assign_project_manager_message",
    fallbackValue:
      "The automation {{ProcessName}} was approved for implementation. You are required to assign a Project manager."
  },
  "1451402743": {
    localizationKey: "notification_component_archived_message",
    fallbackValue:
      "{{ComponentName}} component was archived. You can still view it in My Components - Uploads."
  },
  "1459787690": {
    localizationKey: "notification_process_owner_added_in_idea_message",
    fallbackValue:
      "{{RefUserFullName}} added {{ProcessOwner}} as a Process Owner in the automation {{ProcessName}}."
  },
  "1480968328": {
    localizationKey: "notification_direct_manager_removed_from_user_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{DirectManager}} as a Direct Manager for {{ReporteeFullName}}."
  },
  "1493113330": {
    localizationKey: "notification_documentation_editing_rights_granted_title",
    fallbackValue:
      "Editing rights for Documentation page granted to a collaborator"
  },
  "1495111851": {
    localizationKey: "notification_collaborator_removed_from_ap_title",
    fallbackValue: "Collaborator removed from AP"
  },
  "1509724006": {
    localizationKey:
      "notification_automation_published_for_live_consumption_title",
    fallbackValue:
      "Automation published for large consumption in the Automation Store"
  },
  "1523152436": {
    localizationKey: "notification_component_curation_needs_update_title",
    fallbackValue: "Component curation needs update"
  },
  "1526956278": {
    localizationKey: "notification_automation_has_updated_package_title",
    fallbackValue: "The package for automation has been updated"
  },
  "1556932704": {
    localizationKey: "notification_overview_section_info_changed_title",
    fallbackValue: "Change of info in Overview section"
  },
  "1587425866": {
    localizationKey: "notification_component_curation_downloaded_title",
    fallbackValue: "Component downloaded"
  },
  "1601281036": {
    localizationKey: "notification_you_were_assigned_authorized_user_message",
    fallbackValue: "{{RefUserFullName}} added you as an Authorized User."
  },
  "1650619491": {
    localizationKey: "notification_review_assessed_automation_message",
    fallbackValue:
      "The automation {{ProcessName}} was assessed. It is time to review it with the Automation Advisory Board."
  },
  "1663764726": {
    localizationKey: "notification_direct_manager_removed_from_user_title",
    fallbackValue: "Direct Manager removed from a user"
  },
  "1670127041": {
    localizationKey: "notification_you_were_removed_as_collaborator_message",
    fallbackValue:
      "{{RefUserFullName}} removed you from the automation {{ProcessName}}."
  },
  "1675787761": {
    localizationKey: "notification_you_were_removed_from_certain_role_title",
    fallbackValue:
      "You were removed as a certain role, but remain with other roles"
  },
  "1695195848": {
    localizationKey: "notification_assessed_automation_rejected_title",
    fallbackValue: "Assessed automation is rejected"
  },
  "1716706780": {
    localizationKey: "notification_you_were_removed_as_curator_message",
    fallbackValue: "{{RefUserFullName}} removed you as a Curator."
  },
  "1731315042": {
    localizationKey: "notification_cd_automation_approved_for_power_user_title",
    fallbackValue: "CD Automation approved for CD Power User work"
  },
  "1746330277": {
    localizationKey: "notification_abuse_reported_ap_message",
    fallbackValue:
      "{{RefUserFullName}} reported the abuse '{{ProcessAbuseTitle}}' in the automation {{ProcessName}}: '{{ProcessAbuseDescription}}'."
  },
  "1746652381": {
    localizationKey: "notification_you_were_assigned_program_manager_title",
    fallbackValue: "You were assigned Program Manager"
  },
  "1765673213": {
    localizationKey: "notification_process_role_updated_message",
    fallbackValue:
      "{{RefUserFullName}} updated the role {{ProcessRoleName}} in Manage Access - Roles section."
  },
  "1780966670": {
    localizationKey: "notification_coe_collaborator_removed_from_user_title",
    fallbackValue: "CoE Collaborator removed from user"
  },
  "1782988790": {
    localizationKey: "notification_submitted_idea_rejected_message",
    fallbackValue:
      "{{RefUserFullName}} rejected your idea {{ProcessName}}, with the following comment: '{{ProcessRejectedReason}}'."
  },
  "1796479240": {
    localizationKey: "notification_submitted_idea_followed_approved_title",
    fallbackValue: "Idea that I follow was approved by DM"
  },
  "1861081572": {
    localizationKey: "notification_application_inventory_updated_message",
    fallbackValue:
      "{{RefUserFullName}} updated the Application inventory in Platform setup - App inventory section."
  },
  "1868065948": {
    localizationKey: "notification_document_updated_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} updated the document {{ProcessDocumentTitle}} in the automation {{ProcessName}}."
  },
  "-659066980": {
    localizationKey: "notification_user_mentioned_in_question_in_as_message",
    fallbackValue:
      "{{RefUserFullName}} mentioned you in a question in the automation {{AutomationStoreProcessName}}: {{ProcessCommentText}}."
  },
  "1893422281": {
    localizationKey: "notification_document_updated_in_ap_title",
    fallbackValue: "Document was updated in AP"
  },
  "1895685804": {
    localizationKey: "notification_you_were_removed_as_authorized_user_message",
    fallbackValue: "{{RefUserFullName}} removed you as an Authorized User."
  },
  "1905325053": {
    localizationKey: "notification_you_were_removed_as_coe_collaborator_title",
    fallbackValue: "You were removed as CoE Collaborator"
  },
  "1908618477": {
    localizationKey: "notification_process_owner_added_in_idea_title",
    fallbackValue: "Process owner added in an idea"
  },
  "1915505572": {
    localizationKey: "notification_assessment_launched_message",
    fallbackValue:
      "{{RefUserFullName}} invited you to fill in the Advanced information and the applications used section in the automation {{ProcessName}}, where you were assigned as a Process Owner."
  },
  "2033676218": {
    localizationKey: "notification_component_followed_updated_title",
    fallbackValue: "Component I follow is updated"
  },
  "2036706820": {
    localizationKey: "notification_idea_followed_marked_as_duplicate_title",
    fallbackValue: "Idea that I follow was marked as duplicate by DM"
  },
  "2048797194": {
    localizationKey: "notification_component_curation_started_message",
    fallbackValue:
      "{{RefUserFullName}} updated the component {{ComponentName}} to the status In Review. Track the status in My Components - Uploads."
  },
  "2064263819": {
    localizationKey: "notification_component_curation_downloaded_message",
    fallbackValue:
      "We hope you enjoyed {{ComponentName}} and we would love to hear your opinions on it. Please take two minutes of your time to leave a review on it and tell us what you think."
  },
  "2086257061": {
    localizationKey: "notification_curator_added_to_user_title",
    fallbackValue: "Curator added to a user"
  },
  "2111352403": {
    localizationKey: "notification_curator_added_to_user_message",
    fallbackValue: "{{RefUserFullName}} added {{Curator}} as a Curator."
  },
  "-2081406352": {
    localizationKey: "notification_component_new_comment_message",
    fallbackValue:
      "{{RefUserFullName}} mentioned you in a comment in the component {{ComponentName}}: {{ComponentComment}}."
  },
  "2120849331": {
    localizationKey: "notification_curator_removed_from_user_message",
    fallbackValue: "{{RefUserFullName}} removed {{Curator}} as a Curator."
  },
  "2136083396": {
    localizationKey: "notification_assessed_automation_approved_message",
    fallbackValue:
      "{{RefUserFullName}} updated the automation {{ProcessName}} to the phase {{ProcessPhase}} and status {{ProcessStatus}}."
  },
  "-787268350": {
    localizationKey: "notification_submitted_idea_rejected_title",
    fallbackValue: "Submitted idea rejected"
  },
  "-781445745": {
    localizationKey: "notification_idea_followed_rejected_title",
    fallbackValue: "Idea that I follow was rejected by DM"
  },
  "-638195955": {
    localizationKey: "notification_submitted_idea_marked_as_duplicate_title",
    fallbackValue: "Submitted idea marked as duplicate"
  },
  "-752410224": {
    localizationKey: "notification_parent_idea_new_follower_message",
    fallbackValue:
      "{{RefUserFullName}} is now a follower of the automation {{ProcessName}}."
  },
  "-1933636564": {
    localizationKey: "notification_parent_idea_new_upvote_title",
    fallbackValue: "New upvote for the parent idea"
  },
  "-436146523": {
    localizationKey: "notification_parent_idea_new_upvote_message",
    fallbackValue: "{{RefUserFullName}} upvoted the automation {{ProcessName}}."
  },
  "-806030079": {
    localizationKey: "notification_assessed_automation_approved_title",
    fallbackValue: "Assessed automation is approved"
  },
  "-1555902523": {
    localizationKey: "notification_assessed_automation_put_on_hold_title",
    fallbackValue: "Assessed automation is put on hold"
  },
  "-15515864": {
    localizationKey: "notification_project_manager_assigned_title",
    fallbackValue: "Project manager assigned"
  },
  "-171395354": {
    localizationKey: "notification_project_manager_assigned_message",
    fallbackValue:
      "{{RefUserFullName}} assigned you as a Project Manager in the automation {{ProcessName}}. You can add other collaborators and update the phase and status of the automation."
  },
  "-705007915": {
    localizationKey: "notification_you_were_added_as_collaborator_title",
    fallbackValue: "You were added as a collaborator"
  },
  "-2114265338": {
    localizationKey: "notification_new_collaborator_added_title",
    fallbackValue: "New collaborator added"
  },
  "-1768384833": {
    localizationKey: "notification_about_editing_rights_granted_title",
    fallbackValue: "Editing rights for About page granted to a collaborator"
  },
  "-622875790": {
    localizationKey: "notification_about_editing_rights_granted_message",
    fallbackValue:
      "{{RefUserFullName}} granted you editing rights for the 'About' section in the automation {{ProcessName}}. You can update the information in that page."
  },
  "-1025837892": {
    localizationKey:
      "notification_documentation_editing_rights_granted_message",
    fallbackValue:
      "{{RefUserFullName}} granted you editing rights for the 'Documentation' section in the automation {{ProcessName}}. You can add documents in that page."
  },
  "-1209770571": {
    localizationKey: "notification_you_were_removed_as_collaborator_title",
    fallbackValue: "You were removed as a collaborator"
  },
  "-2125990736": {
    localizationKey: "notification_collaborator_removed_from_ap_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{CollaboratorFullName}} from the automation {{ProcessName}}."
  },
  "-1534445844": {
    localizationKey: "notification_you_were_removed_from_certain_role_message",
    fallbackValue:
      "{{RefUserFullName}} removed you as a {{ProcessCollaboratorRoles}} from the automation {{ProcessName}}."
  },
  "-936668645": {
    localizationKey:
      "notification_role_removed_for_existing_collaborator_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{CollaboratorFullName}} as a {{ProcessCollaboratorRoles}} from the automation {{ProcessName}}."
  },
  "-173467667": {
    localizationKey: "notification_about_editing_rights_revoked_title",
    fallbackValue: "Editing right for About page in AP revoked"
  },
  "-519924759": {
    localizationKey:
      "notification_documentation_editing_rights_revoked_message",
    fallbackValue:
      "{{RefUserFullName}} revoked your editing rights for Documentation section in the automation {{ProcessName}}."
  },
  "-11439879": {
    localizationKey: "notification_phase_status_changed_title",
    fallbackValue: "Change of phase and status"
  },
  "-467645327": {
    localizationKey: "notification_ap_about_page_updated_title",
    fallbackValue: "An update was performed on the AP - About page"
  },
  "-311200507": {
    localizationKey: "notification_new_document_added_in_ap_title",
    fallbackValue: "New document added in AP"
  },
  "-796853257": {
    localizationKey: "notification_new_document_added_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} added the document {{ProcessDocumentTitle}} in the automation {{ProcessName}}."
  },
  "-1604823010": {
    localizationKey: "notification_document_deleted_in_ap_title",
    fallbackValue: "Document deleted in AP"
  },
  "-454545112": {
    localizationKey: "notification_new_follower_in_ap_title",
    fallbackValue: "New follower in AP"
  },
  "-1246491697": {
    localizationKey: "notification_new_upvote_in_ap_title",
    fallbackValue: "New upvote in AP"
  },
  "-1062215138": {
    localizationKey: "notification_new_comment_added_in_ap_title",
    fallbackValue: "New question / comment added in AP"
  },
  "-2025090891": {
    localizationKey: "notification_new_comment_added_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} added a question / comment in the automation {{ProcessName}}: {{ProcessCommentText}}."
  },
  "-816051539": {
    localizationKey: "notification_new_reply_added_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} added a reply in the automation {{ProcessName}}: {{ProcessCommentText}}."
  },
  "-1590336542": {
    localizationKey: "notification_user_mentioned_in_comment_in_ap_title",
    fallbackValue: "User mentioned in a question / comment"
  },
  "931202498": {
    localizationKey: "notification_user_mentioned_in_comment_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} mentioned you in a question / comment in the automation {{ProcessName}}: {{ProcessCommentText}}."
  },
  "-562498035": {
    localizationKey: "notification_abuse_reported_ap_title",
    fallbackValue: "Abuse reported"
  },
  "-1527996251": {
    localizationKey: "notification_direct_manager_added_to_user_title",
    fallbackValue: "Direct Manager added to a user"
  },
  "-393717456": {
    localizationKey: "notification_new_process_role_added_message",
    fallbackValue:
      "{{RefUserFullName}} created the role {{ProcessRoleName}} in Manage Access - Roles section."
  },
  "-2110227663": {
    localizationKey: "notification_process_role_updated_title",
    fallbackValue: "Role updated"
  },
  "-2044203322": {
    localizationKey: "notification_process_role_disabled_title",
    fallbackValue: "Role disabled"
  },
  "-493844532": {
    localizationKey: "notification_process_role_disabled_message",
    fallbackValue:
      "{{RefUserFullName}} disabled the role {{ProcessRoleName}} in Manage Access - Roles section."
  },
  "-1383062089": {
    localizationKey: "notification_process_role_enabled_message",
    fallbackValue:
      "{{RefUserFullName}} enabled the role {{ProcessRoleName}} in Manage Access - Roles section."
  },
  "-325334385": {
    localizationKey: "notification_process_role_deleted_title",
    fallbackValue: "Role deleted"
  },
  "-1773386273": {
    localizationKey: "notification_process_role_deleted_message",
    fallbackValue:
      "{{RefUserFullName}} deleted the role {{ProcessRoleName}} in Manage Access - Roles section."
  },
  "-581228807": {
    localizationKey: "notification_template_document_added_message",
    fallbackValue:
      "{{RefUserFullName}} added the template {{ProcessTemplateTitle}} in Platform setup - Documentation section."
  },
  "-1370580612": {
    localizationKey: "notification_template_document_updated_title",
    fallbackValue: "Template document updated"
  },
  "-1961905424": {
    localizationKey: "notification_template_document_set_as_default_title",
    fallbackValue: "Template document set as default"
  },
  "-1495725576": {
    localizationKey: "notification_template_document_set_as_default_message",
    fallbackValue:
      "{{RefUserFullName}} set as default the template {{ProcessTemplateTitle}} in Platform setup - Documentation section."
  },
  "-88102144": {
    localizationKey: "notification_template_document_deleted_message",
    fallbackValue:
      "{{RefUserFullName}} deleted the template {{ProcessTemplateTitle}} in Platform setup - Documentation section."
  },
  "-8729196": {
    localizationKey: "notification_new_support_task_added_title",
    fallbackValue: "New support task is added"
  },
  "-854872434": {
    localizationKey: "notification_new_support_task_added_message",
    fallbackValue:
      "{{RefUserFullName}} has requested support for your component {{ComponentName}}. Have a look at the request and reply here."
  },
  "-1057399905": {
    localizationKey: "notification_new_report_abuse_task_added_title",
    fallbackValue: "New report abuse task is added"
  },
  "-1867252385": {
    localizationKey: "notification_new_report_abuse_task_added_message",
    fallbackValue:
      "{{RefUserFullName}} has reported an abuse for the component {{ComponentName}}. Have a look at the request and reply here."
  },
  "-1381881570": {
    localizationKey: "notification_project_manager_role_assigned_title",
    fallbackValue: "Project manager role assigned"
  },
  "-172758548": {
    localizationKey: "notification_new_reusable_component_added_in_ap_message",
    fallbackValue:
      "{{RefUserFullName}} added the reusable component {{ComponentName}} in the automation {{ProcessName}}."
  },
  "-14522549": {
    localizationKey: "notification_system_admin_added_to_user_message",
    fallbackValue:
      "{{RefUserFullName}} added {{SystemAdmin}} as a System Admin."
  },
  "-256441678": {
    localizationKey: "notification_assigned_system_admin_title",
    fallbackValue: "You were assigned System admin"
  },
  "-1427210420": {
    localizationKey: "notification_assigned_system_admin_message",
    fallbackValue: "{{RefUserFullName}} added you as a System Admin."
  },
  "-1431031589": {
    localizationKey: "notification_program_manager_added_to_user_message",
    fallbackValue:
      "{{RefUserFullName}} added {{ProgramManager}} as a Program Manager."
  },
  "-775464069": {
    localizationKey: "notification_program_manager_removed_from_user_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{ProgramManager}} as a Program Manager."
  },
  "-573396841": {
    localizationKey: "notification_curator_removed_from_user_title",
    fallbackValue: "Curator removed from user"
  },
  "-1467175361": {
    localizationKey: "notification_process_hierarchy_updated_title",
    fallbackValue: "Process hierarchy updated"
  },
  "-263836460": {
    localizationKey: "notification_process_hierarchy_updated_message",
    fallbackValue:
      "{{RefUserFullName}} updated the Automation Hierarchy in Platform setup - Hierarchy section."
  },
  "-633962745": {
    localizationKey: "notification_application_inventory_updated_title",
    fallbackValue: "Application inventory updated"
  },
  "-1059841663": {
    localizationKey: "notification_you_were_assigned_program_manager_message",
    fallbackValue: "{{RefUserFullName}} added you as a Program Manager."
  },
  "-1969088894": {
    localizationKey: "notification_you_were_assigned_curator_title",
    fallbackValue: "You were assigned Curator"
  },
  "-1099806066": {
    localizationKey: "notification_you_were_removed_as_system_admin_title",
    fallbackValue: "You were removed as System admin"
  },
  "-985329172": {
    localizationKey: "notification_you_were_removed_as_system_admin_message",
    fallbackValue: "{{RefUserFullName}} removed you as a System Admin."
  },
  "-1658116223": {
    localizationKey: "notification_you_were_removed_as_program_manager_title",
    fallbackValue: "You were removed as Program Manager"
  },
  "-1050344735": {
    localizationKey: "notification_you_were_removed_as_program_manager_message",
    fallbackValue: "{{RefUserFullName}} removed you as a Program Manager."
  },
  "-419932890": {
    localizationKey: "notification_you_were_removed_as_curator_title",
    fallbackValue: "You were removed as Curator"
  },
  "-1861209879": {
    localizationKey: "notification_component_uploaded_for_curation_title",
    fallbackValue: "Component uploaded for curation"
  },
  "-1818801939": {
    localizationKey: "notification_component_uploaded_for_curation_message",
    fallbackValue:
      "There is a new request to review content. Click on {{ComponentName}} to review this component."
  },
  "-1123544025": {
    localizationKey: "notification_component_curation_rejected_title",
    fallbackValue: "Component curation reject"
  },
  "-486338789": {
    localizationKey: "notification_component_curation_rejected_message",
    fallbackValue:
      "{{RefUserFullName}} rejected your component {{ComponentName}}, with the following comment: '{{ComponentRejectReason}}'"
  },
  "-1307102311": {
    localizationKey: "notification_component_curation_needs_update_message",
    fallbackValue:
      "{{RefUserFullName}} updated the component {{ComponentName}} to the status Needs Update. The component is now back in Draft status and you can find it in My Components > Uploads."
  },
  "-1794241145": {
    localizationKey: "notification_component_curation_published_title",
    fallbackValue: "Component curation publish"
  },
  "-830184646": {
    localizationKey: "notification_component_curation_published_message",
    fallbackValue:
      "Your component {{ComponentName}} has been published and can now be downloaded by all company users."
  },
  "1607945454": {
    localizationKey: "notification_component_new_reply_message",
    fallbackValue:
      "{{RefUserFullName}} added a reply in the component {{ComponentName}}: {{ComponentComment}}."
  },
  "-887858624": {
    localizationKey: "notification_authorized_user_added_to_user_title",
    fallbackValue: "Authorized User added to user"
  },
  "-124808231": {
    localizationKey: "notification_authorized_user_added_to_user_message",
    fallbackValue:
      "{{RefUserFullName}} added {{AuthorizedUser}} as a Authorized User."
  },
  "-234229443": {
    localizationKey: "notification_coe_collaborator_added_to_user_message",
    fallbackValue:
      "{{RefUserFullName}} added {{CoeCollaborator}} as a CoE Collaborator."
  },
  "-597017603": {
    localizationKey: "notification_coe_collaborator_removed_from_user_message",
    fallbackValue:
      "{{RefUserFullName}} removed {{CoeCollaborator}} as a Coe Collaborator."
  },
  "-1108810939": {
    localizationKey: "notification_user_sync_finished_title",
    fallbackValue: "User sync is completed"
  },
  "-1102327649": {
    localizationKey: "notification_user_sync_finished_message",
    fallbackValue:
      "The import of users and the mass changes from the Assign Roles page are now completed."
  },
  "-1739467850": {
    localizationKey: "notification_cd_automation_to_technical_review_title",
    fallbackValue: "CD Automation to technical review"
  },
  "-545490282": {
    localizationKey: "notification_cd_automation_on_hold_title",
    fallbackValue: "CD Automation on hold"
  },
  "-1246489642": {
    localizationKey: "notification_cd_atuomation_rejected_message",
    fallbackValue:
      "{{RefUserFullName}} rejected your automation {{ProcessName}}, with the following comment: '{{ProcessRejectedReason}}'."
  },
  "-944177691": {
    localizationKey:
      "notification_cd_automation_approved_for_consumption_title",
    fallbackValue: "CD Automation approved for consumption"
  },
  "-1341312562": {
    localizationKey:
      "notification_cd_automation_approved_for_consumption_message",
    fallbackValue:
      "{{RefUserFullName}} approved the automation {{ProcessName}} for large consumption."
  },
  "-764625231": {
    localizationKey: "notification_cd_automation_rework_required_title",
    fallbackValue: "CD Automation rework required"
  },
  "-1884532077": {
    localizationKey: "notification_cd_automation_rework_required_message",
    fallbackValue:
      "{{RefUserFullName}} marked your automation {{ProcessName}} as rework required."
  },
  "-902339343": {
    localizationKey:
      "notification_cd_automation_archived_business_review_title",
    fallbackValue: "CD Automation archived in Business Review"
  },
  "-97043218": {
    localizationKey:
      "notification_cd_automation_approved_for_power_user_message",
    fallbackValue:
      "A new automation has been approved for Citizen Developer Power User Work: {{ProcessName}}. Check the Automation Pipeline - Implementation tab and start the development."
  },
  "-1160646535": {
    localizationKey:
      "notification_cd_automation_archived_technical_review_title",
    fallbackValue: "CD Automation was archived in Technical Review"
  },
  "-1868788844": {
    localizationKey: "notification_automation_has_updated_package_message",
    fallbackValue:
      "The package for automation {{AutomationStoreProcessName}} has been updated! Get the new version {{AutomationStoreProcessNameHere}}"
  },
  "-86229612": {
    localizationKey: "notification_user_mentioned_in_question_in_as_title",
    fallbackValue: "User mentioned in a question"
  },
  "-107962335": {
    localizationKey: "notification_queue_items_finished_title",
    fallbackValue: "Queue Items Finished"
  },
  "115516061": {
    localizationKey: "cd_automation_rework_required_task_message",
    fallbackValue: "Review an automation with the Automation Advisory Board"
  },
  "228008593": {
    localizationKey: "cd_automation_rework_required_task_title",
    fallbackValue: "CD automation rework required"
  },
  "250566353": {
    localizationKey: "cd_automation_to_technical_review_task_message",
    fallbackValue: "Review a new automation moved to technical review"
  },
  "337338389": {
    localizationKey: "ap_edit_documentation_rights_granted_task_message",
    fallbackValue: "Edit the Documentation section in an automation"
  },
  "444823806": {
    localizationKey: "ap_project_manager_role_assigned_task_title",
    fallbackValue: "Project Manager role assigned"
  },
  "596696724": {
    localizationKey: "review_coe_driven_idea_task_message",
    fallbackValue: "Review a new automation idea submitted"
  },
  "626403259": {
    localizationKey: "go_abuse_reported_task_message",
    fallbackValue: "Review an abuse"
  },
  "679383757": {
    localizationKey: "cd_automation_rejected_task_message",
    fallbackValue: "Resubmit your idea following the feedback received"
  },
  "770785773": {
    localizationKey: "go_curation_in_progress_task_message",
    fallbackValue: "Component Curation - in progress"
  },
  "831869014": {
    localizationKey: "ap_edit_about_rights_granted_task_title",
    fallbackValue: "Edit About section rights granted"
  },
  "845356051": {
    localizationKey: "publish_automation_to_automation_store_task_message",
    fallbackValue: "Publish a new automation to the Automation Store"
  },
  "927794657": {
    localizationKey: "ap_edit_cba_rights_granted_task_title",
    fallbackValue: "Edit CBA section rights granted"
  },
  "933028543": {
    localizationKey: "ap_comment_abuse_reported_task_title",
    fallbackValue: "Process comment abuse reported"
  },
  "1068213305": {
    localizationKey: "cd_automation_shared_task_message",
    fallbackValue: "Review a new automation submitted"
  },
  "1353515045": {
    localizationKey: "ap_new_component_created_task_title",
    fallbackValue: "New component created"
  },
  "1643110115": {
    localizationKey: "ap_new_component_created_task_message",
    fallbackValue: "Create a reusable component."
  },
  "1705592917": {
    localizationKey: "assign_project_manager_task_title",
    fallbackValue: "Assign Project Manager"
  },
  "1732446232": {
    localizationKey: "review_approved_idea_task_title",
    fallbackValue: "Review approved idea"
  },
  "1794092589": {
    localizationKey: "ap_edit_cba_rights_granted_task_message",
    fallbackValue: "Edit the CBA section in an automation"
  },
  "1858272193": {
    localizationKey: "review_change_request_task_message",
    fallbackValue: "Review a new change request submitted"
  },
  "1948727233": {
    localizationKey: "ap_edit_component_rights_granted_task_title",
    fallbackValue: "Edit Components section rights granted"
  },
  "2143774083": {
    localizationKey: "ap_edit_component_rights_granted_task_message",
    fallbackValue: "Add reusable components in an automation"
  },
  "-1142535564": {
    localizationKey: "review_approved_idea_task_message",
    fallbackValue:
      "Launch the assessment for an idea approved by the Direct Manager"
  },
  "-944101096": {
    localizationKey: "assesment_launched_task_message",
    fallbackValue: "Fill in the Detailed Assessment for an automation"
  },
  "-135702026": {
    localizationKey: "review_assessed_automation_task_message",
    fallbackValue:
      "Review an assessed automation with the Automation Advisory Board"
  },
  "-356737701": {
    localizationKey: "assign_project_manager_task_message",
    fallbackValue:
      "Assign a Project Manager for an automation approved for implementation"
  },
  "-1896294336": {
    localizationKey: "ap_project_manager_role_assigned_task_message",
    fallbackValue:
      "As a Project manager in an automation, bring other collaborators on board"
  },
  "-1807296606": {
    localizationKey: "ap_edit_about_rights_granted_task_message",
    fallbackValue: "Edit the About section in an automation"
  },
  "-359720823": {
    localizationKey: "ap_edit_documentation_rights_granted_task_title",
    fallbackValue: "Edit Documentation section rights granted"
  },
  "-1930041065": {
    localizationKey: "go_curation_needs_update_task_title",
    fallbackValue: "Curation needs update"
  },
  "-1948840665": {
    localizationKey: "go_curation_needs_update_task_message",
    fallbackValue: "Component Curation - needs update"
  },
  "-2138101763": {
    localizationKey: "go_curation_in_progress_task_title",
    fallbackValue: "Curation in progress"
  },
  "-1170772485": {
    localizationKey: "go_support_request_task_title",
    fallbackValue: "Component support request"
  },
  "-673151202": {
    localizationKey: "go_support_request_task_message",
    fallbackValue: "Support request"
  },
  "-1721055925": {
    localizationKey: "ap_comment_abuse_reported_task_message",
    fallbackValue: "Review an abuse in a Automation comment"
  },
  "-1451443022": {
    localizationKey: "go_review_abuse_reported_task_title",
    fallbackValue: "Component review abuse reported"
  },
  "-28328464": {
    localizationKey: "go_review_abuse_reported_task_message",
    fallbackValue: "Review an abuse in a component review"
  },
  "-1951933327": {
    localizationKey: "go_comment_abuse_reported_task_title",
    fallbackValue: "Component comment abuse reported"
  },
  "-1027736889": {
    localizationKey: "go_comment_abuse_reported_task_message",
    fallbackValue: "Review an abuse in a component comment"
  },
  "-1632404137": {
    localizationKey: "cd_automation_requested_task_message",
    fallbackValue:
      "Make the automation available by the user who requested it: {{RefUserFullName}}"
  },
  "-1489001036": {
    localizationKey: "publish_automation_to_automation_store_task_title",
    fallbackValue: "Publish automation to the Automation Store"
  },
  "-368314425": {
    localizationKey: "review_change_request_task_title",
    fallbackValue: "Review change request"
  },
  "-1935540264": {
    localizationKey: "notification_component_new_comment_title",
    fallbackValue: "Mentioned in component comment"
  },
  "1858245068": {
    localizationKey: "resubmit_idea_task_title",
    fallbackValue: "Resubmit idea"
  },
  "2053848976": {
    localizationKey: "go_abuse_reported_task_title",
    fallbackValue: "Component abuse reported"
  },
  "1626039396": {
    localizationKey: "review_coe_driven_idea_task_title",
    fallbackValue: "Review CoE driven idea"
  },
  "-1767195249": {
    localizationKey: "cd_automation_shared_task_title",
    fallbackValue: "CD automation shared"
  },
  "71422505": {
    localizationKey: "cd_automation_to_technical_review_task_title",
    fallbackValue: "CD automation moved to technical review"
  },
  "-1627290840": {
    localizationKey: "cd_automation_rejected_task_title",
    fallbackValue: "CD automation rejected"
  },
  "-823919100": {
    localizationKey: "cd_automation_requested_task_title",
    fallbackValue: "CD automation requested"
  }
};
