import moment from "moment";
import SessionHelper from "./SessionHelper";
import { featureFlag } from "./FeatureFlag";
import { JA } from "../constants/localizationConstants";
import { isValidNumber } from "../utils/numberHelpers";
import { TFunction } from "react-i18next";

export const getMonthIndex = monthName => {
  const monthMap = new Map([
    ["January", 0],
    ["February", 1],
    ["March", 2],
    ["April", 3],
    ["May", 4],
    ["June", 5],
    ["July", 6],
    ["August", 7],
    ["September", 8],
    ["October", 9],
    ["November", 10],
    ["December", 11]
  ]);

  return monthMap.has(monthName) ? monthMap.get(monthName) : null;
};

export const showDateWithTenantDateFormat = (
  epoch,
  backupFormat = "MM/DD/YYYY"
) => {
  const language = SessionHelper.getLanguage();

  if (featureFlag.isTenantSelectedDateFormatEnabled() && language != JA) {
    backupFormat = SessionHelper.getDateFormat() || backupFormat;
  }

  return moment(+epoch)
    .locale(language)
    .format(backupFormat);
};

export const getDateFormat = (backupFormat, isDatePicker = false) => {
  const language = SessionHelper.getLanguage();
  backupFormat = backupFormat || "MM/dd/yyyy";
  if (featureFlag.isTenantSelectedDateFormatEnabled() && language != JA) {
    backupFormat = SessionHelper.getDateFormat();
  }

  //date picker has a different date format than moment js
  if (isDatePicker) {
    backupFormat = backupFormat.replace("DD", "dd").replace("YYYY", "yyyy");
  }

  return backupFormat || "MM/dd/yyyy";
};

/* Used to convert a date to date picker */
export const convertDateForDatePicker = originalDate => {
  if (typeof originalDate === "string") {
    const date = originalDate.split("/");
    return date.length > 2
      ? new Date(parseInt(date[2]), parseInt(date[0]) - 1, parseInt(date[1]))
      : null;
  }
  return null;
};

/* Used to convert an epoch for date picker */
export const epochToDate = epoch => {
  if (!epoch) {
    return null;
  }

  const date = new Date(0);
  date.setUTCMilliseconds(epoch);
  return date;
};

/* Used to convert an input like 12/03/2020 for date picker */
export const convertInputtedDateForDatePicker = (
  userInputtedDate,
  backupDate = null
) => {
  if (typeof userInputtedDate === "object") {
    // it's already formatted
    return userInputtedDate;
  }

  const dateArr = userInputtedDate ? userInputtedDate.split("/") : [];
  if (!Array.isArray(dateArr) || dateArr.length !== 3) {
    return backupDate;
  }

  const month = dateArr[0].trim();
  const day = dateArr[1].trim();
  const year = dateArr[2].trim();

  if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
    return backupDate;
  }

  const parsedStr = `${month}/${day}/${year}`;
  return convertDateForDatePicker(parsedStr);
};

/* To be called when you're manipulating value from onchange function in date picker*/
export const getTimeStampAndEpochFromDatePickerVal = date => {
  if (!date) {
    return null;
  }

  // we want the epoch to be of the timestamp so that they match in the DB
  let timestamp = `${date.getMonth() +
    1}/${date.getDate()}/${date.getFullYear()}`;
  const dateTimeStamp = convertDateForDatePicker(timestamp);
  const epoch = moment(dateTimeStamp).valueOf();

  return {
    epoch: epoch,
    timestamp: dateTimeStamp
  };
};

// specificially used for launch date right now to convert timestamp to yyyy-MM-dd format
export const convertTimeStampToNonEpochDate = timeStamp => {
  if (!timeStamp) {
    return null;
  }

  const date = new Date(timeStamp);
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

export const convertFromEpochToDateText = (
  epoch: string | number | null | undefined,
  translationFunction?: TFunction
): string | null => {
  const localizedDateFormat =
    typeof translationFunction === "function"
      ? translationFunction(["general_data_format_slash", "L"])
      : "L";

  let epochVal = 0;

  if (isValidNumber(epoch)) {
    epochVal = typeof epoch === "number" ? epoch : parseInt(epoch as string);
  }

  return epochVal > 0
    ? showDateWithTenantDateFormat(epochVal, localizedDateFormat)
    : null;
};
