import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import InputCustomChildWithLabel from "./../../../components/inputs/InputCustomChildWithLabel/InputCustomChildWithLabel";
import LoaderLogoSmall from "./../../../components/LoaderLogoSmall/LoaderLogoSmall";
import SingleReleaseNoteCard from "./../../../components/SingleReleaseNoteCard/SingleReleaseNoteCard";
import { getReleaseHistory } from "./../../../actions/ComponentReleaseHistoryActions";
import "./UploadEditComponentVersionContainer.scss";
import { InputLabel } from "@material-ui/core";
import ButtonApolloSecondary from "../../../components/buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import ComponentVersionBadge from "../../../components/ComponentVersionBadge/ComponentVersionBadge";

class UploadEditComponentVersionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullReleaseNotes: [],
      componentSlug: "",
      showFullReleaseBtn: true,
      showFullReleaseLoader: false
    };
  }

  getFullReleaseNotes = () => {
    this.setState(prevState => {
      const state = { ...prevState };
      state.showFullReleaseLoader = true;
      return state;
    });

    getReleaseHistory(this.props.slug, response => {
      this.setState(prevState => {
        const state = { ...prevState };
        state.fullReleaseNotes = response.versions;
        state.showFullReleaseBtn = false;
        state.showFullReleaseLoader = false;
        return state;
      });
    });
  };

  focusOnTextArea = () => {
    this.textAreaInput.focus();
  };
  render() {
    const {
      readOnly,
      alignRight,
      handleVersionNameChange,
      version_name,
      handleVersionReleaseNotes,
      release_notes,
      component_version_release_notes
    } = this.props;

    const buttonStyles = {
      width: "100%"
    };

    const readOnlyClass = " UploadEditComponentVersionContainer__read-only";
    const alignRightClass = alignRight
      ? " UploadEditComponentVersionContainer__align-right"
      : "";

    return (
      <div
        className={"UploadEditComponentVersionContainer" + alignRightClass}
        data-testid="UploadEditComponentVersionContainer"
      >
        {readOnly ? (
          <div className="UploadEditComponentVersionContainer__version-title">
            {this.props.t([
              "upload_component_version_container_version_text",
              "Version"
            ])}
          </div>
        ) : (
          <>
            <InputLabel required error={this.props.versionError}>
              {this.props.t([
                "upload_component_version_container_version_text",
                "Version"
              ])}
            </InputLabel>
            <InputCustomChildWithLabel
              label=""
              error={this.props.versionError}
            />
          </>
        )}
        <div className="UploadEditComponentVersionContainer__gray-container">
          <div className="UploadEditComponentVersionContainer__version-input-container">
            {readOnly ? (
              <input
                data-testid="UploadEditComponentVersionContainer__VersionNameInput"
                className={
                  "UploadEditComponentVersionContainer__version-name-input" +
                  readOnlyClass
                }
                readOnly={true}
                type="text"
                value={`${this.props.t(
                  "upload_component_version_container_version_text"
                )} ${version_name || ""}`}
              />
            ) : (
              <input
                data-testid="UploadEditComponentVersionContainer__VersionNameInput"
                className={
                  "UploadEditComponentVersionContainer__version-name-input"
                }
                placeholder={this.props.t(
                  "upload_component_version_container_new_vesion"
                )}
                onChange={handleVersionNameChange}
                defaultValue={
                  this.props.preloadVersionData.component_version_name
                }
              />
            )}
            <div className="UploadEditComponentVersionContainer__badge-wrapper">
              <ComponentVersionBadge
                className="UploadEditComponentVersionContainer__badge"
                componentRejectReason={this.props.component_reject_reason}
                componentStatusId={this.props.componentStatusId}
                readOnly={readOnly}
              />
            </div>
          </div>

          <div className="UploadEditComponentVersionContainer__separator" />
          {!readOnly &&
            !component_version_release_notes &&
            !release_notes &&
            !this.props.preloadVersionData.component_version_release_notes && (
              <div
                className="UploadEditComponentVersionContainer__version-notes-placeholders"
                onClick={this.focusOnTextArea}
              >
                {this.props.t("upload_component_version_container_new_label")}{" "}
                <br />
                {this.props.t(
                  "upload_component_version_container_fixed_label"
                )}{" "}
                <br />
                {this.props.t(
                  "upload_component_version_container_changed_fixed"
                )}{" "}
                <br />
              </div>
            )}

          {readOnly ? (
            <textarea
              className={
                "UploadEditComponentVersionContainer__version-notes-input" +
                readOnlyClass
              }
              data-testid="UploadEditComponentVersionContainer__VersionNotesInput"
              readOnly={readOnly}
              value={
                release_notes && release_notes.length > 0
                  ? release_notes
                  : this.props.t(
                      "upload_component_version_container_no_release_note"
                    )
              }
            />
          ) : (
            <textarea
              placeholder={this.props.t(
                "upload_component_version_container_add_release_note"
              )}
              ref={input => {
                this.textAreaInput = input;
              }}
              className={
                "UploadEditComponentVersionContainer__version-notes-input"
              }
              data-testid="UploadEditComponentVersionContainer__VersionNotesInput"
              onChange={handleVersionReleaseNotes}
              defaultValue={
                this.props.preloadVersionData.component_version_release_notes
              }
            />
          )}
        </div>

        {this.state.fullReleaseNotes.length === 0 &&
          this.state.showFullReleaseLoader && <LoaderLogoSmall />}
        {this.state.fullReleaseNotes &&
          this.state.fullReleaseNotes.length > 0 && (
            <div className="UploadEditComponentVersionContainer__version-title">
              {this.props.t(
                "upload_component_version_container_full_version_history"
              )}
            </div>
          )}
        {this.state.fullReleaseNotes.map((versionHistoryObj, index) => {
          return (
            <SingleReleaseNoteCard
              versionHistory={versionHistoryObj}
              key={"version-history-" + index}
              index={index}
            />
          );
        })}

        {this.state.showFullReleaseBtn && this.props.showReleaseNoteBtn && (
          <ButtonApolloSecondary
            style={buttonStyles}
            onClick={this.getFullReleaseNotes}
          >
            {this.props.t([
              "upload_component_version_container_full_version_history",
              "Full version history"
            ])}
          </ButtonApolloSecondary>
        )}
      </div>
    );
  }
}
export default withTranslation("common")(UploadEditComponentVersionContainer);
