import { useEffect, useRef } from "react";

const useResizeObserver = ({
  callback,
  elementRef
}: {
  callback: (ar?: any) => void;
  elementRef?: null | any;
}) => {
  const observer: any = useRef(null);
  // @ts-ignore
  const hasObserver = !!window?.ResizeObserver;

  useEffect(() => {
    const currentElement = elementRef?.current;
    if (hasObserver && typeof callback === "function") {
      // if we are already observing old element
      if (observer && observer.current && currentElement && hasObserver) {
        observer.current.unobserve(currentElement);
      }

      // @ts-ignore
      observer.current = new ResizeObserver(callback);
      if (elementRef && elementRef.current && observer.current && hasObserver) {
        observer.current.observe(elementRef.current);
      }
    }

    return () => {
      if (observer && observer.current && elementRef && currentElement) {
        observer.current.unobserve?.(currentElement);
      }
    };
  }, [elementRef]);
};

export default useResizeObserver;
