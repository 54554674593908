import { ARCHIVED_IDS } from "../constants/automationStatusTypes";
import {
  DEVELOPMENT_TYPE_CD_POWER_USER_SLUG,
  DEVELOPMENT_TYPE_CD_SELF_USER_SLUG,
  PROCESS_SUBMISSION_TYPE_CITIZEN_DEVELOPER,
  PROCESS_SUBMISSION_TYPE_TOP_DOWN,
  PROCESS_SUBMISSION_TYPE_PROCESS_MINING,
  PROCESS_SUBMISSION_TYPE_TASK_MINING,
  PROCESS_SUBMISSION_TYPE_CHANGE_REQUEST
} from "../constants/profileConstants";
import {
  AdvancedInformationSectionQuestionInterface,
  ProcessInterface
} from "../types/processTypes";
import {
  AUTOMATION_ARCHIVE,
  triggerAnalyticsWithDefaultData
} from "./analyticsUtils";

export const isCitizenDeveloperAutomation = ({
  process_development_type,
  process_submission_type
}: ProcessInterface) => {
  return (
    process_submission_type === PROCESS_SUBMISSION_TYPE_CITIZEN_DEVELOPER ||
    process_development_type === DEVELOPMENT_TYPE_CD_POWER_USER_SLUG ||
    process_development_type === DEVELOPMENT_TYPE_CD_SELF_USER_SLUG
  );
};

export const automationPhaseStatusFireAnalytics = ({
  automation,
  phaseStatusProps: { statusChangeId }
}: {
  automation: ProcessInterface;
  phaseStatusProps: {
    statusChangeId: number;
  };
}) => {
  const automationIsCitizenDeveloper = isCitizenDeveloperAutomation(automation);

  if (automationIsCitizenDeveloper) {
    if (ARCHIVED_IDS.includes(statusChangeId)) {
      triggerAnalyticsWithDefaultData(AUTOMATION_ARCHIVE);
    }
  }
};

// returns lightning badge tooltip if we should show lightning badge
export const lightningBadgeTooltip = (submissionType, t) => {
  if (!submissionType || !t) {
    return null;
  }

  if (submissionType === PROCESS_SUBMISSION_TYPE_TOP_DOWN) {
    return t("coe_driven_tooltip", "CoE-driven idea");
  }

  if (submissionType === PROCESS_SUBMISSION_TYPE_PROCESS_MINING) {
    return t("process_mining_tooltip", "Process Mining idea");
  }

  if (submissionType === PROCESS_SUBMISSION_TYPE_TASK_MINING) {
    return t("task_mining_tooltip", "Task Mining idea");
  }

  return null;
};

export const changeRequestBadgeTooltip = (submissionType, t) => {
  if (!submissionType || !t) {
    return null;
  }

  if (submissionType === PROCESS_SUBMISSION_TYPE_CHANGE_REQUEST) {
    return t("change_request_tooltip", "Change Request");
  }

  return null;
};

export const getAdvancedInformationQuestionsDataByProcessIdentifier = (
  sectionKeys: string[],
  questionProcessIdentifiers: { [process_identifier: string]: string } | {},
  automation?: null | ProcessInterface,
  searchCDAutomation = false
): {
  [process_identifier: string]: AdvancedInformationSectionQuestionInterface;
} => {
  let data: {
    [process_identifier: string]: AdvancedInformationSectionQuestionInterface;
  } = {};

  const advancedInfoToCheck = searchCDAutomation
    ? automation?.cd_advanced_information?.questions
    : automation?.advanced_information?.questions;

  if (
    !Array.isArray(advancedInfoToCheck) ||
    Object.keys(questionProcessIdentifiers).length < 1 ||
    sectionKeys.length < 1
  ) {
    return {};
  }

  sectionKeys.forEach(sectionKey => {
    const sectionWithData = advancedInfoToCheck.find(questionSection => {
      return questionSection?.section?.questionnaire_section_key === sectionKey;
    });

    if (sectionWithData) {
      sectionWithData?.questions?.forEach(currQuestion => {
        if (
          currQuestion.process_identifier &&
          questionProcessIdentifiers[currQuestion.process_identifier]
        ) {
          data[
            questionProcessIdentifiers[currQuestion.process_identifier]
          ] = currQuestion;
        }
      });
    }
  });

  return data;
};
