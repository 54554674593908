import {
  COContextInterface,
  COLocalizedStringValuesInterface,
  COLocalizedStringGroupingInterface,
  COLocalizedStringValuesLinkInterface,
  COLocalizationReplacementType
} from "../../interfaces/co-interfaces";
import { DraftJSUrlWithPosition } from "../../interfaces/draft-js-helper-interfaces";
import { isDraftJS, isNullOrUndefined } from "../../utils/co-utils";
import { addLinkToRawDraftJs, convertToRawDraftJs } from "../draft-js.helper";
import { getLocalizationDataFromValue } from "./co-localization.helper";

// This function will ensure a string within the CO system gets localized
// It will use the localization_key if passed in, and will check the Hash otherwise
// It will also pass in the localization_values if there are any
// - Note: If you use any COLocalizedStringValuesLinkInterface, you will always get a DraftJS response
export const localizeStringWithValues = ({
  context,
  value,
  localization_key,
  localization_values,
  t
}: {
  context?: COContextInterface;
  value?: string;
  localization_key?: string;
  localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  t: any;
}): any => {
  if (value === undefined && localization_key === undefined) {
    return "";
  }
  if (typeof t !== "function") {
    console.log(
      "Aborting trying to localize a string due to missing t function"
    );
    return value;
  }

  const localized_localization_values: {
    localizedValues: { [key: string]: string };
    urls: { [key: string]: string };
  } = localizedLocalizationValues({ context, localization_values, t });

  let final_fallback_value: string | undefined = value;
  let final_localization_key: string | undefined = localization_key;
  if (
    isNullOrUndefined(final_localization_key) ||
    final_localization_key === ""
  ) {
    const localizedValues = getLocalizationDataFromValue(
      final_fallback_value ?? ""
    );
    final_fallback_value = localizedValues.fallbackValue;
    if (
      !isNullOrUndefined(localizedValues.localizationKey) &&
      localizedValues.localizationKey !== ""
    ) {
      final_localization_key = localizedValues.localizationKey;
    }
  }

  let final_value: any = final_fallback_value ?? "";
  if (
    !isNullOrUndefined(final_localization_key) &&
    final_localization_key !== ""
  ) {
    final_value =
      t([final_localization_key, final_fallback_value ?? ""], {
        ...localized_localization_values.localizedValues
      }) ?? "";
  } else if (
    Object.keys(localized_localization_values.localizedValues).length > 0
  ) {
    final_value =
      t(["", final_fallback_value ?? ""], {
        ...localized_localization_values.localizedValues
      }) ?? "";
  } else {
    final_value = final_fallback_value;
  }

  // If we have any urls, we need to add them to the string as DraftJS
  if (
    final_value &&
    Object.keys(localized_localization_values.urls).length > 0
  ) {
    // convert to DraftJS
    let draftJS = final_value;
    let text = final_value;
    if (!isDraftJS(final_value)) {
      draftJS = convertToRawDraftJs(final_value);
    }
    if (isDraftJS(draftJS)) {
      if (draftJS.blocks && draftJS.blocks.length > 0) {
        text = draftJS.blocks[0].text;
      }
    }

    // convert the URLs to DraftJSUrlWithPosition's and then add them to our new DraftJS block
    let draftJSURLs: DraftJSUrlWithPosition[] = [];
    Object.keys(localized_localization_values.urls).forEach(key => {
      const url = localized_localization_values.urls[key];
      const matchingString = localized_localization_values.localizedValues[key];
      if (url && matchingString) {
        const offset = text.indexOf(matchingString);
        if (offset >= 0) {
          const length = matchingString.length;
          draftJSURLs.push({
            offset,
            length,
            url
          });
        }
      }
    });

    for (const draftJSURL of draftJSURLs) {
      draftJS = addLinkToRawDraftJs({ rawDraftJs: draftJS, link: draftJSURL });
    }
    final_value = JSON.stringify(draftJS);
  }

  return final_value;
};

const localizedLocalizationValues = ({
  context,
  localization_values,
  t
}: {
  context?: COContextInterface;
  localization_values?: {
    [key: string]: COLocalizationReplacementType;
  };
  t: any;
}): {
  localizedValues: { [key: string]: string };
  urls: { [key: string]: string };
} => {
  // localize all the localization_key values if we have any
  const localized_localization_values: {
    localizedValues: { [key: string]: string };
    urls: { [key: string]: string };
  } = { localizedValues: {}, urls: {} };

  if (localization_values) {
    for (const key of Object.keys(localization_values)) {
      const localization_value: any = localization_values[key];
      const localizedValues = localizedValue({
        context,
        localization_value,
        t
      });
      localized_localization_values.localizedValues[key] = localizedValues.text;
      if (localizedValues.url) {
        localized_localization_values.urls[key] = localizedValues.url;
      }
    }
  }
  return localized_localization_values;
};

const localizedValue = ({
  context,
  localization_value,
  t
}: {
  context?: COContextInterface;
  localization_value?: COLocalizationReplacementType;
  t: any;
}): { text: string; url?: string } => {
  let localizedString: string = "";
  let url: string | undefined = undefined;

  if (typeof localization_value === "number") {
    localization_value = localization_value.toString();
  }

  if (typeof localization_value === "string") {
    // if we have a string, localize it
    const localizedValues = getLocalizationDataFromValue(localization_value);
    localizedString = t([
      localizedValues.localizationKey,
      localizedValues.fallbackValue
    ]);
  } else if (typeof localization_value === "object") {
    // if we have an object, check what type of object
    const localizedStringValuesInterface = localization_value as COLocalizedStringValuesInterface;
    const localizedStringGroupingInterface = localization_value as COLocalizedStringGroupingInterface;
    const localizedStringValuesLinkInterface = localization_value as COLocalizedStringValuesLinkInterface;

    if (
      localizedStringValuesInterface.value &&
      localizedStringValuesInterface.localization_key
    ) {
      // if we have a COLocalizedStringValuesInterface, then we should resolve the values
      localizedString = localizeStringWithValues({
        context,
        value: localizedStringValuesInterface.value,
        localization_key: localizedStringValuesInterface.localization_key,
        localization_values: localizedStringValuesInterface.localization_values,
        t
      });
    } else if (
      localizedStringGroupingInterface.values &&
      localizedStringGroupingInterface.separator
    ) {
      // if we have a COLocalizedStringGroupingInterface, then we should resolve all the values
      // and join them by the separator
      const values = localizedStringGroupingInterface.values.map(value => {
        return localizedValue({ context, localization_value: value, t }).text;
      });
      const separator = t([
        localizedStringGroupingInterface.separator_localization_key ?? "",
        localizedStringGroupingInterface.separator
      ]);
      localizedString = values.join(separator);
    } else if (localizedStringValuesLinkInterface.value) {
      localizedString = localizeStringWithValues({
        context,
        value: localizedStringValuesLinkInterface.value,
        localization_key: localizedStringValuesLinkInterface.localization_key,
        t
      });
      if (localizedStringValuesLinkInterface.url) {
        url = localizedStringValuesLinkInterface.url;
      }
    }
  }
  return { text: localizedString, url };
};
