import { IconButton, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import IconApollo from "../../IconApollo/IconApollo";

const IconButtonStyled = styled(IconButton)(
  props => css`
    && {
      margin-left: auto;
      color: ${props.theme?.apolloTheme?.palette?.semantic?.colorIconDefault ||
        props.theme?.colors?.ink650};
      margin-top: -8px;
    }
  `
);

const ButtonClose = ({ icon = "", srText = "", ...restProps }) => {
  const { t } = useTranslation("common");
  icon = icon || "close";
  srText = srText || t(["sr_alt_text_close", "Close"]);

  return (
    <IconButtonStyled {...restProps}>
      <IconApollo icon={icon} srText={srText} data-testid="ButtonClose" />
    </IconButtonStyled>
  );
};

export default ButtonClose;
