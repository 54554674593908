import React from "react";
import { useTranslation } from "react-i18next";
import InputEdit from "../InputEdit/InputEdit";
import SearchFieldOption from "./SearchFieldOption/SearchFieldOption";
import styled from "styled-components/macro";
import SuccessIcon from "./../../assets/images/ic-success-4.svg";
import getUniqueKey from "../../utils/uniqueIdGenerator";

const SelectedAutomationEmpty = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
  color: var(--color-foreground-de-emp);
`;

const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 30px;
  cursor: pointer;
`;

const SuccessIconImg = styled.img`
  width: 20px;
  height: 20px;
`;

const SelectedAutomationsDiv = styled.div`
  position: relative;
  height: 206px;
  overflow: auto;
  margin: 16px -32px;
`;

const SelectItemSearchField = ({
  selected,
  subtext,
  isComponent,
  CustomOptionComponent,
  removeSelectedItem,
  handleSelectedItemChange,
  loadOptions
}: {
  selected: any;
  subtext: any;
  isComponent?: boolean;
  CustomOptionComponent?: any;
  removeSelectedItem: (thing: any) => void;
  handleSelectedItemChange: any;
  loadOptions: any;
}) => {
  const { t } = useTranslation("common");

  const renderSelected = () => {
    if ((selected || []).length === 0) {
      return <SelectedAutomationEmpty>{subtext}</SelectedAutomationEmpty>;
    }
    return (selected || []).map((obj, index) => (
      <SelectedContainer
        onClick={removeSelectedItem.bind(index)}
        key={getUniqueKey()}
      >
        {CustomOptionComponent ? (
          <CustomOptionComponent data={{ valueObj: obj }} />
        ) : (
          <SearchFieldOption
            data={{ valueObj: obj }}
            isComponent={isComponent}
          />
        )}
        <SuccessIconImg src={SuccessIcon} alt="Success" />
      </SelectedContainer>
    ));
  };

  const dropdownOptions = {
    loadOptions: loadOptions,
    value: null,
    onChange: handleSelectedItemChange,
    components: {
      Option: ({ children, innerProps, data }) => (
        <div {...innerProps}>
          {CustomOptionComponent ? (
            <CustomOptionComponent data={data} />
          ) : (
            <SearchFieldOption data={data} isComponent={isComponent} />
          )}
        </div>
      )
    },
    defaultOptions: true,
    placeholder: t(["search_modal_search", "Search..."])
  };
  //
  return (
    <div>
      <InputEdit
        inputType="asyncDropdown"
        dropdownOptions={dropdownOptions}
        placeholder={t(["search_modal_search", "Search..."])}
      />

      <SelectedAutomationsDiv>{renderSelected()}</SelectedAutomationsDiv>
    </div>
  );
};

export default SelectItemSearchField;
