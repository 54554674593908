import React, { useRef, useLayoutEffect } from "react";
import useResizeObserver from "../../../utils/customHooks/useResizeObserver";
import { getNestedThemeContainer } from "../../../utils/elementUtils";

let resizeLayoutTimeout;

const NavAuthDynamicResponsiveContainer = props => {
  const navRef = useRef();

  useResizeObserver({
    elementRef: navRef,
    callback: checkAndSetResponsiveNav
  });

  useLayoutEffect(() => {
    setTimeout(checkAndSetResponsiveNav, 0);
    window.addEventListener("resize", checkAndSetResponsiveNav);
    return () => {
      window.removeEventListener("resize", checkAndSetResponsiveNav);
    };
  });

  function checkAndSetResponsiveNav() {
    const navItems = document.querySelector(".NavAuth__items");
    const navElement = document.querySelector(".NavAuth");
    const styleContainer = getNestedThemeContainer();
    const navSlot = document.querySelector(".NavAuth__slot-container");

    if (navElement && navItems && navSlot && navItems.childNodes?.length >= 2) {
      const availableWidth = Math.floor(navSlot.getBoundingClientRect().width);
      const navItemsClone: any = navItems.cloneNode(true);
      const navMeasureContainer = document.createElement("div");
      navMeasureContainer.classList.add("NavAuth");
      navMeasureContainer.style.visibility = "hidden";
      navItemsClone.style.width = availableWidth + "px";
      navMeasureContainer.appendChild(navItemsClone);

      document.body.appendChild(navMeasureContainer);

      // clear timeout so things aren't computed too often on resize/layout change
      clearTimeout(resizeLayoutTimeout);
      // need to measure the element after it has rendered on the page
      resizeLayoutTimeout = setTimeout(() => {
        const clonedItemsContainer: any = navMeasureContainer.querySelector(
          ".NavAuth__items"
        );

        if (
          clonedItemsContainer &&
          // checked above but rechecking clone, at least 2 means a left and right side
          clonedItemsContainer.childNodes?.length >= 2
        ) {
          const leftRect: any = clonedItemsContainer.childNodes[
            clonedItemsContainer.childNodes.length - 2
          ].getBoundingClientRect();
          const rightRect: any = clonedItemsContainer.childNodes[
            clonedItemsContainer.childNodes.length - 1
          ].getBoundingClientRect();

          // default case is compact nav since that is safest
          if (leftRect.right + 20 < rightRect.left) {
            styleContainer.classList.remove("compact-nav");
            styleContainer.classList.add("large-nav");
          } else {
            styleContainer.classList.add("compact-nav");
            styleContainer.classList.remove("large-nav");
          }
        }
      }, 10);
      // clean up no matter what
      setTimeout(() => {
        document.body.removeChild(navMeasureContainer);
      }, 10);
    }
  }

  return <div ref={navRef} {...props} />;
};

export default NavAuthDynamicResponsiveContainer;
