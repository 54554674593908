export const LANGUAGE_COOKIE_NAME = "language";
export const EN = "en";
export const JA = "ja";
export const DE = "de";
export const FR = "fr";
export const ES = "es";
export const PT_BR = "pt-BR";
export const PT = "pt";
export const RU = "ru";
export const TR = "tr";
export const ES_MX = "es-MX";
export const KO = "ko";
export const ZH_CN = "zh-CN";

export const VALID_LANGUAGES = [
  EN,
  JA,
  DE,
  FR,
  ES,
  PT_BR,
  PT,
  RU,
  TR,
  ES_MX,
  KO,
  ZH_CN
];

export const getValidLanguage = currentLanguage => {
  if (VALID_LANGUAGES.indexOf(currentLanguage) >= 0) {
    return currentLanguage;
  }
  return EN;
};
