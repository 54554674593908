import React from "react";

import "./RadioButtonPrimary.scss";

const RadioButtonPrimary = props => {
  const { value, name, text, isChecked, onChange } = props;
  const classes = ["RadioButtonPrimary"];

  if (isChecked) {
    classes.push("RadioButtonPrimary--is-checked");
  }

  return (
    <div
      className={classes.join(" ")}
      data-testid="RadioButtonPrimaryContainer"
    >
      <input
        className="RadioButtonPrimary__radio-button"
        data-testid="RadioButtonPrimary__RadioButton"
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      <label className="RadioButtonPrimary__radio-button-label">{text}</label>
      <span className="RadioButtonPrimary__radio-button-custom">
        <span className="RadioButtonPrimary__radio-button-custon-inner"></span>
      </span>
    </div>
  );
};

export default RadioButtonPrimary;
