import React, { useContext } from "react";
import {
  ApolloThemeContext,
  APOLLO_THEME_DARK_KEY
} from "../StyleThemeProvider/StyleThemeProvider";

const ImageThemed = ({
  src,
  srcDark,
  alt,
  className
}: {
  src: string;
  srcDark?: string;
  alt: string;
  className?: string;
}) => {
  const { currentTheme } = useContext(ApolloThemeContext);
  let imageSource = src;
  if (
    currentTheme === APOLLO_THEME_DARK_KEY &&
    typeof srcDark !== "undefined"
  ) {
    imageSource = srcDark;
  }

  return <img className={className} src={imageSource} alt={alt} />;
};

export default ImageThemed;
