import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import promise from "redux-promise";
import reducers from "../reducers";

const initialState = {};
const store = createStore(
  reducers,
  initialState,
  applyMiddleware(
    promise,
    thunk,
    routerMiddleware(require("history").createBrowserHistory)
  )
);

export default store;
