import { COErrorTypes, COValidationType } from "../constants/co-constants";
import { parsePath, errorKeyFromPathArray } from "../utils/co-path.utils";
import { pathArrayForResolvedKey, resolve } from "../utils/co-resolver.utils";
import {
  COContextInterface,
  COPathOverrideInterface,
  COPathPart,
  COSectionInterface,
  COValidationContext,
  COValidationError,
  COValidationInterface,
  COValidationItemInterface
} from "../interfaces/co-interfaces";
import {
  doesExistPositiveContextMatch,
  isNullOrUndefined,
  rawStringFromPotentiallyDraftJS
} from "../utils/co-utils";
import { addValidationErrors } from "./co-context-validation.helper";

export const isError = (potentialErrorObject: any): boolean => {
  if (!potentialErrorObject) {
    return false;
  }
  if (Array.isArray(potentialErrorObject)) {
    if (potentialErrorObject.length > 0) {
      if (potentialErrorObject[0].error_message) {
        return true;
      }
    }
  } else if (potentialErrorObject.error_message) {
    return true;
  }
  return false;
};

export const validationItemForTypeAndTargetInContext = (
  context: COContextInterface,
  target?: string,
  validationType?: string,
  options?: COValidationContext,
  resolveValidators: boolean = true //for if we want the raw ones to compare paths
) => {
  let allValidators = allValidatorsInContext(context);
  let validValidators: COValidationItemInterface[] = [];
  for (const validator of allValidators) {
    if (!validationType || validator.validationType === validationType) {
      if (!target || validator.target === target) {
        if (
          !options ||
          doesExistPositiveContextMatch(options, validator.validation_context)
        ) {
          let resolvedValidator = resolveValidators
            ? resolve({ context, item: validator })
            : { ...validator };
          if (!resolvedValidator.is_disabled) {
            validValidators.push(resolvedValidator);
          }
        }
      }
    }
  }
  return validValidators;
};

export enum COComparisonType {
  MAX = "max",
  MIN = "min",
  EQUALS = "equals"
}
export const validationValueLimit = ({
  context,
  errorKey,
  comparisonType = COComparisonType.EQUALS,
  validationType,
  validation_context = {}
}: {
  context: COContextInterface;
  errorKey: string;
  comparisonType: COComparisonType;
  validationType: string;
  validation_context: COValidationContext;
}): number | undefined => {
  let validators = resolvedValidatorsForErrorKey({
    context,
    errorKey,
    validationType,
    validation_context
  });
  if (validators.length > 0) {
    let val = comparisonType === COComparisonType.MAX ? 0 : 10000000;
    for (const validator of validators) {
      if (comparisonType === COComparisonType.MAX) {
        if (val < toNumber(validator.value)) {
          val = toNumber(validator.value);
        }
      } else if (comparisonType === COComparisonType.MIN) {
        if (val > toNumber(validator.value)) {
          val = toNumber(validator.value);
        }
      } else {
        val = toNumber(validator.value);
      }
    }
    return val;
  }
  return undefined;
};

// uses the error key to find a validator
//-context(ah-answer_option-0-1-0-2):answer_option-co_question_answer_option_meta_json-title-value
export const resolvedValidatorsForErrorKey = ({
  context,
  errorKey,
  validationType,
  validation_context
}: {
  context: COContextInterface;
  errorKey: string;
  validationType: string;
  validation_context: COValidationContext;
}): COValidationItemInterface[] => {
  let allValidators = allValidatorsInContext(context);
  let validValidators: COValidationItemInterface[] = [];
  for (const validator of allValidators) {
    if (!validationType || validator.validationType === validationType) {
      if (
        !validation_context ||
        doesExistPositiveContextMatch(
          validation_context,
          validator.validation_context
        )
      ) {
        let resolvedValidator = resolve({
          context,
          item: validator
        });
        if (!resolvedValidator.is_disabled) {
          let targetPath: COPathPart[] = pathArrayForResolvedKey(
            resolvedValidator,
            "target"
          );
          let validatorErrorKey = errorKeyFromPathArray(targetPath || []);
          // ends with is important because a lot of validators are on a aggregated meta or option and the values is on the object_json
          if (validatorErrorKey && validatorErrorKey.endsWith(errorKey)) {
            validValidators.push(resolvedValidator);
          }
        }
      }
    }
  }
  return validValidators;
};

export const validatorsForPropertyInContext = ({
  context,
  propertyKey,
  propertyParentObject, // what we're actually finding
  validationType,
  validation_context,
  resolveValidator = true
}: {
  context: COContextInterface;
  propertyKey?: string;
  propertyParentObject?: any; // what we're actually finding
  validationType?: string;
  validation_context?: COValidationContext;
  resolveValidator: boolean;
}) => {
  let allValidators = allValidatorsInContext(context);
  let validValidators: COValidationItemInterface[] = [];
  for (const validator of allValidators) {
    if (!validationType || validator.validationType === validationType) {
      if (
        !validation_context ||
        doesExistPositiveContextMatch(
          validation_context,
          validator.validation_context
        )
      ) {
        // we want to render the path against the context to get the objects
        let pathInfo = parsePath({ context, path: validator.target || "" });
        let parentPathItem: COPathPart | null = null;
        for (var p = 0; p < pathInfo.length; p++) {
          var pathItem = pathInfo[p];
          if (pathItem.property === propertyKey) {
            if (
              Object.is(parentPathItem?.contextualValue, propertyParentObject)
            ) {
              // this should be a literal object reference check
              // we don't necessarily want to resolve the validator
              if (resolveValidator) {
                let resolvedValidator = resolve({ context, item: validator });
                if (!resolvedValidator.is_disabled) {
                  validValidators.push(resolvedValidator);
                }
              } else {
                validValidators.push(validator);
              }
            }
          }
          parentPathItem = pathItem;
        }
      }
    }
  }

  return validValidators;
};

export const allValidatorsInContext = (
  context: COContextInterface
): COValidationItemInterface[] => [
  ...(context.assessment?.validators || []),
  ...(context.section?.validators || []),
  ...(context.question?.validators || []),
  ...(context.answer_option?.validators || []),
  ...(context.process_answer?.validators || [])
];

export const automaticValidation = ({
  context,
  callingObject,
  validation
}: {
  context: COContextInterface;
  callingObject: any;
  validation: COValidationInterface;
}): COValidationError[] => {
  // we can deal with more properties in the validation_json interface here

  let errors: COValidationError[] = [];
  if (validation && validation.validations) {
    for (const validator of validation.validations) {
      let errorItems = validateItem({
        context,
        validator
      });
      if (errorItems) {
        errors = [...errors, ...errorItems];
      }
    }
  }

  addValidationErrors(context, errors);

  return errors;
};

// path overrides let us validate an item without it actually being set on the object yet
export const validateItem = ({
  context,
  validator,
  validation_context,
  pathOverrides
}: {
  context: COContextInterface;
  validator: COValidationItemInterface;
  validation_context?: COValidationContext;
  pathOverrides?: COPathOverrideInterface[];
  validationFunction?: Function;
}): COValidationError[] => {
  let errorItems: COValidationError[] = [];

  //use validation context from context unless intentionally overridden
  if (isNullOrUndefined(validation_context)) {
    validation_context = context.validation_context;
  }
  if (!validator) {
    return errorItems;
  }

  let resolvedValidator = resolve({
    context,
    item: validator,
    overrides: pathOverrides
  });

  if (
    doesExistPositiveContextMatch(
      validation_context,
      validator.validation_context
    )
  ) {
    if (!resolvedValidator.is_disabled) {
      let target: any = resolvedValidator.target;
      let targetPath: COPathPart[] = pathArrayForResolvedKey(
        resolvedValidator,
        "target"
      );
      let errorKey =
        errorKeyFromPathArray(targetPath || []) || validator.target;

      let problem_object: any = {};

      // we want to take the path breakdown and make sure we have enough of the context object to validate it
      if (!isNullOrUndefined(targetPath) && Array.isArray(targetPath)) {
        // this means we didn't get out of the context
        if (targetPath.length > 1) {
          let pathPart: COPathPart = targetPath[targetPath.length - 2];
          problem_object = pathPart.contextualValue;
        }
      }
      let error: COValidationError = {
        error_message: resolvedValidator.meta?.value || "no error title",
        error_localization_key:
          resolvedValidator.meta?.value_localization_key || "",
        error_localization_values:
          resolvedValidator.meta?.value_localization_values ?? {},
        error_type: COErrorTypes.ERROR,
        error_key: errorKey,
        resolvedValidationItem: resolvedValidator,
        validationItem: validator,
        target_path_parts: targetPath,
        problem_object: problem_object,
        problem_property: validator.target,
        section: context.section
      };

      // might make an exception for an "exists type"
      if (isNullOrUndefined(target)) {
        // console.log("Validation Target Path Not Found in Context");
        // console.log(targetPath);
        // console.log(validator);
        // console.log(resolvedValidator);
        // console.log(context);
        return [];
      }

      if (validator.validationFunction) {
        errorItems = validator.validationFunction({
          context,
          validation_context: validation_context || {},
          value: resolvedValidator.value,
          target: target,
          error
        });
        return errorItems;
      }

      if (!isNullOrUndefined(target)) {
        let validationType = resolvedValidator.validationType;
        switch (validationType) {
          case COValidationType.VALIDATION_TYPE_EQUALS:
            errorItems = validateObject(
              target,
              valuesEqual,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH:
            errorItems = validateObject(
              target,
              stringMax,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH:
            errorItems = validateObject(
              target,
              stringMin,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE:
            errorItems = validateObject(
              target,
              maxNumber,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE:
            errorItems = validateObject(
              target,
              minNumber,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_MAX_ARRAY_LENGTH:
            errorItems = validateObject(
              target,
              arrayMax,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH:
            errorItems = validateObject(
              target,
              arrayMin,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_ROUND_TO_DECIMAL:
            errorItems = validateObject(
              target,
              roundToDecimal,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_UPPERCASE:
            errorItems = validateObject(
              target,
              upperCase,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_NUMBER:
            errorItems = validateObject(
              target,
              number,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_IS_NUMERICAL:
            errorItems = validateObject(
              target,
              isNumber,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_IS_POSITIVE_NUMERICAL:
            errorItems = validateObject(
              target,
              isPositiveNumber,
              resolvedValidator.value,
              error
            );
            break;
          case COValidationType.VALIDATION_TYPE_ERROR_FOR_DECIMAL:
            errorItems = validateObject(
              target,
              errorForDecimalPlaces,
              resolvedValidator.value,
              error
            );
            break;
        }
        return errorItems;
      }
    }
  }

  // first let's clone the validator and render all of the values using the path system

  // first let's renderout the properties using our path system

  return errorItems;
};

const validateObject = (
  targetObject: any,
  validationFunction: Function,
  value: any,
  error: COValidationError
): COValidationError[] => {
  return validationFunction(targetObject, value, error);
};

export const toFloat = (value: string | number | undefined): number => {
  if (!isNullOrUndefined(value)) {
    if (Number.isInteger(value)) {
      let number: any = value;
      return number;
    }
    let number: any = value;
    return parseFloat(number);
  }
  return 0;
};

export const toNumber = (value: string | number | undefined): number => {
  if (!isNullOrUndefined(value)) {
    if (Number.isInteger(value)) {
      let number: any = value;
      return number;
    }
    let number: any = value;
    return parseInt(number);
  }
  return 0;
};
export const castToString = (value: any): string => {
  if (!isNullOrUndefined(value)) {
    if (typeof value === "string") {
      return value;
    }
    if (typeof value === "object") {
      return value.toString();
    }
    return value + "";
  }
  return "";
};

const roundToDecimal = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  target = `${target}`.replace(/[^0-9.]/g, "0");
  if (target === "" || isNullOrUndefined(target)) {
    error.transformedValue = "";
    return [error];
  }
  let intTarget = toFloat(target).toFixed(toNumber(value));
  if (intTarget === target) {
    return [];
  }
  error.transformedValue = intTarget;
  return [error];
};

const errorForDecimalPlaces = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  if (
    target === "" ||
    isNullOrUndefined(target) ||
    isNullOrUndefined(parseFloat(target))
  ) {
    // if we don't have a number value to check here, don't return an error
    // another validator will handle it if theres an issue related to it being empty or not a number
    return [];
  }
  let numDecimalPlaces: number = 0;
  const targetParts: string[] = target.split(".");
  if (targetParts.length > 1) {
    numDecimalPlaces = targetParts[1].length ?? 0;
  }
  const expectedNumDecimalPlaces: number = toNumber(value) ?? 0;
  if (numDecimalPlaces <= expectedNumDecimalPlaces) {
    return [];
  }
  error.transformedValue = expectedNumDecimalPlaces;
  return [error];
};

const number = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  let numberTarget = toNumber(target);
  if (numberTarget === target) {
    return [];
  }
  error.transformedValue = toNumber(target);
  return [error];
};

const isNumber = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  if (!isNaN(target) && !isNaN(parseFloat(target))) {
    return [];
  }
  error.transformedValue = target;
  return [error];
};

const isPositiveNumber = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  if (isNaN(target) || target === "") {
    // if we don't have a value, don't return an error
    // the value might just be un-answered in an optional question
    return [];
  }
  const numberValue = parseFloat(target);
  if (!isNaN(numberValue) && numberValue >= 0) {
    return [];
  }

  error.transformedValue = target;
  return [error];
};

const upperCase = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  let stringTarget = castToString(target);
  if (stringTarget.toUpperCase() === stringTarget) {
    return [];
  }
  error.transformedValue = stringTarget.toUpperCase();
  return [error];
};

// for min and max numbers we want to no force an undefined answer to be 9999999 or whatever with a transform
const minNumber = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  if (toNumber(target) >= toNumber(value)) {
    return []; // no error
  }
  if (!isNullOrUndefined(target) && target !== "") {
    error.transformedValue = toFloat(value) + 1; // move to the max
  }
  return [error];
};
const maxNumber = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  if (toNumber(target) <= toNumber(value)) {
    return [];
  }
  if (!isNullOrUndefined(target) && target !== "") {
    error.transformedValue = toFloat(value) - 1; // move to the max
  }
  return [error];
};

const arrayMax = (
  array: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  let targetValue = toNumber(value);
  if (array.length <= targetValue) {
    return [];
  }
  return [error];
};

const arrayMin = (
  array: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  let targetValue = toNumber(value);
  if (array.length >= targetValue) {
    return [];
  }
  if (Array.isArray(array)) {
    error.transformedValue = array.slice(0, toNumber(value));
  }
  return [error];
};

const stringMax = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  let targetValue = toNumber(value);
  let stringTarget = castToString(target);
  stringTarget = rawStringFromPotentiallyDraftJS(stringTarget); //deal with draft js
  if (stringTarget.length <= targetValue) {
    return [];
  }
  error.transformedValue = stringTarget;
  return [error];
};

const stringMin = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  let targetValue = toNumber(value);
  let stringTarget = castToString(target);
  stringTarget = rawStringFromPotentiallyDraftJS(stringTarget); //deal with draftJS
  if (stringTarget.length >= targetValue) {
    return [];
  }
  error.transformedValue = stringTarget.substr(0, targetValue);
  return [error];
};

const valuesEqual = (
  target: any,
  value: any,
  error: COValidationError
): COValidationError[] => {
  if (target === value) {
    return [];
  }
  error.transformedValue = value;
  return [error];
};
