import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_DOCUMENTATION_URL,
  AUTOMATION_PROFILE_COMPONENTS_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const contentDeletionUpdateNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = contentDeletionUpdateNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.CONTENT_DELETION,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const contentDeletionUpdateNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.DOCUMENT_DELETED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 66,
          platform_event_type: COPlatformEventType.PROCESS_DOCUMENT_DELETED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.DOCUMENT_DELETED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Document deleted in AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " deleted the document " +
              COActionReplacementStrings.DOCUMENT_TITLE +
              " in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_DOCUMENTATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.REUSABLE_COMPONENT_REMOVED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR
            ]
          },
          legacy_notification_id: 107,
          platform_event_type: COPlatformEventType.REUSABLE_COMPONENT_REMOVED,
          notification_category_type:
            CONotificationActionCategoryTypes.PROCESS_COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.REUSABLE_COMPONENT_REMOVED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Reusable component removed in AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed the reusable component " +
              COActionReplacementStrings.COMPONENT_NAME +
              " from the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COMPONENTS_URL
          }
        }
      };
    }
  }
  return undefined;
};
