import React, { Component } from "react";
import {
  focusOnFirstFocusableElementInContainer,
  maintainFocusInContainer
} from "../../utils/accessibilityUtils/accessibilityUtils";
import "./Modal.scss";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalMainRef = React.createRef();
  }

  onTabPressed = e => {
    const modalElement = this.modalMainRef.current;
    maintainFocusInContainer(e, modalElement);
  };

  listenKeyboard = event => {
    if (event.key === "Escape" || event.keyCode === 27) {
      this.props.onClose();
    } else if (event.key === "Tab" || event.keyCode === 9) {
      this.onTabPressed(event);
    }
  };

  focusOnMount = () => {
    // Focus on first focusable element in the modal
    // because keeping focus on 'modalMainRef' will allow
    // shift+tab to move accessibility focus to the elements
    // outside Modal component.
    this.modalMainRef &&
      this.modalMainRef.current &&
      focusOnFirstFocusableElementInContainer(this.modalMainRef.current);
  };

  componentDidMount() {
    if (this.props.onClose) {
      window.addEventListener("keydown", this.listenKeyboard, true);
    }

    this.focusOnMount();
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      window.removeEventListener("keydown", this.listenKeyboard, true);
    }
  }

  onOverlayClick = () => {
    if (!this.props.disableOverlayClick) {
      this.props.onClose();
    }
  };

  onDialogClick = event => {
    event.stopPropagation();
  };

  render() {
    const overlayStyle = this.props.overlayStyle ? this.props.overlayStyle : {};
    const contentStyle = this.props.contentStyle ? this.props.contentStyle : {};

    const extraClassName = this.props.className || "";

    return (
      <div
        className={`Modal ${extraClassName}`}
        style={overlayStyle}
        onClick={this.onOverlayClick}
      >
        <div
          className="Modal__content"
          style={contentStyle}
          onClick={this.onDialogClick}
          role="dialog"
          tabIndex={-1}
          ref={this.modalMainRef}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
