import React from "react";
import swal, { swalClose } from "../components/SweetAlert/SweetAlert";
import { LocalizeTranslationKeyWithPostText } from "./../utils/localizedAlertMessages";
import { renderToString } from "react-dom/server";
import { NESTED_THEME_CONTAINER_ID } from "../components/StyleThemeProvider/StyleThemeProvider";

let interval;

export const sweetAlertLoader = ({ text = "Saving", delay = 500 }) => {
  swal.fire({
    target: `#${NESTED_THEME_CONTAINER_ID}`,
    title: text,
    showConfirmButton: false,
    allowOutsideClick: false,
    showCloseButton: false
  });
  let dots = 1;
  interval = setInterval(() => {
    let base = text;
    let dotString = "";
    if (typeof base === "object") {
      let key = (base.props || {}).localeKey || "";
      for (let i = 0; i < dots; i++) {
        dotString += ".";
        base = renderToString(
          <LocalizeTranslationKeyWithPostText
            localeKey={key}
            postText={dotString}
          />
        );
      }
    } else {
      for (let i = 0; i < dots; i++) {
        dotString += ".";
        base = text + dotString;
      }
    }

    swal.fire({
      title: base,
      showConfirmButton: false,
      target: `#${NESTED_THEME_CONTAINER_ID}`,
      animation: false
    });

    dots = dots === 3 ? 0 : dots + 1;
  }, delay);
};

export const closeSweetAlertLoader = () => {
  if (interval) {
    clearInterval(interval);
    swalClose();
  }
};
