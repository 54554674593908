import React from "react";
import { useTranslation } from "react-i18next";
import alert_icon from "../../assets/images/alert_icon.png";
import styled, { css } from "styled-components/macro";
import RadioButtonPrimary from "../../components/CheckboxComponents/RadioButtonPrimary/RadioButtonPrimary";
import SignupTenantInputDropdown from "../../pages/SignupPage/SignupTenantForm/SignupTenantInputDropdown/SignupTenantInputDropdown";

const SingleRadioButtonWithLessPadding = styled.div`
  padding: 10px 20px 10px 10px;
  display: flex;
`;

const AlertImg = styled.img`
  width: 40px;
`;
const AlertImgContainer = styled.div`
  width: 40px;
  margin-right: 20px;
`;

const SubTitle = styled.div`
  font-size: 13px;
  line-height: 20px;
  padding: 0 20px;
  margin-bottom: 20px;
`;
const Title = styled.div(
  props => css`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${props.theme.colors.ink700};
    display: flex;
    padding: 20px;
  `
);
const TitleWrapper = styled.div`
  margin: auto;
`;
const CreateNewSection = styled.div`
  padding: 0 20px;
`;

const TenantLinkText = styled.a(
  props => css`
    color: ${props.theme.colors.gray550};
    line-height: 25px;
    font-size: 14px;
  `
);

const TenantLink = styled.a(
  props => css`
    color: ${props.theme.colors.gray550};
    margin-top: 5px;
    margin-left: 12px;
  `
);

const OrphanStatsWrapper = styled.div`
  margin-left: 38px;
`;

const CreateNewDatabaseSection = styled.div`
  height: 85vh;
`;

const CountryPickerWrapper = styled.div`
  margin-left: 20px;
`;

const SingleRadioButton = styled.div`
  padding: 10px 20px 10px 20px;
  display: flex;
`;

const MigrationOption = styled.div(
  props => css`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: ${props.theme.colors.ink700};
    display: flex;
    padding-left: 10px;
  `
);

const MigrateOptionWrapper = styled.div(
  props => css`
    padding-bottom: 20px;
    margin: 10px 10px 10px 40px;
    font-size: 14px;
    word-break: break-word;
    hyphens: auto;
    color: ${props.theme.colors.ink700};
    border-bottom: 1px solid ${props.theme.colors.ink300};
    max-height: 30vh;
    overflow: auto;
  `
);

const FpsProvisionTenantSelectorReuseDBBox = ({
  tenants,
  setSelectedOrphan,
  setCreateNewDBOption,
  country,
  countries,
  createNewDBOption,
  storedCountry,
  onCountryChange,
  onSelectOrphan,
  selectedOrphan
}) => {
  const { t } = useTranslation("common");

  let hasOrphan = false;
  (tenants || []).forEach(({ isOrphan }) => {
    if (isOrphan) {
      hasOrphan = true;
    }
  });

  const onCreateNewOptionChange = e => {
    const value = parseInt(e.target.value);
    if (value === 1) {
      setSelectedOrphan(null);
    }
    setCreateNewDBOption(value);
  };

  const generateDatabaseOptionRadioButtons = ({
    tenantsArray,
    selectedOrphanUuid,
    onChange,
    name
  }) => {
    return (tenantsArray || []).map(
      ({ ahTenantUuid, tenantStats, tenantName, isOrphan }, idx) => {
        if (!isOrphan) {
          return;
        }
        return (
          <div>
            <SingleRadioButtonWithLessPadding>
              <RadioButtonPrimary
                name={name}
                value={tenantStats}
                isChecked={selectedOrphanUuid === ahTenantUuid}
                onChange={e => {
                  onChange(e, ahTenantUuid);
                }}
              />
              <TenantLinkText>{tenantName}</TenantLinkText>
              <TenantLink></TenantLink>
            </SingleRadioButtonWithLessPadding>
            <OrphanStatsWrapper>
              <TenantLinkText>
                <b>{t(["fps_orphan_select_users_label", "Users:"])} </b>
                {tenantStats.number_of_all_users || 0} |{" "}
                <b>
                  {t(["fps_orphan_select_automation_label", "Automations:"])}{" "}
                </b>
                {tenantStats.number_of_automation_in_all_statuses || 0} |{" "}
                <b>{t(["fps_orphan_select_component_label", "Components:"])}</b>
                {tenantStats.number_of_components_in_all_statuses || 0}
              </TenantLinkText>
            </OrphanStatsWrapper>
          </div>
        );
      }
    );
  };

  const generateCountryPicker = () => {
    if (!!storedCountry) {
      return;
    }
    const editDropdownData = (countries || []).map(countryObj => {
      return {
        label: countryObj.country_name,
        value: countryObj
      };
    });
    return (
      <CountryPickerWrapper>
        <SignupTenantInputDropdown
          handleChange={onCountryChange}
          name="tenant_country"
          editDropdownData={editDropdownData}
          value={country}
          label={t(["signup_tenant_country_label", "Country"])}
        />
      </CountryPickerWrapper>
    );
  };

  return (
    <>
      <Title>
        <AlertImgContainer>
          <AlertImg src={alert_icon} alt={"alert icon"} />
        </AlertImgContainer>
        <TitleWrapper>
          {t(["fps_orphan_select_title", "A few databases are not in use!"])}
        </TitleWrapper>
      </Title>
      <SubTitle>
        {" "}
        {t([
          "fps_orphan_select_subtitle",
          "Please select a database of a disabled tenant to reuse or create a new database for this tenant."
        ])}
      </SubTitle>
      <CreateNewDatabaseSection>
        <SingleRadioButton>
          <RadioButtonPrimary
            name="orphan_selector"
            value={0}
            isChecked={createNewDBOption == 0}
            onChange={e => {
              if (!hasOrphan) {
                return;
              }
              onCreateNewOptionChange(e);
            }}
          />
          <MigrationOption>
            {t(["fps_orphan_reuse_db_option", "Reuse Database"])}
          </MigrationOption>
        </SingleRadioButton>
        <MigrateOptionWrapper>
          {generateDatabaseOptionRadioButtons({
            tenantsArray: tenants,
            selectedOrphanUuid: selectedOrphan,
            onChange: onSelectOrphan,
            name: "orphan_selector"
          })}
        </MigrateOptionWrapper>

        <SingleRadioButton>
          <RadioButtonPrimary
            name="orphan_selector"
            value={1}
            isChecked={createNewDBOption == 1}
            onChange={onCreateNewOptionChange}
          />
          <MigrationOption>
            {t(["fps_orphan_create_db_option", "Create new database"])}
          </MigrationOption>
        </SingleRadioButton>
        <CreateNewSection>{generateCountryPicker()}</CreateNewSection>
      </CreateNewDatabaseSection>
    </>
  );
};

export default FpsProvisionTenantSelectorReuseDBBox;
