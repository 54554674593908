import { auth } from "./auth";
import SessionHelper from "../helpers/SessionHelper";
import {
  FPS_AUTH_DOMAIN,
  FPS_AUTH_CLIENT_ID,
  FPS_AUTH_RESPONSE_TYPE,
  FPS_AUTH_SCOPE,
  API_URL
} from "../constants/envConstants";
import auth0 from "auth0-js";
import axios from "axios";
import queryString from "query-string";
import { AUTH_TYPE_FPS } from "../constants/authConstants";
import { LANGUAGE_COOKIE_NAME } from "../constants/localizationConstants";
import apiErrorAlert from "./apiErrorAlert";

export const FPS_LOGIN_EVENT_NAME = "ahFpsLogin";

//https://github.com/UiPath/Cloud-RPA/blob/dev/Portal/src/AuthUtils/index.js#L24
export default class AuthFPSHandler {
  //this triggers the login and returns to the app with a token to the redirectUri

  redirectUri = () => {
    const currentFpsService = SessionHelper.currentFpsService();
    let url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      SessionHelper.fpsAccountFromCurrentPath() +
      "/" +
      SessionHelper.fpsTenantFromCurrentPath() +
      "/" +
      currentFpsService +
      "/login-redirect";
    return url;
  };
  logOutRedirectUri = () => {
    let url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      SessionHelper.tenantNameFromCurrentPath() +
      "/logout-redirect";
    return url;
  };
  //    redirectUri: `http://localhost:3000/testorgaifab/thisisanewtenant/automationhub_/login-redirect`

  auth0Instance = null;

  auth0Config = {
    FPS_AUTH_DOMAIN: FPS_AUTH_DOMAIN,
    FPS_AUTH_CLIENT_ID: FPS_AUTH_CLIENT_ID,
    FPS_AUTH_RESPONSE_TYPE: FPS_AUTH_RESPONSE_TYPE,
    FPS_AUTH_SCOPE: FPS_AUTH_SCOPE
  };

  getAuth0Instance = async () => {
    if (!this.auth0Instance) {
      if (
        !this.auth0Config.FPS_AUTH_CLIENT_ID ||
        this.auth0Config.FPS_AUTH_CLIENT_ID.length < 6
      ) {
        //get auth0Config from FrontDoor
        try {
          let ax = axios.create({
            baseURL: API_URL
          });
          const resp = await ax.get("/fd-api/v1/public-config/web-client");
          let loadedAuthConfig = resp?.data?.data;
          if (loadedAuthConfig) {
            this.auth0Config = loadedAuthConfig;
          }
        } catch (error) {
          apiErrorAlert(error);
        }
      }

      this.auth0Instance = new auth0.WebAuth({
        domain: this.auth0Config.FPS_AUTH_DOMAIN,
        clientID: this.auth0Config.FPS_AUTH_CLIENT_ID,
        responseType: this.auth0Config.FPS_AUTH_RESPONSE_TYPE,
        scope: this.auth0Config.FPS_AUTH_SCOPE,
        redirectUri: this.redirectUri()
      });
    }
    return this.auth0Instance;
  };

  authorize = async () => {
    // FPS sets the tenant information in redirectUri and does not need to
    // set tenant name as query param
    console.log("FPS LOGIN TRIGGERED");
    var loginExtraParams = {};
    let auth0I = await this.getAuth0Instance();
    try {
      auth0I.authorize({
        acr_values: "tenantName:" + SessionHelper.fpsAccountFromCurrentPath(),
        scope: this.auth0Config.FPS_AUTH_SCOPE,
        responseType: this.auth0Config.FPS_AUTH_RESPONSE_TYPE,
        redirectUri: this.redirectUri()
      });
    } catch (error) {
      apiErrorAlert(error);
    }
  };

  getStateValue = () => {
    return "";
  };

  signup = () => {};

  //this checks to see if we have a hash from the callback or then checks in the background - for general login
  logInUserFromHash = (
    parsedQueryParams,
    silent,
    authenticateUserTokenCallBack,
    loginErrorHandler
  ) => {
    //check to see if we have a hash in the url from the auth
    let authResult = {
      idToken: parsedQueryParams.id_token || "",
      accessToken: parsedQueryParams.access_token || "",
      authType: AUTH_TYPE_FPS
    };
    // Clear values from URL
    window.location.hash = "";

    SessionHelper.setUserCloudIDToken(authResult.idToken);
    SessionHelper.setUserCloudAccessToken(authResult.accessToken);
    if (window) {
      try {
        // Dispatch event that cloud tokens have updated and ui should rerender
        window.dispatchEvent(new Event(FPS_LOGIN_EVENT_NAME));
      } catch (e) {
        console.log({ e });
      }
    }
    auth.refreshUserFromAuthResult(
      authResult,
      user_token => {
        if (authenticateUserTokenCallBack) {
          authenticateUserTokenCallBack(user_token);
        }
      },
      silent,
      loginErrorHandler
    );
  };

  //this does the auth call in the background and doens't prompt the user if it works / fails
  //uses an iframe
  refreshSessionInBackground = authenticatedUserTokenCallBack => {
    //we need to handle this - in the mean time let's logout
    let url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      SessionHelper.tenantNameFromCurrentPath() +
      "/backgroundRefresh";

    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("id", "ifrm"); // assign an id
    ifrm.setAttribute("style", "visibility:hidden;");
    ifrm.setAttribute("src", url);
    document.body.appendChild(ifrm); // to place at end of document
    // assign url

    //if this fails after 20 seconds we want to abandon the refresh / could also detect the wrong landing page
    var t = setTimeout(() => {
      console.log("tearing down iframe for background refresh");
      if (ifrm && document && document.body) {
        document.body.removeChild(ifrm);
      }
      if (authenticatedUserTokenCallBack) {
        authenticatedUserTokenCallBack(null);
      }
      authenticatedUserTokenCallBack = null;
    }, 20000);

    let self = this;

    //check to see if the loaded url has a hash in it with the user token
    ifrm.onload = function () {
      try {
        const hash = ifrm?.contentWindow?.location?.hash;
        if (hash) {
          //console.log(ifrm.contentWindow.location.hash);
          const parsedQueryParams = queryString.parse(hash);
          if (parsedQueryParams && parsedQueryParams.id_token) {
            document.body.removeChild(ifrm);
            if (t) {
              clearTimeout(t);
            }
            self.logInUserFromHash(
              parsedQueryParams,
              true,
              user_token => {
                if (authenticatedUserTokenCallBack) {
                  authenticatedUserTokenCallBack(user_token);
                }
              },
              (headers, error, messageObj) => {}
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
  };

  logout = () => {
    console.log("clearing session and attempting to log out");
    SessionHelper.clearExcept([LANGUAGE_COOKIE_NAME]);

    //force back to login screen
    let logoutURL =
      window.location.protocol +
      "//" +
      window.location.host +
      "/portal_/logout";
    console.log(`logout url ${logoutURL}`);
    window.location.assign(logoutURL);
    // this.auth0.logout({
    //   returnTo: this.logOutRedirectUri()
    // });
  };
}

export const authFPSHandler = new AuthFPSHandler();
