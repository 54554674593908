/* eslint-disable no-console */
const childProcess = require("child_process");

const {
  AH_ENVIRONMENT,
  AH_ENVIRONMENT_ORDER_NUMBER,
  AH_INFRA,
  FILEPATH_TO_AH_SERVICE,
  AH_SERVICE_NAMES,
  AH_SERVICE_DEFAULT_PORT
} = require("../constants/ah-environment.constants");

const getEnvironment = () => {
  if (process.env.AH_ENV === undefined && process.env.NODE_ENV === "test") {
    return AH_ENVIRONMENT.TEST;
  }
  if (process.env.AH_ENV in AH_ENVIRONMENT_ORDER_NUMBER) {
    return process.env.AH_ENV;
  }
  return AH_ENVIRONMENT.PRODUCTION;
};

const getEnvironmentOrderNumber = env => {
  // buggy: this will make `test` to be the highest
  // because test is 0 and 0 is falsey so 0 || 1000000 = 1000000
  // If we fix this, we will need to fix a lot of logic re unit tests
  return AH_ENVIRONMENT_ORDER_NUMBER[env] || 1000000;
};

const environmentEquals = env => {
  return getEnvironment() === env;
};

const environmentLessThan = env => {
  return (
    getEnvironmentOrderNumber(getEnvironment()) < getEnvironmentOrderNumber(env)
  );
};

const environmentLessOrEqualThan = env => {
  return (
    getEnvironmentOrderNumber(getEnvironment()) <=
    getEnvironmentOrderNumber(env)
  );
};

const environmentGreaterThan = env => {
  return (
    getEnvironmentOrderNumber(getEnvironment()) > getEnvironmentOrderNumber(env)
  );
};

const environmentGreaterOrEqualThan = env => {
  return (
    getEnvironmentOrderNumber(getEnvironment()) >=
    getEnvironmentOrderNumber(env)
  );
};

// initialize AH service and Git data
// quasi-idempotent
// (can be called both before and after loading .env files)
const initAhEnv = () => {
  // service, from file path
  if (!global.AH_SERVICE) {
    let path = process.cwd();
    path = path.replace(/\\/g, "/");
    const pathSplit = path.split("/");
    const lastPath =
      pathSplit.length > 0 ? pathSplit[pathSplit.length - 1] : "";
    const service = FILEPATH_TO_AH_SERVICE[lastPath];
    global.AH_SERVICE = service;
  }

  // git
  if (!global.GIT_COMMIT_HASH && environmentEquals(AH_ENVIRONMENT.SANDBOX)) {
    // sandbox
    try {
      global.GIT_COMMIT_HASH = childProcess
        .execSync("git rev-parse HEAD")
        .toString()
        .trim();
      global.GIT_BRANCH = childProcess
        .execSync("git rev-parse --abbrev-ref HEAD")
        .toString()
        .trim();
      global.GIT_STATUS = childProcess
        .execSync("git diff HEAD --shortstat")
        .toString()
        .trim();
    } catch (e) {
      // we can safely proceed after this error: git data is only used for logging, and this code path is restricted to sandbox
      console.log(e);
      console.error("Cannot run git");
    }
  } else {
    // deployed
    // TODO
  }
};

const getService = () => {
  return global.AH_SERVICE;
};

const getServiceName = () => {
  return AH_SERVICE_NAMES[getService()];
};

const getServiceDefaultPort = () => {
  return AH_SERVICE_DEFAULT_PORT[getService()];
};

const getGitCommitHash = () => {
  return global.GIT_COMMIT_HASH;
};

const getGitBranch = () => {
  return global.GIT_BRANCH;
};

const getGitStatus = () => {
  return global.GIT_STATUS;
};

function getInfra() {
  return (
    process.env.REACT_APP_AH_INFRA ||
    process.env.AH_INFRA ||
    AH_INFRA.LOCALHOST_NODE
  );
}

function infraEquals(infra) {
  return getInfra() === infra;
}

function onPrem() {
  return (
    infraEquals(AH_INFRA.DOCKER_AZURE_K8_ONPREM) ||
    infraEquals(AH_INFRA.LOCALHOST_NODE_ONPREM) ||
    infraEquals(AH_INFRA.DOCKER_SERVICE_FABRIC_K8_ONPREM)
  );
}

// Skip the Full Text Search Indexes and Ranking
function ftsEnabled() {
  return !(onPrem() || process.env.NO_FTS === "1");
}

module.exports = {
  getInfra,
  initAhEnv,
  getEnvironment,
  getEnvironmentOrderNumber,
  environmentEquals,
  environmentLessThan,
  environmentLessOrEqualThan,
  environmentGreaterThan,
  environmentGreaterOrEqualThan,
  infraEquals,
  onPrem,
  getService,
  getServiceName,
  getServiceDefaultPort,
  getGitCommitHash,
  getGitBranch,
  getGitStatus,
  ftsEnabled
};
