import React from "react";
import axios from "../utils/axiosInstance";
import { LocalizeTranslationKey } from "../utils/localizedAlertMessages";
import apiErrorAlert from "../utils/apiErrorAlert";

import {
  FETCH_COMPONENT_REVIEWS,
  ADD_NEW_COMPONENT_REVIEW,
  CLEAR_COMPONENT_REVIEWS,
  DELETE_COMPONENT_REVIEW,
  SET_REVIEW_TO_REPORT
} from "../constants/actionTypes";
import { fetchComponentProfile } from "./componentProfileActions";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";
import ToastSwal from "../components/ToastAutomationPipeline/ToastAutomationPipeline";

export function fetchComponentReviews(params) {
  const { componentSlug } = params;

  return function(dispatch) {
    axios
      .get(`api/v1/components/slug/${componentSlug}/reviews?limit=100000000000`)
      .then(response => {
        dispatch({
          type: FETCH_COMPONENT_REVIEWS,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function addComponentReview(params) {
  const { ...fields } = params;
  const { componentSlug } = params;
  return function(dispatch) {
    axios
      .post(`/api/v1/components/slug/${componentSlug}/reviews`, fields)
      .then(response => {
        dispatch(fetchComponentProfile(componentSlug));
        dispatch({
          type: ADD_NEW_COMPONENT_REVIEW,
          payload: response.data.data
        });
        if (params.successCallback) {
          params.successCallback(response.data.data);
        }
        dispatch(fetchComponentReviews(params));
      })
      .catch(error => {
        if (params.failureCallback) {
          params.failureCallback(error);
        }
        apiErrorAlert(error);
      });
  };
}

export function clearComponentReviews() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_COMPONENT_REVIEWS
    });
  };
}

export function deleteComponentReview(params) {
  const { component_id, component_review_id, componentSlug } = params;

  return function(dispatch) {
    axios
      .delete(
        `/api/v1/components/${component_id}/reviews/${component_review_id}`
      )
      .then(response => {
        dispatch(fetchComponentProfile(componentSlug));
        dispatch({
          type: DELETE_COMPONENT_REVIEW,
          payload: {
            component_id,
            component_review_id
          }
        });
        dispatch(fetchComponentReviews(params));
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setReviewDataToReport(reportReviewData) {
  return function(dispatch) {
    dispatch({
      type: SET_REVIEW_TO_REPORT,
      payload: reportReviewData
    });
  };
}

export function reportReviewAbuse(review_id, component_slug, reason) {
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_saving_report_title"} />
    )
  });
  return function(dispatch) {
    axios
      .post(
        `/api/v1/components/slug/${component_slug}/reviews/${review_id}/report`,
        reason
      )
      .then(response => {
        swalSpinnerClose();
        ToastSwal.fire({
          title: (
            <LocalizeTranslationKey
              localeKey={"info_alert_review_abuse_reported_content"}
            />
          )
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}
