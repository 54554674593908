import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { TextField, Tooltip } from "@material-ui/core";
import { useGetId } from "../../../utils/customHooks/useGetId";
import TooltipIcon from "../../TooltipIcon/TooltipIcon";
import COAutocompleteTextField from "../../_CO/COMetaJsonEditor/CoTypeAhead/COAutoCompleteTextField";

const ApolloLabelTooltipHolder = styled.span`
  position: relative;
  pointer-events: auto;
`;

const TooltipIconForLabel = styled(TooltipIcon)<{ $required?: boolean }>(
  props => `
    position: absolute;
    /* This needs to be shifted for the * mark */
    margin-left: ${props.$required ? "12px" : "4px"};
`
);

const CounterLabel = styled.span`
  min-width: 38px;
  padding-left: 5px;
  color: var(--color-foreground-de-emp);
  font-size: 0.875rem;
`;

const TextFieldWithStyle = styled(TextField)<{ $maxLength?: number }>(
  props => css`
    ${typeof props?.$maxLength === "number"
      ? css`
          .MuiOutlinedInput-inputAdornedEnd {
            width: unset;
            flex-grow: 1;
          }
        `
      : ""}

    .MuiInputLabel-outlined {
      z-index: unset;
    }
    &&&& {
      input::placeholder {
        opacity: 1 !important;
      }
    }
  `
);

export const ApolloInputLabelWithTooltip = ({
  label,
  labelTooltip,
  required,
  labelID,
  ariaLabelledBy
}: {
  label?: any;
  labelTooltip?: ReactNode;
  required?: boolean;
  labelID?: string;
  ariaLabelledBy?: string;
}): JSX.Element | null => {
  if (label) {
    const labelElement: JSX.Element = (
      <span id={labelID} aria-labelledby={ariaLabelledBy}>
        {label}
      </span>
    );
    if (labelTooltip) {
      return (
        <ApolloLabelTooltipHolder>
          {labelElement}
          <TooltipIconForLabel title={labelTooltip} $required={required} />
        </ApolloLabelTooltipHolder>
      );
    } else {
      return <>{labelElement}</>;
    }
  }
  return null;
};

const InputTextApollo = ({
  label,
  placeholder,
  helperText,
  className,
  variant = "outlined",
  error = false,
  errorCount,
  InputLabelProps = {},
  InputProps = {},
  inputProps = {},
  autoCompleteProps = {},
  tooltipTitle = "",
  required,
  disabled,
  multiline,
  maxLength,
  rows,
  id,
  labelTooltip,
  ariaLabelledBy,
  data_testid
}: {
  label?: string | ReactNode;
  placeholder?: string;
  helperText?: ReactNode;
  className?: string;
  variant?: "outlined" | "filled" | "standard" | undefined;
  error?: boolean;
  errorCount?: number;
  InputLabelProps?: any;
  InputProps?: any;
  inputProps?: any;
  autoCompleteProps?: any;
  tooltipTitle?: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number | string;
  id?: string;
  labelTooltip?: ReactNode;
  ariaLabelledBy?: string;
  data_testid?: string;
}) => {
  const inputEl = useRef<any>(null); // the component
  let inputElementRef = useRef<any>(null); // the actual input
  const inputTimeout = useRef<any>(null);
  // input needs an id for accessability, used the prop or create one
  const accessibleId = useGetId(id);
  // for switching back and forth between controlled on the debouncer
  const [controlled, setControlled] = useState(true);
  // for saving cursor pos
  const [cursorPos, setCursorPos] = useState<null | number>(0);
  // for what to set it to after control is re-enabled (updated in on change)
  const [target, setTarget] = useState({ name: "", value: "" });
  // switching between controlled and uncontrolled doesn't work for character pickers for JA, KO, etc..
  const isUsingInputComposition = useRef<boolean>(false);
  const input_debounce_ms: number | undefined = InputProps?.input_debounce_ms;
  const allInputProps = InputProps
    ? {
        ...InputProps,
        inputRef: InputProps.inputRef || inputElementRef
      }
    : {};

  const valueLength = allInputProps?.value?.length || 0;

  const usingInputDebounce: boolean =
    !autoCompleteProps?.options &&
    input_debounce_ms &&
    input_debounce_ms > 0 &&
    isUsingInputComposition.current === false
      ? true
      : false;

  useEffect(() => {
    if (inputEl && inputEl.current && error) {
      const el: any = inputEl.current;
      el?.scrollIntoView?.({ block: "center" });
    }
  }, [inputEl, error, errorCount]);

  // intercept on change and debounce it forcing the component into uncontrolled temporarily
  if (usingInputDebounce) {
    // switching between controlled and uncontrolled doesn't work for character pickers for JA, KO, etc..
    const disableDebounceForIme = () => {
      isUsingInputComposition.current = true;
    };

    allInputProps.onCompositionStart = disableDebounceForIme;
    allInputProps.onCompositionUpdate = disableDebounceForIme;
    allInputProps.onCompositionEnd = disableDebounceForIme;
    allInputProps.onChange = e => {
      let { name, value } = e.target;
      // store cursor position
      if (e.target.selectionStart || e.target.selectionStart === 0) {
        setCursorPos(e.target.selectionStart);
      } else {
        setCursorPos(null);
      }
      setTarget({ name, value });
      setControlled(false);

      // do the debounce
      clearTimeout(inputTimeout?.current);
      inputTimeout.current = setTimeout(() => {
        setControlled(true);
      }, input_debounce_ms);
    };
  }

  // when controlled is switched on or off by the debouncer we trigger the actual change
  useEffect(() => {
    if (usingInputDebounce && controlled) {
      if (target && target.name) {
        typeof InputProps.onChange === "function" &&
          InputProps.onChange({
            target: target
          });
        setTarget({ name: "", value: "" });
      }
    }
  }, [controlled, usingInputDebounce]);

  // after controlled is flipped back we need to set the cursor pos
  useEffect(() => {
    if (controlled && usingInputDebounce) {
      let refForInput = allInputProps.inputRef;
      if (refForInput.current && (cursorPos || cursorPos === 0)) {
        refForInput.current.selectionStart = cursorPos;
        refForInput.current.selectionEnd = cursorPos;
        setCursorPos(null);
      }
    }
  }, [target, usingInputDebounce]);

  if (usingInputDebounce && !controlled) {
    delete allInputProps.value;
  }

  if (!usingInputDebounce && typeof maxLength === "number" && maxLength > 0) {
    if (valueLength + 10 >= maxLength) {
      allInputProps.endAdornment = (
        <CounterLabel>{`${valueLength}/${maxLength}`}</CounterLabel>
      );
    }
    allInputProps.onChange = e => {
      if (
        typeof e?.target?.value === "string" &&
        e.target.value.length > maxLength
      ) {
        e.target.value = e.target.value.substring(0, maxLength);
      }
      typeof InputProps.onChange === "function" && InputProps.onChange(e);
    };
  }
  var andiOutput = "";
  if (typeof label === "string") {
    andiOutput = label;
  }
  if (placeholder && placeholder.length > 0) {
    andiOutput =
      andiOutput.length === 0 ? placeholder : andiOutput + " " + placeholder;
  }
  var inputPropsWithAriaLabel = inputProps;
  if (ariaLabelledBy) {
    inputPropsWithAriaLabel = {
      "aria-labelledby": ariaLabelledBy,
      ...inputProps
    };
  } else if (andiOutput.length > 0) {
    inputPropsWithAriaLabel = {
      "aria-label": andiOutput,
      ...inputProps
    };
  }
  return (
    <Tooltip title={tooltipTitle}>
      {autoCompleteProps && autoCompleteProps?.options?.length > 0 ? (
        <COAutocompleteTextField
          Component={TextFieldWithStyle}
          options={autoCompleteProps.options}
          ref={inputEl}
          className={className}
          label={
            labelTooltip ? (
              <ApolloInputLabelWithTooltip
                label={label}
                labelTooltip={labelTooltip}
                required={required}
              />
            ) : (
              label
            )
          }
          placeholder={placeholder}
          variant={variant}
          error={error ? true : undefined}
          InputLabelProps={InputLabelProps}
          InputProps={allInputProps}
          inputProps={inputPropsWithAriaLabel}
          helperText={helperText}
          required={required}
          disabled={disabled}
          $maxLength={maxLength}
          multiline={multiline}
          rows={rows}
          id={accessibleId}
        />
      ) : (
        <TextFieldWithStyle
          ref={inputEl}
          className={className}
          label={
            labelTooltip ? (
              <ApolloInputLabelWithTooltip
                label={label}
                labelTooltip={labelTooltip}
                required={required}
              />
            ) : (
              label
            )
          }
          placeholder={placeholder}
          variant={variant}
          error={error ? true : undefined}
          InputLabelProps={InputLabelProps}
          InputProps={allInputProps}
          inputProps={inputPropsWithAriaLabel}
          helperText={helperText}
          required={required}
          disabled={disabled}
          $maxLength={maxLength}
          multiline={multiline}
          rows={rows}
          id={accessibleId}
          data-testid={data_testid}
        />
      )}
    </Tooltip>
  );
};

export default InputTextApollo;
