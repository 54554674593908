export const doesFileExist = file => {
  return (
    file &&
    file.length > 0 &&
    !!file[0].file &&
    Object.keys(file[0].file).length !== 0
  );
};

export const validateFileLink = link => {
  return (
    link.substring(0, 8) === "https://" || link.substring(0, 7) === "http://"
  );
};
