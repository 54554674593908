import React, { ReactElement } from "react";
import "./LoaderLogoSmall.scss";

type Props = {
  fullPage?: boolean;
  style?: any;
};

const LoaderLogoSmall = ({ fullPage, ...props }: Props): ReactElement => {
  let fullPageClassName: string = "";
  if (fullPage) {
    fullPageClassName = "LoaderLogoSmall--full-page";
  }
  return (
    <div
      className={`LoaderLogoSmall ${fullPageClassName}`}
      data-testid="LoaderLogoSmall"
    >
      <div {...props} className="LoaderLogoSmall__container">
        {/* @ts-ignore */}
        <portal-loader></portal-loader>
      </div>
    </div>
  );
};

export default LoaderLogoSmall;
