import React from "react";
import { useTranslation } from "react-i18next";
import IconApollo from "../IconApollo/IconApollo";
import RatingStars from "../RatingStars/RatingStars";

import "./CellRatingUserContainer.scss";

const CellRatingUserContainer = props => {
  const {
    component_reviews_avg,
    component_num_reviews,
    extra_rating_num_style,
    extra_rating_user_style,
    smallStyle,
    leftAlign,
    noMarginTop,
    className
  } = props;
  const { t } = useTranslation("common");
  let small = smallStyle ? " smallStyle" : "";
  let left = leftAlign ? " CellRatingUserContainer__left" : "";
  let marginTopStyle = noMarginTop
    ? " CellRatingUserContainer__no-margin-top"
    : "";
  return (
    <div
      className={
        "CellRatingUserContainer" +
        small +
        left +
        marginTopStyle +
        `${className ? ` ${className}` : ""}`
      }
      data-testid="CellRatingUserContainer"
    >
      <RatingStars
        className="CellRatingUserContainer__stars"
        rating={component_reviews_avg}
      />
      <IconApollo
        fontSize="small"
        style={extra_rating_user_style}
        icon="person_outline"
        srText={t(["sr_alt_text_reviews", "Reviews"])}
        className="CellRatingUserContainer__userIcon"
      />
      <div
        style={extra_rating_num_style}
        className="CellRatingUserContainer__numReviews"
      >
        {component_num_reviews}
      </div>
    </div>
  );
};

export default CellRatingUserContainer;
