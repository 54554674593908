import { Icon, Typography, IconProps } from "@material-ui/core";
import React from "react";
import styled from "styled-components/macro";

export const HiddenSrText = styled(({ children, ...props }) => {
  return <Typography {...props}>{children}</Typography>;
})`
  && {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    left: 0;
    top: 0;
    margin: 0;
  }
`;

const IconApollo = ({
  icon,
  className = "",
  srText,
  outlined,
  ...iconProps
}: {
  icon: string;
  className?: string;
  srText?: string;
  outlined?: boolean;
} & IconProps<any>) => {
  return (
    <>
      <Icon
        className={`${outlined ? "material-icons-outlined" : ""} ${className}`}
        {...iconProps}
      >
        {icon}
      </Icon>
      {srText ? <HiddenSrText variant="srOnly">{srText}</HiddenSrText> : null}
    </>
  );
};

export default IconApollo;
