import { COAssessment } from "../../classes/co-assessment.class";
import {
  COUnitInterface,
  LocalizationHashMapInterface
} from "../../interfaces/co-interfaces";
import {
  BlockTypeEnum,
  InlineStyleRangeStylesEnum,
  RawDraftJSInterface
} from "../../interfaces/draft-js-helper-interfaces";
import { isNullOrUndefined } from "../../utils/co-utils";
import { CO_DEFAULT_LOCALIZATION_HASH_MAP } from "./hashMaps/co-default-hash-maps.helper";
import { CO_ACTIONS_LOCALIZATION_HASH_MAP } from "./hashMaps/co-actions-hash-maps.helper";

// Function for creating hash from text
export const createHashString = (value: string): string => {
  let hash = 0;
  if (value.length === 0) {
    return `${hash}`;
  }
  for (let i = 0; i < value.length; i++) {
    let chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return `${hash}`;
};

const getHtmlFromDraftJs = (draftObj: RawDraftJSInterface) => {
  let htmlString = "";

  draftObj?.blocks?.forEach(block => {
    let textContent = block?.text || "";
    let htmlContent;

    if (textContent) {
      // first do the inline replacements
      if (block?.inlineStyleRanges?.length) {
        let offsetDif = 0;
        block.inlineStyleRanges.forEach(styleRange => {
          if (
            typeof styleRange?.offset === "number" &&
            typeof styleRange?.length === "number"
          ) {
            const stringStart = textContent.substring(
              0,
              styleRange.offset + offsetDif
            );
            const content = textContent.substring(
              styleRange.offset + offsetDif,
              styleRange.offset + offsetDif + styleRange.length
            );
            const stringEnd = textContent.substring(
              styleRange.offset + offsetDif + styleRange.length
            );
            let htmlContent = content;
            switch (styleRange.style) {
              case InlineStyleRangeStylesEnum.BOLD:
                htmlContent = `<b>${content}</b>`;
                break;
              case InlineStyleRangeStylesEnum.LINETHROUGH:
                htmlContent = `<strike>${content}</strike>`;
                break;
              case InlineStyleRangeStylesEnum.ITALIC:
                htmlContent = `<i>${content}</i>`;
                break;
              case InlineStyleRangeStylesEnum.UNDERLINE:
                htmlContent = `<u>${content}</u>`;
                break;
              default:
                break;
            }
            offsetDif += htmlContent.length - content.length;
            textContent = stringStart + htmlContent + stringEnd;
          }
        });
      }
      // then create the block level elements
      htmlContent = textContent;
      switch (block?.type) {
        case BlockTypeEnum.HEADER_ONE:
          htmlContent = `<h1>${textContent}</h1>`;
          break;
        case BlockTypeEnum.HEADER_TWO:
          htmlContent = `<h2>${textContent}</h2>`;
          break;
        case BlockTypeEnum.HEADER_THREE:
          htmlContent = `<h3>${textContent}</h3>`;
          break;
        case BlockTypeEnum.CODE_BLOCK:
          htmlContent = `<code>${textContent}</code>`;
          break;
        case BlockTypeEnum.ORDERED_LIST_ITEM:
        case BlockTypeEnum.UNORDERED_LIST_ITEM:
          htmlContent = `<li>${textContent}</li>`;
          break;
        case BlockTypeEnum.UNSTYLED:
        default:
          htmlContent = `<p>${textContent}</p>`;
          break;
      }

      htmlString += htmlContent;
    }
  });

  return htmlString;
};

const getLocalizationHashDataFromValue = (
  value: string
): {
  hash: string;
  value: string;
} => {
  if (typeof value === "string") {
    // We need to transform draftjs strings into html to make hashing more consistent
    if (value[0] === "{" && value[value.length - 1] === "}") {
      try {
        const json = JSON.parse(value);
        const html = getHtmlFromDraftJs(json);
        return {
          hash: createHashString(html),
          value: html
        };
      } catch (e) {
        // We continue and check the string below
        console.log(e);
      }
    }
    return {
      hash: createHashString(value),
      value
    };
  } else {
    return {
      hash: "",
      value
    };
  }
};

// uses object reference editing, not pure
const hashValueAndAddToMap = ({
  value,
  map,
  oldMap
}: {
  value: any;
  map: LocalizationHashMapInterface;
  oldMap?: LocalizationHashMapInterface;
}) => {
  if (typeof value === "string" && value !== "") {
    const hashData = getLocalizationHashDataFromValue(value);

    if (hashData.value && (!oldMap || !oldMap[hashData.hash])) {
      map[hashData.hash] = {
        localizationKey: "",
        fallbackValue: hashData.value
      };
    }
  }
};

export const coAssessmentToLocalizationHashMap = (
  assessment: COAssessment,
  // oldMap so we can add to the map without needing to redo the whole thing
  oldMap?: LocalizationHashMapInterface
): LocalizationHashMapInterface => {
  const map: LocalizationHashMapInterface = {};

  hashValueAndAddToMap({
    value: assessment?.co_assessment_meta_json?.title?.value,
    map,
    oldMap
  });

  hashValueAndAddToMap({
    value: assessment?.co_assessment_meta_json?.description?.value,
    map,
    oldMap
  });

  // In each section
  assessment?.co_assessment_sections?.forEach(section => {
    hashValueAndAddToMap({
      value: section?.co_section_meta_json?.title?.value,
      map,
      oldMap
    });
    hashValueAndAddToMap({
      value: section?.co_section_meta_json?.description?.value,
      map,
      oldMap
    });

    // In each question
    section?.co_questions?.forEach(question => {
      hashValueAndAddToMap({
        value: question?.co_question_meta_json?.title?.value,
        map,
        oldMap
      });
      hashValueAndAddToMap({
        value: question?.co_question_meta_json?.description?.value,
        map,
        oldMap
      });

      // In each answer option
      question?.co_question_answer_options?.forEach(option => {
        hashValueAndAddToMap({
          value: option?.co_question_answer_option_meta_json?.title?.value,
          map,
          oldMap
        });
      });
    });
  });

  return map;
};

// localization key may be returned empty if there is no match
// value may be returned as normal string, html string, or draftjs string
export const getLocalizationDataFromValue = (
  value: string,
  hashMap = CO_LOCALIZATION_HASH_MAP
): {
  localizationKey: string;
  fallbackValue: string;
} => {
  const data = getLocalizationHashDataFromValue(value);
  if (data.hash && hashMap[data.hash]) {
    return hashMap[data.hash];
  } else {
    return {
      localizationKey: "",
      fallbackValue: value
    };
  }
};

export const getLocalizedUnitsForValue = (
  value: string | null,
  unLocalizedValue: string | number | undefined | null,
  unit: COUnitInterface
) => {
  if (unit?.slug && value && !isNullOrUndefined(unLocalizedValue)) {
    return {
      localizationKey: `export_csv_co_unit_${unit?.slug}`,
      fallbackValue: value,
      unLocalizedValue
    };
  }

  return {
    localizationKey: "",
    fallbackValue: value
  };
};

// Some of these rich text fields are hard coded to slugs here
// be careful creating new map these do not get replaces
export const CO_LOCALIZATION_HASH_MAP: LocalizationHashMapInterface = {
  ...CO_DEFAULT_LOCALIZATION_HASH_MAP,
  ...CO_ACTIONS_LOCALIZATION_HASH_MAP
};
