import {
  FETCH_EXPLORE_COMPONENTS_DATA,
  CLEAR_EXPLORE_COMPONENTS_DATA,
  FETCH_EXPLORE_ALL_COMPONENT_TYPES,
  FETCH_EXPLORE_ALL_COMPONENT_L1,
  SET_EXPLORE_COMPONENTS_IS_LOADING_DATA,
  FETCH_EXPLORE_ALL_COMPONENT_APPLICATION_FILTERS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  exploreComponentsData: null,
  exploreComponentsTypes: null,
  exploreComponentsL1: null,
  exploreComponentsApplicationFilters: null,
  isLoadingExploreComponents: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_EXPLORE_COMPONENTS_DATA:
      return {
        ...state,
        exploreComponentsData: action.payload
      };
    case FETCH_EXPLORE_ALL_COMPONENT_TYPES:
      return {
        ...state,
        exploreComponentsTypes: action.payload
      };
    case FETCH_EXPLORE_ALL_COMPONENT_APPLICATION_FILTERS:
      return {
        ...state,
        exploreComponentsApplicationFilters: action.payload
      };
    case FETCH_EXPLORE_ALL_COMPONENT_L1:
      return {
        ...state,
        exploreComponentsL1: action.payload
      };
    case CLEAR_EXPLORE_COMPONENTS_DATA:
      return {
        ...state,
        exploreComponentsData: null
      };
    case SET_EXPLORE_COMPONENTS_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingExploreComponents: action.payload
      };
    default:
      return state;
  }
}
