import { COValidationError } from "../interfaces/co-interfaces";

// build a better helper
export const mergeValidationErrors = (
  errors: COValidationError[] = [],
  newErrors: COValidationError[] = []
): COValidationError[] => {
  let mergedErrors = errors;
  for (const newError of newErrors) {
    let matchFound = false;
    for (const err of errors) {
      // find matches
      if (err?.error_key === newError?.error_key) {
        if (err?.error_type === newError?.error_type) {
          if (
            (err?.resolvedValidationItem?.validationSlug &&
              err?.resolvedValidationItem?.validationSlug ===
                newError?.resolvedValidationItem?.validationSlug) ||
            (Array.isArray(err?.resolvedValidationItem?.value) &&
              Array.isArray(newError?.resolvedValidationItem?.value) &&
              err?.resolvedValidationItem?.validationType ===
                newError?.resolvedValidationItem?.validationType) ||
            (err?.resolvedValidationItem?.value ===
              newError?.resolvedValidationItem?.value &&
              err?.resolvedValidationItem?.validationType ===
                newError?.resolvedValidationItem?.validationType)
          ) {
            matchFound = true;
          }
        }
      }
    }
    if (!matchFound) {
      mergedErrors.push(newError);
    }
  }
  return mergedErrors;
};
