import Tokens from "@uipath/apollo-core";

const styleTheme = {
  fonts: {
    primary: Tokens.FontFamily.FontNormal
  },

  shadows: {
    cardDefault:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    cardHover:
      "0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);"
  },
  apolloTheme: {
    // Set to current Apollo theme on index.js
    // Will change between themes
    // Use semantic colors
  },
  sizes: {
    "container-max-width": "1172px",
    "container-side-padding": "24px",
    "container-max-width-stretch": "1800px",
    "form-side-nav-width": "280px",
    "nav-auth-height": "var(--ah-fixed-header-height, 76px)",
    "nav-auth-with-admin-nav-height":
      "calc(var(--ah-fixed-header-height, 76px) * 2)",
    "fps-side-nav-width": "68px",
    pageHeaderFontSize: "1.25rem",
    "explore-page-max-width": "1712px"
  },
  breakPoints: {
    mobileNav: "1350px",
    xsmall: "480px",
    small: "768px",
    medium: "1024px",
    large: "1280px"
  },
  colors: {
    // These Colors are deprecated
    // Use semantic colors from apolloTheme
    orange100: "#FEE3DC",
    orange200: "#FDA891",
    orange300: "#FC7753",
    orange400: "#FB5124",
    orange500: "#FA4616",
    orange600: "#DA3B11",
    orange700: "#C2320C",
    orange800: "#832107",
    orange900: "#400F02",
    "orange100-contrast": "#262626",
    "orange200-contrast": "#262626",
    "orange300-contrast": "#262626",
    "orange400-contrast": "#262626",
    "orange500-contrast": "#262626",
    "orange600-contrast": "#EFEFEF",
    "orange700-contrast": "#EFEFEF",
    "orange800-contrast": "#EFEFEF",
    "orange900-contrast": "#EFEFEF",
    blue100: "#DAE8FA",
    blue200: "#9AC2F2",
    blue300: "#4E94E8",
    blue400: "#0C6EDF",
    blue500: "#0067DF",
    blue600: "#0061D3",
    blue700: "#00489D",
    blue800: "#00336F",
    blue900: "#001937",
    "blue100-contrast": "#262626",
    "blue200-contrast": "#262626",
    "blue300-contrast": "#262626",
    "blue400-contrast": "#EFEFEF",
    "blue500-contrast": "#EFEFEF",
    "blue600-contrast": "#EFEFEF",
    "blue700-contrast": "#EFEFEF",
    "blue800-contrast": "#EFEFEF",
    "blue900-contrast": "#EFEFEF",
    blue_secondary100: "#E9F1FA",
    blue_secondary200: "#B2D2F3",
    blue_secondary300: "#70ADE9",
    blue_secondary400: "#358ADF",
    blue_secondary500: "#358ADF",
    blue_secondary600: "#1665B3",
    blue_secondary700: "#11508D",
    blue_secondary800: "#0C3761",
    blue_secondary900: "#061C31",
    "blue_secondary100-contrast": "#262626",
    "blue_secondary200-contrast": "#262626",
    "blue_secondary300-contrast": "#262626",
    "blue_secondary400-contrast": "#262626",
    "blue_secondary500-contrast": "#262626",
    "blue_secondary600-contrast": "#EFEFEF",
    "blue_secondary700-contrast": "#EFEFEF",
    "blue_secondary800-contrast": "#EFEFEF",
    "blue_secondary900-contrast": "#EFEFEF",
    green100: "#E0FAE8",
    green200: "#ADF1C3",
    green300: "#70E795",
    green400: "#42E174",
    green500: "#34DE69",
    green600: "#31D263",
    green700: "#249B49",
    green800: "#1A6F34",
    green900: "#0D371A",
    "green100-contrast": "#262626",
    "green200-contrast": "#262626",
    "green300-contrast": "#262626",
    "green400-contrast": "#262626",
    "green500-contrast": "#262626",
    "green600-contrast": "#262626",
    "green700-contrast": "#262626",
    "green800-contrast": "#EFEFEF",
    "green900-contrast": "#EFEFEF",
    yellow100: "#FFF3DB",
    yellow200: "#FFE19E",
    yellow300: "#FFCA55",
    yellow400: "#FFBB27",
    yellow500: "#FFB40E",
    yellow600: "#F3AB0D",
    yellow700: "#B27D09",
    yellow800: "#805A07",
    yellow900: "#3F2C03",
    "yellow100-contrast": "#262626",
    "yellow200-contrast": "#262626",
    "yellow300-contrast": "#262626",
    "yellow400-contrast": "#262626",
    "yellow500-contrast": "#262626",
    "yellow600-contrast": "#262626",
    "yellow700-contrast": "#262626",
    "yellow800-contrast": "#EFEFEF",
    "yellow900-contrast": "#EFEFEF",
    red100: "#FCDBE6",
    red200: "#F7A1BD",
    red300: "#F25A8C",
    red400: "#FF266D",
    red500: "#ED145B",
    red600: "#D91153",
    red700: "#A60E3F",
    red800: "#76092D",
    red900: "#3B0516",
    "red100-contrast": "#262626",
    "red200-contrast": "#262626",
    "red300-contrast": "#262626",
    "red400-contrast": "#262626",
    "red500-contrast": "#EFEFEF",
    "red600-contrast": "#EFEFEF",
    "red700-contrast": "#EFEFEF",
    "red800-contrast": "#EFEFEF",
    "red900-contrast": "#EFEFEF",
    purple100: "#EEE1EE",
    purple200: "#D3AED3",
    purple300: "#B372B2",
    purple400: "#A244A1",
    purple500: "#933692",
    purple600: "#8C338B",
    purple700: "#662565",
    purple800: "#491B49",
    purple900: "#240D24",
    "purple100-contrast": "#262626",
    "purple200-contrast": "#262626",
    "purple300-contrast": "#262626",
    "purple400-contrast": "#EFEFEF",
    "purple500-contrast": "#EFEFEF",
    "purple600-contrast": "#EFEFEF",
    "purple700-contrast": "#EFEFEF",
    "purple800-contrast": "#EFEFEF",
    "purple900-contrast": "#EFEFEF",
    light_blue100: "#E1F6FD",
    light_blue200: "#AFE8FA",
    light_blue300: "#73D6F7",
    light_blue400: "#44CFFC",
    light_blue500: "#38C6F4",
    light_blue600: "#35BBE7",
    light_blue700: "#278AAB",
    light_blue800: "#1C637A",
    light_blue900: "#0D303C",
    "light_blue100-contrast": "#262626",
    "light_blue200-contrast": "#262626",
    "light_blue300-contrast": "#262626",
    "light_blue400-contrast": "#262626",
    "light_blue500-contrast": "#262626",
    "light_blue600-contrast": "#262626",
    "light_blue700-contrast": "#262626",
    "light_blue800-contrast": "#EFEFEF",
    "light_blue900-contrast": "#EFEFEF",
    white: "#ffffff",
    black: "#000000",
    error50: "#FFE6E7",
    error100: "#FFF0F1",
    error200: "#FFD1D1",
    error250: "#FFA2A8",
    error300: "#FFADAD",
    error400: "#FF8484",
    error500: "#CC3D45",
    error600: "#B6242B",
    error700: "#A6040A",
    // 750 is deprecated, when seeing it in the code replace with 700
    error750: "#A6040A",
    error800: "#6C0004",
    error900: "#3D0607",
    "error250-contrast": "#262626",
    "error500-contrast": "#262626",
    "error750-contrast": "#EFEFEF",
    "error900-contrast": "#EFEFEF",
    "error50-contrast": "#262626",
    success50: "#EEFFE6",
    success250: "#B3EC92",
    success500: "#52C41A",
    success700: "#038108",
    success750: "#038A08",
    success900: "#05361B",
    "success250-contrast": "#262626",
    "success500-contrast": "#262626",
    "success750-contrast": "#EFEFEF",
    "success900-contrast": "#EFEFEF",
    "success50-contrast": "#262626",
    warning50: "#FFF7E6",
    warning250: "#FFE39B",
    warning500: "#FAAD14",
    warning750: "#A96700",
    warning900: "#3D2406",
    "warning250-contrast": "#262626",
    "warning500-contrast": "#262626",
    "warning750-contrast": "#EFEFEF",
    "warning900-contrast": "#EFEFEF",
    "warning50-contrast": "#262626",
    gray50: "#fafafa",
    gray100: "#f7f7f7",
    gray150: "#f4f4f5",
    gray200: "#ecedee",
    gray250: "#e1e2e4",
    gray300: "#d3d4d6",
    gray350: "#c0c2c5",
    gray400: "#aaacaf",
    gray450: "#919295",
    gray500: "#757679",
    gray550: "#595a5c",
    gray600: "#414244",
    gray650: "#2d2e2f",
    gray700: "#1d1d1e",
    gray750: "#111212",
    gray800: "#0a0a0a",
    gray850: "#060606",
    gray900: "#050505",
    "gray100-contrast": "#262626",
    "gray150-contrast": "#262626",
    "gray200-contrast": "#262626",
    "gray250-contrast": "#262626",
    "gray300-contrast": "#262626",
    "gray350-contrast": "#262626",
    "gray400-contrast": "#262626",
    "gray450-contrast": "#262626",
    "gray500-contrast": "#EFEFEF",
    "gray550-contrast": "#EFEFEF",
    "gray600-contrast": "#EFEFEF",
    "gray650-contrast": "#EFEFEF",
    "gray700-contrast": "#EFEFEF",
    "gray750-contrast": "#EFEFEF",
    "gray800-contrast": "#EFEFEF",
    "gray850-contrast": "#EFEFEF",
    "gray900-contrast": "#EFEFEF",
    "gray050-contrast": "#262626",
    ink50: "#f8fcfc",
    ink100: "#f4f9fa",
    ink150: "#f1f6f8",
    ink200: "#e9f0f3",
    ink250: "#dee6ea",
    ink300: "#cfd8dd",
    ink350: "#bbc7cd",
    ink400: "#a4b1b8",
    ink450: "#8a97a0",
    ink500: "#6e7b85",
    ink550: "#526069",
    ink600: "#3a464f",
    ink650: "#273139",
    ink700: "#182027",
    ink750: "#0e1419",
    ink800: "#080c10",
    ink850: "#04070a",
    ink900: "#030508",
    "ink100-contrast": "#262626",
    "ink150-contrast": "#262626",
    "ink200-contrast": "#262626",
    "ink250-contrast": "#262626",
    "ink300-contrast": "#262626",
    "ink350-contrast": "#262626",
    "ink400-contrast": "#262626",
    "ink450-contrast": "#262626",
    "ink500-contrast": "#EFEFEF",
    "ink550-contrast": "#EFEFEF",
    "ink600-contrast": "#EFEFEF",
    "ink650-contrast": "#EFEFEF",
    "ink700-contrast": "#EFEFEF",
    "ink750-contrast": "#EFEFEF",
    "ink800-contrast": "#EFEFEF",
    "ink850-contrast": "#EFEFEF",
    "ink900-contrast": "#EFEFEF",
    "ink050-contrast": "#262626",
    "control-focus": "rgba(0, 103, 223, 0.15)"
  }
};

export default styleTheme;
