//= =====================
// Modal Types
//= =====================
export const TEST_MODAL = "TEST_MODAL";
export const ADD_DOCUMENT_MODAL = "ADD_DOCUMENT_MODAL";
export const UPDATE_DOCUMENT_MODAL = "UPDATE_DOCUMENT_MODAL";
export const IMAGE_UPLOADER_MODAL = "IMAGE_UPLOADER_MODAL";
export const LOGO_UPLOADER_MODAL = "LOGO_UPLOADER_MODAL";
export const CHECK_FOR_PROCESS_DUPLICATES_MODAL =
  "CHECK_FOR_PROCESS_DUPLICATES_MODAL";
export const APPROVE_IDEAS = "APPROVE_IDEAS";
export const DELETE_AUTOMATION = "DELETE_AUTOMATION";
export const REJECT_IDEAS = "REJECT_IDEAS";
export const APPROVE_QUALIFICATION = "APPROVE_QUALIFICATION";
export const ADMIN_IMPORT_TEMPLATE_MODAL = "ADMIN_IMPORT_TEMPLATE_MODAL";
export const ADMIN_UPDATE_TEMPLATE_MODAL = "ADMIN_UPDATE_TEMPLATE_MODAL";
export const REPORT_AUTOMATION_ABUSE_MODAL = "REPORT_AUTOMATION_ABUSE_MODAL";
export const REPORT_COMMENT_ABUSE_MODAL = "REPORT_COMMENT_ABUSE_MODAL";
export const REPORT_REVIEW_ABUSE_MODAL = "REPORT_REVIEW_ABUSE_MODAL";
export const REJECT_COMPONENT_MODAL = "REJECT_COMPONENT_MODAL";
export const REPORT_COMPONENT_ABUSE_MODAL = "REPORT_COMPONENT_ABUSE_MODAL";
export const REPORT_COMPONENT_BUG_MODAL = "REPORT_COMPONENT_BUG_MODAL";
export const COMPONENT_TYPES_MODAL = "COMPONENT_TYPES_MODAL";
export const COMPONENT_VERSION_NOTES_MODAL = "COMPONENT_VERSION_NOTES_MODAL";
export const DOWNLOAD_TEMPLATE_PEOPLE_MODAL = "COMPONENT_VERSION_NOTES_MODAL";
export const CHANGE_ACCOUNT_PLAN_MODAL = "CHANGE_ACCOUNT_PLAN_MODAL";
export const EXTEND_ACCOUNT_PLAN_MODAL = "EXTEND_ACCOUNT_PLAN_MODAL";
export const REQUEST_RENEWAL_MODAL = "REQUEST_RENEWAL_MODAL";
export const CREATE_RENAME_SAVE_VIEW_MODAL = "CREATE_RENAME_SAVE_VIEW_MODAL";

//Generic phase status change modals
export const STATUS_PIPELINE_GENERIC_MODAL = "STATUS_PIPELINE_GENERIC_MODAL";
export const STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL =
  "STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL";
export const STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL =
  "STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL";
