import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import AccessibleMenuContainer from "../../accessibilityComponents/AccessibleMenuContainer/AccessibleMenuContainer";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";

const Container = styled(AccessibleMenuContainer)`
  width: 296px;
  padding: 12px;
  background: var(--color-background);
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`;

const ButtonApolloSecondaryStyled = styled(ButtonApolloSecondary)`
  && {
    margin-right: 12px;
  }
`;

const AddLinkPrompt = ({
  className = "",
  linkValue,
  onLinkChange,
  onAddLink,
  onCancel
}: {
  className?: string;
  linkValue: string;
  onLinkChange: (e) => void;
  onAddLink: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("common");

  return (
    <Container className={className} hasKeyboardTrap>
      <InputTextApollo
        InputProps={{
          value: linkValue,
          onChange: onLinkChange,
          autoFocus: true
        }}
        placeholder={t(["add_link_prompt_placeholder", "Add a link"])}
      />
      <ButtonContainer>
        <ButtonApolloSecondaryStyled onClick={onCancel}>
          {t(["add_link_prompt_cancel_label", "Cancel"])}
        </ButtonApolloSecondaryStyled>
        <ButtonApolloPrimary onClick={onAddLink}>
          {t(["add_link_prompt_add_label", "Add"])}
        </ButtonApolloPrimary>
      </ButtonContainer>
    </Container>
  );
};

export default AddLinkPrompt;
