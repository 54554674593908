import {
  COContextInterface,
  COEmailReminderActionInterface,
  COActionTemplateInterface
} from "../../interfaces/co-interfaces";
import { COBaseAction } from "./co-base-action.class";

export interface COEmailReminderAction extends COEmailReminderActionInterface {}

export class COEmailReminderAction extends COBaseAction {
  static getClassName(): string {
    return "COEmailReminderAction";
  }
  constructor({
    propertiesFromJSON,
    context,
    template
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COActionTemplateInterface;
  }) {
    super({
      propertiesFromJSON,
      context,
      template
    });
  }
}
