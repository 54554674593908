import {
  FETCH_AUTOMATION_FOR_IDEA,
  SHOW_MODAL,
  CLEAR_CURRENT_AUTOMATION,
  SET_STATUS,
  DELETE_AUTOMATION,
  FETCH_ALL_PHASES_AND_STATUSES,
  SET_PIPELINE_PAGE_TYPE,
  CLEAR_PIPELINE_PAGE_TYPE
} from "../constants/actionTypes";

import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import { loadModal } from "./modalActions";

export const fetchAutomationForIdea = (params, cb) => {
  const {
    automation,
    modalType,
    statusChangeId,
    modalProps = {},
    errorCallback
  } = params;

  return dispatch => {
    dispatch({
      type: FETCH_AUTOMATION_FOR_IDEA,
      payload: { automation, statusChangeId, cb, errorCallback }
    });
    dispatch(loadModal(modalType, modalProps));
  };
};

export const fetchDeleteAutomationModal = (params, cb) => {
  const { automation, modalType, errorCallback } = params;

  return dispatch => {
    dispatch({
      type: FETCH_AUTOMATION_FOR_IDEA,
      payload: { automation, cb, errorCallback }
    });
    dispatch({
      type: SHOW_MODAL,
      modalType
    });
  };
};

export const clearCurrentAutomation = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_CURRENT_AUTOMATION
    });
  };
};

export const fetchAllPhasesAndStatuses = () => {
  return dispatch => {
    axios
      .get(`/api/v1/phases/`)
      .then(resp => {
        dispatch({
          type: FETCH_ALL_PHASES_AND_STATUSES,
          payload: resp.data.data
        });
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
};

export const setPageType = pageType => {
  return dispatch => {
    dispatch({
      type: SET_PIPELINE_PAGE_TYPE,
      payload: pageType
    });
  };
};

export const clearPageType = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_PIPELINE_PAGE_TYPE
    });
  };
};

export const deleteAutomationAction = (param, callback, errorCallback) => {
  const { slug, comment } = param;

  return dispatch => {
    const url = `api/v1/processes/slug/${slug}`;
    axios
      .delete(url, {
        data: {
          comment: comment
        }
      })
      .then(resp => {
        dispatch({
          type: DELETE_AUTOMATION,
          payload: resp.data.data
        });

        if (callback) {
          callback(resp.data.data);
        }
      })
      .catch(err => {
        apiErrorAlert(err);
        if (errorCallback) {
          errorCallback(err);
        }
      });
  };
};

export const deleteAutomation = async ({
  slug,
  comment
}: {
  slug: string;
  comment?: string;
}) => {
  const url = `api/v1/processes/slug/${slug}`;
  await axios.delete(url, {
    data: {
      comment: comment
    }
  });
};

export const setStatus = (params, cb) => {
  const {
    slug,
    statusChangeId,
    errorCallback,
    reason,
    noAdditionalBEActions
  } = params;
  const requestData: any = {};

  if (reason) {
    requestData.reason = reason;
  }

  if (noAdditionalBEActions) {
    requestData.noAdditionalBEActions = true;
  }

  return dispatch => {
    axios
      .patch(`api/v1/co/process/${slug}/status/${statusChangeId}`, {
        ...requestData
      })
      .then(resp => {
        dispatch({
          type: SET_STATUS,
          payload: resp.data.data
        });

        if (cb) {
          cb(resp.data.data);
        }
      })
      .catch(err => {
        apiErrorAlert(err);
        if (errorCallback) {
          errorCallback(err);
        }
      });
  };
};
