import {
  FETCH_EXPLORE_AUTOMATIONS_DATA,
  CLEAR_EXPLORE_AUTOMATIONS_DATA,
  FETCH_EXPLORE_AUTOMATIONS_PHASE_FILTERS,
  FETCH_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS,
  FETCHING_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS,
  FETCH_ALL_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS,
  FETCH_EXPLORE_AUTOMATIONS_ADDITIONAL_FILTERS,
  SET_EXPLORE_AUTOMATIONS_IS_LOADING_DATA,
  CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS,
  CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS_BY_L1
} from "../constants/actionTypes";

const INITIAL_STATE = {
  exploreAutomationsData: null,
  exploreAutomationsPhaseFilters: null,
  exploreAutomationApplicationFilterData: null,
  exploreAutomationBusinessUnitFilterData: null,
  exploreAutomationSubmissionTypesFilterData: null,
  isLoadingExploreAutomations: false,
  isLoadingApplicationFilters: false
};

//TODO: remove this once backend creates endpoint that can fetch applications based
//on multiple L1 ids

const addL1ToApplicationIds = (applicationsData, l1Id) => {
  const applications = applicationsData && applicationsData.applications;

  return (applications || []).map(applicationsData => ({
    ...applicationsData,
    l1Id
  }));
};

const removeApplicationsByL1 = (applications, l1Id, selectedL1s) => {
  return (applications || []).filter(application => {
    if (application && application.l1Id) {
      return application.l1Id !== l1Id;
    }

    if (selectedL1s) {
      const taxonomyL1s = (application && application.taxonomyL1s) || [];

      return !(
        taxonomyL1s.indexOf(l1Id) > -1 &&
        !taxonomyL1s.find(currL1 => currL1 !== l1Id)
      );
    }

    return true;
  });
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_EXPLORE_AUTOMATIONS_DATA:
      return {
        ...state,
        exploreAutomationsData: action.payload
      };
    case FETCH_EXPLORE_AUTOMATIONS_PHASE_FILTERS:
      return {
        ...state,
        exploreAutomationsPhaseFilters: action.payload
      };
    case FETCH_EXPLORE_AUTOMATIONS_ADDITIONAL_FILTERS:
      const { businessUnits, submissionTypes } = action.payload;

      return {
        ...state,
        exploreAutomationBusinessUnitFilterData: businessUnits,
        exploreAutomationSubmissionTypesFilterData: submissionTypes
      };
    case FETCH_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS:
      const { id, applicationsData } = action.payload;
      //TODO: remove this once backend creates endpoint that can fetch applications based
      //on multiple L1 ids
      const oldApplications =
        (state.exploreAutomationApplicationFilterData &&
          state.exploreAutomationApplicationFilterData.applications) ||
        [];

      const updatedApplicationsData = applicationsData
        ? {
            ...applicationsData,
            applications: oldApplications
              .map(application => ({ ...application }))
              .concat(addL1ToApplicationIds(applicationsData, id))
          }
        : null;

      return {
        ...state,
        exploreAutomationApplicationFilterData: updatedApplicationsData,
        isLoadingApplicationFilters: false
      };
    case FETCH_ALL_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS:
      return {
        ...state,
        exploreAutomationApplicationFilterData: action.payload,
        isLoadingApplicationFilters: false
      };
    case FETCHING_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS:
      return {
        ...state,
        isLoadingApplicationFilters: true
      };
    case CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS:
      return {
        ...state,
        exploreAutomationApplicationFilterData: null
      };
    //TODO: remove this once backend creates endpoint that can fetch applications based
    //on multiple L1 ids
    case CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS_BY_L1: {
      const { id, selectedL1s } = action.payload;
      const exploreAutomationApplicationFilterData =
        state.exploreAutomationApplicationFilterData || {};
      const updatedApplications = removeApplicationsByL1(
        exploreAutomationApplicationFilterData.applications,
        id,
        selectedL1s
      );

      const updatedApplicationsData =
        updatedApplications.length > 0
          ? {
              ...exploreAutomationApplicationFilterData,
              applications: updatedApplications
            }
          : null;

      return {
        ...state,
        exploreAutomationApplicationFilterData: updatedApplicationsData
      };
    }
    case CLEAR_EXPLORE_AUTOMATIONS_DATA:
      return {
        ...state,
        exploreAutomationsData: null,
        isLoadingAutomations: false
      };

    case SET_EXPLORE_AUTOMATIONS_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingExploreAutomations: action.payload
      };
    default:
      return state;
  }
}
