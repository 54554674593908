import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL,
  AUTOMATION_PROFILE_DOCUMENTATION_URL,
  AUTOMATION_PROFILE_COMPONENTS_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const newContentNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = newContentNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.NEW_CONTENT,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const newContentNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.ASSESSMENT_SCORE_GENERATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER, CORoleType.AP_COLLABORATOR]
          },
          legacy_notification_id: 30,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSESSMENT_SCORE_GENERATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessment score generated"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " marked the detailed assessment of the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " as being complete."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.OVERVIEW_SECTION_INFO_CHANGED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 58,
          platform_event_type: COPlatformEventType.PROCESS_UPDATED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.OVERVIEW_SECTION_INFO_CHANGED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Change of info in Overview section"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the Overview information in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.AP_ABOUT_PAGE_UPDATED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 60,
          platform_event_type: COPlatformEventType.PROCESS_UPDATED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.AP_ABOUT_PAGE_UPDATED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "An update was performed on the AP - About page"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the About page in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.NEW_DOCUMENT_ADDED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 62,
          platform_event_type: COPlatformEventType.PROCESS_DOCUMENT_ADDED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_DOCUMENT_ADDED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New document added in AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added the document " +
              COActionReplacementStrings.DOCUMENT_TITLE +
              " in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_DOCUMENTATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.DOCUMENT_UPDATED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 64,
          platform_event_type: COPlatformEventType.PROCESS_DOCUMENT_UPDATED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.DOCUMENT_UPDATED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Document was updated in AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the document " +
              COActionReplacementStrings.DOCUMENT_TITLE +
              " in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_DOCUMENTATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.NEW_REUSABLE_COMPONENT_ADDED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.COMPONENT_AUTHOR,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 106,
          platform_event_type: COPlatformEventType.REUSABLE_COMPONENT_ADDED,
          notification_category_type:
            CONotificationActionCategoryTypes.PROCESS_COMPONENT,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_REUSABLE_COMPONENT_ADDED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New reusable component added in AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added the reusable component " +
              COActionReplacementStrings.COMPONENT_NAME +
              " in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COMPONENTS_URL
          }
        }
      };
    }
  }
  return undefined;
};
