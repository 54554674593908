import { UserRoleDecorated } from "../constants/userRolesPermissionsConstants";
import {
  LocalizationHashMapInterface,
  getLocalizationDataFromValue
} from "@uipath/ah-co";

export const USER_ROLE_LOCALIZATION_HASH_MAP: LocalizationHashMapInterface = {
  "-1058605390": {
    localizationKey: "collaborator_cell_role_be_ah-submitter",
    fallbackValue: "Employee Idea Submitter"
  },
  "626890114": {
    localizationKey: "collaborator_cell_role_be_ah-process-owner",
    fallbackValue: "Process Owner"
  },
  "981343590": {
    localizationKey: "collaborator_cell_role_be_ah-project-manager",
    fallbackValue: "Project Manager"
  },
  "325506082": {
    localizationKey: "collaborator_cell_role_be_ah-business-analyst",
    fallbackValue: "Business Analyst"
  },
  "161506830": {
    localizationKey: "collaborator_cell_role_be_ah-solution-architect",
    fallbackValue: "Solution Architect"
  },
  "707351949": {
    localizationKey: "collaborator_cell_role_be_ah-rpa-developer",
    fallbackValue: "Rpa Developer"
  },
  "-1505082158": {
    localizationKey: "collaborator_cell_role_be_ah-citizen-developer",
    fallbackValue: "Citizen Developer"
  },
  "126342890": {
    localizationKey: "collaborator_cell_role_be_ah-process-mining-submitter",
    fallbackValue: "Process Mining Submitter"
  },
  "1760311892": {
    localizationKey: "collaborator_cell_role_be_ah-task-mining-submitter",
    fallbackValue: "Task Mining Submitter"
  },
  "-1876478745": {
    localizationKey: "user_role_name_ah-top-down-submitter",
    fallbackValue: "CoE Idea Submitter"
  },
  "-901213413": {
    localizationKey: "user_role_name_ah-automation-submitter",
    fallbackValue: "Citizen Developer Submitter"
  },
  "-2031268322": {
    localizationKey: "user_role_name_ah-system-admin",
    fallbackValue: "System Admin"
  },
  "-1563968832": {
    localizationKey: "user_role_name_ah-account-owner",
    fallbackValue: "Account Owner"
  },
  "-1781640803": {
    localizationKey: "user_role_name_ah-rpa-sponsor",
    fallbackValue: "RPA Sponsor"
  },
  "1050378035": {
    localizationKey: "user_role_name_ah-curator",
    fallbackValue: "Component Curator"
  },
  "-1131129391": {
    localizationKey: "user_role_name_ah-program-manager",
    fallbackValue: "Program Manager"
  },
  "635273678": {
    localizationKey: "user_role_name_ah-idea-approver",
    fallbackValue: "Idea Approver"
  },
  "1933663024": {
    localizationKey: "user_role_name_ah-authorized-user",
    fallbackValue: "Authorized User"
  },
  "-1252782258": {
    localizationKey: "user_role_name_ah-standard-user",
    fallbackValue: "Standard User"
  },
  "1294948945": {
    localizationKey: "user_role_name_ah-cd-self-user",
    fallbackValue: "Citizen Developer Self User"
  },
  "289913428": {
    localizationKey: "user_role_name_ah-cd-power-user",
    fallbackValue: "Citizen Developer Power User"
  },
  "-2030957979": {
    localizationKey: "user_role_name_ah-business-reviewer",
    fallbackValue: "Business Reviewer"
  },
  "-525973592": {
    localizationKey: "user_role_name_ah-technical-reviewer",
    fallbackValue: "Technical Reviewer"
  },
  "-1888882997": {
    localizationKey: "user_role_description_ah-account-owner",
    fallbackValue:
      "The first user of the tenant automatically receives the Account Owner role, allowing them to edit the Tenant Settings page, as well as manage the subscription plan for the tenant."
  },
  "-1059778681": {
    localizationKey: "user_role_description_ah-rpa-sponsor",
    fallbackValue:
      "RPA Sponsors have read-only rights for viewing all the information around the automation ideas and the reusable components, access to the built-in Dashboards and Reports."
  },
  "2075292642": {
    localizationKey: "user_role_description_ah-curator",
    fallbackValue:
      "Componnent Curators are responsible for curating the flow of RPA reusable components within UiPath Automation Hub."
  },
  "-1029818903": {
    localizationKey: "user_role_description_ah-program-manager",
    fallbackValue:
      "Program Managers can view and edit the automation ideas, decide what ideas should progress in the implementation phases, assign implementation teams."
  },
  "106147592": {
    localizationKey: "user_role_description_ah-idea-approver",
    fallbackValue:
      "Idea Approvers push forward ideas by identifying duplicates and approving ideas they endorse for a detailed assessment."
  },
  "345585980": {
    localizationKey: "user_role_description_ah-authorized-user",
    fallbackValue:
      "Authorized Users target Subject Matter Experts, Process Owners, RPA Business Analysts, Process Consultants who know the process in detail and have access to Submit a CoE-driven idea, in order to submit their idea by directly filling in the detailed assessment."
  },
  "1085414754": {
    localizationKey: "user_role_description_ah-standard-user",
    fallbackValue:
      "It gives access to submitting automation ideas and reusable components, as well as exploring the pipeline of automation ideas, the gallery of reusable components and the enterprise community of Automation Hub users."
  },
  "1446087260": {
    localizationKey: "user_role_description_ah-process-owner",
    fallbackValue:
      "As a Process Owner you are responsible for:\n" +
      "- Submitting the detailed assessment,\n" +
      "- Perform updates on the About page as needed,\n" +
      "- Provide complete and accurate documentation in relation to the process,\n" +
      "- Participate in UAT."
  },
  "-641629205": {
    localizationKey: "user_role_description_ah-submitter",
    fallbackValue:
      "Role automatically assigned to any user submitting an idea. Gives access to contribute with documentation."
  },
  "-1441950466": {
    localizationKey: "user_role_description_ah-top-down-submitter",
    fallbackValue:
      "Role automatically assigned to any user submitting a CoE idea."
  },
  "296460815": {
    localizationKey: "user_role_description_ah-project-manager",
    fallbackValue:
      "As a Project Manager you are in charge of:\n" +
      "- Allocating collaborators to the automation initiative,\n" +
      "- Performing and updated the cost-benefit analysis,\n" +
      "- Keep track of the implementation progress\n" +
      "- Keep stakeholders informed and manage their expectations."
  },
  "234904142": {
    localizationKey: "user_role_description_ah-business-analyst",
    fallbackValue:
      "As a Business Analyst you will gather the business requirements and understand the process AS IS, as well as document the TO BE process post automation. You will:\n" +
      "- Help business users/process owners complete the assessment for the process prior to automation;\n" +
      "- Perform in-depth analysis of the process once it is in implementation and capture all requirements and your recommendations for optimization in the Process Definition Document;\n" +
      "- Prepare test cases and conduct tests on the developed solution to make sure that it meets the acceptance criteria."
  },
  "507544864": {
    localizationKey: "user_role_description_ah-solution-architect",
    fallbackValue:
      "As a Solution Architect you will design the technical architecture for the automation solution and document it in the Solution Design Document. Your focus when developing the architecture will be on:\n" +
      "- Robustness;\n" +
      "- Scalability;\n" +
      "- Efficiency;\n" +
      "- Replicability;\n" +
      "- Reusability of components."
  },
  "974637327": {
    localizationKey: "user_role_description_ah-rpa-developer",
    fallbackValue:
      "As an RPA Developer you will be coding the solution, testing as well as providing guidance on process design. You will:\r\n" +
      "- Document your code in the Development Specifications Document;\r\n" +
      "- Create and link reusable components to the automation initiative;\r\n" +
      "- Fix bugs and provide support during UAT and hypercare period;\r\n" +
      "- Create the Move to Production checklist."
  },
  "-1912343169": {
    localizationKey: "user_role_description_ah-system-admin",
    fallbackValue:
      "System Admins can view and edit everything within their UiPath Automation Hub tenant instance. They are primarily responsible for managing users and doing the platform configuration."
  },
  "1483255779": {
    localizationKey: "user_role_description_ah-cd-self-user",
    fallbackValue:
      "Citizen Developer Self Users automate tasks for themselves, on top of their day to day job. They are capable to automate low risk, simple task/activities for own usage only. They have access to Share an automation that they have worked on."
  },
  "727726335": {
    localizationKey: "user_role_description_ah-cd-power-user",
    fallbackValue:
      "Citizen Developer Power Users automate tasks or processes for others and for themselves, on top of their day to day job. They are capable of automating low risk, simple/medium task/activities or processes for users from own department/team. They have access to Share an automation and to the Automation Pipeline to view and select automations that they want to develop."
  },
  "-997364602": {
    localizationKey: "user_role_description_ah-business-reviewer",
    fallbackValue:
      "Business Reviewers push forward automations created by a Citizen Developer Self User and select which automations are worth being endorsed for a technical review."
  },
  "-1099327993": {
    localizationKey: "user_role_description_ah-technical-reviewer",
    fallbackValue:
      "Technical Reviewers push forward automations by identifying code quality issues and approving automations for large consumption."
  },
  "1767234543": {
    localizationKey: "user_role_description_ah-automation-submitter",
    fallbackValue:
      "Role automatically assigned to any user sharing an automation."
  },
  "-1606548866": {
    localizationKey: "user_role_description_ah-citizen-developer",
    fallbackValue:
      "As a Citizen Developer you will:\n" +
      "- Start development for the automations that you have selected;\n" +
      "- Update the Source Code file;\n" +
      "- Document your code in the Development Specifications Document;\n" +
      "- Create and link reusable components to the automation initiative;\n" +
      "- Fix bugs and provide support during UAT and hypercare period;\n" +
      "- Create the Move to Production checklist."
  },
  "-761222326": {
    localizationKey: "user_role_description_ah-process-mining-submitter",
    fallbackValue:
      "Role automatically assigned to any user submitting a Process Mining idea."
  },
  "-1433815152": {
    localizationKey: "user_role_description_ah-task-mining-submitter",
    fallbackValue:
      "Role automatically assigned to any user submitting a Task Mining idea."
  }
};

export const getLocalizedRoleName = (
  t: any,
  roleObj: UserRoleDecorated | undefined,
  value: string
) => {
  if (!roleObj) {
    return "";
  }
  // check updated role name against hashmap
  const localizedRoleHash = getLocalizationDataFromValue(
    value,
    USER_ROLE_LOCALIZATION_HASH_MAP
  );

  const localizedRoleNameKey = localizedRoleHash.localizationKey;
  const localizedRoleNameFallbackValue = localizedRoleHash.fallbackValue;

  // if there's a localization key for the name, it means the name has not been changed so use that
  // otherwise, use the new updated role name / backup value
  const backupValue =
    roleObj.role_name_updated ||
    localizedRoleNameFallbackValue ||
    roleObj.role_name ||
    "";
  const roleName = localizedRoleNameKey
    ? t(localizedRoleNameKey || "", backupValue || "")
    : backupValue;
  return roleName;
};

export const getLocalizedRoleDescription = (
  t: any,
  roleObj: UserRoleDecorated | undefined,
  value: string
) => {
  if (!roleObj) {
    return "";
  }
  // check updated role description against hashmap
  const localizedRoleHash = getLocalizationDataFromValue(
    value,
    USER_ROLE_LOCALIZATION_HASH_MAP
  );

  const localizedRoleDescriptionKey = localizedRoleHash.localizationKey;

  const localizedRoleDescriptionFallbackValue = localizedRoleHash.fallbackValue;

  const backupValue =
    roleObj.role_description_updated ||
    localizedRoleDescriptionFallbackValue ||
    roleObj.role_description ||
    "";

  const roleDescription = localizedRoleDescriptionKey
    ? t(localizedRoleDescriptionKey || "", backupValue || "")
    : backupValue;

  return roleDescription;
};
