import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import {
  swalSpinnerLoading,
  swalSpinnerSuccess,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";

import {
  FETCH_ADMIN_USERS_DATA,
  FETCH_MORE_ADMIN_USERS_DATA,
  SET_ADMIN_USERS_COUNTER_TOTAL,
  CLEAR_ADMIN_USERS_COUNTER_TOTAL,
  CLEAR_ADMIN_USERS_DATA,
  ADMIN_USER_MANAGER_POPUP_SHOWING,
  FETCH_TENANT,
  UPDATE_TENANT,
  SET_ADMIN_USER_OBJECT
} from "../constants/actionTypes";
import { TenantInterface } from "../types/tenantTypes";

export function fetchAdminUsers(queryParams = "", otherParams = {}) {
  return function(dispatch) {
    axios
      .get(`/api/v1/admin/users?${queryParams}`, otherParams)
      .then(response => {
        dispatch({
          type: FETCH_ADMIN_USERS_DATA,
          payload: response.data.data
        });
        dispatch({
          type: SET_ADMIN_USERS_COUNTER_TOTAL,
          payload: response.data.data
        });
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}

export function fetchMoreAdminUsers(queryParams = "") {
  return function(dispatch) {
    axios
      .get(`/api/v1/admin/users?${queryParams}`)
      .then(response => {
        dispatch({
          type: FETCH_MORE_ADMIN_USERS_DATA,
          payload: response.data.data
        });
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}

export function syncAdminUsers(data, callback) {
  return dispatch => {
    swalSpinnerLoading({});

    let path = "/api/v1/admin/users/sync";
    axios
      .post(path, data)
      .then(resp => {
        if (typeof callback === "function") {
          callback(resp);
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}
export function resendInviteEmail(userId, callback) {
  return dispatch => {
    let path = `/api/v1/users/${userId}/email`;
    axios
      .post(path)
      .then(resp => {
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}

export function setAdminUserObject(user) {
  return function(dispatch) {
    dispatch({
      type: SET_ADMIN_USER_OBJECT,
      payload: user
    });
  };
}

export function clearAdminUsers() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ADMIN_USERS_DATA,
      payload: null
    });
    dispatch({
      type: CLEAR_ADMIN_USERS_COUNTER_TOTAL
    });
  };
}

export const toggleManagerPopup = params => {
  return dispatch => {
    dispatch({
      type: ADMIN_USER_MANAGER_POPUP_SHOWING,
      payload: params
    });
  };
};

export function fetchTenantToEdit(slug) {
  let path = "/api/v1/admin/tenant";
  return function(dispatch) {
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_TENANT,
          payload: resp.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function modifyEditedTenant(data: TenantInterface) {
  let path = "/api/v1/admin/tenant";
  return dispatch => {
    swalSpinnerLoading();
    axios
      .patch(path, data)
      .then(resp => {
        swalSpinnerSuccess();
        dispatch({
          type: UPDATE_TENANT,
          payload: resp.data.data.tenant_company_logo
        });
        setTimeout(() => {
          swalSpinnerClose();
        }, 1000);
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}
