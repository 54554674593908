import React, { Component } from "react";
import { Chip } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { uploadFile } from "./../../../actions/fileTransferActions";
import swal from "./../../SweetAlert/SweetAlert";
import linkIcon from "./../../../assets/images/link_icon.svg";
import { downloadFile } from "./../../../actions/fileTransferActions";
import { closeSweetAlertLoader } from "../../../utils/sweetAlertLoader";
import InputTextApollo from "../../../components/inputs/InputTextApollo/InputTextApollo";
import "./InputUploadEditFileList.scss";
import { renderPrivacyTextComponent } from "../../../utils/reusableStaticComponents";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import IconApollo from "../../IconApollo/IconApollo";

const PRIVACY_TEXT_STYLING = {
  background: "none",
  padding: "0",
  fontWeight: 400
};

class InputUploadEditFileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files ? [...this.props.files] : []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.files && this.props.files) {
      this.setState(prevState => {
        const state = { ...prevState };
        state.files = [...this.props.files];
        return state;
      });
    }
    if (prevState !== this.state) {
      const mediaData = {
        field: this.props.field || "files",
        data: [...this.state.files]
      };
      this.props.setData(mediaData);
    }
  }

  handleFileSelection = e => {
    if (e.target.files[0]) {
      const { sizeLimit } = this.props;
      const humanizedFileSize = sizeLimit / 1000000;

      if (sizeLimit && e.target.files[0].size > sizeLimit) {
        swal.fire({
          type: "error",
          title: this.props.t([
            "error_text_file_too_large",
            "File must be smaller than 300MB."
          ]),
          text: this.props.t(
            [
              "error_text_file_too_large_customize",
              "File must be smaller than {{humanizedFileSize}}MB."
            ],
            {
              humanizedFileSize: humanizedFileSize
            }
          )
        });
      } else {
        const params = {
          file: e.target.files[0],
          type: this.props.fileType
        };
        this.props.uploadFile(params, this.fileUploadCallback, null);
      }
      e.target.value = null;
    }
  };

  fileUploadCallback = data => {
    this.setState(prevState => {
      const state = { ...prevState };
      state.files = [{ file: data }, ...state.files];
      return state;
    });
  };

  uploadFileClick = e => {
    e.target.parentNode.click();
  };

  removeItem = index => {
    swal
      .fire({
        type: "warning",
        title: this.props.t(["warning_title_are_you_sure", "Are you sure?"]),
        text: this.props.t([
          "warning_text_remove_file",
          "Are you sure you want to remove this file?"
        ]),
        showCancelButton: true
      })
      .then(e => {
        if (e.value) {
          this.setState(prevState => {
            const state = { ...prevState };
            state.files.splice(index, 1);
            return state;
          });
        }
      });
  };

  renderUploadButton = () => {
    if (this.props.notEditable) {
      return "";
    }
    const { acceptFileTypes = "*", fileLimit } = this.props;
    if (
      fileLimit &&
      fileLimit <= this.state.files.length &&
      this.state.files[0] &&
      this.state.files[0].file &&
      this.state.files[0].file.originalname
    ) {
      return (
        <ButtonApolloSecondary disabled>
          <IconApollo
            className="InputUploadEditFileList__upload-icon"
            icon="cloud_upload"
            outlined
            fontSize="small"
          />
          {this.props.t(["input_upload_widget_upload_file_button", "Upload"])}
        </ButtonApolloSecondary>
      );
    }

    return (
      <label
        className="InputUploadEditFileList__button"
        data-testid="InputUploadEditFileList__Button"
      >
        <input
          type="file"
          className="InputUploadEditFileList__input"
          data-testid="InputUploadEditFileList__Input"
          accept={acceptFileTypes}
          onChange={this.handleFileSelection}
          disabled={!!this.props.link}
        />
        <div className="InputUploadEditFileList_button-link-wrapper">
          <div className="InputUploadEditFileList__button-wrapper">
            <ButtonApolloSecondary
              disabled={!!this.props.link}
              onClick={this.uploadFileClick}
            >
              <IconApollo
                className="InputUploadEditFileList__upload-icon"
                icon="cloud_upload"
                outlined
                fontSize="small"
              />
              {this.props.t([
                "input_upload_widget_upload_file_button",
                "Upload"
              ])}
            </ButtonApolloSecondary>
          </div>
          {this.props.showLink && (
            <React.Fragment>
              <div className="InputUploadEditFileList__or-text">
                <div>{this.props.t(["upload_component_or_text", "Or"])}</div>
              </div>
              <div className="InputUploadEditFileList__file-link-wrapper">
                <InputTextApollo
                  type="text"
                  InputProps={{
                    onChange: this.props.onChange,
                    value: this.props.link
                  }}
                  className="InputUploadEditFileList__file-link"
                  placeholder={this.props.t([
                    "upload_component_link_placeholder",
                    "Insert file link"
                  ])}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </label>
    );
  };

  downloadFileClick = obj => {
    const params = {
      fileID: obj.file.file_id,
      fileName: obj.file.filename
    };

    this.props.downloadFile(params, this.handleDownloadCallback);
  };

  handleDownloadCallback = () => {
    closeSweetAlertLoader();
  };

  renderFilesList = () => {
    return this.state.files.map((obj, i) => {
      if (obj.file && !!obj.file.originalname) {
        return (
          <div key={"file" + i} className="InputUploadEditFileList__item">
            <Chip
              icon={
                <button
                  className="InputUploadEditFileList__download"
                  onClick={() => this.downloadFileClick(obj)}
                  data-index={i}
                >
                  <IconApollo
                    data-index={i}
                    icon="file_download"
                    outlined
                    alt={this.props.t([
                      "sr_alt_text_download_file",
                      "Download file"
                    ])}
                  />
                </button>
              }
              label={(obj.file && obj.file.originalname) || ""}
              onDelete={() => this.removeItem(i)}
            />
          </div>
        );
      }
      if (obj.file && obj.file.originalname === undefined) return "";

      //in View mode
      if (!obj.file && !!this.props.fileLink && this.props.notEditable) {
        return (
          <div
            key={this.props.fileLink || "file-item" + i}
            className="InputUploadEditFileList__item"
          >
            <Chip
              icon={
                <a
                  href={this.props.fileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="InputUploadEditFileList__download"
                >
                  <img data-index={i} src={linkIcon} alt="link" />
                </a>
              }
              label={this.props.fileLink}
            />
          </div>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <div className="InputUploadEditFileList">
        {this.props.hideUploadBtn || this.renderUploadButton()}
        {this.renderFilesList()}
        {renderPrivacyTextComponent({
          style: PRIVACY_TEXT_STYLING,
          t: this.props.t
        })}
      </div>
    );
  }
}

const mapDispatchToProps = {
  uploadFile,
  downloadFile
};

export default withTranslation("common")(
  connect(null, mapDispatchToProps)(InputUploadEditFileList)
);
