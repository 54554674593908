import React from "react";
import { isNullOrUndefined } from "util";
import InputTextWithLabel from "../inputs/InputTextWithLabel/InputTextWithLabel";
import InputSelectWithLabel from "../inputs/InputSelectWithLabel/InputSelectWithLabel";
import InputRadioButtonsWithLabel from "../inputs/InputRadioButtonsWithLabel/InputRadioButtonsWithLabel";
import { generateParsedOptions } from "../../utils/accountLicenseUtils";

import "./FieldsListAccountLicense.scss";

const DROPDOWN_CLASSNAME_PREFIX = "FieldsListAccountLicense";

const setWithFirstValueIfNull = (options, { value, required }) => {
  if (
    options instanceof Array &&
    options.length > 0 &&
    isNullOrUndefined(value)
  ) {
    return required ? options[0] : value;
  }

  return value;
};

const generateFields = (
  { onChange, onDropdownChange, dropdownOptions, fields },
  idx
) => {
  return Object.keys(fields || {}).map(key => {
    const fieldProps = fields[key];
    let InputComponent;

    switch (fieldProps.type) {
      case "dropdown":
        InputComponent = InputSelectWithLabel;
        fieldProps.onChange = onDropdownChange;
        fieldProps.classNamePrefix = DROPDOWN_CLASSNAME_PREFIX;
        fieldProps.options = generateParsedOptions(
          fieldProps.name,
          dropdownOptions
        );
        fieldProps.value = setWithFirstValueIfNull(
          fieldProps.options,
          fieldProps
        );
        break;
      case "radio":
        InputComponent = InputRadioButtonsWithLabel;
        fieldProps.onChange = onChange;
        break;
      default:
        InputComponent = InputTextWithLabel;
        fieldProps.onChange = onChange;
        break;
    }

    return (
      <div
        className={`FieldsListAccountLicense__field FieldsListAccountLicense__field--${fieldProps.name}`}
        key={`fields-${fieldProps.name}-${idx}`}
      >
        <InputComponent {...fieldProps} />
      </div>
    );
  });
};

const FieldsListAccountLicense = props => {
  return (
    <div
      className="FieldsListAccountLicense"
      data-testid="FieldsListAccountLicenseContainer"
    >
      {generateFields(props)}
    </div>
  );
};

export default FieldsListAccountLicense;
