import React, { Component } from "react";
import { auth } from "./../../utils/auth";
import { withTranslation } from "react-i18next";
import SessionHelper from "../../helpers/SessionHelper";
import { FPS_BASE_URL } from "../../constants/envConstants";
import { featureFlag } from "../../helpers/FeatureFlag";
import NavAuthApolloContainer from "../NavAuth/NavAuthApolloContainer";
import ButtonApolloPrimary from "../buttons/ButtonApolloPrimary/ButtonApolloPrimary";

class NavLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      languageTabOpen: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    auth.authorize();
  };

  render() {
    const cloudTenant = SessionHelper.fpsTenantFromCurrentPath();
    const cloudAccount = SessionHelper.fpsAccountFromCurrentPath();
    const cloudAccessToken = SessionHelper.getUserCloudAccessToken();
    const isFpsUri = SessionHelper.isFpsUri();
    const isDarkThemeEnabled = featureFlag.isApolloDarkThemeEnabled();

    return (
      <div className="NavLogin" data-testid="NavLogin">
        <NavAuthApolloContainer
          product="Automation Hub"
          showTrademark={false}
          theme={isDarkThemeEnabled ? undefined : "light"}
          hideTenantPicker={isFpsUri ? "false" : "true"}
          isStandalone={isFpsUri ? "false" : "true"}
          hideAvatar={isFpsUri ? "false" : "true"}
          showHelpMenu={isFpsUri ? "true" : "false"}
          compactModeOverride={
            featureFlag.isApolloCompactHeaderOverrideEnabled()
              ? "true"
              : undefined
          }
          url={FPS_BASE_URL || ""}
          account={cloudAccount}
          tenant={cloudTenant}
          token={cloudAccessToken}
        >
          <form slot="slot-end" autoComplete="off" onSubmit={this.handleSubmit}>
            {!this.props.hideSignupButton && (
              <ButtonApolloPrimary
                className="NavLogin__button Login__button"
                type="submit"
              >
                {this.props.t(["landing_page_signin_button", "Sign in"])}
              </ButtonApolloPrimary>
            )}
          </form>
        </NavAuthApolloContainer>
      </div>
    );
  }
}

export default withTranslation("common")(NavLogin);
