import {
  CONotificationActionInterface,
  COLegacyActionInterface,
  CONotificationActionTemplateInterface,
  COProcess,
  COActionOptionsInterface,
  COActionContextValuesInterface,
  COUserActionInterface
} from "../../interfaces/co-interfaces";
import { CONotificationActionType } from "../../constants/actions/co-notification-action-constants";
import { CONotificationActionSlugs } from "../../constants/actions/co-notification-action-constants";
import { actionTemplate } from "../../templates/elements/actions/co-action-templates";
import { COPlatformEventType } from "../../constants/actions/co-platform-events-constants";
import { convertLegacyEventToCOPlatformEventType } from "./co-platform-events.helper";

export const convertLegacyNotificationTypeToCOActionType = ({
  legacy_type
}: {
  legacy_type: string;
}): string => {
  return legacy_type
    .toUpperCase()
    .split("-")
    .join("_");
};

export const convertLegacyNotificationToAction = ({
  legacy_action,
  targetUserIds,
  taggedUserIds,
  userAttributes
}: {
  legacy_action: COLegacyActionInterface;
  targetUserIds?: string[];
  taggedUserIds?: string[];
  userAttributes?: string;
}): CONotificationActionInterface | undefined => {
  const legacySlug: string | undefined = legacy_action.notification_slug;
  if (!legacySlug) {
    return undefined;
  }
  const actionSlug = legacySlug
    .toUpperCase()
    .split("-")
    .join("_");
  if (!actionSlug) {
    return undefined;
  }
  if (!Object.keys(CONotificationActionSlugs).includes(actionSlug)) {
    console.log(
      "Found an action slug that we don't have in the CO system: " + actionSlug
    );
    return undefined;
  }

  const template: CONotificationActionTemplateInterface = actionTemplate(
    actionSlug
  ) as CONotificationActionTemplateInterface;

  let comment_id: number | undefined = undefined;
  let component_comment_id: number | undefined = undefined;
  if (
    template.co_action_options_json?.notification_type &&
    template.co_action_options_json?.notification_type ===
      CONotificationActionType.COMPONENT_SUPPORT
  ) {
    // its a component comment
    component_comment_id = legacy_action.comment_id;
  } else {
    // its a normal comment
    comment_id = legacy_action.comment_id;
  }

  const options = template.co_options_json;
  if (options) {
    if (targetUserIds) {
      if (options.action_target) {
        options.action_target = {
          target_user_ids: targetUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          target_user_ids: targetUserIds
        };
      }
    }
    if (taggedUserIds) {
      if (options.action_target) {
        options.action_target = {
          mentioned_users: taggedUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          mentioned_users: taggedUserIds
        };
      }
    }
  }

  let co_user_action: COUserActionInterface = {};
  if (legacy_action.notification_has_viewed) {
    co_user_action = {
      co_user_action_options_json: {
        has_seen_action: 1
      }
    };
  }

  return {
    co_action_template_slug: actionSlug,
    co_action_type: template.co_action_template_co_type,
    co_action_meta_json: template.co_meta_json,
    co_action_options_json: options,
    template: template,
    created_epoch: legacy_action.created_epoch,
    updated_epoch: legacy_action.updated_epoch,
    legacy_data: legacy_action,
    co_user_action: co_user_action,
    co_action_context_values_json: {
      process_id: legacy_action.process_id,
      comment_id: comment_id,
      component_id: legacy_action.component_id,
      component_comment_id: component_comment_id,
      reference_user_id: legacy_action.reference_user_id,
      document_id: legacy_action.document_id,
      user_attributes: userAttributes,
      legacy_text_replacement_values:
        legacy_action.notification_text_replacements ??
        legacy_action.replaced_texts
    }
  };
};

export const getNotificationInterfaceForTemplate = ({
  template,
  targetUserIds,
  taggedUserIds,
  actionContextValues
}: {
  template: CONotificationActionTemplateInterface;
  targetUserIds?: string[];
  taggedUserIds?: string[];
  actionContextValues: COActionContextValuesInterface;
}): CONotificationActionInterface => {
  const options = template.co_options_json;
  if (options) {
    if (targetUserIds) {
      if (options.action_target) {
        options.action_target = {
          target_user_ids: targetUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          target_user_ids: targetUserIds
        };
      }
    }
    if (taggedUserIds) {
      if (options.action_target) {
        options.action_target = {
          mentioned_users: taggedUserIds,
          ...options.action_target
        };
      } else {
        options.action_target = {
          mentioned_users: taggedUserIds
        };
      }
    }
  }

  return {
    co_action_template_slug: template.co_action_template_slug,
    co_action_type: template.co_action_template_co_type,
    co_action_meta_json: template.co_meta_json,
    co_action_options_json: options,
    template: template,
    co_action_context_values_json: actionContextValues
  };
};

export const getNotificationTemplatesForPlatformEventSlug = ({
  eventSlug,
  process
}: {
  eventSlug: string;
  process?: COProcess;
}): CONotificationActionTemplateInterface[] => {
  const coEventSlug = convertLegacyEventToCOPlatformEventType({
    legacy_event: eventSlug
  });

  return Object.keys(CONotificationActionSlugs)
    .map(slug => {
      return actionTemplate(slug) as CONotificationActionTemplateInterface;
    })
    .filter(template => {
      if (template.co_options_json) {
        // check that the notification is active and not deleted
        const options: COActionOptionsInterface = template.co_options_json as COActionOptionsInterface;
        if (options.is_deleted === 1 || options.is_inactive === 1) {
          return false;
        }

        // check for event match
        if (template.co_options_json.platform_event_type === coEventSlug) {
          if (
            template.co_options_json.platform_event_type ===
            COPlatformEventType.PROCESS_PHASE_CHANGED
          ) {
            // if the event is PROCESS_PHASE_CHANGED, check the phase status triggers as well
            if (
              template.co_options_json?.platform_event_phase_status_triggers &&
              process?.phase_status_key &&
              template.co_options_json.platform_event_phase_status_triggers.includes(
                process.phase_status_key
              )
            ) {
              return true;
            }
          } else {
            // otherwise the event matches without any other checks
            return true;
          }
          return false;
        }
      }
    });
};

export const notificationIdForNotificationType = ({
  notificationType
}: {
  notificationType: string;
}): number | undefined => {
  switch (notificationType) {
    case CONotificationActionType.TASK_ASSIGNED_ORIGINAL: {
      return 1;
    }
    case CONotificationActionType.AUTOMATION_REVIEWED: {
      return 2;
    }
    case CONotificationActionType.FOLLOWED_AUTOMATION_UPDATES: {
      return 3;
    }
    case CONotificationActionType.COLLABORATION_INVITES: {
      return 4;
    }
    case CONotificationActionType.TASK_ASSIGNED: {
      return 5;
    }
    case CONotificationActionType.FOLLOWERS: {
      return 6;
    }
    case CONotificationActionType.UPVOTES: {
      return 7;
    }
    case CONotificationActionType.AUTOMATION_STATUS_UPDATE: {
      return 8;
    }
    case CONotificationActionType.NEW_CONTENT: {
      return 9;
    }
    case CONotificationActionType.COLLABORATORS_UPDATES: {
      return 10;
    }
    case CONotificationActionType.COMMENTS: {
      return 11;
    }
    case CONotificationActionType.MENTIONS: {
      return 12;
    }
    case CONotificationActionType.USER_PROFILE_UPDATES: {
      return 13;
    }
    case CONotificationActionType.ADMIN_CONSOLE_UPDATES: {
      return 14;
    }
    case CONotificationActionType.COMPONENT_SUPPORT: {
      return 15;
    }
    case CONotificationActionType.CONTENT_DELETION: {
      return 16;
    }
    case CONotificationActionType.GAMIFICATION: {
      return 17;
    }
    case CONotificationActionType.ADMIN_ROLE_INVITES: {
      return 18;
    }
    case CONotificationActionType.QUEUE_STATUS_UPDATES: {
      return 19;
    }
  }
  return undefined;
};
