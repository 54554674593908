import React, { Component } from "react";
import ModalContainer from "../components/Modal/ModalContainer";
import LevelUpModal from "../components/LevelUpModal/LevelUpModal";
import "./authLayout.scss";
import ErrorHandler from "../components/ErrorHandler/ErrorHandler";
import MasterLayoutWrapper from "./masterLayoutWrapper";
import NavLogin from "../components/NavLogin/NavLogin";

export default class AuthRedirectLayout extends Component {
  render() {
    return (
      <MasterLayoutWrapper>
        <div
          className="AuthLayout"
          data-testid="AuthRedirectLayout"
          style={{ padding: 0 }}
        >
          <NavLogin hideSignupButton />
          <ErrorHandler>
            {this.props.children}
            <ModalContainer />
            <LevelUpModal />
          </ErrorHandler>
        </div>
      </MasterLayoutWrapper>
    );
  }
}
