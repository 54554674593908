import {
  COContextInterface,
  CONotificationActionInterface,
  COActionTemplateInterface
} from "../../interfaces/co-interfaces";
import { COBaseAction } from "./co-base-action.class";
import { resolve } from "../../utils/co-resolver.utils";
import { resolvePathWithOverrides } from "../../utils/co-path.utils";

export interface CONotificationAction extends CONotificationActionInterface {}

export class CONotificationAction extends COBaseAction {
  static getClassName(): string {
    return "CONotificationAction";
  }
  constructor({
    propertiesFromJSON,
    context,
    template
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COActionTemplateInterface;
  }) {
    super({
      propertiesFromJSON,
      context,
      template
    });
  }
}
