import { COTaskActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COTaskActionSlugs,
  COTaskType,
  COTaskActivityType,
  COTaskPopUpMessageSource
} from "../../../../../constants/actions/co-task-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import {
  AUTOMATION_PROFILE_URL,
  COActionReplacementStrings
} from "../../../../../constants/actions/co-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";
import { COEmailReminderActionSlugs } from "../../../../../constants/actions/co-email-reminder-action-constants";

export const approveContentTaskActionTemplate = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  // get template based on slug
  let template = approveContentTaskActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each task
    template.co_options_json = {
      task_type: COTaskType.APPROVE_CONTENT,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const approveContentTaskActionTemplateFromSlug = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  switch (slug) {
    case COTaskActionSlugs.SUBMITTED_IDEA_TO_REVIEW_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.IDEA_APPROVER]
          },
          legacy_task_id: 1,
          task_activity_type_id: 1,
          task_activity_type: COTaskActivityType.SUBMITTED_IDEA_TO_REVIEW,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.IDEA_SUBMITTED,
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.IDEA_APPROVED,
              COPhaseStatusKeys.IDEA_REJECTED,
              COPhaseStatusKeys.IDEA_DUPLICATE,
              COPhaseStatusKeys.IDEA_ARCHIVED,
              COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
            ]
          },
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.SUBMITTED_IDEA_TO_REVIEW_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Submitted idea to review"
          },
          message: {
            value: "Review a new automation idea submitted"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.REVIEW_APPROVED_IDEA_TASK: {
      return {
        co_options_json: {
          is_inactive: 1,
          is_deleted: 1,
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_task_id: 2,
          task_activity_type_id: 2,
          task_activity_type: COTaskActivityType.REVIEW_APPROVED_IDEA,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_APPROVED
          ],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review approved idea"
          },
          message: {
            value:
              "Launch the assessment for an idea approved by the Direct Manager"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.REVIEW_ASSESSED_AUTOMATION_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_task_id: 5,
          task_activity_type_id: 5,
          task_activity_type: COTaskActivityType.REVIEW_ASSESSED_AUTOMATION,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
          ],
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.QUALIFICATION_APPROVED,
              COPhaseStatusKeys.QUALIFICATION_REJECTED
            ]
          },
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.REVIEW_ASSESSED_AUTOMATION_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review assessed automation"
          },
          message: {
            value:
              "Review an assessed automation with the Automation Advisory Board"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.REVIEW_COE_DRIVEN_IDEA_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER]
          },
          legacy_task_id: 34,
          task_activity_type_id: 21,
          task_activity_type: COTaskActivityType.REVIEW_COE_DRIVEN_IDEA,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.COE_DRIVEN_IDEA_SUBMITTED,
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
            ]
          },
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.REVIEW_COE_DRIVEN_IDEA_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review CoE driven idea"
          },
          message: {
            value: "Review a new automation idea submitted"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.CD_AUTOMATION_SHARED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.BUSINESS_REVIEWERS]
          },
          legacy_task_id: 36,
          task_activity_type_id: 23,
          task_activity_type: COTaskActivityType.CD_AUTOMATION_SHARED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.CD_AUTOMATION_SHARED,
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.BUSINESS_REVIEW_APPROVED,
              COPhaseStatusKeys.BUSINESS_REVIEW_ARCHIVED,
              COPhaseStatusKeys.BUSINESS_REVIEW_ON_HOLD,
              COPhaseStatusKeys.BUSINESS_REVIEW_REJECTED,
              COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW
            ]
          },
          settings_ids: [37],
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.CD_AUTOMATION_SHARED_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation shared"
          },
          message: {
            value: "Review a new automation submitted"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TECHNICAL_REVIEWERS]
          },
          legacy_task_id: 37,
          task_activity_type_id: 24,
          task_activity_type:
            COTaskActivityType.CD_AUTOMATION_TO_TECHNICAL_REVIEW,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW
          ],
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.TECHNICAL_REVIEW_ON_HOLD,
              COPhaseStatusKeys.TECHNICAL_REVIEW_REWORK_REQUIRED,
              COPhaseStatusKeys.TECHNICAL_REVIEW_ARCHIVED,
              COPhaseStatusKeys.LIVE_ONGOING
            ]
          },
          settings_ids: [37],
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation moved to technical review"
          },
          message: {
            value: "Review a new automation moved to technical review"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.CD_AUTOMATION_REJECTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_task_id: 38,
          task_activity_type_id: 25,
          task_activity_type: COTaskActivityType.CD_AUTOMATION_REJECTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.BUSINESS_REVIEW_REJECTED
          ],
          settings_ids: [37],
          task_popup_message_source:
            COTaskPopUpMessageSource.PROCESS_REJECTED_REASON,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation rejected"
          },
          message: {
            value: "Resubmit your idea following the feedback received"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.CD_AUTOMATION_REWORK_REQUIRED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_task_id: 39,
          task_activity_type_id: 26,
          task_activity_type: COTaskActivityType.CD_AUTOMATION_REWORK_REQUIRED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_REWORK_REQUIRED
          ],
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.TECHNICAL_REVIEW_ON_HOLD,
              COPhaseStatusKeys.TECHNICAL_REVIEW_ARCHIVED,
              COPhaseStatusKeys.QUALIFICATION_REJECTED,
              COPhaseStatusKeys.QUALIFICATION_APPROVED,
              COPhaseStatusKeys.DEVELOPMENT_NOT_STARTED
            ]
          },
          settings_ids: [37],
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.CD_AUTOMATION_REWORK_REQUIRED_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation rework required"
          },
          message: {
            value: "Review an automation with the Automation Advisory Board"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.CD_AUTOMATION_REQUESTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TECHNICAL_REVIEWERS]
          },
          legacy_task_id: 40,
          task_activity_type_id: 27,
          task_activity_type: COTaskActivityType.CD_AUTOMATION_REQUESTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.CD_AUTOMATION_REQUESTED,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation requested"
          },
          message: {
            value:
              "Make the automation available by the user who requested it: " +
              COActionReplacementStrings.USER_FULL_NAME
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.PUBLISH_AUTOMATION_TO_AUTOMATION_STORE_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TECHNICAL_REVIEWERS]
          },
          legacy_task_id: 41,
          task_activity_type_id: 28,
          task_activity_type:
            COTaskActivityType.PUBLISH_AUTOMATION_TO_AUTOMATION_STORE,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.LIVE_ONGOING
          ],
          settings_ids: [44, 45],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Publish automation to the Automation Store"
          },
          message: {
            value: "Publish a new automation to the Automation Store"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.REVIEW_CHANGE_REQUEST_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.PROGRAM_MANAGER,
              CORoleType.ASSESSED_IDEA_REVIEWERS
            ]
          },
          legacy_task_id: 42,
          task_activity_type_id: 29,
          task_activity_type: COTaskActivityType.REVIEW_CHANGE_REQUEST,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.CHANGE_REQUEST_SUBMITTED,
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.QUALIFICATION_APPROVED,
              COPhaseStatusKeys.QUALIFICATION_REJECTED
            ]
          },
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.REVIEW_CHANGE_REQUEST_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review change request"
          },
          message: {
            value: "Review a new change request submitted"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
