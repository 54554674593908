import {
  COCalculatedValueTypes,
  COComponentType,
  CODevelopmentTypes,
  COProcessSubmissionType
} from "./../../constants/co-constants";

import { customQuestionWithInfo } from "../co-custom-questions.template";

import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import {
  CODevelopmentTypeOptions,
  COExternalAnswerSources,
  COProcessSubmissionTypeInfo,
  COProcessSubmissionTypeSlugForType,
  COConditionSlugs
} from "../../constants/co-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  NonStandardQuestionSlugs,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";

export const customOVRInputQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.OVERVIEW_NAME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_NAME,
        standard_question_template_slug:
          NonStandardQuestionSlugs.OVR_AUTOMATION_NAME,
        title: "Automation name",
        description: "",
        note:
          "NOTE: This question cannot be deleted. It is required for any idea submission.",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED }
          ],
          prevent_deleting: 1,
          excluded_from_branching_paths_selection: 1
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_NAME
          ),
          co_question_position_json: {
            sort_order: 0
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_DESCRIPTION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_DESCRIPTION,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Description",
        description:
          "Describe your process and include any pre-conditions, inputs or triggers and outcomes.",
        note:
          "NOTE: If this question gets renamed the Automation Store profile keeps displaying the original question title.",
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.QUESTION_IS_REQUIRED }]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_DESCRIPTION
          ),
          co_question_position_json: {
            sort_order: 30
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_CATEGORY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_CATEGORY,
        standard_question_template_slug:
          NonStandardQuestionSlugs.OVR_CATEGORIES,
        title: "Category",
        description: "Categorize your idea",
        note:
          "NOTE: This question cannot be deleted. It is required for any idea submission.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_CATEGORY
          ),
          co_question_position_json: {
            sort_order: 35
          }
        },
        options_meta_overrides: {
          calculated_value_type: COCalculatedValueTypes.CATEGORY_ARRAY,
          external_answer_source: COExternalAnswerSources.CATEGORIES,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED }
          ],
          min_selectable_answers: 1,
          prevent_deleting: 1
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_AUTO_GENERATED_ID,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_AUTO_GENERATED_ID,
        standard_question_template_slug: StandardQuestionSlugs.PROCESS_PROPERTY,
        title: "Auto-generated ID",
        description:
          "Auto-generated from the database, can be used in API calls ",
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.AUTO_GENERATED_ID,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_AUTO_GENERATED_ID
          ),
          co_question_position_json: {
            sort_order: 10
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_PROCESS_PHASE_STATUS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_PROCESS_PHASE_STATUS,
        standard_question_template_slug: StandardQuestionSlugs.PROCESS_PROPERTY,
        title: "Phase status",
        description: "The Process Phase Status",
        options_meta_overrides: {
          prevent_deleting: 1,
          is_read_only: 1,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
            { slug: COConditionSlugs.HIDE_FOR_EDIT },
            { slug: COConditionSlugs.OVERRIDEN_BY_ANOTHER_COLUMN_IN_PIPELINE }
          ],
          excluded_from_branching_paths_selection: 1
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_PROCESS_PHASE_STATUS
          ),
          co_question_options_json: {
            external_answer_source: COExternalAnswerSources.PHASE_STATUS_KEY
          },
          co_question_position_json: {
            sort_order: 10
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_AUTOMATION_ID,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_AUTOMATION_ID,
        standard_question_template_slug: StandardQuestionSlugs.STANDARD_TEXT,
        title: "Automation ID",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_AUTOMATION_ID
          ),
          co_question_position_json: {
            sort_order: 20
          },
          co_question_options_json: {
            max_answer_length: 50
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_PAIN_POINTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_PAIN_POINTS,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Pain points",
        description:
          "Describe the inconvenience, tedium, and time consumption that affects the person(s) who have to perform the task or process.",
        note:
          "NOTE: If this question gets renamed the Automation Store profile keeps displaying the original question title.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_PAIN_POINTS
          ),
          co_question_position_json: {
            sort_order: 40
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_NEGATIVE_IMPACT,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_NEGATIVE_IMPACT,
        standard_question_template_slug: StandardQuestionSlugs.RICH_TEXT,
        title: "Negative impact",
        description:
          "Describe how the task or process affects the enterprise such as low morale, slow response time for customers which results in lower customer satisfaction or other downsides.",
        note:
          "NOTE: If this question gets renamed the Automation Store profile keeps displaying the original question title.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_NEGATIVE_IMPACT
          ),
          co_question_position_json: {
            sort_order: 50
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_PRIORITY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_PRIORITY,
        standard_question_template_slug: StandardQuestionSlugs.RANGED_NUMBER,
        title: "Priority",
        description: "The process priority",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_PRIORITY
          ),
          co_question_position_json: {
            sort_order: 60
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          prevent_deleting: 1,
          is_read_only: 1,
          external_answer_source: COExternalAnswerSources.PROCESS_PRIORITY,
          rounding_decimals: 0,
          max_value: 127,
          min_value: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.HIDE_FOR_EDIT },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS }
          ],
          excluded_from_branching_paths_selection: 1
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_DEVELOPMENT_TYPE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_DEVELOPMENT_TYPE,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Development type",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_DEVELOPMENT_TYPE
          ),
          co_question_position_json: {
            sort_order: 10
          },
          co_question_meta_json: {
            prefill: {
              value: CODevelopmentTypes.NONE
            }
          }
        },
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.DEVELOPMENT_TYPE
          }),
          prevent_deleting: 1,
          is_read_only: 1,
          external_answer_source:
            COExternalAnswerSources.PROCESS_DEVELOPMENT_TYPE,
          max_question_answer_options: 10,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.HIDE_FOR_EDIT },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS }
          ],
          excluded_from_branching_paths_selection: 1
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        answer_option_info: CODevelopmentTypeOptions.map(option => {
          return {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: option.development_type_name,
            value: option.development_type_slug,
            legacy_slug: option.development_type_slug,
            title_is_editable: false,
            prevent_deleting: 1
          };
        })
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Idea source",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMISSION_TYPE
          ),
          co_question_position_json: {
            sort_order: 10
          },
          co_question_meta_json: {
            prefill: {
              value: COProcessSubmissionType.EMPLOYEE_DRIVEN + ""
            }
          }
        },
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.SUBMISSION_TYPE
          }),
          prevent_deleting: 1,
          is_read_only: 1,
          external_answer_source:
            COExternalAnswerSources.PROCESS_SUBMISSION_TYPE,
          max_question_answer_options: 10,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
            { slug: COConditionSlugs.HIDE_FOR_EDIT },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS }
          ],
          excluded_from_branching_paths_selection: 1
        },
        answer_option_info: Object.keys(COProcessSubmissionTypeInfo).map(
          process_submission_type => {
            let option = COProcessSubmissionTypeInfo[process_submission_type];
            let slug =
              COProcessSubmissionTypeSlugForType[process_submission_type];
            return {
              answer_option_slug:
                StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
              title: option.title,
              value: process_submission_type + "",
              legacy_slug: slug,
              title_is_editable: false,
              prevent_deleting: 1
            };
          }
        )
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_TAGS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_TAGS,
        standard_question_template_slug: NonStandardQuestionSlugs.OVR_TAGS,
        title: "Tags",
        description: "",
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.TAGS,
          prevent_deleting: 0,
          is_read_only: 0,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
            { slug: COConditionSlugs.OVERRIDEN_BY_ANOTHER_COLUMN_IN_PIPELINE }
          ]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_TAGS
          ),
          co_question_position_json: {
            sort_order: 70
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMITTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMITTER,
        standard_question_template_slug: StandardQuestionSlugs.USER,
        title: "Submitter",
        description: "",
        options_meta_overrides: {
          is_read_only: 1,
          external_answer_source: COExternalAnswerSources.PROCESS_SUBMITTER,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
            { slug: COConditionSlugs.HIDE_FOR_EDIT }
          ],
          excluded_from_branching_paths_selection: 1
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_PROCESS_SUBMITTER
          ),
          co_question_position_json: {
            sort_order: 100
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.OVERVIEW_USEFUL_LINK,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.OVERVIEW_USEFUL_LINK,
        standard_question_template_slug: StandardQuestionSlugs.LINK,
        title: "Useful links",
        description: "",
        note:
          "NOTE: This question is only displayed when ideas are submitted from Process Mining or Task Mining.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.OVERVIEW_USEFUL_LINK
          ),
          co_question_position_json: {
            sort_order: 110
          }
        },
        options_meta_overrides: {
          prevent_deleting: 1,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            {
              slug: COConditionSlugs.SUBMISSION_FOR_TASK_AND_PROCESS_MINING_ONLY
            }
          ]
        }
      })
    }
  ];
};
