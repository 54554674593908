import axios from "../utils/axiosInstance";
import queryString from "query-string";
import apiErrorAlert, {
  errorMatchesLocalizationSlug
} from "../utils/apiErrorAlert";
import {
  swalSpinnerLoading,
  swalSpinnerSuccess,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";
import SessionHelper from "../helpers/SessionHelper";
import { flagUnsavedData } from "./globalDataActions";
import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_EDIT,
  UPDATE_USER_PROFILE,
  CLEAR_USER_PROFILE,
  FETCH_ALL_USERS,
  FETCHING_SELECTED_USERS,
  FETCH_SELECTED_USERS,
  CLEAR_SELECTED_USERS,
  FETCH_USER_PROFILE_IDEAS,
  FETCH_USER_PROFILE_COLLABORATIONS,
  FETCH_USER_PROFILE_CHANGE_REQUESTS,
  FETCH_USER_PROFILE_DOCUMENTATION,
  FETCH_MORE_USER_PROFILE_DOCUMENTATION,
  SET_IS_LOADING_USER_PROFILE_DOCUMENTATION,
  FETCH_USER_PROFILE_COMPONENTS,
  MODIFY_EDITED_USER_PROFILE,
  SET_TENANT_LOGO,
  FETCH_USER_PROFILE_SIDEBAR,
  FETCH_SELECTED_USER_BADGES,
  FETCH_FLOW_TREE,
  FETCH_USER_PROFILE_AUTOMATIONS
} from "../constants/actionTypes";

const USER_PROFILE_LIMIT = 8;

export function fetchUserProfile(slug) {
  let path = "/api/v1/users/slug/" + slug;
  return function(dispatch) {
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_USER_PROFILE,
          payload: resp.data.data
        });
      })
      .catch(error => {
        apiErrorAlert(error);
        //gracefully tries to handle deleted user
        if (errorMatchesLocalizationSlug(error, "error_user_not_found")) {
          setTimeout(() => {
            swalSpinnerClose();
            window.history.back();
          }, 2000);
        }
      });
  };
}

export function fetchUserProfileToEdit(slug) {
  let path = "/api/v1/users/slug/" + slug;

  return function(dispatch) {
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_USER_PROFILE_EDIT,
          payload: resp.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function modifyEditedUserProfile(profile) {
  return function(dispatch) {
    dispatch(flagUnsavedData());
    dispatch({
      type: MODIFY_EDITED_USER_PROFILE,
      payload: profile
    });
  };
}

export function setTenantLogo(logo?: string | null) {
  return dispatch => {
    dispatch({
      type: SET_TENANT_LOGO,
      payload: logo
    });
  };
}

export function updateUserProfile(data, callback) {
  let slug = data.user_profile_slug;
  return dispatch => {
    swalSpinnerLoading({});
    let path = "/api/v1/users/slug/" + slug;
    axios
      .patch(path, data)
      .then(resp => {
        swalSpinnerSuccess();
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: resp.data.data
        });
        setTimeout(() => {
          swalSpinnerClose();
          dispatch({
            type: CLEAR_USER_PROFILE
          });
          SessionHelper.setUserObject(resp.data.data);
          if (typeof callback === "function") {
            callback();
          }
        }, 1000);
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}

export function clearUserProfile(slug) {
  return function(dispatch) {
    dispatch({
      type: CLEAR_USER_PROFILE
    });
  };
}

export function fetchAllUsers(isActive) {
  const activeFlag = isActive ? "&active=true" : "";
  return function(dispatch) {
    axios
      .get(`/api/v1/users?page=1&limit=1000000000${activeFlag}`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_USERS,
          payload: response.data.data.users
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchSelectedUsers(queryParams: any = {}, isActive) {
  if (typeof isActive === "boolean") {
    queryParams.active = isActive;
  }

  return function(dispatch) {
    let path = `/api/v1/users?${queryString.stringify(queryParams)}`;
    dispatch({
      type: FETCHING_SELECTED_USERS
    });
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_SELECTED_USERS,
          payload: response.data.data.users
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearSelectedUsers() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_SELECTED_USERS
    });
  };
}

export function fetchUserProfileIdeasByPage(slug, page) {
  let path = `/api/v1/users/slug/${slug}/ideas?page=${page}
  &limit=${USER_PROFILE_LIMIT}`;
  return function(dispatch) {
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_IDEAS,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearUserProfileIdeas() {
  return function(dispatch) {
    dispatch({
      type: FETCH_USER_PROFILE_IDEAS,
      payload: null
    });
  };
}

export function fetchUserProfileAutomationsByPage(slug, page) {
  let path = `/api/v1/users/slug/${slug}/automations?page=${page}
    &limit=${USER_PROFILE_LIMIT}`;

  return function(dispatch) {
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_AUTOMATIONS,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearUserProfileAutomations() {
  return function(dispatch) {
    dispatch({
      type: FETCH_USER_PROFILE_AUTOMATIONS,
      payload: null
    });
  };
}

export function fetchUserProfileCollaborationsByPage(slug, page) {
  return function(dispatch) {
    let path =
      "/api/v1/users/slug/" +
      slug +
      "/collaborations?page=" +
      page +
      "&limit=" +
      USER_PROFILE_LIMIT;
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_COLLABORATIONS,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearUserProfileCollaborations() {
  return function(dispatch) {
    dispatch({
      type: FETCH_USER_PROFILE_COLLABORATIONS,
      payload: null
    });
  };
}

export function fetchUserProfileChangeRequestsByPage(slug, page) {
  return function(dispatch) {
    let path =
      "/api/v1/users/slug/" +
      slug +
      "/changeRequests?page=" +
      page +
      "&limit=" +
      USER_PROFILE_LIMIT;
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_CHANGE_REQUESTS,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearUserProfileChangeRequests() {
  return function(dispatch) {
    dispatch({
      type: FETCH_USER_PROFILE_CHANGE_REQUESTS,
      payload: null
    });
  };
}

export function fetchUserProfileDocumentation(slug) {
  return function(dispatch) {
    dispatch({
      type: SET_IS_LOADING_USER_PROFILE_DOCUMENTATION,
      payload: true
    });

    let path =
      "/api/v1/users/slug/" +
      slug +
      "/documents?page=1&limit=" +
      USER_PROFILE_LIMIT;
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_DOCUMENTATION,
          payload: response.data.data.documents
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchMoreUserProfileDocumentation(slug, page) {
  return function(dispatch) {
    dispatch({
      type: SET_IS_LOADING_USER_PROFILE_DOCUMENTATION,
      payload: true
    });

    let path =
      "/api/v1/users/slug/" +
      slug +
      "/documents?page=" +
      page +
      "&limit=" +
      USER_PROFILE_LIMIT;
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: FETCH_MORE_USER_PROFILE_DOCUMENTATION,
          payload: response.data.data.documents
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearUserProfileDocumentation() {
  return function(dispatch) {
    dispatch({
      type: FETCH_USER_PROFILE_DOCUMENTATION,
      payload: null
    });
  };
}

export function fetchUserProfileComponents(slug) {
  return function(dispatch) {
    axios
      .get("/api/v1/users/slug/" + slug + "/components?public=true")
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_COMPONENTS,
          payload: response.data.data.components
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchUserProfileSidebar(slug) {
  return function(dispatch) {
    axios
      .get("/api/v1/users/slug/" + slug + "/sidebar")
      .then(response => {
        dispatch({
          type: FETCH_USER_PROFILE_SIDEBAR,
          payload: response.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearUserProfileComponents() {
  return function(dispatch) {
    dispatch({
      type: FETCH_USER_PROFILE_COMPONENTS,
      payload: null
    });
  };
}

export function fetchSelectedUserBadges(slug) {
  let path = `/api/v1/users/slug/${slug}/badges`;
  return function(dispatch) {
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_SELECTED_USER_BADGES,
          payload: resp.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchFlowTree(slug) {
  return function(dispatch) {
    axios
      .get(`/api/v1/users/slug/${slug}/flow-tree`)
      .then(resp => {
        dispatch({
          type: FETCH_FLOW_TREE,
          payload: resp.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}
