import { IS_LEVEL_UP_MODAL_OPEN } from "../constants/actionTypes";

export function closeLevelUpModal() {
  return function(dispatch) {
    dispatch({
      type: IS_LEVEL_UP_MODAL_OPEN,
      payload: false
    });
  };
}
