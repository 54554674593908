import { COAssessmentTypes } from "../constants/co-constants";
import { PATH } from "../constants/co-path.constants";
import { optionsInContext } from "../helpers/co-context.helper";
import { getLocalizationDataFromValue } from "../helpers/localization/co-localization.helper";
import {
  COApplicationInfoItem,
  COContextInterface,
  COProcessExternalDataInterface,
  defaultCurrency
} from "../interfaces/co-interfaces";
import { getValue } from "../utils/co-path.utils";
import { isNullOrUndefined } from "../utils/co-utils";
import { COAssessment } from "./co-assessment.class";

export interface COProcessExternalData extends COProcessExternalDataInterface {}
export class COProcessExternalData {
  dont_resolve?: boolean = true;
  constructor() {
    this.dont_resolve = true;
    this.process_id = 0;
    this.phase_status_key = undefined;
    this.post_action_phase_status_key = undefined; // this we pop in right before validation depending on the action the user has taken
    this.container_size = {};
    this.application_data = {
      applications: [],
      allApplications: []
    };
    this.category_data = {
      categories: [],
      selected_categories: []
    };
    this.document_data = {
      documents: []
    };
    this.currency = defaultCurrency;
    this.collaborator_data = {
      collaborators: [],
      process_owner: null
    };
    this.media_data = {
      media: []
    };
    this.similar_automations = {
      processes: []
    };
    this.tag_data = {
      tags: []
    };
    this.component_data = {
      components: []
    };
    this.process = undefined;
    this.refereced_question_control_options = undefined;
  }

  updateFromProcess?(data: COProcessExternalDataInterface) {
    let allKeys = [...Object.keys(this), ...Object.keys(data)];
    for (const prop of allKeys) {
      if (!isNullOrUndefined(data[prop])) {
        if (isNullOrUndefined(this[prop])) {
          this[prop] = data[prop];
        } else {
          //merge objects at at least top level for things like application info and stuff
          if (
            typeof data[prop] === "object" &&
            typeof this[prop] === "object"
          ) {
            this[prop] = { ...this[prop], ...data[prop] };
          } else {
            this[prop] = data[prop];
          }
        }
      }
    }
  }

  syncUserPicker?({
    context,
    user,
    external_data_source
  }: {
    context: COContextInterface;
    user: any;
    external_data_source: string;
  }) {
    if (this.collaborator_data) {
      let splitDataSource = external_data_source.split(".");
      if (splitDataSource.length > 2) {
        // todo this should be able to recurse back or down further - generalize
        let propertyKey = splitDataSource[splitDataSource.length - 1];
        let parentObjectKey = splitDataSource[splitDataSource.length - 2];
        //setValue({ context, parentObject: this.collaborator_data });
        this[parentObjectKey][propertyKey] = user;
      }
    }

    if (this.dataSyncUserCallback) {
      this.dataSyncUserCallback({ user, external_data_source });
    }
  }

  syncMedia?(media: any) {
    let mediaItems: COApplicationInfoItem[] = [];
    if (this.media_data) {
      if (Array.isArray(media)) {
        for (const med of media) {
          mediaItems.push(med);
        }
      }
      this.media_data.media = media;
      if (this.dataSyncMediaCallback) {
        this.dataSyncMediaCallback(this.media_data.media);
      }
    }
  }

  syncCollaborators?(collaborators: any[]) {
    if (this.collaborator_data?.collaborators) {
      this.collaborator_data.collaborators = [...(collaborators || [])];
      if (this.dataSyncCollaboratorsProcessCallback) {
        this.dataSyncCollaboratorsProcessCallback(
          this.collaborator_data.collaborators
        );
      }
    }
  }

  syncComponents?(components: any) {
    let items: any[] = [];
    if (this.component_data) {
      if (Array.isArray(components)) {
        for (const component of components) {
          items.push(component);
        }
      }
      this.component_data.components = items;
      if (this.dataSyncComponentsCallback) {
        this.dataSyncComponentsCallback(this.component_data.components);
      }
    }
  }

  syncDocuments?(documents: any) {
    let items: any[] = [];
    if (this.document_data) {
      if (Array.isArray(documents)) {
        for (const item of documents) {
          items.push(item);
        }
      }
      this.document_data.documents = items;
      if (this.dataSyncDocumentsProcessCallback) {
        this.dataSyncDocumentsProcessCallback(this.document_data.documents);
      }
    }
  }

  syncSimilarAutomations?(automations: any) {
    let items: any[] = [];
    if (this.similar_automations) {
      if (Array.isArray(automations)) {
        for (const automation of automations) {
          items.push(automation);
        }
      }
      this.similar_automations.processes = items;
      if (this.dataSyncSimilarAutomationsCallback) {
        this.dataSyncSimilarAutomationsCallback(
          this.similar_automations.processes
        );
      }
    }
  }

  syncApplications?(newApplications: COApplicationInfoItem[]) {
    let applicationItems: COApplicationInfoItem[] = [];
    if (this.application_data) {
      if (Array.isArray(newApplications)) {
        for (const app of newApplications) {
          applicationItems.push(app);
        }
      }

      this.application_data.applications = applicationItems;
      if (this.dataSyncApplicationsProcessCallback) {
        this.dataSyncApplicationsProcessCallback(
          this.application_data.applications
        );
      }
    }
  }

  syncTags?(tags: any) {
    let items: any[] = [];
    if (this.tag_data) {
      if (Array.isArray(tags)) {
        for (const automation of tags) {
          items.push(automation);
        }
      }
      this.tag_data.tags = items;
      if (this.dataSyncTagsCallback) {
        this.dataSyncTagsCallback(this.tag_data.tags);
      }
    }
  }

  syncSelectedCategories?(categories: any[]) {
    let items: any[] = [];
    if (this.category_data) {
      if (Array.isArray(categories)) {
        for (const cat of categories) {
          items.push(cat);
        }
      }
      this.category_data.selected_categories = items;
      if (this.dataSyncCategoryCallback) {
        this.dataSyncCategoryCallback(this.category_data.selected_categories);
      }
    }
  }

  // for if we have questions that let you choose the priority, submission type, development type
  // not hooked up yet
  syncProcessProperty?(updatedData: any) {
    if (this.process) {
      for (const updatedKey of updatedData) {
        this.process[updatedKey] = updatedData[updatedKey];
      }
      if (this.dataSyncProcessPropertyCallback) {
        this.dataSyncProcessPropertyCallback({ updatedData });
      }
    }
  }

  // used in path system
  // used by question answer options to get the value of a external property
  // looks up things like the user_id inside the process.process_owner
  functionExternalPropertyValueForQuestion?(context: COContextInterface) {
    if (context) {
      if (context.question) {
        if (context.question.options?.external_answer_source) {
          let resolvedOptions = optionsInContext({
            context: context,
            options: context.question.options,
            should_resolve: true
          });
          let pathToProperty = resolvedOptions.external_answer_source;
          if (pathToProperty) {
            let qualifedPathToProperty = PATH({ route: pathToProperty });
            let valueForPath = getValue(context, qualifedPathToProperty);
            return valueForPath;
          }
        }
      }
    }
    return undefined;
  }

  functionReferencedCalculatedValue?(context: COContextInterface) {
    if (context) {
      if (context.question) {
        if (context.question.options?.external_answer_source) {
          // let's find the referenced calculated value from the external reference source
          let process_column_name =
            context.question.options
              .referenced_calculated_value_process_column_name;

          if (this.global_calculated_values && process_column_name) {
            if (
              !isNullOrUndefined(
                this.global_calculated_values[process_column_name]
              )
            ) {
              return this.global_calculated_values[process_column_name]
                .co_calculated_value;
            }
          } else {
            console.log(
              "Missing global_calculated_values or Empty process_column_name for Ref Question"
            );
            console.log(context.question.options);
            console.log(this.global_calculated_values);
          }
          return undefined;
        }
      }
    }
    return undefined;
  }

  functionLazyLoadedQuestionOptionsForReferenceControl?(
    context: COContextInterface
  ) {
    const process_external_data = context.process_external_data;
    if (!process_external_data) {
      console.log("process_external_data not set on context!");
      return;
    }
    if (
      isNullOrUndefined(
        process_external_data.refereced_question_control_options
      )
    ) {
      // load them
      if (
        process_external_data?.externallyLoadReferencedQuestions &&
        context.assessment?.co_assessment_type
      ) {
        process_external_data.refereced_question_control_options = []; // set to empty so we don't make a million loading calls
        process_external_data.externallyLoadReferencedQuestions({
          co_assessment_type: context.assessment
            ?.co_assessment_type as COAssessmentTypes,
          onLoaded: controlOptions => {
            // if we have access to the translate function try to translate options
            if (context.function_hooks?.translateFunction) {
              const translateFunction =
                context.function_hooks.translateFunction;
              controlOptions = controlOptions.map(option => {
                if (
                  option?.meta?.value &&
                  option?.assessment_title &&
                  option?.question_title
                ) {
                  const assessmentTitleLocalizationData = getLocalizationDataFromValue(
                    option.assessment_title
                  );
                  const assessmentTitleLocalized = translateFunction([
                    assessmentTitleLocalizationData.localizationKey || "",
                    assessmentTitleLocalizationData.fallbackValue || ""
                  ]);
                  const questionTitleLocalizationData = getLocalizationDataFromValue(
                    option.question_title
                  );
                  const questionTitleLocalized = translateFunction([
                    questionTitleLocalizationData.localizationKey || "",
                    questionTitleLocalizationData.fallbackValue || ""
                  ]);
                  option.meta.value = `${assessmentTitleLocalized} - ${questionTitleLocalized}`;

                  if (option.is_nested) {
                    const nestedLocalizationData = getLocalizationDataFromValue(
                      "nested"
                    );
                    const nestedLocalized = translateFunction([
                      nestedLocalizationData.localizationKey || "",
                      nestedLocalizationData.fallbackValue || "nested"
                    ]);
                    option.meta.value += ` (${nestedLocalized})`;
                  }
                }
                if (
                  option?.meta?.tooltip &&
                  option?.assessment_version_title &&
                  option?.assessment_version_number
                ) {
                  const assessmentVersionTitleLocalizationData = getLocalizationDataFromValue(
                    option.assessment_version_title
                  );
                  const assessmentVersionTitleLocalized = translateFunction([
                    assessmentVersionTitleLocalizationData.localizationKey ||
                      "",
                    assessmentVersionTitleLocalizationData.fallbackValue || ""
                  ]);
                  const assessmentVersionNumber =
                    option?.assessment_version_number || "";
                  option.meta.tooltip = `${assessmentVersionTitleLocalized} - ${assessmentVersionNumber}`;
                }
                return option;
              });
            }
            process_external_data.refereced_question_control_options = controlOptions;
            if (context.assessment) {
              COAssessment.incrementRenderCounters(
                context,
                context.assessment || {}
              );
              context?.function_hooks?.triggerUiRender?.();
            }
          },
          onError: error => {
            if (
              process_external_data?.refereced_question_control_options &&
              process_external_data?.refereced_question_control_options
                ?.length === 0
            ) {
              process_external_data.refereced_question_control_options = undefined; // will load next try
            }
          }
        });
      }
    } else {
      return process_external_data.refereced_question_control_options;
    }

    // return [
    //   {
    //     key: `employee_average_full_cost`,
    //     value: "employee_average_full_cost",
    //     meta: {
    //       value: `WOO Employee Average Full Cost`,
    //       value_localization_key: `!!!!`
    //     }
    //   },
    //   {
    //     key: `employee_average_working_days`,
    //     value: "employee_average_working_days",
    //     meta: {
    //       value: `Employee Average Working Days Per Year`,
    //       value_localization_key: `!!!!`
    //     }
    //   },
    //   {
    //     key: `estimated_benefit_hours_per_employee`,
    //     value: "estimated_benefit_hours_per_employee",
    //     meta: {
    //       value: `Estimated Benefit, Hours Per Employee`,
    //       value_localization_key: `!!!!`
    //     }
    //   },
    //   {
    //     key: `estimated_cost_benefit_per_employee`,
    //     value: "estimated_cost_benefit_per_employee",
    //     meta: {
    //       value: `Estimated Benefit Cost Per Employee`,
    //       value_localization_key: `!!!!`
    //     }
    //   }
    // ];
  }
}
