import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import { fetchPPPProfileData } from "./processProfileActions";
import {
  swalSpinnerClose,
  swalSpinnerSuccess
} from "../utils/SweetAlertLoaderSpinner";
import {
  FETCH_ALL_PROCESS_DOCUMENT_TYPES,
  FETCH_ALL_PROCESS_DOCUMENTS,
  SET_PROCESS_DOCUMENT_TO_UPDATE
} from "../constants/actionTypes";

export function fetchAllProcessDocumentTypes() {
  return function(dispatch) {
    axios
      .get(`/api/v1/filetypes`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_PROCESS_DOCUMENT_TYPES,
          payload: response.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchAllProcessDocuments(processSlug) {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_PROCESS_DOCUMENTS,
      payload: null
    });
    axios
      .get(`/api/v1/processes/slug/${processSlug}/documents?page=1&limit=1000`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_PROCESS_DOCUMENTS,
          payload: response.data.data
        });
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          // IMPORTANT: do not throw error if it's a 403 error. we will block the view on the UI but cannot get access to permissions fast enough for the check
          dispatch({
            type: FETCH_ALL_PROCESS_DOCUMENTS,
            payload: null
          });
        } else {
          apiErrorAlert(error);
        }
      });
  };
}

export function clearProcessDocuments() {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_PROCESS_DOCUMENTS,
      payload: null
    });
  };
}

export function addProcessDocument(options, callback, errorCallback) {
  const { processSlug, ...data } = options;
  return function(dispatch) {
    axios
      .post(`/api/v1/processes/slug/${processSlug}/documents`, data)
      .then(response => {
        if (callback && typeof callback === "function") {
          callback(response.data);
        }
        swalSpinnerSuccess();
        setTimeout(() => {
          swalSpinnerClose();
        }, 600);
      })
      .catch(error => {
        apiErrorAlert(error);
        if (errorCallback && typeof errorCallback === "function") {
          errorCallback(error);
        }
      });
  };
}

export function deleteProcessDocument(options, callback: Function = () => {}) {
  const { processSlug, documentId } = options;
  return function(dispatch) {
    axios
      .delete(`/api/v1/processes/slug/${processSlug}/documents/${documentId}`)
      .then(response => {
        dispatch(fetchAllProcessDocuments(processSlug));
        if (callback) {
          callback();
        } else {
          dispatch(fetchPPPProfileData({ processId: processSlug }));
        }
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function updateProcessDocument(options, callback, errorCallback) {
  const { processSlug, document_id, ...restOptions } = options;
  return function(dispatch) {
    axios
      .put(`/api/v1/processes/slug/${processSlug}/documents/${document_id}`, {
        ...restOptions
      })
      .then(response => {
        if (callback && typeof callback === "function") {
          callback(response.data);
        }
        swalSpinnerSuccess();
        setTimeout(() => {
          swalSpinnerClose();
        }, 600);
      })
      .catch(error => {
        apiErrorAlert(error);

        if (errorCallback && typeof errorCallback === "function") {
          errorCallback(error);
        }
      });
  };
}

export function setProcessDocumentToUpdate(documentObject) {
  return function(dispatch) {
    dispatch({
      type: SET_PROCESS_DOCUMENT_TO_UPDATE,
      payload: documentObject
    });
  };
}
