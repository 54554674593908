import {
  COContextInterface,
  COUserInterface,
  COMetadataItemInterface,
  COLocalizedStringGroupingInterface,
  COLocalizedStringValuesInterface,
  COLocalizedStringValuesLinkInterface,
  COLocalizationReplacementType
} from "../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL,
  PROCESS_SUBMITTER_PROFILE_URL,
  PROCESS_OWNER_PROFILE_URL,
  USER_PROFILE_URL,
  EXPLORE_PUBLISHED_AUTOMATIONS_URL,
  COMPONENT_PROFILE_URL,
  EXPLORE_URL,
  TOP_DOWN_SUBMISSION_URL,
  AUTOMATION_PROFILE_CBA_URL,
  AUTOMATION_PROFILE_COMPONENTS_URL,
  AUTOMATION_PROFILE_DOCUMENTATION_URL,
  AUTOMATION_PROFILE_EDIT_URL,
  USER_PROFILE_EMAIL_SETTINGS_URL
} from "../../constants/actions/co-action-constants";
import { COCollaboratorRoleType } from "../../constants/actions/co-roles.constants";
import { fullNameForUser } from "../co-user-helper";
import { convertToRawDraftJs } from "../draft-js.helper";
import { isDraftJS, isNullOrUndefined } from "../../utils/co-utils";
import { isPath } from "../../utils/co-path.utils";
import { resolvePathWithOverrides } from "../../utils/co-path.utils";

const MAX_DESCRIPTION_LEN = 500;

// Regex looks for all words that begin with {{ and end with }}
// and are only letters or _
const CO_ACTION_VAR_MATCH_ALL_REGEX = () => {
  return /([^A-Z_\S]|^|\W)({{([>A-Za-z_]{3,})}})/g;
};

// ([^A-Z_\S]|^|\W)({{RefUserFullName}})([^A-Z_\S]|^|\W)
const CO_ACTION_VAR_REPLACE_REGEX = variable => {
  let regex = new RegExp(
    "([^A-Z_S]|^|\\W)({{" + variable + "}})([^A-Z_S]|$|\\W)",
    "g"
  );
  return regex;
};

// Parse the provided Action meta item value into an unlocalized string
// This should really only be used for strings we wouldn't localize anyway, such as URLs
// or for something that comes pre-localized, like email titles/templates
export const parseMetaItemValueToUnlocalizedString = ({
  context,
  value
}: {
  context: COContextInterface;
  value?: string;
}): string => {
  let metaItem = parseMetaItemValueToLocalizationValuesMeta({ context, value });
  return convertMetaItemValueToUnlocalizedString({ context, metaItem });
};

// Parse the provided Action meta item into an unlocalized string
// This should really only be used for strings we wouldn't localize anyway, such as URLs
// or for something that comes pre-localized, like email titles/templates
export const convertMetaItemValueToUnlocalizedString = ({
  context,
  metaItem
}: {
  context: COContextInterface;
  metaItem: COMetadataItemInterface;
}): string => {
  return convertLocalizedStringValuesToUnlocalizedString({
    context,
    localizedStringValues: {
      value: metaItem.value,
      localization_values: metaItem.value_localization_values
    }
  });
};

const convertLocalizedStringValuesToUnlocalizedString = ({
  context,
  localizedStringValues
}: {
  context: COContextInterface;
  localizedStringValues: COLocalizedStringValuesInterface;
}): string => {
  let finalValue = localizedStringValues.value ?? "";

  // do our own local replacing of values without localizing anything
  // again, this should only be for things like URLs so no need to localize
  if (localizedStringValues.localization_values) {
    for (const key of Object.keys(localizedStringValues.localization_values)) {
      const value = localizedStringValues.localization_values[key];

      if (typeof value === "string") {
        finalValue = replaceActionStringValue({
          context,
          value: finalValue,
          variable: key,
          replacementValue: value
        });
      } else if (typeof value === "object") {
        // if we have an object, check what type of object
        const localizedStringValuesInterface = value as COLocalizedStringValuesInterface;
        const localizedStringGroupingInterface = value as COLocalizedStringGroupingInterface;
        const localizedStringValuesLinkInterface = value as COLocalizedStringValuesLinkInterface;

        if (
          localizedStringValuesInterface.value &&
          localizedStringValuesInterface.localization_values
        ) {
          // If there are localization values in this one
          // then perform conversion on that and then replace the action string value
          const replacementValue = convertLocalizedStringValuesToUnlocalizedString(
            { context, localizedStringValues: localizedStringValuesInterface }
          );
          finalValue = replaceActionStringValue({
            context,
            value: finalValue,
            variable: key,
            replacementValue: replacementValue
          });
        } else if (
          localizedStringGroupingInterface.values &&
          localizedStringGroupingInterface.separator
        ) {
          // don't do any localizing, just set it
          const joinedValues = localizedStringGroupingInterface.values.join(
            localizedStringGroupingInterface.separator
          );
          finalValue = replaceActionStringValue({
            context,
            value: finalValue,
            variable: key,
            replacementValue: joinedValues
          });
        } else if (localizedStringValuesLinkInterface.value) {
          // don't do any localizing, just set it
          finalValue = replaceActionStringValue({
            context,
            value: finalValue,
            variable: key,
            replacementValue: localizedStringValuesLinkInterface.value
          });
        }
      }
    }
  }

  return finalValue;
};

// Parse the provided Action meta filepath into a localizable meta item
// complete with replaceable localization_values that T functions can use
// If the value isn't a filepath, it will just use the value passed in as a string
// in the case of edited emails that are no longer filepaths but instead the actual email as a string
export const parseMetaItemFileToLocalizationValuesMeta = ({
  context,
  value
}: {
  context: COContextInterface;
  value?: string;
}): COMetadataItemInterface => {
  let fileName = value ?? "";
  if (isPath(fileName)) {
    const resolvedValue = resolvePathWithOverrides({
      context,
      valueOrPath: fileName
    });
    fileName = resolvedValue?.value ?? "";
  }
  if (!fileName) {
    return {};
  }

  let templateContents: string = fileName;
  if (context.process_external_data?.emailTemplate) {
    templateContents = context.process_external_data?.emailTemplate;
  }

  return parseMetaItemValueToLocalizationValuesMeta({
    context,
    value: templateContents
  });
};

// Parse the provided Action meta item value into a localizable meta item
// complete with replaceable localization_values that T functions can use
export const parseMetaItemValueToLocalizationValuesMeta = ({
  context,
  value
}: {
  context: COContextInterface;
  value?: string;
}): COMetadataItemInterface => {
  let parsedValue = value ?? "";
  if (isPath(parsedValue)) {
    const resolvedValue = resolvePathWithOverrides({
      context,
      valueOrPath: parsedValue
    });
    parsedValue = resolvedValue?.value ?? "";
  }

  return {
    value: parsedValue,
    value_localization_values: getLocalizationValues({
      context,
      value: parsedValue
    })
  };
};

// converts the provided Action meta value to a String with all the replacement values replaced
// and with any links included in the string
const getLocalizationValues = ({
  context,
  value
}: {
  context: COContextInterface;
  value?: string;
}): {
  [key: string]: COLocalizationReplacementType;
} => {
  let parsedValue = value ?? "";

  // find all variables
  const variables: string[] = actionStringVariables({
    value: parsedValue
  });

  // loop through variables and find the replacement localization values
  let localizationValues: {
    [key: string]: COLocalizationReplacementType;
  } = {};
  for (const variable of variables) {
    const replacementValues = replacemenLocalizationValuesForVariable({
      context,
      variable
    });
    if (!isNullOrUndefined(replacementValues)) {
      // for the key, we want to remove the leading/trailing {{}}
      const key = variable.slice(2, -2);
      localizationValues[key] = replacementValues ?? "";
    }
  }

  // convert to string and return
  return localizationValues;
};

// Finds all of the action string replacement variables within the provided string
export const actionStringVariables = ({
  value
}: {
  value: string;
}): string[] => {
  let variables: string[] = [];
  let variableSearchResults = value.matchAll(CO_ACTION_VAR_MATCH_ALL_REGEX());
  for (const match of variableSearchResults) {
    if (match && match.length > 2) {
      // second capture group
      let varFound = match[2];
      if (varFound) {
        variables.push(varFound);
      }
    }
  }
  return variables;
};

// replaces all of the occurences of the provided variable in the provided value string
// including any links that were also included with the replaced value
const replaceActionStringValue = ({
  context,
  variable,
  value,
  replacementValue
}: {
  context: COContextInterface;
  variable: string;
  value: string;
  replacementValue: string;
}): string => {
  if (!isNullOrUndefined(replacementValue)) {
    const regex = CO_ACTION_VAR_REPLACE_REGEX(variable);
    let parsedValue = value;

    let match: RegExpExecArray | null = null;
    while ((match = regex.exec(parsedValue))) {
      if (match.length > 2) {
        // the string we want to be replacing is in the second capture group of the regex
        parsedValue = parsedValue.replace(match[2], replacementValue);
      }
    }
    return parsedValue;
  }
  return value;
};

// The replacement string localization values/URL to use based on the provided variable
const replacemenLocalizationValuesForVariable = ({
  context,
  variable
}: {
  context: COContextInterface;
  variable: string;
}): COLocalizationReplacementType | undefined => {
  let values: COLocalizationReplacementType | undefined = undefined;

  switch (variable) {
    case COActionReplacementStrings.PROCESS_SLUG: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.process?.process_slug,
        legacy_text_replacement_slug: "{idOrSlug}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_NAME: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.process?.process_name,
        text_url: AUTOMATION_PROFILE_URL,
        legacy_text_replacement_slug: "{ProcessName}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_DESCR: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.process?.process_description,
        text_max_length: MAX_DESCRIPTION_LEN,
        legacy_text_replacement_slug: "{ProcessDescription}"
      });
      break;
    }
    case COActionReplacementStrings.VIEW_MORE_PROCESS_DESCR: {
      // View More should only be shown if the description is too long
      let process_description: string =
        context.process_external_data?.process?.process_description ?? "";
      if (isDraftJS(process_description)) {
        const draftJS = convertToRawDraftJs(process_description ?? "");
        if (draftJS.blocks && draftJS.blocks.length > 0) {
          process_description = draftJS.blocks[0].text ?? "";
        }
      }
      if (process_description.length > MAX_DESCRIPTION_LEN) {
        values = replacementValues({
          context,
          text_value: "...view more",
          text_value_localization_key: "co_action_replacement_value_view_more",
          text_url: AUTOMATION_PROFILE_URL,
          legacy_text_replacement_slug: "{ViewMore}"
        });
      } else {
        values = "";
      }
      break;
    }
    case COActionReplacementStrings.PROCESS_SUBMITTER_SLUG: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process?.process_submitter
            ?.user_profile_slug,
        collaborator_role_type: COCollaboratorRoleType.PROCESS_SUBMITTER,
        collaborator_property: "user_profile_slug"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_SUBMITTER_NAME: {
      values = userFullNameReplacementValues({
        context,
        user_value: context.process_external_data?.process?.process_submitter,
        user_url: PROCESS_SUBMITTER_PROFILE_URL,
        collaborator_role_type: COCollaboratorRoleType.PROCESS_SUBMITTER,
        legacy_text_replacement_slug: "{ProcessSubmitter}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_OWNER_SLUG: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process?.process_owner
            ?.user_profile_slug,
        collaborator_role_type: COCollaboratorRoleType.PROCESS_OWNER,
        collaborator_property: "user_profile_slug"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_OWNER_NAME: {
      values = userFullNameReplacementValues({
        context,
        user_value: context.process_external_data?.process?.process_owner,
        user_url: PROCESS_OWNER_PROFILE_URL,
        collaborator_role_type: COCollaboratorRoleType.PROCESS_OWNER,
        legacy_text_replacement_slug: "{ProcessOwner}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_PHASE: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process_phase ??
          context.process_external_data?.process?.process_phase?.phase_name,
        legacy_text_replacement_slug: "{ProcessPhase}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_STATUS: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process_status ??
          context.process_external_data?.process?.process_phase_status
            ?.phase_status_name,
        legacy_text_replacement_slug: "{ProcessStatus}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_ROLE_NAME: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process_role_name ??
          context.process_external_data?.role?.role_title,
        legacy_text_replacement_slug: "{ProcessRoleName}"
      });
      break;
    }
    case COActionReplacementStrings.COLLABORATOR_ROLES: {
      if (context.process_external_data?.role) {
        values = replacementValues({
          context,
          text_value: context.process_external_data?.role?.role_title,
          legacy_text_replacement_slug: "{ProcessCollaboratorRoles}"
        });
      } else {
        values = replacementValues({
          context,
          text_value: context.process_external_data?.collaborator_roles,
          legacy_text_replacement_slug: "{ProcessCollaboratorRoles}"
        });
      }
      break;
    }
    case COActionReplacementStrings.USER_SLUG: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.referenced_user?.user_profile_slug
      });
      break;
    }
    case COActionReplacementStrings.USER_FULL_NAME: {
      values = userFullNameReplacementValues({
        context,
        user_value: context.process_external_data?.referenced_user,
        user_url: USER_PROFILE_URL,
        legacy_text_replacement_slug: "{UserFullName}"
      });
      break;
    }
    case COActionReplacementStrings.TARGET_USER_FIRST_NAME: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.user?.user_first_name,
        legacy_text_replacement_slug: "{UserFirstName}"
      });
      break;
    }
    case COActionReplacementStrings.DOCUMENT_TITLE: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.document?.document_title,
        legacy_text_replacement_slug: "{ProcessDocumentTitle}"
      });
      break;
    }
    case COActionReplacementStrings.COMMENT_TITLE: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.comment?.comment_text,
        legacy_text_replacement_slug: "{ProcessCommentText}"
      });
      break;
    }
    case COActionReplacementStrings.COMPONENT_COMMENT: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.component_comment?.comment_text,
        legacy_text_replacement_slug: "{ProcessCommentText}"
      });
      break;
    }
    case COActionReplacementStrings.CURATOR_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.CURATOR,
        legacy_text_replacement_slug: "{Curator}"
      });
      break;
    }
    case COActionReplacementStrings.SYSTEM_ADMIN_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.SYSTEM_ADMIN,
        legacy_text_replacement_slug: "{SystemAdmin}"
      });
      break;
    }
    case COActionReplacementStrings.PROGRAM_MANAGER_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.PROGRAM_MANAGER,
        legacy_text_replacement_slug: "{ProgramManager}"
      });
      break;
    }
    case COActionReplacementStrings.DIRECT_MANAGER_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.DIRECT_MANAGER,
        legacy_text_replacement_slug: "{DirectManager}"
      });
      break;
    }
    case COActionReplacementStrings.REPORTEE_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.REPORTEE,
        legacy_text_replacement_slug: "{RepoteeFullName}"
      });
      break;
    }
    case COActionReplacementStrings.AUTHORIZED_USER_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.AUTHORIZED_USER,
        legacy_text_replacement_slug: "{AuthorizedUser}"
      });
      break;
    }
    case COActionReplacementStrings.COE_COLLABORATOR_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.COE_COLLABORATOR,
        legacy_text_replacement_slug: "{CoeCollaborator}"
      });
      break;
    }
    case COActionReplacementStrings.COLLABORATOR_NAME: {
      values = userFullNameReplacementValues({
        context,
        collaborator_role_type: COCollaboratorRoleType.COLLABORATOR,
        legacy_text_replacement_slug: "{CollaboratorFullName}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_TEMPLATE_TITLE: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.template?.template_title,
        legacy_text_replacement_slug: "{ProcessTemplateTitle}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_ARCHIVED_REASON: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process_archived_reason ??
          context.process_external_data?.process?.process_archived_reason,
        legacy_text_replacement_slug: "{ProcessArchivedReason}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_REJECTED_REASON: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.process_rejected_reason ??
          context.process_external_data?.process?.process_rejected_reason,
        legacy_text_replacement_slug: "{ProcessRejectedReason}"
      });
      break;
    }
    case COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.process?.process_name,
        text_url: EXPLORE_PUBLISHED_AUTOMATIONS_URL,
        legacy_text_replacement_slug: "{AutomationStoreProcessName}"
      });
      break;
    }
    case COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME_HERE: {
      // This is supposed to be the word "here" with a link to the Automation Store profile
      values = replacementValues({
        context,
        text_value: "here",
        text_value_localization_key:
          "co_action_replacement_value_automation_store_here",
        text_url: EXPLORE_PUBLISHED_AUTOMATIONS_URL,
        legacy_text_replacement_slug: "{AutomationStoreProcessNameHere}"
      });
      break;
    }
    case COActionReplacementStrings.COMPONENT_SLUG: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.component?.component_slug
      });
      break;
    }
    case COActionReplacementStrings.COMPONENT_NAME: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.component?.component_name,
        text_url: COMPONENT_PROFILE_URL,
        legacy_text_replacement_slug: "{ComponentName}"
      });
      break;
    }
    case COActionReplacementStrings.COMPONENT_REJECTED_REASON: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.component_rejected_reason ??
          context.process_external_data?.component?.component_reject_reason,
        legacy_text_replacement_slug: "{ComponentRejectReason}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_ABUSE_TITLE: {
      // reports don't have a title, and the only notification using this is no longer active
      // so we shouldn't need to do anything here but handle legacy notifications
      values = replacementValues({
        context,
        legacy_text_replacement_slug: "{ProcessAbuseTitle}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_ABUSE_DESCR: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.reported_process?.reported_reason,
        legacy_text_replacement_slug: "{ProcessAbuseDescription}"
      });
      break;
    }
    case COActionReplacementStrings.UPDATED_USER_ATTRIBUTES: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.user_attributes,
        legacy_text_replacement_slug: "{UpdatedUserAttributes}"
      });
    }
    case COActionReplacementStrings.PROCESS_COMMENT: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.comment?.comment_text ??
          context?.process_external_data?.process_question?.comment,
        legacy_text_replacement_slug: "{ProcessComment}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_QUESTION: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.process_question?.comment,
        legacy_text_replacement_slug: "{ProcessQuestion}"
      });
      break;
    }
    case COActionReplacementStrings.TENANT_NAME: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.tenant?.tenant_name,
        legacy_text_replacement_slug: "{TenantName}"
      });
      break;
    }
    case COActionReplacementStrings.TENANT_STATE: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.tenant?.state,
        legacy_text_replacement_slug: "{State}"
      });
      break;
    }
    case COActionReplacementStrings.TENANT_COMPANY_NAME: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.tenant?.tenant_company_name,
        legacy_text_replacement_slug: "{CompanyName}"
      });
      break;
    }
    case COActionReplacementStrings.LICENSE_PLAN: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.tenant?.license_plan,
        legacy_text_replacement_slug: "{LicensePlan}"
      });
      break;
    }
    case COActionReplacementStrings.LICENSE_PLAN_OPTION_NUMBER: {
      values = replacementValues({
        context,
        text_value:
          context.process_external_data?.tenant?.license_plan_option_number,
        legacy_text_replacement_slug: "{LicensePlanOptionNumber}"
      });
      break;
    }
    case COActionReplacementStrings.TENANT_MIGRATION_OPTION: {
      const text_value =
        context.process_external_data?.tenant?.is_new_tenant === true
          ? "New Tenant Migration"
          : "Keep Current Tenant Migration";
      const text_value_localization_key =
        context.process_external_data?.tenant?.is_new_tenant === true
          ? "co_action_replacement_value_migration_option_new_tenant"
          : "co_action_replacement_value_migration_option_existing_tenant";
      values = replacementValues({
        context,
        text_value,
        text_value_localization_key,
        legacy_text_replacement_slug: "{MigrationOption}"
      });
      break;
    }
    case COActionReplacementStrings.SALES_MESSAGE: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.tenant?.sales_message,
        legacy_text_replacement_slug: "{SalesMessage}"
      });
      break;
    }
    case COActionReplacementStrings.ERROR_MESSAGE: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.tenant?.error_message,
        legacy_text_replacement_slug: "{ErrorMessage}"
      });
      break;
    }
    case COActionReplacementStrings.PERMISSION_ROLES: {
      values = replacementValues({
        context,
        text_value: context.process_external_data?.permission_roles,
        legacy_text_replacement_slug: "{PermissionRoles}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_PROFILE_URL: {
      values = urlReplacementValues({
        context,
        url_string: AUTOMATION_PROFILE_URL,
        legacy_text_replacement_slug: "{ProcessUrl}"
      });
      break;
    }
    case COActionReplacementStrings.AUTOMATION_STORE_PROCESS_URL: {
      values = urlReplacementValues({
        context,
        url_string: EXPLORE_PUBLISHED_AUTOMATIONS_URL,
        legacy_text_replacement_slug: "{AutomationStoreProcessUrl}"
      });
      break;
    }
    case COActionReplacementStrings.EXPLORE_IDEAS_URL: {
      values = urlReplacementValues({
        context,
        url_string: EXPLORE_URL,
        legacy_text_replacement_slug: "{ExploreIdeasUrl}"
      });
      break;
    }
    case COActionReplacementStrings.SUBMIT_IDEA_URL: {
      values = urlReplacementValues({
        context,
        url_string: TOP_DOWN_SUBMISSION_URL,
        legacy_text_replacement_slug: "{SubmitIdeaUrl}"
      });
      break;
    }
    case COActionReplacementStrings.USER_PROFILE_URL: {
      values = urlReplacementValues({
        context,
        url_string: USER_PROFILE_URL,
        legacy_text_replacement_slug: "{RefUserUrl}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_COMMENT_URL: {
      values = urlReplacementValues({
        context,
        url_string: AUTOMATION_PROFILE_URL,
        legacy_text_replacement_slug: "{ProcessCommentUrl}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_CBA_URL: {
      values = urlReplacementValues({
        context,
        url_string: AUTOMATION_PROFILE_CBA_URL,
        legacy_text_replacement_slug: "{ProcessCBAUrl}"
      });
      break;
    }
    case COActionReplacementStrings.COMPONENT_PROFILE_URL: {
      values = urlReplacementValues({
        context,
        url_string: COMPONENT_PROFILE_URL,
        legacy_text_replacement_slug: "{ComponentUrl}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_COMPONENT_URL: {
      values = urlReplacementValues({
        context,
        url_string: AUTOMATION_PROFILE_COMPONENTS_URL,
        legacy_text_replacement_slug: "{ProcessComponentUrl}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_DOCUMENTATION_URL: {
      values = urlReplacementValues({
        context,
        url_string: AUTOMATION_PROFILE_DOCUMENTATION_URL,
        legacy_text_replacement_slug: "{ProcessDocumentationUrl}"
      });
      break;
    }
    case COActionReplacementStrings.PROCESS_EDIT_URL: {
      values = urlReplacementValues({
        context,
        url_string: AUTOMATION_PROFILE_EDIT_URL,
        legacy_text_replacement_slug: "{ProcessEditUrl}"
      });
      break;
    }
    case COActionReplacementStrings.STATUS_PAGE_URL: {
      values = urlReplacementValues({
        context,
        url_string: "https://status.uipath.com",
        legacy_text_replacement_slug: "{StatusPage}"
      });
      break;
    }
    case COActionReplacementStrings.USER_GUIDE_FIRST_USER_URL: {
      values = urlReplacementValues({
        context,
        url_string:
          "https://docs.uipath.com/automation-hub/docs/starting-as-the-a-system-admin",
        legacy_text_replacement_slug: "{UserGuideFirstUserUrl}"
      });
      break;
    }
    case COActionReplacementStrings.USER_GUIDE_STANDARD_USER_URL: {
      values = urlReplacementValues({
        context,
        url_string:
          "https://docs.uipath.com/automation-hub/docs/starting-as-a-standard-user",
        legacy_text_replacement_slug: "{UserGuideStandardUserUrl}"
      });
      break;
    }
    case COActionReplacementStrings.TENANT_LOGIN_URL: {
      values = urlReplacementValues({
        context,
        url_string: "/",
        legacy_text_replacement_slug: "{TenantLoginUrl}"
      });
      break;
    }
    case COActionReplacementStrings.EMAIL_CONFIRMATION_LINK: {
      values = urlReplacementValues({
        context,
        url_string: "/",
        legacy_text_replacement_slug: "{EmailConfirmationLink}"
      });
      break;
    }
    case COActionReplacementStrings.UNSUBSCRIBE_LINK: {
      values = urlReplacementValues({
        context,
        url_string: USER_PROFILE_EMAIL_SETTINGS_URL,
        legacy_text_replacement_slug: "{UnsubscribeLink}"
      });
      break;
    }
    case COActionReplacementStrings.EMAIL_TITLE: {
      values = localizationReplacementValue({
        context,
        value: context.process_external_data?.emailTitle
      });
      break;
    }
    case COActionReplacementStrings.BEGINNING_OF_EMAIL: {
      values = localizationReplacementValue({
        context,
        value: context.process_external_data?.beginningOfEmail
      });
      break;
    }
    case COActionReplacementStrings.END_OF_EMAIL: {
      values = localizationReplacementValue({
        context,
        value: context.process_external_data?.endOfEmail
      });
      break;
    }
    case COActionReplacementStrings.REMINDER_EMAIL_TITLE_PREFIX: {
      values = localizationReplacementValue({
        context,
        value: context.process_external_data?.reminderEmailTitlePrefix
      });
      break;
    }
    case COActionReplacementStrings.REMINDER_EMAIL_BODY_PREFIX: {
      values = localizationReplacementValue({
        context,
        value: context.process_external_data?.reminderEmailBodyPrefix
      });
      break;
    }
  }

  // we need to resolve the url before we return it
  if (typeof values === "object") {
    const urlValues = values as COLocalizedStringValuesLinkInterface;
    if (urlValues && urlValues.url) {
      urlValues.url =
        getURLValue({ context, url_string: urlValues.url }) ?? undefined;
    }
  }
  return values;
};

const replacementValues = ({
  context,
  text_value,
  text_value_localization_key,
  text_url,
  text_max_length,
  collaborator_role_type,
  collaborator_property,
  legacy_text_replacement_slug
}: {
  context: COContextInterface;
  text_value?: string | null;
  text_value_localization_key?: string;
  text_url?: string | null;
  text_max_length?: number;
  collaborator_role_type?: COCollaboratorRoleType;
  collaborator_property?: string;
  legacy_text_replacement_slug?: string;
}): COLocalizationReplacementType | undefined => {
  if (text_value) {
    if (text_value_localization_key) {
      // if we have a localization_key, just return it as a localization object
      return {
        value: text_value,
        localization_key: text_value_localization_key,
        url: text_url ?? undefined
      };
    }
    // just use the text_value
    let text: string | undefined = text_value;
    if (isDraftJS(text)) {
      const draftJS = convertToRawDraftJs(text);
      if (draftJS.blocks && draftJS.blocks.length > 0) {
        text = draftJS.blocks[0].text;
      }
    }
    if (text && text_max_length) {
      if (text.length > text_max_length) {
        text = text.substring(0, text_max_length + 1);
      }
    }
    return { value: text, url: text_url ?? undefined };
  } else if (collaborator_role_type && collaborator_property) {
    // if we have collaborator arguments, check for the collaborator property next
    const collaborator =
      context.process_external_data?.collaborators?.[collaborator_role_type];
    if (collaborator && collaborator[collaborator_property]) {
      let text: string | undefined = collaborator[collaborator_property];
      if (isDraftJS(text)) {
        const draftJS = convertToRawDraftJs(text ?? "");
        if (draftJS.blocks && draftJS.blocks.length > 0) {
          text = draftJS.blocks[0].text;
        }
      }
      return { value: text, url: text_url ?? undefined };
    }
  } else if (legacy_text_replacement_slug) {
    // if we have a legacy_text_replacement_slug, check that last
    return textReplacementValue({
      context,
      slug: legacy_text_replacement_slug
    });
  }
  return undefined;
};

const userFullNameReplacementValues = ({
  context,
  user_value,
  user_url,
  collaborator_role_type,
  legacy_text_replacement_slug
}: {
  context: COContextInterface;
  user_value?: COUserInterface | null;
  user_url?: string | null;
  collaborator_role_type?: COCollaboratorRoleType;
  legacy_text_replacement_slug?: string;
}): COLocalizationReplacementType | undefined => {
  if (user_value) {
    // get the fullname from the user_value if there is one
    return {
      value: fullNameForUser({
        user: user_value
      }),
      url: user_url ?? undefined
    };
  } else if (
    collaborator_role_type &&
    context.process_external_data?.collaborators?.[collaborator_role_type]
  ) {
    // if we have collaborator_role_type, check for the collaborator next
    const collaborator =
      context.process_external_data.collaborators[collaborator_role_type];
    if (collaborator) {
      return {
        value: fullNameForUser({
          user: collaborator
        }),
        url: user_url ?? undefined
      };
    }
  } else if (legacy_text_replacement_slug) {
    // if we have a legacy_text_replacement_slug, check that last
    return textReplacementValue({
      context,
      slug: legacy_text_replacement_slug
    });
  }
  return undefined;
};

const urlReplacementValues = ({
  context,
  url_string,
  legacy_text_replacement_slug
}: {
  context: COContextInterface;
  url_string?: string | null;
  legacy_text_replacement_slug?: string;
}): COLocalizationReplacementType | undefined => {
  if (url_string) {
    // parse the url
    return {
      value: getURLValue({ context, url_string }) ?? undefined
    };
  } else if (legacy_text_replacement_slug) {
    // if we have a legacy_text_replacement_slug, check that last
    return textReplacementValue({
      context,
      slug: legacy_text_replacement_slug
    });
  }
  return undefined;
};

const getURLValue = ({
  context,
  url_string
}: {
  context: COContextInterface;
  url_string: string;
}): string | null => {
  let url: string | null = url_string;
  if (url[0] === "/") {
    // if the url starts with a /, we need to add the beginning of the url to it
    url = parseMetaItemValueToUnlocalizedString({
      context,
      value: url_string
    });
    if (context.function_hooks?.determineUrlBasedOnService) {
      url = context.function_hooks.determineUrlBasedOnService({
        context,
        url
      });
    } else if (context.process_external_data?.tenant?.tenant_company_url) {
      url = context.process_external_data.tenant.tenant_company_url + url;
    }
  }
  return url;
};

const localizationReplacementValue = ({
  context,
  value
}: {
  context: COContextInterface;
  value?: string;
}): COLocalizationReplacementType => {
  if (!value) {
    return "";
  }
  return {
    value: value,
    localization_values: getLocalizationValues({
      context,
      value: value
    })
  };
};

// Text Replacement Values are from the legacy_notification system
// legacy_notifications don't have all the IDs, so we need to fallback to the Text Replacement Values in those cases
// Normal COAction's shouldn't have text_replacement_values
const textReplacementValue = ({
  context,
  slug
}: {
  context: COContextInterface;
  slug: string;
}): COLocalizationReplacementType | undefined => {
  console.log("Checking for legacy_text_replacement_slug: " + slug);
  if (context.action?.co_action_context?.legacy_text_replacement_values) {
    let legacy_text_replacement_values =
      context.action.co_action_context.legacy_text_replacement_values;
    if (typeof legacy_text_replacement_values === "string") {
      legacy_text_replacement_values = JSON.parse(
        legacy_text_replacement_values
      );
    }

    const text_replacement_value = legacy_text_replacement_values[slug];
    if (text_replacement_value) {
      return {
        value: text_replacement_value.text,
        url: text_replacement_value.url
      };
    }
  }
  console.log("No value found for legacy_text_replacement_slug: " + slug);
  return undefined;
};
