import { COValidationErrorException } from "../classes/co-validation-error-manager.class";
import { COValidationType } from "../constants/co-constants";
import {
  COAssessmentInterface,
  COContextInterface,
  COQuestionInterface,
  COSectionInterface
} from "../interfaces/co-interfaces";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";
import { optionsInContext } from "./co-context.helper";
import { validateAndThrowException } from "./co-object-validation-helper";
import { updatePositionSortOrderForItems } from "./co-position.helper";
import {
  toNumber,
  validationItemForTypeAndTargetInContext
} from "./co-validation.helper";
import { addQuestion, removeQuestion } from "../classes/co-question.class";
import { addSection } from "../classes/co-assessment-section.class";
import { updateContext } from "../classes/co-context.class";

export const addSectionToAssessment = ({
  context,
  assessment,
  template,
  validate = true
}: {
  context: COContextInterface;
  assessment: COAssessmentInterface;
  template: any;
  validate?: boolean;
}): COSectionInterface | null => {
  if (validate) {
    // so when we get this exception it's kinda to late - we  need to check some things ahead of time
    let validatorsForNumberOfSections = validationItemForTypeAndTargetInContext(
      context,
      PATH({
        route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_SECTIONS_OBJECT
      }),
      COValidationType.VALIDATION_TYPE_MAX_ARRAY_LENGTH,
      context.validation_context
    );

    let validator;
    if (validatorsForNumberOfSections.length > 0) {
      validator = validatorsForNumberOfSections[0];
    }

    if (validator) {
      let maxSectionsAllowed = toNumber(validator.value);
      let currentSectionCount = 0;
      if (assessment.co_assessment_sections) {
        currentSectionCount = assessment.co_assessment_sections.length;
      }
      if (currentSectionCount + 1 > maxSectionsAllowed) {
        return null;
      }
    }
  }

  // actually making and adding it
  let section = addSection({
    context,
    assessment,
    rawValuesFromTemplate: template
  });

  //let's check anyways
  if (validate) {
    try {
      validateAndThrowException(context, assessment);
    } catch (error) {
      // console.log(error);
      let valError: COValidationErrorException | any = error;
      console.log(valError.validation_errors);
    }
  }

  return section;
};

export const removeSection = (
  context: COContextInterface,
  assessment: COAssessmentInterface,
  section: COSectionInterface
) => {
  if (assessment.co_assessment_sections) {
    assessment.co_assessment_sections = assessment.co_assessment_sections?.filter(
      existinSection =>
        existinSection.co_assessment_section_ahid !==
        section.co_assessment_section_ahid
    );
    updatePositionSortOrderForItems(assessment.co_assessment_sections);
  }
  const sectionContext = updateContext(context, {
    assessment: assessment,
    section: section
  });
  section.onRemoveFromAssessment?.(sectionContext);
};

// add question
export const addQuestionToSection = ({
  context,
  section,
  template,
  atIndex,
  reCalculatePosition = true,
  validate = true
}: {
  context: COContextInterface;
  section: COSectionInterface;
  template: any;
  atIndex?: number;
  reCalculatePosition?: boolean;
  validate?: boolean;
}): COQuestionInterface => {
  // do we want to dig through the other questions in the section and set the position meta to sort max
  // we also need to check to see if this fails any validation
  // do we have any checks to see the max number of questions? I think we do
  let question = addQuestion({
    context,
    section,
    questionTemplate: template,
    atIndex,
    reCalculatePosition
  });
  if (validate) {
    try {
      validateAndThrowException(context, section);
    } catch (error) {
      let valError: COValidationErrorException | any = error;
      console.log(valError.validation_errors);
    }
  }

  return question;
};

export const removeQuestionFromSection = ({
  context,
  section,
  question,
  validate = true
}: {
  context: COContextInterface;
  section: COSectionInterface;
  question: COQuestionInterface;
  validate?: boolean;
}): COQuestionInterface => {
  // do we want to dig through the other questions in the section and set the position meta to sort max
  // we also need to check to see if this fails any validation
  // do we have any checks to see the max number of questions? I think we do
  removeQuestion(context, section, question);
  if (validate) {
    try {
      validateAndThrowException(context, section);
    } catch (error) {
      let valError: COValidationErrorException | any = error;
      console.log(valError.validation_errors);
    }
  }

  return question;
};

export const canAddQuestion = ({
  context,
  section
}: {
  context: COContextInterface;
  section: COSectionInterface;
}): boolean => {
  // we can do a validator check here or something to see if we can add more
  return true;
};

export const canRemoveQuestion = ({
  context,
  section,
  question
}: {
  context: COContextInterface;
  section: COSectionInterface;
  question: COQuestionInterface;
}): boolean => {
  let resolvedOptions = optionsInContext({
    context: context.update?.({ question }) || context,
    options: question.options || {},
    should_resolve: true
  });
  if (resolvedOptions.prevent_deleting || resolvedOptions.is_read_only) {
    return false;
  }

  return true;
};
