const ALPHABETICAL_LABEL = "Alphabetical";
export const ALPHABETICAL_KEY = "process_name";

const LAUNCH_DATE_LABEL = "Launch Date";
export const LAUNCH_DATE_KEY = "launch_date";

export const SORT_OPTIONS_LIST = [
  {
    label: ALPHABETICAL_LABEL,
    key: ALPHABETICAL_KEY,
    value: ALPHABETICAL_LABEL
  },
  {
    label: LAUNCH_DATE_LABEL,
    key: LAUNCH_DATE_KEY,
    value: LAUNCH_DATE_LABEL
  }
];

//Bottom calculations constants
export const COUNT_DATA_KEY = "count";

export const PAGE_STATS_BACKEND_KEY = "pageStatistics";
export const TOTAL_STATS_BACKEND_KEY = "totalStatistics";

export const PAGE_STATS_FRONTEND_KEY = "page_stats";
export const TOTAL_STATS_FRONTEND_KEY = "total_stats";

export const PAGE_SUM_STAT_ROW_KEY = "page_stats_sum";
export const PAGE_AVERAGE_STAT_ROW_KEY = "page_stats_average";
export const TOTAL_SUM_STAT_ROW_KEY = "total_stats_sum";
export const TOTAL_AVERAGE_STAT_ROW_KEY = "total_stats_average";

export const BACKEND_STATS_KEY_TO_FRONTEND_KEY = {
  [PAGE_STATS_BACKEND_KEY]: PAGE_STATS_FRONTEND_KEY,
  [TOTAL_STATS_BACKEND_KEY]: TOTAL_STATS_FRONTEND_KEY
};

export const ROW_KEY_TO_COLUMN_ORDER = {
  [PAGE_SUM_STAT_ROW_KEY]: 1,
  [TOTAL_SUM_STAT_ROW_KEY]: 2,
  [PAGE_AVERAGE_STAT_ROW_KEY]: 3,
  [TOTAL_AVERAGE_STAT_ROW_KEY]: 4
};

export const COST_LIVE_PAGE_TYPE = "cost-live-automations";
export const PLAN_LIVE_PAGE_TYPE = "plan-live-automations";
export const LIVE_PAGE_BOTTOM_ROWS = {
  [PLAN_LIVE_PAGE_TYPE]: [
    PAGE_SUM_STAT_ROW_KEY,
    PAGE_AVERAGE_STAT_ROW_KEY,
    TOTAL_SUM_STAT_ROW_KEY,
    TOTAL_AVERAGE_STAT_ROW_KEY
  ],
  [COST_LIVE_PAGE_TYPE]: [PAGE_SUM_STAT_ROW_KEY, TOTAL_SUM_STAT_ROW_KEY]
};

export const COLUMNS_WITH_CURRENCY = [
  "human_cost_per_year",
  "running_cost_per_transaction_human",
  "implementation_people_costs",
  "rpa_software_costs_per_year",
  "other_software_costs_per_year",
  "support_team_costs_per_year",
  "infrastructure_costs_per_year",
  "other_costs_per_year",
  "total_running_costs_per_year_robot",
  "running_cost_per_transaction_robot",
  "total_year_one",
  "total_year_two"
];

export const COST_REPORT_SAVE_VIEW_PARAM = "cost_report";
export const COST_REPORT_SAVE_VIEW_KEY = "cost_report";

export const PLANNING_REPORT_SAVE_VIEW_PARAM = "planning_report";
export const PLANNING_REPORT_SAVE_VIEW_KEY = "planning_report";

export const ROW_TOOLTIP_KEY_MAP = {
  [TOTAL_AVERAGE_STAT_ROW_KEY]: "live_automation_total_stats_average_tooptip"
};

export const ROW_TOOLTIP_MAP = {
  [TOTAL_AVERAGE_STAT_ROW_KEY]:
    "Only automations that have the number of days filled in are taken into consideration for computing the average."
};
