import React from "react";
import {
  LOGIN_REDIRECT,
  SIGNUP_TENANT_NAME
} from "../../constants/routerConstants";
import queryString from "query-string";
import styled from "styled-components/macro";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SessionHelper from "../../helpers/SessionHelper";
import { isValidTenantSlug } from "../../utils/tenantSlugValidator";

const Container = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  /* Delay button from showing until redirect likely failed */
  &&&& {
    opacity: 0;
    animation: LoginRedirectPage-button-fade-in 0.3s 3s forwards;

    @keyframes LoginRedirectPage-button-fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
`;

const LoginRedirectPage = () => {
  const { t } = useTranslation("common");
  const getRedirectUrl = () => {
    //determine if we're lookin for a tenant or we gotta signup
    let params = {};
    if (window && window.location && window.location.search) {
      params = queryString.parse(window.location.search);
    }
    let tenant_name = "";
    if (params && params.tenantName && isValidTenantSlug(params.tenantName)) {
      tenant_name = params.tenantName;
    }

    // If the tenant name query param is not present fallback to possible value from storage
    if (!tenant_name) {
      const tenantNameFromStorage = SessionHelper.getLoginLogoutTenantNameWithoutPrefix();
      if (tenantNameFromStorage && isValidTenantSlug(tenantNameFromStorage)) {
        tenant_name = tenantNameFromStorage;
      }
    }

    if (!tenant_name) {
      tenant_name = "";
    }
    let referrerLocation = "";
    if (tenant_name === SIGNUP_TENANT_NAME) {
      referrerLocation = `?referrer=${LOGIN_REDIRECT}`;
    }
    let hash = window.location.hash;
    // Add a query param so we know they came from the login page
    let redirectUrl = "/" + tenant_name + referrerLocation + hash;
    return redirectUrl;
  };

  const redirectToLogin = () => {
    const redirectUrl = getRedirectUrl();
    //this needs to be an actual refresh so the frontend build is proxied
    window.open(redirectUrl, "_self");
  };

  setTimeout(() => {
    console.log("retry redirect");
    redirectToLogin();
  }, 3000);

  redirectToLogin();

  return (
    <Container>
      <a href={getRedirectUrl()}>
        <StyledButton>
          {t([
            "login_redirect_click_if_not_redirected",
            "Click here if you are not automatically redirected"
          ])}
        </StyledButton>
      </a>
    </Container>
  );
};

export default LoginRedirectPage;
