import { COQuestion } from "../../../classes/co-question.class";
import { COTypes, COValidationType } from "../../../constants/co-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../constants/co-path.constants";
import { optionsInContext } from "../../../helpers/co-context.helper";
import {
  COAssessmentInterface,
  COContextInterface,
  COValidationContext,
  COValidationError,
  COValidationItemInterface,
  COValidationSlugs
} from "../../../interfaces/co-interfaces";
import { isNullOrUndefined } from "../../../utils/co-utils";

export const updateCustomizationAssessmentValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.ASSESSMENT_OBJECT
      }),
      meta: {
        value:
          "Questions that can impact a KPI can not be removed from the Assessment"
      },
      validationFunction: ({
        context,
        validation_context,
        target,
        value,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        target: any;
        value: any;
        error: COValidationError;
      }): COValidationError[] => {
        let errors: COValidationError[] = [];
        let contextAssessment: COAssessmentInterface | undefined =
          context.assessment;
        let compareContext = context.comparison_context;
        if (contextAssessment && compareContext) {
          let compareAssessment: COAssessmentInterface | undefined =
            compareContext?.assessment;
          if (compareAssessment) {
            let contextQuestions =
              contextAssessment.functionAllQuestions?.(context) || [];

            let compareQuestions =
              compareAssessment.functionAllQuestions?.(compareContext) || [];
            for (const question of compareQuestions) {
              if (
                !contextQuestions.find(
                  q => q.co_question_ahid === question.co_question_ahid
                )
              ) {
                const canImpactKPI = optionsInContext({
                  context: compareContext.update?.({ question }) || {},
                  options: question.options || {}
                }).can_impact_kpi;

                if (!isNullOrUndefined(canImpactKPI) && !canImpactKPI) {
                  continue;
                }
                error = { ...error };
                error.problem_object = question;
                errors.push(error);
              }
            }
          }
        }

        return errors;
      },
      validationSlug: COValidationSlugs.QUESTION_REMOVED,
      validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.ASSESSMENT_OBJECT
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.ASSESSMENT_OBJECT
      }),
      meta: {
        value:
          "Reference Questions can not be added or removed from the Assessment"
      },
      validationFunction: ({
        context,
        validation_context,
        target,
        value,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        target: any;
        value: any;
        error: COValidationError;
      }): COValidationError[] => {
        let errors: COValidationError[] = [];
        let contextAssessment: COAssessmentInterface | undefined =
          context.assessment;
        let compareContext = context.comparison_context;
        if (contextAssessment && compareContext) {
          let compareAssessment: COAssessmentInterface | undefined =
            compareContext?.assessment;
          if (compareAssessment) {
            let contextQuestions =
              contextAssessment.functionAllQuestions?.(context) || [];

            let compareQuestions =
              compareAssessment.functionAllQuestions?.(compareContext) || [];

            // If there isn't a pair that means one was added or removed
            const pairedReferenceQuestions: {
              [key: string]: {
                count: number;
                question: COQuestion;
              };
            } = {};

            [...contextQuestions, ...compareQuestions].forEach(question => {
              if (
                question.co_question_co_type ===
                COTypes.CALCULATED_VALUE_REFERENCE
              ) {
                const question_ahid = question.co_question_ahid || "";
                if (question_ahid && pairedReferenceQuestions[question_ahid]) {
                  pairedReferenceQuestions[question_ahid].count++;
                } else if (question_ahid) {
                  pairedReferenceQuestions[question_ahid] = {
                    count: 1,
                    question
                  };
                }
              }
            });

            Object.values(pairedReferenceQuestions).forEach(
              pairedReferenceQuestion => {
                if (pairedReferenceQuestion.count !== 2) {
                  error = { ...error };
                  error.problem_object = pairedReferenceQuestion.question;
                  errors.push(error);
                }
              }
            );
          }
        }
        return errors;
      },
      validationSlug: COValidationSlugs.REFERENCE_QUESTION_ADDED_OR_REMOVED,
      validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.ASSESSMENT_OBJECT
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.ASSESSMENT_OBJECT
      }),
      meta: {
        value:
          "Reference Questions can not be have their referenced value changed"
      },
      validationFunction: ({
        context,
        validation_context,
        target,
        value,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        target: any;
        value: any;
        error: COValidationError;
      }): COValidationError[] => {
        let errors: COValidationError[] = [];
        let contextAssessment: COAssessmentInterface | undefined =
          context.assessment;
        let compareContext = context.comparison_context;
        if (contextAssessment && compareContext) {
          let compareAssessment: COAssessmentInterface | undefined =
            compareContext?.assessment;
          if (compareAssessment) {
            let contextQuestions =
              contextAssessment.functionAllQuestions?.(context) || [];

            let compareQuestions =
              compareAssessment.functionAllQuestions?.(compareContext) || [];
            const referenceQuestionsCalculatedColumnNameMatch: {
              [q_ahid: string]: string;
            } = {};

            [...compareQuestions, ...contextQuestions].forEach(question => {
              if (
                question.co_question_co_type ===
                COTypes.CALCULATED_VALUE_REFERENCE
              ) {
                const q_ahid = question?.co_question_ahid;
                if (
                  q_ahid &&
                  referenceQuestionsCalculatedColumnNameMatch[q_ahid]
                ) {
                  if (
                    referenceQuestionsCalculatedColumnNameMatch[q_ahid] !==
                    question?.co_question_options_json
                      ?.referenced_calculated_value_process_column_name
                  ) {
                    error = { ...error };
                    error.problem_object = question;
                    errors.push(error);
                  }
                } else if (q_ahid) {
                  referenceQuestionsCalculatedColumnNameMatch[q_ahid] =
                    question?.co_question_options_json
                      ?.referenced_calculated_value_process_column_name ||
                    `\_o_/`; // fall back to something because we want to know if this changed
                }
              }
            });
          }
        }
        return errors;
      },
      validationSlug: COValidationSlugs.REFERENCE_QUESTION_REFERENCE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.ASSESSMENT_OBJECT
      })
    }
  ];
};

export const updateCustomizationQuestionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_EQUATION
      }),
      meta: {
        value: "Question equations must not change"
      },
      validationSlug: COValidationSlugs.QUESTION_EQUATION_MATCH,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_EQUATION
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_VARIABLE_NAME
      }),
      meta: {
        value: "Question variables must not change"
      },
      validationSlug: COValidationSlugs.QUESTION_VARIABLE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_VARIABLE_NAME
      }),
      meta: {
        value: "Question variables must not change"
      },
      validationSlug: COValidationSlugs.QUESTION_VARIABLE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      }),
      meta: {
        value: "Question type must not change"
      },
      validationSlug: COValidationSlugs.QUESTION_TYPE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_QUESTION_ANSWER_OPTIONS
      }),
      meta: {
        value: "Question answer options must not be removed"
      },
      validationFunction: ({
        context,
        validation_context,
        target,
        value,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        target: any;
        value: any;
        error: COValidationError;
      }): COValidationError[] => {
        let errors: COValidationError[] = [];
        if (Array.isArray(target) && Array.isArray(value)) {
          for (const answer_option of target) {
            if (
              !value.find(
                ao =>
                  ao.co_question_answer_option_ahid ===
                  answer_option.co_question_answer_option_ahid
              )
            ) {
              error = { ...error };
              error.problem_object = answer_option;
              errors.push(error);
            }
          }
        }
        return errors;
      },
      validationSlug: COValidationSlugs.QUESTION_ANSWER_OPTION_REMOVED,
      validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_ANSWER_OPTIONS
      })
    }
  ];
};

export const updateCustomizationKPIValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_EQUATION
      }),
      meta: {
        value: "KPI equations must not change"
      },
      validationSlug: COValidationSlugs.KPI_EQUATION_MATCH,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_EQUATION
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_VARIABLE_NAME
      }),
      meta: {
        value: "KPI variables must not change"
      },
      validationSlug: COValidationSlugs.KPI_VARIABLE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.COMPARISON_CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      }),
      meta: {
        value: "KPI type must not change"
      },
      validationSlug: COValidationSlugs.KPI_TYPE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      })
    }
  ];
};

export const updateCustomizationAnswerOptionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.COMPARISON_CONTEXT.ANSWER_OPTION
            .CO_QUESTION_ANSWER_OPTION_VALUE
      }),
      meta: {
        value: "Question answer options values must not change"
      },
      validationSlug: COValidationSlugs.QUESTION_ANSWER_OPTION_VALUE_CHANGED,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.CO_QUESTION_ANSWER_OPTION_VALUE
      }),
      // we don't need to check answer_option_value if the question doesnt impact KPI
      // the UI for answer_option_value isn't even shown if can_impact_kpi is OFF
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
        operator: PATH_OPERATORS.INVERSE
      })
    }
  ];
};

export const assessmentVersionCheck = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON.VERSION
            .VALUE
      }),
      meta: {
        value: "Please enter a valid version number such as 2.0"
      },
      validationFunction: ({
        context,
        validation_context,
        target,
        value,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        target: any;
        value: any;
        error: COValidationError;
      }): COValidationError[] => {
        let errors: COValidationError[] = [];

        if (target) {
          let regexForVersion = /^(?:\d+)(?:\.(?:\d+))$/g;

          if (!target.match(regexForVersion)) {
            error = { ...error };
            errors.push(error);
            return errors;
          }
        }
        return errors;
      },
      validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
      validation_context: {
        assessment_customize: 1,
        assessment_customize_update: 1
      },
      value: 1
    }
  ];
};

export const updateCustomizationSectionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.COMPARISON_CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON
            .IS_TABULAR
      }),
      meta: {
        value:
          "Existing Sections can not be changed between table formatting and non table formatting "
      },
      validationSlug: COValidationSlugs.SECTION_IS_TABULAR_MATCH,
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validation_context: {
        assessment_customize_update: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_TABULAR
      })
    }
  ];
};
