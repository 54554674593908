import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./../inputWithLabelStyles.scss";
import "./InputTextWithLabel.scss";

class InputTextWithLabel extends Component {
  constructor(props) {
    super(props);
    this.errorRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && !prevProps.error && this.errorRef.current) {
      this.errorRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }
  }

  render() {
    const {
      label,
      value = "",
      name,
      className,
      onChange,
      placeholder = "",
      type = "text",
      error = "",
      required = false,
      maxLength,
      autoComplete = "on",
      readOnly = false,
      disabled
    } = this.props;
    const baseClass = ["inputWithLabelStyles", "InputTextWithLabel"];

    if (className) {
      baseClass.push(className);
    }

    const maxLengthCheck = e => {
      if (e.target.value.length > maxLength && maxLength > 0) {
        let value = e.target.value;
        value = value.substring(0, maxLength);
        e.target.value = value;
      }
      onChange(e);
    };

    let maxLengthClass = "inputWithLabelStyles__length-limit";
    const valueLength = value && !isNaN(value.length) ? value.length : 0;

    if (maxLength && valueLength >= maxLength) {
      maxLengthClass += " inputWithLabelStyles__length-limit--hit";
    }

    const showMaxLength = maxLength && valueLength > maxLength - 5;

    const inputProps = {
      className: `inputWithLabelStyles__input ${
        showMaxLength ? "inputWithLabelStyles__input--max-length" : ""
      }`,
      type,
      value,
      name,
      onChange: maxLength ? maxLengthCheck : onChange,
      required,
      placeholder,
      autoComplete,
      readOnly,
      disabled
    };

    return (
      <div className={baseClass.join(" ")} data-testid="InputTextWithLabel">
        <p className="inputWithLabelStyles__label">
          {label}
          {required && (
            <span className="inputWithLabelStyles__label-required">*</span>
          )}
        </p>
        <div className="inputWithLabelStyles__input-wrapper">
          {type === "textarea" ? (
            <textarea {...inputProps} />
          ) : (
            <input data-testid="inputWithLabelStyles__Input" {...inputProps} />
          )}
          {showMaxLength && (
            <span
              className={maxLengthClass}
            >{`${valueLength}/${maxLength}`}</span>
          )}
        </div>
        {error && <p className="inputWithLabelStyles__error">{error}</p>}
      </div>
    );
  }
}

export default withTranslation("common")(InputTextWithLabel);
