import axios from "axios";
import { auth0Handler } from "./auth0Handler";
import { authFPSHandler } from "./authFPSHandler";
import Swal, {
  SwalForRegisteringError
} from "./../components/SweetAlert/SweetAlert";
import {
  swalSpinnerClose,
  swalSpinnerForAuthenticatingLoading
} from "./../utils/SweetAlertLoaderSpinner";
import apiErrorAlert, {
  checkForAndRedirectFromBackend
} from "../utils/apiErrorAlert";
import SessionHelper from "./../helpers/SessionHelper";
import TenantSettingsHelper from "./../helpers/TenantSettingsHelper";
import {
  API_URL,
  PUBLIC_URL,
  BUILD_VERSION
} from "./../constants/envConstants";

import logoHelper from "../helpers/tenantLogoHelper";
import { isNullOrUndefined } from "util";

import {
  triggerAnalyticsWithDefaultData,
  ANALYTICS_LOG_IN_EVENT_NAME,
  triggerTelemetryAfterAuth
} from "./analyticsUtils";
import { AUTH_TYPE_AUTH0 } from "../constants/authConstants";
import platform from "platform";

export default class Auth {
  //this triggers the login and returns to the app with a token to the redirectUri
  authorize = () => {
    SessionHelper.isFpsUri()
      ? authFPSHandler.authorize()
      : auth0Handler.authorize();
  };

  signup = () => {
    auth0Handler.signup();
    //no FPS signup
  };

  //this checks to see if we have a hash from the callback or then checks in the background - for general login
  getLoggedInUserTokenFromHash = authenticateUserTokenCallBack => {
    SessionHelper.isFpsUri()
      ? authFPSHandler.getLoggedInUserTokenFromHash(
          authenticateUserTokenCallBack
        )
      : auth0Handler.getLoggedInUserTokenFromHash(
          authenticateUserTokenCallBack
        );
  };

  //this is used for signup // so only for auth0 flow
  getAuthResultFromHash = authResultCallback => {
    SessionHelper.isFpsUri()
      ? authFPSHandler.getAuthResultFromHash(authResultCallback)
      : auth0Handler.getAuthResultFromHash(authResultCallback);
  };

  //this does the auth call in the background and doens't prompt the user if it works / fails
  refreshSessionInBackground = authenticateUserTokenCallBack => {
    SessionHelper.isFpsUri()
      ? authFPSHandler.refreshSessionInBackground(authenticateUserTokenCallBack)
      : auth0Handler.refreshSessionInBackground(authenticateUserTokenCallBack);
  };

  logout = () => {
    SessionHelper.isFpsUri() ? authFPSHandler.logout() : auth0Handler.logout();
  };

  //here we pass the auth and id token up to the backend where it loads the user data and sends us a new token that we save for future auth that has the tenant data in it
  refreshUserFromAuthResult = (
    authResult,
    authenticateUserTokenCallBack,
    silentRefresh,
    loginErrorHandler = null
  ) => {
    if (silentRefresh === false) {
      swalSpinnerForAuthenticatingLoading();
    }
    let idToken = authResult.idToken ? authResult.idToken : "";
    let accessToken = authResult.accessToken ? authResult.accessToken : "";
    let authType = authResult.authType ? authResult.authType : AUTH_TYPE_AUTH0; //default to auth0
    // The contents of authResult depend on which authentication parameters were used.
    // authResult.accessToken - access token for the API specified by `audience`
    // authResult.idToken - ID token JWT containing user profile information
    let headers = {
      "x-id-token": idToken,
      Authorization: "Bearer " + accessToken,
      Tenant: SessionHelper.tenantNameFromCurrentPath(),
      authType: authType,
      PUBLIC_URL: PUBLIC_URL,
      BUILD_VERSION: BUILD_VERSION,
      "x-ah-browser-version": platform.name + " " + platform.version,
      "x-ah-os-version": platform.os?.toString(),
      "x-ah-system-lang": navigator.language,
      "x-ah-product-lang": SessionHelper.getLanguage() || ""
    };

    let path =
      API_URL +
      (authType === AUTH_TYPE_AUTH0
        ? "/fd-api/v1/auth/login"
        : "/fd-api/v1/auth/fps-login");

    // get the old token before we use the new token
    const oldToken = SessionHelper.getUserToken();
    axios
      .get(path, { headers: headers, isAuthLogin: true })
      .then(resp => {
        //set the user and token, now all calls will be authentiated
        if (silentRefresh === false) {
          swalSpinnerClose();
        }
        if (resp.data) {
          if (resp.data.data) {
            let data = resp.data.data;
            let user = data.user;
            let tenantLogo = data.tenant_company_logo;
            let user_token = data.token;
            let tenant_settings = data.tenant_settings;
            SessionHelper.userLoggedIn(user, user_token);
            if (!isNullOrUndefined(tenantLogo)) {
              logoHelper.setLogo(tenantLogo);
            }
            let refresh_required = data.refresh_required;
            if (refresh_required) {
              Swal.fire({
                title: "Automation Hub has Been Updated!",
                text: "Press Ok to Reload your Browser Window",
                icon: "success",
                timer: 60000 //auto reload if they're not paying attention
              }).then(() => {
                window.location.reload();
              });
            }
            if (!isNullOrUndefined(tenant_settings)) {
              TenantSettingsHelper.setSettings(tenant_settings);
            }
            if (authenticateUserTokenCallBack) {
              authenticateUserTokenCallBack(user_token);
            }
            triggerTelemetryAfterAuth(authType, oldToken);
          }
        }

        // Needs to happen after user data is set
        triggerAnalyticsWithDefaultData(ANALYTICS_LOG_IN_EVENT_NAME, {
          isLoggedIn: true
        });
      })
      .catch(error => {
        if (error.config && error.response) {
          let message = "An error has occurred";
          //If it's cloud exclusive tenant, redirect to Automation Cloud directly
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 405 &&
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.url
          ) {
            swalSpinnerClose();
            // Add partial path for redirect
            // if user tried to go to /submit/idea when we redirect to cloud we need to add on the /submit/idea at the end of the url
            const pathWithTimestamp =
              SessionHelper.getValueForKey("pathWithTimestamp") || {};
            const routePath = pathWithTimestamp?.pathWithoutTenantInfo || "";
            const redirectUrl = error.response.data.data.url;
            window.location.assign(redirectUrl + routePath);
            return;
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.message
          ) {
            message = error.response.data.data.message;
          }
          swalSpinnerClose();

          if (checkForAndRedirectFromBackend(error)) {
            return false;
          }

          let messageObj = {
            message: message
          };

          if (
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.localizationKey
          ) {
            messageObj.localizationKey =
              error.response.data.data.localizationKey;
          }

          let countryName =
            error.response.data?.data?.automation_cloud?.country;
          if (!!countryName) {
            SessionHelper.setDataForKey(countryName, "country");
          }
          if (loginErrorHandler) {
            //specifically for handling situations were we want to create a tenant for fps
            if (authenticateUserTokenCallBack) {
              authenticateUserTokenCallBack(null);
            }
            loginErrorHandler(headers, error, messageObj);
          } else {
            SwalForRegisteringError(messageObj)
              .fire()
              .then(e => {
                if (authenticateUserTokenCallBack) {
                  authenticateUserTokenCallBack(null);
                }
              });
          }
        } else {
          swalSpinnerClose();
          apiErrorAlert(error);
          if (authenticateUserTokenCallBack) {
            authenticateUserTokenCallBack(null);
          }
        }
        console.log(error);
      });
  };
}

export const auth = new Auth();
