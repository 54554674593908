import { COMetadataInterface } from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "./co-utils";

export const updateMetaDataValues = ({
  metadata,
  title,
  description,
  help_text,
  note
}: {
  metadata?: COMetadataInterface;
  title?: string;
  description?: string;
  help_text?: string;
  note?: string;
}) => {
  if (metadata && metadata.title && !isNullOrUndefined(title)) {
    metadata.title.value = title;
    metadata.title.help_text = help_text;
  }
  if (metadata && !isNullOrUndefined(note)) {
    if (metadata.note) {
      metadata.note.value = note;
    } else {
      metadata.note = { value: note };
    }
  }
  if (metadata && metadata.description) {
    metadata.description.value = draftJSMetaDescriptionString(description);
  }
};

export const draftJSMetaDescriptionString = (str = "") => {
  let json = {
    blocks: [
      {
        key: "desc_key",
        text: str,
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  };
  return JSON.stringify(json);
};
