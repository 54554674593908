import isEmail from "validator/lib/isEmail";
import axios from "./axiosInstance";
import apiErrorAlert from "./apiErrorAlert";
import { getDomain } from "../utils/stringParsers";

import {
  COMPANY_NAME,
  WORK_EMAIL,
  LICENSE_PLAN,
  LICENSE_PLAN_OPTIONS,
  TENANT_NAME,
  SALES_MESSAGE,
  RENEW_SUBSCRIPTION
} from "../constants/accountLicenseConstants";
import cloneDeep from "lodash/cloneDeep";

const nonWhitespaceCharRegex = /\S/;

const removeAllErrors = state => {
  const stateWithoutErrors = cloneDeep(state);
  Object.keys(stateWithoutErrors).forEach(fieldKey => {
    delete stateWithoutErrors[fieldKey].error;
  });

  return stateWithoutErrors;
};

export const checkIfPersonalEmail = async email => {
  const domain = getDomain(email);
  let responseObj;

  if (domain) {
    try {
      responseObj = await axios.get(
        `/api/v1/admin/tenant/personal-email-domain/${domain}`
      );
      if (
        typeof responseObj.data === "object" &&
        responseObj.data.data instanceof Array &&
        responseObj.data.data.length > 0
      ) {
        return true;
      }

      return false;
    } catch (error) {
      apiErrorAlert(error);
      return false;
    }
  }

  return false;
};

export const validateChangePlanFields = async state => {
  let updatedState = cloneDeep(state);
  //do validations
  let validationObj = {
    hasError: false
  };

  updatedState = removeAllErrors(updatedState);

  if (
    !updatedState[COMPANY_NAME].value ||
    !nonWhitespaceCharRegex.test(updatedState[COMPANY_NAME].value)
  ) {
    validationObj.hasError = true;
    updatedState[COMPANY_NAME].error = "Please include your company name.";
  }

  if (!isEmail(updatedState[WORK_EMAIL].value)) {
    validationObj.hasError = true;
    updatedState[WORK_EMAIL].error = "Please enter a valid email.";
  }

  const isPersonalEmail = await checkIfPersonalEmail(
    updatedState[WORK_EMAIL].value
  );

  if (isPersonalEmail) {
    validationObj.hasError = true;
    updatedState[WORK_EMAIL].error =
      "Please enter a different email address. Personal email addresses are not accepted.";
  }

  if (!updatedState[LICENSE_PLAN].value) {
    validationObj.hasError = true;
    updatedState[LICENSE_PLAN].error = "Please select a license plan.";
  }

  if (
    !updatedState[TENANT_NAME].value ||
    !nonWhitespaceCharRegex.test(updatedState[TENANT_NAME].value)
  ) {
    validationObj.hasError = true;
    updatedState[TENANT_NAME].error = "Please include your tenant name.";
  }

  if (
    !updatedState[SALES_MESSAGE].value ||
    !nonWhitespaceCharRegex.test(updatedState[SALES_MESSAGE].value)
  ) {
    validationObj.hasError = true;
    updatedState[SALES_MESSAGE].error = "Please include a message to sales.";
  }

  if (validationObj.hasError) {
    validationObj.updatedState = updatedState;
  }
  return validationObj;
};

export const validateExtendTrialFields = state => {
  let updatedState = cloneDeep(state);
  //do validations
  let validationObj = {
    hasError: false
  };

  updatedState = removeAllErrors(updatedState);

  if (
    !updatedState[SALES_MESSAGE].value ||
    !nonWhitespaceCharRegex.test(updatedState[SALES_MESSAGE].value)
  ) {
    validationObj.hasError = true;
    updatedState[SALES_MESSAGE].error = "Please include a message.";
  }

  if (validationObj.hasError) {
    validationObj.updatedState = updatedState;
  }

  return validationObj;
};

export const validateBuyMoreLicensesFields = state => {
  let updatedState = cloneDeep(state);
  //do validations
  let validationObj = {
    hasError: false
  };

  updatedState = removeAllErrors(updatedState);

  if (
    !updatedState[SALES_MESSAGE].value ||
    !nonWhitespaceCharRegex.test(updatedState[SALES_MESSAGE].value)
  ) {
    validationObj.hasError = true;
    updatedState[SALES_MESSAGE].error = "Please include a message.";
  }

  if (validationObj.hasError) {
    validationObj.updatedState = updatedState;
  }

  return validationObj;
};

export const validateRequestRenewalFields = state => {
  let updatedState = cloneDeep(state);
  //do validations
  let validationObj = {
    hasError: false
  };

  updatedState = removeAllErrors(updatedState);
  if (
    !updatedState[RENEW_SUBSCRIPTION] ||
    !updatedState[RENEW_SUBSCRIPTION].value
  ) {
    validationObj.hasError = true;
    if (!updatedState[RENEW_SUBSCRIPTION]) {
      updatedState[RENEW_SUBSCRIPTION] = {};
    }
    updatedState[RENEW_SUBSCRIPTION].error =
      "Please select the number of years you want to renew for.";
  }

  if (
    !updatedState[SALES_MESSAGE].value ||
    !nonWhitespaceCharRegex.test(updatedState[SALES_MESSAGE].value)
  ) {
    validationObj.hasError = true;
    updatedState[SALES_MESSAGE].error = "Please include a message.";
  }

  if (validationObj.hasError) {
    validationObj.updatedState = updatedState;
  }

  return validationObj;
};
