import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import TetherComponent from "react-tether";
import { ReactComponentLike, ReactElementLike } from "prop-types";
import { NESTED_THEME_CONTAINER_ID } from "../StyleThemeProvider/StyleThemeProvider";
import { focusOnFirstFocusableElement } from "../../utils/accessibilityUtils/accessibilityUtils";
import { useGetId } from "../../utils/customHooks/useGetId";
import { maintainFocusInContainer } from "../../utils/accessibilityUtils/accessibilityUtils";
import {
  FOCUSABLE_ELEMENTS_SELECTOR,
  CLOSE_DROPDOWN_EVENT
} from "../../constants/accessibilityConstants";

const Container = styled.div`
  display: inline-block;
`;

export const DROPDOWN_TETHERED_NO_CLOSE_ON_CLICK_CLASS_NAME =
  "DropdownTethered__no-close-onclick-by-closest";

// Dropdown Tethered is for creating dropdowns
// in components whose ancestors have overflow issues

const DropdownTethered = ({
  attachment = "top right",
  targetAttachment = "bottom right",
  baseComponent,
  children,
  initialOpen = false,
  disableClose = false,
  className,
  tetherClassName = "",
  tetherId,
  offset,
  targetOffset,
  closeOnOutsideClickOnly,
  constrainToWindow = false,
  tetherStyle = {},
  DropdownComponent,
  parentComponentCallbck
}: {
  baseComponent?: ReactElementLike;
  attachment?: string;
  targetAttachment?: string;
  children?: ReactElementLike;
  initialOpen?: boolean;
  disableClose?: boolean;
  className?: string;
  tetherId?: string;
  tetherClassName?: string;
  offset?: string;
  targetOffset?: string;
  closeOnOutsideClickOnly?: boolean;
  constrainToWindow?: boolean;
  tetherStyle?: { [styleKey: string]: any };
  DropdownComponent?: ReactComponentLike;
  parentComponentCallbck?: (isOpen: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  let dropdownContainerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(
    null
  );
  let parentContainerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(
    null
  );
  const tetherElementId = useGetId(tetherId);

  useEffect(() => {
    typeof parentComponentCallbck === "function" &&
      parentComponentCallbck(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !!dropdownContainerRef?.current) {
      focusOnFirstFocusableElement(dropdownContainerRef.current);

      document.addEventListener(
        CLOSE_DROPDOWN_EVENT,
        focusOnParentContainerFocusableElement,
        false
      );
    }
    return () => {
      document.removeEventListener(
        CLOSE_DROPDOWN_EVENT,
        focusOnParentContainerFocusableElement
      );
    };
  }, [isOpen, dropdownContainerRef?.current]);

  useEffect(() => {
    if (!disableClose) {
      if (isOpen) {
        document.addEventListener("click", handleOutsideClick);
      } else {
        document.removeEventListener("click", handleOutsideClick);
      }

      document.addEventListener("keydown", listenKeyboard, true);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
        document.removeEventListener("keydown", listenKeyboard, true);
      };
    }
  }, [isOpen, disableClose, handleOutsideClick]);

  function listenKeyboard(event) {
    if (event.key === "Escape" || event.keyCode === 27) {
      setIsOpen(false);
      focusOnParentContainerFocusableElement();
    } else if (event.key === "Tab" || event.keyCode === 9) {
      onTabPressed(event);
    }
  }

  function onTabPressed(e) {
    const dropdownElement = dropdownContainerRef?.current;
    maintainFocusInContainer(e, dropdownElement);
  }

  function focusOnParentContainerFocusableElement() {
    if (
      !parentContainerRef ||
      !parentContainerRef.current ||
      typeof parentContainerRef.current.focus !== "function"
    ) {
      return;
    }
    const focusableElements =
      parentContainerRef.current.querySelectorAll(
        FOCUSABLE_ELEMENTS_SELECTOR
      ) || [];
    const firstFocusableElement = focusableElements[0];
    (firstFocusableElement as HTMLElement)?.focus();
  }

  function isNotOutsideClick(target?: EventTarget | null): boolean {
    //needs to be casted to use methods for elements
    const castedTarget = target as HTMLElement;
    const closestTetherElement = castedTarget?.closest(".tether-element");
    const closestPseudoOutsideElement = castedTarget?.closest(
      ".tether-is-outside"
    );

    //close element if the parent has a '.tether-is-outside' class
    if (
      !!closestPseudoOutsideElement &&
      closestTetherElement?.id === tetherElementId
    ) {
      return false;
    }

    //need to the check that tether element closest is the same tether element as this component
    //fixes edge case where multiple tether elements are open
    //which gives a false positive

    if (
      (!!closestTetherElement &&
        closestTetherElement?.id === tetherElementId) ||
      !!castedTarget?.closest(".tether-no-close-onclick")
    ) {
      return true;
    }

    return false;
  }

  function handleOutsideClick(e: MouseEvent) {
    if (!disableClose) {
      if (closeOnOutsideClickOnly && isNotOutsideClick(e?.target)) {
        return;
      }
      const target = e?.target as HTMLElement;
      if (
        target?.closest &&
        target?.closest(`.${DROPDOWN_TETHERED_NO_CLOSE_ON_CLICK_CLASS_NAME}`)
      ) {
        return;
      }
      setIsOpen(false);
    }
  }

  const extraProps: any = {};
  if (offset) {
    extraProps.offset = offset;
  }

  if (targetOffset) {
    extraProps.targetOffset = targetOffset;
  }

  if (constrainToWindow) {
    extraProps.constraints = [
      {
        to: "window",
        attachment: "together"
      }
    ];
  }

  const generateDropdownElement = (ref: any) => {
    dropdownContainerRef = ref;
    return (
      isOpen && (
        <div ref={ref}>
          {DropdownComponent ? (
            <DropdownComponent
              closeDropdown={() => setIsOpen(false)}
              parentContainerRef={parentContainerRef}
            />
          ) : (
            children
          )}
        </div>
      )
    );
  };

  const onKeyDown = (e, ref) => {
    if (isOpen && (e.key === "Escape" || e.keyCode === 27)) {
      e.preventDefault();
      setIsOpen(false);
    } else if (
      !isOpen &&
      (e.key === "Enter" ||
        e.key === " " ||
        e.keyCode === 13 ||
        e.keyCode === 32)
    ) {
      e.preventDefault();
      openMenu(e, ref);
    }
  };

  const openMenu = (e, ref) => {
    e?.stopPropagation();
    if (!disableClose) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <TetherComponent
      attachment={attachment || "top center"}
      targetAttachment={targetAttachment}
      className={tetherClassName}
      id={tetherElementId}
      style={tetherStyle}
      {...extraProps}
      renderTarget={(ref: any) => {
        parentContainerRef = ref;
        return (
          <Container
            ref={ref}
            className={className}
            onClick={e => openMenu(e, ref)}
            onKeyDown={e => onKeyDown(e, ref)}
          >
            {baseComponent || null}
          </Container>
        );
      }}
      renderElement={generateDropdownElement}
      renderElementTo={
        document.querySelector(`#${NESTED_THEME_CONTAINER_ID}`) || document.body
      }
    />
  );
};

export default DropdownTethered;
