import { COContext } from "../classes/co-context.class";
import { COProcessExternalData } from "../classes/co-process-external-data.class";
import {
  COBaseInterface,
  CODocumentOptionsInterface,
  COExternalCallbackInterface,
  COSwalPopUpInterface,
  COContextInterface,
  COActionContextInterface
} from "../interfaces/co-interfaces";
import { COModalType } from "../constants/co-constants";

// sets the reference (which can be shared among multiple assessments)
export const setProcessData = (
  context: COContext,
  data: COProcessExternalData
): COContext => {
  context.process_external_data = data;
  return context;
};

// merges external process data
export const updateProcessData = (
  context: COContext,
  data: COProcessExternalData
): COContext => {
  if (!context.process_external_data) {
    context.process_external_data = new COProcessExternalData();
  }
  context.process_external_data?.updateFromProcess?.(data);
  return context;
};

export const updateFromActionContext = ({
  context,
  co_action_context
}: {
  context: COContext;
  co_action_context: COActionContextInterface;
}): COContext => {
  return updateProcessData(context, {
    process: co_action_context.process,
    process_phase: co_action_context.process_phase,
    process_status: co_action_context.process_status,
    process_rejected_reason: co_action_context.process_rejected_reason,
    process_archived_reason: co_action_context.process_archived_reason,
    user: co_action_context.user,
    referenced_user: co_action_context.referenced_user,
    comment: co_action_context.comment,
    component: co_action_context.component,
    component_rejected_reason: co_action_context.component_rejected_reason,
    component_comment: co_action_context.component_comment,
    document: co_action_context.document,
    template: co_action_context.template,
    role: co_action_context.role,
    reported_process: co_action_context.reported_process,
    process_reported_comment: co_action_context.process_reported_comment,
    process_question: co_action_context.process_question,
    tenant: co_action_context.tenant,
    process_role_name: co_action_context.process_role_name,
    collaborator_roles: co_action_context.collaborator_roles,
    permission_roles: co_action_context.permission_roles,
    user_attributes: co_action_context.user_attributes,
    collaborators: co_action_context.collaborators,
    beginningOfEmail: co_action_context.beginningOfEmail,
    endOfEmail: co_action_context.endOfEmail,
    emailTitle: co_action_context.emailTitle,
    emailTemplate: co_action_context.emailTemplate,
    reminderEmailTitlePrefix: co_action_context.reminderEmailTitlePrefix,
    reminderEmailBodyPrefix: co_action_context.reminderEmailBodyPrefix
  });
};

export const setFunctionHooks = ({
  context,
  showModal,
  showSwalPopUp,
  documentSelectPopup,
  openEditViewForProcess,
  determineUrlBasedOnService,
  triggerUiRender,
  translateFunction
}: {
  context: COContext;
  showModal?: (props: {
    context: any;
    modalType: COModalType;
    payload: any;
  }) => void;
  showSwalPopUp?: (props: {
    context: any;
    co_swal_pop_up: COSwalPopUpInterface;
    callback?: (confirmed: boolean) => void;
  }) => void;
  documentSelectPopup?: (props: {
    context: COContext;
    question?: COBaseInterface;
    documentOptions: CODocumentOptionsInterface;
    callback: Function;
  }) => void;
  openEditViewForProcess?: (props: { context: COContextInterface }) => void;
  determineUrlBasedOnService?: (props: {
    context: COContextInterface;
    url: string;
  }) => string | null;
  triggerUiRender?: () => void;
  translateFunction?: (key: string | string[], options?: any) => string;
}) => {
  if (!context.function_hooks) {
    context.function_hooks = {};
  }
  if (showModal) {
    context.function_hooks.showModal = showModal;
  }
  if (showSwalPopUp) {
    context.function_hooks.showSwalPopUp = showSwalPopUp;
  }
  if (documentSelectPopup) {
    context.function_hooks.documentSelectPopup = documentSelectPopup;
  }
  if (openEditViewForProcess) {
    context.function_hooks.openEditViewForProcess = openEditViewForProcess;
  }
  if (determineUrlBasedOnService) {
    context.function_hooks.determineUrlBasedOnService = determineUrlBasedOnService;
  }
  if (triggerUiRender) {
    context.function_hooks.triggerUiRender = triggerUiRender;
  }
  if (translateFunction) {
    context.function_hooks.translateFunction = translateFunction;
  }
  return context;
};

interface COExternalCallbackInterfaceWithContext
  extends COExternalCallbackInterface {
  context: COContext;
}

export const setProcessCallbacks = ({
  context,
  applicationsCallback,
  userPickerCallback,
  documentsCallback,
  mediaCallback,
  processCallback,
  similarAutomationCallback,
  componentsCallback,
  tagsCallback,
  categoriesCallback,
  collaboratorsCallback
}: COExternalCallbackInterfaceWithContext): COContext => {
  if (!context.process_external_data) {
    context.process_external_data = new COProcessExternalData();
  }
  if (applicationsCallback) {
    context.process_external_data.dataSyncApplicationsProcessCallback = applicationsCallback;
  }
  if (documentsCallback) {
    context.process_external_data.dataSyncDocumentsProcessCallback = documentsCallback;
  }
  if (userPickerCallback) {
    context.process_external_data.dataSyncUserCallback = userPickerCallback;
  }
  if (mediaCallback) {
    context.process_external_data.dataSyncMediaCallback = mediaCallback;
  }
  if (processCallback) {
    context.process_external_data.dataSyncProcessPropertyCallback = processCallback;
  }
  if (similarAutomationCallback) {
    context.process_external_data.dataSyncSimilarAutomationsCallback = similarAutomationCallback;
  }
  if (componentsCallback) {
    context.process_external_data.dataSyncComponentsCallback = componentsCallback;
  }
  if (tagsCallback) {
    context.process_external_data.dataSyncTagsCallback = tagsCallback;
  }
  if (categoriesCallback) {
    context.process_external_data.dataSyncCategoryCallback = categoriesCallback;
  }
  if (collaboratorsCallback) {
    context.process_external_data.dataSyncCollaboratorsProcessCallback = collaboratorsCallback;
  }
  return context;
};
