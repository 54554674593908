import { CO_SUBMIT_PROCESS_PAGE } from "./urlConstants";

// explore
export const EXPLORE_AUTOMATIONS_PROGRAM_ROUTE = "/explore-automation-program";
export const EXPLORE_IDEAS_ROUTE = "/explore-ideas";
export const EXPLORE_AUTOMATIONS_ROUTE = "/explore-automations";
export const EXPLORE_PEOPLE_ROUTE = "/explore-people";
export const EXPLORE_COMPONENTS_ROUTE = "/explore-components";
export const UPLOAD_COMPONENT_ROUTE = "/upload-component";
export const AUTOMATION_PROFILE_ROUTE = "/automation-profile";
export const SHARE_AUTOMATION_ROUTE = "/submit/automation";
export const COE_ROUTE = "/submit/top-down";
export const EXPLORE_LEADERBOARD = "/leaderboard";
export const USER_NOTIFICATIONS = "/notifications";
// admin
export const ADMIN_CONSOLE_ROUTE = "/admin/account";
export const APP_INVENTORY_ROUTE = "/admin/app-inventory";
export const IDEA_ASSESSMENT_ROUTE = "/admin/idea-assessment";
export const COST_SETUP_ROUTE = "/admin/cost-setup";
export const MANAGE_COMPONENTS_ROUTE = "/admin/manage-components";
export const MANAGE_USERS = "/admin/manage-users";
export const RPA_SOFTWARE_VENDOR_ROUTE = "/admin/rpa-software-vendor";
export const ONE_TIME_COSTS_ROUTE = "/admin/one-time-costs";
export const RUNNING_COSTS_ROUTE = "/admin/running-costs";
export const RUNNING_COSTS_ROUTE_EDIT = "/admin/running-costs/edit";
export const OTHER_RUNNING_COSTS_ROUTE = "/admin/other-running-costs";
export const OPENAPI_TOKEN_ROUTE = "/admin/open-api-management";
export const CUSTOMIZE_HIGH_LEVEL_ASSESSMENT =
  "/admin/idea-assessment?assessmentType=Q1";
export const CUSTOMIZE_DETAILED_ASSESSMENT =
  "/admin/idea-assessment?assessmentType=Q2";
export const CUSTOMIZE_ASSESSMENT_LEGAL_FROM_DEFAULT =
  "/admin/idea-assessment/customize?assessmentType=MISC&categoryId=4&creationMethod=Default&isDefault=true";
export const IMPORT_PIPELINE_ROUTE = "/admin/import-pipeline";
export const ADMIN_DOCUMENTATION_ROUTE = "/admin/documentation";
export const ADMIN_CATEGORIES_ROUTE = "/admin/categories";
export const AUDIT_LOGS_ROUTE = "/admin/audit-logs";
export const ADMIN_ROLES_ROUTE = "/admin/user-roles-permissions";
// workspace
export const AUTOMATION_PIPELINE_ROUTE = "/automation-pipeline";
export const MY_COLLABORATIONS_ROUTE = "/my-collaborations";
export const MY_COMPONENTS_ROUTE = "/my-components";
export const MY_FOLLOWING_ROUTE = "/my-following";
export const MY_SUBMISSIONS_ROUTE = "/my-submissions";
// sumbit ideas
export const EMPLOYEE_SUBMIT_IDEA_ROUTE = "/submit/idea";
export const AUTOMATION_PERFORMANCE_DASHBOARD = "/dashboard/performance";
export const AVERAGE_AUTOMATION_DASHBOARD = "/dashboard/cost";
export const AUTOMATION_PLANNING_REPORT =
  "/performance/planning-live-automations";
export const AUTOMATION_COST_REPORT = "/performance/cost-report";
export const CO_SUBMIT_PROCESS_ROUTE = `${CO_SUBMIT_PROCESS_PAGE}/:process_submission_type`;
