import React from "react";
import axios from "../utils/axiosInstance";
import { LocalizeTranslationKey } from "../utils/localizedAlertMessages";
import swal from "../components/SweetAlert/SweetAlert";
import ToastSwal from "../components/ToastAutomationPipeline/ToastAutomationPipeline";
import {
  swalSpinnerLoading,
  swalSpinnerSuccess,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";
import apiErrorAlert from "../utils/apiErrorAlert";

import {
  FETCH_PPP_PROFILE_DATA,
  CLEAR_PPP_PROFILE_DATA,
  RECEIVE_UPVOTE,
  FETCH_ALL_FOLLOWERS,
  FETCH_ALL_PHASE_AND_STATUSES,
  RECEIVE_DOWNVOTE,
  RECEIVE_UNFOLLOW,
  RECEIVE_FOLLOW,
  FETCH_EDIT_DATA,
  FETCH_APPLICATIONS,
  EDIT_PROFILE_Q1,
  FETCH_BASE_EDIT_DATA,
  FETCH_APPLICATION_FOR_L1_TAXONOMY,
  FETCH_PPP_PROFILE_MEDIA,
  FETCH_SIMILAR_AUTOMATIONS,
  FETCH_AUTOMATION_COMPONENTS,
  FETCH_AUTOMATIONS_FOR_SIMILAR_SEARCH,
  FETCH_REUSED_COMPONENTS,
  FETCH_REUSED_COMPONENTS_FOR_SEARCH,
  FETCH_AUTOMATIONS_USING_COMPONENT,
  SET_COMMENT_TO_REPORT,
  GET_REAL_TIME_ASSESSMENT_CALCULATION_TYPES,
  REQUEST_PROCESS,
  FETCH_CHANGE_REQUESTS
} from "../constants/actionTypes";
import { getKeyValue } from "../utils/objectParsers";
import { filterCategoriesForCurrentUser } from "../utils/categoryUtils";

export function fetchEditData(id) {
  return dispatch => {
    const editObj: any = {};
    axios
      .get("api/v1/processes/taxonomy")
      .then(resp => {
        editObj.taxonomyData = resp.data.data;
        dispatch({
          type: FETCH_EDIT_DATA,
          payload: editObj
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

// export function upvoteProcess(id, callback) {
//   return dispatch => {
//     dispatch({
//       type: RECEIVE_UPVOTE
//     });
//     axios
//       .post(`/api/v1/processes/slug/${id}/upvote`)
//       .then(() => {
//         typeof callback === "function" && callback();
//       })
//       .catch(error => {
//         apiErrorAlert(error);
//         dispatch({
//           type: RECEIVE_DOWNVOTE
//         });
//       });
//   };
// }

// export function downvoteProcess(id) {
//   return dispatch => {
//     dispatch({
//       type: RECEIVE_DOWNVOTE
//     });
//     axios.delete(`/api/v1/processes/slug/${id}/downvote`).catch(error => {
//       apiErrorAlert(error);
//       dispatch({
//         type: RECEIVE_UPVOTE
//       });
//     });
//   };
// }

// export function followProcess(id) {
//   return dispatch => {
//     dispatch({
//       type: RECEIVE_FOLLOW
//     });
//     axios.post(`/api/v1/processes/slug/${id}/follow`).catch(error => {
//       apiErrorAlert(error);
//       dispatch({
//         type: RECEIVE_UNFOLLOW
//       });
//     });
//   };
// }

// export function unfollowProcess(id) {
//   return dispatch => {
//     dispatch({
//       type: RECEIVE_UNFOLLOW
//     });
//     axios.delete(`/api/v1/processes/slug/${id}/unfollow`).catch(error => {
//       apiErrorAlert(error);
//       dispatch({
//         type: RECEIVE_FOLLOW
//       });
//     });
//   };
// }

export const requestProcessAsync = async (slug, callback) => {
  swalSpinnerLoading();

  return new Promise((reject, resolve) => {
    axios
      .post(`/api/v1/processes/slug/${slug}/request`)
      .then(resp => {
        swalSpinnerSuccess({
          title: (
            <LocalizeTranslationKey localeKey="automation_profile_request_message" />
          )
        });
        setTimeout(() => {
          swalSpinnerClose();
        }, 2000);
        typeof callback === "function" && callback();
        resolve(true);
      })
      .catch(error => {
        apiErrorAlert(error);
        reject(false);
      });
  });
};

// export function requestProcess(slug, callback) {
//   swalSpinnerLoading();
//   return dispatch => {
//     dispatch({
//       type: REQUEST_PROCESS
//     });
//     axios
//       .post(`/api/v1/processes/slug/${slug}/request`)
//       .then(resp => {
//         swalSpinnerSuccess({
//           title: (
//             <LocalizeTranslationKey localeKey="automation_profile_request_message" />
//           )
//         });
//         setTimeout(() => {
//           swalSpinnerClose();
//         }, 2000);
//         typeof callback === "function" && callback();
//       })
//       .catch(error => {
//         apiErrorAlert(error);
//       });
//   };
// }

export function getAllfollowers(id) {
  return dispatch => {
    axios
      .get(`/api/v1/processes/slug/${id}/followers`)
      .then(resp => {
        const { data } = resp.data;
        dispatch({
          type: FETCH_ALL_FOLLOWERS,
          payload: data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchPPPProfileData(params) {
  const { processId } = params;
  return function(dispatch) {
    axios
      .get(`/api/v1/co/process/view/${processId}`)
      .then(resp => {
        const processProfileInfo = resp?.data?.data;
        dispatch({
          type: FETCH_PPP_PROFILE_DATA,
          payload: processProfileInfo
        });
      })
      .catch(error => {
        swal
          .fire({
            type: "error",
            title: (
              <LocalizeTranslationKey
                localeKey={"error_unable_to_load_automation"}
              />
            )
          } as any)
          .then(() => {
            //TODO  dispatch(push('/explore-automations'))
          });
      });
  };
}

export function clearPPPProfileData() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_PPP_PROFILE_DATA,
      payload: null
    });
  };
}

export function fetchAllPhasesAndStatuses() {
  return function(dispatch) {
    axios
      .get(`/api/v1/phases/`)
      .then(resp => {
        dispatch({
          type: FETCH_ALL_PHASE_AND_STATUSES,
          payload: resp.data.data
        });
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}

export function fetchPPPProfileMedia(params) {
  const { slug } = params;
  return function(dispatch) {
    dispatch({
      type: FETCH_PPP_PROFILE_MEDIA,
      payload: null
    });
    axios
      .get(`/api/v1/processes/slug/${slug}/media?page=1&limit=1000000`)
      .then(resp => {
        dispatch({
          type: FETCH_PPP_PROFILE_MEDIA,
          payload: resp.data.data.media
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchApplications(params) {
  const { slug } = params;
  return dispatch => {
    axios
      .get(`/api/v1/processes/slug/${slug}/applications`)
      .then(resp => {
        dispatch({
          type: FETCH_APPLICATIONS,
          payload: resp.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

// //this section will be for actions related to editing automation profile
// //might decide to move these actions to it's own actions file
// export function fetchBaseEditData(params) {
//   const { slug } = params;
//   //gets all base edit data in one swopp
//   return dispatch => {
//     axios
//       .get(`api/v1/processes/slug/${slug}/edit-info`)
//       .then(resp => {
//         return dispatch({
//           type: FETCH_BASE_EDIT_DATA,
//           payload: {
//             processProfileInfo: resp.data.data.process,
//             editInfo: {
//               categories: filterCategoriesForCurrentUser(
//                 resp.data.data.categories
//               ),
//               levels: resp.data.data.levels,
//               developmentTypes: resp.data.data.development_types
//             }
//           }
//         });
//       })
//       .catch(error => apiErrorAlert(error));
//   };
// }

//OLD
// export function fetchCreateTopDownAutomationObject(params, t) {
//   //let's do draft here
//   //gets all base edit data in one swopp
//   //necessary because different endpoint have important data
//   return dispatch => {
//     let promises = [
//       axios.get(`/api/v1/processes/top-down`),
//       axios.get(`/api/v1/categories`)
//     ];
//     if (params.draft_id && params.draft_id > 0) {
//       promises.push(axios.get(`/api/v1/drafts/${params.draft_id}`));
//     }
//     Promise.all(promises)
//       .then(resp => {
//         const [processProfileData, categoriesData, draftResponseData] = resp;
//         let processProfileInfo = processProfileData.data.data;

//         if (draftResponseData) {
//           //here we want to merge the draft data
//           let draftData = draftResponseData.data.data;
//           if (draftData) {
//             let draftProcessData;
//             try {
//               draftProcessData = JSON.parse(draftData.draft_json || "");
//             } catch (e) {
//               console.log(`error parsing draft json ${draftData.draft_json}`);
//             }
//             if (draftProcessData) {
//               processProfileInfo = updateAssessmentDraftsToLatestVersion(
//                 draftProcessData,
//                 processProfileInfo,
//                 t
//               );
//               processProfileInfo.draft_id = draftData.draft_id;
//             }
//           }
//         }

//         return dispatch({
//           type: FETCH_BASE_EDIT_DATA,
//           payload: {
//             processProfileInfo: processProfileInfo,
//             editInfo: {
//               categories: filterCategoriesForCurrentUser(
//                 categoriesData.data.data.categories
//               ),
//               levels: categoriesData.data.data.levels
//             }
//           }
//         });
//       })
//       .catch(error => apiErrorAlert(error));
//   };
// }

// export function createProcessFromTopDown(params, cb, duplicateProcessCb) {
//   const { data } = params;
//   return dispatch => {
//     swalSpinnerLoading({});
//     axios
//       .post(`/api/v1/processes/top-down`, data)
//       .then(resp => {
//         swalSpinnerClose({});
//         if (cb) {
//           cb(resp.data.data);
//         }
//       })
//       .catch(error => {
//         if (
//           typeof duplicateProcessCb === "function" &&
//           error.response &&
//           error.response.data &&
//           error.response.data.data &&
//           error.response.data.data.localizationKey ===
//             "error_process_already_submitted"
//         ) {
//           duplicateProcessCb();
//           return;
//         }
//         swalSpinnerClose({});
//         apiErrorAlert(error);
//       });
//   };
// }

// export function editProccessProfile(params, callback) {
//   const { slug, data, looseValidation = true } = params;
//   let queryParam = "";
//   if (looseValidation) {
//     queryParam = `?looseValidation=${looseValidation}`;
//   }

//   return dispatch => {
//     swalSpinnerLoading({});
//     axios
//       .put(`/api/v1/processes/slug/${slug}${queryParam}`, data)
//       .then(resp => {
//         swalSpinnerClose({});
//         const responseData = getKeyValue(resp, "data.data") || {};
//         dispatch({
//           type: EDIT_PROFILE_Q1,
//           payload: responseData
//         });
//         if (typeof callback === "function") {
//           callback(responseData);
//         }
//       })
//       .catch(error => {
//         swalSpinnerClose({});
//         apiErrorAlert(error);
//       });
//   };
// }

export function updateProcessPhaseAndStatus(params) {
  const { slug, process_phase_status_id, callbackFunction } = params;

  swalSpinnerLoading({});

  return dispatch => {
    axios["patch"](
      `/api/v1/co/process/${slug}/status/${process_phase_status_id}`
    )
      .then(resp => {
        swalSpinnerSuccess();

        setTimeout(() => {
          swalSpinnerClose();
          if (callbackFunction) {
            callbackFunction(resp.data.data);
          }
        }, 1000);
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  };
}

export function fetchAllApplications() {
  let path = `/api/v1/applications`;
  return dispatch => {
    dispatch({
      type: FETCH_APPLICATION_FOR_L1_TAXONOMY,
      payload: null
    });
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_APPLICATION_FOR_L1_TAXONOMY,
          payload: resp.data.data.applications
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchSimilarAutomations({ slug }) {
  return dispatch => {
    dispatch({
      type: FETCH_SIMILAR_AUTOMATIONS,
      payload: null
    });
    axios
      .get(`/api/v1/processes/slug/${slug}/similar?page=1&limit=10000000`)
      .then(resp => {
        dispatch({
          type: FETCH_SIMILAR_AUTOMATIONS,
          payload: resp.data.data.processes
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export async function fetchChangeRequestsAutomations({
  slug
}: {
  slug: string;
}): Promise<object[] | undefined> {
  try {
    const response = await axios.get(
      `/api/v1/processes/slug/${slug}/changeRequests?page=1&limit=10000`
    );
    return response.data.data.processes;
  } catch (error) {
    apiErrorAlert(error);
    return undefined;
  }
}

export function fetchChangeRequestsAutomationsWithDispatch({ slug }) {
  return dispatch => {
    dispatch({
      type: FETCH_CHANGE_REQUESTS,
      payload: null
    });
    const processes = fetchChangeRequestsAutomations({ slug });
    if (processes !== undefined) {
      dispatch({
        type: FETCH_CHANGE_REQUESTS,
        payload: processes
      });
    }
  };
}

export function fetchAutomationComponents({ slug }) {
  return dispatch => {
    dispatch({
      type: FETCH_AUTOMATION_COMPONENTS,
      payload: null
    });
    axios
      .get(`/api/v1/components?page=1&limit=1000`)
      .then(resp => {
        dispatch({
          type: FETCH_AUTOMATION_COMPONENTS,
          payload: resp.data.data.components
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchAutomationsUsingComponent(params) {
  const { slug } = params;

  return dispatch => {
    dispatch({
      type: FETCH_AUTOMATIONS_USING_COMPONENT,
      payload: null
    });
    axios
      .get(`/api/v1/components/slug/${slug}/processes`)
      .then(resp => {
        dispatch({
          type: FETCH_AUTOMATIONS_USING_COMPONENT,
          payload: resp.data.data.processes
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchAutomationsForSimilarSearch(
  search_text,
  results_callback
) {
  return dispatch => {
    axios
      .get("/api/v1/processes?s=" + search_text + "&page=1&limit=30")
      .then(resp => {
        if (results_callback) {
          results_callback(resp.data.data.processes);
        }
        dispatch({
          type: FETCH_AUTOMATIONS_FOR_SIMILAR_SEARCH,
          payload: resp.data.data.processes
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchReusedComponents({ slug }) {
  return dispatch => {
    axios
      .get(`api/v1/processes/slug/${slug}/components`)
      .then(resp => {
        dispatch({
          type: FETCH_REUSED_COMPONENTS,
          payload: {
            components: resp.data.data.components,
            count: resp.data.data.totalItems
          }
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchReusedComponentsForSearch(search_text, results_callback) {
  return dispatch => {
    axios
      .get(`/api/v1/components?s=${search_text}&limit=10`)
      .then(resp => {
        if (results_callback) {
          results_callback(resp.data.data.components);
        }
        dispatch({
          type: FETCH_REUSED_COMPONENTS_FOR_SEARCH,
          payload: resp.data.data.components
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function addReusedComponents(
  slug,
  components,
  callback: Function = () => {}
) {
  let componentIds: any = [];
  components.forEach(component => {
    componentIds.push(component.component_id);
  });
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_adding_component_title"} />
    )
  });
  return dispatch => {
    axios
      .post(`/api/v1/processes/slug/${slug}/components`, {
        components: componentIds
      })
      .then(resp => {
        swalSpinnerClose();
        dispatch(fetchReusedComponents({ slug }));
        if (callback) {
          callback();
        }
      })
      .catch(error => {
        swalSpinnerClose();
        apiErrorAlert(error);
      });
  };
}

export function removeReusedComponent(
  slug,
  component_id,
  callback: Function = () => {}
) {
  return dispatch => {
    axios
      .delete(`/api/v1/processes/slug/${slug}/components/${component_id}`)
      .then(resp => {
        dispatch(fetchReusedComponents({ slug }));
        if (callback) {
          callback();
        }
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setCommentIdToReport(comment_id) {
  return function(dispatch) {
    dispatch({
      type: SET_COMMENT_TO_REPORT,
      payload: comment_id
    });
  };
}

export function reportAbuseForAutomationProfile(slug, data) {
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_saving_report_title"} />
    )
  });
  return dispatch => {
    axios
      .post(`/api/v1/processes/slug/${slug}/report`, data)
      .then(resp => {
        swalSpinnerClose();
        ToastSwal.fire({
          title: (
            <LocalizeTranslationKey
              localeKey={"info_alert_saving_report_content"}
            />
          )
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function reportAbuseForAutomationComment(
  automationSlug,
  commentID,
  data
) {
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_saving_report_title"} />
    )
  });
  return dispatch => {
    axios
      .post(
        `/api/v1/processes/slug/${automationSlug}/comments/${commentID}/report`,
        data
      )
      .then(resp => {
        swalSpinnerClose();
        ToastSwal.fire({
          title: (
            <LocalizeTranslationKey
              localeKey={"info_alert_comment_abuse_reported_content"}
            />
          )
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function getRealTimeAssessmentCalculationTypes() {
  // #REMOVED FOR CDA Q2
  // return dispatch => {
  //   axios
  //     .get(`/api/v1/processes/calculation`)
  //     .then(resp => {
  //       dispatch({
  //         type: GET_REAL_TIME_ASSESSMENT_CALCULATION_TYPES,
  //         payload: resp.data.data
  //       });
  //     })
  //     .catch(error => apiErrorAlert(error));
  // };
}
