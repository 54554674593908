import {
  COActionContextValuesInterface,
  COActionInterface,
  COActionTemplateInterface
} from "../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  COActionReplacementContextualValues
} from "../../constants/actions/co-action-constants";
import { actionStringVariables } from "./co-actions-replacement-values.helper";
import { COBaseAction } from "../../classes/actions/co-base-action.class";
import { COLoadableProcessFields } from "../../constants/co-constants";
import { COCollaboratorRoleType } from "../../constants/actions/co-roles.constants";

export const findMetaContextualValuesInAction = ({
  action
}: {
  action: COActionInterface;
}): Set<COActionReplacementContextualValues> => {
  // find all variables in all the meta items of the template
  let allVariables: string[] = [];
  const actionTemplateMeta = action.template?.co_meta_json;
  if (actionTemplateMeta) {
    for (const metaKey in actionTemplateMeta) {
      const metaItem = actionTemplateMeta[metaKey];
      if (metaItem && metaItem.value) {
        const variables = actionStringVariables({
          value: metaItem.value
        });
        allVariables.push(...variables);
      }
    }
  }

  return findMetaContextualValuesForVariables({ variables: allVariables });
};

export const findMetaContextualValuesInString = ({
  value
}: {
  value: string;
}): Set<COActionReplacementContextualValues> => {
  const allVariables: string[] = actionStringVariables({
    value
  });

  return findMetaContextualValuesForVariables({ variables: allVariables });
};

const findMetaContextualValuesForVariables = ({
  variables
}: {
  variables: string[];
}): Set<COActionReplacementContextualValues> => {
  const contextualValues = new Set<COActionReplacementContextualValues>();

  // determine which contextual values we need based on that
  variables.forEach(variable => {
    switch (variable) {
      case COActionReplacementStrings.PROCESS_SLUG:
      case COActionReplacementStrings.PROCESS_NAME:
      case COActionReplacementStrings.PROCESS_DESCR:
      case COActionReplacementStrings.VIEW_MORE_PROCESS_DESCR:
      case COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME: {
        contextualValues.add(COActionReplacementContextualValues.PROCESS_ID);
        break;
      }
      case COActionReplacementStrings.PROCESS_ARCHIVED_REASON: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_ARCHIVED_REASON
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_REJECTED_REASON: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_REJECTED_REASON
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_PROFILE_URL:
      case COActionReplacementStrings.PROCESS_DOCUMENTATION_URL:
      case COActionReplacementStrings.PROCESS_CBA_URL:
      case COActionReplacementStrings.PROCESS_COMPONENT_URL:
      case COActionReplacementStrings.PROCESS_EDIT_URL:
      case COActionReplacementStrings.AUTOMATION_STORE_PROCESS_URL: {
        contextualValues.add(COActionReplacementContextualValues.PROCESS_ID);
        contextualValues.add(COActionReplacementContextualValues.TENANT_ID);
        break;
      }
      case COActionReplacementStrings.PROCESS_PHASE: {
        contextualValues.add(COActionReplacementContextualValues.PROCESS_PHASE);
        break;
      }
      case COActionReplacementStrings.PROCESS_STATUS: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_STATUS
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_QUESTION: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_QUESTION_ID
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_SUBMITTER_NAME:
      case COActionReplacementStrings.PROCESS_SUBMITTER_SLUG: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_SUBMITTER_ID
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_OWNER_NAME:
      case COActionReplacementStrings.PROCESS_OWNER_SLUG: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_OWNER_ID
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_ABUSE_TITLE:
      case COActionReplacementStrings.PROCESS_ABUSE_DESCR: {
        contextualValues.add(
          COActionReplacementContextualValues.REPORTED_PROCESS_ID
        );
        break;
      }
      case COActionReplacementStrings.PROCESS_TEMPLATE_TITLE: {
        contextualValues.add(COActionReplacementContextualValues.TEMPLATE_ID);
        break;
      }
      case COActionReplacementStrings.USER_FULL_NAME:
      case COActionReplacementStrings.USER_SLUG: {
        contextualValues.add(
          COActionReplacementContextualValues.REFERENCED_USER_ID
        );
        break;
      }
      case COActionReplacementStrings.PERMISSION_ROLES: {
        contextualValues.add(
          COActionReplacementContextualValues.PERMISSION_ROLES
        );
        break;
      }
      case COActionReplacementStrings.USER_PROFILE_URL:
      case COActionReplacementStrings.UNSUBSCRIBE_LINK: {
        contextualValues.add(
          COActionReplacementContextualValues.REFERENCED_USER_ID
        );
        contextualValues.add(COActionReplacementContextualValues.TENANT_ID);
        break;
      }
      case COActionReplacementStrings.COLLABORATOR_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.COLLABORATOR_ID
        );
        break;
      }
      case COActionReplacementStrings.DIRECT_MANAGER_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.DIRECT_MANAGER_ID
        );
        break;
      }
      case COActionReplacementStrings.REPORTEE_NAME: {
        contextualValues.add(COActionReplacementContextualValues.REPORTEE_ID);
        break;
      }
      case COActionReplacementStrings.SYSTEM_ADMIN_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.SYSTEM_ADMIN_ID
        );
        break;
      }
      case COActionReplacementStrings.PROGRAM_MANAGER_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.PROGRAM_MANAGER_ID
        );
        break;
      }
      case COActionReplacementStrings.CURATOR_NAME: {
        contextualValues.add(COActionReplacementContextualValues.CURATOR_ID);
        break;
      }
      case COActionReplacementStrings.AUTHORIZED_USER_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.AUTHORIZED_USER_ID
        );
        break;
      }
      case COActionReplacementStrings.COE_COLLABORATOR_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.COE_COLLABORATOR_ID
        );
        break;
      }
      case COActionReplacementStrings.DOCUMENT_TITLE: {
        contextualValues.add(COActionReplacementContextualValues.DOCUMENT_ID);
        break;
      }
      case COActionReplacementStrings.COMMENT_TITLE:
      case COActionReplacementStrings.PROCESS_COMMENT: {
        contextualValues.add(COActionReplacementContextualValues.COMMENT_ID);
        break;
      }
      case COActionReplacementStrings.PROCESS_COMMENT_URL: {
        contextualValues.add(COActionReplacementContextualValues.COMMENT_ID);
        contextualValues.add(COActionReplacementContextualValues.TENANT_ID);
        break;
      }
      case COActionReplacementStrings.COMPONENT_NAME:
      case COActionReplacementStrings.COMPONENT_SLUG: {
        contextualValues.add(COActionReplacementContextualValues.COMPONENT_ID);
        break;
      }
      case COActionReplacementStrings.COMPONENT_REJECTED_REASON: {
        contextualValues.add(
          COActionReplacementContextualValues.COMPONENT_REJECTED_REASON
        );
        break;
      }
      case COActionReplacementStrings.COMPONENT_PROFILE_URL: {
        contextualValues.add(COActionReplacementContextualValues.COMPONENT_ID);
        contextualValues.add(COActionReplacementContextualValues.TENANT_ID);
        break;
      }
      case COActionReplacementStrings.COMPONENT_COMMENT: {
        contextualValues.add(
          COActionReplacementContextualValues.COMPONENT_COMMENT_ID
        );
        break;
      }
      case COActionReplacementStrings.TENANT_NAME:
      case COActionReplacementStrings.TENANT_STATE:
      case COActionReplacementStrings.TENANT_COMPANY_NAME:
      case COActionReplacementStrings.LICENSE_PLAN:
      case COActionReplacementStrings.LICENSE_PLAN_OPTION_NUMBER:
      case COActionReplacementStrings.TENANT_MIGRATION_OPTION:
      case COActionReplacementStrings.SALES_MESSAGE:
      case COActionReplacementStrings.ERROR_MESSAGE:
      case COActionReplacementStrings.TENANT_LOGIN_URL:
      case COActionReplacementStrings.EXPLORE_IDEAS_URL:
      case COActionReplacementStrings.SUBMIT_IDEA_URL:
      case COActionReplacementStrings.USER_GUIDE_FIRST_USER_URL:
      case COActionReplacementStrings.USER_GUIDE_STANDARD_USER_URL:
      case COActionReplacementStrings.STATUS_PAGE_URL:
      case COActionReplacementStrings.EMAIL_CONFIRMATION_LINK: {
        contextualValues.add(COActionReplacementContextualValues.TENANT_ID);
        break;
      }
      case COActionReplacementStrings.PROCESS_ROLE_NAME: {
        contextualValues.add(
          COActionReplacementContextualValues.PROCESS_ROLE_NAME
        );
        break;
      }
      case COActionReplacementStrings.UPDATED_USER_ATTRIBUTES: {
        contextualValues.add(
          COActionReplacementContextualValues.UPDATED_USER_ATTRIBUTES
        );
        break;
      }
      case COActionReplacementStrings.COLLABORATOR_ROLES: {
        contextualValues.add(
          COActionReplacementContextualValues.COLLABORATOR_ROLES
        );
        break;
      }
      case COActionReplacementStrings.AUTOMATION_STORE_PROCESS_NAME_HERE:
      case COActionReplacementStrings.BEGINNING_OF_EMAIL:
      case COActionReplacementStrings.END_OF_EMAIL: {
        break;
      }
    }
  });

  return contextualValues;
};

export const actionContextValuesForTemplate = ({
  template,
  additionalStrings,
  processId,
  processPhase,
  processStatus,
  processRejectedReason,
  processArchivedReason,
  processSubmitterId,
  processOwnerId,
  refUserId,
  targetUserId,
  subjectUserId,
  documentId,
  componentId,
  componentRejectedReason,
  templateId,
  commentId,
  questionId,
  reportedProcessId,
  tenantId,
  userAttributesString,
  processRoleName,
  collaboratorRolesString,
  permissionRolesString
}: {
  template: COActionTemplateInterface;
  additionalStrings?: string[];
  processId?: number;
  processPhase?: string;
  processStatus?: string;
  processRejectedReason?: string;
  processArchivedReason?: string;
  processSubmitterId?: number;
  processOwnerId?: number;
  refUserId?: number;
  targetUserId?: number;
  subjectUserId?: number;
  documentId?: number;
  componentId?: number;
  componentRejectedReason?: string;
  templateId?: number;
  commentId?: number;
  questionId?: number;
  reportedProcessId?: number;
  tenantId?: string;
  userAttributesString?: string;
  processRoleName?: string;
  collaboratorRolesString?: string;
  permissionRolesString?: string;
}): COActionContextValuesInterface => {
  const contextValues: COActionContextValuesInterface = {
    collaborator_ids: {},
    user_id: targetUserId,
    reference_user_id: refUserId // always include this so the user doesn't get this COAction
  };
  const action = new COBaseAction({
    propertiesFromJSON: template
  });
  let contextualValues = findMetaContextualValuesInAction({
    action
  });

  if (additionalStrings) {
    for (const value of additionalStrings) {
      const additionalContextualValues = findMetaContextualValuesInString({
        value
      });
      contextualValues = new Set<COActionReplacementContextualValues>([
        ...contextualValues,
        ...additionalContextualValues
      ]);
    }
  }

  // variables
  if (contextualValues.has(COActionReplacementContextualValues.PROCESS_ID)) {
    contextValues.process_id = processId;
  }
  if (contextualValues.has(COActionReplacementContextualValues.PROCESS_PHASE)) {
    contextValues.process_phase = processPhase;
  }
  if (
    contextualValues.has(COActionReplacementContextualValues.PROCESS_STATUS)
  ) {
    contextValues.process_status = processStatus;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.FETCH_PROCESS_PHASE_STATUS
    )
  ) {
    contextValues.process_specific_fields = [
      COLoadableProcessFields.phase_status
    ];
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.PROCESS_REJECTED_REASON
    )
  ) {
    contextValues.process_rejected_reason = processRejectedReason;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.PROCESS_ARCHIVED_REASON
    )
  ) {
    contextValues.process_archived_reason = processArchivedReason;
  }
  if (
    contextualValues.has(COActionReplacementContextualValues.REFERENCED_USER_ID)
  ) {
    contextValues.reference_user_id = refUserId;
  }
  if (contextualValues.has(COActionReplacementContextualValues.DOCUMENT_ID)) {
    contextValues.document_id = documentId;
  }
  if (contextualValues.has(COActionReplacementContextualValues.COMPONENT_ID)) {
    contextValues.component_id = componentId;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.COMPONENT_REJECTED_REASON
    )
  ) {
    contextValues.component_rejected_reason = componentRejectedReason;
  }
  if (contextualValues.has(COActionReplacementContextualValues.TEMPLATE_ID)) {
    contextValues.template_id = templateId;
  }
  if (contextualValues.has(COActionReplacementContextualValues.COMMENT_ID)) {
    contextValues.comment_id = commentId;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.COMPONENT_COMMENT_ID
    )
  ) {
    contextValues.component_comment_id = commentId;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.PROCESS_QUESTION_ID
    )
  ) {
    contextValues.process_question_id = questionId;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.UPDATED_USER_ATTRIBUTES
    )
  ) {
    contextValues.user_attributes = userAttributesString;
  }
  if (
    contextualValues.has(COActionReplacementContextualValues.PROCESS_ROLE_NAME)
  ) {
    contextValues.process_role_name = processRoleName;
  }
  if (
    contextualValues.has(COActionReplacementContextualValues.COLLABORATOR_ROLES)
  ) {
    contextValues.collaborator_roles = collaboratorRolesString;
  }
  if (
    contextualValues.has(
      COActionReplacementContextualValues.REPORTED_PROCESS_ID
    )
  ) {
    contextValues.reported_process_id = reportedProcessId;
  }
  if (contextualValues.has(COActionReplacementContextualValues.TENANT_ID)) {
    contextValues.tenant_id = tenantId;
  }
  if (
    contextualValues.has(COActionReplacementContextualValues.PERMISSION_ROLES)
  ) {
    contextValues.permission_roles = permissionRolesString;
  }

  // Collaborators
  if (contextValues.collaborator_ids) {
    if (
      contextualValues.has(
        COActionReplacementContextualValues.PROCESS_SUBMITTER_ID
      ) &&
      processSubmitterId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.PROCESS_SUBMITTER
      ] = processSubmitterId;
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.PROCESS_OWNER_ID
      ) &&
      processOwnerId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.PROCESS_OWNER
      ] = processOwnerId;
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.PROGRAM_MANAGER_ID
      )
    ) {
      if (targetUserId) {
        contextValues.collaborator_ids[
          COCollaboratorRoleType.PROGRAM_MANAGER
        ] = targetUserId;
      } else if (refUserId) {
        contextValues.collaborator_ids[
          COCollaboratorRoleType.PROGRAM_MANAGER
        ] = refUserId;
      }
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.SYSTEM_ADMIN_ID
      ) &&
      targetUserId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.SYSTEM_ADMIN
      ] = targetUserId;
    }
    if (
      contextualValues.has(COActionReplacementContextualValues.CURATOR_ID) &&
      targetUserId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.CURATOR
      ] = targetUserId;
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.AUTHORIZED_USER_ID
      ) &&
      targetUserId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.AUTHORIZED_USER
      ] = targetUserId;
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.COE_COLLABORATOR_ID
      ) &&
      targetUserId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.COE_COLLABORATOR
      ] = targetUserId;
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.COLLABORATOR_ID
      ) &&
      targetUserId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.COLLABORATOR
      ] = targetUserId;
    }
    if (
      contextualValues.has(
        COActionReplacementContextualValues.DIRECT_MANAGER_ID
      )
    ) {
      if (targetUserId) {
        contextValues.collaborator_ids[
          COCollaboratorRoleType.DIRECT_MANAGER
        ] = targetUserId;
      } else if (!subjectUserId && refUserId) {
        contextValues.collaborator_ids[
          COCollaboratorRoleType.DIRECT_MANAGER
        ] = refUserId;
      }
    }
    if (
      contextualValues.has(COActionReplacementContextualValues.REPORTEE_ID) &&
      subjectUserId
    ) {
      contextValues.collaborator_ids[
        COCollaboratorRoleType.REPORTEE
      ] = subjectUserId;
    }
  }

  return contextValues;
};
