import { COContext } from "../../../classes/co-context.class";
import { COValidationType } from "../../../constants/co-constants";
import { PATH, PATH_ROUTE } from "../../../constants/co-path.constants";
import {
  COValidationContext,
  COValidationError,
  COValidationItemInterface
} from "../../../interfaces/co-interfaces";
import { getValue } from "../../../utils/co-path.utils";
import { isNullOrUndefined, toSlug } from "../../../utils/co-utils";

export const selectableAnswerOptionValueValidator = (): COValidationItemInterface => {
  return {
    target: PATH({
      route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.TITLE.VALUE
    }),
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "Non KPI impacting question answer options must have slugs"
    },
    validation_context: {
      assessment_customize_transform_blur: 1,
      assessment_customize: 1
    },
    value: 1,
    is_disabled: PATH({
      route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI
    }),
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContext;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      if (!isNullOrUndefined(target) && context?.answer_option) {
        let question_option_title = getValue(
          context,
          context.answer_option?.meta?.title?.value || ""
        );
        let slugValue = toSlug(question_option_title);
        if (slugValue === "") {
          slugValue = toSlug(
            context.answer_option?.co_question_answer_option_ahid ?? ""
          );
          if (slugValue === "") {
            console.log(
              "Error generating co_question_answer_option_value for " +
                (context.answer_option?.co_question_answer_option_ahid ?? "")
            );
          }
        }
        context.answer_option.co_question_answer_option_value = slugValue;
      }
      return [];
    }
  };
};
