import React from "react";
import styled, { css } from "styled-components/macro";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";

const CUSTOM_CLASS_NAME_PREFIX = "react-select-apollo";

const selectStyle = ({ classNamePrefix }: { classNamePrefix: string }) => {
  return css`
    .${classNamePrefix}__menu {
      background: var(--color-background-raised);
    }

    .${classNamePrefix}__placeholder {
      color: var(--color-foreground-disable, #8a97a0);
    }

    .${classNamePrefix}__control {
      font-size: 14px;
      background: var(--color-background);
      border-color: var(--color-border);
      min-height: 40px;

      &:hover {
        border-color: var(--color-foreground-de-emp);
      }

      &--is-disabled {
        border-color: var(--color-border-disabled);
        background: var(--color-background-disabled);
      }
    }

    .${classNamePrefix}__single-value {
      font-size: 14px;
      color: var(--color-foreground);
    }

    .${classNamePrefix}__input {
      font-size: 14px;
      color: var(--color-foreground);
    }

    .${classNamePrefix}__menu-notice {
      color: var(--color-foreground);
    }

    .${classNamePrefix}__dropdown-indicator {
      color: var(--color-icon-default);
      cursor: pointer;
      &:hover {
        color: var(--color-icon-hover);
      }
    }

    .${classNamePrefix}__indicator-separator {
      display: none;
    }

    .${classNamePrefix}__multi-value {
      background: var(--color-background-canvas);
      border-radius: 16px;
      padding: 1px 6px;
    }

    .${classNamePrefix}__multi-value__label {
      color: var(--color-foreground);
    }

    .${classNamePrefix}__option {
      color: var(--color-foreground-de-emp);

      &:hover {
        background: var(--color-hover);
      }

      &--is-selected {
        background: var(--color-background-highlight);
      }

      &--is-focused:not(&--is-selected) {
        background: var(--color-primary-lighter);
      }
    }
  `;
};

const SelectWithApollo = styled(
  Select
)(({ classNamePrefix }: { classNamePrefix: string }) =>
  selectStyle({ classNamePrefix })
);

const AsyncSelectWithApollo = styled(
  AsyncSelect
)(({ classNamePrefix }: { classNamePrefix: string }) =>
  selectStyle({ classNamePrefix })
);

const CreatableSelectWithApollo = styled(
  CreatableSelect
)(({ classNamePrefix }: { classNamePrefix: string }) =>
  selectStyle({ classNamePrefix })
);

const AsyncCreatableSelectWithApollo = styled(
  AsyncCreatableSelect
)(({ classNamePrefix }: { classNamePrefix: string }) =>
  selectStyle({ classNamePrefix })
);

const ReactSelectApollo = ({
  classNamePrefix = CUSTOM_CLASS_NAME_PREFIX,
  isAsync = false,
  isCreatable = false,
  isAsyncCreatable = false,
  ...selectProps
}: {
  classNamePrefix?: string;
  isAsync?: boolean;
  isCreatable?: boolean;
  isAsyncCreatable?: boolean;
  [prop: string]: any;
}) => {
  let MatchedSelectComponent = SelectWithApollo;
  if (isAsync) {
    MatchedSelectComponent = AsyncSelectWithApollo;
  } else if (isCreatable) {
    MatchedSelectComponent = CreatableSelectWithApollo;
  } else if (isAsyncCreatable) {
    MatchedSelectComponent = AsyncCreatableSelectWithApollo;
  }

  return (
    <MatchedSelectComponent
      classNamePrefix={`${classNamePrefix}`}
      {...selectProps}
    />
  );
};

export default ReactSelectApollo;
