import SessionHelper from "../helpers/SessionHelper";
import cloneDeep from "lodash/cloneDeep";
import { NON_WHITESPACE_CHAR_REGEX } from "../constants/regexConstants";
import { ApplicationInterface } from "../types/applicationTypes";
import { CategoryInterface } from "../types/categoriesTypes";
import { TFunction } from "i18next";

export const getLevelCategories = (categories, level = 1) => {
  if (!Array.isArray(categories) || categories.length < 1) {
    return [];
  }

  let currLevel = 1;

  let currentCategories = [...categories];

  while (currLevel < level) {
    currentCategories = currentCategories.reduce((accum, { subcategories }) => {
      return Array.isArray(subcategories) ? accum.concat(subcategories) : accum;
    }, []);
    currLevel++;
  }

  return currentCategories;
};

export const mapCategoryIdToCategory = categories => {
  return (categories || []).reduce((accum, categoryObj) => {
    accum[categoryObj.category_id] = categoryObj;

    return accum;
  }, {});
};

export const getAssociatedCategoryObjWithLevelArr = ({
  levels,
  categories,
  selectedCategories
}) => {
  const categoryLevelArr: any = [];
  const categoryIdsToCheckObj = (selectedCategories || []).reduce(
    (accum, id) => {
      accum[id] = true;
      return accum;
    },
    {}
  );

  let currLevelCategories = categories;

  for (let levelIdx = 0; levelIdx < levels.length; levelIdx++) {
    const level = levels[levelIdx];
    let nextLevelCategories = [];

    if (Object.keys(categoryIdsToCheckObj).length < 1) {
      break;
    }

    for (
      let categoryIdx = 0;
      categoryIdx < currLevelCategories.length;
      categoryIdx++
    ) {
      const category = currLevelCategories[categoryIdx];
      if (categoryIdsToCheckObj[category.category_id]) {
        if (Array.isArray(category.subcategories)) {
          nextLevelCategories = nextLevelCategories.concat(
            category.subcategories
          );
          delete categoryIdsToCheckObj[category.category_id];
          categoryLevelArr.push([category, level]);
        }
      }
    }

    currLevelCategories = nextLevelCategories;
  }

  return categoryLevelArr;
};

const mapCategories = (categories, mapObj, parentId) => {
  (categories || []).forEach(category => {
    mapObj[category.category_id] = parentId;
    if (Array.isArray(category.subcategories)) {
      mapCategories(category.subcategories, mapObj, category.category_id);
    }
  });
};

const generateChildCategoryToParentCategoryIdMap = (
  allCategories,
  parentId = null
): any => {
  if (
    !allCategories ||
    !Array.isArray(allCategories) ||
    allCategories.length < 1
  ) {
    return {};
  }

  let mapping = {};
  mapCategories(allCategories, mapping, null);

  return mapping;
};

export const removeChildOptionsFromState = (
  allCategories,
  uncheckedIds,
  currSelectedCategories
) => {
  const parentIdsToRemove = Array.isArray(uncheckedIds)
    ? [...uncheckedIds]
    : [uncheckedIds];
  let updatedSelectedCategories = { ...currSelectedCategories };

  const childCategoryToParentCategoryIdMap = generateChildCategoryToParentCategoryIdMap(
    allCategories
  );

  while (parentIdsToRemove.length > 0) {
    const currentParentToRemove = parentIdsToRemove.pop();
    const IdsToCheck = Object.keys(updatedSelectedCategories);

    for (let idx = 0; idx < IdsToCheck.length; idx++) {
      const potentialChildId = IdsToCheck[idx];

      if (
        childCategoryToParentCategoryIdMap[potentialChildId] ===
        currentParentToRemove
      ) {
        parentIdsToRemove.push(parseInt(potentialChildId));
        delete updatedSelectedCategories[potentialChildId];
      }
    }
  }

  return updatedSelectedCategories;
};

export const removeChildCategoriesFromQueryParams = (
  parsedQueryParams,
  removedCategoryId,
  categoriesFilterData
) => {
  const { categories } = categoriesFilterData || {};

  const potentialChildren = parsedQueryParams.category
    ? parsedQueryParams.category.split(",")
    : [];

  if (potentialChildren.length < 1) {
    return;
  }

  const potentialChildrenObj = potentialChildren.reduce((accum, id) => {
    accum[id] = id;
    return accum;
  }, {});

  const updatedCategoriesParamsObj = removeChildOptionsFromState(
    categories || [],
    removedCategoryId,
    potentialChildrenObj
  );

  if (Object.keys(updatedCategoriesParamsObj).length < 1) {
    delete parsedQueryParams.category;
  } else {
    parsedQueryParams.category = Object.keys(updatedCategoriesParamsObj);
  }
};

export const sortCategoryOptions = (categoryOptions: any[] | null): any[] => {
  const updatedCategoryOptions: any[] = [...(categoryOptions || [])];
  return updatedCategoryOptions.sort((categoryOption1, categoryOption2) => {
    //if it's an "other category" then shoot to bottom
    if (categoryOption2 && categoryOption2.category_is_other === 1) {
      return -1;
    }

    if (categoryOption1.parentOption && categoryOption2.parentOption) {
      const {
        category_order_number: parentOrder1
      } = categoryOption1.parentOption;
      const {
        category_order_number: parentOrder2
      } = categoryOption2.parentOption;

      if (
        typeof parentOrder1 === "number" &&
        typeof parentOrder2 === "number" &&
        parentOrder2 !== parentOrder1
      ) {
        return parentOrder1 - parentOrder2;
      } else if (
        typeof parentOrder1 === "number" ||
        typeof parentOrder2 === "number"
      ) {
        return typeof parentOrder1 === "number" ? 1 : -1;
      }
    }

    const { category_order_number: order1 } = categoryOption1;
    const { category_order_number: order2 } = categoryOption2;

    if (typeof order1 === "number" && typeof order2 === "number") {
      return order1 - order2;
    } else if (typeof order1 === "number" || typeof order2 === "number") {
      return typeof order1 === "number" ? 1 : -1;
    }

    return 0;
  });
};

export const createApplicationObj = (application?: ApplicationInterface) => {
  const application_id = application?.application_id || "";
  const application_name = application?.application_name || "";
  const application_version = application?.application_version || "";
  const application_language = application?.application_language || "";

  const labelArray = [
    application_name,
    application_version,
    application_language
  ];

  return {
    label: labelArray.join(" ").trim(),
    value: application_id
  };
};

export const filterCategoriesForCurrentUser = categories => {
  return filterCategoriesWithinCategoryIds(
    categories,
    SessionHelper.currentUser()?.categories || []
  );
};
//this removes categories that don't fit in the tree of the user_categories
export const filterCategoriesWithinCategoryIds = (categories, category_ids) => {
  if (
    category_ids.length === 0 ||
    category_ids.includes(0) ||
    category_ids.includes("0")
  ) {
    return categories;
  }
  categories = cloneDeep(categories);

  const existsMatchInCategories = (cats, match_category_ids) => {
    for (const cat of cats) {
      if (
        match_category_ids.find(mc => {
          return mc == cat.category_id;
        })
      ) {
        return true;
      }
      if (
        existsMatchInCategories(cat.subcategories || [], match_category_ids)
      ) {
        return true;
      }
    }
    return false;
  };

  const processCategories = (cats, match_category_ids) => {
    for (const cat of cats) {
      if (
        match_category_ids.find(mc => {
          return mc === cat.category_id;
        })
      ) {
        // if it's a direct match then we don't need to process
      } else {
        if (existsMatchInCategories([cat], match_category_ids)) {
          // ok then we keep processing
          processCategories(cat.subcategories || [], match_category_ids);
        } else {
          //we gotta remove the category object;
          cat.deleteMe = true;
        }
      }
    }
  };

  processCategories(categories, category_ids);

  //now we purge the deleted ones
  const cleanedCategoryArray = cats => {
    let newArray: any[] = [];
    for (const cat of cats) {
      if (cat.deleteMe === true) {
      } else {
        if (cat.subcategories) {
          cat.subcategories = cleanedCategoryArray(cat.subcategories);
        }
        newArray.push(cat);
      }
    }
    return newArray;
  };

  categories = cleanedCategoryArray(categories);

  return categories;
};

export const visibleCategoriesForUserAndAreCategoriesEditable = (
  categories,
  selectedCategories
) => {
  return {
    valid_categories: categories,
    categorySelectorIsDisabled: false
  };
  let categorySelectorIsDisabled = false;
  const validCategories = cloneDeep(categories);
  let currentValidCategories = validCategories;
  if (
    Array.isArray(categories) &&
    Array.isArray(selectedCategories) &&
    selectedCategories[0]
  ) {
    // Using references so we can push at lower levels
    let currentCategories = categories;
    let currentSelectedCategory = selectedCategories[0];

    const findMatch = (current, selected) => {
      const isFound = (current || []).find(category => {
        return category.category_id === (selected || {}).category_id;
      });
      return isFound;
    };

    while (
      currentSelectedCategory &&
      !categorySelectorIsDisabled &&
      Array.isArray(currentCategories) &&
      Array.isArray(currentValidCategories)
    ) {
      const currentCategoryMatch = findMatch(
        currentCategories,
        currentSelectedCategory
      );
      if (currentCategoryMatch) {
        let newCurrentValidCategories = currentValidCategories.find(
          cat => cat.category_id === currentCategoryMatch.category_id
        );

        if (
          newCurrentValidCategories &&
          Array.isArray(newCurrentValidCategories.subcategories)
        ) {
          currentValidCategories = newCurrentValidCategories.subcategories;
        }
        currentSelectedCategory =
          currentSelectedCategory.subcategories &&
          currentSelectedCategory.subcategories[0];
        currentCategories = currentCategoryMatch.subcategories;
      } else {
        categorySelectorIsDisabled = true;
        currentValidCategories.push(currentSelectedCategory);
      }
    }
  }
  return {
    valid_categories: currentValidCategories,
    categorySelectorIsDisabled: categorySelectorIsDisabled
  };
};

export const getLowestLevelCategoryId = selectedCategories => {
  let current = selectedCategories?.[0];
  let lowestId;
  while (current) {
    lowestId = current?.category_id;
    current = current?.subcategories?.[0];
  }
  return lowestId;
};

export const allCategoryIdsFromDeepest = (
  deepestCategoryIds,
  allCategories
) => {
  //for each deepestCategoryId // find all parent categories and put them in the array if they're not in the array
  let allCategoryIds: any[] = cloneDeep(deepestCategoryIds);

  for (const cat of allCategories) {
    traverseAndAppendCategoryIdIfSubcategoryHasSearchIDDownStream(
      cat,
      deepestCategoryIds,
      allCategoryIds
    );
  }
  return allCategoryIds;
};

const traverseAndAppendCategoryIdIfSubcategoryHasSearchIDDownStream = (
  category,
  searchCategoryIds,
  arrayToAppendTo
) => {
  if (
    searchCategoryIds.find(scat => {
      return scat === category.category_id;
    }) ||
    hasChildCategoryOfIds(category, searchCategoryIds)
  ) {
    if (
      !arrayToAppendTo.find(aacat => {
        return aacat === category.category_id;
      })
    ) {
      arrayToAppendTo.push(category.category_id);
    }
    for (const subcat of category.subcategories || []) {
      traverseAndAppendCategoryIdIfSubcategoryHasSearchIDDownStream(
        subcat,
        searchCategoryIds,
        arrayToAppendTo
      );
    }
  }
};

const hasChildCategoryOfIds = (category, categoryIdsToSearch) => {
  if (
    categoryIdsToSearch.find(cat_id => {
      return cat_id === category.category_id;
    })
  ) {
    return true;
  }
  for (const subcat of category.subcategories || []) {
    if (hasChildCategoryOfIds(subcat, categoryIdsToSearch)) {
      return true;
    }
  }
  return false;
};

export const deepestCategoriesForCategoryPicker = (
  selectedCategoryIds,
  allCategories
) => {
  //go through all categories - see if there is a "deeper category in your mix" // remove if

  //turn into Array
  let filteredCategories = Object.keys(selectedCategoryIds).map(key => {
    return selectedCategoryIds[key];
  });

  //remove zero if any other categories
  if (
    filteredCategories.length > 0 &&
    filteredCategories.find(cat => {
      return cat === 0;
    })
  ) {
    filteredCategories = filteredCategories.filter(cat2 => {
      return cat2 !== 0;
    });
  }

  // console.log(filteredCategories);
  // console.log("starting to fully flter");
  let fullyFilteredCategories = filteredCategories.filter(category_id => {
    //find the actual category
    //remove self from id
    // console.log(`filtering category ${category_id}`);
    let filteredSubCategoryIdsToFind = filteredCategories.filter(
      scategory_id => {
        return scategory_id !== category_id;
      }
    );
    let categoryToValidate = findCategory(category_id, allCategories);
    if (categoryToValidate) {
      // console.log(categoryToValidate);
      //  console.log(`looking for `);
      //  console.log(filteredSubCategoryIdsToFind);
      let hasChildrenInArray = hasChildCategoryOfIds(
        categoryToValidate,
        filteredSubCategoryIdsToFind
      );
      //  console.log(hasChildrenInArray);
      if (!hasChildrenInArray) {
        return true;
      }
    }
    return false;
  });

  //turn back into dictionary
  let returnObject = {};
  for (const val of fullyFilteredCategories) {
    returnObject[val] = val;
  }
  return returnObject;

  //ok need to find each category - then determine if any children are in the array - if so then remove from array
};

export const findCategory = (
  category_id,
  categories
): CategoryInterface | null => {
  if (Array.isArray(categories)) {
    for (const cat of categories) {
      if (cat.category_id === category_id) {
        return cat;
      }
      const subcatFound = findCategory(category_id, cat.subcategories || []);
      if (subcatFound) {
        return subcatFound;
      }
    }
  }
  return null;
};

export const getRootLevelCategory = (
  category_id,
  categories
): CategoryInterface | null => {
  const categoryTree = getCategoryTreeWithParents(
    category_id,
    null,
    categories
  );
  return invertCategoryTreeFromParents(categoryTree);
};

//this finds a category of a specific id, and maintains a chain to the parent categories, which can be inverted and then sent into the category picker
export const getCategoryTreeWithParents = (
  categoryIdToFind,
  parentCategory,
  subcategories
) => {
  if (parentCategory && categoryIdToFind === parentCategory.category_id) {
    return parentCategory;
  }
  for (const cat of subcategories) {
    cat.parentCategory = parentCategory;
    if (cat.category_id === categoryIdToFind) {
      return cat;
    }
    if (cat.subcategories && cat.subcategories.length > 0) {
      let subCategoryFound = getCategoryTreeWithParents(
        categoryIdToFind,
        cat,
        cat.subcategories
      );
      if (subCategoryFound) {
        return subCategoryFound;
      }
    }
  }
};

//this inverts a category tree L3->L2->L1 and makes it L1->L2-L3 with only subcategory in each level
export const invertCategoryTreeFromParents = categoryWithParent => {
  if (categoryWithParent.parentCategory == null) {
    return categoryWithParent;
  } else {
    let parent = categoryWithParent.parentCategory;
    parent.subcategories = [];
    parent.subcategories.push(categoryWithParent);
    return invertCategoryTreeFromParents(parent);
  }
};

//Return true if it's empty string, or all spaces
export const categoryOtherIsEmpty = categoryl1 => {
  return (
    !categoryl1.category_other_text ||
    !NON_WHITESPACE_CHAR_REGEX.test(categoryl1.category_other_text)
  );
};

export const generateAutomationAreaText = (categories, translationFunction) => {
  let text = "";

  if (Array.isArray(categories) && categories.length > 0) {
    text = categories[0]?.category_is_other
      ? typeof translationFunction === "function"
        ? translationFunction("taxonomy_other", "Other")
        : "Other"
      : categories[0].category_name;
  }

  return text;
};

export const categoryLineageText = (
  category_id: number,
  categories: CategoryInterface[],
  t?: TFunction
) => {
  let finalText = "";

  const generateLineageText = (category: CategoryInterface, stopId) => {
    let text =
      category?.category_is_other && t
        ? t(["admin_customize_assessment_category_other", "Other"])
        : category?.category_name;

    if (
      category?.subcategories &&
      category.subcategories[0] &&
      category.category_id !== stopId
    ) {
      text = `${text} / ${generateLineageText(
        category.subcategories[0],
        stopId
      )}`;
    }
    return text;
  };

  const mappingCategories = filterCategoriesWithinCategoryIds(categories, [
    category_id
  ]);

  if (mappingCategories[0]) {
    finalText = generateLineageText(mappingCategories[0], category_id);
  }

  return finalText;
};
