import React from "react";
import "./CellLastUpdatedByOwner.scss";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";

const CellLastUpdatedByOwner = props => {
  const { component_updated_epoch } = props;

  return (
    <div
      className="CellLastUpdatedByOwner"
      data-testid="CellLastUpdatedByOwnerContainer"
    >
      <span className="CellLastUpdatedByOwner__last-updated-time">
        {`${showDateWithTenantDateFormat(
          component_updated_epoch,
          props.t("general_data_format_slash", "L")
        )}`}
      </span>
    </div>
  );
};

export default withTranslation("common")(CellLastUpdatedByOwner);
