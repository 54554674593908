import { customQuestionWithInfo } from "../co-custom-questions.template";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import { CO_CHART_DEFAULT_RANGE } from "../../constants/co-chart.constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardKPISlugs
} from "../../constants/co-question.constants";
import {
  COPermissionSlugs,
  COConditionSlugs
} from "../../constants/co-constants";

export const customQ2KPITemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.DA_LIVE_BENEFIT_EM_HRS_MO,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.DA_LIVE_BENEFIT_EM_HRS_MO,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        note: "NOTE: KPI is displayed in the Automation Store profile",
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_MONTH
          }),
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        },
        question_overrides: {
          co_question_meta_json: {
            title: {
              value: "Benefit per employee (hours saved/month)"
            }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.DA_LIVE_BENEFIT_EM_HRS_MO
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS}/12,0)`,
          co_question_position_json: {
            sort_order: 100
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.CHART_AUTOMATION_POTENTIAL,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CHART_AUTOMATION_POTENTIAL,
        standard_kpi_template_slug: StandardKPISlugs.CHART_KPI,
        title: "Ease vs benefits",
        description: "",
        question_overrides: {
          co_question_default_options_json: {
            default_equation_value: undefined
          },
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          chart: {
            title: {
              value: "Estimated in Detailed Assessment",
              value_localization_key:
                "cda_chart_estimated_in_detailed_assessment"
            },
            x_axis_range: CO_CHART_DEFAULT_RANGE,
            y_axis_range: CO_CHART_DEFAULT_RANGE,
            bubble_range: "0:10000",
            x_axis_ahid: CustomQuestionSlugs.EASE_OF_IMPLEMENTATION,
            y_axis_ahid: CustomQuestionSlugs.AUTOMATION_POTENTIAL,
            bubble_ahid: CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_FTES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_FTES,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Benefit per employee (FTEs)",
        description:
          "The benefit computed per company divided by the number of employees",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_FTES
          ),
          co_equation: "",
          co_display_equation: `round(BENEFIT_CO_FTE/NB_EMPLOYEES,2)`,
          co_question_position_json: {
            sort_order: 100
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_CURRENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_CURRENCY,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Benefit per employee (currency/year)",
        description:
          "The benefit computed per company divided by the number of employees",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_CURRENCY
          ),
          co_equation: "",
          co_display_equation: `round(BENEFIT_CO_CURRENCY/NB_EMPLOYEES,0)`,
          co_question_default_options_json: {
            default_equation_value: undefined
          },
          co_question_position_json: {
            sort_order: 90
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Benefit per employee (hours saved/year)",
        description:
          "The benefit computed per company divided by the number of employees",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS
          ),
          co_equation: "",
          co_display_equation: `round(BENEFIT_CO_HOURS/NB_EMPLOYEES,0)`,
          co_question_default_options_json: {
            default_equation_value: undefined
          },
          co_question_position_json: {
            sort_order: 80
          }
        },
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.BENEFIT_PER_COMPANY_FTE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.BENEFIT_PER_COMPANY_FTE,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Benefit per company (FTEs)",
        description:
          "The Automation Potential % multiplied by the FTEs Required.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.BENEFIT_PER_COMPANY_FTE
          ),
          co_equation: "",
          co_display_equation: `round(FTE*AUTOMATION_POTENTIAL/100,2)`,
          co_question_default_options_json: {
            default_equation_value: 0
          },
          co_question_position_json: {
            sort_order: 70
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Benefit per company (currency/year)",
        description:
          "The Automation Potential % multiplied by the Cost/Year for the Process AS-IS",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY
          ),
          co_equation: "",
          co_display_equation: `round(COST_PER_YEAR*AUTOMATION_POTENTIAL/100,0)`,
          co_question_default_options_json: {
            default_equation_value: undefined
          },
          co_question_position_json: {
            sort_order: 60
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Benefit per company (hours saved/year)",
        description:
          "The Automation Potential % multiplied by the Total Time Needed to Perform the Work - AS-IS Process (hours/year)",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS
          ),
          co_equation: "",
          co_display_equation: `round(HOURS_PER_YEAR*AUTOMATION_POTENTIAL/100,0)`,
          co_question_position_json: {
            sort_order: 50
          }
        },
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EASE_OF_IMPLEMENTATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EASE_OF_IMPLEMENTATION,
        standard_kpi_template_slug: StandardKPISlugs.LOCKED_PERCENT_KPI,
        title: "Ease of implementation",
        description:
          "Computed based on the answers from Environmental Stability, Process Ways of Working, Scanned Data Input, % of Structured Digital Data Input and Applications Used sections",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EASE_OF_IMPLEMENTATION
          ),
          co_equation: "",
          co_question_position_json: {
            sort_order: 40
          },
          co_display_equation: `round(max(1-((0.05*CHANGES_EXPCT+0.05*APPS_CHANGES_EXPCT+0.4*PCT_STRC_DATA+0.3*NB_WAYS_COMP_PROC+0.1*NB_STEPS+0.1*NB_APPS)*THIN_CLIENT_M*OCR),0)*100,0)`
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EASE_OF_IMPLEMENTATION_LEVEL,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EASE_OF_IMPLEMENTATION,
        standard_kpi_template_slug: StandardKPISlugs.LOCKED_NUMBER_KPI,
        title: "Ease of implementation level",
        description:
          "Buckets the Ease of implementation into easy / medium / difficult ranges",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EASE_OF_IMPLEMENTATION_LEVEL
          ),
          co_equation: "",
          co_question_position_json: {
            sort_order: 41
          },
          co_display_equation: `(${CustomQuestionSlugs.EASE_OF_IMPLEMENTATION} > 64 ) ? 2 : (${CustomQuestionSlugs.EASE_OF_IMPLEMENTATION} > 34) ? 1 : 0`
        },
        options_meta_overrides: {
          unit: PATH({
            route:
              PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.EASE_OF_IMPLEMENTATION_LEVEL
          }),
          rounding_decimals: 0,
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
            { slug: COConditionSlugs.HIDE_FOR_EDIT }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_POTENTIAL,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_POTENTIAL,
        standard_kpi_template_slug: StandardKPISlugs.LOCKED_PERCENT_KPI,
        title: "Automation potential",
        description:
          "Computed based on the answers to % of structured digital data input, % of digital data input and Number of ways to complete the process",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_POTENTIAL
          ),
          co_equation: "",
          co_question_position_json: {
            sort_order: 30
          },
          co_display_equation: `round((PCT_DIGITAL_DATA/100.0)*(1-0.5*PCT_STRC_DATA-0.1*NB_WAYS_COMP_PROC)*100,0)`,
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.APPLICATION_THIN_CLIENT_MULTIPLIER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.APPLICATION_THIN_CLIENT_MULTIPLIER,
        standard_kpi_template_slug: StandardKPISlugs.APPLICATION_KPI,
        title: "Thin client multiplier",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.APPLICATION_THIN_CLIENT_MULTIPLIER
          ),
          co_equation: "",
          co_display_equation: ` (${CustomQuestionSlugs.APPLICATIONS_THIN} * 1.6 + (${CustomQuestionSlugs.APPLICATIONS}-${CustomQuestionSlugs.APPLICATIONS_THIN})) / max(1,${CustomQuestionSlugs.APPLICATIONS})`,
          co_question_position_json: {
            sort_order: 40
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.APPLICATION_NUMBER_MULTIPLIER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.APPLICATION_NUMBER_MULTIPLIER,
        standard_kpi_template_slug: StandardKPISlugs.APPLICATION_KPI,
        title: "Application number multiplier",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.APPLICATION_NUMBER_MULTIPLIER
          ),
          co_equation: "",
          co_display_equation: `${CustomQuestionSlugs.APPLICATIONS} <= 3 ? 0 : ${CustomQuestionSlugs.APPLICATIONS} <= 5 ? 0.2 : ${CustomQuestionSlugs.APPLICATIONS} <=7 ? 0.4 : ${CustomQuestionSlugs.APPLICATIONS} <= 9 ? 0.8 : 1`,
          co_question_position_json: {
            sort_order: 30
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.COST_PER_YEAR_AS_IS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.COST_PER_YEAR_AS_IS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Cost/Year for process AS IS",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.COST_PER_YEAR_AS_IS
          ),
          co_equation: "",
          co_display_equation: `round(EMPLOYEE_COST_YEARLY*HOURS_PER_YEAR/WORKING_HOURS/WORKING_DAYS,2)`,
          co_question_position_json: {
            sort_order: 140
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.FTE_REQUIRED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.FTE_REQUIRED,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "FTEs required",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.FTE_REQUIRED
          ),
          co_equation: "",
          co_display_equation: `round(HOURS_PER_YEAR/WORKING_HOURS/WORKING_DAYS,2)`,
          co_question_position_json: {
            sort_order: 130
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.TOTAL_TIME_NEEDED_AS_IS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.TOTAL_TIME_NEEDED_AS_IS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total time needed to perform the work - AS IS",
        description: "Total number of hours at the level of the company",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.TOTAL_TIME_NEEDED_AS_IS
          ),
          co_equation: "",
          co_display_equation: `TOTAL_PROC_TIME+TOTAL_REWORK_TIME+TOTAL_REVIEW_TIME`,
          co_question_position_json: {
            sort_order: 120
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.TOTAL_REV_TIME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.TOTAL_REV_TIME,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total review/audit time",
        description: "Total review/audit time at the level of the company",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.TOTAL_REV_TIME
          ),
          co_equation: "",
          co_display_equation: `round(FREQ*VOLUMES*REVIEW_TIME*(REVIEW_RATE/100)*((NB_EMPLOYEES*VOLUMES_FREQUENCY)+(1-VOLUMES_FREQUENCY))/60,0)`,
          co_question_position_json: {
            sort_order: 110
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.TOTAL_REWORK_TIME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.TOTAL_REWORK_TIME,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total rework time",
        description: "Total rework time at the level of the company",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.TOTAL_REWORK_TIME
          ),
          co_equation: "",
          co_display_equation: `round(FREQ*VOLUMES*REWORK_TIME*(ERROR_RATE/100)*((NB_EMPLOYEES*VOLUMES_FREQUENCY)+(1-VOLUMES_FREQUENCY))/60,0)`,
          co_question_position_json: {
            sort_order: 80
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.TOTAL_PROCESSING_TIME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.TOTAL_PROCESSING_TIME,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total processing time",
        description: "Total processing time at the level of the company",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.TOTAL_PROCESSING_TIME
          ),
          co_equation: "",
          co_display_equation: `round(FREQ*VOLUMES*TIME_PER_TRANS*((NB_EMPLOYEES*VOLUMES_FREQUENCY)+(1-VOLUMES_FREQUENCY))/60,0)`,
          co_question_position_json: {
            sort_order: 50
          }
        },
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        }
      })
    }
  ];
};
