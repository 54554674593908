import {
  COContextInterface,
  COTaskActionInterface,
  COActionTemplateInterface
} from "../../interfaces/co-interfaces";
import { COBaseAction } from "./co-base-action.class";

export interface COTaskAction extends COTaskActionInterface {}

export class COTaskAction extends COBaseAction {
  static getClassName(): string {
    return "COTaskAction";
  }
  constructor({
    propertiesFromJSON,
    context,
    template
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COActionTemplateInterface;
  }) {
    super({
      propertiesFromJSON,
      context,
      template
    });
  }
}
