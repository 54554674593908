import {
  FETCH_AUTOMATION_PERFORMANCE_DATA,
  CLEAR_AUTOMATION_PERFORMANCE_DATA,
  FETCH_APD_FILTER_VALUES,
  SET_APD_SEARCH_QUERY,
  SET_APD_AUTOMATIONS_IS_LOADING_DATA
} from "../constants/actionTypes";

const INITIAL_STATE = {
  automationPerformanceData: null,
  APDUpperFilterValues: null,
  searchQuery: "",
  isLoadingAPDAutomations: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AUTOMATION_PERFORMANCE_DATA:
      return {
        ...state,
        automationPerformanceData: action.payload
      };
    case SET_APD_AUTOMATIONS_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingAPDAutomations: action.payload
      };
    case CLEAR_AUTOMATION_PERFORMANCE_DATA:
      return {
        ...state,
        automationPerformanceData: null
      };
    case FETCH_APD_FILTER_VALUES:
      return {
        ...state,
        APDUpperFilterValues: action.payload
      };
    case SET_APD_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      };
    default:
      return state;
  }
}
