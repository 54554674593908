import {
  COErrorTypes,
  COValidationType
} from "../../../constants/co-constants";
import { getValue } from "../../../utils/co-path.utils";
import {
  COContextInterface,
  COValidationContext,
  COValidationError,
  COValidationItemInterface,
  COValidationSlugs
} from "../../../interfaces/co-interfaces";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../constants/co-path.constants";
import {
  updateCustomizationQuestionValidators,
  updateCustomizationSectionValidators
} from "./co-submission-update-validators.template";

export const titleQuestionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.META.TITLE.VALUE
      }),
      meta: {
        value: "The title can be no longer than 150 characters",
        value_localization_key: "co_error_title_too_long"
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      validation_context: {
        assessment_customize: 1
      },
      value: 150
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.META.TITLE.VALUE
      }),
      meta: {
        value: "Please fill in all required fields before saving.",
        value_localization_key: "co_error_fill_required_fields"
      },
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      validation_context: {
        assessment_customize: 1
      },
      value: 1
    }
  ];
};

export const descriptionQuestionValidators = (): COValidationItemInterface[] => {
  let validationInterfaces: COValidationItemInterface[] = [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.META.DESCRIPTION.VALUE
      }),
      meta: {
        value: "Description can be no longer than 750 characters",
        value_localization_key: "co_error_description_too_long"
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      validation_context: {
        assessment_customize: 1
      },
      value: 750
    }
  ];
  return validationInterfaces;
};

export const tabularQuestionValidators = (): COValidationItemInterface[] => {
  let validationInterfaces: COValidationItemInterface[] = [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.NO_TABULAR_SUPPORT
      }),
      meta: {
        value:
          "A question in this section can not be displayed in a table format",
        value_localization_key:
          "co_error_question_in_section_cannot_be_displayed_as_table"
      },
      validationType: COValidationType.VALIDATION_TYPE_EQUALS,
      validationSlug:
        COValidationSlugs.QUESTION_CANNOT_BE_SHOWN_IN_TABULAR_FORMAT,
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_TABULAR,
        operator: PATH_OPERATORS.INVERSE
      }),
      validation_context: {
        assessment_customize: 1
      },
      value: 0
    }
  ];
  return validationInterfaces;
};

export const maxSelectedAnswersQuestionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_PROCESS_ANSWER
            .CO_PROCESS_ANSWER_SELECTIONS
      }),
      meta: {
        value: "Max Selected Answers",
        value_localization_key: "co_error_max_selected_answers"
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_ARRAY_LENGTH,
      validation_context: {
        assessment_submission: 1,
        assessment_submission_transform_view: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_SELECTABLE_ANSWERS
      })
    }
  ];
};

export const maxMinAnswersOptionsQuestionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_ANSWER_OPTIONS
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_ARRAY_LENGTH,
      meta: {
        value: "Max Answer Options",
        value_localization_key: "co_error_max_options"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_QUESTION_ANSWER_OPTIONS
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_ANSWER_OPTIONS
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
      meta: {
        value:
          "Single choice and Multiple choice questions must have at least two answer options.",
        value_localization_key: "co_error_more_options_needed"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MIN_QUESTION_ANSWER_OPTIONS
      })
    }
  ];
};

export const variableLengthQuestionValidators = (): COValidationItemInterface[] => {
  let validationInterfaces: COValidationItemInterface[] = [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      meta: {
        value: "You must define a variable of minimum 3 characters.",
        value_localization_key: "co_error_variable_name_too_short"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 3
    }
  ];
  return validationInterfaces;
};
export const answerSelectionRequiredQuestionValidators = (): COValidationItemInterface[] => {
  let validationInterfaces: COValidationItemInterface[] = [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.PROCESS_ANSWER.CO_PROCESS_ANSWER_SELECTIONS
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
      meta: {
        value: "An answer selection is required for submission",
        value_localization_key: "co_error_answer_selection_required"
      },
      validation_context: {
        assessment_submission: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION
      }),
      value: 1
    }
  ];
  return validationInterfaces;
};

export const minimumCategoriesSelectionValidator = (): COValidationItemInterface[] => {
  let validationInterfaces: COValidationItemInterface[] = [
    {
      // The target is categories and selected_categories of the context
      // but we are referencing it directly from the context instead of using the target
      // resolving categories as the target can really slow things down when there are thousands
      target: "",
      validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
      meta: {
        value: "You must select the minimum number of categories",
        value_localization_key: "co_error_categories_minimum_selection_required"
      },
      validation_context: {
        assessment_submission: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MIN_SELECTABLE_ANSWERS
      }),
      validationFunction: ({
        context,
        validation_context,
        value,
        target,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        value: any;
        target: any;
        error: COValidationError;
      }) => {
        // find the selected categories
        if (
          (context?.process_external_data?.category_data?.selected_categories
            ?.length || 0) > 0
        ) {
          if (typeof value === "number")
            // loop through sub_categories to see if we meet the count
            var count: number;
          count = 0;
          var selectedCategory: any | undefined =
            context?.process_external_data?.category_data
              ?.selected_categories?.[0];

          // find the existing category that matches the selected category
          var matchingCategoryData = (
            context?.process_external_data?.category_data?.categories || []
          )?.find(category => {
            return category.category_id === selectedCategory.category_id;
          });

          // keep looping while a subcategory is selected
          while (selectedCategory !== undefined) {
            count += 1;
            if ((selectedCategory?.subcategories?.length || 0) > 0) {
              selectedCategory = selectedCategory?.subcategories?.[0];

              // find the existing category that matches the selected subcategory
              if (selectedCategory) {
                matchingCategoryData = (
                  matchingCategoryData?.subcategories || []
                ).find(category => {
                  return category.category_id === selectedCategory.category_id;
                });
              }
            } else {
              selectedCategory = undefined;
            }
          }

          if (count >= value) {
            // no error. minimum number of categories selected.
            return [];
          } else if (
            matchingCategoryData?.subcategories &&
            matchingCategoryData?.subcategories?.length === 0
          ) {
            // if the category had no possible sub-categories to select, it counts as not being an error
            // the user selected the max amount they physically could, even if it didn't match the amount requested
            return [];
          }
        }
        return [error];
      }
    }
  ];
  return validationInterfaces;
};

export const answerInputRequiredQuestionValidators = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      meta: {
        value: "An answer entry is required for submission",
        value_localization_key: "co_error_answer_entry_required"
      },
      validation_context: {
        assessment_submission: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION
      }),
      value: 1
    }
  ];
};

export const numberQuestionRequiresPositiveNumberAnswerValidators = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      validationType: COValidationType.VALIDATION_TYPE_IS_POSITIVE_NUMERICAL,
      meta: {
        value: "This answer must be a positive number",
        value_localization_key: "co_error_answer_positive_number_required"
      },
      validation_context: {
        assessment_submission: 1
      },
      value: 1
    }
  ];
};

export const prefillAnswerRequiresPositiveNumberValidators = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.PREFILL.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_IS_POSITIVE_NUMERICAL,
      meta: {
        value: "The pre-filled answer must be a positive number",
        value_localization_key: "co_error_prefill_positive_number_required"
      },
      validation_context: {
        assessment_customize: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.PRE_FILL_DEFAULT_ANSWER,
        operator: PATH_OPERATORS.INVERSE
      }),
      value: 1
    }
  ];
};

export const prefillAnswerCheckDecimalPlacesValidators = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.PREFILL.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_ERROR_FOR_DECIMAL,
      meta: {
        value: "The pre-filled answer has too many decimal places",
        value_localization_key: "co_error_prefill_too_many_decimal_places"
      },
      validation_context: {
        assessment_customize: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.PRE_FILL_DEFAULT_ANSWER,
        operator: PATH_OPERATORS.INVERSE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.ROUNDING_DECIMALS
      })
    }
  ];
};

export const answerRequiredExternalAnswerSourceValidators = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.EXTERNAL_ANSWER_SOURCE
      }),
      validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
      meta: {
        value: "A selection required for submission",
        value_localization_key: "co_validation_selection_required"
      },
      validation_context: {
        assessment_submission: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION
      }),
      validationFunction: ({
        context,
        validation_context,
        value,
        target,
        error
      }: {
        context: COContextInterface;
        validation_context: COValidationContext;
        value: any;
        target: any;
        error: COValidationError;
      }): COValidationError[] => {
        // I don't know if this is the best place to do this - but but we want to "poke" all the equations to re-render when a variable is updated
        let answerSource = target;
        let qualifedPathToProperty = PATH({ route: answerSource });
        let valueForPath = getValue(context, qualifedPathToProperty);
        // console.log(valueForPath);
        if (valueForPath) {
        } else {
          return [error];
        }
        return [];
      },
      value: 1
    }
  ];
};

export const answerInputRangeValidators = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE,
      meta: {
        value: "Answer value is too large",
        value_localization_key: "co_answer_too_large"
      },
      validation_context: {
        assessment_submission: 1,
        assessment_submission_transform_view: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_VALUE
      })
    },
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE,
      meta: {
        value: "Answer value is too small",
        value_localization_key: "co_answer_too_small"
      },
      validation_context: {
        assessment_submission: 1,
        assessment_submission_transform_view: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MIN_VALUE
      })
    }
  ];
};

export const roundToDecimalTransformQuestionValidators = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      validationType: COValidationType.VALIDATION_TYPE_ROUND_TO_DECIMAL,
      validation_context: {
        assessment_submission_transform_view: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.ROUNDING_DECIMALS
      })
    }
  ];
};

export const applicationRequiredQuestionValidators = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.APPLICATION_DATA.APPLICATIONS
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
      meta: {
        value: "An application selection is required for submission",
        value_localization_key: "co_error_application_selection_required"
      },
      validation_context: {
        assessment_submission: 1
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION
      }),
      value: 1
    }
  ];
};

export const assessmentValidators = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_SECTIONS
      }),
      meta: {
        value: "At least one section is required",
        value_localization_key: "co_error_one_selection_required"
      },
      validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
      validation_context: {
        assessment_customize: 1
      },
      value: 1
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ASSESSMENT.FUNCTION_ALL_QUESTIONS,
        operator: PATH_OPERATORS.FUNCTION
      }),
      meta: {
        value: "You can not have more than 150 questions",
        value_localization_key: "co_error_too_many_questions"
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_ARRAY_LENGTH,
      validation_context: {
        assessment_customize: 1
      },
      value: 150
    },
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON.TITLE
            .VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      meta: {
        value: "Please fill in all required fields before saving.",
        value_localization_key: "co_error_fill_required_fields"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 1
    },
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
            .DESCRIPTION.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      meta: {
        value:
          "Assessment Version Description can be no longer than 750 characters",
        value_localization_key:
          "co_error_assessment_version_description_max_characters"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 750
    },
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON.VERSION
            .VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      meta: {
        value: "Please fill in all required fields before saving.",
        value_localization_key: "co_error_fill_required_fields"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 1
    }
  ];
};

export const sectionValidators = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.META.TITLE.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      meta: {
        value: "Please fill in all required fields before saving.",
        value_localization_key: "co_error_fill_required_fields"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 1
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.META.TITLE.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      meta: {
        value: "Section title can be no longer than 50 characters",
        value_localization_key: "co_error_section_title_too_long"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 50
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.META.DESCRIPTION.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      meta: {
        value: "Section description can be no longer than 500 characters",
        value_localization_key: "co_error_section_description_too_long"
      },
      validation_context: {
        assessment_customize: 1
      },
      value: 500
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.CO_QUESTIONS
      }),
      meta: {
        value: "Section must contain at least one question.",
        value_localization_key: "co_error_section_one_question_required"
      },
      validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
      validation_context: {
        assessment_customize: 1
      },
      value: 1
    },
    ...updateCustomizationSectionValidators()
  ];
};

export const processAnswerValidators = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.PROCESS_ANSWER.META.COMMENT.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.HIDE_COMMENTS_SECTION
      }),
      meta: {
        value: "Comment can be no longer than 750 characters",
        value_localization_key: "co_error_comment_too_long"
      },
      validation_context: {
        assessment_submission: 1,
        assessment_customize_transform_edit: 1
      },
      value: 750
    }
  ];
};

export const answerValueValidations = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.CO_QUESTION_ANSWER_OPTION_VALUE
      }),
      meta: {
        value: "All answer scores must be numberical",
        value_localization_key: "co_error_answer_options_not_numerical"
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
        operator: PATH_OPERATORS.INVERSE
      }),
      validation_context: {
        assessment_customize: 1,
        assessment_customize_transform_edit: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_IS_NUMERICAL
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.CO_QUESTION_ANSWER_OPTION_VALUE
      }),
      meta: {
        value: "All answer options must have values < 9999999999",
        value_localization_key: "co_error_answer_options_too_big"
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
        operator: PATH_OPERATORS.INVERSE
      }),
      validation_context: {
        assessment_customize: 1,
        assessment_customize_transform_edit: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE,
      value: 9999999999
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.CO_QUESTION_ANSWER_OPTION_VALUE
      }),
      meta: {
        value: "All answer options must have values > -9999999999",
        value_localization_key: "co_error_answer_options_too_small"
      },
      is_disabled: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
        operator: PATH_OPERATORS.INVERSE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE,
      validation_context: {
        assessment_customize: 1,
        assessment_customize_transform_edit: 1
      },
      value: -9999999999
    }
  ];
};

export const titleQuestionAnswerOptionValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.TITLE.VALUE
      }),
      meta: {
        value: "An answer option can not have more then 300 characters",
        value_localization_key: "co_error_answer_options_max_character_limit"
      },
      validation_context: {
        assessment_customize: 1,
        assessment_customize_transform_edit: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      value: 300
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.TITLE.VALUE
      }),
      meta: {
        value: "An answer option must have at least 1 character.",
        value_localization_key:
          "co_error_answer_options_at_least_one_character_required"
      },
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      validation_context: {
        assessment_customize: 1,
        assessment_customize_transform_edit: 1
      },
      value: 1
    }
  ];
};

export const prefillMaxAnswerLengthValidators = (): COValidationItemInterface[] => {
  let validationInterfaces: COValidationItemInterface[] = [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.PREFILL.VALUE
      }),
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      meta: {
        value:
          "A prefilled answer to this question can be no longer than {{max_answer_length}} characters",
        value_localization_key: "co_error_prefill_answer_max_length_reached",
        value_localization_values: {
          max_answer_length: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
          })
        }
      },
      validation_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
      })
    }
  ];
  return validationInterfaces;
};

export const defaultMaxAnswerLengthValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.VALUE
      }),
      validation_context: {
        assessment_submission: 1,
        assessment_submission_transform_view: 1
      },
      meta: {
        value: "An answer entry can be no longer than 10000 characters",
        value_localization_key: "co_error_answer_value_too_long"
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      value: 10000
    }
  ];
};

export const maxAnswerLengthValidators = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.VALUE
      }),
      validation_context: {
        assessment_submission: 1,
        assessment_submission_transform_view: 1
      },
      meta: {
        value:
          "An answer to this question can be no longer than {{max_answer_length}} characters",
        value_localization_key: "co_error_answer_max_length_reached",
        value_localization_values: {
          max_answer_length: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
          })
        }
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
      })
    }
  ];
};

//max_answer_length

export const numberAnswerOptionRoundToDecimalTransformValidator = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.VALUE
      }),
      validation_context: {
        assessment_submission_transform_blur: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_ROUND_TO_DECIMAL,
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.ROUNDING_DECIMALS
      })
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.PLACEHOLDER
      }),
      validation_context: {
        assessment_submission_transform_view: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_ROUND_TO_DECIMAL,
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.ROUNDING_DECIMALS
      })
    }
  ];
};

export const numberAnswerOptionMinMaxValidator = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.VALUE
      }),
      validation_context: {
        assessment_submission_transform_blur: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE,
      value: 9999999999
    },
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.VALUE
      }),
      validation_context: {
        assessment_submission_transform_blur: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE,
      value: -9999999999
    }
  ];
};

export const equationLengthValidator = (): COValidationItemInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_DISPLAY_EQUATION
      }),
      meta: {
        value: "The Formula field is empty.",
        value_localization_key: "co_error_formula_is_empty"
      },
      validation_context: {
        assessment_customize: 1
      },
      validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
      value: 1
    }
  ];
};
