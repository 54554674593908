import SessionHelper from "./SessionHelper";

const TENANT_LOGO_KEY = "tenant_logo";

const logoHelper = {
  setLogo: logoPath => {
    SessionHelper.setDataForKey(logoPath, TENANT_LOGO_KEY);
    return logoPath;
  },
  getLogo: () => {
    const logoPath = SessionHelper.getValueForKey(TENANT_LOGO_KEY);
    if (logoPath && logoPath.length > 0) {
      return logoPath;
    }
    return null;
  },
  clearLogo: () => {
    SessionHelper.clearValueForKey(TENANT_LOGO_KEY);
  }
};

export default logoHelper;
