export const CO_CHART_TYPES = {
  BUBBLE_CHART: "BUBBLE_CHART",
  IDEA_GAUGE_CHART: "IDEA_GAUGE_CHART"
};
export const CO_CHART_RANGES = {
  PCT_RANGE: "0:100",
  THOUSAND_RANGE: "0:1000",
  TEN_THOUSAND_RANGE: "0:10000",
  HUNDRED_THOUSAND_RANGE: "0:100000",
  MILLION_RANGE: "0:1000000",
  BIG_RANGE: "0:10000000"
};
export const CO_CHART_DEFAULT_RANGE = CO_CHART_RANGES.PCT_RANGE;
