export enum COEmailReminderActionSlugs {
  SUBMITTED_IDEA_TO_REVIEW_EMAIL_REMINDER = "SUBMITTED_IDEA_TO_REVIEW_EMAIL_REMINDER",
  REVIEW_COE_DRIVEN_IDEA_EMAIL_REMINDER = "REVIEW_COE_DRIVEN_IDEA_EMAIL_REMINDER",
  ASSESSMENT_LAUNCHED_EMAIL_REMINDER = "ASSESSMENT_LAUNCHED_EMAIL_REMINDER",
  REVIEW_ASSESSED_AUTOMATION_EMAIL_REMINDER = "REVIEW_ASSESSED_AUTOMATION_EMAIL_REMINDER",
  ASSIGN_PROJECT_MANAGER_EMAIL_REMINDER = "ASSIGN_PROJECT_MANAGER_EMAIL_REMINDER",
  CD_AUTOMATION_SHARED_EMAIL_REMINDER = "CD_AUTOMATION_SHARED_EMAIL_REMINDER",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL_REMINDER = "CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL_REMINDER",
  CD_AUTOMATION_REWORK_REQUIRED_EMAIL_REMINDER = "CD_AUTOMATION_REWORK_REQUIRED_EMAIL_REMINDER",
  REVIEW_CHANGE_REQUEST_EMAIL_REMINDER = "REVIEW_CHANGE_REQUEST_EMAIL_REMINDER"
}
