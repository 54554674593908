export enum COCategoryType {
  AUTOMATION = "AUTOMATION",
  COMPONENT = "COMPONENT",
  USER = "USER",
  TENANT = "TENANT"
}

export enum COPlatformEventType {
  IDEA_SUBMITTED = "IDEA_SUBMITTED",
  PROCESS_PHASE_CHANGED = "PROCESS_PHASE_CHANGED",
  PROCESS_MARK_COMPLETED = "PROCESS_MARK_COMPLETED",
  USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED",
  PROCESS_UPDATED = "PROCESS_UPDATED",
  PROCESS_DOCUMENT_ADDED = "PROCESS_DOCUMENT_ADDED",
  PROCESS_FOLLOWED = "PROCESS_FOLLOWED",
  PROCESS_UPVOTED = "PROCESS_UPVOTED",
  PROCESS_COMMENT_ADDED = "PROCESS_COMMENT_ADDED",
  PROCESS_COMMENT_REPLY_ADDED = "PROCESS_COMMENT_REPLY_ADDED",
  PROCESS_COMMENT_USER_MENTIONED = "PROCESS_COMMENT_USER_MENTIONED",
  PROCESS_COLLABORATOR_ROLE_ASSIGNED = "PROCESS_COLLABORATOR_ROLE_ASSIGNED",
  REUSABLE_COMPONENT_ADDED = "REUSABLE_COMPONENT_ADDED",
  PROCESS_ABUSE_REPORTED = "PROCESS_ABUSE_REPORTED",
  COMPONENT_STATUS_CHANGED = "COMPONENT_STATUS_CHANGED",
  COMPONENT_SUPPORT_REQUESTED = "COMPONENT_SUPPORT_REQUESTED",
  COMPONENT_ABUSE_REPORTED = "COMPONENT_ABUSE_REPORTED",
  SEND_USER_INVITE_EMAIL = "SEND_USER_INVITE_EMAIL",
  EMAIL_VERIFICATION_SENT = "EMAIL_VERIFICATION_SENT",
  COMPONENT_REVIEW_ABUSE_REPORTED = "COMPONENT_REVIEW_ABUSE_REPORTED",
  PROCESS_ABUSE_COMMENT_REPORTED = "PROCESS_ABUSE_COMMENT_REPORTED",
  COMPONENT_ABUSE_COMMENT_REPORTED = "COMPONENT_ABUSE_COMMENT_REPORTED",
  PROCESS_COLLABORATOR_ROLE_REMOVED = "PROCESS_COLLABORATOR_ROLE_REMOVED",
  PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED",
  PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_REVOKED = "PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_REVOKED",
  PROCESS_OWNER_ASSIGNED = "PROCESS_OWNER_ASSIGNED",
  PROJECT_MANAGER_ASSIGNED = "PROJECT_MANAGER_ASSIGNED",
  PROCESS_DOCUMENT_UPDATED = "PROCESS_DOCUMENT_UPDATED",
  PROCESS_DOCUMENT_DELETED = "PROCESS_DOCUMENT_DELETED",
  DIRECT_MANAGER_ADDED = "DIRECT_MANAGER_ADDED",
  DIRECT_MANAGER_REMOVED = "DIRECT_MANAGER_REMOVED",
  ADMIN_PROCESS_ROLE_ADDED = "ADMIN_PROCESS_ROLE_ADDED",
  ADMIN_PROCESS_ROLE_UPDATED = "ADMIN_PROCESS_ROLE_UPDATED",
  ADMIN_PROCESS_ROLE_DELETED = "ADMIN_PROCESS_ROLE_DELETED",
  ADMIN_TEMPLATE_DOCUMENT_ADDED = "ADMIN_TEMPLATE_DOCUMENT_ADDED",
  ADMIN_TEMPLATE_DOCUMENT_UPDATED = "ADMIN_TEMPLATE_DOCUMENT_UPDATED",
  ADMIN_TEMPLATE_DOCUMENT_SET_DEFAULT = "ADMIN_TEMPLATE_DOCUMENT_SET_DEFAULT",
  ADMIN_PROCESS_ROLE_ENABLED = "ADMIN_PROCESS_ROLE_ENABLED",
  ADMIN_PROCESS_ROLE_DISABLED = "ADMIN_PROCESS_ROLE_DISABLED",
  ADMIN_TEMPLATE_DELETED = "ADMIN_TEMPLATE_DELETED",
  PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_GRANTED",
  PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_REVOKED = "PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_REVOKED",
  PROCESS_COLLABORATOR_CERTAIN_ROLE_REMOVED = "PROCESS_COLLABORATOR_CERTAIN_ROLE_REMOVED",
  COMPONENT_VERSION_UPDATED = "COMPONENT_VERSION_UPDATED",
  PROJECT_MANAGER_ROLE_ASSIGNED = "PROJECT_MANAGER_ROLE_ASSIGNED",
  COMPONENT_UPDATED = "COMPONENT_UPDATED",
  COE_DRIVEN_IDEA_SUBMITTED = "COE_DRIVEN_IDEA_SUBMITTED",
  REUSABLE_COMPONENT_REMOVED = "REUSABLE_COMPONENT_REMOVED",
  SYSTEM_ADMIN_ADDED_TO_USER = "SYSTEM_ADMIN_ADDED_TO_USER",
  PROGRAM_MANAGER_ADDED_TO_USER = "PROGRAM_MANAGER_ADDED_TO_USER",
  CURATOR_ADDED_TO_USER = "CURATOR_ADDED_TO_USER",
  ASSIGNED_SYSTEM_ADMIN = "ASSIGNED_SYSTEM_ADMIN",
  SYSTEM_ADMIN_REMOVED_FROM_USER = "SYSTEM_ADMIN_REMOVED_FROM_USER",
  PROGRAM_MANAGER_REMOVED_FROM_USER = "PROGRAM_MANAGER_REMOVED_FROM_USER",
  CURATOR_REMOVED_FROM_USER = "CURATOR_REMOVED_FROM_USER",
  PROCESS_HIERARCHY_UPDATED = "PROCESS_HIERARCHY_UPDATED",
  APPLICATION_INVENTORY_UPDATED = "APPLICATION_INVENTORY_UPDATED",
  ADMIN_USERS_INFORMATION_UPDATED = "ADMIN_USERS_INFORMATION_UPDATED",
  ASSIGNED_PROGRAM_MANAGER = "ASSIGNED_PROGRAM_MANAGER",
  ASSIGNED_CURATOR = "ASSIGNED_CURATOR",
  REMOVED_AS_SYSTEM_ADMIN = "REMOVED_AS_SYSTEM_ADMIN",
  REMOVED_AS_PROGRAM_MANAGER = "REMOVED_AS_PROGRAM_MANAGER",
  REMOVED_AS_CURATOR = "REMOVED_AS_CURATOR",
  COMPONENT_ARCHIVED = "COMPONENT_ARCHIVED",
  COMPONENT_UPDATED_FOR_DOWNLOADED_USERS = "COMPONENT_UPDATED_FOR_DOWNLOADED_USERS",
  COMPONENT_UPLOADED_FOR_CURATION = "COMPONENT_UPLOADED_FOR_CURATION",
  COMPONENT_CURATION_STARTED = "COMPONENT_CURATION_STARTED",
  COMPONENT_CURATION_REJECT = "COMPONENT_CURATION_REJECT",
  COMPONENT_CURATION_NEEDS_UPDATE = "COMPONENT_CURATION_NEEDS_UPDATE",
  COMPONENT_CURATION_PUBLISH = "COMPONENT_CURATION_PUBLISH",
  FIRST_USER_STANDARD_EMAIL = "FIRST_USER_STANDARD_EMAIL",
  FIRST_USER_TRIAL_EMAIL = "FIRST_USER_TRIAL_EMAIL",
  COMPONENT_DOWNLOADED = "COMPONENT_DOWNLOADED",
  COMPONENT_REVIEW_ADDED = "COMPONENT_REVIEW_ADDED",
  COMPONENT_COMMENT_ADDED = "COMPONENT_COMMENT_ADDED",
  COMPONENT_NEW_REPLY = "COMPONENT_NEW_REPLY",
  COMPONENT_USER_MENTIONED = "COMPONENT_USER_MENTIONED",
  AUTHORIZED_USER_ADDED_TO_USER = "AUTHORIZED_USER_ADDED_TO_USER",
  COE_COLLABORATOR_ADDED_TO_USER = "COE_COLLABORATOR_ADDED_TO_USER",
  ASSIGNED_AUTHORIZED_USER = "ASSIGNED_AUTHORIZED_USER",
  ASSIGNED_COE_COLLABORATOR = "ASSIGNED_COE_COLLABORATOR",
  AUTHORIZED_USER_REMOVED_FROM_USER = "AUTHORIZED_USER_REMOVED_FROM_USER",
  COE_COLLABORATOR_REMOVED_FROM_USER = "COE_COLLABORATOR_REMOVED_FROM_USER",
  REMOVED_AS_AUTHORIZED_USER = "REMOVED_AS_AUTHORIZED_USER",
  REMOVED_AS_COE_COLLABORATOR = "REMOVED_AS_COE_COLLABORATOR",
  CHANGED_PLAN_DEALHUB_EMAIL = "CHANGED_PLAN_DEALHUB_EMAIL",
  CHANGED_PLAN_OWNER_EMAIL = "CHANGED_PLAN_OWNER_EMAIL",
  CHANGED_PLAN_ERROR_EMAIL = "CHANGED_PLAN_ERROR_EMAIL",
  PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED",
  EXTEND_TRIAL_GRANTED = "EXTEND_TRIAL_GRANTED",
  USER_SYNC_FINISHED = "USER_SYNC_FINISHED",
  USER_DELETED = "USER_DELETED",
  PROCESS_COLLABORATOR_COMPONENTS_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_COMPONENTS_EDIT_RIGHTS_GRANTED",
  USER_DEACTIVATED = "USER_DEACTIVATED",
  USER_ACTIVATED = "USER_ACTIVATED",
  COMPONENT_BUG_REPORTED = "COMPONENT_BUG_REPORTED",
  CD_AUTOMATION_SUBMITTED = "CD_AUTOMATION_SUBMITTED",
  CD_AUTOMATION_SHARED = "CD_AUTOMATION_SHARED",
  CD_AUTOMATION_REQUESTED = "CD_AUTOMATION_REQUESTED",
  AUTOMATION_PUBLISHED = "AUTOMATION_PUBLISHED",
  PROCESS_REVIEW_ADDED = "PROCESS_REVIEW_ADDED",
  PROCESS_QUESTION_ADDED = "PROCESS_QUESTION_ADDED",
  PROCESS_QUESTION_REPLY_ADDED = "PROCESS_QUESTION_REPLY_ADDED",
  PROCESS_REVIEW_UPDATED = "PROCESS_REVIEW_UPDATED",
  GROUP_ADDED = "GROUP_ADDED",
  GROUP_DELETED = "GROUP_DELETED",
  GROUP_UPDATED = "GROUP_UPDATED",
  AUTOMATION_CONSUMED = "AUTOMATION_CONSUMED",
  USER_ADDED = "USER_ADDED",
  USER_UPDATED = "USER_UPDATED",
  PROCESS_QUESTION_USER_MENTIONED = "PROCESS_QUESTION_USER_MENTIONED",
  CHANGE_REQUEST_SUBMITTED = "CHANGE_REQUEST_SUBMITTED"
}
