import React, { useRef, useEffect, ReactNode } from "react";
import { InputLabel, FormHelperText } from "@material-ui/core";
import InputRichTextWithLabel from "../InputRichTextWithLabel/InputRichTextWithLabel";
import styled, { css } from "styled-components/macro";
import { ApolloInputLabelWithTooltip } from "../InputTextApollo/InputTextApollo";
import { useTranslation } from "react-i18next";

const InputLabelStyled = styled(InputLabel)``;

const Container = styled.div<{ $error?: any }>(props => {
  // matches apollo inputs, non semantic
  const errorBorderColor = props.theme.colors.error500;
  return css`
    .TextEditorComments__main {
      &:hover {
        border-color: var(
          --color-foreground-de-emp,
          ${props.theme.colors.ink750}
        ) !important;
      }
      &:focus-within {
        border: 2px solid
          var(--color-primary-focused, ${props.theme.colors.blue500}) !important;
        ${props.$error ? `border-color: ${errorBorderColor} !important` : ""}
      }
      ${props.$error ? `border-color: ${errorBorderColor} !important` : ""}
    }

    &:focus-within {
      ${InputLabelStyled} {
        color: ${props.$error
          ? `var(--color-error-text, ${props.theme.colors.error500})`
          : `var(--color-primary-focused, ${props.theme.colors.blue500})`};
      }
    }
  `;
});

const InputRichTextApollo = ({
  InputProps = {},
  inputProps = {},
  label,
  helperText,
  required,
  className,
  error,
  errorCount,
  placeholder = "",
  hideBlockStyling,
  hideInlineStyling,
  readOnly,
  updateValue,
  autoFocus,
  manualUpdate,
  maxLength,
  ariaLabel,
  ariaLabelledBy,
  labelTooltip
}: {
  InputProps: any;
  inputProps?: any;
  label?: any;
  helperText?: any;
  required?: boolean;
  className?: string;
  error?: any;
  errorCount?: number;
  placeholder?: string;
  hideBlockStyling?: boolean;
  hideInlineStyling?: boolean;
  readOnly?: boolean;
  updateValue?: boolean;
  autoFocus?: boolean;
  manualUpdate?: boolean;
  maxLength?: number;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  labelTooltip?: ReactNode;
}) => {
  const { t } = useTranslation("common");
  const { name, value, onChange, input_debounce_ms } = InputProps || {};
  const inputEl = useRef(null);
  maxLength = maxLength || inputProps?.maxLength;

  useEffect(() => {
    if (inputEl && inputEl.current && error) {
      const el: any = inputEl.current;
      el?.scrollIntoView({ block: "center" });
    }
  }, [inputEl, error, errorCount]);

  let richTextAriaLabel = ariaLabel ?? label;
  if (
    !ariaLabelledBy &&
    (!richTextAriaLabel || richTextAriaLabel?.count === 0)
  ) {
    richTextAriaLabel = t(["rich_text_default_aria", "Textfield"]);
  }
  return (
    <Container className={className} ref={inputEl} $error={error}>
      {label ? (
        <InputLabelStyled required={required} error={error}>
          <ApolloInputLabelWithTooltip
            label={label}
            labelTooltip={labelTooltip}
            required={required}
          />
        </InputLabelStyled>
      ) : (
        ""
      )}
      <InputRichTextWithLabel
        value={value}
        onChange={onChange}
        name={name}
        maxLength={maxLength}
        error={error}
        placeholder={placeholder}
        hideBlockStyling={hideBlockStyling}
        hideInlineStyling={hideInlineStyling}
        readOnly={readOnly}
        updateValue={updateValue}
        focusOnLoad={autoFocus}
        manualUpdate={manualUpdate}
        ariaLabel={richTextAriaLabel}
        ariaLabelledBy={ariaLabelledBy}
        input_debounce_ms={input_debounce_ms}
      />
      {helperText ? (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      ) : (
        ""
      )}
    </Container>
  );
};

export default InputRichTextApollo;
