import { customQuestionWithInfo } from "../co-custom-questions.template";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardKPISlugs
} from "../../constants/co-question.constants";
import {
  COConditionSlugs,
  COPermissionSlugs
} from "../../constants/co-constants";

export const customEXKPITemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.EX_DA_AHT_PER_TRANSACTION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_AHT_PER_TRANSACTION,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Detailed Assessment - AHT saved per transaction",
        description:
          "Total manual time estimated to be saved for performing and reviewing one transaction, in case of using the detailed assessment",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_AHT_PER_TRANSACTION
          ),
          co_equation: "",
          co_display_equation: `(${CustomQuestionSlugs.EX_DA_TIME_PER_TRANS_BEFORE} + (${CustomQuestionSlugs.EX_DA_ERROR_RATE_BEFORE} / 100) * ${CustomQuestionSlugs.EX_DA_REWORK_TIME_BEFORE} + (${CustomQuestionSlugs.EX_DA_REVIEW_RATE} / 100) * ${CustomQuestionSlugs.EX_DA_REVIEW_TIME}) - (${CustomQuestionSlugs.EX_DA_AVERAGE_PROCESSING_TIME_AFTER} + (${CustomQuestionSlugs.EX_DA_AVERAGE_ERROR_RATE_AFTER} / 100) * ${CustomQuestionSlugs.EX_DA_AVERAGE_REWORK_TIME_AFTER})`,
          co_question_position_json: {
            sort_order: 800
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_HOURLY_EMPLOYEE_COST,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_HOURLY_EMPLOYEE_COST,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Hourly employee cost",
        description:
          "Hourly cost with the employee performing the activity, based on the detailed assessment data. In case of citizen developer assessment, this data is lacking.",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          visibility_permissions: [COPermissionSlugs.EX_PERMISSIONS]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_HOURLY_EMPLOYEE_COST
          ),
          co_equation: "",
          co_display_equation: `${CustomQuestionSlugs.EX_DA_AVERAGE_EMPLOYEE_FULL_COST} / (${CustomQuestionSlugs.EX_DA_AVERAGE_WORKING_DAYS_PER_YEAR} * ${CustomQuestionSlugs.EX_DA_WORKING_HOURS_PER_DAY})`,
          co_question_position_json: {
            sort_order: 1200
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_MANUAL_VOLUMES_PER_YEAR,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_MANUAL_VOLUMES_PER_YEAR,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Detailed Assessment - Manual volumes (per year)",
        description:
          "Total number of transactions performed manually, in a year (in case of using the detailed assessment)",
        options_meta_overrides: {
          rounding_decimals: 0,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_MANUAL_VOLUMES_PER_YEAR
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.EX_DA_TASK_PROCESS_FREQUENCY} * ${CustomQuestionSlugs.EX_DA_ACTIVITY_VOLUME_AVERAGE} * ((${CustomQuestionSlugs.EX_DA_NUMBER_OF_EMPLOYEES} * ${CustomQuestionSlugs.EX_DA_VOLUMES_FREQUENCY}) + (1 - ${CustomQuestionSlugs.EX_DA_VOLUMES_FREQUENCY})),0)`,
          co_question_position_json: {
            sort_order: 1280
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA_AHT_SAVED_PER_TRANSACTION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA_AHT_SAVED_PER_TRANSACTION,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Citizen Developer Assessment - AHT saved per transaction",
        description:
          "Total manual time saved performing and reviewing one transaction, in case of using the citizen developer assessment.",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA_AHT_SAVED_PER_TRANSACTION
          ),
          co_equation: "",
          co_display_equation: `(${CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_BEFORE} - ${CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_AFTER}) + ((${CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_BEFORE} / 100) * ${CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_BEFORE} - (${CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_AFTER} / 100) * ${CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_AFTER})`,
          co_question_position_json: {
            sort_order: 2100
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA_MANUAL_VOLUMES_PER_YEAR,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA_MANUAL_VOLUMES_PER_YEAR,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Citizen Developer Assessment - Manual volumes (per year)",
        description:
          "Total yearly volumes handled by the employees performing the activity  (in case of using the citizen developer assessment)",
        options_meta_overrides: {
          rounding_decimals: 0,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA_MANUAL_VOLUMES_PER_YEAR
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__VOLUME_AVERAGE} * ${CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__PROCESS_FREQUENCY} * ${CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__NUMBER_OF_PEOPLE},0)`,
          co_question_position_json: {
            sort_order: 2500
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_REFERENCE_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.EX_REFERENCE_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Reference manual time saved per run (min)",
        description:
          "Auto-computed based on values inserted in the Process Volumetry section of the Detailed assessment or the Citizen Developer assessment",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_REFERENCE_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `(${CustomQuestionSlugs.EX_DA_AHT_PER_TRANSACTION} > 0) ? ${CustomQuestionSlugs.EX_DA_AHT_PER_TRANSACTION} : ${CustomQuestionSlugs.EX_CDA_AHT_SAVED_PER_TRANSACTION}`,
          co_question_position_json: {
            sort_order: 300
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Time savings per run",
        description:
          "Auto-computed based on whether the user overridden the Final manual time saved per run or not. Used in Insights for ROI reporting purposes",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `(${CustomQuestionSlugs.EX_FINAL_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS} > 0) ? ${CustomQuestionSlugs.EX_FINAL_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS} : ${CustomQuestionSlugs.EX_REFERENCE_MANUAL_TIME_SAVED_PER_RUN_INSIGHTS}`,
          co_question_position_json: {
            sort_order: 400
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_COST_SAVINGS_PER_RUN_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_COST_SAVINGS_PER_RUN_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Cost savings per run",
        description:
          "Auto-computed from the Employee Profile data and the Final manual time saved per run",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [],
          visibility_permissions: [COPermissionSlugs.EX_PERMISSIONS]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_COST_SAVINGS_PER_RUN_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `${CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS} * ${CustomQuestionSlugs.EX_DA_HOURLY_EMPLOYEE_COST} / 60`,
          co_question_position_json: {
            sort_order: 450
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_YEARLY_AUTOMATED_VOLUMES_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_YEARLY_AUTOMATED_VOLUMES_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Yearly target automated volumes",
        description:
          "Auto-computed based on the manual volumes of transactions and the automation potential %",
        options_meta_overrides: {
          rounding_decimals: 0,
          condition_overrides: []
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_YEARLY_AUTOMATED_VOLUMES_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `((${CustomQuestionSlugs.EX_DA_MANUAL_VOLUMES_PER_YEAR} * ${CustomQuestionSlugs.EX_DETAILED_ASSESSMENT__AUTOMATION_POTENTIAL} / 100) > 0) ? round(${CustomQuestionSlugs.EX_DA_MANUAL_VOLUMES_PER_YEAR} * ${CustomQuestionSlugs.EX_DETAILED_ASSESSMENT__AUTOMATION_POTENTIAL} / 100, 0) : ${CustomQuestionSlugs.EX_CDA_MANUAL_VOLUMES_PER_YEAR}`,
          co_question_position_json: {
            sort_order: 500
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_YEARLY_TARGET_HOURS_SAVED_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_YEARLY_TARGET_HOURS_SAVED_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Yearly target hours saved",
        description:
          "Auto-computed based on the data input per Orchestrator package",
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_YEARLY_TARGET_HOURS_SAVED_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS} * ${CustomQuestionSlugs.EX_YEARLY_AUTOMATED_VOLUMES_INSIGHTS} / 60, 0)`,
          co_question_position_json: {
            sort_order: 600
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_YEARLY_TARGET_COST_SAVINGS_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_YEARLY_TARGET_COST_SAVINGS_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Yearly target cost savings",
        description:
          "Auto-computed based on the data input per Orchestrator package",
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [],
          visibility_permissions: [COPermissionSlugs.EX_PERMISSIONS]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_YEARLY_TARGET_COST_SAVINGS_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.EX_COST_SAVINGS_PER_RUN_INSIGHTS} * ${CustomQuestionSlugs.EX_YEARLY_AUTOMATED_VOLUMES_INSIGHTS},0)`,
          co_question_position_json: {
            sort_order: 700
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_TOTAL_TIME_SAVINGS_PER_RUN_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_TOTAL_TIME_SAVINGS_PER_RUN_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total time savings per run",
        description:
          "Sum of time savings per run from the referenced Orchestrator packages",
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_TOTAL_TIME_SAVINGS_PER_RUN_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `sum(${CustomQuestionSlugs.EX_TIME_SAVINGS_PER_RUN_INSIGHTS})`,
          co_question_position_json: {
            sort_order: 800
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_PER_RUN_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_PER_RUN_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total cost savings per run",
        description:
          "Sum of cost savings per run from the referenced Orchestrator packages",
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [],
          visibility_permissions: [COPermissionSlugs.EX_PERMISSIONS]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_PER_RUN_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `sum(${CustomQuestionSlugs.EX_COST_SAVINGS_PER_RUN_INSIGHTS})`,
          co_question_position_json: {
            sort_order: 900
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_TOTAL_HOURS_SAVED_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_TOTAL_HOURS_SAVED_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total hours saved",
        description:
          "Sum of all yearly target hours saved from the referenced Orchestrator packages",
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_TOTAL_HOURS_SAVED_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `sum(${CustomQuestionSlugs.EX_YEARLY_TARGET_HOURS_SAVED_INSIGHTS})`,
          co_question_position_json: {
            sort_order: 1000
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_INSIGHTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_INSIGHTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Total cost savings",
        description:
          "Sum of all yearly target cost savings from the referenced Orchestrator packages",
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [],
          visibility_permissions: [COPermissionSlugs.EX_PERMISSIONS]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_TOTAL_COST_SAVINGS_INSIGHTS
          ),
          co_equation: "",
          co_display_equation: `sum(${CustomQuestionSlugs.EX_YEARLY_TARGET_COST_SAVINGS_INSIGHTS})`,
          co_question_position_json: {
            sort_order: 1100
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    }
  ];
};
