import {
  COComponentType,
  COHorizontalAlignment
} from "../../../constants/co-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../constants/co-path.constants";
import {
  COMetadataInterface,
  COContextInterface,
  COControlInterface,
  COQuestionInterface,
  COQuestionAnswerOptionInterface,
  COConditionOverrideSlugPayload
} from "../../../interfaces/co-interfaces";
import { confirmRemoveBranchPopUpData } from "./co-section-branching-controls.template";
import { branchConditionSlugs } from "../../../constants/co-question-answer-option.constants";

export const branchingQuestionControlMeta = (): COMetadataInterface => {
  return {
    branching: {
      is_editable: true,
      label: "Question branching logic controls",
      position: { sort_order: 3 },
      options: {
        component_type: COComponentType.META_CONTROL,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_READ_ONLY
        })
      },
      controls: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.FUNCTION_QUESTION_BRANCHING_CONTROLS,
        operator: PATH_OPERATORS.FUNCTION
      }),
      display_context: {
        assessment_customize: 1
      }
    }
  };
};

export const questionBranchingControls = (
  context: COContextInterface
): COControlInterface[] => {
  let controls: COControlInterface[] = [];

  let currentQuestion: COQuestionInterface | undefined = context.question;
  if (!currentQuestion || !currentQuestion.options) {
    return controls;
  }
  let conditions = currentQuestion.options.condition_overrides;
  const branchConditionSlugStrings: string[] = branchConditionSlugs.map(
    cd => cd + ""
  );
  let selectedBranchConditions =
    conditions?.filter(cond =>
      branchConditionSlugStrings.includes(cond.slug ?? "")
    ) || [];
  if (conditions && context) {
    for (const selectedBranchCondition of selectedBranchConditions) {
      const questionTitle: string = questionTitleForBranchingCondition({
        context,
        selectedBranchCondition
      });
      const answerOptionTitles: string[] = answerOptionTitlesForBranchingCondition(
        { context, selectedBranchCondition }
      );

      let ctrl: COControlInterface = {
        options: {
          component_type: COComponentType.CONTROL_BANNER,
          horizontal_alignment: COHorizontalAlignment.RIGHT
        },
        meta: {
          title: {
            value:
              'This question is hidden until the {{allAnswerOptionTitles}} option is selected on the "{{questionTitle}}" question',
            value_localization_key: "branching_paths_banner_question_message",
            value_localization_values: {
              questionTitle: questionTitle,
              allAnswerOptionTitles: {
                values: answerOptionTitles,
                separator: " or ",
                separator_localization_key:
                  "branching_paths_message_or_separator"
              }
            }
          }
        },
        subControls: [
          {
            meta: {
              title: {
                value: "Remove",
                value_localization_key: "branching_paths_banner_remove"
              }
            },
            options: {
              component_type: COComponentType.CONTROL_BUTTON
            },
            controlFunction: ({
              context,
              control,
              option
            }: {
              context: COContextInterface;
              control: COControlInterface;
              option: any;
            }) => {
              // confirm that the user wants to remove the condition
              if (context.function_hooks?.showSwalPopUp) {
                context.function_hooks.showSwalPopUp({
                  context,
                  co_swal_pop_up: confirmRemoveBranchPopUpData,
                  callback: confirmed => {
                    if (
                      confirmed &&
                      currentQuestion?.co_question_options_json
                        ?.condition_overrides &&
                      selectedBranchCondition
                    ) {
                      // we need to remove the condition
                      currentQuestion.co_question_options_json.condition_overrides = currentQuestion.co_question_options_json.condition_overrides.filter(
                        cd => {
                          return !(
                            cd.slug === selectedBranchCondition.slug &&
                            cd.payload?.co_question_ahid ===
                              selectedBranchCondition.payload
                                ?.co_question_ahid &&
                            cd.payload?.co_question_answer_option_ahid ===
                              selectedBranchCondition.payload
                                ?.co_question_answer_option_ahid
                          );
                        }
                      );
                    }
                  }
                });
              }
            }
          }
        ]
      };
      controls.push(ctrl);
    }
  }
  return controls;
};

export const questionTitleForBranchingCondition = ({
  context,
  selectedBranchCondition
}: {
  context: COContextInterface;
  selectedBranchCondition: COConditionOverrideSlugPayload;
}): string => {
  let questionTitle: string = "";
  if (
    context.assessment?.findAHId &&
    selectedBranchCondition.payload?.co_question_ahid
  ) {
    const question = context.assessment.findAHId(
      selectedBranchCondition.payload.co_question_ahid
    ) as COQuestionInterface;
    if (question) {
      questionTitle = question.co_question_meta_json?.title?.value ?? "";
    }
  }
  return questionTitle;
};

export const answerOptionTitlesForBranchingCondition = ({
  context,
  selectedBranchCondition
}: {
  context: COContextInterface;
  selectedBranchCondition: COConditionOverrideSlugPayload;
}): string[] => {
  let answerOptionTitles: string[] = [];
  if (selectedBranchCondition.payload?.co_question_answer_option_ahid) {
    const co_question_answer_option_ahids = selectedBranchCondition.payload.co_question_answer_option_ahid.split(
      ","
    );
    answerOptionTitles = co_question_answer_option_ahids
      .map(ahid => {
        if (context.assessment?.findAHId) {
          const answerOption = context.assessment.findAHId(
            ahid
          ) as COQuestionAnswerOptionInterface;
          if (answerOption) {
            const title =
              answerOption.co_question_answer_option_meta_json?.title?.value ??
              "";
            return '"' + title + '"';
          }
        }
        return "";
      })
      .filter(title => title.length > 0);
  }
  return answerOptionTitles;
};
