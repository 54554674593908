import {
  FETCH_AUTOMATION_COST_DATA,
  CLEAR_AUTOMATION_COST_DATA,
  FETCH_COST_DASHBOARD_FILTER_VALUES,
  SET_COST_DASHBOARD_SEARCH_QUERY,
  SET_COST_DASHBOARD_IS_LOADING_DATA
} from "../constants/actionTypes";

const INITIAL_STATE = {
  automationCostData: null,
  automationCostUpperFilterValues: null,
  searchQuery: "",
  isLoadingAutomations: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AUTOMATION_COST_DATA:
      return {
        ...state,
        automationCostData: action.payload
      };
    case SET_COST_DASHBOARD_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingAutomations: action.payload
      };
    case CLEAR_AUTOMATION_COST_DATA:
      return {
        ...state,
        automationCostData: null
      };
    case FETCH_COST_DASHBOARD_FILTER_VALUES:
      return {
        ...state,
        automationCostUpperFilterValues: action.payload
      };
    case SET_COST_DASHBOARD_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      };
    default:
      return state;
  }
}
