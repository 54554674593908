import React from "react";
import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import { LocalizeTranslationKey } from "../utils/localizedAlertMessages";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../utils/SweetAlertLoaderSpinner";

import {
  FETCH_COMPONENT_PROFILE,
  CLEAR_COMPONENT_PROFILE,
  SET_COMPONENT_REVIEW_SECTION,
  FETCH_SIMILAR_COMPONENTS
} from "../constants/actionTypes";
import ToastSwal from "../components/ToastAutomationPipeline/ToastAutomationPipeline";

export function fetchComponentProfile(slug, version: any = undefined) {
  let path = "/api/v1/components/slug/" + slug;
  if (version) {
    path += version;
  }
  return function(dispatch) {
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_COMPONENT_PROFILE,
          payload: resp.data.data
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchSimilarComponents(slug) {
  let path = `/api/v1/components/slug/${slug}/similar`;
  return function(dispatch) {
    axios
      .get(path)
      .then(resp => {
        dispatch({
          type: FETCH_SIMILAR_COMPONENTS,
          payload: resp.data.data.components
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function clearComponentProfile(slug) {
  return function(dispatch) {
    dispatch({
      type: CLEAR_COMPONENT_PROFILE
    });
  };
}

export function reportComponentBug(slug, data) {
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_saving_report_title"} />
    )
  });
  return function(dispatch) {
    axios
      .post(`/api/v1/components/slug/${slug}/bug`, data)
      .then(response => {
        swalSpinnerClose();
        ToastSwal.fire({
          title: (
            <LocalizeTranslationKey
              localeKey={"info_alert_component_bug_reported_content"}
            />
          )
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function reportComponentAbuse(slug, data) {
  swalSpinnerLoading({
    title: (
      <LocalizeTranslationKey localeKey={"info_alert_saving_report_title"} />
    )
  });
  return function(dispatch) {
    axios
      .post(`/api/v1/components/slug/${slug}/report`, data)
      .then(response => {
        swalSpinnerClose();
        ToastSwal.fire({
          title: (
            <LocalizeTranslationKey
              localeKey={"info_alert_component_abuse_reported_content"}
            />
          )
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function setComponentReviewSection(reviewSection) {
  return function(dispatch) {
    dispatch({
      type: SET_COMPONENT_REVIEW_SECTION,
      payload: reviewSection
    });
  };
}
