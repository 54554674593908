// For Feature Flags

export const EXTEND_TRIAL_LD_KEY = "account-admin-extend-trial";
export const GLOBAL_LOCALIZATION_LD_KEY = "global-localization";
export const TASK_CAPTURE_LD_KEY = "task-capture";
export const TASK_CAPTURE_MAC_LD_KEY = "task-capture-mac-compatibility";
export const LOCALIZATION_TESTING_LD_KEY = "localization-testing";
export const ADMIN_MANAGE_OPEN_API_LD_KEY = "admin-manage-open-api";
export const TENANT_SELECTED_DATE_FORMAT = "tenant-selected-date-format";
export const USERPILOT_KEY = "userpilot-disabled";
export const HOTJAR_KEY = "hotjar-disabled";
export const ADMIN_AUDIT_LOGS_LD_KEY = "audit-log-enabled";
export const AUTOMATION_CLOUD_USER_GROUP_MANAGEMENT_LD_KEY =
  "automation-cloud-user-group-management";
export const IMPORT_PIPELINE_KEY = "import-pipeline";
export const AUTOMATION_STORE_KEY = "automation-store";
export const APOLLO_DARK_THEME_KEY = "apollo-dark-theme";
export const LOCALIZATION_SPANISH_MEXICO_LD_KEY = "localization-spanish-mexico";
export const LOCALIZATION_KOREAN_LD_KEY = "localization-korean";
export const LOCALIZATION_CHINESE_SIMPLIFIED_LD_KEY =
  "localization-chinese-simplified";
export const LOCALIZATION_TURKISH_LD_KEY = "localization-turkish";
export const LOCALIZATION_RUSSIAN_LD_KEY = "localization-russian";
export const LOCALIZATION_PORTUGUESE_PORTUGAL_LD_KEY =
  "localization-portuguese-portugal";
export const USER_PILOT_DARK_THEME_LD_KEY = "user-pilot-dark-theme";
export const AUTOMATION_STORE_OPEN_IN_ASSISTANT_LD_KEY =
  "automation-store-open-in-assistant";
export const ASSISTANT_MAC_COMPATIBILITY_LD_KEY = "assistant-mac-compatibility";
export const TASK_MINING_INTEGRATION_LD_KEY = "task-mining-integration";
export const ORCHESTRATOR_MAPPING_LD_KEY = "orchestrator-mapping-screen";
export const CO_ADVANCED_EDITING_LD_KEY = "co-advanced-editing";
export const CO_ASSESSMENT_EXPORT_IMPORT_LD_KEY = "co-assessment-export-import";
export const CO_DEBUG_LD_KEY = "co-debug";
export const INSIGHTS_PRIVATE_PREVIEW_LD_KEY = "insights-private-preview";
export const TENANT_SIGNUP_MIGRATE_DISABLED_LD_KEY =
  "tenant-signup-migrate-disabled";
export const APOLLO_COMPACT_HEADER_OVERRIDE_LD_KEY =
  "apollo-compact-header-override";
export const JIRA_INTEGRATION_ENABLED_LD_KEY = "jira-integration-enabled";
export const HIDE_USER_GUIDE_AND_SUPPORT_LINK_ENABLED =
  "hide-user-guide-and-support-link-enabled";
export const BULK_DELETE_AUTOMATION_PIPELINE =
  "bulk-delete-automation-pipeline-enabled";
export const APOLLO_PEOPLE_PICKER_ENABLED_LE_KEY =
  "apollo-people-picker-enabled";
export const INSIGHTS_DEBUG_LD_KEY = "insights-debug-enabled";
export const EXECUTION_ASSESSMENT_ENABLED_LD_KEY =
  "execution-assessment-enabled";
