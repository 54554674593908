import { COContextObjectKey } from "../constants/co-constants";
import { modifyAnswerInputForCalculationModifiers } from "../helpers/co-calculation.helper";
import { optionsInContext } from "../helpers/co-context.helper";
import {
  COContextInterface,
  COProcessAnswerInterface,
  COProcessAnswerSelectionDBClass,
  COProcessAnswerSelectionInterface,
  COQuestionAnswerOptionInterface
} from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "../utils/co-utils";
import { COBase } from "./co-base.class";
import { updateContext } from "./co-context.class";
import { COQuestionAnswerOption } from "./co-question-answer-option.class";

export interface COProcessAnswerSelection
  extends COProcessAnswerSelectionInterface {}
export class COProcessAnswerSelection extends COBase {
  static getClassName(): string {
    return "COProcessAnswerSelection";
  }
  constructor({
    propertiesFromJSON,
    context,
    processAnswer,
    answerOption
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    processAnswer?: COProcessAnswerInterface;
    answerOption?: COQuestionAnswerOptionInterface;
  }) {
    super({ propertiesFromJSON });
    this.objectClassMapping = {
      arrays: [],
      objects: [
        {
          objectKey: "co_question_answer_option",
          objectClass: COQuestionAnswerOption
        }
      ],
      dbClass: COProcessAnswerSelectionDBClass,
      contextKey: COContextObjectKey.PROCESS_ANSWER_SELECTION,
      ahid_key: "co_process_answer_selection_ahid",
      objectClass: COProcessAnswerSelection
    };

    // meaning we're creating a new process answer selection - not loading from json
    if (processAnswer && answerOption) {
      this[
        this.objectClassMapping.ahid_key || ""
      ] = COProcessAnswerSelection.generateAHID();

      this.co_process_answer_ahid = processAnswer.co_process_answer_ahid;
      if (context) {
        this.updateAnswer?.(context, answerOption);
        this.initializeDefaultAnswer?.({
          context
        });
      }
    }
  }

  updateAnswer?(
    context: COContextInterface,
    answerOption?: COQuestionAnswerOption
  ) {
    if (answerOption) {
      this.co_question_answer_option = answerOption;
      this.co_question_answer_option_id =
        answerOption.co_question_answer_option_id;
      this.co_question_answer_option_ahid =
        answerOption.co_question_answer_option_ahid;

      if (isNullOrUndefined(this.co_question_answer_option_value)) {
        this.co_question_answer_option_value = "";
      }
      if (isNullOrUndefined(this.co_process_answer_selection_input)) {
        this.co_process_answer_selection_input = "";
      }
    }
  }

  // I don't think we actually need to do the input as value prefil check here - that should already be happening at process-answers level
  prepForSubmissionViewOrEdit?({ context }: { context: COContextInterface }) {
    // ok - let's check to see if we have null answers or answer inputs
    // prefil if null answers - this is for input prefills (not multiple or single choice selection)
    if (this.co_question_answer_option) {
      let optInCtx = optionsInContext({
        context,
        options: context.question?.options || {}
      });
      if (this.co_question_answer_option.calculation_options?.input_is_value) {
        if (isNullOrUndefined(this.co_question_answer_option_value)) {
          let prefill = false;
          if (context.question?.options) {
            let pre_fill_default_answer = optInCtx.pre_fill_default_answer;
            if (pre_fill_default_answer) {
              if (
                !isNullOrUndefined(
                  context.question?.co_question_meta_json?.prefill?.value
                )
              ) {
                prefill = true;
              }
            }
          }
          if (prefill) {
            console.log(
              "prefilling where I don't think it's necessary in co-process-answer-selection"
            );
            this.co_question_answer_option_value =
              context.question?.co_question_meta_json?.prefill?.value;
          } else {
            this.co_question_answer_option_value = "";
          }
        }
        if (isNullOrUndefined(this.co_process_answer_selection_input)) {
          this.co_process_answer_selection_input = "";
        }
      }
    }
  }

  // handle prefill logic, moved here for better organization
  initializeDefaultAnswer?({ context }) {
    // now let's see if there is actually a prefill at this level - the above is for the question level prefilling
    // this is for prefilling at the question answer option level
    if (this.co_question_answer_option) {
      let unresolvedOptions = this.co_question_answer_option.options || {};
      // functions can't be copied in deep copy
      let ansOptInCtx = optionsInContext({
        context,
        options: unresolvedOptions
      });
      if (
        ansOptInCtx.pre_fill_default_answer &&
        !isNullOrUndefined(
          unresolvedOptions.pre_fill_default_answer_function
        ) &&
        typeof unresolvedOptions.pre_fill_default_answer_function === "function"
      ) {
        unresolvedOptions.pre_fill_default_answer_function(
          updateContext(context, {
            answer_option: this.co_question_answer_option,
            process_answer_selection: this
          })
        );
      }
    }
  }

  static prepareForCalculation(
    context: COContextInterface,
    co_object: COProcessAnswerSelection
  ) {
    super.prepareForCalculation(context, co_object);
    if (co_object) {
      if (co_object.co_question_answer_option) {
        if (
          co_object.co_question_answer_option.calculation_options
            ?.input_is_value
        ) {
          let value = co_object.co_process_answer_selection_input;

          value = modifyAnswerInputForCalculationModifiers({
            context,
            question: context.question || {},
            value
          });

          co_object.co_process_answer_selection_input = value;

          co_object.co_question_answer_option_value =
            co_object.co_process_answer_selection_input;
        } else {
          co_object.co_question_answer_option_value =
            co_object.co_question_answer_option.co_question_answer_option_value;
        }
      }
    }
  }
}
