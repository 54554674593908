import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SessionHelper from "../../helpers/SessionHelper";
import moment from "moment";
import {
  LANGUAGE_COOKIE_NAME,
  EN,
  JA,
  DE,
  FR,
  ES,
  PT_BR,
  PT,
  RU,
  TR,
  ES_MX,
  KO,
  ZH_CN
} from "../../constants/localizationConstants";
import styled, { css } from "styled-components/macro";
import { featureFlag } from "../../helpers/FeatureFlag";
import {
  getUserLanguageSettingsData,
  editUserSettings,
  USER_SETTINGS_TYPE_LANGUAGE,
  LanguageSetting
} from "../../utils/apiUtils/userSettingsApiUtils";
import IconApollo from "../IconApollo/IconApollo";
import { setLanguagePropertyForPage } from "./utils/languageUtils";

// trigger resize event after callstack clears so responsive nav can test sizing
export const afterLanguageChangeCallback = () =>
  setTimeout(() => window?.dispatchEvent?.(new Event("resize")), 0);

const LanguageLabel = styled.button`
  color: var(--color-foreground);
  background: transparent;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 24px 4px 6px;
`;

const IconApolloStyled = styled(IconApollo)<{ $areOptionsOpen?: boolean }>(
  props => css`
    && {
      margin-right: 1px;
      transition: 0.3s;
      background: transparent;
      ${props.$areOptionsOpen ? `transform: rotate(90deg)` : ""}
    }
  `
);

const LanguageOptionsContainer = styled.div<{ $areOptionsOpen?: boolean }>(
  props => css`
    ${props.$areOptionsOpen ? "" : "display: none;"}
    max-height: 200px;
    overflow: auto;
  `
);

const LanguageOption = styled.button<{ $selected?: boolean }>(
  props => css`
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    color: inherit;
    text-decoration: none;
    color: var(--color-foreground);
    background: var(--color-background);
    padding: 6px 32px;
    text-align: left;
    transition: 0.3s;

    &:hover {
      background-color: var(--color-hover);
      transition: 0.3s;
    }

    ${props.$selected
      ? // @ts-ignore
        css`
          color: var(--color-selection, ${props.theme.colors.orange500});

          &::after {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            background: var(--color-selection, ${props.theme.colors.orange500});
            border-radius: 100%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 16px;
          }
        `
      : ""}
  `
);

const LanguagePicker = () => {
  const { t, i18n } = useTranslation("common");
  const [
    currentLanguageSetting,
    setCurrentLanguageSetting
  ] = useState<null | LanguageSetting>(null);
  const user = SessionHelper.currentUser() || {};
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);

  useEffect(() => {
    if (user.user_profile_slug) {
      getCurrentLanguageSetting(user.user_profile_slug);
    }
  }, [user.user_profile_slug]);

  const getCurrentLanguageSetting = async userSlug => {
    try {
      const option = await getUserLanguageSettingsData(userSlug);
      if (option) {
        setCurrentLanguageSetting(option);
      }
    } catch (e) {
      // This can fail silently
      console.log(e);
    }
  };

  const languageOptions = [
    { label: "English", value: EN },
    { label: "日本語", value: JA }
  ];

  if (featureFlag.isLocalizationTestingEnabled()) {
    languageOptions.push({ label: "Keys", value: "keys" });
  }

  if (featureFlag.isLocalizationGermanEnabled()) {
    languageOptions.push({ label: "Deutsch", value: DE });
  }

  if (featureFlag.isLocalizationSpanishEnabled()) {
    languageOptions.push({ label: "Español", value: ES });
  }

  if (featureFlag.isLocalizationSpanishMexicoEnabled()) {
    languageOptions.push({ label: "Español (México)", value: ES_MX });
  }

  if (featureFlag.isLocalizationFrenchEnabled()) {
    languageOptions.push({ label: "Français", value: FR });
  }

  if (featureFlag.isLocalizationKoreanEnabled()) {
    languageOptions.push({ label: "한국어", value: KO });
  }

  if (featureFlag.isLocalizationPortugueseEnabled()) {
    languageOptions.push({ label: "Português", value: PT });
  }

  if (featureFlag.isLocalizationPortugueseBrazilEnabled()) {
    languageOptions.push({ label: "Português (Brasil)", value: PT_BR });
  }

  if (featureFlag.isLocalizationRussianEnabled()) {
    languageOptions.push({ label: "Pусский", value: RU });
  }

  if (featureFlag.isLocalizationTurkishEnabled()) {
    languageOptions.push({ label: "Türkçe", value: TR });
  }

  if (featureFlag.isLocalizationChineseSimplifiedEnabled()) {
    languageOptions.push({ label: "中文(简体)", value: ZH_CN });
  }

  const updateBackendWithLanguageChange = async lang => {
    if (currentLanguageSetting && typeof currentLanguageSetting === "object") {
      const userSlug = user.user_profile_slug;
      const userId = user.user_id;
      const optionData = { ...currentLanguageSetting };
      optionData.user_id = userId;
      optionData.user_setting_value = lang;

      try {
        await editUserSettings(
          userSlug,
          { user_settings: [optionData] },
          USER_SETTINGS_TYPE_LANGUAGE
        );
      } catch (e) {
        // This can fail silently
        console.log(e);
      }
    }
  };

  const handleLanguageChange = value => {
    if (!value) {
      return;
    }

    SessionHelper.setDataForKey(value, LANGUAGE_COOKIE_NAME);
    moment.locale(value);
    i18n.changeLanguage(value, afterLanguageChangeCallback);
    SessionHelper.updateUserPilot();
    updateBackendWithLanguageChange(value);
    setLanguagePropertyForPage(value);
  };

  let currentLanguageLabel = "English";
  const renderedLanguageOptions = languageOptions.map(option => {
    let isSelected = false;
    if (option.value === (i18n.language || EN)) {
      isSelected = true;
      currentLanguageLabel = option.label;
    }
    return (
      <LanguageOption
        key={option.value}
        onClick={() => {
          handleLanguageChange(option.value);
        }}
        $selected={isSelected}
      >
        {option.label}
      </LanguageOption>
    );
  });

  if (featureFlag.isLocalizationEnabled() && !SessionHelper.isFpsUri()) {
    return (
      <>
        <LanguageLabel
          onClick={e => {
            setAreOptionsOpen(!areOptionsOpen);
            e.stopPropagation(); // Stop event propagation so dropdown isnt closed when clicked
          }}
        >
          <IconApolloStyled
            fontSize="small"
            icon="arrow_right"
            $areOptionsOpen={areOptionsOpen}
          />
          {t(
            [
              "language_picker_language_label",
              `Language: ${currentLanguageLabel}`
            ],
            { selectedLanguage: currentLanguageLabel }
          )}
        </LanguageLabel>
        <LanguageOptionsContainer $areOptionsOpen={areOptionsOpen}>
          {renderedLanguageOptions}
        </LanguageOptionsContainer>
      </>
    );
  } else {
    return null;
  }
};

export default LanguagePicker;
