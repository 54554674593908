import { COTaskActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COTaskActionSlugs,
  COTaskType,
  COTaskActivityType
} from "../../../../../constants/actions/co-task-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import { COMPONENT_PROFILE_URL } from "../../../../../constants/actions/co-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";

export const supportTaskActionTemplate = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  // get template based on slug
  let template = supportTaskActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each task
    template.co_options_json = {
      task_type: COTaskType.SUPPORT,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const supportTaskActionTemplateFromSlug = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  switch (slug) {
    case COTaskActionSlugs.GO_SUPPORT_REQUEST_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_task_id: 15,
          task_activity_type_id: 15,
          task_activity_type: COTaskActivityType.GO_SUPPORT_REQUEST,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_SUPPORT_REQUESTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component support request"
          },
          message: {
            value: "Support request"
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.GO_ABUSE_REPORTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN]
          },
          legacy_task_id: 16,
          task_activity_type_id: 16,
          task_activity_type: COTaskActivityType.GO_ABUSE_REPORTED,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_ABUSE_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component abuse reported"
          },
          message: {
            value: "Review an abuse"
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.GO_REVIEW_ABUSE_REPORTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN]
          },
          legacy_task_id: 24,
          task_activity_type_id: 19,
          task_activity_type: COTaskActivityType.GO_REVIEW_ABUSE_REPORTED,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type:
            COPlatformEventType.COMPONENT_REVIEW_ABUSE_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component review abuse reported"
          },
          message: {
            value: "Review an abuse in a component review"
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.GO_COMMENT_ABUSE_REPORTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SYSTEM_ADMIN]
          },
          legacy_task_id: 27,
          task_activity_type_id: 20,
          task_activity_type: COTaskActivityType.GO_COMMENT_ABUSE_REPORTED,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type:
            COPlatformEventType.COMPONENT_ABUSE_COMMENT_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Component comment abuse reported"
          },
          message: {
            value: "Review an abuse in a component comment"
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
