import { COComponentType } from "../../../constants/co-constants";
import {
  COMetadataInterface,
  COOptionsInterface
} from "../../../interfaces/co-interfaces";
import {
  PATH_OPERATORS,
  PATH,
  PATH_ROUTE
} from "../../../constants/co-path.constants";
import {
  addMultipleAnswersControls,
  answerPickerControl
} from "../controls/co-controls.template";

export const titleQuestionTemplateMeta = (
  {
    is_editable = true
  }: {
    is_editable: boolean;
  } = { is_editable: true }
): COMetadataInterface => {
  return {
    title: {
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        }),
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_TABULAR
        })
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: "",
          is_editable: is_editable,
          label: "Question",
          label_localization_key: "co_meta_editable_text_label",
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "",
          placeholder: "Prompt your users with a question",
          placeholder_localization_key: "co_meta_editable_text_placeholder"
        },
        assessment_submission: {
          required: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION,
            operator: PATH_OPERATORS.INVERSE
          })
        }
      }
    },
    note: {
      options: { component_type: COComponentType.META_TEXT },
      display_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.NOTE.VALUE
      })
    }
  };
};

export const titleTabularQuestionTemplateMeta = (
  {
    is_editable = true
  }: {
    is_editable: boolean;
  } = { is_editable: true }
): COMetadataInterface => {
  return {
    tabular_title: {
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        }),
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_TABULAR,
          operator: PATH_OPERATORS.INVERSE
        })
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: "",
          is_editable: is_editable,
          label: "Column title",
          label_localization_key: "co_control_column_question_title",
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "",
          placeholder: "Prompt your users with a question",
          placeholder_localization_key: "co_meta_editable_text_placeholder"
        },
        assessment_submission: {
          required: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION,
            operator: PATH_OPERATORS.INVERSE
          })
        }
      }
    },
    note: {
      options: { component_type: COComponentType.META_TEXT },
      display_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.NOTE.VALUE
      })
    }
  };
};

export const titleApplicationQuestionTemplateMeta = (): COMetadataInterface => {
  return {
    title: {
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: "",
          is_editable: true,
          label: "Application Question",
          label_localization_key: "co_meta_options_title",
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "This question references an Application Picker",
          tooltip_localization_key: "co_meta_options_tooltip",
          placeholder: ""
        },
        assessment_submission: {
          required: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION,
            operator: PATH_OPERATORS.INVERSE
          })
        }
      }
    }
  };
};

export const titleProcessPropertyQuestionTemplateMeta = (): COMetadataInterface => {
  return {
    title: {
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: "",
          is_editable: true,
          label: "Property",
          label_localization_key: "co_questions_title_process_property",
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "This question references a property of the process",
          tooltip_localization_key:
            "co_questions_title_process_property_tooltip",
          placeholder: ""
        },
        assessment_submission: {
          required: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION,
            operator: PATH_OPERATORS.INVERSE
          })
        }
      }
    }
  };
};

export const titleUserQuestionTemplateMeta = (): COMetadataInterface => {
  return {
    title: {
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: "",
          is_editable: true,
          label: "User",
          label_localization_key: "co_questions_question_type_user",
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "This question references a user",
          tooltip_localization_key: "co_questions_question_type_user_tooltip",
          placeholder: ""
        },
        assessment_submission: {
          required: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.OPTIONAL_FOR_SUBMISSION,
            operator: PATH_OPERATORS.INVERSE
          })
        }
      }
    }
  };
};

export const descriptionQuestionTemplateMeta = ({
  is_editable = true
}: {
  is_editable?: boolean;
} = {}): COMetadataInterface => {
  return {
    description: {
      is_editable: is_editable,
      label: "Description",
      label_localization_key: "co_meta_editable_paragraph_text_label",
      position: { sort_order: 1 },
      options: {
        component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      tooltip: "",
      placeholder: "This text will appear in a tooltip next to the question",
      placeholder_localization_key:
        "co_meta_editable_paragraph_text_placeholder",

      display_context: {
        assessment_customize: 1
      }
    }
  };
};
export const variableQuestionTemplateMeta = ({
  is_editable = true
}: {
  is_editable?: boolean;
} = {}): COMetadataInterface => {
  return {
    variable: {
      is_editable: is_editable,
      label: "Variable name",
      label_localization_key: "co_meta_variable_name_label",
      position: { sort_order: 2 },
      options: {
        component_type: COComponentType.META_EDITABLE_H2_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        }),
        optional_for_submission: 0
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
      }),
      tooltip:
        "The variable name will be used in the formulas. E.g.: HOURS_SAVED",
      tooltip_localization_key: "co_meta_variable_name_tooltip",
      placeholder: "",
      display_context: {
        assessment_customize: 1
      }
    }
  };
};
export const prefillQuestionTemplateMeta = ({
  is_editable = true
}: {
  is_editable?: boolean;
} = {}): COMetadataInterface => {
  return {
    prefill: {
      is_editable: is_editable,
      label: "Pre-filled Answer",
      label_localization_key: "co_meta_prefill_answer_label",
      position: { sort_order: 3 },
      options: {
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.PRE_FILL_DEFAULT_ANSWER,
          operator: PATH_OPERATORS.INVERSE
        }),
        component_type: COComponentType.META_EDITABLE_H2_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.PREFILL.VALUE
      }),
      tooltip: "",
      placeholder: "Answer",
      placeholder_localization_key: "co_meta_prefill_answer_placeholder",
      display_context: {
        assessment_customize: 1
      }
    }
  };
};

export const prefillOptionSingleChoiceQuestionTemplateMeta = (): COMetadataInterface => {
  return prefillOptionQuestionTemplateMeta({ max_selectable_answers: 1 });
};

export const prefillOptionMultipleChoiceQuestionTemplateMeta = (): COMetadataInterface => {
  return prefillOptionQuestionTemplateMeta({
    max_selectable_answers: Number.MAX_VALUE
  });
};

const prefillOptionQuestionTemplateMeta = (
  options: COOptionsInterface
): COMetadataInterface => {
  return {
    prefill: {
      is_editable: true,
      label: "Pre-filled Answer",
      label_localization_key: "co_meta_prefill_answer_label",
      position: { sort_order: 3 },
      options: {
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.PRE_FILL_DEFAULT_ANSWER,
          operator: PATH_OPERATORS.INVERSE
        }),
        component_type: COComponentType.META_CONTROL,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.PREFILL.VALUE
      }),
      tooltip: "",
      controls: [
        ...answerPickerControl({
          target: PATH({
            route:
              PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.PREFILL.VALUE
          }),
          meta: {
            title: {
              value: "Choose Prefilled Answer",
              value_localization_key: "co_control_prefill_questions_selection"
            }
          },
          position: {},
          options: options
        })
      ],
      display_context: {
        assessment_customize: 1
      }
    }
  };
};

export const answerChoiceQuestionAnswerOptionMeta = (): COMetadataInterface => {
  return {
    title_header: {
      options: {
        component_type: COComponentType.META_LABEL_TEXT
      },
      value: "Answer options", //not editable
      value_localization_key: "co_meta_answer_option_label",
      display_context: {
        assessment_customize: 1
      }
    },
    title: {
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.ANSWER_OPTION.CO_QUESTION_ANSWER_OPTION_META_JSON
            .TITLE.VALUE
      }),
      display_context_overrides: {
        assessment_customize: {
          placeholder: "Answer option",
          placeholder_localization_key: "co_meta_editable_answer_placeholder",
          options: { component_type: COComponentType.META_EDITABLE_TEXT },
          is_editable: true
        }
      }
    },
    answer_value_header: {
      options: {
        component_type: COComponentType.META_LABEL_TEXT,
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
          operator: PATH_OPERATORS.INVERSE
        }),
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: "Answer score", // not editable
      value_localization_key: "co_meta_answer_score_option_label",
      tooltip: "The value used in the calculation",
      tooltip_localization_key: "co_meta_editable_answer_score_tooltip",
      display_context: {
        assessment_customize: 1
      }
    },
    answer_value: {
      is_editable: true,
      options: {
        component_type: COComponentType.META_EDITABLE_TEXT,
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
          operator: PATH_OPERATORS.INVERSE
        }),
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.CO_QUESTION_ANSWER_OPTION_VALUE
      }),

      display_context: {
        assessment_customize: 1
      }
    }
  };
};

export const textAnswerOptionMeta = (): COMetadataInterface => {
  return {
    answer_value: {
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      },
      display_context_overrides: {
        assessment_submission: {
          is_editable: true,
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            max_answer_length: PATH({
              route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
            })
          },
          placeholder: "Enter answer",
          placeholder_localization_key: "co_meta_editable_text"
        }
      }
    }
  };
};

export const richTextAnswerOptionMeta = (): COMetadataInterface => {
  return {
    answer_value: {
      options: {
        component_type: COComponentType.META_PARAGRAPH_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      },
      display_context_overrides: {
        assessment_submission: {
          is_editable: true,
          options: {
            component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT,
            max_answer_length: PATH({
              route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
            })
          },
          placeholder: "Enter answer",
          placeholder_localization_key: "co_meta_editable_text"
        }
      }
    }
  };
};

export const numberAnswerOptionMeta = (): COMetadataInterface => {
  return {
    prefix: {
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.INPUT_VALUE_FORMAT.PREFIX
      }),
      display_context: {
        assessment_submission: 1
      }
    },
    answer_value: {
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.DISPLAY_VALUE_FORMAT
      }),
      display_context_overrides: {
        assessment_submission: {
          format: "",
          is_editable: true,
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            max_answer_length: PATH({
              route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_ANSWER_LENGTH
            })
          },
          placeholder: "0"
        }
      }
    },
    suffix: {
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.INPUT_VALUE_FORMAT.SUFFIX
      }),
      display_context: {
        assessment_submission: 1
      }
    }
  };
};

export const kpiMeta = (): COMetadataInterface => {
  return {
    title: {
      options: {
        component_type: COComponentType.META_LABEL_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      display_context_overrides: {
        assessment_customize: {
          is_editable: true,
          label: "KPI title",
          label_localization_key: "co_meta_kpi_title_label",
          position: { sort_order: 0 },
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "",
          placeholder: "Enter KPI title",
          placeholder_localization_key: "co_meta_kpi_title_placeholder"
        },
        assessment_view: {
          format: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
          })
        },
        assessment_submission: {
          format: PATH({
            route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
          })
        },
        assessment_customize_modal: {
          options: {
            is_hidden: 1
          }
        }
      }
    },
    note: {
      options: { component_type: COComponentType.META_TEXT },
      display_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.NOTE.VALUE
      })
    },
    description: {
      is_editable: true,
      label: "Description",
      label_localization_key: "co_meta_kpi_description_label",
      position: { sort_order: 1 },
      options: {
        component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      tooltip: "",
      placeholder: "This text will appear in a tooltip next to the KPI title",
      placeholder_localization_key: "co_meta_kpi_description_placeholder",
      display_context: {
        assessment_customize: 1
      },
      display_context_overrides: {
        assessment_customize_modal: {
          options: {
            is_hidden: 1
          }
        }
      }
    },
    variable: {
      is_editable: true,
      label: "Variable name",
      label_localization_key: "co_meta_kpi_variable_name_label",
      position: { sort_order: 2 },
      options: {
        component_type: COComponentType.META_EDITABLE_H2_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        }),
        optional_for_submission: 0
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
      }),
      tooltip:
        "The variable name will be used in the formulas. E.g.: HOURS_SAVED",
      tooltip_localization_key: "co_meta_kpi_variable_name_tooltip",
      placeholder: "",
      display_context: {
        assessment_customize: 1
      },
      display_context_overrides: {
        assessment_customize_modal: {
          options: {
            is_hidden: 1
          }
        }
      }
    },
    equation: {
      is_editable: true,
      label: "Formula",
      label_localization_key: "co_meta_formula_label",
      position: { sort_order: 1 },
      options: {
        component_type: COComponentType.META_EDITABLE_TEXT,
        multi_line: 1,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        }),
        optional_for_submission: 0
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_DISPLAY_EQUATION
      }),
      tooltip: "This is what is used to calculate the KPI",
      tooltip_localization_key: "co_meta_formula_tooltip",
      placeholder: "",
      autocomplete: {
        options: PATH({
          route:
            PATH_ROUTE.CONTEXT.QUESTION
              .FUNCTION_OTHER_QUESTIONS_VARIABLES_DROPDOWN_OPTIONS,
          operator: PATH_OPERATORS.FUNCTION
        })
      },
      display_context: {
        assessment_customize: 1
      }
    },
    variable_configuration: {
      options: {
        component_type: COComponentType.META_VARIABLE_CONFIG,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      display_context: {
        assessment_customize: 1
      }
    },
    calculated_value: {
      label: "Calculated value",
      label_localization_key: "co_meta_display_value_label",
      options: { component_type: COComponentType.META_TEXT },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.DISPLAY_VALUE_FORMAT
      }),
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_PROCESS_CALCULATED_VALUE
            .CO_CALCULATED_VALUE
      }),
      placeholder: "will - be - calculated",
      placeholder_localization_key: "co_meta_display_value_placeholder",
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      }
    }
  };
};

export const kpiChartMeta = (): COMetadataInterface => {
  return {
    title: {
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      options: {
        component_type: COComponentType.META_LABEL_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      tooltip: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.HELP_TEXT
      }),
      help_text: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.HELP_TEXT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: undefined,
          is_editable: true,
          label: "Chart title",
          label_localization_key: "co_meta_chart_title_label",
          position: { sort_order: 0 },
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "This is the name of the KPI Chart",
          tooltip_localization_key: "co_meta_chart_title_tooltip",
          placeholder: "Enter Chart Title",
          placeholder_localization_key: "co_meta_chart_title_placeholder"
        }
      }
    },
    description: {
      options: {
        component_type: COComponentType.META_LABEL_TEXT,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART.TITLE.VALUE
      }),
      display_context_overrides: {
        assessment_customize: {
          is_editable: true,
          label: "Chart Subtitle",
          label_localization_key: "co_meta_chart_subtitle_label",
          position: { sort_order: 0 },
          options: { component_type: COComponentType.META_EDITABLE_TEXT },
          placeholder: "Enter Chart Subtitle",
          placeholder_localization_key: "co_meta_chart_subtitle_placeholder"
        }
      }
    },
    chart: {
      options: {
        component_type: COComponentType.KPI_CHART
      },
      display_context: {
        assessment_customize: 1
      }
    }
  };
};

export const multiAnswerMeta = (): COMetadataInterface => {
  return {
    controls: {
      options: {
        component_type: COComponentType.META_CONTROL,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      display_context: {
        assessment_submission: 1
      },
      value: "",
      controls: [...addMultipleAnswersControls()]
    }
  };
};

export const dateAnswerOptionMeta = (): COMetadataInterface => {
  return {
    answer_value: {
      options: {
        component_type: COComponentType.META_EPOCH_DATE_VIEW,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      },
      display_context_overrides: {
        assessment_submission: {
          is_editable: true,
          options: { component_type: COComponentType.META_EPOCH_DATE_INPUT },
          placeholder: "",
          placeholder_localization_key: ""
        }
      }
    }
  };
};

export const processLinkAnswerOptionMeta = (): COMetadataInterface => {
  return {
    answer_value: {
      options: {
        component_type: COComponentType.META_PROCESS_LINK_VIEW,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      },
      display_context_overrides: {
        assessment_submission: {
          is_editable: true,
          options: { component_type: COComponentType.META_PROCESS_LINK_EDIT },
          placeholder: "",
          placeholder_localization_key: ""
        }
      }
    }
  };
};

export const orchestratorPackageMappingAnswerOptionMeta = (): COMetadataInterface => {
  return {
    answer_value: {
      options: {
        component_type: COComponentType.META_ORCHESTRATOR_PACKAGE_MAPPING_VIEW,
        is_read_only: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.IS_READ_ONLY
        })
      },
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
            .CO_PROCESS_ANSWER_SELECTION_INPUT
      }),
      display_context: {
        assessment_submission: 1,
        assessment_view: 1
      },
      display_context_overrides: {
        assessment_submission: {
          is_editable: true,
          options: {
            component_type:
              COComponentType.META_ORCHESTRATOR_PACKAGE_MAPPING_INPUT
          },
          placeholder: "",
          placeholder_localization_key: ""
        }
      }
    }
  };
};

export const titleReferenceTemplateMeta = (): COMetadataInterface => {
  return {
    title: {
      tooltip: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.DESCRIPTION.VALUE
      }),
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.TITLE.VALUE
      }),
      options: {
        component_type: COComponentType.META_TEXT,
        is_read_only: 0
      },
      format: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.UNIT.TITLE_FORMAT
      }),
      display_context_overrides: {
        assessment_customize: {
          format: "",
          is_editable: false,
          label: "Reference",
          label_localization_key: "co_item_label_reference",
          options: {
            component_type: COComponentType.META_EDITABLE_TEXT,
            optional_for_submission: 0
          },
          tooltip: "",
          placeholder: "Reference a variable from a different assessment type",
          placeholder_localization_key:
            "co_item_title_placeholder_reference_a_value_from_different_assessment"
        }
      }
    },
    note: {
      options: { component_type: COComponentType.META_TEXT },
      display_context: {
        assessment_customize: 1
      },
      value: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_META_JSON.NOTE.VALUE
      })
    }
  };
};
