import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL,
  AUTOMATION_PIPELINE_URL,
  AUTOMATION_PROFILE_COLLABORATORS_URL,
  AUTOMATION_PROFILE_DOCUMENTATION_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const taskAssignedNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = taskAssignedNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.TASK_ASSIGNED,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const taskAssignedNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.REVIEW_APPROVED_IDEA: {
      return {
        co_options_json: {
          is_deleted: 1,
          is_inactive: 1,
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER]
          },
          legacy_notification_id: 16,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.REVIEW_APPROVED_IDEA,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review approved idea in order to launch assessment"
          },
          message: {
            value:
              "The automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " was approved by " +
              COActionReplacementStrings.USER_FULL_NAME +
              ". It is your turn to take a look and launch the assessment for it."
          },
          redirect_url: {
            value: AUTOMATION_PIPELINE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ASSESSMENT_LAUNCHED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER]
          },
          legacy_notification_id: 24,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.ASSESSMENT_IN_PROGRESS
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSESSMENT_LAUNCHED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessment launched"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " invited you to fill in the Advanced information and the applications used section in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ", where you were assigned as a Process Owner."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.REVIEW_ASSESSED_AUTOMATION: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 29,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.REVIEW_ASSESSED_AUTOMATION,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Review assessed automation"
          },
          message: {
            value:
              "The automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " was assessed. It is time to review it with the Automation Advisory Board."
          },
          redirect_url: {
            value: AUTOMATION_PIPELINE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ASSIGN_PROJECT_MANAGER: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 34,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ASSIGN_PROJECT_MANAGER,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assign Project manager"
          },
          message: {
            value:
              "The automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " was approved for implementation. You are required to assign a Project manager."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROJECT_MANAGER_ASSIGNED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 41,
          platform_event_type: COPlatformEventType.PROJECT_MANAGER_ASSIGNED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROJECT_MANAGER_ASSIGNED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Project manager assigned"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " assigned you as a Project Manager in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ". You can add other collaborators and update the phase and status of the automation."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ABOUT_EDITING_RIGHTS_GRANTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 48,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.EDITING_ABOUT_GRANTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Editing rights for About page granted to a collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " granted you editing rights for the 'About' section in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ". You can update the information in that page."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.DOCUMENTATION_EDITING_RIGHTS_GRANTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 49,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_DOCUMENTATION_EDIT_RIGHTS_GRANTED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.EDITING_DOCUMENTATION_GRANTED,
          settings_ids: [25],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value:
              "Editing rights for Documentation page granted to a collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " granted you editing rights for the 'Documentation' section in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ". You can add documents in that page."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_DOCUMENTATION_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ABUSE_REPORTED_AP: {
      return {
        co_options_json: {
          is_deleted: 1,
          is_inactive: 1,
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_notification_id: 74,
          platform_event_type: COPlatformEventType.PROCESS_ABUSE_REPORTED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ABUSE_REPORTED_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Abuse reported"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " reported the abuse '" +
              COActionReplacementStrings.PROCESS_ABUSE_TITLE +
              "' in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ": '" +
              COActionReplacementStrings.PROCESS_ABUSE_DESCR +
              "'."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
