export const COMPONENT_FILE = "component_file";
export const COMPONENT_MEDIA = "component_media";
export const COMPONENT_MANUAL = "component_manual";
export const COMPONENT_SOURCE_CODE = "component_source_code";
export const PROCESS_MEDIA = "process_media";
export const PROCESS_TEMPLATE = "process_template";
export const PROCESS_DOCUMENT = "process_document";
export const PROFILE_IMAGE = "profile_image";
export const ADMIN_DOCUMENT_TEMPLATE = "admin_document_template";

export const ALLOWED_FILE_TYPES_DEFAULT = [
  "jpg",
  "jpeg",
  "gif",
  "png",
  "txt",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "csv",
  "xls",
  "xlsx",
  "xlsm",
  "pdf",
  "ssp",
  "zip",
  "rar",
  "vsdx",
  "xaml",
  "nupkg",
  "bmp"
];

export const ALLOWED_FILE_TYPES_EXECUTABLE = ["bat", "exe"];

export const PACKAGE_DOCUMENT_MAX_UPLOAD_SIZE = 50 * 1024 * 1024; // 50MB
export const SOURCE_CODE_DOCUMENT_MAX_UPLOAD_SIZE = 50 * 1024 * 1024; // 50MB
