import {
  FETCH_ALL_PROCESS_DOCUMENT_TYPES,
  UPLOAD_PROCESS_DOCUMENT_SUCCESSFUL,
  FETCH_ALL_PROCESS_DOCUMENTS,
  SET_PROCESS_DOCUMENT_TO_UPDATE
} from "../constants/actionTypes";

const INITIAL_STATE = {
  documentTypes: {},
  documentUploadSuccessful: false,
  documents: null,
  processDocumentToUpdate: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_PROCESS_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload
      };
    case UPLOAD_PROCESS_DOCUMENT_SUCCESSFUL:
      return {
        ...state,
        documentUploadSuccessful: action.payload
      };
    case FETCH_ALL_PROCESS_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      };
    case SET_PROCESS_DOCUMENT_TO_UPDATE:
      return {
        ...state,
        processDocumentToUpdate: action.payload
      };
    default:
      return state;
  }
}
