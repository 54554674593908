import React from "react";
import "./SignupTenantInputDropdown.scss";
import SignupTenantInputWrapper from "../SignupTenantInputWrapper/SignupTenantInputWrapper";
import ReactSelectApollo from "../../../../components/ReactSelectApollo/ReactSelectApollo";
import { useTranslation } from "react-i18next";

const SignupTenantInputDropdown = props => {
  let {
    handleChange,
    name,
    value,
    editDropdownData = [],
    label,
    className,
    disabled
  } = props;
  const { t } = useTranslation("common");

  let SignupTenantInputDropdownClassName = "SignupTenantInput";
  if (className) {
    SignupTenantInputDropdownClassName += ` ${className}`;
  }

  disabled = disabled ? disabled : false;

  let onClickOption = option => {
    if (option) {
      handleChange({ target: { name: name, value: option.value } });
    }
  };

  return (
    <div
      className={SignupTenantInputDropdownClassName}
      data-testid="SignupTenantInputDropdown"
    >
      <SignupTenantInputWrapper label={label}>
        <ReactSelectApollo
          className="SignupTenantInputDropdown"
          classNamePrefix="SignupTenantInputDropdown"
          placeholder={t([
            "signup_tenant_country_dropdown_placeholder",
            "Select Country"
          ])}
          options={editDropdownData}
          onChange={onClickOption}
          value={editDropdownData.find(option => option.value === value)}
          disabled={disabled}
        />
      </SignupTenantInputWrapper>
    </div>
  );
};

export default SignupTenantInputDropdown;
