import {
  COContextInterface,
  CODocumentInfo,
  COQuestionAnswerOptionTemplateInterface
} from "../interfaces/co-interfaces";

import {
  COCalculatedValueTypes,
  COComponentType
} from "../constants/co-constants";
import {
  defaultMaxAnswerLengthValidators,
  maxAnswerLengthValidators,
  answerValueValidations,
  titleQuestionAnswerOptionValidators,
  numberAnswerOptionRoundToDecimalTransformValidator,
  numberAnswerOptionMinMaxValidator
} from "./elements/validators/co-validators.template";
import {
  answerChoiceQuestionAnswerOptionMeta,
  multiAnswerMeta,
  numberAnswerOptionMeta,
  richTextAnswerOptionMeta,
  textAnswerOptionMeta,
  dateAnswerOptionMeta,
  processLinkAnswerOptionMeta,
  orchestratorPackageMappingAnswerOptionMeta
} from "./elements/meta/co-meta.template";
import { questionAnswerQuestionPickerControl } from "./elements/controls/co-controls.template";
import { updateCustomizationAnswerOptionValidators } from "./elements/validators/co-submission-update-validators.template";
import {
  PATH,
  PATH_OPERATORS,
  PATH_ROUTE
} from "../constants/co-path.constants";
import {
  NonStandardQuestionAnswerOptionSlugs,
  StandardQuestionAnswerOptionSlugs
} from "../constants/co-question.constants";
import { documentPickerControls } from "./elements/controls/co-document-controls.template";

export const questionAnswerOptionTemplateForSlug = (
  template_slug: StandardQuestionAnswerOptionSlugs | string | undefined
) => {
  switch (template_slug) {
    case StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION:
      return multipleAnswerOptionChoice();
    case NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION:
      return documentMultipleAnswerOptionChoice();
    case StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER:
      return answerOptionDocument();
    case StandardQuestionAnswerOptionSlugs.STANDARD_TEXT_OPTION:
      return freeTextAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.STANDARD_RICH_TEXT_OPTION:
      return richTextAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.STANDARD_NUMBER_OPTION:
      return numberAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.STANDARD_PERCENT_OPTION:
      return percentAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION:
      return singleAnswerOptionChoice();
    case StandardQuestionAnswerOptionSlugs.QUESTION_ANSWER_SINGLE_CHOICE_OPTION:
      return questionAnswerSingleAnswerOptionChoice();
    case StandardQuestionAnswerOptionSlugs.USER_OPTION:
      return questionAnswerUserOption();
    case StandardQuestionAnswerOptionSlugs.PROCESS_PROPERTY_CHOICE_OPTION:
      return questionAnswerProcessPropertyOption();
    case StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER:
      return questionAnswerExternalDataOption();
    case StandardQuestionAnswerOptionSlugs.STANDARD_DATE_OPTION:
      return dateAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.PROCESS_LINK_OPTION:
      return processLinkAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.ORCHESTRATOR_PACKAGE_MAPPING_OPTION:
      return orchestratorPackageMappingAnswerOptionTemplate();
    case StandardQuestionAnswerOptionSlugs.REFERENCED_CALCULATED_VALUE_EXTERNAL_DATA_ANSWER_OPTION:
      return referencedCalculatedValueAnswerOptionTemplate();
  }
};

export const questionAnswerOptionTemplates = (): COQuestionAnswerOptionTemplateInterface[] => {
  let answerOptions = [
    freeTextAnswerOptionTemplate(),
    richTextAnswerOptionTemplate(),
    numberAnswerOptionTemplate(),
    singleAnswerOptionChoice(),
    questionAnswerSingleAnswerOptionChoice(),
    multipleAnswerOptionChoice(),
    documentMultipleAnswerOptionChoice(),
    questionAnswerUserOption(),
    questionAnswerProcessPropertyOption(),
    answerOptionDocument(),
    questionAnswerExternalDataOption()
  ];
  return answerOptions;
};

export const freeTextAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Text Input Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_TEXT_OPTION,
    co_meta_json: {
      ...textAnswerOptionMeta(),
      ...multiAnswerMeta()
    },
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_TEXT_INPUT,
      calculated_value_type: COCalculatedValueTypes.TEXT
    },
    co_validators_json: [...maxAnswerLengthValidators()],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_TEXT_OPTION,
      co_question_answer_option_meta_json: {},
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const richTextAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Rich Text Input Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_RICH_TEXT_OPTION,
    co_meta_json: {
      ...richTextAnswerOptionMeta()
    },
    co_options_json: {
      calculated_value_type: COCalculatedValueTypes.RICH_TEXT,
      component_type: COComponentType.ANSWER_OPTION_TEXT_INPUT
    },
    co_validators_json: [...maxAnswerLengthValidators()],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_RICH_TEXT_OPTION,
      co_question_answer_option_meta_json: {},
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const numberAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Number Input Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_NUMBER_OPTION,
    co_meta_json: {
      ...numberAnswerOptionMeta()
    },
    co_validators_json: [
      ...numberAnswerOptionRoundToDecimalTransformValidator(),
      ...numberAnswerOptionMinMaxValidator()
    ],
    co_options_json: {
      calculated_value_type: COCalculatedValueTypes.NUMBER,
      component_type: COComponentType.ANSWER_OPTION_TEXT_INPUT
    },
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_NUMBER_OPTION,
      co_question_answer_option_options_json: {
        unit: undefined
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const percentAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Percent Input Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_PERCENT_OPTION,
    co_meta_json: {
      ...numberAnswerOptionMeta()
    },
    co_validators_json: [
      ...numberAnswerOptionRoundToDecimalTransformValidator(),
      ...numberAnswerOptionMinMaxValidator()
    ],
    co_options_json: {
      calculated_value_type: COCalculatedValueTypes.PERCENT,
      component_type: COComponentType.ANSWER_OPTION_TEXT_INPUT
    },
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_PERCENT_OPTION,
      co_question_answer_option_options_json: {
        unit: undefined
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const singleAnswerOptionChoice = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Single Choice Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
    co_controls_json: [],
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_SINGLE_CHOICE,
      display_context_overrides: {
        assessment_view: {
          component_type: COComponentType.META_TEXT
        }
      }
    },
    co_validators_json: [
      ...answerValueValidations(),
      ...titleQuestionAnswerOptionValidators(),
      ...updateCustomizationAnswerOptionValidators()
    ],
    co_meta_json: {
      ...answerChoiceQuestionAnswerOptionMeta()
    },
    co_calculation_json: {},
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
      co_question_answer_option_value: "0",
      co_question_answer_option_meta_json: {
        title: {
          value: ""
        }
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      },
      co_question_answer_option_options_json: {}
    }
  };
};

export const questionAnswerSingleAnswerOptionChoice = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Question Answer Single Choice Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.QUESTION_ANSWER_SINGLE_CHOICE_OPTION,
    co_validators_json: [...updateCustomizationAnswerOptionValidators()],
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_SINGLE_CHOICE
    },
    co_meta_json: {
      ...answerChoiceQuestionAnswerOptionMeta(),
      answer_value: {
        // we need to hide this because of the control
        ...answerChoiceQuestionAnswerOptionMeta().answer_value,
        is_editable: false,
        display_context: {
          assessment_customize: 0
        }
      }
    },
    co_calculation_json: {
      answer_value_is_question_ahid: 1
    },
    co_controls_json: [
      ...questionAnswerQuestionPickerControl({ advanced_editor_only: true })
    ],
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.QUESTION_ANSWER_SINGLE_CHOICE_OPTION,
      co_question_answer_option_value: "0",
      co_question_answer_option_meta_json: {
        title: {
          value: ""
        }
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      },
      co_question_answer_option_options_json: {}
    }
  };
};

export const multipleAnswerOptionChoice = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Multiple Choice Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION,
    co_controls_json: [],
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_MULTIPLE_CHOICE,
      display_context_overrides: {
        assessment_view: {
          component_type: COComponentType.META_TEXT
        }
      }
    },
    co_validators_json: [
      ...answerValueValidations(),
      ...titleQuestionAnswerOptionValidators(),
      ...updateCustomizationAnswerOptionValidators()
    ],
    co_meta_json: {
      ...answerChoiceQuestionAnswerOptionMeta()
    },
    co_calculation_json: {},
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION,
      co_question_answer_option_value: "0",
      co_question_answer_option_meta_json: {
        title: {
          value: ""
        }
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      },
      co_question_answer_option_options_json: {}
    }
  };
};

export const documentMultipleAnswerOptionChoice = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Document Multiple Choice Option"
      }
    },
    co_question_answer_option_template_slug:
      NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_MULTIPLE_CHOICE,
      calculated_value_type: COCalculatedValueTypes.FILE_ARRAY,
      pre_fill_default_answer: 1,
      pre_fill_default_answer_function: (context: COContextInterface) => {
        // only if we have a specified documentTypeId
        let documentTypeId =
          context.answer_option?.options?.document_options?.documentTypeId;
        if (documentTypeId && context.process_answer_selection) {
          let existing_document_data: CODocumentInfo = context
            ?.process_external_data?.document_data || { documents: [] };

          const filteredDocuments =
            existing_document_data.documents?.filter(
              doc => doc.document_type_id == documentTypeId
            ) || [];

          let docsBykey: any = {};
          for (const doc of filteredDocuments) {
            docsBykey[doc.co_control_key] = doc;
          }
          context.process_answer_selection.co_process_answer_selection_info_json = docsBykey;

          context.process_answer_selection.co_process_answer_selection_input = filteredDocuments
            .map(doc => doc.document_title || "Unknown")
            .join(",");
          // we want to prefill the answer selection and json with the doc arrays
          // this is so un selection still works
          // probably still need to deal with filtering after you submit - because you can uncheck a box and we need to remove the docs from the docs array
        }
      }
    },
    co_validators_json: [
      ...answerValueValidations(),
      ...titleQuestionAnswerOptionValidators(),
      ...updateCustomizationAnswerOptionValidators()
    ],
    co_meta_json: {
      ...answerChoiceQuestionAnswerOptionMeta(),
      ...{
        controls: {
          is_editable: true,
          controls: [
            ...documentPickerControls({
              options: {
                // we only want to show the picker when we have an answer option selected for this answer
                is_hidden: 1,
                max_selectable_answers: 10,
                display_context_overrides: {
                  assessment_submission: {
                    is_hidden: PATH({
                      route:
                        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
                          .PROCESS_ANSWER_SELECTION_OBJECT,
                      operator: PATH_OPERATORS.IS_UNDEFINED
                    })
                  },
                  assessment_customize: {
                    is_hidden: 1
                  }
                }
              }
            })
          ],
          options: {
            component_type: COComponentType.META_CONTROL
          },
          display_context: {
            assessment_submission: 1
          }
        }
      }
    },
    co_calculation_json: {},
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
      co_question_answer_option_value: "0",
      co_question_answer_option_meta_json: {
        title: {
          value: ""
        }
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      },
      co_question_answer_option_options_json: {}
    }
  };
};

export const questionAnswerUserOption = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "User Choice Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.USER_OPTION,
    co_controls_json: [],
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_USER,
      calculated_value_type: COCalculatedValueTypes.USER
    },
    co_validators_json: [...answerValueValidations()],
    co_meta_json: {
      ...textAnswerOptionMeta()
    },
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.USER_OPTION,
      co_question_answer_option_value: "0",
      co_question_answer_option_meta_json: {
        title: {
          value: ""
        }
      },
      co_question_answer_option_position_json: {
        sort_order: 0
      },
      co_question_answer_option_options_json: {}
    }
  };
};

export const questionAnswerProcessPropertyOption = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Process property",
        value_localization_key: "co_questions_question_type_process_property"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.PROCESS_PROPERTY_CHOICE_OPTION,
    co_controls_json: [],
    co_options_json: {
      component_type: COComponentType.META_TEXT,
      calculated_value_type: COCalculatedValueTypes.IDENTIFIER
    },
    co_validators_json: [],
    co_meta_json: {
      answer_value: {
        options: {
          component_type: COComponentType.META_TEXT,
          is_copyable: 1
        },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
              .FUNCTION_EXTERNAL_PROPERTY_VALUE_FOR_QUESTION,
          operator: PATH_OPERATORS.FUNCTION
        }),
        display_context: {
          assessment_submission: 1,
          assessment_view: 1
        }
      }
    },
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.PROCESS_PROPERTY_CHOICE_OPTION,
      co_question_answer_option_value: PATH({
        route:
          PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
            .FUNCTION_EXTERNAL_PROPERTY_VALUE_FOR_QUESTION,
        operator: PATH_OPERATORS.FUNCTION
      }),
      co_question_answer_option_options_json: {}
    }
  };
};

export const answerOptionDocument = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Document Answer"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER,
    co_meta_json: {
      title: {
        value: "Document",
        value_localization_key: "co_questions_question_type_document",
        is_editable: true,
        controls: [
          ...documentPickerControls({
            meta: {
              title: {
                value: PATH({
                  route:
                    PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
                      .CO_PROCESS_ANSWER_SELECTION_INPUT
                }),
                placeholder: "Add document",
                placeholder_localization_key: "add_document_control_title"
              }
            },
            options: {
              is_hidden: 1,
              display_context_overrides: {
                assessment_submission: {
                  is_hidden: 0
                }
              }
            }
          })
        ],
        label: "Document",
        options: {
          is_hidden: 0,
          component_type: COComponentType.META_CONTROL
        },
        display_context: {
          assessment_submission: 1
        }
      },
      ...multiAnswerMeta()
    },
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_DOCUMENT,
      calculated_value_type: COCalculatedValueTypes.FILE
    },
    co_controls_json: [],
    co_validators_json: [...defaultMaxAnswerLengthValidators()],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER,
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

// this is for things like arrays of applications, categories, media whatever
export const questionAnswerExternalDataOption = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "External Data Answer"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
    co_meta_json: {},
    co_options_json: {
      // this needs to be set at the question level calculated_value_type: COCalculatedValueTypes.FILE
    },
    co_controls_json: [],
    co_validators_json: [],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1 // not sure here
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_EXTERNAL_DATA_ANSWER,
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {}
    }
  };
};

export const dateAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Date Input Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.STANDARD_DATE_OPTION,
    co_meta_json: {
      ...dateAnswerOptionMeta(),
      ...multiAnswerMeta()
    },
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_DATE_INPUT,
      calculated_value_type: COCalculatedValueTypes.EPOCH
    },
    co_validators_json: [...defaultMaxAnswerLengthValidators()],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_DATE_OPTION,
      co_question_answer_option_meta_json: {},
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const processLinkAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Process Link Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.PROCESS_LINK_OPTION,
    co_meta_json: {
      ...processLinkAnswerOptionMeta(),
      ...multiAnswerMeta()
    },
    co_options_json: {
      component_type: COComponentType.ANSWER_OPTION_PROCESS_LINK,
      calculated_value_type: COCalculatedValueTypes.PROCESS
    },
    co_validators_json: [...defaultMaxAnswerLengthValidators()],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.PROCESS_LINK_OPTION,
      co_question_answer_option_meta_json: {},
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const orchestratorPackageMappingAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Orchestrator Package Mapping Input Option"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.ORCHESTRATOR_PACKAGE_MAPPING_OPTION,
    co_meta_json: {
      ...orchestratorPackageMappingAnswerOptionMeta(),
      ...multiAnswerMeta()
    },
    co_options_json: {
      component_type:
        COComponentType.ANSWER_OPTION_ORCHESTRATOR_PACKAGE_MAPPING,
      calculated_value_type: COCalculatedValueTypes.ORCHESTRATOR_PACKAGE
    },
    co_validators_json: [...defaultMaxAnswerLengthValidators()],
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_value: undefined,
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.ORCHESTRATOR_PACKAGE_MAPPING_OPTION,
      co_question_answer_option_meta_json: {},
      co_question_answer_option_options_json: {},
      co_question_answer_option_position_json: {
        sort_order: 0
      }
    }
  };
};

export const referencedCalculatedValueAnswerOptionTemplate = (): COQuestionAnswerOptionTemplateInterface => {
  return {
    co_question_answer_option_template_meta_json: {
      title: {
        value: "Referenced Calculated Value",
        value_localization_key: "co_item_title_referenced_calculated_value"
      }
    },
    co_question_answer_option_template_slug:
      StandardQuestionAnswerOptionSlugs.REFERENCED_CALCULATED_VALUE_EXTERNAL_DATA_ANSWER_OPTION,
    co_controls_json: [], // to do overriddes
    co_options_json: {
      component_type: COComponentType.META_TEXT,
      calculated_value_type: COCalculatedValueTypes.REFERENCED_CALCULATED_VALUE
    },
    co_validators_json: [],
    co_meta_json: {
      answer_value: {
        options: {
          component_type: COComponentType.META_TEXT,
          is_copyable: 1
        },
        // for the value we want to take the override if we have an override set - override should be stored in the process_answer_selection, maybe info?
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.QUESTION.CO_PROCESS_CALCULATED_VALUE
              .CO_CALCULATED_VALUE
        }),
        display_context: {
          assessment_submission: 1,
          assessment_view: 1
        },
        display_context_overrides: {}
      }
    },
    co_calculation_json: {
      is_auto_selected: 1,
      input_is_value: 1
    },
    co_question_answer_option_json: {
      co_question_answer_option_template_slug:
        StandardQuestionAnswerOptionSlugs.REFERENCED_CALCULATED_VALUE_EXTERNAL_DATA_ANSWER_OPTION,
      co_question_answer_option_options_json: {}
    }
  };
};
