import { CODefaultEquations } from "../constants/co-calculation.constants";
import {
  COTypes,
  COComponentType,
  COExternalAnswerSources,
  COConditionSlugs,
  COCalculatedValueTypes
} from "../constants/co-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../constants/co-path.constants";
import {
  StandardQuestionSlugs,
  StandardQuestionAnswerOptionSlugs,
  StandardReferenceSlugs
} from "../constants/co-question.constants";
import {
  COContextInterface,
  COControlInterface,
  COControlOptionListItem,
  COQuestionTemplateInterface
} from "../interfaces/co-interfaces";
import { questionAnswerOptionTemplateForSlug } from "./co-question-answer-options.template";
import { templateQuestionConditions } from "./elements/conditions/co-conditions";
import {
  defaultRefQuestionAnswerControl,
  questionVisibilityControls,
  settingsOptionsControls
} from "./elements/controls/co-controls.template";
import {
  titleQuestionTemplateMeta,
  titleReferenceTemplateMeta,
  variableQuestionTemplateMeta
} from "./elements/meta/co-meta.template";
import { transformToCOVariableFormat } from "./elements/validators/co-question.validators";
import { updateCustomizationQuestionValidators } from "./elements/validators/co-submission-update-validators.template";
import {
  variableLengthQuestionValidators,
  titleQuestionValidators,
  tabularQuestionValidators
} from "./elements/validators/co-validators.template";

export const referenceTemplateForSlug = (
  template_slug: StandardReferenceSlugs | string | undefined
): COQuestionTemplateInterface | undefined => {
  switch (template_slug) {
    case StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE:
      return defaultReferencedCalculatedValueQuestion();
  }
};

export const referenceTemplates = (): COQuestionTemplateInterface[] => {
  let questionTemplateArray = [defaultReferencedCalculatedValueQuestion()];
  return questionTemplateArray;
};

const settingsControls = ({
  includeCanImpactKPIField = false,
  includePrefillQuestionField = true
}: {
  includeRequiredField?: boolean;
  includeCanImpactKPIField?: boolean;
  includePrefillQuestionField?: boolean;
} = {}): COControlInterface[] => {
  const controls: COControlInterface[] = [];
  controls.push(
    ...settingsOptionsControls({
      includeRequiredField: false,
      includeCanImpactKPIField,
      includeCommentsField: false,
      includePrefillQuestionField
    })
  );
  return controls;
};

const viewSettingsControls = ({
  visibilityControlsDisabled = 0,
  includeHideBeforeLiveVisibilityOption = true,
  includeHideFromPipelineVisibilityOption = true
}: {
  visibilityControlsDisabled?: number;
  includeHideBeforeLiveVisibilityOption?: boolean;
  includeHideFromPipelineVisibilityOption?: boolean;
} = {}): COControlInterface[] => {
  const controls: COControlInterface[] = [
    ...questionVisibilityControls({
      is_disabled: visibilityControlsDisabled,
      includeHideFromPipelineVisibilityOption: includeHideFromPipelineVisibilityOption,
      includeHideBeforeLiveVisibilityOption: includeHideBeforeLiveVisibilityOption
    })
  ];
  return controls;
};

// default external data question
export const defaultReferencedCalculatedValueQuestion = (): COQuestionTemplateInterface => {
  return {
    co_question_template_slug:
      StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
    co_question_template_answer_option_template_slugs:
      StandardQuestionAnswerOptionSlugs.REFERENCED_CALCULATED_VALUE_EXTERNAL_DATA_ANSWER_OPTION,
    co_question_template_co_type: COTypes.CALCULATED_VALUE_REFERENCE,
    co_question_template_meta_json: {
      title: {
        options: { component_type: COComponentType.META_H2_TEXT },
        value: "Referenced Calculated Value",
        value_localization_key: "co_item_title_referenced_calculated_value"
      }
    },
    co_meta_json: {
      ...titleReferenceTemplateMeta(),
      ...variableQuestionTemplateMeta()
    },
    co_validators_json: [
      ...variableLengthQuestionValidators(),
      ...titleQuestionValidators(),
      ...updateCustomizationQuestionValidators(),
      ...tabularQuestionValidators()
    ],
    co_controls_json: [
      ...defaultRefQuestionAnswerControl(),
      {
        target: PATH({
          route:
            PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON
              .REFERENCED_CALCULATED_VALUE_PROCESS_COLUMN_NAME
        }),
        meta: {
          title: {
            value: "Referenced variable from other assessment",
            value_localization_key:
              "co_question_title_referenced_calculated_value_from_other_assessment",
            tooltip: "",
            tooltip_localization_key: ""
          }
        },
        controlFunction: ({
          context,
          control,
          option
        }: {
          context: COContextInterface;
          control: COControlInterface;
          option: any;
        }) => {
          let selectedOption = option;

          if (selectedOption) {
            if (context?.question?.co_question_options_json) {
              context.question.co_question_options_json.referenced_calculated_value_process_column_name =
                selectedOption.value;
            }
            if (context?.question?.co_question_meta_json?.title) {
              context.question.co_question_meta_json.title.value =
                selectedOption.label;
            }
            if (context?.question) {
              context.question.co_variable_name = transformToCOVariableFormat(
                selectedOption.label || ""
              );
            }
          }
        },
        options: {
          component_type: COComponentType.CONTROL_PICKER
        },
        items: PATH({
          route:
            PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
              .FUNCTION_LAZY_LOADED_REFERENCE_CONTROL_QUESTION_OPTIONS,
          operator: PATH_OPERATORS.FUNCTION
        })
      },
      ...viewSettingsControls({})
    ],
    co_options_json: {
      no_tabular_support: 1,
      condition_overrides: [
        {
          slug: COConditionSlugs.QUESTION_HIDES_COMMENTS_SECTION
        },
        {
          slug: COConditionSlugs.QUESTION_NOT_REQUIRED
        }
      ],
      external_answer_source:
        COExternalAnswerSources.REFERENCED_EXTERNAL_CALCULATED_VALUE
    },
    co_calculation_json: {
      is_auto_selected: 1
    },
    co_question_json: {
      co_question_template_slug:
        StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
      co_question_co_type: COTypes.CALCULATED_VALUE_REFERENCE,
      co_equation: CODefaultEquations.REFERENCED_CALCULATED_VALUE,
      co_variable_name: "",
      co_question_position_json: {
        sort_order: 0
      },
      co_question_meta_json: {
        title: {
          value: "",
          help_text: ""
        }
      },
      co_question_default_options_json: {
        default_equation_value: 0
      },
      co_question_options_json: {
        visibility_permissions: [],
        condition_overrides: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE },
          { slug: COConditionSlugs.HIDDEN },
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        is_disabled: 0,
        referenced_calculated_value_process_column_name: "",
        calculated_value_type:
          COCalculatedValueTypes.REFERENCED_CALCULATED_VALUE
      },
      co_question_answer_options: [
        questionAnswerOptionTemplateForSlug(
          StandardQuestionAnswerOptionSlugs.REFERENCED_CALCULATED_VALUE_EXTERNAL_DATA_ANSWER_OPTION
        )?.co_question_answer_option_json || {}
      ]
    }
  };
};
