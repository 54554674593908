import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL,
  AUTOMATION_PROFILE_COLLABORATORS_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const collaboratorsUpdatesNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = collaboratorsUpdatesNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.COLLABORATORS_UPDATES,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const collaboratorsUpdatesNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.NEW_COLLABORATOR_ADDED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 43,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_ASSIGNED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_AS_PROCESS_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New collaborator added"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.COLLABORATOR_NAME +
              " as a " +
              COActionReplacementStrings.COLLABORATOR_ROLES +
              " in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.COLLABORATOR_REMOVED_FROM_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR
            ]
          },
          legacy_notification_id: 51,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_REMOVED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_FROM_PROCESS,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Collaborator removed from AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.COLLABORATOR_NAME +
              " from the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.ROLE_REMOVED_FOR_EXISTING_COLLABORATOR: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR
            ]
          },
          legacy_notification_id: 53,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_CERTAIN_ROLE_REMOVED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.REMOVED_AS_PROCESS_COLLABORATOR,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Role removed for an existing collaborator"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " removed " +
              COActionReplacementStrings.COLLABORATOR_NAME +
              " as a " +
              COActionReplacementStrings.COLLABORATOR_ROLES +
              " from the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COLLABORATORS_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.PROJECT_MANAGER_ROLE_ASSIGNED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER, CORoleType.AP_COLLABORATOR]
          },
          legacy_notification_id: 105,
          platform_event_type:
            COPlatformEventType.PROJECT_MANAGER_ROLE_ASSIGNED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.PROJECT_MANAGER_ROLE_ASSIGNED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Project manager role assigned"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added " +
              COActionReplacementStrings.COLLABORATOR_NAME +
              " as a Project Manager in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
