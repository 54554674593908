import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import {
  enableAutomationHub,
  enableExistingOrphan
} from "../SignupPage/SignupTenantForm/FPSActions";
import urlWithTenant from "../../utils/urlHelper";
import countries from "../../constants/countries.json";
import SessionHelper from "../../helpers/SessionHelper";
import SignupTenantInputDropdown from "../../pages/SignupPage/SignupTenantForm/SignupTenantInputDropdown/SignupTenantInputDropdown";
import {
  FPS_SELECT_EXISTING_INSTANCE,
  FPS_CREATE_NEW_INSTANCE,
  triggerAnalyticsWithDefaultData,
  triggerFrontendTelemetryEvent
} from "../../utils/analyticsUtils";
import FpsProvisionTenantSelectorMigrationOptionBox from "./FpsProvisionTenantSelectorMigrationOptionBox";
import FpsProvisionTenantSelectorReuseDBBox from "./FpsProvisionTenantSelectorReuseDBBox";
import FpsProvisionTenantSelectorMigrationConfirmBox from "./FpsProvisionTenantSelectorMigrationConfirmBox";
import { onPrem } from "@uipath/ah-common";
import ButtonApolloPrimary from "../../components/buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../components/buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import TenantSignupFeatureFlag from "../../components/TenantSignupFeatureFlag/TenantSignupFeatureFlag";

const Container = styled.div(
  props => css`
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 416px;
    top: 0px;
    right: 0;
    height: calc(100vh);
    overflow: auto;
    background: var(--color-background, ${props.theme.colors.white});
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 1px 5px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    margin-bottom: 36px;
    animation: admin-users-sidebar-slidein 0.5s;

    @keyframes admin-users-sidebar-slidein {
      0% {
        transform: translate(416px, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }
  `
);

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  width: calc(100% - 10px);
  background: var(--color-background, #fff);
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const ButtonConfirm = styled(ButtonApolloPrimary)`
  && {
    min-width: 88px;
    margin-right: 10px;
  }
`;

const ButtonCancel = styled(ButtonApolloSecondary)`
  && {
    min-width: 88px;
    margin-right: 10px;
  }
`;

const CountryPickerWrapper = styled.div`
  margin-left: 28px;
  margin-bottom: 8px;
`;

const FpsProvisionTenantSelector = ({
  tenantsByOwner = [],
  tenants = [],
  hideSelectTenantBox,
  userEmail,
  history,
  signupInfo,
  showLoader
}) => {
  const SIDE_BAR_CONTENT = {
    CREATE_NEW_OPTION: 1,
    MIGRATION_OPTION: 2,
    NEW_DB_OPTION: 3
  };

  const [currentSideBarContent, setCurrentSideBarContent] = useState(
    SIDE_BAR_CONTENT["CREATE_NEW_OPTION"]
  );
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedOrphan, setSelectedOrphan] = useState(null);
  const [selectedTenantSlug, setSelectedTenantSlug] = useState(null);
  //1: create new db. 0: reuse existing db (orphan)
  const [createNewDBOption, setCreateNewDBOption] = useState(0);
  //1: full migration. 0: partial migration
  const [migrationOption, setMigrationOption] = useState(1);
  const [createNewInstance, setCreateNewInstance] = useState(false);
  const storedCountry = SessionHelper.getValueForKey("country");
  const matchedCountry: any =
    countries.find(obj => obj.country_name === storedCountry) || null;
  const [country, setCountry] = useState(matchedCountry);

  useEffect(() => {
    if (createNewInstance) {
      setSelectedTenant(null);
    } else if (!createNewInstance && tenantsByOwner.length > 0) {
      if (!selectedTenant) {
        //@ts-ignore
        setSelectedTenant(tenantsByOwner[0]?.tenant_uuid);
        //@ts-ignore
        setSelectedTenantSlug(tenantsByOwner[0]?.tenant_slug);
      }
    }
  }, [createNewInstance]);

  const { t } = useTranslation("common");
  const REUSE_DB_FLAG = false;

  const onSelectOrphan = (e, tenantUuid) => {
    setCreateNewDBOption(0);
    setSelectedOrphan(tenantUuid);
  };

  const onSelectTenant = (e, tenant_slug) => {
    setSelectedTenant(e.target.value);
    setSelectedTenantSlug(tenant_slug);
    setCreateNewInstance(false);
  };

  const onMigrationOptionChange = e => {
    setMigrationOption(parseInt(e.target.value));
  };

  const onSelectNewInstance = e => {
    setCreateNewInstance(!createNewInstance);
    setSelectedTenant(null);
  };

  const onCountryChange = e => {
    setCountry(e.target.value);
  };

  const isCountrySelected = () => {
    return !!matchedCountry || !!country || onPrem();
  };

  const generateCountryPicker = () => {
    if (!!matchedCountry || onPrem()) {
      return;
    }

    const editDropdownData = (countries || []).map(country => {
      return {
        label: country.country_name,
        value: country
      };
    });

    return (
      <CountryPickerWrapper>
        <SignupTenantInputDropdown
          handleChange={onCountryChange}
          name="tenant_country"
          editDropdownData={editDropdownData}
          value={country}
          label={t(["signup_tenant_country_label", "Country"])}
        />
      </CountryPickerWrapper>
    );
  };

  const migrateExistingTenantAction = () => {
    showLoader(true);
    if (!!selectedTenant) {
      enableAutomationHub(
        {
          data: {
            tenant_uuid: selectedTenant,
            full_migration: migrationOption
          },
          config: {
            headers: signupInfo.headers,
            isAuthLogin: true
          }
        },
        responseData => {
          if (responseData) {
            history.replace(urlWithTenant("/"));
          } else {
            //it's an error - do nothing
          }
        },
        t,
        true
      );
    }
  };
  const createNewInstanceAction = () => {
    showLoader(true);
    triggerAnalyticsWithDefaultData(FPS_CREATE_NEW_INSTANCE);
    enableAutomationHub(
      {
        data: {
          tenant_country: country?.country_abbreviation
        },
        config: {
          headers: signupInfo.headers,
          isAuthLogin: true
        }
      },
      responseData => {
        if (responseData) {
          history.replace(urlWithTenant("/"));
        } else {
          //it's an error - do nothing
        }
      },
      t,
      false
    );
  };
  // Uncomment when we enable reuse db feature
  // const enableOrphanAction = () => {
  //   showLoader(true);
  //   enableExistingOrphan(
  //     {
  //       data: {
  //         tenant_uuid: selectedOrphan
  //       },
  //       headers: signupInfo.headers,
  //       isAuthLogin: true
  //     },
  //     (response) => {
  //       if(responseData){
  //        history.replace(urlWithTenant("/"));
  //         }else{
  //        //it's an error - do nothing
  //        }
  //     },
  //     t
  //   );
  // };

  const onClickNextStep = () => {
    //if no option was selected, disable button
    if (!selectedTenant && !createNewInstance) {
      return;
    }

    //select an existing tenant
    if (selectedTenant) {
      setCurrentSideBarContent(SIDE_BAR_CONTENT["MIGRATION_OPTION"]);
    }
  };

  const onConfirm = () => {
    if (currentSideBarContent === SIDE_BAR_CONTENT["MIGRATION_OPTION"]) {
      migrateExistingTenantAction();
    }
    if (
      currentSideBarContent === SIDE_BAR_CONTENT["CREATE_NEW_OPTION"] &&
      !selectedTenant
    ) {
      createNewInstanceAction();
    }
  };

  const renderConfirmAndCancelButton = () => {
    let blueButton;
    if (
      currentSideBarContent === SIDE_BAR_CONTENT["CREATE_NEW_OPTION"] &&
      !!selectedTenant
    ) {
      //Next Step
      blueButton = (
        <ButtonConfirm
          onClick={onClickNextStep}
          disabled={!(!!selectedTenant || !!createNewInstance)}
        >
          {t(["fps_tenant_select_next_step_button", "Next step"])}
        </ButtonConfirm>
      );
    } else {
      //Confirm
      if (
        !isCountrySelected() &&
        currentSideBarContent === SIDE_BAR_CONTENT["CREATE_NEW_OPTION"]
      ) {
        blueButton = (
          <ButtonConfirm disabled>
            {t(["fps_tenant_select_confirm_button", "Confirm"])}
          </ButtonConfirm>
        );
      } else {
        blueButton = (
          <TenantSignupFeatureFlag
            component={ButtonConfirm}
            onClick={onConfirm}
          >
            {t(["fps_tenant_select_confirm_button", "Confirm"])}
          </TenantSignupFeatureFlag>
        );
      }
    }

    return (
      <ButtonGroup>
        {blueButton}
        <ButtonCancel onClick={hideSelectTenantBox}>
          {t(["fps_tenant_select_cancel_button", "Cancel"])}
        </ButtonCancel>
      </ButtonGroup>
    );
  };

  /* Uncomment following code when we enable reuse db feature */
  // const onConfirm = () => {
  //   if (currentSideBarContent === SIDE_BAR_CONTENT["MIGRATION_OPTION"]) {
  //     migrateExistingTenantAction();
  //   } else if (currentSideBarContent === SIDE_BAR_CONTENT["NEW_DB_OPTION"]) {
  //     if (createNewDBOption) {
  //       createNewInstanceAction();
  //     } else {
  //       enableOrphanAction();
  //     }
  //   }
  // };

  // const onClickNextStep = () => {
  //   //if no option was selected, disable button
  //   if (!selectedTenant && !createNewInstance) {
  //     return;
  //   }
  //   //select create new
  //   if (createNewInstance) {
  //     setCurrentSideBarContent(SIDE_BAR_CONTENT["NEW_DB_OPTION"]);
  //   }
  //   //select an existing tenant
  //   if (selectedTenant) {
  //     setCurrentSideBarContent(SIDE_BAR_CONTENT["MIGRATION_OPTION"]);
  //   }
  // };

  // const renderConfirmAndCancelButton = () => {
  //   let blueButton;
  //   if (currentSideBarContent === SIDE_BAR_CONTENT["CREATE_NEW_OPTION"]) {
  //     //Next Step
  //     blueButton = (
  //       <ButtonConfirm
  //         clickHandler={onClickNextStep}
  //         type={
  //           !!selectedTenant || !!createNewInstance ? "primary" : "disabled"
  //         }
  //       >
  //         {t(["fps_tenant_select_next_step_button", "Next step"])}
  //       </ButtonConfirm>
  //     );
  //   } else {
  //     blueButton = (
  //       <ButtonConfirm
  //         clickHandler={onConfirm}
  //         type={
  //           !!selectedTenant || !!selectedOrphan || !!createNewDBOption
  //             ? "primary"
  //             : "disabled"
  //         }
  //       >
  //         {t(["fps_tenant_select_confirm_button", "Confirm"])}
  //       </ButtonConfirm>
  //     );
  //   }

  //   return (
  //     <ButtonGroup>
  //       <ButtonCancel clickHandler={hideSelectTenantBox}>
  //         {t(["fps_tenant_select_cancel_button", "Cancel"])}
  //       </ButtonCancel>
  //       {blueButton}
  //     </ButtonGroup>
  //   );
  // };

  const renderFirstConfirmBox = () => {
    return (
      <FpsProvisionTenantSelectorMigrationOptionBox
        createNewInstance={createNewInstance}
        setCreateNewInstance={setCreateNewInstance}
        onSelectNewInstance={onSelectNewInstance}
        tenantsByOwner={tenantsByOwner}
        selectedTenant={selectedTenant}
        onSelectTenant={onSelectTenant}
        generateCountryPicker={generateCountryPicker}
        showSelectTenantSection={showSelectTenantSection}
        enableReuseDB={REUSE_DB_FLAG}
        userEmail={userEmail}
      />
    );
  };

  const renderCreateNewInstanceBox = () => {
    return (
      <FpsProvisionTenantSelectorReuseDBBox
        setSelectedOrphan={setSelectedOrphan}
        selectedOrphan={selectedOrphan}
        onSelectOrphan={onSelectOrphan}
        setCreateNewDBOption={setCreateNewDBOption}
        onCountryChange={onCountryChange}
        storedCountry={storedCountry}
        createNewDBOption={createNewDBOption}
        country={country}
        countries={countries}
        tenants={tenants}
      />
    );
  };

  const renderMigrationConfirmBox = () => {
    return (
      <FpsProvisionTenantSelectorMigrationConfirmBox
        selectedTenantSlug={selectedTenantSlug}
        migrationOption={migrationOption}
        onMigrationOptionChange={onMigrationOptionChange}
      />
    );
  };

  const showSelectTenantSection = !!tenantsByOwner && tenantsByOwner.length > 0;

  return (
    <Container data-testid="FpsProvisionTenantSelector">
      {currentSideBarContent === SIDE_BAR_CONTENT["CREATE_NEW_OPTION"] &&
        renderFirstConfirmBox()}
      {currentSideBarContent === SIDE_BAR_CONTENT["MIGRATION_OPTION"] &&
        renderMigrationConfirmBox()}
      {/*flag reuse db feature for now*/}
      {REUSE_DB_FLAG &&
        currentSideBarContent === SIDE_BAR_CONTENT["NEW_DB_OPTION"] &&
        renderCreateNewInstanceBox()}

      {renderConfirmAndCancelButton()}
    </Container>
  );
};
export default FpsProvisionTenantSelector;
