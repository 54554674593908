import axios from "../utils/axiosInstance";
import { saveAs } from "file-saver";
import apiErrorAlert from "../utils/apiErrorAlert";
import fileUploadSwal from "../utils/fileUploadSwal/fileUploadSwal";
import fileDownloadSwal from "../utils/fileDownloadSwal/fileDownloadSwal";
import {
  swalSpinnerClose,
  swalSpinnerSuccess
} from "../utils/SweetAlertLoaderSpinner";
import { fetchComponentProfile } from "./componentProfileActions";
import { UPLOAD_FILE } from "../constants/actionTypes";

// When set responseType: "blob" for file downloads the
// Error will also be a blob, to show error message we
// need to parse the blob through a file reader to get
// to the actual error message
export const parseErrorBlobAndShowApiErrorAlert = blobError => {
  if (
    blobError &&
    blobError.request &&
    blobError.request.responseType === "blob" &&
    blobError.response &&
    blobError.response.data instanceof Blob &&
    blobError.response.data.type &&
    blobError.response.data.type.toLowerCase().indexOf("json") !== -1
  ) {
    let reader: any = new FileReader();
    reader.onload = () => {
      try {
        blobError.response.data = JSON.parse(reader.result);
      } catch (_) {
        blobError.response.data = {};
      }
      apiErrorAlert(blobError);
    };

    reader.onerror = () => {
      apiErrorAlert(blobError);
    };

    reader.readAsText(blobError.response.data);
  } else {
    apiErrorAlert(blobError);
  }
};

export function uploadFile(
  params,
  callback,
  errorCallback,
  removeSwal = false
) {
  return function(dispatch) {
    const { file, type } = params;
    const formData = new FormData();
    formData.append("file", file);
    if (type) {
      formData.append("type", type);
    }
    const { size } = file;
    const headerData = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        let percentValue = Math.floor((progressEvent.loaded / size) * 100);
        if (percentValue > 98) {
          percentValue = 98;
        }
        fileUploadSwal({ percentValue });
      }
    };

    axios
      .post(`/api/v1/upload/file`, formData, headerData)
      .then(response => {
        fileUploadSwal({ percentValue: 100 });
        setTimeout(() => {
          dispatch({
            type: UPLOAD_FILE,
            payload: response.data.data
          });

          if (callback) {
            callback(response.data.data);
          }

          if (!removeSwal) {
            swalSpinnerSuccess();

            setTimeout(() => {
              swalSpinnerClose();
            }, 600);
          }
        }, 800);
      })
      .catch(error => {
        apiErrorAlert(error);
        if (errorCallback) {
          errorCallback(error);
        }
      });
  };
}

export function uploadImage(file, callback, type, hideSuccessSwal = false) {
  return function(dispatch) {
    const formData = new FormData();
    formData.append("image", file);
    if (type) {
      formData.append("type", type);
    }
    const { size } = file;
    const headerData = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        let percentValue = Math.floor((progressEvent.loaded / size) * 100);
        if (percentValue > 98) {
          percentValue = 98;
        }
        fileUploadSwal({ percentValue });
      }
    };

    axios
      .post(`/api/v1/upload/image`, formData, headerData)
      .then(response => {
        fileUploadSwal({ percentValue: 100 });
        setTimeout(() => {
          dispatch({
            type: UPLOAD_FILE,
            payload: response.data.data
          });

          if (callback) {
            callback(response.data.data);
          }
          if (!hideSuccessSwal) {
            swalSpinnerSuccess();

            setTimeout(() => {
              swalSpinnerClose();
            }, 800);
          }
        }, 600);
      })
      .catch(error => {
        swalSpinnerClose();
        apiErrorAlert(error);
      });
  };
}

export function downloadComponent(params, callback) {
  const { fileID, fileName, component_slug } = params;

  return function(dispatch) {
    fileDownloadSwal({} as any);
    axios({
      url: `/api/v1/download/file/${fileID}`,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: progressEvent => {
        if (progressEvent.total && progressEvent.total !== 0) {
          let percentValue: any = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          fileDownloadSwal({ percentValue } as any);
        }
      }
    })
      .then(response => {
        saveAs(new Blob([response.data]), fileName);

        setTimeout(() => {
          axios
            .post(`/api/v1/components/slug/${component_slug}/download`)
            .then(res => {
              dispatch(fetchComponentProfile(component_slug));
            })
            .catch(error => {
              apiErrorAlert(error);
            });

          swalSpinnerSuccess();

          setTimeout(() => {
            swalSpinnerClose();
            if (typeof callback === "function") {
              callback();
            }
          }, 600);
        }, 800);
      })
      .catch(error => {
        swalSpinnerClose();
        parseErrorBlobAndShowApiErrorAlert(error);
      });
  };
}

export function downloadFile(
  params,
  completionCallback,
  titleText,
  successCallback
) {
  const { fileID, fileName } = params;

  return function() {
    fileDownloadSwal({ titleText } as any);
    axios({
      url: `/api/v1/download/file/${fileID}`,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: progressEvent => {
        if (progressEvent.total && progressEvent.total !== 0) {
          let percentValue = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          fileDownloadSwal({ percentValue, titleText });
        }
      }
    })
      .then(response => {
        saveAs(new Blob([response.data]), fileName);

        setTimeout(() => {
          if (typeof completionCallback === "function") {
            completionCallback();
          }
          swalSpinnerSuccess();
          if (typeof successCallback === "function") {
            successCallback();
          }

          setTimeout(() => {
            swalSpinnerClose();
          }, 600);
        }, 800);
      })
      .catch(error => {
        if (typeof completionCallback === "function") {
          completionCallback();
        }
        parseErrorBlobAndShowApiErrorAlert(error);
      });
  };
}

export async function getFileBlob(fileID, progressCallBack) {
  return axios({
    url: `/api/v1/download/file/${fileID}`,
    method: "GET",
    responseType: "blob", // important
    onDownloadProgress: progressEvent => {
      if (progressEvent.total && progressEvent.total !== 0) {
        if (typeof progressCallBack === "function") {
          let percentValue = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          progressCallBack(percentValue);
        }
      }
    }
  })
    .then(response => {
      return new Blob([response.data]);
    })
    .catch(error => {
      parseErrorBlobAndShowApiErrorAlert(error);
      return null;
    });
}

export function clearUploadFile() {
  return function(dispatch) {
    dispatch({
      type: UPLOAD_FILE,
      payload: null
    });
  };
}
