export const coreContextPathKeys = ["section", "question", "answer_option"];

export const contextPathKeys = [
  "assessment",
  ...coreContextPathKeys,
  "process_answer",
  "process_answer_selection",
  "process_external_data",
  "templates",
  "function_hooks",
  "calculation_modifiers"
];

export const isContextProperty = (str: string) => {
  return contextPathKeys.includes(str);
};
