import {
  FETCH_ALL_COMPONENT_TYPES,
  FETCH_AND_SEARCH_COMPONENT_APPLICATIONS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  componentTypes: null,
  componentApplications: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_COMPONENT_TYPES:
      return { ...state, componentTypes: action.payload };
    case FETCH_AND_SEARCH_COMPONENT_APPLICATIONS:
      return { ...state, componentApplications: action.payload };
    default:
      return state;
  }
}
