import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReportAbuseModal from "./ModalReportAbuse";
import { REPORT_COMMENT } from "../../../constants/reportConstants";

class ModalReportComment extends React.Component<any, any> {
  render() {
    return (
      <ReportAbuseModal
        {...this.props}
        title={this.props.t("modal_report_comment_title")}
        message={this.props.t("modal_report_comment_content")}
        type={REPORT_COMMENT}
      />
    );
  }
}

export default withTranslation("common")(ModalReportComment);
