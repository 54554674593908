import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";

import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import {
  COAssessmentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import {
  COSectionTemplateSlugs,
  CONonStandardSectionTemplateSlugs
} from "../../constants/co-section.constants";
import { defaultAssessmentTemplate } from "../co-assessment.template";

export const defaultQ1AssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.HIGH_LEVEL_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.Q1_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.HIGH_LEVEL_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.Q1_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default High Level Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
    assessmentTemplate.co_options_json = {
      ...assessmentTemplate.co_options_json,
      condition_overrides: [
        { slug: COConditionSlugs.Q1_VISIBILITY },
        // This assessment is not optional during submission, but is optional with a warning when saving
        {
          slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE
        },
        { slug: COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING },
        { slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_IDEA_APPROVAL },
        {
          slug: COConditionSlugs.HIDE_ASSESSMENT_DURING_CHANGE_REQUEST
        },
        {
          slug:
            COConditionSlugs.SHOW_DURING_CR_BASED_ON_Q1_ASSESSMENT_VISIBILITY_QUESTION
        },
        ...templateAssessmentConditions()
      ]
    };
  }
  return assessmentTemplate;
};

export const AHFreshQ1AssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateQ1(false) });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-q1-0-0`;
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "",
    sectionTemplateSlug: COSectionTemplateSlugs.Q1_DEFAULT_SECTION_V1,
    note: ""
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: []
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHDefaultQ1AssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateQ1() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-q1-0-0`;

  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Assessment",
    sectionTemplateSlug: COSectionTemplateSlugs.Q1_DEFAULT_SECTION_V1,
    note: ""
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.Q1_DECISIONS_STRAIGHTFORWARD_RULES,
      CustomQuestionSlugs.Q1_INPUT_DATA_ACCESSED_DIGITALLY,
      CustomQuestionSlugs.Q1_DATA_HIGHLY_FORMATTED,
      CustomQuestionSlugs.Q1_NO_EXPECTED_CHANGES,
      CustomQuestionSlugs.Q1_PROCESS_DOCUMENTS
    ]
  });
  // need to override some section controls
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Assessment KPIs",
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_DEFAULT_SIDEBAR_SECTION_V1,
    condition_overrides: [
      { slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT },
      { slug: COConditionSlugs.SIDEBAR }
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.Q1_READINESS_SCORE,
      CustomQuestionSlugs.Q1_SUITABILITY_SCORE,
      CustomQuestionSlugs.Q1_OVERALL_GAUGE_SCORE,
      CustomQuestionSlugs.CHART_IDEA_SCORE
    ]
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Assign Process Owner",
    description:
      "Assign a Process Owner who will be invited to fill in a more detailed assessment for this automation idea.",
    sectionTemplateSlug:
      CONonStandardSectionTemplateSlugs.PROCESS_OWNER_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.PROCESS_OWNER_Q1]
  });
};

export const assessmentTemplateQ1 = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultQ1AssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "High Level Assessment",
      description:
        "Tell us about how this process works now. This section will help us compute a score for your idea. This is a score generated based on the as is suitability for automation and how ready it is to be automated."
    });
    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New High Level Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};
