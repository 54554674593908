import {
  COProcessAnswerSelectionHistoryDBClass,
  COProcessAnswerSelectionHistoryInterface
} from "../interfaces/co-interfaces";
import { COBase } from "./co-base.class";
import { COQuestionAnswerOption } from "./co-question-answer-option.class";

export interface COProcessAnswerSelectionHistory
  extends COProcessAnswerSelectionHistoryInterface {}
export class COProcessAnswerSelectionHistory extends COBase {
  static getClassName(): string {
    return "COProcessAnswerSelectionHistory";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [
        {
          objectKey: "co_question_answer_option",
          objectClass: COQuestionAnswerOption
        }
      ],
      dbClass: COProcessAnswerSelectionHistoryDBClass,
      objectClass: COProcessAnswerSelectionHistory
    };
  }
}
