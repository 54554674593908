import { COAssessmentTemplateSlugs } from "../constants/co-assessment.constants";
import { COAssessmentTypes, COComponentType } from "../constants/co-constants";
import { COAssessmentTemplateInterface } from "../interfaces/co-interfaces";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";
import { templateAssessmentConditions } from "./elements/conditions/co-conditions";
import {
  assessmentVersionCheck,
  updateCustomizationAssessmentValidators
} from "./elements/validators/co-submission-update-validators.template";
import { assessmentValidators } from "./elements/validators/co-validators.template";
import { draftJSMetaDescriptionString } from "../utils/co-template.utils";

// this is generic for the OVR Q1 Q2 Q3 and MISC assessments - the specific values are overridden in the specific functions
export const currentAssessmentBuildVersion = "2"; // for the CDA release it's going to be undefined (which we're retroactively calling v1)
export const defaultAssessmentTemplate = (): COAssessmentTemplateInterface => {
  return {
    co_assessment_type: COAssessmentTypes.DETAILED_ASSESSMENT_TYPE,
    co_assessment_template_slug: COAssessmentTemplateSlugs.Q2_DEFAULT_V1,
    co_meta_json: {
      title: {
        position: { sort_order: 0 },
        options: { component_type: COComponentType.META_H2_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.TITLE.VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            placeholder: "Enter assessment title",
            placeholder_localization_key:
              "co_assessment_label_assessment_title_placeholder",
            label: "Assessment Title",
            label_localization_key: "co_assessment_label_assessment_title",
            is_editable: false,
            options: {
              component_type: COComponentType.META_EDITABLE_H2_TEXT
            }
          }
        }
      },
      description: {
        position: { sort_order: 1 },
        options: {
          component_type: COComponentType.META_PARAGRAPH_TEXT
        },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.DESCRIPTION
              .VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            placeholder: "Enter assessment description",
            placeholder_localization_key:
              "co_assessment_label_assessment_description_placeholder",
            label: "Assessment Description",
            label_localization_key:
              "co_assessment_label_assessment_description",
            is_editable: true,
            options: {
              component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT
            }
          },
          assessment_view: {
            options: {
              is_hidden: 1
            }
          }
        }
      }
    },
    co_version_meta_json: {
      title: {
        position: { sort_order: 0 },
        options: { component_type: COComponentType.META_H2_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON.TITLE
              .VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            placeholder: "Enter version title",
            placeholder_localization_key:
              "co_assessment_label_assessment_version_name_placeholder",
            label: "Assessment Version Name",
            label_localization_key:
              "co_assessment_label_assessment_version_name",
            is_editable: true,
            options: {
              component_type: COComponentType.META_EDITABLE_TEXT
            }
          }
        }
      },
      version: {
        position: { sort_order: 1 },
        options: { component_type: COComponentType.META_H3_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
              .VERSION.VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            label: "Version Number",
            label_localization_key:
              "co_assessment_label_assessment_version_number",
            placeholder: "1.0",
            is_editable: true,
            options: {
              component_type: COComponentType.META_EDITABLE_TEXT
            }
          }
        }
      },
      version_number: {
        label: "",
        position: { sort_order: 2 },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
              .VERSION_NUMBER.VALUE
        }),
        options: {
          component_type: COComponentType.META_TEXT,
          is_hidden: 1
        }
      },
      description: {
        position: { sort_order: 3 },
        options: { component_type: COComponentType.META_PARAGRAPH_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
              .DESCRIPTION.VALUE
        }),
        display_context_overrides: {
          assessment_customize: {
            label: "Assessment version description",
            label_localization_key:
              "co_assessment_label_assessment_version_description",
            placeholder_localization_key:
              "co_assessment_label_assessment_version_description_placeholder",
            placeholder: "Enter Version Description",
            is_editable: true,
            options: {
              component_type: COComponentType.META_EDITABLE_PARAGRAPH_TEXT
            }
          }
        }
      }
    },
    co_controls_json: [],
    co_options_json: {
      condition_overrides: [...templateAssessmentConditions()]
    },
    co_validators_json: [
      ...assessmentValidators(),
      ...updateCustomizationAssessmentValidators(),
      ...assessmentVersionCheck()
    ],
    co_assessment_json: {
      co_assessment_type: COAssessmentTypes.DETAILED_ASSESSMENT_TYPE,
      co_assessment_template_slug: COAssessmentTemplateSlugs.Q2_DEFAULT_V1,
      co_assessment_meta_json: {
        title: {
          value: "Automation Hub Default Detailed Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        }
      },
      co_assessment_options_json: {
        co_system_build_version: currentAssessmentBuildVersion
      },
      co_assessment_version_meta_json: {
        title: {
          value: "Default Assessment",
          value_localization_key: "cda_default_assessment_name"
        },
        description: {
          value:
            "The default Detailed Assessment that ships with Automation Hub"
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      },
      is_deleted: 0,
      created_epoch: 0,
      updated_epoch: 0
    }
  };
};
