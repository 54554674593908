import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

export const StyleHeight = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  overflow-x: hidden;
`;

const DynamicErrorGraphic = ({
  type,
  title,
  path
}: {
  type:
    | "401"
    | "403"
    | "404"
    | "503"
    | "loading"
    | "browser-not-supported"
    | "end-ie"
    | "ip-restricted";
  title: string;
  path?: string;
}) => {
  const { i18n } = useTranslation("common");
  return (
    <StyleHeight data-testid="DynamicErrorGraphic">
      {/* @ts-ignore */}
      <portal-error-page
        type={type}
        page-title={title}
        button-href={path ? path : ""}
        language={i18n.language}
        key={i18n.language}
      />
    </StyleHeight>
  );
};
export default DynamicErrorGraphic;
