import axios from "../axiosInstance";

export const syncAdminUsers = async data => {
  let path = "/api/v1/admin/users/sync";
  const response = await axios.post(path, data);
  return response;
};

export const searchUsersByEmailArray = async data => {
  let path = "/api/v1/users/user-emails";
  const response = await axios.post(path, data);
  return response.data.data;
};
