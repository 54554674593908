import { SHOW_MODAL, HIDE_MODAL } from "../constants/actionTypes";

const INITIAL_STATE = { modalType: null, modalProps: null };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_MODAL:
      const updatedState = {
        ...state,
        modalType: action.modalType
      };

      if (action.modalProps && typeof action.modalProps === "object") {
        updatedState.modalProps = action.modalProps;
      }

      return updatedState;
    case HIDE_MODAL:
      return {
        ...state,
        modalType: null,
        modalProps: null
      };
    default:
      return state;
  }
}
