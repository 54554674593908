import {
  FETCHING_DATA_FOR_SETUP_CURRENCY,
  FETCH_DATA_FOR_SETUP_CURRENCY,
  FETCH_AVAILABLE_CURRENCY_OPTIONS,
  FETCH_DATA_FOR_COST_SETUP_RPA_SOFTWARE_VENDOR
} from "../constants/actionTypes";

const INITIAL_STATE = {
  rpaSoftwareVendor: [],
  setupCurrency: null,
  availableCurrencyOptions: [],
  fetchingData: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_DATA_FOR_SETUP_CURRENCY:
      return {
        ...state,
        fetchingData: true
      };
    case FETCH_DATA_FOR_SETUP_CURRENCY:
      const currencyData = (action.payload && action.payload.currency) || null;
      return {
        ...state,
        setupCurrency: currencyData,
        fetchingData: false
      };
    case FETCH_AVAILABLE_CURRENCY_OPTIONS:
      return {
        ...state,
        availableCurrencyOptions: action.payload,
        fetchingData: false
      };
    case FETCH_DATA_FOR_COST_SETUP_RPA_SOFTWARE_VENDOR:
      return {
        ...state,
        rpaSoftwareVendor: action.payload
      };
    default:
      return state;
  }
}
