import React from "react";
import moment from "moment";
import "./CellModifiedMyComponents.scss";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";

const CellModifiedMyComponents = props => {
  const { component_updated_epoch } = props;

  const dateUpdated = component_updated_epoch
    ? `${showDateWithTenantDateFormat(
        component_updated_epoch,
        props.t("general_data_format_slash", "L")
      )}`
    : "N/A";

  return (
    <div
      className="CellModifiedMyComponents"
      data-testid="CellModifiedMyComponentsContainer"
    >
      <span className="CellModifiedMyComponents__date-updated">
        {dateUpdated}
      </span>
    </div>
  );
};
export default withTranslation("common")(CellModifiedMyComponents);
