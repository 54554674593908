import {
  COValidationError,
  COValidationErrorManagerInterface
} from "../interfaces/co-interfaces";

export class COValidationErrorManager
  implements COValidationErrorManagerInterface {
  validation_errors?: COValidationError[];
  constructor() {
    this.validation_errors = [];
  }
}

//we'll extend that more
export class COValidationErrorException extends Error {
  validation_errors: COValidationError[];
  constructor(errors: COValidationError[] | any, message?: string) {
    let error_message = errors.message || message;
    if (Array.isArray(errors)) {
      error_message =
        message || errors.map(error => error.error_message).join(",");
    }
    super(error_message);
    this.validation_errors = errors;
  }
}
