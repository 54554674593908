import { COTaskActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COTaskActionSlugs,
  COTaskType,
  COTaskActivityType
} from "../../../../../constants/actions/co-task-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import {
  ADMIN_MANAGER_COMPONENTS_URL,
  COMPONENT_PROFILE_URL
} from "../../../../../constants/actions/co-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";

export const curateTaskActionTemplate = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  // get template based on slug
  let template = curateTaskActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each task
    template.co_options_json = {
      task_type: COTaskType.CURATE,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const curateTaskActionTemplateFromSlug = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  switch (slug) {
    case COTaskActionSlugs.GO_CURATION_NEEDS_UPDATE_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.COMPONENT_AUTHOR]
          },
          legacy_task_id: 13,
          task_activity_type_id: 13,
          task_activity_type: COTaskActivityType.GO_CURATION_NEEDS_UPDATE,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_STATUS_CHANGED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Curation needs update"
          },
          message: {
            value: "Component Curation - needs update"
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.GO_CURATION_IN_PROGRESS_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.CURATOR]
          },
          legacy_task_id: 14,
          task_activity_type_id: 14,
          task_activity_type: COTaskActivityType.GO_CURATION_IN_PROGRESS,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.COMPONENT_STATUS_CHANGED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Curation in progress"
          },
          message: {
            value: "Component Curation - in progress"
          },
          redirect_url: {
            value: ADMIN_MANAGER_COMPONENTS_URL
          }
        }
      };
    }
  }
  return undefined;
};
