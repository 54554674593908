import { COSection } from "../../classes/co-assessment-section.class";
import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";

import {
  draftJSMetaDescriptionString,
  updateMetaDataValues
} from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";
import { CO_SECTION_CUSTOM_QUESTIONS_LEGACY_SLUG } from "../../constants/co-migration-constants";
import {
  sectionTemplateWithTitleAndDescription,
  addSectionAndQuestions
} from "../../helpers/co-assessment-template.helper";
import {
  COAssessmentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { templateAssessmentConditions } from "../elements/conditions/co-conditions";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import {
  COSectionTemplateSlugs,
  CONonStandardSectionTemplateSlugs
} from "../../constants/co-section.constants";
import { defaultAssessmentTemplate } from "../co-assessment.template";

export const defaultQ2AssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.DETAILED_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.Q2_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.DETAILED_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.Q2_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: draftJSMetaDescriptionString(
          "The default Detailed Assessment that ships with Automation Hub"
        )
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };

    assessmentTemplate.co_options_json = {
      ...assessmentTemplate.co_options_json,
      optional_for_submission: 1,
      condition_overrides: [
        { slug: COConditionSlugs.Q2_VISIBILITY },
        { slug: COConditionSlugs.Q2_REQUIRED_OVERRIDES },
        // This assessment is not optional when submitting and when marking as complete
        // When saving before the Qualification phase it is not optional without warning
        // When saving at or after the Qualification phase it is not optional with warning
        {
          slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE
        },
        { slug: COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING },
        {
          slug:
            COConditionSlugs.ASSESSMENT_OPTIONAL_WITHOUT_WARNING_BEFORE_QUALIFICATION
        },
        {
          slug: COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_MARK_AS_COMPLETE
        },
        {
          slug: COConditionSlugs.HIDE_ASSESSMENT_DURING_CHANGE_REQUEST
        },
        {
          slug:
            COConditionSlugs.SHOW_DURING_CR_BASED_ON_Q2_ASSESSMENT_VISIBILITY_QUESTION
        },
        ...templateAssessmentConditions()
      ]
    };
  }
  return assessmentTemplate;
};

export const AHDefaultQ2AssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateQ2() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-0-0`;
  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

export const AHFreshQ2AssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateQ2(false) });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "",
      sectionTemplateSlug: COSectionTemplateSlugs.Q2_DEFAULT_SECTION_V1
    }),
    question_slugs: []
  });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Automation goal",
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_DEFAULT_SIDEBAR_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.PRIMARY_REASON_TO_AUTOMATE]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "Environmental stability"
    }),
    question_slugs: [
      CustomQuestionSlugs.PROCESS_CHANGE_EXPECTED,
      CustomQuestionSlugs.APPLICATION_CHANGE_EXPECTED
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "Employee profile"
    }),
    question_slugs: [
      CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR,
      CustomQuestionSlugs.WORKING_HOURS_PER_DAY,
      CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "Process volumetry"
    }),
    question_slugs: [
      CustomQuestionSlugs.TASK_PROCESS_FREQUENCY,
      CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE,
      CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE_FREQUENCY,
      CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK,
      CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION,
      CustomQuestionSlugs.TOTAL_PROCESSING_TIME,
      CustomQuestionSlugs.AVG_ERROR_RATE,
      CustomQuestionSlugs.AVG_REWORK_TIME,
      CustomQuestionSlugs.TOTAL_REWORK_TIME,
      CustomQuestionSlugs.AVG_WORK_REVIEWED,
      CustomQuestionSlugs.AVG_REV_TIME,
      CustomQuestionSlugs.TOTAL_REV_TIME,
      CustomQuestionSlugs.TOTAL_TIME_NEEDED_AS_IS,
      CustomQuestionSlugs.FTE_REQUIRED,
      CustomQuestionSlugs.COST_PER_YEAR_AS_IS
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "Process ways of working"
    }),
    question_slugs: [
      CustomQuestionSlugs.PROCESS_PEAKS,
      CustomQuestionSlugs.AVERAGE_NUMBER_OF_STEPS,
      CustomQuestionSlugs.NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplateWithTitleAndDescription({
      title: "Data input"
    }),
    question_slugs: [
      CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT,
      CustomQuestionSlugs.SCANNED_DATA_INPUT,
      CustomQuestionSlugs.PCT_STRUCTURED_DIGITAL_DATA_INPUT
    ]
  });

  sectionTemplate = sectionTemplateWithTitleAndDescription({
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_APPLICATIONS_SECTION_V1,
    title: "Applications",
    note: "The Applications Section is currently unavailable for customization."
  });

  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.APPLICATIONS,
      CustomQuestionSlugs.APPLICATIONS_THIN,
      CustomQuestionSlugs.APPLICATION_THIN_CLIENT_MULTIPLIER,
      CustomQuestionSlugs.APPLICATION_NUMBER_MULTIPLIER
    ]
  });

  // need to override some section controls
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Assessment KPIs",
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_DEFAULT_SIDEBAR_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.AUTOMATION_POTENTIAL,
      CustomQuestionSlugs.EASE_OF_IMPLEMENTATION,
      CustomQuestionSlugs.EASE_OF_IMPLEMENTATION_LEVEL,
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS,
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY,
      CustomQuestionSlugs.BENEFIT_PER_COMPANY_FTE,
      CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_HOURS,
      CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_CURRENCY,
      CustomQuestionSlugs.BENEFIT_PER_EMPLOYEE_FTES,
      CustomQuestionSlugs.CHART_AUTOMATION_POTENTIAL // this goes at the end when inserting because it needs the prior questions in context to convert the equations to ahids
    ]
  });
  addNewQ2Sections(context, assessment);
};

export const addNewQ2Sections = (context, assessment) => {
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    sectionTemplateSlug: COSectionTemplateSlugs.Q2_DEFAULT_SECTION_V1,
    title: "Post automation results",
    description: "",
    condition_overrides: [
      { slug: COConditionSlugs.EDITABLE_PRE_PUBLISH },
      { slug: COConditionSlugs.SECTION_SHOWN_AFTER_LIVE }
    ]
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION,
      CustomQuestionSlugs.DA_AVG_ERROR_RATE_AFTER_AUTOMATION,
      CustomQuestionSlugs.DA_AVG_REWORK_TIME_AFTER_AUTOMATION,
      CustomQuestionSlugs.DA_LIVE_BENEFIT_EM_HRS_MO
    ],
    ahid_modifier: "pa"
  });
  sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Assign Process Owner",
    description: "Assign a Process Owner to review the filled in information.",
    sectionTemplateSlug:
      CONonStandardSectionTemplateSlugs.PROCESS_OWNER_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [CustomQuestionSlugs.PROCESS_OWNER_Q2],
    ahid_modifier: "po"
  });
};

export const assessmentTemplateQ2 = (
  isDefault: boolean = true
): COAssessment => {
  let assessmentTemplate = defaultQ2AssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Detailed Assessment",
      description:
        "You need to answer all the questions in this section (except Average Employee Full Cost, Error Rate and Review/Audit related questions) in order to submit the assessment."
    });

    if (!isDefault) {
      assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
        title: {
          value: "New Detailed Assessment"
        },
        description: {
          value: draftJSMetaDescriptionString("")
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      };
    }
  }
  return assessmentTemplate;
};

export const addCustomQuestionsSectionToAssessment = (
  context,
  assessment
): COSection | undefined => {
  let customQuestionSectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Additional Information"
  });
  if (customQuestionSectionTemplate.co_section_json?.co_section_options_json) {
    customQuestionSectionTemplate.co_section_json.co_section_options_json.legacy_slug = CO_SECTION_CUSTOM_QUESTIONS_LEGACY_SLUG;
  }
  return (
    addSectionAndQuestions({
      context,
      assessment,
      section_template: customQuestionSectionTemplate,
      question_slugs: []
    }) || undefined
  );
};
