import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import { updateMetaDataValues } from "../../utils/co-template.utils";
import {
  COAssessmentTypes,
  COComponentType
} from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import { COSectionTemplateSlugs } from "../../constants/co-section.constants";

export const cbaAssessmentTemplate = (): COAssessmentTemplateInterface => {
  return {
    co_assessment_type: COAssessmentTypes.COST_BENEFIT_ANALYSIS_ASSESSMENT_TYPE,
    co_assessment_template_slug: COAssessmentTemplateSlugs.CBA_DEFAULT_V1,
    co_meta_json: {
      title: {
        position: { sort_order: 0 },
        options: { component_type: COComponentType.META_H2_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.TITLE.VALUE
        })
      },
      description: {
        position: { sort_order: 1 },
        options: {
          component_type: COComponentType.META_PARAGRAPH_TEXT
        },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.DESCRIPTION
              .VALUE
        })
      }
    },
    co_version_meta_json: {
      title: {
        position: { sort_order: 0 },
        options: { component_type: COComponentType.META_H2_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON.TITLE
              .VALUE
        })
      },
      version: {
        position: { sort_order: 1 },
        options: { component_type: COComponentType.META_H3_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
              .VERSION.VALUE
        })
      },
      version_number: {
        label: "",
        position: { sort_order: 2 },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
              .VERSION_NUMBER.VALUE
        }),
        options: {
          component_type: COComponentType.META_TEXT,
          is_hidden: 1
        }
      },
      description: {
        position: { sort_order: 3 },
        options: { component_type: COComponentType.META_PARAGRAPH_TEXT },
        value: PATH({
          route:
            PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_VERSION_META_JSON
              .DESCRIPTION.VALUE
        })
      }
    },
    co_controls_json: [],
    co_validators_json: [],
    co_assessment_json: {
      co_assessment_type:
        COAssessmentTypes.COST_BENEFIT_ANALYSIS_ASSESSMENT_TYPE,
      co_assessment_template_slug: COAssessmentTemplateSlugs.CBA_DEFAULT_V1,
      co_assessment_meta_json: {
        title: {
          value: "CBA Assessment"
        },
        description: {
          value: ""
        }
      },
      co_assessment_options_json: {},
      co_assessment_version_meta_json: {
        title: {
          value: "CBA"
        },
        description: {
          value: "The default CBA that ships with Automation Hub"
        },
        version: {
          value: "1.0"
        },
        version_number: {
          value: "1"
        }
      },
      is_deleted: 0,
      created_epoch: 0,
      updated_epoch: 0
    }
  };
};

export const AHDefaultCBAAssessmentJSON = () => {
  let template = assessmentTemplateCBA();
  if (template) {
    let assessment = new COAssessment({ template });
    let context = new COContext({
      assessment,
      validation_context: { assessment_customize: 1 }
    });
    assessment.co_assessment_ahid = `ah-assessment-cba-0-0`;
    addDefaultSections({ context, assessment });
    COAssessment.prepareAssessmentEquations(assessment);
    let jsoned = JSON.stringify(assessment);
    let parsed = JSON.parse(jsoned);
    return parsed;
  }
  return null;
};

export const assessmentTemplateCBA = (): COAssessment | null => {
  let assessmentTemplate = cbaAssessmentTemplate();
  if (assessmentTemplate) {
    if (
      assessmentTemplate &&
      assessmentTemplate.co_assessment_json?.co_assessment_meta_json
    ) {
      updateMetaDataValues({
        metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
        title: "Cost Benefit Analysis",
        description: ""
      });
    }
    return assessmentTemplate;
  } else {
    console.log(`unable to load assessment template for cba`);
  }
  return null;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "KPIs",
    sectionTemplateSlug: COSectionTemplateSlugs.CBA_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: []
  });
};
