import SessionHelper from "./SessionHelper";
import {
  TENANT_SETTINGS_SESSION_KEY,
  TENANT_SETTINGS_FILE_UPLOAD_DISPLAY,
  TENANT_SETTINGS_COMPONENTS_DISPLAY,
  TENANT_SETTINGS_TASK_CAPTURE_DISPLAY,
  TENANT_SETTINGS_LEADERBOARD_DISPLAY,
  TENANT_SETTINGS_EXECUTABLE_FILE_DISPLAY,
  TENANT_SETTINGS_BINARY_ZERO,
  TENANT_SETTINGS_SHARE_AUTOMATION_SOURCE_CODE_MANDATORY,
  TENANT_SETTINGS_AUTOMATION_STORE,
  TENANT_SETTINGS_INSTANT_CONSUMPTION,
  TENANT_SETTINGS_USER_GAMIFICATION,
  TENANT_SETTINGS_EXPLORE_USERS_AND_PROFILE,
  TENANT_SETTINGS_COMMENTS_AND_QUESTIONS_DISPLAY
  // TENANT_SETTINGS_CITIZEN_DEVELOPMENT_DISPLAY
} from "../constants/tenantSettingConstants";

export const showSection = key => {
  const tenantSettings = TenantSettingsHelper.getSettings();
  if (tenantSettings) {
    const settings = tenantSettings[key];
    if (
      settings &&
      settings.setting_option_slug === TENANT_SETTINGS_BINARY_ZERO
    ) {
      return false;
    }
  }
  return true;
};

const TenantSettingsHelper = {
  setSettings: settings => {
    let settingsDict = {};
    (settings || []).forEach(setting => {
      settingsDict[setting.setting_key] = setting;
    });
    SessionHelper.setDataForKey(settingsDict, TENANT_SETTINGS_SESSION_KEY);

    return settings;
  },
  getSettings: () => {
    const settings = SessionHelper.getValueForKey(TENANT_SETTINGS_SESSION_KEY);
    return settings;
  },
  showCommentsAndQuestions: () => {
    return showSection(TENANT_SETTINGS_COMMENTS_AND_QUESTIONS_DISPLAY);
  },
  showLeaderboard: () => {
    return showSection(TENANT_SETTINGS_LEADERBOARD_DISPLAY);
  },
  showComponents: () => {
    return showSection(TENANT_SETTINGS_COMPONENTS_DISPLAY);
  },
  showFileUploads: () => {
    return showSection(TENANT_SETTINGS_FILE_UPLOAD_DISPLAY);
  },
  showTaskCapture: () => {
    return showSection(TENANT_SETTINGS_TASK_CAPTURE_DISPLAY);
  },
  showExecutableFile: () => {
    return showSection(TENANT_SETTINGS_EXECUTABLE_FILE_DISPLAY);
  },
  isShareAutomationSourceCodeMandatory: () => {
    return showSection(TENANT_SETTINGS_SHARE_AUTOMATION_SOURCE_CODE_MANDATORY);
  },
  isAutomationStoreEnabled: () => {
    return showSection(TENANT_SETTINGS_AUTOMATION_STORE);
  },
  isInstantConsumptionEnabled: () => {
    // Instant consumption is dependent on automation store. Now that automation store is controlled in the cloud,
    // I am unable to toggle this off automatically, so we have to do 2 checks here.
    return (
      showSection(TENANT_SETTINGS_AUTOMATION_STORE) &&
      showSection(TENANT_SETTINGS_INSTANT_CONSUMPTION)
    );
  },
  isUserGamificationEnabled: () => {
    return showSection(TENANT_SETTINGS_USER_GAMIFICATION);
  },
  isExploreUsersAndUserProfileEnabled: () => {
    return showSection(TENANT_SETTINGS_EXPLORE_USERS_AND_PROFILE);
  }
  /* 
  Disabling on the backend for now 
  showCitizenDevelopment: () => {
    return showSection(TENANT_SETTINGS_CITIZEN_DEVELOPMENT_DISPLAY);
  }
  */
};

export default TenantSettingsHelper;
