import {
  COEmailReminderIntervalOptionSlugs,
  CONumberOfEmailReminderOptionSlugs,
  COTenantSettingsKeys
} from "../constants/co-tenant-settings.constants";
import { COTenantSettingsDataInterface } from "../interfaces/co-interfaces";

export const numberOfEmailRemindersForSetting = ({
  setting
}: {
  setting?: COTenantSettingsDataInterface;
}): number => {
  // default to 3 reminders
  const defaultNumberOfReminders = 3;
  if (
    !setting ||
    setting.setting_key !== COTenantSettingsKeys.NUMBER_OF_EMAIL_REMINDERS
  ) {
    return defaultNumberOfReminders;
  }

  switch (setting.setting_option_slug) {
    case CONumberOfEmailReminderOptionSlugs.ONE_REMINDER: {
      return 1;
    }
    case CONumberOfEmailReminderOptionSlugs.TWO_REMINDERS: {
      return 2;
    }
    case CONumberOfEmailReminderOptionSlugs.THREE_REMINDERS: {
      return 3;
    }
    case CONumberOfEmailReminderOptionSlugs.FOUR_REMINDERS: {
      return 4;
    }
    case CONumberOfEmailReminderOptionSlugs.FIVE_REMINDERS: {
      return 5;
    }
  }
  return defaultNumberOfReminders;
};

export const emailRemindersIntervalForSetting = ({
  setting,
  isProduction
}: {
  setting?: COTenantSettingsDataInterface;
  isProduction: boolean;
}): number => {
  // default to 7 days (7 minutes for non-prod)
  const epochFactor = isProduction ? 86400000 : 60000;
  const defaultInterval = epochFactor * 7;
  if (
    !setting ||
    setting.setting_key !== COTenantSettingsKeys.EMAIL_REMINDER_INTERVAL
  ) {
    return defaultInterval;
  }

  switch (setting.setting_option_slug) {
    case COEmailReminderIntervalOptionSlugs.ONE_DAY: {
      return epochFactor;
    }
    case COEmailReminderIntervalOptionSlugs.THREE_DAYS: {
      return epochFactor * 3;
    }
    case COEmailReminderIntervalOptionSlugs.FIVE_DAYS: {
      return epochFactor * 5;
    }
    case COEmailReminderIntervalOptionSlugs.SEVEN_DAYS: {
      return epochFactor * 7;
    }
    case COEmailReminderIntervalOptionSlugs.TEN_DAYS: {
      return epochFactor * 10;
    }
    case COEmailReminderIntervalOptionSlugs.FIFTEEN_DAYS: {
      return epochFactor * 15;
    }
    case COEmailReminderIntervalOptionSlugs.TWENTY_DAYS: {
      return epochFactor * 20;
    }
    case COEmailReminderIntervalOptionSlugs.TWENTY_FIVE_DAYS: {
      return epochFactor * 25;
    }
    case COEmailReminderIntervalOptionSlugs.THIRTY_DAYS: {
      return epochFactor * 30;
    }
  }
  return defaultInterval;
};
