import React, { useCallback, useRef, useState } from "react";
import useResizeObserver from "../../../utils/customHooks/useResizeObserver";

const withResizeObserver = (Component: React.ComponentType<any>) => props => {
  const [isCallingObserverCallback, setIsCallingObserverCallback] = useState(
    false
  );
  const observerContainerRef: React.MutableRefObject<null | HTMLElement> = useRef(
    null
  );

  const callObserverCallback = useCallback(() => {
    setIsCallingObserverCallback(true);

    setTimeout(() => {
      setIsCallingObserverCallback(false);
    });
  }, []);

  useResizeObserver({
    callback: callObserverCallback,
    elementRef: observerContainerRef
  });

  return (
    <Component
      observerContainerRef={observerContainerRef}
      isCallingObserverCallback={isCallingObserverCallback}
      {...props}
    />
  );
};

export default withResizeObserver;
