import {
  COPhaseStatusKeys,
  isPhaseSlugInRange
} from "../constants/co-phase-status.constants";
import {
  COAssessmentInterface,
  COCalculatedValueInterface,
  COCondition,
  COConditionCalculationInterface,
  COContextInterface,
  COQuestionInterface,
  COVisibilityConditionOperator
} from "../interfaces/co-interfaces";

import { isPath } from "../utils/co-path.utils";
import { resolve } from "../utils/co-resolver.utils";
import {
  doesExistPositiveContextMatch,
  isNullOrUndefined,
  isString
} from "../utils/co-utils";

// to do probably move to helper
export const calculatedValueForKeyOrColumnOrQuestion = ({
  context,
  co_calculated_value_key,
  process_column_name,
  co_question_ahid
}: {
  context: COContextInterface;
  co_calculated_value_key?: string;
  process_column_name?: string;
  co_question_ahid?: string;
}) => {
  // this is used for the conditional question stuff
  if (co_calculated_value_key || process_column_name || co_question_ahid) {
    if (context.process_external_data?.global_calculated_values) {
      let calculated_value: COCalculatedValueInterface | undefined =
        context.process_external_data.global_calculated_values[
          (co_calculated_value_key
            ? co_calculated_value_key
            : process_column_name
            ? process_column_name
            : co_question_ahid) || ""
        ];
      if (calculated_value) {
        // console.log(
        //   `found calculated value for key or column or question! ${co_question_ahid} ${process_column_name} ${co_calculated_value_key} ${calculated_value}`
        // );
        return calculated_value.co_calculated_value;
      }
    } else if (co_question_ahid) {
      console.log(
        "Context can't fetch external calculated values from external process data"
      );
      // we'll check the local context - this is slower but will work without an external process-context object
      const assessment: COAssessmentInterface | undefined = context.assessment;
      if (assessment) {
        let foundQuestions:
          | COQuestionInterface[]
          | undefined = assessment.findObjectsWithPropertyOfValue?.(
          "co_question_ahid",
          co_question_ahid
        );
        if (foundQuestions && foundQuestions.length > 0) {
          const foundQuestion = foundQuestions[0];
          if (foundQuestion.co_process_calculated_value) {
            console.log(
              `found calculated value for question! ${foundQuestion.co_process_calculated_value.co_calculated_value}`
            );
            return foundQuestion.co_process_calculated_value
              .co_calculated_value;
          }
        }
      }

      return undefined;
    }
  }
};

// this checks the conditions and phase status ranges to determine visibility
export const trueInContextForRangeConditions = ({
  context,
  condition
}: {
  context: COContextInterface;
  condition: COCondition;
}): boolean => {
  // no conditions means good to go
  if (!condition) {
    return false;
  }

  // we need to pass all of these - ANDed to be visible
  let visibility_condition = resolve({ context, item: condition });
  if (!visibility_condition) {
    console.log(`unable to resolve condition object ${visibility_condition}`);
  }

  let conditionPassed = false;
  if (
    !doesExistPositiveContextMatch(
      context?.display_context,
      visibility_condition.display_context
    )
  ) {
    return false;
  }
  if (
    visibility_condition.or_conditions &&
    visibility_condition.or_conditions.length > 0
  ) {
    conditionPassed = areCalculationConditionsTrue({
      context,
      conditions: visibility_condition.or_conditions
    });
  } else {
    conditionPassed = true;
  }
  // not in phase range
  return conditionPassed;
};

// this "ORs the conditions"
// these are all resolved before they get here
export const areCalculationConditionsTrue = ({
  context,
  conditions
}: {
  context: COContextInterface;
  conditions: COConditionCalculationInterface[];
}) => {
  if (!conditions || conditions.length == 0) {
    return true;
  }

  for (const condition of conditions) {
    if (condition?.debug_key) {
      console.log(`${condition.debug_key} - condition`);
      console.log(condition);
    }
    let desired_value = condition.desired_value; // this should already be resolved;
    if (isPath(desired_value)) {
      // actually I would very much like to resolve it here
      console.log(
        "visibilty condition target should already be resolved, not a path at this point"
      );
      return true;
    }
    if (condition.debug_key) {
      console.log(`${condition.debug_key} - desired_value ${desired_value}`);
    }
    // ok we find the thing to compare
    // if we don't have a current_value to compare with but we do have an operator it means the condition wants to try computing something
    // the current_value could just be null, which would lead most cases to fail
    let condition_check_passed = true;
    if (
      !isNullOrUndefined(condition.current_value) ||
      condition.operator ||
      condition.co_calculated_value_key ||
      condition.process_column_name ||
      condition.co_question_ahid
    ) {
      let value: any = undefined;
      // if we have current values that's a path or actual value then use it - if not try the calculated value key or process_column_name or co_question_ahid
      if (!isNullOrUndefined(condition.current_value)) {
        value = condition.current_value;
      } else {
        // let's look up the calculated value - this needs to be a fast lookup because this could run a lot
        value = calculatedValueForKeyOrColumnOrQuestion({
          context,
          co_calculated_value_key: condition.co_calculated_value_key,
          process_column_name: condition.process_column_name,
          co_question_ahid: condition.co_question_ahid
        });
      }

      if (condition.debug_key) {
        console.log(`${condition.debug_key} - value ${value}`);
      }

      condition_check_passed = false;

      // issue here that value can be like a draft js value and empty string - it's never going to be undefined if we calculate the value
      // ok we have a value
      switch (condition.operator) {
        case COVisibilityConditionOperator.EQUALS: {
          // lose check
          if (value == desired_value) {
            condition_check_passed = true;
          }
          break;
        }
        case COVisibilityConditionOperator.NOT_EQUALS: {
          if (isNullOrUndefined(value) || value != desired_value) {
            condition_check_passed = true;
          }
          break;
        }
        case COVisibilityConditionOperator.EXISTS: {
          if (value) {
            //if (!isNullOrUndefined(value)) {  TOOD need to have calculated values deal with undefined or something because 0 can be an answer
            condition_check_passed = true;
          }
          break;
        }
        case COVisibilityConditionOperator.NOT_EXISTS: {
          if (!value) {
            //if (isNullOrUndefined(value)) {
            condition_check_passed = true;
          }
          break;
        }
      }
      if (!isNullOrUndefined(value) && !isNullOrUndefined(desired_value)) {
        const value_num: number = parseFloat(value + "");
        const target_num: number = parseFloat(desired_value + "");
        switch (condition.operator) {
          case COVisibilityConditionOperator.LESS_THAN: {
            if (value_num < target_num) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.LESS_THAN_OR_EQUALS: {
            if (value_num <= target_num) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.MORE_THAN: {
            if (value_num > target_num) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.MORE_THAN_OR_EQUALS: {
            if (value_num >= target_num) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.MAX_PHASE_OR_STATUS: {
            if (
              isPhaseSlugInRange({
                phase_status_key: value,
                range: {
                  max_key: desired_value + ""
                }
              })
            ) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.MIN_PHASE_OR_STATUS: {
            if (
              isPhaseSlugInRange({
                phase_status_key: value,
                range: {
                  min_key: desired_value + ""
                }
              })
            ) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.BETWEEN_PHASE_OR_STATUSES: {
            let range = {
              min_key: (desired_value + "").split(",")?.[0] || undefined,
              max_key: (desired_value + "").split(",")?.[1] || undefined
            };
            if (
              isPhaseSlugInRange({
                phase_status_key: value,
                range: range
              })
            ) {
              condition_check_passed = true;
            }
            break;
          }
        }
      }

      // array things
      if (
        !condition_check_passed &&
        isString(desired_value) &&
        isString(value)
      ) {
        let desired_value_array = ((desired_value || "") + "").split(",");
        let value_array = ((value || "") + "").split(",");

        let num_common_elements = desired_value_array.filter(dv =>
          value_array.find(v => v == dv)
        ).length;
        let total_elements = Math.max(
          desired_value_array.length,
          value_array.length
        );

        switch (condition.operator) {
          case COVisibilityConditionOperator.HAS_ALL_COMMON_ELEMENTS: {
            if (num_common_elements === total_elements) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.HAS_COMMON_ELEMENT: {
            if (num_common_elements > 0) {
              condition_check_passed = true;
            }
            break;
          }
          case COVisibilityConditionOperator.HAS_NO_COMMON_ELEMENT: {
            if (num_common_elements === 0) {
              condition_check_passed = true;
            }
            break;
          }
        }
      }

      if (condition.debug_key) {
        console.log(
          `${condition.debug_key} - condition check passed ${condition_check_passed}`
        );
      }
    }

    if (
      condition_check_passed &&
      condition.and_conditions &&
      condition.and_conditions.length > 0
    ) {
      // all in the "and_conditions" must be true
      for (const and_condition of condition.and_conditions) {
        let check_passed = areCalculationConditionsTrue({
          context,
          conditions: [and_condition]
        });
        if (!check_passed) {
          condition_check_passed = false;
          break;
        }
      }
    }
    if (
      condition_check_passed &&
      condition.or_conditions &&
      condition.or_conditions.length > 0
    ) {
      // any in the "or_conditions" must be true
      let check_passed = areCalculationConditionsTrue({
        context,
        conditions: condition.or_conditions
      });
      if (!check_passed) {
        condition_check_passed = false;
        break;
      }
    }

    if (condition_check_passed) {
      return true;
    }
    // else keep looping through to the other OR conditions (top level)
  }
  return false;
};
