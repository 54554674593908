import { FETCH_LICENSE_PLAN_OPTIONS } from "../constants/actionTypes";
import {
  STATE,
  LICENSE_PLAN,
  LICENSE_PLAN_OPTIONS
} from "../constants/accountLicenseConstants";

const INITIAL_STATE = {
  licenseDropdownOptions: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_LICENSE_PLAN_OPTIONS:
      //variable called 'states' to avoid conflict
      const states = action.payload["states"];
      const license_plan_option = action.payload["license_options"];
      const license_plan = action.payload["license_plans"];
      return {
        ...state,
        licenseDropdownOptions: {
          [STATE]: states,
          [LICENSE_PLAN]: license_plan,
          [LICENSE_PLAN_OPTIONS]: license_plan_option
        }
      };
    default:
      return state;
  }
}
