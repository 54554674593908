import React from "react";
import axios from "../utils/axiosInstance";
import SessionHelper from "./SessionHelper";
import apiErrorAlert from "../utils/apiErrorAlert";
import swal from "../components/SweetAlert/SweetAlert";
import ToastBadgeSwal from "../components/Gamification/ToastBadgeSwal/ToastBadgeSwal";
import BadgePopup from "../components/Gamification/BadgePopup/BadgePopup";
import {
  FETCH_USER_STATUS,
  LEVEL_UP,
  IS_LEVEL_UP_MODAL_OPEN
} from "../constants/actionTypes";
import TenantSettingHelper from "./TenantSettingsHelper";

let statusIntervalLength = 30000;
let lastCompletedStatusCheck = 0;
let statusInterval;
let minutesBeforeStatusRetryIfNoResponse = 5;
let hasMadeFirstStatusCall = false;

export function refreshUserStatus() {
  return function(dispatch) {
    window.clearInterval(statusInterval);
    statusInterval = window.setInterval(() => {
      refreshStatus(dispatch);
    }, statusIntervalLength);

    refreshStatus(dispatch);
  };
}

// Status counter let's backend preform specific operations
// once every few status calls
let statusCounter = 0;

function refreshStatus(dispatch) {
  let user = SessionHelper.currentUser();
  if (!user || !user.user_profile_slug) {
    return;
  }
  const currentEpoch = new Date().getTime();

  // Check if status call has not been completed within the interval length
  // if it has been over minutesBeforeStatusRetryIfNoResponse we do not want to return and try the call again
  if (
    lastCompletedStatusCheck !== 0 &&
    currentEpoch - lastCompletedStatusCheck > statusIntervalLength + 3000 &&
    currentEpoch - lastCompletedStatusCheck <
      minutesBeforeStatusRetryIfNoResponse * 60 * 1000
  ) {
    return;
  }

  // On the first status call set lastCompletedStatusCheck so if the first call hangs it will be handled
  if (!hasMadeFirstStatusCall) {
    hasMadeFirstStatusCall = true;
    lastCompletedStatusCheck = 1;
  }

  // if it has been over minutesBeforeStatusRetryIfNoResponse we need to reset
  // lastCompletedStatusCheck so the call doesnt keep happening if there is still no response
  if (
    currentEpoch - lastCompletedStatusCheck >
    minutesBeforeStatusRetryIfNoResponse * 60 * 1000
  ) {
    lastCompletedStatusCheck = new Date().getTime() - statusIntervalLength;
  }

  statusCounter++;
  if (statusCounter > 100000) {
    statusCounter = 1;
  }

  const path = `/api/v1/users/slug/${user.user_profile_slug}/status?counter=${statusCounter}`;
  axios
    .get(path, {
      headers: {
        "x-ah-user-cloud-access-token": SessionHelper.getUserCloudAccessToken()
      }
    })
    .then(resp => {
      lastCompletedStatusCheck = new Date().getTime();
      if (!resp.data.data) {
        return;
      }

      let user_status = resp.data.data;
      handleNotifications(user_status.messages, user_status.badges);

      //disable until we want it handleWebNotifications(user_status);

      dispatch({
        type: FETCH_USER_STATUS,
        payload: user_status
      });

      updateLevelAndStatus(user_status);

      if (
        shouldShowLevelUpModal(resp.data.data) &&
        TenantSettingHelper.isUserGamificationEnabled()
      ) {
        if (
          user_status.levels &&
          user_status.levels.length > 0 &&
          user_status.statusPoints
        ) {
          dispatch({
            type: LEVEL_UP,
            payload: user_status
          });
          dispatch({
            type: IS_LEVEL_UP_MODAL_OPEN,
            payload: true
          });
        }
      }
    })
    .catch(error => {
      lastCompletedStatusCheck = new Date().getTime();
      apiErrorAlert(error);
    });
}

function updateLevelAndStatus(statusObj) {
  if (
    statusObj.statusPoints &&
    statusObj.statusPoints.user_points !== null &&
    statusObj.statusPoints.user_points >= 0
  ) {
    SessionHelper.setUserStatusPoints(statusObj.statusPoints.user_points);
  }
  if (
    statusObj.statusPoints &&
    statusObj.statusPoints.user_exchangeable_points !== null &&
    statusObj.statusPoints.user_exchangeable_points >= 0
  ) {
    SessionHelper.setUserExchangeablePoints(
      statusObj.statusPoints.user_exchangeable_points
    );
  }
  if (
    statusObj.levels &&
    statusObj.levels.length > 0 &&
    statusObj.levels[0].level_name &&
    statusObj.levels[0].level_slug
  ) {
    SessionHelper.setUserLevel(
      statusObj.levels[0].level_name,
      statusObj.levels[0].level_slug
    );
  }
}

function shouldShowLevelUpModal(statusObj) {
  if (
    statusObj.levels &&
    statusObj.levels.length > 0 &&
    statusObj.levels[0].has_seen === 0
  ) {
    return true;
  }
  return false;
}

function handleNotifications(messages, badges) {
  let popup_queue = [];

  if (messages) {
    //QUEUE messages
    messages.forEach(message => {
      let seenKey = "message_" + message.message_id;
      //  messages:[
      //   {
      //         message_id:1,
      //         message_type:1,
      //         message_title:"Hello",
      //         message_text:"This is a message from the backend!",
      //         message_redirect_url:"",
      //         message_show_epoch:0,
      //         message_exp_epoch:0,
      //   }]
      if (SessionHelper.getValueForKey(seenKey).length === 0) {
        let item = {};
        item.type = "message";
        item.swal_type = "info";
        item.title = message.message_title;
        item.text = message.message_text;
        item.after_function = () => {
          SessionHelper.setDataForKey(true, seenKey);
          //put callback or cookie log here
          if (message.message_redirect_url) {
            window.location = message.message_redirect_url;
          }
        };
        popup_queue.push(item);
      }
    });
  }

  if (badges && TenantSettingHelper.isUserGamificationEnabled()) {
    badges.forEach(badge => {
      let seenKey = "badges_" + badge.badge_id;
      if (SessionHelper.getValueForKey(seenKey).length === 0) {
        let item = {};
        item.type = "badge";
        item.seenKey = seenKey;
        item.badge_slug = badge.badge_slug || "";
        item.after_function = () => {
          // Bagde was not going away, force it's removal
          const el = document.querySelector(".swal2-container");
          if (el) {
            el.parentElement.removeChild(el);
          }
          SessionHelper.setDataForKey(true, seenKey);
          let user = SessionHelper.currentUser();
          if (!user || !user.user_profile_slug) {
            return;
          }
          let path =
            "/api/v1/users/" + user.user_id + "/badges/" + badge.badge_id;
          axios
            .patch(path)
            .then(() => {})
            .catch(error => apiErrorAlert(error));
        };
        item.html = (
          <BadgePopup badge={badge} clickHandler={item.after_function} />
        );
        popup_queue.push(item);
      }
    });
  }
  setTimeout(() => {
    recursivePopupFunction([...popup_queue]);
  }, 2000);
}

function recursivePopupFunction(popup_queue) {
  if (popup_queue.length > 0 && !swal.isVisible()) {
    let popup_data = popup_queue[0];
    let popup = swal;
    if (popup_data.type === "badge") {
      popup = ToastBadgeSwal;
      if (SessionHelper.getValueForKey(popup_data.seenKey).length !== 0) {
        popup_queue.shift();
        recursivePopupFunction(popup_queue);
        return;
      }
    }

    popup
      .fire({
        html: popup_data.html,
        type: popup_data.swal_type,
        title: popup_data.title,
        text: popup_data.text,
        showCancelButton: false
      })
      .then(e => {
        if (popup_data.after_function) {
          popup_data.after_function();
        }
        popup_queue.shift();
        recursivePopupFunction(popup_queue);
      });
  } else if (popup_queue.length > 0) {
    setTimeout(() => {
      recursivePopupFunction(popup_queue);
    }, 10000);
  }
}

function handleWebNotifications(user_status) {
  if (user_status && user_status.num_notifications > 0) {
    let user = SessionHelper.currentUser();
    if (!user || !user.user_id) {
      return;
    }

    axios
      .get(`/api/v1/users/${user.user_id}/notifications?limit=1`)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          checkPremissionsAndSendNotification(response.data.data[0]);
        }
      })
      .catch(error => {
        apiErrorAlert(error);
      });
  }
}

function checkPremissionsAndSendNotification(notificationObject) {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    sendWebNotification(notificationObject);
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function(permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        sendWebNotification(notificationObject);
      }
    });
  }
}

function sendWebNotification(notificationObject) {
  let seenKey =
    "user_web_notification_" + notificationObject.user_notification_id;
  if (SessionHelper.getValueForKey(seenKey).length === 0) {
    var img = "/favicon.ico";
    var text = notificationObject.user_notification_text;
    if (text) {
      // eslint-disable-next-line no-unused-vars
      var notification = new Notification("UiPath Automation Hub", {
        body: text,
        icon: img
      });
    }
    SessionHelper.setDataForKey(true, seenKey);
  }
}
