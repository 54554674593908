import { COAssessment } from "./co-assessment.class";
import { COContext } from "./co-context.class";
import { assessmentIsEmpty } from "../helpers/co-assessment.helper";
import {
  COContextInterface,
  COContextsByType,
  CODisplayContext,
  COExternalCallbackInterface,
  COProcess,
  COProcessContextInterface,
  COValidationContext
} from "../interfaces/co-interfaces";
import {
  COAssessmentTypeInfo,
  COAssessmentTypes
} from "../constants/co-constants";
import { COProcessExternalData } from "./co-process-external-data.class";
import { answerContextQuestionsWithUpdatedExternalValues } from "../helpers/co-process-external-data.helper";
import {
  setProcessCallbacks,
  setProcessData
} from "../helpers/co-context-external-data.helper";
import { updateContext } from "./co-context.class";
import { isNullOrUndefined } from "../utils/co-utils";

export interface COProcessContext extends COProcessContextInterface {}
export class COProcessContext {
  dont_resolve?: boolean = true;
  constructor({
    process,
    callbacks,
    display_context,
    ah_permissions
  }: {
    process: COProcess;
    callbacks?: COExternalCallbackInterface;
    display_context?: CODisplayContext;
    ah_permissions?: any;
  }) {
    this.calculated_values = {};
    this.co_contexts = {};
    this.display_context = {};
    this.process_external_data = new COProcessExternalData();
    this.process_callbacks = callbacks || {};
    this.updateExternalData?.({
      global_calculated_values: this.calculated_values,
      process_id: process?.process_id,
      phase_status_key:
        process?.process_phase_status?.phase_status_key ||
        process?.phase_status_key ||
        undefined,
      process: process,
      document_data: {
        documents: process?.documents || []
      },
      application_data: {
        applications: process?.applications
      },
      category_data: {
        selected_categories: process?.categories
      },
      currency: process?.currency,
      permissions: {
        process_permissions: process?.permissions || {},
        ah_permissions: ah_permissions || {}
      },
      collaborator_data: {
        process_owner: process?.process_owner,
        process_submitter: process?.process_submitter,
        collaborators: process?.collaborators || []
      },
      media_data: {
        media: process?.media || [] // I don't think this is loaded here
      },
      similar_automations: {
        processes: process?.similar_automations || []
      },
      tag_data: {
        tags: process?.process_tags || []
      },
      component_data: {
        components: process?.components || []
      }
    });

    // this is for new contexts to be made from JSON
    let co_assessments = process.co_assessments || {};
    if (!process.co_assessments) {
      console.log(
        "warning can not create process-context, empty co_assessment object on process"
      );
    }

    for (const co_assessment_type of Object.keys(co_assessments)) {
      if (!assessmentIsEmpty(co_assessments[co_assessment_type])) {
        // want todo this in the right order so we have access to external data fron the get-go
        let assessment =
          COAssessment.objectFromJSON(co_assessments[co_assessment_type]) || {};
        // set the assessment context for each assessment in the array
        let context = new COContext({
          assessment,
          display_context: display_context,
          validation_context: display_context
        });
        this.co_contexts[co_assessment_type] = context;
        setProcessData(context, this.process_external_data);
        setProcessCallbacks({ ...this.process_callbacks, context });
        // want to prep after setting the process data
        COAssessment.prepForSubmissionViewOrEdit({ context, assessment });
        answerContextQuestionsWithUpdatedExternalValues({
          context
        });
      }
    }
    this.updateCalculatedValues?.({ recalc: true });
  }

  currentAssessmentIds?() {
    let current_assessment_ids: number[] = Object.keys(
      this.co_contexts || {}
    ).map(co_assessment_type => {
      return (
        (this.co_contexts || {})[co_assessment_type].assessment
          ?.co_assessment_id || 0
      );
    });
    return current_assessment_ids;
  }
  // OK now this is organized - everything runs through here

  visibleContexts?(display_context?: CODisplayContext) {
    if (!this.co_contexts) {
      return [];
    }
    let contexts: COContextInterface[] = Object.keys(this.co_contexts)
      .map(co_assessment_type => (this.co_contexts || {})[co_assessment_type])
      .sort((a, b) => {
        return (
          (COAssessmentTypeInfo?.[a.assessment?.co_assessment_type || ""]
            ?.order || 0) -
          (COAssessmentTypeInfo?.[b.assessment?.co_assessment_type || ""]
            ?.order || 0)
        );
      });
    contexts = contexts.filter(ctx => {
      const context = isNullOrUndefined(display_context)
        ? ctx
        : updateContext(ctx, {
            display_context: display_context
          });
      return context.assessment?.isVisibleInContext?.(context);
    });

    return contexts;
  }

  // this is for swapping in new contexts (like on assessment swap)
  updateContexts?(co_contexts: COContextsByType) {
    this.co_contexts = co_contexts;
    for (const co_assessment_type of Object.keys(co_contexts)) {
      if (
        !assessmentIsEmpty(co_contexts?.[co_assessment_type]?.assessment || {})
      ) {
        // set the assessment context for each assessment in the array
        let ctx = new COContext({
          assessment: co_contexts?.[co_assessment_type]?.assessment,
          display_context: this.display_context,
          validation_context: this.display_context
        });
        ctx = setProcessData(ctx, this.process_external_data || {});
        ctx = setProcessCallbacks({
          context: ctx,
          ...(this.process_callbacks || {})
        });
        this.co_contexts[co_assessment_type] = ctx;
      }
    }
    this.updateCalculatedValues?.({ recalc: true });
  }

  updateDisplayContext?(display_context: CODisplayContext) {
    this.display_context = display_context;
    if (this.co_contexts) {
      this.updateContexts?.(this.co_contexts);
    }
  }

  visibleAssessments?() {
    return (this.visibleContexts?.() || []).map(context => context.assessment);
  }

  /// this builds a local index of calculated values by calculated value key, process column name, and question ahid after re-calculation
  updateCalculatedValues?({ recalc = false }: { recalc: boolean }) {
    if (this.co_contexts) {
      if (!this.calculated_values) {
        this.calculated_values = {};
      }
      for (const co_assessment_type of Object.keys(this.co_contexts)) {
        let context: COContextInterface | undefined = this.co_contexts[
          co_assessment_type
        ];
        let assessment = context?.assessment;
        if (assessment) {
          if (recalc) {
            COAssessment.calculate({ context });
          }
          for (const section of assessment.co_assessment_sections || []) {
            for (const question of section.co_questions || []) {
              if (question.co_process_calculated_value) {
                if (
                  question.co_process_calculated_value.co_calculated_value_key
                ) {
                  this.calculated_values[
                    question.co_process_calculated_value.co_calculated_value_key
                  ] = question.co_process_calculated_value;
                }
                if (question.co_process_calculated_value.process_column_name) {
                  this.calculated_values[
                    question.co_process_calculated_value.process_column_name
                  ] = question.co_process_calculated_value;
                }
                if (question.co_question_ahid) {
                  this.calculated_values[question.co_question_ahid] =
                    question.co_process_calculated_value;
                }
              }
            }
          }
        }
      }
    }
    // console.log(
    //   "calculated values re-calculated - should show up in context.globals"
    // );
    // console.log(this.calculated_values);
    // console.log(this.co_contexts);
  }

  calculatedValueValueForKey?(key: string) {
    return this.calculated_values?.[key] || null;
  }

  updateExternalData?(processExternalData: COProcessExternalData) {
    this.process_external_data?.updateFromProcess?.(processExternalData);
    //console.log("updating external data - re-save external data questions");
    for (const context of this.visibleContexts?.() || []) {
      let anyContext: any = context;
      answerContextQuestionsWithUpdatedExternalValues({
        context: anyContext
      });
    }
  }

  incrementRenderCounters?() {
    for (const context of this.visibleContexts?.() || []) {
      COAssessment.incrementRenderCounters(context, context.assessment || {});
    }
  }

  calculatedAfterDebounce?({
    co_assessment_type,
    reRenderCallback,
    onUpdateProcessContext
  }: {
    co_assessment_type: COAssessmentTypes;
    reRenderCallback?: () => void;
    onUpdateProcessContext?: (process_context: COProcessContext) => void;
  }) {
    console.log(
      `handleAssessmentChange in process-context - ${co_assessment_type}`
    );
    if (calculateDebounce) {
      clearTimeout(calculateDebounce);
    }
    calculateDebounce = setTimeout(() => {
      let context: COContextInterface | undefined = this.co_contexts?.[
        co_assessment_type
      ];
      if (context) {
        let recalculatedContext = COAssessment.calculate({ context });
        this.updateCalculatedValues?.({ recalc: false });

        if (!recalculatedContext) {
          // console.log("disaster re-calculating assessment");
        } else {
          // we need to re-render
          if (onUpdateProcessContext) {
            onUpdateProcessContext(this);
          }
          if (reRenderCallback) {
            COAssessment.incrementRenderCounters(
              context,
              context.assessment || {}
            );
            reRenderCallback();
          }
        }
      }
    }, 700);
  }
}

let calculateDebounce: any = null;
