import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SessionHelper from "./../../helpers/SessionHelper";
import urlWithTenant from "../../utils/urlHelper";
import styled, { css } from "styled-components/macro";
import LandingPageMaskImage from "../../assets/images/landing_images/fps_landing_video_mask.png";
import PlayIcon from "../../assets/images/landing_images/play_circle_outline_24px.png";
import automationIcon from "./../../assets/images/branded-icons/automation.svg";
import automationIconDark from "./../../assets/images/branded-icons/automation-dark.svg";
import chartIcon from "./../../assets/images/branded-icons/chart.svg";
import chartIconDark from "./../../assets/images/branded-icons/chart-dark.svg";
import labelIcon from "./../../assets/images/branded-icons/label.svg";
import labelIconDark from "./../../assets/images/branded-icons/label-dark.svg";
import manageDocumentsIcon from "./../../assets/images/branded-icons/manage-documents.svg";
import manageDocumentsIconDark from "./../../assets/images/branded-icons/manage-documents-dark.svg";
import managingAltIcon from "./../../assets/images/branded-icons/managing-alt.svg";
import managingAltIconDark from "./../../assets/images/branded-icons/managing-alt-dark.svg";
import visibilityOnIcon from "./../../assets/images/branded-icons/visibility-on.svg";
import visibilityOnIconDark from "./../../assets/images/branded-icons/visibility-on-dark.svg";
import ModalVideo from "react-modal-video";
import countries from "./../../constants/countries.json";
import { enableAutomationHub } from "../../pages/SignupPage/SignupTenantForm/FPSActions";
import {
  FPS_CREATE_NEW_INSTANCE,
  triggerAnalyticsWithDefaultData
} from "../../utils/analyticsUtils";
import ButtonApolloPrimary from "../../components/buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloText from "../../components/buttons/ButtonApolloText/ButtonApolloText";
import ImageThemed from "../../components/ImageThemed/ImageThemed";

const CONTAINER_MAX_WIDTH = `1272px`;

const landingPageData = [
  {
    id: 1,
    icon: chartIcon,
    iconDark: chartIconDark,
    title: "Spread RPA throughout your company",
    content:
      "Engage and reward employees for contributing to the automation program with the Enterprise Community feature."
  },
  {
    id: 2,
    icon: automationIcon,
    iconDark: automationIconDark,
    title: "Visualize and control your automation program instantly",
    content: "Measure & track the performance of your automation program"
  },
  {
    id: 3,
    icon: managingAltIcon,
    iconDark: managingAltIconDark,
    title:
      "Select RPA candidates in a controlled, consistent and data-driven way ",
    content: "Choose the right automation oppotunities with the ROI Calculator"
  },
  {
    id: 4,
    icon: visibilityOnIcon,
    iconDark: visibilityOnIconDark,
    title: "Accelerate your implementation speed",
    content: "Develop faster with the Component Gallery"
  },
  {
    id: 5,
    icon: labelIcon,
    iconDark: labelIconDark,
    title: "Manage your automation pipeline centrally ",
    content:
      "Centralize control of your automation initiatives across your company (even in multivendor RPA environments) "
  },
  {
    id: 6,
    icon: manageDocumentsIcon,
    iconDark: manageDocumentsIconDark,
    title: "Create a centralized repository of all automation documentation",
    content:
      "Store all automation materials in one place with the Documentation Repository"
  }
];

const LoginHero = styled.div<{ showSelectTenantBox: boolean }>(
  props => css`
    background-size: cover;
    background-position: center;
    /* hides scrollbar */
    ${props.showSelectTenantBox
      ? `
    width: calc(100% - ${props.theme.sizes["fps-side-nav-width"]});
    position: fixed;
    `
      : ""}
  `
);

const LoginHeroContainer = styled.div(
  props => css`
    text-align: center;
    background-size: cover;
    background-position: center;
    background: var(--color-background-secondary, ${props.theme.colors.ink150});
    min-height: 300px;

    .LoginHero-play-icon {
      width: 50px;
      height: 50px;
      margin: 5px 0 0 0;
      cursor: pointer;
    }
  `
);

const LoginHeroInner = styled.div(
  props => css`
    padding: 30px ${props.theme.sizes["container-side-padding"]};
    max-width: ${CONTAINER_MAX_WIDTH};
    display: flex;
    flex-direction: row;
    margin: 0 auto 30px auto;

    @media (max-width: 999px) {
      display: block;
    }
  `
);

const LoginHeroLeft = styled.div`
  width: calc(66.66% - 15px);
  margin-right: 30px;

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const LoginHeroRight = styled.div`
  width: calc(33.33% - 15px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
    display: block;
    margin: auto;
  }
`;

const LoginHeroTitle = styled.h2(
  props => css`
    color: var(--color-foreground, ${props.theme.colors.gray600});
    letter-spacing: 0px;
    margin-bottom: 30px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;

    @media (max-width: 999px) {
      font-size: 32px;
      line-height: 44px;
      text-align: center;
    }
  `
);

const LoginHeroSubtitle = styled.h4(
  props => css`
    text-align: left;
    color: var(--color-foreground-de-emp, ${props.theme.colors.ink550});
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 30px;

    @media (max-width: 999px) {
      text-align: center;
    }
  `
);

const LoginHeroButtonContainer = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
  }
`;

const LoginHeroRightImageContainer = styled.div`
  max-width: 446px;
  margin-left: auto;
  position: relative;
  cursor: pointer;

  @media (max-width: 1255px) {
    margin-left: unset;
  }

  @media (max-width: 1000px) {
    margin: auto;
  }
`;

const LoginHeroRightImage = styled.img`
  width: 100%;
`;

const LoginHeroRightImageIcon = styled.img`
  width: 38px;
  height: 38px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const LoginBottomContainer = styled.div(
  props => css`
    max-width: ${CONTAINER_MAX_WIDTH};
    padding: 0 ${props.theme.sizes["container-side-padding"]};
    margin: 0px auto;
    text-align: left;

    @media (max-width: 1000px) {
      text-align: center;
    }
  `
);

const LoginBottomTitle = styled.div(
  props => css`
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: var(--color-foreground, ${props.theme.colors.ink650});
    width: 100%;
    margin: 30px 0;
  `
);

const LoginBottomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 30px;

  @media (max-width: 1255px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const LoginBottomGridCell = styled.div`
  max-width: 374px;
  width: 100%;
  min-width: 112px;
  height: 100%;

  @media (max-width: 1000px) {
    margin: auto;
  }
`;

const LoginBottomGridCellTop = styled.div`
  display: flex;
  font-size: 16px;

  @media (max-width: 1000px) {
    align-items: center;
    flex-direction: column;
  }
`;

const LoginBottomGridCellIcon = styled(ImageThemed)`
  width: 36px;
  height: 36px;
`;

const LoginBottomGridCellTitle = styled.p(
  props => css`
    margin: auto 8px;
    font-weight: 600;
    font-size: 16px;

    color: var(--color-foreground, ${props.theme.colors.ink650});

    @media (max-width: 1000px) {
      margin-top: 8px;
      margin-left: 0px;
      min-height: unset;
    }
  `
);

const LoginBottomGridCellBottom = styled.div(
  props => css`
    font-size: 12px;
    line-height: 16px;
    color: var(--color-foreground-de-emp, ${props.theme.colors.ink550});
    padding: 12px 0;
    margin-left: 44px;

    @media (max-width: 1000px) {
      margin: 0 auto;
      width: 80%;
    }
  `
);

const LoginContinueButton = styled(ButtonApolloPrimary)`
  && {
    margin-right: 16px;
    min-width: 164px;
  }
`;

const LoginLearnMoreButton = styled(ButtonApolloText)`
  && {
    min-width: 148px;
  }
`;

const VIDEO_ID = "Bijpv2O_Yj0";

const FpsLoginRedirectMainContent = ({
  history,
  signupInfo,
  tenantsByOwner,
  setShowSelectTenantBox,
  setShowLoader,
  showSelectTenantBox
}) => {
  const [videoIsOpen, setVideoIsOpen]: any = useState(false);
  const { t } = useTranslation("common");
  const clickImage = () => {
    setVideoIsOpen(true);
  };

  const onClickEnableButton = () => {
    const storedCountry = SessionHelper.getValueForKey("country");
    const matchedCountry =
      countries.find(obj => obj.country_name === storedCountry) || null;

    if (tenantsByOwner.length > 0) {
      setShowSelectTenantBox(true);
    } else {
      if (!!matchedCountry) {
        setShowLoader(true);
        triggerAnalyticsWithDefaultData(FPS_CREATE_NEW_INSTANCE);
        enableAutomationHub(
          {
            data: {
              tenant_country: matchedCountry.country_abbreviation
            },
            config: {
              headers: signupInfo.headers,
              isAuthLogin: true
            }
          },
          response => {
            setShowLoader(false);
            if (response) {
              history.replace(urlWithTenant("/"));
            } else {
              // it's an error - don't redirect
            }
          },
          t
        );
      } else {
        setShowSelectTenantBox(true);
      }
    }
  };

  return (
    <LoginHero showSelectTenantBox={showSelectTenantBox}>
      <LoginHeroContainer>
        <LoginHeroInner>
          <LoginHeroLeft>
            <LoginHeroTitle>
              {t([
                "landing_page_title",
                "Turn great ideas from anywhere into great automations everywhere"
              ])}
            </LoginHeroTitle>
            <LoginHeroSubtitle>
              {t([
                "landing_page_subtitle",
                "Identify and manage automations, store process documentation, leverage reusable components and easily communicate ROI"
              ])}
            </LoginHeroSubtitle>
            <LoginHeroButtonContainer>
              <LoginContinueButton onClick={onClickEnableButton}>
                {t(["landing_page_enable_button", "Continue"])}
              </LoginContinueButton>
              <a
                href="https://docs.uipath.com/automation-hub"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LoginLearnMoreButton>
                  {t(["landing_page_learn_more_button", "Learn More"])}
                </LoginLearnMoreButton>
              </a>
            </LoginHeroButtonContainer>
          </LoginHeroLeft>

          <LoginHeroRight>
            <LoginHeroRightImageContainer onClick={clickImage}>
              <LoginHeroRightImage src={LandingPageMaskImage} alt="Hero" />

              <LoginHeroRightImageIcon src={PlayIcon} alt="Hero" />
            </LoginHeroRightImageContainer>
          </LoginHeroRight>
        </LoginHeroInner>

        <ModalVideo
          id="homevideo"
          channel="youtube"
          isOpen={videoIsOpen}
          videoId={VIDEO_ID}
          animationSpeed={2000}
          autoplay={1}
          onClose={() => setVideoIsOpen(false)}
        />
      </LoginHeroContainer>

      <LoginBottomContainer>
        <LoginBottomTitle>
          {t(["landing_page_help_you_title", "Automation Hub helps you"])}
        </LoginBottomTitle>
        <LoginBottomGrid>
          {landingPageData.map(data => {
            return (
              <LoginBottomGridCell>
                <LoginBottomGridCellTop>
                  <LoginBottomGridCellIcon
                    src={data.icon}
                    srcDark={data.iconDark}
                    alt=""
                  />
                  <LoginBottomGridCellTitle>
                    {t(["landing_page_title_" + data.id, data.title])}
                  </LoginBottomGridCellTitle>
                </LoginBottomGridCellTop>
                <LoginBottomGridCellBottom>
                  {/* {data.content} */}
                  {t(["landing_page_content_" + data.id, data.content])}
                </LoginBottomGridCellBottom>
              </LoginBottomGridCell>
            );
          })}
        </LoginBottomGrid>
      </LoginBottomContainer>
    </LoginHero>
  );
};

export default FpsLoginRedirectMainContent;
