import React from "react";
import { EditorState, convertFromRaw } from "draft-js";
import ComponentTypeIcon from "../../ComponentTypeIcon/ComponentTypeIcon";
import styled from "styled-components/macro";

const SearchFieldOptionDiv = styled.div`
  padding: 8px 16px;
  display: flex;
  color: var(--color-foreground);
  cursor: pointer;
  flex-grow: 1;
`;

const SearchFieldOptionImage = styled(ComponentTypeIcon)`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

const SearchFieldOptionName = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
`;

const SearchFieldOptionDescr = styled.p`
  font-size: 0.75rem;
`;

const SearchFieldOption = ({
  data,
  isComponent
}: {
  data: {
    valueObj: {
      component_description?: string;
      process_description?: string;
      component_name?: string;
      process_name?: string;
      component_type?: {
        component_type_slug: string;
      };
    };
  };
  isComponent?: boolean;
}) => {
  const { valueObj } = data;
  let description = !!isComponent
    ? valueObj.component_description
    : valueObj.process_description;
  let name = !!isComponent
    ? valueObj.component_name || ""
    : valueObj.process_name || "";
  let description_parsed = "";
  let component_type_slug = "";

  if (description) {
    try {
      let description_text = JSON.parse(description);
      description_text = EditorState.createWithContent(
        convertFromRaw(description_text)
      );
      description_parsed = description_text.getCurrentContent().getPlainText();
    } catch (e) {}
  }
  if (description_parsed.length > 50) {
    description_parsed = description_parsed.substring(0, 47) + "...";
  }
  if (name.length > 60) {
    name = name.substring(0, 57) + "...";
  }

  if (!!isComponent) {
    if (
      valueObj.component_type &&
      valueObj.component_type.component_type_slug
    ) {
      component_type_slug = valueObj.component_type.component_type_slug;
    } else {
      component_type_slug = "custom-activity";
    }
  } else {
    component_type_slug = "solution";
  }

  return (
    <SearchFieldOptionDiv data-testid="SearchFieldOption">
      <SearchFieldOptionImage componentTypeSlug={component_type_slug} alt="" />
      <div>
        <SearchFieldOptionName>{name}</SearchFieldOptionName>
        <SearchFieldOptionDescr className="SearchFieldOption__description">
          {description_parsed}
        </SearchFieldOptionDescr>
      </div>
    </SearchFieldOptionDiv>
  );
};

export default SearchFieldOption;
