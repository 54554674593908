import React from "react";
import Swal from "./../../components/SweetAlert/SweetAlert";
import "./fileDownloadSwal.scss";
import { LocalizeTranslationKey } from "./../localizedAlertMessages";
import { renderToString } from "react-dom/server";
import LoaderLogoSmall from "../../components/LoaderLogoSmall/LoaderLogoSmall";

function fileDownloadSwal({ percentValue, titleText }) {
  const getHtmlContent = () => {
    if (typeof percentValue === "number" && isFinite(percentValue)) {
      return `
      <div class="fileDownloadSwal">
        <div class="fileDownloadSwal__bar-container">
          <div class="fileDownloadSwal__bar" style="width: ${percentValue *
            2}px"></div>
        </div>
        <h2 class="fileDownloadSwal__text" style="font-size: 16px;">${percentValue}%</h2>
      </div>
    `;
    } else {
      return renderToString(<LoaderLogoSmall style={{ width: "100px" }} />);
    }
  };

  Swal.fire({
    animation: false,
    title:
      titleText ||
      renderToString(
        <LocalizeTranslationKey localeKey={"alert_downloading"} />
      ),
    showCloseButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    html: getHtmlContent()
  });
}

export default fileDownloadSwal;
