import SessionHelper from "../helpers/SessionHelper";

const loadFonts = (href: string, type: string, callbackfn: () => void) => {
  var link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", type);
  link.setAttribute("href", href);
  link.addEventListener("load", callbackfn, false);
  document.head.appendChild(link);
};

// load versionless apollo fonts for all Envs
export const registerApolloFonts = () => {
  if (process.env.NODE_ENV !== "production") {
    // For local dev, we can fetch apollo fonts from alpha portal
    loadFonts(
      `/portal_/apollo/packages/apollo-fonts/1/font.css?spaceName=automationhub`,
      "text/css",
      function() {
        console.log("load apollo fonts from alpha Portal");
      }
    );
  } else if (!SessionHelper.isFpsUri()) {
    // For non fps we want to load files from build to prevent cross site requests
    loadFonts(
      `${process.env.PUBLIC_URL}/static/apollo/packages/apollo-fonts/font.css`,
      "text/css",
      function() {
        console.log("load apollo fonts from local");
      }
    );
  } else {
    // For cloud and K8S, we can fetch apollo fonts from portal
    loadFonts(
      `/portal_/apollo/packages/apollo-fonts/1/font.css?spaceName=automationhub`,
      "text/css",
      function() {
        console.log("load apollo fonts from Portal");
      }
    );
  }
};
