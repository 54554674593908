export const COEquationFunctions = {
  MAX_OF_ANSWERS: "QUESTION_ANSWER_MAX",
  MIN_OF_ANSWERS: "QUESTION_ANSWER_MIN",
  SUM_OF_ANSWERS: "QUESTION_ANSWER_SUM",
  AVERAGE_OF_ANSWERS: "QUESTION_ANSWER_AVERAGE",
  FIRST_ANSWER: "QUESTION_ANSWER_FIRST",
  PLAIN_TEXT: "QUESTION_ANSWER_PLAIN_TEXT",
  EXTERNAL_ANSWER: "EXTERNAL_ANSWER",
  REF_CALC_VAL: "REF_CALC_VAL",
  COUNT_OF_ANSWERS: "QUESTION_ANSWER_COUNT",
  COUNT_APPLICATIONS: "QUESTION_COUNT_APPLICATIONS",
  COUNT_THIN_APPLICATIONS: "COUNT_THIN_APPLICATIONS"
};

export const variableIsFunction = (variable: string) => {
  if (variable) {
    for (const key of Object.keys(COEquationFunctions)) {
      if (COEquationFunctions[key] === variable) {
        return true;
      }
    }
  }
  return false;
};
export const CODefaultEquations = {
  MAX_OF_ANSWERS: COEquationFunctions.MAX_OF_ANSWERS,
  MIN_OF_ANSWERS: COEquationFunctions.MIN_OF_ANSWERS,
  SUM_OF_ANSWERS: COEquationFunctions.SUM_OF_ANSWERS,
  AVERAGE_OF_ANSWERS: COEquationFunctions.AVERAGE_OF_ANSWERS,
  COUNT_OF_ANSWERS: COEquationFunctions.COUNT_OF_ANSWERS,
  ANSWER: COEquationFunctions.FIRST_ANSWER,
  TEXT: COEquationFunctions.PLAIN_TEXT,
  EXTERNAL: COEquationFunctions.EXTERNAL_ANSWER,
  REFERENCED_CALCULATED_VALUE: COEquationFunctions.REF_CALC_VAL,
  NUMBER_KPI: "1",
  REFERENCE_KPI: "0",
  COUNT_APPLICATIONS: COEquationFunctions.COUNT_APPLICATIONS,
  COUNT_THIN_APPLICATIONS: COEquationFunctions.COUNT_THIN_APPLICATIONS
};

// this is somewhat legacy
export const COExternalEquationFunctionsHandledInCalculationSystem = [
  CODefaultEquations.COUNT_APPLICATIONS,
  CODefaultEquations.COUNT_THIN_APPLICATIONS,
  CODefaultEquations.REFERENCED_CALCULATED_VALUE
];
