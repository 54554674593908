import {
  FETCH_PPP_PROFILE_DATA,
  CLEAR_PPP_PROFILE_DATA,
  FETCH_ALL_FOLLOWERS,
  FETCH_ALL_PHASE_AND_STATUSES,
  RECEIVE_UPVOTE,
  RECEIVE_DOWNVOTE,
  RECEIVE_FOLLOW,
  RECEIVE_UNFOLLOW,
  REQUEST_PROCESS,
  FETCH_EDIT_DATA,
  FETCH_APPLICATIONS,
  EDIT_PROFILE_Q1,
  FETCH_BASE_EDIT_DATA,
  FETCH_APPLICATION_FOR_L1_TAXONOMY,
  FETCH_PPP_PROFILE_MEDIA,
  FETCH_AUTOMATIONS_FOR_SIMILAR_SEARCH,
  FETCH_SIMILAR_AUTOMATIONS,
  FETCH_AUTOMATION_COMPONENTS,
  FETCH_REUSED_COMPONENTS,
  FETCH_REUSED_COMPONENTS_FOR_SEARCH,
  FETCH_AUTOMATIONS_USING_COMPONENT,
  SET_COMMENT_TO_REPORT,
  GET_REAL_TIME_ASSESSMENT_CALCULATION_TYPES,
  FETCH_CHANGE_REQUESTS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  processProfileComments: null,
  needToFetchProcessProfileComments: false,
  currentPage: 1,
  totalPages: 1,
  commentIdToEdit: null,
  commentIdToReplyTo: null,
  processProfileInfo: {},
  editInfo: {},
  currentPhases: null,
  q2Data: null,
  applications: null,
  applicationsForL1Taxonomy: null,
  processProfileMedia: null,
  automationsForSimilarSearch: null,
  similarAutomations: null,
  automationComponents: null,
  automationsUsingComponent: null,
  reusedComponents: null,
  realTimeAssessmentCalculationTypes: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PPP_PROFILE_DATA:
      return {
        ...state,
        processProfileInfo: action.payload
      };
    case FETCH_PPP_PROFILE_MEDIA:
      return {
        ...state,
        processProfileMedia: action.payload
      };
    case CLEAR_PPP_PROFILE_DATA:
      return {
        ...INITIAL_STATE
      };
    case FETCH_ALL_FOLLOWERS:
      const { payload: followersData } = action;
      const { processProfileInfo } = state;
      return {
        ...state,
        processProfileInfo: {
          ...processProfileInfo,
          followers: followersData
        }
      };
    case FETCH_APPLICATIONS:
      return {
        ...state,
        applications: action.payload
      };
    case FETCH_APPLICATION_FOR_L1_TAXONOMY:
      return {
        ...state,
        applicationsForL1Taxonomy: action.payload
      };
    case FETCH_ALL_PHASE_AND_STATUSES:
      return {
        ...state,
        currentPhases: action.payload
      };
    case FETCH_EDIT_DATA:
      return {
        ...state,
        editInfo: action.payload
      };
    case EDIT_PROFILE_Q1:
      return {
        ...state,
        processProfileInfo: action.payload
      };
    case FETCH_BASE_EDIT_DATA:
      return {
        ...state,
        ...action.payload
      };
    case RECEIVE_UPVOTE: {
      let processProfileInfo = null;
      if (state.processProfileInfo) {
        processProfileInfo = { ...state.processProfileInfo };
        processProfileInfo.user_has_voted = 1;
        processProfileInfo.process_num_votes++;
      }
      return {
        ...state,
        processProfileInfo: processProfileInfo
      };
    }
    case RECEIVE_DOWNVOTE: {
      let processProfileInfo = null;
      if (state.processProfileInfo) {
        processProfileInfo = { ...state.processProfileInfo };
        processProfileInfo.user_has_voted = 0;
        processProfileInfo.process_num_votes--;
      }
      return {
        ...state,
        processProfileInfo: processProfileInfo
      };
    }
    case RECEIVE_FOLLOW: {
      let processProfileInfo = null;
      if (state.processProfileInfo) {
        processProfileInfo = { ...state.processProfileInfo };
        processProfileInfo.user_is_following = 1;
        processProfileInfo.process_num_followers++;
      }
      return {
        ...state,
        processProfileInfo: processProfileInfo
      };
    }
    case RECEIVE_UNFOLLOW: {
      let processProfileInfo = null;
      if (state.processProfileInfo) {
        processProfileInfo = { ...state.processProfileInfo };
        processProfileInfo.user_is_following = 0;
        processProfileInfo.process_num_followers--;
      }
      return {
        ...state,
        processProfileInfo: processProfileInfo
      };
    }
    case REQUEST_PROCESS: {
      let processProfileInfo = null;
      if (state.processProfileInfo) {
        processProfileInfo = { ...state.processProfileInfo };
        processProfileInfo.user_has_requested = 1;
      }
      return {
        ...state,
        processProfileInfo: processProfileInfo
      };
    }
    case FETCH_AUTOMATIONS_FOR_SIMILAR_SEARCH:
      return {
        ...state,
        automationsForSimilarSearch: action.payload
      };
    case FETCH_SIMILAR_AUTOMATIONS:
      return {
        ...state,
        similarAutomations: action.payload
      };
    case FETCH_AUTOMATION_COMPONENTS:
      return {
        ...state,
        automationComponents: action.payload
      };
    case FETCH_REUSED_COMPONENTS:
      return {
        ...state,
        reusedComponents: action.payload
      };
    case FETCH_REUSED_COMPONENTS_FOR_SEARCH:
      return {
        ...state,
        reusedComponentsForSearch: action.payload
      };
    case FETCH_AUTOMATIONS_USING_COMPONENT:
      return {
        ...state,
        automationsUsingComponent: action.payload
      };
    case FETCH_CHANGE_REQUESTS:
      return {
        ...state,
        changeRequests: action.payload
      };
    case SET_COMMENT_TO_REPORT:
      return {
        ...state,
        commentIdToReport: action.payload
      };
    case GET_REAL_TIME_ASSESSMENT_CALCULATION_TYPES:
      return {
        ...state,
        realTimeAssessmentCalculationTypes: action.payload
      };
    default:
      return state;
  }
}
