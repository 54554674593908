import { COContext } from "../classes/co-context.class";
import {
  COMetadataInterface,
  COMetadataItemInterface
} from "../interfaces/co-interfaces";

import { resolve } from "../utils/co-resolver.utils";
import { metaInContext } from "./co-context.helper";

export const visibleMetaItemsInContext = ({
  context,
  meta
}: {
  context: COContext;
  meta?: COMetadataInterface;
}): COMetadataItemInterface[] => {
  let visibleInterfaces: COMetadataItemInterface[] = [];
  if (!meta) {
    return [];
  }
  const resolvedMeta: COMetadataInterface | undefined = resolve({
    context,
    item: metaInContext({
      context,
      meta
    })
  });
  if (resolvedMeta) {
    for (const itemKey of Object.keys(resolvedMeta)) {
      let metaItem = resolvedMeta[itemKey];
      if (metaItem.options?.is_hidden) {
      } else {
        visibleInterfaces.push(metaItem);
      }
    }
  }
  return visibleInterfaces;
};
