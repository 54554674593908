import React from "react";
import moment from "moment";
import "./CellLastCuratedManageComponents.scss";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";

const CellLastCuratedManageComponents = props => {
  const { component_updated_epoch } = props;

  //THIS FIELD IS NOT CORRECT, CHANGE THIS ONCE THE LAST CURATED DATA IS PASSED FORM THE ENDPOINT
  return (
    <div
      className="CellLastCuratedManageComponents"
      data-testid="CellLastCuratedManageComponentsContainer"
    >
      <span className="CellLastCuratedManageComponents__date">{`${showDateWithTenantDateFormat(
        component_updated_epoch,
        props.t("general_data_format_slash", "L")
      )}`}</span>
    </div>
  );
};

export default withTranslation("common")(CellLastCuratedManageComponents);
