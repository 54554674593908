import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./LevelUpModal.scss";
import SessionHelper from "../../helpers/SessionHelper";
import axios from "../../utils/axiosInstance";
import apiErrorAlert from "../../utils/apiErrorAlert";
import { closeLevelUpModal } from "./../../actions/userStatusActions";
import { getUserGamificationLevelImage } from "../../utils/achievementHelper";
import ButtonApolloPrimary from "../buttons/ButtonApolloPrimary/ButtonApolloPrimary";

class LevelUpModal extends Component {
  clickDismissHandler = () => {
    const level = this.props.userStatus.levels.levels[0];
    const user = SessionHelper.currentUser();

    if (!user || !user.user_profile_slug) {
      return;
    }
    //Change the has_been to 1, so the user don't see this level up modal again.
    let path =
      "/api/v1/users/" + user.user_id + "/level/" + level.user_level_id;
    axios
      .patch(path)
      .then(() => {})
      .catch(error => apiErrorAlert(error));
    this.props.closeLevelUpModal();
  };

  render() {
    if (
      !this.props.userStatus.levels ||
      this.props.userStatus.levels.length === 0
    ) {
      return null;
    }
    if (!this.props.userStatus.isLevelUpModalOpen) {
      return null;
    }

    const level = this.props.userStatus.levels.levels[0] || {};
    const statusPoints = this.props.userStatus.user_status.statusPoints;
    const currentLevelNameSlug = SessionHelper.getUserLevelSlug() || "";
    const currentStatusPoint = SessionHelper.getUserStatusPoints();

    const currentLevelName = this.props.t([
      "gamification_level_name_" + currentLevelNameSlug,
      SessionHelper.getUserLevel() || ""
    ]);

    const newLevelName = this.props.t([
      "gamification_level_name_" + level.level_slug,
      level.level_name || ""
    ]);
    const newStatusPoints = statusPoints.user_points;

    const levelIconBefore = getUserGamificationLevelImage(currentLevelNameSlug);
    const levelIconAfter = getUserGamificationLevelImage(
      level.level_slug || ""
    );

    const beginingPoint = Math.floor(
      (100 * currentStatusPoint) / level.level_up_points
    );

    return (
      <div
        className="LevelUpModal__container"
        data-testid="LevelUpModalContainer"
      >
        <div className="LevelUpModal__container-background"></div>
        <div className="LevelUpModal__image">
          <img
            src={levelIconBefore}
            className="LevelUpModal__image-before"
            alt={this.props.t([
              "gamification_level_up_img_alt_level_before",
              "Level before"
            ])}
          />
          <img
            src={levelIconAfter}
            className="LevelUpModal__image-after"
            alt={this.props.t([
              "gamification_level_up_img_alt_level_after",
              "Level after"
            ])}
          />
        </div>

        <div>
          <div className="LevelUpModal__level-title LevelUpModal__level-title-before">
            {currentLevelName}
          </div>
          <div className="LevelUpModal__level-title LevelUpModal__level-title-after">
            <span className="sparkle">
              <span className="sparkle__lines"></span>
            </span>

            <span>{newLevelName}</span>

            <span className="sparkle sparkle--reverse">
              <span className="sparkle__lines"></span>
            </span>
          </div>
        </div>

        <div className="LevelUpModal__level-progress">
          <div
            className="LevelUpModal__level-progress-inside"
            style={{ "--beginWidth": beginingPoint + "%" }}
          ></div>
        </div>

        <div className="LevelUpModal__level-points">
          <div className="LevelUpModal__level-points-wrapper">
            <div className="LevelUpModal__level-points-container">
              <div className="LevelUpModal__level-points-number number-before">
                {currentStatusPoint}
              </div>
              <div className="LevelUpModal__level-points-number number-after">
                {newStatusPoints}
              </div>
            </div>
          </div>
          <div className="LevelUpModal__level-points-label-container">
            <span className="LevelUpModal__level-points-label">
              {this.props.t(["level_up_modal_points", "PTS"])}!
            </span>
          </div>
        </div>

        <div className="LevelUpModal__new-points-wrapper">
          <div className="LevelUpModal__new-points-container">
            <div className="LevelUpModal__new-points-before">
              +{newStatusPoints - currentStatusPoint}{" "}
              {this.props.t(["level_up_modal_points", "PTS"])}!
            </div>
            <div className="LevelUpModal__new-points-after">
              +0 {this.props.t(["level_up_modal_points", "PTS"])}!
            </div>
          </div>
        </div>

        <div className="LevelUpModal__level-dismiss">
          <ButtonApolloPrimary onClick={this.clickDismissHandler}>
            {this.props.t(["level_up_modal_dismiss_button", "Dismiss"])}
          </ButtonApolloPrimary>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userStatus: state.userStatus
});

const mapActionsToProps = {
  closeLevelUpModal
};

export default withTranslation("common")(
  connect(mapStateToProps, mapActionsToProps)(LevelUpModal)
);
