/* eslint-disable eqeqeq */
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import apiErrorAlert from "./apiErrorAlert";
import { LocalizeTranslationKey } from "./localizedAlertMessages";
import SessionHelper from "../helpers/SessionHelper";
import cloneDeep from "lodash/cloneDeep";
import {
  SweetAlertLoaderSpinner,
  swalSpinnerClose
} from "./SweetAlertLoaderSpinner";
import {
  USER_INVITE_STATUS_SEND_EMAIL,
  USER_STATUS_TEXT_COLOR_MAPPING,
  FREE_TEXT_MAX_LENGTH
} from "../constants/userConstants";
import { fetchUsersData } from "./reusableFetchApi";
import fileUploadSwal from "./fileUploadSwal/fileUploadSwal";
import { searchUsersByEmailArray } from "./apiUtils/adminUserApiUtils";
import {
  userRolesCSVStringToUserRoles,
  userRolesToCSVString
} from "./roleAndPermissionHelper";

const USER_FIELDS: any = {
  user_first_name: "First Name",
  user_last_name: "Last Name",
  user_email: "E-mail",
  user_job_title: "Job title",
  user_business_unit: "BU",
  user_department: "Dept",
  user_location: "City",
  user_roles: "Roles"
};

const USER_VIEW_FIELDS = {
  ...USER_FIELDS,
  user_status: "Status"
};

// need to add helper text at the end
const CSV_HELPER_TEXT = `"For details on how to fill in the information in the CSV file, please check the user documentation: https://docs.uipath.com/automation-hub/docs/downloading-and-uploading-csv"`;
USER_FIELDS.helperText = CSV_HELPER_TEXT;
USER_VIEW_FIELDS.helperText = CSV_HELPER_TEXT;

const csvHelperText = t => {
  if (t !== null) {
    return t("download_user_csv_header_helper_text", CSV_HELPER_TEXT);
  } else {
    return CSV_HELPER_TEXT;
  }
};

export const userSearchString = user => {
  if (!user) {
    return "";
  }
  const firstName = user.user_first_name || "";
  const lastName = user.user_last_name || "";
  const email = user.user_email || "";
  const jobTitle = user.user_job_title || "";
  const location = user.user_location || "";
  const businessUnit = user.user_business_unit || "";
  const department = user.user_department || "";

  //  line breaks so it doesnt search concatenated fields
  const searchString = `
    ${firstName} ${lastName} 
    ${email}
    ${jobTitle}
    ${location}
    ${businessUnit}
    ${department}
  `.toLowerCase();
  return searchString;
};

export const getCurrentUserSlug = () => {
  return SessionHelper.currentUser()
    ? SessionHelper.currentUser().user_profile_slug
    : -1;
};

export const userInitials = user => {
  if (!user) {
    return "";
  }
  let first = "";
  let last = "";
  if (user.user_first_name && user.user_first_name.length > 0) {
    first = user.user_first_name.substring(0, 1).toUpperCase();
  }
  if (user.user_last_name && user.user_last_name.length > 0) {
    last = user.user_last_name.substring(0, 1).toUpperCase();
  }
  return first + last;
};

export const userTruncatedFullName = (userData, max_name_length) => {
  if (!userData || max_name_length < 1) {
    return "";
  }
  let user_first_name = userData.user_first_name
    ? userData.user_first_name
    : "";
  let user_last_name = userData.user_last_name ? userData.user_last_name : "";
  let processed_full_name = user_first_name + " " + user_last_name;
  if (processed_full_name.length > max_name_length) {
    processed_full_name =
      processed_full_name.substr(0, max_name_length - 1) + "...";
  }

  return processed_full_name;
};

export const maxUserRefId = users => {
  let max_id = 0;
  if (users) {
    users.forEach(user => {
      if (!user.refId) {
        user.refId = user.user_id;
      }
      if (user.refId > max_id) {
        max_id = user.refId;
      }
    });
  }
  return max_id;
};

export const getAndDownloadAllUserCSV = async (
  isView,
  fetchOptions,
  functionOptions,
  fileName,
  categories,
  t
) => {
  let users: any[] = [];
  fetchOptions = fetchOptions || {};
  const { startCallback, completionCallback, errorCallback } =
    functionOptions || {};
  let currentPage = 1;
  let totalPages = 1;
  let percentValue = 0;

  if (typeof startCallback === "function") {
    startCallback();
  }

  fileUploadSwal({
    percentValue,
    titleText: renderToStaticMarkup(
      <LocalizeTranslationKey localeKey="alert_loading_users" />
    )
  });
  while (currentPage <= totalPages) {
    try {
      const userData = await fetchUsersData({
        ...fetchOptions,
        page: currentPage,
        isAdmin: true
      });
      const {
        users: usersFromBackend,
        totalPages: totalPagesFromBackend,
        totalItems
      } = userData;
      if (totalPagesFromBackend !== totalPages) {
        totalPages = totalPagesFromBackend;
      }
      users =
        usersFromBackend instanceof Array
          ? users.concat(usersFromBackend)
          : users;
      percentValue = Math.round((users.length / totalItems) * 100);
      fileUploadSwal({
        percentValue,
        titleText: <LocalizeTranslationKey localeKey="alert_loading_users" />
      });
      currentPage++;
    } catch (error) {
      apiErrorAlert(error);
      setTimeout(() => swalSpinnerClose(), 0);
      if (typeof errorCallback === "function") {
        errorCallback();
      }
      return;
    }
  }

  let csvData = getUserCSVData(users, categories, isView);

  doCSVExport(csvData, fileName, isView, t);
  SweetAlertLoaderSpinner({
    title: (
      <LocalizeTranslationKey localeKey="alert_loading_users_downloaded_sucessfully" />
    ),
    type: "success",
    showGif: false,
    showCloseButton: true,
    timer: 1500
  });
  if (typeof completionCallback === "function") {
    completionCallback();
  }
};

export const newUser = () => {
  const new_user: any = {};
  new_user.user_first_name = "";
  new_user.user_last_name = "";
  new_user.user_email = "";
  new_user.user_job_title = "";
  new_user.user_department = "";
  new_user.user_business_unit = "";
  new_user.user_location = "";
  new_user.manager_email = "";
  new_user.hasEdited = 1;
  new_user.user_is_system_admin = 0;
  new_user.user_is_program_manager = 0;
  new_user.user_is_authorized_user = 0;
  new_user.user_is_curator = 0;
  new_user.user_is_active = 1;
  new_user.user_invite_status = USER_INVITE_STATUS_SEND_EMAIL;
  new_user.user_roles = [];
  return new_user;
};

export const processUsersToSave = users => {
  const userDatToSave: any[] = [];
  users.forEach(function(user) {
    const userToSave: any = {};
    if (user.hasEdited && user.hasEdited === 1) {
      userToSave.user_id = user.user_id ? user.user_id : 0;
      //If existing email was edited
      userToSave.user_email = user.user_email;
      if (user.user_new_email) {
        userToSave.user_new_email = user.user_new_email;
      }
      userToSave.user_first_name = user.user_first_name;
      userToSave.user_last_name = user.user_last_name;
      userToSave.user_location = user.user_location;
      userToSave.user_country = user.user_country;
      userToSave.user_department = user.user_department;
      userToSave.user_business_unit = user.user_business_unit;
      userToSave.user_job_title = user.user_job_title;

      userToSave.user_roles = (user.user_roles || []).map(user_role => {
        return {
          role_id: user_role.role_id,
          category_id: user_role.category_id
        };
      });

      userToSave.user_location = user.user_location;
      userToSave.user_is_active = user.user_is_active;
      userToSave.user_invite_status = user.user_invite_status;
      userToSave.user_is_new = user.user_is_new;
      userDatToSave.push(userToSave);
    }
  });
  return userDatToSave;
};

export const syncRawCSVData = async (
  lines,
  existing_users,
  roles,
  categories,
  t,
  handleErrorCallback
) => {
  const result: any[] = [];
  let errorText = "";
  let errorKey = "";

  // Check for bad data
  try {
    const getFieldsFromHeaders = userFriendlyKeys => {
      return userFriendlyKeys.map(userFriendlyKey => {
        const userKeys = Object.keys(USER_FIELDS);
        for (let i = 0; i < userKeys.length; i++) {
          const userKey = userKeys[i];

          if (t !== null) {
            // check localized headers, since they're exported localized now
            const localizedUserKeyValue = t(
              `download_user_csv_header_${userKey}`,
              USER_FIELDS[userKey] || ""
            );

            if (localizedUserKeyValue === userFriendlyKey) {
              return userKey;
            } else if (localizedUserKeyValue === csvHelperText(t)) {
              return "";
            }
          }

          // also check English for backwards compatibility
          // headers used to always be in English so we should still check that
          const userKeyValue = USER_FIELDS[userKey];
          if (userKeyValue === userFriendlyKey) {
            return userKey;
          } else if (userKeyValue === CSV_HELPER_TEXT) {
            return "";
          }
        }
        throw "Column header not found";
      });
    };

    var headers = getFieldsFromHeaders(lines[0]);
    for (let i = 1; i < lines.length; i++) {
      const obj: any = {};
      const currentline = lines[i];
      if (currentline.length > 1) {
        if (currentline.length > headers.length) {
          errorText =
            "A row has been detected with too many columns, please check all rows for extra columns or commas.";
          errorKey = "csv_error_row_has_too_many_columns";
          throw "Column length mismatch found";
        }
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        if (!obj.user_first_name || !obj.user_last_name || !obj.user_email) {
          errorText =
            "A row has been detected with a missing name or email.  Please check all rows have first name, last name and email.";
          errorKey = "csv_error_row_missing_name_or_email";
          throw "Missing name or email";
        }
        result.push(obj);
      }
    }
  } catch (e) {
    console.log(e);
    if (typeof handleErrorCallback === "function") {
      handleErrorCallback(errorText, errorKey);
    }
    return null;
  }

  const userEmailsToVerify: any[] = [];

  //add new users
  //add new users or sync data
  result.forEach(function(new_user) {
    let userFound = false;

    //let's parse the user roles
    let rolesParsedData = userRolesCSVStringToUserRoles(
      new_user.user_roles || "",
      roles
    );
    if (!rolesParsedData.parseError && !rolesParsedData.isIgnored) {
      new_user.user_roles = rolesParsedData.parsedRoles;
    } else if (rolesParsedData.isIgnored) {
      new_user.user_roles = [];
      new_user.user_roles_are_ignored = true;
    } else if (rolesParsedData.parseError) {
      new_user.user_roles = [];
      new_user.user_roles_parsing_has_error = true;
    } else {
      new_user.user_roles = [];
      new_user.user_roles_parsing_has_error = true;
      new_user.user_roles_are_ignored = true;
    }

    existing_users.forEach(function(existing_user) {
      if (new_user.user_email) {
        if (
          existing_user.user_email.toUpperCase() ===
          new_user.user_email.toUpperCase()
        ) {
          userFound = true;
          //sync data
          existing_user.user_first_name = new_user.user_first_name;
          existing_user.user_last_name = new_user.user_last_name;
          existing_user.user_job_title = new_user.user_job_title;
          existing_user.user_department = new_user.user_department;
          existing_user.user_business_unit = new_user.user_business_unit;
          existing_user.user_location = new_user.user_location;
          existing_user.manager_email = new_user.manager_email;
          existing_user.manager = null;

          if (!new_user.user_roles_are_ignored) {
            existing_user.user_roles = new_user.user_roles;
          }

          existing_user.hasEdited = 1;
        }
      }
    });
    if (userFound === false) {
      if (new_user.user_email && new_user.user_email.length > 3) {
        userEmailsToVerify.push(new_user.user_email);
        new_user.refId = maxUserRefId(existing_users) + 1;
        new_user.hasEdited = 1;
        new_user.user_is_active = 1;
        new_user.user_is_new = true;
        new_user.user_invite_status = USER_INVITE_STATUS_SEND_EMAIL;

        existing_users.push(getTrimmedUserDetailsObj(new_user));
      }
    }
  });

  // Check if any of the users from the CSV, are in the system but not on the page
  if (userEmailsToVerify.length > 0) {
    try {
      const emailSet = new Set(userEmailsToVerify);
      const data = await searchUsersByEmailArray({
        user_emails: [...emailSet]
      });
      if (data && data.users && data.users.length > 0) {
        // Sync users data from existing users into users from the CSV
        data.users.forEach(prevUserData => {
          existing_users.forEach(updatedUserData => {
            if (
              updatedUserData.user_email &&
              updatedUserData.user_email.toUpperCase() ===
                prevUserData.user_email.toUpperCase()
            ) {
              updatedUserData.user_id = prevUserData.user_id;
              delete updatedUserData.user_is_new;
              updatedUserData.user_invite_status =
                prevUserData.user_invite_status;
            }

            //check the existing data roles object - need to see if we're clearing the roels
            if (
              prevUserData.user_roles &&
              updatedUserData.user_roles_are_ignored
            ) {
              updatedUserData.user_roles = prevUserData.user_roles;
            }
          });
        });
      }
    } catch (e) {
      apiErrorAlert(e);
      return null;
    }
  }

  return existing_users;
};

const getTrimmedUserDetailsObj = user => {
  let userObj = cloneDeep(user);
  let firstName = userObj.user_first_name || "";
  let lastName = userObj.user_last_name || "";
  let jobTitle = userObj.user_job_title || "";
  let bu = userObj.user_business_unit || "";
  let city = userObj.user_location || "";
  let dept = userObj.user_department || "";

  userObj.user_first_name =
    firstName.length > FREE_TEXT_MAX_LENGTH
      ? firstName.substr(0, FREE_TEXT_MAX_LENGTH)
      : firstName;
  userObj.user_last_name =
    lastName.length > FREE_TEXT_MAX_LENGTH
      ? lastName.substr(0, FREE_TEXT_MAX_LENGTH)
      : lastName;
  userObj.user_job_title =
    jobTitle.length > FREE_TEXT_MAX_LENGTH
      ? jobTitle.substr(0, FREE_TEXT_MAX_LENGTH)
      : jobTitle;
  userObj.user_business_unit =
    bu.length > FREE_TEXT_MAX_LENGTH ? bu.substr(0, FREE_TEXT_MAX_LENGTH) : bu;
  userObj.user_location =
    city.length > FREE_TEXT_MAX_LENGTH
      ? city.substr(0, FREE_TEXT_MAX_LENGTH)
      : city;
  userObj.user_department =
    dept.length > FREE_TEXT_MAX_LENGTH
      ? dept.substr(0, FREE_TEXT_MAX_LENGTH)
      : dept;

  return userObj;
};

export const getUserCSVData = (users, categories, isView = false) => {
  const data: any[] = [];
  if (users) {
    users.forEach(item => {
      const user: any = {};
      user.user_first_name = item.user_first_name;
      user.user_last_name = item.user_last_name;
      user.user_email = item.user_email;
      user.user_job_title = item.user_job_title || "";
      user.user_business_unit = item.user_business_unit || "";
      user.user_department = item.user_department || "";
      user.user_location = item.user_location || "";
      user.user_roles = userRolesToCSVString(item.user_roles);
      if (isView) {
        const statusObj = USER_STATUS_TEXT_COLOR_MAPPING(item);
        user.user_status = statusObj.statusText;
      }

      data.push(user);
    });
  }
  return data;
};

export const generateEmptyUsersCSV = (payload: any = {}, fileName) => {
  const { completionCallback } = payload;

  let headers = Object.keys(USER_FIELDS).map(key => USER_FIELDS[key]);
  const result = headers.join(",");

  const element = document.createElement("a");
  const file = new Blob([result], { type: "text/csv" });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  if (typeof completionCallback === "function") {
    completionCallback();
  }
};

export const doCSVExport = (
  data,
  fileName,
  isView,
  t,
  defaultHeaders = null
) => {
  // eslint-disable-next-line no-use-before-define
  if (data == null || !data.length) {
    return null;
  }
  let result = "";
  let headers: any = "";
  let ctr = 0;
  const keys = Object.keys(data[0]);
  let columnDelimiter = ",";
  let lineDelimiter = "\n";

  const user_fields = isView ? USER_VIEW_FIELDS : USER_FIELDS;
  if (defaultHeaders) {
    headers = defaultHeaders;
  } else if (t !== null) {
    headers = keys.map(key =>
      t(`download_user_csv_header_${key}`, user_fields[key] || "")
    );
  } else {
    headers = keys.map(key => user_fields[key]);
  }

  headers.push(csvHelperText(t));

  result = "";
  result += headers.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function(item) {
    ctr = 0;

    keys.forEach(function(key) {
      if (ctr > 0) result += columnDelimiter;

      let text = item[key] + ""; //need to cast to string or it flips out

      //escape quptes
      if (text.replace(/ /g, "").match(/[\s,"]/)) {
        text = '"' + text.replace(/"/g, '""') + '"';
      }

      result += text;
      ctr++;
    });

    result += lineDelimiter;
  });

  const element = document.createElement("a");
  const file = new Blob(["\ufeff", result], { type: "text/csv" });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const getUserRolesSlugString = () => {
  let userRolesString = "";
  const currentUser = SessionHelper.currentUser();
  if (
    currentUser &&
    currentUser.user_roles &&
    Array.isArray(currentUser.user_roles)
  ) {
    const { userRolesArr } = currentUser.user_roles.reduce(
      ({ userRolesArr, roleStringObj }, role) => {
        if (role && role.role_slug && !roleStringObj[role.role_slug]) {
          userRolesArr.push(role.role_slug);
          roleStringObj[role.role_slug] = true;
        }

        return { userRolesArr, roleStringObj };
      },
      {
        userRolesArr: [],
        roleStringObj: {}
      }
    );

    userRolesString = userRolesArr.join(" ");
  }
  return userRolesString;
};
