export enum COEmailActionSlugs {
  WELCOME_EMAIL = "WELCOME_EMAIL",
  SUBMITTED_IDEA_TO_REVIEW_EMAIL = "SUBMITTED_IDEA_TO_REVIEW_EMAIL",
  VERIFICATION_EMAIL = "VERIFICATION_EMAIL",
  LAUNCH_ASSESSMENT_EMAIL = "LAUNCH_ASSESSMENT_EMAIL",
  ASSESMENT_LAUNCHED_EMAIL = "ASSESMENT_LAUNCHED_EMAIL",
  RESUBMIT_IDEA_EMAIL = "RESUBMIT_IDEA_EMAIL",
  ASSESSED_AUTOMATION_EMAIL = "ASSESSED_AUTOMATION_EMAIL",
  ASSIGN_PROJECT_MANAGER_EMAIL = "ASSIGN_PROJECT_MANAGER_EMAIL",
  PROJECT_MANAGER_ASSIGNED_EMAIL = "PROJECT_MANAGER_ASSIGNED_EMAIL",
  FIRST_USER_STANDARD_EMAIL = "FIRST_USER_STANDARD_EMAIL",
  FIRST_USER_TRIAL_EMAIL = "FIRST_USER_TRIAL_EMAIL",
  DEAL_HUB_CHANGE_PLAN_EMAIL = "DEAL_HUB_CHANGE_PLAN_EMAIL",
  CHANGED_PLAN_OWNER_EMAIL = "CHANGED_PLAN_OWNER_EMAIL",
  CHANGED_PLAN_ERROR_EMAIL = "CHANGED_PLAN_ERROR_EMAIL",
  EXTEND_TRIAL_GRANTED_EMAIL = "EXTEND_TRIAL_GRANTED_EMAIL",
  USER_DELETED_EMAIL = "USER_DELETED_EMAIL",
  AUTOMATION_RESUBMIT_IDEA_EMAIL = "AUTOMATION_RESUBMIT_IDEA_EMAIL",
  AUTOMATION_ABUSE_REPORTED_EMAIL = "AUTOMATION_ABUSE_REPORTED_EMAIL",
  AUTOMATION_COMMENT_ABUSE_REPORTED_EMAIL = "AUTOMATION_COMMENT_ABUSE_REPORTED_EMAIL",
  ABOUT_EDITING_RIGHTS_GRANTED_EMAIL = "ABOUT_EDITING_RIGHTS_GRANTED_EMAIL",
  CBA_EDITING_RIGHTS_GRANTED_EMAIL = "CBA_EDITING_RIGHTS_GRANTED_EMAIL",
  COMPONENT_EDITING_RIGHTS_GRANTED_EMAIL = "COMPONENT_EDITING_RIGHTS_GRANTED_EMAIL",
  REVIEW_COE_DRIVEN_IDEA_EMAIL = "REVIEW_COE_DRIVEN_IDEA_EMAIL",
  USER_MENTIONED_IN_COMMENT_EMAIL = "USER_MENTIONED_IN_COMMENT_EMAIL",
  ACCOUNT_DEACTIVATED_EMAIL = "ACCOUNT_DEACTIVATED_EMAIL",
  ACCOUNT_ACTIVATED_EMAIL = "ACCOUNT_ACTIVATED_EMAIL",
  DOCUMENTATION_EDITING_RIGHTS_GRANTED_EMAIL = "DOCUMENTATION_EDITING_RIGHTS_GRANTED_EMAIL",
  COMPONENT_UPDATED_EMAIL = "COMPONENT_UPDATED_EMAIL",
  COMPONENT_REJECTED_EMAIL = "COMPONENT_REJECTED_EMAIL",
  COMPONENT_BUG_REPORTED_EMAIL = "COMPONENT_BUG_REPORTED_EMAIL",
  COMPONENT_ABUSE_REPORTED_EMAIL = "COMPONENT_ABUSE_REPORTED_EMAIL",
  NEW_IDEA_SUBMITTED_PROGRAM_MANAGER_EMAIL = "NEW_IDEA_SUBMITTED_PROGRAM_MANAGER_EMAIL",
  NEW_COE_IDEA_SUBMITTED_PROGRAM_MANAGER_EMAIL = "NEW_COE_IDEA_SUBMITTED_PROGRAM_MANAGER_EMAIL",
  CD_AUTOMATION_SHARED_EMAIL = "CD_AUTOMATION_SHARED_EMAIL",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL = "CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL",
  CD_AUTOMATION_REJECTED_EMAIL = "CD_AUTOMATION_REJECTED_EMAIL",
  CD_AUTOMATION_REWORK_REQUIRED_EMAIL = "CD_AUTOMATION_REWORK_REQUIRED_EMAIL",
  CD_AUTOMATION_REQUESTED_EMAIL = "CD_AUTOMATION_REQUESTED_EMAIL",
  AUTOMATION_COMMENT_REPLY_ADDED_EMAIL = "AUTOMATION_COMMENT_REPLY_ADDED_EMAIL",
  WELCOME_EMAIL_FPS = "WELCOME_EMAIL_FPS",
  USER_MENTIONED_IN_QUESTION_EMAIL = "USER_MENTIONED_IN_QUESTION_EMAIL",
  CHANGE_REQUEST_SUBMITTED_EMAIL = "CHANGE_REQUEST_SUBMITTED_EMAIL"
}

export enum COEmailType {
  FUNCTIONAL_NOTIFICATION = "FUNCTIONAL_NOTIFICATION",
  CURATION_NOTIFICATION = "CURATION_NOTIFICATION",
  CONTENT_PRODUCER_NOTIFICATION = "CONTENT_PRODUCER_NOTIFICATION",
  CONTENT_CONSUMER_NOTIFICATION = "CONTENT_CONSUMER_NOTIFICATION"
}

export enum COEmailActivityType {
  USER_INVITED_TO_JOIN_NAVGO = "USER_INVITED_TO_JOIN_NAVGO",
  EMAIL_VERIFICATION_SENT = "EMAIL_VERIFICATION_SENT",
  SUBMITTED_IDEA_REVIEW = "SUBMITTED_IDEA_REVIEW",
  REVIEW_APPROVED_IDEA = "REVIEW_APPROVED_IDEA",
  ASSESSMENT_LAUNCHED = "ASSESSMENT_LAUNCHED",
  RESUBMIT_IDEA = "RESUBMIT_IDEA",
  REVIEW_ASSESSED_AUTOMATION = "REVIEW_ASSESSED_AUTOMATION",
  ASSIGN_PROJECT_MANAGER = "ASSIGN_PROJECT_MANAGER",
  PROJECT_MANAGER_ASSIGNED = "PROJECT_MANAGER_ASSIGNED",
  FIRST_USER_STANDARD = "FIRST_USER_STANDARD",
  FIRST_USER_TRIAL = "FIRST_USER_TRIAL",
  CHANGED_PLAN_DEALHUB_EMAIL = "CHANGED_PLAN_DEALHUB_EMAIL",
  CHANGED_PLAN_OWNER_EMAIL = "CHANGED_PLAN_OWNER_EMAIL",
  CHANGED_PLAN_ERROR_EMAIL = "CHANGED_PLAN_ERROR_EMAIL",
  EXTEND_TRIAL_GRANTED = "EXTEND_TRIAL_GRANTED",
  USER_DELETED = "USER_DELETED",
  REVIEW_REJECTED_IDEA = "REVIEW_REJECTED_IDEA",
  PROCESS_ABUSE_REPORTED = "PROCESS_ABUSE_REPORTED",
  PROCESS_ABUSE_COMMENT_REPORTED = "PROCESS_ABUSE_COMMENT_REPORTED",
  PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_ABOUT_EDIT_RIGHTS_GRANTED",
  PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED",
  PROCESS_COLLABORATOR_COMPONENTS_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_COMPONENTS_EDIT_RIGHTS_GRANTED",
  COE_DRIVEN_IDEA_SUBMITTED = "COE_DRIVEN_IDEA_SUBMITTED",
  PROCESS_COMMENT_USER_MENTIONED = "PROCESS_COMMENT_USER_MENTIONED",
  USER_DEACTIVATED = "USER_DEACTIVATED",
  USER_ACTIVATED = "USER_ACTIVATED",
  PROCESS_COLLABORATOR_DOCUMENTS_EDIT_RIGHTS_GRANTED = "PROCESS_COLLABORATOR_DOCUMENTS_EDIT_RIGHTS_GRANTED",
  COMPONENT_UPDATED_FOR_DOWNLOADED_USERS = "COMPONENT_UPDATED_FOR_DOWNLOADED_USERS",
  COMPONENT_CURATION_REJECTED = "COMPONENT_CURATION_REJECTED",
  COMPONENT_BUG_REPORTED = "COMPONENT_BUG_REPORTED",
  COMPONENT_ABUSE_REPORTED = "COMPONENT_ABUSE_REPORTED",
  NEW_IDEA_SUBMITTED_PROGRAM_MANAGER = "NEW_IDEA_SUBMITTED_PROGRAM_MANAGER",
  NEW_COE_IDEA_SUBMITTED_PROGRAM_MANAGER = "NEW_COE_IDEA_SUBMITTED_PROGRAM_MANAGER",
  CD_AUTOMATION_SHARED = "CD_AUTOMATION_SHARED",
  CD_AUTOMATION_TO_TECHNICAL_REVIEW = "CD_AUTOMATION_TO_TECHNICAL_REVIEW",
  CD_AUTOMATION_REJECTED = "CD_AUTOMATION_REJECTED",
  CD_AUTOMATION_REWORK_REQUIRED = "CD_AUTOMATION_REWORK_REQUIRED",
  CD_AUTOMATION_REQUESTED = "CD_AUTOMATION_REQUESTED",
  PROCESS_COMMENT_REPLY_ADDED = "PROCESS_COMMENT_REPLY_ADDED",
  WELCOME_EMAIL_FPS = "WELCOME_EMAIL_FPS",
  PROCESS_QUESTION_USER_MENTIONED = "PROCESS_QUESTION_USER_MENTIONED",
  CHANGE_REQUEST_SUBMITTED = "CHANGE_REQUEST_SUBMITTED"
}
