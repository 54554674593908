import axios from "../axiosInstance";

export const USER_SETTINGS_TYPE_EMAIL: string = "email-settings";
export const USER_SETTINGS_TYPE_LANGUAGE: string = "language-settings";
export const LOCALIZATION_PREFIX_EMAIL_SETTING = "email_settings";
export const MASTER_PREFERENCES_SECTION_SLUG = "master-preferences";
export const BINARY_SETTING_TYPE = "Binary";
export const SETTING_OPTION_INPUT_FIELD = "InputField";
export const EMAIL_REMINDER_INTERVAL_TYPE = "EmailReminderInterval";
export const NUM_EMAIL_REMINDER_TYPE = "NumEmailReminder";

export type LanguageSetting = {
  setting_id: number;
  setting_type: string;
  setting_key: string;
  setting_description: string;
  setting_name: string;
  setting_default_value: string;
  setting_is_active: number;
  user_id: null | number;
  user_setting_id: null | number;
  user_setting_value: string;
};

export const getEmailSettingsData = async (userSlug: string): Promise<any> => {
  let path = `/api/v1/users/slug/${userSlug}/settings?settingType=email-settings`;
  const response = await axios.get(path);
  return response.data.data;
};

export const getUserLanguageSettingsData = async (
  userSlug: string
): Promise<LanguageSetting> => {
  let path = `/api/v1/users/slug/${userSlug}/settings?settingType=language-settings`;
  const response: any = await axios.get(path);
  return response.data.data[0];
};

export const editUserSettings = async (
  userSlug: string,
  data: { user_settings: any; turn_off_all_emails?: boolean },
  settingType: string
): Promise<any> => {
  let path = `/api/v1/users/slug/${userSlug}/settings?settingType=${settingType}`;
  const response = await axios.patch(path, data);
  return response.data.data;
};
