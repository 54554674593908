import axios, { longRunningRequest } from "../../../utils/axiosInstance";
import apiErrorAlert from "../../../utils/apiErrorAlert";
import Swal from "../../../components/SweetAlert/SweetAlert";
import { API_URL } from "../../../constants/envConstants";

const URL_WITHOUT_TENANT = "/fd-api/v1/auth/fps-signup";
const URL_WITH_TENANT = "/fd-api/v1/auth/fps-attach-classic";
const URL_FOR_ORPHAN = "/fd-api/v1/auth/fps-attach-orphan";

export const enableAutomationHub = (param, cb, t, withTenant = false) => {
  if (!t || typeof t !== "function") {
    t = () => {};
  }

  let url = API_URL + (withTenant ? URL_WITH_TENANT : URL_WITHOUT_TENANT);

  longRunningRequest(axios, {
    method: "post",
    url: url,
    ...param
  })
    .then(resp => {
      if (resp && resp.data) {
        if (cb && typeof cb === "function") {
          cb(resp);
        }
      }
    })
    .catch(e => {
      apiErrorAlert(e);
      if (cb && typeof cb === "function") {
        cb();
      }
    });
};

export const enableExistingOrphan = (param, cb, t) => {
  axios({
    method: "post",
    url: URL_FOR_ORPHAN,
    ...param
  })
    .then(resp => {
      if (resp && resp.data) {
        if (cb && typeof cb === "function") {
          cb(resp);
        }
      }
    })
    .catch(e => {
      apiErrorAlert(e);
      if (cb && typeof cb === "function") {
        cb();
      }
    });
};
