import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReportAbuseModal from "./ModalReportAbuse";
import { REPORT_COMPONENT_BUG } from "../../../constants/reportConstants";

class ModalReportComponentBug extends React.Component<any, any> {
  render() {
    return (
      <ReportAbuseModal
        {...this.props}
        title={this.props.t("modal_bug_component_title")}
        message={this.props.t("modal_bug_component_content")}
        type={REPORT_COMPONENT_BUG}
      />
    );
  }
}

export default withTranslation("common")(ModalReportComponentBug);
