import {
  COOptionsInterface,
  AHPermissionsInterface,
  COAssessmentInterface,
  COContextInterface,
  CODisplayContext
} from "../interfaces/co-interfaces";
import { doesExistPositiveContextMatch } from "../utils/co-utils";
import { optionsInContext } from "./co-context.helper";
export const SENSITIVE_INFO_PERMISSIONS_MASK_KEY = "SENSITIVE_INFO";

export const maskSensitiveInfoFromAssessment = ({
  context
}: {
  context: COContextInterface;
}) => {
  let assessment: COAssessmentInterface | undefined = context.assessment;
  if (assessment) {
    for (const section of assessment.co_assessment_sections || []) {
      section.co_questions = section.co_questions?.filter(question => {
        let questionContext = context.update?.({ question }) || context;
        const resolvedOptionsInContext: COOptionsInterface = optionsInContext({
          context: questionContext,
          options: question.options || {},
          should_resolve: true
        });
        if (
          !isVisibleBasedOnPermissions({
            context,
            resolvedOptionsInContext
          })
        ) {
          return false;
        }
        return true;
      });
    }
  }
};

export const isVisibleBasedOnPermissions = ({
  context,
  resolvedOptionsInContext
}: {
  context: COContextInterface;
  resolvedOptionsInContext: COOptionsInterface;
}): boolean => {
  if (
    resolvedOptionsInContext.visibility_permissions &&
    resolvedOptionsInContext.visibility_permissions.length > 0
  ) {
    if (
      context.process_external_data?.permissions &&
      Array.isArray(resolvedOptionsInContext.visibility_permissions) &&
      resolvedOptionsInContext.visibility_permissions.length > 0 &&
      // we can't filter out questions during submission or customization because we need them for calculation
      !doesExistPositiveContextMatch(context.display_context, {
        assessment_customize: 1,
        assessment_submission: 1
      } as CODisplayContext)
    ) {
      let permissions: AHPermissionsInterface =
        context.process_external_data?.permissions;
      for (const permission of resolvedOptionsInContext.visibility_permissions) {
        let hasPermissions = !!permissions.ah_permissions?.[permission];
        if (hasPermissions) {
          return true;
        }
      }
      // if we have permissions se t for the user and visibility_permissions defined and not empty
      return false;
    }
  }
  return true;
};
