import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { hideModal } from "../../../actions/modalActions";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import customActivityIcon from "../../../assets/images/component_type_icons/ic-custom-activity.svg";
import snippetIcon from "../../../assets/images/component_type_icons/ic-snippet.svg";
import workflowIcon from "../../../assets/images/component_type_icons/ic-workflow-template.svg";
import connectorIcon from "../../../assets/images/component_type_icons/ic-connector.svg";
import dashboardIcon from "../../../assets/images/component_type_icons/ic-dashboard.svg";
import solutionIcon from "../../../assets/images/component_type_icons/ic-solution.svg";
import machineLearningIcon from "../../../assets/images/component_type_icons/ic-machine-learning-model.svg";

import "./ModalComponentTypes.scss";

class ModalComponentTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: 1,
      comment: ""
    };
  }

  closeModal = () => {
    const { hideModal } = this.props;

    hideModal();
  };

  render() {
    const contentStyle = {
      borderRadius: "4px",
      boxShadow: "-8px 0 16px 0 rgba(0, 0, 0, 0.1)",
      paddinBottom: "49px"
    };

    const headerStyle = {
      borderBottom: "0px",
      paddingBottom: "0px"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={contentStyle}>
        <ModalTemplateOne
          heading=""
          onClose={this.closeModal}
          headerStyle={headerStyle}
        >
          <div
            className="ModalComponentTypes"
            data-testid="ModalComponentTypes"
          >
            <h1 className="ModalComponentTypes__header">
              {this.props.t("modal_component_types_header")}
            </h1>
            <ul className="ModalComponentTypes-list">
              <li className="ModalComponentTypes-item">
                <img src={customActivityIcon} alt="customActivityIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_custom_activity")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t(
                    "modal_component_types_custom_activity_content"
                  )}
                </p>
              </li>
              <li className="ModalComponentTypes-item">
                <img src={snippetIcon} alt="snippetIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_snippet")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t("modal_component_types_snippet_content")}
                </p>
              </li>
              <li className="ModalComponentTypes-item">
                <img src={workflowIcon} alt="workflowIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_workflow")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t("modal_component_types_workflow_content")}
                </p>
              </li>
              <li className="ModalComponentTypes-item">
                <img src={connectorIcon} alt="connectorIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_connector")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t("modal_component_types_connector_content")}
                </p>
              </li>
              <li className="ModalComponentTypes-item">
                <img src={dashboardIcon} alt="dashboardIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_dashboard")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t("modal_component_types_dashboard_content")}
                </p>
              </li>
              <li className="ModalComponentTypes-item">
                <img src={solutionIcon} alt="solutionIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_machine_learning")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t(
                    "modal_component_types_machine_learning_content"
                  )}
                </p>
              </li>
              <li className="ModalComponentTypes-item">
                <img src={machineLearningIcon} alt="machineLearningIcon" />
                <h3 className="ModalComponentTypes-item-header">
                  {this.props.t("modal_component_types_solution")}
                </h3>
                <p className="ModalComponentTypes-item-body">
                  {this.props.t("modal_component_types_solution_content")}
                </p>
              </li>
            </ul>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  hideModal
};

export default withTranslation("common")(
  connect(null, mapDispatchToProps)(ModalComponentTypes)
);
