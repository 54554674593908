import {
  FETCH_COMPONENT_PROFILE,
  FETCH_SIMILAR_COMPONENTS,
  CLEAR_COMPONENT_PROFILE,
  SET_COMPONENT_REVIEW_SECTION
} from "../constants/actionTypes";

const INITIAL_STATE = {
  componentProfile: null,
  similarComponents: null,
  componentReviewSection: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COMPONENT_PROFILE:
      return {
        ...state,
        componentProfile: action.payload
      };
    case FETCH_SIMILAR_COMPONENTS:
      return {
        ...state,
        similarComponents: action.payload
      };
    case CLEAR_COMPONENT_PROFILE:
      return {
        ...INITIAL_STATE
      };
    case SET_COMPONENT_REVIEW_SECTION:
      return {
        ...state,
        componentReviewSection: action.payload
      };
    default:
      return state;
  }
}
