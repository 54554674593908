import Auth, { auth } from "./../utils/auth";
import axios from "axios";
import SessionHelper from "./../helpers/SessionHelper";
import { LANGUAGE_COOKIE_NAME } from "../constants/localizationConstants";
import { PUBLIC_URL, BUILD_VERSION } from "../constants/envConstants";

//= ===============================
// Server Instance
//= ==============================

const axiosInstance = axios.create();

//global refresh array for batching callback functions, this is so we don't have multiple auth retries if we have multiple axios events fail
const sessionRefreshAuthArray = [];

export const longRunningRequest = async (
  axe,
  { method = "GET", url, data = {}, config = {} }
) => {
  //make request
  return new Promise((resolve, reject) => {
    axe({
      method: method,
      url: url,
      data: data,
      timeout: 1000 * 60 * 60 * 4,
      onDownloadProgress: progressEvent => {
        //I think this gets skipped if there is no progress event
        let output =
          progressEvent &&
          progressEvent.srcElement &&
          progressEvent.srcElement.responseText;
        //console.log("got output");
        // console.log(output)
      },
      ...config
    })
      .then(response => {
        //this won't called until it's over BUT the response header is set as 200 from the initial response - so we need really good error handling here
        let output = response.data || response;
        if (typeof output === "object" && output !== null) {
          if (output.status && output.status === "error") {
            let error = {};
            error.response = {};
            error.response.data = {};
            error.response.data.data = output.data;
            error.response.status = output.statusCode || 500;
            error.config = {};
            console.log(error);
            reject(error);
            return;
          }

          resolve(response);
          return;
        }

        let error = {};
        error.response = {};
        error.response.data = { message: "Unable to Parse Response" };
        reject(error);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      });
  });
};

//set headers
axiosInstance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers["x-connect-localization"] =
      SessionHelper.getValueForKey(LANGUAGE_COOKIE_NAME) || "en";
    config.headers["PUBLIC_URL"] = PUBLIC_URL;
    config.headers["BUILD_VERSION"] = BUILD_VERSION;
    config.headers["x-ah-referer"] =
      window.location.origin + window.location.pathname;

    let cloudAccessToken = SessionHelper.getUserCloudAccessToken();
    if (cloudAccessToken) {
      config.headers["x-ah-user-cloud-access-token"] = cloudAccessToken;
    }

    if (!config.isAuthLogin) {
      config.headers["Authorization"] =
        "Bearer " +
        (SessionHelper.getUserToken() ? SessionHelper.getUserToken() : "");
      const userTokenData = SessionHelper.getDataFromUserToken();
      const tenantApiUrl = userTokenData.t_url;
      if (tenantApiUrl) {
        // have to set the default here too for when token expires to still know where to go
        axiosInstance.defaults.baseURL = tenantApiUrl;
        config.baseURL = tenantApiUrl;
      }

      // if we don't have a base URL, and there is not a url in memory we want to log the user out.
      // This is to handle moving from the single backend structure to the frontdoor structure
      if (!config.baseURL) {
        const error = {
          response: {
            data: {
              data: {
                message: "Session expired, please log in."
              }
            }
          }
        };
        auth.logout();
        return Promise.reject(error);
      }

      return config;
    } else {
      return config;
    }
  },
  function(error) {
    return Promise.reject(error);
  }
);

//if we get a 401 try to auth and retry one time only
axiosInstance.interceptors.response.use(null, error => {
  //console.log("API Error Handling");
  //console.log(error);
  //console.log(error.config);

  //auth login is handled in the auth.js file
  if (
    error.config &&
    error.response &&
    error.response.status === 401 &&
    !error.config.isRetry &&
    !error.config.isAuthLogin
  ) {
    return new Promise((resolve, reject) => {
      const completionCallBack = user_token => {
        const new_config = error.config;
        if (user_token) {
          new_config.isRetry = true;
          new_config.headers["Authorization"] = "Bearer " + user_token;

          //if we're switching backends on the fly - we need to make sure we don't retry any pending requests without swapping out the new backend url
          const userTokenData = SessionHelper.getDataFromUserToken();
          const tenantApiUrlFromToken = userTokenData.t_url;
          if (tenantApiUrlFromToken) {
            if (tenantApiUrlFromToken !== new_config.baseURL) {
              if (new_config.url && new_config.baseURL) {
                new_config.url = new_config.url.replace(
                  new_config.baseURL,
                  tenantApiUrlFromToken
                );
                console.log("base url miss-match swapping");
              } else {
                resolve(new_config);
                return;
              }
            }
          }

          console.log("retrying request");
          resolve(axiosInstance.request(new_config));
        } else {
          console.log("error refreshing token from background in interceptor");
          resolve(new_config);
        }
      };

      //we want to organze the callbacks so we only have to re-auth once regardless of how many endpoints 401d
      if (sessionRefreshAuthArray.length === 0) {
        const auth = new Auth();
        auth.refreshSessionInBackground(user_token => {
          //loop through each callback and tell them they can retry with the new token
          sessionRefreshAuthArray.forEach(callbackFunction => {
            callbackFunction(user_token);
          });

          if (!user_token) {
            //lets log out
            console.log("logout from reauth flow");
            auth.logout();
          }
          //clear
          const length = sessionRefreshAuthArray.length;
          for (var x = 0; x < length; x++) {
            sessionRefreshAuthArray.pop();
          }
        });
      }
      sessionRefreshAuthArray.push(completionCallBack);
    });
  }
  return Promise.reject(error);
});

export default axiosInstance;
