import { SHOW_MODAL, HIDE_MODAL } from "../constants/actionTypes";

//= ===============================
// Modal actions
//= ===============================

export function loadModal(modalType: string, modalProps = {}) {
  return {
    type: SHOW_MODAL,
    modalType,
    modalProps
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL
  };
}
