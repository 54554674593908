import React, { Component } from "react";
import apiErrorAlert from "../../utils/apiErrorAlert";
import axios from "../../utils/axiosInstance";
import { auth } from "./../../utils/auth";

class LogOut extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.logOutUser();
  }

  logOutUser = async () => {
    try {
      let loggedOut = false;
      // In case logout is too slow or fails
      setTimeout(() => {
        if (!loggedOut) {
          auth.logout();
        }
      }, 1000);
      await axios.get("/api/v1/logout");
      loggedOut = true;
      auth.logout();
    } catch (e) {
      auth.logout();
    }
  };

  render() {
    return <div style={{ height: "100vh" }} />;
  }
}

export default LogOut;
