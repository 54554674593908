import { FETCH_SAVE_VIEW, CLEAR_SAVE_VIEW } from "../constants/actionTypes";

const DEFAULT_STATE = {
  saveViewData: null
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SAVE_VIEW:
      return {
        ...state,
        saveViewData: action.payload
      };
    case CLEAR_SAVE_VIEW:
      return {
        ...state,
        saveViewData: null
      };
    default:
      return state;
  }
};
