import {
  COComponentType,
  COConditionSlugs
} from "../../../constants/co-constants";
import {
  COMetadataInterface,
  COContextInterface,
  COControlInterface,
  COQuestionInterface,
  COConditionOverrideSlugPayload,
  COQuestionAnswerOptionInterface,
  COControlOptionListItem
} from "../../../interfaces/co-interfaces";
import { branchConditionSlugs } from "../../../constants/co-question-answer-option.constants";

export const branchingPathsControls = (
  context: COContextInterface
): COMetadataInterface | undefined => {
  let currentQuestion: COQuestionInterface | undefined = context.question;
  let currentQuestionAnswerOption: COQuestionAnswerOptionInterface | undefined =
    context.answer_option;
  if (!currentQuestion || !currentQuestionAnswerOption) {
    return undefined;
  }

  let [
    allSections,
    allQuestions
  ] = context.functionAllOtherSectionsAndQuestions?.(context) ?? [[], []];

  // filter out all questions that have branching logic showing/hiding this question
  // to avoid a loop
  if (currentQuestion?.co_question_options_json?.condition_overrides) {
    const allParentBranchQuestionAHIDs: (
      | string
      | undefined
    )[] = currentQuestion?.co_question_options_json?.condition_overrides
      ?.map(condition => {
        const slug: COConditionSlugs = condition.slug as COConditionSlugs;
        if (slug) {
          if (
            branchConditionSlugs.includes(slug) &&
            condition.payload?.co_question_ahid
          ) {
            return condition.payload.co_question_ahid;
          }
        }
        return undefined;
      })
      .filter(ahid => ahid !== undefined);
    allQuestions = allQuestions.filter(question => {
      return !allParentBranchQuestionAHIDs.includes(question.co_question_ahid);
    });
  }

  const optionIsSelectedFunction = ({ option }) => {
    if (context.assessment?.findAHId) {
      const coInterfaceItem = context.assessment.findAHId(option.value);
      if (coInterfaceItem && coInterfaceItem?.options?.condition_overrides) {
        return coInterfaceItem.options.condition_overrides.some(cd =>
          cdIsAMatchingBranchingToggleCondition({
            condition: cd,
            currentQuestion,
            currentQuestionAnswerOption
          })
        );
      }
    }
    return false;
  };

  const getAllSectionOptionListItems = () => {
    return allSections.map(section => {
      return {
        key: section.co_assessment_section_ahid,
        value: section.co_assessment_section_ahid,
        meta: {
          value: section.co_section_meta_json?.title?.value ?? ""
        }
      };
    });
  };

  const getAllQuestionOptionListItems = () => {
    return allQuestions.map(question => {
      return {
        key: question.co_question_ahid,
        value: question.co_question_ahid,
        meta: {
          value: question.co_question_meta_json?.title?.value ?? ""
        }
      };
    });
  };

  return {
    title: {
      value: 'If "{{answer_option_title}}" is selected:',
      value_localization_key: "branching_paths_modal_answer_option",
      value_localization_values: {
        answer_option_title:
          context.answer_option?.co_question_answer_option_meta_json?.title
            ?.value ?? ""
      },
      options: {
        component_type: COComponentType.META_TEXT
      }
    },
    branching: {
      is_editable: true,
      label: "Branching Logic Controls",
      options: {
        component_type: COComponentType.META_CONTROL
      },
      display_context: {
        assessment_customize: 1
      },
      controls: [
        {
          meta: {
            title: {
              value: "Show sections:",
              value_localization_key: "branching_paths_modal_sections_title"
            }
          },
          optionIsSelectedFunction,
          controlFunction: ({
            context,
            control,
            option
          }: {
            context: COContextInterface;
            control: COControlInterface;
            option: any;
          }) => {
            const options = (option as COControlOptionListItem[]) ?? [];

            if (context.assessment?.co_assessment_sections) {
              for (const section of context.assessment.co_assessment_sections) {
                if (section.co_section_options_json?.condition_overrides) {
                  // toggle option for section
                  section.co_section_options_json.condition_overrides = toggleBranchingLogicConditionForSelectedQuestion(
                    {
                      options: options,
                      co_object_ahid: section.co_assessment_section_ahid,
                      condition_overrides:
                        section.co_section_options_json.condition_overrides,
                      currentQuestion,
                      currentQuestionAnswerOption,
                      postConditionAddedFunction: () => {
                        // add the condition to all the questions as well
                        if (section.co_questions) {
                          for (const question of section.co_questions) {
                            // only add if it doesn't already have it
                            if (
                              question.co_question_options_json
                                ?.condition_overrides
                            ) {
                              let questionContainsMatchingCD: boolean = false;
                              for (const condition of question
                                .co_question_options_json.condition_overrides) {
                                if (
                                  cdIsAMatchingBranchingToggleCondition({
                                    condition,
                                    currentQuestion,
                                    currentQuestionAnswerOption
                                  })
                                ) {
                                  questionContainsMatchingCD = true;
                                  break;
                                }
                              }
                              // add condition to the question if it's not already there
                              if (!questionContainsMatchingCD) {
                                question.co_question_options_json.condition_overrides = addCurrentQuestionToBranchingLogicConditions(
                                  {
                                    condition_overrides:
                                      question.co_question_options_json
                                        .condition_overrides,
                                    currentQuestion,
                                    currentQuestionAnswerOption
                                  }
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                  );
                }
              }
            }
          },
          options: {
            component_type: COComponentType.CONTROL_PICKER,
            max_selectable_answers: 10000
          },
          items: getAllSectionOptionListItems()
        },
        {
          meta: {
            title: {
              value: "Show questions:",
              value_localization_key: "branching_paths_modal_questions_title"
            }
          },
          optionIsSelectedFunction,
          controlFunction: ({
            context,
            control,
            option
          }: {
            context: COContextInterface;
            control: COControlInterface;
            option: any;
          }) => {
            const options = (option as COControlOptionListItem[]) ?? [];

            if (context.assessment?.co_assessment_sections) {
              for (const section of context.assessment.co_assessment_sections) {
                if (section.co_questions) {
                  for (const question of section.co_questions) {
                    if (
                      question.co_question_options_json?.condition_overrides
                    ) {
                      // toggle option for question
                      question.co_question_options_json.condition_overrides = toggleBranchingLogicConditionForSelectedQuestion(
                        {
                          options: options,
                          co_object_ahid: question.co_question_ahid,
                          condition_overrides:
                            question.co_question_options_json
                              .condition_overrides,
                          currentQuestion,
                          currentQuestionAnswerOption
                        }
                      );
                    }
                  }
                }
              }
            }
          },
          options: {
            component_type: COComponentType.CONTROL_PICKER,
            max_selectable_answers: 10000
          },
          items: getAllQuestionOptionListItems()
        }
      ]
    }
  };
};

// Toggle branching logic on/off for the section/question with the provided co_object_ahid
// it will add a branching condition to the section/question with the AHIDs
// of the provided currentQuestion/currentQuestionAnswerOption
// so that when currentQuestion is answered with currentQuestionAnswerOption
// co_object_ahid will go from being hidden to being shown
const toggleBranchingLogicConditionForSelectedQuestion = ({
  options,
  co_object_ahid,
  condition_overrides,
  currentQuestion,
  currentQuestionAnswerOption,
  postConditionAddedFunction
}: {
  options: any[]; // all of the already toggled-on options
  co_object_ahid?: string; // the AHID of the option to toggle
  condition_overrides: COConditionOverrideSlugPayload[]; // the condition_overrides of the option to toggle branching_logic on
  currentQuestion?: COQuestionInterface; // the question that, when answered, will show the option
  currentQuestionAnswerOption?: COQuestionAnswerOptionInterface; // the answer_option that, when selected, will show the option
  postConditionAddedFunction?: () => void;
}): COConditionOverrideSlugPayload[] => {
  var conditionOverrides = condition_overrides;

  // if the branching logic condition already exists on this section/question
  // we need to find and remove it
  const optionsContainsQuestion = options.some(
    option => option.value === co_object_ahid
  );
  let containsMatchingCD: boolean = false;
  for (const condition of conditionOverrides) {
    if (
      cdIsAMatchingBranchingToggleCondition({
        condition,
        currentQuestion,
        currentQuestionAnswerOption
      })
    ) {
      containsMatchingCD = true;
      if (!optionsContainsQuestion) {
        // we need to remove the condition
        conditionOverrides = removeAnswerOptionAHIDFromBranchingLogicConditions(
          {
            condition,
            condition_overrides: conditionOverrides,
            currentQuestion,
            currentQuestionAnswerOption
          }
        );
      }
    }
  }

  // if the branching logic condition doesn't exist on the section/question
  // we need to add it
  if (!containsMatchingCD && optionsContainsQuestion) {
    conditionOverrides = addCurrentQuestionToBranchingLogicConditions({
      condition_overrides: conditionOverrides,
      currentQuestion,
      currentQuestionAnswerOption
    });
    postConditionAddedFunction?.();
  }
  return conditionOverrides;
};

// check if the provided condition matches the provided currentQuestion/currentQuestionAnswerOption
// by checking if the condition is a branching logic condition
// that contains the AHIDs of the currentQuestion & currentQuestionAnswerOption
const cdIsAMatchingBranchingToggleCondition = ({
  condition,
  currentQuestion,
  currentQuestionAnswerOption
}: {
  condition: COConditionOverrideSlugPayload; // the condition we want to check if it matches
  currentQuestion?: COQuestionInterface; // the question whose AHID we want to check if exists in the condtion
  currentQuestionAnswerOption?: COQuestionAnswerOptionInterface; // the question_answer_option whose AHID we want to check if exists in the condtion
}): boolean => {
  const co_question_ahid = currentQuestion?.co_question_ahid;
  const co_question_answer_option_ahid =
    currentQuestionAnswerOption?.co_question_answer_option_ahid;
  // some payloads will have an array of answer options that are comma separated
  const payload_answer_options_ahids = (
    condition.payload?.co_question_answer_option_ahid ?? ""
  ).split(",");

  if (
    condition.slug ===
    COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS
  ) {
    // check that condition payload matches the question
    // and the answer option array includes the current answer option
    return (
      condition.payload?.co_question_ahid === co_question_ahid &&
      payload_answer_options_ahids.includes(
        co_question_answer_option_ahid ?? ""
      )
    );
  } else if (
    condition.slug ===
    COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION
  ) {
    // check that condition payload match question and answer option
    return (
      condition.payload?.co_question_ahid === co_question_ahid &&
      condition.payload?.co_question_answer_option_ahid ===
        co_question_answer_option_ahid
    );
  }
  return false;
};

// Add the provided currentQuestion/currentQuestionAnswerOption to the section's/question's branching logic conditions
// Either by adding a brand new condition
// or by adding the currentQuestionAnswerOption AHID to an existing branching logic condition for the currentQuestion AHID
const addCurrentQuestionToBranchingLogicConditions = ({
  condition_overrides,
  currentQuestion,
  currentQuestionAnswerOption
}: {
  condition_overrides: COConditionOverrideSlugPayload[]; // the array of condition_overrides on the section/question we want to add the branching logic condition to
  currentQuestion?: COQuestionInterface; // The question to add to the branching logic conditions
  currentQuestionAnswerOption?: COQuestionAnswerOptionInterface; // The question_answer_option to add to the branching logic conditions
}): COConditionOverrideSlugPayload[] => {
  const co_question_ahid = currentQuestion?.co_question_ahid;
  const co_question_answer_option_ahid =
    currentQuestionAnswerOption?.co_question_answer_option_ahid;
  var conditionOverrides = condition_overrides;

  // find an existing BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS condition
  // for the currentQuestion if one exists
  const existingCondition = condition_overrides.find(cd => {
    return (
      cd.slug ===
        COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS &&
      cd.payload?.co_question_ahid === co_question_ahid
    );
  });
  if (
    existingCondition &&
    existingCondition.payload?.co_question_answer_option_ahid
  ) {
    // add the provided currentQuestionAnswerOption AHID to the existing condition
    // The answer_option AHIDs of BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS
    // will be a comma-separated array
    let payload_answer_options_ahids = (
      existingCondition.payload?.co_question_answer_option_ahid ?? ""
    ).split(",");
    payload_answer_options_ahids.push(co_question_answer_option_ahid ?? "");
    existingCondition.payload.co_question_answer_option_ahid = payload_answer_options_ahids.join(
      ","
    );
  } else {
    // if we don't have an existing condition yet, check for legacy BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION conditions
    // so we can combine them into one new BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS condition
    var co_question_answer_option_ahids: string[] = [];
    if (co_question_answer_option_ahid) {
      co_question_answer_option_ahids.push(co_question_answer_option_ahid);
    }

    const existingLegacyConditions = condition_overrides.filter(cd => {
      return (
        cd.slug ===
          COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION &&
        cd.payload?.co_question_ahid === co_question_ahid
      );
    });
    if (existingLegacyConditions) {
      // we found legacy BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION conditions
      existingLegacyConditions.forEach(condition => {
        // get each AHID for the new condition
        if (condition.payload?.co_question_answer_option_ahid) {
          co_question_answer_option_ahids.push(
            condition.payload.co_question_answer_option_ahid
          );
        }
        // and remove the legacy condition
        conditionOverrides = removeAnswerOptionAHIDFromBranchingLogicConditions(
          {
            condition,
            condition_overrides: conditionOverrides,
            currentQuestion,
            currentQuestionAnswerOption
          }
        );
      });
    }

    // make a new BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS
    // using the AHID of the currentQuestion and the AHIDs of the other legacy conditions
    conditionOverrides.push({
      slug:
        COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS,
      payload: {
        co_question_ahid: co_question_ahid,
        co_question_answer_option_ahid: co_question_answer_option_ahids.join(
          ","
        )
      }
    });
  }

  return conditionOverrides;
};

// Remove the currentQuestion/currentQuestionAnswerOption from the condition_overrides
// By removing the answer_option AHID from a condition with multiple AHIDs
// Or removing a condition that only has 1 AHID
const removeAnswerOptionAHIDFromBranchingLogicConditions = ({
  condition,
  condition_overrides,
  currentQuestion,
  currentQuestionAnswerOption
}: {
  condition: COConditionOverrideSlugPayload; // The condition we want to remove
  condition_overrides: COConditionOverrideSlugPayload[]; // the array of condition_overrides on the section/question we want to remove the answer_options AHID from
  currentQuestion?: COQuestionInterface; // The question for the answer_option we want to remove from the branching logic conditions
  currentQuestionAnswerOption?: COQuestionAnswerOptionInterface; // The answer_option we want to remove from the branching logic conditions
}): COConditionOverrideSlugPayload[] => {
  const co_question_answer_option_ahid =
    currentQuestionAnswerOption?.co_question_answer_option_ahid;

  const removeCondition = (): COConditionOverrideSlugPayload[] => {
    return condition_overrides.filter(cd => {
      return !(
        cd.slug === condition.slug &&
        cd.payload?.co_question_ahid === condition.payload?.co_question_ahid &&
        cd.payload?.co_question_answer_option_ahid ===
          condition.payload?.co_question_answer_option_ahid
      );
    });
  };

  if (
    condition.slug ===
    COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS
  ) {
    if (condition.payload?.co_question_answer_option_ahid) {
      // remove the answer_option AHID from the list of AHIDs for this condition
      // BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS will have an array of AHIDs
      let payload_answer_options_ahids = (
        condition.payload?.co_question_answer_option_ahid ?? ""
      ).split(",");
      payload_answer_options_ahids = payload_answer_options_ahids.filter(
        ahid => ahid !== co_question_answer_option_ahid
      );
      condition.payload.co_question_answer_option_ahid = payload_answer_options_ahids.join(
        ","
      );
      if (condition.payload.co_question_answer_option_ahid === "") {
        // if we now have no AHIDs left, remove the condition
        return removeCondition();
      }
      return condition_overrides;
    } else {
      // if the condition doesn't have a proper payload, just remove it
      return removeCondition();
    }
  } else if (
    condition.slug ===
    COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION
  ) {
    // BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION is a legacy condition
    // that will only have 1 AHID, so we can just remove the condition
    return removeCondition();
  }
  return [];
};
