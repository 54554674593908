import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  ADMIN_MANAGER_USERS_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleAction } from "../../../../../classes/actions/co-role-actions.class";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const adminRoleInvitesNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = adminRoleInvitesNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.ADMIN_ROLE_INVITES,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const adminRoleInvitesNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.ASSIGNED_SYSTEM_ADMIN: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_notification_id: 109,
          platform_event_type: COPlatformEventType.ASSIGNED_SYSTEM_ADMIN,
          notification_category_type:
            CONotificationActionCategoryTypes.ADMIN_ROLES,
          notification_group_slug:
            CONotificationActionGroupSlugs.ADDED_SYSTEM_ADMIN,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "You were assigned System admin"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added you as a System Admin."
          },
          redirect_url: {
            value: ADMIN_MANAGER_USERS_URL
          }
        }
      };
    }
  }
  return undefined;
};
