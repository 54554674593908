import React, { ReactNode } from "react";
import styled, { css } from "styled-components/macro";
import {
  EditorState,
  RichUtils,
  Modifier,
  SelectionState,
  CompositeDecorator,
  getDefaultKeyBinding,
  KeyBindingUtil,
  get
} from "draft-js";
import "draft-js/dist/Draft.css";
import MultiDecorator from "draft-js-plugins-editor/lib/Editor/MultiDecorator";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "./TextEditorComments.scss";
import {
  INLINE_STYLES,
  BLOCK_STYLES,
  CUSTOM_STYLES
} from "../../constants/draftJSDefinitions";
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "@draft-js-plugins/mention";
import axios from "../../utils/axiosInstance";
import apiErrorAlert from "../../utils/apiErrorAlert";
import urlWithTenant from "../../utils/urlHelper";
import {
  getCharacters,
  getBlockCharacters,
  getCurrentSelectionDomElement
} from "../../utils/editorHelper";
import IconApollo from "../IconApollo/IconApollo";
import AddLinkPrompt from "./AddLinkPrompt/AddLinkPrompt";
import { sanitizeUrl } from "@braintree/sanitize-url";
import withResizeObserver from "../hoc/withResizeObserver/withResizeObserver";
import { setHttp } from "../../constants/regexConstants";
import { getKeyValue } from "../../utils/objectParsers";
import DropdownTethered from "../DropdownTethered/DropdownTethered";
import { withTranslation } from "react-i18next";
import { MentionEntry } from "./MentionComponents/MentionComponents";

const AddLinkPromptStyled = styled(AddLinkPrompt)<{
  $positionTop?: number;
  $positionLeft?: number;
}>(
  props => css`
    position: absolute;
    top: ${props.$positionTop}px;
    left: ${props.$positionLeft}px;
    z-index: 3;
  `
);

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const LinkDecoratorComponent = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const linkDecoratorObj = {
  strategy: findLinkEntities,
  component: LinkDecoratorComponent
};
//this is for plain text to rich text
export const linkDecorator = new CompositeDecorator([linkDecoratorObj]);

const StylingButtons = props => {
  const { toggle, style, label, active, icon, editorState, onChange } = props;
  const defaultClass = ["StylingButtons__btn"];

  if (active) {
    defaultClass.push("StylingButtons__btn--active");
  }
  return (
    <button
      className={defaultClass.join(" ")}
      onClick={e => toggle(e, style, editorState, onChange)}
    >
      {icon ? (
        <IconApollo
          className="StylingButtons__btn-icon"
          icon={icon}
          srText={label}
        />
      ) : (
        label
      )}
    </button>
  );
};

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank"
});

export const draftJsPlugins = [
  linkifyPlugin,
  {
    decorators: [linkDecoratorObj]
  }
];

const getPluginDecoratorArray = () => {
  let decorators = [];
  let plugin;
  for (plugin of draftJsPlugins) {
    if (
      plugin &&
      plugin.decorators !== null &&
      plugin.decorators !== undefined
    ) {
      decorators = decorators.concat(plugin.decorators);
    }
  }
  return decorators;
};

// Need to export decorators to use them when creating initial state of
// view only editor. Allows links to be clickable with no editor state update
export const getAllDraftJsDecorators = () => {
  return new MultiDecorator([
    new CompositeDecorator(getPluginDecoratorArray())
  ]);
};

class TextEditorComments extends React.Component<any, any> {
  private editorRef: any;
  private editorContainerRef: any;
  private mentionPlugin: any;
  private timer: any;

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.editorContainerRef = React.createRef();
    this.state = {
      suggestions: [],
      full_suggestions: [],
      suggestionsAreVisible: false,
      showLinkInput: false,
      linkValue: "",
      linkBoxPosition: null
    };
    // mention plugin needs to be created inside the constructor
    this.mentionPlugin = createMentionPlugin({
      theme: {
        mentionSuggestionsPopup: "TextEditorComments__mention-container"
      }
    });
    this.timer = null;
  }

  componentDidMount = () => {
    if (this.props.focusOnLoad) {
      setTimeout(() => {
        this.focusOnEditor();
      }, 250);
    }
  };

  generateControlButtons = () => {
    const {
      hideInlineStyling,
      hideBlockStyling,
      editorState = EditorState.createEmpty(getAllDraftJsDecorators()),
      onChange = () => {},
      observerContainerRef
    } = this.props;
    const currentInlineStyles = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();

    const contentState = editorState.getCurrentContent();
    const blockType = contentState
      .getBlockForKey(selection.getStartKey())
      .getType();

    let buttonsOutsideMenu: ReactNode[] = [];
    let buttonsInsideMenu: ReactNode[] = [];

    if (!hideInlineStyling) {
      INLINE_STYLES.forEach(({ label, style, icon }, idx) => {
        let active = false;

        if (currentInlineStyles && currentInlineStyles.has(style)) {
          active = true;
        }

        buttonsInsideMenu.push(
          <StylingButtons
            toggle={this.toggleInline}
            editorState={editorState}
            onChange={onChange}
            style={style}
            label={label}
            active={active}
            icon={icon}
            key={`${label}-${idx}`}
          />
        );
      });
    }

    if (!hideBlockStyling) {
      BLOCK_STYLES.forEach(({ label, style, icon }, index) => {
        buttonsInsideMenu.push(
          <StylingButtons
            toggle={this.toggleBlock}
            onChange={onChange}
            editorState={editorState}
            label={<span>{label}</span>}
            style={style}
            active={[blockType].indexOf(style) > -1}
            icon={icon}
            key={`${label}-${index}`}
          />
        );
      });
    }

    if (observerContainerRef.current) {
      let containerWidth =
        getKeyValue(observerContainerRef, "current.clientWidth") || 0;
      //This value is obtained from the min-width of "StylingButtons__btn" in TextEditorComments.scss

      const controlIconWidth = 49;

      if (containerWidth > 0) {
        //we need space to keep adding in icons
        // we need a 5px buffer here so size changes can continue to be detected when they change
        while (containerWidth - 5 >= controlIconWidth) {
          containerWidth = containerWidth - controlIconWidth;
          buttonsOutsideMenu.push(buttonsInsideMenu.shift());
        }

        if (buttonsInsideMenu.length > 0) {
          buttonsInsideMenu.unshift(buttonsOutsideMenu.pop());
        }
      } else {
        buttonsOutsideMenu = buttonsInsideMenu;
        buttonsInsideMenu = [];
      }
    } else {
      buttonsOutsideMenu = buttonsInsideMenu;
      buttonsInsideMenu = [];
    }

    return {
      buttonsOutsideMenu,
      buttonsInsideMenu
    };
  };

  generateButtonsMenu = buttonsInsideMenu => {
    return Array.isArray(buttonsInsideMenu) && buttonsInsideMenu.length > 0 ? (
      <DropdownTethered
        closeOnOutsideClickOnly
        tetherStyle={{ zIndex: 1 }}
        baseComponent={
          <button className="StylingButtons__btn">
            <IconApollo
              className="StylingButtons__btn-icon"
              icon="more_horiz"
              srText={this.props.t(
                "sr_alt_text_button_actions_dropdown",
                "Button Actions Dropdown"
              )}
            />
          </button>
        }
      >
        <div className="TextEditorComments__buttons-menu-container">
          {buttonsInsideMenu}
        </div>
      </DropdownTethered>
    ) : null;
  };

  focusOnEditor = () => {
    this?.editorRef?.current?.editor?.focus?.();
  };

  getPromptForLink = editorState => {
    const { onChange } = this.props;
    const selection = editorState.getSelection();

    this.setState(({ showLinkInput }) => {
      if (showLinkInput) {
        onChange(RichUtils.toggleInlineStyle(editorState, "HYPERLINK"));
        return {
          showLinkInput: false,
          linkValue: ""
        };
      } else if (!selection.isCollapsed()) {
        onChange(RichUtils.toggleInlineStyle(editorState, "HYPERLINK"));
        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
        const selectionDomElement = getCurrentSelectionDomElement(
          editorState,
          this.editorContainerRef.current
        );

        let top = 0;
        let left = 0;
        let topOffset = 0;
        let leftOffset = 0;

        if (selectionDomElement) {
          ({ top, left } = selectionDomElement.getBoundingClientRect());

          //need to calculate where the 'Add Link' modal position relative to editor
          //20 needs to be added to account for the styling bar on the top offset
          if (
            this.editorContainerRef.current &&
            this.editorContainerRef.current.getBoundingClientRect()
          ) {
            const editorContainerAbsCoord = this.editorContainerRef.current.getBoundingClientRect();
            topOffset = editorContainerAbsCoord.top - 20;
            leftOffset = editorContainerAbsCoord.left;
          }
        }

        let url = "";

        if (linkKey) {
          const linkInstance = contentState.getEntity(linkKey);
          url = linkInstance.getData().url;
        }

        return {
          showLinkInput: true,
          linkValue: url,
          linkBoxPosition: {
            top: top - topOffset,
            left: left - leftOffset
          }
        };
      }

      return null;
    });
  };

  toggleInline = (e, style, editorState, onChange) => {
    e.preventDefault();
    if (style === "HYPERLINK") {
      this.getPromptForLink(editorState);
      return;
    }
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  toggleBlock = (e, style, editorState, onChange) => {
    e.preventDefault();
    onChange(RichUtils.toggleBlockType(editorState, style));
  };

  onSearchChange = ({ value }) => {
    let self = this;
    self.setState({
      suggestions: defaultSuggestionsFilter(value, this.state.full_suggestions),
      current_search: value
    });
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      let query = "/api/v1/users?s=" + self.state.current_search + "&limit=10";
      axios
        .get(query)
        .then(response => {
          let users = response.data.data.users;
          let suggestions: any[] = [];
          users.forEach(function(user) {
            let userToSuggest: {
              name?: string;
              link?: string;
              avatar?: string;
              user_profile_slug?: string;
            } = {};
            userToSuggest.name =
              (user.user_first_name ? user.user_first_name : "") +
              " " +
              (user.user_last_name ? user.user_last_name : "");
            userToSuggest.link = urlWithTenant(
              "/user/" + user.user_profile_slug
            );
            userToSuggest.avatar = user.user_profile_pic
              ? user.user_profile_pic
              : "";
            userToSuggest.user_profile_slug = user.user_profile_slug;
            suggestions.push(userToSuggest);
          });
          self.setState({
            suggestions: defaultSuggestionsFilter(
              self.state.current_search,
              suggestions
            ),
            full_suggestions: suggestions
          });
        })
        .catch(error => {
          apiErrorAlert(error);
        });
    }, 1000);
  };

  handleBeforeInput = () => {
    if (this.props.maxLength) {
      const currentContent = this.props.editorState.getCurrentContent();
      const currentContentLength = getCharacters(currentContent).length;

      if (currentContentLength > this.props.maxLength - 1) {
        return "handled";
      }
    }
  };

  moveCursorToEnd = (editorState, contentState) => {
    return EditorState.forceSelection(
      editorState,
      contentState.getSelectionAfter()
    );
  };

  addTextUptoMaxLength = editorState => {
    const currentContent = editorState.getCurrentContent();
    let currentContentLength = getCharacters(currentContent).length;
    if (
      typeof this.props.maxLength === "number" &&
      currentContentLength > this.props.maxLength
    ) {
      let amountOverMax = currentContentLength - this.props.maxLength;
      let lastBlock = currentContent.getLastBlock();
      let currentContentTrimmed = currentContent;
      const focusOffset = lastBlock.getLength();
      let selectionStateForTrim = SelectionState.createEmpty(
        lastBlock.getKey()
      ).merge({
        focusOffset,
        focusKey: lastBlock.getKey(),
        hasFocus: true,
        isBackward: false
      });

      while (lastBlock && amountOverMax > 0) {
        const anchorOffset =
          amountOverMax > getBlockCharacters(lastBlock).length
            ? 0
            : getBlockCharacters(lastBlock).length - amountOverMax;

        selectionStateForTrim = selectionStateForTrim.merge({
          anchorKey: lastBlock.getKey(),
          anchorOffset
        });

        currentContentTrimmed = Modifier.removeRange(
          currentContentTrimmed,
          selectionStateForTrim,
          "forward"
        );

        editorState = this.moveCursorToEnd(
          EditorState.push(editorState, currentContentTrimmed, "remove-range"),
          currentContentTrimmed
        );

        selectionStateForTrim = editorState.getSelection();
        const secondToLastBlock = currentContentTrimmed.getBlockBefore(
          lastBlock.getKey()
        );
        lastBlock = secondToLastBlock;
        currentContentLength = getCharacters(currentContentTrimmed).length;
        amountOverMax = currentContentLength - this.props.maxLength;
      }
    }

    return editorState;
  };

  onChange = editorState => {
    editorState = this.addTextUptoMaxLength(editorState);

    if (typeof this.props.onChange === "function") {
      this.props.onChange(editorState);
    }
  };

  customKeyBinding = e => {
    //'1' + `Cmd/Ctrl` + shift
    if (
      (e.keyCode === 49 || e.key === "1") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "header-one";
    } else if (
      (e.keyCode === 50 || e.key === "2") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "header-two";
    } else if (
      (e.keyCode === 48 || e.key === "0") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "header-three";
    } else if (
      (e.keyCode === 88 || e.key === "x") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "strikethrough";
    } else if (
      (e.keyCode === 55 || e.key === "7") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "unordered-list-item";
    } else if (
      (e.keyCode === 56 || e.key === "8") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "ordered-list-item";
    } else if (
      (e.keyCode === 57 || e.key === "9") &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.shiftKey
    ) {
      return "code-block";
    }

    return getDefaultKeyBinding(e);
  };

  handleKeyCommand = (command, editorState) => {
    let newState;
    if (command === "header-one") {
      newState = RichUtils.toggleBlockType(editorState, "header-one");
    } else if (command === "header-two") {
      newState = RichUtils.toggleBlockType(editorState, "header-two");
    } else if (command === "header-three") {
      newState = RichUtils.toggleBlockType(editorState, "header-three");
    } else if (command === "ordered-list-item") {
      newState = RichUtils.toggleBlockType(editorState, "ordered-list-item");
    } else if (command === "unordered-list-item") {
      newState = RichUtils.toggleBlockType(editorState, "unordered-list-item");
    } else if (command === "code-block") {
      newState = RichUtils.toggleBlockType(editorState, "code-block");
    } else if (command === "strikethrough") {
      newState = RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH");
    } else {
      newState = RichUtils.handleKeyCommand(editorState, command);
    }

    if (newState) {
      this.onChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  renderLinkSection = (): ReactNode => {
    const { showLinkInput, linkValue, linkBoxPosition } = this.state;

    let top = 0;
    let left = 0;

    if (linkBoxPosition) {
      ({ top, left } = linkBoxPosition);
    }

    return showLinkInput ? (
      <AddLinkPromptStyled
        linkValue={linkValue}
        onLinkChange={e => {
          this.setState({ linkValue: e.target.value });
        }}
        onAddLink={this.onAddLink}
        onCancel={this.onAddLinkCancel}
        $positionTop={top}
        $positionLeft={left}
      />
    ) : null;
  };

  onAddLink = e => {
    e.preventDefault();
    const { editorState, onChange } = this.props;
    const { linkValue } = this.state;

    const processedLink = linkValue ? setHttp(linkValue) : "";

    let updatedEditorState = editorState;
    if (processedLink) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "LINK",
        "MUTABLE",
        {
          url: processedLink ? sanitizeUrl(processedLink) : processedLink
        }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        editorState.getSelection(),
        entityKey
      );
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithLink,
        decorator: getAllDraftJsDecorators()
      });

      updatedEditorState = RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      );
    }

    onChange(RichUtils.toggleInlineStyle(updatedEditorState, "HYPERLINK"));

    this.setState({
      linkValue: "",
      showLinkInput: false
    });
  };

  onAddLinkCancel = () => {
    const { onChange, editorState } = this.props;

    this.setState(
      {
        linkValue: "",
        showLinkInput: false,
        linkBoxPosition: null
      },
      () => {
        if (typeof onChange === "function" && editorState) {
          onChange(RichUtils.toggleInlineStyle(editorState, "HYPERLINK"));
        }
      }
    );
  };

  onToggleMentionsPopUp = open => {
    this.setState({
      suggestionsAreVisible: open
    });
  };

  render() {
    const {
      onFocus,
      readOnly,
      editorState = EditorState.createEmpty(getAllDraftJsDecorators()),
      ariaLabel,
      ariaLabelledBy,
      observerContainerRef
    } = this.props;
    const controlButtons = this.generateControlButtons();

    const { buttonsOutsideMenu, buttonsInsideMenu } = controlButtons || {};

    let { extraStyling } = this.props;

    let { maxHeight } = this.props;
    maxHeight = maxHeight || "300px";

    let commentTextAreaStyle = {};
    let extraClasses: string[] = [];
    const contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        extraClasses.push("TextEditorComments__hide-placeholder");
      }
    }

    if (readOnly) {
      extraClasses.push("TextEditorComments__read-only");
    }

    if (readOnly) {
      commentTextAreaStyle = {
        height: "auto"
      };
    } else {
      commentTextAreaStyle = {
        overflowY: "auto",
        maxHeight: maxHeight
      };
    }

    extraStyling = {
      border: `1px solid var(--color-border)`,
      padding: ".5rem .5rem 1.8rem",
      borderRadius: "4px",
      minHeight: "150px",
      ...extraStyling
    };

    extraStyling = readOnly ? {} : extraStyling;

    const { MentionSuggestions } = this.mentionPlugin;
    const isButtonsBarHidden =
      readOnly ||
      ((buttonsOutsideMenu || []).length < 1 &&
        (buttonsInsideMenu || []).length < 1);

    return (
      <div
        className={"TextEditorComments " + extraClasses.join(" ")}
        data-testid="TextEditorComments"
      >
        <div
          className="TextEditorComments__main"
          style={extraStyling}
          ref={this.editorContainerRef}
        >
          {isButtonsBarHidden ? null : (
            <div
              className="TextEditorComments__buttons"
              ref={observerContainerRef}
            >
              {buttonsOutsideMenu}
              {this.generateButtonsMenu(buttonsInsideMenu)}
            </div>
          )}
          {this.renderLinkSection()}
          <div
            className="TextEditorComments__text-section"
            data-testid="TextEditorComments__TextSection"
            onClick={this.focusOnEditor}
            style={commentTextAreaStyle}
          >
            <Editor
              editorState={editorState}
              customStyleMap={CUSTOM_STYLES}
              onChange={this.onChange}
              readOnly={readOnly}
              ref={this.editorRef}
              plugins={[...draftJsPlugins, this.mentionPlugin]}
              onFocus={onFocus}
              placeholder={this.props.placeholder || ""}
              ariaLabel={ariaLabel}
              ariaLabelledBy={ariaLabelledBy}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.customKeyBinding}
            />
            <MentionSuggestions
              onSearchChange={this.onSearchChange}
              suggestions={this.state.suggestions}
              open={this.state.suggestionsAreVisible}
              onOpenChange={this.onToggleMentionsPopUp}
              entryComponent={MentionEntry}
            />
            {!!this.props.maxLength && !readOnly && (
              <span className="TextEditorComments__counter">
                {getCharacters(contentState).length}/{this.props.maxLength}
              </span>
            )}
            {this.props.error && (
              <div className="TextEditorComments__error-text">
                {" "}
                {this.props.error}{" "}
              </div>
            )}
          </div>
        </div>
        {readOnly ? null : <div className="TextEditorComments__footer"></div>}
      </div>
    );
  }
}

export default withResizeObserver(
  withTranslation("common")(TextEditorComments)
);
