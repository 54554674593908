import { COBase } from "../classes/co-base.class";
import { COPositionInterface } from "../interfaces/co-interfaces";

export const updatePositionSortOrderForItems = (items: COBase[]) => {
  if (!items) {
    return;
  }
  const spaceMultiplier = 100; // leave some room
  for (var x = 0; x < items.length; x++) {
    // find position property
    let item = items[x];
    for (const key of Object.keys(item)) {
      //find key that ends with position_json
      if (key.endsWith("position_json")) {
        let position_json: COPositionInterface = item[key];
        if (position_json) {
          position_json.sort_order = x * spaceMultiplier;
        }
      }
    }
  }
};
