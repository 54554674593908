import { COCalculatedValueTypes } from "../constants/co-constants";
import {
  COContextInterface,
  COOptionsInterface,
  COQuestionAnswerOptionInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";

export const getValueAndDisplayValueForLinkCOType = (
  answer_value: string
): { value: string; display_value: string } => {
  let parts = (answer_value || "").split(",");
  return {
    value: parts[0],
    display_value: parts.length > 1 ? parts[1] : parts[0]
  };
};

export const getAnswerValueForLinkCOType = (link: string, alias: string) => {
  return `${link},${alias}`;
};

export const calculatedValueTypeForQuestion = ({
  context,
  question,
  optionsInContext
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  optionsInContext: (any) => COOptionsInterface;
}) => {
  let co_calculated_value_type: COCalculatedValueTypes | string | undefined = undefined;

  // First Try overriding from answer type
  if (
    question.co_process_answer?.co_process_answer_selections &&
    question.co_process_answer?.co_process_answer_selections.length > 0
  ) {
    let firstSelectedAnswerOption: COQuestionAnswerOptionInterface | undefined =
      question.co_process_answer?.co_process_answer_selections[0]
        .co_question_answer_option;
    if (firstSelectedAnswerOption) {
      let answerOptionsInContext = optionsInContext({
        context,
        options: firstSelectedAnswerOption.options || {},
        should_resolve: true
      });
      co_calculated_value_type =
        answerOptionsInContext?.calculated_value_type || undefined;
    }
  }

  // Second: Try overriding from Question Template if Answer override did not succeed
  if (!co_calculated_value_type) {
    let questionOptionsInContext = optionsInContext({
      context,
      options: question.options || {},
      should_resolve: true
    });

    if (questionOptionsInContext?.calculated_value_type) {
      // If we have an override from the template, use it
      co_calculated_value_type = questionOptionsInContext?.calculated_value_type;
    }
    else if (questionOptionsInContext?.can_impact_kpi) {
      // If there was no override try to infer if this type must be a NUMBER 
      co_calculated_value_type = COCalculatedValueTypes.NUMBER;
    }
  }

  // Third: Default to TEXT if extraction did not succeed
  if (!co_calculated_value_type) {
    co_calculated_value_type = COCalculatedValueTypes.TEXT;
  }

  return co_calculated_value_type;
};
