import { COContext } from "../classes/co-context.class";
import {
  COMetadataItemInterface,
  COProcessAnswerInterface,
  COQuestionInterface,
  COStringFormat
} from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "../utils/co-utils";
import { metaInContext } from "./co-context.helper";

export const formatValue = ({
  context,
  resolvedMetaItem,
  value,
  translateString
}: {
  context: COContext;
  resolvedMetaItem: COMetadataItemInterface;
  value: any;
  translateString?: (val: string) => string;
}) => {
  if (
    !isNullOrUndefined(resolvedMetaItem.format) &&
    resolvedMetaItem.format &&
    typeof resolvedMetaItem.format !== "string"
  ) {
    let format: COStringFormat = resolvedMetaItem.format;
    if (format.enum_options && typeof format.enum_options !== "string") {
      for (const enumopt of format.enum_options || []) {
        if (parseInt(value + "") === enumopt.VALUE) {
          value = enumopt.KEY;
          break;
        }
      }
    } else if (format.currency && typeof format.currency !== "string") {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: format.currency.currency_abbreviation,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
      value = formatter.format(value);
    } else {
      let prefix = format.prefix || "";
      let suffix = format.suffix || "";

      if (prefix && typeof translateString === "function") {
        prefix = translateString(prefix);
      }
      if (suffix && typeof translateString === "function") {
        suffix = translateString(suffix);
      }
      value = `${prefix}${value}${suffix ? " " + suffix : ""}`;
    }
  }
  return value;
};

export const displayValueForProcessAnswer = ({
  context,
  process_answer,
  value
}: {
  context: COContext;
  process_answer: COProcessAnswerInterface;
  value: string;
}): string => {
  if ((process_answer.co_process_answer_selections?.length || 0) > 0) {
    let displayValue = "";
    for (const answer_selection of process_answer.co_process_answer_selections ||
      []) {
      let answerOption = answer_selection.co_question_answer_option || {};
      let answerOptionMeta = metaInContext({
        context:
          context.update?.({
            display_context: {
              assessment_view: 1
            },
            answer_option: answerOption,
            process_answer_selection: answer_selection,
            process_answer: process_answer || {}
          }) || context,
        meta: answerOption.meta || {},
        should_resolve: true
      });
      if (answerOption.calculation_options?.input_is_value) {
        if (!isNullOrUndefined(value)) {
          return formatValue({
            context,
            resolvedMetaItem:
              answerOptionMeta.title || answerOptionMeta.answer_value || {}, //for the cba we need the answer_value not the title of the answerOptionMeta
            value
          });
        }
      } else {
        displayValue =
          displayValue +
          (displayValue.length > 0 ? " - " : "") +
          answerOptionMeta.title?.value;
      }
    }
    return displayValue || value;
  }
  return value;
};

export const displayValueForKPI = ({
  context,
  question,
  value
}: {
  context: COContext;
  question: COQuestionInterface;
  value: string;
}): string => {
  if (isNullOrUndefined(value)) {
    return value;
  }

  let meta = metaInContext({
    context:
      context.update?.({
        display_context: {
          assessment_view: 1
        },
        question
      }) || context,
    meta: question.meta || {},
    should_resolve: true
  });
  return formatValue({
    context,
    resolvedMetaItem: meta.calculated_value || {},
    value
  });
};
