import {
  FETCH_MANAGE_COMPONENTS,
  EDIT_COMPONENT,
  CLEAR_MANAGE_COMPONENTS,
  PHASE_CHANGE_MANAGE_COMPONENTS,
  DELETE_MANAGE_COMPONENTS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  components: null,
  page: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  count: null
};

const findAndReplaceComponent = (arr, newObj) => {
  for (let i = 0; i < arr.length; i++) {
    const currComponent = arr[i];
    if (currComponent.component_id === newObj.component_id) {
      arr[i] = newObj;
      return newObj;
    }
  }

  return newObj;
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MANAGE_COMPONENTS:
      const {
        components,
        page,
        pageSize,
        totalItems,
        totalPages,
        count
      } = action.payload;

      return {
        ...state,
        components,
        page,
        pageSize,
        totalItems,
        totalPages,
        count
      };
    case EDIT_COMPONENT:
      let newComponents = [...state.components];
      findAndReplaceComponent(newComponents, action.payload);
      return {
        ...state,
        components: newComponents
      };
    case CLEAR_MANAGE_COMPONENTS:
      return INITIAL_STATE;

    case PHASE_CHANGE_MANAGE_COMPONENTS:
      newComponents = state.components.map(component => {
        if (component.component_id === action.payload.component_id) {
          component = action.payload;
        }
        return component;
      });

      return {
        ...state,
        components: newComponents
      };
    case DELETE_MANAGE_COMPONENTS:
      newComponents = state.components.filter(
        component => component.component_slug !== action.payload.slug
      );

      return {
        ...state,
        components: newComponents
      };
    default:
      return state;
  }
}
