import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { BASE_URL } from "../../constants/envConstants";
import { onPrem } from "@uipath/ah-common";
import { FormControlLabel, Radio } from "@material-ui/core";
import IconApollo from "../../components/IconApollo/IconApollo";

const ConfirmSectionWrapper = styled.div`
  height: 95vh;
  padding-bottom: 120px;
  overflow: scroll;
`;

const Title = styled.div(
  props => css`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-foreground, ${props.theme.colors.ink700});
    display: flex;
    padding: 20px;
  `
);

const AlertImgContainer = styled.div`
  width: 40px;
  margin-right: 20px;
`;

const AlertImg = styled(IconApollo)<{ $success?: boolean }>(
  props => css`
    && {
      font-size: 40px;
      color: var(
        ${props.$success ? `--color-success-icon` : `--color-warning-icon`}
      );
    }
  `
);

const Placeholder = styled.div`
  height: calc(76vh - 100px);
`;

const CreateNewText = styled.div`
  font-size: 13px;
  line-height: 20px;
  padding: 10px 20px 10px 26px;
`;

const CreateNewSection = styled.div`
  padding: 0 20px;
`;

const CreateNewSubSection = styled.div`
  margin-left: 27px;
`;

const RadioButtonGroup = styled.div(
  props => css`
    border-bottom: 1px solid var(--color-border, ${props.theme.colors.ink300});
    padding-bottom: 20px;
    max-height: 30vh;
    overflow: auto;
    margin-top: 20px;
  `
);

const BottomText = styled.div`
  font-size: 13px;
  line-height: 20px;
  padding: 40px 20px;
`;

const SingleRadioButton = styled.div`
  padding: 10px 20px 0px 20px;
  display: flex;
  align-items: center;
`;

const TenantLinkText = styled.p(
  props => css`
    color: var(--color-foreground-de-emp, ${props.theme.colors.gray550});
    line-height: 25px;
    font-size: 14px;
  `
);

const TenantLink = styled.a(
  props => css`
    color: var(--color-foreground-de-emp, ${props.theme.colors.gray550});
    margin-top: 5px;
    margin-left: 12px;
  `
);

const TenantLinkWrapper = styled.div`
  margin-left: 62px;
`;

const FpsProvisionTenantSelectorMigrationOptionBox = ({
  createNewInstance,
  setCreateNewInstance,
  onSelectNewInstance,
  tenantsByOwner,
  selectedTenant,
  onSelectTenant,
  generateCountryPicker,
  showSelectTenantSection,
  enableReuseDB,
  userEmail
}) => {
  const { t } = useTranslation("common");
  useEffect(() => {
    if (!tenantsByOwner || tenantsByOwner.length === 0) {
      setCreateNewInstance(true);
    }
  }, []);

  const generateRadioButtons = ({
    tenantsByOwnerArray,
    selectedTenantUuid,
    onChange,
    name
  }) => {
    return (tenantsByOwnerArray || []).map(
      ({ tenant_uuid, tenant_company_name, tenant_slug }, idx) => {
        const url = BASE_URL + "/" + tenant_slug;

        return (
          <div>
            <SingleRadioButton>
              <Radio
                name={name}
                value={tenant_uuid}
                checked={selectedTenantUuid === tenant_uuid}
                onChange={e => {
                  onChange(e, tenant_slug);
                }}
              />
              <TenantLinkText>
                <b>{t(["fps_tenant_select_name", "Name: "])}</b>
                {tenant_company_name}
              </TenantLinkText>
              <TenantLink href={url} target="_blank" rel="noopener noreferrer">
                <IconApollo
                  icon="launch"
                  srText={t([
                    "sr_alt_text_open_link_in_new_tab",
                    "Open link in new tab"
                  ])}
                  fontSize="inherit"
                />
              </TenantLink>
            </SingleRadioButton>
            <TenantLinkWrapper>
              <TenantLinkText>
                <b>{t(["fps_tenant_select_url", "URL: "])}</b>
                {url}
              </TenantLinkText>
            </TenantLinkWrapper>
          </div>
        );
      }
    );
  };

  const showCountryPicker = () => {
    return !enableReuseDB;
  };

  const renderSelectTenantSection = () => {
    if (!tenantsByOwner || tenantsByOwner.length === 0) {
      return;
    }

    return (
      <>
        <CreateNewSection>
          <div>
            <FormControlLabel
              value={!createNewInstance}
              control={
                <Radio
                  value={!createNewInstance}
                  name={"create_new_selector"}
                  checked={!createNewInstance}
                  onChange={onSelectNewInstance}
                />
              }
              label={t([
                "fps_tenant_select_tenant",
                "Migrate existing Automation Hub to this tenant"
              ])}
            />
          </div>
        </CreateNewSection>
        <CreateNewSubSection>
          <CreateNewText>
            {/* prettier-ignore */}
            <Trans t={t} i18nKey="fps_tenant_selection_subtitle">
          Using this option will allow you to <b>migrate your existing Automation Hub data</b> to the selected Automation Cloud tenant. 
          <br />Here are the existing Automation Hub instances we have found for the current logged in user  (<b>{{ userEmail }}</b>):
          
          </Trans>
          </CreateNewText>
          <RadioButtonGroup>
            {generateRadioButtons({
              tenantsByOwnerArray: tenantsByOwner,
              selectedTenantUuid: selectedTenant,
              onChange: onSelectTenant,
              name: "tenant_selector"
            })}
          </RadioButtonGroup>

          <BottomText>
            {t([
              "fps_tenant_select_bottom_warning",
              "Before you continue, we recommend you to make sure that the selection you have made is accurate. Double check the URL, the name and the content of the existing Automation Hub instance."
            ])}
          </BottomText>
        </CreateNewSubSection>
      </>
    );
  };

  const renderCreateNewSection = () => {
    return (
      <>
        <CreateNewSection>
          <div>
            <FormControlLabel
              value={!createNewInstance}
              control={
                <Radio
                  value={createNewInstance}
                  name={"create_new_selector"}
                  checked={createNewInstance}
                  onChange={onSelectNewInstance}
                />
              }
              label={t([
                "fps_tenant_create_new",
                "Create new Automation Hub instance"
              ])}
            />
          </div>
          {showCountryPicker() && generateCountryPicker()}
        </CreateNewSection>
        <CreateNewSubSection>
          <CreateNewText>
            {t([
              "fps_tenant_select_create_new",
              "Start from scratch and add a new Automation Hub instance to this tenant"
            ])}
          </CreateNewText>
        </CreateNewSubSection>
      </>
    );
  };

  return (
    <ConfirmSectionWrapper>
      {onPrem() ? (
        <Title>
          <AlertImgContainer>
            <AlertImg icon="check_circle" $success />
          </AlertImgContainer>
          {t([
            "fps_tenant_selection_title_onprem",
            "Create New Automation Hub Instance"
          ])}
        </Title>
      ) : (
        <Title>
          <AlertImgContainer>
            <AlertImg icon="warning" />
          </AlertImgContainer>
          {t([
            "fps_tenant_selection_title",
            "Migrate existing Automation Hub data or create new instance"
          ])}
        </Title>
      )}
      <div>
        {showSelectTenantSection && renderSelectTenantSection()}
        {renderCreateNewSection()}
        {showSelectTenantSection || <Placeholder />}
      </div>
    </ConfirmSectionWrapper>
  );
};
export default FpsProvisionTenantSelectorMigrationOptionBox;
