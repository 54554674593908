import { COProcessSubmissionType } from "@uipath/ah-co";
import { CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE } from "../constants/urlConstants";
import urlWithTenant from "./urlHelper";

export const getChangeRequestSubmissionUrl = parent_process_id => {
  const linkLocation = urlWithTenant(
    CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(COProcessSubmissionType.CHANGE_REQUEST)
  );
  return `${linkLocation}?parentProcessId=${parent_process_id}`;
};
