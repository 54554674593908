import React from "react";
import { useHistory } from "react-router";
import Truncate from "react-text-truncate";
import urlWithTenant from "../../utils/urlHelper";
import "./CellComponentManageComponents.scss";

const CellComponentManageComponents = props => {
  const history = useHistory();

  const { component_name, component_slug } = props;

  const goToComponentPage = () => {
    history.push(urlWithTenant(`/component/${component_slug}`));
  };

  return (
    <div
      className="CellComponentManageComponents"
      data-testid="CellComponentManageComponentsContainer"
    >
      <Truncate
        element="button"
        containerClassName="CellComponentManageComponents__header"
        className="CellComponentManageComponents__header-text"
        text={component_name}
        line={2}
        onClick={goToComponentPage}
      />
    </div>
  );
};

export default CellComponentManageComponents;
