/**
 * Analytics Event names and event logging functions
 */
import axios from "./axiosInstance";
import { ai } from "./TelemetryService";
import SessionHelper from "../helpers/SessionHelper";
import { getUserRolesSlugString } from "./userHelper";
import {
  AUTH_TYPE_FPS,
  APPLICATION_START_MINIMUM_TOKEN_EXP_DELTA
} from "../constants/authConstants";
import jwtDecode from "jwt-decode";
import { AUTOMATION_STORE_SERVICE } from "../constants/urlConstants";

export const ANALYTICS_LOG_IN_EVENT_NAME = "isLoggedIn";
export const SUBMIT_EMPLOYEE_IDEA_SUCCESS_EVENT_NAME =
  "SubmitEmployeeIdea_Success";
export const SUBMIT_COE_IDEA_SUCCESS_EVENT_NAME = "SubmitCOEIdea_Success";
export const APPROVE_IDEA_SUCCESS_EVENT_NAME = "ApproveIdea_Success";
export const REJECT_IDEA_SUCCESS_EVENT_NAME = "RejectIdea_Success";
export const MARK_DUPLICATE_IDEA_SUCCESS_EVENT_NAME =
  "MarkDuplicateIdea_Success";
export const LAUNCH_ASSESSMENT_SUCCESS_EVENT_NAME = "LaunchAssesment_Success";
export const SUBMIT_ASSESSMENT_SUCCESS_EVENT_NAME = "SubmitAssessment_Success";
export const APPROVE_ASSESSED_IDEA_SUCCESS_EVENT_NAME =
  "ApproveAssessedIdea_Success";
export const REJECT_ASSESSED_IDEA_SUCCESS_EVENT_NAME =
  "RejectAssessedIdea_Success";
export const PUT_ON_HOLD_ASSESSED_SUCCESS_EVENT_NAME =
  "PutOnHoldAssessedIdea_Success";
export const EDIT_HIERARCHY_SUCCESS_EVENT_NAME = "EditHierarchy_Success";
export const SUBMIT_COMPONENT_SUCCESS_EVENT_NAME = "SubmitComponent_Success";
export const SAVE_DRAFT_COMPONENT_SUCCESS_EVENT_NAME =
  "SaveDraftComponent_Success";
export const PUBLISH_COMPONENT_SUCCESS_EVENT_NAME = "PublishComponent_Success";
export const REJECT_COMPONENT_SUCCESS_EVENT_NAME = "RejectComponent_Success";
export const SAVE_CURATION_SUCCESS_EVENT_NAME = "SaveCurationComponent_Success";
export const DOWNLOAD_COMPONENT_SUCCESS_EVENT_NAME =
  "DownloadComponent_Success";
export const ADD_USER_SUCCESS = "AddUser_Success";
export const ADD_USER_CANCEL = "AddUser_Cancel";
export const BULK_EDIT_USERS_SUCCESS = "BulkEditUsers_Success";
export const USERS_CSV_SAVE_SUCCESS = "UploadBulkChanges_Success";
export const USERS_RESEND_INVITE_SUCCESS = "UploadBulkChanges_Success";
export const UPLOAD_CSV_USERS1_SUCCESS = "UploadCsvUsers1_Success";
export const UPLOAD_CSV_USERS2_SUCCESS = "UploadCsvUsers2_Success";
export const DOWNLOAD_MASS_CHANGES_TEMPLATE_SUCCESS =
  "DownloadMassChangesTemplate_Success";
export const DOWNLOAD_NEW_USERS_TEMPLATE_SUCCESS =
  "DownloadNewUsersTemplate_Success";
export const BULK_EDIT_USERS_CANCEL = "BulkEditUsers_Cancel";
export const VIEW_USER_PERMISSIONS = "ViewUserPermissions";
export const VIEW_USER_ROLES = "ViewUserRoles";
export const CREATE_NEW_ROLE = "CreateNewRole";
export const CREATE_NEW_ROLE_SUCCESS = "CreateNewRole_Success";
export const CREATE_NEW_ROLE_CANCEL = "CreateNewRole_Cancel";
export const EDIT_USER_ROLE_SUCCESS = "EditUserRole_Success";
export const EDIT_USER_ROLE_CANCEL = "EditUserRole_Cancel";
export const DELETE_USER_ROLE_SUCCESS = "DeleteUserRole_Success";
export const EDIT_HIERARCHY_CANCEL = "EditHierarchy_Cancel";
export const EDIT_APP_INVENTORY_SUCCESS = "EditAppInventory_Success";
export const EDIT_APP_INVENTORY_CANCEL = "EditAppInventory_Cancel";
export const VIEW_DOCUMENTATION = "ViewDocumentation";
export const IMPORT_NEW_DOC_TEMPLATE_SUCCESS = "ImportNewDocTemplate_Success";
export const CREATE_QUESTION_ASSESSMENT = "CreateQuestionAssessment";
export const EDIT_CUSTOMIZATION_ASSESSMENT = "EditCustomizeAssessment";
export const CUSTOMIZE_ASSESSMENT_SUCCESS = "CustomizeAssessement_Success";
export const CUSTOMIZE_ASSESSMENT_CANCEL = "CustomizeAssessment_Cancel";
export const VIEW_COST_SETUP = "ViewCostSetup";
export const SETUP_CURRENCY_SUCCESS = "SetupCurrency_Success";
export const EDIT_RPA_SOFTWARE_VENDOR_SUCCESS = "EditRpaSoftwareVendor_Success";
export const EDIT_IMPLEMENTATIION_PEOPLE_COSTS_SUCCESS =
  "EditImplementationPeopleCosts_Success";
export const EDIT_RPA_SOFTWARE_COSTS_SUCCESS = "EditRpaSoftwareCosts_Success";
export const EDIT_OTHER_RUNNING_COSTS_SUCCESS = "EditOtherRunningCosts_Success";
export const OPEN_API_GENERATE_TOKEN_BUTTON_CLICK =
  "OpenApiGenerateToken_ButtonClick";
export const OPEN_API_EDIT_TOKEN_BUTTON_CLICK = "OpenApiEditToken_ButtonClick";
export const TASK_CAPTURE_QUESTIONNAIRE_BUTTON_CLICK =
  "TaskCapture_QuestionnaireButtonClick";
export const TASK_CAPTURE_DOCUMENTATION_BUTTON_CLICK =
  "TaskCapture_DocumentationButtonClick";
export const TASK_CAPTURE_EDIT_BUTTON_CLICK = "TaskCapture_EditButtonClick";
export const TASK_CAPTURE_PREVIEW_BUTTON_CLICK =
  "TaskCapture_PreviewButtonClick";
export const TASK_CAPTURE_DOWNLOAD_32BIT = "TCDownload_32bit";
export const TASK_CAPTURE_DOWNLOAD_64BIT = "TCDownload_64bit";
export const TASK_CAPTURE_DOWNLOAD_MSI_32BIT = "TCDownloadmsi_32bit";
export const TASK_CAPTURE_DOWNLOAD_MSI_64BIT = "TCDownlaadmsi_64bit";

export const DOWNLOAD_PERFORMANCE_DASHBOARD =
  "DownloadPerformanceDashboard_Success";
export const DOWNLOAD_COST_DASHBOARD = "DownloadCostDashboard_Success";
export const DOWNLOAD_PLANNING_REPORT = "DownloadPlanningReport_Success";
export const DOWNLOAD_COST_REPORT = "DownloadCostReport_Success";

export const VIEW_SETTING_PAGE = "VisitSettingsPage_Success";
export const FILE_UPLOAD_TOGGLE_OFF_SUCCESS = "FileUploadToggle_Success";
export const TASK_CAPTURE_TOGGLE_OFF_SUCCESS = "TaskCaptureToggle_Success";
export const COMPONENTS_TOGGLE_OFF_SUCCESS = "ComponentsToggle_Success";
export const LEADERBOARD_TOGGLE_OFF_SUCCESS = "LeaderboardToggle_Success";
export const COMMENT_QUESTION_SECTION_TOGGLE_OFF_SUCCESS =
  "CommentAndQuestionToggle_Success";

export const SHARE_AN_AUTOMATION = "ShareAnAutomation";
export const AUTOMATION_BUSINESS_REVIEW_APPROVE =
  "AutomationBusinessReview_Approve";
export const AUTOMATION_BUSINESS_REVIEW_REJECT =
  "AutomationBusinessReview_Reject";
export const AUTOMATION_TECHNICAL_REVIEW_MOVE_TO_TECHNICAL_REVIEW =
  "AutomationTechnicalReview_MoveToTechnicalReview";
export const AUTOMATION_TECHNICAL_REVIEW_REWORK_REQUIRED =
  "AutomationTechnicalReview_ReworkRequired";
export const AUTOMATION_TECHNICAL_REVIEW_APPROVE =
  "AutomationTechnicalReview_Approve";

export const EDIT_USER_SUCCESS = "EditUser_Success";
export const EDIT_USER_CANCEL = "EditUser_Cancel";

export const FPS_CREATE_NEW_INSTANCE = "FPS_CreateNewInstance";
export const FPS_CONTINUE_BUTTON_CLICK = "FPS_ContinueButtonClick";
export const FPS_START_FULL_MIGRATION = "FPS_StartFullMigration";
export const FPS_INVITE_USERS = "FPS_InviteUsers";
export const AUTOMATION_INTERESTED = "AutomationInterested";
export const AUTOMATION_REQUESTED = "AutomationRequest";
export const AUTOMATION_DELETE = "AutomationDelete";
export const AUTOMATION_ARCHIVE = "AutomationArchive";

export const NO_RESPONSE_ERROR_EVENT_NAME = "NoResponseError";

//New Telemetry Event Names
export const COE_IDEA_OPEN_FORM = "COEIdea.OpenForm";
export const COE_IDEA_CANCEL_FORM = "COEIdea.CancelForm";
export const EMPLOYEE_IDEA_OPEN_FORM = "EmployeeIdea.OpenForm";
export const EMPLOYEE_IDEA_CANCEL_FORM = "EmployeeIdea.CancelForm";
export const AUTOMATION_OPEN_FORM = "Automation.OpenForm";
export const AUTOMATION_CANCEL_FORM = "Automation.CancelForm";
export const CHANGE_REQUEST_OPEN_FORM = "ChangeRequest.OpenForm";
export const CHANGE_REQUEST_CANCEL_FORM = "ChangeRequest.CancelForm";
export const COMPONENT_OPEN_FORM = "Component.OpenForm";
export const COMPONENT_CANCEL_FORM = "Component.CancelForm";
export const FPS_CONTINUE = "FPS.Continue";
export const FPS_SELECT_EXISTING_INSTANCE = "FPS.SelectExistingInstance";
export const USERS_ADD_NEW_CANCEL = "Users.AddNewCancel";
export const USERS_EDIT_CANCEL = "Users.EditCancel";
export const USERS_BULK_EDIT_CANCEL = "Users.BulkEditCancel";
export const USERS_DOWNLOAD = "Users.Download";
export const USERS_DOWNLOAD_TEMPLATE = "Users.DownloadTemplate";
export const ROLES_ADD_NEW = "Roles.AddNew";
export const ROLES_ADD_NEW_CANCEL = "Roles.AddNewCancel";
export const CATEGORY_EDITOR_CANCEL = "CategoryEditor.Cancel";
export const ASSESSMENT_CUSTOMIZATION_CANCEL = "AssessmentCustomization.Cancel";
export const IDEA_OPEN_TC = "Idea.OpenTC";
export const IDEA_OPEN_TC_TO_EDIT = "Idea.OpenTCToEdit";
export const HOME_PAGE_VIEW = "Homepage.View";
export const IMPORT_PIPELINE_DOWNLOAD_CSV = "ImportPipeline.DownloadCSV";
export const IMPORT_PIPELINE_UPLOAD_CSV = "ImportPipeline.UploadCSV";
export const IMPORT_PIPELINE_SYNC_UPDATES = "ImportPipeline.SyncUpdates";

export const IDEA_OPEN_ASSISTANT = "Idea.OpenAssistant";
export const IDEA_OPEN_STUDIO = "Idea.OpenStudio";
export const STUDIO_DOWNLOAD_INSTALLER = "Studio.DownloadInstaller";
export const IDEA_OPEN_IN_TM = "Idea.OpenInTM";
export const IDEA_JIRA_LINK_SUCCESS = "Idea.JiraLinkSuccess";
export const IDEA_JIRA_CREATE_SUCCESS = "Idea.JiraCreateSuccess";
export const IDEA_JIRA_UNLINK = "Idea.JiraUnlink";
export const IDEA_JIRA_CREATE_OPEN_FORM = "Idea.JiraCreateOpenForm";

export const CUSTOMIZE_IDEA_FORM_CUSTOMIZE = "CustomizeIdeaForm.Customize";
export const CUSTOMIZE_IDEA_FORM_CANCEL = "CustomizeIdeaForm.Cancel";
export const CUSTOMIZE_IDEA_FORM_DOWNLOAD = "CustomizeIdeaForm.Download";

export const APPLICATION_START = "Application.Start";
//InvocationMethod strings
export const IDEA_OPEN_STUDIO_START_DEVELOPMENT_IN_STUDIO =
  "StartDevelopmentInStudio";
export const IDEA_OPEN_STUDIO_OPEN_THE_APP = "StudioOpenTheApp";

export const triggerCustomAnalyticsEvent = (eventName, eventData = {}) => {
  if (ai && ai.appInsights && typeof ai.appInsights.trackEvent === "function") {
    ai.appInsights.trackEvent({
      name: eventName,
      properties: eventData
    });
  }
};

export const triggerAnalyticsWithDefaultData = (eventName, eventData = {}) => {
  const DEFAULT_EVENT_DATA = {
    tenant: SessionHelper.tenantNameFromCurrentPath(),
    tenant_id: SessionHelper.getTenantUuid(),
    user_roles: getUserRolesSlugString(),
    u_uuid: SessionHelper.getUserUuid()
  };

  triggerCustomAnalyticsEvent(eventName, {
    ...DEFAULT_EVENT_DATA,
    ...eventData
  });
};

export const triggerFrontendTelemetryEvent = (
  eventName = "",
  eventData = {}
) => {
  axios
    .post(`/api/v1/telemetry/${eventName}`, eventData)
    .then(response => {})
    .catch(e => {
      console.log(e);
    });
};

const getBrowserVersionFromString = (userAgentString, browserName) => {
  if (!browserName || userAgentString === null) {
    return "";
  }
  const userAgentStringArray = userAgentString.split(" ") || [];
  let versionNumber = "";
  for (let i = 0; i < userAgentStringArray.length; i++) {
    if (userAgentStringArray[i].indexOf(browserName)) {
      if (userAgentStringArray[i].indexOf("/")) {
        versionNumber = userAgentStringArray[i].split("/")[1];
        break;
      }
    }
  }
  return versionNumber;
};

export const getBrowserAndVersion = () => {
  const browser = {};
  const usrAgent = navigator.userAgent;
  // user agent string example(Chrome):
  //"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  if (usrAgent.indexOf("Firefox") > -1) {
    browser.displayName = "Mozilla Firefox";
    browser.name = "Firefox";
  } else if (usrAgent.indexOf("Opera") > -1) {
    browser.displayName = "Opera";
    browser.name = "Opera";
  } else if (usrAgent.indexOf("OPR") > -1) {
    browser.displayName = "Opera";
    browser.name = "OPR";
  } else if (usrAgent.indexOf("Trident") > -1) {
    browser.displayName = "Microsoft Internet Explorer";
    browser.name = "Trident";
  } else if (usrAgent.indexOf("Edge") > -1) {
    browser.displayName = "Microsoft Edge";
    browser.name = "Edge";
  } else if (usrAgent.indexOf("Edg") > -1) {
    browser.displayName = "Microsoft Edge";
    browser.name = "Edg";
  } else if (usrAgent.indexOf("Chrome") > -1) {
    browser.displayName = "Google Chrome or Chromium";
    browser.name = "Chrome";
  } else if (usrAgent.indexOf("Safari") > -1) {
    browser.displayName = "Apple Safari";
    browser.name = "Safari";
  } else {
    browser.displayName = "other";
    browser.name = "";
  }

  return (
    browser.displayName +
    "/" +
    getBrowserVersionFromString(usrAgent, browser.name)
  );
};

export const triggerTelemetryAfterAuth = (authType, oldToken) => {
  if (authType === AUTH_TYPE_FPS) {
    let hasOldToken = false;

    // get the current time in seconds because the tokens use seconds for epoch
    const currentTime = Math.floor(new Date().getTime() / 1000);
    let expirationEpoch = 0;

    try {
      let oldTokenJson = {};
      if (oldToken) {
        oldTokenJson = jwtDecode(oldToken);
        hasOldToken = true;
      }

      expirationEpoch = oldTokenJson.exp || 0;
    } catch (e) {
      console.log(e);
    }

    // get seconds since old token's expiration
    const timeSinceExpiration = currentTime - expirationEpoch;

    // send FE telemetry here if no existing token or if it's a token refresh (token expired < 5 min ago)
    if (
      !hasOldToken ||
      timeSinceExpiration > APPLICATION_START_MINIMUM_TOKEN_EXP_DELTA
    ) {
      const service = SessionHelper.currentFpsService();
      const applicationName =
        service === AUTOMATION_STORE_SERVICE
          ? "UiPath.AutomationStore"
          : "UiPath.AutomationHub";

      triggerFrontendTelemetryEvent(APPLICATION_START, {
        ApplicationName: applicationName,
        BrowserVersion: getBrowserAndVersion(),
        OSVersion: navigator.platform,
        ScreenWidth: screen.width,
        ScreenHeight: screen.height,
        WindowWidth: window.innerWidth,
        WindowHeight: window.innerHeight
      });
    }
  }
};
