import { ACCESS_ADMIN_CONSOLE_PERMISSIONS } from "../constants/adminPermissionsConstants";

export const hasPermissionToView = ({
  userPermissions,
  permissionKeys
}: {
  userPermissions?: { [permissionKey: string]: 0 | 1 } | null;
  permissionKeys?: any[] | null;
}) => {
  if (!Array.isArray(permissionKeys) || permissionKeys.length < 1) {
    return true;
  }

  for (const permissionKey of permissionKeys) {
    if (userPermissions?.[permissionKey]) {
      return true;
    }
  }

  return false;
};

export const canViewAdminAccountPage = userPermissions => {
  if (!userPermissions) {
    return false;
  }

  for (let permission of Object.keys(userPermissions)) {
    if (
      ACCESS_ADMIN_CONSOLE_PERMISSIONS.includes(permission) &&
      userPermissions[permission]
    ) {
      return true;
    }
  }
  return false;
};
