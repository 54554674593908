import { isNullOrUndefined } from "./co-utils";

export const failedAssert = (message, obj) => {
  const e = new Error(
    `CO TESTING ASSERT FAILURE: ${message} ${obj &&
      JSON.stringify(obj).substr(0, 30)}`
  );
  console.log(e);
  if (obj && JSON.stringify(obj).length > 30) {
    console.log(obj);
  }
  throw e;
};

export const coAssertEquals = (obj, expected) => {
  if (obj === expected) {
    return true;
  }
  return failedAssert(`Should be equal to ${expected}`, obj);
};

export const coAssertArrayOrNull = (obj, name) => {
  if (obj === null || Array.isArray(obj)) {
    return true;
  }
  return failedAssert(`${name} must be an array or null`, obj);
};
export const coAssertValueInObjectValues = (value, obj) => {
  if (Object.values(obj).indexOf(value) > -1) {
    return true;
  }

  return failedAssert(
    `${value} is not an enum defined in TelemetryFields`,
    obj
  );
};

export const coAssertNotUndefinedOrNull = (obj, name) => {
  if (!isNullOrUndefined(obj)) {
    return true;
  }
  return failedAssert(`${name} must be not (undefined or null)`, obj);
};

export const coAssertUndefinedOrNull = (obj, name) => {
  if (isNullOrUndefined(obj)) {
    return true;
  }
  return failedAssert(`${name} must not exist already`, obj);
};

export const isArrayWithContents = arr => {
  if (
    arr &&
    Array.isArray(arr) &&
    arr.length > 0 &&
    !arr.includes(undefined) &&
    !arr.includes(null)
  ) {
    return true;
  }

  return false;
};

export const coAssertArrayWithContents = (obj, name) => {
  if (isArrayWithContents(obj)) {
    return true;
  }
  return failedAssert(`${name} must be an array with contents`, obj);
};

export const coAssertArray = (obj, name) => {
  if (Array.isArray(obj)) {
    return true;
  }
  return failedAssert(`${name} must be an array`, obj);
};

export const coAssertIsNumber = (obj, name) => {
  if (typeof obj === "number" || typeof obj === "bigint") {
    return true;
  }
  return failedAssert(`${name} must be a number`, obj);
};

export const coAssertInteger = (obj, name) => {
  if (Number.isInteger(obj) || typeof obj === "bigint") {
    return true;
  }
  return failedAssert(`${name} must be an integer`, obj);
};

export const coAssertIntegerOrNull = (obj, name) => {
  if (obj === null || Number.isInteger(obj)) {
    return true;
  }

  return failedAssert(`${name} must be an integer or null`, obj);
};

export const coAssertPositiveInteger = (obj, name) => {
  if (Number.isInteger(obj) && obj > 0) {
    return true;
  }
  return failedAssert(`${name} must be a positive integer`, obj);
};

export const coAssertNatural = (obj, name) => {
  if (Number.isInteger(obj) && obj >= 0) {
    return true;
  }
  return failedAssert(`${name} must be a nonnegative integer`, obj);
};

export const isBoolean = val => {
  return typeof val === "boolean";
};

export const coAssertBoolean = (obj, name) => {
  if (isBoolean(obj)) {
    return true;
  }
  return failedAssert(`${name} must be a boolean`, obj);
};

export const coAssertString = (obj, name) => {
  if (typeof obj === "string") {
    return true;
  }
  return failedAssert(`${name} must be a string`, obj);
};
