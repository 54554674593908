import axios from "../utils/axiosInstance";
import apiErrorAlert from "../utils/apiErrorAlert";
import {
  FETCH_TAXONOMY,
  SET_QUESTIONNAIRE_CLOSE_ROUTE,
  FETCH_FILTERED_TAXONOMY_BY_PERMISSION
} from "../constants/actionTypes";
import {
  filterCategoriesForCurrentUser,
  filterCategoriesWithinCategoryIds
} from "../utils/categoryUtils";
import { getUserTopLevelCategoryIdsForPermission } from "../utils/apiUtils/userCategoriesAPIUtils";

export function fetchTaxonomy() {
  return function(dispatch) {
    axios
      .get("/api/v1/categories")
      .then(response => {
        let formattedResponseObject: any = {};
        formattedResponseObject.levels = response?.data?.data?.levels || [];
        formattedResponseObject.categories = filterCategoriesForCurrentUser(
          response?.data?.data?.categories || []
        );

        dispatch({
          type: FETCH_TAXONOMY,
          payload: formattedResponseObject
        });
      })
      .catch(error => apiErrorAlert(error));
  };
}

export function fetchFilteredTaxonomyForUserPermission(user_permission_slug) {
  return function(dispatch) {
    axios
      .get("/api/v1/categories")
      .then(response => {
        let formattedResponseObject: any = {};
        formattedResponseObject.levels = response?.data?.data?.levels || [];
        formattedResponseObject.categories = filterCategoriesForCurrentUser(
          response?.data?.data?.categories || []
        );

        //filter on permission as well
        getUserTopLevelCategoryIdsForPermission(user_permission_slug).then(
          filteredCategoryIds => {
            if (filteredCategoryIds && filteredCategoryIds.length > 0) {
              formattedResponseObject.categories = filterCategoriesWithinCategoryIds(
                formattedResponseObject.categories,
                filteredCategoryIds
              );
            }
            dispatch({
              type: FETCH_FILTERED_TAXONOMY_BY_PERMISSION,
              payload: formattedResponseObject
            });
          }
        );
      })
      .catch(error => apiErrorAlert(error));
  };
}
export function clearQuestionnaireCloseRoute() {
  return function(dispatch) {
    dispatch({
      type: SET_QUESTIONNAIRE_CLOSE_ROUTE,
      payload: null
    });
  };
}
export function setQuestionnaireCloseRoute(route) {
  return function(dispatch) {
    dispatch({
      type: SET_QUESTIONNAIRE_CLOSE_ROUTE,
      payload: route
    });
  };
}
