import theme from "./styleTheme";

//= =====================
// Modal Types
//= =====================

//user_is_active
export const USER_STATUS_DISABLED = 0;
export const USER_STATUS_ACTIVE = 1;

//user_invite_status
export const USER_INVITE_STATUS_NOT_INVITED = 0;
export const USER_INVITE_STATUS_PENDING = 1;
export const USER_INVITE_STATUS_SEND_EMAIL = 2;
export const USER_INVITE_STATUS_ACTIVE = 3;

export const USER_STATUS_DISABLED_TEXT = "Inactive";
export const USER_STATUS_DISABLED_KEY = "inactive";
export const USER_INVITE_STATUS_NOT_INVITED_TEXT = "Not Invited";
export const USER_INVITE_STATUS_NOT_INVITED_KEY = "not_invited";
export const USER_INVITE_STATUS_PENDING_TEXT = "Pending";
export const USER_INVITE_STATUS_PENDING_KEY = "pending";
export const USER_INVITE_STATUS_SEND_EMAIL_TEXT = "Send Email";
export const USER_INVITE_STATUS_SEND_EMAIL_KEY = "send_email";
export const USER_INVITE_STATUS_ACTIVE_TEXT = "Active";
export const USER_INVITE_STATUS_ACTIVE_KEY = "active";

export const USER_STATUS_TEXT_COLOR_MAPPING = user => {
  let cellColor = theme.colors.error250;
  let statusText = USER_STATUS_DISABLED_TEXT;
  let statusKey = USER_STATUS_DISABLED_KEY;
  let chipClassName = "";

  if (user.user_is_active === USER_STATUS_DISABLED) {
    cellColor = theme.colors.error250;
    statusText = USER_STATUS_DISABLED_TEXT;
    statusKey = USER_STATUS_DISABLED_KEY;
    chipClassName = "error";
  } else if (user.user_invite_status === USER_INVITE_STATUS_NOT_INVITED) {
    cellColor = theme.colors.gray300;
    statusText = USER_INVITE_STATUS_NOT_INVITED_TEXT;
    statusKey = USER_INVITE_STATUS_NOT_INVITED_KEY;
    chipClassName = "";
  } else if (user.user_invite_status === USER_INVITE_STATUS_PENDING) {
    cellColor = theme.colors.yellow200;
    statusText = USER_INVITE_STATUS_PENDING_TEXT;
    statusKey = USER_INVITE_STATUS_PENDING_KEY;
    chipClassName = "warning";
  } else if (user.user_invite_status === USER_INVITE_STATUS_SEND_EMAIL) {
    cellColor = theme.colors.green200;
    statusText = USER_INVITE_STATUS_SEND_EMAIL_TEXT;
    statusKey = USER_INVITE_STATUS_SEND_EMAIL_KEY;
    chipClassName = "success";
  } else if (user.user_invite_status === USER_INVITE_STATUS_ACTIVE) {
    cellColor = theme.colors.green200;
    statusText = USER_INVITE_STATUS_ACTIVE_TEXT;
    statusKey = USER_INVITE_STATUS_ACTIVE_KEY;
    chipClassName = "success";
  }

  return { cellColor, statusText, statusKey, chipClassName };
};

//user permission ids
export const PROCESS_OWNER_ROLE_ID = 2;
export const PROJECT_MANAGER_ROLE_ID = 1;
export const SUBMITTER_ROLE_ID = 6;

export const FREE_TEXT_MAX_LENGTH = 50;
export const USER_EMAIL_MAX_LENGTH = 64;
