import { COUserInterface } from "../interfaces/co-interfaces";

export const fullNameForUser = ({
  user
}: {
  user: COUserInterface;
}): string => {
  const names: string[] = [user.user_first_name, user.user_last_name].filter(
    (name): name is string => {
      return !!name;
    }
  );
  return names.join(" ");
};
