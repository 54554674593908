import apiErrorAlert from "../apiErrorAlert";
import axios from "../axiosInstance";

export const fetchAutomationsForChangeRequestParentSearch = ({
  searchText,
  resultsCallback
}) => {
  axios
    .get("/api/v1/processes?s=" + searchText + "&page=1&limit=30")
    .then(resp => {
      if (resultsCallback) {
        resultsCallback(resp.data.data.processes);
      }
    })
    .catch(error => apiErrorAlert(error));
};
