import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../actions/modalActions";
import ButtonClose from "../../../components/buttons/ButtonClose/ButtonClose";
import {
  maintainFocusInContainer,
  focusOnFirstFocusableElement
} from "../../../utils/accessibilityUtils/accessibilityUtils";

import "./ModalTemplateOne.scss";

class ModalTemplateOne extends Component {
  constructor(props) {
    super(props);
    this.modalMainRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.closeModal) {
      window.addEventListener("keydown", this.listenKeyboard, true);
    }
    focusOnFirstFocusableElement(this.modalMainRef.current || undefined);
  }

  componentWillUnmount() {
    if (this.props.closeModal) {
      window.removeEventListener("keydown", this.listenKeyboard, true);
    }
  }

  onTabPressed = e => {
    const modalElement = this.modalMainRef.current;
    maintainFocusInContainer(e, modalElement);
  };

  listenKeyboard = event => {
    if (event.key === "Tab" || event.keyCode === 9) {
      this.onTabPressed(event);
    }
  };

  focusOnMount = () => {
    this.modalMainRef &&
      this.modalMainRef.current &&
      typeof this.modalMainRef.current.focus === "function" &&
      this.modalMainRef.current.focus();
  };

  render() {
    let classes = ["ModelTemplateOne"];
    const { className, children, heading, onClose, rightIcon } = this.props;
    let { headerStyle = {} } = this.props;
    let { exitBtnStyle = {} } = this.props;

    if (className && typeof className === "string") {
      //filter discards classes that are just whitespace
      //i.e. className="myClass  wooClass"
      classes = classes.concat(
        className.split(" ").filter(currClass => currClass.trim())
      );
    }

    return (
      <div
        className={classes.join(" ")}
        data-testid="ModalTemplateOne"
        ref={this.modalMainRef}
      >
        <div className="ModelTemplateOne__header" style={headerStyle}>
          <h3 className="ModelTemplateOne__label">
            {heading}
            {rightIcon}
          </h3>
          <ButtonClose onClick={onClose} />
        </div>
        {children}
      </div>
    );
  }
}

const mapStateToProps = ({ modal }) => ({
  modal
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalTemplateOne);
