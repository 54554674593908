import React from "react";
import { LocalizeTranslationKey } from "../utils/localizedAlertMessages";
import {
  STATE,
  LICENSE_PLAN,
  LICENSE_PLAN_OPTIONS
  // RENEW_SUBSCRIPTION,
} from "../constants/accountLicenseConstants";

export const generateParsedOptions = (type, options) => {
  let mapCallback;

  switch (type) {
    case LICENSE_PLAN:
      mapCallback = mapLicensePlans;
      break;
    case LICENSE_PLAN_OPTIONS:
      mapCallback = mapLicenseOptions;
      break;
    case STATE:
      mapCallback = mapStates;
      break;
    // case RENEW_SUBSCRIPTION:
    //   mapCallback = mapSubscriptionTime
    default:
      mapCallback = obj => {
        return obj;
      };
  }

  return (options[type] || []).map(mapCallback);
};

export const parseDataForRequest = fields => {
  const dataObj = {};

  Object.keys(fields).forEach(fieldKey => {
    const currentFieldObj = fields[fieldKey];
    if (currentFieldObj.type === "dropdown") {
      dataObj[fieldKey] = currentFieldObj.value
        ? currentFieldObj.value.fullOptionObj
        : {};
    } else {
      dataObj[fieldKey] = currentFieldObj.value;
    }
  });

  return dataObj;
};

const mapLicensePlans = licensePlanObj => {
  const {
    license_plan_name,
    license_plan_id,
    license_plan_is_active,
    license_plan_key
  } = licensePlanObj;
  return {
    label: (
      <LocalizeTranslationKey
        localeKey={"license_plan_be_" + license_plan_key}
        backupValue={license_plan_name}
      />
    ),
    value: license_plan_id,
    isDisabled: !license_plan_is_active,
    fullOptionObj: licensePlanObj
  };
};

const mapLicenseOptions = licenseOptionObj => {
  const {
    license_plan_option_description,
    license_plan_option_key,
    license_plan_option_id,
    license_plan_option_is_active
  } = licenseOptionObj;

  return {
    label: (
      <LocalizeTranslationKey
        localeKey={"license_plan_option_be_" + license_plan_option_key}
        backupValue={license_plan_option_description}
      />
    ),
    value: license_plan_option_id,
    isDisabled: !license_plan_option_is_active,
    fullOptionObj: licenseOptionObj
  };
};

const mapStates = stateObj => {
  const { state_id, state_name } = stateObj;

  return {
    label: state_name,
    value: state_id,
    fullOptionObj: stateObj
  };
};

// const mapSubscriptionTime = (subscriptionTypeObj) => {

// }
