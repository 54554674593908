import { UPLOAD_FILE } from "../constants/actionTypes";

const INITIAL_STATE = {
  fileUpload: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        fileUpload: action.payload
      };
    default:
      return state;
  }
}
