import {
  CLEAR_UNSAVED_DATA,
  FLAG_UNSAVED_DATA,
  SET_SAVE_CALLBACK,
  SET_TITLE_TEXT_BUTTON_TEXT_OPTIONS,
  UPDATE_LEAVING_CURATION
} from "../constants/actionTypes";

const INITIAL_STATE = {
  hasUnsavedData: false,
  isLeavingCuration: false,
  saveCallback: null,
  titleTextButtonOptions: null
};

const globalDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_UNSAVED_DATA:
      return {
        ...state,
        hasUnsavedData: action.payload
      };
    case SET_TITLE_TEXT_BUTTON_TEXT_OPTIONS: {
      return {
        ...state,
        titleTextButtonOptions: action.payload
      };
    }
    case FLAG_UNSAVED_DATA:
      return {
        ...state,
        hasUnsavedData: action.payload
      };

    case SET_SAVE_CALLBACK:
      return {
        ...state,
        saveCallback: action.payload
      };
    case UPDATE_LEAVING_CURATION:
      return {
        ...state,
        isLeavingCuration: action.payload
      };
    default:
      return state;
  }
};

export default globalDataReducer;
