import React, { ReactNode, useRef } from "react";
import useResizeObserver from "../../utils/customHooks/useResizeObserver";

const NavAuthApolloContainer = ({
  children,
  product,
  hideTenantPicker,
  isStandalone,
  hideAvatar,
  showTrademark,
  theme,
  compactModeOverride,
  coBrand,
  style,
  showHelpMenu
}: {
  children: ReactNode;
  product: string;
  hideTenantPicker?: boolean | string;
  isStandalone?: boolean | string;
  hideAvatar?: boolean | string;
  showTrademark?: boolean | string;
  theme?: string;
  compactModeOverride?: boolean | string;
  coBrand?: string;
  style?: { [key: string]: string };
  showHelpMenu?: "true" | "false";
}) => {
  const navRef = useRef(null);

  const checkAndSetResponsiveNav = entry => {
    const height = entry?.[0]?.contentRect?.height;
    if (height) {
      document.documentElement?.style?.setProperty(
        "--ah-fixed-header-height",
        height + "px"
      );
    }
  };

  useResizeObserver({
    elementRef: navRef,
    callback: checkAndSetResponsiveNav
  });

  return (
    <div ref={navRef}>
      {/* @ts-ignore */}
      <portal-app-bar
        product={product}
        show-trademark={showTrademark}
        hide-tenant-picker={hideTenantPicker}
        isstandalone={isStandalone}
        hide-avatar={hideAvatar}
        theme={theme}
        compact-mode-override={compactModeOverride}
        co-brand={coBrand}
        help-menu={showHelpMenu || "true"}
        style={style}
      >
        {children}
        {/* @ts-ignore */}
      </portal-app-bar>
    </div>
  );
};

export default NavAuthApolloContainer;
