import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { NESTED_THEME_CONTAINER_ID } from "../../StyleThemeProvider/StyleThemeProvider";
import "./ToastBadgeSwal.scss";

const ToastBadgeSwal = withReactContent(Swal).mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  animation: false,
  target: `#${NESTED_THEME_CONTAINER_ID}`,
  customClass: {
    container: "ToastBadgeSwal"
  },

  showCloseButton: true
});

export default ToastBadgeSwal;
