import { COAssessment } from "../../classes/co-assessment.class";
import { COContext } from "../../classes/co-context.class";
import { updateMetaDataValues } from "../../utils/co-template.utils";
import { CustomQuestionSlugs } from "../../constants/co-question.constants";

import {
  addSectionAndQuestions,
  sectionTemplateWithTitleAndDescription
} from "../../helpers/co-assessment-template.helper";
import { COAssessmentTypes } from "../../constants/co-constants";
import { COAssessmentTemplateInterface } from "../../interfaces/co-interfaces";
import { defaultAssessmentTemplate } from "../co-assessment.template";
import { COAssessmentTemplateSlugs } from "../../constants/co-assessment.constants";
import { COSectionTemplateSlugs } from "../../constants/co-section.constants";

export const defaultStatsAssessmentTemplate = (): COAssessmentTemplateInterface => {
  let assessmentTemplate = defaultAssessmentTemplate();

  assessmentTemplate.co_assessment_type =
    COAssessmentTypes.STATS_ASSESSMENT_TYPE;
  assessmentTemplate.co_assessment_template_slug =
    COAssessmentTemplateSlugs.STATS_DEFAULT_V1;

  if (assessmentTemplate.co_assessment_json) {
    assessmentTemplate.co_assessment_json.co_assessment_type =
      COAssessmentTypes.STATS_ASSESSMENT_TYPE;
    assessmentTemplate.co_assessment_json.co_assessment_template_slug =
      COAssessmentTemplateSlugs.STATS_DEFAULT_V1;
    assessmentTemplate.co_assessment_json.co_assessment_meta_json = {
      title: {
        value: "Automation Hub Default Overview Assessment"
      },
      description: {
        value: ""
      }
    };
    assessmentTemplate.co_assessment_json.co_assessment_version_meta_json = {
      title: {
        value: "Default Assessment",
        value_localization_key: "cda_default_assessment_name"
      },
      description: {
        value: "The default Overview Assessment that ships with Automation Hub"
      },
      version: {
        value: "1.0"
      },
      version_number: {
        value: "1"
      }
    };
  }
  return assessmentTemplate;
};

export const AHDefaultStatsAssessmentJSON = () => {
  let assessment = new COAssessment({ template: assessmentTemplateSTATS() });
  let context = new COContext({
    assessment,
    validation_context: { assessment_customize: 1 }
  });
  assessment.co_assessment_ahid = `ah-assessment-stats-0-0`;
  addDefaultSections({ context, assessment });
  COAssessment.prepareAssessmentEquations(assessment);
  let jsoned = JSON.stringify(assessment);
  let parsed = JSON.parse(jsoned);
  return parsed;
};

const addDefaultSections = ({
  context,
  assessment
}: {
  context: COContext;
  assessment: COAssessment;
}) => {
  // need to override some section controls
  let sectionTemplate = sectionTemplateWithTitleAndDescription({
    title: "Stats",
    sectionTemplateSlug: COSectionTemplateSlugs.STATS_DEFAULT_SECTION_V1
  });
  addSectionAndQuestions({
    context,
    assessment,
    section_template: sectionTemplate,
    question_slugs: [
      CustomQuestionSlugs.STATS_PROCESS_NUM_APPLICATIONS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_COLLABORATORS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_COMMENTS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_COMPONENTS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_DOCUMENTS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_FOLLOWERS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_MEDIA,
      CustomQuestionSlugs.STATS_PROCESS_NUM_SIMILAR_AUTOMATIONS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_VOTES,
      CustomQuestionSlugs.STATS_PROCESS_NUM_REQUESTS,
      CustomQuestionSlugs.STATS_PROCESS_TAGS,
      CustomQuestionSlugs.STATS_PROCESS_APPLICATIONS,
      CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVER,
      CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER,
      CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVER,
      CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH,
      CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH,
      CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH,
      CustomQuestionSlugs.STATS_PROCESS_CREATED_EPOCH,
      CustomQuestionSlugs.STATS_PROCESS_UPDATED_EPOCH,
      CustomQuestionSlugs.STATS_PROCESS_LAUNCHED_EPOCH,
      CustomQuestionSlugs.STATS_AUTOMATION_IS_PUBLISHED,
      CustomQuestionSlugs.STATS_AUTOMATION_NUM_DOWNLOADS,
      CustomQuestionSlugs.STATS_AUTOMATION_REVIEW_SCORE,
      CustomQuestionSlugs.STATS_AUTOMATION_NUM_REVIEWS,
      CustomQuestionSlugs.STATS_PDD_LINKS,
      CustomQuestionSlugs.STATS_PROCESS_NUM_CHANGE_REQUESTS
    ]
  });
};

export const assessmentTemplateSTATS = (): COAssessment => {
  let assessmentTemplate = defaultStatsAssessmentTemplate();
  if (
    assessmentTemplate &&
    assessmentTemplate.co_assessment_json?.co_assessment_meta_json
  ) {
    updateMetaDataValues({
      metadata: assessmentTemplate.co_assessment_json.co_assessment_meta_json,
      title: "Stats",
      description: ""
    });
  }
  return assessmentTemplate;
};
