import {
  FETCH_COMPONENT_REVIEWS,
  ADD_NEW_COMPONENT_REVIEW,
  CLEAR_COMPONENT_REVIEWS,
  DELETE_COMPONENT_REVIEW,
  SET_REVIEW_TO_REPORT
} from "./../constants/actionTypes";

const INITIAL_STATE = {
  componentProfileReviews: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COMPONENT_REVIEWS:
      return {
        ...state,
        componentProfileReviews: [
          ...action.payload.reviews.sort(
            (a, b) => b.created_epoch - a.created_epoch
          )
        ]
      };
    case ADD_NEW_COMPONENT_REVIEW:
      var componentProfileReviews = null;
      if (state.componentProfileReviews) {
        componentProfileReviews = [...state.componentProfileReviews];
        componentProfileReviews.unshift(action.payload);
      }
      return {
        ...state,
        componentProfileReviews: componentProfileReviews
      };
    case CLEAR_COMPONENT_REVIEWS:
      return {
        ...INITIAL_STATE
      };
    case DELETE_COMPONENT_REVIEW:
      componentProfileReviews = [...state.componentProfileReviews];
      componentProfileReviews = componentProfileReviews.filter(
        review =>
          review.component_review_id !== action.payload.component_review_id
      );
      return {
        ...state,
        componentProfileReviews: componentProfileReviews
      };
    case SET_REVIEW_TO_REPORT:
      return {
        ...state,
        reviewDataToReport: action.payload
      };
    default:
      return state;
  }
}
