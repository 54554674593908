import { FETCH_HIERARCHY_FOR_ADMIN_TO_MANAGE } from "../constants/actionTypes";

const INITIAL_STATE = {
  hierarchy: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_HIERARCHY_FOR_ADMIN_TO_MANAGE:
      return {
        ...state,
        hierarchy: action.payload
      };
    default:
      return state;
  }
}
