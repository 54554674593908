import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { sweetAlertLoader } from "../../../utils/sweetAlertLoader";
import {
  clearCurrentAutomation,
  setStatus
} from "../../../actions/automationPipelineActions";
import {
  IDEA_ARCHIVED,
  IDEA_REJECTED
} from "../../../constants/automationStatusTypes";
import Modal from "../Modal";
import Swal from "../../SweetAlert/SweetAlert";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import "./ModalRejectIdea.scss";
import {
  triggerAnalyticsWithDefaultData,
  REJECT_IDEA_SUCCESS_EVENT_NAME
} from "../../../utils/analyticsUtils";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import { FormControlLabel, Radio } from "@material-ui/core";
import IconApollo from "../../IconApollo/IconApollo";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";
import { REJECT_IDEA_CHECKBOX_LIST } from "../../../constants/automationStatusTypes";

class ModalRejectIdea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: 1,
      comment: ""
    };
  }

  closeModal = () => {
    const { closeModal, clearCurrentAutomation } = this.props;
    clearCurrentAutomation();
    closeModal();
  };

  onRejectClick = e => {
    e.preventDefault();
    const { checked, comment } = this.state;

    if (!comment) {
      Swal.fire({
        type: "error",
        title: this.props.t(
          "modal_reject_idea_leave_a_comment",
          "Leave a comment"
        ),
        text: this.props.t(
          "modal_reject_idea_please_detail_why",
          "Please detail why you want to reject this idea"
        )
      });

      return;
    }

    if (checked === 3) {
      this.archiveIdea(comment);
    } else {
      this.rejectIdea(comment);
    }
  };

  rejectIdea = reason => {
    const { automationPipeline, setStatus } = this.props;
    const { currentAutomation, allPhasesAndStatuses } = automationPipeline;

    const statusChangeId = allPhasesAndStatuses[IDEA_REJECTED];

    setStatus(
      {
        slug: currentAutomation.process_slug,
        statusChangeId,
        reason
      },
      this.afterRejectIdea
    );
    sweetAlertLoader({ text: this.props.t("modal_reject_idea_updating") });
  };

  afterRejectIdea = data => {
    triggerAnalyticsWithDefaultData(REJECT_IDEA_SUCCESS_EVENT_NAME);

    if (
      this.props.automationPipeline &&
      typeof this.props.automationPipeline.statusChangeCallback === "function"
    ) {
      this.props.automationPipeline.statusChangeCallback(data);
    }
  };

  archiveIdea = reason => {
    const { setStatus, automationPipeline } = this.props;
    const {
      currentAutomation,
      statusChangeCallback,
      allPhasesAndStatuses
    } = automationPipeline;

    const statusChangeId = allPhasesAndStatuses[IDEA_ARCHIVED];

    setStatus(
      {
        slug: currentAutomation.process_slug,
        statusChangeId,
        reason
      },
      statusChangeCallback
    );
    sweetAlertLoader({
      text: this.props.t("modal_reject_idea_updating", "Updating")
    });
  };

  handleCheck = e => {
    this.setState({
      checked: parseInt(e.target.value)
    });
  };

  handleType = e => {
    let newComment = e.target.value;

    if (newComment.length > 100) {
      newComment = newComment.slice(0, 100);
    }

    this.setState({
      comment: newComment
    });
  };

  getRadioList = () => {
    const { checked } = this.state;

    return REJECT_IDEA_CHECKBOX_LIST.map((messObj, idx) => {
      const { message_key, message_text, value } = messObj;

      return (
        <FormControlLabel
          control={
            <Radio
              checked={checked === value}
              value={value}
              onChange={this.handleCheck}
            />
          }
          label={this.props.t(message_key, message_text)}
        />
      );
    });
  };

  render() {
    const { comment } = this.state;

    const contentStyle = {
      borderRadius: "4px",
      boxShadow: "-8px 0 16px 0 rgba(0, 0, 0, 0.1)"
    };

    const headerStyle = {
      borderBottom: "0px",
      paddingBottom: "0px"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={contentStyle}>
        <ModalTemplateOne
          heading=""
          onClose={this.closeModal}
          headerStyle={headerStyle}
        >
          <div className="ModalRejectIdea" data-testid="ModalRejectIdea">
            <div className="ModalRejectIdea__header">
              <IconApollo
                className="ModalRejectIdea__main-icon"
                icon="cancel"
              />
              <span className="ModalRejectIdea__title">
                {this.props.t("modal_reject_idea_reject_idea", "Reject Idea")}
              </span>
            </div>
            <div className="ModalRejectIdea__main">
              <span className="ModalRejectIdea__message-text">
                {this.props.t(
                  "modal_reject_idea_please_select_reason",
                  "Please select a reason and add a comment for rejecting the idea:"
                )}
              </span>
              <div className="ModalRejectIdea__radio-buttons">
                {this.getRadioList()}
              </div>
              <div className="ModalRejectIdea__comment-container">
                <InputTextApollo
                  className="ModalRejectIdea__comment"
                  label={this.props.t(
                    "modal_reject_idea_leave_a_comment",
                    "Leave a comment"
                  )}
                  InputProps={{
                    value: comment,
                    onChange: this.handleType
                  }}
                  rows={3}
                  multiline
                  required
                  data_testid="ModalRejectIdea_comment"
                />
              </div>
            </div>
            <div className="ModalRejectIdea__footer">
              <ButtonApolloSecondary
                className="ModalRejectIdea__btn"
                onClick={this.closeModal}
                data-testid="ModalRejectIdea_cancel_btn"
              >
                {this.props.t("modal_reject_idea_cancel", "Cancel")}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                className="ModalRejectIdea__btn"
                onClick={this.onRejectClick}
                data-testid="ModalRejectIdea_reject_btn"
              >
                {this.props.t("modal_reject_idea_reject", "Reject")}
              </ButtonApolloPrimary>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = ({ tableAutomations, automationPipeline }) => ({
  automationStatuses: tableAutomations.automationStatuses,
  automationPipeline
});

const mapDispatchToProps = {
  clearCurrentAutomation,
  setStatus
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ModalRejectIdea)
);
