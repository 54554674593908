export const createApplicationObj = application => {
  const application_id = application?.application_id || "";
  const application_name = application?.application_name || "";
  const application_version = application?.application_version || "";
  const application_language = application?.application_language || "";

  const labelArray = [
    application_name,
    application_version,
    application_language
  ];

  return {
    label: labelArray.join(" ").trim(),
    value: application_id
  };
};
