import { LegacyProcessColumnName } from "../../src/interfaces/co-legacy.interfaces";
import { objectDeepCopy } from "../helpers/co-context.helper";
import {
  COFilterOptionItemInterface,
  COQueryConditionOperator
} from "../interfaces/co-interfaces";
import { PIPELINE_COLUMN_NAME } from "./co-constants";
import { CustomQuestionSlugs } from "./co-question.constants";

export const PIPELINE_COLUMN_TOOLTIP_TEXT = {
  [PIPELINE_COLUMN_NAME.IDEA_PHASE_APPROVER]: "Name of the Idea Approver.",
  [PIPELINE_COLUMN_NAME.LAUNCH_ASSESSMENT_APPROVER]:
    "Name of the Program Manager who launched the assessment.",
  [PIPELINE_COLUMN_NAME.QUALIFICATION_PHASE_APPROVER]:
    "Name of the Program Manager who reviewed the idea.",
  [PIPELINE_COLUMN_NAME.AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY]:
    "Computed by multiplying the Potential Benefit per employee with the number of people that can benefit from the automation",
  [LegacyProcessColumnName(
    CustomQuestionSlugs.STATS_PDD_LINKS
  ) as string]: "Will be populated only if a PDD link is embedded for the idea. Uploaded documents are not displayed in this column."
};

// there are some calculated values that are in the pipelines but don't use the column key directly because it's a different property on the process
// object that we don't want to have overridden when we load calculated values
export const LegacyPipelineColumnColumnKeyOverridesForProcessProperties = {
  "ovr-overview_auto_generated_id": "process_id"
};

export const COExternallyLoadableFilterColumns = [
  "process_tag_names",
  "department",
  "process_priority",
  "parent_process_id",
  "business_unit",
  "software_vendor",
  "license_type",
  "collaborators",
  "process_applications",
  "orchestrator_automation_uuid"
];

// this is necessary to handle getting in dynamc filter value that's been loaded on the frontend or passed in from a controller
// for example a process id or user id where we don't want to load the entire list of users or processes when making the query
export const COExternallyLoadableFilterColumnDynamicFilterTemplate: {
  [key: string]: {
    emptyFilterForDynamicFilterSelection: COFilterOptionItemInterface;
    baseFilterOptions: COFilterOptionItemInterface[];
  };
} = {
  software_vendor: {
    emptyFilterForDynamicFilterSelection: {
      filter_option_key: "",
      meta: {
        value: ""
      },
      filter_query_condition: {
        values: [],
        operator: COQueryConditionOperator.IN
      }
    },
    baseFilterOptions: []
  },
  license_type: {
    emptyFilterForDynamicFilterSelection: {
      filter_option_key: "",
      meta: {
        value: ""
      },
      filter_query_condition: {
        values: [],
        operator: COQueryConditionOperator.IN
      }
    },
    baseFilterOptions: [
      {
        filter_option_key: "license-type-none",
        meta: {
          value: ""
        },
        filter_query_condition: {
          values: [""],
          operator: COQueryConditionOperator.IN
        }
      }
    ]
  },
  orchestrator_automation_uuid: {
    emptyFilterForDynamicFilterSelection: {
      filter_option_key: "",
      meta: {
        value: ""
      },
      filter_query_condition: {
        values: [],
        operator: COQueryConditionOperator.IN
      }
    },
    baseFilterOptions: []
  },
  parent_process_id: {
    emptyFilterForDynamicFilterSelection: {
      filter_option_key: "",
      meta: {
        value: ""
      },
      filter_query_condition: {
        values: [],
        operator: COQueryConditionOperator.IN
      }
    },
    baseFilterOptions: []
  }
};

export const createDynamicFilterOptionFromFilterOptionTemplate = (
  filterOption: COFilterOptionItemInterface,
  filterKey: string
) => {
  let newOption = objectDeepCopy(filterOption);
  newOption.filter_query_condition.values = [filterKey];
  newOption.filter_option_key = filterKey;
  newOption.meta.value = filterKey;
  return newOption;
};
