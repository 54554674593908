import { COProcessExternalData } from "../classes/co-process-external-data.class";
import {
  CODefaultEquations,
  COExternalEquationFunctionsHandledInCalculationSystem
} from "../constants/co-calculation.constants";
import { COExternalAnswerSourceIDColumnForSource } from "../constants/co-constants";
import {
  COContextInterface,
  CODisplayContext,
  COProcessAnswerInterface,
  COQuestionAnswerOptionInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import {
  doesExistPositiveContextMatch,
  isNullOrUndefined
} from "../utils/co-utils";

// this converts things like media arrays into comma sep id strings, and user picker into user_id etc
export const answerContextQuestionsWithUpdatedExternalValues = ({
  context
}: {
  context: COContextInterface;
}) => {
  // there's no reason to do this when viewing a process - we wont have answer options
  if (
    doesExistPositiveContextMatch(context?.display_context, {
      assessment_submission: 1
    } as CODisplayContext)
  ) {
    if (context.assessment) {
      for (const section of context.assessment.co_assessment_sections || []) {
        for (const question of section.co_questions || []) {
          let questionObj: COQuestionInterface = question;
          if (
            questionObj.options?.external_answer_source &&
            !COExternalEquationFunctionsHandledInCalculationSystem.includes(
              questionObj.co_equation || ""
            )
          ) {
            if (context.process_external_data) {
              const externalData: COProcessExternalData =
                context.process_external_data;
              let external_data_value = externalData?.functionExternalPropertyValueForQuestion?.(
                context.update?.({
                  question,
                  section
                }) || context
              );

              // console.log(
              //   `Answering ${questionObj.co_question_meta_json?.title?.value}`
              // );
              // only save it it exists
              if (external_data_value) {
                let answerValue: string = "";

                let id_key =
                  COExternalAnswerSourceIDColumnForSource[
                    questionObj.options?.external_answer_source
                  ];

                if (id_key) {
                  let idParts = id_key.split("."); //for stupid stuff like file.file_id
                  let idObjectFromKey = objectToFindIDIn => {
                    let currentObject = objectToFindIDIn;
                    for (const idPart of idParts) {
                      let currentProperty = currentObject[idPart];
                      if (currentProperty) {
                        if (typeof currentProperty === "object") {
                          currentObject = currentProperty; // and continue down the chain
                        } else {
                          return currentProperty;
                        }
                      }
                    }
                    return null;
                  };

                  if (Array.isArray(external_data_value)) {
                    answerValue = external_data_value
                      .map(external_value => {
                        if (external_value) {
                          return idObjectFromKey(external_value) || "";
                        }
                      })
                      .filter(val => val != "")
                      .join(",");
                    // need to flatten this by ID - which is going to be hard because all the ids are different
                  } else if (typeof external_data_value === "object") {
                    let property = idObjectFromKey(external_data_value) || "";
                    if (property) {
                      answerValue = property;
                    }
                  }
                } else {
                  answerValue = external_data_value;
                }
                /// ok if we have an actual value then let's update it this will be passed up and saved.
                // this may need to be re-saved after the submission, because we don't always have ids - I need to check applications and tags to see if we make them on the fly
                let first_process_answer_selection =
                  questionObj.co_process_answer
                    ?.co_process_answer_selections?.[0];

                if (
                  first_process_answer_selection &&
                  first_process_answer_selection?.co_question_answer_option
                    ?.calculation_options?.input_is_value // so we don't mess with the thin apps application question
                ) {
                  first_process_answer_selection.co_process_answer_selection_input = answerValue;
                } else if (answerValue) {
                  // this is likely a dropdown options question
                  let selectedAnswerOption:
                    | COQuestionAnswerOptionInterface
                    | undefined;
                  if (
                    questionObj.co_question_answer_options &&
                    questionObj.co_question_answer_options.length > 0 &&
                    questionObj.co_process_answer
                  ) {
                    selectedAnswerOption = questionObj.co_question_answer_options.find(
                      answer_option =>
                        answer_option.co_question_answer_option_value ==
                        answerValue + ""
                    );
                    if (selectedAnswerOption) {
                      let process_answer: COProcessAnswerInterface =
                        questionObj.co_process_answer;
                      if (process_answer.selectOrUpdateOption) {
                        process_answer.selectOrUpdateOption({
                          context,
                          question,
                          answerOption: selectedAnswerOption
                        });
                      }
                    }
                  }
                  if (!selectedAnswerOption) {
                    console.log(
                      `unable to answer external answer question - unable to find question doesn't have an answer option with that value for value ${answerValue}`
                    );
                    console.log(question);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
