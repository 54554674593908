import { COQuestionTemplate } from "../../classes/co-question.class";
import { CO_CHART_TYPES } from "../../constants/co-chart.constants";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardKPISlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";

export const customQ1KPITemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      /// this is calculated with current  Q1 -
      slug: CustomQuestionSlugs.CHART_IDEA_SCORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CHART_IDEA_SCORE,
        standard_kpi_template_slug: StandardKPISlugs.GAUGE_KPI,
        title: "Overall score",
        description: "",
        options_meta_overrides: {
          chart: {
            component_type: CO_CHART_TYPES.IDEA_GAUGE_CHART,
            title: {
              value: "Estimated in High Level Assessment",
              value_localization_key:
                "cda_chart_estimated_in_high_level_assessment"
            },
            left_value_ahid: CustomQuestionSlugs.Q1_SUITABILITY_SCORE,
            right_value_ahid: CustomQuestionSlugs.Q1_READINESS_SCORE,
            gauge_main_ahid: CustomQuestionSlugs.Q1_OVERALL_GAUGE_SCORE
          }
        },
        question_overrides: {
          co_equation: "",
          co_question_position_json: {
            sort_order: 40
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_OVERALL_GAUGE_SCORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_OVERALL_GAUGE_SCORE,
        standard_kpi_template_slug: StandardKPISlugs.PERCENT_KPI,
        title: "Idea score",
        description:
          "This is an overall score indicating how good your idea is for automation.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_OVERALL_GAUGE_SCORE
          ),
          co_equation: "",
          co_display_equation: OVERALL_SCORE_DEFAULT_DISPLAY_EQUATION,
          co_question_position_json: {
            sort_order: 10
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_READINESS_SCORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_READINESS_SCORE,
        standard_kpi_template_slug: StandardKPISlugs.PERCENT_KPI,
        title: "Readiness score",
        description:
          "This is an overall score indicating how ready your idea is for automation.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_READINESS_SCORE
          ),
          co_equation: "",
          co_display_equation: READINESS_SCORE_DEFAULT_DISPLAY_EQUATION,
          co_question_position_json: {
            sort_order: 20
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_SUITABILITY_SCORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_SUITABILITY_SCORE,
        standard_kpi_template_slug: StandardKPISlugs.PERCENT_KPI,
        title: "Suitability score",
        description:
          "This is an overall score indicating how good of a candidate your idea is for automation.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_SUITABILITY_SCORE
          ),
          co_equation: "",
          co_display_equation: SUITABILITY_SCORE_DEFAULT_DISPLAY_EQUATION,
          co_question_position_json: {
            sort_order: 30
          }
        }
      })
    }
  ];
};
export const OVERALL_SCORE_DEFAULT_DISPLAY_EQUATION = `round(((DECISIONS_STRAIGHTFORWARD_RULES*0.2)+(INPUT_DATA_ACCESSED_DIGITALLY*0.2)+(DATA_HIGHLY_FORMATTED*0.2)+(NO_EXPECTED_CHANGES*0.2)+(PROCESS_DOCUMENTS*0.2)),0)`;
export const READINESS_SCORE_DEFAULT_DISPLAY_EQUATION = `round((NO_EXPECTED_CHANGES+PROCESS_DOCUMENTS)/2,0)`;
export const SUITABILITY_SCORE_DEFAULT_DISPLAY_EQUATION = `round((DECISIONS_STRAIGHTFORWARD_RULES+INPUT_DATA_ACCESSED_DIGITALLY+DATA_HIGHLY_FORMATTED)/3,0)`;

// I need these when migrating customized versions of the Q1 - they might have deleted some of these questions and that breaks the equations
export const READINESS_SCORE_REQUIRED_VARIABLES = [
  "NO_EXPECTED_CHANGES",
  "PROCESS_DOCUMENTS"
];
export const SUITABILITY_SCORE_REQUIRED_VARIABLES = [
  "DECISIONS_STRAIGHTFORWARD_RULES",
  "INPUT_DATA_ACCESSED_DIGITALLY",
  "DATA_HIGHLY_FORMATTED"
];
