import swal, { swalClose } from "../components/SweetAlert/SweetAlert";

export const DEFAULT_SUCCESS_CALLBACK = () => {
  setTimeout(() => {
    swalClose();
  }, 1000);
};

export const alertSwal = ({
  type = "question",
  title = "Save Changes?",
  text = "Save changes you have made?",
  confirmButtonText = "Save",
  showCancelButton = true,
  afterConfirm
}) => {
  swal
    .fire({
      type,
      title,
      text,
      confirmButtonText,
      showCancelButton
    })
    .then(e => {
      if (e.value && afterConfirm) {
        afterConfirm();
      }
    });
};

export const successSwal = ({
  type = "success",
  title = "Successfully Sent Your Request!",
  text = "Your request has been sent successfully.",
  showConfirmButton = false
}) => {
  swal.fire({
    type,
    text,
    title,
    showConfirmButton
  });
};

export const getDisableText = (source, target, targetPage) => {
  return `<p>Are you sure want to disable this ${source}?</p>
  <p>It will no longer appear as an option in the '${target}' dropdown in the '${targetPage}' page.<p>
  <p>All existing automations using this ${source} will not be impacted.</p>`;
};
export const getEnableText = (source, target, targetPage) => {
  return `<p>Are you sure want to enable this ${source}? </p>
  <p>It will now appear as an option in the '${target}' dropdown in the '${targetPage}' page.</p>`;
};
