import { VARIABLE_MAX_LENGTH } from "../templates/elements/validators/co-question.validators";
import { coRandomShortVar } from "./co-utils";
import { LegacyProcessColumnName } from "../interfaces/co-legacy.interfaces";
import { CO_DISPLAY_VALUE_SUFFIX } from "../constants/co-constants";

export const toLegacyVariable = (title: string) => {
  return (
    `LEGACY_${(title || "")
      .toUpperCase()
      .replace(/\s/g, "_")
      .replace(/[^A-Z_]/gi, "")}`.substring(0, VARIABLE_MAX_LENGTH - 6) +
    `_${coRandomShortVar()}`
  );
};

export const valueFromCOColumnsByCustomQuestionSlug = ({
  columns,
  slug,
  display = false
}: {
  columns: { [key: string]: string };
  slug: string;
  display: boolean;
}) => {
  const legacyColumnName = LegacyProcessColumnName(slug);
  return (
    (legacyColumnName &&
      columns &&
      display &&
      columns.hasOwnProperty(`${legacyColumnName}${CO_DISPLAY_VALUE_SUFFIX}`) &&
      columns[`${legacyColumnName}${CO_DISPLAY_VALUE_SUFFIX}`]) ||
    (legacyColumnName && columns && columns[legacyColumnName]) ||
    null
  );
};
