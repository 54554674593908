import {
  FormControl,
  InputLabel,
  TextareaAutosize,
  FormHelperText,
  Tooltip
} from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import React, { useRef, useEffect } from "react";

const StyledTextareaAutosize = styled(TextareaAutosize)<{
  error?: boolean;
  disableresize?: boolean;
}>(
  // @ts-ignore
  props => css`
    ${props.disableresize ? `resize: none; overflow: scroll;` : ""}

    && {
      color: var(--color-foreground);
      background: var(--color-background);
      ${props.error
        ? // @ts-ignore
          css`
            border: 2px solid var(--color-error-icon);
          `
        : ""}

      &:hover {
        border: 1px solid var(--color-icon-hover);
      }

      &:focus-within {
        border: 2px solid var(--color-primary-focused);
      }
    }
  `
);

const InputTextareaApollo = ({
  label,
  placeholder,
  helperText,
  className,
  error = false,
  errorCount,
  InputLabelProps = {},
  InputProps = {},
  HelperTextProps = {},
  tooltipTitle = "",
  minRows = 5,
  rowsMax,
  required,
  disableresize
}: {
  label?: string;
  placeholder?: string;
  helperText?: string;
  className?: string;
  error?: boolean;
  errorCount?: number;
  InputLabelProps?: any;
  InputProps?: any;
  HelperTextProps?: any;
  tooltipTitle?: string;
  minRows?: number;
  required?: boolean;
  disableresize?: boolean;
  rowsMax?: number;
}) => {
  const inputEl = useRef(null);

  useEffect(() => {
    if (inputEl && inputEl.current && error) {
      const el: any = inputEl.current;
      el?.scrollIntoView();
    }
  }, [inputEl, error, errorCount]);

  return (
    <Tooltip title={tooltipTitle}>
      <FormControl className={className} ref={inputEl}>
        <InputLabel {...InputLabelProps} required={required}>
          {label}
        </InputLabel>
        <StyledTextareaAutosize
          aria-label="empty textarea"
          placeholder={placeholder}
          minRows={minRows}
          error={error}
          disableresize={disableresize}
          {...InputProps}
          rowsMax={rowsMax}
        />
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      </FormControl>
    </Tooltip>
  );
};

export default InputTextareaApollo;
