import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import ModalPipelineGeneric from "../ModalPipelineGeneric/ModalPipelineGeneric";
import { FormControlLabel, Radio } from "@material-ui/core";
import InputTextApollo from "../../../inputs/InputTextApollo/InputTextApollo";

const DescriptionList = styled.ul`
  margin-top: 19px;
  display: flex;
  flex-direction: column;
`;

const CommentTextArea = styled(InputTextApollo)`
  && {
    width: 100%;
    margin-top: 28px;
  }
`;

interface ListItemType {
  text: string;
  textLocalizationKey: string;
  value: number;
}

interface Props {
  radioItems?: ListItemType[] | undefined;
  hasComment?: boolean;
  placeholderKey?: string;
  commentLimit?: number;
}
const ModalPipelineGenericWithRadioButtons = ({
  radioItems,
  hasComment,
  placeholderKey,
  commentLimit
}: Props) => {
  const [selectedRadioVal, setSelectedRadioVal] = useState<undefined | number>(
    radioItems?.[0]?.value
  );
  const [comment, setComment] = useState("");
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation("common");

  const onConfirm = (setStatusCallback: (reason: string) => void): void => {
    let reason = "";

    if (hasComment && comment) {
      reason = comment;
    } else if (hasComment && !comment) {
      setHasError(true);
      return;
    }

    typeof setStatusCallback === "function" && setStatusCallback(reason);
  };

  return (
    <ModalPipelineGeneric onConfirmProp={onConfirm}>
      {typeof radioItems?.length === "number" && radioItems.length > 0 ? (
        <DescriptionList>
          {radioItems?.map(({ text, textLocalizationKey, value }, idx) => (
            <FormControlLabel
              control={
                <Radio
                  checked={selectedRadioVal === value}
                  onChange={() => {
                    setSelectedRadioVal(value);
                  }}
                />
              }
              label={t(textLocalizationKey, text)}
            />
          ))}
        </DescriptionList>
      ) : null}
      {hasComment && (
        <CommentTextArea
          InputProps={{
            value: comment,
            onChange: e => {
              if (
                typeof commentLimit === "number" &&
                typeof e?.target?.value?.length === "number" &&
                e.target.value.length > commentLimit
              ) {
                setComment(e?.target?.value.slice(0, commentLimit));
                return;
              }
              setComment(e?.target?.value || "");
            }
          }}
          error={hasError}
          helperText={
            hasError
              ? `${t("admin_assessment_question_required", "Required")} *`
              : ""
          }
          placeholder={t(placeholderKey || "", "Leave a Comment...")}
          maxLength={commentLimit}
          multiline
          rows={2}
          data_testid="ModalCommentTextArea"
        />
      )}
    </ModalPipelineGeneric>
  );
};

const mapStateWithProps = ({ modal }) => ({
  radioItems: modal?.modalProps.radioItems,
  hasComment: modal?.modalProps.hasComment,
  placeholderKey: modal?.modalProps.placeholder,
  commentLimit: modal?.modalProps.commentLimit
});

export default connect(mapStateWithProps)(ModalPipelineGenericWithRadioButtons);
