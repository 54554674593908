import { customQuestionWithInfo } from "../co-custom-questions.template";

import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import {
  COCalculatedValueTypes,
  COExternalAnswerSources,
  COConditionSlugs
} from "../../constants/co-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardKPISlugs
} from "../../constants/co-question.constants";

export const customStatsKPITemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_CREATED_EPOCH,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_CREATED_EPOCH,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          calculated_value_type: COCalculatedValueTypes.EPOCH,
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          undefined_value: "0"
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_CREATED_EPOCH
          ),
          co_question_meta_json: {
            title: { value: "Date Submitted" } // NO THIS IS NOT A TYPO
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_UPDATED_EPOCH,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_UPDATED_EPOCH,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          calculated_value_type: COCalculatedValueTypes.EPOCH,
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          undefined_value: "0"
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_UPDATED_EPOCH
          ),
          co_question_meta_json: {
            title: { value: "Last Modified" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_LAUNCHED_EPOCH,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_LAUNCHED_EPOCH,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          calculated_value_type: COCalculatedValueTypes.EPOCH,
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          undefined_value: "0"
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_LAUNCHED_EPOCH
          ),
          co_question_meta_json: {
            title: { value: "Launch Date" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_APPLICATIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_APPLICATIONS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_APPLICATIONS
          ),
          co_question_meta_json: {
            title: { value: "Number of Applications" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_APPLICATIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_APPLICATIONS,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.APPLICATION_ARRAY,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_APPLICATIONS
          ),
          co_question_meta_json: {
            title: { value: "Applications Used" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_COLLABORATORS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_COLLABORATORS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_COLLABORATORS
          ),
          co_question_meta_json: {
            title: { value: "Number of Collaborators" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_COMMENTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_COMMENTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_COMMENTS
          ),
          co_question_meta_json: {
            title: { value: "Number of Comments" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_COMPONENTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_COMPONENTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_COMPONENTS
          ),
          co_question_meta_json: {
            title: { value: "Number of Components" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_DOCUMENTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_DOCUMENTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_DOCUMENTS
          ),
          co_question_meta_json: {
            title: { value: "Number of Documents" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_FOLLOWERS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_FOLLOWERS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_FOLLOWERS
          ),
          co_question_meta_json: {
            title: { value: "Number of Followers" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_MEDIA,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_MEDIA,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_MEDIA
          ),
          co_question_meta_json: {
            title: { value: "Number of Media Items" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_SIMILAR_AUTOMATIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_SIMILAR_AUTOMATIONS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_SIMILAR_AUTOMATIONS
          ),
          co_question_meta_json: {
            title: { value: "Number of Similar Automations" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_REQUESTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_REQUESTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_REQUESTS
          ),
          co_question_meta_json: {
            title: { value: "Requests" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_VOTES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_VOTES,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_VOTES
          ),
          co_question_meta_json: {
            title: { value: "Interests" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_TAGS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_TAGS,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.TAG_ARRAY,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_TAGS
          ),
          co_question_meta_json: {
            title: { value: "Tags" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVER,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.USER,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVER
          ),
          co_question_meta_json: {
            title: { value: "Idea Phase Approver" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.EPOCH,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH
          ),
          co_question_meta_json: {
            title: { value: "Date of Idea Approval" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVER,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.USER,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVER
          ),
          co_question_meta_json: {
            title: { value: "Qualification Phase Approver" }
          }
        }
      })
    },
    {
      slug:
        CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.EPOCH,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH
          ),
          co_question_meta_json: {
            title: { value: "Date of Qualification Approval" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.USER,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER
          ),
          co_question_meta_json: {
            title: { value: "Launch Assessment Approver" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.EPOCH,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH
          ),
          co_question_meta_json: {
            title: { value: "Date of Launched Assessment" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_AUTOMATION_IS_PUBLISHED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_AUTOMATION_IS_PUBLISHED,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.YESNO
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_AUTOMATION_IS_PUBLISHED
          ),
          co_question_meta_json: {
            title: { value: "Published to Store" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_AUTOMATION_NUM_DOWNLOADS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_AUTOMATION_NUM_DOWNLOADS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_AUTOMATION_NUM_DOWNLOADS
          ),
          co_question_meta_json: {
            title: {
              value: "Number of Automation Downloads from Automation Store"
            }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_AUTOMATION_NUM_REVIEWS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_AUTOMATION_NUM_REVIEWS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_AUTOMATION_NUM_REVIEWS
          ),
          co_question_meta_json: {
            title: { value: "Number of Automation Store Reviews" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PDD_LINKS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PDD_LINKS,
        standard_kpi_template_slug: StandardKPISlugs.REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          calculated_value_type: COCalculatedValueTypes.URL_ARRAY,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PDD_LINKS
          ),
          co_question_meta_json: {
            title: { value: "PDD Link" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_AUTOMATION_REVIEW_SCORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_AUTOMATION_REVIEW_SCORE,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_AUTOMATION_REVIEW_SCORE
          ),
          co_question_meta_json: {
            title: { value: "Automation Store Average Review" }
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.STATS_PROCESS_NUM_CHANGE_REQUESTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.STATS_PROCESS_NUM_CHANGE_REQUESTS,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_REFERENCE_KPI,
        options_meta_overrides: {
          external_answer_source: COExternalAnswerSources.STATS,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          })
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.STATS_PROCESS_NUM_CHANGE_REQUESTS
          ),
          co_question_meta_json: {
            title: { value: "Number of Change Requests" }
          }
        }
      })
    }
  ];
};
