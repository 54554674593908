import { NESTED_THEME_CONTAINER_ID } from "../components/StyleThemeProvider/StyleThemeProvider";

export const getRelativeBoundingRect = (child, parent) => {
  const parentPos = parent.getBoundingClientRect();
  const childPos = child.getBoundingClientRect();
  const relativePos = {
    right: childPos.right - parentPos.right,
    left: childPos.left - parentPos.left,
    top: childPos.top - parentPos.top,
    bottom: childPos.bottom - parentPos.bottom
  };
  return relativePos;
};

export const eventIsEnterKey = e => {
  return e?.keyCode === 13;
};

export const eventIsEscapeKey = e => {
  return e?.keyCode === 27;
};

export const elementIsChildOfQuery = (
  element: HTMLElement,
  query: string
): boolean => {
  const containerFromQuery = document.querySelector(query);

  if (containerFromQuery && element && containerFromQuery.contains(element)) {
    return true;
  }
  return false;
};

export const getNestedThemeContainer = () =>
  document.getElementById(NESTED_THEME_CONTAINER_ID) || document.body;
