import {
  IDEA_APPROVED,
  BUSINESS_REVIEW_APPROVED,
  QUALIFICATION_APPROVED,
  IDEA_REJECTED,
  BUSINESS_REVIEW_REJECTED,
  IDEA_DUPLICATE,
  ASSESSMENT_NOT_STARTED,
  QUALIFICATION_REJECTED,
  QUALIFICATION_ON_HOLD,
  BUSINESS_REVIEW_ON_HOLD,
  TECHNICAL_REVIEW_AWAITING_REVIEW,
  LIVE_ONGOING,
  TECHNICAL_REVIEW_REWORK_REQUIRED,
  TECHNICAL_REVIEW_ON_HOLD,
  DEVELOPMENT_NOT_STARTED,
  DEVELOPMENT_IN_PROGRESS
} from "../constants/automationStatusTypes";
import { component_map_to_color } from "../constants/componentPermissionsConstants";

export const convertStatusIdToColor = statusId => {
  if (!statusId || !component_map_to_color[statusId]) {
    return "default";
  }
  return component_map_to_color[statusId].toLowerCase();
};

export const getLocalizationKeyFromPhaseStatus = currentPhaseStatus => {
  switch (currentPhaseStatus) {
    case IDEA_APPROVED:
    case BUSINESS_REVIEW_APPROVED:
    case QUALIFICATION_APPROVED:
    case DEVELOPMENT_NOT_STARTED:
    case LIVE_ONGOING:
      return "phase_status_change_notification_toast_approved";
    case IDEA_REJECTED:
    case BUSINESS_REVIEW_REJECTED:
    case QUALIFICATION_REJECTED:
      return "phase_status_change_notification_toast_rejected";
    case IDEA_DUPLICATE:
      return "phase_status_change_notification_toast_marked_as_duplicate";
    case ASSESSMENT_NOT_STARTED:
      return "phase_status_change_notification_toast_assessment_launched";
    case QUALIFICATION_ON_HOLD:
    case BUSINESS_REVIEW_ON_HOLD:
    case TECHNICAL_REVIEW_ON_HOLD:
      return "phase_status_change_notification_toast_put_on_hold";
    case TECHNICAL_REVIEW_AWAITING_REVIEW:
      return "phase_status_change_notification_toast_moved_to_technical_review";
    case TECHNICAL_REVIEW_REWORK_REQUIRED:
      return "phase_status_change_notification_toast_rework_required";
    case DEVELOPMENT_IN_PROGRESS:
      return "phase_status_change_notification_toast_development_started";
    default:
      return "phase_status_change_notification_toast_archived";
  }
};
