import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IconApollo from "../IconApollo/IconApollo";
import styled from "styled-components/macro";

export const Container = styled.div`
  .extraPadding {
    &.Star {
      margin: 20px 4px;
    }
  }

  .align-left {
    margin-left: 0px;
  }
`;

export const RatingsContainer = styled.div`
  display: flex;

  .Star {
    font-size: 16px;
    color: var(--color-logo, #da3b11);
  }

  .smallStyle {
    transform: scale(0.5);
    margin: 1px;
  }

  &:first-child {
    margin-left: 0px;
  }
`;

export const ErrorTextContainer = styled.div`
  color: var(--color-error-text);
  font-size: 12px;
  margin: -15px 0 10px 0;
`;

export const StarsButton = styled.button`
  width: 21px;
  margin: 5px 0px;
  margin-left: 4px;
  background-color: transparent;
`;

// have to normalize 5 starts to 1
export const NORMALIZATION_FACTOR: number = 5;

const RatingStars = ({
  rating,
  isEditable,
  error,
  extraPadding,
  align,
  smallStyle,
  className,
  onlyChangeOnClick,
  handleRatingChange
}: {
  rating?: number;
  isEditable?: boolean;
  error?: string;
  extraPadding?: boolean;
  align?: string;
  smallStyle?: boolean;
  className?: string;
  onlyChangeOnClick?: boolean;
  handleRatingChange?: (rating: number) => void;
}) => {
  const { t } = useTranslation("common");
  const [highestHovered, setHighestHovered] = useState<number>(0);
  const [highestClicked, setHighestClicked] = useState<number>(0);

  const handleOnClick = (rating: number) => {
    setHighestClicked(rating);
    if (onlyChangeOnClick) {
      setHighestHovered(rating);
    }
    handleRatingChange?.(rating);
  };

  const handleMouseOver = (rating: number) => {
    setHighestHovered(rating);
    handleRatingChange?.(rating);
  };

  const handleMouseLeave = () => {
    handleRatingChange?.(highestClicked);
  };

  const determineColors = (isFilled: boolean): string => {
    if (isFilled) {
      return "star";
    }
    return "star_outline";
  };

  const getStarSrText = (isFilled: boolean, index: number): string => {
    let srText =
      t(["rating_star_index_srtext", "Rating star {{index}} of 5"], {
        index: index
      }) +
      ", " +
      (isFilled
        ? t("rating_star_star_filled", "Filled")
        : t("rating_star_star_empty", "Empty"));
    console.log("srText: " + srText);
    return srText;
  };

  const renderEditableStars = (starClasses: string): JSX.Element[] => {
    let stars: JSX.Element[] = [];

    const onStarsMouseOver = onlyChangeOnClick
      ? () => {}
      : index => handleMouseOver(index);

    for (let i = 1; i <= 5; i++) {
      const isFilled: boolean =
        rating !== 0 ? (highestHovered >= i ? true : false) : false;
      const icon: string = determineColors(isFilled);
      stars.push(
        <StarsButton
          onClick={() => handleOnClick(i)}
          onMouseOver={() => onStarsMouseOver(i)}
          onMouseLeave={() => handleMouseLeave()}
          aria-label={getStarSrText(isFilled, i)}
        >
          <IconApollo
            key={`editable-star-${i}`}
            data-testid={`editable-${isFilled ? "filled" : "empty"}-star`}
            className={starClasses}
            icon={icon}
          />
        </StarsButton>
      );
    }
    return stars;
  };

  const renderStaticStars = (starClasses: string): JSX.Element[] => {
    let stars: JSX.Element[] = [];
    for (let i = 0.2; i <= 1.0; i += 0.2) {
      const isFilled: boolean =
        (rating ?? 0) >= +i.toPrecision(1) ? true : false;
      const icon: string = determineColors(isFilled);
      stars.push(
        <StarsButton tabIndex={-1} aria-label={getStarSrText(isFilled, i * 5)}>
          <IconApollo
            key={`static-star-${i}`}
            data-testid={`static-${isFilled ? "filled" : "empty"}-star`}
            className={starClasses}
            icon={icon}
          />
        </StarsButton>
      );
    }
    return stars;
  };

  let starClasses = "Star";
  let ratingsClasses = "";

  starClasses += extraPadding ? " extraPadding" : "";
  starClasses += smallStyle ? " smallStyle" : "";
  ratingsClasses += align ? " align-" + { align } : "";

  return isEditable ? (
    <Container className={`${className}`}>
      <RatingsContainer className={ratingsClasses}>
        {renderEditableStars(starClasses)}
      </RatingsContainer>
      {error ? <ErrorTextContainer>{error}</ErrorTextContainer> : ""}
    </Container>
  ) : (
    <Container className={`${className}`}>
      <RatingsContainer className={`${ratingsClasses}`}>
        {renderStaticStars(starClasses)}
      </RatingsContainer>
    </Container>
  );
};

export default RatingStars;
