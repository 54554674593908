import { COAssessment } from "../classes/co-assessment.class";
import { COContext } from "../classes/co-context.class";
import { COValidationType } from "../constants/co-constants";
import { PATH, PATH_ROUTE } from "../constants/co-path.constants";
import { validateItem } from "../helpers/co-validation.helper";
import {
  COValidationContext,
  COValidationError,
  COValidationFunction,
  COValidationItemInterface
} from "../interfaces/co-interfaces";
import { setValue } from "../utils/co-path.utils";
import { coAssertEquals } from "../utils/co-test.utils";
import { isNullOrUndefined } from "../utils/co-utils";

export const testValidationSystem = ({
  assessment,
  logSuccess = false
}: {
  assessment: COAssessment;
  logSuccess?: boolean;
}) => {
  let context = new COContext({ assessment });

  let valueToSet = 100;
  let parent = {
    value: PATH({
      route:
        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
          .CO_PROCESS_ANSWER_SELECTION_INPUT
    })
  };
  COAssessment.prepForSubmissionViewOrEdit({ assessment });
  let process_answer_selected =
    context.assessment?.co_assessment_sections?.[5].co_questions?.[0]
      .co_process_answer?.co_process_answer_selections?.[0];

  context =
    context.update?.({
      process_answer_selection: process_answer_selected
    }) || context;
  setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: valueToSet
  });

  testValidationType({
    testName: "Max numerical value test",
    context: context,
    path: PATH({
      route:
        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
          .CO_PROCESS_ANSWER_SELECTION_INPUT
    }),
    validationType: COValidationType.VALIDATION_TYPE_MAX_NUMERICAL_VALUE,
    value: 50,
    logSuccess: logSuccess
  });

  valueToSet = 10;
  setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: valueToSet
  });

  testValidationType({
    testName: "Min numerical value test",
    context,
    path: PATH({
      route:
        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
          .CO_PROCESS_ANSWER_SELECTION_INPUT
    }),
    validationType: COValidationType.VALIDATION_TYPE_MIN_NUMERICAL_VALUE,
    value: 20,
    logSuccess: logSuccess
  });

  testValidationType({
    testName: "Max string length test",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.TITLE.VALUE
    }),
    validationType: COValidationType.VALIDATION_TYPE_MAX_STRING_LENGTH,
    value: 5,
    logSuccess: logSuccess
  });

  testValidationType({
    testName: "Min string length test",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_META_JSON.TITLE.VALUE
    }),
    validationType: COValidationType.VALIDATION_TYPE_MIN_STRING_LENGTH,
    value: 20,
    logSuccess: logSuccess
  });

  testValidationType({
    testName: "Max array length test",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_SECTIONS_OBJECT
    }),
    validationType: COValidationType.VALIDATION_TYPE_MAX_ARRAY_LENGTH,
    value: 1,
    logSuccess: logSuccess
  });

  testValidationType({
    testName: "Min array length test",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.ASSESSMENT.CO_ASSESSMENT_SECTIONS_OBJECT
    }),
    validationType: COValidationType.VALIDATION_TYPE_MIN_ARRAY_LENGTH,
    value: 20,
    logSuccess: logSuccess
  });

  valueToSet = 80.888;

  setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: valueToSet
  });

  testValidationType({
    testName: "Round to decimal test",
    context: context,
    path: PATH({
      route:
        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
          .CO_PROCESS_ANSWER_SELECTION_INPUT
    }),
    validationType: COValidationType.VALIDATION_TYPE_ROUND_TO_DECIMAL,
    value: 2, // number of digits after
    logSuccess: logSuccess
  });

  let stringValueToSet = "not a number or uppercase";

  setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: stringValueToSet
  });

  testValidationType({
    testName: "Number test",
    context: context,
    path: PATH({
      route:
        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
          .CO_PROCESS_ANSWER_SELECTION_INPUT
    }),
    validationType: COValidationType.VALIDATION_TYPE_NUMBER,
    value: 1,
    logSuccess: logSuccess
  });

  setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: stringValueToSet
  });

  testValidationType({
    testName: "Uppercase test",
    context: context,
    path: PATH({
      route:
        PATH_ROUTE.CONTEXT.PROCESS_ANSWER_SELECTION
          .CO_PROCESS_ANSWER_SELECTION_INPUT
    }),
    validationType: COValidationType.VALIDATION_TYPE_UPPERCASE,
    value: 1,
    logSuccess: logSuccess
  });

  let question =
    context.assessment?.co_assessment_sections?.[5].co_questions?.[0];

  context =
    context.update?.({
      question: question
    }) || context;

  testValidationType({
    testName: "Validation equals test",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.QUESTION.CO_VARIABLE_NAME
    }),
    validationType: COValidationType.VALIDATION_TYPE_EQUALS,
    value: "Not a real variable name", //PCT_DIGITAL_DATA
    logSuccess: logSuccess
  });

  let invalidVariableName = "No-Hyphens-Allowed";

  parent = {
    value: PATH({
      route: PATH_ROUTE.CONTEXT.QUESTION.META.VARIABLE.VALUE
    })
  };

  setValue({
    context,
    parentObject: parent,
    property: "value",
    newValueToSet: invalidVariableName
  });

  testValidationType({
    testName: "Function test",
    context,
    path: PATH({
      route: PATH_ROUTE.CONTEXT.QUESTION.META.VARIABLE.VALUE
    }),
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContext;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      if (!isNullOrUndefined(target)) {
        let regexForCAPITALUNDERSCORES = /([^A-Z_])/g;
        let regexForSPACES = /([ ])/g;

        let transformedValue = (target || "")
          .toUpperCase()
          .replace(regexForSPACES, "_")
          .replace(regexForCAPITALUNDERSCORES, "");

        if (transformedValue !== target) {
          error.transformedValue = transformedValue;
          return [error];
        }
      }

      return [];
    },
    value: 1,
    logSuccess: logSuccess
  });
};

export const testValidationType = ({
  testName,
  context,
  path,
  validationType,
  value, // value to test against
  logSuccess = false,
  validationFunction = undefined
}: {
  testName: string;
  context: COContext;
  path: string;
  validationType: COValidationType;
  value: any;
  logSuccess?: boolean;
  validationFunction?: COValidationFunction;
}) => {
  let validator: COValidationItemInterface = {
    target: path,
    validationType: validationType,
    validationFunction: validationFunction,
    value: value
  };

  let result = validateItem({
    context,
    validator
  });

  coAssertEquals(result.length, 1);
  if (logSuccess) {
    console.log(`Test Passed ${testName} - ${path}`);
  }
};
