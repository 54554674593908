import { customQuestionWithInfo } from "../co-custom-questions.template";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";
import { CODefaultEquations } from "../../constants/co-calculation.constants";
import {
  COPermissionSlugs,
  COConditionSlugs
} from "../../constants/co-constants";

export const customQ2InputQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT,
        standard_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
        title: "% of Digital data input",
        description:
          "Here we would like to understand if the input data is digital and to what extent. Digital data is data that can be stored on a machine: mobile phone, laptop, server etc. Please provide as close as possible of an approximation. Don't hesitate to reach out to the submitters of this idea to gain a better understanding of the degree of digitization. You can find them listed in the contributors section.",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PCT_DATA_DIGITAL_INPUT
          ),
          co_question_position_json: {
            sort_order: 10
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.APPLICATIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.APPLICATIONS,
        standard_question_template_slug:
          StandardQuestionSlugs.APPLICATIONS_COUNT,
        title: "Applications used",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.APPLICATIONS
          ),
          co_equation: CODefaultEquations.COUNT_APPLICATIONS,
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.APPLICATIONS_THIN,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.APPLICATIONS_THIN,
        standard_question_template_slug:
          StandardQuestionSlugs.APPLICATIONS_COUNT,
        title: "Thin applications used",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.APPLICATIONS_THIN
          ),
          co_equation: CODefaultEquations.COUNT_THIN_APPLICATIONS,
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVG_REV_TIME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVG_REV_TIME,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average review or audit time per transaction",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVG_REV_TIME
          ),
          co_question_position_json: {
            sort_order: 100
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVG_WORK_REVIEWED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVG_WORK_REVIEWED,
        standard_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
        title: "Average work to be reviewed/audited (% of total volume)",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVG_WORK_REVIEWED
          ),
          co_question_position_json: {
            sort_order: 90
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVG_REWORK_TIME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVG_REWORK_TIME,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average rework time per transaction",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVG_REWORK_TIME
          ),
          co_question_position_json: {
            sort_order: 70
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVG_ERROR_RATE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVG_ERROR_RATE,
        standard_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
        title: "Average error rate (% of total volume)",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVG_ERROR_RATE
          ),
          co_question_position_json: {
            sort_order: 60
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average processing time per transaction",
        description:
          "Please insert the average time it takes to run the process/task once in minutes. Feel free to have a discussion with the submitters of this idea which you can find in the collaborators section.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION
          ),
          co_question_position_json: {
            sort_order: 40
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Number of employees performing the task",
        description:
          "This value is taken into account if the volumes added are 'per employee'. It will also be taken into consideration in the number of required licenses if you decide to use attended robots, in the Cost-Benefit Analysis stage.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK
          ),
          co_question_position_json: {
            sort_order: 30
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Activity volume average (per selected frequency)",
        description:
          "E.g. number of incoming documents, files, requests, number of transactions executed.",
        help_text: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average employee full cost per year (Optional)",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR
          ),
          co_question_position_json: {
            sort_order: 30
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average working days per year",
        description:
          "Exclude bank holidays, paid holidays, any company benefit holidays.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_WORKING_DAYS_PER_YEAR
          ),
          co_question_default_options_json: {
            default_equation_value: 260
          },
          co_question_position_json: {
            sort_order: 10
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.DAYS_YEAR
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.WORKING_HOURS_PER_DAY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.WORKING_HOURS_PER_DAY,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Working hours per day",
        description:
          "Legal number of working hours in a day, as defined in the legislation of the country or as defined in the working contract.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.WORKING_HOURS_PER_DAY
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          visibility_permissions: [COPermissionSlugs.Q2_PERMISSIONS],
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_DAY
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.DA_AVG_REWORK_TIME_AFTER_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.DA_AVG_REWORK_TIME_AFTER_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average rework time - after automation",
        description:
          "After automation, in minutes, how long does it take to resolve an error?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.DA_AVG_REWORK_TIME_AFTER_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 30
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average processing time per transaction - after automation",
        description:
          "After automation, how long does it take to perform this process once?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.DA_AVG_ERROR_RATE_AFTER_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.DA_AVG_ERROR_RATE_AFTER_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
        title: "Average error rate - after automation",
        description: "After automation, how often do errors occur?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.DA_AVG_ERROR_RATE_AFTER_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        answer_option_info: []
      })
    }
  ];
};
