import {
  COConditionSlugs,
  PIPELINE_COLUMN_NAME
} from "../../constants/co-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardReferenceSlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";
import { LegacyQuestionSlugs } from "../../constants/co-migration-constants";

export const customEXRefQuestionsTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.EX_DA_TIME_PER_TRANS_BEFORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_TIME_PER_TRANS_BEFORE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Average processing time per transaction",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_TIME_PER_TRANS_BEFORE
          ),
          co_question_position_json: {
            sort_order: 0
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              PIPELINE_COLUMN_NAME.AVG_PROCESS_TIME_TRANSACTION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_ERROR_RATE_BEFORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_ERROR_RATE_BEFORE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Average error rate (% of total volume)",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_ERROR_RATE_BEFORE
          ),
          co_question_position_json: {
            sort_order: 100
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.AVERAGE_ERROR_RATE
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_REWORK_TIME_BEFORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_REWORK_TIME_BEFORE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Average rework time per transaction",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_REWORK_TIME_BEFORE
          ),
          co_question_position_json: {
            sort_order: 200
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.AVERAGE_REWORK_TIME
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_REVIEW_RATE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_REVIEW_RATE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Detailed Assessment - Average work to be reviewed/audited (% of total volume)",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_REVIEW_RATE
          ),
          co_question_position_json: {
            sort_order: 300
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.AVERAGE_WORK_TO_BE_REVIEWED
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_REVIEW_TIME,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_REVIEW_TIME,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Detailed Assessment - Average review or audit time per transaction",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_REVIEW_TIME
          ),
          co_question_position_json: {
            sort_order: 400
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.AVERAGE_REVIEW_AUDIT_TIME
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_AVERAGE_PROCESSING_TIME_AFTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_AVERAGE_PROCESSING_TIME_AFTER,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Detailed Assessment - Average processing time per transaction - after automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_AVERAGE_PROCESSING_TIME_AFTER
          ),
          co_question_position_json: {
            sort_order: 500
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.DA_AVERAGE_PROCESS_DURATION_POST_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_AVERAGE_ERROR_RATE_AFTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_AVERAGE_ERROR_RATE_AFTER,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Average error rate - after automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_AVERAGE_ERROR_RATE_AFTER
          ),
          co_question_position_json: {
            sort_order: 600
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.DA_AVERAGE_ERROR_RATE_POST_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_AVERAGE_REWORK_TIME_AFTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_AVERAGE_REWORK_TIME_AFTER,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Average rework time - after automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_AVERAGE_REWORK_TIME_AFTER
          ),
          co_question_position_json: {
            sort_order: 700
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.DA_AVERAGE_REWORK_TIME_POST_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_AVERAGE_EMPLOYEE_FULL_COST,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_AVERAGE_EMPLOYEE_FULL_COST,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Detailed Assessment - Average employee full cost per year (Optional)",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_AVERAGE_EMPLOYEE_FULL_COST
          ),
          co_question_position_json: {
            sort_order: 900
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.EMPLOYEE_AVERAGE_FULL_COST
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_AVERAGE_WORKING_DAYS_PER_YEAR,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_AVERAGE_WORKING_DAYS_PER_YEAR,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Average working days per year",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_AVERAGE_WORKING_DAYS_PER_YEAR
          ),
          co_question_position_json: {
            sort_order: 1000
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.EMPLOYEE_AVERAGE_WORKING_DAYS
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_WORKING_HOURS_PER_DAY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_WORKING_HOURS_PER_DAY,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Working hours per day",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_WORKING_HOURS_PER_DAY
          ),
          co_question_position_json: {
            sort_order: 1100
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.EMPLOYEE_WORKING_HOURS
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_TASK_PROCESS_FREQUENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_TASK_PROCESS_FREQUENCY,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Task/process frequency",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_TASK_PROCESS_FREQUENCY
          ),
          co_question_position_json: {
            sort_order: 1220
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.FREQUENCY
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_ACTIVITY_VOLUME_AVERAGE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_ACTIVITY_VOLUME_AVERAGE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Detailed Assessment - Activity volume average (per selected frequency)",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_ACTIVITY_VOLUME_AVERAGE
          ),
          co_question_position_json: {
            sort_order: 1230
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              PIPELINE_COLUMN_NAME.PROCESS_VOLUME
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_VOLUMES_FREQUENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_VOLUMES_FREQUENCY,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Detailed Assessment - Activity volume average (per selected frequency) (nested)",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_VOLUMES_FREQUENCY
          ),
          co_question_position_json: {
            sort_order: 1240
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.VOLUMES_OPTION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DA_NUMBER_OF_EMPLOYEES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DA_NUMBER_OF_EMPLOYEES,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Number of employees performing the task",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DA_NUMBER_OF_EMPLOYEES
          ),
          co_question_position_json: {
            sort_order: 1250
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              PIPELINE_COLUMN_NAME.NUMBER_OF_EMPLOYEES
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_DETAILED_ASSESSMENT__AUTOMATION_POTENTIAL,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_DETAILED_ASSESSMENT__AUTOMATION_POTENTIAL,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Detailed Assessment - Automation potential",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_DETAILED_ASSESSMENT__AUTOMATION_POTENTIAL
          ),
          co_question_position_json: {
            sort_order: 1400
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              PIPELINE_COLUMN_NAME.PROCESS_AUTOMATION_SCORE
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_BEFORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_BEFORE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Average process duration - before automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_BEFORE
          ),
          co_question_position_json: {
            sort_order: 1500
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_AVERAGE_PROCESS_DURATION_PRE_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_AFTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_AFTER,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Average process duration - after automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA__AVERAGE_PROCESS_DURATION_AFTER
          ),
          co_question_position_json: {
            sort_order: 1600
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_AVERAGE_PROCESS_DURATION_POST_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_BEFORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_BEFORE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Average error rate - before automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_BEFORE
          ),
          co_question_position_json: {
            sort_order: 1700
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_AVERAGE_ERROR_RATE_PRE_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_AFTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_AFTER,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Average error rate - after automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA__AVERAGE_ERROR_RATE_AFTER
          ),
          co_question_position_json: {
            sort_order: 1800
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_AVERAGE_ERROR_RATE_POST_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_BEFORE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_BEFORE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Average rework time - before automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_BEFORE
          ),
          co_question_position_json: {
            sort_order: 1900
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_AVERAGE_REWORK_TIME_PRE_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_AFTER,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_AFTER,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Average rework time - after automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CDA__AVERAGE_REWORK_TIME_AFTER
          ),
          co_question_position_json: {
            sort_order: 2000
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_AVERAGE_REWORK_TIME_POST_AUTOMATION
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__VOLUME_AVERAGE,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__VOLUME_AVERAGE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Citizen Developer Assessment - Volume average",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__VOLUME_AVERAGE
          ),
          co_question_position_json: {
            sort_order: 2200
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_VOLUME_AVERAGE
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug:
        CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__PROCESS_FREQUENCY,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__PROCESS_FREQUENCY,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title: "Citizen Developer Assessment - Process frequency",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__PROCESS_FREQUENCY
          ),
          co_question_position_json: {
            sort_order: 2300
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_PROCESS_FREQUENCY
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug:
        CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__NUMBER_OF_PEOPLE,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__NUMBER_OF_PEOPLE,
        standard_reference_template_slug:
          StandardReferenceSlugs.REFERENCED_CALCULATED_VALUE,
        title:
          "Citizen Developer Assessment - Number of people that could benefit from this automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.EX_CITIZEN_DEVELOPER_ASSESSMENT__NUMBER_OF_PEOPLE
          ),
          co_question_position_json: {
            sort_order: 2400
          },
          co_question_options_json: {
            referenced_calculated_value_process_column_name:
              LegacyQuestionSlugs.CD_POTENTIAL_BENEFIT
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }],
          prevent_deleting: 0,
          is_read_only: 0
        },
        condition_overrides_to_remove: []
      })
    }
  ];
};
