import {
  FETCH_HIERARCY_FOR_MANAGE_APPLICATONS,
  FETCH_APPLICATIONS_FOR_MANAGE_APPLICATONS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  hierarchy: null,
  applications: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_HIERARCY_FOR_MANAGE_APPLICATONS:
      return {
        ...state,
        hierarchy: action.payload
      };
    case FETCH_APPLICATIONS_FOR_MANAGE_APPLICATONS:
      return {
        ...state,
        applications: action.payload
      };
    default:
      return state;
  }
}
