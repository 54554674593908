import { COEmailActionTemplateInterface } from "../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  COActionTypes
} from "../../../../constants/actions/co-action-constants";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../../constants/co-path.constants";
import { functionNotificationEmailActionTemplate } from "./types/co-email-functional-notification-templates";
import { curationNotificationEmailActionTemplate } from "./types/co-email-curation-notification-templates";
import { contentProducerNotificationEmailActionTemplate } from "./types/co-email-content-producer-notification-templates";
import { contentConsumerNotificationEmailActionTemplate } from "./types/co-email-content-consumer-notification-templates";

export const emailActionTemplate = (
  slug: string
): COEmailActionTemplateInterface => {
  // get template based on slug
  let template = emailActionTemplateFromSlug(slug);

  // add values that will be the same for each notification
  template = {
    co_action_template_slug: slug,
    co_action_template_co_type: COActionTypes.EMAIL,
    ...template
  };
  template.co_action_json = {
    co_action_type: COActionTypes.EMAIL,
    co_action_template_slug: slug,
    ...(template.co_action_json ?? {})
  };
  template.co_meta_json = {
    title: {
      ...template.co_meta_json?.title,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    email_title: {
      // The title for Emails should always start as EMAIL_TITLE
      // since it comes from the templates in tenant-service
      // after being edited it will become it's own string in the db
      value:
        COActionReplacementStrings.REMINDER_EMAIL_TITLE_PREFIX +
        " " +
        COActionReplacementStrings.EMAIL_TITLE,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    contextual_email_title: {
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.ACTION.FUNCTION_PARSE_ACTION_META_VALUE_TO_STRING,
        operator: PATH_OPERATORS.FUNCTION,
        operatorParams: {
          value: PATH({
            route: PATH_ROUTE.CONTEXT.ACTION.META.EMAIL_TITLE.VALUE
          })
        }
      })
    },
    email_body: {
      ...template.co_meta_json?.email_body,
      display_context_overrides: {
        assessment_view: {
          is_hidden: 1
        }
      }
    },
    contextual_email_body: {
      value: PATH({
        route:
          PATH_ROUTE.CONTEXT.ACTION
            .FUNCTION_LOAD_AND_PARSE_ACTION_META_EMAIL_TEMPLATE,
        operator: PATH_OPERATORS.FUNCTION,
        operatorParams: {
          value: PATH({
            route: PATH_ROUTE.CONTEXT.ACTION.META.EMAIL_BODY.VALUE
          })
        }
      })
    },
    ...(template.co_meta_json ?? {})
  };

  return template;
};

const emailActionTemplateFromSlug = (
  slug: string
): COEmailActionTemplateInterface => {
  return (
    functionNotificationEmailActionTemplate(slug) ??
    curationNotificationEmailActionTemplate(slug) ??
    contentProducerNotificationEmailActionTemplate(slug) ??
    contentConsumerNotificationEmailActionTemplate(slug) ??
    {}
  );
};
