//= =====================
// Modal Types
//= =====================
export const PROCESS_STATUS_ARCHIVED = 50;

export const PROCESS_SUBMISSION_TYPE_QUESTIONNAIRE = 1;
export const PROCESS_SUBMISSION_TYPE_TOP_DOWN = 2;
export const PROCESS_SUBMISSION_TYPE_CITIZEN_DEVELOPER = 3;
export const PROCESS_SUBMISSION_TYPE_PROCESS_MINING = 4;
export const PROCESS_SUBMISSION_TYPE_TASK_MINING = 5;
export const PROCESS_SUBMISSION_TYPE_CHANGE_REQUEST = 6;

export const IDEA_TYPES = [
  PROCESS_SUBMISSION_TYPE_QUESTIONNAIRE,
  PROCESS_SUBMISSION_TYPE_TOP_DOWN
];
export const AUTOMATION_TYPE = PROCESS_SUBMISSION_TYPE_CITIZEN_DEVELOPER;
export const DEVELOPMENT_TYPE_COE_USER_SLUG = "coe";
export const DEVELOPMENT_TYPE_CD_POWER_USER_SLUG = "cd-power-user";
export const DEVELOPMENT_TYPE_CD_SELF_USER_SLUG = "cd-self-user";

export const PROCESS_COE_SUBMISSION_TYPES = [
  PROCESS_SUBMISSION_TYPE_TOP_DOWN,
  PROCESS_SUBMISSION_TYPE_PROCESS_MINING,
  PROCESS_SUBMISSION_TYPE_TASK_MINING,
  PROCESS_SUBMISSION_TYPE_CHANGE_REQUEST
];

// we want to show post automation results for these submission types
export const SUBMISSION_TYPES_WITH_POST_AUTOMATION_RESULTS = [
  PROCESS_SUBMISSION_TYPE_QUESTIONNAIRE,
  PROCESS_SUBMISSION_TYPE_TOP_DOWN,
  PROCESS_SUBMISSION_TYPE_PROCESS_MINING
];
