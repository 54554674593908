import { FormHelperText, InputLabel } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { ApolloInputLabelWithTooltip } from "./../InputTextApollo/InputTextApollo";

export const InputLabelInputLabelApollo = styled(InputLabel)`
  &&& {
    background: transparent;
  }
`;

const InputLabelApollo = ({
  children,
  label,
  labelTooltip,
  helperText,
  className,
  labelFor,
  required,
  disabled,
  error,
  labelID,
  ariaLabelledBy
}: {
  children?: any;
  label?: any;
  labelTooltip?: any;
  helperText?: any;
  className?: string;
  labelFor?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  labelID?: string;
  ariaLabelledBy?: string;
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = () => {
    setHasFocus(true);
  };
  const handleBlur = () => {
    setHasFocus(false);
  };
  return (
    <div className={className} onFocus={handleFocus} onBlur={handleBlur}>
      {!!label && (
        <InputLabelInputLabelApollo
          error={error}
          required={required}
          focused={hasFocus}
          disabled={disabled}
          htmlFor={labelFor}
        >
          <ApolloInputLabelWithTooltip
            label={label}
            labelTooltip={labelTooltip}
            required={required}
            labelID={labelID}
            ariaLabelledBy={ariaLabelledBy}
          />
        </InputLabelInputLabelApollo>
      )}
      {children}
      {!!helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default InputLabelApollo;
