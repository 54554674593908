import { COAssessment } from "../classes/co-assessment.class";

export const testPipeline = ({
  assessment,
  logSuccess = false
}: {
  assessment: COAssessment;
  logSuccess?: boolean;
}) => {
  // basic path get
};
