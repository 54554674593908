import {
  FETCH_TAXONOMY,
  FETCH_FILTERED_TAXONOMY_BY_PERMISSION,
  SET_QUESTIONNAIRE_CLOSE_ROUTE
} from "../constants/actionTypes";

const INITIAL_STATE = {
  q1Data: null,
  taxonomy: null,
  closeQuestionnaireRoute: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TAXONOMY:
      return {
        ...state,
        taxonomy: action.payload
      };
    case FETCH_FILTERED_TAXONOMY_BY_PERMISSION:
      return {
        ...state,
        taxonomyFilteredByPermission: action.payload
      };

    case SET_QUESTIONNAIRE_CLOSE_ROUTE:
      return {
        ...state,
        closeQuestionnaireRoute: action.payload
      };
    default:
      return state;
  }
}
