import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import DropDownNavAuth from "./../DropDownNavAuth/DropDownNavAuth";
import ButtonHamburger from "./../ButtonHamburger/ButtonHamburger";
import {
  clearUnsavedData,
  setSaveCallback,
  clearLeavingCuration
} from "../../actions/globalDataActions";
import { setQuestionnaireCloseRoute } from "../../actions/questionnaireActions";
import { EXPLORE_AUTOMATIONS_PROGRAM_ROUTE } from "../../constants/routesConstants";
import { setTenantLogo } from "../../actions/userProfileActions";
import NavigationPrompt from "react-router-navigation-prompt";
import swal from "./../../components/SweetAlert/SweetAlert";
import Badge from "@material-ui/core/Badge";
import SessionHelper from "./../../helpers/SessionHelper";
import { refreshUserStatus } from "./../../helpers/UserStatusHelper";
import urlWithTenant, { setActiveLinkAttribute } from "../../utils/urlHelper";
import {
  MANAGE_COMPONENTS_PERMISSION,
  DASHBOARD_PERMISSION,
  VIEW_REPORTS_PERMISSION,
  AUTOMATION_PIPELINE_TAB_PERMISSIONS,
  TOP_DOWN_PERMISSION_PERMISSION,
  EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION,
  SUBMIT_AUTOMATION_PERMISSION,
  SUBMIT_COMPONENT_PERMISSION
} from "../../constants/adminPermissionsConstants";
import { featureFlag } from "./../../helpers/FeatureFlag";
import "./NavAuth.scss";
import logoHelper from "../../helpers/tenantLogoHelper";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import TenantSettingsHelper from "../../helpers/TenantSettingsHelper";
import ShareIdeaButton from "./ShareIdeaButton/ShareIdeaButton";
import { getKeyValue } from "../../utils/objectParsers";
import { hasPermissionToView } from "../../utils/permissionVisibilityUtils";
import styled from "styled-components/macro";
import { canViewAdminAccountPage } from "../../utils/permissionVisibilityUtils";
import {
  elementIsChildOfQuery,
  eventIsEscapeKey
} from "../../utils/elementUtils";
import { Icon, Typography } from "@material-ui/core";
import NavAuthDynamicResponsiveContainer from "./NavAuthDynamicResponsiveContainer/NavAuthDynamicResponsiveContainer";
import IconApollo from "../IconApollo/IconApollo";
import NavAuthApolloContainer from "./NavAuthApolloContainer";

const NavInnerContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  ${SessionHelper.isFpsUri() ? "" : `margin: 0 auto;`}
  display: flex;
  align-items: center;
`;

export const LogoDivider = styled(({ className }) => {
  return (
    <div className={className}>
      <portal-divider />
    </div>
  );
})`
  display: flex;
  align-items: center;
`;

export const NavLink = ({
  to,
  children,
  match,
  onClick,
  className,
  ah_permissions,
  userPermissions
}) => {
  const canView = hasPermissionToView({
    permissionKeys: ah_permissions,
    userPermissions
  });

  return canView ? (
    <Link
      to={to}
      onClick={onClick}
      className={className}
      data-active-link={setActiveLinkAttribute(to, match)}
    >
      {children}
    </Link>
  ) : null;
};

class NavAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.unsavedAlertIsOpen = false;
  }

  componentDidMount() {
    this.props.setTenantLogo(logoHelper.getLogo() || null);
    this.props.refreshUserStatus();
    this.props.clearUnsavedData();
    window.addEventListener("iconClicked", this.onNavBrandIconClicked);
  }

  componentWillUnmount() {
    window.removeEventListener("iconClicked", this.onNavBrandIconClicked);
  }

  onNavBrandIconClicked = () => {
    this.closeNav();
    this.props.history.push(urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE));
  };

  toggleNav = () => {
    this.setState(prevState => {
      const state = { ...prevState };
      state.isOpen = !state.isOpen;
      if (state.isOpen) {
        document.body.addEventListener("click", this.closeNavListener);
        window.addEventListener("keydown", this.closeMobileNavOnEscapeKey);
      } else {
        this.removeCloseNavListeners();
      }
      return state;
    });
  };

  closeNav = () => {
    this.setState({ isOpen: false });
    this.removeCloseNavListeners();
  };

  closeMobileNavOnEscapeKey = e => {
    if (eventIsEscapeKey(e)) {
      this.closeNav();
    }
  };

  closeNavListener = e => {
    if (!elementIsChildOfQuery(e.target, ".NavAuth")) {
      this.closeNav();
    }
  };

  removeCloseNavListeners = () => {
    document.body.removeEventListener("click", this.closeNavListener);
    window.removeEventListener("keydown", this.closeMobileNavOnEscapeKey);
  };

  saveChangesPrompt = (onCancel, onConfirm, titleTextButtonOptions) => {
    this.unsavedAlertIsOpen = true;
    if (!titleTextButtonOptions) {
      titleTextButtonOptions = {
        title: this.props.t("nav_auth_save_change_title"),
        text: this.props.t("nav_auth_save_change_content"),
        cancel_text: this.props.t("nav_auth_save_change_cancel_button"),
        confirm_text: this.props.t("nav_auth_save_change_confirm_button")
      };
    }
    swal
      .fire({
        type: "question",
        title: titleTextButtonOptions.title
          ? titleTextButtonOptions.title
          : this.props.t("nav_auth_save_change_title"),
        text: titleTextButtonOptions.text
          ? titleTextButtonOptions.text
          : this.props.t("nav_auth_save_change_content"),
        showCancelButton: true,
        cancelButtonText: titleTextButtonOptions.cancel_text
          ? titleTextButtonOptions.cancel_text
          : this.props.t("nav_auth_save_change_cancel_button"),
        confirmButtonText: titleTextButtonOptions.confirm_text
          ? titleTextButtonOptions.confirm_text
          : this.props.t("nav_auth_save_change_confirm_button")
      })
      .then(e => {
        if (e.value) {
          onCancel();
          if (typeof this.props.saveCallback === "function") {
            this.props.saveCallback();
          }
        } else if (e.dismiss == "cancel") {
          this.props.clearUnsavedData();
          this.props.setSaveCallback(null);
          onConfirm();
        } else {
          onCancel();
        }
        this.unsavedAlertIsOpen = false;
      });
  };

  leavingCurationConfirmationPrompt = (onCancel, onConfirm) => {
    swal
      .fire({
        type: "question",
        title: this.props.t("nav_auth_naviage_away_title"),
        text: this.props.t("nav_auth_naviage_away_content"),
        showCancelButton: true,
        cancelButtonText: this.props.t("nav_auth_naviage_away_cancel_button"),
        confirmButtonText: this.props.t("nav_auth_naviage_away_confirm_button")
      })
      .then(e => {
        if (e.value) {
          onConfirm();
          this.props.clearLeavingCuration();
          this.props.clearUnsavedData();
        } else {
          onCancel();
        }
      });
  };

  renderUnsavedModal = (onCancel, onConfirm, titleTextButtonOptions) => {
    if (this.unsavedAlertIsOpen) return;
    if (!this.props.isLeavingCuration) {
      this.saveChangesPrompt(onCancel, onConfirm, titleTextButtonOptions);
    } else {
      this.leavingCurationConfirmationPrompt(onCancel, onConfirm);
    }
  };

  getAdminNavLinkLocation = user => {
    if (typeof user !== "object" && Object.keys(user).length === 0) {
      return urlWithTenant("/");
    } else {
      const canSeeAccountPage =
        user && user.ah_permissions
          ? canViewAdminAccountPage(user.ah_permissions)
          : false;

      const canSeeComponentsPage =
        user && user.ah_permissions
          ? user.ah_permissions[MANAGE_COMPONENTS_PERMISSION] === 1
          : false;
      if (canSeeAccountPage) {
        return urlWithTenant("/admin/account");
      } else if (canSeeComponentsPage) {
        return urlWithTenant("/admin/manage-components");
      } else {
        return urlWithTenant("/");
      }
    }
  };

  getLogoImage = () => {
    const isFps = SessionHelper.isFpsUri();
    if (
      !isFps &&
      this.props.userAdmin &&
      this.props.userAdmin.tenantLogo &&
      typeof this.props.userAdmin.tenantLogo === "string" &&
      this.props.userAdmin.tenantLogo.substring(0, 7) !== "/static"
    ) {
      return this.props.userAdmin.tenantLogo;
    } else if (
      !isFps &&
      this.props.tenantLogo &&
      typeof this.props.tenantLogo === "string" &&
      this.props.tenantLogo.substring(0, 7) !== "/static"
    ) {
      return this.props.tenantLogo;
    } else {
      return undefined;
    }
  };

  render() {
    const navItemsOpen = this.state.isOpen ? "NavAuth__items--open" : "";
    let currentUser = SessionHelper.currentUser()
      ? SessionHelper.currentUser()
      : {};
    let user_link = urlWithTenant("/user/" + currentUser.user_profile_slug);
    const userPermissions = getKeyValue(currentUser, "ah_permissions");

    const adminNavLinkLocation = this.getAdminNavLinkLocation(currentUser);

    const isFpsUri = SessionHelper.isFpsUri();
    const cloudTenant = SessionHelper.fpsTenantFromCurrentPath();
    const cloudAccount = SessionHelper.fpsAccountFromCurrentPath();
    const cloudAccessToken = SessionHelper.getUserCloudAccessToken();

    /* Tenant setting permissions section */
    const showLeaderBoardLink = TenantSettingsHelper.showLeaderboard();
    const showComponentsLink = TenantSettingsHelper.showComponents();
    const showUserProfile = TenantSettingsHelper.isExploreUsersAndUserProfileEnabled();
    const coBrandLogoImage = this.getLogoImage();

    // User Guide
    const formattedLangCode = (this.props.i18n?.language || "en").replace(
      "-",
      "_"
    );
    const userGuideUrl = `https://docs.uipath.com/automation-hub/lang-${formattedLangCode}`;

    let showAdminButton = false;
    let showDashboardOption = false;
    let showReportOption = false;
    let showAutomationPipeline = false;

    if (currentUser) {
      if (currentUser.ah_permissions) {
        if (canViewAdminAccountPage(currentUser.ah_permissions)) {
          showAdminButton = true;
        }

        if (currentUser.ah_permissions[DASHBOARD_PERMISSION]) {
          showDashboardOption = true;
        }
        if (currentUser.ah_permissions[VIEW_REPORTS_PERMISSION]) {
          showReportOption = true;
        }

        for (let permission of AUTOMATION_PIPELINE_TAB_PERMISSIONS) {
          if (currentUser.ah_permissions[permission] === 1) {
            showAutomationPipeline = true;
            break;
          }
        }
      }
    }

    const openAutomationIcon = (
      <Icon className="NavAuth__external-link">launch</Icon>
    );

    return (
      <nav
        className={"NavAuth" + (isFpsUri ? " NavAuthFPS" : "")}
        data-testid="NavAuth"
      >
        <NavigationPrompt
          when={this.props.hasUnsavedData}
          afterConfirm={this.location}
        >
          {({ isActive, onCancel, onConfirm }) => {
            if (isActive) {
              return (
                <div>
                  {this.renderUnsavedModal(
                    onCancel,
                    onConfirm,
                    this.props.titleTextButtonOptions
                  )}
                </div>
              );
            }
            return null;
          }}
        </NavigationPrompt>
        <NavAuthApolloContainer
          product="Automation Hub"
          hideTenantPicker={isFpsUri ? "false" : "true"}
          isStandalone={isFpsUri ? "false" : "true"}
          hideAvatar={isFpsUri ? "false" : "true"}
          theme={featureFlag.isApolloDarkThemeEnabled() ? undefined : "light"}
          compactModeOverride={
            featureFlag.isApolloCompactHeaderOverrideEnabled()
              ? "true"
              : undefined
          }
          coBrand={coBrandLogoImage}
          showHelpMenu={isFpsUri ? "true" : "false"}
        >
          <NavAuthDynamicResponsiveContainer
            slot="slot-mid"
            style={{ display: "flex", flexGrow: 1 }}
          >
            <LogoDivider />
            <NavInnerContainer className="NavAuth__slot-container">
              <div className="NavAuth__hamburger-container">
                <ButtonHamburger
                  isOpen={this.state.isOpen}
                  toggleNav={this.toggleNav}
                />
              </div>
              <div className={`NavAuth__items ${navItemsOpen}`}>
                <DropDownNavAuth
                  key={`workspace ${this.state.isOpen}`}
                  title={this.props.t([
                    "application_header_workspace_menuitem",
                    "Workspace"
                  ])}
                >
                  {showAutomationPipeline && (
                    <NavLink
                      to={urlWithTenant("/automation-pipeline")}
                      onClick={this.closeNav}
                      match={this.props.match}
                    >
                      {this.props.t([
                        "application_header_automation_pipeline_menuitem",
                        "Automation Pipeline"
                      ])}
                    </NavLink>
                  )}
                  <NavLink
                    to={urlWithTenant("/my-submissions")}
                    onClick={this.closeNav}
                    match={this.props.match}
                    userPermissions={userPermissions}
                    ah_permissions={[
                      TOP_DOWN_PERMISSION_PERMISSION,
                      EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION,
                      SUBMIT_AUTOMATION_PERMISSION
                    ]}
                  >
                    {this.props.t([
                      "application_header_my_submissions_menuitem",
                      "My Submissions"
                    ])}
                  </NavLink>
                  <NavLink
                    to={urlWithTenant("/my-collaborations")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t([
                      "application_header_my_collaborations_menuitem",
                      "My Collaborations"
                    ])}
                  </NavLink>
                  {showComponentsLink ? (
                    <NavLink
                      to={urlWithTenant("/my-components")}
                      onClick={this.closeNav}
                      match={this.props.match}
                      userPermissions={userPermissions}
                      ah_permissions={[SUBMIT_COMPONENT_PERMISSION]}
                    >
                      {this.props.t([
                        "application_header_my_components_menuitem",
                        "My Components"
                      ])}
                    </NavLink>
                  ) : null}
                  <NavLink
                    to={urlWithTenant("/my-following")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t([
                      "application_header_following_menuitem",
                      "Following"
                    ])}
                  </NavLink>
                </DropDownNavAuth>
                <DropDownNavAuth
                  key={`explore ${this.state.isOpen}`}
                  title={this.props.t([
                    "application_header_explore_menuitem",
                    "Explore"
                  ])}
                >
                  <NavLink
                    to={urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE)}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t([
                      "application_header_automations_menuitem",
                      "Automation Program"
                    ])}
                  </NavLink>

                  {showUserProfile && (
                    <NavLink
                      to={urlWithTenant("/explore-people")}
                      onClick={this.closeNav}
                      match={this.props.match}
                    >
                      {this.props.t([
                        "application_header_people_menuitem",
                        "People"
                      ])}
                    </NavLink>
                  )}

                  {showComponentsLink ? (
                    <NavLink
                      to={urlWithTenant("/explore-components")}
                      onClick={this.closeNav}
                      match={this.props.match}
                    >
                      {this.props.t([
                        "application_header_components_menuitem",
                        "Components"
                      ])}
                    </NavLink>
                  ) : null}
                  {showLeaderBoardLink ? (
                    <NavLink
                      to={urlWithTenant("/leaderboard")}
                      onClick={this.closeNav}
                      match={this.props.match}
                    >
                      {this.props.t([
                        "application_header_leaderboard_menuitem",
                        "Leaderboard"
                      ])}
                    </NavLink>
                  ) : (
                    ""
                  )}
                </DropDownNavAuth>

                {showDashboardOption || showReportOption ? (
                  <DropDownNavAuth
                    key={`dashboards ${this.state.isOpen}`}
                    title={this.props.t([
                      "application_header_dashboards_menuitem",
                      "Dashboards"
                    ])}
                  >
                    {showDashboardOption ? (
                      <NavLink
                        to={urlWithTenant("/dashboard/performance")}
                        onClick={this.closeNav}
                        match={this.props.match}
                      >
                        {this.props.t([
                          "application_header_automation_program_performance_menuitem",
                          "Automation Program Performance"
                        ])}
                      </NavLink>
                    ) : null}
                    {showDashboardOption ? (
                      <NavLink
                        to={urlWithTenant("/dashboard/cost")}
                        onClick={this.closeNav}
                        match={this.props.match}
                      >
                        {this.props.t([
                          "application_header_average_automation_cost_menuitem",
                          "Average Automation Cost"
                        ])}
                      </NavLink>
                    ) : null}
                    {showReportOption ? (
                      <NavLink
                        to={urlWithTenant(
                          "/performance/planning-live-automations"
                        )}
                        onClick={this.closeNav}
                        match={this.props.match}
                      >
                        {this.props.t([
                          "application_header_planning_menuitem",
                          "Planning Report"
                        ])}
                      </NavLink>
                    ) : null}
                    {showReportOption ? (
                      <NavLink
                        to={urlWithTenant("/performance/cost-report")}
                        onClick={this.closeNav}
                        match={this.props.match}
                      >
                        {this.props.t([
                          "application_header_cost_report_menuitem",
                          "Cost Report"
                        ])}
                      </NavLink>
                    ) : null}
                  </DropDownNavAuth>
                ) : null}
                {showAdminButton ? (
                  <NavLink
                    to={adminNavLinkLocation}
                    className="NavAuth__admin-console"
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    <span className="NavAuth__admin-console--admin-console-text">
                      {this.props.t([
                        "application_header_admin_menuitem_admin_console",
                        "Admin Console"
                      ])}
                    </span>
                    <span className="NavAuth__admin-console--admin-text">
                      {this.props.t([
                        "application_header_admin_menuitem_admin",
                        "Admin"
                      ])}
                    </span>
                  </NavLink>
                ) : null}

                <div className="NavAuth__items-right">
                  <ShareIdeaButton
                    user={currentUser}
                    isOpen={this.state.isOpen}
                    onAutomationIdeaClickCallback={this.closeNav}
                    onShareAutomationClickCallback={this.closeNav}
                    onSubmitCoeIdeaClickCallback={this.closeNav}
                  />

                  <NavLink
                    to={urlWithTenant("/my-tasks")}
                    className="NavAuth__icon"
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    <Badge
                      badgeContent={this.props.num_incomplete_tasks}
                      color="secondary"
                      classes={{
                        badge: "NavAuth__counter-badge"
                      }}
                    >
                      <Icon className="NavAuth_icon-img">
                        playlist_add_check
                      </Icon>
                      <Typography variant="srOnly">
                        {this.props.t("application_header_tasks", "Tasks")}
                      </Typography>
                    </Badge>
                  </NavLink>

                  <NavLink
                    to={urlWithTenant("/notifications")}
                    className="NavAuth__icon"
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    <Badge
                      badgeContent={this.props.num_notifications}
                      color="secondary"
                      classes={{
                        badge: "NavAuth__counter-badge"
                      }}
                    >
                      <Icon className="NavAuth_icon-img material-icons-outlined">
                        notifications
                      </Icon>
                      <Typography variant="srOnly">
                        {this.props.t(
                          "application_header_notifications",
                          "Notifications"
                        )}
                      </Typography>
                    </Badge>
                  </NavLink>

                  <DropDownNavAuth
                    key={`More ${this.state.isOpen}`}
                    titleComponent={
                      <div
                        className={`NavAuth__icon NavAuth__icon--more-dropdown ${
                          isFpsUri ? "NavAuth__icon--last-fps" : ""
                        }`}
                      >
                        <IconApollo
                          icon="more_vert"
                          className="NavAuth_icon-img material-icons-outlined NavAuth_icon-img--dropdown"
                          srText={this.props.t(
                            "application_header_more",
                            "More Options in Navigation"
                          )}
                        />
                      </div>
                    }
                    rightItem={true}
                    className="NavAuth__more-dropdown"
                  >
                    <NavLink
                      to={user_link}
                      onClick={this.closeNav}
                      match={this.props.match}
                    >
                      {this.props.t([
                        "application_header_view_my_profile_menuitem",
                        "View My Profile"
                      ])}
                    </NavLink>
                    <NavLink
                      to={`${user_link}/email-settings`}
                      onClick={this.closeNav}
                      match={this.props.match}
                    >
                      {this.props.t([
                        "application_header_email_settings",
                        "E-mail Settings"
                      ])}
                    </NavLink>
                    {!isFpsUri ||
                    (isFpsUri &&
                      !featureFlag.isHideUserGuideAndSupportLinkEnabled()) ? (
                      <React.Fragment>
                        <a
                          href={userGuideUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.t([
                            "application_header_user_guide_menuitem",
                            "User Guide"
                          ])}
                          {openAutomationIcon}
                        </a>
                      </React.Fragment>
                    ) : null}

                    <LanguagePicker />

                    {!isFpsUri && (
                      <Link
                        to={urlWithTenant("/log-out")}
                        onClick={this.closeNav}
                      >
                        {this.props.t([
                          "application_header_log_out_menuitem",
                          "Log Out"
                        ])}
                      </Link>
                    )}
                  </DropDownNavAuth>
                </div>
              </div>
            </NavInnerContainer>
          </NavAuthDynamicResponsiveContainer>
        </NavAuthApolloContainer>
      </nav>
    );
  }
}

const mapStateToProps = ({
  userStatus,
  globalData,
  userProfile,
  userAdmin
}) => ({
  num_notifications: userStatus.num_notifications,
  num_incomplete_tasks: userStatus.num_incomplete_tasks,
  hasUnsavedData: globalData.hasUnsavedData,
  titleTextButtonOptions: globalData.titleTextButtonOptions,
  saveCallback: globalData.saveCallback,
  isLeavingCuration: globalData.isLeavingCuration,
  tenantLogo: userProfile.tenantLogo,
  userAdmin: userAdmin
});

const mapDispatchToProps = dispatch => {
  return {
    refreshUserStatus: () => {
      dispatch(refreshUserStatus());
    },
    clearUnsavedData: () => {
      dispatch(clearUnsavedData());
    },
    setSaveCallback: () => {
      dispatch(setSaveCallback());
    },
    setQuestionnaireCloseRoute: route => {
      dispatch(setQuestionnaireCloseRoute(route));
    },
    clearLeavingCuration: () => {
      dispatch(clearLeavingCuration());
    },
    setTenantLogo: logo => {
      dispatch(setTenantLogo(logo));
    }
  };
};

export default withRouter(
  withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(NavAuth)
  )
);
