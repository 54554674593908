import React from "react";
import InputCustomChildWithLabel from "../InputCustomChildWithLabel/InputCustomChildWithLabel.js";
import RadioButtonPrimary from "../../CheckboxComponents/RadioButtonPrimary/RadioButtonPrimary";

import "./InputRadioButtonsWithLabel.scss";

const generateRadioButtons = ({ options, value, onChange, name }) => {
  return (options || []).map(
    ({ value: radioValue, label, description }, idx) => {
      return (
        <div
          className="InputRadioButtonsWithLabel__radio-option"
          key={`${label}-${idx}`}
        >
          <RadioButtonPrimary
            name={name}
            text={label}
            value={radioValue}
            isChecked={value === radioValue}
            onChange={onChange}
          />
          {description && (
            <p className="InputRadioButtonsWithLabel__radio-description">
              {description}
            </p>
          )}
        </div>
      );
    }
  );
};

const InputRadioButtonsWithLabel = ({
  label,
  required,
  error,
  ...radioButtonProps
}) => {
  return (
    <div className="InputRadioButtonsWithLabel">
      <InputCustomChildWithLabel
        label={label}
        required={required}
        error={error}
      >
        <div className="InputRadioButtonsWithLabel__radio-options">
          {generateRadioButtons(radioButtonProps)}
        </div>
      </InputCustomChildWithLabel>
    </div>
  );
};

export default InputRadioButtonsWithLabel;
