export const NON_WHITESPACE_CHAR_REGEX = /\S/;
export const PROCESS_NAME_REGEX = /[\/\\,+$~%'":*`@^?<>{}]/g;
export const ORCHESTRATOR_AUTOMATION_UUID_REGEX = /[\/\\,+$~%'":*`@^?<>{}]/g;

export const ONLY_WHOLE_NUMBERS_REGEX = /^[1-9][0-9]*$/;
export const CONTAINS_HTML_TAG_REGEX = /<\/?[a-z][\s\S]*>/i;

export const setHttp = link => {
  if (link.search(/^http[s]?\:\/\//) == -1) {
    link = "https://" + link;
  }
  return link;
};
