import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";

export const commentsNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = commentsNotificationActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.COMMENTS,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const commentsNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.NEW_COMMENT_ADDED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 69,
          platform_event_type: COPlatformEventType.PROCESS_COMMENT_ADDED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_COMMENT_ADDED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New question / comment added in AP"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added a question / comment in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ": " +
              COActionReplacementStrings.COMMENT_TITLE +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.NEW_REPLY_ADDED_IN_AP: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.SUBMITTER,
              CORoleType.PROCESS_OWNER,
              CORoleType.PROJECT_MANAGER,
              CORoleType.AP_COLLABORATOR,
              CORoleType.FOLLOWER_IN_AP
            ]
          },
          legacy_notification_id: 71,
          platform_event_type: COPlatformEventType.PROCESS_COMMENT_REPLY_ADDED,
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.NEW_REPLY_ADDED_IN_AP,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New reply to an existing comment"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " added a reply in the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              ": " +
              COActionReplacementStrings.COMMENT_TITLE +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
