import {
  COComponentType,
  CO_VALUE_DELIMITER,
  COConditionSlugs
} from "../constants/co-constants";
import { questionTemplateForSlug } from "../templates/co-questions.template";
import { isNullOrUndefined } from "../utils/co-utils";
import { updatePositionSortOrderForItems } from "./co-position.helper";
import { resolve } from "../utils/co-resolver.utils";
import { toNumber } from "./co-validation.helper";
import { COBase } from "../classes/co-base.class";
import { calculate } from "./co-calculation.helper";
import { isString, rawStringFromPotentiallyDraftJS } from "../utils/co-utils";
import {
  COAssessmentInterface,
  COContextInterface,
  COOptionsInterface,
  COProcessAnswerInterface,
  COQuestionAnswerOptionInterface,
  COQuestionAnswerOptionTemplateInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { answerOptionTemplatesFromQuestionTemplate } from "./co-question-template.helper";
import { COQuestionAnswerOption } from "../classes/co-question-answer-option.class";
import {
  newProcessAnswerWithoutImporting,
  newQuestionAnswerOptionWithoutImporting
} from "./co-circular-refrence-helper";
import { removeAnswerOptionBranchConditions } from "./co-question-answer-option.helper";
import { updateContext } from "../classes/co-context.class";
import { PATH } from "../constants/co-path.constants";
import { getValue } from "../utils/co-path.utils";

export const answerQuestion = ({
  context,
  question,
  questionAnswerOption
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionAnswerOption: COQuestionAnswerOptionInterface;
}): COQuestionInterface => {
  // so when we load an assessment for submission - we create answer options at the start for all of the questions - this lets the rendering work and we can handle defaults
  // you should have done COAssessmentInterface.prepForSubmissionViewOrEdit(assessment) before - so that all questions have answers
  // but if you haven't

  if (!question.co_process_answer) {
    question.co_process_answer = newProcessAnswerWithoutImporting({
      context,
      question
    });
  }

  let process_answer: COProcessAnswerInterface = question.co_process_answer;
  if (process_answer.selectOrUpdateOption) {
    process_answer.selectOrUpdateOption({
      context,
      question,
      answerOption: questionAnswerOption
    });
  }

  return question;
};

// deselect answers
export const deselectQuestionAnswer = ({
  context,
  question,
  questionAnswerOption
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionAnswerOption: COQuestionAnswerOptionInterface;
}): COQuestionInterface => {
  if (question.co_process_answer) {
    let process_answer: COProcessAnswerInterface = question.co_process_answer;
    process_answer.deSelectOption?.({
      context,
      answerOption: questionAnswerOption
    });
  }
  return question;
};

export const isAnswerOptionSelected = ({
  question,
  answerOption
}: {
  question: COQuestionInterface;
  answerOption: COQuestionAnswerOptionInterface;
}): boolean => {
  return !isNullOrUndefined(
    (question.co_process_answer?.co_process_answer_selections || []).find(
      pas =>
        pas.co_question_answer_option_ahid ===
        answerOption.co_question_answer_option_ahid
    )
  );
};

export const selectedAnswerOptionOfComponentType = ({
  question,
  component_type
}: {
  question: COQuestionInterface;
  component_type: COComponentType;
}): COQuestionAnswerOptionInterface | undefined => {
  return question.co_question_answer_options?.find(
    answer_option =>
      answer_option.options?.component_type === component_type &&
      question.co_process_answer?.co_process_answer_selections?.some(
        cpas =>
          cpas.co_question_answer_option_ahid ===
          answer_option.co_question_answer_option_ahid
      )
  );
};

export const canAddAnswerOption = ({
  context,
  question,
  questionAnswerOptionTemplate
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionAnswerOptionTemplate?: COQuestionAnswerOptionTemplateInterface;
}): boolean => {
  // may but in more checks
  let resolvedOptions: COOptionsInterface = resolve({
    context,
    item: question.options || {}
  });
  if (resolvedOptions.is_read_only === 1) {
    // if the question is read-only, user can't add options
    return false;
  }
  if (!isNullOrUndefined(resolvedOptions.max_question_answer_options)) {
    let maxOptions = 0;
    maxOptions = toNumber(resolvedOptions.max_question_answer_options);
    if ((question.co_question_answer_options || []).length < maxOptions) {
      return true;
    }
  }
  return false;
};

export const canRemoveAnswerOption = ({
  context,
  question,
  questionAnswerOption
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionAnswerOption: COQuestionAnswerOptionInterface;
}): boolean => {
  let resolvedOptions: COOptionsInterface = resolve({
    context,
    item: question.options || {}
  });

  if (questionAnswerOption.options?.prevent_deleting === 1) {
    return false;
  }

  if (!isNullOrUndefined(resolvedOptions.min_question_answer_options)) {
    let min = 0;
    min = toNumber(resolvedOptions.min_question_answer_options);
    if ((question.co_question_answer_options || []).length > min) {
      return true;
    }
  }
  return false;
};

export const answerOptionTemplates = ({
  context,
  question
}: {
  context: COContextInterface;
  question: COQuestionInterface;
}): COQuestionAnswerOptionTemplateInterface[] => {
  let resolvedOptions: COOptionsInterface = resolve({
    context,
    item: question.options || {}
  });
  if (!isNullOrUndefined(resolvedOptions.max_question_answer_options)) {
    let maxOptions = 0;
    maxOptions = toNumber(resolvedOptions.max_question_answer_options);
    if ((question.co_question_answer_options || []).length >= maxOptions) {
      return [];
    }
  }
  // first get the question template
  const questionTemplate = questionTemplateForSlug(
    question.co_question_template_slug
  );
  if (!questionTemplate) {
    throw new Error(
      `Unable to load question template for question ${question.co_question_template_slug}`
    );
  }
  let answerOptions = answerOptionTemplatesFromQuestionTemplate({
    questionTemplate
  });

  return answerOptions;
};

export const addAnswerOption = ({
  context,
  question,
  questionAnswerOption,
  questionAnswerOptionTemplate,
  questionAnswerOptionRaw
}: {
  context: COContextInterface;
  question: COQuestionInterface;
  questionAnswerOption?: COQuestionAnswerOptionInterface;
  questionAnswerOptionTemplate?: COQuestionAnswerOptionTemplateInterface;
  questionAnswerOptionRaw?: any;
}): COQuestionAnswerOptionInterface => {
  if (
    !questionAnswerOption &&
    !questionAnswerOptionRaw &&
    !questionAnswerOptionTemplate
  ) {
    throw new Error(
      "Add Question Option Requires a QuestionAnswerOption Object or a Template"
    );
  }

  if (!question.co_question_answer_options) {
    question.co_question_answer_options = [];
  }

  if (!questionAnswerOption) {
    questionAnswerOption = newQuestionAnswerOptionWithoutImporting({
      context: context,
      question: question,
      template: questionAnswerOptionTemplate,
      raw: questionAnswerOptionRaw
    });
  } else {
    questionAnswerOption.updateQuestion?.(question);
  }

  question.co_question_answer_options?.push(questionAnswerOption);
  updatePositionSortOrderForItems(question.co_question_answer_options);
  return questionAnswerOption;
};

export const removeAnswerOption = (
  context: COContextInterface,
  question: COQuestionInterface,
  questionAnswerOption: COQuestionAnswerOptionInterface
) => {
  if (question.co_question_answer_options) {
    question.co_question_answer_options = question.co_question_answer_options?.filter(
      existinSection =>
        existinSection.co_question_answer_option_ahid !==
        questionAnswerOption.co_question_answer_option_ahid
    );
    updatePositionSortOrderForItems(question.co_question_answer_options);
  }
  const answerOptionContext = updateContext(context, {
    question: question,
    answer_option: questionAnswerOption
  });
  questionAnswerOption.onRemoveFromAssessment?.(answerOptionContext);
};

export const splitDelimittedValues = (value?: string): string[] => {
  if (isString(value)) {
    return value?.split(CO_VALUE_DELIMITER)?.filter(Boolean) || [];
  }
  return [value + ""];
};

export const isQuestionAnswered = ({
  context,
  question
}: {
  context: COContextInterface;
  question?: COQuestionInterface;
}): boolean => {
  // I think using "this" here can break things because it might not be a class object
  let currentQuestion: COQuestionInterface | undefined =
    question ?? context.question;
  if (!currentQuestion || !currentQuestion.options) {
    return false;
  }

  if (currentQuestion.options.external_answer_source) {
    let value = getValue(
      context,
      PATH({
        route: currentQuestion.options.external_answer_source
      })
    );
    if (!isNullOrUndefined(value)) {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return true;
    }
    return false;
  }

  if (!currentQuestion.co_process_answer) {
    return false;
  }
  if (
    currentQuestion.co_process_answer.co_process_answer_selections?.length == 0
  ) {
    return false;
  }
  let selectedAnswerOption =
    currentQuestion?.co_process_answer?.co_process_answer_selections?.[0];
  if (
    selectedAnswerOption?.co_question_answer_option?.calculation_options
      ?.input_is_value
  ) {
    // if this isn't input_is_value then it's a selection question and thus answered
    let input_value = selectedAnswerOption.co_process_answer_selection_input;
    if (
      isNullOrUndefined(input_value) ||
      input_value == "" ||
      rawStringFromPotentiallyDraftJS(input_value || "") == ""
    ) {
      return false;
    }
  }
  return true;
};
