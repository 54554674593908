export enum COTenantSettingsKeys {
  EMAIL_REMINDERS = "email-reminders",
  NUMBER_OF_EMAIL_REMINDERS = "number-of-email-reminders",
  EMAIL_REMINDER_INTERVAL = "email-reminder-interval"
}

export enum COEmailReminderIntervalOptionSlugs {
  ONE_DAY = "email-reminder-interval-one-day",
  THREE_DAYS = "email-reminder-interval-three-days",
  FIVE_DAYS = "email-reminder-interval-five-days",
  SEVEN_DAYS = "email-reminder-interval-seven-days",
  TEN_DAYS = "email-reminder-interval-ten-days",
  FIFTEEN_DAYS = "email-reminder-interval-fifteen-days",
  TWENTY_DAYS = "email-reminder-interval-twenty-days",
  TWENTY_FIVE_DAYS = "email-reminder-interval-twenty-five-days",
  THIRTY_DAYS = "email-reminder-interval-thirty-days"
}

export enum CONumberOfEmailReminderOptionSlugs {
  ONE_REMINDER = "one-email-reminder",
  TWO_REMINDERS = "two-email-reminders",
  THREE_REMINDERS = "three-email-reminders",
  FOUR_REMINDERS = "four-email-reminders",
  FIVE_REMINDERS = "five-email-reminders"
}
