import React, { Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import SessionHelper from "./helpers/SessionHelper";
import { module_go_id, module_navigator_id } from "./constants/moduleConstants";
import ModuleHelper from "./helpers/ModuleHelper";
import urlWithTenant from "./utils/urlHelper";
import { featureFlag } from "./helpers/FeatureFlag";
import TenantSettingHelper from "./helpers/TenantSettingsHelper";
import LoaderLogoSmall from "./components/LoaderLogoSmall/LoaderLogoSmall";
import PageLazyLoader from "./components/PageLazyLoader/PageLazyLoader";
import Loadable from "react-loadable";
import {
  DASHBOARD_PERMISSION,
  MANAGE_APP_INVENTORY_PERMISSION,
  MANAGE_PEOPLE_PERMISSION,
  MANAGE_ROLES_PERMISSION,
  MANAGE_COMPONENTS_PERMISSION,
  MANAGE_CATEGORIES_PERMISSION,
  MANAGE_TEMPLATES_PERMISSION,
  MANAGE_COST_SETUP_PERMISSION,
  TENANT_SETTINGS_PERMISSION,
  CUSTOMIZE_ASSESSMENT_PERMISSION,
  MANAGE_OPEN_API_PERMISSION,
  ADMIN_AUDIT_LOGS_PERMISSION,
  VIEW_REPORTS_PERMISSION,
  EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION,
  TOP_DOWN_PERMISSION_PERMISSION,
  SUBMIT_AUTOMATION_PERMISSION,
  AUTOMATION_PIPELINE_TAB_PERMISSIONS,
  SUBMIT_COMPONENT_PERMISSION,
  IMPORT_PIPELINE_PERMISSION,
  ACCESS_ADMIN_CONSOLE_PERMISSIONS,
  ORCHESTRATOR_MAPPING_PERMISSION,
  INSIGHTS_CONNECTION_PERMISSION,
  MANAGE_INTEGRATIONS_PERMISSION
} from "./constants/adminPermissionsConstants";

// Import all layouts
import MainLayout from "./layouts/mainLayout";
import AuthLayout from "./layouts/authLayout";
import AdminLayout from "./layouts/adminLayout";
import MasterLayoutWrapper from "./layouts/masterLayoutWrapper";
import AuthRedirectLayout from "./layouts/AuthRedirectLayout";

// Import core pages
// These pages are shipped with core bundle and should be limited
import LoginPage from "./pages/LoginPage/LoginPage.js";
import LogOut from "./components/LogOut/LogOut";
import LoginRedirectPage from "./components/Auth/LoginRedirectPage";
import LogoutRedirectPage from "./components/Auth/LogoutRedirectPage";
import FpsLoginRedirectPage from "./pages/FpsLoginRedirectPage/FpsLoginRedirectPage";
import FpsBackgroundRefreshPage from "./pages/FpsBackgroundRefreshPage/FpsBackgroundRefreshPage";
import {
  AUTOMATION_HUB_SERVICE,
  AUTOMATION_STORE_SERVICE,
  CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE
} from "./constants/urlConstants";
import {
  EXPLORE_AUTOMATIONS_PROGRAM_ROUTE,
  EXPLORE_AUTOMATIONS_ROUTE,
  EXPLORE_IDEAS_ROUTE
} from "./constants/routesConstants";
import { COProcessSubmissionType } from "@uipath/ah-co";
import { SUBMIT_EMPLOYEE_IDEA_SUCCESS_EVENT_NAME } from "./utils/analyticsUtils";

// Lazy import pages for code splitting
const AutomationProfilePage = ReactLazyLoad(() =>
  import("./pages/AutomationProfilePage/AutomationProfilePage")
);
const ExploreAutomationsPage = ReactLazyLoad(() =>
  import("./pages/ExploreAutomationsPage/ExploreAutomationsPage")
);
const ExplorePeoplePage = ReactLazyLoad(() =>
  import("./pages/ExplorePeoplePage/ExplorePeoplePage")
);
const ExploreComponentsPage = ReactLazyLoad(() =>
  import("./pages/ExploreComponentsPage/ExploreComponentsPage")
);
const AutomationProfileEditPage = ReactLazyLoad(() =>
  import("./pages/AutomationProfileEditPage/AutomationProfileEditPage")
);

const UserProfilePage = ReactLazyLoad(() =>
  import("./pages/UserProfilePage/UserProfilePage")
);
const EditUserProfilePage = ReactLazyLoad(() =>
  import("./pages/UserProfilePage/EditUserProfilePage")
);
const ComponentProfilePage = ReactLazyLoad(() =>
  import("./pages/ComponentProfilePage/ComponentProfilePage")
);
const ComponentReleaseHistoryPage = ReactLazyLoad(() =>
  import("./pages/ComponentReleaseHistoryPage/ComponentReleaseHistoryPage")
);
const UploadEditComponentPage = ReactLazyLoad(() =>
  import("./pages/UploadEditComponentPage/UploadEditComponentPage")
);
const MySubmissionsPage = ReactLazyLoad(() =>
  import("./pages/MySubmissionsPage/MySubmissionsPage")
);
const MyCollaborationsPage = ReactLazyLoad(() =>
  import("./pages/MyCollaborationsPage/MyCollaborationsPage")
);
const MyComponentsPage = ReactLazyLoad(() =>
  import("./pages/MyComponentsPage/MyComponentsPage")
);
const AutomationPipelinePage = ReactLazyLoad(() =>
  import("./pages/AutomationPipelinePage/AutomationPipelinePage")
);
const MyTasksPage = ReactLazyLoad(() =>
  import("./pages/MyTasksPage/MyTasksPage")
);
const MyFollowingPage = ReactLazyLoad(() =>
  import("./pages/MyFollowingPage/MyFollowingPage")
);
const NotificationsPage = ReactLazyLoad(() =>
  import("./pages/NotificationsPage/NotificationsPage")
);
const CookiePolicyPage = ReactLazyLoad(() =>
  import("./pages/CookiePolicyPage/CookiePolicyPage")
);
const AdminAccountPage = ReactLazyLoad(() =>
  import("./pages/AdminAccountPage/AdminAccountPage")
);
const ManageComponentsPage = ReactLazyLoad(() =>
  import("./pages/ManageComponentsPage/ManageComponentsPage")
);
const AdminManageCategoriesPage = ReactLazyLoad(() =>
  import("./pages/AdminManageCategoriesPage/AdminManageCategoriesPage")
);
// const AdminPlatformAssessmentPage = ReactLazyLoad(() =>
//   import("./pages/AdminPlatformAssessmentPage/AdminPlatformAssessmentPage")
// );
const AdminCustomizeAssessmentsOverviewPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCustomizeAssessmentsOverviewPage/AdminCustomizeAssessmentsOverviewPage"
  )
);
const AdminDocumentationTemplatesPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminDocumentationTemplatesPage/AdminDocumentationTemplatesPage"
  )
);
const AdminManageApplicationsPage = ReactLazyLoad(() =>
  import("./pages/AdminManageApplicationsPage/AdminManageApplicationsPage")
);
const AdminCostSetupPage = ReactLazyLoad(() =>
  import("./pages/AdminCostSetupPage/AdminCostSetupPage")
);
const AdminCostSetupRPASoftwareVendorPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupRPASoftwareVendorPage/AdminCostSetupRPASoftwareVendorPage"
  )
);
const AdminCostSetupRPASoftwareVendorEditPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupRPASoftwareVendorEditPage/AdminCostSetupRPASoftwareVendorEditPage"
  )
);
const AdminCostSetupOneTimeCostsPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupOneTimeCostsPage/AdminCostSetupOneTimeCostsPage"
  )
);
const AdminCostSetupOneTimeCostsEditPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupOneTimeCostsEditPage/AdminCostSetupOneTimeCostsEditPage"
  )
);
const AdminCostSetupRunningCostsPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupRunningCostsPage/AdminCostSetupRunningCostsPage"
  )
);
const AdminCostSetupRunningCostsEditPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupRunningCostsEditPage/AdminCostSetupRunningCostsEditPage"
  )
);
const AdminCostSetupOtherRunningCostsPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupOtherRunningCostsPage/AdminCostSetupOtherRunningCostsPage"
  )
);
const AdminCostSetupOtherRunningCostsEditPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCostSetupPage/AdminCostSetupOtherRunningCostsEditPage/AdminCostSetupOtherRunningCostsEditPage"
  )
);
const ProjectPlanLiveAutomationsPage = ReactLazyLoad(() =>
  import(
    "./pages/ProjectPlanLiveAutomationsPage/ProjectPlanLiveAutomationsPage"
  )
);
const CostReportLiveAutomationsPage = ReactLazyLoad(() =>
  import("./pages/CostReportLiveAutomationsPage/CostReportLiveAutomationsPage")
);
const LeaderboardPage = ReactLazyLoad(() =>
  import("./pages/LeaderboardPage/LeaderboardPage")
);
const NotFound = ReactLazyLoad(() =>
  import("./components/ErrorGraphics/NotFound")
);
const LandingPage = ReactLazyLoad(() =>
  import("./pages/LandingPage/LandingPage")
);
const AutomationPerformanceDashboardPage = ReactLazyLoad(() =>
  import(
    "./pages/AutomationPerformanceDashboard/AutomationPerformanceDashboardPage"
  )
);
const AutomationCostDashboardPage = ReactLazyLoad(() =>
  import("./pages/AutomationCostDashboardPage/AutomationCostDashboardPage")
);
const SignupPage = ReactLazyLoad(() => import("./pages/SignupPage/SignupPage"));
const LaunchTaskCapturePage = ReactLazyLoad(() =>
  import("./pages/LaunchTaskCapturePage/LaunchTaskCapturePage")
);
const EmailSettingsPage = ReactLazyLoad(() =>
  import("./pages/EmailSettingsPage/EmailSettingsPage")
);
const AdminOpenApiTablePage = ReactLazyLoad(() =>
  import("./pages/AdminOpenApiTablePage/AdminOpenApiTablePage")
);
const AdminOrchestratorConnectionPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminOrchestratorConnectionPage/AdminOrchestratorConnectionPage"
  )
);
const FpsLandingPage = ReactLazyLoad(() =>
  import("./pages/FpsLandingPage/FpsLandingPage")
);
const TenantSettingsPage = ReactLazyLoad(() =>
  import("./pages/TenantSettingsPage/TenantSettingsPage.tsx")
);
const AutomationStorePage = ReactLazyLoad(() =>
  import("./pages/AutomationStorePage/AutomationStorePage.tsx")
);
const AutomationStoreLandingPage = ReactLazyLoad(() =>
  import("./pages/AutomationStoreLandingPage/AutomationStoreLandingPage.tsx")
);
const AutomationStoreLoginRedirectPage = ReactLazyLoad(() =>
  import(
    "./pages/AutomationStoreLoginRedirectPage/AutomationStoreLoginRedirectPage.tsx"
  )
);
const AutomationStoreProfilePage = ReactLazyLoad(() =>
  import("./pages/AutomationStoreProfilePage/AutomationStoreProfilePage.tsx")
);
const AdminManageUserRolesPermissionsPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminManageUserRolesPermissionsPage/AdminManageUserRolesPermissionsPage"
  )
);
const AdminManageUsersPage = ReactLazyLoad(() =>
  import("./pages/AdminManageUsersPage/AdminManageUsersPage")
);
const AdminManageGroupsPage = ReactLazyLoad(() =>
  import("./pages/AdminManageGroupsPage/AdminManageGroupsPage")
);
const AdminAuditLogsPage = ReactLazyLoad(() =>
  import("./pages/AdminAuditLogsPage/AdminAuditLogsPage")
);

// const SubmitAutomationPage = ReactLazyLoad(() =>
//   import("./pages/SubmitAutomationPage/SubmitAutomationPage")
// );

const DebugPage = ReactLazyLoad(() => import("./pages/DebugPage/DebugPage"));
const ProcessSubmitSuccessPage = ReactLazyLoad(() =>
  import("./pages/ProcessSubmitSuccessPage/ProcessSubmitSuccessPage.tsx")
);
const ImportPipelinePage = ReactLazyLoad(() =>
  import("./pages/ImportPipelinePage/ImportPipelinePage.tsx")
);
// const SubmitEmployeeDrivenIdeaPage = ReactLazyLoad(() =>
//   import(
//     "./pages/SubmitEmployeeDrivenIdeaPage/SubmitEmployeeDrivenIdeaPage.tsx"
//   )
// );
const EmployeeDrivenIdeaSuccessPage = ReactLazyLoad(() =>
  import(
    "./pages/EmployeeDrivenIdeaSuccessPage/EmployeeDrivenIdeaSuccessPage.tsx"
  )
);
// const AdminCustomizeHighLevelAssessmentPage = ReactLazyLoad(() =>
//   import(
//     "./pages/AdminCustomizeHighLevelAssessmentPage/AdminCustomizeHighLevelAssessmentPage.tsx"
//   )
// );
const AdminCustomizeDetailedAssessmentPage = ReactLazyLoad(() =>
  import(
    "./pages/AdminCustomizeDetailedAssessmentPage/AdminCustomizeDetailedAssessmentPage.tsx"
  )
);
const LaunchAssistantPage = ReactLazyLoad(() =>
  import("./pages/LaunchAssistantPage/LaunchAssistantPage.tsx")
);

const LaunchStudioPage = ReactLazyLoad(() =>
  import("./pages/LaunchStudioPage/LaunchStudioPage.tsx")
);

const InsightsConnectionPage = ReactLazyLoad(() =>
  import("./pages/AdminInsightsConnectionPage/AdminInsightsConnectionPage.tsx")
);

const AdminJiraMappingPage = ReactLazyLoad(() =>
  import("./pages/AdminJiraMappingPage/AdminJiraMappingPage.tsx")
);

const OrchestratorMappingPage = ReactLazyLoad(() =>
  import("./pages/OrchestratorMappingPage/OrchestratorMappingPage.tsx")
);

const COSubmitProcessPage = ReactLazyLoad(() =>
  import("./pages/AutomationProfileCO/AutomationProfileCOCreatePage.tsx")
);

const UnauthorizedRoutePage = ReactLazyLoad(() =>
  import("./pages/InvalidPermissionPage/InvalidPermissionPage.tsx")
);

function ReactLazyLoad(importStatement) {
  return Loadable({
    loader: importStatement,
    loading: PageLazyLoader
  });
}

const AUTOMATION_HUB_SERVICE_FPS_ROUTE_PREFIX = `/:cloud_account/:cloud_tenant/${AUTOMATION_HUB_SERVICE}`;
const AUTOMATION_STORE_SERVICE_FPS_ROUTE_PREFIX = `/:cloud_account/:cloud_tenant/${AUTOMATION_STORE_SERVICE}`;
const STAND_ALONE_TENANT_ROUTE_PREFIX = "/:tenant";

const setRedirectPathAndRedirectToLogin = matchProps => {
  let path = urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE);
  let pathWithoutTenantInfo = EXPLORE_AUTOMATIONS_PROGRAM_ROUTE;
  const pathParts = (matchProps?.location?.pathname || "").split("/");

  if (SessionHelper.currentFpsService() === AUTOMATION_STORE_SERVICE) {
    path = urlWithTenant(
      "/explore-published-automations",
      AUTOMATION_STORE_SERVICE
    );
  }

  if (matchProps?.location?.pathname) {
    path = matchProps.location.pathname;
    path += matchProps.location.search || "";
    path += matchProps.location.hash || "";
  }

  // We need pathWithoutTenantInfo in case the redirect is from a standalone tenant to an fps tenant
  // need to remember the path they tried to hit to preform the proper redirect
  if (
    SessionHelper.isFpsUri() &&
    matchProps?.location?.pathname &&
    pathParts.length > 4
  ) {
    pathParts.shift(); // removes first empty element
    pathParts.shift(); // removes the tenant name
    pathParts.shift(); // removes the org name
    pathParts.shift(); // removes the service name
    pathWithoutTenantInfo = "/" + pathParts.join("/");
    pathWithoutTenantInfo += matchProps.location.search || "";
    pathWithoutTenantInfo += matchProps.location.hash || "";
  } else if (matchProps?.location?.pathname && pathParts.length > 2) {
    pathParts.shift(); // removes first empty element
    pathParts.shift(); // removes the tenant name
    pathWithoutTenantInfo = "/" + pathParts.join("/");
    pathWithoutTenantInfo += matchProps.location.search || "";
    pathWithoutTenantInfo += matchProps.location.hash || "";
  }

  const pathWithTimestamp = {
    path,
    timestamp: Date.now(),
    pathWithoutTenantInfo
  };

  SessionHelper.setDataForKey(pathWithTimestamp, "pathWithTimestamp");

  return (
    <Redirect
      to={{
        pathname: urlWithTenant("/", SessionHelper.currentFpsService()),
        state: { from: matchProps.location }
      }}
    />
  );
};

const adminPermissionsAllowed = permissions => {
  let user = SessionHelper.currentUser();
  if (!permissions || permissions.length === 0) {
    //allow if we don't pass any permissions
    return true;
  }

  if (user && user.ah_permissions && permissions && permissions.length > 0) {
    let hasPermission = false;
    permissions.forEach(perm => {
      if (user.ah_permissions[perm] && user.ah_permissions[perm] > 0) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  return false;
};

const modulePermissionsAllowed = permissions => {
  if (!permissions || permissions.length === 0) {
    //allow if we don't pass any permissions
    return true;
  }

  if (permissions && permissions.length > 0) {
    let hasPermission = false;
    permissions.forEach(perm => {
      if (ModuleHelper.isModuleEnabled(perm)) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  return false;
};

// Generates tenant specific routes for both classic AH, FPS AH and FPS Automation store
// All the FPS paths need to start with /:cloud_account/:cloud_tenant/
// Automation Hub FPS routes start with /:cloud_account/:cloud_tenant/automationhub_
// Automation Store FPS routes start with /:cloud_account/:cloud_tenant/automationstore_
// services param states which services should be able to access this route
export const tenantPaths = (path, services = [AUTOMATION_HUB_SERVICE]) => {
  if (services.includes(AUTOMATION_HUB_SERVICE)) {
    return SessionHelper.isFpsUri()
      ? AUTOMATION_HUB_SERVICE_FPS_ROUTE_PREFIX + path
      : STAND_ALONE_TENANT_ROUTE_PREFIX + path;
  } else {
    return AUTOMATION_STORE_SERVICE_FPS_ROUTE_PREFIX + path;
  }
};

const DefaultRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <MasterLayoutWrapper>
          <Suspense fallback={<LoaderLogoSmall fullPage />}>
            <Component {...matchProps} />
          </Suspense>
        </MasterLayoutWrapper>
      )}
    />
  );
};

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <MainLayout>
          <Suspense fallback={<LoaderLogoSmall fullPage />}>
            <Component {...matchProps} />
          </Suspense>
        </MainLayout>
      )}
    />
  );
};

const AuthRedirectRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthRedirectLayout>
          <Suspense fallback={<LoaderLogoSmall fullPage />}>
            <Component {...matchProps} />
          </Suspense>
        </AuthRedirectLayout>
      )}
    />
  );
};

const AdminRoute = ({
  component: Component,
  ah_permissions: permissions,
  moduleIds: modPermissions,
  ...rest
}) => {
  if (!SessionHelper.isLoggedIn()) {
    return (
      <Route
        {...rest}
        render={matchProps => setRedirectPathAndRedirectToLogin(matchProps)}
      />
    );
  }

  if (
    !adminPermissionsAllowed(permissions) ||
    !modulePermissionsAllowed(modPermissions)
  ) {
    return (
      <Redirect
        to={{
          pathname: urlWithTenant("/invalid-permissions")
        }}
      />
    );
  }

  // Redirect to account page if license is expired
  const jwt = SessionHelper.getDataFromUserToken() || {};
  if (
    jwt.t_license_expired &&
    rest &&
    rest.location &&
    rest.location.pathname &&
    rest.location.pathname !== urlWithTenant("/admin/account")
  ) {
    return (
      <Redirect
        to={{
          pathname: urlWithTenant("/admin/account")
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <AdminLayout>
          <Suspense fallback={<LoaderLogoSmall fullPage />}>
            <Component {...matchProps} />
          </Suspense>
        </AdminLayout>
      )}
    />
  );
};

const AuthRoute = ({
  component: Component,
  ah_permissions: permissions,
  moduleIds: modPermissions,
  isAutomationStoreNav,
  ...rest
}) => {
  if (!SessionHelper.isLoggedIn()) {
    return (
      <Route
        {...rest}
        render={matchProps => setRedirectPathAndRedirectToLogin(matchProps)}
      />
    );
  }

  if (
    !adminPermissionsAllowed(permissions) ||
    !modulePermissionsAllowed(modPermissions)
  ) {
    return (
      <Redirect
        to={{
          pathname: urlWithTenant("/invalid-permissions")
        }}
      />
    );
  }

  // Redirect to account page if license is expired
  const jwt = SessionHelper.getDataFromUserToken() || {};
  if (jwt.t_license_expired) {
    return (
      <Redirect
        to={{
          pathname: urlWithTenant("/admin/account")
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthLayout isAutomationStoreNav={isAutomationStoreNav}>
          <Suspense fallback={<LoaderLogoSmall fullPage />}>
            <Component {...matchProps} />
          </Suspense>
        </AuthLayout>
      )}
    />
  );
};

const GeneralInfoRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        SessionHelper.isLoggedIn() ? (
          <AuthLayout>
            <Suspense fallback={<LoaderLogoSmall fullPage />}>
              <Component {...matchProps} />
            </Suspense>
          </AuthLayout>
        ) : (
          <MainLayout>
            <Suspense fallback={<LoaderLogoSmall fullPage />}>
              <Component {...matchProps} />
            </Suspense>
          </MainLayout>
        )
      }
    />
  );
};

const Routes = ({ history }) => {
  //we specifically need to disable the /login page (without a tenant)

  const showLeaderboard = TenantSettingHelper.showLeaderboard();
  const showComponents = TenantSettingHelper.showComponents();
  const showTaskCapture = TenantSettingHelper.showTaskCapture();
  const showUserProfile = TenantSettingHelper.isExploreUsersAndUserProfileEnabled();

  return (
    <Switch>
      <GeneralInfoRoute exact path="/404" component={NotFound} />
      <GeneralInfoRoute
        exact
        path={tenantPaths("/cookie-policy")}
        component={CookiePolicyPage}
      />
      <DefaultRoute exact path="/" component={LandingPage} />
      <DefaultRoute exact path="/login" component={NotFound} />
      <DefaultRoute exact path="/signup" component={SignupPage} />
      <MainRoute
        exact
        path={`${AUTOMATION_HUB_SERVICE_FPS_ROUTE_PREFIX}/login-redirect`}
        component={FpsLoginRedirectPage}
      />
      <MainRoute
        exact
        path={`${AUTOMATION_HUB_SERVICE_FPS_ROUTE_PREFIX}/backgroundRefresh`}
        component={FpsBackgroundRefreshPage}
      />
      <AuthRedirectRoute
        exact
        path={`/login-redirect`}
        component={LoginRedirectPage}
      />
      <AuthRedirectRoute
        exact
        path={"/logout-redirect"}
        component={LogoutRedirectPage}
      />
      <MainRoute
        exact
        path={STAND_ALONE_TENANT_ROUTE_PREFIX}
        component={LoginPage}
      />
      <MainRoute
        exact
        path={AUTOMATION_HUB_SERVICE_FPS_ROUTE_PREFIX}
        component={FpsLandingPage}
      />
      <MainRoute
        exact
        path={AUTOMATION_STORE_SERVICE_FPS_ROUTE_PREFIX}
        component={AutomationStoreLandingPage}
      />
      <MainRoute
        exact
        path={`${AUTOMATION_STORE_SERVICE_FPS_ROUTE_PREFIX}/login-redirect`}
        component={AutomationStoreLoginRedirectPage}
      />
      <DefaultRoute exact path={tenantPaths("/debug")} component={DebugPage} />
      <AuthRedirectRoute
        exact
        path={tenantPaths("/log-out")}
        component={LogOut}
      />
      <MainRoute exact path={tenantPaths("/login")} component={LoginPage} />
      <AuthRoute
        exact
        path={tenantPaths("/invalid-permissions")}
        component={UnauthorizedRoutePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug/cba")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug/cba/edit")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug/documentation")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug/collaborators")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug/components")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:slug/change-requests")}
        component={AutomationProfilePage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/automation-profile/:id/edit")}
        component={AutomationProfileEditPage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute exact path={tenantPaths(EXPLORE_IDEAS_ROUTE)}>
        <Redirect to={urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE)} />
      </AuthRoute>
      <AuthRoute exact path={tenantPaths(EXPLORE_AUTOMATIONS_ROUTE)}>
        <Redirect to={urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE)} />
      </AuthRoute>
      <AuthRoute
        exact
        path={tenantPaths(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE)}
        component={ExploreAutomationsPage}
        moduleIds={[module_navigator_id]}
      />
      {showLeaderboard && (
        <AuthRoute
          exact
          path={tenantPaths("/leaderboard")}
          component={LeaderboardPage}
          moduleIds={[module_navigator_id]}
        />
      )}
      {showUserProfile && (
        <AuthRoute
          exact
          path={tenantPaths("/explore-people")}
          component={ExplorePeoplePage}
        />
      )}
      {showComponents && (
        <AuthRoute
          exact
          path={tenantPaths("/explore-components")}
          component={ExploreComponentsPage}
          moduleIds={[module_go_id]}
        />
      )}
      <AuthRoute
        exact
        path={tenantPaths("/dashboard/performance")}
        component={AutomationPerformanceDashboardPage}
        ah_permissions={[DASHBOARD_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/dashboard/cost")}
        component={AutomationCostDashboardPage}
        ah_permissions={[DASHBOARD_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/performance/planning-live-automations")}
        component={ProjectPlanLiveAutomationsPage}
        ah_permissions={[VIEW_REPORTS_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/performance/cost-report")}
        component={CostReportLiveAutomationsPage}
        ah_permissions={[VIEW_REPORTS_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      {showComponents && (
        <AuthRoute
          exact
          path={tenantPaths("/component/:slug")}
          component={ComponentProfilePage}
          moduleIds={[module_go_id]}
        />
      )}
      {showComponents && (
        <AuthRoute
          exact
          path={tenantPaths("/component/:slug/version-history")}
          component={ComponentReleaseHistoryPage}
          moduleIds={[module_go_id]}
        />
      )}
      {showComponents && (
        <AuthRoute
          exact
          path={tenantPaths("/upload-component")}
          component={UploadEditComponentPage}
          moduleIds={[module_go_id]}
        />
      )}
      {showComponents && (
        <AuthRoute
          exact
          path={tenantPaths("/component/:slug/edit")}
          component={UploadEditComponentPage}
          moduleIds={[module_go_id]}
        />
      )}
      <AuthRoute
        exact
        path={tenantPaths("/user/:slug/email-settings")}
        component={EmailSettingsPage}
      />
      <AuthRoute
        path={tenantPaths("/user/:slug")}
        component={UserProfilePage}
      />
      <AuthRoute
        path={tenantPaths("/edit-user/:slug")}
        component={EditUserProfilePage}
      />
      <AuthRoute
        exact
        path={tenantPaths("/my-submissions")}
        component={MySubmissionsPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[
          TOP_DOWN_PERMISSION_PERMISSION,
          EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION,
          SUBMIT_AUTOMATION_PERMISSION
        ]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/my-collaborations")}
        component={MyCollaborationsPage}
        moduleIds={[module_navigator_id]}
      />
      {showComponents && (
        <AuthRoute
          exact
          path={tenantPaths("/my-components")}
          component={MyComponentsPage}
          moduleIds={[module_go_id]}
          ah_permissions={[SUBMIT_COMPONENT_PERMISSION]}
        />
      )}
      <AuthRoute
        exact
        path={tenantPaths("/automation-pipeline")}
        component={AutomationPipelinePage}
        moduleIds={[module_navigator_id]}
        ah_permissions={AUTOMATION_PIPELINE_TAB_PERMISSIONS}
      />
      <AuthRoute
        exact
        path={tenantPaths("/my-tasks")}
        component={MyTasksPage}
      />
      <AuthRoute
        exact
        path={tenantPaths("/my-following")}
        component={MyFollowingPage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/notifications")}
        component={NotificationsPage}
      />
      {showTaskCapture && (
        <AuthRoute
          exact
          path={tenantPaths("/launch-task-capture/:actionType")}
          component={LaunchTaskCapturePage}
        />
      )}
      {featureFlag.isAutomationStoreOpenInAssistantEnabled() &&
        SessionHelper.isFpsUri() && (
          <AuthRoute
            exact
            path={tenantPaths("/launch-assistant/:actionType", [
              AUTOMATION_STORE_SERVICE
            ])}
            component={LaunchAssistantPage}
            isAutomationStoreNav
          />
        )}
      {featureFlag.isAutomationStoreEnabled() && SessionHelper.isFpsUri() && (
        <AuthRoute
          exact
          path={tenantPaths("/explore-published-automations", [
            AUTOMATION_STORE_SERVICE
          ])}
          component={AutomationStorePage}
          isAutomationStoreNav
        />
      )}
      {featureFlag.isAutomationStoreEnabled() && SessionHelper.isFpsUri() && (
        <AuthRoute
          exact
          path={tenantPaths("/explore-published-automations/:slug", [
            AUTOMATION_STORE_SERVICE
          ])}
          component={AutomationStoreProfilePage}
          isAutomationStoreNav
        />
      )}
      <AdminRoute
        exact
        path={tenantPaths("/admin/manage-users")}
        component={AdminManageUsersPage}
        ah_permissions={[MANAGE_PEOPLE_PERMISSION]}
      />
      {featureFlag.isAutomationCloudUserGroupManagementEnabled() ? (
        <AdminRoute
          exact
          path={tenantPaths("/admin/manage-users/groups")}
          component={AdminManageGroupsPage}
          ah_permissions={[MANAGE_PEOPLE_PERMISSION]}
        />
      ) : (
        ""
      )}
      <AdminRoute
        exact
        path={tenantPaths("/admin/user-roles-permissions")}
        component={AdminManageUserRolesPermissionsPage}
        adminPermissions={[MANAGE_ROLES_PERMISSION]}
      />
      {showComponents && (
        <AdminRoute
          exact
          path={tenantPaths("/admin/manage-components")}
          component={ManageComponentsPage}
          ah_permissions={[MANAGE_COMPONENTS_PERMISSION]}
        />
      )}
      <AdminRoute
        exact
        path={tenantPaths("/admin/categories")}
        component={AdminManageCategoriesPage}
        ah_permissions={[MANAGE_CATEGORIES_PERMISSION]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/account")}
        component={AdminAccountPage}
        ah_permissions={ACCESS_ADMIN_CONSOLE_PERMISSIONS}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/tenant-settings")}
        component={TenantSettingsPage}
        ah_permissions={[TENANT_SETTINGS_PERMISSION]}
      />
      {/* <AdminRoute
        exact
        path={tenantPaths("/admin/idea-assessment")}
        component={AdminPlatformAssessmentPage}
        ah_permissions={[CUSTOMIZE_ASSESSMENT_PERMISSION]}
        moduleIds={[module_navigator_id]}
      /> */}
      <AdminRoute
        exact
        path={tenantPaths("/admin/idea-assessment")}
        component={AdminCustomizeAssessmentsOverviewPage}
        ah_permissions={[CUSTOMIZE_ASSESSMENT_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/idea-assessment/customize")}
        component={AdminCustomizeDetailedAssessmentPage}
        ah_permissions={[CUSTOMIZE_ASSESSMENT_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      {/* <AdminRoute
        exact
        path={tenantPaths(
          "/admin/idea-assessment/customize-high-level-assessment"
        )}
        component={AdminCustomizeHighLevelAssessmentPage}
        ah_permissions={[CUSTOMIZE_ASSESSMENT_PERMISSION]}
        moduleIds={[module_navigator_id]}
      /> */}
      <AdminRoute
        exact
        path={tenantPaths("/admin/documentation")}
        component={AdminDocumentationTemplatesPage}
        ah_permissions={[MANAGE_TEMPLATES_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/app-inventory")}
        component={AdminManageApplicationsPage}
        ah_permissions={[MANAGE_APP_INVENTORY_PERMISSION]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/customize-detailed-assessment")}
        component={AdminCustomizeDetailedAssessmentPage}
        ah_permissions={[CUSTOMIZE_ASSESSMENT_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/cost-setup")}
        component={AdminCostSetupPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/import-pipeline")}
        component={ImportPipelinePage}
        ah_permissions={[IMPORT_PIPELINE_PERMISSION]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/rpa-software-vendor")}
        component={AdminCostSetupRPASoftwareVendorPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/rpa-software-vendor/edit")}
        component={AdminCostSetupRPASoftwareVendorEditPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/one-time-costs")}
        component={AdminCostSetupOneTimeCostsPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/one-time-costs/edit")}
        component={AdminCostSetupOneTimeCostsEditPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/running-costs")}
        component={AdminCostSetupRunningCostsPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/running-costs/edit")}
        component={AdminCostSetupRunningCostsEditPage}
        ah_permissions={[MANAGE_ROLES_PERMISSION, TENANT_SETTINGS_PERMISSION]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/other-running-costs")}
        component={AdminCostSetupOtherRunningCostsPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      <AdminRoute
        exact
        path={tenantPaths("/admin/other-running-costs/edit")}
        component={AdminCostSetupOtherRunningCostsEditPage}
        ah_permissions={[
          MANAGE_COST_SETUP_PERMISSION,
          TENANT_SETTINGS_PERMISSION
        ]}
        moduleIds={[module_navigator_id]}
      />
      {featureFlag.isAdminManageOpenApiEnabled() && (
        <AdminRoute
          exact
          path={tenantPaths("/admin/open-api-management")}
          component={AdminOpenApiTablePage}
          ah_permissions={[MANAGE_OPEN_API_PERMISSION]}
          moduleIds={[module_navigator_id]}
        />
      )}
      {featureFlag.isAutomationStoreEnabled() &&
        TenantSettingHelper.isAutomationStoreEnabled() && (
          <AdminRoute
            exact
            path={tenantPaths("/admin/orchestrator-connection")}
            component={AdminOrchestratorConnectionPage}
            ah_permissions={[TENANT_SETTINGS_PERMISSION]}
            moduleIds={[module_navigator_id]}
          />
        )}
      )
      {SessionHelper.isFpsUri() &&
        featureFlag.isInsightsPrivatePreviewEnabled() && (
          <AdminRoute
            exact
            path={tenantPaths("/admin/insights-connection")}
            component={InsightsConnectionPage}
            ah_permissions={[INSIGHTS_CONNECTION_PERMISSION]}
            moduleIds={[module_navigator_id]}
          />
        )}
      {SessionHelper.isFpsUri() && featureFlag.isJiraIntegrationEnabled() && (
        <AdminRoute
          exact
          path={tenantPaths("/admin/jira-connection")}
          component={AdminJiraMappingPage}
          ah_permissions={[MANAGE_INTEGRATIONS_PERMISSION]}
          moduleIds={[module_navigator_id]}
        />
      )}
      {SessionHelper.isFpsUri() && featureFlag.isJiraIntegrationEnabled() && (
        <AdminRoute
          exact
          path={tenantPaths("/admin/jira-connection/:tableType")}
          component={AdminJiraMappingPage}
          ah_permissions={[MANAGE_INTEGRATIONS_PERMISSION]}
          moduleIds={[module_navigator_id]}
        />
      )}
      {featureFlag.isAdminAuditLogsEnabled() ? (
        <AdminRoute
          exact
          path={tenantPaths("/admin/audit-logs")}
          component={AdminAuditLogsPage}
          ah_permissions={[ADMIN_AUDIT_LOGS_PERMISSION]}
          moduleIds={[module_navigator_id]}
        />
      ) : (
        ""
      )}
      {/* <AuthRoute
        exact
        path={tenantPaths("/questionnaire/q1")}
        component={SubmitEmployeeDrivenIdeaPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION]}
      /> */}
      <Route
        exact
        path={tenantPaths("/questionnaire/landing")}
        render={() => (
          <Redirect to={{ pathname: urlWithTenant("/questionnaire/q1") }} />
        )}
      />
      <Route
        exact
        path={tenantPaths("/questionnaire/q1")}
        render={() => (
          <Redirect to={{ pathname: urlWithTenant("/submit/idea") }} />
        )}
      />
      <AuthRoute
        exact
        path={tenantPaths("/submit-idea-success/:slug")}
        component={EmployeeDrivenIdeaSuccessPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION]}
      />
      <AuthRoute
        exact
        path={tenantPaths("/submit-automation/success/:process_slug")}
        component={ProcessSubmitSuccessPage}
        moduleIds={[module_navigator_id]}
      />
      <AuthRoute
        exact
        path={tenantPaths(
          CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
            COProcessSubmissionType.EMPLOYEE_DRIVEN
          )
        )}
        component={COSubmitProcessPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION]}
      />
      <AuthRoute
        exact
        path={tenantPaths(
          CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
            COProcessSubmissionType.TOP_DOWN_SUBMISSION
          )
        )}
        component={COSubmitProcessPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[TOP_DOWN_PERMISSION_PERMISSION]}
      />
      <AuthRoute
        exact
        path={tenantPaths(
          CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
            COProcessSubmissionType.PROCESS_MINING
          )
        )}
        component={COSubmitProcessPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[TOP_DOWN_PERMISSION_PERMISSION]}
      />
      <AuthRoute
        exact
        path={tenantPaths(
          CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
            COProcessSubmissionType.TASK_MINING
          )
        )}
        component={COSubmitProcessPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[TOP_DOWN_PERMISSION_PERMISSION]}
      />
      <AuthRoute
        exact
        path={tenantPaths(
          CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
            COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION
          )
        )}
        component={COSubmitProcessPage}
        moduleIds={[module_navigator_id]}
        ah_permissions={[SUBMIT_AUTOMATION_PERMISSION]}
      />
      <AuthRoute
        exact
        path={tenantPaths(
          CO_SUBMIT_PAGE_FOR_SUBMISSION_TYPE(
            COProcessSubmissionType.CHANGE_REQUEST
          )
        )}
        component={COSubmitProcessPage}
        moduleIds={[module_navigator_id]}
      />
      {SessionHelper.isFpsUri() && (
        <AuthRoute
          exact
          path={tenantPaths("/launch-studio")}
          component={LaunchStudioPage}
          moduleIds={[module_navigator_id]}
        />
      )}
      {SessionHelper.isFpsUri() &&
        featureFlag.isOrchestratorMappingEnabled() && (
          <AdminRoute
            exact
            path={tenantPaths("/admin/orchestrator-mapping")}
            component={OrchestratorMappingPage}
            ah_permissions={[ORCHESTRATOR_MAPPING_PERMISSION]}
            moduleIds={[module_navigator_id]}
          />
        )}
      <GeneralInfoRoute component={NotFound} />
    </Switch>
  );
};

export default withRouter(Routes);
