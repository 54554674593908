import { FETCH_ALL_BADGE_TYPES } from "../constants/actionTypes";

const INITIAL_STATE = {
  badgeTypes: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_BADGE_TYPES: {
      return {
        ...state,
        badgeTypes: action.payload
      };
    }
    default:
      return state;
  }
}
