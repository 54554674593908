import {
  COBaseTemplateInterface,
  COContextInterface,
  COProcessAnswerInterface,
  COQuestionAnswerOptionInterface
} from "../interfaces/co-interfaces";

let COContextClass,
  COProcessAnswerClass,
  COQuestionAnswerOptionClass,
  COTemplateLoader;

export const newContextWithoutImporting = (props?: any): COContextInterface => {
  COContextClass = COContextClass || require("../classes/co-context.class");
  return new COContextClass.COContext(props || {});
};

export const newProcessAnswerWithoutImporting = (
  props
): COProcessAnswerInterface => {
  COProcessAnswerClass =
    COProcessAnswerClass || require("../classes/co-process-answer.class");
  return new COProcessAnswerClass.COProcessAnswer(props);
};
export const newQuestionAnswerOptionWithoutImporting = (
  props
): COQuestionAnswerOptionInterface => {
  COQuestionAnswerOptionClass =
    COQuestionAnswerOptionClass ||
    require("../classes/co-question-answer-option.class");
  return new COQuestionAnswerOptionClass.COQuestionAnswerOption(props);
};

export const loadTemplateForSlugWithoutImporting = (
  template_slug
): COBaseTemplateInterface => {
  COTemplateLoader =
    COTemplateLoader || require("../templates/co-template-loader");
  return COTemplateLoader.loadTemplateForSlug(template_slug);
};
