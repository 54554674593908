import React, { Component } from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import swal from "../../SweetAlert/SweetAlert";
import {
  sweetAlertLoader,
  closeSweetAlertLoader
} from "../../../utils/sweetAlertLoader";
import { getKeyValue } from "../../../utils/objectParsers";

import {
  clearCurrentAutomation,
  deleteAutomationAction
} from "../../../actions/automationPipelineActions";
import { hideModal } from "../../../actions/modalActions";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import "./ModalDeleteIdea.scss";
import { isCitizenDeveloperAutomation } from "../../../utils/automationUtils";
import {
  AUTOMATION_DELETE,
  triggerAnalyticsWithDefaultData
} from "../../../utils/analyticsUtils";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";

const InputTextApolloWithStyles = styled(InputTextApollo)`
  && {
    margin-top: 20px;
    width: 100%;
  }
`;

class ModalDeleteIdea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      errorMsg: ""
    };
  }

  closeModal = () => {
    const { clearCurrentAutomation, hideModal } = this.props;

    clearCurrentAutomation();
    hideModal();
  };

  preSubmit = () => {
    if (this.state.comment.length === 0) {
      this.setState({
        errorMsg: this.props.t(
          "modal_delete_automation_error_enter_comment",
          "Please enter comment"
        )
      });
    } else {
      this.setState({
        errorMsg: ""
      });
      this.deleteIdea();
    }
  };

  deleteIdea = () => {
    const { automationPipeline, hideModal } = this.props;
    const { statusChangeCallback, errorCallback } = automationPipeline;
    sweetAlertLoader({
      text: this.props.t("modal_delete_automation_deleting", "Deleting")
    });

    this.props.deleteAutomationAction(
      {
        slug: automationPipeline.currentAutomation.process_slug,
        comment: this.state.comment,
        errorCallback
      },
      () => {
        closeSweetAlertLoader();

        swal.fire({
          title: this.props.t(
            "modal_delete_automation_delete_successfully",
            "Automation has been deleted successfully."
          ),
          timer: 2000
        });

        setTimeout(() => {
          if (typeof statusChangeCallback === "function") {
            hideModal();
            statusChangeCallback();
          }
        }, 1000);

        this.fireCDDeleteAnalyticsEvent(automationPipeline.currentAutomation);
      },
      e => {
        closeSweetAlertLoader();
        hideModal();

        let errorMsg = this.props.t(
          "modal_delete_automation_please_try_again",
          "Please try again."
        );
        errorMsg = getKeyValue(e.response, "data.data.message") || errorMsg;

        swal.fire({
          title: this.props.t(
            "modal_delete_automation_error_cannot_delete_idea",
            "Cannot delete idea"
          ),
          text: errorMsg,
          icon: "error",
          confirmButtonText: this.props.t(
            "modal_delete_automation_error_ok",
            "OK"
          )
        });
      }
    );
  };

  fireCDDeleteAnalyticsEvent = automationDeleted => {
    if (automationDeleted && isCitizenDeveloperAutomation(automationDeleted)) {
      triggerAnalyticsWithDefaultData(AUTOMATION_DELETE);
    }
  };

  onCommentChange = e => {
    const { value } = e.target;

    this.setState({
      comment: value
    });
  };

  render() {
    const modalContentStyle = {
      padding: "0px 24px 24px 24px",
      borderRadius: "4px",
      boxShadow:
        "0px 5px 10px rgb(0 0 0 / 20%), 0px 1px 5px rgb(0 0 0 / 12%), 0px 8px 10px rgb(0 0 0 / 14%)",
      width: "464px"
    };

    const headerStyle = {
      borderBottom: "0",
      paddingRight: "0px"
    };

    return (
      <Modal contentStyle={modalContentStyle} onClose={this.closeModal}>
        <ModalTemplateOne
          className="ModalDeleteIdea__container"
          heading={this.props.t([
            "modal_delete_automation_title",
            "Delete Automation?"
          ])}
          headerStyle={headerStyle}
          onClose={this.closeModal}
        >
          <div className="ModalDeleteIdea" data-testid="ModalDeleteIdea">
            <p className="ModalDeleteIdea__text">
              {this.props.t(
                [
                  "modal_delete_automation_are_you_sure",
                  "Are you sure you want to delete the automation? All data related to it will be permanently lost. {{moreMessage}}"
                ],
                {
                  moreMessage: this.state.errorMsg
                }
              )}
            </p>
            <InputTextApolloWithStyles
              label={this.props.t(
                "modal_delete_automation_leave_comment",
                "Leave a Comment"
              )}
              maxLength={100}
              InputProps={{
                onChange: this.onCommentChange,
                value: this.state.comment
              }}
              rows={2}
              error={!!this.state.errorMsg}
              helperText={this.state.errorMsg}
              required
              multiline
            />
            <div className="ModalDeleteIdea__buttons">
              <ButtonApolloPrimary
                className={`ModalDeleteIdea__button ModalDeleteIdea__approve`}
                onClick={this.preSubmit}
              >
                {this.props.t(["modal_delete_automation_delete", "Delete"])}
              </ButtonApolloPrimary>
              <ButtonApolloSecondary
                className="ModalDeleteIdea__button ModalDeleteIdea__cancel"
                onClick={this.closeModal}
              >
                {this.props.t(["modal_delete_automation_cancel", "Cancel"])}
              </ButtonApolloSecondary>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = ({ tableAutomations, automationPipeline }) => ({
  tableAutomations,
  automationPipeline
});

const mapDispatchToProps = {
  hideModal,
  clearCurrentAutomation,
  deleteAutomationAction
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ModalDeleteIdea)
);
