//= =====================
// Modal Actions
//= =====================
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

//= =====================
// Language Actions
//= =====================
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

//= =====================
// Global Data Actions
//= =====================
export const CLEAR_UNSAVED_DATA = "CLEAR_UNSAVED_DATA";
export const FLAG_UNSAVED_DATA = "FLAG_UNSAVED_DATA";
export const SET_SAVE_CALLBACK = "SET_SAVE_CALLBACK";
export const UPDATE_LEAVING_CURATION = "UPDATE_LEAVING_CURATION";
export const SET_TITLE_TEXT_BUTTON_TEXT_OPTIONS =
  "SET_TITLE_TEXT_BUTTON_TEXT_OPTIONS";

//= =====================
// PPP Actions
//= =====================
export const FETCH_PPP_PROFILE_DATA = "FETCH_PPP_PROFILE_DATA";
export const FETCH_PPP_PROFILE_MEDIA = "FETCH_PPP_PROFILE_MEDIA";
export const CLEAR_PPP_PROFILE_DATA = "CLEAR_PPP_PROFILE_DATA";

export const RECEIVE_UPVOTE = "RECEIVE_UPVOTE";
export const RECEIVE_DOWNVOTE = "RECEIVE_DOWNVOTE";
export const RECEIVE_UNFOLLOW = "RECEIVE_UNFOLLOW";
export const RECEIVE_FOLLOW = "RECEIVE_FOLLOW";
export const FETCH_EDIT_DATA = "FETCH_EDIT_DATA";
export const REQUEST_PROCESS = "REQUEST_PROCESS";

export const FETCH_APPLICATIONS = "FETCH_APPLICATIONS";
export const FETCH_APPLICATION_FOR_L1_TAXONOMY =
  "FETCH_APPLICATION_FOR_L1_TAXONOMY";

export const FETCH_AUTOMATIONS_FOR_SIMILAR_SEARCH =
  "FETCH_AUTOMATIONS_FOR_SIMILAR_SEARCH";
export const FETCH_SIMILAR_AUTOMATIONS = "FETCH_SIMILAR_AUTOMATIONS";
export const FETCH_AUTOMATION_COMPONENTS = "FETCH_AUTOMATION_COMPONENTS";
export const FETCH_AUTOMATIONS_USING_COMPONENT =
  "FETCH_AUTOMATIONS_USING_COMPONENT";

export const FETCH_REUSED_COMPONENTS = "FETCH_REUSED_COMPONENTS";
export const FETCH_REUSED_COMPONENTS_FOR_SEARCH =
  "FETCH_REUSED_COMPONENTS_FOR_SEARCH";

export const FETCH_CHANGE_REQUESTS = "FETCH_CHANGE_REQUESTS";

export const SET_COMMENT_TO_REPORT = "SET_COMMENT_TO_REPORT";

export const FETCH_ALL_PHASE_AND_STATUSES = "FETCH_ALL_PHASE_AND_STATUSES";
export const GET_REAL_TIME_ASSESSMENT_CALCULATION_TYPES =
  "GET_REAL_TIME_ASSESSMENT_CALCULATION_TYPES";

//= =====================
// PPP Comment Actions
//= =====================
export const FETCH_PPP_COMMENTS = "FETCH_PPP_COMMENTS";
export const ADD_NEW_PPP_COMMENT = "ADD_NEW_PPP_COMMENT";
export const SET_PPP_COMMENT_ID_TO_EDIT = "SET_PPP_COMMENT_ID_TO_EDIT";
export const SET_PPP_COMMENT_ID_TO_REPLY_TO = "SET_PPP_COMMENT_ID_TO_REPLY_TO";
export const DELETE_PPP_COMMENT = "DELETE_PPP_COMMENT";
export const UPDATE_PPP_COMMENT = "UPDATE_PPP_COMMENT";
export const CLEAR_PPP_COMMENTS = "CLEAR_PPP_COMMENTS";

//= =====================
// Edit Automation Profile Actions
//= =====================
export const EDIT_PROFILE_Q1 = "EDIT_PROFILE_Q1";
export const FETCH_BASE_EDIT_DATA = "FETCH_BASE_EDIT_DATA";

export const FETCH_AUTOMATION_FOR_IDEA = "FETCH_SLUG_FOR_IDEA";
export const CLEAR_SLUG_FOR_IDEA = "CLEAR_SLUG_FOR_IDEA";
//= =====================
// PPP Documents Actions
//= =====================
export const FETCH_ALL_PROCESS_DOCUMENT_TYPES =
  "FETCH_ALL_PROCESS_DOCUMENT_TYPES";
export const FETCH_ALL_PROCESS_DOCUMENTS = "FETCH_ALL_PROCESS_DOCUMENTS";
export const UPLOAD_PROCESS_DOCUMENT_SUCCESSFUL =
  "UPLOAD_PROCESS_DOCUMENT_SUCCESSFUL";
export const SET_PROCESS_DOCUMENT_TO_UPDATE = "SET_PROCESS_DOCUMENT_TO_UPDATE";

//= =====================
// User Profile Actions
//= =====================
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_EDIT = "FETCH_USER_PROFILE_EDIT";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const MODIFY_EDITED_USER_PROFILE = "MODIFY_EDITED_USER_PROFILE";
export const SET_TENANT_LOGO = "SET_TENANT_LOGO";

export const CLEAR_USER_PROFILE = "CLEAR_USER_PROFILE";
export const FETCH_USER_PROFILE_IDEAS = "FETCH_USER_PROFILE_IDEAS";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_SELECTED_USERS = "FETCH_SELECTED_USERS";
export const FETCHING_SELECTED_USERS = "FETCHING_SELECTED_USERS";
export const CLEAR_SELECTED_USERS = "CLEAR_SELECTED_USERS";
export const FETCH_USER_PROFILE_COLLABORATIONS =
  "FETCH_USER_PROFILE_COLLABORATIONS";
export const FETCH_USER_PROFILE_CHANGE_REQUESTS =
  "FETCH_USER_PROFILE_CHANGE_REQUESTS";
export const FETCH_USER_PROFILE_DOCUMENTATION =
  "FETCH_USER_PROFILE_DOCUMENTATION";
export const FETCH_MORE_USER_PROFILE_DOCUMENTATION =
  "FETCH_MORE_USER_PROFILE_DOCUMENTATION";
export const SET_IS_LOADING_USER_PROFILE_DOCUMENTATION =
  "SET_IS_LOADING_USER_PROFILE_DOCUMENTATION";
export const FETCH_USER_PROFILE_COMPONENTS = "FETCH_USER_PROFILE_COMPONENTS";
export const FETCH_USER_PROFILE_SIDEBAR = "FETCH_USER_PROFILE_SIDEBAR";
export const FETCH_SELECTED_USER_BADGES = "FETCH_SELECTED_USER_BADGES";
export const FETCH_FLOW_TREE = "FETCH_FLOW_TREE";
export const FETCH_USER_PROFILE_AUTOMATIONS = "FETCH_USER_PROFILE_AUTOMATIONS";

//= =====================
// PPP Templates Actions
//= =====================
export const FETCH_ALL_PROCESS_TEMPLATES = "FETCH_ALL_PROCESS_TEMPLATES";
export const FETCH_ACTIVE_PROCESS_TEMPLATES = "FETCH_ACTIVE_PROCESS_TEMPLATES";
export const FETCH_ALL_FOLLOWERS = "FETCH_ALL_FOLLOWERS";

//= =====================
// Component Profile Actions
//= =====================
export const FETCH_COMPONENT_PROFILE = "FETCH_COMPONENT_PROFILE";
export const FETCH_SIMILAR_COMPONENTS = "FETCH_SIMILAR_COMPONENTS";
export const CLEAR_COMPONENT_PROFILE = "CLEAR_COMPONENT_PROFILE";
export const SET_COMPONENT_REVIEW_SECTION = "SET_COMPONENT_REVIEW_SECTION";

//= =====================
// Component Profile Comment Actions
//= =====================
export const FETCH_COMPONENT_COMMENTS = "FETCH_COMPONENT_COMMENTS";
export const ADD_NEW_COMPONENT_COMMENT = "ADD_NEW_COMPONENT_COMMENT";
export const SET_COMPONENT_COMMENTS_PAGE = "SET_COMPONENT_COMMENTS_PAGE";
export const SET_COMPONENT_COMMENT_ID_TO_EDIT =
  "SET_COMPONENT_COMMENT_ID_TO_EDIT";
export const SET_COMPONENT_COMMENT_ID_TO_REPLY_TO =
  "SET_COMPONENT_COMMENT_ID_TO_REPLY_TO";
export const DELETE_COMPONENT_COMMENT = "DELETE_COMPONENT_COMMENT";
export const UPDATE_COMPONENT_COMMENT = "UPDATE_COMPONENT_COMMENT";
export const CLEAR_COMPONENT_COMMENTS = "CLEAR_COMPONENT_COMMENTS";

//= =====================
// Component Profile Reviews Actions
//= =====================
export const FETCH_COMPONENT_REVIEWS = "FETCH_COMPONENT_REVIEWS";
export const ADD_NEW_COMPONENT_REVIEW = "ADD_COMPONENT_REVIEW";
export const CLEAR_COMPONENT_REVIEWS = "CLEAR_COMPONENT_REVIEW";
export const DELETE_COMPONENT_REVIEW = "DELETE_COMPONENT_REVIEW";
export const SET_REVIEW_TO_REPORT = "SET_REVIEW_TO_REPORT";

//= =====================
// Component Upload Edit Actions
//= =====================
export const FETCH_ALL_COMPONENT_TYPES = "FETCH_ALL_COMPONENT_TYPES";
export const FETCH_AND_SEARCH_COMPONENT_APPLICATIONS =
  "FETCH_AND_SEARCH_COMPONENT_APPLICATIONS";

//= =====================
// Questionnaire Actions
//= =====================
export const FETCH_TAXONOMY = "FETCH_TAXONOMY";
export const FETCH_FILTERED_TAXONOMY_BY_PERMISSION =
  "FETCH_FILTERED_TAXONOMY_BY_PERMISSION";
export const SET_QUESTIONNAIRE_CLOSE_ROUTE = "SET_QUESTIONNAIRE_CLOSE_ROUTE";

//= =====================
// Explore Automations Actions
//= =====================
export const FETCH_EXPLORE_AUTOMATIONS_DATA = "FETCH_EXPLORE_AUTOMATIONS_DATA";
export const CLEAR_EXPLORE_AUTOMATIONS_DATA = "CLEAR_EXPLORE_AUTOMATIONS_DATA";
export const SET_EXPLORE_AUTOMATIONS_IS_LOADING_DATA =
  "SET_EXPLORE_AUTOMATIONS_IS_LOADING_DATA";
export const FETCH_EXPLORE_AUTOMATIONS_PHASE_FILTERS =
  "FETCH_EXPLORE_AUTOMATIONS_PHASE_FILTERS";
export const FETCH_EXPLORE_AUTOMATIONS_ADDITIONAL_FILTERS =
  "FETCH_EXPLORE_AUTOMATIONS_ADDITIONAL_FILTERS";
export const FETCH_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS =
  "FETCH_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS";
export const FETCH_ALL_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS =
  "FETCH_ALL_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS";
export const FETCHING_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS =
  "FETCHING_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS";
export const CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS =
  "CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS";
export const CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS_BY_L1 =
  "CLEAR_EXPLORE_AUTOMATIONS_APPLICATION_FILTERS_BY_L1";

//= =====================
// Explore People Actions
//= =====================
export const FETCH_EXPLORE_PEOPLE_DATA = "FETCH_EXPLORE_PEOPLE_DATA";
export const CLEAR_EXPLORE_PEOPLE_DATA = "CLEAR_EXPLORE_PEOPLE_DATA";
export const SET_EXPLORE_PEOPLE_IS_LOADING_DATA =
  "SET_EXPLORE_PEOPLE_IS_LOADING_DATA";
export const FETCH_EXPLORE_PEOPLE_FILTERS = "FETCH_EXPLORE_PEOPLE_FILTERS";

//= =====================
// Explore Component Actions
//= =====================
export const FETCH_EXPLORE_COMPONENTS_DATA = "FETCH_EXPLORE_PEOPLE_DATA";
export const CLEAR_EXPLORE_COMPONENTS_DATA = "CLEAR_EXPLORE_PEOPLE_DATA";
export const FETCH_EXPLORE_ALL_COMPONENT_TYPES =
  "FETCH_EXPLORE_ALL_COMPONENT_TYPES";
export const FETCH_EXPLORE_ALL_COMPONENT_APPLICATION_FILTERS =
  "FETCH_EXPLORE_ALL_COMPONENT_APPLICATION_FILTERS";
export const FETCH_EXPLORE_ALL_COMPONENT_L1 = "FETCH_EXPLORE_ALL_COMPONENT_L1";

export const SET_EXPLORE_COMPONENTS_IS_LOADING_DATA =
  "SET_EXPLORE_COMPONENTS_IS_LOADING_DATA";

//= =====================
// File Transfer Actions
//= =====================
export const UPLOAD_FILE = "UPLOAD_FILE";

//= =====================
// My Ideas Actions
//= =====================
export const FETCH_SAVE_VIEW = "FETCH_SAVE_VIEW";
export const CLEAR_SAVE_VIEW = "CLEAR_SAVE_VIEW";

//= =====================
// Image Uploader actions
//= =====================
export const SET_UPLOADED_IMAGE_URL = "SET_UPLOADED_IMAGE_URL";
export const SET_UPLOADED_IMAGE_MODAL_COMPLETION =
  "SET_UPLOADED_IMAGE_MODAL_COMPLETION";
export const CLEAR_UPLOADED_IMAGE_URL = "CLEAR_UPLOADED_IMAGE_URL";

//= =====================
// Process Collaborators
//= =====================
export const FETCH_PROCESS_COLLABORATORS = "FETCH_PROCESS_COLLABORATORS";
export const FETCH_PROCESS_ROLES = "FETCH_PROCESS_ROLES";
export const FETCH_PROCESS_PERMISSIONS = "FETCH_PROCESS_PERMISSIONS";
export const UPDATE_PROCESS_COLLABORATORS = "UPDATE_PROCESS_COLLABORATORS";
export const ADD_SINGLE_PROCESS_COLLABORATOR =
  "ADD_SINGLE_PROCESS_COLLABORATOR";

//= =====================
// Process Duplicate Check Actions
//= =====================
export const SET_CHECK_FOR_DUPLICATES_DATA = "SET_CHECK_FOR_DUPLICATES_DATA";
export const POTENTIAL_DUPLICATES_SERVER_RESPONSE =
  "POTENTIAL_DUPLICATES_SERVER_RESPONSE";
export const CLEAR_POTENTIAL_DUPLICATES = "CLEAR_POTENTIAL_DUPLICATES";

//= =====================
// Admin Actions
//= =====================
export const FETCH_ADMIN_USERS_DATA = "FETCH_ADMIN_USERS_DATA";
export const FETCH_MORE_ADMIN_USERS_DATA = "FETCH_MORE_ADMIN_USERS_DATA";
export const SET_ADMIN_USERS_COUNTER_TOTAL = "SET_ADMIN_USERS_COUNTER_TOTAL";
export const CLEAR_ADMIN_USERS_COUNTER_TOTAL =
  "CLEAR_ADMIN_USERS_COUNTER_TOTAL";
export const CLEAR_ADMIN_USERS_DATA = "CLEAR_ADMIN_USERS_DATA";
export const ADMIN_USER_MANAGER_POPUP_SHOWING =
  "ADMIN_USER_MANAGER_POPUP_SHOWING";
export const FETCH_TENANT = "FETCH_TENANT";
export const UPDATE_TENANT = "UPDATE_TENANT";
export const SET_ADMIN_USER_OBJECT = "SET_ADMIN_USER_OBJECT";

//= =====================
// Automation Pipeline Actions
//= =====================
export const SET_STATUS = "SET_STATUS";
export const DELETE_AUTOMATION = "DELETE_AUTOMATION";
export const CLEAR_CURRENT_AUTOMATION = "CLEAR_CURRENT_AUTOMATION";
export const FETCH_ALL_PHASES_AND_STATUSES = "FETCH_ALL_PHASES_AND_STATUSES";
export const SET_PIPELINE_PAGE_TYPE = "SET_PIPELINE_PAGE_TYPE";
export const CLEAR_PIPELINE_PAGE_TYPE = "CLEAR_PIPELINE_PAGE_TYPE";

//= =====================
// Admin Manage Users Actions
//= =====================
export const FETCH_ROLES_FOR_ADMIN_TO_MANAGE =
  "FETCH_ROLES_FOR_ADMIN_TO_MANAGE";

//= =====================
// Admin Documentation Template Actions
//= =====================
export const FETCH_ALL_DOCUMENT_TEMPLATES = "FETCH_ALL_DOCUMENT_TEMPLATES";
export const FETCH_ALL_DOCUMENT_TEMPLATE_TYPES =
  "FETCH_ALL_DOCUMENT_TEMPLATE_TYPES";
export const SET_DOCUMENT_TEMPLATE_TO_UPDATE =
  "SET_DOCUMENT_TEMPLATE_TO_UPDATE";
export const SEARCH_DOCUMENT_TEMPLATES = "SEARCH_DOCUMENT_TEMPLATES";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA =
  "SET_SEARCH_DOCUMENTATION_TEMPLATE_IS_LOADING_DATA";

//= =====================
// My Components Actions
//= =====================
export const FETCH_MY_COMPONENTS = "FETCH_MY_COMPONENTS";
export const CLEAR_MY_COMPONENTS = "CLEAR_MY_COMPONENTS";
export const PHASE_CHANGE_MY_COMPONENTS = "PHASE_CHANGE_MY_COMPONENTS";
export const DELETE_MY_COMPONENTS = "DELETE_MY_COMPONENTS";
export const SET_MY_COMPONENTS_UPLOAD_COUNT = "SET_MY_COMPONENTS_UPLOAD_COUNT";
export const SET_MY_COMPONENTS_DOWNLOAD_COUNT =
  "SET_MY_COMPONENTS_DOWNLOAD_COUNT";

//= =====================
// My Components Actions
//= =====================
export const FETCH_MANAGE_COMPONENTS = "FETCH_MANAGE_COMPONENTS";
export const CLEAR_MANAGE_COMPONENTS = "CLEAR_MANAGE_COMPONENTS";
export const EDIT_COMPONENT = "EDIT_COMPONENT";
export const PHASE_CHANGE_MANAGE_COMPONENTS = "PHASE_CHANGE_MANAGE_COMPONENTS";
export const DELETE_MANAGE_COMPONENTS = "DELETE_MANAGE_COMPONENTS";

//= =====================
// My Components Actions
//= =====================
export const FETCH_MY_TASKS = "FETCH_MY_TASKS";
export const FETCH_TASK_FILTERS = "FETCH_TASK_FILTERS";
export const FETCH_TASK_FILTER_COLLABORATORS =
  "FETCH_TASK_FILTER_COLLABORATORS";
export const FETCH_TASK_COLLABORATORS = "FETCH_TASK_COLLABORATORS";
export const EDIT_SINGLE_AUTOMATION_TASK = "EDIT_SINGLE_AUTOMATION_TASK";
export const REMOVE_TASK = "REMOVE_TASK";
export const CLEAR_MY_TASKS = "CLEAR_MY_TASKS";
export const CLEAR_TASK_COLLABORATORS = "CLEAR_TASK_COLLABORATORS";

//= =====================
// Admin Manage Users Actions
//= =====================
export const FETCH_HIERARCHY_FOR_ADMIN_TO_MANAGE =
  "FETCH_HIERARCHY_FOR_ADMIN_TO_MANAGE";

//= =====================
// Admin Manage Applications Actions
//= =====================
export const FETCH_HIERARCY_FOR_MANAGE_APPLICATONS =
  "FETCH_HIERARCY_FOR_MANAGE_APPLICATONS";
export const FETCH_APPLICATIONS_FOR_MANAGE_APPLICATONS =
  "FETCH_APPLICATIONS_FOR_MANAGE_APPLICATONS";

//= =====================
// User Status Actions
//= =====================
export const FETCH_USER_STATUS = "FETCH_USER_STATUS";
export const LEVEL_UP = "LEVEL_UP";
export const IS_LEVEL_UP_MODAL_OPEN = "IS_LEVEL_UP_MODAL_OPEN";

//= =====================
// Notification Actions
//= =====================
export const FETCH_NOTIFICATION_TYPES = "FETCH_NOTIFICATION_TYPES";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_MORE_NOTIFICATIONS = "FETCH_MORE_NOTIFICATIONS";
export const MARK_NOTIFICATION_READ = "MARK_NOTIFICATION_READ";
export const UNMARK_NOTIFICATION_READ = "UNMARK_NOTIFICATION_READ";
export const CLEAR_NOTIFICATIONS_REDUCER = "CLEAR_NOTIFICATIONS_REDUCER";
export const SET_IS_LOADING_NOTIFICATIONS = "SET_IS_LOADING_NOTIFICATIONS";
export const SET_IS_LOADING_MORE_NOTIFICATIONS =
  "SET_IS_LOADING_MORE_NOTIFICATIONS";
export const MARK_ALL_NOTIFICATIONS_READ = "MARK_ALL_NOTIFICATIONS_READ";
export const IS_MARKING_ALL_NOTIFICATIONS_READ =
  "IS_MARKING_ALL_NOTIFICATIONS_READ";
export const HAS_MARKED_ALL_NOTIFICATIONS_READ =
  "HAS_MARKED_ALL_NOTIFICATIONS_READ";

export const MARK_ACTION_READ = "MARK_ACTION_READ";
export const UNMARK_ACTION_READ = "UNMARK_ACTION_READ";

//= =====================
// Notification Actions
//= =====================
export const FETCH_ALL_BADGE_TYPES = "FETCH_ALL_BADGE_TYPES";

//= =====================
// Decision Pipeline
//= =====================
export const FETCH_DECISION_PIPELINE_AUTOMATIONS =
  "FETCH_DECISION_PIPELINE_AUTOMATIONS";
export const CLEAR_DECISION_PIPELINE_AUTOMATIONS =
  "CLEAR_DECISION_PIPELINE_AUTOMATIONS";
export const SET_DECISION_PIPELINE_AUTOMATIONS_IS_LOADING_DATA =
  "SET_DECISION_PIPELINE_AUTOMATIONS_IS_LOADING_DATA";
export const FETCH_DECISION_PIPELINE_UPPER_FILTER_VALUES =
  "FETCH_DECISION_PIPELINE_UPPER_FILTER_VALUES";
export const EDIT_SINGLE_DECISION_PIPELINE_AUTOMATION =
  "EDIT_SINGLE_DECISION_PIPELINE_AUTOMATION";

//= =====================
// Leaderboard
//= =====================

export const FETCH_LEADERBOARD = "FETCH_LEADERBOARD";
export const FETCH_LEADERBOARD_USER_RANKING = "FETCH_LEADERBOARD_USER_RANKING";
export const CLEAR_LEADERBOARD_DATA = "CLEAR_LEADERBOARD_DATA";

//= =====================
// Cost Setup
//= =====================
export const FETCHING_DATA_FOR_SETUP_CURRENCY =
  "FETCHING_DATA_FOR_SETUP_CURRENCY";
export const FETCH_DATA_FOR_SETUP_CURRENCY = "FETCH_DATA_FOR_SETUP_CURRENCY";
export const FETCH_AVAILABLE_CURRENCY_OPTIONS =
  "FETCH_AVAILABLE_CURRENCY_OPTIONS";
export const SAVE_DATA_FOR_SETUP_CURRENCY = "SAVE_DATA_FOR_SETUP_CURRENCY";
export const FETCH_DATA_FOR_COST_SETUP_RPA_SOFTWARE_VENDOR =
  "FETCH_DATA_FOR_COST_SETUP_RPA_SOFTWARE_VENDOR";
export const EDIT_DATA_FOR_COST_SETUP_RPA_SOFTWARE_VENDOR =
  "EDIT_DATA_FOR_COST_SETUP_RPA_SOFTWARE_VENDOR";

//= =====================
// RPA Software Vendor
//= =====================

export const FETCHING_RPA_SOFTWARE_VENDOR_DATA =
  "FETCHING_RPA_SOFTWARE_VENDOR_DATA";
export const FETCH_RPA_SOFTWARE_VENDOR_DATA = "FETCH_RPA_SOFTWARE_VENDOR_DATA";
export const CLEAR_RPA_SOFTWARE_VENDOR_DATA = "CLEAR_RPA_SOFTWARE_VENDOR_DATA";
export const FETCH_RPA_SOFTWARE_VENDOR_DROPDOWN_DATA =
  "FETCH_RPA_SOFTWARE_VENDOR_DROPDOWN_DATA";

//= =====================
// OneTime Costs
//= =====================

export const FETCHING_ONE_TIME_COSTS_DATA = "FETCHING_ONE_TIME_COSTS_DATA";
export const FETCH_ONE_TIME_COSTS_DATA = "FETCH_ONE_TIME_COSTS_DATA";
export const CLEAR_ONE_TIME_COSTS_DATA = "CLEAR_ONE_TIME_COSTS_DATA";
export const FETCH_ONE_TIME_COSTS_DROPDOWN_DATA =
  "FETCH_ONE_TIME_COSTS_DROPDOWN_DATA";

//= =====================
// Running Costs
//= =====================

export const FETCHING_RUNNING_COSTS_DATA = "FETCHING_RUNNING_COSTS_DATA";
export const FETCH_RUNNING_COSTS_DATA = "FETCH_RUNNING_COSTS_DATA";
export const CLEAR_RUNNING_COSTS_DATA = "CLEAR_RUNNING_COSTS_DATA";
export const FETCH_RUNNING_COSTS_DROPDOWN_DATA =
  "FETCH_RUNNING_COSTS_DROPDOWN_DATA";

//= =====================
// Other Running Costs
//= =====================

export const FETCHING_OTHER_RUNNING_COSTS_DATA =
  "FETCHING_OTHER_RUNNING_COSTS_DATA";
export const FETCH_OTHER_RUNNING_COSTS_DATA = "FETCH_OTHER_RUNNING_COSTS_DATA";
export const FETCH_OTHER_RUNING_COSTS_DROPDOWN_DATA =
  "FETCH_OTHER_RUNING_COSTS_DROPDOWN_DATA";
export const CLEAR_OTHER_RUNNING_COSTS_DATA = "CLEAR_OTHER_RUNNING_COSTS_DATA";

//= =====================
// Automation Performance Dashboard
//= =====================
export const FETCH_AUTOMATION_PERFORMANCE_DATA =
  "FETCH_AUTOMATION_PERFORMANCE_DATA";
export const CLEAR_AUTOMATION_PERFORMANCE_DATA =
  "CLEAR_AUTOMATION_PERFORMANCE_DATA";
export const FETCH_APD_FILTER_VALUES = "FETCH_APD_FILTER_VALUES";
export const SET_APD_SEARCH_QUERY = "SET_APD_SEARCH_QUERY";
export const SET_APD_AUTOMATIONS_IS_LOADING_DATA =
  "SET_APD_AUTOMATIONS_IS_LOADING_DATA";

//= =====================
// Automation Cost Dashboard
//= =====================
export const FETCH_AUTOMATION_COST_DATA = "FETCH_AUTOMATION_COST_DATA";
export const CLEAR_AUTOMATION_COST_DATA = "CLEAR_AUTOMATION_COST_DATA";
export const FETCH_COST_DASHBOARD_FILTER_VALUES =
  "FETCH_COST_DASHBOARD_FILTER_VALUES";
export const SET_COST_DASHBOARD_SEARCH_QUERY =
  "SET_COST_DASHBOARD_SEARCH_QUERY";
export const SET_COST_DASHBOARD_IS_LOADING_DATA =
  "SET_COST_DASHBOARD_IS_LOADING_DATA";
//= =====================
// Account Licensing
//= =====================

export const FETCHING_LICENSE_PLAN_OPTIONS = "FETCHING_LICENSE_PLAN_OPTIONS";
export const FETCH_LICENSE_PLAN_OPTIONS = "FETCH_LICENSE_PLAN_OPTIONS";
export const SET_LICENSE_PLAN_OPTIONS = "FETCH_LICENSE_PLAN_OPTIONS";
