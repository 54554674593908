import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getChangeRequestSubmissionUrl } from "../../../utils/routeUtils";
import SearchModal from "../../SearchModal/SearchModal";
import { searchParentAutomationsForChangeRequest } from "../../../helpers/fetchParentAutomationsHelpers";
import { COProcessSubmissionType } from "@uipath/ah-co";

const ModalChangeRequestParentSelection = ({
  onClose
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState<any[]>([]);
  const debounceTimeout = useRef<any>(null);
  const history = useHistory();

  const handleSelectedItemChange = e => {
    setSelected([e.valueObj]);
  };

  const removeSelectedItem = index => {
    selected.splice(index, 1);
    setSelected([...selected]);
  };

  const handleAddSelectedItems = () => {
    if (selected.length === 1 && selected[0]?.process_id) {
      const linkLocation = getChangeRequestSubmissionUrl(
        selected[0].process_id
      );
      history.push(linkLocation);
      onClose();
    }
  };

  const loadAutomations = (inputValue, callback) => {
    window.clearTimeout(debounceTimeout.current);
    debounceTimeout.current = window.setTimeout(() => {
      const currentlySelectedAutomationIds: number[] = [...selected].map(
        obj => obj.process_id
      );
      searchParentAutomationsForChangeRequest({
        searchText: inputValue,
        ignoreProcessIDs: currentlySelectedAutomationIds,
        ignoreSubmissionTypes: [COProcessSubmissionType.CHANGE_REQUEST],
        callback: callback
      });
    }, 500);
  };

  return (
    <SearchModal
      heading={t("co_ca_select_parent_idea_heading", "Select a parent idea")}
      subtext={""}
      closeModal={onClose}
      loadOptions={loadAutomations}
      selected={selected}
      handleSelectedItemChange={handleSelectedItemChange}
      removeSelectedItem={removeSelectedItem}
      handleAddSelectedItems={handleAddSelectedItems}
      addButtonText={t("co_ca_continue_button", "Continue")}
    />
  );
};

export default ModalChangeRequestParentSelection;
