import React from "react";
import "./CuratorManageComponents.scss";
import Avatar from "../Avatar/Avatar";
import { Tooltip } from "@material-ui/core";

const CuratorManageComponents = props => {
  let { curator = {}, component_curator_user_id = null } = props;

  curator = {
    user_first_name: (curator || {}).user_first_name,
    user_last_name: (curator || {}).user_last_name,
    user_profile_pic: (curator || {}).user_profile_pic
  };

  const curator_id = component_curator_user_id;

  const fullName = `${curator.user_first_name} ${curator.user_last_name}`;

  const profilePicture = curator_id ? (
    <Avatar width="32px" user={curator} />
  ) : (
    ""
  );
  return (
    <div
      className="CuratorManageComponents"
      data-testid="CuratorManageComponentsContainer"
    >
      <Tooltip title={fullName} placement="top">
        <div>{profilePicture}</div>
      </Tooltip>
    </div>
  );
};

export default CuratorManageComponents;
