import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import DropDownNavAuth from "./../DropDownNavAuth/DropDownNavAuth";
import ButtonArrowDown from "./../ButtonArrowDown/ButtonArrowDown";
import { featureFlag } from "../../helpers/FeatureFlag";
import SessionHelper from "./../../helpers/SessionHelper";
import urlWithTenant, { setActiveLinkAttribute } from "../../utils/urlHelper";
import ModuleHelper from "../../helpers/ModuleHelper";
import {
  MANAGE_APP_INVENTORY_PERMISSION,
  MANAGE_COMPONENTS_PERMISSION,
  MANAGE_CATEGORIES_PERMISSION,
  MANAGE_PEOPLE_PERMISSION,
  MANAGE_ROLES_PERMISSION,
  MANAGE_TEMPLATES_PERMISSION,
  TENANT_SETTINGS_PERMISSION,
  MANAGE_OPEN_API_PERMISSION,
  ADMIN_AUDIT_LOGS_PERMISSION,
  MANAGE_COST_SETUP_PERMISSION,
  CUSTOMIZE_ASSESSMENT_PERMISSION,
  IMPORT_PIPELINE_PERMISSION,
  MANAGE_INTEGRATIONS_PERMISSION,
  ORCHESTRATOR_MAPPING_PERMISSION,
  INSIGHTS_CONNECTION_PERMISSION
} from "../../constants/adminPermissionsConstants";
import {
  triggerAnalyticsWithDefaultData,
  VIEW_USER_PERMISSIONS,
  VIEW_DOCUMENTATION,
  VIEW_COST_SETUP,
  VIEW_SETTING_PAGE,
  VIEW_USER_ROLES
} from "../../utils/analyticsUtils";
import TenantSettingsHelper from "../../helpers/TenantSettingsHelper";
import "./NavAdmin.scss";
import { NavLink } from "../NavAuth/NavAuth";
import styled, { css } from "styled-components/macro";
import { canViewAdminAccountPage } from "../../utils/permissionVisibilityUtils";
import {
  elementIsChildOfQuery,
  eventIsEscapeKey
} from "../../utils/elementUtils";

const NavInnerContainer = styled.div(
  ({ theme }) => css`
    /* TODO: Add back max width when all page containers finished */
    /* max-width: ${theme.sizes["container-max-width-stretch"]}; */
    width: 100%;
    ${SessionHelper.isFpsUri() ? "" : `margin: 0 auto;`}
    display: flex;
    align-items: center;
  `
);

class NavAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleNav = () => {
    this.setState(prevState => {
      const state = { ...prevState };
      state.isOpen = !state.isOpen;
      if (state.isOpen) {
        window.addEventListener("keydown", this.closeMobileNavOnEscapeKey);
        document.body.addEventListener("click", this.closeNavListener);
      } else {
        this.removeCloseNavListeners();
      }
      return state;
    });
  };

  closeNav = () => {
    this.setState({ isOpen: false });
    this.removeCloseNavListeners();
  };

  closeNavListener = e => {
    if (!elementIsChildOfQuery(e.target, ".NavAdmin")) {
      this.closeNav();
    }
  };

  removeCloseNavListeners = () => {
    window.removeEventListener("keydown", this.closeMobileNavOnEscapeKey);
    document.body.removeEventListener("click", this.closeNavListener);
  };

  closeMobileNavOnEscapeKey = e => {
    if (eventIsEscapeKey(e)) {
      this.closeNav();
    }
  };

  onUserPermissionsClick = () => {
    triggerAnalyticsWithDefaultData(VIEW_USER_PERMISSIONS);
    this.closeNav();
  };

  onDocumentationClick = () => {
    triggerAnalyticsWithDefaultData(VIEW_DOCUMENTATION);
    this.closeNav();
  };

  onCostSetupClick = () => {
    triggerAnalyticsWithDefaultData(VIEW_COST_SETUP);
    this.closeNav();
  };

  onImportPipelineClick = () => {
    this.closeNav();
  };

  onUserRolesClick = () => {
    triggerAnalyticsWithDefaultData(VIEW_USER_ROLES);
    this.closeNav();
  };

  render() {
    const navItemsOpen = this.state.isOpen ? "NavAdmin__items--open" : "";
    const isFpsUri = SessionHelper.isFpsUri();
    let user = SessionHelper.currentUser() ? SessionHelper.currentUser() : [];

    let showAccountPage = false;
    let showTenantSettingPage = false;
    let showOrchestratorConnectionPage = false;
    let showJiraIntegrationPage = false;
    let showAuditLogsPage = false;

    let showManageUsers = false;
    let showPlatformSetup = false;
    let showManageComponents = false;
    let showImportPipeline = false;

    let showManageAppInventory = false;
    let showManageHierarchy = false;
    let showManageTemplates = false;
    let showManagePeople = false;
    let showManageRoles = false;

    let showIdeaAsssessment = false;

    let showCostSetup = false;

    let showOpenApiManagement = false;
    let showOrchestratorMappingPage = false;
    let showInsightsIntegrationsPage = false;

    if (user) {
      if (user.ah_permissions) {
        showAccountPage = canViewAdminAccountPage(user.ah_permissions);

        if (
          user.ah_permissions[MANAGE_COMPONENTS_PERMISSION] &&
          ModuleHelper.isGoEnabled() &&
          TenantSettingsHelper.showComponents()
        ) {
          showManageComponents = true;
        }
        if (user.ah_permissions[MANAGE_APP_INVENTORY_PERMISSION]) {
          showPlatformSetup = true;
          showManageAppInventory = true;
        }
        if (user.ah_permissions[IMPORT_PIPELINE_PERMISSION]) {
          showPlatformSetup = true;
          showImportPipeline = true;
        }
        if (user.ah_permissions[MANAGE_CATEGORIES_PERMISSION]) {
          showPlatformSetup = true;
          showManageHierarchy = true;
        }
        if (
          user.ah_permissions[MANAGE_TEMPLATES_PERMISSION] &&
          ModuleHelper.isNavigatorEnabled()
        ) {
          showPlatformSetup = true;
          showManageTemplates = true;
        }
        if (user.ah_permissions[MANAGE_PEOPLE_PERMISSION]) {
          showManageUsers = true;
          showManagePeople = true;
        }
        if (user.ah_permissions[MANAGE_ROLES_PERMISSION]) {
          showManageUsers = true;
          showManageRoles = true;
        }
        if (user.ah_permissions[MANAGE_COST_SETUP_PERMISSION]) {
          showCostSetup = true;
          showPlatformSetup = true;
        }
        if (user.ah_permissions[TENANT_SETTINGS_PERMISSION]) {
          showTenantSettingPage = true;
          showPlatformSetup = true;
          showCostSetup = true;
          showOrchestratorConnectionPage = isFpsUri;
        }

        if (user.ah_permissions[ADMIN_AUDIT_LOGS_PERMISSION]) {
          if (featureFlag.isAdminAuditLogsEnabled()) {
            showAuditLogsPage = true;
          }
        }

        if (user.ah_permissions[MANAGE_OPEN_API_PERMISSION]) {
          if (featureFlag.isAdminManageOpenApiEnabled()) {
            showOpenApiManagement = true;
            showPlatformSetup = true;
          }
        }

        if (
          ModuleHelper.isNavigatorEnabled() &&
          user.ah_permissions[CUSTOMIZE_ASSESSMENT_PERMISSION]
        ) {
          showPlatformSetup = true;
          showIdeaAsssessment = true;
        }

        if (
          user.ah_permissions[MANAGE_INTEGRATIONS_PERMISSION] &&
          isFpsUri &&
          featureFlag.isJiraIntegrationEnabled()
        ) {
          showPlatformSetup = true;
          showJiraIntegrationPage = true;
        }

        if (
          user.ah_permissions[ORCHESTRATOR_MAPPING_PERMISSION] &&
          isFpsUri &&
          featureFlag.isOrchestratorMappingEnabled()
        ) {
          showPlatformSetup = true;
          showOrchestratorMappingPage = true;
        }

        if (
          user.ah_permissions[INSIGHTS_CONNECTION_PERMISSION] &&
          isFpsUri &&
          featureFlag.isInsightsPrivatePreviewEnabled()
        ) {
          showPlatformSetup = true;
          showInsightsIntegrationsPage = true;
        }
      }
    }

    let isAccountDropdown = showTenantSettingPage || showAuditLogsPage;

    return (
      <nav
        className={`NavAdmin ${isFpsUri ? ` NavAdmin--fps` : ""}`}
        data-testid="NavAdmin"
      >
        <NavInnerContainer>
          <div
            className={`NavAdmin__hamburger-container ${
              isFpsUri ? ` NavAdmin__hamburger-container--fps` : ""
            }`}
          >
            <ButtonArrowDown
              isOpen={this.state.isOpen}
              toggleNav={this.toggleNav}
            />
          </div>
          <div className={`NavAdmin__items ${navItemsOpen}`}>
            {showAccountPage ? (
              isAccountDropdown ? (
                <DropDownNavAuth
                  className="NavAdmin__account-dropdown"
                  key={`Account ${this.state.isOpen}`}
                  title={this.props.t(["amdin_nav_account", "Account"])}
                  adminOptions
                >
                  <NavLink
                    to={urlWithTenant("/admin/account")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_account_info", "Account Info"])}
                  </NavLink>

                  {showTenantSettingPage ? (
                    <NavLink
                      to={urlWithTenant("/admin/tenant-settings")}
                      onClick={() => {
                        triggerAnalyticsWithDefaultData(VIEW_SETTING_PAGE);
                        this.onUserPermissionsClick();
                      }}
                      match={this.props.match}
                    >
                      {this.props.t(["amdin_nav_tenant_settings", "Settings"])}
                    </NavLink>
                  ) : (
                    ""
                  )}

                  {showAuditLogsPage ? (
                    <NavLink
                      to={urlWithTenant("/admin/audit-logs")}
                      onClick={() => {
                        this.closeNav();
                      }}
                      match={this.props.match}
                    >
                      {this.props.t(["admin_nav_audit_logs", "Audit Logs"])}
                    </NavLink>
                  ) : (
                    ""
                  )}
                </DropDownNavAuth>
              ) : (
                <Link
                  to={urlWithTenant("/admin/account")}
                  onClick={this.closeNav}
                >
                  <span
                    className="NavAdmin_link NavAdmin_link--first"
                    data-active-link={setActiveLinkAttribute(
                      urlWithTenant("/admin/account"),
                      this.props.match
                    )}
                  >
                    {this.props.t("amdin_nav_account")}
                  </span>
                </Link>
              )
            ) : null}

            {showManageUsers ? (
              <DropDownNavAuth
                key={`Manage Users ${this.state.isOpen}`}
                title={this.props.t([
                  "amdin_nav_manage_users",
                  "Manage Access"
                ])}
                adminOptions
              >
                {showManagePeople ? (
                  <NavLink
                    to={urlWithTenant("/admin/manage-users")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_people", "Assign Roles"])}
                  </NavLink>
                ) : null}
                {showManageRoles ? (
                  <NavLink
                    to={urlWithTenant("/admin/user-roles-permissions")}
                    onClick={this.onUserRolesClick}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_user_roles", "Roles"])}
                  </NavLink>
                ) : null}
              </DropDownNavAuth>
            ) : null}

            {showPlatformSetup ? (
              <DropDownNavAuth
                key={`platform setup ${this.state.isOpen}`}
                title={this.props.t([
                  "amdin_nav_platform_setup",
                  "Platform Setup"
                ])}
                adminOptions
              >
                {showManageHierarchy ? (
                  <NavLink
                    to={urlWithTenant("/admin/categories")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_categories", "Categories"])}
                  </NavLink>
                ) : null}
                {showManageAppInventory ? (
                  <NavLink
                    to={urlWithTenant("/admin/app-inventory")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_app_inventory", "App Inventory"])}
                  </NavLink>
                ) : null}
                {showManageTemplates ? (
                  <NavLink
                    to={urlWithTenant("/admin/documentation")}
                    onClick={this.onDocumentationClick}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_documentation", "Documentation"])}
                  </NavLink>
                ) : null}
                {showIdeaAsssessment ? (
                  <NavLink
                    to={urlWithTenant("/admin/idea-assessment")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t([
                      "amdin_nav_idea_assessment",
                      "Customize Assessments"
                    ])}
                  </NavLink>
                ) : null}
                {showCostSetup ? (
                  <NavLink
                    to={urlWithTenant("/admin/cost-setup")}
                    onClick={this.onCostSetupClick}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_cost_setup", "Cost Setup"])}
                  </NavLink>
                ) : null}
                {featureFlag.isImportPipelineEnabled() && showImportPipeline ? (
                  <NavLink
                    to={urlWithTenant("/admin/import-pipeline")}
                    onClick={this.onImportPipelineClick}
                    match={this.props.match}
                  >
                    {this.props.t([
                      "admin_nav_import_pipeline",
                      "Import Pipeline"
                    ])}
                  </NavLink>
                ) : null}
                {showOpenApiManagement ? (
                  <NavLink
                    to={urlWithTenant("/admin/open-api-management")}
                    onClick={this.closeNav}
                    match={this.props.match}
                  >
                    {this.props.t(["amdin_nav_open_api", "Open API"])}
                  </NavLink>
                ) : null}
                {TenantSettingsHelper.isAutomationStoreEnabled() &&
                  isFpsUri &&
                  showOrchestratorConnectionPage &&
                  featureFlag.isAutomationStoreEnabled() && (
                    <NavLink
                      to={urlWithTenant("/admin/orchestrator-connection")}
                      onClick={this.closeNav}
                      match={this.props.match}
                      userPermissions={user.ah_permissions}
                    >
                      {this.props.t([
                        "admin_nav_orchestrator_connection",
                        "Orchestrator Connection"
                      ])}
                    </NavLink>
                  )}

                {showOrchestratorMappingPage && (
                  <NavLink
                    to={urlWithTenant("/admin/orchestrator-mapping")}
                    match={this.props.match}
                  >
                    {this.props.t([
                      "admin_nav_orchestrator_mapping",
                      "Orchestrator Mapping"
                    ])}
                  </NavLink>
                )}

                {showInsightsIntegrationsPage && (
                  <NavLink
                    to={urlWithTenant("/admin/insights-connection")}
                    onClick={this.closeNav}
                    match={this.props.match}
                    userPermissions={user.ah_permissions}
                  >
                    {this.props.t([
                      "insights_connection_title",
                      "Insights Connection"
                    ])}
                  </NavLink>
                )}

                {showJiraIntegrationPage && (
                  <NavLink
                    to={urlWithTenant("/admin/jira-connection")}
                    onClick={this.closeNav}
                    match={this.props.match}
                    userPermissions={user.ah_permissions}
                  >
                    {this.props.t([
                      "amdin_nav_jira_connection",
                      "Jira Connection"
                    ])}
                  </NavLink>
                )}
              </DropDownNavAuth>
            ) : null}

            {showManageComponents ? (
              <Link
                to={urlWithTenant("/admin/manage-components")}
                onClick={this.closeNav}
              >
                <span
                  className="NavAdmin_link"
                  data-active-link={setActiveLinkAttribute(
                    urlWithTenant("/admin/manage-components"),
                    this.props.match
                  )}
                >
                  {this.props.t([
                    "amdin_nav_manage_components",
                    "Manage Components"
                  ])}
                </span>
              </Link>
            ) : null}
          </div>
        </NavInnerContainer>
      </nav>
    );
  }
}

const mapStateToProps = ({ translation }) => ({
  translation
});

export default withRouter(
  withTranslation("common")(connect(mapStateToProps)(NavAdmin))
);
