import {
  COErrorTypes,
  COTypes,
  CO_NON_TABULAR_DEFAULT_INDEX,
  CO_TABULAR_AGGREGATION_OPERATORS
} from "../constants/co-constants";
import { coMathEvaluate, coMathValidateEquation } from "../libraries/co-math";
import { errorKeyFromPath } from "../utils/co-path.utils";
import { isNullOrUndefined } from "../utils/co-utils";
import {
  COAssessmentInterface,
  COContextInterface,
  COQuestionInterface,
  COValidationError
} from "../interfaces/co-interfaces";
import { variableIsFunction } from "../constants/co-calculation.constants";
import { QUESTION_EQUATION_VALIDATE_TARGET } from "../constants/co-equation.constants";
import { optionsInContext } from "./co-context.helper";
import { updateContext } from "../classes/co-context.class";

// breaks out into display string using variables from the questions in the assessment
// returns warnings / errors if it can't find a variable
export const CO_EQUATION_AHID_MATCH_ALL_REGEX = () => {
  return /\$\((\S*?)\)/g;
};
export const CO_AHID_REPLACE_REGEX = ahid => {
  return new RegExp("\\$\\(" + ahid + "\\)", "g");
};

export const CO_AHID_REPLACE_REGEX_WITH_AGGREGATION = (
  ahid,
  aggregation_operator
) => {
  return new RegExp(
    aggregation_operator + "\\(" + "\\$\\(" + ahid + "\\)\\)",
    "g"
  );
};

const CO_AHID_INSERT_REGEX = ahid => {
  return CO_VARIABLE_RESOLVED_VALUE_INSERT_REGEX(`$(${ahid})`);
};

export const CO_EQUATION_VAR_MATCH_ALL_REGEX = () => {
  return /([^A-Z_\S]|^|\W)([A-Z_]{3,})/g;
};

// ([^A-Z_\S]|^|\W)(VOLUMES)([^A-Z_\S]|^|\W)
export const CO_VAR_REPLACE_REGEX = variable => {
  let regex = new RegExp(
    "([^A-Z_S]|^|\\W)(" + variable + ")([^A-Z_S]|$|\\W)",
    "g"
  );
  return regex;
  //return new RegExp("\\[" + variable + "\\]", "g");
};
// the {} in the capture group is important because if it's a number $1 becomes $11
export const CO_VARIABLE_RESOLVED_VALUE_INSERT_REGEX = value => {
  return `$1[AHVAR]${value}[AHVAR]$3`;
};
export const CO_VARIABLE_RESOLVED_INERT_REGEX_CLEANUP_REGEX = () => {
  return /\[AHVAR\]/g;
};
export const CO_AHID_RESOLVED_VALUE_INSERT_REGEX = value => {
  return `${value}`;
};

const CO_VAR_INSERT_REGEX = variable => {
  return `${variable}`;
  //return `[${variable}]`;
};

export const parseRawEquation = ({
  context,
  question,
  rawEquation
}: {
  context: COContextInterface;
  question?: COQuestionInterface;
  rawEquation?: string;
}): {
  rawEquation: string;
  displayEquation: string;
  questionsInEquation: COQuestionInterface[];
  questionsAHIdsNotFound: string[];
  validation_errors: COValidationError[];
} => {
  // ok an equation is comprised of AH-IDS
  let questionsInEquation: COQuestionInterface[] = [];
  let validation_errors: COValidationError[] = [];
  rawEquation = rawEquation || (question && question.co_equation) || "";
  let displayEquation = rawEquation;

  // equation has things like + / * and () and answer() and whatever
  // we're storing the ahids in there
  // $(AHID)
  //rawEquation = "1 + $(ahid-abcd-cd) + (3+1.2/$(fsdf-fdsf-fds))/answer()";

  const ahids = ahidsInEquation({ equation: rawEquation });
  let questionsAHIdsNotFound: string[] = [];
  let assessment: COAssessmentInterface | undefined = context.assessment;
  if (assessment) {
    for (const ahidFound of ahids) {
      if (assessment.findAHIds) {
        let questionsFound: COQuestionInterface[] =
          assessment.findAHIds(ahidFound) || [];

        // if we can find more than one - then it's tabular and we need to find the one with the same tabular index
        // this lets a KPI within a row reference the questions inside that row
        if (questionsFound.length > 0) {
          let table_index = isNullOrUndefined(question?.co_table_row_index)
            ? -1
            : parseInt(question?.co_table_row_index + "");

          if (table_index != CO_NON_TABULAR_DEFAULT_INDEX) {
            const table_index_match:
              | COQuestionInterface
              | undefined = questionsFound.find(
              q => parseInt(q.co_table_row_index + "") == table_index
            );

            if (table_index_match) {
              questionsFound = [table_index_match];
            }
          } else {
            // this means that i'ts tabular - and we want to send down all the questionsFound to the calculation system
          }
        }

        // this will add all of the questions being found here

        if (questionsFound.length > 0) {
          for (const questionInEquation of questionsFound) {
            // the question may be in there more than once
            if (
              !questionsInEquation.find(
                q =>
                  q.co_question_ahid === questionInEquation?.co_question_ahid &&
                  q.co_table_row_index ===
                    questionInEquation?.co_table_row_index
              )
            ) {
              questionsInEquation.push(questionInEquation);
              let question_variable =
                questionInEquation.co_variable_name || "Unknown Variable";
              if (ahidFound) {
                let regex: RegExp = CO_AHID_REPLACE_REGEX(ahidFound);
                displayEquation = displayEquation.replace(
                  regex,
                  CO_VAR_INSERT_REGEX(question_variable)
                );
              }
            }
          }
        } else {
          questionsAHIdsNotFound.push(ahidFound);
          let validationError: COValidationError = {
            error_message: `Unable to Find a Referenced Question in Equation for ${question?.objectDisplayName?.()}`,
            error_type: COErrorTypes.IMPACTS_CALCULATION,
            error_key: errorKeyFromPath(
              context,
              QUESTION_EQUATION_VALIDATE_TARGET
            ),
            problem_object: question,
            problem_property: ahidFound
          };
          validation_errors.push(validationError);

          // let's not display the ahid - it's super ugly
          let regex: RegExp = CO_AHID_REPLACE_REGEX(ahidFound);
          displayEquation = displayEquation.replace(
            regex,
            CO_VAR_INSERT_REGEX("UNKNOWN_VARIABLE")
          );
        }
      }
    }
  }

  return {
    rawEquation,
    displayEquation,
    questionsInEquation,
    questionsAHIdsNotFound,
    validation_errors
  };
};

export const parseDisplayEquation = ({
  context,
  question,
  displayEquation
}: {
  context: COContextInterface;
  question?: COQuestionInterface;
  displayEquation?: string;
}): {
  rawEquation: string;
  displayEquation: string;
  questionsInEquation: COQuestionInterface[];
  questionVariablesNotFound: string[];
  validation_errors: COValidationError[];
} => {
  // ok an equation is comprised of AH-IDS
  let questionsInEquation: COQuestionInterface[] = [];
  let validation_errors: COValidationError[] = [];

  displayEquation = isNullOrUndefined(displayEquation)
    ? (question && question.co_display_equation) || ""
    : displayEquation || "";

  let rawEquation = displayEquation + "";

  // equation has things like + / * and () and answer() and whatever
  // we're storing the ahids in there
  // $(AHID)
  //displayeuqatio  = "1 + fsdfs_fdsfd_fdsfs + (3+1.2/fsdfs_fdsfd_fdsfs)/answer()";

  const variables = variablesInEquation({ equation: displayEquation });
  let questionVariablesNotFound: string[] = [];
  let assessment: COAssessmentInterface | undefined = context.assessment;
  if (assessment) {
    for (const varFound of variables) {
      if (assessment.findObjectsWithPropertyOfValue) {
        let foundQuestions: COQuestionInterface[] = assessment.findObjectsWithPropertyOfValue(
          "co_variable_name",
          varFound
        );
        if (foundQuestions && foundQuestions.length > 0) {
          // if we have mulitple questions with the same EQ that's bad
          if (foundQuestions.length > 1) {
            let validationError: COValidationError = {
              error_message: `Duplicate Variable ${
                foundQuestions[0].co_variable_name
              } in Question  ${foundQuestions[0].objectDisplayName?.(
                false
              )} and in in Question  ${foundQuestions[1].objectDisplayName?.(
                false
              )}`,
              error_type: COErrorTypes.IMPACTS_CALCULATION,
              error_key: errorKeyFromPath(
                context,
                QUESTION_EQUATION_VALIDATE_TARGET
              ),
              problem_object: foundQuestions,
              problem_property: varFound
            };
            validation_errors.push(validationError);
          }
          let foundQuestion = foundQuestions[0];
          // the question may be in there more than once
          if (
            !questionsInEquation.find(
              q => q.co_question_ahid === foundQuestion?.co_question_ahid
            )
          ) {
            questionsInEquation.push(foundQuestion);
            let question_ahid =
              foundQuestion.co_question_ahid || "Unknown Question";
            if (varFound) {
              let regex = CO_VAR_REPLACE_REGEX(varFound);
              rawEquation = rawEquation
                .replace(regex, CO_AHID_INSERT_REGEX(question_ahid))
                .replace(CO_VARIABLE_RESOLVED_INERT_REGEX_CLEANUP_REGEX(), "");
            }
          }

          // replace out our thing for the real variable
        } else {
          questionVariablesNotFound.push(varFound);
          let validationError: COValidationError = {
            error_message: `Unable to Find Referenced Variable ${varFound} in Equation ${question?.objectDisplayName?.(
              false
            )}`,
            error_type: COErrorTypes.IMPACTS_CALCULATION,
            error_key: errorKeyFromPath(
              context,
              QUESTION_EQUATION_VALIDATE_TARGET
            ),
            problem_object: question,
            problem_property: varFound
          };
          validation_errors.push(validationError);
        }
      }
    }
  }

  return {
    rawEquation,
    displayEquation,
    questionsInEquation,
    questionVariablesNotFound,
    validation_errors
  };
};

export const variablesInEquation = ({
  equation,
  includeFunctions = false
}: {
  equation?: string;
  includeFunctions?: boolean;
}): string[] => {
  let variables: string[] = [];
  if (equation) {
    let results = equation.matchAll(CO_EQUATION_VAR_MATCH_ALL_REGEX());
    for (const match of results) {
      if (match && match.length > 2) {
        // second capture group
        let varFound = match[2];
        if (
          varFound && includeFunctions ? true : !variableIsFunction(varFound)
        ) {
          variables.push(varFound);
        }
      }
    }
  }
  return variables;
};

export const replaceAHIDInEquationWithEmpty = ({
  equation,
  ahid
}: {
  equation?: string;
  ahid?: string;
}): string => {
  let equationReplaced = equation || "";
  equationReplaced = equationReplaced.replace(
    CO_AHID_REPLACE_REGEX(ahid),
    CO_AHID_RESOLVED_VALUE_INSERT_REGEX("")
  );
  return equationReplaced;
};

export const replaceVariablesAndAHIdsWith1 = ({
  equation
}: {
  equation?: string;
}): string => {
  let equationReplaced = equation || "";
  let varsInEq = variablesInEquation({ equation, includeFunctions: true });
  for (const variable of varsInEq) {
    equationReplaced = equationReplaced
      .replace(
        CO_VAR_REPLACE_REGEX(variable),
        CO_VARIABLE_RESOLVED_VALUE_INSERT_REGEX("1")
      )
      .replace(CO_VARIABLE_RESOLVED_INERT_REGEX_CLEANUP_REGEX(), "");
  }

  let ahids = ahidsInEquation({ equation });
  for (const ahid of ahids) {
    equationReplaced = equationReplaced.replace(
      CO_AHID_REPLACE_REGEX(ahid),
      CO_AHID_RESOLVED_VALUE_INSERT_REGEX("1")
    );
  }

  return equationReplaced;
};

export const ahidsInEquation = ({
  equation
}: {
  equation?: string;
}): string[] => {
  let ahids: string[] = [];
  if (equation) {
    let results = equation.matchAll(CO_EQUATION_AHID_MATCH_ALL_REGEX());
    for (const match of results) {
      if (match && match.length > 1) {
        // internal result is ahid
        let ahidFound = match[1];
        if (ahidFound) {
          ahids.push(ahidFound);
        }
      }
    }
  }
  return ahids;
};

export const validateEquationFormat = ({
  context,
  equation,
  question
}: {
  context: COContextInterface;
  question?: COQuestionInterface;
  equation: string;
}): COValidationError[] => {
  let errors: COValidationError[] = [];
  let resolvedEquation = replaceVariablesAndAHIdsWith1({ equation });

  try {
    coMathValidateEquation(resolvedEquation);
    coMathEvaluate(resolvedEquation);
  } catch (error) {
    let err: any = error;
    console.log(err.message);

    const questionName = question?.objectDisplayName?.(false);
    errors.push({
      error_message: `Unable to Validate Equation for {{question_name}}: {{error_message}}`,
      error_localization_key: "co_equation_error_unable_to_validate_equation",
      error_localization_values: {
        question_name: questionName
          ? questionName
          : {
              value: "Question",
              localization_key: "co_equation_error_default_question_name"
            },
        error_message: err?.message
          ? err?.message
          : {
              value: "Please Check Parentheses and Operators",
              localization_key: "co_question_error_check_parameters"
            }
      },
      error_key: errorKeyFromPath(context, QUESTION_EQUATION_VALIDATE_TARGET),
      error_type: COErrorTypes.IMPACTS_CALCULATION,
      problem_object: question
    });
  }
  return errors;
};

export const equationIsRaw = (equation: string) => {
  let matches = equation.matchAll(CO_EQUATION_AHID_MATCH_ALL_REGEX());
  if (matches) {
    for (const match of matches) {
      if (match && match.length > 0) {
        return true;
      }
    }
  }
  return false;
};

export const allKPIsInContext = ({
  context
}: {
  context: COContextInterface;
}): COQuestionInterface[] => {
  let kpis: COQuestionInterface[] = [];
  // loop through all kpis
  for (const section of context.assessment?.co_assessment_sections || []) {
    for (const q of section.co_questions || []) {
      if (q.co_question_co_type === COTypes.KPI) {
        kpis.push(q);
      }
    }
  }
  return kpis;
};

export const kpisWithEquationReferencingQuestion = ({
  context,
  question
}: {
  context: COContextInterface;
  question: COQuestionInterface;
}): COQuestionInterface[] => {
  let kpisReferencingQuestion: COQuestionInterface[] = [];
  // loop through all kpis
  for (const section of context.assessment?.co_assessment_sections || []) {
    for (const q of section.co_questions || []) {
      if (q.co_question_co_type === COTypes.KPI) {
        const ahids = ahidsInEquation({ equation: q.co_equation });
        for (const ahid of ahids) {
          if (question.co_question_ahid === ahid) {
            if (!kpisReferencingQuestion.includes(q)) {
              kpisReferencingQuestion.push(q);
            }
          }
        }
      }
    }
  }
  return kpisReferencingQuestion;
};

export const getOtherQuestionsInAssessmentWithVariablesThatCanImpactKpi = (
  context: COContextInterface
): COQuestionInterface[] => {
  const currentQuestion: COQuestionInterface | undefined = context?.question;
  const otherQuestionsWithVariables: COQuestionInterface[] = [];
  const assessment: COAssessmentInterface | undefined = context?.assessment;
  for (const section of assessment?.co_assessment_sections || []) {
    for (const question of section.co_questions || []) {
      let questionOptions = optionsInContext({
        context: updateContext(context, { question }),
        options: question.options || {}
      });
      if (
        //don't pick self
        currentQuestion?.co_question_ahid !== question?.co_question_ahid &&
        // Needs a variable name
        question?.co_variable_name &&
        // Question must be able to impact kpi
        questionOptions?.can_impact_kpi
      ) {
        otherQuestionsWithVariables.push(question);
      }
    }
  }
  return otherQuestionsWithVariables;
};
