import { CONotificationActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COActionReplacementStrings,
  AUTOMATION_PROFILE_URL
} from "../../../../../constants/actions/co-action-constants";
import {
  CONotificationActionSlugs,
  CONotificationActionType,
  CONotificationActionGroupSlugs,
  CONotificationActionCategoryTypes
} from "../../../../../constants/actions/co-notification-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPlatformEventType } from "../../../../../constants/actions/co-platform-events-constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const followedAutomationUpdateNotificationActionTemplate = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  // get template based on slug
  let template = followedAutomationUpdateNotificationActionTemplateFromSlug(
    slug
  );
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      notification_type: CONotificationActionType.FOLLOWED_AUTOMATION_UPDATES,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const followedAutomationUpdateNotificationActionTemplateFromSlug = (
  slug: string
): CONotificationActionTemplateInterface | undefined => {
  switch (slug) {
    case CONotificationActionSlugs.SUBMITTED_IDEA_FOLLOWED_APPROVED: {
      return {
        co_options_json: {
          is_deleted: 1,
          is_inactive: 1,
          action_target: {
            target_roles: [CORoleType.FOLLOWER_IN_AP]
          },
          legacy_notification_id: 13,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_APPROVED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.SUBMITTED_IDEA_APPROVED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Idea that I follow was approved by DM"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.IDEA_FOLLOWED_REJECTED: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.FOLLOWER_IN_AP]
          },
          legacy_notification_id: 18,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_REJECTED
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.SUBMITTED_IDEA_REJECTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Idea that I follow was rejected by DM"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case CONotificationActionSlugs.IDEA_FOLLOWED_MARKED_AS_DUPLICATE: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.FOLLOWER_IN_AP]
          },
          legacy_notification_id: 21,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_DUPLICATE
          ],
          notification_category_type:
            CONotificationActionCategoryTypes.AUTOMATION,
          notification_group_slug:
            CONotificationActionGroupSlugs.DUPLICATE_SUBMITTED_IDEA,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Idea that I follow was marked as duplicate by DM"
          },
          message: {
            value:
              "" +
              COActionReplacementStrings.USER_FULL_NAME +
              " updated the automation " +
              COActionReplacementStrings.PROCESS_NAME +
              " to the phase " +
              COActionReplacementStrings.PROCESS_PHASE +
              " and status " +
              COActionReplacementStrings.PROCESS_STATUS +
              "."
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
  }
  return undefined;
};
