import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import { swalClose } from "../../../SweetAlert/SweetAlert";
import ModalAccountLicense from "../ModalAccountLicense";
import { successSwal } from "../../../../utils/accountLicenseAlerts";
import {
  swalSpinnerLoading,
  swalSpinnerClose
} from "../../../../utils/SweetAlertLoaderSpinner";
import { hideModal } from "../../../../actions/modalActions";
import {
  fetchLicensePlanOptions,
  renewLicense
} from "../../../../actions/accountLicenseActions";
import { fetchTenantToEdit } from "../../../../actions/userAdminActions";
import { REQUEST_RENEWAL_FIELDS } from "../../../../constants/accountLicenseConstants";
import { validateRequestRenewalFields } from "../..//../../utils/accountLicenseValidationHelper";
import { parseDataForRequest } from "../../../../utils/accountLicenseUtils";

class ModalBuyMoreLicenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: cloneDeep(REQUEST_RENEWAL_FIELDS)
    };
  }

  componentDidMount() {
    //replace later with the request for 'Request Renewal'
    if (!this.props.licenseDropdownOptions) {
      this.props.fetchLicensePlanOptions();
    }
  }

  setStateWithObj = obj => {
    this.setState(obj);
  };

  handleSave = () => {
    const validationObj = validateRequestRenewalFields(this.state.fields);

    if (validationObj.hasError) {
      this.setStateWithObj({ fields: validationObj.updatedState });
    } else {
      const parsedData = parseDataForRequest(this.state.fields);
      //comment back in when back end is implemented
      // this.sendRequest(parsedData);
    }
  };

  sendRequest = data => {
    swalSpinnerLoading();
    this.props.renewLicense({ data, callback: this.afterSaveRequest });
  };

  afterSaveRequest = response => {
    swalSpinnerClose();
    this.props.hideModal();
    successSwal({});

    setTimeout(() => {
      swalClose();
    }, 2000);
  };

  render() {
    return (
      <ModalAccountLicense
        header={this.props.t([
          "modal_request_renewal_header",
          "Request Renewal"
        ])}
        requestSwalTitle={this.props.t([
          "modal_request_renewal_title",
          "Request License Renewal?"
        ])}
        fields={this.state.fields}
        dropdownOptions={this.props.licenseDropdownOptions}
        setStateWithObj={this.setStateWithObj}
        handleCancel={this.handleCancel}
        handleSave={this.handleSave}
        closeModalCallback={this.closeModalCallback}
      />
    );
  }
}

const mapStateToProps = ({ accountLicense, userAdmin }) => ({
  licenseDropdownOptions: accountLicense.licenseDropdownOptions,
  tenantInformation: userAdmin.tenantInformation
});

const mapDispatchToProps = {
  hideModal,
  fetchLicensePlanOptions,
  fetchTenantToEdit,
  renewLicense
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ModalBuyMoreLicenses)
);
