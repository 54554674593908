import auth0 from "auth0-js";
import { auth } from "./auth";
import SessionHelper from "../helpers/SessionHelper";
import {
  AUTH_DOMAIN,
  AUTH_CLIENT_ID,
  AUTH_RESPONSE_TYPE,
  AUTH_SCOPE,
  AUTH_AUDIENCE
} from "../constants/envConstants";
import {
  SIGNUP_TENANT_NAME,
  SELECTED_LANGUAGE
} from "../constants/routerConstants";
import { AUTH_TYPE_AUTH0 } from "../constants/authConstants";
import {
  SwalForRegistering,
  SwalForRegisteringError
} from "./../components/SweetAlert/SweetAlert";
import { API_URL } from "./../constants/envConstants";
import apiErrorAlert, {
  checkForAndRedirectFromBackend
} from "../utils/apiErrorAlert";
import { swalSpinnerSuccess } from "./../utils/SweetAlertLoaderSpinner";
import axios from "axios";
import { LANGUAGE_COOKIE_NAME } from "../constants/localizationConstants";
export default class Auth0Handler {
  redirectUri = () => {
    let url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/login-redirect";
    return url;
  };
  logOutRedirectUri = () => {
    let url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/logout-redirect";
    return url;
  };
  auth0 = new auth0.WebAuth({
    domain: AUTH_DOMAIN,
    clientID: AUTH_CLIENT_ID,
    responseType: AUTH_RESPONSE_TYPE,
    scope: AUTH_SCOPE,
    audience: AUTH_AUDIENCE,
    redirectUri: this.redirectUri()
  });
  //this triggers the login and returns to the app with a token to the redirectUri
  authorize = () => {
    //we redirect to a non tenant page (login-redirect and logout redirect) - then we route on the client side to the proper page
    // Need to set tenantName as query param
    const tenantName = SessionHelper.tenantNameFromCurrentPath() || "";

    // Also set tenant name in storage as fallback if query param
    // does not come back
    SessionHelper.setLoginLogoutTenantNameWithoutPrefix(tenantName);

    this.auth0.authorize({
      audience: AUTH_AUDIENCE,
      scope: AUTH_SCOPE,
      responseType: AUTH_RESPONSE_TYPE,
      redirectUri: this.redirectUri() + `?tenantName=${tenantName}`
    });
  };

  signup = () => {
    let paramsObject = "";
    let langString = "";
    try {
      paramsObject = new URLSearchParams(window.location.search || "");
      langString = paramsObject.get(SELECTED_LANGUAGE) || "";
    } catch (e) {}

    this.setLanguageWhenSignup(langString);
    let auth0Object = {
      audience: AUTH_AUDIENCE,
      scope: AUTH_SCOPE,
      responseType: AUTH_RESPONSE_TYPE,
      redirectUri: this.redirectUri() + `?tenantName=${SIGNUP_TENANT_NAME}`,
      hide_login: true,
      type: "signup",
      platform_name: "Automation Hub"
    };
    if (!!langString) {
      auth0Object[SELECTED_LANGUAGE] = langString;
    }
    this.auth0.authorize(auth0Object);
  };

  setLanguageWhenSignup = (langString = "") => {
    if (!!langString) {
      SessionHelper.setLanguage(langString);
    }
  };

  //this checks to see if we have a hash from the callback or then checks in the background - for general login
  getLoggedInUserTokenFromHash = authenticateUserTokenCallBack => {
    //check to see if we have a hash in the url from the auth
    let self = this;
    if (window.location.hash) {
      let hash = window.location.hash;
      window.location.hash = "";
      this.auth0.parseHash({ hash: hash }, function(err, authResult) {
        //clear the hash
        if (err) {
          console.log(err);
          if (authenticateUserTokenCallBack) {
            authenticateUserTokenCallBack(null);
          }
        } else if (authResult) {
          authResult.authType = AUTH_TYPE_AUTH0;
          auth.refreshUserFromAuthResult(
            authResult,
            authenticateUserTokenCallBack,
            false,
            (headers, error, messageObj) => {
              if (error.response.status === 409) {
                //specifically for email not verified errors
                SwalForRegistering(messageObj)
                  .fire()
                  .then(e => {
                    if (e.value) {
                      self.resendVerificationEmail(headers);
                    }
                    if (authenticateUserTokenCallBack) {
                      authenticateUserTokenCallBack(null);
                    }
                  });
              } else {
                SwalForRegisteringError(messageObj)
                  .fire()
                  .then(e => {
                    self.logout();
                    if (authenticateUserTokenCallBack) {
                      authenticateUserTokenCallBack(null);
                    }
                  });
              }
            }
          );
        } else {
          if (authenticateUserTokenCallBack) {
            authenticateUserTokenCallBack(null);
          }
        }
      });
    }
  };

  //this is used for signup
  getAuthResultFromHash = authResultCallback => {
    //check to see if we have a hash in the url from the auth
    if (window.location.hash) {
      let hash = window.location.hash;
      window.location.hash = "";
      this.auth0.parseHash({ hash: hash }, function(err, authResult) {
        if (err) {
          console.log(err);
          if (authResultCallback) {
            authResultCallback(null);
          }
        } else if (authResult) {
          if (authResultCallback) {
            authResultCallback(authResult);
          }
        }
      });
    }
  };

  //this does the auth call in the background and doens't prompt the user if it works / fails
  refreshSessionInBackground = authenticateUserTokenCallBack => {
    this.auth0.checkSession(
      {
        audience: AUTH_AUDIENCE,
        scope: AUTH_SCOPE,
        responseType: AUTH_RESPONSE_TYPE
      },
      (err, authResult) => {
        if (authResult) {
          //console.log(authResult);
          //console.log("user token from session during background refresh");
          //do login call with the auth token and get the user object and real token
          authResult.authType = AUTH_TYPE_AUTH0;
          auth.refreshUserFromAuthResult(
            authResult,
            authenticateUserTokenCallBack,
            true
          );
        } else if (err) {
          console.log(err);
          if (authenticateUserTokenCallBack) {
            authenticateUserTokenCallBack(null);
          }
        }
      }
    );
  };

  resendVerificationEmail = headers => {
    const path = `${API_URL}/fd-api/v1/auth/verification-email`;
    axios
      .get(path, { headers: headers })
      .then(resp => {
        swalSpinnerSuccess();
        this.logout();
      })
      .catch(e => {
        apiErrorAlert(e);
        this.logout();
      });
  };

  logout = () => {
    SessionHelper.clearExcept([LANGUAGE_COOKIE_NAME]);
    //we redirect to a non tenant page (login-redirect and logout redirect) - then we route on the client side to the proper page
    // need to set the tenantName in query param
    const tenantName = SessionHelper.tenantNameFromCurrentPath() || "";
    this.auth0.logout({
      returnTo: this.logOutRedirectUri() + `?tenantName=${tenantName}`
    });
  };
}

export const auth0Handler = new Auth0Handler();
