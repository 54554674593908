import React from "react";
import { useTranslation } from "react-i18next";
import Truncate from "react-text-truncate";
import compareVersions from "compare-versions";
import {
  component_map_to_name,
  component_map_to_slug,
  component_map_to_classname,
  PUBLISHED_PHASE,
  ARCHIVED_PHASE
} from "../../constants/componentPermissionsConstants";
import { CLASSNAME_GRAY } from "../../constants/phaseColorsConstants";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";

import "./CellNameMyComponents.scss";
import "../CellStatusComponents/CellStatusComponents.scss";
import { Tooltip } from "@material-ui/core";
import ChipApollo from "../ChipApollo/ChipApollo";

const CellNameMyComponents = props => {
  const { component_name, component_created_epoch, onClick, versions } = props;
  const { t } = useTranslation("common");

  const dateCreated = component_created_epoch
    ? `${t("my_component_date_submitted")}: ${showDateWithTenantDateFormat(
        component_created_epoch,
        t(["general_data_format_slash", "lll"])
      )}`
    : "";

  const versionsMap = new Map();

  // Instead of only show Major versions, show the lastest of all versions under the same Major versions
  for (let i in versions) {
    let version = versions[i];
    let majorVersion = (version.semantic_version || "").split(".")[0];

    if (versionsMap.has(majorVersion)) {
      if (
        compareVersions.compare(
          versionsMap.get(majorVersion).semantic_version,
          version.semantic_version,
          "<"
        )
      ) {
        versionsMap.set(majorVersion, version);
      }
    } else {
      versionsMap.set(majorVersion, version);
    }
  }
  const truncateVersionName = versionName => {
    if (versionName && versionName.length < 50) {
      return versionName;
    }
    return (
      <Truncate
        element="a"
        className="CellNameMyComponents__version-name-text"
        text={versionName}
      />
    );
  };
  const createVersions = () => {
    const versionComponents = [];
    versionsMap.forEach((value, key) => {
      let version = value;
      const {
        component_status_id,
        component_version,
        semantic_version
      } = version;

      const ver = t("my_component_version", {
        versionString: component_version || semantic_version
      });

      let statusChipClassName = component_map_to_classname[component_status_id];
      let status = component_map_to_name[component_status_id];
      let statusSlug = component_map_to_slug[component_status_id];

      let translatedStatus = statusSlug
        ? t("component_cell_status_" + (statusSlug || "").toLowerCase(), status)
        : status;

      if (component_status_id === null || component_status_id === undefined) {
        statusChipClassName = component_map_to_classname[CLASSNAME_GRAY];
      }

      versionComponents.push(
        <div className="CellNameMyComponents__version" key={semantic_version}>
          <span className="CellNameMyComponents__version-name">
            <Tooltip title={ver} placement="top">
              {component_status_id != ARCHIVED_PHASE ? (
                <button
                  className="CellNameMyComponents__version-name-button-active"
                  onClick={() => onClick(semantic_version)}
                >
                  {" "}
                  {truncateVersionName(ver)}{" "}
                  {component_status_id === PUBLISHED_PHASE &&
                    t("my_component_live")}
                </button>
              ) : (
                <button
                  data-tip={ver}
                  className="CellNameMyComponents__version-name-button"
                >
                  {" "}
                  {truncateVersionName(ver)}
                </button>
              )}
            </Tooltip>
          </span>
          <ChipApollo
            size="small"
            label={translatedStatus}
            className={statusChipClassName}
          />
        </div>
      );
    });
    return versionComponents;
  };

  return (
    <div
      className="CellNameMyComponents"
      data-testid="CellNameMyComponentsContainer"
    >
      <Truncate
        element="a"
        containerClassName="CellNameMyComponents__header"
        className="CellNameMyComponents__header-text"
        text={component_name}
        line={2}
      />

      <span className="CellNameMyComponents__created-date">{dateCreated}</span>
      {versions && createVersions()}
    </div>
  );
};

export default withTranslation("common")(CellNameMyComponents);
