import { COAssessment } from "../classes/co-assessment.class";
import { COContext } from "../classes/co-context.class";
import { COStatusKeys } from "../interfaces/co-interfaces";

export const sortAssessmentByStatus = (assessments: COAssessment[]) => {
  const sortedAssessment = [...assessments];

  sortedAssessment.sort((assessmentA, assessmentB) => {
    const versionStatusA =
      assessmentA?.co_assessment_version_meta_json?.version_status;
    const versionStatusB =
      assessmentB?.co_assessment_version_meta_json?.version_status;

    if (
      versionStatusA === COStatusKeys.ARCHIVED &&
      versionStatusB !== COStatusKeys.ARCHIVED
    ) {
      return -1;
    } else if (
      versionStatusB === COStatusKeys.ARCHIVED &&
      versionStatusA !== COStatusKeys.ARCHIVED
    ) {
      return 1;
    }

    return 0;
  });

  return sortedAssessment;
};

export const isAssessmentContextArchived = (context: COContext) => {
  return (
    context?.assessment?.co_assessment_version_meta_json?.version_status ===
    COStatusKeys.ARCHIVED
  );
};

export const filterContextsByAssessmentStatus = (contexts: COContext[]) => {
  return contexts.filter(context => !isAssessmentContextArchived(context));
};
