import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import SessionHelper from "./helpers/SessionHelper";
import { EN, LANGUAGE_COOKIE_NAME } from "./constants/localizationConstants";

i18n
  .use(HttpApi) // load locales files from /public folder
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json",
      crossDomain: true
    },
    fallbackLng: EN,
    lng: SessionHelper.getValueForKey(LANGUAGE_COOKIE_NAME) || EN,
    // default namespace is common
    ns: "common",
    interpolation: {
      escapeValue: false
    },
    nsSeparator: false
  });

export default i18n;
