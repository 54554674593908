import { COEmailActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COEmailActionSlugs,
  COEmailActivityType,
  COEmailType
} from "../../../../../constants/actions/co-email-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";

export const curationNotificationEmailActionTemplate = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  // get template based on slug
  let template = curationNotificationEmailActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each notification
    template.co_options_json = {
      email_type: COEmailType.CURATION_NOTIFICATION,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const curationNotificationEmailActionTemplateFromSlug = (
  slug: string
): COEmailActionTemplateInterface | undefined => {
  switch (slug) {
    case COEmailActionSlugs.VERIFICATION_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.STANDARD_USER]
          },
          legacy_email_id: 3,
          email_activity_type: COEmailActivityType.EMAIL_VERIFICATION_SENT,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.EMAIL_VERIFICATION_SENT,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Verification Email"
          },
          email_body: {
            value: "verification_email"
          }
        }
      };
    }
    case COEmailActionSlugs.LAUNCH_ASSESSMENT_EMAIL: {
      return {
        co_options_json: {
          is_inactive: 1,
          is_deleted: 1,
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 4,
          email_activity_type: COEmailActivityType.REVIEW_APPROVED_IDEA,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_APPROVED
          ],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Launch assessment"
          },
          email_body: {
            value: "launch_assesment"
          }
        }
      };
    }
    case COEmailActionSlugs.ASSESSED_AUTOMATION_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 7,
          email_activity_type: COEmailActivityType.REVIEW_ASSESSED_AUTOMATION,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
          ],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New automation assessed"
          },
          email_body: {
            value: "assessed_automation"
          }
        }
      };
    }
    case COEmailActionSlugs.CHANGED_PLAN_OWNER_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.ACCOUNT_OWNER]
          },
          legacy_email_id: 13,
          email_activity_type: COEmailActivityType.CHANGED_PLAN_OWNER_EMAIL,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.CHANGED_PLAN_OWNER_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Changed plan owner"
          },
          email_body: {
            value: "changed_plan_owner_email"
          }
        }
      };
    }
    case COEmailActionSlugs.CHANGED_PLAN_ERROR_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.DEALHUB]
          },
          legacy_email_id: 14,
          email_activity_type: COEmailActivityType.CHANGED_PLAN_ERROR_EMAIL,
          email_category_type: COCategoryType.TENANT,
          platform_event_type: COPlatformEventType.CHANGED_PLAN_ERROR_EMAIL,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Changed plan error"
          },
          email_body: {
            value: "changed_plan_error_email"
          }
        }
      };
    }
    case COEmailActionSlugs.EXTEND_TRIAL_GRANTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TRIGGER_USER]
          },
          legacy_email_id: 15,
          email_activity_type: COEmailActivityType.EXTEND_TRIAL_GRANTED,
          email_category_type: COCategoryType.USER,
          platform_event_type: COPlatformEventType.EXTEND_TRIAL_GRANTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Extended trial granted"
          },
          email_body: {
            value: "extend_trial_granted_email"
          }
        }
      };
    }
    case COEmailActionSlugs.AUTOMATION_ABUSE_REPORTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 18,
          email_activity_type: COEmailActivityType.PROCESS_ABUSE_REPORTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_ABUSE_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Automation abuse reported"
          },
          email_body: {
            value: "automation_abuse_reported"
          }
        }
      };
    }
    case COEmailActionSlugs.AUTOMATION_COMMENT_ABUSE_REPORTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 19,
          email_activity_type:
            COEmailActivityType.PROCESS_ABUSE_COMMENT_REPORTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_ABUSE_COMMENT_REPORTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Automation abuse reported"
          },
          email_body: {
            value: "automation_comment_abuse_reported"
          }
        }
      };
    }
    case COEmailActionSlugs.NEW_IDEA_SUBMITTED_PROGRAM_MANAGER_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 32,
          email_activity_type:
            COEmailActivityType.NEW_IDEA_SUBMITTED_PROGRAM_MANAGER,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.IDEA_SUBMITTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New idea submitted to program manager"
          },
          email_body: {
            value: "new_idea_submitted_program_manager"
          }
        }
      };
    }
    case COEmailActionSlugs.NEW_COE_IDEA_SUBMITTED_PROGRAM_MANAGER_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 33,
          email_activity_type:
            COEmailActivityType.NEW_COE_IDEA_SUBMITTED_PROGRAM_MANAGER,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.COE_DRIVEN_IDEA_SUBMITTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New CoE idea submitted to program manager"
          },
          email_body: {
            value: "new_coe_idea_submitted_program_manager"
          }
        }
      };
    }
    case COEmailActionSlugs.CD_AUTOMATION_SHARED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.BUSINESS_REVIEWERS]
          },
          legacy_email_id: 34,
          email_activity_type: COEmailActivityType.CD_AUTOMATION_SHARED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.CD_AUTOMATION_SHARED,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation shared"
          },
          email_body: {
            value: "cd_automation_shared"
          }
        }
      };
    }
    case COEmailActionSlugs.CD_AUTOMATION_TO_TECHNICAL_REVIEW_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TECHNICAL_REVIEWERS]
          },
          legacy_email_id: 35,
          email_activity_type:
            COEmailActivityType.CD_AUTOMATION_TO_TECHNICAL_REVIEW,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_AWAITING_REVIEW
          ],
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation moved to technical review"
          },
          email_body: {
            value: "cd_automation_to_technical_review"
          }
        }
      };
    }
    case COEmailActionSlugs.CD_AUTOMATION_REJECTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.AUTOMATION_SUBMITTER]
          },
          legacy_email_id: 36,
          email_activity_type: COEmailActivityType.CD_AUTOMATION_REJECTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.BUSINESS_REVIEW_REJECTED
          ],
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation rejected"
          },
          email_body: {
            value: "cd_automation_rejected"
          }
        }
      };
    }
    case COEmailActionSlugs.CD_AUTOMATION_REWORK_REQUIRED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROGRAM_MANAGER]
          },
          legacy_email_id: 37,
          email_activity_type:
            COEmailActivityType.CD_AUTOMATION_REWORK_REQUIRED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.TECHNICAL_REVIEW_REWORK_REQUIRED
          ],
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation required rework"
          },
          email_body: {
            value: "cd_automation_rework_required"
          }
        }
      };
    }
    case COEmailActionSlugs.CD_AUTOMATION_REQUESTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.TECHNICAL_REVIEWERS]
          },
          legacy_email_id: 38,
          email_activity_type: COEmailActivityType.CD_AUTOMATION_REQUESTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.CD_AUTOMATION_REQUESTED,
          settings_ids: [37],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "CD automation requested"
          },
          email_body: {
            value: "cd_automation_requested"
          }
        }
      };
    }
    case COEmailActionSlugs.CHANGE_REQUEST_SUBMITTED_EMAIL: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [
              CORoleType.PROGRAM_MANAGER,
              CORoleType.ASSESSED_IDEA_REVIEWERS
            ]
          },
          legacy_email_id: 42,
          email_activity_type: COEmailActivityType.CHANGE_REQUEST_SUBMITTED,
          email_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.CHANGE_REQUEST_SUBMITTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Change Request submitted"
          },
          email_body: {
            value: "change_request_submitted"
          }
        }
      };
    }
  }
  return undefined;
};
