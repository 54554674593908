import {
  COContextInterface,
  COActionInterface,
  COActionTemplateInterface,
  COActionTemplateDBClass,
  COActionDBClass,
  COMetadataItemInterface
} from "../../interfaces/co-interfaces";
import { COBase } from "../co-base.class";
import { COBaseTemplate } from "../co-base-template.class";
import {
  COComponentType,
  COContextObjectKey
} from "../../constants/co-constants";
import {
  parseMetaItemValueToLocalizationValuesMeta,
  parseMetaItemValueToUnlocalizedString,
  parseMetaItemFileToLocalizationValuesMeta
} from "../../helpers/actions/co-actions-replacement-values.helper";
import { coUUID } from "../../utils/co-utils";
import { COActionTypes } from "../../constants/actions/co-action-constants";

export interface COBaseAction extends COActionInterface {}

export class COBaseAction extends COBase {
  static getClassName(): string {
    return "COBaseAction";
  }
  constructor({
    propertiesFromJSON,
    context,
    template
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COActionTemplate;
  }) {
    super({
      propertiesFromJSON
    });

    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: COActionDBClass,
      contextKey: COContextObjectKey.ACTION,
      ahid_key: "co_action_ahid",
      objectClass: COBaseAction
    };

    if (!this.co_action_ahid) {
      this.co_action_ahid = COBaseAction.generateAHID(
        this.co_action_type || "action"
      );
    }

    if (template) {
      this.template = template;
    }
    this.checkAndLoadTemplate?.();
  }

  static generateAHID(co_action_type?: COActionTypes | string): string {
    return `ah-${co_action_type || ""}-${new Date().getTime()}-${coUUID()}`;
  }

  templateSlug?(): string | undefined {
    return this.co_action_template_slug;
  }

  // converts the provided Action meta value to a COMetadataItemInterface
  // with localization_values in it to be replaced later
  functionParseActionMetaValueToLocalizationMetaItem?(
    context: COContextInterface,
    operatorParams: { value?: string }
  ): COMetadataItemInterface | undefined {
    const metaItem: COMetadataItemInterface = parseMetaItemValueToLocalizationValuesMeta(
      {
        context,
        value: operatorParams.value
      }
    );

    return {
      ...metaItem,
      options: {
        component_type: COComponentType.META_PARAGRAPH_TEXT
      }
    };
  }

  // converts the provided Action meta value to a String
  functionParseActionMetaValueToString?(
    context: COContextInterface,
    operatorParams: { value?: string }
  ): string | undefined {
    return parseMetaItemValueToUnlocalizedString({
      context,
      value: operatorParams.value
    }).trim();
  }

  // converts the provided Action meta value to a loaded email template
  functionLoadAndParseActionMetaEmailTemplate?(
    context: COContextInterface,
    operatorParams: { value?: string }
  ): COMetadataItemInterface | undefined {
    return parseMetaItemFileToLocalizationValuesMeta({
      context,
      value: operatorParams.value
    });
  }
}

export interface COActionTemplate extends COActionTemplateInterface {}
export class COActionTemplate extends COBaseTemplate {
  static getClassName(): string {
    return "COActionTemplate";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: COActionTemplateDBClass,
      objectClass: COActionTemplate
    };
  }

  calculate?(context: COContextInterface): any[] {
    return [];
  }
}
