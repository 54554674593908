import { Button } from "@material-ui/core";
import React from "react";
import { ButtonApolloProps } from "../../../types/buttonTypes";

const ButtonApolloSecondary = ({
  customRef,
  ...props
}: { customRef?: React.RefObject<any> } & ButtonApolloProps) => {
  return (
    <Button ref={customRef} variant="outlined" disableElevation {...props} />
  );
};

export default ButtonApolloSecondary;
