import React from "react";
import "./ButtonArrowDown.scss";

const ButtonArrowDown = ({ isOpen, toggleNav }) => {
  const openClass = isOpen ? "ButtonArrowDown--open" : "";
  return (
    <button
      data-testid="ButtonArrowDownContainer"
      className={`ButtonArrowDown ${openClass}`}
      onClick={toggleNav}
      tabIndex="0"
    >
      <b className="ButtonArrowDown__label">Toggle Navigation Menu</b>
      <span className="ButtonArrowDown__bar ButtonArrowDown__bar--1"></span>
      <span className="ButtonArrowDown__bar ButtonArrowDown__bar--2"></span>
    </button>
  );
};

export default ButtonArrowDown;
