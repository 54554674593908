import {
  FETCH_ADMIN_USERS_DATA,
  FETCH_MORE_ADMIN_USERS_DATA,
  SET_ADMIN_USERS_COUNTER_TOTAL,
  CLEAR_ADMIN_USERS_COUNTER_TOTAL,
  CLEAR_ADMIN_USERS_DATA,
  ADMIN_USER_MANAGER_POPUP_SHOWING,
  FETCH_TENANT,
  UPDATE_TENANT,
  SET_ADMIN_USER_OBJECT
} from "../constants/actionTypes";

const INITIAL_STATE = {
  users: null,
  usersCounterTotal: null,
  managerPopup: null,
  tenantInformation: null,
  tenantLogo: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ADMIN_USERS_DATA:
      return {
        ...state,
        users: action.payload
      };
    case FETCH_MORE_ADMIN_USERS_DATA: {
      const newUsersObj = {
        ...action.payload,
        users: [...state.users.users, ...action.payload.users]
      };
      return {
        ...state,
        users: newUsersObj
      };
    }
    case SET_ADMIN_USER_OBJECT: {
      if (!state.users || !state.users.users) {
        return state;
      }
      const users = [...state.users.users].map(user => {
        if (user.user_id === action.payload.user_id) {
          return action.payload;
        } else {
          return user;
        }
      });
      const newUsersObj = {
        ...state.users,
        users: users
      };
      return {
        ...state,
        users: newUsersObj
      };
    }
    case SET_ADMIN_USERS_COUNTER_TOTAL: {
      let usersCounterTotal = null;
      if (action.payload && action.payload.totalItems) {
        usersCounterTotal = action.payload.totalItems;
      }
      return {
        ...state,
        usersCounterTotal: usersCounterTotal
      };
    }
    case CLEAR_ADMIN_USERS_COUNTER_TOTAL: {
      return {
        ...state,
        usersCounterTotal: null
      };
    }
    case CLEAR_ADMIN_USERS_DATA:
      return {
        ...state,
        users: null
      };
    case ADMIN_USER_MANAGER_POPUP_SHOWING:
      return {
        ...state,
        managerPopup: action.payload
      };
    case FETCH_TENANT:
      return {
        ...state,
        tenantInformation: action.payload,
        tenantLogo: action.payload.tenant_company_logo
      };
    case UPDATE_TENANT:
      return {
        ...state,
        tenantLogo: action.payload
      };
    default:
      return state;
  }
}
