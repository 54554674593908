import {
  COCalculatedValueHistoryDBClass,
  COCalculatedValueHistoryInterface
} from "../interfaces/co-interfaces";
import { COBase } from "./co-base.class";

export interface COCalculatedValueHistory
  extends COCalculatedValueHistoryInterface {}
export class COCalculatedValueHistory extends COBase {
  static getClassName(): string {
    return "COCalculatedValueHistory";
  }
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [],
      dbClass: COCalculatedValueHistoryDBClass,
      objectClass: COCalculatedValueHistory
    };
  }
}
