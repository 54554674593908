import { COTaskActionTemplateInterface } from "../../../../../interfaces/co-interfaces";
import {
  COTaskActionSlugs,
  COTaskType,
  COTaskActivityType,
  COTaskPopUpMessageSource
} from "../../../../../constants/actions/co-task-action-constants";
import {
  COPlatformEventType,
  COCategoryType
} from "../../../../../constants/actions/co-platform-events-constants";
import {
  AUTOMATION_PROFILE_COMPONENTS_URL,
  AUTOMATION_PROFILE_URL,
  COMPONENT_PROFILE_URL,
  AUTOMATION_PROFILE_CBA_URL
} from "../../../../../constants/actions/co-action-constants";
import { CORoleType } from "../../../../../constants/actions/co-roles.constants";
import { COPhaseStatusKeys } from "../../../../../constants/co-phase-status.constants";
import { COEmailReminderActionSlugs } from "../../../../../constants/actions/co-email-reminder-action-constants";

export const generateContentTaskActionTemplate = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  // get template based on slug
  let template = generateContentTaskActionTemplateFromSlug(slug);
  if (template) {
    // add values that will be the same for each task
    template.co_options_json = {
      task_type: COTaskType.GENERATE_CONTENT,
      ...template.co_options_json
    };
    return template;
  }
  return undefined;
};

const generateContentTaskActionTemplateFromSlug = (
  slug: string
): COTaskActionTemplateInterface | undefined => {
  switch (slug) {
    case COTaskActionSlugs.RESUBMIT_IDEA_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.SUBMITTER]
          },
          legacy_task_id: 3,
          task_activity_type_id: 3,
          task_activity_type: COTaskActivityType.RESUBMIT_IDEA,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.IDEA_REJECTED
          ],
          task_popup_message_source:
            COTaskPopUpMessageSource.PROCESS_REJECTED_REASON,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Resubmit idea"
          },
          message: {
            value: "Resubmit your idea following the feedback received"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.ASSESMENT_LAUNCHED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_OWNER]
          },
          legacy_task_id: 4,
          task_activity_type_id: 4,
          task_activity_type: COTaskActivityType.ASSESMENT_LAUNCHED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type: COPlatformEventType.PROCESS_PHASE_CHANGED,
          platform_event_phase_status_triggers: [
            COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
          ],
          task_auto_completion_criteria: {
            moved_to_phase_status: [
              COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW
            ]
          },
          email_reminder_options: {
            email_reminder_slug:
              COEmailReminderActionSlugs.ASSESSMENT_LAUNCHED_EMAIL_REMINDER
          },
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Assessment launched"
          },
          message: {
            value: "Fill in the Detailed Assessment for an automation"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_EDIT_ABOUT_RIGHTS_GRANTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_task_id: 8,
          task_activity_type_id: 8,
          task_activity_type: COTaskActivityType.AP_EDIT_ABOUT_RIGHTS_GRANTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_ASSIGNED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Edit About section rights granted"
          },
          message: {
            value: "Edit the About section in an automation"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_EDIT_DOCUMENTATION_RIGHTS_GRANTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_task_id: 9,
          task_activity_type_id: 9,
          task_activity_type:
            COTaskActivityType.AP_EDIT_DOCUMENTATION_RIGHTS_GRANTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_ASSIGNED,
          settings_ids: [25],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Edit Documentation section rights granted"
          },
          message: {
            value: "Edit the Documentation section in an automation"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_EDIT_COMPONENT_RIGHTS_GRANTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_task_id: 10,
          task_activity_type_id: 10,
          task_activity_type:
            COTaskActivityType.AP_EDIT_COMPONENT_RIGHTS_GRANTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_ROLE_ASSIGNED,
          settings_ids: [27],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Edit Components section rights granted"
          },
          message: {
            value: "Add reusable components in an automation"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_COMPONENTS_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_NEW_COMPONENT_CREATED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_task_id: 11,
          task_activity_type_id: 11,
          task_activity_type: COTaskActivityType.AP_NEW_COMPONENT_CREATED,
          task_category_type: COCategoryType.COMPONENT,
          platform_event_type: COPlatformEventType.REUSABLE_COMPONENT_ADDED,
          settings_ids: [27],
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "New component created"
          },
          message: {
            value: "Create a reusable component."
          },
          redirect_url: {
            value: COMPONENT_PROFILE_URL
          }
        }
      };
    }
    case COTaskActionSlugs.AP_EDIT_CBA_RIGHTS_GRANTED_TASK: {
      return {
        co_options_json: {
          action_target: {
            target_roles: [CORoleType.PROCESS_COLLABORATOR]
          },
          legacy_task_id: 35,
          task_activity_type_id: 22,
          task_activity_type: COTaskActivityType.AP_EDIT_CBA_RIGHTS_GRANTED,
          task_category_type: COCategoryType.AUTOMATION,
          platform_event_type:
            COPlatformEventType.PROCESS_COLLABORATOR_CBA_EDIT_RIGHTS_GRANTED,
          condition_overrides: []
        },
        co_meta_json: {
          title: {
            value: "Edit CBA section rights granted"
          },
          message: {
            value: "Edit the CBA section in an automation"
          },
          redirect_url: {
            value: AUTOMATION_PROFILE_CBA_URL
          }
        }
      };
    }
  }
  return undefined;
};
