import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import {
  COComponentType,
  COConditionSlugs,
  CODocumentTypes,
  COExternalAnswerSources,
  COPermissionSlugs
} from "../../constants/co-constants";
import { LegacyQuestionAnswerSlugs } from "../../constants/co-migration-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";
import { CODefaultEquations } from "../../constants/co-calculation.constants";
import {
  ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE,
  ASSESSMENT_VISIBILITY_START_FRESH_OPTION_VALUE,
  ASSESSMENT_VISIBILITY_INHERIT_OPTION_VALUE
} from "../../constants/co-question-answer-option.constants";

const assessmentVisibilityQuestionAnswerOptions = (): {
  answer_option_slug: string;
  title?: string;
  value?: string;
  legacy_slug?: string;
  title_is_editable?: boolean;
  prevent_deleting?: number;
}[] => {
  // if the title of any of these change we also need to update the values
  // so they match the slugs produced by selectableAnswerOptionValueValidator
  // or the special logic for assessment visibility options will break
  return [
    {
      answer_option_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
      title: "Hidden",
      value: ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE,
      title_is_editable: false,
      prevent_deleting: 1
    },
    {
      answer_option_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
      title: "Start fresh",
      value: ASSESSMENT_VISIBILITY_START_FRESH_OPTION_VALUE,
      title_is_editable: false,
      prevent_deleting: 1
    },
    {
      answer_option_slug:
        StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
      title: "Inherit from parent idea",
      value: ASSESSMENT_VISIBILITY_INHERIT_OPTION_VALUE,
      title_is_editable: false,
      prevent_deleting: 1
    }
  ];
};

export const customCRQuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.CR_PARENT_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_PARENT_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.PROCESS_LINK,
        title: "Parent automation",
        description: "",
        note: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_PARENT_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 0
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS } // Needed for setting parent id to start, this question does not allow user to prefill answer
          ],
          prevent_deleting: 1,
          is_read_only: 1
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.CR_REASON_FOR_CHANGE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_REASON_FOR_CHANGE,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Reason for change",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_REASON_FOR_CHANGE
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [{ slug: COConditionSlugs.QUESTION_IS_REQUIRED }]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "System change",
            value: "-1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Process change",
            value: "-1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Improvement",
            value: "-1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Bug",
            value: "-1"
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.CR_TARGET_LAUNCH_DATE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_TARGET_LAUNCH_DATE,
        standard_question_template_slug: StandardQuestionSlugs.DATE,
        title: "Target launch date",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_TARGET_LAUNCH_DATE
          ),
          co_question_position_json: {
            sort_order: 40
          }
        },
        answer_option_info: [],
        options_meta_overrides: {}
      })
    },
    {
      slug: CustomQuestionSlugs.CR_URGENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_URGENCY,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Urgency",
        description:
          "Select the option that best fits your scenario and explain the selection in comments",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_URGENCY
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "ASAP - Bot will undergo downtime soon",
            value: "-1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "High - Upcoming hard deadline",
            value: "-1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Medium - Next few months",
            value: "-1"
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Low - Bot will not have downtime at all",
            value: "-1"
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.CR_APPLICATIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_APPLICATIONS,
        standard_question_template_slug:
          StandardQuestionSlugs.APPLICATIONS_COUNT,
        title: "What applications are impacted?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_APPLICATIONS
          ),
          co_equation: CODefaultEquations.COUNT_APPLICATIONS,
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.CR_DOCUMENTATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_DOCUMENTATION,
        standard_question_template_slug: StandardQuestionSlugs.DOCUMENT,
        title: "Documents",
        description: "",
        note:
          "NOTE: The answer will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_DOCUMENTATION
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 25,
          document_options: {},
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            { slug: COConditionSlugs.HIDE_FOR_EDIT }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_HOURS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_HOURS,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Additional benefit per company (hours saved/year)",
        description:
          "How many additional hours would the company save if this change request gets implemented? ",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_HOURS
          ),
          co_question_position_json: {
            sort_order: 40
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          }),
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_CURRENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_CURRENCY,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Additional benefit per company (currency/year) ",
        description:
          "What additional amount of money would the company save if this change request gets implemented? ",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_CURRENCY
          ),
          co_question_position_json: {
            sort_order: 40
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.CURRENCY
          }),
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
          ],
          visibility_permissions: [COPermissionSlugs.CHANGE_REQUEST_PERMISSIONS]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_FTE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_FTE,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Additional benefit per company (FTEs)",
        description:
          "How many additional FTEs would the company save if this change request gets implemented? ",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_BENEFIT_PER_COMPANY_FTE
          ),
          co_question_position_json: {
            sort_order: 40
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
          ],
          visibility_permissions: [COPermissionSlugs.CHANGE_REQUEST_PERMISSIONS]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.CR_COLLABORATORS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_COLLABORATORS,
        standard_question_template_slug: StandardQuestionSlugs.COLLABORATORS,
        title: "Collaborators",
        description: "",
        note:
          "NOTE: The answer will be displayed in the Collaborators page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_COLLABORATORS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 25,
          document_options: {},
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE }
          ]
        },
        condition_overrides_to_remove: []
      })
    },
    {
      slug: CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "High level assessment",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          excluded_from_branching_paths_selection: 1,
          is_read_only: 1,
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.CR_Q1_BUTTON_VISIBILITY }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        answer_option_info: assessmentVisibilityQuestionAnswerOptions()
      })
    },
    {
      slug: CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Detailed assessment",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          excluded_from_branching_paths_selection: 1,
          is_read_only: 1,
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        answer_option_info: assessmentVisibilityQuestionAnswerOptions()
      })
    },
    {
      slug: CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Citizen developer assessment",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          excluded_from_branching_paths_selection: 1,
          is_read_only: 1,
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.CR_Q3_BUTTON_VISIBILITY }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        answer_option_info: assessmentVisibilityQuestionAnswerOptions()
      })
    },
    {
      slug: CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Misc",
        description: "",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          excluded_from_branching_paths_selection: 1,
          is_read_only: 1,
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI },
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        answer_option_info: assessmentVisibilityQuestionAnswerOptions()
      })
    }
  ];
};
