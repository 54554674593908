import React from "react";
import "./ButtonMain.scss";
import ButtonContainer from "../sharedJS/ButtonContainer";

const ButtonMain = props => {
  let { type, className, extraStyles } = props;
  const classes = ["ButtonConfirm", className];

  extraStyles = extraStyles || {};

  if (type === "primary") {
    classes.push(`${classes[0]}__primary`);
  } else if (type === "secondary") {
    classes.push(`${classes[0]}__secondary`);
  } else if (type === "disabled") {
    classes.push(`${classes[0]}__disabled`);
  } else if (type === "danger") {
    classes.push(`${classes[0]}__danger`);
  } else if (type === "green") {
    classes.push(`${classes[0]}__green`);
  }

  return (
    <ButtonContainer
      {...props}
      className={classes.join(" ")}
      style={extraStyles}
    >
      {props.children}
    </ButtonContainer>
  );
};

export default ButtonMain;
