import {
  COOptionsInterface,
  COQuestionInterface,
  COQuestionAnswerOptionInterface,
  COContextInterface
} from "../interfaces/co-interfaces";
import { COConditionSlugs } from "../constants/co-constants";
import { branchConditionSlugs } from "../constants/co-question-answer-option.constants";
import { isNullOrUndefined } from "../utils/co-utils";

export const removeAnswerOptionBranchConditions = ({
  context
}: {
  context: COContextInterface;
}) => {
  const question: COQuestionInterface | undefined = context.question;
  const question_answer_option: COQuestionAnswerOptionInterface | undefined =
    context.answer_option;
  if (isNullOrUndefined(question)) {
    return;
  }

  const optionsWithAnswerOptionBranchConditionsRemoved = ({
    co_object_options,
    question,
    question_answer_option
  }: {
    co_object_options: COOptionsInterface;
    question?: COQuestionInterface;
    question_answer_option?: COQuestionAnswerOptionInterface;
  }): COOptionsInterface => {
    if (question && co_object_options.condition_overrides) {
      const allBranchConditionSlugs = branchConditionSlugs.map(
        slug => slug + ""
      );
      co_object_options.condition_overrides = co_object_options.condition_overrides.filter(
        cd => {
          if (
            allBranchConditionSlugs.includes(cd.slug ?? "") ||
            cd.payload?.co_question_ahid === question.co_question_ahid
          ) {
            // only check the specific answer option if we have one
            if (question_answer_option?.co_question_answer_option_ahid) {
              return (
                cd.payload?.co_question_answer_option_ahid !==
                question_answer_option.co_question_answer_option_ahid
              );
            } else {
              return false;
            }
          }
          return true;
        }
      );
    }
    return co_object_options;
  };

  if (context.assessment && context.assessment.co_assessment_sections) {
    for (const section of context.assessment.co_assessment_sections) {
      if (section.co_section_options_json) {
        section.co_section_options_json = optionsWithAnswerOptionBranchConditionsRemoved(
          {
            co_object_options: section.co_section_options_json,
            question,
            question_answer_option
          }
        );
      }
      if (section.co_questions) {
        for (const question of section.co_questions) {
          if (question.co_question_options_json) {
            question.co_question_options_json = optionsWithAnswerOptionBranchConditionsRemoved(
              {
                co_object_options: question.co_question_options_json,
                question,
                question_answer_option
              }
            );
          }
        }
      }
    }
  }
};
