import autobiographerImage from "./../assets/images/badges/autobiographer.svg";
import plannerImage from "./../assets/images/badges/planner.svg";
import resourcefulPlannerImage from "./../assets/images/badges/resourceful-planner.svg";
import immersedPlannerImage from "./../assets/images/badges/immersed-planner.svg";
import masterPlannerImage from "./../assets/images/badges/master-planner.svg";
import achieverImage from "./../assets/images/badges/achiever.svg";
import resourcefulAchieverImage from "./../assets/images/badges/resourceful-achiever.svg";
import immersedAchieverImage from "./../assets/images/badges/immersed-achiever.svg";
import economistImage from "./../assets/images/badges/economist.svg";
import resourcefulEconomistImage from "./../assets/images/badges/resourceful-economist.svg";
import immersedEconomistImage from "./../assets/images/badges/immersed-economist.svg";
import supporterOneImage from "./../assets/images/badges/supporter-one.svg";
import supporterTwoImage from "./../assets/images/badges/supporter-two.svg";
import supporterThreeImage from "./../assets/images/badges/supporter-three.svg";
import supporterFourImage from "./../assets/images/badges/supporter-four.svg";
import supporterFiveImage from "./../assets/images/badges/supporter-five.svg";
import influencerOneImage from "./../assets/images/badges/influencer-one.svg";
import influencerTwoImage from "./../assets/images/badges/influencer-two.svg";
import influencerThreeImage from "./../assets/images/badges/influencer-three.svg";
import influencerFourImage from "./../assets/images/badges/influencer-four.svg";
import influencerFiveImage from "./../assets/images/badges/influencer-five.svg";
import commentatorOneImage from "./../assets/images/badges/commentator-one.svg";
import commentatorTwoImage from "./../assets/images/badges/commentator-two.svg";
import commentatorThreeImage from "./../assets/images/badges/commentator-three.svg";
import commentatorFourImage from "./../assets/images/badges/commentator-four.svg";
import commentatorFiveImage from "./../assets/images/badges/commentator-five.svg";
import contributorOneImage from "./../assets/images/badges/contributor-one.svg";
import contributorTwoImage from "./../assets/images/badges/contributor-two.svg";
import contributorThreeImage from "./../assets/images/badges/contributor-three.svg";
import contributorFourImage from "./../assets/images/badges/contributor-four.svg";
import contributorFiveImage from "./../assets/images/badges/contributor-five.svg";
import ideatorOneImage from "./../assets/images/badges/ideator-one.svg";
import ideatorTwoImage from "./../assets/images/badges/ideator-two.svg";
import ideatorThreeImage from "./../assets/images/badges/ideator-three.svg";
import ideatorFourImage from "./../assets/images/badges/ideator-four.svg";
import ideatorFiveImage from "./../assets/images/badges/ideator-five.svg";
import activatorOneImage from "./../assets/images/badges/activator-one.svg";
import activatorTwoImage from "./../assets/images/badges/activator-two.svg";
import activatorThreeImage from "./../assets/images/badges/activator-three.svg";
import activatorFourImage from "./../assets/images/badges/activator-four.svg";
import activatorFiveImage from "./../assets/images/badges/activator-five.png";
import lockedBadgeImage from "./../assets/images/badges/locked-badge.svg";
// level images
import noviceLevelImage from "./../assets/images/level-icons/level_0.svg";
import advancedBeginnerLevelImage from "./../assets/images/level-icons/level_1.svg";
import intermediateLevelImage from "./../assets/images/level-icons/level_2.svg";
import advancedLevelImage from "./../assets/images/level-icons/level_3.svg";
import proficientLevelImage from "./../assets/images/level-icons/level_4.svg";
import expertLevelImage from "./../assets/images/level-icons/level_5.svg";

const documentationBadgePrefix = "contributor";

export const shouldShowBadge = (badgeSlug, isFileUploadEnabled) => {
  if (
    !isFileUploadEnabled &&
    badgeSlug.indexOf(documentationBadgePrefix) >= 0
  ) {
    return false;
  }
  return true;
};

export const badgesOnly = (badgeArray, isFileUploadEnabled = true) => {
  let badges = [];
  badgeArray.forEach(badge => {
    if (!shouldShowBadge(badge.badge_slug, isFileUploadEnabled)) {
      return false;
    }
    if (badge.achievement_id && badge.achievement_id > 0) {
      //an achievement
    } else {
      badges.push(badge);
    }
  });
  return badges;
};

export const achievementsOnly = (
  badgeArray,
  userBadges,
  isFileUploadEnabled = true
) => {
  let achievements = [];

  let sortedBadgeArray = badgeArray.sort((a, b) => a.badge_id > b.badge_id);

  sortedBadgeArray.forEach(badge => {
    if (!shouldShowBadge(badge.badge_slug, isFileUploadEnabled)) {
      return false;
    }

    if (badge.achievement_id && badge.achievement_id > 0) {
      //we want to find if it's the highest badge ID that the user has OR the lowest one

      let userHasBadge = false;
      userBadges.forEach(user_badge => {
        if (badge.badge_id === user_badge.badge_id) {
          //user has it
          userHasBadge = true;
        }
      });

      if (!achievements[badge.achievement_id]) {
        achievements[badge.achievement_id] = [];
      }

      //push the first empty one or the one the user has
      if (achievements[badge.achievement_id].length === 0 || userHasBadge) {
        achievements[badge.achievement_id].push(badge);
      }
    }
  });

  let topOfEach = [];
  achievements.forEach(element => {
    if (element.length > 0) {
      topOfEach.push(element[element.length - 1]);
    }
  });
  return topOfEach;
};

export const achievementBadgeFromSlug = typeSlug => {
  if (typeSlug === "autobiographer") {
    return autobiographerImage;
  }

  if (typeSlug === "planner") {
    return plannerImage;
  }
  if (typeSlug === "resourceful-planner") {
    return resourcefulPlannerImage;
  }
  if (typeSlug === "immersed-planner") {
    return immersedPlannerImage;
  }
  if (typeSlug === "master-planner") {
    return masterPlannerImage;
  }

  if (typeSlug === "achiever") {
    return achieverImage;
  }
  if (typeSlug === "resourceful-achiever") {
    return resourcefulAchieverImage;
  }
  if (typeSlug === "immersed-achiever") {
    return immersedAchieverImage;
  }

  if (typeSlug === "economist") {
    return economistImage;
  }
  if (typeSlug === "resourceful-economist") {
    return resourcefulEconomistImage;
  }
  if (typeSlug === "immersed-economist") {
    return immersedEconomistImage;
  }

  if (typeSlug === "supporter-one") {
    return supporterOneImage;
  } else if (typeSlug === "supporter-two") {
    return supporterTwoImage;
  } else if (typeSlug === "supporter-three") {
    return supporterThreeImage;
  } else if (typeSlug === "supporter-four") {
    return supporterFourImage;
  } else if (typeSlug === "supporter-five") {
    return supporterFiveImage;
  }

  if (typeSlug === "influencer-one") {
    return influencerOneImage;
  } else if (typeSlug === "influencer-two") {
    return influencerTwoImage;
  } else if (typeSlug === "influencer-three") {
    return influencerThreeImage;
  } else if (typeSlug === "influencer-four") {
    return influencerFourImage;
  } else if (typeSlug === "influencer-five") {
    return influencerFiveImage;
  }

  if (typeSlug === "commentator-one") {
    return commentatorOneImage;
  } else if (typeSlug === "commentator-two") {
    return commentatorTwoImage;
  } else if (typeSlug === "commentator-three") {
    return commentatorThreeImage;
  } else if (typeSlug === "commentator-four") {
    return commentatorFourImage;
  } else if (typeSlug === "commentator-five") {
    return commentatorFiveImage;
  }

  if (typeSlug === "contributor-one") {
    return contributorOneImage;
  } else if (typeSlug === "contributor-two") {
    return contributorTwoImage;
  } else if (typeSlug === "contributor-three") {
    return contributorThreeImage;
  } else if (typeSlug === "contributor-four") {
    return contributorFourImage;
  } else if (typeSlug === "contributor-five") {
    return contributorFiveImage;
  }

  if (typeSlug === "ideator-one") {
    return ideatorOneImage;
  } else if (typeSlug === "ideator-two") {
    return ideatorTwoImage;
  } else if (typeSlug === "ideator-three") {
    return ideatorThreeImage;
  } else if (typeSlug === "ideator-four") {
    return ideatorFourImage;
  } else if (typeSlug === "ideator-five") {
    return ideatorFiveImage;
  }

  if (typeSlug === "activator-one") {
    return activatorOneImage;
  } else if (typeSlug === "activator-two") {
    return activatorTwoImage;
  } else if (typeSlug === "activator-three") {
    return activatorThreeImage;
  } else if (typeSlug === "activator-four") {
    return activatorFourImage;
  } else if (typeSlug === "activator-five") {
    return activatorFiveImage;
  }

  return lockedBadgeImage;
};

export const getUserGamificationLevelImage = levelSlug => {
  switch ((levelSlug || "").toLowerCase()) {
    case "novice":
      return noviceLevelImage;
    case "advanced-beginner":
      return advancedBeginnerLevelImage;
    case "intermediate":
      return intermediateLevelImage;
    case "advanced":
      return advancedLevelImage;
    case "proficient":
      return proficientLevelImage;
    case "expert":
      return expertLevelImage;
    default:
      return noviceLevelImage;
  }
};
