import {
  FETCH_USER_STATUS,
  LEVEL_UP,
  IS_LEVEL_UP_MODAL_OPEN
} from "../constants/actionTypes";

const INITIAL_STATE = {
  user_status: null,
  num_notifications: 0,
  num_incomplete_tasks: 0,
  levels: null,
  isLevelUpModalOpen: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_STATUS:
      return {
        ...state,
        user_status: action.payload,
        num_notifications: action.payload.num_notifications || 0,
        num_incomplete_tasks: action.payload.num_incomplete_tasks || 0
      };
    case LEVEL_UP:
      return {
        ...state,
        levels: action.payload
      };
    case IS_LEVEL_UP_MODAL_OPEN:
      return {
        ...state,
        isLevelUpModalOpen: action.payload
      };
    default:
      return state;
  }
}
