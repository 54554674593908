import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { alertSwal } from "../../../utils/accountLicenseAlerts";
import { connect } from "react-redux";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import FieldsListAccountLicense from "../../FieldsListAccountLicense/FieldsListAccountLicense";
import LoaderLogoSmall from "../../LoaderLogoSmall/LoaderLogoSmall";
import ButtonMain from "../../buttons/ButtonMain/ButtonMain";
import { hideModal } from "../../../actions/modalActions";
import "./ModalAccountLicense.scss";

const MODAL_HEADER_STYLE = {
  borderBottom: "0px",
  paddingLeft: "0",
  paddingRight: "0"
};

const EXIT_BTN_STYLE = {
  right: "0"
};

const MODAL_CONTENT_STYLE = {
  borderBottom: "0px",
  padding: "0 46px",
  paddingBottom: "24px",
  width: "90%",
  maxWidth: "700px",
  borderRadius: "8px"
};

class ModalAccountLicense extends Component {
  onChange = e => {
    let { name, value } = e.target;

    const currentField = this.props.fields[name];

    if (currentField.type === "radio") {
      value = parseInt(value);
    }

    if (currentField.validRegex) {
      if (!currentField.validRegex.test(value)) {
        return;
      }
    }

    this.setFieldValue({ currentField, value });
  };

  onDropdownChange = (selectedObj, { name }) => {
    const currentField = this.props.fields[name];
    this.setFieldValue({ currentField, value: selectedObj });
  };

  setFieldValue = ({ currentField, value }) => {
    const newFieldsObj = { ...this.props.fields };

    newFieldsObj[currentField.name].value = value;

    if (newFieldsObj[currentField.name].error) {
      delete newFieldsObj[currentField.name].error;
    }

    this.props.setStateWithObj({
      fields: newFieldsObj
    });
  };

  closeModal = () => {
    const { hideModal } = this.props;
    hideModal();
    if (this.props.closeModalCallback) {
      this.props.closeModalCallback();
    }
  };

  closeDialog = () => {
    alertSwal({
      title: this.props.t([
        "modal_account_license_close_title",
        "Are you sure you want to close?"
      ]),
      text: this.props.t([
        "modal_account_license_close_content",
        "All your changes will be discarded."
      ]),
      confirmButtonText: this.props.t([
        "modal_account_license_ok_button",
        "OK"
      ]),
      afterConfirm: this.closeModal
    });
  };

  requestPlanChange = () => {
    //Swal Message
    alertSwal({
      title: this.props.requestSwalTitle,
      text: this.props.t([
        "modal_account_license_send_request_content",
        "Are you sure you want to send this request?"
      ]),
      confirmButtonText: this.props.t([
        "modal_account_license_ok_button",
        "OK"
      ]),
      afterConfirm: this.props.handleSave
    });
  };

  render() {
    if (this.props.isLoading) {
      return <LoaderLogoSmall />;
    }

    return (
      <Modal
        onClose={this.closeDialog}
        contentStyle={MODAL_CONTENT_STYLE}
        disableOverlayClick={true}
      >
        <ModalTemplateOne
          heading={this.props.header}
          onClose={this.closeModal}
          headerStyle={MODAL_HEADER_STYLE}
          exitBtnStyle={EXIT_BTN_STYLE}
        >
          <div
            className="ModalAccountLicense"
            data-testid="ModalAccountLicense"
          >
            <div className="ModalAccountLicense__fields-list-container">
              <FieldsListAccountLicense
                fields={this.props.fields}
                dropdownOptions={this.props.dropdownOptions || {}}
                onChange={this.onChange}
                onDropdownChange={this.onDropdownChange}
              />
            </div>

            <div className="ModalAccountLicense__buttons">
              <ButtonMain
                type="secondary"
                className="ModalAccountLicense__button ModalAccountLicense__cancel"
                clickHandler={this.closeDialog}
              >
                {this.props.t([
                  "modal_account_license_cancel_button",
                  "Cancel"
                ])}
              </ButtonMain>
              <ButtonMain
                type="primary"
                className="ModalAccountLicense__button ModalAccountLicense__request"
                clickHandler={this.requestPlanChange}
              >
                {this.props.t([
                  "modal_account_license_request_button",
                  "Request"
                ])}
              </ButtonMain>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  hideModal
};

export default withTranslation("common")(
  connect(null, mapDispatchToProps)(ModalAccountLicense)
);
