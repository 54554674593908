import {
  FETCHING_OTHER_RUNNING_COSTS_DATA,
  FETCH_OTHER_RUNNING_COSTS_DATA,
  CLEAR_OTHER_RUNNING_COSTS_DATA,
  FETCH_OTHER_RUNING_COSTS_DROPDOWN_DATA
} from "../constants/actionTypes";

const INITIAL_STATE = {
  otherRunningCostsData: null,
  otherRunningCostsDropdownData: null,
  isLoading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_OTHER_RUNNING_COSTS_DATA:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_OTHER_RUNNING_COSTS_DATA:
      return {
        ...state,
        otherRunningCostsData: action.payload,
        isLoading: false
      };
    case CLEAR_OTHER_RUNNING_COSTS_DATA:
      return {
        ...state,
        otherRunningCostsData: null
      };
    case FETCH_OTHER_RUNING_COSTS_DROPDOWN_DATA:
      return {
        ...state,
        otherRunningCostsDropdownData: action.payload
      };
    default:
      return state;
  }
}
