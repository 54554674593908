import React from "react";
import { useTranslation } from "react-i18next";
import TextEditorComments from "../TextEditorComments/TextEditorComments";
import "./InputEdit.scss";
import toolTipImage from "./../../assets/images/ic-info.svg";
import ReactTooltip from "react-tooltip";
import ReactSelectApollo from "../ReactSelectApollo/ReactSelectApollo";

const generateInput = (props, defaultLocalePlaceholders, t) => {
  const {
    inputType,
    onChange,
    editorState,
    readOnly,
    data,
    extraEditorStyling,
    dropdownOptions,
    autoFocus,
    name,
    inputStyles,
    maxLength,
    inputClasses,
    placeholder,
    index,
    selectStyles,
    hideBlockStyling
  } = props;

  let sharedClasses = ["InputEdit-field"];

  const customSelectStyles = selectStyles ? { ...selectStyles } : {};

  if (inputClasses) {
    sharedClasses.push(inputClasses);
  }

  switch (inputType) {
    case "rich-text":
      sharedClasses.push("InputEdit-rich-text-container");
      sharedClasses.shift();
      return (
        <div className={sharedClasses.join(" ")}>
          <TextEditorComments
            onChange={onChange}
            editorState={editorState}
            readOnly={readOnly}
            maxHeight="100px"
            extraStyling={extraEditorStyling}
            maxLength={maxLength}
            hideBlockStyling={hideBlockStyling}
          />
        </div>
      );
    case "dropdown":
      sharedClasses.push("InputEdit-dropdown");
      return (
        <ReactSelectApollo
          {...dropdownOptions}
          autoFocus={autoFocus}
          placeholder={placeholder || defaultLocalePlaceholders.dropdown}
          styles={customSelectStyles}
          noOptionsMessage={() => t(["filter_no_option_text", "No Options"])}
        />
      );
    case "asyncDropdown":
      sharedClasses.push("InputEdit-dropdown");
      return (
        <ReactSelectApollo
          {...dropdownOptions}
          isAsync
          autoFocus={autoFocus}
          cacheOption
          placeholder={placeholder}
          styles={customSelectStyles}
          noOptionsMessage={() => t(["async_selector_no_option", "No Options"])}
          loadingMessage={() => t(["async_selector_loading", "Loading..."])}
        />
      );
    case "creatableDropdown":
      sharedClasses.push("InputEdit-dropdown");
      return (
        <ReactSelectApollo
          {...dropdownOptions}
          isCreatable
          autoFocus={autoFocus}
          placeholder={placeholder}
          styles={customSelectStyles}
          noOptionsMessage={() => t(["async_selector_no_option", "No Options"])}
          formatCreateLabel={newValue =>
            t(["create_new_application_label", `Create ${newValue}`], {
              newValue: newValue
            })
          }
        />
      );
    case "asyncCreatableDropdown":
      sharedClasses.push("InputEdit-dropdown");
      return (
        <ReactSelectApollo
          {...dropdownOptions}
          isAsyncCreatable
          autoFocus={autoFocus}
          placeholder={placeholder}
          styles={customSelectStyles}
          noOptionsMessage={() => t(["async_selector_no_option", "No Options"])}
          loadingMessage={() => t(["async_selector_loading", "Loading..."])}
          formatCreateLabel={newValue =>
            t(["create_new_application_label", `Create ${newValue}`], {
              newValue: newValue
            })
          }
        />
      );
    default:
      sharedClasses.push("InputEdit-input-field");

      let readOnlyClass = "";
      if (readOnly) {
        readOnlyClass = " InputEdit__readonly";
      }
      const onChangeWithTextLimit = e => {
        if (maxLength && e.target.value.length > maxLength) {
          const trimmedValue = e.target.value.slice(0, maxLength);
          e = { ...e };
          e.target.value = trimmedValue;
        }
        if (typeof onChange === "function") {
          onChange(e);
        }
      };

      return (
        <input
          name={name}
          onChange={maxLength ? onChangeWithTextLimit : onChange}
          className={sharedClasses.join(" ") + readOnlyClass}
          type={props.type || "text"}
          value={data}
          placeholder={placeholder}
          autoFocus={autoFocus}
          style={inputStyles}
          data-index={index}
          data-testid="InputEditDefault"
          readOnly={readOnly}
        />
      );
  }
};

const InputEdit = props => {
  const { t } = useTranslation("common");

  const {
    header,
    className,
    required,
    mainStyles = {},
    tooltipText = "",
    index
  } = props;
  const mainClasses = ["InputEdit"];
  const defaultLocalePlaceholders = {
    dropdown: t("general_dropdown_placeholder")
  };

  let requiredMark = null;
  if (header && required) {
    requiredMark = <span className="InputEdit__header-required-mark">*</span>;
  }

  if (className) {
    className
      .trim()
      .split(" ")
      .forEach(name => {
        mainClasses.push(name);
      });
  }

  return (
    <div
      className={mainClasses.join(" ")}
      style={mainStyles}
      data-testid="InputEditContainer"
    >
      {header ? (
        <h4 className="InputEdit__header">
          {header}
          {requiredMark}
          {!!tooltipText && (
            <React.Fragment>
              <span data-tip data-for={`question-${!!index ? index : header}`}>
                <img
                  src={toolTipImage}
                  alt="info"
                  className="InputEdit__tooltip-icon"
                />
              </span>

              <ReactTooltip
                html={true}
                place="top"
                effect="float"
                id={`question-${!!index ? index : header}`}
                className="InputEdit__item-tip-wrapper"
              >
                {tooltipText}
              </ReactTooltip>
            </React.Fragment>
          )}
        </h4>
      ) : null}
      {generateInput(props, defaultLocalePlaceholders, t)}
    </div>
  );
};

export default InputEdit;
