import uniqBy from "lodash/uniqBy";

import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_EDIT,
  MODIFY_EDITED_USER_PROFILE,
  SET_TENANT_LOGO,
  UPDATE_USER_PROFILE,
  CLEAR_USER_PROFILE,
  FETCH_ALL_USERS,
  FETCH_SELECTED_USERS,
  FETCHING_SELECTED_USERS,
  CLEAR_SELECTED_USERS,
  FETCH_USER_PROFILE_IDEAS,
  FETCH_USER_PROFILE_COLLABORATIONS,
  FETCH_USER_PROFILE_CHANGE_REQUESTS,
  FETCH_USER_PROFILE_DOCUMENTATION,
  FETCH_MORE_USER_PROFILE_DOCUMENTATION,
  SET_IS_LOADING_USER_PROFILE_DOCUMENTATION,
  FETCH_USER_PROFILE_COMPONENTS,
  FETCH_USER_PROFILE_SIDEBAR,
  FETCH_SELECTED_USER_BADGES,
  FETCH_FLOW_TREE,
  FETCH_USER_PROFILE_AUTOMATIONS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  profile: null,
  profile_editing: null,
  tenantLogo: null,
  allUsers: null,
  selectedUsers: null,
  isLoadingSelectedUsers: false,
  userIdeas: null,
  userAutomations: null,
  userChangeRequests: null,
  userCollaborations: null,
  userDocumentation: null,
  hasMoreDocumentations: true,
  isLoadingDocumentations: false,
  userComponents: null,
  userSidebar: null,
  userBadges: null,
  flowTree: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return { ...state, profile: action.payload };
    case FETCH_USER_PROFILE_EDIT:
      return { ...state, profile_editing: action.payload };
    case MODIFY_EDITED_USER_PROFILE:
      return { ...state, profile_editing: action.payload };
    case SET_TENANT_LOGO:
      return { ...state, tenantLogo: action.payload };
    case UPDATE_USER_PROFILE:
      return { ...state, profile: action.payload };
    case CLEAR_USER_PROFILE:
      return {
        ...state,
        profile: null,
        profile_editing: null,
        userSidebar: null,
        userBadges: null
      };
    case FETCH_ALL_USERS:
      return { ...state, allUsers: action.payload };
    case FETCHING_SELECTED_USERS:
      return { ...state, isLoadingSelectedUsers: true };
    case FETCH_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.payload,
        isLoadingSelectedUsers: false
      };
    case CLEAR_SELECTED_USERS:
      return { ...state, selectedUsers: null };
    case FETCH_USER_PROFILE_IDEAS:
      return { ...state, userIdeas: action.payload };
    case FETCH_USER_PROFILE_COLLABORATIONS:
      return { ...state, userCollaborations: action.payload };
    case FETCH_USER_PROFILE_CHANGE_REQUESTS:
      return { ...state, userChangeRequests: action.payload };
    case FETCH_USER_PROFILE_AUTOMATIONS:
      return { ...state, userAutomations: action.payload };
    case FETCH_USER_PROFILE_DOCUMENTATION: {
      let hasMoreDocumentations = true;

      return {
        ...state,
        userDocumentation: action.payload,
        hasMoreDocumentations,
        isLoadingDocumentations: false
      };
    }
    case FETCH_MORE_USER_PROFILE_DOCUMENTATION: {
      let hasMoreDocumentations = true;
      if (
        !Array.isArray(state.userDocumentation) &&
        !Array.isArray(action.payload)
      ) {
        return { ...state };
      }

      const allDocumentations = uniqBy(
        [...state.userDocumentation, ...action.payload],
        "document_id"
      );
      if (allDocumentations.length === state.userDocumentation.length) {
        hasMoreDocumentations = false;
      }

      return {
        ...state,
        userDocumentation: allDocumentations,
        hasMoreDocumentations,
        isLoadingDocumentations: false
      };
    }
    case SET_IS_LOADING_USER_PROFILE_DOCUMENTATION: {
      return {
        ...state,
        isLoadingDocumentations: action.payload
      };
    }
    case FETCH_USER_PROFILE_COMPONENTS:
      return { ...state, userComponents: action.payload };
    case FETCH_USER_PROFILE_SIDEBAR:
      return { ...state, userSidebar: action.payload };
    case FETCH_SELECTED_USER_BADGES:
      return { ...state, userBadges: action.payload };
    case FETCH_FLOW_TREE:
      return { ...state, flowTree: action.payload };
    default:
      return state;
  }
}
