import { LegacyQuestionAnswerSlugs } from "../../constants/co-migration-constants";
import {
  CODocumentTypes,
  COConditionSlugs
} from "../../constants/co-constants";
import { COMetadataItemInterface } from "../../interfaces/co-interfaces";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  NonStandardQuestionAnswerOptionSlugs,
  NonStandardQuestionSlugs,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";
import { CODefaultEquations } from "../../constants/co-calculation.constants";
import { COPhaseStatusKeys } from "../../constants/co-phase-status.constants";

export const customQ1SelectionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.Q1_DECISIONS_STRAIGHTFORWARD_RULES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_DECISIONS_STRAIGHTFORWARD_RULES,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "The decisions have straight forward rules",
        description:
          "Use the scale to indicate if the task/process is managed through precise and well-defined rules or if it is rather creative and irregular.",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_DECISIONS_STRAIGHTFORWARD_RULES
          ),
          co_question_position_json: {
            sort_order: 0
          }
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly agree",
            value: "100",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_RULES_STRONGLY_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Agree",
            value: "80",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_RULES_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Neither disagree or agree",
            value: "60",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_RULES_NEITHER_DISAGREE_OR_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Disagree",
            value: "40",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_RULES_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly disagree",
            value: "20",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_RULES_STRONGLY_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "I don't know",
            value: "60",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_RULES_I_DONT_KNOW
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_INPUT_DATA_ACCESSED_DIGITALLY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_INPUT_DATA_ACCESSED_DIGITALLY,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "The input data is accessed digitally",
        description:
          "Use the scale to indicate if the input data is stored and accessible from a machine (mobile phone, laptop, server etc).",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_INPUT_DATA_ACCESSED_DIGITALLY
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly agree",
            value: "100",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_TYPE_STRONGLY_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Agree",
            value: "80",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_TYPE_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Neither disagree or agree",
            value: "60",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_INPUT_TYPE_NEITHER_DISAGREE_OR_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Disagree",
            value: "40",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_TYPE_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly disagree",
            value: "20",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_INPUT_TYPE_STRONGLY_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "I don't know",
            value: "60",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_TYPE_I_DONT_KNOW
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_DATA_HIGHLY_FORMATTED,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_DATA_HIGHLY_FORMATTED,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "The data is highly formatted",
        description:
          "Use the scale to indicate if the format of the input data has a predictable format available in fixed fields, and is easily detectable via search algorithms (excels, tables with fixed structure) or if it can be described as not highly predictable and difficult to detect (web logs, multimedia content, customer service interactions and social media data).",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_DATA_HIGHLY_FORMATTED
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly agree",
            value: "100",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_INPUT_QUALITY_STRONGLY_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Agree",
            value: "80",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_QUALITY_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Neither disagree or agree",
            value: "60",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_INPUT_QUALITY_NEITHER_DISAGREE_OR_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Disagree",
            value: "40",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_QUALITY_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly disagree",
            value: "20",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_INPUT_QUALITY_STRONGLY_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "I don't know",
            value: "60",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_INPUT_QUALITY_I_DONT_KNOW
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_NO_EXPECTED_CHANGES,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_NO_EXPECTED_CHANGES,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "This process has no expected changes in the next 6 months",
        description:
          "Use the scale to indicate if the way in which you perform the task/process can vary or if changes are expected for the next 6 months. If you do not have visibility on upcoming changes then you can base your answer on your past experience with the task/process.",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_NO_EXPECTED_CHANGES
          ),
          co_question_position_json: {
            sort_order: 30
          }
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly agree",
            value: "100",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_STABILITY_STRONGLY_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Agree",
            value: "80",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_STABILITY_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Neither disagree or agree",
            value: "60",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_STABILITY_NEITHER_DISAGREE_OR_AGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Disagree",
            value: "40",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_STABILITY_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Strongly disagree",
            value: "20",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_STABILITY_STRONGLY_DISAGREE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "I don't know",
            value: "60",
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_STABILITY_I_DONT_KNOW
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.Q1_PROCESS_DOCUMENTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q1_PROCESS_DOCUMENTS,
        standard_question_template_slug: NonStandardQuestionSlugs.Q1_DOCUMENTS,
        title: "Are any of these process documents available?",
        description:
          "Having any documentation increases the readiness score and builds a stronger case for your idea to be automated. Attaching files is optional.",
        note:
          "NOTE: The documents added will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings.",
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION }
          ],
          max_selectable_answers: 6
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q1_PROCESS_DOCUMENTS
          ),
          co_question_position_json: {
            sort_order: 40
          },
          co_equation: CODefaultEquations.MAX_OF_ANSWERS,
          co_display_equation: CODefaultEquations.MAX_OF_ANSWERS
        },
        answer_option_info: [
          {
            answer_option_slug:
              NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
            title: "Detailed work instructions",
            value: "100",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_DETAILED_WORK_INSTRUCTIONS,
            document_options: {
              documentTypeId: CODocumentTypes.DETAILED_WORK_INSTRUCTIONS
            },
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
            title: "Standard operating procedure",
            value: "80",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_STANDARD_OPERATING_PROCEDURE,
            document_options: {
              documentTypeId: CODocumentTypes.STANDARD_OPERATING_PROCEDURE
            },
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
            title: "Task/Process maps/flowcharts",
            value: "60",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_TASK_PROCESS_MAPS_FLOWCHARTS,
            document_options: {
              documentTypeId: CODocumentTypes.PROCESS_MAP
            },
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
            title: "Input files",
            value: "40",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_INPUT_FILES,
            document_options: {
              documentTypeId: CODocumentTypes.INPUT_FILE
            },
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
            title: "Output files",
            value: "40",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_OUTPUT_FILES,
            document_options: {
              documentTypeId: CODocumentTypes.OUTPUT_FILE
            },
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              NonStandardQuestionAnswerOptionSlugs.DOCUMENT_MULTIPLE_CHOICE_OPTION,
            title: "Misc. files",
            value: "40",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_MISC,
            document_options: {
              documentTypeId: CODocumentTypes.MISC
            },
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION,
            title: "There are no files affiliated with this process",
            value: "20",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED_EXCEPT,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_MULTIPLE_CHOICE_OPTION,
            title: "I don't know",
            value: "20",
            title_is_editable: false,
            prevent_deleting: 1,
            legacy_slug: LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW,
            condition_overrides: [
              {
                slug: COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED_EXCEPT,
                payload: {
                  legacy_slugs: [
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_NO_DOCUMENTATION,
                    LegacyQuestionAnswerSlugs.Q1_DOCUMENTATION_I_DONT_KNOW
                  ]
                }
              }
            ]
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.PROCESS_OWNER_Q1,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.PROCESS_OWNER_Q1,
        standard_question_template_slug: StandardQuestionSlugs.USER,
        title: "Process Owner",
        description: "",
        note:
          "NOTE: This question is tied to a default collaborator role. The answer will be displayed in the Collaborators page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.PROCESS_OWNER_Q1
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FOR_EDIT },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE },
            {
              slug: COConditionSlugs.REQUIRED_FOR_PHASE_STATUS_CHANGE,
              payload: {
                phase_status_key: COPhaseStatusKeys.IDEA_AWAITING_REVIEW,
                next_phase_status_key: COPhaseStatusKeys.ASSESSMENT_NOT_STARTED
              }
            }
          ]
        }
      })
    }
  ];
};
