import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import {
  COComponentType,
  CODocumentTypes,
  COExternalAnswerSources,
  COConditionSlugs
} from "../../constants/co-constants";
import { LegacyQuestionAnswerSlugs } from "../../constants/co-migration-constants";
import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardQuestionAnswerOptionSlugs,
  StandardQuestionSlugs
} from "../../constants/co-question.constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";
import { CODefaultEquations } from "../../constants/co-calculation.constants";

const PACKAGE_DOCUMENT_MAX_UPLOAD_SIZE = 50 * 1024 * 1024; // 50MB
const SOURCE_CODE_DOCUMENT_MAX_UPLOAD_SIZE = 50 * 1024 * 1024; // 50MB

export const customQ3QuestionTemplatesBySlugs = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.AUTOMATION_COMPONENTS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_COMPONENTS,
        standard_question_template_slug: StandardQuestionSlugs.COMPONENTS,
        title: "Components",
        description: "Tag any components used in this automation",
        note:
          "NOTE: This question will not be shown in the automation pipeline. The answer will be displayed in the Components page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_COMPONENTS
          ),
          co_question_position_json: {
            sort_order: 190
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE }
          ]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_USER_GUIDES_AND_DOCS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_USER_GUIDES_AND_DOCS,
        standard_question_template_slug: StandardQuestionSlugs.DOCUMENT,
        title: "User guides and documents",
        description:
          "If you've created any documents to go with your automation upload them here. This can include user guides and documentation and also PDD and SDD documents. Note: Please make sure that the technical documentation you upload does not contain classified information or personal data.",
        note:
          "NOTE: This question will not be shown in the automation pipeline. The answer will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_USER_GUIDES_AND_DOCS
          ),
          co_question_position_json: {
            sort_order: 170
          }
        },
        options_meta_overrides: {
          max_selectable_answers: 25,
          document_options: {},
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_PACKAGE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_PACKAGE,
        standard_question_template_slug: StandardQuestionSlugs.DOCUMENT,
        title: "Upload package",
        description:
          "Please upload your automation package (nugpkg - exported from UiPath Studio). Check here where to find the package locally. The maximum file size is 50MB.",
        note:
          "NOTE: This question will not be shown in the automation pipeline. The answer will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_PACKAGE
          ),
          co_question_position_json: {
            sort_order: 150
          }
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER,
            document_options: {
              allowedFileTypes: ["nupkg"],
              allowedFileTypesMetaItem: {
                value: "Select .nupkg file. Max upload size is 50MB",
                value_localization_key: ""
              },
              documentTypeId: CODocumentTypes.AUTOMATION_NUGET_PACKAGE,
              maxAllowedFileSize: PACKAGE_DOCUMENT_MAX_UPLOAD_SIZE
            }
          }
        ],
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_SOURCE_CODE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_SOURCE_CODE,
        standard_question_template_slug: StandardQuestionSlugs.DOCUMENT,
        title: "Source code",
        description:
          "Please upload your code (.zip, .xaml) or provide a link to the repository. Check here where to find the source code of your automation. The maximum file size is 50MB.",
        note:
          "NOTE: This question will not be shown in the automation pipeline. The answer will be displayed in the Documentation page of the automation profile regardless of this section's visibility settings.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_SOURCE_CODE
          ),
          co_question_position_json: {
            sort_order: 140
          }
        },
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_DOCUMENT_ANSWER,
            document_options: {
              allowedFileTypes: ["zip", "xaml"],
              allowedFileTypesMetaItem: {
                value: "Select .zip or .xaml file. Max upload size is 50MB",
                value_localization_key: ""
              },
              documentTypeId: CODocumentTypes.AUTOMATION_SOURCE_CODE,
              maxAllowedFileSize: SOURCE_CODE_DOCUMENT_MAX_UPLOAD_SIZE
            }
          }
        ],
        options_meta_overrides: {
          max_selectable_answers: 1,
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE },
            { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
            { slug: COConditionSlugs.HIDE_FROM_PROFILE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average rework time - after automation",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 120
          },
          co_question_meta_json: {
            title_header: { value: "After Automation" }
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average rework time - before automation",
        description: "In minutes, how long does it take to resolve an error?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 110
          },
          co_question_meta_json: {
            title_header: { value: "Before Automation" }
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          })
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
        title: "Average error rate - after automation",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 90
          },
          co_question_meta_json: {
            title_header: { value: "After Automation" }
          }
        },
        answer_option_info: []
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.PERCENTAGE,
        title: "Average error rate - before automation",
        description: "In percentages how often do errors occur?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 80
          },
          co_question_meta_json: {
            title_header: { value: "Before Automation" }
          }
        },
        answer_option_info: []
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average process duration - after automation",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 60
          },
          co_question_meta_json: {
            title_header: { value: "After Automation" }
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [{ slug: COConditionSlugs.QUESTION_IS_REQUIRED }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Average process duration - before automation",
        description: "How long does it take to perform this process once?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION
          ),
          co_question_position_json: {
            sort_order: 50
          },
          co_question_meta_json: {
            title_header: { value: "Before Automation" }
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 2,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: [{ slug: COConditionSlugs.QUESTION_IS_REQUIRED }]
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Number of people that could benefit from this automation",
        description:
          "How many people do you think can benefit from using this automation at your organization?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT
          ),
          co_question_position_json: {
            sort_order: 40
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE,
        standard_question_template_slug: StandardQuestionSlugs.NUMBER,
        title: "Volume average",
        description:
          "How many repetitions do you typically perform on this process in the selected frequency?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE
          ),
          co_question_position_json: {
            sort_order: 30
          }
        },
        answer_option_info: [],
        options_meta_overrides: {
          rounding_decimals: 0,
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.NONE
          }),
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_PROCESS_PEAKS,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_PROCESS_PEAKS,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Automation process peaks",
        description:
          "A peak is a noticeable increase in activity/volumes in a certain period of time. E.g. Purchase Orders may experience a peak at the end of the month or quarter as people are trying to squeeze in purchases in that budget cycle.",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_PROCESS_PEAKS
          ),
          co_question_position_json: {
            sort_order: 20
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE },
          { slug: COConditionSlugs.QUESTION_CAN_IMPACT_KPI }
        ],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Regular (e.g. month closing)",
            value: "-1",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_PEAKS_REGULAR
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title:
              "Rare but predictable event (e.g. winter holidays/ yearly closing)",
            value: "-1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q3_PROCESS_PEAKS_RARE_PREDICTABLE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Rare and unpredictable event",
            value: "-1",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q3_PROCESS_PEAKS_RARE_UNPREDICTABLE
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "There are no peaks",
            value: "-1",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_PEAKS_NO_PEAKS
          }
        ]
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY,
        standard_question_template_slug: StandardQuestionSlugs.SINGLE_CHOICE,
        title: "Process frequency",
        description: "How often do you have to perform this process?",
        question_overrides: {
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY
          ),
          co_question_position_json: {
            sort_order: 10
          }
        },
        options_meta_overrides: {
          condition_overrides: [
            { slug: COConditionSlugs.QUESTION_IS_REQUIRED },
            { slug: COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE }
          ]
        },
        condition_overrides_to_remove: [
          { slug: COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE }
        ],
        answer_option_info: [
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Daily",
            value: "260",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_FREQUENCY_DAILY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Weekly",
            value: "52",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_FREQUENCY_WEEKLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Bi-Weekly",
            value: "26",
            legacy_slug:
              LegacyQuestionAnswerSlugs.Q3_PROCESS_FREQUENCY_BI_WEEKLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Monthly",
            value: "12",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_FREQUENCY_MONTHLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Quarterly",
            value: "4",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_FREQUENCY_QUATERLY
          },
          {
            answer_option_slug:
              StandardQuestionAnswerOptionSlugs.STANDARD_SINGLE_CHOICE_OPTION,
            title: "Yearly",
            value: "1",
            legacy_slug: LegacyQuestionAnswerSlugs.Q3_PROCESS_FREQUENCY_YEARLY
          }
        ]
      })
    }
  ];
};
