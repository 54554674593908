import React from "react";
import LoaderLogoSmall from "../LoaderLogoSmall/LoaderLogoSmall";
import SessionHelper from "../../helpers/SessionHelper";
import { ErrorHandlerContent } from "../ErrorHandler/ErrorHandler";

const PageLazyLoader = ({ error }) => {
  if (error) {
    const lastErrorRefreshEpoch = SessionHelper.getLazyLoadRefreshEpoch();
    const currentEpoch = new Date().getTime();
    if (lastErrorRefreshEpoch && lastErrorRefreshEpoch > currentEpoch - 15000) {
      return <ErrorHandlerContent />;
    } else {
      SessionHelper.setLazyLoadRefreshEpoch();
      window.location.reload();
    }

    return null;
  } else {
    return <LoaderLogoSmall fullPage />;
  }
};

export default PageLazyLoader;
