import { COQuestionTemplate } from "../../classes/co-question.class";
import {
  CustomQuestionSlugs,
  CustomQuestionVariableName,
  StandardKPISlugs,
  NonStandardKPISlugs
} from "../../constants/co-question.constants";
import { PATH, PATH_ROUTE } from "../../constants/co-path.constants";
import { COConditionSlugs } from "../../constants/co-constants";
import { customQuestionWithInfo } from "../co-custom-questions.template";

export const customQ3KPITemplatesBySlug = (): {
  slug: string;
  template: COQuestionTemplate | undefined;
}[] => {
  return [
    {
      slug: CustomQuestionSlugs.CTZN_DEV_LIVE_BENEFIT_EM_HRS_MO,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.DA_LIVE_BENEFIT_EM_HRS_MO,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        note: "NOTE: KPI is displayed in the Automation Store profile",
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_MONTH
          }),
          condition_overrides: [{ slug: COConditionSlugs.HIDDEN }]
        },
        question_overrides: {
          co_question_meta_json: {
            title: {
              value: "Potential benefit per employee (hours saved/month)"
            }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.CTZN_DEV_LIVE_BENEFIT_EM_HRS_MO
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE}/12,0)`,
          co_question_position_json: {
            sort_order: 200
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_AVERAGE_REWORK_TIME_DIFFERENCE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_AVERAGE_REWORK_TIME_DIFFERENCE,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Rework time difference",
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_question_meta_json: {
            title_header: { value: "Difference" }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_AVERAGE_REWORK_TIME_DIFFERENCE
          ),
          co_equation: "",
          co_display_equation: `${CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION}-${CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION}`,
          co_question_position_json: {
            sort_order: 130
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_AVERAGE_ERROR_RATE_DIFFERENCE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.AUTOMATION_AVERAGE_ERROR_RATE_DIFFERENCE,
        standard_kpi_template_slug: StandardKPISlugs.PERCENT_KPI,
        title: "Error rate difference",
        options_meta_overrides: {
          condition_overrides: []
        },
        question_overrides: {
          co_question_meta_json: {
            title_header: { value: "Difference" }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_AVERAGE_ERROR_RATE_DIFFERENCE
          ),
          co_equation: "",
          co_display_equation: `${CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION}-${CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION}`,
          co_question_position_json: {
            sort_order: 100
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.AUTOMATION_AVERAGE_PROCESS_DURATION_DIFFERENCE,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.AUTOMATION_AVERAGE_PROCESS_DURATION_DIFFERENCE,
        standard_kpi_template_slug: StandardKPISlugs.NUMBER_KPI,
        title: "Process duration difference",
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.MINUTES
          }),
          condition_overrides: []
        },
        question_overrides: {
          co_question_meta_json: {
            title_header: { value: "Difference" }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.AUTOMATION_AVERAGE_PROCESS_DURATION_DIFFERENCE
          ),
          co_equation: "",
          co_display_equation: `${CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION}-${CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION}`,
          co_question_position_json: {
            sort_order: 70
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY,
      template: customQuestionWithInfo({
        slug:
          CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY,
        standard_kpi_template_slug:
          NonStandardKPISlugs.Q3_POTENTIAL_BENEFIT_KPI,
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        },
        question_overrides: {
          co_question_meta_json: {
            title: { value: "Potential benefit across company" }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY
          ),
          co_equation: "",
          co_display_equation: `round(${CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE}*${CustomQuestionSlugs.AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT},0)`,
          co_question_position_json: {
            sort_order: 20
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    },
    {
      slug: CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE,
      template: customQuestionWithInfo({
        slug: CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE,
        standard_kpi_template_slug:
          NonStandardKPISlugs.Q3_POTENTIAL_BENEFIT_KPI,
        options_meta_overrides: {
          unit: PATH({
            route: PATH_ROUTE.CONTEXT.TEMPLATES.UNITS.HOURS_YEAR
          })
        },
        question_overrides: {
          co_question_meta_json: {
            title: { value: "Potential benefit per employee" }
          },
          co_variable_name: CustomQuestionVariableName(
            CustomQuestionSlugs.Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE
          ),
          co_equation: "",
          co_display_equation: `round(round((${CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE}* (${CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION}+ (${CustomQuestionSlugs.AVERAGE_ERROR_RATE_BEFORE_AUTOMATION} / 100) * ${CustomQuestionSlugs.AVERAGE_REWORK_TIME_BEFORE_AUTOMATION}) *
           ${CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY}) / 60,0) - round((${CustomQuestionSlugs.AUTOMATION_VOLUME_AVERAGE}* (${CustomQuestionSlugs.AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION}+ (${CustomQuestionSlugs.AVERAGE_ERROR_RATE_AFTER_AUTOMATION} / 100) * ${CustomQuestionSlugs.AVERAGE_REWORK_TIME_AFTER_AUTOMATION}) * ${CustomQuestionSlugs.AUTOMATION_PROCESS_FREQUENCY}) / 60,0),0)`,
          co_question_position_json: {
            sort_order: 10
          },
          co_question_default_options_json: {
            default_equation_value: undefined
          }
        }
      })
    }
  ];
};
